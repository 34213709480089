import {Injectable} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {UserInfoService} from '../../service/user-info.service';
import {RequestDataService} from '../../service/request-data.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {AppConfigService} from '../../service/app-config.service';
import {AjaxService} from '../../service/ajax.service';
import {Md5} from 'ts-md5/dist/md5';
import * as _ from 'underscore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private autoInsert: AutoInsertService,
              private userInfo: UserInfoService,
              private requestData: RequestDataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService,
              private util: UtilsService,
              private ajaxService: AjaxService) {

  }

  uploadFile(file) {
    let params = file;
    const tmpUrl = this.config.config.javaPlatform + '/dataController/fileUpload';
    return this.ajaxService.ajaxUpload(tmpUrl, params,true);
  }
  // 授权
  insertReEmpower(pModel) {
    const user = this.userInfo.getUserInfo();
    const templateJson = {
      'LIST': [
        {
          'TABLE': 're_empower',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'EMPOWER_USER_CODE'},
            {'COLUMN': 'EMPOWER_USER_ID'},
            {'COLUMN': 'EMPOWER_USER_NAME'},
            {'COLUMN': 'GET_EMPOWER_USER_ID'},
            {'COLUMN': 'GET_EMPOWER_USER_NAME'},
            {'COLUMN': 'GET_EMPOWER_USER_CODE'},
            {'COLUMN': 'GET_EMPOWER_USER_TYPE'},
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_USERID'}
          ],
          'RECORDS': [
            {
              'ID': 'SYS_UUID',
              'EMPOWER_USER_CODE': user.USER_NAME,
              'EMPOWER_USER_ID': user['EMP_ID'],
              'EMPOWER_USER_NAME': user['USERNAME'],
              'GET_EMPOWER_USER_ID': pModel['ID'],
              'GET_EMPOWER_USER_NAME': pModel['name'],
              'GET_EMPOWER_USER_CODE': pModel['code'],
              'GET_EMPOWER_USER_TYPE': pModel['type'],
              'ADD_TIME': 'SYS-DATETIME',
              'ADD_NAME': user.USERNAME,
              'ADD_USERID': user.USER_ID,
              'U_DELETE': 1
            }
          ]
        }
      ]
    };
    if (pModel.type === 'STUDENT' && pModel.IS_USER === 0) {
      let insertStudentUser: any = {
        'TABLE': 'system_user',
        'ACTION': 'Insert',
        'WHEREGROUP': [{}],
        'WHERE': [{}],
        'COLUMNS': [
          {'COLUMN': 'USER_ID'},
          {'COLUMN': 'USER_NAME'},
          {'COLUMN': 'PASSWD'},
          {'COLUMN': 'ISVAILID'},
          {'COLUMN': 'EMP_ID'},
          {'COLUMN': 'EMP_NAME'},
          {'COLUMN': 'USER_TYPE'},
          {'COLUMN': 'U_DELETE'},
          {'COLUMN': 'ADD_TIME'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'ADD_USERID'}
        ],
        'RECORDS': [
          {
            'USER_ID': 'SYS_UUID',
            'USER_NAME': pModel['STUDENT_CODE'],
            'PASSWD': pModel['PASSWORD'],
            'ISVAILID': 1,
            'EMP_ID': pModel['ID'],
            'EMP_NAME': pModel['STUDENT_NAME'],
            'USER_TYPE': 'STUDENT',
            'ADD_TIME': 'SYS-DATETIME',
            'ADD_NAME': user.USERNAME,
            'ADD_USERID': user.USER_ID,
            'U_DELETE': 1
          }
        ]
      };
      templateJson.LIST.push(insertStudentUser);
      let updateStudent: any = {
        'TABLE': 'basic_student',
        'ACTION': 'Update',
        'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
        'WHERE': [{
          'COLUMN': 'ID',
          'VALUE': pModel['ID'],
          'OPERATOR': '=',
          'VALUE_TYPE': 'string',
          'CONDITION_OPERATOR': 'AND',
          'GROUP': 'GROUP_01'
        }],
        'COLUMNS': [
          {'COLUMN': 'IS_USER'},
          {'COLUMN': 'UPD_TIME'},
          {'COLUMN': 'UPD_NAME'},
          {'COLUMN': 'UPD_USERID'}
        ],
        'RECORDS': [
          {
            'IS_USER': 1,
            'UPD_TIME': 'SYS-DATETIME',
            'UPD_NAME': user.USERNAME,
            'UPD_USERID': user.USER_ID
          }
        ]
      };
      templateJson.LIST.push(updateStudent);
    }
    return this.autoInsert.ExecJson(templateJson);
  }

  // 取消授权
  updateReEmpower(pModel) {
    const user = this.userInfo.getUserInfo();
    const templateJson = {
      'LIST': [
        {
          'TABLE': 're_empower',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pModel['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'U_DELETE': 0,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 教师生成账号
  insertTeacherUser(pList) {
    const user = this.userInfo.getUserInfo();
    let idList = [];
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'system_user',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'USER_ID'},
            {'COLUMN': 'USER_NAME'},
            {'COLUMN': 'PASSWD'},
            {'COLUMN': 'ISVAILID'},
            {'COLUMN': 'EMP_ID'},
            {'COLUMN': 'EMP_NAME'},
            {'COLUMN': 'USER_TYPE'},
            {'COLUMN': 'RGID'},
            {'COLUMN': 'ORG_NAME'},
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_USERID'}
          ],
          'RECORDS': []
        }
      ]
    };
    let data: any = {};
    _.each(pList, oneData => {
      data = {
        'USER_ID': 'SYS_UUID',
        'USER_NAME': oneData['JOB_CODE'],
        'PASSWD': oneData['PASSWORD'],
        'ISVAILID': 1,
        'EMP_ID': oneData['ID'],
        'EMP_NAME': oneData['TEACHER_NAME'],
        'USER_TYPE': 'TEACHER',
        'RGID': oneData['ORG_ID'],
        'ADD_TIME': 'SYS-DATETIME',
        'ADD_NAME': user.USERNAME,
        'ADD_USERID': user.USER_ID,
        'U_DELETE': 1
      };
      idList.push(oneData['ID']);
      templateJson.LIST[0].RECORDS.push(data);
    });
    let ids = idList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let updateTeacher = {
      'TABLE': 'basic_teacher',
      'ACTION': 'Update',
      'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
      'WHERE': [{
        'COLUMN': 'ID',
        'VALUE': ids,
        'OPERATOR': 'in',
        'VALUE_TYPE': 'int',
        'CONDITION_OPERATOR': 'AND',
        'GROUP': 'GROUP_01'
      }],
      'COLUMNS': [
        {'COLUMN': 'IS_USER'},
        {'COLUMN': 'UPD_TIME'},
        {'COLUMN': 'UPD_NAME'},
        {'COLUMN': 'UPD_USERID'}
      ],
      'RECORDS': [
        {
          'IS_USER': 1,
          'UPD_TIME': 'SYS-DATETIME',
          'UPD_NAME': user.USERNAME,
          'UPD_USERID': user.USER_ID
        }
      ]
    };
    templateJson.LIST.push(updateTeacher);
    return this.autoInsert.ExecJson(templateJson);
  }

  updateModel(pData) {
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'basic_teacher',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pData['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'CH_TEMPLATE_NO'},
            {'COLUMN': 'EN_TEMPLATE_NO'}
          ],
          'RECORDS': [
            {
              'CH_TEMPLATE_NO': pData['CH_TEMPLATE_NO'],
              'EN_TEMPLATE_NO': pData['EN_TEMPLATE_NO'],
            }
          ]
        }]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 问题反馈更改状态为已处理
  updateFeedback(pIds) {
    const user = this.userInfo.getUserInfo();
    let ids = pIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 're_feedback',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ERROR_STATE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ERROR_STATE': 'PROCESSED',
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID
            }
          ]
        }]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 新增修改日志,记录修改了哪些字段,并记录从什么修改成什么
  updateDataLog(pData) {
    const that = this;
    const user = that.userInfo.getUserInfo();
    // let logTable = '';
    // let logPK = '';
    // let logContent = '';
    // let i = 0;
    // _.each(parentData['data']['detailColumns'],  (Oneform) => {
    //   if (i === 0) {
    //     logTable = Oneform['formname'];
    //   }
    //   // 只记录主表变动的日志
    //   if (that.util.Base_compare(Oneform['type'], 'normal')) {
    //     let j = 0;
    //     _.each(Oneform['columns'],  (OneColumn) => {
    //       // 获取记录主键
    //       if (i === 0 && OneColumn['pk_column']) {
    //         logPK = OneColumn['model'];
    //       }
    //       // 只获取显示的字段
    //       if (OneColumn['visible']) {
    //         // 判断字段
    //         let curValue = OneColumn['model']; // 原值
    //         let prvValue = prvForms['data']['detailColumns'][i].columns[j].model; // 现有值
    //         if (!that.util.Base_compare(prvValue, curValue)) { // 当两个值不想等,表明值已经修改过
    //           if (_.contains(['select', 'poplist', 'inputDataFilter'], OneColumn['component'])) { // 下拉框和弹框取值方式不一样
    //             _.each(OneColumn['itemArray'],  (oneItem) => {
    //               if (that.util.Base_compare(oneItem['code'], curValue)) { // 将字段转换成汉字
    //                 curValue = oneItem['name'];
    //               }
    //               if (that.util.Base_compare(oneItem['code'], prvValue)) {
    //                 prvValue = oneItem['name'];
    //               }
    //             });
    //           }
    //           logContent += OneColumn['lable'] + ':由[' + prvValue + ']修改为[' + curValue + '] ; ';
    //         }
    //       }
    //       j++;
    //     });
    //   }
    //   i++;
    // });

    // if (logContent === '') {
    //   logContent = '直接保存,未修改数据!';
    // }

    const templateJson = {
      'LIST': [
        {
          'TABLE': 'system_edit_log',
          'ACTION': 'Insert',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'UPD_ID'},
            {'COLUMN': 'USER_ID'},
            {'COLUMN': 'USER_NAME'},
            {'COLUMN': 'USER_REL_NAME'},
            {'COLUMN': 'UPD_TABLE'},
            {'COLUMN': 'UPD_TYPE'},
            {'COLUMN': 'UPD_PK'},
            {'COLUMN': 'UPD_CONTENT'}
          ],
          'RECORDS': [
            {
              'UPD_ID': 'SYS-UUID',
              'USER_ID': user.USER_ID,
              'USER_NAME': user.USER_NAME,
              'USER_REL_NAME': user.USERNAME,
              'UPD_TABLE': pData.logTable,
              'UPD_TYPE': pData.logType,
              'UPD_PK': pData.logPK,
              'UPD_CONTENT': pData.logContent
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  updateTeacherUrlAlias(pData) {
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'basic_teacher',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pData['teacherId'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'RESUME_URL_ALIAS'}
          ],
          'RECORDS': [
            {
              'RESUME_URL_ALIAS': pData['urlAlias']
            }
          ]
        }]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  getResumeJsonModel(pType) {
    let jsonUrl = '../../assets/resume/model/ch_resume.json';
    if (pType === 'EN') {
      jsonUrl = '../../assets/resume/model/en_resume.json';
    }
    return this.ajaxService.ajaxGet(jsonUrl);
  }

  // 编辑完成接口
  saveResumeResult(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/resumeSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  releaseResume(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/releaseResume?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  publishResume(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/publishResume?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  resumeRebuild() {
    const user = this.userInfo.getUserInfo();
    let params = {
      userId: user.USER_ID,
      userName: user.USERNAME
    };
    const tmpUrl = this.config.config.quickSliver + '/resume/rebuildResume?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  publishTeachersResume(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/publishTeachersResume?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }
  outstandingTalent(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/outstandingTalent?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }
  setCollegeAdmin(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/resume/setCollegeAdmin?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }
}
