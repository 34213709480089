import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as moment from "moment";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
@Component({
  selector: 'app-sale-seat',
  templateUrl: './sale-seat.component.html',
  styleUrls: ['./sale-seat.component.scss']
})
export class SaleSeatComponent implements OnInit {
  @Input() parentData: any;
  constructor(
    private requestService: RequestDataService,
    private utils: UtilsService,
    private dataCenterService: DataCenterService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) { }

  hotelDataList: any = [];
  salesList: any = [];
  seatList: any = [];
  payList: any = [];
  showPay:any = false;
  out_trade_no: any = null;
  description: any = null;
  total: any = 1;
  selectData: any = [];
  payData: any = [];
  payWay: any = "wx";
  modeType: any = "";
  buttonLabel: any = "开通席位缴费";

  ngOnInit() {
    let temp = _sh.filter(this.parentData.data.data,function (e){
      return e.checked == true;
    })
    this.hotelDataList = temp[0];
    this.getSaleAndSeatData()
  }

  getSaleAndSeatData(){
    const search = observableForkJoin(
      this.requestService.getDataByCondition("meeting/hotel/mt_hotel_sales_detail.json",{'HOTEL_ID':this.hotelDataList['ID']}),
      this.requestService.getDataByCondition("meeting/hotel/mt_hotel_sales_binding.json",{'HOTEL_ID':this.hotelDataList['ID']}),
      this.requestService.getDataByCondition("meeting/hotel/mt_hotel_payment.json",{'PAYMENT_TARGET_ID':this.hotelDataList['ID']})
    );
    search.subscribe(res=>{
      this.salesList = _sh.get(res,[0,'data'],[]);
      this.seatList = _sh.get(res,[1,'data'],[]);
      this.payList = _sh.get(res,[2,'data'],[]);
    })
  }

  gotoBack() {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _sh.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _sh.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doRefresh() {
    this.getSaleAndSeatData()
  }



  binding(sale){
    this.requestService.getDataByCondition("meeting/hotel/mt_hotel_sales_binding_search.json",{'HOTEL_ID':this.hotelDataList['ID']}).subscribe(res=>{
      if(!this.utils.Base_HasValue(res.data)){
        this.confirmModal.show("warning",{title:"暂无空余销售席位，请缴费开通席位。"})
      }
      else{
        if(sale['PAYMENT_MARK'] == 'YES'){
          this.confirmModal.show('warning',{title:"该销售已绑定席位，请勿重复绑定"})
        }
        else{
          this.requestService.getDataByCondition("meeting/hotel/mt_hotel_sales_user_search.json",{'USER_NAME':sale.SALES_PHONE}).subscribe(res=>{
            if(!this.utils.Base_HasValue(res.data)){
              this.confirmModal.show("warning",{title:'该销售未登陆注册app无法绑定，请通知该销售进行app的登陆注册。'})
            }
            else{
              let nzComponentParams = {
                "modalFormData": {
                  "tablename": "mt_hotel_payment_order",
                  "formname": "mt_hotel_payment_order",
                  "columns": [
                    {
                      "column": "LICENSE_CODE",
                      "name": "LICENSE_CODE_NAME",
                      "component": "poplist",
                      "lable": "席位号",
                      "comment": "席位号",
                      "class": "col-xs-6",
                      "placeholder": "请选择席位",
                      "model": null,
                      "visible": true,
                      "require": true,
                      "dictionary": "meeting/basic/dict/PAYMENT_ORDER_POPLIST.json",
                      "dictionaryPF": null,
                    }
                  ]
                }
              };
              let nzTitle = '确定绑定该销售人员吗？';
              this.modalService.create({
                nzTitle,
                nzContent: FormModalComponent,
                nzWidth: 700,
                nzComponentParams: nzComponentParams,
                nzOnOk: (res) => {
                  const data = res.modalFormData.columns;

                  // 1 表单校验
                  let requireCheck = true;
                  _sh.each(data, oneColumn => {
                    if (!this.utils.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
                      requireCheck = false;
                    }
                  })
                  if (!requireCheck) {
                    this.confirmModal.show('error', {title: '请填写必填信息。'});
                    return false
                  }
                  let query= {
                    saleId :sale['ID'],
                    orderId : data[0].model
                  }
                  this.requestService.doActionByHdbsthor("AppApi/MsHotelController","bindingSale",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
                    if(res.status == 500){
                      this.confirmModal.show('error',{title:res.message})
                    }else{
                      this.confirmModal.show('success',{title:"绑定成功"})
                      this.doRefresh();
                    }
                  })
                }
              })
            }
          })
        }
      }
    })

  }

  unbinding(sale){
    if(sale['PAYMENT_MARK'] == 'NO'){
      this.confirmModal.show('warning',{title:"该销售未绑定席位，无法解绑"})
    }
    else{
      let query= {
        saleId :sale['ID']
      }
      this.requestService.doActionByHdbsthor("AppApi/MsHotelController","unbindingSale",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
        if(res.status == 500){
          this.confirmModal.show('error',{title:res.message})
        }else{
          this.confirmModal.show('success',{title:"解绑成功"})
          this.doRefresh();
        }
      })
    }
  }

  doCancel() {
    this.showPay = false
  }

  doOk() {
    let query = {
      out_trade_no: this.out_trade_no,
      wxPf: "meetingWxPF"
    }
    this.requestService.doActionByHdbsthor("AppApi/WxNativePaymentController","queryWxPaymentOrder",query,"",null,"hdbsthor_login_center","appToken").subscribe(res=>{
      if(res.trade_state === 'SUCCESS'){
        let query = {
          out_trade_no: this.out_trade_no
        }
        this.requestService.doActionByHdbsthor("AppApi/MsHotelController","saleSeatPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
          if(res === true){
            this.showPay = false;
            this.doRefresh();
          }else {
            if(res.status === 500){
              this.confirmModal.show('error', {'title': '执行错误','content':res.message});
            }
          }
        })
      }else if(res.trade_state === 'NOTPAY'){

      }else{
        this.confirmModal.show('error', {'title': '执行错误','content':res.message});
      }
    })

  }


  openSeat() {
    observableForkJoin(
      this.requestService.getDataByCondition("meeting/hotel/mt_pay_number.json",{'PAYMENT_TYPE':'SALE','PAYMENT_TARGET_ID':this.hotelDataList['ID']}),//查询今年购买数量
      this.requestService.getDataByCondition("meeting/hotel/mt_pricing_management_list.json",{})//查询定价信息
    ).subscribe(async res=>{
      let check = true
      const account = _sh.get(res,[0,'data',0,'ACCOUNT'],0);
      const princeInfo = _sh.get(res,[1,'data',0],[]);
      if(!this.utils.Base_HasValue(princeInfo)){
        this.confirmModal.show('error',{title:"未找到定价信息"});
        check = false;
      }
      if(account>=princeInfo['SALES_SEAT_PRICING_COUNT']){
        this.confirmModal.show('error',{title:"今年开通次数已达上限"})
        check = false;
      }
      if(check){
        this.requestService.getDataByCondition("meeting/hotel/mt_pricing_management_list.json",{}).subscribe(res=>{
          let checked = true;
          this.total = _sh.get(res,['data',0,'SALES_SEAT_PRICING'],null);
          if(!this.utils.Base_HasValue(this.total)){
            this.confirmModal.show("warning",{title:"未找到定价请联系管理员"});
            checked = false;
          }
          if(checked){
            let query = {
              hotelId: this.hotelDataList['ID'],
              total: this.total,
              orderType: "saleSeat"
            }
            this.requestService.doActionByHdbsthor("AppApi/MsHotelController","createPayOrderLog",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
              this.out_trade_no = res.id
              this.description = this.hotelDataList['HOTEL_NAME'] + "--销售席位年费";
              let query = {
                wxPf: "meetingWxPF",
                out_trade_no: this.out_trade_no,
                description: this.description,
                total: this.total*100
              }
              this.requestService.doActionByHdbsthor("AppApi/WxNativePaymentController","placeNativeOrder",query,"",null,"hdbsthor_login_center","appToken").subscribe(res=>{
                if(this.utils.Base_HasValue(res)){
                  this.payData = res;
                  this.showPay = true
                }
              })
            })
          }
        })
      }
    })

  }

  changePayWay() {
  }

  checkPay(_pay) {
    if(_pay['PAYMENT_TYPE'] == 'HOTEL'){
      let query = {
        out_trade_no:_pay['ID']
      }
      this.requestService.doActionByHdbsthor("AppApi/MsHotelController","hotelPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
          if(res === true){
            this.doRefresh();
          }else {
            if(res.status === 500){
              this.confirmModal.show('error', {'title': '执行错误','content':res.message});
            }
          }
        })
    }
    if(_pay['PAYMENT_TYPE'] == 'SALE'){
      let query = {
        out_trade_no:_pay['ID']
      }
      this.requestService.doActionByHdbsthor("AppApi/MsHotelController","saleSeatPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
        if(res === true){
          this.doRefresh();
        }else {
          if(res.status === 500){
            this.confirmModal.show('error', {'title': '执行错误','content':res.message});
          }
        }
      })
    }
  }
}
