import { Component, OnInit } from '@angular/core';
import {DataCenterService} from "../../service/data-center.service";
import {RequestDataService} from "../../service/request-data.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';

@Component({
  selector: 'app-hulk-config-sd-client',
  templateUrl: './hulk-config-sd-client.component.html',
  styleUrls: ['./hulk-config-sd-client.component.scss']
})
export class HulkConfigSdClientComponent implements OnInit {
  array = [1, 2, 3, 4];
  taskList = [];
  // 总条数
  public total: any = 1;
  // 当前页数
  public pageIndex: any = 1;
  // 每页条数
  public pageSize: any = 10;
  public nzPageSizeOptions: any = [10, 20, 50, 100, 200];
  public pageMax = 0;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.getPicList();
  }

  getPicList() {
    let searchId = "simulationTrain/stableDiffusion/st_sd_result.json";
    const parallel$ = observableForkJoin(
      this.requestService.getPaginationData(searchId, {}, this.pageIndex, this.pageSize)
    );
    parallel$.subscribe(
      values => {
        this.taskList = _sh.get(values, '[0].data', []);
        this.pageMax = _sh.get(values, '[0].page.pageMax', []);

      }
    );
  }

  getPrevious() {
    if (this.pageIndex > 1) {
      this.pageIndex = this.pageIndex - 1;
      this.getPicList();
    }
  }

  getNext() {
    if (this.pageIndex < this.pageMax) {
      this.pageIndex = this.pageIndex + 1;
      this.getPicList();

    }
  }
}
