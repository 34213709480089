import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-workflow-start',
  templateUrl: './workflow-start.component.html',
  styleUrls: ['./workflow-start.component.scss']
})
export class WorkflowStartComponent implements OnInit {

  constructor() { }

  @Input() workflowData:any;

  ngOnInit() {
  }

}
