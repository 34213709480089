import {Component, Input, OnInit, Pipe,PipeTransform,ViewChild} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {UtilsService} from '../../service/utils.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  constructor(private utils: UtilsService) {
  }

  changeModel() {
    if (!this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.model = null;
    }
  }

  ngOnInit() {
    this.hulkColumn.step = this.hulkColumn.step || 1;
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    if (this.hulkColumn.min && this.hulkColumn.max) {
      this.hulkColumn.placeholder = '请输入' + this.hulkColumn.min + '~' + this.hulkColumn.max + '之间的数字';
    } else if (this.hulkColumn.min) {
      this.hulkColumn.placeholder = '请输入大于等于' + this.hulkColumn.min + '的数字';
    } else if (this.hulkColumn.max) {
      this.hulkColumn.placeholder = '请输入小于等于' + this.hulkColumn.max + '的数字';
    }
  }

}
