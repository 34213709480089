import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {JumpCenterService} from "../../../service/jump-center.service";

@Component({
  selector: 'app-descriptions-group-row',
  templateUrl: './descriptions-group-row.component.html',
  styleUrls: ['./descriptions-group-row.component.scss']
})
export class DescriptionsGroupRowComponent implements OnInit {


  @Input() oneTab: any;
  @Input() oneGroup:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() execButton: any;

  constructor(
      private jumpCenterService: JumpCenterService
  ) { }

  ngOnInit() {
  }

  doJump(pOneColumn, pData) {
    pData['checked'] = true;
    let simulationParentData = {
      data: {
        data: [pData]
      }
    }
    let readOnlyData = {
      'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
      'aggregateListData': _sh.cloneDeep(this.aggregateListData)
    }

    this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
  }

}
