import {Component, Input, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {ActivatedRoute, Data} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import * as _sh from "lodash";
import {forkJoin as observableForkJoin} from "rxjs";

@Component({
  selector: 'app-hulk-config-columns',
  templateUrl: './hulk-config-columns.component.html',
  styleUrls: ['./hulk-config-columns.component.scss']
})
export class HulkConfigColumnsComponent implements OnInit {

  columnList:any = [];
  @Input() oneColumn: any;
  @Input() attributes: any;
  @Input() showLabel = true;


  isVisible = false;
  public pTableName: String = null;

  constructor(
      private modalService: NzModalService,
      private requestDataService: RequestDataService,
      private appConfigService: AppConfigService,
      private activatedRoute: ActivatedRoute,
      private confirmModal: ConfirmModalService,
      private utils: UtilsService
  ) { }

  showData = [];
  allChecked = false;
  indeterminate = false;

// 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = 10;
  // 总条数
  public total: any = 1;
  public nzPageSizeOptions: any = [5, 10, 20, 30, 50, 100, 200];

  ngOnInit() {

  }

  searchFilter() {
    let begin = (this.pageIndex - 1) * this.pageSize;
    let end = this.pageIndex * this.pageSize;
    this.showData = _sh.slice(this.columnList, begin, end);
  }

  /**
   * 获取某一个表的所有的字段信息
   */
  getColumnList() {
    let that = this;
    const search = observableForkJoin(
        this.requestDataService.getTableColumnsThisPf(this.pTableName)
    );
    search.subscribe(
        values => {
          this.columnList = _sh.get(values,[0],[]);
        });
  }

  // 弹出框
  showModal() {
    this.isVisible = true;
    this.getColumnList();
  }


  deleteTextReSearch() {
    this.pTableName = null;
    this.getColumnList();
  }

  doSearch() {
    this.getColumnList();
  }

  // 单选框 点击行事件
  selectData(data) {
    this.oneColumn[this.attributes] = data['COLUMN_NAME'];
    this.isVisible = false;
  }

  // 清除所选内容
  removeValue(): void {
    this.oneColumn[this.attributes]= ""
  }


}
