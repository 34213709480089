import {Component, Input, OnInit} from '@angular/core';
import {JumpCenterService} from "../../../service/jump-center.service";
import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {RequestDataService} from "../../../service/request-data.service";

@Component({
  selector: 'app-descriptions-edit-row',
  templateUrl: './descriptions-edit-row.component.html',
  styleUrls: ['./descriptions-edit-row.component.scss']
})
export class DescriptionsEditRowComponent implements OnInit {


  @Input() oneTab: any;
  @Input() oneGroup:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() execButton: any;
  visible: any;

  constructor(private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private jumpCenterService: JumpCenterService,
              private requestService: RequestDataService) {
  }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.execButton)) {
      let groupEditType = _sh.get(this.execButton,['groupEditType'], null);
      if (!this.utils.Base_HasValue(groupEditType)) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "请填写 buttons 之下的 groupEditType"
        });
      }
      this.visible = _sh.includes(['editInNewRow', 'editInOldRow'], this.execButton['groupEditType']);

      _sh.each(this.execButton['columns'],oneColumn=>{
        oneColumn['component'] = _sh.get(oneColumn,['component'],'lable');
      })
    }
  }

  doJump(pOneColumn, pData) {
    pData['checked'] = true;
    let simulationParentData = {
      data: {
        data: [pData]
      }
    }
    let readOnlyData = {
      'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
      'aggregateListData': _sh.cloneDeep(this.aggregateListData)
    }

    this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
  }

}
