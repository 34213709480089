import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from './../data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';

import * as _ from 'underscore';

@Component({
  selector: 'app-library-info',
  templateUrl: './library-info.component.html',
  styleUrls: ['./library-info.component.css']
})
export class LibraryInfoComponent implements OnInit {

  public libId: any = '';
  public modeType: any = 'add';
  public formData: any = {
    'title': '',
    'type': 'normal',
    'columns': [
      {
        'column': 'TOTAL_AREA',
        'name': 'TOTAL_AREA_NAME',
        'component': 'input',
        'lable': '馆舍总面积(m³)',
        'comment': '馆舍总面积(m³)',
        'placeholder': '请输入馆舍总面积(m³)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'BOOKS',
        'name': 'BOOKS_NAME',
        'component': 'input',
        'lable': '图书(册)',
        'comment': '图书(册)',
        'placeholder': '请输入图书(册)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'E_BOOKDS',
        'name': 'E_BOOKDS_NAME',
        'component': 'input',
        'lable': '电子图书(册)',
        'comment': '电子图书(册)',
        'placeholder': '请输入电子图书(册)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'CHINESE_DATABASE',
        'name': 'CHINESE_DATABASE_NAME',
        'component': 'input',
        'lable': '中文数据库(个)',
        'comment': '中文数据库(个)',
        'placeholder': '请输入中文数据库(个)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'ENGLISH_DATABASE',
        'name': 'ENGLISH_DATABASE_NAME',
        'component': 'input',
        'lable': '外文数据库(个)',
        'comment': '外文数据库(个)',
        'placeholder': '请输入外文数据库(个)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'FEATURED_DATABASE',
        'name': 'FEATURED_DATABASE_NAME',
        'component': 'input',
        'lable': '特色数据库(个)',
        'comment': '特色数据库(个)',
        'placeholder': '请输入特色数据库(个)',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'ANCIENT_BOODS',
        'name': 'ANCIENT_BOODS_NAME',
        'component': 'input',
        'lable': '古籍图书(册)',
        'comment': '古籍图书(册)',
        'placeholder': '请输入古籍图书(册)',
        'model': null,
        'visible': true,
        'require': false
      }
    ]
  };

  @ViewChild('childForm', {static: false}) childForm;

  constructor(private utils: UtilsService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService,
              private requestData: RequestDataService) {
  }

  // 保存
  saveOrModify() {
    if (this.childForm._validateForm.status === 'PENDING') {
      return;
    }
    if (this.childForm._validateForm.invalid) {
      for (const key in this.childForm._validateForm.controls) {
        this.childForm._validateForm.controls[key].markAsDirty();
        this.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (this.childForm._validateForm.valid) {
      const model = this.childForm._validateForm['columns'];
      let data = {};
      _.each(model, column => {
        data[column['column']] = column['model'];
      });
      if (this.modeType === 'add') {
        this.libId = this.utils.Base_getUuid();
      }
      this.dataService.saveLibInfo(this.libId, this.modeType, data).subscribe(result => {
        if (result['state'] === 'success') {
          this.modeType = 'modify';
          this.confirmModal.show('success', {title: '保存成功！'});
          return false;
        } else {
          this.confirmModal.show('error', {title: '保存失败，请刷新重试！'});
          return false;
        }
      });
    }
  }

  ngOnInit() {
    this.requestData.getDataByCondition('headLine/ds/ds_libinfo_detail.json').subscribe(result => {
      const data = result['data'][0] || {};
      // 如果存在数据则回显
      if (this.utils.Base_HasValue(data['ID'])) {
        this.libId = data['ID'];
        this.modeType = 'modify';
        _.each(this.formData.columns, column => {
          column['model'] = data[column['column']];
        });
      }
    });
  }

}
