import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import {UtilsService} from '../../../service/utils.service';
import {DataService} from '../data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataCenterService} from '../../../service/data-center.service';

@Component({
  selector: 'app-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.css']
})
export class PictureUploadComponent implements OnInit {

  @Input() parentData: any;

  labelsList = [];
  data: any = {
    resourcesType: 'PICTURE',
    siteId: '',
    channelId: '',
    labels: null,
    fileList: []
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;

  showModelImg = false;
  showModelImgUrl = '';

  constructor(
    private requestData: RequestDataService,
    private dataService: DataService,
    private dataCenterService: DataCenterService,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService) {
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME_SHOW.json', {SITE_ID: pSiteId,CHANNEL_STATE:'ENABLE'}).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (let oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  gotoBack() {
    this.transformData('customForm', this.parentData);
  }

  previewImg(pImgUrl) {
    this.showModelImg = true;
    this.showModelImgUrl = pImgUrl;
  }

  deleteImg(pOneData) {
    this.data.fileList = _.without(this.data.fileList, pOneData);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-picture-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doSave() {
    this.required = true;
    if (!this.utils.Base_HasValue(this.data.siteId)
      || !this.utils.Base_HasValue(this.data.channelId)
    ) {
      return;
    }
    if (!this.utils.Base_HasValue(this.data.fileList)) {
      this.confirmModal.show('warning', {title: '请上传图片'});
      return false;
    }
    this.dataService.pictureSave(this.data).subscribe((rep) => {
      if (rep.state === 'success') {
        this.confirmModal.show('success', {title: '保存成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('warning', {title: '保存失败'});
      }
    });
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json')
    );
    search.subscribe(
      values => {
        this.siteList = values[0]['data'] || [];
      });
  }

}
