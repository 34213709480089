import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {RequestDataService} from '../../../service/request-data.service';
import {DataService} from '../data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {GroupingModelComponent} from '../grouping-model/grouping-model.component';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-group-multiple',
  templateUrl: './group-multiple.component.html',
  styleUrls: ['./group-multiple.component.css']
})
export class GroupMultipleComponent implements OnInit {
  @Input() group;
  @Input() modelType;
  visible: boolean;
  isVisibleDetail: boolean;
  isVisible: boolean;
  tempArr = [];
  editData = {};
  _validateForm: FormGroup;

  constructor(private requestData: RequestDataService,
              private dataService: DataService,
              private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService,
              private modalService: NzModalService) {
  }


  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    const validatorList = [];
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    // if (this.utils.Base_HasValue(oneColumn.maxLength)) {
    //   validatorList.push(Validators.maxLength(oneColumn.maxLength));
    // }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    if (oneColumn.column === 'Title') {
      return new FormControl({value: '', disabled: true}, validatorList);
    }
    return new FormControl('', validatorList);
  }


  private createItem(group): FormGroup {
    const arrayGroup = {};
    _.each(group['columns'], column => {
      if (column['canEdit'] === 1) {
        arrayGroup[column['column']] = this.addOneColumn(column);
      }
    });
    return this.formBuilder.group(arrayGroup);
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    const group = {};
    const array = [];
    // 如果是文件上传需要将column添加到form里
    // 其他类型则按照data添加
    array.push(this.createItem(this.group));
    group[this.group['groupId']] = this.formBuilder.array(array);
    return this.formBuilder.group(group);
  }

  showModal(pOneData): void {
    if (this.modelType === 'detail') {
      this.isVisibleDetail = true;
    } else {
      this.isVisible = true;
    }
    this.editData = pOneData;
    for (const oneColumn of this.group['columns']) {
      if (oneColumn['canEdit'] === 1) {
        oneColumn['model'] = this.editData[oneColumn['column']];
      }
    }
  }

  handleOk(): void {
    if (this._validateForm && this._validateForm.invalid) {
      _.each(this._validateForm.controls, (controls, groupKey) => {
        _.each(controls['controls'], groupCon => {
          for (const key in groupCon['controls']) {
            groupCon['controls'][key].markAsDirty();
            groupCon['controls'][key].updateValueAndValidity();
          }
        });
      });
    }
    if (this._validateForm && this._validateForm.valid) {
      this.isVisible = false;
      this.isVisibleDetail = false;
      for (const oneColumn of this.group['columns']) {
        if (oneColumn['canEdit'] === 1) {
          this.editData[oneColumn['column']] = oneColumn['model'];
          this.editData['tips'] = false;
          oneColumn['model'] = null;
        }
      }
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleDetail = false;
  }

  deleteResource(pOneData) {
    for (const oneArr  of this.group.data) {
      if (oneArr['resourceId'] === pOneData['resourceId']) {
        this.group.data = _.without(this.group.data, oneArr);
      }
    }
    this.manageTempArr();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      for (let i = 0; i < this.tempArr.length; i++) {
        // 小于6就从下个数组拿一个对象来
        if (this.tempArr[i]['key'].length < 6 && i !== this.tempArr.length - 1 && this.tempArr[i + 1]['key'].length > 1) {
          const nextObj = this.tempArr[i + 1]['key'].shift();
          this.tempArr[i]['key'].push(nextObj);
        } else if (this.tempArr[i]['key'].length > 6 && i !== this.tempArr.length - 1) {
          // 大于6就拿一个对象去下一个数组
          const nowObj = this.tempArr[i]['key'].pop();
          this.tempArr[i + 1]['key'].unshift(nowObj);
        }
      }
    }
    const tempData = [];
    let i = 0;
    for (const oneData of this.tempArr) {
      for (const one of oneData['key']) {
        one['order'] = i;
        i++;
        tempData.push(one);
      }
    }
    this.group.data = tempData;
  }

  addResources() {
    const data: any = {
      fileList: this.group.data,
      type: 'multi'
    };
    this.modalService.create({
      nzTitle: '资源选择',
      nzContent: GroupingModelComponent,
      nzComponentParams: data,
      nzWidth: 1200,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: (response) => {
        const selectList = _.filter(response.listData, oneData => {
          return oneData['checked'];
        });
        if (!this.utils.Base_HasValue(selectList)) {
          this.confirmModal.show('warning', {
            title: '请选择一个资源'
          });
          return false;
        }
        if (this.utils.Base_HasValue(selectList)) {
          for (const oneData of selectList) {
            const obj = {};
            for (const oneColumn of this.group['columns']) {
              if (oneColumn['column'] === 'resourceId') {
                obj['resourceId'] = oneData['resourceId'];
              } else if (oneColumn['column'] === 'resourceUrl') {
                obj['resourceUrl'] = oneData['resourcesPicUrl'];
              } else if (oneColumn['column'] === 'resourceName') {
                obj['resourceName'] = oneData['resourcesName'];
              } else {
                obj[oneColumn['column']] = null;
              }
              if (oneColumn['canEdit'] === 1) {
                obj['canEdit'] = 1;
                if (oneColumn['require']) {
                  obj['tips'] = true;
                }
              }
            }
            if (!this.utils.Base_HasValue(this.group.data)) {
              this.group.data = [];
            }
            this.group.data.push(obj);
          }
        }
        this.group.data = _.uniqWith(this.group.data, (arrVal, otherVal) =>
          arrVal['resourceId'] === otherVal['resourceId']
        );
        let i = 0;
        for (const oneData of this.group.data) {
          oneData['order'] = i;
          i++;
        }
        this.manageTempArr();
      }
    });
  }

  manageTempArr() {
    if (this.utils.Base_HasValue(this.group.data)) {
      const lengthArr = this.group.data.length;
      const sizeNum = Math.ceil(lengthArr / 6);
      let start = 0;
      let end = 6;
      this.tempArr = [];
      for (let i = 0; i < sizeNum; i++) {
        const obj = {};
        obj['key'] = this.group.data.slice(start, end);
        this.tempArr.push(obj);
        start += 6;
        end += 6;
      }
    } else {
      this.tempArr = [];
    }
  }

  ngOnInit() {
    this.manageTempArr();
    this._validateForm = this.addOneForm();
  }

}
