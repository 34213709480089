import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import {DataCenterService} from '../../../service/data-center.service';
import {ResumeService} from '../resume.service';
import {DataService} from '../data.service';
import {RequestDataService} from '../../../service/request-data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UserInfoService} from '../../../service/user-info.service';
import {AppConfigService} from '../../../service/app-config.service';
import {StorageService} from '../../../service/storage.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  @Input() parentData: any;
  data: any = {};
  isAuthorize = false;
  jobCode = '';
  authorizedPerson: any = {};
  authorizedList = []; // 授权列表
  supplementaryList = []; // 被授权列表
  userData: any;
  showAuthor = false; // 是否显示授权信息

  enResumePublish = false;
  chResumePublish = false;
  chResumeLastTime = '';
  enResumeLastTime = '';
  chOnlineNotNew = false;
  enOnlineNotNew = false;
  openTip = false;
  openType = '';
  releaseTip = false;
  releaseType = '';

  constructor(private dataCenterService: DataCenterService,
              private resumeService: ResumeService,
              private dataService: DataService,
              private userInfo: UserInfoService,
              private config: AppConfigService,
              private requestService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private storage: StorageService) {
    this.userData = this.userInfo.getUserInfo();
  }

  // 选择模版
  selectModel(pModel, pType) {
    if (pType === 'ch') {
      this.data['CH_TEMPLATE_NO'] = pModel;
    } else {
      this.data['EN_TEMPLATE_NO'] = pModel;
    }
    this.dataService.updateModel(this.data).subscribe(response => {
    });
  }

  // 编缉中文简历
  compilationCnResume() {
    this.parentData.data = this.data;
    this.resumeService.transformData('customForm', this.parentData, null, 'cnResume', 'app-cn-resume');
  }

  // 编缉中文简历
  compilationEnResume() {
    this.parentData.data = this.data;
    this.resumeService.transformData('customForm', this.parentData, null, 'enResume', 'app-en-resume');
  }

  previewCh() {
    const url = this.config.config.resumeWebCh + this.data['JOB_CODE'] + '/temp';
    let save_link = document.createElement('a');
    save_link.href = url;
    save_link.target = '_blank';
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
  }

  previewEn() {
    const url = this.config.config.resumeWebEn + this.data['JOB_CODE'] + '/temp';
    let save_link = document.createElement('a');
    save_link.href = url;
    save_link.target = '_blank';
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
  }

  // 授权
  authorize() {
    this.isAuthorize = true;
  }

  handleOk(): void {
    let duplicate = _.filter(this.authorizedList, item => {
      return item.GET_EMPOWER_USER_CODE === this.authorizedPerson.code;
    });
    if (!this.util.Base_HasValue(this.authorizedPerson)) {
      return this.confirmModal.show('warning', {title: '您还没有选择授权人，请点击查询。'});
    } else if (this.authorizedPerson.code === this.userData.USER_NAME) {
      return this.confirmModal.show('warning', {title: '您不能把简历授权给您自己。'});
    } else if (this.util.Base_HasValue(duplicate)) {
      return this.confirmModal.show('warning', {title: '您已经授权给' + this.authorizedPerson.name + '了。'});
    } else {
      this.confirmModal.show('confirm', {
        title: '您确定将您的简历授权给' + this.authorizedPerson.name + '吗？',
        suc: () => {
          let queryData = {
            'userName': this.authorizedPerson.code
          };
          const bodyData = {
            'ids': [
              {
                'id': 'de2b1f16eb134903b7e67dea24e968e3'
              },
              {
                'id': '5a41e34a0cab4954b23956206df88d0d'
              }
            ]
          };
          this.requestService.doActionByHdbsthor('hdbsthor-right/rightController', 'giveRole', queryData, bodyData, true, 'hdbsthor', 'accessToken').subscribe(response => {
            if (response.toString() === 'true') {
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });

          this.dataService.insertReEmpower(this.authorizedPerson).subscribe(result => {
            if (result['state'] === 'success') {
              let userCondition = {
                USER_CODE: this.userData['USER_NAME']
              };
              this.requestService.getDataByCondition('resume/re/empower_user_list.json', userCondition).subscribe(response => {
                if (this.util.Base_HasValue(response.data)) {
                  this.authorizedList = response.data;
                }
              });
              this.isAuthorize = false;
              this.confirmModal.show('success', {title: '授权成功。'});
              this.authorizedPerson = {};
              this.jobCode = '';
            }
          });
        }
      });
    }
  }

  // 取消授权
  cancelAuthorize(pModel) {
    this.confirmModal.show('confirm', {
      title: '您确定取消' + pModel.GET_EMPOWER_USER_NAME + '对您简历的操作权限吗？',
      suc: () => {
        this.dataService.updateReEmpower(pModel).subscribe(result => {
          if (result['state'] === 'success') {
            let userCondition = {
              USER_CODE: this.userData['USER_NAME']
            };
            this.requestService.getDataByCondition('resume/re/empower_user_list.json', userCondition).subscribe(response => {
              this.authorizedList = response.data;
            });
            this.isAuthorize = false;
            this.confirmModal.show('success', {title: '操作成功。'});
          }
        });
      }
    });
  }

  handleCancel(): void {
    this.isAuthorize = false;
    this.authorizedPerson = {};
    this.jobCode = '';
  }

  searchName() {
    if (this.util.Base_HasValue(this.jobCode)) {
      let condition = {
        JOB_CODE: this.jobCode
      };
      const parallel$ = observableForkJoin(
        this.requestService.getDataByCondition('resume/basic/basic_student_detail.json', condition),
        this.requestService.getDataByCondition('resume/basic/basic_teacher_detail.json', condition)
      );
      parallel$.subscribe(response => {
        if (this.util.Base_HasValue(response[0]['data'])) {
          let student = response[0]['data'][0];
          student['code'] = student['STUDENT_CODE'];
          student['name'] = student['STUDENT_NAME'];
          student['type'] = 'STUDENT';
          this.authorizedPerson = student;
        } else if (this.util.Base_HasValue(response[1]['data'])) {
          let teacher = response[1]['data'][0];
          teacher['code'] = teacher['JOB_CODE'];
          teacher['name'] = teacher['TEACHER_NAME'];
          teacher['type'] = 'TEACHER';
          this.authorizedPerson = teacher;
        } else {
          this.confirmModal.show('warning', {title: '该工号/学号不存在，请重新输入。'});
        }
      });
    }
  }

  releaseOk() {
    const data = {
      'teacherId': this.data['ID'],
      'jobCode': this.data['JOB_CODE'],
      'teacherName': this.data['TEACHER_NAME'],
      'resumeType': this.releaseType
    };
    this.dataService.releaseResume(data).subscribe(rep => {
      if (rep.state === 'success') {
        this.releaseTip = false;
        this.releaseType = '';
        this.confirmModal.show('success', {title: '发布成功'});
        this.requestService.getDataByCondition('resume/re/re_online_new.json', {JOB_CODE: this.data['JOB_CODE']}).subscribe(item => {
          if (item['data'].length > 0) {
            _.each(item['data'], oneData => {
              if (oneData['RESUME_TYPE'] === 'CH') {
                if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                  this.chOnlineNotNew = true;
                } else {
                  this.chOnlineNotNew = false;
                }
                const resumeData = JSON.parse(oneData['RESUME_RESULT']);
                _.each(resumeData['resumeData'], resume => {
                  if (resume['id'] === 'information') {
                    _.each(resume['columns'], column => {
                      if (column['id'] === 'homePageUrl') {
                        data['urlAlias'] = column['value'];
                      }
                    });
                  }
                });
                this.dataService.updateTeacherUrlAlias(data).subscribe(resp => {
                });
              }
              if (oneData['RESUME_TYPE'] === 'EN') {
                if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                  this.enOnlineNotNew = true;
                } else {
                  this.enOnlineNotNew = false;
                }
              }
            });
          }
          let condition = {};
          if (this.releaseType === 'CH') {
            condition = {
              'logTable': 'basic_teacher',
              'logType': 'update',
              'logPk': null,
              'logContent': '发布了' + this.data['TEACHER_NAME'] + '的中文简历',
            };
          } else if (this.releaseType === 'EN') {
            condition = {
              'logTable': 'basic_teacher',
              'logType': 'update',
              'logPk': null,
              'logContent': '发布了' + this.data['TEACHER_NAME'] + '的英文简历',
            };
          }
          this.dataService.updateDataLog(condition).subscribe(response => {
          });
        });
      }
    });
  }

  // 发布最新编辑信息
  releaseResume(pType) {
    this.releaseTip = true;
    this.releaseType = pType;
  }

  releaseCancel() {
    this.releaseTip = false;
    this.releaseType = '';
  }

  openCancel() {
    this.openTip = false;
  }

  openOk() {
    let publishType = 'OPEN';
    if (this.openType === 'CH') {
      publishType = !this.chResumePublish ? 'OPEN' : 'CLOSE';
    } else {
      publishType = !this.enResumePublish ? 'OPEN' : 'CLOSE';
    }
    const data = {
      'teacherId': this.data['ID'],
      'teacherName': this.data['JOB_CODE'],
      'resumeType': this.openType,
      'publishType': publishType
    };
    this.dataService.publishResume(data).subscribe(rep => {
      if (rep.state === 'success') {
        let condition = {};
        const resumeType = publishType === 'OPEN' ? '开通了' : '下架了';
        if (this.openType === 'CH') {
          this.chResumePublish = !this.chResumePublish;
          condition = {
            'logTable': 'basic_teacher',
            'logType': 'update',
            'logPk': null,
            'logContent': resumeType + this.data['TEACHER_NAME'] + '的中文简历',
          };
        } else {
          this.enResumePublish = !this.enResumePublish;
          condition = {
            'logTable': 'basic_teacher',
            'logType': 'update',
            'logPk': null,
            'logContent': resumeType + this.data['TEACHER_NAME'] + '的英文简历',
          };
        }
        this.dataService.updateDataLog(condition).subscribe(response => {
          this.confirmModal.show('success', {title: '您的主页已开通'});
          this.openTip = false;
        });
      } else {
        this.confirmModal.show('warning', {title: rep.text});
        this.openTip = false;
      }
    });
  }

  /*
  * 开通主页，如果是开通给弹框提示。关闭主页不给弹框提示*/
  publishResume(pType) {
    this.openType = pType;
    let publishType = 'OPEN';
    if (this.openType === 'CH') {
      publishType = !this.chResumePublish ? 'OPEN' : 'CLOSE';
    } else {
      publishType = !this.enResumePublish ? 'OPEN' : 'CLOSE';
    }
    if (publishType === 'OPEN') {
      this.openTip = true;
    } else {
      const data = {
        'teacherId': this.data['ID'],
        'teacherName': this.data['JOB_CODE'],
        'resumeType': this.openType,
        'publishType': publishType
      };
      this.dataService.publishResume(data).subscribe(rep => {
        if (rep.state === 'success') {
          let condition = {};
          const resumeType = publishType === 'OPEN' ? '开通了' : '下架了';
          if (this.openType === 'CH') {
            this.chResumePublish = !this.chResumePublish;
            condition = {
              'logTable': 'basic_teacher',
              'logType': 'update',
              'logPk': null,
              'logContent': resumeType + this.data['TEACHER_NAME'] + '的中文简历',
            };
          } else {
            this.enResumePublish = !this.enResumePublish;
            condition = {
              'logTable': 'basic_teacher',
              'logType': 'update',
              'logPk': null,
              'logContent': resumeType + this.data['TEACHER_NAME'] + '的英文简历',
            };
          }
          this.dataService.updateDataLog(condition).subscribe(response => {
            this.confirmModal.show('success', {title: '您的主页已关闭'});
            this.openTip = false;
          });
        } else {
          this.confirmModal.show('warning', {title: rep.text});
          this.openTip = false;
        }
      });
    }
  }

  // 切换简历
  checkResume(pData?) {
    let condition: any = {};
    if (this.util.Base_HasValue(pData)) {
      condition = {
        JOB_CODE: pData.EMPOWER_USER_CODE,
      };
    } else {
      condition = {
        JOB_CODE: this.userData.USER_NAME,
        RESUME_TYPE: 'CH'
      };
    }
    const parallel$ = observableForkJoin(
      this.requestService.getDataByCondition('resume/basic/basic_teacher.json', condition),
      this.requestService.getDataByCondition('resume/re/re_resume_publish_detail.json', condition)
    );
    parallel$.subscribe(result => {
      this.data = result[0]['data'][0] || {};
      this.userData['JOB_CODE'] = this.data['JOB_CODE'];
      this.storage.setData('userInfo', this.userData);
      this.data['researchDirection'] = [];
      if (this.util.Base_HasValue(this.data['RESEARCH_DIRECTION'])) {
        this.data['researchDirection'] = this.data['RESEARCH_DIRECTION'].split('、');
      }
      this.enResumePublish = this.data['PUBLISH_EN_RESUME'] === 1;
      this.chResumePublish = this.data['PUBLISH_CH_RESUME'] === 1;
      // 如果是自己就显示授权信息
      if (this.userData['USER_NAME'] === this.userData['JOB_CODE']) {
        this.showAuthor = true;
      } else {
        this.showAuthor = false;
      }
      if (result[1]['data'].length > 0) {
        const resumeDetail = result[1]['data'][0]['RESUME_RESULT'];
        this.transformData(JSON.parse(resumeDetail));
      }
    });
  }

  // 数据处理，将基本信息替换成从简历取
  transformData(pData) {
    for (let oneData of pData.resumeData) {
      if (oneData['id'] === 'information') {
        _.each(oneData.columns, item => {
          switch (item['id']) {
            case 'jobTitle':
              this.data['JOB_TITLE_DICT'] = item['value'];
              break;
            case 'headPortraitUrl':
              this.data['TEACHER_PICTURE'] = item['value'];
              break;
            case 'boMasterGuide':
              this.data['IS_MASTER_GUIDE'] = 0;
              this.data['IS_BO_GUIDE'] = 0;
              _.each(item['group'], group => {
                if (group['id'] === 'masterGuide' && group['value']) {
                  this.data['IS_MASTER_GUIDE'] = 1;
                }
                if (group['id'] === 'boGuide' && group['value']) {
                  this.data['IS_BO_GUIDE'] = 1;
                }
              });
              break;
            case 'college':
              this.data['ORG_NAME'] = item['value'];
          }
        });
      }
      if (oneData['id'] === 'researchDirection') {
        _.each(oneData.columns, result => {
          if (result['id'] === 'researchDirection' && this.util.Base_HasValue(result['value'])) {
            this.data['researchDirection'] = result['value'].split('、');
          } else {
            this.data['researchDirection'] = [];
          }
        });
      }
    }
  }

  ngOnInit() {
    // JOB_CODE只是做为全局变量方便判断，并不一定是当前用户的工号／学号
    let userCondition = {
      JOB_CODE: this.userData['USER_NAME']
    };
    if (this.userData['JOB_CODE']) {
      userCondition.JOB_CODE = this.userData['JOB_CODE'];
    }
    let conditions = {
      USER_CODE: this.userData['USER_NAME']
    };
    const parallel$ = observableForkJoin(
      this.requestService.getDataByCondition('resume/basic/basic_teacher.json', userCondition),
      this.requestService.getDataByCondition('resume/re/empower_user_list.json', conditions),
      this.requestService.getDataByCondition('resume/re/get_empower_user_list.json', conditions),
      this.requestService.getDataByCondition('resume/re/re_resume_publish_detail.json', userCondition), // 如果有简历基本信息就从简历取
      this.requestService.getDataByCondition('resume/re/re_online_new.json', userCondition)
    );
    parallel$.subscribe(response => {
      // 如果当前用户是老师
      if (response[0]['data'].length > 0) {
        this.data = response[0]['data'][0];
        this.userData['JOB_CODE'] = this.data['JOB_CODE'];
        this.storage.setData('userInfo', this.userData);
        this.data['researchDirection'] = [];
        if (this.util.Base_HasValue(this.data['RESEARCH_DIRECTION'])) {
          this.data['researchDirection'] = this.data['RESEARCH_DIRECTION'].split('、');
        }
        this.enResumePublish = this.data['PUBLISH_EN_RESUME'] === 1;
        this.chResumePublish = this.data['PUBLISH_CH_RESUME'] === 1;
        // 如果是自己就显示授权信息
        if (this.userData['USER_NAME'] === this.userData['JOB_CODE']) {
          this.showAuthor = true;
        } else {
          this.showAuthor = false;
        }
        if (response[3]['data'].length > 0) {
          let resumeDetail: any = {};
          _.each(response[3]['data'], oneData => {
            if (oneData['RESUME_TYPE'] === 'CH') {
              resumeDetail = oneData['RESUME_RESULT'];
              this.chResumeLastTime = oneData['U_TIME'];
            } else if (oneData['RESUME_TYPE'] === 'EN') {
              this.enResumeLastTime = oneData['U_TIME'];
            }
          });
          this.transformData(JSON.parse(resumeDetail));
        }
        if (response[4]['data'].length > 0) {
          _.each(response[4]['data'], oneData => {
            if (oneData['RESUME_TYPE'] === 'CH') {
              if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                this.chOnlineNotNew = true;
              } else {
                this.chOnlineNotNew = false;
              }
            }
            if (oneData['RESUME_TYPE'] === 'EN') {
              if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                this.enOnlineNotNew = true;
              } else {
                this.enOnlineNotNew = false;
              }
            }
          });
        }
      } else if (response[2]['data'].length > 0) { // 如果当前用户不是老师就去查询授权给当前用户的
        let condition = {
          JOB_CODE: response[2]['data'][0].EMPOWER_USER_CODE,
        };
        this.requestService.getDataByCondition('resume/basic/basic_teacher.json', condition).subscribe(result => {
          this.data = result.data[0];
          this.userData['JOB_CODE'] = this.data['JOB_CODE'];
          this.storage.setData('userInfo', this.userData);
          this.data['researchDirection'] = [];
          if (this.util.Base_HasValue(this.data['RESEARCH_DIRECTION'])) {
            this.data['researchDirection'] = this.data['RESEARCH_DIRECTION'].split('、');
          }
          this.enResumePublish = this.data['PUBLISH_EN_RESUME'] === 1;
          this.chResumePublish = this.data['PUBLISH_CH_RESUME'] === 1;
          this.requestService.getDataByCondition('resume/re/re_resume_publish_detail.json', condition).subscribe(response => {
            if (response.data.length > 0) {
              let resumeDetail: any = {};
              _.each(response.data, oneData => {
                if (oneData['RESUME_TYPE'] === 'CH') {
                  resumeDetail = oneData['RESUME_RESULT'];
                  this.chResumeLastTime = oneData['U_TIME'];
                } else if (oneData['RESUME_TYPE'] === 'EN') {
                  this.enResumeLastTime = oneData['U_TIME'];
                }
              });
              this.transformData(JSON.parse(resumeDetail));
            }
            this.requestService.getDataByCondition('resume/re/re_online_new.json', condition).subscribe(item => {
              if (item['data'].length > 0) {
                _.each(item['data'], oneData => {
                  if (oneData['RESUME_TYPE'] === 'CH') {
                    if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                      this.chOnlineNotNew = true;
                    } else {
                      this.chOnlineNotNew = false;
                    }
                  }
                  if (oneData['RESUME_TYPE'] === 'EN') {
                    if (oneData['RESUME_RESULT'] !== oneData['RESUME_RESULT1']) {
                      this.enOnlineNotNew = true;
                    } else {
                      this.enOnlineNotNew = false;
                    }
                  }
                });
              }
            });
          });
        });
      }
      this.authorizedList = response[1]['data']; // 我授权的
      this.supplementaryList = response[2]['data']; // 授权给我的
    });
  }

}
