import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import { UtilsService } from './../../service/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-months',
  templateUrl: './date-months.component.html',
  styleUrls: ['./date-months.component.css']
})
export class DateMonthsComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  private dateFormat = 'YYYY-MM';

  constructor(private utils: UtilsService) {
  }

  changeModel () {
    const date = this.hulkColumn.modelDate;
    if (date) {
      this.hulkColumn.model = moment(date).format(this.dateFormat);
    } else {
      this.hulkColumn.model = null;
    }
  }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.modelDate = moment(this.hulkColumn.model)['_d'];
    }
  }

  ngDoCheck(): void {
    if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        this.hulkColumn.modelDate = moment(this.hulkColumn.model)['_d'];
      } else {
        this.hulkColumn.modelDate = null;
      }
      this.hulkColumn.oldModel = this.hulkColumn.model;
    }
  }

}
