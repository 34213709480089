import {Component, Input, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {UtilsService} from "../../service/utils.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import {NzFormatEmitEvent} from "ng-zorro-antd";

@Component({
  selector: 'app-hulk-config-design-readonly-fromvalue',
  templateUrl: './hulk-config-design-readonly-fromvalue.component.html',
  styleUrls: ['./hulk-config-design-readonly-fromvalue.component.scss']
})
export class HulkConfigDesignReadonlyFromvalueComponent implements OnInit {

  @Input() label = '';
  @Input() oneColumn: any;
  @Input() attributes: any;
  @Input() sqlFileList: any;

  public isVisible = false;
  public tablelist: any = [];
  treeData = [];
  searchId = null;
  taSelectedIndex = 0;
  columnsData = [];

  constructor(
      private modalService: NzModalService,
      private requestDataService: RequestDataService,
      private appConfigService: AppConfigService,
      private activatedRoute: ActivatedRoute,
      private confirmModal: ConfirmModalService,
      private utils: UtilsService
  ) {
  }

  ngOnInit() {
  }

  removeValue(): void {
    this.oneColumn[this.attributes] = null;
  }

  // 弹出框
  showModal() {
    this.isVisible = true;
  }

  // 弹出框
  closeModal() {
    this.isVisible = false;
  }


  // 单选框 点击行事件
  selectData(data) {
    let that = this;
    let searchId = _sh.get(data,['sqlFile'])
    this.searchId = searchId;
    const search = observableForkJoin(
        this.requestDataService.getPaginationData(searchId, {}, null, 1, null
        ),
        this.requestDataService.getDataByJson(searchId)
    );
    search.subscribe(
        values => {
          this.treeData = this.utils.toTree(_sh.get(values, ['0', 'data', '0']));
          this.taSelectedIndex = 1;

          let tables = _sh.get(values, [1,'formText']);
          tables = _sh.chain(tables)
              .trim()
              .trimStart('from')
              .split(",")
              .map(_sh.trim)
              .split(" ")
              .split(",")
              .remove(function (o) {
                return o.length > 1
              })
              .value();
          let ajaxList = [];
          that.columnsData = [];
          if (that.utils.Base_HasValue(tables)) {
            _sh.each(tables,oneTable=>{
              ajaxList.push(this.requestDataService.getTableColumnsThisPf(oneTable))
            })
            const searchTable = observableForkJoin(
                ajaxList
            );
            searchTable.subscribe(
                searchTableValues => {
                  _sh.each(searchTableValues,oneTableValues=>{
                    that.columnsData = _sh.concat(that.columnsData, oneTableValues);
                  });
                }
            );
          }
        }
    );
    // this.isVisible = false;
  }

  nzClick(event: NzFormatEmitEvent): void {
    let that = this;

    // function execCallBack() {
    //   let newName = _sh.get(event, ['node', 'key']);
    //   that.confirmModal.show('confirm', {
    //     title: '温馨提示',
    //     content: '选择：' + newName,
    //     suc: () => {
    //       that.callBack(event, that.oneColumn, newName);
    //       that.isVisible = false;
    //     }
    //   });
    // }
    //
    // let link = _sh.reverse(that.utils.getLinks(_sh.get(event, ['node'])));
    // if (that.getLvOne) {
    //   if (link.length == 1) {
    //     execCallBack();
    //   } else if (link.length > 1) {
    //     that.confirmModal.show('confirm', {
    //       title: '温馨提示',
    //       content: '不能选择二级数据'
    //     });
    //   }
    // } else {
    //   execCallBack();
    // }
  }


  changeTab(tab: any) {
    this.taSelectedIndex = tab;
  }
}
