import { Component, OnInit } from '@angular/core';
import { UtilsService } from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import { DataCenterService } from './../../service/data-center.service';
import {AppConfigService} from './../../service/app-config.service';
import {AjaxService} from './../../service/ajax.service';
import {RequestDataService} from './../../service/request-data.service';
import * as _ from 'underscore';

@Component({
    selector: 'app-role-cust',
    templateUrl: './role-cust.component.html',
    styleUrls: ['./role-cust.component.css']
})
export class RoleCustComponent implements OnInit {
	public listRole:any;
	public listCom:any;
	public roleId:string = "";
	public helpUrl:string;
	public uniqIndex:any;
	public doCheck:any;
    public searchRole:any;

    constructor(private utils: UtilsService,
        private confirmModal: ConfirmModalService,
        private dataCenter: DataCenterService,
        private appConfigService: AppConfigService,
        private ajaxService: AjaxService,
        private requestData: RequestDataService) { }

    doCheckRole (pOneRole) {
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path+'/system/system_common_role_list.json',{"ROLE_ID":pOneRole.ROLE_ID,"ROLE_TYPE":"basic_cust"}).subscribe((response) => {
            //点击之后将所有选中的菜单项目晴空
            _.each(this.listCom,(OneCom) => {
                OneCom["selected"] = false;
            });
            _.each(this.listCom,OneCom => {
            	//[ 循环用户有的权限列表 ]
            	_.each(response["data"],oneData => {
                    // 如果发现用户有这个权限，那么打上对勾，退出 [ 循环用户有的权限列表 ] 循环体。
                    if(this.utils.Base_compare(oneData["FK_ID"],OneCom["CUST_ID"])){
                        OneCom["selected"] = true;
                        if(this.utils.Base_HasValue(oneData["UNIQ_INDEX"])){
                            this.uniqIndex = oneData["UNIQ_INDEX"];
                        }
                    }
                })
            })

        }, (error) => {
            this.utils.Base_Errored(error);
        });

    }

    cancelCheck(){
        this.roleId = "";
        _.each( this["listDept"],(OneDept) => {
            OneDept["selected"] = false;
        });
        _.each( this["listCom"],(OneCom) => {
            OneCom["selected"] = false;
        });
    }

    save () {
        let custId = "";
        if (!this.doCheck ) {
            if (this.uniqIndex === 0) {
                this.uniqIndex = 1;
            } else if (this.uniqIndex === 1) {
                this.uniqIndex = 0;
            }
        }
        if(!this.utils.Base_HasValue(this.uniqIndex)){
            this.uniqIndex = 0;
        }
        _.each( this.listCom,(OneCom) => {
            //[ 循环用户有的权限列表 ]
            if ( OneCom["selected"] == true) {
                custId += OneCom["CUST_ID"]+",";
            }
        });

        let roleId = this.roleId;

        if(!this.utils.Base_HasValue(roleId)){
        	return this.confirmModal.show('confirm', {
                title: '请选择角色'
            })
        }
        // if(!this.utils.Base_HasValue(custId)){
        //     return this.confirmModal.show('confirm', {
        //         title: '请选择公司'
        //     })
        // }
        let data = {
            dataObject: {
                roleId: roleId,
                type:"basic_cust",
                ids:custId,
                uniqIndex: this.uniqIndex
            }
        };
        this.requestData.doActionByHdbsthorJson('hdbsthor-right', 'assignRightController', this.appConfigService.config.MysqlPF + '/roleToData', data).subscribe(
            (response) => {
                if(this.utils.Base_HasValue(response)){
                    if (response["suc"] === 'success') {
                        this.doCheck  = false;
                        this.utils.Base_Checking_Java("执行成功");
                    } else {
                        this.utils.Base_JAVA_Checked(response);
                    }
                }else{
                    this.utils.Base_JAVA_Checked("执行失败");
                }
            },
            (error) => {
                this.utils.Base_JAVA_Checked(error);
            }
            );
    };

    selallcust(){
        _.each(this.listCom,(OneCom) => {
            //[ 循环用户有的权限列表 ]
            OneCom["selected"] = this["selectAll"];
        })
    }

    ngOnInit() {
        let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
        if(this.utils.Base_HasValue(currentMenu)){
            this.helpUrl = currentMenu.HELP_URL;
        }

        const sqlPath = this.appConfigService.config.sql_path;
        let comJson = sqlPath+'/basic/basic_company_role.json';//公司
        let roleJson = sqlPath+'/system/system_role_ctrl_list.json';//角色
        if(sqlPath == "otms"){
            roleJson = 'otms/system/system_data_role.json';
            comJson = 'otms/basic/basic_customer_role.json';
        }
        //查询所有菜单角色
        this.requestData.getDataByCondition(roleJson,{"ROLE_TYPE": "DATAROLE"}).subscribe( response => {
            this.listRole = response["data"];
        })
        //查询所有委托客户
        this.requestData.getDataByCondition(comJson).subscribe( response => {
            this.listCom = response["data"];
        })
    }

}
