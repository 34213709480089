import {Component, Input, OnInit, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {RequestDataService} from '../../service/request-data.service';
import {
  ControlAnchor,
  MapOptions,
  MapTypeControlOptions,
  MapTypeControlType,
  NavigationControlOptions,
  NavigationControlType,
  OverviewMapControlOptions,
  ScaleControlOptions,
  BNavigationControl,
  Point,
  BMarker,
  MarkerOptions,
  Animation,BMapInstance,
  MapTypeEnum
} from 'angular2-baidu-map';

import {TableModalComponent} from '../table-modal/table-modal.component';
import * as _ from 'underscore';
import {UtilsService} from '../../service/utils.service';
import * as _sh from 'lodash';
import {AppConfigService} from "../../service/app-config.service";

declare var BMap: any;

@Component({
  selector: 'app-pop-map',
  templateUrl: './pop-map.component.html',
  styleUrls: ['./pop-map.component.css']
})
export class PopMapComponent implements OnInit {
  public options: MapOptions;
  public controlOpts: NavigationControlOptions;
  public overviewmapOpts: OverviewMapControlOptions;
  public scaleOpts: ScaleControlOptions;
  public mapTypeOpts: MapTypeControlOptions;
  public tplModal: NzModalRef;
  public mapLng;
  public mapLat;
  public searchText;
  public markers: Array<{ point: Point; options?: MarkerOptions }>
  // 是否显示弹框
  public isVisible = false;
  local: any;
  // 改变绑定对象
  @Input() isMulti = false;
  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;
  @Input() bindModel: any;
  @Input() forms: any;
  @Input() oneForms: any;


  @ViewChild('childForm', {static: false}) childForm;

  constructor(private el: ElementRef,
              private modalService: NzModalService,
              private utils: UtilsService,
              private appConfigService: AppConfigService,
              private requestDataService: RequestDataService
  ) {
    this.options = {
      centerAndZoom: {
        lat: 39.920116,
        lng: 116.403703,
        zoom: 16
      }
    };
    this.controlOpts = {
      anchor: ControlAnchor.BMAP_ANCHOR_TOP_LEFT,
      type: NavigationControlType.BMAP_NAVIGATION_CONTROL_LARGE
    };

    this.overviewmapOpts = {
      anchor: ControlAnchor.BMAP_ANCHOR_BOTTOM_RIGHT,
      isOpen: true
    };

    this.scaleOpts = {
      anchor: ControlAnchor.BMAP_ANCHOR_BOTTOM_LEFT
    };

    this.mapTypeOpts = {
      type: MapTypeControlType.BMAP_MAPTYPE_CONTROL_HORIZONTAL,
      mapTypes: [MapTypeEnum.BMAP_NORMAL_MAP, MapTypeEnum.BMAP_SATELLITE_MAP]
    };
  }
  setPoint(arr) {
    this.mapLng = _sh.toNumber(arr[0]);
    this.mapLat = _sh.toNumber(arr[1]);
    if (this.utils.Base_HasValue(arr)) {
      this.options = {
        centerAndZoom: {
          lat: this.mapLat,
          lng: this.mapLng,
          zoom: 16
        }
      };
    } else {
      this.appConfigService.config.baiduCenter;
      let pointArray = _sh.split(this.appConfigService.config.baiduCenter,',');
      let mapLng = _sh.toNumber(pointArray[0]) || 116.403931;
      let mapLat = _sh.toNumber(pointArray[1]) || 39.91328;

      this.options = {
        centerAndZoom: {
          lng: mapLng,
          lat: mapLat,
          zoom: 16
        }
      };

    }
    this.markers = [
      {
        point: {
          lat: this.mapLat,
          lng: this.mapLng
        }
      }
    ]


  }

  getPointFromValue() {
    if (this.bindModel) {
      if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
        let arr = _sh.split(this.bindModel[this.hulkColumn.column],",");
        this.setPoint(arr);
      }
    } else {
      let arr = _sh.split(this.hulkColumn.model,",");
      this.setPoint(arr);
    }
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
  }

  showModal(tplContent: TemplateRef<{}>) {
    if (this.hulkColumn.disable) {
      return false;
    }
    this.getPointFromValue();
    this.tplModal = this.modalService.create({
      nzTitle: '选择地址',
      nzContent: tplContent,
      nzWidth: 850,
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: (response) => {
        if (this.bindModel) {
          this.bindModel[this.hulkColumn.column] = this.mapLng + "," + this.mapLat;
          this.bindModel[this.hulkColumn.column + 'Name'] = this.mapLng + "," + this.mapLat;
        } else {
          this.hulkColumn.model = this.mapLng + "," + this.mapLat;
          this.hulkColumn.modelName = this.mapLng + "," + this.mapLat;
        }
      }
    });
  }

  public mapClick(e: any) {
    this.mapLng = e.point.lng.toFixed(6);
    this.mapLat = e.point.lat.toFixed(6);
    this.markers = [
      {
        point: {
          lat: this.mapLat,
          lng: this.mapLng
        }
      }
    ]
  }

  public controlLoaded(control: BNavigationControl): void {
  }

  getAddress() {
    if (this.utils.Base_HasValue(this.searchText)) {
      this.requestDataService.baiduMapSearch(this.searchText, null, null).subscribe(rep => {
        if (this.utils.Base_HasValue(rep)) {
          const message = _sh.get(rep,'message');
          if (message === "ok") {
            const oneAddrObj = _sh.get(rep,['results',0]);
            this.mapLat = oneAddrObj.location.lat;
            this.mapLng = oneAddrObj.location.lng;
            this.options = {
              centerAndZoom: {
                lat: this.mapLat,
                lng: this.mapLng,
                zoom: 16
              }
            };
            this.markers = [
              {
                point: {
                  lat: this.mapLat, // 纬度
                  lng: this.mapLng // 经度
                }
              }
            ]
          }
        }
      });
    }
  }

  public setAnimation(marker: BMarker): void {
    marker.setAnimation(Animation.BMAP_ANIMATION_BOUNCE)
  }

  //清空poplist值
  removeValue(): void {
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = null;
      this.bindModel[this.hulkColumn.column + 'Name'] = '';
    } else {
      this.hulkColumn.model = null;
      this.hulkColumn.modelName = '';
    }
  }
}
