import {CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from '@angular/router';
import {Injectable} from "@angular/core";
import {UserInfoService} from '../service/user-info.service';
import {UtilsService} from '../service/utils.service';


@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {

 	constructor(private userInfoService: UserInfoService,
		private utils: UtilsService) { }


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean{
		const userInfo = this.userInfoService.getUserInfo();
		if (this.utils.Base_HasValue(userInfo)) {
			return true;
		}else {
			this.userInfoService.gotoLogin();
		}
		　　
	}
}
