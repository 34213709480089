import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import * as _sh from 'lodash';

@Component({
  selector: 'app-ac-settlement',
  templateUrl: './ac-settlement.component.html',
  styleUrls: ['./ac-settlement.component.scss']
})
export class AcSettlementComponent implements OnInit {


  @Input() parentData: any;
  busiId = null;
  startPaidTime = null;
  endPaidTime = null;
  showList: any[];
  custSum = 0;
  custCount = 0;
  allChecked = false;
  indeterminate = false;
  checklist: any[];
  // 查询对象
  public conditionColumns: any = {
    "columns": [
      {
        "column": "START_TIME",
        "name": "START_TIME_NAME",
        "component": "datetime",
        "lable": "订单开始时间",
        "comment": "订单开始时间",
        "placeholder": "请选择订单开始时间",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "END_TIME",
        "name": "END_TIME_NAME",
        "component": "datetime",
        "lable": "订单结束时间",
        "comment": "订单结束时间",
        "placeholder": "请选择订单结束时间",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "BUSI_ID",
      "title": "商家ID",
      "visible": false,
      "pk_column": true,
      "iscondition": true,
      "conditionColumn": "BUSI_ID",
      "type": "normal",
      "export": true,
      "class": ""
    },
    {
      "column": "BUSI_NAME",
      "title": "商家名称",
      "visible": true,
      "type": "normal",
      "export": true,
      "class": ""
    },
    {
      "column": "ACOUNT",
      "title": "结算订单数量",
      "visible": true,
      "type": "normal",
      "export": true,
      "class": ""
    },
    {
      "column": "SUM_AMOUNT",
      "title": "结算总金额",
      "visible": true,
      "type": "normal",
      "export": true,
      "class": ""
    }
  ];
  private user: any;

  constructor(
    private confirmModal: ConfirmModalService,
    private requestDataService: RequestDataService,
    private requestService: RequestDataService,
    private userInfoService: UserInfoService,
    private utils: UtilsService
  ) {
    this.user = this.userInfoService.getUserInfo();
  }


  ngOnInit() {
    this.busiId = _sh.cloneDeep(_sh.get(this.parentData, ['id']))
  }

  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('jsbPro02/es/ac_settlement_orders.json', {
        'START_TIME': this.startPaidTime,
        'END_TIME': this.endPaidTime
      })
    );
    search.subscribe(values => {
      if (_sh.get(values, [0, 'page', 'rowCount'], 0) == 0) {
        this.showList = [];
        this.confirmModal.show('error', {'title': '查询错误', 'content': '该时段没有结算单。'});
      } else {
        this.showList = _sh.get(values, [0, 'data'], []);
        _sh.each(this.showList, oneData => {
          oneData.checked = true;
        })
      }
      this.refreshStatus();
    })
  }


  doSave() {
    this.checklist = [];
    _sh.each(this.showList, oneData => {
      if (oneData['checked'] == true) {
        this.checklist.push(oneData['BUSI_ID']);
      }
    });

    let queryData = {
      "userId": this.user.USER_ID,
    };
    const bodyData = {
      "startTime": this.startPaidTime,
      "endTime": this.endPaidTime,
      "esOrderList": this.checklist
    };
    this.requestService.doActionByHdbsthor('AppApi/EsPoController', 'doSettlement', queryData, bodyData, false, 'hdbsthor_jsb02', 'appToken').subscribe(response => {
      let status = _sh.get(response, ['status'], 200);
      if (status == 200) {
        this.confirmModal.show('success', {'title': '执行成功'});
      } else if (status == 500) {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    })
  }


  searchFilter() {
    this.showList = [];
    this.custCount = 0;
    this.custSum = 0;
    this.startPaidTime = this.findValue(this.conditionColumns, 'START_TIME');
    this.endPaidTime = this.findValue(this.conditionColumns, 'END_TIME');
    this.doSearch();
  }

  resetFilter() {
    _sh.each(this.conditionColumns['columns'], oneColum => {
      oneColum['model'] = null;
    })
    this.showList = [];
    this.custCount = 0;
    this.custSum = 0;
  }

  findValue(pForm, pColumnName) {
    let bFind = false;
    let value = null;
    _sh.each(pForm['columns'], oneColumn => {
      if ((oneColumn['column'] == pColumnName) && (!bFind)) {
        value = oneColumn['model'];
        bFind = true;
      }
    });
    return value;
  }


  checkAll(value: boolean): void {
    this.showList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(data?): void {
    const validData = this.showList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.custCount = 0;
    this.custSum = 0;
    _sh.each(this.showList, oneData => {
      if (oneData.checked == true) {
        this.custCount = this.custCount + 1;
        this.custSum = this.custSum + oneData['SUM_AMOUNT']
      }
    })
  }

}
