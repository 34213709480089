import { Component, OnInit } from '@angular/core';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
@Component({
  selector: 'app-research-outlay',
  templateUrl: './research-outlay.component.html',
  styleUrls: ['./research-outlay.component.css']
})
export class ResearchOutlayComponent implements OnInit {
  listData: any;
  year: any;
  objectKeys: any = Object.keys;
  sumCountArray = []; // 合计
  subjectAll = []; // 近五年承担科研项目
  subjectY = []; // 近五年纵向科研项目
  subjectCountry = []; // 近五年国家级科研项目
  subjectProvince = []; // 近五年省部级科研项目
  avgSubject = 0; // 年师均科研项目数s
  avgTotalMoney = 0; // 年师均总科研经费
  avgYTotalMoney = 0; // 年师均纵向科研经费
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [];
    const data2 = [
      {
        '项目名称': '近五年承担科研项目',
        '总数（项）': this.subjectAll[0]['SUBJECT_COUNT'] || 0,
        '总经费（万元）': this.subjectAll[0]['PROJECT_TOTAL'] || 0
      },
      {
        '项目名称': '近五年纵向科研项目',
        '总数（项）': this.subjectY[0]['SUBJECT_COUNT'] || 0,
        '总经费（万元）': this.subjectY[0]['PROJECT_TOTAL'] || 0
      },
      {
        '项目名称': '近五年国家级科研项目',
        '总数（项）': this.subjectCountry[0]['SUBJECT_COUNT'] || 0,
        '总经费（万元）': this.subjectCountry[0]['PROJECT_TOTAL'] || 0,
      },
      {
        '项目名称': '近五年省部级科研项目',
        '总数（项）': this.subjectProvince[0]['SUBJECT_COUNT'] || 0,
        '总经费（万元）': this.subjectProvince[0]['PROJECT_TOTAL'] || 0
      }
    ];
    const data3 = [
      {
        '年师均科研项目数（项）': this.avgSubject || 0,
        '年师均总科研经费（万元）': this.avgTotalMoney || 0,
        '年师均纵向科研经费（万元）': this.avgYTotalMoney || 0
      }
    ];
    _.each(this.listData, (value, key) => {
      const data = {
        '类别/计数': key
      };
      _.each(this.year, item => {
        data[item + '年新增项目数（个）'] = this.dataYear(value, item)[0]['START_COUNT'] || 0;
        data[item + '年结题项目数（个）'] = this.dataYear(value, item)[0]['END_COUNT'] || 0;
        data[item + '年经费数（万元）'] = this.dataYear(value, item)[0]['PROJECT_TOTAL'] || 0;
      });
      data1.push(data);
    });
    const datas = {
      '类别/计数': '合计'
    };
    _.each(this.year, item => {
      datas[item + '年新增项目数（个）'] = this.dataYear(this.sumCountArray, item)[0]['START_COUNT'] || 0;
      datas[item + '年结题项目数（个）'] = this.dataYear(this.sumCountArray, item)[0]['END_COUNT'] || 0;
      datas[item + '年经费数（万元）'] = this.dataYear(this.sumCountArray, item)[0]['PROJECT_TOTAL'] || 0;
    });
    data1.push(datas);

    if (!this.utils.Base_HasValue(this.listData)) {
      return this.confirmModal.show('warning', {title: '特殊报表没有数据不支持导出。'});
    }
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年科研项目数及经费情况',
        header: true
      },
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年科研项目数及经费情况（总数）',
        header: true
      },
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年师均科研项目数及经费',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("IV-1 科研项目数及经费情况.xlsx",?) FROM ?', [opts, [data1, data2, data3]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  dataYear(pArray, year) {
    const data = _.filter(pArray, oneData => {
      return oneData['YEAR'] === year;
    });
    if (data.length) {
      return data;
    } else {
      return [0];
    }
  }

  dataManage(pData, condition?) {
    const alaSql = require('alasql');
    if (this.utils.Base_HasValue(condition)) {
      return alaSql('select count(*) SUBJECT_COUNT, SUM(PROJECT_TOTAL) PROJECT_TOTAL FROM ? WHERE ' + condition + ' and 1 = 1', [pData]);
    } else {
      return alaSql('select count(*) SUBJECT_COUNT, SUM(PROJECT_TOTAL) PROJECT_TOTAL from ?', [pData]);
    }
  }

  // 数据处理
  dataFormat(pEndData, pData, pTeacherCount) {
    const alaSql = require('alasql');
    _.each(this.listData, oneData => {
      _.each(pEndData, item => {
        if (oneData['PROJECT_LEVEL'] === item['PROJECT_LEVEL'] && oneData['YEAR'] === item['YEAR']) {
          oneData['END_COUNT'] = item['END_COUNT'];
          item['selected'] = true;
        }
      });
    });
    const endData = _.filter(pEndData, item => {
      item['START_COUNT'] = 0;
      item['PROJECT_TOTAL'] = 0;
      return !item['selected'];
    });
    this.listData = this.listData.concat(endData);
    this.sumCountArray = alaSql('select YEAR,sum(START_COUNT) START_COUNT,SUM(END_COUNT) END_COUNT, SUM(PROJECT_TOTAL) PROJECT_TOTAL from ? group by YEAR', [this.listData]);
    this.listData = _.groupBy(this.listData, oneData => {
      return oneData['PROJECT_LEVEL'];
    });
    this.subjectAll = this.dataManage(pData);
    this.subjectY = this.dataManage(pData, 'PROJECT_LEVEL <> "非政府项目（横向项目）"');
    this.subjectCountry = this.dataManage(pData, 'PROJECT_LEVEL = "国家级"');
    this.subjectProvince = this.dataManage(pData, 'PROJECT_LEVEL = "省部级"');
    this.avgSubject = parseFloat((this.subjectAll[0]['SUBJECT_COUNT'] / pTeacherCount[0].acount).toFixed(2)); // 年师均科研项目数
    this.avgTotalMoney = parseFloat((this.subjectAll[0]['PROJECT_TOTAL'] / pTeacherCount[0].acount).toFixed(2)); // 年师均总科研经费
    this.avgYTotalMoney = parseFloat((this.subjectY[0]['PROJECT_TOTAL'] / pTeacherCount[0].acount).toFixed(2)); // 年师均纵向科研经费
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.year = [this.evaluationYear - 4, this.evaluationYear - 3, this.evaluationYear - 2, this.evaluationYear - 1, this.evaluationYear - 0];
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_project_start_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_project_end_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_project_data.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/teacher_count.json')
    );
    parallel.subscribe(response => {
      this.listData = response[0]['data'];
      this.dataFormat(response[1]['data'], response[2]['data'], response[3]['data']);
    });
  }

}
