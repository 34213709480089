import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hulk-config-radio',
  templateUrl: './hulk-config-radio.component.html',
  styleUrls: ['./hulk-config-radio.component.scss']
})
export class HulkConfigRadioComponent implements OnInit {

  @Input() oneColumn: any;
  @Input() attributes: any;
  @Input() selectOptions = [];
  @Input() label: any;
  @Input() code = 'code';
  @Input() name = 'name';

  constructor() { }

  ngOnInit() {
  }

}
