import {Component, OnInit} from '@angular/core';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import * as _ from 'underscore';
import * as Mtils from 'mtils';

@Component({
  selector: 'app-modify-column',
  templateUrl: './modify-column.component.html',
  styleUrls: ['./modify-column.component.css']
})
export class ModifyColumnComponent implements OnInit {

  // 字段数据
  public modelData: any = {};
  public selectedGroup: any = [];
  // 选择字段类型
  public columnType = [
    {
      code: 'input',
      name: '输入框'
    },
    {
      code: 'textarea',
      name: '长文本输入'
    },
    {
      code: 'date',
      name: '日期选择'
    }
  ];

  constructor(private confirmModal: ConfirmModalService) {
  }

  changeModel(model) {
    this.selectedGroup = model;
    _.each(this.modelData.groups, group => {
      if (group['visible']) {
        this.selectedGroup.push(group['groupId']);
      }
    });
    if (!this.selectedGroup.length) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少添加一条元数据字段'
      });
      return false;
    }
  }

  getColumnComponent(component) {
    const item = _.find(this.columnType, column => column['code'] === component);
    if (item) {
      return item['name'];
    } else {
      return '未知';
    }
  }

  changeColumn(column, columnName) {
    column['column'] = Mtils.utils.makePy(columnName);
  }

  // 添加分组
  addGroup() {
    this.modelData.groups.push({
      isAdd: true,
      visible: true,
      isList: false,
      type: 'form',
      groupOrder: this.modelData.groups.length + 1,
      groupName: '',
      groupId: '',
      columns: []
    });
  }

  // 添加字段
  addColumn(group, type) {
    group.columns.push({
      order: group.columns.length + 1,
      column: '',
      columnName: '',
      component: type,
      require: false,
      maxlength: type === 'input' ? 50 : 500
    });
  }

  // 删除分组
  delGroup(index) {
    this.modelData.groups.splice(index, 1);
    this.selectedGroup = [];
    _.each(this.modelData.groups, group => {
      if (group['visible']) {
        this.selectedGroup.push(group['groupId']);
      }
    });
  }

  // 上移字段
  upShiftColumn(i, j, column) {
    if (j > 0) {
      const prev = this.modelData.groups[i].columns[j - 1];
      prev['order'] = column['order'];
      column['order'] = column['order'] - 1;
      this.modelData.groups[i].columns[j - 1] = column;
      this.modelData.groups[i].columns[j] = prev;
    }
  }

  // 下移字段
  downShiftColumn(i, j, column) {
    if (this.modelData.groups[i].columns.length - 1 > j) {
      const next = this.modelData.groups[i].columns[j + 1];
      next['order'] = column['order'];
      column['order'] = column['order'] + 1;
      this.modelData.groups[i].columns[j + 1] = column;
      this.modelData.groups[i].columns[j] = next;
    }
  }

  // 删除一个字段
  deleteColumn(i, j) {
    this.modelData.groups[i].columns.splice(j, 1);
  }

  ngOnInit() {
    _.each(this.modelData.groups, group => {
      if (group['visible']) {
        this.selectedGroup.push(group['groupId']);
      }
      _.each(group['columns'], column => {
        column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
      });
    });
  }

}
