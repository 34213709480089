import {Pipe, PipeTransform} from '@angular/core';
import {RequestDataService} from "../service/request-data.service";
import {UtilsService} from "../service/utils.service";
import {AppConfigService} from "../service/app-config.service";
import {ConfirmModalService} from "../service/confirm-modal.service";
import * as _ from 'underscore';
import * as _sh from 'lodash';

@Pipe({
    name: 'thousandthsFormat'
})
export class ThousandthsFormatPipe implements PipeTransform {


    constructor(private utils: UtilsService
    ) {
    }

    transform(value: any, showType: any): any {
        let useThis = this.utils.Base_HasValue(showType);
        let dplaces = _sh.get(showType,['dplaces'],2);
        let result = null;
        if (useThis) {
            if (value) {
                value = parseFloat(value);
                value = value.toFixed(dplaces);
                value = value.toString().split(".");  // 分隔小数点
                let arr = value[0].split("").reverse();  // 转换成字符数组并且倒序排列
                let res = [];
                for (let i = 0, len = arr.length; i < len; i++) {
                    if (i % 3 === 0 && i !== 0) {
                        res.push(",");   // 添加分隔符
                    }
                    res.push(arr[i]);
                }
                res.reverse(); // 再次倒序成为正确的顺序
                if (value[1]) {  // 如果有小数的话添加小数部分
                    result = res.join("").concat("." + value[1]);
                } else {
                    result = res.join("");
                }
            }
            else {
                result = value;
            }
        } else {
            result = value;
        }
        return result;
    }

}
