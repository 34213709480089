import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../../service/request-data.service";
import * as _sh from "lodash";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss']
})

export class ReportCardComponent implements OnInit {
  @Input() parentData: any;

  public menu = [
    'nlpen',
    'nlpzh',
    'tfidf',
    'classfication',
    'cluster',
    'txtsentiment',
    'lda'
  ]
  public user: any;
  public labReport: any = {};
  public groupedData: any[] = [];
  public dataList: any[] = [];
  public scoreList: any[] = [];
  readOnlyVisibleBtn = {
    "back": true,
    "print": false
  }

  constructor(
    private requestDataService: RequestDataService,
    private userInfoService: UserInfoService,
    private utilsService: UtilsService,
  ) {
    this.user = userInfoService.getUserInfo();
  }

  async getData() {
    //console.log("report-card.component.ts 00 ", this.parentData);
    const resAll = await observableForkJoin(
      this.requestDataService.getDataByCondition("simulationTrain/nlp/st_nlp_experimental_task.json", {}), // 查询所有的子任务，与该子任务的 设置分值
      this.requestDataService.getDataByCondition("simulationTrain/nlp/st_nlp_reports.json", {}), // 查询每一个菜单的实验报告，及该实验报告的 设置分值
      this.requestDataService.getDataByCondition("simulationTrain/nlp/st_nlp_task_result_my.json", {USER_ID: this.parentData.id}), // 查询 这个人的 每一个 子任务的 得分
      this.requestDataService.getDataByCondition("simulationTrain/nlp/st_nlp_task_lab_report.json", {USER_ID: this.parentData.id}) // 查询 这个人的实验报告的 得分分值。
    ).toPromise();
    //console.log("report-card.component.ts 01 ", resAll);
    this.dataList = await (_sh.get(resAll, [0, 'data'], []));
    const reportList = await (_sh.get(resAll, [1, 'data'], []));
    this.scoreList = await (_sh.get(resAll, [2, 'data'], []));
    let labReportList = await (_sh.get(resAll, [3, 'data'], []));
    //将子任务分数添加到数据中
    this.dataList = this.mergeScoresIntoData(this.scoreList,this.dataList);
    //console.log("handleGetDatas 00: ",this.dataList);
    this.dataList = this.addExperimentalReportData(this.dataList, reportList);
    this.groupDataByMenu();
    //将实验成绩添加到数据中
    this.labReport = this.modifyLabReportList(labReportList);
  }

  mergeScoresIntoData(scoreList: any[], dataList: any[]): any[] {
    // 克隆 dataList 数组，避免直接修改原始数据
    const mergedDataList = dataList.map((dataItem) => ({ ...dataItem }));
    mergedDataList.forEach((dataItem) => {
      // 找到与 dataList 中的元素 ID 属性相同的 scoreList 元素
      const matchedScoreItem = scoreList.find(
        (scoreItem) => scoreItem.EXPERIMENTAL_TASK_DESIGN_ID === dataItem.ID
      );
      // 如果找到了匹配的 scoreList 元素，则将其 OPERATION_SCORE 属性加入到 dataList 对应元素中
      if (matchedScoreItem) {
        dataItem.OPERATION_SCORE = matchedScoreItem.OPERATION_SCORE;
      }
    });
    return mergedDataList;
  }

  modifyLabReportList = (labReportList): any => {
    // 创建一个新的对象用于存放转换后的数据
    const modifiedLabReport = {};
    for (let i = 0; i < this.menu.length; i++) {
      modifiedLabReport[this.menu[i]] = '未上传实验报告';
    }
    // 遍历 labReportList 数组的每个元素
    for (let i = 0; i < labReportList.length; i++) {
      const item = labReportList[i];
      // 根据 MENU_ID 设置对应的分数属性
      if (item.USER_ID === this.parentData.parentDataId) {
        for (let j = 0; j < this.menu.length; j++) {
          if (item.HAS_SCORE == 'YES' && item.MENU_ID == this.menu[j]) {
            modifiedLabReport[this.menu[j]] = item.SCORE;
          }
          if (item.HAS_SCORE == 'NO' && item.MENU_ID == this.menu[j]) {
            modifiedLabReport[this.menu[j]] = '还未评分';
          }
        }
      }
    }
    // 返回转换后的对象
    return modifiedLabReport;
  }

  addExperimentalReportData = (_dataList, _reportList) => {
    let result = [];
    let report_score = null;
    for (let i = 0; i < this.menu.length; i++) {
      for (let j = 0; j < this.menu.length; j++) {
        if (_reportList[j].MENU === this.menu[i]) {
          report_score = _reportList[j].SCORE_VALUE_SETTING;
        }
      }
      // 保存 this.menu[i] 到一个变量 menuValue
      let menuValue = this.menu[i];
      // 使用过滤函数时，使用箭头函数来维持上下文
      let temp = _sh.filter(_dataList, (value) => {
        return value.MENU === menuValue;
      });
      temp.push({
        MENU: menuValue,
        MENU_DICT: 'experimentalReport',
        TASK_TITLE: '完成实验报告',
        SCORE_VALUE_SETTING: report_score,
        OPERATION_ID_DICT: ''
      });
      result.push(...temp);
    }
    return result;
  }


  groupDataByMenu() {
    const groupedData = {};
    let experimentalReportMenu = [];
    this.dataList.forEach(item => {
      if (item.MENU_DICT === 'experimentalReport') {
        experimentalReportMenu.push(item);
      } else {
        if (!groupedData[item.MENU_DICT]) {
          groupedData[item.MENU_DICT] = {menu: item.MENU_DICT, subTasks: [], MENU: item.MENU};
        }
        groupedData[item.MENU_DICT].subTasks.push(item);
      }
    });
    // 将 "experimentalReport" 菜单元素添加到每个 groupedData 数组元素的 subTasks 数组末尾
    if (experimentalReportMenu) {
      experimentalReportMenu.forEach(menuItem => {
        const menu = menuItem.MENU;
        Object.keys(groupedData).forEach(key => {
          if (groupedData[key].MENU === menu) {
            groupedData[key].subTasks.push(menuItem);
          }
        });
      });
    }
    this.groupedData = Object.values(groupedData);
  }


  calculateSubTaskScores(subTasks: any[]): number {
    let totalScore = 0;
    subTasks.forEach(subTask => {
      totalScore += subTask.SCORE_VALUE_SETTING;
    });
    return totalScore;
  }

  calculateTotalScore(subTasks: any[]): number {
    let totalScore = 0;
    subTasks.forEach(subTask => {
      const score = subTask.MENU_DICT === 'experimentalReport' ? this.labReport[subTask.MENU] : subTask.OPERATION_SCORE;
      if (!isNaN(score)) {
        totalScore += score;
      }
    });
    return totalScore;
  }


  ngOnInit() {
    this.getData();
    //console.log("parentData: ", this.parentData);
  }

  doRefresh(): void {
    this.getData();
  }

  printWindows(): void {
    window.print();
  }
}
