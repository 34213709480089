import {Component, OnInit} from '@angular/core';
import {UploadService} from '../../service/upload.service';
import {NzMessageService, UploadXHRArgs} from 'ng-zorro-antd';
import {AppConfigService} from '../../service/app-config.service';
import {AjaxService} from '../../service/ajax.service';
import {UserInfoService} from '../../service/user-info.service';
import {UtilsService} from '../../service/utils.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {DataCenterService} from '../../service/data-center.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  public data: Object;
  public fileList: any = [];
  public fileUrl: string = null;
  public modelDownloadUrl: string = null;
  public user: any;
  public errorUrl: string;

  constructor(private appConfigService: AppConfigService,
              private uploadService: UploadService,
              private userInfo: UserInfoService,
              private utils: UtilsService,
              private dataCenter: DataCenterService,
              private autoInsert: AutoInsertService,
              private msg: NzMessageService,
              private confirmModal: ConfirmModalService,
              private ajaxService: AjaxService) {
    this.user = userInfo.getUserInfo();
  }

  customReq = (item: UploadXHRArgs) => {
    if (this.appConfigService.config['userOssOrCos'] === 'COS') {
      return this.uploadService.doCosUpload(item.file, item['progress'], null, null, null).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config['COS']['domain'] + '/' + result['url'];
        this.importData(this.fileUrl);
      });
    } else {
      return this.uploadService.doAliOssUpload(item.file, item['progress'], null).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config['OSS']['domain'] + '/' + result.name;
        this.importData(this.fileUrl);
      });
    }
  }

  importData(fileUrl) {
    const tmpUrl = this.appConfigService.getServer().importServlet + '?random=' + Math.random();
    const logId = this.utils.Base_getUuid();
    const myParam = {
      'PF': this.appConfigService.config.MysqlPF,
      'configJsonFileName': this.data['IMPORT_JSON_FILE'],
      'excelFileName': fileUrl,
      'comId': '',
      'userId': this.user.USER_ID,
      'empId': this.user.EMP_ID,
      'userName': this.user.USERNAME,
      'logId': logId
    };
    this.insertImportLog(fileUrl, this.data, logId).subscribe(response => {
      if (response.state === 'success') {
        this.ajaxService.ajaxPost(tmpUrl, myParam).subscribe(rep => {
          if (rep.status == 200) {
            this.confirmModal.show('success', {
              'title': '温馨提示',
              content: '导入成功'
            });
          } else {
            this.errorUrl = rep.error.errorUrl;
            this.confirmModal.show('error', {
              'title': '导入失败',
              content: rep.error.text
            });
          }
          this.fileUrl = null;
        }, error => {
          this.fileUrl = null;
          this.confirmModal.show('error', {
            'title': '温馨提示',
            content: error
          });
        });
      }
    });
  }

  beforeUpload = (file: File) => {
    const isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isJPG) {
      this.msg.error('You can only upload xlsx file!');
    }
    return isJPG ;
  }

  changeFileList = (result) => {
    if (result.type === 'success' && result.fileList.length === 2) {
      // 只传一个文件
      result.fileList.splice(0, 1);
    }
  }

  insertImportLog(pExcelUrl, pAction, logId) {
    const templateJson = {
      'LIST': [{
        'TABLE': 'system_import_log',
        'ACTION': 'Insert',
        'WHEREGROUP': [],
        'WHERE': [],
        'COLUMNS': [
          {'COLUMN': 'LOG_ID'},
          {'COLUMN': 'LOG_MENU_ID'},
          {'COLUMN': 'LOG_MENU_NAME'},
          {'COLUMN': 'LOG_IMPORT_URL'},
          {'COLUMN': 'ACTION_TEXT'},
          {'COLUMN': 'ADD_USERID'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'ADD_DATE'}
        ],
        'RECORDS': [
          {
            'LOG_ID': logId,
            'LOG_MENU_ID': this.dataCenter._dataObject.nowMenu.MENU_ID,
            'LOG_MENU_NAME': this.dataCenter._dataObject.nowMenu.MENU_NAME,
            'LOG_IMPORT_URL': pExcelUrl,
            'ACTION_TEXT': pAction.ACTION_TEXT,
            'ADD_DATE': 'SYS-DATETIME',
            'ADD_NAME': this.user.USERNAME,
            'ADD_USERID': this.user.USER_ID
          }
        ]
      }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  ngOnInit() {
    //hulkServer/assets/import/info_company_import.xlsx
    //http://127.0.0.1:3000/assets/import/info_company_import.xlsx
    this.data = this;
    this.modelDownloadUrl = this.data['IMPORT_MODEL_URL'];
    let seed = 'hulkServer'
    let bFind = _.startsWith(this.modelDownloadUrl, seed);
    if (bFind) {
      this.modelDownloadUrl = _.replace(this.modelDownloadUrl, seed, this.appConfigService.config.NodeJsServer);
    }
  }

}
