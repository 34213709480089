///<reference path="../../node_modules/@angular/http/src/http_module.d.ts"/>
import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PathLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {BaiduMapModule} from 'angular2-baidu-map';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {LoginComponent} from './components/login/login.component';
import {MenusComponent} from './components/menus/menus.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {IndexComponent} from './components/index/index.component';
import {ListFormComponent} from './basicForm/list-form/list-form.component';
import {DetailFormComponent} from './basicForm/detail-form/detail-form.component';
import {ReadOnlyFormComponent} from './basicForm/read-only-form/read-only-form.component';
import {CustomFormComponent} from './basicForm/custom-form/custom-form.component';
import {InputComponent} from './basicComponent/input/input.component';
import {SelectComponent} from './basicComponent/select/select.component';
import {DateComponent} from './basicComponent/date/date.component';

import {CheckLoginGuard} from './guard/check-login.guard';
import * as localForage from 'localforage';
import {NgxEchartsModule} from 'ngx-echarts';
import {UEditorModule} from 'ngx-ueditor';
import {InputTrimModule} from 'ng2-trim-directive';
import {CommonFilterPipe} from './filter/common-filter.pipe';
import {FuncFilterPipe} from './filter/func-filter.pipe';
import {FormComponent} from './basicComponent/form/form.component';
import {ReportFormComponent} from './basicForm/report-form/report-form.component';
import {ParsonalCenterComponent} from './components/parsonal-center/parsonal-center.component';
import {TextareaComponent} from './basicComponent/textarea/textarea.component';
import {InputMultiComponent} from './basicComponent/input-multi/input-multi.component';
import {SelectMultiComponent} from './basicComponent/select-multi/select-multi.component';
import {RichTextEditorComponent} from './basicComponent/rich-text-editor/rich-text-editor.component';
import {PoplistComponent} from './basicComponent/poplist/poplist.component';
import {MenuBtnComponent} from './components/menu-btn/menu-btn.component';
import {MultiPoplistComponent} from './basicComponent/multi-poplist/multi-poplist.component';
import {UploadComponent} from './basicComponent/upload/upload.component';
import {GroupFilterPipe} from './filter/group-filter.pipe';
import {ValidateFormComponent} from './basicComponent/validate-form/validate-form.component';
import {RoleMenuComponent} from './components/role-menu/role-menu.component';
import {UserRoleComponent} from './components/user-role/user-role.component';
import {MultiPopupJsonComponent} from './basicComponent/multi-popup-json/multi-popup-json.component';
import {RoleCustComponent} from './components/role-cust/role-cust.component';
import {AuditContractComponent} from './project/shopManagement/audit-contract/audit-contract.component';
import {DateRangeComponent} from './basicComponent/date-range/date-range.component';
import {SettingColumnsComponent} from './components/setting-columns/setting-columns.component';
import {ScriptUploadComponent} from './project/headLine/script-upload/script-upload.component';
import {FormModalComponent} from './basicComponent/form-modal/form-modal.component';
import {ImportComponent} from './basicComponent/import/import.component';
import {SiteReportComponent} from './project/headLine/site-report/site-report.component';
import {VisitReportComponent} from './project/headLine/visit-report/visit-report.component';
import {DatasetInsertComponent} from './project/headLine/dataset-insert/dataset-insert.component';
import {DatasetDetailComponent} from './project/headLine/dataset-detail/dataset-detail.component';
import {CollectReportComponent} from './project/headLine/collect-report/collect-report.component';
import {TextComponent} from './basicComponent/text/text.component';
import {GrabReportComponent} from './project/headLine/grab-report/grab-report.component';
import {DayReleaseComponent} from './project/headLine/day-release/day-release.component';
import {TimeComponent} from './basicComponent/time/time.component';
import {PreReleaseComponent} from './project/headLine/pre-release/pre-release.component';
import {DateMonthsComponent} from './basicComponent/date-months/date-months.component';
import {FileDescComponent} from './project/headLine/file-desc/file-desc.component';
import {SiteReleaseComponent} from './project/headLine/site-release/site-release.component';
import {ModelInsertComponent} from './project/headLine/model-insert/model-insert.component';
import {DataverseInsertComponent} from './project/headLine/dataverse-insert/dataverse-insert.component';
import {DataverseDetailComponent} from './project/headLine/dataverse-detail/dataverse-detail.component';
import {ModelDetailComponent} from './project/headLine/model-detail/model-detail.component';
import {ViewModelComponent} from './project/headLine/view-model/view-model.component';
import {ModifyColumnComponent} from './project/headLine/modify-column/modify-column.component';
import {DsMultiPoplistComponent} from './basicComponent/ds-multi-poplist/ds-multi-poplist.component';
import {EsRebuildComponent} from './project/headLine/es-rebuild/es-rebuild.component';
import {DataversePermissionComponent} from './project/headLine/dataverse-permission/dataverse-permission.component';
import {DatasetPermissionComponent} from './project/headLine/dataset-permission/dataset-permission.component';
import {ApplyFileDetailComponent} from './project/headLine/apply-file-detail/apply-file-detail.component';
import {LibraryInfoComponent} from './project/headLine/library-info/library-info.component';
import {VisualReportComponent} from './project/headLine/visual-report/visual-report.component';
import {AutoJsonComponent} from './project/headLine/auto-json/auto-json.component';
import {TableModalComponent} from './basicComponent/table-modal/table-modal.component';
import {ColumnGroupComponent} from './basicComponent/column-group/column-group.component';
import {OperateTableComponent} from './project/headLine/operate-table/operate-table.component';
import {ResumeComponent} from './project/resume/resume/resume.component';
import {CnResumeComponent} from './project/resume/cn-resume/cn-resume.component';
import {EnResumeComponent} from './project/resume/en-resume/en-resume.component';
import {UploadPictureResumeComponent} from './project/resume/upload-picture-resume/upload-picture-resume.component';
import {SelectJsonComponent} from './basicComponent/select-json/select-json.component';
import {UploadFileResumeComponent} from './project/resume/upload-file-resume/upload-file-resume.component';
import {
  UploadFileResumeNormalComponent
} from './project/resume/upload-file-resume-normal/upload-file-resume-normal.component';
import {CustplanModalComponent} from './project/fury/custplan-modal/custplan-modal.component';
import {FuryCockpitComponent} from './project/fury/fury-cockpit/fury-cockpit.component';
import {DateWeekComponent} from './basicComponent/date-week/date-week.component';
import {ReportTeacherComponent} from './project/subjectInfo/report-teacher/report-teacher.component';
import {GraduateJobUnitComponent} from './project/subjectInfo/graduate-job-unit/graduate-job-unit.component';
import {NumberOfDoctoralComponent} from './project/subjectInfo/number-of-doctoral/number-of-doctoral.component';
import {GraduateProgramsComponent} from './project/subjectInfo/graduate-programs/graduate-programs.component';
import {NumberOfMasterComponent} from './project/subjectInfo/number-of-master/number-of-master.component';
import {
  CommunicationDoctoralComponent
} from './project/subjectInfo/communication-doctoral/communication-doctoral.component';
import {CommunicationMasterComponent} from './project/subjectInfo/communication-master/communication-master.component';
import {
  CommunicationForeignersComponent
} from './project/subjectInfo/communication-foreigners/communication-foreigners.component';
import {GraduateEmploymentComponent} from './project/subjectInfo/graduate-employment/graduate-employment.component';
import {ResearchResultsComponent} from './project/subjectInfo/research-results/research-results.component';
import {ResearchOutlayComponent} from './project/subjectInfo/research-outlay/research-outlay.component';
import {InstrumentEquipmentComponent} from './project/subjectInfo/instrument-equipment/instrument-equipment.component';
import {PaperComponent} from './project/subjectInfo/paper/paper.component';
import {StudentAidInfoComponent} from './project/subjectInfo/student-aid-info/student-aid-info.component';
import {TeachingAwardComponent} from './project/subjectInfo/teaching-award/teaching-award.component';
import {AcademicExchangeComponent} from './project/subjectInfo/academic-exchange/academic-exchange.component';
import {ScienceInnovationComponent} from './project/subjectInfo/science-innovation/science-innovation.component';
import {SubjectTeacherComponent} from './project/subjectInfo/subject-teacher/subject-teacher.component';
import {StudentAchievementComponent} from './project/subjectInfo/student-achievement/student-achievement.component';
import {RateComponent} from './basicComponent/rate/rate.component';
import {HrComponent} from './basicComponent/hr/hr.component';
import {
  SubjectVisualReportComponent
} from './project/subjectInfo/subject-visual-report/subject-visual-report.component';
import {YearComponent} from './basicComponent/year/year.component';
import {
  SubjectConferDegreeIComponent
} from './project/subjectInfo/subject-visual-report/subject-confer-degree-I/subject-confer-degree-I.component';
import {
  SubjectConferDegreeIIComponent
} from './project/subjectInfo/subject-visual-report/subject-confer-degree-II/subject-confer-degree-II.component';
import {
  SubjectConferDegreeIIIComponent
} from './project/subjectInfo/subject-visual-report/subject-confer-degree-III/subject-confer-degree-III.component';
import {
  SubjectConferDegreeIIIIComponent
} from './project/subjectInfo/subject-visual-report/subject-confer-degree-IIII/subject-confer-degree-IIII.component';
import {
  TeachingInfrastructureComponent
} from './project/subjectInfo/teaching-infrastructure/teaching-infrastructure.component';
import {GraduatePracticeComponent} from './project/subjectInfo/graduate-practice/graduate-practice.component';
import {StudentsExamComponent} from './project/subjectInfo/students-exam/students-exam.component';
import {TeacherInformationComponent} from './project/subjectInfo/teacher-information/teacher-information.component';
import {TeacherTrainingComponent} from './project/subjectInfo/teacher-training/teacher-training.component';
import {
  SubjectVisualReportSetComponent
} from './project/subjectInfo/subject-visual-report-set/subject-visual-report-set.component';
import {
  AcademicCommunicationComponent
} from './project/subjectInfo/academic-communication/academic-communication.component';
import {MultiPopComponent} from './project/resume/multi-pop/multi-pop.component';
import {ResumeChartComponent} from './project/resume/resume-chart/resume-chart.component';
import {GrabReleaseComponent} from './project/headLine/grab-release/grab-release.component';
import {EditNewsComponent} from './project/headLine/edit-news/edit-news.component';
import {ModelManagementComponent} from './project/digitalResources/model-management/model-management.component';
import {DrModelDetailComponent} from './project/digitalResources/dr-model-detail/dr-model-detail.component';
import {ChannelComponent} from './project/digitalResources/channel/channel.component';
import {PictureListComponent} from './project/digitalResources/picture-list/picture-list.component';
import {PictureUploadComponent} from './project/digitalResources/picture-upload/picture-upload.component';
import {
  PictureUploadDigitalComponent
} from './basicComponent/picture-upload-digital/picture-upload-digital.component';
import {PictureEditComponent} from './project/digitalResources/picture-edit/picture-edit.component';
import {PictureDetailComponent} from './project/digitalResources/picture-detail/picture-detail.component';
import {GroupingListComponent} from './project/digitalResources/grouping-list/grouping-list.component';
import {GroupingAddEditComponent} from './project/digitalResources/grouping-add-edit/grouping-add-edit.component';
import {GroupingModelComponent} from './project/digitalResources/grouping-model/grouping-model.component';
import {GroupingEditComponent} from './project/digitalResources/grouping-edit/grouping-edit.component';
import {WordListComponent} from './project/digitalResources/word-list/word-list.component';
import {AllDrComponent} from './project/digitalResources/all-dr/all-dr.component';
import {WordAddEditComponent} from './project/digitalResources/word-add-edit/word-add-edit.component';
import {GroupingDetailComponent} from './project/digitalResources/grouping-detail/grouping-detail.component';
import {WordDetailComponent} from './project/digitalResources/word-detail/word-detail.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {GroupSingleComponent} from './project/digitalResources/group-single/group-single.component';
import {GroupMultipleComponent} from './project/digitalResources/group-multiple/group-multiple.component';
import {
  ResourceGroupManagementComponent
} from './project/digitalResources/resource-group-management/resource-group-management.component';
import {
  ResourceGroupDetailComponent
} from './project/digitalResources/resource-group-detail/resource-group-detail.component';
import {
  KnowledgeMapManagementComponent
} from './project/digitalResources/knowledge-map-management/knowledge-map-management.component';
import {
  FileUploadDigitalComponent
} from './project/digitalResources/upload-components/file-upload-digital/file-upload-digital.component';
import {
  UserGroupManagementComponent
} from './project/digitalResources/user-group-management/user-group-management.component';
import {
  GroupUserManagementComponent
} from './project/digitalResources/group-user-management/group-user-management.component';
import {SetPointModalComponent} from './project/digitalResources/set-point-modal/set-point-modal.component';
import {
  DistributionUserGroupComponent
} from './project/digitalResources/distribution-user-group/distribution-user-group.component';
import {
  DigitalVisualReportComponent
} from './project/digitalResources/digital-visual-report/digital-visual-report.component';
import {ReportManagementComponent} from './project/education/report-management/report-management.component';
import {RitchTextResumeComponent} from './project/resume/ritch-text-resume/ritch-text-resume.component';
import {PlaceUseDayComponent} from './project/wisdomHs/place-use-day/place-use-day.component';
import {OnePageReportComponent} from './basicForm/one-page-report/one-page-report.component';
import {FutureHighSpeedComponent} from './project/fury/future-high-speed/future-high-speed.component';
import {FutureSignalListComponent} from './project/fury/future-signal-list/future-signal-list.component';
import {InputWagonComponent} from './project/dfmc/input-wagon/input-wagon.component';
import {InputWagonNewComponent} from './project/dfmc/input-wagon-new/input-wagon-new.component';

import {InputStorageComponent} from './project/dfmc/input-storage/input-storage.component';
import {InputOutboundComponent} from './project/dfmc/input-outbound/input-outbound.component';
import {InputRolloverComponent} from './project/dfmc/input-rollover/input-rollover.component';
import {InputInventoryComponent} from './project/dfmc/input-inventory/input-inventory.component';
import {InputIncomeComponent} from './project/dfmc/input-income/input-income.component';
import {InputDisbursementComponent} from './project/dfmc/input-disbursement/input-disbursement.component';
import {WagonFormationComponent} from './project/dfmc/wagon-formation/wagon-formation.component';
import {DashboardOneComponent} from './project/dfmc/dashboard-one/dashboard-one.component';
import {PopMapComponent} from './basicComponent/pop-map/pop-map.component';
import {TinymceComponent} from './basicComponent/tinymce/tinymce.component';
import {InputSkuComponent} from './basicForm/input-sku/input-sku.component';
import {CascaderComponent} from './basicComponent/cascader/cascader.component';
import {ArrayFilterPipe} from './filter/array-filter.pipe';
import {DescriptionFormComponent} from './basicForm/description-form/description-form.component';
import {InputServiceComponent} from './project/genecreate/input-service/input-service.component';
import {
  InputServiceCheckboxsComponent
} from './project/genecreate/input-service/input-service-checkboxs/input-service-checkboxs.component';
import {
  InputServiceRadiosComponent
} from './project/genecreate/input-service/input-service-radios/input-service-radios.component';
import {
  InputServiceInputComponent
} from './project/genecreate/input-service/input-service-input/input-service-input.component';
import {
  InputServiceDescribeComponent
} from './project/genecreate/input-service/input-service-describe/input-service-describe.component';
import {
  InputServiceTableComponent
} from './project/genecreate/input-service/input-service-table/input-service-table.component';
import {CustomerStatementsComponent} from './project/agriculture/customer-statements/customer-statements.component';
import {EsOrderDetailComponent} from './project/genecreate/es-order-detail/es-order-detail.component';
import {
  ReadServiceCheckboxsComponent
} from './project/genecreate/es-order-detail/read-service-checkboxs/read-service-checkboxs.component';
import {
  ReadServiceInputComponent
} from './project/genecreate/es-order-detail/read-service-input/read-service-input.component';
import {
  ReadServiceRadiosComponent
} from './project/genecreate/es-order-detail/read-service-radios/read-service-radios.component';
import {
  ReadServiceDescribeComponent
} from './project/genecreate/es-order-detail/read-service-describe/read-service-describe.component';
import {
  ReadServiceTableComponent
} from './project/genecreate/es-order-detail/read-service-table/read-service-table.component';
import {EsOrderFilesComponent} from './project/genecreate/es-order-files/es-order-files.component';
import {SimpleUploadComponent} from './basicComponent/simple-upload/simple-upload.component';
import {RoleFormComponent} from './basicForm/role-form/role-form.component';
import {WorkbenchFormComponent} from './basicForm/workbench-form/workbench-form.component';
import {TreeFormComponent} from './basicForm/tree-form/tree-form.component';
import {WordTemplateComponent} from './basicForm/word-template/word-template.component';
import {ClipboardModule} from 'ngx-clipboard';
import {WorkflowBranchComponent} from './basicForm/workflow-management/workflow-branch/workflow-branch.component';
import {WorkflowAddBtnComponent} from './basicForm/workflow-management/workflow-add-btn/workflow-add-btn.component';
import {
  WorkflowCheckManComponent
} from './basicForm/workflow-management/workflow-check-man/workflow-check-man.component';
import {WorkflowCopyManComponent} from './basicForm/workflow-management/workflow-copy-man/workflow-copy-man.component';
import {WorkflowStartComponent} from './basicForm/workflow-management/workflow-start/workflow-start.component';
import {
  WorkflowConditionComponent
} from './basicForm/workflow-management/workflow-condition/workflow-condition.component';
import {TreeSelectComponent} from './basicComponent/tree-select/tree-select.component';
import {HdbsPipePipe} from './pipe/hdbs-pipe.pipe';
import {WorkflowManagementComponent} from './basicForm/workflow-management/workflow-management.component';
import {PopAmapComponent} from './basicComponent/pop-amap/pop-amap.component';
import {TodoCenterComponent} from './basicForm/todo-center/todo-center.component';
import {RadioComponent} from './basicComponent/radio/radio.component';
import {DescriptionsComponent} from './basicComponent/descriptions/descriptions.component';
import {DrawerBtnComponent} from './basicForm/drawer-btn/drawer-btn.component';
import {DashboardFormComponent} from './basicForm/dashboard-form/dashboard-form.component';
import {ImagePreviewModuleComponent} from './basicForm/image-preview-module/image-preview-module.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {PrintResultComponent} from './basicForm/print-result/print-result.component';
import {HelpDocumentComponent} from './basicForm/help-document/help-document.component';
import {DescriptionsFileComponent} from './basicComponent/descriptions/descriptions-file/descriptions-file.component';
import {
  DescriptionsTimelineComponent
} from './basicComponent/descriptions/descriptions-timeline/descriptions-timeline.component';
import {HulkConfigDetailComponent} from './hulkConifg/hulk-config-detail/hulk-config-detail.component';
import {
  HulkConfigPoplistComponentComponent
} from './hulkConifg/hulk-config-poplist-component/hulk-config-poplist-component.component';
import {
  HulkConfigPoplistInsertvalueComponent
} from './hulkConifg/hulk-config-poplist-insertvalue/hulk-config-poplist-insertvalue.component';
import {
  HulkConfigPoplistModelComponent
} from './hulkConifg/hulk-config-poplist-model/hulk-config-poplist-model.component';
import {
  HulkConfigSelectDisableComponent
} from './hulkConifg/hulk-config-select-disable/hulk-config-select-disable.component';
import {
  HulkConfigPoplistTablenameComponent
} from './hulkConifg/hulk-config-poplist-tablename/hulk-config-poplist-tablename.component';
import {HulkConfigSelectComponent} from './hulkConifg/hulk-config-select/hulk-config-select.component';
import {HulkConfigWidthComponent} from './hulkConifg/hulk-config-width/hulk-config-width.component';
import {HulkConfigDictionaryComponent} from './hulkConifg/hulk-config-dictionary/hulk-config-dictionary.component';
import {
  HulkConfigPoplistDictionaryComponent
} from './hulkConifg/hulk-config-poplist-dictionary/hulk-config-poplist-dictionary.component';
import {HulkConfigListSqlfileComponent} from './hulkConifg/hulk-config-list-sqlfile/hulk-config-list-sqlfile.component';
import {HulkConfigDesignListComponent} from './hulkConifg/hulk-config-design-list/hulk-config-design-list.component';
import {HulkConfigInputComponent} from './hulkConifg/hulk-config-input/hulk-config-input.component';
import {
  HulkConfigDesignConditionComponent
} from './hulkConifg/hulk-config-design-condition/hulk-config-design-condition.component';
import {HulkConfigNewFileComponent} from './hulkConifg/hulk-config-new-file/hulk-config-new-file.component';
import {
  HulkConfigPoplistDictionaryInputComponent
} from './hulkConifg/hulk-config-poplist-dictionary-input/hulk-config-poplist-dictionary-input.component';
import {
  HulkConfigDesignReadonlyComponent
} from './hulkConifg/hulk-config-design-readonly/hulk-config-design-readonly.component';
import {HulkConfigBatchBtnComponent} from './hulkConifg/hulk-config-batch-btn/hulk-config-batch-btn.component';
import {
  HulkConfigDesignReadonlyFromvalueComponent
} from './hulkConifg/hulk-config-design-readonly-fromvalue/hulk-config-design-readonly-fromvalue.component';
import {HulkConfigCheckboxComponent} from './hulkConifg/hulk-config-checkbox/hulk-config-checkbox.component';
import {SettlementFormComponent} from './settlement-form/settlement-form.component';
import {ThousandthsFormatPipe} from './pipe/thousandths-format.pipe';
import {HulkConfigColumnsComponent} from './hulkConifg/hulk-config-columns/hulk-config-columns.component';
import {HulkConfigMsgTemplateComponent} from './hulkConifg/hulk-config-msg-template/hulk-config-msg-template.component';
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {HulkConfigWfMoreInfoComponent} from './hulkConifg/hulk-config-wf-more-info/hulk-config-wf-more-info.component';
import {
  HulkConfigDatasourceCheckComponent
} from './hulkConifg/hulk-config-datasource-check/hulk-config-datasource-check.component';
import {
  DescriptionsEditTableComponent
} from './basicComponent/descriptions/descriptions-edit-table/descriptions-edit-table.component';
import {
  DescriptionsEditInputComponent
} from './basicComponent/descriptions/descriptions-edit-input/descriptions-edit-input.component';
import {
  DescriptionsEditLableComponent
} from './basicComponent/descriptions/descriptions-edit-lable/descriptions-edit-lable.component';
import {
  DescriptionsEditDateComponent
} from './basicComponent/descriptions/descriptions-edit-date/descriptions-edit-date.component';
import {
  DescriptionsEditSelectComponent
} from './basicComponent/descriptions/descriptions-edit-select/descriptions-edit-select.component';
import {
  DescriptionsEditMoreBtnComponent
} from './basicComponent/descriptions/descriptions-edit-more-btn/descriptions-edit-more-btn.component';
import {
  DescriptionsEditTableInnerComponent
} from './basicComponent/descriptions/descriptions-edit-table-inner/descriptions-edit-table-inner.component';
import {
  DescriptionsGroupRowComponent
} from './basicComponent/descriptions/descriptions-group-row/descriptions-group-row.component';
import {PaperDesignComponent} from './basicForm/paper-design/paper-design.component';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {HulkConfigRadioComponent} from './hulkConifg/hulk-config-radio/hulk-config-radio.component';
import {HulkConfigTextareaComponent} from './hulkConifg/hulk-config-textarea/hulk-config-textarea.component';
import {PoplistEditComponent} from './basicComponent/poplist-edit/poplist-edit.component';
import {InputBidComponent} from './project/jieli/input-bid/input-bid.component';
import {RankBidComponent} from './project/jieli/rank-bid/rank-bid.component';
import {RankBidAdminComponent} from './project/jieli/rank-bid-admin/rank-bid-admin.component';
import {PrintForkDispatchComponent} from './project/jieli/print-fork-dispatch/print-fork-dispatch.component';
import {PrintDeliverDispatchComponent} from './project/jieli/print-deliver-dispatch/print-deliver-dispatch.component';
import {PrintStorageDispatchComponent} from './project/jieli/print-storage-dispatch/print-storage-dispatch.component';
import {PrintDispatchComponent} from './project/jieli/print-dispatch/print-dispatch.component';
import {PrintStoDispatchComponent} from "./project/jieli/print-sto-dispatch/print-sto-dispatch.component";
import {OrderSpiltComponent} from './project/jieli/order-spilt/order-spilt.component';
import {GoodsSpiltComponent} from './project/jieli/goods-spilt/goods-spilt.component';
import {StoGoodsSpiltComponent} from './project/jieli/sto-goods-spilts/sto-goods-spilt.component';
import {CutStringPipe} from './pipe/cut-string.pipe';
import {ImportFormComponent} from './basicForm/import-form/import-form.component';
import {DescriptionsEditRowComponent} from './basicComponent/descriptions/descriptions-edit-row/descriptions-edit-row.component';
import {DispatchComponent} from './project/jieli/dispatch/dispatch.component';
import {AcSettlementComponent} from './project/jsb02/ac-settlement/ac-settlement.component';
import { HulkConfigPoplistComponent } from './hulkConifg/hulk-config-poplist/hulk-config-poplist.component';
import { HulkConfigPoplistEditComponent } from './hulkConifg/hulk-config-poplist-edit/hulk-config-poplist-edit.component';
import { MsStorageStrategyComponent } from './project/jieli/ms-storage-strategy/ms-storage-strategy.component';
import { MsDeliverStrategyComponent } from './project/jieli/ms-deliver-strategy/ms-deliver-strategy.component';
import { StoDispatchComponent } from './project/jieli/sto-dispatch/sto-dispatch.component';
import { InputStdSkuComponent } from './basicForm/input-std-sku/input-std-sku.component';
import { ImageListComponent } from './basicComponent/image-list/image-list.component';
import { DescriptionsTableComponent } from './basicComponent/descriptions/descriptions-table/descriptions-table.component';
import { HulkConfigDataBuildComponent } from './hulkConifg/hulk-config-data-build/hulk-config-data-build.component';
import { HulkConfigFileCenterComponent } from './hulkConifg/hulk-config-file-center/hulk-config-file-center.component';
import { PictureUploadSeComponent} from "./basicComponent/picture-upload-se/picture-upload-se.component";
import { HulkConfigTableDesignComponent } from './hulkConifg/hulk-config-table-design/hulk-config-table-design.component';
import { HulkThanosComponent } from './hulkConifg/hulk-thanos/hulk-thanos.component';
import { HulkConfigSdserverComponent } from './hulkConifg/hulk-config-sdserver/hulk-config-sdserver.component';
import { HulkConfigSdClientComponent } from './hulkConifg/hulk-config-sd-client/hulk-config-sd-client.component';
import { HulkConfigTailwindcssComponent } from './hulkConifg/hulk-config-tailwindcss/hulk-config-tailwindcss.component';
import { EditApprovalComponent } from './project/simulationTrain/edit-approval/edit-approval.component';
import { EditBookComponent } from './project/simulationTrain/edit-book/edit-book.component';
import { BriefingPreviewComponent } from './project/simulationTrain/briefing-preview/briefing-preview.component';
import { HulkConfigTinymceComponent } from './hulkConifg/hulk-config-tinymce/hulk-config-tinymce.component';
import { IgWordTemplateComponent } from './project/simulationTrain/ig-word-template/ig-word-template.component';
import { SettlementAmountComponent } from './project/jieli/settlement-amount/settlement-amount.component';
import { StorageSettlementAmountComponent } from './project/jieli/storage-settlement-amount/storage-settlement-amount.component';
import { AiocrComponent } from './project/ai/aiocr/aiocr.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { SeatViewComponent } from './project/jieli/seat-view/seat-view.component';
import { PayFormComponent} from "./basicForm/pay-form/pay-form.component";
import { LoginRoleMenuComponent } from './basicForm/login-role-menu/login-role-menu.component';
import { SaleSeatComponent } from './project/meeting/sale-seat/sale-seat.component';
import { ReportCardComponent } from './project/simulationTrain/report-card/report-card.component';
import {loadingExpenditureComponent} from './basicForm/loading-expenditure/loading-expenditure.component';
import { StdBtnComponent } from './basicComponent/std-btn/std-btn.component';
import {
  generateSettlementStatementComponent
} from "./basicForm/generate-settlement-statement/generate-settlement-statement";

registerLocaleData(zh);

localForage.config({
  'driver': localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  'name': 'antDesign',
  'version': 1.0,
  'size': 4980736,
  'description': 'ant design data center'
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenusComponent,
    TabsComponent,
    ListFormComponent,
    DetailFormComponent,
    ReadOnlyFormComponent,
    CustomFormComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    IndexComponent,
    CommonFilterPipe,
    FuncFilterPipe,
    FormComponent,
    ReportFormComponent,
    ParsonalCenterComponent,
    WorkflowManagementComponent,
    PoplistComponent,
    TextareaComponent,
    InputMultiComponent,
    SelectMultiComponent,
    RichTextEditorComponent,
    MenuBtnComponent,
    MultiPoplistComponent,
    UploadComponent,
    GroupFilterPipe,
    ValidateFormComponent,
    UserRoleComponent,
    MultiPopupJsonComponent,
    RoleMenuComponent,
    RoleCustComponent,
    AuditContractComponent,
    DateRangeComponent,
    SettingColumnsComponent,
    ScriptUploadComponent,
    FormModalComponent,
    ImportComponent,
    SiteReportComponent,
    VisitReportComponent,
    DatasetInsertComponent,
    DatasetDetailComponent,
    TextComponent,
    CollectReportComponent,
    GrabReportComponent,
    DayReleaseComponent,
    TimeComponent,
    PreReleaseComponent,
    DateMonthsComponent,
    FileDescComponent,
    SiteReleaseComponent,
    ModelInsertComponent,
    DataverseInsertComponent,
    DataverseDetailComponent,
    ModelDetailComponent,
    ViewModelComponent,
    ModifyColumnComponent,
    DsMultiPoplistComponent,
    EsRebuildComponent,
    DataversePermissionComponent,
    DatasetPermissionComponent,
    ApplyFileDetailComponent,
    LibraryInfoComponent,
    VisualReportComponent,
    AutoJsonComponent,
    TableModalComponent,
    ColumnGroupComponent,
    ResumeComponent,
    CnResumeComponent,
    EnResumeComponent,
    UploadPictureResumeComponent,
    SelectJsonComponent,
    UploadFileResumeComponent,
    UploadFileResumeNormalComponent,
    OperateTableComponent,
    CustplanModalComponent,
    FuryCockpitComponent,
    DateWeekComponent,
    ReportTeacherComponent,
    NumberOfDoctoralComponent,
    GraduateProgramsComponent,
    NumberOfMasterComponent,
    CommunicationDoctoralComponent,
    ReportTeacherComponent,
    GraduateJobUnitComponent,
    CommunicationMasterComponent,
    CommunicationForeignersComponent,
    GraduateEmploymentComponent,
    ResearchResultsComponent,
    ResearchOutlayComponent,
    InstrumentEquipmentComponent,
    PaperComponent,
    StudentAidInfoComponent,
    TeachingAwardComponent,
    AcademicExchangeComponent,
    ScienceInnovationComponent,
    GraduateJobUnitComponent,
    SubjectTeacherComponent,
    StudentAchievementComponent,
    RateComponent,
    HrComponent,
    SubjectVisualReportComponent,
    YearComponent,
    SubjectConferDegreeIComponent,
    SubjectConferDegreeIIComponent,
    SubjectConferDegreeIIIComponent,
    SubjectConferDegreeIIIIComponent,
    TeachingInfrastructureComponent,
    GraduatePracticeComponent,
    StudentsExamComponent,
    TeacherInformationComponent,
    TeacherTrainingComponent,
    SubjectVisualReportSetComponent,
    AcademicCommunicationComponent,
    MultiPopComponent,
    AcademicCommunicationComponent,
    ResumeChartComponent,
    GrabReleaseComponent,
    EditNewsComponent,
    ModelManagementComponent,
    DrModelDetailComponent,
    ChannelComponent,
    PictureListComponent,
    PictureUploadComponent,
    PictureUploadDigitalComponent,
    PictureEditComponent,
    PictureDetailComponent,
    GroupingListComponent,
    GroupingAddEditComponent,
    GroupingModelComponent,
    GroupingEditComponent,
    WordListComponent,
    AllDrComponent,
    WordAddEditComponent,
    GroupingDetailComponent,
    WordDetailComponent,
    GroupSingleComponent,
    GroupMultipleComponent,
    ResourceGroupManagementComponent,
    ResourceGroupDetailComponent,
    KnowledgeMapManagementComponent,
    FileUploadDigitalComponent,
    UserGroupManagementComponent,
    GroupUserManagementComponent,
    SetPointModalComponent,
    DistributionUserGroupComponent,
    DigitalVisualReportComponent,
    ReportManagementComponent,
    RitchTextResumeComponent,
    PlaceUseDayComponent,
    OnePageReportComponent,
    FutureHighSpeedComponent,
    FutureSignalListComponent,
    InputWagonComponent,
    InputWagonNewComponent,
    InputStorageComponent,
    InputOutboundComponent,
    InputRolloverComponent,
    InputInventoryComponent,
    InputIncomeComponent,
    InputDisbursementComponent,
    WagonFormationComponent,
    DashboardOneComponent,
    PopMapComponent,
    TinymceComponent,
    InputSkuComponent,
    CascaderComponent,
    ArrayFilterPipe,
    DescriptionFormComponent,
    InputServiceComponent,
    InputServiceCheckboxsComponent,
    InputServiceRadiosComponent,
    InputServiceInputComponent,
    InputServiceDescribeComponent,
    InputServiceTableComponent,
    WorkflowManagementComponent,
    CustomerStatementsComponent,
    EsOrderDetailComponent,
    ReadServiceCheckboxsComponent,
    ReadServiceInputComponent,
    ReadServiceRadiosComponent,
    ReadServiceDescribeComponent,
    ReadServiceTableComponent,
    EsOrderFilesComponent,
    SimpleUploadComponent,
    RoleFormComponent,
    WorkbenchFormComponent,
    TreeFormComponent,
    WordTemplateComponent,
    WorkflowBranchComponent,
    WorkflowAddBtnComponent,
    WorkflowCheckManComponent,
    WorkflowCopyManComponent,
    WorkflowStartComponent,
    WorkflowConditionComponent,
    TreeSelectComponent,
    HdbsPipePipe,
    PopAmapComponent,
    TodoCenterComponent,
    RadioComponent,
    DescriptionsComponent,
    DrawerBtnComponent,
    DashboardFormComponent,
    ImagePreviewModuleComponent,
    PrintResultComponent,
    HelpDocumentComponent,
    DescriptionsFileComponent,
    DescriptionsTimelineComponent,
    HulkConfigDetailComponent,
    HulkConfigPoplistEditComponent,
    HulkConfigPoplistComponent,
    HulkConfigPoplistComponentComponent,
    HulkConfigPoplistInsertvalueComponent,
    HulkConfigPoplistModelComponent,
    HulkConfigSelectDisableComponent,
    HulkConfigPoplistTablenameComponent,
    HulkConfigSelectComponent,
    HulkConfigWidthComponent,
    HulkConfigDictionaryComponent,
    HulkConfigPoplistDictionaryComponent,
    HulkConfigListSqlfileComponent,
    HulkConfigDesignListComponent,
    HulkConfigInputComponent,
    HulkConfigDesignConditionComponent,
    HulkConfigNewFileComponent,
    HulkConfigPoplistDictionaryInputComponent,
    HulkConfigDesignReadonlyComponent,
    HulkConfigBatchBtnComponent,
    HulkConfigDesignReadonlyFromvalueComponent,
    SettlementFormComponent,
    HulkConfigCheckboxComponent,
    ThousandthsFormatPipe,
    HulkConfigColumnsComponent,
    HulkConfigMsgTemplateComponent,
    HulkConfigWfMoreInfoComponent,
    HulkConfigDatasourceCheckComponent,
    DescriptionsEditTableComponent,
    DescriptionsEditTableComponent,
    DescriptionsEditInputComponent,
    DescriptionsEditLableComponent,
    DescriptionsEditDateComponent,
    DescriptionsEditSelectComponent,
    DescriptionsEditMoreBtnComponent,
    DescriptionsEditTableInnerComponent,
    DescriptionsGroupRowComponent,
    DescriptionsEditRowComponent,
    PaperDesignComponent,
    HulkConfigRadioComponent,
    HulkConfigTextareaComponent,
    PoplistEditComponent,
    InputBidComponent,
    RankBidComponent,
    RankBidAdminComponent,
    PrintForkDispatchComponent,
    PrintDeliverDispatchComponent,
    PrintStorageDispatchComponent,
    PrintDispatchComponent,
    PrintStoDispatchComponent,
    OrderSpiltComponent,
    GoodsSpiltComponent,
    StoGoodsSpiltComponent,
    CutStringPipe,
    ImportFormComponent,
    DispatchComponent,
    AcSettlementComponent,
    MsStorageStrategyComponent,
    MsDeliverStrategyComponent,
    StoDispatchComponent,
    InputStdSkuComponent,
    DescriptionsTableComponent,
    ImageListComponent,
    HulkConfigDataBuildComponent,
    HulkConfigFileCenterComponent,
    PictureUploadSeComponent,
    HulkConfigTableDesignComponent,
    HulkThanosComponent,
    HulkConfigSdserverComponent,
    HulkConfigSdClientComponent,
    HulkConfigTailwindcssComponent,
    EditApprovalComponent,
    EditBookComponent,
    EditApprovalComponent,
    BriefingPreviewComponent,
    HulkConfigTinymceComponent,
    IgWordTemplateComponent,
    HulkConfigTinymceComponent,
    SettlementAmountComponent,
    StorageSettlementAmountComponent,
    AiocrComponent,
    SeatViewComponent,
    PayFormComponent,
    LoginRoleMenuComponent,
    SaleSeatComponent,
    ReportCardComponent,
    loadingExpenditureComponent,
    StdBtnComponent,
    generateSettlementStatementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    InputTrimModule,
    NgxEchartsModule,
    DragDropModule,
    OverlayModule,
    EditorModule,
    ClipboardModule,
    NgxExtendedPdfViewerModule,
    NgxQRCodeModule,
    BackButtonDisableModule.forRoot(),
    BaiduMapModule.forRoot({ak: 'Cbn7pXN2kX4u0lcpChRnU5bk7h1WgfrF'}),
    UEditorModule.forRoot({
        js: [
          `./assets/ueditor/ueditor.config.js`,
          `./assets/ueditor/ueditor.all.js`,
        ],
        // 默认前端配置项
        options: {
          UEDITOR_HOME_URL: './assets/ueditor/'
        }
      }
    ),
    LazyLoadImagesModule
  ],
  providers: [
    {provide: NZ_I18N, useValue: zh_CN}, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }, CheckLoginGuard, TodoCenterComponent, ReadOnlyFormComponent],
  bootstrap: [AppComponent],
  entryComponents: [
    AuditContractComponent,
    SettingColumnsComponent,
    ScriptUploadComponent,
    FormModalComponent,
    ImportComponent,
    FileDescComponent,
    ViewModelComponent,
    ModifyColumnComponent,
    EsRebuildComponent,
    TableModalComponent,
    ColumnGroupComponent,
    GroupingModelComponent,
    AllDrComponent,
    MultiPopComponent,
    SetPointModalComponent,
    UploadPictureResumeComponent
  ]
})
export class AppModule {
  constructor(private title: Title) {
    const projectData = require('./../environments/environment.json');
    this.title.setTitle(projectData['title']);
  }
}
