import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PoplistComponent} from "../../basicComponent/poplist/poplist.component";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _ from "underscore";

@Component({
    selector: 'app-hulk-config-poplist-component',
    templateUrl: './hulk-config-poplist-component.component.html',
    styleUrls: ['./hulk-config-poplist-component.component.scss']
})
export class HulkConfigPoplistComponentComponent implements OnInit {
    @Input() oneColumn: any;

    // 组件
    componentModel = [
        {
            'code': 'hr',
            'name': '分组线',
        },
        {
            'code': 'cascader',
            'name': '级联选择',
        },
        {
            'code': 'input',
            'name': '输入框',
        },
        {
            'code': 'inputMulti',
            'name': '多行输入框',
        },
        {
            'code': 'text',
            'name': '文本框',
        }, {
            'code': 'textarea',
            'name': '大文本框',
        }, {
            'code': 'singleTextarea',
            'name': '富文本框',
        }, {
        'code': 'tinymce',
        'name': '富文本框',
      },{
            'code': 'picture',
            'name': '图片上传',
        }, {
            'code': 'file',
            'name': '文件上传',
        },
        {
            'code': 'select',
            'name': '下拉框',
        }
        ,
        {
            'code': 'poplist',
            'name': '弹出框',
        },
        {
            'code': 'multiSelect',
            'name': '多选下拉框',
        }, {
            'code': 'multipop',
            'name': '多选弹出框',
        },
        {
            'code': 'date',
            'name': '日期',
        },
        {
            'code': 'datetime',
            'name': '日期时间',
        },
        {
            'code': 'dateRange',
            'name': '日期起止框',
        },
        {
            'code': 'dateTimeRange',
            'name': '日期时间起止框',
        },
        {
            'code': 'time',
            'name': '时分秒',
        },
        {
            'code': 'dateMonths',
            'name': '月份',
        },
        {
            'code': 'popMap',
            'name': '百度地图',
        }
    ];

    constructor() {
    }

    // 是否显示弹框
    public isVisible = false;


    // 弹出框
    showModal() {
        this.isVisible = true;
    }

    // 单选框 点击行事件
    selectData(data) {
        this.oneColumn['component'] = data['code'];
        if (this.oneColumn['component'] == 'hr') this.oneColumn['column'] = 'HR';
        this.isVisible = false;
    }


    //清空poplist值
    removeValue(): void {
        this.oneColumn['component'] = "input"
    }

    ngOnInit() {
    }

}
