import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestDataService} from '../../../service/request-data.service';
import {DataService} from '../../education/data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {UEditorComponent} from 'ngx-ueditor';

declare const UE: any;

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {
  @Input() parentData: any;
  required = false;
  data: any = {
    REPORT_TITLE: '',
    DATA_SOURCE_NAME: '',
    REPORT_SPECIFIC_SOURCE: '',
    ORIGINAL_LINK: '',
    REPORT_AUTHOR: '',
    RELEASE_AREA: '',
    RELEASE_TIME: null,
    REPORT_CONTENT: '',
    REPORT_KEYWORDS: '',
    DOMAIN_PROJECT_ID: '',
    EMOTIONAL_ATTRIBUTES: '',
    SEARCH_WORD_NAME: '',
    ADD_TIME: null,
    ADD_NAME: ''
  };
  public _validateForm: FormGroup;
  domainProjectColumn = {
    'column': 'DOMAIN_PROJECT_ID',
    'name': 'DOMAIN_PROJECT_ID_NAME',
    'component': 'poplist',
    'lable': '领域专题',
    'comment': '领域专题',
    'placeholder': '请输入领域专题',
    'modelName': '',
    'model': null,
    'visible': true,
    'require': true,
    'dictionary': 'education/basic/dict/DOMAIN_PROJECT.json',
    'dictionaryPF': null
  };

  constructor(private formBuilder: FormBuilder,
              private requestData: RequestDataService,
              private dataService: DataService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  gotoBack() {
    this.transformData('customForm', this.parentData);
  }

  doSave() {
    if (this._validateForm.invalid) {
      for (const key in this._validateForm.controls) {
        this._validateForm.controls[key].markAsDirty();
        this._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (this._validateForm.valid) {
      this.required = true;
      this.data['DOMAIN_PROJECT_ID'] = this.domainProjectColumn.model;
      if (!this.utils.Base_HasValue(this.data['REPORT_TITLE']) ||
        !this.utils.Base_HasValue(this.data['DATA_SOURCE_NAME']) ||
        !this.utils.Base_HasValue(this.data['ORIGINAL_LINK']) ||
        !this.utils.Base_HasValue(this.data['REPORT_CONTENT']) ||
        !this.utils.Base_HasValue(this.data['DOMAIN_PROJECT_ID'])) {
        return;
      }
      this.dataService.updateReport(this.data).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '保存成功'});
          this.gotoBack();
        }
      });
    }
  }

  _ready(event) {
    setInterval(() => {
      if (event.Instance) {
        event.Instance.setHeight(400);
      }
    }, 10);
  }

  onPreReady(comp: UEditorComponent) {
    UE.registerUI('autoclearstyle', function (editor, uiName) {
      //注册按钮执行时的command命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand: function () {
        }
      });
      //创建一个button
      const btn = new UE.ui.Button({
        //按钮的名字
        name: 'autoclearstyle',
        //提示
        title: '自动生成样式',
        //添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-position: -200px 0;',
        //点击时执行的命令
        onclick: function () {
          editor.execCommand('autotypeset');
          editor.execCommand('selectall'); //选中所有
          editor.execCommand('removeformat'); //清除样式
          editor.execCommand('unlink'); //清除链接
          editor.execCommand('fontfamily', '微软雅黑');
          editor.execCommand('rowspacing', 15, 'top'); //上间距
          editor.execCommand('rowspacing', 25, 'bottom'); //下间距
          editor.execCommand('lineheight', 2);
          editor.execCommand('indent'); //首行缩进
        }
      });
      //当点到编辑内容上时，按钮要做的状态反射
      editor.addListener('selectionchange', function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });
      //因为你是添加button,所以需要返回这个button
      return btn;
    }, 5, comp.id);
    // comp.id 是指当前UEditor实例Id
  }


  private addOneForm(): FormGroup {
    let group = {};
    group['DOMAIN_PROJECT_ID_NAME'] = new FormControl('', [Validators.required]);
    const validateForm = this.formBuilder.group(group);
    return validateForm;
  }

  ngOnInit() {
    this._validateForm = this.addOneForm();
    this.requestData.getDataByCondition('education/edu/edu_report_detail.json', this.parentData.detailCondition).subscribe(response => {
      this.data = response['data'][0];
      this.domainProjectColumn.model = this.data['DOMAIN_PROJECT_ID'];
      this.domainProjectColumn.modelName = this.data['DOMAIN_PROJECT_ID_DICT'];
    });
  }

}
