import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';

@Component({
  selector: 'app-all-dr',
  templateUrl: './all-dr.component.html',
  styleUrls: ['../grouping-add-edit/grouping-add-edit.component.css', './all-dr.component.css']
})
export class AllDrComponent implements OnInit {
  @Input() parentData;
  showModelImg = false;
  showModelImgUrl = '';

  dataList = [];

  constructor(private requestData: RequestDataService, private utils: UtilsService) {
  }

  previewImg(pImgUrl) {
    this.showModelImg = true;
    this.showModelImgUrl = pImgUrl;
  }

  ngOnInit() {
    this.requestData.getDataByCondition('digitalResources/dr/dr_group_list.json', {RESOURCES_ID: this.parentData.RESOURCES_ID}).subscribe(response => {
      const data = response.data[0];
      const jsonP = JSON.parse(data['RESOURCES_JSON']);
      if (this.utils.Base_HasValue(jsonP.childResourcesIds)) {
        const ids = [];
        _.each(jsonP.childResourcesIds, oneData => {
          ids.push(oneData['resourcesId']);
        });
        this.requestData.getDataByCondition('digitalResources/dr/dr_group_edit.json', {RESOURCES_ID: ids}).subscribe(resp => {
          this.dataList = resp.data;
          for (let oneData of this.dataList) {
            for (let item of jsonP.childResourcesIds) {
              if (oneData['resourceId'] === item['resourcesId']) {
                oneData['order'] = item['order'];
              }
            }
            this.dataList = _.sortBy(this.dataList, (item) => {
              return +item['order'];
            });
          }
        });
      }
    });
  }

}
