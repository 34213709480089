import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as _ from 'underscore';
import {DataCenterService} from '../../../service/data-center.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {UtilsService} from '../../../service/utils.service';
import {ListActionService} from '../../../service/list-action.service';
import {NzModalService} from 'ng-zorro-antd';
import {SettingColumnService} from '../../../service/setting-column.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as moment from 'moment';
import {DataService} from '../data.service';

@Component({
  selector: 'app-grouping-model',
  templateUrl: './grouping-model.component.html',
  styleUrls: ['./grouping-model.component.css']
})
export class GroupingModelComponent implements OnInit {
  @Input() fileList;
  @Input() type;

  private allChecked = false;
  private indeterminate = false;

  public allListColChecked = true;
  public listColIndeterminate = false;

  checkedAll = false;

  labelsList = [];

  conditionColumn: any = {
    input: {
      'pageNo': 1,
      'pageSize': 12,
      'sort': {
        'uploadTime': 'desc'
      },
      'multiMatch': '',
      'mustFilter': {
        'siteId-keyword': [],
        'resourcesType-keyword': []
      },
      'shouldFilter': {
        'channelId-keyword': []
      },
      'mustWildcardFilter': {
        'labels-keyword': [],
        'resourcesName-keyword': []
      },
      'mustNotFilter': {
        'resourcesState-keyword': ['NO_PASS'],
        'resourcesType-keyword': ['GROUP', 'WORD']
      }
    }
  };

  listData = [];
  nowCount = 0;
  page = {
    pageSize: 12,
    nowPage: 1,
    totalRows: 0
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  resourcesType = []; // 资源类型
  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private listAction: ListActionService,
              private modalService: NzModalService,
              private settingSerive: SettingColumnService,
              private confirmModal: ConfirmModalService,
              private dataService: DataService) {
  }

  refreshStatus(): void {
    const validData = this.listData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.listData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  /**
   * 过滤调用方法
   */
  searchFilter() {
    this.searchData();
  }

  /**
   * 清除条件调用方法
   */
  resetFilter() {
    this.conditionColumn = {
      input: {
        'pageNo': 1,
        'pageSize': 10000,
        'sort': {
          'uploadTime': 'desc'
        },
        'multiMatch': '',
        'mustFilter': {
          'resourcesState-keyword': [],
          'siteId-keyword': [],
          'resourcesType-keyword': []
        },
        'shouldFilter': {
          'channelId-keyword': []
        },
        'mustWildcardFilter': {
          'labels-keyword': [],
          'resourcesName-keyword': []
        },
        'mustNotFilter': {
          'resourcesState-keyword': ['NO_PASS'],
          'resourcesType-keyword': ['GROUP', 'WORD'],
        }
      }
    };
    this.searchData();
  }

  // 修改标题全选框选中状态
  updateCheckAndIndeter(model): void {
    this.allListColChecked = model.checked;
    this.listColIndeterminate = model.indeterminate;
  }

  changeChannel(pData) {
    if (!this.utils.Base_HasValue(pData)) {
      this.conditionColumn.input.shouldFilter['channelId-keyword'] = [];
    }
  }

  pageSearchData(n) {
    this.conditionColumn.input.pageNo = n;
    this.searchData();
  }

  pageSizeChange(n) {
    this.conditionColumn.input.pageSize = n;
    this.searchData();
  }

  // 过滤
  searchData(): void {
    if (this.utils.Base_HasValue(this.conditionColumn.input.shouldFilter['channelId-keyword'])) {
      const channelIds = [];
      this.getChildChannel(this.conditionColumn.input.shouldFilter['channelId-keyword'][0], channelIds, this.channelList);
      this.conditionColumn.input.shouldFilter['channelId-keyword'] = channelIds;
    }
    this.dataService.columnsDataSearch(this.conditionColumn).subscribe((data: any) => {
      this.checkedAll = false;
      this.listData = data.listDatas;
      this.page = data.pageDatas;
      this.nowCount = this.listData.length;
      _.each(this.listData, oneData => {
        oneData['uploadTime'] = moment(oneData['uploadTime']).format('YYYY-MM-DD');
        oneData['labels'] = oneData['labels'].replace(/；/g, '、');
        _.each(this.resourcesType, item => {
          if (item.code === oneData['resourcesType']) {
            oneData['resourcesTypeDict'] = item.name;
          }
        });
      });
      _.each(this.fileList, oneData => {
        _.each(this.listData, item => {
          if (oneData['resourceId'] === item['resourceId']) {
            item['checked'] = true;
          }
        });
      });
    });
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestDataService.getDataByCondition('digitalResources/dict/CHANNEL_NAME_SHOW.json', {SITE_ID: pSiteId, CHANNEL_STATE:'ENABLE'}).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  getChildsChannelId(pMenus, pChildChannelIds) {
    for (const oneMenu of pMenus) {
      pChildChannelIds.push(oneMenu['key']);
      if (this.utils.Base_HasValue(oneMenu['children'])) {
        this.getChildsChannelId(oneMenu['children'], pChildChannelIds);
      }
    }
    return pChildChannelIds;
  }

  getChildChannel(pChannelId, pChildChannelIds, pMenus) {
    for (const oneMenu of pMenus) {
      if (oneMenu['key'] === pChannelId) {
        pChildChannelIds.push(pChannelId);
        if (this.utils.Base_HasValue(oneMenu['children'])) {
          this.getChildsChannelId(oneMenu['children'], pChildChannelIds);
        }
      } else {
        if (this.utils.Base_HasValue(oneMenu['children'])) {
          this.getChildChannel(pChannelId, pChildChannelIds, oneMenu['children']);
        }
      }
    }
  }

  ngOnInit() {
    const parallel$ = observableForkJoin(
      this.requestDataService.getConditionData('digitalResources/dr/dr_picture_list.json'),
      this.requestDataService.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestDataService.getDataByCondition('digitalResources/dict/RESOURCES_TYPE.json')
    );
    parallel$.subscribe(
      values => {
        this.listData = values[0]['data'];
        this.siteList = values[1]['data'];
        this.resourcesType = values[2]['data'];
      }
    );
  }

}
