import {Component, Input, OnInit} from '@angular/core';
import * as _sh from "lodash";
import {UtilsService} from "../../service/utils.service";

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {

  @Input() imageList;
  processed : Boolean = false;
  processedUrl : String = "";

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.imageList)) {
      try {
        let processedImageList = JSON.parse(this.imageList);
        this.processed = true;
        this.processedUrl = processedImageList;
        _sh.each(this.processedUrl,onePic=> {
          onePic['fileType'] = this.utils.Base_getFileType(onePic['name']);
        });
      } catch (e) {
        this.processed = true;
        this.processedUrl = this.imageList;
      }
    }
  }

}
