import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as alasql from 'alasql';
import * as _ from 'underscore';

@Component({
  selector: 'app-communication-doctoral',
  templateUrl: './communication-doctoral.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './communication-doctoral.component.css']
})
export class CommunicationDoctoralComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [
      {
        '总人数': this.listData[0]['TOTAL_NUM'] || 0,
        '获国际组织或机构资助（人数）': this.listData[0]['INTERNATION_MONEY'] || 0,
        '获国家留学基金委资助（人数）': this.listData[0]['COUNTRY_MONEY'] || 0,
        '获其它方式资助（人数）': this.listData[0]['OTHER_MONEY'] || 0,
        '无资助（人数）': this.listData[0]['NO_MONEY'] || 0,
        '亚洲其它国家/地区（人数）': this.listData[0]['ASIA_NUM'] || 0,
        '北美洲（人数）': this.listData[0]['NORTH_AMERICA_NUM'] || 0,
        '南美洲（人数）': this.listData[0]['SOUTH_AMERICA_NUM'] || 0,
        '欧洲（人数）': this.listData[0]['EUROPE_NUM'] || 0,
        '大洋洲（人数）': this.listData[0]['OCEANIA_NUM'] || 0,
        '非洲（人数）': this.listData[0]['AFRICA_NUM'] || 0
      }
    ];
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年博士研究生赴境外学习交流',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-5-1 近5年博士研究生赴境外学习交流.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.requestDataService.getDataByCondition('subjectInfo/report/report_student_abroad_study_doctor.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe(response => {
      this.listData = response.data;
    });
  }

}
