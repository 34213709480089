import {Component, Input, OnInit} from '@angular/core';
import {AjaxService} from '../../../service/ajax.service';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {UserInfoService} from '../../../service/user-info.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'lodash';
import * as alasql from 'alasql';
import {DataService} from '../data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd';
import {GroupingModelComponent} from '../grouping-model/grouping-model.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';

@Component({
  selector: 'app-resource-group-management',
  templateUrl: './resource-group-management.component.html',
  styleUrls: ['./resource-group-management.component.css']
})
export class ResourceGroupManagementComponent implements OnInit {
  //父级组件的数据对象
  @Input() parentData: any;
  labelsList = [];
  data: any = {
    resourcesName: '',
    siteId: '',
    channelId: '',
    labels: null,
    modelId: '',
    resourcesPicUrl: '',
    childResourcesIds: [],
    fileList: []
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;

  showModelImg = false;
  showModelImgUrl = '';
  basicModelList = [];

  tempArr = [];

  addModel = false;

  modelList = [];
  nowModel = {
    code: ''
  };
  models: any = [
    {modelName: null}
  ]; // 扩展模型
  _validateForm: FormGroup;
  selectedIndex = 0;

  constructor(private requestData: RequestDataService,
              private dataService: DataService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService,
              private formBuilder: FormBuilder,
              private modalService: NzModalService) {
  }

  // 选择基础模型
  addBasicModal() {
    if (!this.utils.Base_HasValue(this.data.modelId)) {
      return;
    }
    this.models = [];
    this.dataService.searchModel({modelId: this.data.modelId}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        this.models[0] = response;
        this.nowModel = response;
        this.transformModifyData();
        this.models = this.initModelData(this.models);
        this._validateForm = this.addOneForm();
      }
      this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json', {BASIC_MODEL_ID: this.models[0]['modelId']}).subscribe(response => {
        this.modelList = response['data'] || [];
      });
    });
  }

  deleteOneModel(pModel) {
    if (pModel && pModel['modelType'] === 'basic') {
      this.confirmModal.show('warning', {title: '当前模型为基础模型,不能被删除!'});
      return false;
    }
    this.confirmModal.show('confirm', {
      title: '您确定删除该模型吗？', suc: () => {

        const index = this.models.indexOf(pModel);
        // 如果是选中后删除 则最后一个删除后index-1
        if (this.selectedIndex === index) {
          // 如果是删除掉最后一个tab则选中删除后的最后一个tab
          if (this.selectedIndex === this.models.length - 1) {
            this.selectedIndex = this.selectedIndex - 1;
          }
        } else if (this.selectedIndex > index) { //在当前选中tab前面删除则当前dataINdex -1 nowMenu
          this.selectedIndex = this.selectedIndex - 1;
        }
        this.models.splice(index, 1);
        for (const oneData of this.modelList) {
          if (oneData['modelId'] === pModel['modelId']) {
            oneData.checked = false;
          }
        }
        if (this.models.length) {
          this.nowModel = this.models[this.selectedIndex];
        } else {
          this.selectedIndex = 0;
          this.nowModel = {
            code: ''
          };
        }
        // this.models = _.without(this.models, pModel);
      }
    });
  }

  // 切换扩展模型
  checkModel(oneData) {
    this.nowModel = oneData;
    const index = _.findIndex(this.models, (item) => {
      return item['modelId'] === this.nowModel['modelId'];
    });
    this.selectedIndex = index;
  }

  transformModifyData() {
    _.each(this.models, list => {
      _.each(list['groups'], group => {
        _.each(group['columns'], column => {
          if (!this.utils.Base_HasValue(column['model'])) {
            column['model'] = null;
          }
        });
      });
    });
  }

  handleCancel(): void {
    this.addModel = false;
  }

  handleOk(): void {
    let ids = '';
    _.each(this.modelList, oneData => {
      if (oneData['checked']) {
        let ok = true;
        _.each(this.models, item => {
          if (oneData['code'] === item['modelId']) {
            ok = false;
          }
        });
        if (ok) {
          ids += oneData['code'] + '；';
        }
      }
    });
    if (ids.length < 1) {
      this.addModel = false;
      return;
    }
    ids = ids.slice(0, ids.length - 1);
    this.dataService.searchModel({modelId: ids}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        if (_.isArray(response)) {
          this.models = this.models.concat(response);
        } else {
          this.models.push(response);
        }
        this.transformModifyData();
        this.models = this.initModelData(this.models);
        this._validateForm = this.addOneForm();
      }
    });
    this.addModel = false;
  }

  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    const validatorList = [];
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    // if (this.utils.Base_HasValue(oneColumn.maxLength)) {
    //   validatorList.push(Validators.maxLength(oneColumn.maxLength));
    // }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    return new FormControl('', validatorList);
  }

  private createItem(group): FormGroup {
    const arrayGroup = {};
    _.each(group['columns'], column => {
      arrayGroup[column['column']] = this.addOneColumn(column);
    });
    return this.formBuilder.group(arrayGroup);
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    const group = {};
    _.each(this.models, model => {
      _.each(model['groups'], (item) => {
        const array = [];
        // 如果是文件上传需要将column添加到form里
        // 其他类型则按照data添加
        array.push(this.createItem(item));
        group[item['groupId']] = this.formBuilder.array(array);
      });
    });
    return this.formBuilder.group(group);
  }

  // 初始化数据。将group某个组没有data的默认给上值
  initModelData(modelList) {
    _.each(modelList, (model, index) => {
      if (index === 0) {
        model['active'] = true;
      }
      _.each(model['groups'], group => {
        // if (!this.utils.Base_HasValue(group['data']) && !_.contains(['pictureModel', 'fileModel'], group['type'])) {
        //   const data = {};
        //   _.each(group['columns'], column => {
        //     data[column['column']] = column['component'] === 'select' ? null : '';
        //   });
        //   data['row'] = 0;
        //   group['data'] = [data];
        // }
        _.each(group['columns'], column => {
          column['name'] = column['column'];
          column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
          switch (column['component']) {
            case 'input':
            case 'textarea':
              column['placeholder'] = '请输入' + column['columnName'];
              break;
            case 'date':
            case 'datetime':
            case 'poplist':
            case 'multipop':
            case 'multiSelect':
              if (column['column'] === 'keywordsName') {
                column['nzMode'] = 'tags';
              } else {
                column['nzMode'] = 'multiple';
              }
            case 'select':
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              column['placeholder'] = '请选择' + column['columnName'];
              column['comment'] = column['columnName'];
              column['lable'] = column['columnName'];
              if (column['columnGroup']) {
                _.each(column['columnGroup'], col => {
                  col['comment'] = col['columnName'];
                  col['lable'] = col['columnName'];
                });
              }
              break;
            case 'dateRange':
              column['placeholder'] = column['columnName'].split(',');
              column['comment'] = group['groupName'];
              column['lable'] = group['groupName'];
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              break;
          }
        });
      });
    });
    return modelList;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      for (let i = 0; i < this.tempArr.length; i++) {
        // 小于6就从下个数组拿一个对象来
        if (this.tempArr[i]['key'].length < 6 && i !== this.tempArr.length - 1 && this.tempArr[i + 1]['key'].length > 1) {
          let nextObj = this.tempArr[i + 1]['key'].shift();
          this.tempArr[i]['key'].push(nextObj);
        } else if (this.tempArr[i]['key'].length > 6 && i !== this.tempArr.length - 1) {
          // 大于6就拿一个对象去下一个数组
          let nowObj = this.tempArr[i]['key'].pop();
          this.tempArr[i + 1]['key'].unshift(nowObj);
        }
      }
    }
  }


  manageTempArr() {
    if (this.utils.Base_HasValue(this.data.fileList)) {
      let lengthArr = this.data.fileList.length;
      let sizeNum = Math.ceil(lengthArr / 6);
      let start = 0;
      let end = 6;
      this.tempArr = [];
      for (let i = 0; i < sizeNum; i++) {
        let obj = {};
        obj['key'] = this.data.fileList.slice(start, end);
        this.tempArr.push(obj);
        start += 6;
        end += 6;
      }
    }
  }

  manageFileList() {
    if (this.utils.Base_HasValue(this.tempArr)) {
      this.data.fileList = [];
      for (let oneArr  of this.tempArr) {
        for (let one of oneArr['key']) {
          this.data.fileList.push(one);
        }
      }
    }
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME_SHOW.json', {
      SITE_ID: pSiteId,
      CHANNEL_STATE: 'ENABLE'
    }).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        for (const oneData of this.channelList) {
          if (oneData['code'] === this.data.channelId) {
            oneData['selected'] = true;
          }
        }
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  // 返回
  gotoBack(): void {
    this.transformData('customForm', this.parentData);
  }

  // 保存
  doSave() {
    this.required = true;
    if (!this.utils.Base_HasValue(this.data.resourcesName) ||
      !this.utils.Base_HasValue(this.data.siteId) ||
      !this.utils.Base_HasValue(this.data.channelId) ||
      !this.utils.Base_HasValue(this.data.modelId)) {
      return;
    }
    let ok = true;
    _.each(this.models, oneData => {
      _.each(oneData.groups, group => {
        if (group.type === 'from') {
          _.each(group.columns, column => {
            if (column.require && !this.utils.Base_HasValue(column.model) && column.canEdit === 1) {
              column['error'] = true;
              ok = false;
            }
          });
        }
        if (group.type === 'list') {
          let data = [];
          _.each(group.columns, column => {
            if (column.require && column.canEdit === 1) {
              data.push(column.column);
            }
          });
          _.each(group.data, item => {
            _.each(data, str => {
              if (!this.utils.Base_HasValue(item[str])) {
                ok = false;
              }
            });
          });
        }
      });
    });
    if (!ok) {
      return this.confirmModal.show('warning', {title: '您有必填项没有填写，请填写完整保存。'});
    }
    this.data['models'] = this.models;
    const data = {
      data: this.utils.DeepCopy(this.data)
    };
    delete data.data.fileList;
    delete data.data.childResourcesIds;
    data.data = JSON.stringify(data.data);
    this.dataService.classifyResourceSave(data).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {
          title: '温馨提示',
          content: '保存成功',
          suc: () => {
            this.parentData.needSearch = true;
            this.transformData('customForm', this.parentData);
          }
        });
      }
    });
  }

  // 获取资源id
  childResourcesIds() {
    const ids = [];
    let i = 0;
    for (let oneArr  of this.tempArr) {
      for (let oneData of oneArr['key']) {
        ids.push(
          {
            resourcesId: oneData['resourceId'],
            order: i++
          });
      }
    }
    this.data.childResourcesIds = ids;
  }

  previewImg(pImgUrl) {
    this.showModelImg = true;
    this.showModelImgUrl = pImgUrl;
  }

  deleteImg(pOneData) {
    for (let oneArr  of this.tempArr) {
      for (let oneData of oneArr['key']) {
        if (oneData['resourceId'] === pOneData['resourceId']) {
          oneArr['key'] = _.without(oneArr['key'], oneData);
        }
      }
    }
    if (pOneData.selected) {
      this.data.resourcesPicUrl = '';
    }
    this.childResourcesIds();
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-grouping-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 根据当前数据json内的modelId去查出模型，把JSON里的值合并到查出来的模型里。这样做为了避免后面模型有修改
  groupModelValue(modelList) {
    function marageModel(pResponse) {
      _.each(pResponse, list => {
        _.each(list['groups'], group => {
          _.each(group['columns'], column => {
            _.each(modelList, itemList => {
              if (itemList['modelId'] === list['modelId']) {
                _.each(itemList['groups'], itemGroup => {
                  if (itemGroup['groupId'] === group['groupId'] && group['type'] === 'form') {
                    _.each(itemGroup['columns'], itemColumn => {
                      if (itemColumn['modelColumnId'] === column['modelColumnId']) {
                        column['model'] = itemColumn['model'];
                      }
                    });
                  }
                  if (itemGroup['groupId'] === group['groupId'] && group['type'] === 'list') {
                    group['data'] = itemGroup['data'];
                  }
                });
              }
            });
          });
        });
      });
      return pResponse;
    }

    let ids = '';
    _.each(modelList, oneData => {
      ids += oneData['modelId'] + '；';
    });
    ids = ids.slice(0, ids.length - 1);
    this.dataService.searchModel({modelId: ids}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        if (_.isArray(response)) {
          this.models = marageModel(response);
        } else {
          this.models = marageModel([response]);
        }
      }
      this.transformModifyData();
      this.models = this.initModelData(this.models);
      this.nowModel = this.models[0];
      this.selectedIndex = 0;
      this._validateForm = this.addOneForm();
      this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json', {BASIC_MODEL_ID: this.models[0]['modelId']}).subscribe(response => {
        this.modelList = response['data'] || [];
      });
    });
  }

  modifyDataFormat(data) {
    const jsonP = JSON.parse(data['RESOURCES_JSON']);
    this.data = {
      resourceId: data['ID'],
      resourcesName: data['RESOURCES_NAME'],
      siteId: data['SITE_ID'],
      channelId: data['CHANNEL_ID'],
      labels: data['RESOURCES_LABEL'],
      modelId: jsonP.models[0]['modelId'],
      resourcesPicUrl: data['RESOURCES_PIC_URL']
    };
    this.groupModelValue(jsonP.models);
    this.searchChannel(this.data.siteId);
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json', {RESOURCES_TYPE: 'GROUP'})
    );
    search.subscribe(
      values => {
        this.siteList = values[0]['data'] || [];
        this.basicModelList = values[1]['data'] || [];
        if (this.parentData.modeType === 'modify') {
          this.requestData.getDataByCondition('digitalResources/dr/dr_group_list.json', {RESOURCES_ID: this.parentData.RESOURCES_ID}).subscribe(response => {
            const data = response.data[0];
            this.modifyDataFormat(data);
          });
        }
      }
    );
  }

}
