import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestDataService} from '../../../service/request-data.service';
import {DataService} from '../data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'underscore';
import {AllDrComponent} from '../all-dr/all-dr.component';
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-grouping-detail',
  templateUrl: './grouping-detail.component.html',
  styleUrls: ['../picture-edit/picture-edit.component.css','./grouping-detail.component.css']
})
export class GroupingDetailComponent implements OnInit {

  @Input() parentData: any;
  labelsList = [];
  data: any = {
    resourcesType: 'GROUP',
    siteId: '',
    channelId: '',
    labels: null,
    fileList: []
  };
  mysqlData:any = {};
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;
  modelList = [];
  nowModel = {
    code: ''
  };
  addModel = false;
  models: any = [
    {modelName: null}
  ]; // 扩展模型
  picUrl = '';

  _validateForm: FormGroup;
  basicModel = [];
  selectedIndex = 0;
  constructor(
    private formBuilder: FormBuilder,
    private requestData: RequestDataService,
    private dataService: DataService,
    private dataCenterService: DataCenterService,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService,
    private modalService: NzModalService) {
  }

  // 查看全部资源
  gotoAllResources(): void {
    const data = {
      parentData: this.parentData
    };
    this.modalService.create({
      nzTitle: '资源列表',
      nzContent: AllDrComponent,
      nzComponentParams: data,
      nzWidth: 1200,
      nzMaskClosable: false,
      nzClosable: true,
      nzOkText: null,
      nzCancelText: '关闭'
    });
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME.json', {SITE_ID: pSiteId}).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        for (let oneData of this.channelList) {
          if (oneData['code'] === this.data.channelId) {
            oneData['selected'] = true;
          }
        }
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }
  // 切换扩展模型
  checkModel(oneData) {
    this.nowModel = oneData;
    let index = _.findIndex(this.models, (item) => {
      return item['modelId'] === this.nowModel['modelId'];
    });
    this.selectedIndex = index;
  }

  gotoBack() {
    this.transformData('customForm', this.parentData, 'app-grouping-list');
  }

  transformData(pFormType, parentData, custormType, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = custormType;
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }
  transformModifyData() {
    _.each(this.models, list => {
      _.each(list['groups'], group => {
        _.each(group['columns'], column => {
          if (!this.utils.Base_HasValue(column['model'])) {
            column['model'] = null;
          }
        });
      });
    });
  }
  // 根据当前数据json内的modelId去查出模型，把JSON里的值合并到查出来的模型里。这样做为了避免后面模型有修改
  groupModelValue(modelList) {
    function marageModel(pResponse) {
      _.each(pResponse, list => {
        _.each(list['groups'], group => {
          _.each(group['columns'], column => {
            _.each(modelList, itemList => {
              if (itemList['modelId'] === list['modelId']) {
                _.each(itemList['groups'], itemGroup => {
                  if (itemGroup['groupId'] === group['groupId']) {
                    _.each(itemGroup['columns'], itemColumn => {
                      if (itemColumn['modelColumnId'] === column['modelColumnId']) {
                        column['model'] = itemColumn['model'];
                      }
                    });
                  }
                });
              }
            });
          });
        });
      });
      return pResponse;
    }
    let ids = '';
    _.each(modelList, oneData => {
      ids += oneData['modelId'] + '；';
    });
    ids = ids.slice(0, ids.length - 1);
    this.dataService.searchModel({modelId: ids}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        if (_.isArray(response)) {
          this.models = marageModel(response);
        } else {
          this.models = marageModel([response]);
        }
      }
      this.transformModifyData();
      this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json', {BASIC_MODEL_ID: this.models[0]['modelId']}).subscribe(response => {
        this.modelList = response['data'] || [];
      });
    });
  }

  dataFormat(pData) {
    this.data = JSON.parse(pData);
    this.nowModel = this.data.models[0];
    this.groupModelValue(this.data.models);
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_group_list.json', {'RESOURCES_ID': this.parentData.RESOURCES_ID}),
      this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json')
    );
    search.subscribe(
      values => {
        this.siteList = values[0]['data'] || [];
        this.basicModel = values[2]['data'] || [0];
        this.mysqlData = values[1]['data'][0] || [];
        this.picUrl = this.mysqlData.RESOURCES_PIC_URL;
        this.dataFormat(this.mysqlData.RESOURCES_JSON);
        this.searchChannel(this.mysqlData['SITE_ID']);
      });
  }

}
