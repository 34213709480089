import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {NzModalService} from 'ng-zorro-antd';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import * as _ from 'lodash';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataCenterService} from '../../../service/data-center.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UserInfoService} from '../../../service/user-info.service';
import * as moment from 'moment';
import {MapOptions} from 'angular2-baidu-map'

@Component({
  selector: 'app-input-wagon',
  templateUrl: './input-wagon.component.html',
  styleUrls: ['./input-wagon.component.css']
})
export class InputWagonComponent implements OnInit {
  @Input() parentData: any;
  today = moment().format('YYYY-MM-DD');

  public saveTips = '';

  public formData: any = {
    'title': '货物运单',
    'type': 'normal',
    'tablename': 'mc_wagon',
    'formname': 'mc_wagon',
    'modalModeType': 'add',
    'changeList': [
      {
        '_comment': '该功能是输入了过磅重（吨）之后默认净重（吨）= 过磅重（吨）',
        'triggerColumn_comment': '触发条件：指的是，当这些字段的值有变化的时候，启动;',
        'triggerColumn': [
          'SYS.mc_wagon.WEIGHING_W'
        ],
        'valueColumn_comment': '满足值条件：启动后，当这些都有值的时候，才执行动作',
        'valueColumn': [
          'SYS.mc_wagon.WEIGHING_W'
        ],
        'execList': [
          {
            'execList_comment': '过磅重（吨），获取值',
            'execType': 'normal',
            'datas': [
              {
                'column': 'WEIGHING_W',
                'fromValue': {
                  'msgTemplate': '<%=WEIGHING_W%>',
                  'msgValue': [
                    {
                      'column': 'WEIGHING_W',
                      'fromValue': 'MODAL.WEIGHING_W',
                      'isNull': 0
                    }
                  ]
                }
              }
            ]
          }
        ],
        'fillback': [
          {
            'toValue': 'SYS.mc_wagon.NET_W',
            'msgTemplate': '<%= NET_W %>',
            'msgValue': [
              {
                'column': 'NET_W',
                'fromValue': 'execList.0.WEIGHING_W',
                'isNull': 0
              }
            ]
          }
        ]
      }
    ],
    'columns': [
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '基础信息',
        'customize': 24,
        'comment': '基础信息',
        'visible': true,
        'disable': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '基础信息',
        'model': null
      },
      {
        'column': 'ID',
        'pk_column': true,
        'iscondition': true,
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'insertValue': 'SYS-UUID',
        'model': null,
        'visible': false,
        'require': true
      },
      {
        'column': 'INOUT_TYPE',
        'name': 'INOUT_TYPE_NAME',
        'component': 'select',
        'lable': '接&发车',
        'comment': '',
        'placeholder': '请选择接&发车',
        'dictionary': 'dfmc/basic/dict/INOUT_TYPE.json',
        'dictionaryPF': null,
        'model': 'ACCEPT',
        'visible': true,
        'require': true
      },
      {
        'column': 'TASK_TYPE',
        'name': 'TASK_TYPE_NAME',
        'component': 'select',
        'lable': '任务属性',
        'comment': '',
        'placeholder': '请选择任务属性',
        'dictionary': 'dfmc/basic/dict/TASK_TYPE.json',
        'dictionaryPF': null,
        'model': 'FULL2EMPTY',
        'visible': true,
        'require': true
      },
      {
        'column': 'TIME_ACCESS_DATE',
        'name': 'TIME_ACCESS_DATE_NAME',
        'component': 'date',
        'lable': '接入日期',
        'comment': '',
        'placeholder': '请选择接入日期',
        'model': this.today,
        'visible': true,
        'require': true
      },
      {
        'column': 'WAGON_TYPE',
        'name': 'WAGON_TYPE_NAME',
        'component': 'select',
        'lable': '车皮属性',
        'comment': '车皮属性',
        'placeholder': '车皮属性',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'CHINARAILWAY',
        'insertValue': 'CHINARAILWAY',
        'visible': true,
        'require': true,
        'dictionary': 'dfmc/basic/dict/WAGON_TYPE.json',
        'dictionaryPF': null
      },
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '方向信息',
        'customize': 24,
        'comment': '方向信息',
        'visible': true,
        'disable': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '方向信息',
        'model': null
      },
      {
        'column': 'CUST_ID',
        'name': 'CUST_ID_NAME',
        'component': 'poplist',
        'lable': '客户信息',
        'comment': '客户信息',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择客户信息',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'CUST_NAME',
            'name': 'CUST_NAME_NAME',
            'component': 'input',
            'lable': '收货人',
            'comment': '收货人',
            'placeholder': '收货人',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'DEPARTURE_ID',
        'name': 'DEPARTURE_ID_NAME',
        'component': 'poplist',
        'lable': '发（到）站',
        'comment': '发（到）站',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入发（到）站',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/DEPARTURE_STATION.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'STATION_CODE',
            'name': 'STATION_CODE_NAME',
            'component': 'input',
            'lable': '发站编号',
            'comment': '发站编号',
            'placeholder': '',
            'fromValue': 'STATION_CODE',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'DEPARTURE_STATION',
            'name': 'DEPARTURE_STATION_NAME',
            'component': 'input',
            'lable': '发站名称',
            'comment': '发站名称',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'STATION_SHORT_NAME',
            'name': 'STATION_SHORT_NAME_NAME',
            'component': 'input',
            'lable': '发站简称',
            'comment': '发站简称',
            'placeholder': '',
            'fromValue': 'STATION_SHORT_NAME',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'STATION_ID_ACCESS',
        'name': 'STATION_ID_ACCESS_NAME',
        'component': 'select',
        'lable': '接入站点',
        'comment': '接入站点',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入站点',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_YESONNO_TRAIN_STATION_FINISH.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'STATION_NAME_ACCESS',
            'name': 'STATION_NAME_ACCESS_NAME',
            'component': 'input',
            'lable': '接入站点',
            'comment': '接入站点',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'STATION_SHORT_ACCESS',
            'name': 'STATION_SHORT_ACCESS_NAME',
            'component': 'input',
            'lable': '列车站点简称',
            'comment': '列车站点简称',
            'placeholder': '',
            'fromValue': 'STATION_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'TRACK_ID_ACCESS',
        'name': 'TRACK_ID_ACCESS_NAME',
        'component': 'poplist',
        'lable': '接入股道',
        'comment': '接入股道',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入股道',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': false,
        'dictionary': 'dfmc/basic/dict/MC_TRACK_POPLIST.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'TRACK_NAME_ACCESS',
            'name': 'TRACK_NAME_ACCESS_NAME',
            'component': 'input',
            'lable': '接入股道',
            'comment': '接入股道',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'TRACK_SHORT_ACCESS',
            'name': 'TRACK_SHORT_ACCESS_NAME',
            'component': 'input',
            'lable': '接入股道简称',
            'comment': '接入股道简称',
            'placeholder': '',
            'fromValue': 'TRACK_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'MAN_ID_ACCESS',
        'name': 'MAN_ID_ACCESS_NAME',
        'component': 'poplist',
        'lable': '接入操作人',
        'comment': '接入操作人',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入操作人',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/SYSTEM_USER_POPLIST.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'MAN_ACCESS',
            'name': 'MAN_ACCESS_NAME',
            'component': 'input',
            'lable': '接入操作人',
            'comment': '接入操作人',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'TIME_ACCESS',
        'name': 'TIME_ACCESS_NAME',
        'component': 'datetime',
        'lable': '接入时间',
        'comment': '接入时间',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请输入接入时间',
        'visible': false,
        'require': false
      },
      {
        'column': 'TIME_ACCESS_TIME',
        'name': 'TIME_ACCESS_TIME_NAME',
        'component': 'time',
        'lable': '接入时间',
        'comment': '',
        'placeholder': '请选择接入时间',
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'TRACK_ID_DISCHARGE',
        'name': 'TRACK_ID_DISCHARGE_NAME',
        'component': 'poplist',
        'lable': '装卸专用线',
        'comment': '装卸专用线',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择装卸专用线',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_TRACK_SPECIAL_POPLIST.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'TRACK_NAME_DISCHARGE',
            'name': 'TRACK_NAME_DISCHARGE_NAME',
            'component': 'input',
            'lable': '专用线',
            'comment': '专用线',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'TRACK_SHORT_DISCHARGE',
            'name': 'TRACK_SHORT_DISCHARGE_NAME',
            'component': 'input',
            'lable': '专用线简称',
            'comment': '专用线简称',
            'placeholder': '专用线简称',
            'fromValue': 'TRACK_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'STATION_ID_RETURN',
        'name': 'STATION_ID_RETURN_NAME',
        'component': 'select',
        'lable': '归还站点',
        'comment': '归还站点',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择归还站点',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_YESONNO_TRAIN_STATION_FINISH.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'STATION_NAME_RETURN',
            'name': 'STATION_NAME_RETURN_NAME',
            'component': 'input',
            'lable': '归还站点',
            'comment': '归还站点',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'STATION_SHORT_RETURN',
            'name': 'STATION_SHORT_RETURN_NAME',
            'component': 'input',
            'lable': '归还站点简称',
            'comment': '归还站点简称',
            'placeholder': '',
            'fromValue': 'STATION_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '重点信息',
        'customize': 24,
        'comment': '重点信息',
        'visible': true,
        'disable': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '重点信息',
        'model': null
      },
      {
        'column': 'WAYBILL_ID',
        'name': 'WAYBILL_ID_NAME',
        'component': 'input',
        'lable': '货运单号',
        'comment': '货运单号',
        'placeholder': '系统自动生成',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'WAGON_CODE',
        'name': 'WAGON_CODE_NAME',
        'component': 'input',
        'lable': '车号',
        'comment': '车号',
        'placeholder': '请输入车号',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'WAGON_PROPERTY',
        'name': 'WAGON_PROPERTY_NAME',
        'component': 'poplist',
        'lable': '车种',
        'comment': '车种',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择车种',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_VEHICLES.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'STANDARD_W',
            'name': 'STANDARD_W_NAME',
            'component': 'input',
            'type': 'number',
            'lable': '标重(吨)',
            'comment': '标重(吨)',
            'placeholder': '标重(吨)',
            'fromValue': 'STANDARD_WEIGHT',
            'model': null,
            'visible': true,
            'require': true
          }
        ]
      },
      {
        'column': 'WAGON_STATE',
        'name': 'WAGON_STATE_NAME',
        'component': 'input',
        'lable': '车皮流程状态',
        'comment': '车皮流程状态',
        'placeholder': '车皮流程状态',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'TRACK',
        'insertValue': 'TRACK',
        'visible': false,
        'require': true,
        'dictionary': 'dfmc/basic/dict/WAGON_STATE.json',
        'dictionaryPF': null
      },
      {
        'column': 'WAGON_RUN_STATE',
        'name': 'WAGON_RUN_STATE_NAME',
        'component': 'input',
        'lable': '车皮行进状态',
        'comment': '车皮行进状态',
        'placeholder': '车皮行进状态',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'TRACK',
        'insertValue': 'TRACK',
        'visible': false,
        'require': true,
        'dictionary': 'dfmc/basic/dict/WAGON_RUN_STATE.json',
        'dictionaryPF': null
      },
      {
        'column': 'WEIGHING_W',
        'name': 'WEIGHING_W_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '过磅重（吨）',
        'comment': '过磅重（吨）',
        'placeholder': '过磅重（吨）',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'NET_W',
        'name': 'NET_W_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '净重（吨）',
        'comment': '净重（吨）',
        'placeholder': '净重（吨）',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'TARPAULIN_ID',
        'name': 'TARPAULIN_ID_NAME',
        'component': 'input',
        'lable': '篷布号',
        'comment': '篷布号',
        'placeholder': '篷布号',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'WAGON_MEMO',
        'name': 'WAGON_MEMO_NAME',
        'component': 'textarea',
        'lable': '备注',
        'comment': '备注',
        'placeholder': '请输入备注',
        'class': 'col-xs-12',
        'model': null,
        'visible': true,
        'require': false
      }
    ]
  };
  data: any = {};
  itemData: any = [];
  duplicateDatas: any = [];
  duplicateVisible = false;

  duplicateCancel() {
    this.duplicateVisible = false;
  }

  public allChecked = false;
  private indeterminate = false;
  public options: MapOptions;


  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {

  }

  private columnsJson: any = [
    {
      'column': 'GOODS_ID',
      'name': 'GOODS_ID_NAME',
      'component': 'poplist',
      'lable': '货物名称',
      'comment': '货物名称',
      'placeholder': '请输入货物名称',
      'insertValue': null,
      'model': null,
      'dictionary': 'dfmc/basic/dict/BASIC_GOODS_WAGON.json',
      'dictionaryPF': null,
      'visible': true,
      'require': true,
      'columnGroup': [
        {
          'column': 'GOODS_NAME',
          'name': 'GOODS_NAME_NAME',
          'component': 'input',
          'lable': '品名NAME',
          'comment': '品名NAME',
          'placeholder': '请输入品名NAME',
          'insertValue': null,
          'fromValue': 'name',
          'model': null,
          'visible': false,
          'require': true
        }
      ]
    },
    {
      'column': 'WAGON_NUMBER',
      'name': 'WAGON_NUMBER_NAME',
      'component': 'input',
      'type': 'number',
      'lable': '数量',
      'comment': '数量',
      'placeholder': '请输入数量',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': true
    },
    {
      'column': 'GOODS_PRICE',
      'name': 'GOODS_PRICE_NAME',
      'component': 'input',
      'type': 'number',
      'lable': '货物价格（元）',
      'comment': '货物价格（元）',
      'placeholder': '请输入货物价格（元）',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'WEIGHT',
      'name': 'WEIGHT_NAME',
      'component': 'input',
      'type': 'number',
      'lable': '重量（吨）',
      'comment': '重量（吨）',
      'placeholder': '请输入重量（吨）',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': true
    }];

  clearItemModel() {
    _.each(this.columnsJson, (oneColumn) => {
      _.set(oneColumn, 'model', null);
      let columnGroup = _.get(oneColumn, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        _.set(oneGroupColumn, 'model', null);
      });
    });
  }

  addItem() {
    this.clearItemModel(); // 清除 this.columnsJson 之上的 model
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': this.columnsJson
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let oneRow = [];
        _.each(data, oneColumn => {
          oneRow[oneColumn['column']] = oneColumn['model'];
          let columnGroup = _.get(oneColumn, 'columnGroup', null);
          _.each(columnGroup, (oneGroupColumn) => {
            oneRow[oneGroupColumn['column']] = _.get(oneGroupColumn, 'model', null);
          });
        });
        this.itemData.push(oneRow);
      }
    });
  }

  // 明细table删除
  itemRemove(): void {
    const checkedList = _.filter(this.itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.itemData = this.itemData.filter((item) => {
            return !item.checked;
          });
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  checkAll(value: boolean): void {
    this.itemData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.itemData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.formData, 'columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatItemToEntity() {
    let entityList: any = [];
    _.each(this.itemData, (oneRow) => {
      let entity: any = {};
      _.each(_.keys(oneRow), (key) => {
        const column = _.camelCase(key);
        const value = _.get(oneRow, key);
        entity[column] = value;
      });
      entityList.push(entity);
    });
    return entityList;
  }

  findOneColumnValue(pColumnName) {
    let columns = _.get(this.formData, 'columns', []); // 找到 columns 层级
    let oneValue = null; // 定义一个返回值，给予默认值为控制

    _.each(columns, (oneOneColum) => { // 循环 columns 层级
      if (oneOneColum['column'] === pColumnName) { // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
        oneValue = _.get(oneOneColum, 'model');
      }
      let columnGroup = _.get(oneOneColum, 'columnGroup', null); // 找到 columnGroup 层级
      _.each(columnGroup, (oneGroupColumn) => {
        if (oneGroupColumn['column'] === pColumnName) {  // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
          oneValue = _.get(oneGroupColumn, 'model', null);
        }
      });
    });
    return oneValue; // 返回值
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput(pCheckItem) {
    let result = true;

    result = this.checkOneColumn('TIME_ACCESS_DATE', '接入日期');
    if (!result) {
      return result;
    }
    result = this.checkOneColumn('WAGON_TYPE', '车皮属性');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('DEPARTURE_ID', '发站');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('CUST_ID', '客户信息');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('TRACK_ID_DISCHARGE', '装卸专用线');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('STATION_ID_RETURN', '归还站点');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('MAN_ID_ACCESS', '接入操作人');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('TIME_ACCESS_TIME', '接入时间');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('WAGON_PROPERTY', '车种');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('WAGON_CODE', '车号');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('STANDARD_W', '标重');
    if (!result) {
      return result;
    }

    result = this.checkOneColumn('WEIGHING_W', '过磅重');
    if (!result) {
      return result;
    }


    result = this.checkOneColumn('NET_W', '净重');
    if (!result) {
      return result;
    }

    let WagonCode = this.findOneColumnValue('WAGON_CODE');
    let StandardW = this.findOneColumnValue('STANDARD_W'); // 标重（吨）
    let WeighingW = this.findOneColumnValue('WEIGHING_W'); // 过磅重（吨）
    let NetW = this.findOneColumnValue('NET_W'); // 净重（吨）

    if (WagonCode.length != 7) {
      this.confirmModal.show('error', {'title': '车号位数错误', 'content': '车号位数只能是7位'});
      result = false;
      return result;
    }

    if (StandardW >= 75) {
      this.confirmModal.show('error', {'title': '重量错误', 'content': '标重（吨），不能超过75吨'});
      result = false;
      return result;
    }

    if (WeighingW >= 75) {
      this.confirmModal.show('error', {'title': '重量错误', 'content': '过磅重（吨），不能超过75吨'});
      result = false;
      return result;
    }

    if (NetW >= 75) {
      this.confirmModal.show('error', {'title': '重量错误', 'content': '净重（吨），不能超过75吨'});
      result = false;
      return result;
    }

    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (pCheckItem) {
      // tslint:disable-next-line:triple-equals
      if (this.itemData.length == 0) {
        this.confirmModal.show('error', {'title': '必填项', 'content': '货物明细数据为必填项'});
        result = false;
        return result;
      }
    }
    return result;
  }


  execSave() {
    if (!this.checkInput(true)) {
      return false;
    }
    const data = {
      'userId': this.userInfoService.getUserInfo().USER_ID,
      'mcWagon': this.formatMainToEntity(),
      'mcWagonDetailList': this.formatItemToEntity()
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McWagonController', 'input', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.saveTips = '数据已经保存成功，可以选择【清除数据】，或者【复制数据】继续下一张车皮录入工作。';
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        this.saveTips = '数据已经保存失败，请联系管理员解决。';
      }
    });
  }

  saveData() {
    const wagonCode = this.findOneColumnValue('WAGON_CODE');
    if (!this.util.Base_HasValue(wagonCode)) {
      this.confirmModal.show('error', {'title': '请输入车号', 'content': '车号，为必填项。'});
    } else {
      const search = observableForkJoin(
        this.requestData.getDataByCondition('dfmc/mc/mc_wagon_duplicate.json', {'WAGON_CODE': wagonCode})
      );
      search.subscribe(
        values => {
          const rowCount = _.get(values, [0, 'page', 'rowCount'], 0);
          if (rowCount > 0) {
            this.duplicateVisible = true;
            this.duplicateDatas = _.get(values, [0, 'data'], 0);
          } else {
            this.execSave(); // 执行保存
          }
        }
      );
    }
  }

  copyData() {
    if (!this.checkInput(false)) {
      return false;
    }
    this.clearItemModel(); // 清除 this.columnsJson 之上的 model
    this.itemData = [];     // 清空明细。
    const removeColumns = [
      {'column': 'WAYBILL_ID'},
      {'column': 'WAGON_PROPERTY'},
      {'column': 'WAGON_CODE'},
      {'column': 'STANDARD_W'},
      {'column': 'WEIGHING_W'},
      {'column': 'NET_W'},
      {'column': 'TARPAULIN_ID'},
    ];
    _.each(this.formData.columns, (oneColumn) => {
      const fIndex = _.findIndex(removeColumns, {'column': oneColumn.column});
      if (fIndex > -1) {
        oneColumn['model'] = null;
      }
    });
    this.saveTips = '复制数据成功，填写完必填项请记得按【保存】按钮。';
  }

  clearData() {
    this.clearItemModel(); // 清除 this.columnsJson 之上的 model
    _.each(this.formData.columns, (oneColumn) => {
      _.set(oneColumn, 'model', null);
      let columnGroup = _.get(oneColumn, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        _.set(oneGroupColumn, 'model', null);
      });
    });
    this.saveTips = '';
  }

  ngOnInit(): void {
    // const search = observableForkJoin(
    //   this.requestData.getDataByCondition('dfmc/mc/mc_wagon_list.json', {WAGON_ID: this.parentData.RESOURCES_ID}),
    // );
    // search.subscribe(
    //   values => {
    //     this.data = values[0]['data'][0];
    //   });
  }
}
