import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
// import {PdfViewerModule} from 'ng2-pdf-viewer';
import * as _un from 'underscore';
import * as _ from 'lodash';

@Component({
    selector: 'app-image-preview-module',
    templateUrl: './image-preview-module.component.html',
    styleUrls: ['./image-preview-module.component.scss']
})
export class ImagePreviewModuleComponent implements OnInit {

    @Input() src: String      // 接受地址
    @Input() title: String    // 接受模态框标题

    public modalVisible = false;        // 模态框可视化
    public isConfirmLoading = false;    // 模态框加载
    public fileType = '';               // 文件类型
    public fileName = '';

    constructor(private utils: UtilsService) {
    }

    ngOnInit() {
        let type = 'other';
        if (this.src) {
            // 1 取文件名后缀
            let suffix = this.utils.Base_getFileType(this.src);

            // 2 判断文件类型
            let picture = ['jpg', 'jpeg', 'png', 'svg', 'eps', 'gif', 'tiff', 'psd', 'raw', 'bmp'];
            let pdf = ['pdf'];
            let word = ['doc', 'docx']
            let excel = ['xls', 'xlsx']

            if (picture.includes(suffix)) {
                type = 'picture'
            } else if (pdf.includes(suffix)) {
                type = 'pdf'
            } else if (word.includes(suffix)) {
                type = 'word'
            } else if (excel.includes(suffix)) {
                type = 'excel'
            }
        }
        this.fileType = type;
        this.fileName = this.utils.Base_getFileNameOnly(this.src);
    }

    // 点击预览图片
    previewImg(src) {
        if (_un.contains(['pdf','word','excel'],this.fileType)) {
            // @ts-ignore
            window.open(this.src);
        } else if (_un.contains(['picture'],this.fileType)) {
            this.modalVisible = true;
        }
    }

    // 点击右上角取消
    modalCancel(){
        this.modalVisible = false;
    }

    // 点击下载
    download(src): void {
        this.isConfirmLoading = true;
        window.location.href = src;
        this.isConfirmLoading = false;
    }
}
