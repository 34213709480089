import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as alasql from 'alasql';
import * as _ from 'underscore';

@Component({
  selector: 'app-graduate-practice',
  templateUrl: './graduate-practice.component.html',
  styleUrls: ['./graduate-practice.component.css']
})
export class GraduatePracticeComponent implements OnInit {
  listData: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1: any = [];
    _.each(this.listData, oneData => {
      const data = {
        '基地级别': oneData['BASE_LEVEL'],
        '数量（个）': oneData['BASE_COUNT'] || 0,
        '年均接受学生数（人）': '',
        '人均实践时长（月）': '',
        '基地中的行业教师数（人）': oneData['TEACHER_COUNT'] || 0,
        '基地导师数（人）': ''
      };
      data1.push(data);
    });
    if (this.listData.length === 0) {
      const data = {
        '基地级别': '',
        '数量（个）': '',
        '年均接受学生数（人）': '',
        '人均实践时长（月）': '',
        '基地中的行业教师数（人）': '',
        '基地导师数（人）': ''
      };
      data1.push(data);
    }
    const opts = [
      {
        sheetid: '校外实践教学基地情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("IV-2-1 校外实践教学基地情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_teaching_base.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
