import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.css']
})
export class HrComponent implements OnInit {
  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  raClass: any = '';
  constructor() { }

  randomNo() {
    const num = Math.floor(Math.random() * 8);
    switch (num) {
      case 0:
        this.raClass = 'c1';
        break;
      case 1:
        this.raClass = 'c2';
        break;
      case 2:
        this.raClass = 'c3';
        break;
      case 3:
        this.raClass = 'c4';
        break;
      case 4:
        this.raClass = 'c5';
        break;
      case 5:
        this.raClass = 'c6';
        break;
      case 6:
        this.raClass = 'c7';
        break;
      case 7:
        this.raClass = 'c8';
        break;
      case 8:
        this.raClass = 'c9';
        break;
    }
  }

  ngOnInit() {
    this.randomNo();
  }

}
