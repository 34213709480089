import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {DataService} from '../data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-custplan-modal',
  templateUrl: './custplan-modal.component.html',
  styleUrls: ['./custplan-modal.component.css']
})
export class CustplanModalComponent implements OnInit {
  @Input() parentData: any;
  custData: any = {};
  planData: any = [];
  visitData: any = [];
  nowProduct: any = {
    checked: false
  };
  checkbox = true;
  allChecked = false;
  indeterminate = false;

  constructor(private dataCenterService: DataCenterService,
              private requestService: RequestDataService,
              private util: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService) {
  }

  // 切换产品
  switchProduct(pObject) {
    _.each(this.planData, item => {
      item['checked'] = false;
    });
    pObject.checked = true;
    this.nowProduct = pObject;
    const visitCondition = {
      CUST_ID: this.custData['ID'],
      PRODUCT: this.nowProduct['PRODUCT']
    };
    this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(result => {
      this.visitData = this.visitFormat(result.data);
    });
  }

  // 修改客户信息
  editCust() {
    this.modalService.create({
      nzTitle: '修改客户信息',
      nzContent: FormModalComponent,
      nzWidth: 800,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'CUST_NAME',
              'name': 'CUST_NAME_NAME',
              'customize': 12,
              'component': 'input',
              'lable': '客户名称',
              'uniqueCheck': ['OWNER_NAME', 'U_DELETE'],
              'comment': '客户名称',
              'placeholder': '请输入客户名称',
              'model': this.custData['CUST_NAME'],
              'visible': true,
              'require': true
            },
            {
              'column': 'CUST_CODE',
              'name': 'CUST_CODE_NAME',
              'component': 'input',
              'customize': 12,
              'uniqueCheck': ['OWNER_CODE', 'U_DELETE'],
              'lable': '客户代码',
              'comment': '客户代码',
              'placeholder': '请输入客户代码',
              'model': this.custData['CUST_CODE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_TYPE',
              'name': 'CUST_TYPE_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '客户类型',
              'comment': '客户类型',
              'placeholder': '客户类型',
              'model': this.custData['CUST_TYPE'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/CUST_TYPE.json',
              'dictionaryPF': null
            },
            {
              'column': 'CUST_LEVEL',
              'name': 'CUST_LEVEL_NAME',
              'component': 'multiSelect',
              'customize': 12,
              'lable': '客户层次',
              'comment': '客户层次',
              'placeholder': '客户层次',
              'model': this.custData['CUST_LEVEL'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/CUST_LEVEL.json',
              'dictionaryPF': null
            },
            {
              'column': 'CUST_ATTRIBUTE',
              'name': 'CUST_ATTRIBUTE_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '客户属性',
              'comment': '客户属性',
              'placeholder': '客户属性',
              'model': this.custData['CUST_ATTRIBUTE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_PEOPLE_NUM',
              'name': 'CUST_PEOPLE_NUM_NAME',
              'component': 'input',
              'customize': 12,
              'type': 'number',
              'lable': '在校总人数',
              'comment': '在校总人数',
              'placeholder': '请输入在校总人数',
              'model': this.custData['CUST_PEOPLE_NUM'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_RESEARCH_NUM',
              'name': 'CUST_RESEARCH_NUM_NAME',
              'component': 'input',
              'customize': 12,
              'type': 'number',
              'lable': '研究生在校总人数',
              'comment': '研究生在校总人数',
              'placeholder': '请输入研究生在校总人数',
              'model': this.custData['CUST_RESEARCH_NUM'],
              'visible': true,
              'require': false
            },
            {
              'column': 'PROVINCE',
              'name': 'PROVINCE_NAME',
              'component': 'poplist',
              'customize': 12,
              'lable': '省份',
              'comment': '省份',
              'placeholder': '请输入省份',
              'model': this.custData['PROVINCE'],
              'modelName': this.custData['PROVINCE'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_PROVINCE.json',
              'dictionaryPF': null
            },
            {
              'column': 'CITY',
              'name': 'CITY_NAME',
              'component': 'poplist',
              'customize': 12,
              'lable': '城市',
              'comment': '城市',
              'placeholder': '请输入城市',
              'model': this.custData['CITY'],
              'modelName': this.custData['CITY'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_CITY.json',
              'dictionaryPF': null
            },
            {
              'column': 'ADDRESS',
              'name': 'ADDRESS_NAME',
              'component': 'input',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '详细地址',
              'comment': '详细地址',
              'placeholder': '请输入详细地址',
              'model': this.custData['ADDRESS'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_COMPETITION_USAGE',
              'name': 'CUST_COMPETITION_USAGE_NAME',
              'component': 'textarea',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '竞品使用情况',
              'comment': '竞品使用情况',
              'placeholder': '请输入竞品使用情况',
              'model': this.custData['CUST_COMPETITION_USAGE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_DESC',
              'name': 'CUST_DESC_NAME',
              'component': 'textarea',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '备注',
              'comment': '备注',
              'placeholder': '请输入备注',
              'model': this.custData['CUST_DESC'],
              'visible': true,
              'require': false
            },
            {
              'column': 'CUST_IP',
              'name': 'CUST_IP_NAME',
              'component': 'textarea',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '客户IP段',
              'comment': '客户IP段',
              'placeholder': '请输入客户IP段',
              'model': this.custData['CUST_IP'],
              'visible': true,
              'require': false
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (oneData['component'] === 'multiSelect' && oneData['model']) {
            oneData['model'] = oneData['model'].join(';');
          }
          this.custData[oneData['column']] = oneData['model'];
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require']) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        this.dataService.editCust(this.custData).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                this.requestService.getDataByCondition('fury/cust/fury_cust_detail.json', {'CUST_ID': this.parentData['checkedData']['CUST_ID']}).subscribe(item => {
                  this.custData = item['data'][0];
                });
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  showOverPlay(pUrl): void {
    window.open(pUrl);
  }

  // 添加客户产品关系
  addCustProduct() {
    if (!this.util.Base_HasValue(this.nowProduct)) {
      return this.confirmModal.show('warning', {title: '请先添加客户产品情况'});
    }
    this.modalService.create({
      nzTitle: '添加客户产品',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PRODUCT',
              'name': 'PRODUCT_NAME',
              'component': 'multiSelect',
              'customize': 24,
              'lable': '产品名称',
              'comment': '产品名称',
              'placeholder': '产品名称',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/PRODUCT_NAME.json',
              'dictionaryPF': null
            },
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'component': 'select',
              'customize': 24,
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': this.nowProduct['PLAN_PERSON_CHARGEID'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            },
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (oneData['column'] === 'PLAN_PERSON_CHARGEID' && oneData['model']) {
            const seller = _.find(oneData['itemArray'], item => {
              return item['code'] === oneData['model'];
            });
            oneData['modelName'] = seller['name'];
          }
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require'] === true) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        const condition = {
          CUST_ID: this.custData['ID'],
          PRODUCT: data[0]['model']
        };
        this.requestService.getDataByCondition('fury/cust/cust_plant_list.json', condition).subscribe(respon => {
          this.dataService.salePlan(this.custData, data, respon.data).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '操作成功',
                suc: () => {
                  this.requestService.getDataByCondition('fury/cust/fury_cust_plan_detail.json', {'CUST_ID': this.custData['ID']}).subscribe(resp => {
                    this.planData = resp.data;
                    if (this.planData) {
                      this.planData[0]['checked'] = true;
                      this.nowProduct = this.planData[0];
                      const visitCondition = {
                        CUST_ID: this.custData['ID'],
                        PRODUCT: this.nowProduct['PRODUCT']
                      };
                      this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(item => {
                        this.visitData = this.visitFormat(item.data);
                      });
                    }
                  });
                }
              });
            } else {
              this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
              return false;
            }
          });
        });
      }
    });
  }

  // 修改客户产品关系
  editCustProduct() {
    this.modalService.create({
      nzTitle: '修改客户产品信息',
      nzContent: FormModalComponent,
      nzWidth: 800,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PLAN_STATE',
              'name': 'PLAN_STATE_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '状态',
              'comment': '状态',
              'placeholder': '状态',
              'model': this.nowProduct['PLAN_STATE'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/PLAN_STATE.json',
              'dictionaryPF': null
            },
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': this.nowProduct['PLAN_PERSON_CHARGEID'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null,
            },
            {
              'column': 'TRIAL_AGREE_NAME',
              'name': 'TRIAL_AGREE_NAME_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '试用人',
              'comment': '试用人',
              'placeholder': '试用人',
              'model': this.nowProduct['TRIAL_AGREE_NAME'],
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_FIRST_VISIT',
              'name': 'TRIAL_FIRST_VISIT_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '是否首次拜访',
              'comment': '是否首次拜访',
              'placeholder': '同意试用时再赋值',
              'model': this.nowProduct['TRIAL_FIRST_VISIT'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/YES_OR_NO.json',
              'dictionaryPF': null
            },
            {
              'column': 'TRIAL_PHONE',
              'name': 'TRIAL_PHONE_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '联系方式',
              'comment': '联系方式',
              'placeholder': '联系方式',
              'model': this.nowProduct['TRIAL_PHONE'],
              'visible': true,
              'require': true
            },
            {
              'column': 'TRIAL_POST',
              'name': 'TRIAL_POST_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '职务',
              'comment': '职务',
              'placeholder': '职务',
              'model': this.nowProduct['TRIAL_POST'],
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_DATE',
              'name': 'TRIAL_DATE_NAME',
              'component': 'date',
              'lableSpan': 4,
              'itemSpan': 8,
              'customize': 24,
              'lable': '试用日期',
              'comment': '试用日期',
              'placeholder': '试用日期',
              'model': this.nowProduct['TRIAL_DATE'],
              'visible': true,
              'require': false
            },
            {
              'component': 'hr',
              'customize': 24,
              'visible': true,
            },
            {
              'column': 'DELIVER_DATE',
              'name': 'DELIVER_DATE_NAME',
              'component': 'date',
              'customize': 12,
              'lable': '交付日期',
              'comment': '交付日期',
              'placeholder': '交付日期',
              'model': this.nowProduct['DELIVER_DATE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'DELIVER_CONFIRM_ID',
              'name': 'DELIVER_CONFIRM_ID_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '交付确认人',
              'comment': '交付确认人',
              'placeholder': '请选择交付确认人',
              'model': this.nowProduct['DELIVER_CONFIRM_ID'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            },
            {
              'column': 'SPREAD_NET_DATE',
              'name': 'SPREAD_NET_DATE_NAME',
              'component': 'date',
              'customize': 12,
              'lable': '挂网日期',
              'comment': '挂网日期',
              'placeholder': '挂网日期',
              'model': this.nowProduct['SPREAD_NET_DATE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'SPREADNET_CONFIRM_ID',
              'name': 'SPREADNET_CONFIRM_ID_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '挂网确认人',
              'comment': '挂网确认人',
              'placeholder': '请选择挂网确认人',
              'model': this.nowProduct['SPREADNET_CONFIRM_ID'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            },
            {
              'column': 'SPREADNET_URL',
              'name': 'SPREADNET_URL_NAME',
              'component': 'textarea',
              'customize': 12,
              'lable': '挂网地址',
              'comment': '挂网地址',
              'placeholder': '请输入挂网地址',
              'model': this.nowProduct['SPREADNET_URL'],
              'visible': true,
              'require': false
            },
            {
              'column': 'SPREADNET_PROVER',
              'name': 'SPREADNET_PROVER_NAME',
              'component': 'picture',
              'customize': 12,
              'lable': '挂网凭证',
              'comment': '挂网凭证',
              'placeholder': '请选择挂网凭证',
              'model': this.nowProduct['SPREADNET_PROVER'],
              'visible': true,
              'require': false
            },
            {
              'column': 'PLAN_CANCEL_DESC',
              'name': 'PLAN_CANCEL_DESC_NAME',
              'component': 'inputMulti',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '改进建议',
              'comment': '改进建议',
              'placeholder': '请输入改进建议',
              'model': this.nowProduct['PLAN_CANCEL_DESC'],
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_DESC',
              'name': 'TRIAL_DESC_NAME',
              'component': 'inputMulti',
              'lableSpan': 4,
              'itemSpan': 20,
              'customize': 24,
              'lable': '备注',
              'comment': '备注',
              'placeholder': '备注',
              'model': this.nowProduct['TRIAL_DESC'],
              'visible': true,
              'require': false
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (oneData['component'] === 'multiSelect') {
            oneData['model'] = oneData['model'].join(';');
          }
          if (oneData['component'] === 'select' && this.util.Base_HasValue(oneData['model'])) {
            let seller: any;
            switch (oneData['column']) {
              case 'PLAN_PERSON_CHARGEID':
                seller = _.find(oneData['itemArray'], item => {
                  return item['code'] === oneData['model'];
                });
                this.nowProduct['PLAN_PERSON_CHARGEID'] = seller['name'];
                break;
              case 'DELIVER_CONFIRM_ID':
                seller = _.find(oneData['itemArray'], item => {
                  return item['code'] === oneData['model'];
                });
                this.nowProduct['DELIVER_CONFIRM_NAME'] = seller['name'];
                break;
              case 'SPREADNET_CONFIRM_ID':
                seller = _.find(oneData['itemArray'], item => {
                  return item['code'] === oneData['model'];
                });
                this.nowProduct['SPREADNET_CONFIRM_NAME'] = seller['name'];
                break;
            }
          }
          if (oneData['column'] === 'PLAN_PERSON_CHARGEID') {
            const seller = _.find(oneData['itemArray'], item => {
              return item['code'] === oneData['model'];
            });
            this.nowProduct['PLAN_PERSON_CHARGE'] = seller['name'];
          }
          this.nowProduct[oneData['column']] = oneData['model'];
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require']) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        this.dataService.editCustProduct(this.nowProduct).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                this.requestService.getDataByCondition('fury/cust/fury_cust_plan_detail.json', {'CUST_ID': this.custData['ID']}).subscribe(resp => {
                  this.planData = resp.data;
                  if (this.planData) {
                    this.planData[0]['checked'] = true;
                    this.nowProduct = this.planData[0];
                    const visitCondition = {
                      CUST_ID: this.custData['ID'],
                      PRODUCT: this.nowProduct['PRODUCT']
                    };
                    this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(item => {
                      this.visitData = this.visitFormat(item.data);
                    });
                  }
                });
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 修改拜访明细
  editVisitDetail(pObject) {
    this.modalService.create({
      nzTitle: '修改拜访明细',
      nzContent: FormModalComponent,
      nzWidth: 800,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'VISIT_STATE',
              'name': 'VISIT_STATE_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '状态',
              'comment': '状态',
              'placeholder': '状态',
              'model': pObject['VISIT_STATE'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/VISIT_STATE.json',
              'dictionaryPF': null
            },
            {
              'column': 'VISIT_RESULT',
              'name': 'VISIT_RESULT_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '拜访结果',
              'comment': '拜访结果',
              'placeholder': '拜访结果',
              'model': pObject['VISIT_RESULT'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/VISIT_RESULT.json',
              'dictionaryPF': null
            },
            {
              'column': 'VISIT_DATE',
              'name': 'VISIT_DATE_NAME',
              'component': 'date',
              'customize': 12,
              'lable': '拜访日期',
              'comment': '拜访日期',
              'placeholder': '拜访日期',
              'model': pObject['VISIT_DATE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_MODE',
              'name': 'VISIT_MODE_NAME',
              'component': 'select',
              'customize': 12,
              'lable': '拜访方式',
              'comment': '拜访方式',
              'placeholder': '拜访方式',
              'model': pObject['VISIT_MODE'],
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/VISIT_MODE.json',
              'dictionaryPF': null
            },
            {
              'column': 'VISIT_NAME',
              'name': 'VISIT_NAME_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '拜访对象',
              'comment': '拜访对象',
              'placeholder': '拜访对象',
              'model': pObject['VISIT_NAME'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_DEPT',
              'name': 'VISIT_DEPT_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '所属部门',
              'comment': '所属部门',
              'placeholder': '所属部门',
              'model': pObject['VISIT_DEPT'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_POST',
              'name': 'VISIT_POST_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '职务',
              'comment': '职务',
              'placeholder': '职务',
              'model': pObject['VISIT_POST'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_PHONE',
              'name': 'VISIT_PHONE_NAME',
              'component': 'input',
              'customize': 12,
              'lable': '联系方式',
              'comment': '联系方式',
              'placeholder': '联系方式',
              'model': pObject['VISIT_PHONE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_OFFICE',
              'name': 'VISIT_OFFICE_NAME',
              'component': 'input',
              'customize': 24,
              'lableSpan': 4,
              'itemSpan': 20,
              'lable': '办公室地址',
              'comment': '办公室地址',
              'placeholder': '办公室地址',
              'model': pObject['VISIT_OFFICE'],
              'visible': true,
              'require': false
            },
            {
              'column': 'VISIT_DESC',
              'name': 'VISIT_DESC_NAME',
              'component': 'inputMulti',
              'customize': 24,
              'lableSpan': 4,
              'itemSpan': 20,
              'lable': '备注',
              'comment': '备注',
              'placeholder': '备注',
              'model': pObject['VISIT_DESC'],
              'visible': true,
              'require': false
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (oneData['component'] === 'multiSelect') {
            oneData['model'] = oneData['model'].join(';');
          }
          pObject[oneData['column']] = oneData['model'];
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require']) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        // 根据客户id、产品、拜访状态查询是否是第一次拜访
        this.requestService.getDataByCondition('fury/cust/fury_visit_object_detail.json', {
          'CUST_ID': this.nowProduct['CUST_ID'],
          'PRODUCT': this.nowProduct['PRODUCT'],
          'VISIT_STATE': 'FINISH'
        }).subscribe( respon => {
          const visitCount = respon.data.length;
          this.dataService.editVisitObject(pObject, this.nowProduct, visitCount).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '操作成功',
                suc: () => {
                  this.requestService.getDataByCondition('fury/cust/fury_cust_plan_detail.json', {'CUST_ID': this.custData['ID']}).subscribe(resp => {
                    this.planData = resp.data;
                    if (this.planData) {
                      this.planData[0]['checked'] = true;
                      this.nowProduct = this.planData[0];
                      const visitCondition = {
                        CUST_ID: this.custData['ID'],
                        PRODUCT: this.nowProduct['PRODUCT']
                      };
                      this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(item => {
                        this.visitData = this.visitFormat(item.data);
                      });
                    }
                  });
                }
              });
            } else {
              this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
              return false;
            }
          });
        });
      }
    });
  }

  // 添加拜访信息
  // 判断是否有拜访计划 CUST_ID,VISIT_DATE_PLAN,VISIT_YEAR
  // 没有做新增调用之前生成拜访计划的方法
  // 有就判断明细是否存在，product , 存在就跳过，没有就新增明细
  addVisit() {
    this.modalService.create({
      nzTitle: '添加拜访信息',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PRODUCT',
              'name': 'PRODUCT_NAME',
              'component': 'multiSelect',
              'customize': 24,
              'lable': '产品名称',
              'comment': '产品名称',
              'placeholder': '产品名称',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/PRODUCT_NAME.json',
              'dictionaryPF': null
            },
            {
              'column': 'VISIT_DATE_PLAN',
              'name': 'VISIT_DATE_PLAN_NAME',
              'component': 'dateWeek',
              'customize': 24,
              'lable': '拜访时间',
              'comment': '拜访时间',
              'placeholder': '请选择拜访时间',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'component': 'select',
              'customize': 24,
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': this.nowProduct['PLAN_PERSON_CHARGEID'],
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require'] === true) {
            ok = false;
          }
        });
        if (this.util.Base_HasValue(data[2]['model'])) {
          let seller = _.find(data[2]['itemArray'], item => {
            return item['code'] === data[2]['model'];
          });
          data[2]['modelName'] = seller['name'];
        }
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        const condition = {
          CUST_ID: this.custData['ID'],
          VISIT_DATE_PLAN: data[1]['model']
        };
        // 查询拜访主表是否存在
        this.requestService.getDataByCondition('fury/cust/visit_cust_plan.json', condition).subscribe(respon => {
          this.dataService.insertCustVisit(this.custData, data, respon.data).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '操作成功',
                suc: () => {
                  const visitCondition = {
                    CUST_ID: this.custData['ID'],
                    PRODUCT: this.nowProduct['PRODUCT']
                  };
                  this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(item => {
                    this.visitData = this.visitFormat(item.data);
                  });
                }
              });
            } else {
              this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
              return false;
            }
          });
        });
      }
    });
  }

  // 删除拜访明细
  deleteVisitDetail(pData) {
    this.confirmModal.show('confirm', {
      title: '您确定删除吗？', suc: () => {
        this.dataService.deleteVisitObject(pData['ID']).subscribe(response => {
          if (response['state'] === 'success') {
            this.requestService.getDataByCondition('fury/cust/visit_detail.json', {'VISIT_ID': pData['VISIT_ID']}).subscribe(result => {
              if (result.data.length === 0) {
                this.dataService.deleteVisit(pData['VISIT_ID']).subscribe(rep => {
                  if (rep['state'] === 'success') {
                    this.confirmModal.show('success', {
                      title: '操作成功',
                      suc: () => {
                        const visitCondition = {
                          CUST_ID: this.custData['ID'],
                          PRODUCT: this.nowProduct['PRODUCT']
                        };
                        this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(item => {
                          this.visitData = this.visitFormat(item.data);
                        });
                      }
                    });
                  }
                });
              } else {
                this.confirmModal.show('success', {
                  title: '操作成功',
                  suc: () => {
                    const visitCondition = {
                      CUST_ID: this.custData['ID'],
                      PRODUCT: this.nowProduct['PRODUCT']
                    };
                    this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe( item => {
                      this.visitData = this.visitFormat(item.data);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  visitFormat(pList) {
    _.each(pList, oneData => {
      if (this.util.Base_HasValue(oneData['VISIT_DATE_PLAN'])) {
        const weekOfday = parseInt(moment(moment().week(oneData['VISIT_DATE_PLAN'])).format('E')); // 计算今天是这周第几天
        oneData['LAST_MONDAY'] = moment(moment().isoWeek(oneData['VISIT_DATE_PLAN'])['_d']).subtract(weekOfday, 'days').format('YYYY-MM-DD'); // 周一日期
        oneData['LAST_SUNDAY'] = moment(oneData['LAST_MONDAY']).add(6, 'days').format('YYYY-MM-DD'); // 周日日期
      }
    });
    return pList;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  ngOnInit() {
    const a = moment().isoWeeks(10);
    const condition = {
      'CUST_ID': this.parentData['checkedData']['CUST_ID']
    };
    const parallel = observableForkJoin(
      this.requestService.getDataByCondition('fury/cust/fury_cust_detail.json', condition),
      this.requestService.getDataByCondition('fury/cust/fury_cust_plan_detail.json', condition)
    );
    parallel.subscribe(response => {
      if (response[0]['data']) {
        this.custData = response[0]['data'][0];
        this.planData = response[1]['data'];
        if (this.planData.length > 0) {
          this.planData[0]['checked'] = true;
          this.nowProduct = this.planData[0];
          const visitCondition = {
            CUST_ID: this.custData['ID'],
            PRODUCT: this.nowProduct['PRODUCT']
          };
          this.requestService.getDataByCondition('fury/cust/visit_detail.json', visitCondition).subscribe(result => {
            this.visitData = this.visitFormat(result.data);
          });
        }
      }
    });
  }

}
