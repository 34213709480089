import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _ from 'lodash';
import {CascaderOption} from 'ng-zorro-antd/cascader';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UUID} from 'angular2-uuid';
import * as cartesian from 'cartesian';

@Component({
  selector: 'app-customer-statements',
  templateUrl: './customer-statements.component.html',
  styleUrls: ['./customer-statements.component.css']
})
export class CustomerStatementsComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';

  constructor(
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private appConfigService: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = this.appConfigService.config.pageCount;
  // 总条数
  public total: any = 1;
  esCustomerId: String = null;
  title: String = null;


  public mainData: any = {};
  public itemData: any = {
    data: [],
    page: {
      nowCount: 0
    }
  };
  public busiData: any = [];

  doRefresh(){
    this.searchFilter();
  }

  searchFilter(reset: boolean = true) {
    if (reset) {
      this.pageIndex = 1;
    }

    const search = observableForkJoin(
      this.requestData.getDataByCondition('agriculture/report/customer_statements/es_customer_list.json', {'ID': this.esCustomerId}),
      this.requestData.getDataByCondition('agriculture/report/customer_statements/es_business_list.json', {'CUST_ID': this.esCustomerId}),
    );
    search.subscribe(
      values => {
        this.mainData = values[0]['data'][0];
        this.busiData = values[1]['data'][0];
        this.title = this.mainData['CUSTOMER_TYPE'] === 'ENTERPRISE' ? this.mainData['BUSI_NAME'] : this.mainData['CUSTOMER_NAME'];
        const itemCondition = this.mainData['CUSTOMER_TYPE'] === 'ENTERPRISE' ? {
          'CUST_ID': this.esCustomerId,
          'BUSI_ID': this.mainData['BUSI_ID'],
          'DATA_TYPE_CUST': 'PERSONAL',
          'DATA_TYPE_BUSI': 'ENTERPRISE'
        } : {
          'CUST_ID': this.esCustomerId,
          'DATA_TYPE_CUST': 'PERSONAL'
        };
        const search = observableForkJoin(
          this.requestData.getPaginationData('agriculture/report/customer_statements/es_customer_balance_log_list.json',
            itemCondition, this.pageIndex, this.pageSize
          )
        );
        search.subscribe(
          values => {
            this.itemData = values[0];
            this.total = this.itemData.page.rowCount;
            this.pageIndex = this.itemData.page.pageNum;
            this.itemData.data = this.removeZero(this.itemData.data);
          }
        );

      }
    );
  }

  removeZero(pData) {
    const arrayColumns = ['ADVANCE_PAYMENT', 'RECHARGE_PAID', 'RECHARGE_HANDSEL', 'CASH_BALANCE', 'ONTHEWAY_CASH_BALANCE', 'CASHOUT_BALANCE', 'COUPONS_BALANCE', 'ONTHEWAY_COUPONS_BALANCE', 'CASHOUT_COUPONS_BALANCE'];
    _.each(pData, oneData => {
      const columns = _.keys(oneData);
      _.each(columns, oneColumn => {
        if ((_.indexOf(arrayColumns, oneColumn) > -1) && (oneData[oneColumn] == 0))
          _.unset(oneData, [oneColumn]);
      })
    })
    return pData;
  }

  ngOnInit() {
    this.esCustomerId = this.parentData['id'];
    this.searchFilter();
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }
}
