import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {UtilsService} from './utils.service';
import {UUID} from 'angular2-uuid';
import {Md5} from 'ts-md5';
import {HttpClient, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private client: any;

  constructor(private config: AppConfigService,
              private httpClient: HttpClient,
              private utils: UtilsService) {
    const OSS = require('ali-oss');
    if (this.config.config['userOssOrCos'] === 'COS') {
      this.client = new COS(this.config.config.COS.config);
    } else {
      // 使用内网
      // this.config.config.OSS.config.internal = true;
      this.client = new OSS(this.config.config.OSS.config);
    }
  }/**/

  uploadFileCloud(file) {
    const fileName = file.name;
    const formData = new FormData();
    formData.append('file', file, fileName);
    let url = this.config.config.quickSliver + '/uploadFileCloud';
    if (this.config.config['userOssOrCos'] === 'OSS') {
      let ossPath = this.config.config.OSS.ossPath;
      url += "?server=oss&path=" + ossPath;
    } else if (this.config.config['userOssOrCos'] === 'COS') {
      let cosPath = this.config.config.COS.cosPath;
      url += "?server=css&path=" + cosPath;
    }
    const req = new HttpRequest('POST', url , formData);
    return this.httpClient.request(req);
  }



  doUpLoadSimple(pFile, pFileName, pFileType, progress) {
    if (this.config.config['userOssOrCos'] === 'COS') {
      return this.doCosUpload(pFile, progress, null, null, pFileName, pFileType);
    } else {
      return this.doAliOssUpload(pFile, progress, null, null, pFileName, pFileType);
    }
  }

  doAliOssUpload(pFile, progress, pFolder, pOosPath?, pFileName?, pFileType?) {
    let file = pFile;
    let fileType: any;
    if (this.utils.Base_HasValue(pFileType)) {
      fileType = pFileType;
    } else {
      fileType = this.utils.Base_getFileType(file.name);
    }
    let folder = this.utils.Base_getDef(pFolder, '');
    if (this.utils.Base_HasValue(folder)) {
      folder = folder + '/';
    }
    let key = this.utils.Base_replace(UUID.UUID(), '-', '');
    let ossPath = this.config.config.OSS.ossPath;
    if (this.utils.Base_HasValue(pOosPath)) {
      ossPath = pOosPath;
    }
    if (this.utils.Base_HasValue(pFileName)) {
      key = pFileName + '_' + key;
    } else {
      const fileName = this.utils.Base_getFileName(file.name);
      key = fileName + '_' + key;
    }
    if (this.utils.Base_HasValue(ossPath)) {
      let uuid = folder + ossPath + key + '.' + fileType;
      return this.client.multipartUpload(uuid, file, {
        progress: (info) => {
          if (typeof progress === 'function') {
            progress(info);
          }
        }
      });
    } else {
      console.error('请在appconfig中配置ossPath');
    }
  }

  doCosUpload(pFile, progress, pFolder, pCosPath, pFileName?, pFileType?) {
    let file = pFile;
    let fileType: any;
    if (this.utils.Base_HasValue(pFileType)) {
      fileType = pFileType;
    } else {
      fileType = this.utils.Base_getFileType(file.name);
    }
    let folder = this.utils.Base_getDef(pFolder, '');
    let cos = this.config.config['COS'];
    let cosPath = cos['cosPath'];
    let key = this.utils.Base_replace(UUID.UUID(), '-', '');
    if (this.utils.Base_HasValue(folder)) {
      folder = folder + '/';
    }
    if (this.utils.Base_HasValue(pFileName)) {
      key = pFileName + '_' + key;
    }
    if (this.utils.Base_HasValue(pCosPath)) {
      cosPath = pCosPath;
    }
    if (this.utils.Base_HasValue(cosPath)) {
      const uuid = folder + cosPath + key + '.' + fileType;
      return new Promise((resolve, reject) => {
        this.client.multipartUpload({
          Bucket: cos.config.bucket,
          Region: cos.config.region,
          Key: uuid,
          Body: file,
          onProgress: (info) => {
            if (typeof progress === 'function') {
              progress(info);
            }
          }
        }, (err, data) => {
          if (this.utils.Base_HasValue(err)) {
            reject(err);
          } else {
            data.url = uuid;
            resolve(data);
          }
        });
      });
    } else {
      console.error('请在appConfig中配置cosPath');
    }

  }
}
