import {Component, Input, OnInit} from '@angular/core';
import {ListActionService} from "../../service/list-action.service";
import * as _sh from 'lodash';
import {Observable, observable, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {ReadOnlyFormComponent} from "../../basicForm/read-only-form/read-only-form.component";
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";

@Component({
  selector: 'app-std-btn',
  templateUrl: './std-btn.component.html',
  styleUrls: ['./std-btn.component.scss']
})
export class StdBtnComponent implements OnInit {

  @Input() buttonConfig: any;  // 这个按钮的配置
  @Input() readonlyDatas: any; // 只读 详情页的值
  @Input() inputForm: any;
  @Input() specialData: any; // 选中的值
  @Input() parentData: any;
  //1、如果在制度详情页
  //1.1 table类型之中，代表选中的值
  //1.2 row 类型之中，代表选中的值
  public buttonJson = {
    ACTION_VALUE: '',
    ACTION_OPERATE: ''
  }; // 这个配置文件路径得到文件JSON。

  constructor(
    private utils: UtilsService,
    private listAction: ListActionService,
    private confirmModal: ConfirmModalService,
    private readOnlyFormComponent: ReadOnlyFormComponent
  ) {
  }

  ngOnInit() {
    this.buttonJson = {
      ACTION_VALUE: this.buttonConfig['action_value'],
      ACTION_OPERATE: this.buttonConfig['action_operate']
    }
  }

  handleClickBtn() {

    const mockdata = {
      data: {
        data: []
      }
    }

    this.listAction.doAction(this.buttonJson, mockdata, this.inputForm, this.readonlyDatas)
      .pipe(
        switchMap(lv01 => lv01 as Observable<any>),
        switchMap(lv02 => lv02 as Observable<any>),
        switchMap(lv03 => lv03 as Observable<any>)
      )
      .subscribe({
        next: lv04 => {
          const status = _sh.get(lv04, ['status'], 200);
          if (status == 500) {
            let errorMsg = _sh.get(lv04, [0, 'error'], null);  // thur 的错误
            errorMsg = _sh.get(lv04, ['error', 'message'], errorMsg);
            if (!this.utils.Base_HasValue(errorMsg)) {
              errorMsg = _sh.get(lv04, ['error'], null); // hulk 的错误
            }
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': errorMsg
            });
          } else if (status == 200) {
            this.readOnlyFormComponent.searchData();
          }
        },
        error: err => console.error('something wrong occurred: ' + err),
        complete: () => {
        }
      });

    // 首先要获取 按钮的配置文件。
  }

}

