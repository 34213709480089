import {Component, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import {DataCenterService} from "../../service/data-center.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _sh from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import * as _ from "underscore";

@Component({
  selector: 'app-hulk-config-sdserver',
  templateUrl: './hulk-config-sdserver.component.html',
  styleUrls: ['./hulk-config-sdserver.component.scss']
})
export class HulkConfigSdserverComponent implements OnInit {


  taskList = [];
  // 总条数
  public total: any = 1;
  // 当前页数
  public pageIndex: any = 1;
  // 每页条数
  public pageSize: any = 10;
  public nzPageSizeOptions: any = [10, 20, 50, 100, 200];

  sdText = "猫带着眼睛骑摩托";

  sdStyles = [
    '古风', '二次元', '写实风格', '浮世绘', 'low poly', '未来主义', '像素风格', '概念艺术', '赛博朋克', '洛丽塔风格', '巴洛克风格', '超现实主义', '水彩画', '蒸汽波艺术', '油画', '卡通画'
  ];
  selectedSdStyle = '古风';

  selectedSdResolution = '1024*1536';

  sdResolutions = [
    {
      resolution: '1024*1024',
      selected: false
    },
    {
      resolution: '1024*1536',
      selected: true
    },
    {
      resolution: '1536*1024',
      selected: false
    }
  ];

  sdModifierWords = [
    {
      word: '治愈',
      selected: false
    },
    {
      word: '涂鸦',
      selected: false
    },
    {
      word: '赛博朋克',
      selected: false
    },
    {
      word: '波普艺术',
      selected: false
    },
    {
      word: '毕加索',
      selected: false
    },
    {
      word: '齐白石',
      selected: false
    },
    {
      word: '安迪沃霍尔',
      selected: false
    },
    {
      word: '肌理风格',
      selected: false
    },
    {
      word: '张大千',
      selected: false
    },
    {
      word: '梵高',
      selected: false
    },
    {
      word: '中国剪纸',
      selected: false
    },
    {
      word: '像素风',
      selected: false
    },
    {
      word: '包豪斯',
      selected: false
    },
    {
      word: 'low-poly',
      selected: false
    },
    {
      word: '线稿',
      selected: false
    },
    {
      word: '日系',
      selected: false
    },
    {
      word: '蒸汽波',
      selected: false
    },
    {
      word: '刺绣',
      selected: false
    },
    {
      word: '巴洛克',
      selected: false
    },
    {
      word: '水彩',
      selected: false
    },
    {
      word: '油画',
      selected: false
    },
    {
      word: '中世纪',
      selected: false
    },
    {
      word: '哥特风',
      selected: false
    },
    {
      word: '科技风',
      selected: false
    },
    {
      word: '韩系',
      selected: false
    },
    {
      word: '中国风',
      selected: false
    },
    {
      word: '欧普艺术',
      selected: false
    },
    {
      word: '故障艺术',
      selected: false
    },
    {
      word: '极繁艺术',
      selected: false
    },
    {
      word: '光影合成',
      selected: false
    },
    {
      word: '迪斯科',
      selected: false
    },
    {
      word: '水墨',
      selected: false
    },
    {
      word: '雕像',
      selected: false
    },
    {
      word: '砖石',
      selected: false
    },
    {
      word: '扁平化',
      selected: false
    },
    {
      word: '奇幻',
      selected: false
    },
    {
      word: '粉笔',
      selected: false
    },
    {
      word: '民俗',
      selected: false
    },
    {
      word: '新国风',
      selected: false
    },
    {
      word: '森系',
      selected: false
    },
    {
      word: '暗黑',
      selected: false
    },
    {
      word: '国潮',
      selected: false
    },
    {
      word: '粉笔',
      selected: false
    },
    {
      word: '民俗',
      selected: false
    },
    {
      word: 'CG感',
      selected: false
    },
    {
      word: '厚涂风格',
      selected: false
    },
    {
      word: '精致面容',
      selected: false
    },
    {
      word: '穆夏风格',
      selected: false
    },
    {
      word: '机械感',
      selected: false
    },
    {
      word: '宫崎骏动画',
      selected: false
    },
    {
      word: '烟雾缭绕',
      selected: false
    },
    {
      word: '皮克斯动画',
      selected: false
    },
    {
      word: '拟人化',
      selected: false
    },
    {
      word: '剪纸叠加风格',
      selected: false
    },
    {
      word: '色彩斑斓',
      selected: false
    },
    {
      word: '城市映象',
      selected: false
    },
    {
      word: '原型轮廓',
      selected: false
    },
    {
      word: '上半身立绘',
      selected: false
    },
    {
      word: '人物立绘',
      selected: false
    },
    {
      word: '电影质感',
      selected: false
    },
    {
      word: 'logo设计',
      selected: false
    },
    {
      word: '细节清洗',
      selected: false
    },
    {
      word: '毛发细致',
      selected: false
    },
    {
      word: '复古未来主义风格',
      selected: false
    },
    {
      word: '海滩兔风格',
      selected: false
    },
    {
      word: '抽象技术风格',
      selected: false
    },
    {
      word: '酸性精灵风格',
      selected: false
    },
    {
      word: '古埃及风格',
      selected: false
    },
    {
      word: '风帽风格',
      selected: false
    },
    {
      word: '装饰技术风格',
      selected: false
    },
    {
      word: '极光风格',
      selected: false
    },
    {
      word: '极光风格',
      selected: false
    },
    {
      word: '秋天风格',
      selected: false
    },
    {
      word: '巴洛克风格',
      selected: false
    },
    {
      word: '摩托车手风格',
      selected: false
    },
    {
      word: '碎核风格',
      selected: false
    },
    {
      word: '纸箱风格',
      selected: false
    },
    {
      word: '未来主义风格',
      selected: false
    },
    {
      word: '孟菲斯公司风格',
      selected: false
    },
    {
      word: '立体主义风格',
      selected: false
    },
    {
      word: '赛博崩溃风格',
      selected: false
    },
    {
      word: '黑暗自然主义风格',
      selected: false
    },
    {
      word: '表现主义风格',
      selected: false
    },
    {
      word: '野兽派风格',
      selected: false
    },
    {
      word: '鬼混风格',
      selected: false
    },
    {
      word: '嘻哈风格',
      selected: false
    },
    {
      word: '嬉皮士风格',
      selected: false
    },
    {
      word: '幻想之城风格',
      selected: false
    },
    {
      word: '印象主义风格',
      selected: false
    },
    {
      word: '卡哇伊风格',
      selected: false
    },
    {
      word: '美人鱼风格',
      selected: false
    },
    {
      word: '极简主义风格',
      selected: false
    },
    {
      word: '水井惠廊风格',
      selected: false
    },
    {
      word: '苔藓风格',
      selected: false
    },
    {
      word: '新浪潮风格',
      selected: false
    },
    {
      word: '迷宫物语风格',
      selected: false
    },
    {
      word: '仙女风格',
      selected: false
    },
    {
      word: '粉彩朋克风格',
      selected: false
    },
    {
      word: '照片写实风格',
      selected: false
    },
    {
      word: '分红公主风格',
      selected: false
    },
    {
      word: '海盗风格',
      selected: false
    },
    {
      word: '像素可爱风格',
      selected: false
    },
    {
      word: '波普艺术风格',
      selected: false
    },
    {
      word: '史前遗迹风格',
      selected: false
    },
    {
      word: '迷幻风格',
      selected: false
    },
    {
      word: '雨天风格',
      selected: false
    },
    {
      word: '湿漉漉风格',
      selected: false
    },
    {
      word: '浮世绘风格',
      selected: false
    },
    {
      word: '矢量心风格',
      selected: false
    },
    {
      word: '维京人风格',
      selected: false
    },
    {
      word: '女巫店风格',
      selected: false
    },
    {
      word: '后映象主义',
      selected: false
    },
    {
      word: '素人风格',
      selected: false
    }
  ];

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit() {
    this.doGetTaskList();
  }

  doSelectSdStyle(oneSdStyle: string) {
    this.selectedSdStyle = oneSdStyle;
  }

  doSelectSdModifierWord(oneSdWord) {
    oneSdWord.selected = !oneSdWord.selected;
  }

  doSelectSdResolution(oneSdResolution) {
    _sh.each(this.sdResolutions, oneSdResolution => {
      oneSdResolution.selected = false;
    })
    oneSdResolution.selected = true
    this.selectedSdResolution = oneSdResolution.resolution;
  }

  doGetTaskList() {
    let searchId = "simulationTrain/stableDiffusion/st_sd_result.json";
    const parallel$ = observableForkJoin(
      this.requestService.getPaginationData(searchId, {}, this.pageIndex, this.pageSize)
    );
    parallel$.subscribe(
      values => {
        this.taskList = _sh.get(values, '[0].data', []);
      }
    );
  }

  doGetOneTaskResult(oneRow) {
    let stSdResultId = oneRow['ID'];
    let taskId = oneRow['TASK_ID'];
    taskId = JSON.parse(taskId);
    taskId = _sh.get(taskId, ['data', 'taskId'], null);
    let checked = true
    if (checked) {
      if (!this.util.Base_HasValue(taskId)) {
        checked = false;
        this.confirmModal.show('error', {'title': '错误如下', 'content': "未获得正确的任务ID"});
      }
    }

    if (checked) {
      let queryData = {
        'stSdResultId': stSdResultId,
        'taskId': taskId
      };
      let styleTextList = []
      _sh.each(this.sdModifierWords, oneWord => {
        if (oneWord.selected) {
          styleTextList.push(oneWord.word);
        }
      })

      let bodyData = null;

      this.requestService.doActionByHdbsthor('AppApi/StableDiffusionController', 'getTaskResult', queryData, bodyData, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(response => {
        if (response.toString() === 'true') {
          this.doGetTaskList();
          this.confirmModal.show('success', {'title': '执行成功'});
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    }
  }

  doCommit() {
    let queryData = {
      'userId': "65eea60e61834d9a8874bde2d71753ad"
    };
    let styleTextList = []
    _sh.each(this.sdModifierWords, oneWord => {
      if (oneWord.selected) {
        styleTextList.push(oneWord.word);
      }
    })

    let bodyData = {
      textList: _sh.split(this.sdText, "\n"),
      styleTextList: styleTextList,
      style: this.selectedSdStyle,
      resolution: this.selectedSdResolution
    }

    this.requestService.doActionByHdbsthor('AppApi/StableDiffusionController', 'commitSd', queryData, bodyData, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }
}
