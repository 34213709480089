import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import * as _ from 'underscore';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
@Component({
  selector: 'app-academic-exchange',
  templateUrl: './academic-exchange.component.html',
  styleUrls: ['./academic-exchange.component.css']
})
export class AcademicExchangeComponent implements OnInit {
  listData: any = [
    {
      SUM_INTERNATIONAL: 0,
      SUM_DOMESTIC: 0,
      SUM_MONEY: 0,
      AVG_INTERNATIONAL: 0,
      AVG_DOMESTIC: 0,
      AVG_MONEY: 0
    }
  ];
  year: any = [];
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [
      {
        '项目': '国际交流（人次）'
      },
      {
        '项目': '国内交流（人次）'
      },
      {
        '项目': '资助经费（万元）'
      }
    ];
    _.each(data1, oneData => {
      _.each(this.year, item => {
        if (oneData['项目'] === '国际交流（人次）') {
          oneData[item + '年'] = this.dataYear(item)[0]['INTERNATIONAL'] || 0;
        } else if (oneData['项目'] === '国内交流（人次）') {
          oneData[item + '年'] = this.dataYear(item)[0]['DOMESTIC'] || 0;
        } else if (oneData['项目'] === '资助经费（万元）') {
          oneData[item + '年'] = this.dataYear(item)[0]['FINANCIAL_SUPPORT_AMOUNT'] || 0;
        }
      });
      if (oneData['项目'] === '国际交流（人次）') {
        oneData['累计'] = this.listData[0]['SUM_INTERNATIONAL'] || 0;
        oneData['平均'] = this.listData[0]['AVG_INTERNATIONAL'] || 0;
      } else if (oneData['项目'] === '国内交流（人次）') {
        oneData['累计'] = this.listData[0]['SUM_DOMESTIC'] || 0;
        oneData['平均'] = this.listData[0]['AVG_DOMESTIC'] || 0;
      } else if (oneData['项目'] === '资助经费（万元）') {
        oneData['累计'] = this.listData[0]['SUM_MONEY'] || 0;
        oneData['平均'] = this.listData[0]['AVG_MONEY'] || 0;
      }
    });
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年在校生参加国际国内学术交流情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-4 近5年在校生参加国际国内学术交流情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  dataYear(year) {
    const data = _.filter(this.listData, oneData => {
      return oneData['YEAR'] === year;
    });
    if (data.length) {
      return data;
    } else {
      return [0];
    }
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.year = [this.evaluationYear - 4, this.evaluationYear - 3, this.evaluationYear - 2, this.evaluationYear - 1, this.evaluationYear - 0];
    this.requestDataService.getDataByCondition('subjectInfo/report/report_attend_meeting.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe(response => {
      this.listData = response.data;
      let sumInternational = 0;
      let sumDomestic = 0;
      let sumMoney = 0;
      _.each(this.listData, oneData => {
        sumInternational += oneData['INTERNATIONAL'];
        sumDomestic += oneData['DOMESTIC'];
        sumMoney += oneData['FINANCIAL_SUPPORT_AMOUNT'];
      });
      if (this.listData.length === 0) {
        this.listData[0] = {
          SUM_INTERNATIONAL: 0,
          SUM_DOMESTIC: 0,
          SUM_MONEY: 0,
          AVG_INTERNATIONAL: 0,
          AVG_DOMESTIC: 0,
          AVG_MONEY: 0
        };
      }
      this.listData[0]['SUM_INTERNATIONAL'] = sumInternational;
      this.listData[0]['SUM_DOMESTIC'] = sumDomestic;
      this.listData[0]['SUM_MONEY'] = sumMoney;
      this.listData[0]['AVG_INTERNATIONAL'] = (sumInternational / this.year.length).toFixed(2);
      this.listData[0]['AVG_DOMESTIC'] = (sumDomestic / this.year.length).toFixed(2);
      this.listData[0]['AVG_MONEY'] = (sumMoney / this.year.length).toFixed(2);
    });
  }

}
