
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {DataCenterService} from '../../../service/data-center.service';
import {DataService} from '../../../project/headLine/data.service';
import * as _ from 'underscore';
import {ViewModelComponent} from '../view-model/view-model.component';

@Component({
  selector: 'app-dataverse-detail',
  templateUrl: './dataverse-detail.component.html',
  styleUrls: ['./dataverse-detail.component.css']
})
export class DataverseDetailComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;

  public typeArray: any = [];
  public model: any = {
    ds_dataverse: {},
    dataverse_column: [],
    dataverse_model: [],
    dataverse_boutique: []
  };
  public dataverse_model: any = [];

  public previewImage: any = '';
  public previewVisible: any = false;

  // 一键展开手气
  public expendAll: any = false;

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private modalService: NzModalService) {
  }

  gotoBack() {
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL)) {
          item = _.extend(item, this.parentData);
          item['formType'] = 'listForm';
          item['data'] = {};
          // item["detailCondition"] = {};
          item['conditionColumns'] = {};
        }
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 一键展开一键收起
  expendFun() {
    this.expendAll = !this.expendAll;
    _.each(this.model.modelList, model => {
      model['active'] = this.expendAll;
    });
  }
  handlePreview = (url) => {
    this.previewImage = url;
    this.previewVisible = true;
    return false;
  };


  // 元数据配置查看元数据字段
  viewModel(model) {
    const data = this.utils.DeepCopy(model);
    data['groups'] = _.filter(model['groups'], group => group['visible']);
    this.modalService.create({
      nzTitle: model.modelName,
      nzContent: ViewModelComponent,
      nzWidth: 1200,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modelData: data
      },
      nzCancelText: null,
      nzOnOk: () => {
        return true;
      }
    });
  }

  ngOnInit() {
    const condition = this.parentData['detailCondition'];
    const id = condition['DATAVERSE_ID'];
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition('headLine/basic/dict/DATAVERSE_TYPE.json'),
      this.dataService.searchDataverse({
        dataverseId: id
      })
    );
    search.subscribe(
      values => {
        this.typeArray = values[0]['data'] || [];
        this.model = values[1] || {};
        this.model.ds_dataverse = this.model['ds_dataverse'][0];
        let data = _.find(this.typeArray, item => item['code'] === this.model['ds_dataverse']['DATAVERSE_TYPE']);
        this.model.ds_dataverse['DATAVERSE_TYPE'] = data['name'];
        this.dataverse_model = _.filter(this.model.dataverse_model, model => model['visible']);
      });
  }

}
