import {Injectable} from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import {UtilsService} from './utils.service';
import {RequestDataService} from './request-data.service';
import {Observable, Observer} from 'rxjs';
import * as _ from 'underscore';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(private utils: UtilsService,
              private requestDataService: RequestDataService) {
  }


  // 测试例子 非使用代码
  confirmValidator(oneColumn): ValidatorFn {
    return (control: FormControl): { [ s: string ]: boolean } => {
      return null;
    };
  }

  // 判断开始时间结束时间
  timeCheckValidator(checkColumn): ValidatorFn {
    return (control: FormControl): { [ s: string ]: boolean } => {
      if (control.value) {
        const form = control.parent;
        if (form) {
          const targetControl: AbstractControl = form.controls[checkColumn];
          if (targetControl.value) {
            if (moment(targetControl.value).isSame(control.value) || moment(targetControl.value).isAfter(control.value)) {
              return {errorAfterTime: true};
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return null;
    };
  }

  // 另外一种写法 Observable 非使用代码
  userNameAsyncValidator = () => {
    return (control: FormControl) => Observable.create((observer: Observer<ValidationErrors>) => {
      setTimeout(() => {
        observer.next(null);
        observer.complete();
      }, 100);
    });
  }

  checkValueType(pOneColumn,pValue){
    let value = pValue;
    if(this.utils.Base_HasValue(pValue)){
      if(this.utils.Base_HasValue(pValue) && _.isDate(pValue)){
        switch (pOneColumn.component) {
          case 'year':
            value = moment(value).format('YYYY');
            break;
          case 'date':
            value = moment(value).format('YYYY-MM-DD');
            break;
          case 'datetime':
            value = moment(value).format('YYYY-MM-DD HH:mm:ss');
            break;
          case 'dateMonths':
            value = moment(value).format('YYYY-MM');
            break;
        }
      }else{
        return value;
      }
    }
    return value;
  }

  uniqueCheck(modeType, formGroup, oneColumn) {
    return (control: FormControl) => {
      const q = new Promise((resolve, reject) => {
        if (control.dirty && this.utils.Base_HasValue(control.value)) {
          let dataArray = [{}];
          dataArray[0][oneColumn['column']] = this.checkValueType(oneColumn,control.value);
          // 获取修改时候id数组用于过滤当前数据
          let pkArr = [{}];
          const pkColumn = {};
          // uniqueCheck 数组里面对应字段的值是否都不为空
          let checkModelHasValue = true;
          _.each(formGroup.columns, (column) => {
            if (this.utils.Base_getJsonValue(column, 'pk_column', false)
              && this.utils.Base_HasValue(column['model'])
              && modeType !== 'add') {
              pkColumn[column['column']] = column['model'];
            }
            if (_.contains(oneColumn.uniqueCheck, column['column'])
              && column['column'] !== oneColumn.column) {
              checkModelHasValue = this.utils.Base_HasValue(column['model']) && checkModelHasValue;
              if (checkModelHasValue) {
                dataArray[0][column['column']] = column['model'];
              }
            }
          });
          if (this.utils.Base_HasValue(pkColumn)) {
            pkArr.push(pkColumn);
          }
          if (!_.isArray(oneColumn.uniqueCheck)) {
            resolve({error: true, uniqueCheckArrError: true});
          } else if (!checkModelHasValue) {
            resolve({error: true, uniqueCheckValueError: true});
          } else {
            setTimeout(() => {
              this.requestDataService.getCountJson(formGroup.tablename, dataArray, pkArr).subscribe((result) => {
                if (result['count'] > 0) {
                  resolve({'uniqueCheck': true});
                } else {
                  resolve(null);
                }
              }, () => {
                reject({error: true, uniqueCheck: true});
              });
            }, 1000);
          }
        } else {
          resolve(null);
        }

      });
      return q;
    };
  }
}
