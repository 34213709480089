import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {NzModalService} from "ng-zorro-antd/modal";
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {UtilsService} from "../../service/utils.service";
import {UploadService} from "../../service/upload.service";
import {RequestDataService} from "../../service/request-data.service";
import {AjaxService} from "../../service/ajax.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UserInfoService} from "../../service/user-info.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {JumpCenterService} from "../../service/jump-center.service";
import * as _ from 'lodash';
import {UUID} from "angular2-uuid";
// import {MqttServiceService} from "../../service/mqtt-service.service";

@Component({
    selector: 'app-todo-center',
    templateUrl: './todo-center.component.html',
    styleUrls: ['./todo-center.component.scss']
})
export class TodoCenterComponent implements OnInit {

    @Input() parentData: any;

    public user: any;              // 用户信息

    timer: any;                      // 定时器
    deadlineConfig = 120;            // 定时2min
    countDownTimer: any;
    countDown = new Date('2021-01-01 00:02:00');   // 刷新倒计时
    refreshTime = new Date();                      // 数据刷新时间
    searchText:any;


    public awaitListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5,
        total: 1,// 总条数
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    public refuseListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5, // 总条数
        total: 1,
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    constructor(private modalService: NzModalService,
                private config: AppConfigService,
                public dataCenterService: DataCenterService,
                private utils: UtilsService,
                private uploadService: UploadService,
                private requestService: RequestDataService,
                private ajaxService: AjaxService,
                private notification: NzNotificationService,
                private userInfoService: UserInfoService,
                private jumpCenterService: JumpCenterService,
                private confirmModal: ConfirmModalService) {
        this.user = userInfoService.getUserInfo();
    }

    ngOnInit() {
        this.getAllData(true);
    }

    goAlltodo() {
        let jumpConfig = {
            "list": {
                "default": {
                    "jumpPage": {
                        "newPage": true,
                        "menuId": "EF7D200C812F05BD51D428FC1D3576F7",
                        "menuName": "个人中心-全部待办",
                        "titleTemplate": "全部待办",
                        "msgValue": {},
                        "modeType": null,
                        "component": null,
                        "formType": "readOnlyForm",
                        "formClass": "readOnlyForm",
                        "visibleBtn": {
                            "back": false,
                            "print": false
                        }
                    },
                    "jumpData": {
                        "id": "simulation"
                    }
                }
            }
        };

        let simulationParentData = {
            data: {
                data: []
            }
        }
        this.jumpCenterService.execJumpPage(jumpConfig, simulationParentData, null, null);

    }



    getAwaitList() {
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['MORE_INFO'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_user_await_data.json', condition, this.awaitListPage.pageIndex, this.awaitListPage.pageSize),          // 4
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.awaitList = _.get(values, [0, 'data'], []);                     // 查询全部待办数据
            _.each(this.dataCenterService._todoCenter.awaitList, oneRow => {
                oneRow['MORE_INFO'] = JSON.parse(oneRow['MORE_INFO']);
            })
            this.awaitListPage['total'] = _.get(values, [0, 'page', 'rowCount'], 0);
            this.awaitListPage['pageCount'] = _.get(values, [0, 'page', 'nowCount'], 0);

        });
    }

    getrefuseList() {
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['MORE_INFO'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_await_refuse_list.json',condition, this.refuseListPage.pageIndex, this.refuseListPage.pageSize),            // 8
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.refuseList = _.get(values, [0, 'data'], []);                    // 我发起的，但是被拒绝的。
            _.each(this.dataCenterService._todoCenter.refuseList, oneRow => {
                oneRow['MORE_INFO'] = JSON.parse(oneRow['MORE_INFO']);
            });
            this.refuseListPage['total'] = _.get(values, [0, 'page', 'rowCount'], 0);
            this.refuseListPage['pageCount'] = _.get(values, [0, 'page', 'nowCount'], 0);
        });
    }

    // 获取全部数据
    getAllData(pStartTimer?) {
        const searchAll = observableForkJoin(
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_count.json'),         // 0
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_timeout.json'),       // 1
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_willtimeout.json'),   // 2
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_urgen.json'),         // 3
            this.requestService.getPaginationData('assets/workflow/wf_user_await_data.json', {}, this.awaitListPage.pageIndex, this.awaitListPage.pageSize),          // 4
            this.requestService.getDataByCondition('assets/workflow/wf_await_myinit.json'),             // 5
            this.requestService.getDataByCondition('assets/workflow/wf_await_myfinish.json'),           // 6
            this.requestService.getDataByCondition('assets/workflow/wf_await_refuse_count.json'),            // 7
            this.requestService.getPaginationData('assets/workflow/wf_await_refuse_list.json',{}, this.refuseListPage.pageIndex, this.refuseListPage.pageSize),            // 8
            this.requestService.getDataByCondition('assets/workflow/sum_con/main.json')                 //9
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.todoSum = _.get(values, [0, 'data', 0, 'ACOUNT'], 0);           // 查询全部待办
            this.dataCenterService._todoCenter.todoOverSum = _.get(values, [1, 'data', 0, 'ACOUNT'], 0);       // 查询已超时
            this.dataCenterService._todoCenter.todoWillOverSum = _.get(values, [2, 'data', 0, 'ACOUNT'], 0);   // 查询即将超时
            this.dataCenterService._todoCenter.todoUrgeSum = _.get(values, [3, 'data', 0, 'ACOUNT'], 0);       // 查询催办
            this.dataCenterService._todoCenter.awaitList = _.get(values, [4, 'data'], []);                     // 查询全部待办数据
            _.each(this.dataCenterService._todoCenter.awaitList, oneRow => {
                oneRow['MORE_INFO'] = JSON.parse(oneRow['MORE_INFO']);
            })
            this.awaitListPage['total'] = _.get(values, [4, 'page', 'rowCount'], 0);
            this.awaitListPage['pageCount'] = _.get(values, [4, 'page', 'nowCount'], 0);

            this.dataCenterService._todoCenter.myInit = _.get(values, [5, 'data', 0, 'ACOUNT'], 0);            // 我发起的（未完成）
            this.dataCenterService._todoCenter.myFinish = _.get(values, [6, 'data', 0, 'ACOUNT'], 0);          // 我发起的（已完成）
            this.dataCenterService._todoCenter.refuseSum = _.get(values, [7, 'data', 0, 'ACOUNT'], 0);         // 我发起的，但是被拒绝的。
            this.dataCenterService._todoCenter.refuseList = _.get(values, [8, 'data'], []);                    // 我发起的，但是被拒绝的。
            this.dataCenterService._todoCenter.myconCount = _.get(values,[9,'data',0,'ACCOUNT'],0);             //经我手合同数
            _.each(this.dataCenterService._todoCenter.refuseList, oneRow => {
                oneRow['MORE_INFO'] = JSON.parse(oneRow['MORE_INFO']);
            });
            this.refuseListPage['total'] = _.get(values, [8, 'page', 'rowCount'], 0);
            this.refuseListPage['pageCount'] = _.get(values, [8, 'page', 'nowCount'], 0);

        });
        if (pStartTimer) {
            this.doRestTime()
        }
    }

    // 定时刷新
    doRestTime() {
        this.timer = setInterval(() => {
            this.getAllData(false);
            this.refreshTime = new Date();
            this.countDown = new Date('2021-01-01 00:02:00');
        }, this.deadlineConfig * 1000);

        this.countDownTimer = setInterval(() => {
            this.countDown = new Date(this.countDown.getTime() - 1000);
        }, 1000)

    }

    // 手动刷新
    doRefreshTodoCenter() {
        clearInterval(this.timer);
        clearInterval(this.countDownTimer);
        this.refreshTime = new Date();
        this.countDown = new Date('2021-01-01 00:02:00');
        this.getAllData(true);
    }

    // 销毁
    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.countDownTimer) {
            clearInterval(this.countDownTimer);
        }
    }

    gotoDetail(pData, pFormClass) {
        const documentId = pData['DOCUMENT_ID'];
        const wfTitel = pData['WF_TITEL'];
        const wfConfigId = pData['WF_CONFIG_ID'];

        let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
            return item['parentDataId'] === documentId;
        });
        if (tabIndex > 0) {
            this.dataCenterService._dataObject.index = tabIndex;
            this.dataCenterService._dataObject.nowMenu = pData['DOCUMENT_ID'];
            this.dataCenterService.refreshDataCenterInStorage();
        } else {
            const searchAll = observableForkJoin(
                this.requestService.getDataByCondition('workflow/wf_config_list.json', {'ID': wfConfigId})
            );
            searchAll.subscribe(
                values => {
                    const searchId = _.get(values, [0, 'data', 0, 'MENU_ID_DICT_ARRAY', 'SEARCH_ID']);
                    this.dataCenterService.newTab({
                        id: documentId, // 唯一标识
                        parentDataId: documentId, // 唯一标识
                        nowMenu: _.get(values, [0, 'data', 0, 'MENU_ID_DICT_ARRAY']),
                        searchId: searchId,
                        title: wfTitel, // 菜单名称 用户tab展示
                        formType: 'readOnlyForm', // 菜单类型 用于tab过滤显示模板
                        formClass: pFormClass, // 工作流审批页面
                        visibleBtn: {
                            "back": false,
                            "print": false
                        },
                        data: {}, // 查询返回数据
                        detailCondition: {
                            'ID': documentId
                        },
                        workflowData: {
                            "wfUserAwaitId": pData['ID']
                        }
                    });
                }
            );


        }
    }


}
