import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _sh from 'lodash';
import * as _ from 'underscore';
import {forkJoin as observableForkJoin} from "rxjs";
import * as moment from 'moment';
import { getLodop } from "../../../../assets/lib/LodopFuncs.js";

@Component({
  selector: 'app-print-deliver-dispatch',
  templateUrl: './print-deliver-dispatch.component.html',
  styleUrls: ['./print-deliver-dispatch.component.scss']
})
export class PrintDeliverDispatchComponent implements OnInit {
  @Input() parentData: any;
  LODOP = getLodop(); //声明为全局变量
  printName: any;
  printDate: any;
  listDatas: any[];
  EMP_NAME: any;
  taskId: any;
  rcName: any;
  constructor(private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private userInfoService: UserInfoService,
              private requestService: RequestDataService,
              private util: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService) {
  }

  ngOnInit() {
    this.taskId = _sh.get(this.parentData, 'id');
    this.printName = this.userInfoService.getUserInfo()['USERNAME'];
    this.printDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const search01$ = this.requestService.getDataByCondition('JieLiv2/printOrder/pr_deliver_dispatch.json', {'TASK_ID': this.taskId})
    search01$.subscribe(
      values => {
        this.listDatas = _sh.get(values, ['data'], []);
        _sh.each(this.listDatas, oneData=> {
          if (_.has(oneData, 'GOODS_ID')) {
            this.requestService.getDataByCondition('JieLiv2/printOrder/pr_goods_detail.json', {'GOODS_ID': oneData['GOODS_ID']}).subscribe(response => {
              oneData.GOODS_NAME = response.data[0].GOODS_CN_NAME;
              oneData.GOODS_CODE = response.data[0].GOODS_CODE;
              oneData.GOODS_UNIT = response.data[0].GOODS_UNIT_DICT;
            })
          }else if(_.has(oneData, 'LOC_ID')){
            this.requestService.getDataByCondition('JieLiv2/printOrder/pr_loc_detail.json', {'LOC_ID': oneData['LOC_ID']}).subscribe(response => {
              oneData.LOC_NAME = response.data[0].LOC_NAME;
              oneData.LOC_CODE = response.data[0].LOC_CODE;
            });
          };

          if (_.has(oneData, 'ORDER_ID')) {
            this.requestService.getDataByCondition('JieLiv2/printOrder/pr_cust_orders.json', {'ORDER_ID': oneData['ORDER_ID']}).subscribe(response => {
                this.rcName = response.data[0].RC_ID_DICT;
                oneData.CUST_ORDER_CODE = response.data[0].CUST_ORDER_CODE;
              }
            );
          };
        });
      }
    );
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  printPage() {
    var strBodyStyle = "<style> .sign-border {\n" +
      "    display: inline-block;\n" +
      "    width: 100px;\n" +
      "    border-bottom: 1px solid #000;\n" +
      "    height: 20px;\n" +
      "  }\n" +
      "\n" +
      "  .table-bordered td, .table-bordered th, .stacking-table td {\n" +
      "    border-color: #000 !important;\n" +
      "  }\n" +
      "\n" +
      "  .table-noborder td, .table-noborder th {\n" +
      "    border-top: none !important;\n" +
      "  }\n" +
      "\n" +
      "  .detail-table{\n" +
      "    padding: 10px 0;\n" +
      "    width: 100%;\n" +
      "    font-size: 14px;\n" +
      "    clear: both;\n" +
      "  }\n" +
      "\n" +
      "  .divMain-table{\n" +
      "    border-left: none;\n" +
      "    border-right: none;\n" +
      "  }\n" +
      "  .divMain-table tr td{\n" +
      "    border-left: none !important;\n" +
      "    border-right: none !important;\n" +
      "  }\n" +
      "  .divMain-table .no-border-top td{\n" +
      "    border-top: none !important;\n" +
      "  }\n" +
      "  .divMain-table .no-border-bottom td{\n" +
      "    border-bottom: none !important;\n" +
      "  }" +
      "</style>";
    //var strFormHtml = strBodyStyle + "<body>" + document.getElementById("ckd-printPage").innerHTML + "</body>";
    try {
      //var printCheckYzhou = document.getElementById("x").value;
      //var printCheckXzhou = document.getElementById("y").value;
      //var printLength = document.getElementById("length").value;
      //var printWeidth = document.getElementById("weidth").value;
      //Base_Constant.utils.setParam("printLength", printLength);
      //Base_Constant.utils.setParam("printWeidth", printWeidth);
      //Base_Constant.utils.setParam("printCheckYzhou", printCheckYzhou);
      //Base_Constant.utils.setParam("printCheckXzhou", printCheckXzhou);
      this.LODOP.SET_PRINT_PAGESIZE(0, "2400", "1400", "CreateCustomPage");
      this.LODOP.NewPageA();
      // this.LODOP.SET_LICENSES("", licenesObj.registerLicenes, licenesObj.fixedLicenes,"");
      //ADD_PRINT_TABLE(Top,Left,Width,Height,strHtml)
      this.LODOP.ADD_PRINT_HTM(390,"5%","90%",300,strBodyStyle+document.getElementById("divDown").innerHTML);
      this.LODOP.SET_PRINT_STYLEA(0,"ItemType",1);
      this.LODOP.SET_PRINT_STYLEA(0,"LinkedItem",1);
      this.LODOP.ADD_PRINT_TABLE(160,"5%","90%",310,strBodyStyle+document.getElementById("divMain").innerHTML);
      this.LODOP.SET_PRINT_STYLEA(0,"Vorient",3);
      //ADD_PRINT_HTML(Top,Left,Width,Height, strHtmlContent)
      this.LODOP.ADD_PRINT_HTM(10,"5%","90%",200,document.getElementById("divTop").innerHTML);
      this.LODOP.SET_PRINT_STYLEA(0,"ItemType",1);
      this.LODOP.SET_PRINT_STYLEA(0,"LinkedItem",1);
      //ADD_PRINT_HTML(Top,Left,Width,Height, strHtmlContent)
      this.LODOP.ADD_PRINT_HTM(135,600,300,100,"总页号：<font color='#0000ff' format='ChineseNum'><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>");
      this.LODOP.SET_PRINT_STYLEA(0,"ItemType",1);
      this.LODOP.SET_PRINT_STYLEA(0,"Horient",1);
      this.LODOP.PRINT();
      // this.LODOP.PREVIEW();
    } catch (e) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请先安装C-LODOP插件！'
      });
      // confirmModalFactory.doShowAlert("请先安装C-LODOP插件");
      // vm.showDowlnoad = true;
    }
  }
}
