
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import {UploadXHRArgs, UploadFile} from 'ng-zorro-antd';
import {NzModalService} from 'ng-zorro-antd';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UploadService} from './../../../service/upload.service';
import {UtilsService} from './../../../service/utils.service';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import {AppConfigService} from './../../../service/app-config.service';
import {DataService} from './../data.service';

import * as _ from 'underscore';
import * as _sh from 'lodash';
import {FileDescComponent} from '../file-desc/file-desc.component';

@Component({
  selector: 'app-dataset-insert',
  templateUrl: './dataset-insert.component.html',
  styleUrls: ['./dataset-insert.component.css']
})
export class DatasetInsertComponent implements OnInit {


  @Input() parentData: any;

  public modeType: any = 'add';

  public _datasetForm: FormGroup;
  public _validateForm: FormGroup;

  public stateArray: any = [];

  public baseModel: any = {
    datasetState: 'OUTSALE',
    datasetName: '',
    dataverseId: null
  };

  public modelList: any = [];

  public previewVisible = false;
  public previewImage = '';

  // 选择空间组件封装
  public dataverseColumn: any = {
    lableSpan: 8,
    itemSpan: 16,
    name: 'dataverseId',
    column: 'dataverseId',
    lable: '选择空间',
    comment: '选择空间',
    placeholder: '请选择选择空间',
    visible: true,
    require: true,
    limitLength: 10,
    itemArray: []
  };

  public fileTypeDict = {
    'xlsx': 'Excel',
    'xls': 'Excel',
    'csv': 'Excel',
    'mpp': 'Project',
    'pptx': 'PPT',
    'zip': '压缩包',
    'rar': '压缩包',
    'pdf': 'Pdf',
    'docx': 'Word',
    'doc': 'Word',
    'tab': 'TableAu',
    'png': '图片',
    'jpg': '图片',
    'jpeg': '图片',
    'gif': '图片',
    'txt': '文本'
  };
  // 是否点击了选择空间
  public selectedDataverse: any = false;


  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 上传图片回调
  customReq = (item: UploadXHRArgs, group) => {
    if (this.config.config['userOssOrCos'] === 'COS') {
      return this.uploadService.doCosUpload(item.file, item['progress'], null, null, null).then((result) => {
        item.file.url = this.config.config['COS']['domain'] + '/' + result['url'];
        let fileType = _sh.chain(item.file.name).split('.').last().value();
        if (fileType) {
          fileType = fileType.toLowerCase();
        }
        item.file.fileType = this.fileTypeDict[fileType] || '其他';
        item.file.pictureSize = item.file.size;
        item.onSuccess({}, item.file, {});
      });
    } else {
      return this.uploadService.doAliOssUpload(item.file, item['progress'], null).then((result) => {
        item.file.url = this.config.config['OSS']['domain'] + '/' + result['name'];
        let fileType = _sh.chain(item.file.name).split('.').last().value();
        if (fileType) {
          fileType = fileType.toLowerCase();
        }
        item.file.fileType = this.fileTypeDict[fileType] || '其他';
        item.file.pictureSize = item.file.size;
        item.onSuccess({}, item.file, {});
      });
    }
  };

  // 图片预览
  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
    return false;
  };

  // 将size转为KB MB GB
  convert(size) {
    return this.utils.conver(size);
  }

  // 上传文件回调
  uploadFileChange(group) {
    group.columns[0]['model'] = '已上传' + group['data'].length;
  }

  // 删除文件
  removeFile = (group, i) => {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该文件吗？',
      suc: () => {
        group.data.splice(i, 1);
        let model = '';
        if (group['data'].length) {
          model = '已上传' + group['data'].length;
        }
        group.columns[0]['model'] = model;
      }
    });
  };

  // 上传文件回调
  customFileReq = (item: UploadXHRArgs) => {
    if (this.config.config['userOssOrCos'] === 'COS') {
      return this.uploadService.doCosUpload(item.file, (progressInfo) => {
        if (progressInfo.loaded < progressInfo.total) {
          item.file.loading = true;
          item.file.percent = +(progressInfo.loaded * 100 / progressInfo.total).toFixed(0);
        } else if (progressInfo.loaded === progressInfo.total) {
          item.file.loading = false;
          item.file.percent = 100;
        }
      }, null, null, null).then((result) => {
        item.file.url = this.config.config['COS']['domain'] + '/' + result['url'];
        item.file.fileSize = this.utils.conver(item.file.size);
        item.file.fileSizeMb = this.utils.converToMb(item.file.size);
        let fileType = _sh.chain(item.file.name).split('.').last().value();
        if (fileType) {
          fileType = fileType.toLowerCase();
        }
        item.file.fileType = this.fileTypeDict[fileType] || '其他';
        item.onSuccess({}, item.file, {});
      });
    } else {
      return this.uploadService.doAliOssUpload(item.file, (progressInfo) => {
        if (progressInfo < 1) {
          item.file.loading = true;
          item.file.percent = +(progressInfo * 100).toFixed(0);
        } else if (progressInfo === 1) {
          item.file.loading = false;
          item.file.percent = 100;
        }
      }, null).then((result) => {
        item.file.url = this.config.config['OSS']['domain'] + '/' + result['name'];
        item.onSuccess({}, item.file, {});
        let fileType = _sh.chain(item.file.name).split('.').last().value();
        if (fileType) {
          fileType = fileType.toLowerCase();
        }
        item.file.fileType = this.fileTypeDict[fileType] || '其他';
        item.file.fileSize = this.utils.conver(item.file.size);
        item.file.fileSizeMb = this.utils.converToMb(item.file.size);
      });
    }
  };

  // 封装多选组件数据
  transformMultiSelect(itemArray, model) {
    let insertList = [];
    let ids = [];
    let names = [];
    _.each(model, (value: string) => {
      let keywordsObj = _.groupBy(itemArray, item => item['code']);
      if (keywordsObj.hasOwnProperty(value)) {
        ids.push(value);
        names.push(keywordsObj[value][0]['name']);
      } else {
        let id = this.utils.Base_getUuid();
        ids.push(id);
        names.push(value);
        insertList.push({
          id: id,
          name: value
        });
      }
    });
    return {
      insertList: insertList,
      ids: ids.join('<,>'),
      names: names.join('<,>')
    };
  }

  // 添加文件描述
  addDesc(item) {
    this.modalService.create({
      nzTitle: '文件字段描述',
      nzContent: FileDescComponent,
      nzWidth: 1200,
      nzMaskClosable: false,
      nzComponentParams: {
        tableList: this.utils.DeepCopy(item.fileDescDatas)
      },
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: (response) => {
        let tableList = response.tableList || [];
        // 封装元数据表格
        if (tableList.length) {
          let flag = true;
          _.each(tableList, table => {
            _.each(table['dataList'], data => {
              flag = !data['edit'] && flag;
            });
          });
          if (flag) {
            item.fileDescDatas = Object.assign([], response.tableList || []);
          } else {
            this.confirmModal.show('warning', {
              title: '温馨提示',
              content: '请先保存元数据字段后再试！',
            });
            return false;
          }
        } else {
          item.fileDescDatas = [];
        }
      }
    });
  }

  // 数据集名称字段唯一性校验
  uniqueCheck() {
    return (control: FormControl) => {
      const q = new Promise((resolve, reject) => {
        if (control.dirty && this.utils.Base_HasValue(control.value)) {
          let data = {
            DATASET_NAME: control.value
          };
          let conditons = this.parentData.detailCondition || {};
          data = Object.assign(conditons, data);
          this.requestData.getDataByCondition('headLine/ds/ds_dataset_detail.json', data).subscribe((result) => {
            if (result.data[0]['account'] > 0) {
              resolve({'uniqueCheck': true});
            } else {
              resolve(null);
            }
          }, () => {
            reject({error: true, uniqueCheck: true});
          });
        } else {
          resolve(null);
        }

      });
      return q;
    };
  }

  // 封装parentData数据用于返回列表
  transformData(pFormType, parentData) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 保存
  saveOrModify() {
    let flag = true;
    _.each(this.modelList, (model: any) => {
      if (model.modelType === 'basic') {
        _.each(model.groups, (group: any) => {
          if (group.type === 'fileModel') {
            _.each(group.data, (data: any) => {
              flag = data.status === 'done' && flag;
            });
          }
        });
      }
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '还有文件正在上传，请稍等！'
      });
      return;
    }
    if (this._datasetForm.status === 'PENDING') {
      return;
    }
    if (this._datasetForm.invalid) {
      _.each(this._datasetForm.controls, (controls, groupKey) => {
        this._datasetForm.get(groupKey).markAsDirty();
        this._datasetForm.get(groupKey).updateValueAndValidity();
      });
    }
    if (this._validateForm && this._validateForm.invalid) {
      _.each(this._validateForm.controls, (controls, groupKey) => {
        _.each(controls['controls'], groupCon => {
          for (const key in groupCon['controls']) {
            groupCon['controls'][key].markAsDirty();
            groupCon['controls'][key].updateValueAndValidity();
          }
        });
      });
    }
    if (this._datasetForm.valid && this._validateForm && this._validateForm.valid) {
      // 封装关键词数据 如果字典没有的数据需要新增到关键词表
      // 封装学科名称数据
      let model = this.utils.DeepCopy(this.modelList);
      _.each(model, item => {
        _.each(item['groups'], group => {
          if (!_.contains(['pictureModel', 'fileModel'], group['type'])) {
            _.each(group['data'], data => {
              _.each(group['columns'], column => {
                if (_.contains(['multipop', 'poplist'], column['component'])) {
                  delete data['old' + column['column']];
                  delete data[column['column'] + 'showName'];
                  if (column['columnGroup']) {
                    _.each(column['columnGroup'], colg => {
                      if (colg['model']) {
                        data[colg['column']] = colg['model'];
                      }
                    });
                  }
                }
                if (_.contains(['date', 'datetime'], column['component'])) {
                  delete data[column['column'] + 'Date'];
                  delete data['old' + column['column']];
                }
                if (_.contains(['dateRange'], column['component'])) {
                  if (data[column['column']]) {
                    const bindColumns = column['column'].split(',');
                    _.each(bindColumns, (col: any, colIndex) => {
                      if (item) {
                        data[col] = data[column['column']][colIndex];
                      }
                    });
                    data[column['column']] = data[column['column']].join(',');
                    delete data['old' + column['column']];
                    delete data[column['column'] + 'Date'];
                  }
                }
                if (column['component'] === 'multiSelect') {
                  const multiData = this.transformMultiSelect(column['itemArray'], data[column['column']]);
                  data[column['column']] = multiData ? multiData.ids : [];
                  data[column['column'] + 'Name'] = multiData ? multiData.names : [];
                  if (column['column'] === 'keywordsName') {
                    data['insertList'] = multiData ? multiData.insertList : [];
                  }
                }
                if (column['component'] === 'select') {
                  const multiData = _.find(column['itemArray'], itemArray => itemArray['code'] === data[column['column']]);
                  data[column['column'] + 'Name'] = multiData ? multiData['name'] : '';
                }
              });
            });
            _.each(group['columns'], column => {
              delete column['placeholder'];
              delete column['name'];
              delete column['lableSpan'];
              delete column['itemSpan'];
              delete column['lable'];
              delete column['comment'];
              // delete column['columnGroup'];
              delete column['itemArray'];
            });
          } else if (group['type'] === 'pictureModel') {
            let data = [];
            _.each(group['data'], picture => {
              data.push({
                pictureUrl: picture['originFileObj']['url'],
                pictureSize: picture['originFileObj']['pictureSize'],
                pictureName: picture['name'],
                pictureType: picture['originFileObj']['fileType']
              });
            });
            group['data'] = data;
          } else if (group['type'] === 'fileModel') {
            let data = [];
            _.each(group['data'], file => {
              data.push({
                ID: file['ID'],
                fileUrl: file['originFileObj']['url'],
                fileSize: file['originFileObj']['fileSize'],
                fileSizeMb: file['originFileObj']['fileSizeMb'],
                fileName: file['name'],
                fileType: file['originFileObj']['fileType'],
                fileDescDatas: file['fileDescDatas'],
                isPower: file['isPower']
              });
            });
            group['data'] = data;
          }
        });
      });
      let data = this.utils.DeepCopy(this.baseModel);
      data.modelV = model[0].modelV;
      data.modelList = JSON.stringify(model);
      this.dataService.insertDataSet(data).subscribe(result => {
        if (result.state === 'success') {
          this.confirmModal.show('success', {
            title: '温馨提示',
            content: '保存成功',
            suc: () => {
              this.transformData('listForm', this.parentData);
            }
          });
        }
      });

    }
  }

  // group 组添加一列
  addColumnData(group) {
    let data = {};
    _.each(group.columns, column => {
      data[column['column']] = '';
    });
    data['row'] = group['data'].length;
    group.data.push(data);
    const items = this._validateForm.get(group.groupId) as FormArray;
    items.push(this.createItem(group));
  }

  // group 组删除一列
  removeColumnData(group, index) {
    const arr = this._validateForm.get(group.groupId) as FormArray;
    if (arr.length > 1) {
      arr.removeAt(index);
    }
    group['data'].splice(index, 1);
  }

  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    let validatorList = [];
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    // if (this.utils.Base_HasValue(oneColumn.maxLength)) {
    //   validatorList.push(Validators.maxLength(oneColumn.maxLength));
    // }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    return new FormControl('', validatorList);
  }

  private createItem(group): FormGroup {
    let arrayGroup = {};
    _.each(group['columns'], column => {
      arrayGroup[column['column']] = this.addOneColumn(column);
    });
    return this.formBuilder.group(arrayGroup);
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    let group = {};
    _.each(this.modelList, model => {
      _.each(model['groups'], (item) => {
        let array = [];
        // 如果是文件上传需要将column添加到form里
        // 其他类型则按照data添加
        if (item['type'] === 'fileModel') {
          _.each(item['columns'], column => {
            array.push(this.createItem(item));
          });
        } else if (item['type'] !== 'pictureModel') {
          _.each(item['data'], data => {
            array.push(this.createItem(item));
          });
        }
        group[item['groupId']] = this.formBuilder.array(array);
      });
    });
    return this.formBuilder.group(group);
  }

  // 初始化数据。将group某个组没有data的默认给上值
  initModelData(modelList) {
    _.each(modelList, (model, index) => {
      if (index === 0) {
        model['active'] = true;
      }
      _.each(model['groups'], group => {
        if (this.modeType === 'add') {
          group['data'] = [];
        }
        if (!this.utils.Base_HasValue(group['data']) && !_.contains(['pictureModel', 'fileModel'], group['type'])) {
          let data = {};
          _.each(group['columns'], column => {
            data[column['column']] = column['component'] === 'select' ? null : '';
          });
          data['row'] = 0;
          group['data'] = [data];
        }
        _.each(group['columns'], column => {
          column['name'] = column['column'];
          column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
          switch (column['component']) {
            case 'input':
            case 'textarea':
              column['placeholder'] = '请输入' + column['columnName'];
              break;
            case 'date':
            case 'datetime':
            case 'poplist':
            case 'multipop':
            case 'multiSelect':
              if (column['column'] === 'keywordsName') {
                column['nzMode'] = 'tags';
              } else {
                column['nzMode'] = 'multiple';
              }
            case 'select':
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              column['placeholder'] = '请选择' + column['columnName'];
              column['comment'] = column['columnName'];
              column['lable'] = column['columnName'];
              if (column['columnGroup']) {
                _.each(column['columnGroup'], col => {
                  col['comment'] = col['columnName'];
                  col['lable'] = col['columnName'];
                });
              }
              break;
            case 'dateRange':
              column['placeholder'] = column['columnName'].split(',');
              column['comment'] = group['groupName'];
              column['lable'] = group['groupName'];
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              break;
          }
        });
      });
    });
    return modelList;
  }

  transformModifyData(model) {
    this.baseModel['datasetName'] = model['datasetName'];
    this.baseModel['datasetState'] = model['datasetState'];
    this.baseModel['datasetVersion'] = model['datasetVersion'];
    this.baseModel['dataverseId'] = model['dataverseId'];
    this.baseModel['dataverseName'] = model['dataverseName'];
    this.baseModel['ID'] = model['ID'];
    // picture 值
    _.each(model.modelList, list => {
      _.each(list['groups'], group => {
        if (group['type'] === 'pictureModel') {
          _.each(group['data'], (data, dataIndex) => {
            data['uid'] = dataIndex;
            data['name'] = data['pictureName'];
            data['size'] = data['pictureSize'];
            data['url'] = data['pictureUrl'];
            data['originFileObj'] = {
              url: data['pictureUrl'],
              pictureSize: data['size'],
              fileType: data['pictureType']
            };
          });
        } else if (group['type'] === 'fileModel') {
          _.each(group['data'], (data, dataIndex) => {
            data['status'] = 'done';
            data['uid'] = dataIndex;
            data['name'] = data['fileName'];
            data['type'] = data['fileType'];
            data['isPower'] = data['isPower'];
            data['originFileObj'] = {
              url: data['fileUrl'],
              fileSize: data['fileSize'],
              fileSizeMb: data['fileSizeMb'],
              fileType: data['fileType']
            };
            _.each(data['fileDescDatas'], fileItem => {
              fileItem['cacheData'] = this.utils.DeepCopy(fileItem['dataList']);
            });
          });
          group['columns'][0]['model'] = group['data'][0]['originFileObj']['url'];
        } else {
          _.each(group['columns'], column => {
            if (column['component'] === 'dateRange') {
              _.each(group['data'], data => {
                data[column['column']] = data[column['column']] ? data[column['column']].split(',') : [];
              });
            }
            if (column['component'] === 'multiSelect') {
              _.each(group['data'], data => {
                data[column['column']] = data[column['column']] ? data[column['column']].split('<,>') : [];
              });
            }
          });
        }

      });
    });
    this.modelList = this.initModelData(model.modelList);
  }

  // 更改空间
  changeDataverse(pDataverseId) {
    if (this.utils.Base_HasValue(pDataverseId) && this.selectedDataverse) {
      const current = _.find(this.dataverseColumn.itemArray, item => item['code'] === pDataverseId);
      this.baseModel.dataverseName = current['name'];
      this.dataService.searchModelByDataverseId({
        dataverseId: pDataverseId
      }).subscribe(result => {
        this.modelList = this.initModelData(result.modelList);
        this._validateForm = this.addOneForm();
      });
    } else if (!this.utils.Base_HasValue(pDataverseId) && this.selectedDataverse) {
      this.modelList = [];
    }
  }

  ngOnInit() {
    this._datasetForm = this.formBuilder.group({
      datasetState: ['OUTSALE', [Validators.required]],
      datasetName: new FormControl('datasetName', {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: [this.uniqueCheck().bind(this)],
        updateOn: 'blur'
      }),
      dataverseId: [null, [Validators.required]]
    });
    this.modeType = this.parentData.modeType;
    if (this.modeType === 'add') {
      const parallel$ = observableForkJoin(
        this.requestData.getDataByCondition('headLine/basic/dict/DATASET_STATE.json'),
        this.dataService.searchAllDataverse({})
      );
      parallel$.subscribe(
        values => {
          this.stateArray = values[0]['data'] || [];
          this.dataverseColumn['itemArray'] = values[1] || [];
        });
    } else {
      const datasetId = this.parentData.detailCondition['DATASET_ID'];
      const search = observableForkJoin(
        this.requestData.getDataByCondition('headLine/basic/dict/DATASET_STATE.json'),
        this.dataService.searchAllDataverse({}),
        this.dataService.searchDataSet({
          datasetId: datasetId
        })
      );
      search.subscribe(
        values => {
          this.stateArray = values[0]['data'] || [];
          this.dataverseColumn['itemArray'] = values[1] || [];
          const model = values[2];
          this.transformModifyData(model);
          this._validateForm = this.addOneForm();
        });
    }

  }

}
