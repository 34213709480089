import {Component, Input, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';
import * as echarts from 'echarts';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../../service/request-data.service';
import {AppConfigService} from '../../../../service/app-config.service';
import {UtilsService} from '../../../../service/utils.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-subject-confer-degree-ii',
  templateUrl: './subject-confer-degree-II.component.html',
  styleUrls: ['../subject-visual-report.component.css', './subject-confer-degree-II.component.css']
})
export class SubjectConferDegreeIIComponent implements OnInit {

  isfull: any;
  nowYear: any = '';
  nowDate = null;
  // 近五年教学获奖情况
  teachingAwardsData = [];
  // 近5年在校生取得成果
  studentResultsData = {
    paper: {},
    patent: {},
    entry: {},
    scientific: {}
  };
  // 近五年在校生参加国内国际学术交流情况
  studentMeetingData = [];
  // 研究生学位授予情况
  studentAwardedData = {
    doctor: [],
    master: []
  };
  // 近5年毕业生就业基本情况
  graduateEmployData = [];
  areaHeight = '34vh';
  centerAreaHeight = '72vh';
  model: any = {
    left1: {},
    left2: {},
    center: {},
    right1: {},
    right2: {}
  };

  constructor(
    private requestDataService: RequestDataService,
    private utils: UtilsService
  ) {
  }

  formaterStr(params, index, fontCount) {
    let newParamsName = '';// 最终拼接成的字符串
    let paramsNameNumber = params.length;// 实际标签的个数
    let provideNumber = fontCount;// 每行能显示的字的个数
    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
    // 条件等同于rowNumber>1
    if (paramsNameNumber > provideNumber) {
      /** 循环每一行,p表示行 */
      for (let p = 0; p < rowNumber; p++) {
        let tempStr = '';// 表示每一次截取的字符串
        let start = p * provideNumber;// 开始截取的位置
        let end = start + provideNumber;// 结束截取的位置
        // 此处特殊处理最后一行的索引值
        if (p == rowNumber - 1) {
          // 最后一次不换行
          tempStr = params.substring(start, paramsNameNumber);
        } else {
          // 每一次拼接字符串并换行
          tempStr = params.substring(start, end) + '\n';
        }
        newParamsName += tempStr;// 最终拼成的字符串
      }
    } else {
      // 将旧标签的值赋给新标签
      newParamsName = params;
    }
    //将最终的字符串返回
    return newParamsName;
  }

  // 近五年教学获奖情况
  teachingAwards(pListData) {
    let leftTitle = [];
    let seriesData = [];
    // 图例
    let legend = ['特等奖', '一等奖', '二等奖'];
    for (let obj of pListData) {
      leftTitle.push(obj['AWARD_CATEGORY']);
    }
    for (let oneLe of legend) {
      let seriesObj = {
        name: oneLe,
        type: 'bar',
        data: [],
        label: {
          normal: {
            position: 'right',
            show: true,
            color: '#BFCBFF'
          }
        }
      };
      for (let obj of pListData) {
        seriesObj.data.push(obj[oneLe]);
      }
      seriesData.push(seriesObj);
    }
    let option = {
      color: ['#F9605C', '#FDB628', '#249CF9'],
      legend: {
        data: legend,
        top: 0,
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#303743'
          }
        },
        axisLabel: {
          color: '#BFCBFF',
          formatter: '{value}'
        }
      },
      yAxis: {
        type: 'category',
        data: leftTitle,
        nameTextStyle: {},
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#BFCBFF',
          formatter: (params, index) => {
            return this.formaterStr(params, index, 5);
          }
        }
      },
      series: seriesData
    };
    return option;
  }

  // 近5年在校生取得成果
  studentResults(pListData) {
    let downTitle = ['发表论文篇数', '已获授权专利数', '创新创业获奖数', '行业竞赛获奖数', '科研成果获奖数'];
    let seriesData = [];
    // 图例
    let legend = ['硕士研究生', '博士研究生'];
    let seriesObjMaster = {
      name: '硕士研究生',
      type: 'bar',
      data: [],
      barWidth: '20%',
      label: {
        normal: {
          position: 'top',
          show: true,
          color: '#BFCBFF'
        }
      }
    };
    let masterPaper = pListData.paper['MASTER'] || 0;
    let masterPatent = pListData.patent ['MASTER'] || 0;
    let masterChuangx = pListData.entry['MASTER'] || 0;
    let masterChuangy = pListData.entry['MASTER1'] || 0;
    let masterScientific = pListData.scientific['MASTER'] || 0;
    seriesObjMaster.data.push(masterPaper, masterPatent, masterChuangx, masterChuangy, masterScientific);
    seriesData.push(seriesObjMaster);

    let seriesObjDoctor = {
      name: '博士研究生',
      type: 'bar',
      data: [],
      barWidth: '20%',
      label: {
        normal: {
          position: 'top',
          show: true,
          color: '#BFCBFF'
        }
      }
    };
    let docterPaper = pListData.paper['DOCTOR'] || 0;
    let docterPatent = pListData.patent ['DOCTOR'] || 0;
    let docterChuangx = pListData.entry['DOCTOR'] || 0;
    let docterChuangy = pListData.entry['DOCTOR1'] || 0;
    let docterScientific = pListData.scientific['DOCTOR'] || 0;
    seriesObjDoctor.data.push(docterPaper, docterPatent, docterChuangx, docterChuangy, docterScientific);
    seriesData.push(seriesObjDoctor);

    let option = {
      color: ['#2A89EE', '#FB6D5C'],
      legend: {
        data: legend,
        top: 0,
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: downTitle,
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6076AD'
          }
        },
        splitLine: {show: false},
        axisLabel: {
          color: '#BFCBFF',
          rotate: '45',
          fontSize: 12
        }
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#303743'
          }
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#BFCBFF',
          formatter: '{value}'
        }
      },
      series: seriesData
    };
    return option;
  }

  // 近五年在校生参加国内国际学术交流情况
  studentMeeting(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    let seriesData = [];
    // 图例
    let legend = ['国际交流', '国内交流', '资助经费'];
    for (let oneLe of legend) {
      let seriesObj = {
        name: oneLe,
        type: 'line',
        data: [],
        yAxisIndex: 0,
        label: {
          normal: {
            position: 'top',
            show: true,
            color: '#BFCBFF'
          }
        },
        smooth: true,
        lineStyle: {
          width: 5
        },
      };
      if (oneLe === '资助经费') {
        seriesObj.yAxisIndex = 1;
      }
      for (let year of downTitle) {
        let findData = _.find(pListData, (item) => {
          return item['YEAR'] === year;
        });
        if (this.utils.Base_HasValue(findData)) {
          let value = 0;
          if (oneLe === '国际交流') {
            value = findData['INTERNATIONAL'] || 0;
          } else if (oneLe === '国内交流') {
            value = findData['DOMESTIC'] || 0;
          } else if (oneLe === '资助经费') {
            value = findData['FINANCIAL_SUPPORT_AMOUNT'] || 0;
          }
          seriesObj.data.push(value);
        } else {
          seriesObj.data.push(0);
        }
      }
      seriesData.push(seriesObj);
    }
    let option = {
      color: ['#F74C5A', '#FD9F28', '#2887ED'],
      legend: {
        data: legend,
        top: 0,
        itemHeight: 10,
        icon: 'roundRect',
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: '{value}年'
          }
        }
      ],
      yAxis: [
        {
          name: '人数',
          type: 'value',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 22, 0, 0]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: '经费(万元)',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 0, 18]
          },
          splitLine: {show: false},
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        }
      ],
      series: seriesData
    };
    return option;
  }

  // 研究生学位授予情况
  studentAwarded(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    let seriesData = [];
    // 图例
    let legend = ['硕士研究生', '博士研究生'];
    for (let oneLe of legend) {
      let seriesObj = {
        name: oneLe,
        type: 'line',
        data: [],
        yAxisIndex: 0,
        label: {
          normal: {
            position: 'top',
            show: true,
            color: '#BFCBFF',
            formatter: '{c}个'
          }
        },
        smooth: true,
        lineStyle: {
          width: 5
        },
      };
      for (let year of downTitle) {
        let findData = {};
        if (oneLe === '硕士研究生') {
          findData = _.find(pListData.master, (item) => {
            return item['yearDo'] === year;
          });
        } else {
          findData = _.find(pListData.doctor, (item) => {
            return item['yearDo'] === year;
          });
        }
        if (this.utils.Base_HasValue(findData)) {
          let value = findData['ACOUNT'] || 0;
          seriesObj.data.push(value);
        } else {
          seriesObj.data.push(0);
        }
      }
      seriesData.push(seriesObj);
    }
    let option = {
      color: ['#F74C5A', '#08E87F'],
      legend: {
        data: legend,
        itemHeight: 10,
        top: 0,
        icon: 'roundRect',
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: '{value}年'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        }
      ],
      series: seriesData
    };
    return option;
  }

  graduateEmploy(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    let seriesData = [];
    // 图例
    let legend = ['签订就业协议、劳动合同', '升学-国内', '升学-国外', '自主创业', '其它形式就业', '未就业'];
    for (let onl of legend) {
      let seriesObj = {
        name: onl,
        type: 'bar',
        data: [],
        stack: '总量',
        barWidth: '25%',
        label: {
          normal: {
            position: 'inside',
            show: true,
            color: '#BFCBFF'
          }
        }
      };
      for (let year of downTitle) {
        let findData = _.filter(pListData, (item) => {
          return item['YEAR'] === year;
        });
        if (this.utils.Base_HasValue(findData)) {
          let value = 0;
          for (let oneData of findData) {
            let key = onl;
            if (onl === '升学-国内' || onl === '升学-国外') {
              key = key.replace('升学-', '');
            }
            value += oneData[key];
          }
          seriesObj.data.push(value);
        } else {
          seriesObj.data.push(0);
        }
      }
      seriesData.push(seriesObj);
    }

    let option = {
      color: ['#FFAC2A', '#6954EB', '#2B8AEE', '#FF9860', '#FF5961', '#6884F2'],
      legend: {
        data: legend,
        top: 0,
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        top: '16%',
        left: '2%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: downTitle,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#6076AD'
          }
        },
        splitLine: {show: false},
        axisLabel: {
          color: '#BFCBFF',
          fontSize: 12,
          margin: 14
        }
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#303743'
          }
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#BFCBFF',
          formatter: '{value}'
        }
      },
      series: seriesData
    };
    return option;

  }

  // 初始化数据
  initData() {
    // 近五年教学获奖情况
    if (this.utils.Base_HasValue(this.teachingAwardsData)) {
      this.model.left1 = this.teachingAwards(this.teachingAwardsData);
    }
    this.model.left2 = this.studentResults(this.studentResultsData);
    if (this.utils.Base_HasValue(this.studentMeetingData)) {
      this.model.right1 = this.studentMeeting(this.studentMeetingData);
    }
    this.model.right2 = this.studentAwarded(this.studentAwardedData);
    if (this.utils.Base_HasValue(this.graduateEmployData)) {
      this.model.center = this.graduateEmploy(this.graduateEmployData);
    }
  }

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  ngOnInit() {
    this.nowYear = moment().format('YYYY');
    this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    setInterval(() => {
      this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    },1000);
    let conditon = {
      EVALUATION_YEAR_BEGIN: +this.nowYear - 5,
      EVALUATION_YEAR_END: +this.nowYear
    };
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_teaching_award.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/paper_student_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/patent_student_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/entry_student_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/scientific_student_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_attend_meeting.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/master_degree_awarded.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/doctor_degree_awarded.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_graduate_employment.json', conditon),
    );
    parallel.subscribe(response => {
      this.teachingAwardsData = response[0]['data'];
      if (this.utils.Base_HasValue(response[1])) {
        this.studentResultsData.paper = response[1]['data'][0];
      }
      if (this.utils.Base_HasValue(response[2])) {
        this.studentResultsData.patent = response[2]['data'][0];
      }
      if (this.utils.Base_HasValue(response[3])) {
        this.studentResultsData.entry = response[3]['data'][0];
      }
      if (this.utils.Base_HasValue(response[4])) {
        this.studentResultsData.scientific = response[4]['data'][0];
      }
      if (this.utils.Base_HasValue(response[5])) {
        this.studentMeetingData = response[5]['data'];
      }
      if (this.utils.Base_HasValue(response[6])) {
        this.studentAwardedData.master = response[6]['data'];
      }
      if (this.utils.Base_HasValue(response[7])) {
        this.studentAwardedData.doctor = response[7]['data'];
      }
      if (this.utils.Base_HasValue(response[8])) {
        this.graduateEmployData = response[8]['data'];
      }
      // 初始化数据
      this.initData();
    });
  }

}
