import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from '../../service/request-data.service';
import {UserInfoService} from '../../service/user-info.service';
import {UtilsService} from '../../service/utils.service';
import {AppConfigService} from '../../service/app-config.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import * as _un from 'underscore';


import {CascaderOption} from 'ng-zorro-antd/cascader';
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as cartesian from 'cartesian';
import {UploadService} from "../../service/upload.service";
import {UUID} from "angular2-uuid";


let catelv01 = [];
let catelv02 = {};
let catelv03 = {};

@Component({
  selector: 'app-input-sku',
  templateUrl: './input-sku.component.html',
  styleUrls: ['./input-sku.component.css']
})
export class InputSkuComponent implements OnInit {
  @Input() parentData: any;
  @Input() modeType = 'add';
  @Input() hulkColumn: any;

  apiKey: 'tz9q2op8zdy73lw8clfcn70muzvy4o4wk28c8na72bjv6ma8';
  initConfig: any;
  spuType: any; // 商品类型

  public listDatas: any;
  public datas: any;

  showModelBacthAddSku: Boolean = false;

  batchSku = {
    skuInput: null,
    inputType: 'N_C_U_P', // 名称/编号/单位/价格
    typeOption: [
      {
        "code": "N_C_U_P",
        "name": "名称/编号/单位/价格"
      },
      {
        "code": "N_C_U_P_S",
        "name": "名称/编号/单位/价格/库存"
      }
    ]
  }


  constructor(
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService,
    private util: UtilsService) {

    if (!this.util.Base_HasValue(this.config.config.defaultThor)) {
      console.error("该功能需要配置defaultThor，请先配置。");
    }

    this.initConfig = {
      base_url: '/tinymce',
      language: 'zh_CN',
      language_url: '/assets/tinymce/langs/zh_CN.js',
      height: 500,
      font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;',
      plugins: [
        'advlist autolink image imagetools lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'table emoticons template paste help axupimgs bdmap importword fullscreen'
      ],
      toolbar: 'fullscreen | undo redo | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | image axupimgs | print preview media fullpage | ' +
        'forecolor backcolor emoticons | bdmap importword code help',
      images_upload_handler: (blobInfo, success, failure, progress) => {
        this.doUploadFile(blobInfo, success, failure, progress);
      },
      importword_handler: (editor, files, next) => {
        this.changeWord(editor, files, next);
      },
      importword_filter: function (result, insert, message) {
        // 自定义操作部分
        insert(result); //回插函数
      }
    };

  }

  changeWord(editor, files, next) {
    var file_name = files[0].name;
    if (file_name.substr(file_name.lastIndexOf('.') + 1) == 'docx') {
      // todo 可以加一个转化完成的提示。
      // editor.notificationManager.open({
      //   text: '正在转换中...',
      //   type: 'info',
      //   closeButton: false,
      // });
      next(files);
    } else {
      editor.notificationManager.open({
        text: '目前仅支持docx文件格式，若为doc111，请将扩展名改为docx',
        type: 'warning',
      });
    }
    // next(files);
  }

  isBlob(blob) {
    return typeof Blob !== 'undefined' && blob instanceof Blob;
  }

  isFile(obj) {
    return typeof File !== 'undefined' && obj instanceof File;
  };

  doUploadFile(blobInfo, success, failure, progress) {
    let file = blobInfo.blob();//转化为易于理解的file对象
    if (this.isFile(file)) {
      this.uploadFiletoLocal(file, success, failure, progress);
    } else {
      this.uploadFiletoServer(file, success);
    }
  }

  // 本地浏览器，直接传输到阿里云服务器。(或者腾讯云服务器）
  uploadFiletoLocal(file, success, failure, progress) {
    let fileName = this.util.Base_getFileName(file.name);
    let fileType = this.util.Base_getFileType(file.name);
    this.uploadService.doUpLoadSimple(file, fileName, fileType, (progressInfo) => {
      let percent = (progressInfo.loaded / progressInfo.total * 100).toFixed(0);
      if (this.util.Base_HasValue(progress)) {
        progress(percent);
      }
    }).then((result) => {
      let url = '';
      if (this.config.config['userOssOrCos'] === 'COS') {
        url = this.config.config['COS']['domain'] + '/' + result['url'];
      } else if (this.config.config['userOssOrCos'] === 'OSS') {
        url = this.config.config['OSS']['domain'] + '/' + result['name'];
      }
      success(url);
    }).then((result) => {
      failure(result);
    });
  }

  uploadFiletoServer(file, success) {
    this.uploadService.uploadFileCloud(file).subscribe(response => {
      if (this.util.Base_HasValue(response) && response.type === 4) {
        let url = _.get(response, ['body', 'url']);
        success(url);
      }
    });
  }


  public modeId = ""; // id

  public pictureList = {
    fileList: [],
    componentConfig: {
      title: "产品图片",
      col_nzSpan: 24,
      lable_nzSm: 2,
      control_nzSm: 22,
      nzLimit: 5, // 五张图
      info: true,
      infoTemplate: [
        {
          text: '1、支持上传的图片类型：jpg、png、jpeg、gif。'
        },
        {
          text: '2、单张图片大小不超过20M，每次上传图片总数不超过5张。'
        },
        {
          text: '3、图片上传成功后，需要保存，否则不会显示在系统中。'
        }
      ]
    }
  };

  public mainJson = {
    skuDatas: [] // sku普通商品的明细
  };

  public filterColumns = []; // 参与过滤的字段。

  public specNameArry = []; // 属性组
  public allChecked = false;
  private indeterminate = false;
  public allSPEC: any;// 所有的属性的数据
  public SPEC: any;// 属性数据
  public hasSPEC = false;
  public pushCreateSku = true;
  public formData: any = {
    'BUSI_ID': {
      'column': 'BUSI_ID',
      'name': 'BUSI_ID_NAME',
      'lable': '店铺',
      'comment': '店铺',
      'placeholder': '请选择店铺',
      'model': null,
      'visible': true,
      'require': true
    },
    'SPU_NAME': {
      'column': 'SPU_NAME',
      'name': 'SPU_NAME_NAME',
      'lable': '产品名称',
      'comment': '产品名称',
      'placeholder': '请输入产品名称',
      'model': null,
      'visible': true,
      'require': true
    },
    'SPU_CODE': {
      'column': 'SPU_CODE',
      'name': 'SPU_CODE_NAME',
      'lable': '产品编码',
      'comment': '产品编码',
      'placeholder': '请输入产品编码',
      'model': null,
      'visible': true,
      'require': true
    },
    'CATEGORIES_ID': {
      'column': 'CATEGORIES_ID',
      'name': 'CATEGORIES_ID_NAME',
      'lable': '产品分类',
      'comment': '产品分类',
      'placeholder': '请选择产品分类',
      'model': null,
      'modelName': null,
      'visible': true,
      'require': true
    },
    'BRAND_ID': {
      'column': 'BRAND_ID',
      'name': 'BRAND_ID_NAME',
      'lable': '品牌',
      'comment': '品牌',
      'placeholder': '请选择品牌',
      'model': null,
      'visible': true,
      'require': true
    },
    'SPU_DETAIL': {
      'column': 'SPU_DETAIL',
      'name': 'SPU_DETAIL_NAME',
      'component': 'tinymce',
      'lableSpan': 2,
      'itemSpan': 22,
      'customize': 24,
      'lable': '商品详情',
      'comment': '商品详情',
      'placeholder': '商品详情',
      'model': null,
      'disable': true,
      'visible': true,
      'require': true
    }
  };

  public showModelImg = false;
  public showModelImgUrl = '';
  public showModelImgName = '';
  public showModelImgSku = false; // 控制sku明细图片的弹出框
  public showModelImgTitle = '';
  public showModelImgPic = false;
  public showModelImgDatas = false;
  public detailDatas: any = {
    'SKU_PRICE': {
      'column': 'SKU_PRICE',
      'name': 'SKU_PRICE_NAME',
      'lable': '商品价格',
      'comment': '商品价格',
      'placeholder': '请输入商品价格',
      'model': null,
      'visible': true,
      'require': true
    },
    'SKU_STOCK': {
      'column': 'SKU_STOCK',
      'name': 'SKU_STOCK_NAME',
      'lable': '商品库存',
      'comment': '商品库存',
      'placeholder': '请输入商品库存',
      'model': null,
      'visible': true,
      'require': true
    },
    'detailPictureList': {
      fileList: [],
      componentConfig: {
        col_nzSpan: 24,
        lable_nzSm: 2,
        control_nzSm: 22,
        nzLimit: 5, // 五张图
        info: true,
        infoTemplate: [
          {
            text: '1、支持上传的图片类型：jpg、png、jpeg、gif。'
          },
          {
            text: '2、单张图片大小不超过20M，每次上传图片总数不超过6张。'
          },
          {
            text: '3、图片上传成功后，需要保存，否则不会显示在系统中。'
          }
        ]
      }
    }
  };

  public requestData: any;

  formatMainToEntity(datas) {
    let entity: any = {};
    _.each(datas, (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatSelectEntity(datas) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        const column = _.camelCase(oneKeys);
        obj[column] = OneData[oneKeys];
      });
      entity.push(obj);
    });
    return entity;
  }

  toSpuModel(spuData) {
    let entity = {
      "esSpu": {
        "brandId": "string",

      },
      "esSpuPicList": [],
      "esSpuSpecList": []
    };

    return entity;
  }

  toSkuDataList(spuData) {
    let entity: any = [];

    return entity;
  }

  toSkuPointDataList(spuData) {
    let entity: any = [];

    return entity;
  }


  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  findOneColumnValue(pColumnName) {
    let oneValue = null; // 定义一个返回值，给予默认值为控制
    _.each(this.formData, (oneOneColum) => { // 循环 columns 层级
      if (oneOneColum['column'] === pColumnName) { // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
        oneValue = _.get(oneOneColum, 'model');
      }
      let columnGroup = _.get(oneOneColum, 'columnGroup', null); // 找到 columnGroup 层级
      _.each(columnGroup, (oneGroupColumn) => {
        if (oneGroupColumn['column'] === pColumnName) {  // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
          oneValue = _.get(oneGroupColumn, 'model', null);
        }
      });
    });
    return oneValue; // 返回值
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput() {
    let result = true;
    result = this.checkOneColumn('BUSI_ID', '店铺');
    if (!result) {
      return result;
    }
    result = this.checkOneColumn('SPU_NAME', '产品名称');
    if (!result) {
      return result;
    }
    result = this.checkOneColumn('BRAND_ID', '品牌');
    if (!result) {
      return result;
    }
    result = this.checkOneColumn('CATEGORIES_ID', '产品分类');
    if (!result) {
      return result;
    }

    // Begin 控制商品属性为必填项
    // let count = 0;
    // _.each(this.SPEC, item => {
    //   let modelArray = _.get(item, ['model'], null);
    //   if (this.util.Base_HasValue(modelArray)) {
    //     count++;
    //   }
    // });
    // result = (count > 0);
    // if (!result) {
    //   this.confirmModal.show('error', {'title': '必填项', 'content': '商品属性至少选一个'});
    // }
    // End 控制商品属性为必填项

    return result;
  }

  clearSku() {
    this.pushCreateSku = true;
    this.mainJson.skuDatas = [];
    this.specNameArry = [];
    this.filterColumns = [];
  }

  doFilter(values, pOneSpec) {
    this.checkAll(false); // 勾选，全部选空。
    let fColumn = _.replace(pOneSpec.column, 'SPEC_ITEM_ID_', 'SPEC_ITEM_F_');

    if (this.util.Base_HasValue(values)) {
      this.filterColumns = _.union(this.filterColumns, [fColumn]);
    } else {
      this.filterColumns = _.without(this.filterColumns, fColumn);
    }

    _.each(this.mainJson.skuDatas, oneSku => {
      oneSku[fColumn] = false;
    }); // 初始化
    _.each(this.mainJson.skuDatas, oneSku => {
      _.each(values, oneSearch => {
        let index = _.indexOf(oneSku.SPEC_LIST, oneSearch);
        if (!oneSku[fColumn]) {
          oneSku[fColumn] = index > -1;
        }
      });
    });
    _.each(this.mainJson.skuDatas, oneSku => {
      let selected = 0;
      _.each(this.filterColumns, oneColumn => {
        if (oneSku[oneColumn]) {
          selected++;
        }
      });
      oneSku.checked = selected === this.filterColumns.length && selected > 0;
    });

  }

  createSku() {
    if (!this.checkInput()) {
      return false;
    } else {
      let barndId = this.formData.BRAND_ID.model;
      this.pushCreateSku = false;
      this.mainJson.skuDatas = [];
      this.specNameArry = [];
      let specValueArry = [];
      let index = 0;
      _.each(this.SPEC, item => {
        let modelArray = _.get(item, ['model'], null);
        let selected = _.filter(_.get(item, ['listOfOption']), oneOption => {
          return _.indexOf(modelArray, oneOption.value) > -1;
        });
        if (this.util.Base_HasValue(modelArray)) {
          this.specNameArry.push({
              'column': 'SPEC_ITEM_ID_' + index,
              'code': _.get(item, ['SPEC_ID']),
              'name': _.get(item, ['SPEC_NAME']),
              'filterValues': selected
            }
          );
          index++;
          specValueArry.push(modelArray);
        }
      });
      let cartesianResult = cartesian(specValueArry);
      let specArray = [];
      let SPEC_LISTArray = [];
      _.each(cartesianResult, oneArray => {
        let temp = [];
        let tempCode = [];
        _.each(oneArray, oneSpec => {
          let tempRow = _.filter(this.allSPEC, function (o) {
            return o.ID === oneSpec;
          });
          temp.push(tempRow[0]['SPEC_VALUE']);
          tempCode.push(tempRow[0]['ID']);

        });
        specArray.push(temp);
        SPEC_LISTArray.push(tempCode);
      });
      let inx = 0;
      _.each(specArray, oneSku => {
        let obj = {
          'inx': inx
        };
        let inxName = 0;
        _.each(this.specNameArry, oneName => {
          obj['SPEC_ITEM_ID_' + inxName] = oneSku[inxName];
          obj['SPEC_ITEM_F_' + inxName] = false;
          inxName++;
        });
        obj['SKU_NAME'] = this.formData.SPU_NAME.model + ' ' + _.join(oneSku, ' ');
        obj['SKU_CODE'] = null;
        obj['SPEC_LIST'] = SPEC_LISTArray[inx];
        obj['SKU_PRICE'] = null;
        obj['SKU_STOCK'] = null;
        obj['fileList'] = [];
        this.mainJson.skuDatas.push(obj);
        inx++;
      });

      this.pushCreateSku = false;
    }
  }

  public getSingleFile(testCaseUrl: string): Observable<any> {
    return this.httpClient.get(testCaseUrl);
  }

  optionList: string[] = [];
  selectedUser = null;
  isLoading = false;

  getListOfOption(pData, pValueColumn, pTextColumn) {
    let listOfOption: Array<{ value: string; text: string }> = [];
    pData.forEach(item => {
      listOfOption.push({
        value: item[pValueColumn],
        text: item[pTextColumn]
      });
    });
    return listOfOption;
  }


  ngOnInit() {
    this.spuType = _.split(this.parentData.btnName, '.')[3];
    this.modeType = this.parentData.modeType;
    this.modeId = this.parentData.id;
    const search = observableForkJoin(
      this.requestService.getDataByCondition('agriculture/basic/dict/ES_CATEGORIES_SELECT.json'),
      this.requestService.getDataByCondition('agriculture/basic/dict/ES_BRAND_SELECT.json'),
      this.requestService.getDataByCondition('agriculture/basic/dict/ES_BUSINESS_WITH_ROLE.json')
    );
    search.subscribe(
      values => {
        this.buildCategoriesData(values[0]['data'] || []);
        let brandData = values[1]['data'] || [];
        let busiData = values[2]['data'] || [];
        _.set(this.formData.BRAND_ID, 'itemArray', this.getListOfOption(brandData, 'ID', 'BRAND_NAME'));
        _.set(this.formData.BUSI_ID, 'itemArray', this.getListOfOption(busiData, 'code', 'name'));
      }
    );
    if (this.modeType == "modify") {
      const search = observableForkJoin(
        this.requestService.getDataByCondition('agriculture/sku/read/es_spu_list.json', {'ID': this.modeId}), //0
        this.requestService.getDataByCondition('agriculture/sku/read/es_sku_list.json', {'SPU_ID': this.modeId}),//3
      );
      search.subscribe(
        values => {
          this.fillBack(_.get(values, [0, 'data', 0]));
          this.formData['CATEGORIES_ID']['model'] = _.get(values, [0, 'data', 0])['CATEGORIES_ID'].split(',');
          this.formData['CATEGORIES_ID']['modelName'] = _.get(values, [0, 'data', 0])['CATEGORIES_ID_DICT'].split(',').join(" / ");
          let spu = _.get(values, '[0].data[0]');
          this.onCategoriesChanges(this.formData['CATEGORIES_ID']['model'], spu);
          if (_.get(spu, 'SPU_PIC_JSON')) this.pictureList.fileList = JSON.parse(spu['SPU_PIC_JSON']);
          let skuList = _.get(values, '[1].data');

          _.each(skuList, (itemSku, row) => {
            if (row == 0) this.pushCreateSku = false;
            this.mainJson.skuDatas[row] = {
              "inx": row,
              "checked": false,
              "ID": itemSku["ID"],
              "SORT_INDEX": itemSku["SORT_INDEX"],
              "SKU_NAME": itemSku["SKU_NAME"],
              'SKU_CODE': itemSku['SKU_CODE'],
              "SKU_PRICE": itemSku["SKU_PRICE"],
              "SKU_STOCK": itemSku["SKU_STOCK"],
              "SKU_UNIT": itemSku["SKU_UNIT"],
              "SPEC_ITEM_F_0": false,
              "SPEC_ITEM_F_1": false,
              "SPEC_ITEM_F_2": false,
            };

            if (itemSku['SKU_SPEC_JSON']) this.mainJson.skuDatas[row].specList = JSON.parse(itemSku['SKU_SPEC_JSON']);
            _.each(this.mainJson.skuDatas[row].specList, (skuSpecItem, rowJ) => {
              this.mainJson.skuDatas[row]["SPEC_ITEM_ID_" + rowJ] = skuSpecItem;
            });
            if (itemSku['SKU_PIC_JSON']) this.mainJson.skuDatas[row].fileList = JSON.parse(itemSku['SKU_PIC_JSON']);
          });
        }
      );
    }
  }

  fillBack(pData) {
    const keys = _.keys(this.formData);
    _.each(keys, oneColumn => {
      this.formData[oneColumn]['model'] = pData[oneColumn]
    })
  }

  /**
   * 组织商品分类数据
   * @param pData
   */
  buildCategoriesData(pData) {
    catelv01 = [];
    catelv02 = {};
    catelv03 = {};
    let dataLv01 = _.filter(pData, function (o) {
      return o.SORT_LV == 1;
    });
    _.each(dataLv01, oneData => {
      let obj = {
        value: oneData.code,
        label: oneData.name
      };
      catelv01.push(obj);
    });

    let dataLv02 = _.filter(pData, function (o) {
      return o.SORT_LV == 2;
    });
    _.each(dataLv02, oneData => {
      let obj = {
        value: oneData.code,
        label: oneData.name,
        isLeaf: oneData.FINAL_MARK == 'LAST_STAGE'
      };
      let tempLv02 = _.get(catelv02, [oneData.UP_ID], null);
      if (!this.util.Base_HasValue(tempLv02)) {
        catelv02[oneData.UP_ID] = [];
      }
      catelv02[oneData.UP_ID].push(obj);
    });

    let dataLv03 = _.filter(pData, function (o) {
      return o.SORT_LV == 3;
    });
    _.each(dataLv03, oneData => {
      let obj = {
        value: oneData.code,
        label: oneData.name,
        isLeaf: oneData.FINAL_MARK == 'LAST_STAGE'
      };
      let tempLv03 = _.get(catelv03, [oneData.UP_ID], null);
      if (!this.util.Base_HasValue(tempLv03)) {
        catelv03[oneData.UP_ID] = [];
      }
      catelv03[oneData.UP_ID].push(obj);
    });
  }

  /**
   * 当商品分类变化的时候，查询商品属性组
   * @param values
   */
  onCategoriesChanges(values: string[], spu): void {
    if (!this.util.Base_HasValue(values)) {
      this.SPEC = [];
      this.hasSPEC = false;
    } else {
      let len = values.length;
      let categoriesId = _.get(values, len - 1);
      const search = observableForkJoin(
        this.requestService.getDataByCondition('agriculture/basic/dict/ES_SPEC_SELECT.json', {'CATEGORIES_ID': categoriesId}),
        this.requestService.getDataByCondition('agriculture/basic/dict/ES_SPEC_ITEM_SELECT.json', {'CATEGORIES_ID': categoriesId}),
      );
      search.subscribe(
        values => {
          let datas = values[0]['data'] || [];
          let itemDatas = values[1]['data'] || [];
          this.SPEC = [];
          this.allSPEC = itemDatas;
          _.each(datas, (item) => {
            let oneItems = _.filter(itemDatas, function (o) {
              return o.SPEC_ID == item.ID;
            });
            let listOfOption = [];
            _.each(oneItems, (itemTwo) => {
              listOfOption.push({
                'text': itemTwo.SPEC_VALUE,
                'value': itemTwo.ID
              });
            });
            this.SPEC.push({
              'ID': item.ID,
              'SPEC_NAME': item.SPEC_NAME,
              'model': null,
              'listOfOption': listOfOption
            });
          });
          this.hasSPEC = this.util.Base_HasValue(this.SPEC);
          if (spu && _.get(spu, 'SPU_SPEC_JSON')) {
            let spuSpecList = JSON.parse(spu['SPU_SPEC_JSON']);
            _.each(spuSpecList, (specItem, row) => {
              this.SPEC[row]['model'] = specItem['model'];
            });
          }
        }
      );
    }
  }

  /**
   * 异步加载商品分类数据。
   * @param node
   * @param index
   */
  loadCategoriesData(node: CascaderOption, index: number): PromiseLike<void> {
    return new Promise(resolve => {
      if (index < 0) {
        // if index less than 0 it is root node
        node.children = catelv01;
      } else if (index === 0) {
        node.children = catelv02[node.value];
      } else {
        node.children = catelv03[node.value];
      }
      resolve();
    });
  }

  /**
   * 当商品分类变化的时候。
   * @param selectedOptions
   */
  onCategoriesSelectChange(selectedOptions: CascaderOption[]): void {
    this.formData.CATEGORIES_ID.modelName = selectedOptions.map(o => o.label).join(' / ');
  }

  checkAll(value: boolean): void {
    this.mainJson.skuDatas.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.mainJson.skuDatas.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  previewImg(pImgUrl) {
    this.showModelImg = true;
    this.showModelImgUrl = pImgUrl.pictureUrl;
    this.showModelImgName = pImgUrl.pictureName;
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }


  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  batchModfiyPic() {
    if (this.selectMore(this.mainJson.skuDatas, true) < 1) {
      return false;
    } else {
      this.showModelImgSku = true;
      this.showModelImgPic = true;
      this.showModelImgDatas = false;

      let selectList = _.filter(this.mainJson.skuDatas, (item) => {
        return item['checked'];
      });
      selectList = _.cloneDeep(selectList);
      this.detailDatas.detailPictureList.fileList = [];

      const isSingle = selectList.length == 1; //
      if (isSingle) {
        this.showModelImgTitle = "修改商品图片"
        this.detailDatas.detailPictureList.fileList = selectList[0].fileList;
      } else {
        this.showModelImgTitle = "批量修改商品图片"
      }
    }
  }

  /**
   * 批量修改
   */
  batchModfiy() {
    if (this.selectMore(this.mainJson.skuDatas, true) < 1) {
      return false;
    } else {
      this.showModelImgSku = true;
      this.showModelImgPic = false;
      this.showModelImgDatas = true;

      let selectList = _.filter(this.mainJson.skuDatas, (item) => {
        return item['checked'];
      });
      selectList = _.cloneDeep(selectList);
      this.detailDatas.SKU_PRICE.model = null;
      this.detailDatas.SKU_STOCK.model = null;
      const isSingle = selectList.length == 1; //
      if (isSingle) {
        this.showModelImgTitle = "修改商品库存与价格"
        this.detailDatas.SKU_PRICE.model = selectList[0].SKU_PRICE;
        this.detailDatas.SKU_STOCK.model = selectList[0].SKU_STOCK;
      } else {
        this.showModelImgTitle = "批量修改商品库存与价格"
      }
    }
  }

  /**
   * 弹窗的确定按钮，有2种状态，一种是修改价格与库存，一种是修改图片。
   */
  showModelImgSkuOk() {
    let selectList = _.filter(this.mainJson.skuDatas, (item) => {
      return item['checked'];
    });
    if (this.showModelImgDatas) {
      if (this.util.Base_HasValue(this.detailDatas.SKU_PRICE.model)) {
        _.each(selectList, oneRow => {
          oneRow.SKU_PRICE = this.detailDatas.SKU_PRICE.model;
        });
        this.showModelImgSku = false;
      } else if (this.util.Base_HasValue(this.detailDatas.SKU_STOCK.model)) {
        _.each(selectList, oneRow => {
          oneRow.SKU_STOCK = this.detailDatas.SKU_STOCK.model;
        });
        this.showModelImgSku = false;
      }
    } else if (this.showModelImgPic) {
      _.each(selectList, oneRow => {
        oneRow['fileList'] = _.cloneDeep(this.detailDatas.detailPictureList.fileList);
      });
      this.showModelImgSku = false;
    }
  }

  showModelImgSkuCancel() {
    this.showModelImgSku = false;
  }

  buildSkuDatas() {
    let keys = _.keys(this.mainJson.skuDatas);
    let toCamelArr = ['SKU_NAME', 'SKU_CODE', 'SPEC_LIST', 'SKU_PRICE', 'SKU_STOCK']

    let list = [];
    _.each(this.mainJson.skuDatas, oneData => {
      let keys = _.keys(oneData);
      let obj = {}
      let otherObj = {}
      _.each(keys, oneColumn => {
        if (_un.contains(toCamelArr, oneColumn)) {
          let value = oneData[oneColumn];
          let key = _.camelCase(oneColumn);
          obj[key] = value;
        } else {
          let value = oneData[oneColumn];
          otherObj[oneColumn] = value;
        }
      })
      obj['skuJson'] = JSON.stringify(otherObj);
      list.push(obj);
    })
    return list;
  }

  buildSpu(esSpuFormData) {
    // if (esSpuFormData) esSpuFormData = this.formatMainToEntity(this.formData);
    let esSpu = esSpuFormData;
    esSpu['categoriesId'] = _.join(esSpu['categoriesId'], ',');
    esSpu['spuSpecJson'] = JSON.stringify(this.SPEC);
    esSpu['spuPicJson'] = JSON.stringify(this.pictureList.fileList);

    return esSpu;
  }

  doSave(): void {

    this.buildSkuDatas();

    const queryData = {
      'userId': this.userInfoService.getUserInfo().USER_ID,
      "spuType": this.spuType
    };

    let isAdd = this.modeType;
    let esSpuFormData = this.formatMainToEntity(this.formData);
    let esSkuListFormData = this.formatSelectEntity(this.mainJson.skuDatas);

    let esSpu = this.buildSpu(esSpuFormData);
    esSpu['id'] = this.modeId;

    let esSpuPicList = [];
    _.each(this.pictureList.fileList, imgItem => {
      let esSpuPicItem = {
        "picName": imgItem.pictureName,
        "picUrl": imgItem.pictureUrl
      };
      esSpuPicList.push(esSpuPicItem);
    });

    let esSpuSpecList = [];
    _.each(this.SPEC, spec => {
      let specId = spec.ID;
      _.each(spec.model, specItem => {
        let esSpuSpecItem = {
          "specId": specId,
          "specItemId": specItem
        };
        esSpuSpecList.push(esSpuSpecItem);
      });
    });

    let esSkuList = [];
    let esSkuPicList = [];
    let esSkuSpecList = [];

    let sortIndex = 1;
    _.each(esSkuListFormData, (item, row) => {

      let skuId = this.modeType == "add" ? this.util.Base_getUuid() : item.id; // GUID


      let esSkuItem = {
        "id": skuId,
        "spuId": esSpu['id'],
        "skuName": item.skuName,
        "skuCode": item.skuCode,
        "skuPrice": item.skuPrice,
        "skuStock": item.skuStock,
        "skuUnit": item.skuUnit,
        "sortIndex": sortIndex
      };
      sortIndex++;

      _.each(item.fileList, imgItem => {
        let esSkuPicItem = {
          "skuId": skuId,
          "picName": imgItem.pictureName,
          "picUrl": imgItem.pictureUrl
        };
        esSkuPicList.push(esSkuPicItem);
      });

      _.each(item.specList, specItem => {
        let esSkuSpecItem = {
          "skuId": skuId,
          "specItemId": specItem
        };
        esSkuSpecList.push(esSkuSpecItem);
      });

      esSkuItem['skuPicJson'] = JSON.stringify(item.fileList);
      esSkuItem['skuSpecJson'] = JSON.stringify(item.specList);
      esSkuList.push(esSkuItem);

    });


    let bodyData = {
      "isAdd": isAdd,
      "esSpu": esSpu,
      "esSpuPicList": esSpuPicList,
      "esSpuSpecList": esSpuSpecList,
      "esSkuList": esSkuList,
      "esSkuPicList": esSkuPicList,
      "esSkuSpecList": esSkuSpecList,
    };
    this.requestService.doActionByHdbsthor('AppApi/EsSpuController', 'saveSpu', queryData, bodyData, false, this.config.config.defaultThor, 'appToken').subscribe(response => {
      if (response == true) {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }

  addSku() {
    if (!this.checkInput()) {
      return false;
    } else {
      let spuName = this.formData.SPU_NAME.model; // 产品名称

      let obj = {
        'inx': this.mainJson.skuDatas.length,
        'SKU_NAME': spuName,
        'SKU_CODE': null,
        'SKU_PRICE': null,
        'SKU_STOCK': null,
        'SKU_UNIT': null
      };
      this.mainJson.skuDatas.push(obj);
    }
  }

  batchAddSku() {
    if (!this.checkInput()) {
      return false;
    } else {
      this.showModelBacthAddSku = true;
      this.batchSku['skuInput'] = null;
    }
  }

  showModelBacthAddSkuCancel() {
    this.showModelBacthAddSku = false;
  }

  doBacthAddSku() {
    let check = true;
    if (!this.util.Base_HasValue(this.batchSku['skuInput'])) {
      this.confirmModal.show('error', {'title': '必填项', 'content': '内容为必填项'});
      check = false;
    }

    if (check) {
      let columns = ['SKU_NAME', 'SKU_CODE', 'SKU_UNIT', 'SKU_PRICE'];
      if (this.batchSku['inputType'] == 'N_C_U_P') {
        columns = ['SKU_NAME', 'SKU_CODE', 'SKU_UNIT', 'SKU_PRICE'];
      } else if (this.batchSku['inputType'] == 'N_C_U_P_S') {
        columns = ['SKU_NAME', 'SKU_CODE', 'SKU_UNIT', 'SKU_PRICE', 'SKU_STOCK'];
      }
      let array = _.chain(this.batchSku['skuInput']).split("\n").map(oneMap => {
        let oneRowArr = _.split(oneMap, "\t");
        let obj = _.zipObject(columns, oneRowArr)
        return obj
      }).value();
      let rowIndex = this.mainJson.skuDatas.length;
      _.each(array, oneRow => {
        let obj = {
          'inx': rowIndex,
          'SKU_NAME': oneRow['SKU_NAME'],
          'SKU_CODE': oneRow['SKU_CODE'],
          'SKU_UNIT': oneRow['SKU_UNIT'],
          'SKU_PRICE': oneRow['SKU_PRICE'],
          'SKU_STOCK': oneRow['SKU_STOCK']
        };
        this.mainJson.skuDatas.push(obj);
        rowIndex++;
      })
    }
  }
}
