import {Injectable} from '@angular/core';
import {environment} from './../../environments/environment';
import * as _ from 'underscore';
import * as _sh from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  public config: any = {};
  public version: any = null;

  constructor() {
    const projectData = require('./../../environments/environment.json');
    this.version = projectData['version'];
    let title = projectData['title'];
    this.config = _.extend(environment.commonConfig, environment[projectData.project]);
    if (title) {
      _sh.set(this.config,['logoConfig','sysTitle'],title);
    }
  }

  getServer() {
    let exportServer = this.config.ExportServer ? this.config.ExportServer : 'https://export-hulk.jiuling.sinotrans.com';
    return {
      'NodeJsApi': this.config.NodeJsServer + '/api',
      'AutoInsertAPi': this.config.AutoPlatform + '/servlet/AutoInsertServlet',
      'NodeMongoSql': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/mysqlServlet/mongoSql',
      'aiServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/aiServlet',
      'redisServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/redisServlet',
      'ImageBase64': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/mysqlServlet/getImageBase64',
      'NodeJsonFile': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/jsonfileServlet/getfileWithData',
      'GetJsonFile': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/jsonfileServlet/getfile',
      'mongoServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/mongoServlet/api',
      'excelServlet': exportServer + '/' + this.config.NodeJsApiVersion + '/excelServlet/getExcel',
      'countServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/countServlet/count',
      'QaAnalysisServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/QaAnalysisServlet',
      'devToolsServletColumns': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/devToolsServlet/columns',
      'serverTime': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/md5Servlet/getServerTime',
      'importServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/importServlet/putExcel',
      'baiduMapServlet': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/baiduMapServlet/search',
      'execWordProcess': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/wordProcessServlet/execWordProcess',
      'buildData': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/dataCenterServlet/buildData',
      'translate': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/dataCenterServlet/translate',
      'getRole': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/getRole',
      'loginApi': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/login',
      'saveRole': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/saveRole',
      'saveRoleMenuAction': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/saveRoleMenuAction',
      'menuToAction': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/menuToAction',
      'saveLoginRoleMenu': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/saveLoginRoleMenu',
      'checkIp': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/checkIp',
      'captchaApi': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/captcha',
      'checkCaptchaApi': this.config.NodeJsServer + '/' + this.config.NodeJsApiVersion + '/loginServlet/checkCaptcha',
      'SystemTest': 'http://hdbsthor-system.wmsb2b.com'
    };
  }
}
