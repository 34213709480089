import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import * as _sh from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-briefing-preview',
  templateUrl: './briefing-preview.component.html',
  styleUrls: ['./briefing-preview.component.scss']
})
export class BriefingPreviewComponent implements OnInit {

  @Input() parentData: any;
  briefingData = [];
  hmtOfficialDataList = []; //港澳台官方视野
  hmtFolkViewsDataList = []; //港澳台民间观点
  hmtUniversityTrendsDataList = []; //港澳台高校动态
  bothOfficialDataList = []; //两岸官方视野
  bothFolkViewsDataList = []; //两岸民间观点
  bothBasicEducationTrendsDataList = []; //两岸基础教育动态

  constructor(
    private requestService: RequestDataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.doSearch(this.parentData.id);
  }

  doSearch(id){
    const search = observableForkJoin(
      this.requestService.getDataByCondition("simulationTrain/ig/info/ig_briefing_sheet_detail.json",{'ID':id}), //主表信息 0
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_hmt_preview_01.json",{'ID':id}), //港澳台教育动态 / 官方视野 1
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_hmt_preview_02.json",{'ID':id}), //港澳台教育动态 / 民间观点 2
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_hmt_preview_03.json",{'ID':id}), //港澳台教育动态 / 高校动态 3
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_both_preview_01.json",{'ID':id}), //港澳台教育动态 / 官方视野 4
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_both_preview_02.json",{'ID':id}), //港澳台教育动态 / 民间观点 5
      this.requestService.getDataByCondition("simulationTrain/ig/info/briefing_preview/ig_both_preview_03.json",{'ID':id}), //港澳台教育动态 / 基础教育动态 6
    );
    search.subscribe(value => {
        this.briefingData = _sh.get(value,[0,'data',0],[]);
        this.hmtOfficialDataList = _sh.get(value,[1,'data'],[]);
        _sh.each(this.hmtOfficialDataList,oneData=>{
          oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
        });
        this.hmtFolkViewsDataList = _sh.get(value,[2,'data'],[]);
      _sh.each(this.hmtFolkViewsDataList,oneData=>{
        oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
      });
        this.hmtUniversityTrendsDataList = _sh.get(value,[3,'data'],[]);
      _sh.each(this.hmtUniversityTrendsDataList,oneData=>{
        oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
      });
        this.bothOfficialDataList = _sh.get(value,[4,'data'],[]);
      _sh.each(this.bothOfficialDataList,oneData=>{
        oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
      });
        this.bothFolkViewsDataList = _sh.get(value,[5,'data'],[]);
      _sh.each(this.bothFolkViewsDataList,oneData=>{
        oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
      });
        this.bothBasicEducationTrendsDataList = _sh.get(value,[6,'data'],[]);
      _sh.each(this.bothBasicEducationTrendsDataList,oneData=>{
        oneData['CONTENT'] = this.sanitizer.bypassSecurityTrustHtml(oneData['CONTENT'])
      });
    })
  }

  doJump(url){
    window.open(url,'_blank')
  }

  printWindows(): void {
    window.print();
  }

}
