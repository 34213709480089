import {Component, OnInit} from '@angular/core';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import {UtilsService} from './../../../service/utils.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-file-desc',
  templateUrl: './file-desc.component.html',
  styleUrls: ['./file-desc.component.css']
})
export class FileDescComponent implements OnInit {

  // 元数据
  public tableList: any = [];

  constructor(
    private utils: UtilsService,
    private confirmModal: ConfirmModalService) {
  }


  save() {
    // 封装元数据表格
    if (this.tableList.length) {
      let flag = true;
      _.each(this.tableList, table => {
        _.each(table['dataList'], data => {
          flag = !data['edit'] && flag;
        });
      });
      if (flag) {
        this.transformTableList();
      } else {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '请先保存元数据字段后再试！',
        });
        return;
      }
    }
  }

  // 删除表格
  delTable() {
    const selectedList = this.tableList.filter(item => item.checked);
    if (selectedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '确认删除这些表格吗',
        suc: () => {
          this.tableList = this.tableList.filter(item => !item.checked);
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '至少选择一个表格进行删除'
      });
      return;
    }
  }

  // 添加表格
  addTable(): void {
    this.tableList.push({
      tableName: '表格' + (this.tableList.length + 1),
      title: [
        {
          column: 'A1',
          name: '第一列'
        }
      ],
      dataList: [
        {
          index: 0,
          'A1': '',
          edit: false
        }
      ],
      cacheData: [
        {
          index: 0,
          'A1': '',
          edit: false
        }
      ]
    });
  }

  // 删除列
  deleteCell(i, j) {
    if (this.tableList[i].title.length === 1) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '至少保留一列'
      });
      return;
    }
    this.tableList[i].title = _.filter(this.tableList[i].title, (item, index) => index != j);
  }

  // 添加列
  addCell(index): void {
    const len = this.tableList[index].title.length;
    this.tableList[index].title.push({
      column: 'A' + (len + 1),
      name: '第' + this.utils.NumberToChinese(len + 1) + '列'
    });
    _.each(this.tableList[index].dataList, data => {
      _.each(this.tableList[index].title, title => {
        if (!data[title['column']]) {
          data[title['column']] = '';
        }
      });
    });
    _.each(this.tableList[index].cacheData, data => {
      _.each(this.tableList[index].title, title => {
        if (!data[title['column']]) {
          data[title['column']] = '';
        }
      });
    });
  }

  // 添加行
  addRow(index): void {
    let data = {edit: false, index: this.tableList[index].dataList.length};
    _.each(this.tableList[index].title, title => {
      data[title['column']] = '';
    });
    this.tableList[index].dataList = [...this.tableList[index].dataList, data];
    let cacheList = [];
    _.each(this.tableList[index].dataList, (data: any) => {
      let obj = {};
      _.each(data, (item, key) => {
        obj[key] = item;
      });
      cacheList.push(obj);
    });
    this.tableList[index].cacheData = cacheList;
  }

  // 添加行
  deleteRow(i, j): void {
    if (this.tableList[i].dataList.length === 1) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '至少保留一行'
      });
      return;
    }
    this.tableList[i].dataList.splice(j, 1);
    this.tableList[i].cacheData.splice(j, 1);
  }

  // 行数据编辑
  startEdit(data: {}): void {
    data['edit'] = true;
  }

  // 取消编辑
  cancelEdit(data: {}, i, j): void {
    data['edit'] = false;
    _.each(this.tableList[i].dataList[j], (value, key: string) => {
      if (!_.contains(['edit', 'index'], key)) {
        this.tableList[i].cacheData[j][key] = value;
      }
    });
  }

  // i tableList 下标 j dataList 下标
  saveEdit(i, j): void {
    this.tableList[i].cacheData[j].edit = false;
    Object.assign(this.tableList[i].dataList[j], this.tableList[i].cacheData[j]);
  }

  // 封装元数据表格
  transformTableList() {
    let resultList = [];
    _.each(this.tableList, (table, index) => {
      let dataList = [];
      // 该坐标用于后面封装auto数据时候判断字段添加到多少。
      let resultIndex = 0;
      _.each(table['dataList'], (data, dataIndex) => {
        let result = {};
        _.each(table['title'], (title, titleIndex) => {
          // 封装某table标题数据
          result['A' + (titleIndex + 1)] = title['name'];
          result['B' + (titleIndex + 1)] = data[title['column']] || '';
          resultIndex = titleIndex + 1;
        });
        dataList.push(result);
      });
      resultList.push({
        TABLE_NAME: table['name'],
        TABLE_INDEX: index,
        resultIndex: resultIndex,
        dataList: dataList
      });
    });
    return resultList;
  }

  ngOnInit() {
  }

}
