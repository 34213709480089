import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from './../../../service/request-data.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import {UtilsService} from "../../../service/utils.service";
import {AutoInsertService} from '../../../service/auto-insert.service';
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {DataCenterService} from '../../../service/data-center.service';

@Component({
  selector: 'app-operate-table',
  templateUrl: './operate-table.component.html',
  styleUrls: ['./operate-table.component.css']
})
export class OperateTableComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;
  constructor(private requestData: RequestDataService,
              private autoService: AutoInsertService,
              private confirmModal: ConfirmModalService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService) {
  }

  public conditionForm: any = {
    beginDate: null,
    endDate: null
  };

  public nowSource: any;
  public nowSourceReal: any;
  source: any = '';
  sourceReal: any = '微信';

  public mainData: any = {
    data: [],
    page: {
      rowCount: 0,
      pageNum: 1,
      pageCount: 5
    }
  };

  public itemData: any = {
    data: [],
    page: {
      rowCount: 0,
      pageNum: 1,
      pageCount: 5
    }
  };
  titleSearch = '';
  allChecked = false;
  indeterminate = false;
  mainDataAllCheck = false;
  allMainChecked = false;

  sort(sort: { key: string; value: string }): void {
    if (sort.key && sort.value) {
      this.mainData.data = this.mainData.data.sort((a, b) =>
        sort.value === 'ascend' ? a[sort.key!] > b[sort.key!] ? 1 : -1 : b[sort.key!] > a[sort.key!] ? 1 : -1
      );
    }
  }

  refreshStatus(pObject, pName): void {
    const validData = pObject.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    if (pName === 'indeterminate') {
      this.allChecked = allChecked;
    } else  {
      this.mainDataAllCheck = allChecked;
    }
    pName = (!allChecked) && (!allUnChecked);
  }

  checkAll(pObject, pName, value: boolean): void {
    pObject.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus(pObject, pName);
  }

  gotoDetail(oneData: string): void {
    let that = this;
    that.parentData['source'] = this.source;
    that.parentData['sourceReal'] = this.sourceReal;
    that.parentData['nowSource'] = this.nowSource;
    that.parentData['nowSourceReal'] = this.nowSourceReal;
    that.parentData['beginDate'] = this.conditionForm.beginDate;
    that.parentData['endDate'] = this.conditionForm.endDate;
    that.parentData['mainData'] = this.mainData;
    that.parentData['itemData'] = this.itemData;
    that.parentData['titleSearch'] = this.titleSearch;
    that.parentData['allChecked'] = this.allChecked;
    that.parentData['indeterminate'] = this.indeterminate;
    let tmp = {
      ID: oneData['ID']
    };
    let isGotoDetail = false;
    _.each(that.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData['id']) {
        item = _.extend(item, that.parentData);
        item['data'] = {};
        item['detailCondition'] = tmp;
        isGotoDetail = true;
        item['formType'] = 'readOnlyForm';
      }
    });
    if (isGotoDetail) {
      that.dataCenterService.refreshDataCenterInStorage();
    }
  }

  ngOnInit() {
    this.setDay(0);
    if(this.utils.Base_HasValue(this.parentData)){
      this.source = this.parentData['source'] = this.source;
      this.sourceReal = this.parentData['sourceReal'] || '微信';
      this.nowSource = this.parentData['nowSource'];
      this.nowSourceReal = this.parentData['nowSourceReal'];
      this.conditionForm.beginDate = this.parentData['beginDate'];
      this.conditionForm.endDate = this.parentData['endDate'];
      this.titleSearch = this.parentData['titleSearch'];
      this.indeterminate = this.parentData['indeterminate'];
      this.allChecked = this.parentData['allChecked'];
      if(this.utils.Base_HasValue(this.parentData['mainData'])){
        this.mainData = this.parentData['mainData'];
      }
      if(this.utils.Base_HasValue(this.parentData['itemData'])){
        this.itemData = this.parentData['itemData'];
      }
    }
  }

// 获取数据
  getMainData(pPageIndex?) {
    const condition = {
      ADD_TIME_ADD: moment(this.conditionForm.beginDate).format('YYYY-MM-DD'),
      ADD_TIME_END: moment(this.conditionForm.endDate).format('YYYY-MM-DD'),
      SOURCE_REAL: this.sourceReal,
      SOURCE: this.source
    };
    let pageIndex = this.utils.Base_HasValue(pPageIndex) ? pPageIndex : 1;

    this.requestData.getPaginationData("headLine/hl/operate-table-originallink.json", condition, pageIndex, this.mainData.page.pageCount).subscribe(result => {
      this.mainData = result || [];
      if (this.utils.Base_HasValue(this.mainData.data)) {
        const firstData = this.mainData.data[0];
        this.nowSource = firstData.SOURCE;
        this.nowSourceReal = firstData.SOURCE_REAL;
        this.getItemData(1, this.nowSource, this.nowSourceReal);
      }
    })
  }

  searchFilter() {
    this.getMainData(this.mainData.page.pageNum);
  }

  gotoOneSource(pSource, pSourceReal) {
    this.nowSource = pSource;
    this.nowSourceReal = pSourceReal;
    this.getItemData(1, this.nowSource, this.nowSourceReal);
  }

  searchItemFilter() {
    this.getItemData(this.itemData.page.pageNum, this.nowSource, this.nowSourceReal);
  }

  getItemData(pPageIndex, pSource, pSourceReal?) {
    let pageIndex = this.utils.Base_HasValue(pPageIndex) ? pPageIndex : 1;
    const condition = {
      ADD_TIME_ADD: moment(this.conditionForm.beginDate).format('YYYY-MM-DD'),
      ADD_TIME_END: moment(this.conditionForm.endDate).format('YYYY-MM-DD'),
      SOURCE: pSource,
      SOURCE_REAL: pSourceReal,
      TITLE: this.titleSearch
    };
    this.requestData.getPaginationData("headLine/hl/operate-table-originallink-item.json", condition, pageIndex, this.itemData.page.pageCount).subscribe(result => {
      this.itemData = result || [];
    })
  }

  setDay(pBeforeDays) {
    const _today = moment();
    let beginDay = _today.format('YYYY-MM-DD');
    let endDay = beginDay;
    if (this.utils.Base_HasValue(pBeforeDays)) {
      beginDay = _today.subtract(pBeforeDays, 'days').format('YYYY-MM-DD');
    }
    this.conditionForm.beginDate = moment(beginDay).toDate();
    this.conditionForm.endDate = moment(endDay).toDate();
  }



  updateItemData(pQUALITY_LEVEL,pObject?) {
    let ids = '';
    let selectList = [];
    if(!this.utils.Base_HasValue(pObject)){
      let selectCount = this.selectMore(this.itemData.data);
      selectList = _.filter(this.itemData.data, (item) => {
        return item['checked'];
      });
      let id = [];
      _.each(selectList, oneData => {
        id.push(oneData['ID']);
      });
      ids = id.join('\',\'');
      ids = '(\'' + ids + '\')';
    } else {
      ids = '(\'' + pObject['ID'] + '\')';
    }
    let templateJson = {
      "LIST": [
        {
          "TABLE": "originallink",
          "ACTION": "Update",
          "WHEREGROUP": [{"GROUP": "GROUP_01", "CONDITION_OPERATOR": "AND"}],
          "WHERE": [{
            "COLUMN": "ID",
            "VALUE": ids,
            "OPERATOR": "in",
            "VALUE_TYPE": "int",
            "CONDITION_OPERATOR": "AND",
            "GROUP": "GROUP_01"
          }],
          "COLUMNS": [
            {"COLUMN": "QUALITY_LEVEL"}
          ],
          "RECORDS": [
            {
              "QUALITY_LEVEL": pQUALITY_LEVEL
            }
          ]
        }
      ]
    };
    this.autoService.ExecJson(templateJson).subscribe((response) => {
      if (response["state"] === "success") {
        if(this.utils.Base_HasValue(pObject)){
          this.updateLocalData(pObject);
        } else {
          _.each(selectList, oneData => {
            this.updateLocalData(oneData);
          });
        }
      } else {
        this.confirmModal.show('error', {title: '修改失败'})
      }
    })
  }

  updateLocalData(pOneData) {
    _.each(this.mainData.data, (oneMain) => {
      if ((oneMain['SOURCE'] == pOneData['SOURCE']) && (oneMain['SOURCE_REAL'] == pOneData['SOURCE_REAL'])) {
        oneMain['ACOUNT'] = oneMain['ACOUNT'] - 1;
      }
    });
    this.itemData.data.splice(this.itemData.data.indexOf(pOneData), 1);
  }

  updateMainData(pOneData, pQUALITY_LEVEL) {
    let updateData:any = [];
    let data: any = {};
    let deleteArray = [];
    if(this.utils.Base_HasValue(pOneData)){
      data = {
        "SOURCE": pOneData['SOURCE'],
        "SOURCE_REAL": pOneData.SOURCE_REAL,
        "QUALITY_LEVEL": pQUALITY_LEVEL
      };
      deleteArray.push(pOneData);
      updateData.push(data);
    } else {
      _.each(this.mainData.data, oneData => {
        if(oneData['checked']) {
          data = {
            "SOURCE": oneData['SOURCE'],
            "SOURCE_REAL": oneData['SOURCE_REAL'],
            "QUALITY_LEVEL": pQUALITY_LEVEL
          };
          deleteArray.push(oneData);
          updateData.push(data);
        }
      })
    }
    let templateJson = {
      "LIST": [
        {
          "TABLE": "originallink",
          "ACTION": "Update",
          "WHEREGROUP": [{"GROUP": "GROUP_01", "CONDITION_OPERATOR": "AND"}],
          "WHERE": [
            {
              "COLUMN": "SOURCE",
              "VALUE": "?",
              "OPERATOR": "=",
              "VALUE_TYPE": "string",
              "CONDITION_OPERATOR": "AND",
              "GROUP": "GROUP_01"
            },
            {
              "COLUMN": "SOURCE_REAL",
              "VALUE": "?",
              "OPERATOR": "=",
              "VALUE_TYPE": "string",
              "CONDITION_OPERATOR": "AND",
              "GROUP": "GROUP_01"
            }
          ],
          "COLUMNS": [
            {"COLUMN": "QUALITY_LEVEL"}
          ],
          "RECORDS": updateData
        }
      ]
    };
    this.autoService.ExecJson(templateJson).subscribe((response) => {
      if (response["state"] === "success") {
        _.each(deleteArray, item => {
          this.mainData.data = _.without(this.mainData.data, item);
        })
      } else {
        this.confirmModal.show('error', {title: '修改失败'})
      }
    })
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
