import {Component, OnInit, TemplateRef} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {NzFormatEmitEvent, NzTreeNode} from 'ng-zorro-antd';
import {UtilsService} from '../../../service/utils.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import {DataService} from '../data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit {
  channelList: any = [];
  channelListNode: any = [];
  sitelList: any = [];
  nowChannel: any = {};
  required = false;
  nodes: any = [];
  showChannel = false;
  hasAddChannel = false;
  activedNode: NzTreeNode;

  channelState = [];
  publishState = [];

  constructor(private requestServer: RequestDataService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  initData() {
    let copyNode = [];
    if (this.utils.Base_HasValue(this.channelList)) {
      this.channelListNode = this.utils.Base_ChannelData(this.channelList);
    }
    if (this.utils.Base_HasValue(this.sitelList)) {
      for (let oneSite of this.sitelList) {
        let node: any = {
          title: oneSite['SITE_NAME'],
          key: oneSite['ID'],
          siteId: oneSite['ID'],
          siteName: oneSite['SITE_NAME'],
          type: 'SITE'
        };
        if (this.utils.Base_HasValue(this.channelListNode)) {
          for (let oneChennel of this.channelListNode) {
            if (oneChennel['siteId'] === oneSite['ID']) {
              if (!node['children']) {
                node['children'] = [];
              }
              node['children'].push(oneChennel);
            }
          }
        }
        copyNode.push(node);
      }
      this.nodes = this.utils.DeepCopy(copyNode);

    }
  }

  addNode(pNode) {
    if (this.hasAddChannel) {
      this.confirmModal.show('confirm', {
        title: '您还有栏目未进行保存,确定不进行保存吗？',
        suc: () => {
          this.showChannel = true;
          this.hasAddChannel = true;
          this.activedNode = pNode;
          this.nowChannel = {
            SITE_ID: pNode['origin']['siteId'],
            CHANNEL_STATE: 'ENABLE',
            PUBLISH_STATE: 'PUBLISH',
            UP_CHANNEL_ID: pNode['origin']['key'],
            UP_CHANNEL_ID_DICT: pNode['origin']['title'],
            SITE_ID_DICT: pNode['origin']['siteName']
          };
          if (pNode['origin']['type'] === 'SITE') {
            this.nowChannel.UP_CHANNEL_ID_DICT = '';
            this.nowChannel.UP_CHANNEL_ID = '';
          }
        }
      });
    } else {
      this.activedNode = pNode;
      this.showChannel = true;
      this.hasAddChannel = true;
      this.nowChannel = {
        SITE_ID: pNode['origin']['siteId'],
        CHANNEL_STATE: 'ENABLE',
        PUBLISH_STATE: 'PUBLISH',
        UP_CHANNEL_ID: pNode['origin']['key'],
        UP_CHANNEL_ID_DICT: pNode['origin']['title'],
        SITE_ID_DICT: pNode['origin']['siteName']
      };
      if (pNode['origin']['type'] === 'SITE') {
        this.nowChannel.UP_CHANNEL_ID_DICT = '';
        this.nowChannel.UP_CHANNEL_ID = '';
      }
    }
  }

  cancelSaveNode(){
    this.confirmModal.show('confirm',
      {
        title: '您确定取消保存该栏目吗？',
        suc: () => {
          this.hasAddChannel = false;
          this.showChannel = false;
          this.nowChannel = {};
        }
      });
  }

  saveNode() {
    this.confirmModal.show('confirm',
      {
        title: '您确定保存该栏目吗？',
        suc: () => {
          this.dataService.addChannel(this.nowChannel).subscribe((response) => {
            if (response['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '保存成功'
              });
              this.hasAddChannel = false;
              this.showChannel = false;
              this.nowChannel = {};
              this.ngOnInit();
            } else {
              this.confirmModal.show('error', {title: '保存失败'});
            }
          });
        }
      });
  }

  deleteNode(pNode) {
    this.confirmModal.show('confirm',
      {
        title: '您确定删除该栏目吗？',
        suc: () => {
          this.dataService.deleteChannel(pNode['origin']['key']).subscribe((response) => {
            if (response['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '删除成功'
              });
              this.ngOnInit();
            } else {
              this.confirmModal.show('error', {title: '删除失败'});
            }
          });
        }
      });
  }

  choiceNode(pNode) {
    if (this.hasAddChannel) {
      this.confirmModal.show('confirm', {
        title: '您还有栏目未进行保存,确定不进行保存吗？',
        suc: () => {
          this.nowChannel = {};
          this.activedNode = pNode;
          if (pNode['origin']['type'] === 'SITE') {
            this.showChannel = false;
          } else {
            this.showChannel = true;
          }
          this.hasAddChannel = false;
          this.nowChannel = _.find(this.channelList, (item) => {
            return item['code'] === pNode['origin']['key'];
          });
        }
      });
    } else {
      if (pNode['origin']['type'] === 'SITE') {
        this.showChannel = false;
      } else {
        this.showChannel = true;
      }
      this.activedNode = pNode;
      this.nowChannel = _.find(this.channelList, (item) => {
        return item['code'] === pNode['origin']['key'];
      });
    }
  }

  /**
   * 查询站点
   * 点示展开某个站点时去查询站点下的栏目以及栏目关联的栏目
   */

  ngOnInit() {
    const search = observableForkJoin(
      this.requestServer.getDataByCondition('digitalResources/dict/CHANNEL_NAME.json'),
      this.requestServer.getDataByCondition('digitalResources/dr/dr_site_channel_add.json'),
      this.requestServer.getDataByCondition('digitalResources/dict/PUBLISH_STATE.json'),
      this.requestServer.getDataByCondition('digitalResources/dict/CHANNEL_STATE.json')
    );
    search.subscribe(
      values => {
        this.channelList = values[0]['data'] || [];
        this.sitelList = values[1]['data'] || [];
        this.initData();
        this.publishState = values[2]['data'] || [];
        this.channelState = values[3]['data'] || [];
      });
  }

}
