export const hulkConfigProject = [
  {
    "code": "agriculture",
    "name": "东风天成资产"
  },
  {
    "code": "dfmc",
    "name": "东风铁路处"
  },
  {
    "code": "genecreate",
    "name": "金开瑞"
  },
  {
    "code": "JieLi",
    "name": "武汉捷利v1"
  },
  {
    "code": "JieLiv2",
    "name": "武汉捷利v2"
  },
  {
    "code": "jsbPro01",
    "name": "北师大盐城附属学校餐费缴费查询系统"
  },
  {
    "code": "jsbPro02",
    "name": "跑团抽奖管理系统"
  },
  {
    "code": "jsbPro03",
    "name": "江苏涵邦会员管理系统"
  },
  {
    "code": "jsbPro04",
    "name": "淮安清江区政府智慧食堂"
  },
  {
    "code": "jsbPro05",
    "name": "悦跑体育俱乐部"
  },
  {
    "code": "dpca",
    "name": "神龙汽车安保点检系统"
  },
  {
    "code": "agriculture",
    "name": "冠恒集团县域农业一体化平台"
  },
]
