import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../service/utils.service';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class DfmcService {
  public user: any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple === true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case 'locomotiveArrivals':
        this.doLocomotiveArrivals(pParentData);
        break;
      case 'locomotiveOut':
        this.doLocomotiveOut(pParentData);
        break;
      case 'wagonEmpty':
        this.doEmpty(pParentData); // 列车卸空拍照
        break;
      case 'wagonFull':
        this.doFull(pParentData); // 列车卸空拍照
        break;
      case 'wagonReturn':
        this.doReturnWagon(pParentData);
        break;
      case 'warehouseInput':
        this.doWarehouseInput(pBtn, pParentData);
        break;
      case 'warehouseCheck':
        this.dowarehouseCheck(pParentData);
        break;
      case 'warehouseDisable':
        this.dowarehouseDisable(pParentData);
        break;
      case 'warehouseUncheck':
        this.dowarehouseUncheck(pParentData);
        break;
      case 'outboundInput':
        this.doOutboundInput(pBtn, pParentData);
        break;
      case 'outboundCheck':
        this.doOutboundCheck(pBtn, pParentData);
        break;
      case 'outboundDisable':
        this.doOutboundDisable(pBtn, pParentData);
        break;
      case 'takePictures':
        this.doTakePictures(pBtn, pParentData);
        break;
      case 'rolloverOut':
        this.dorolloverOut(pBtn, pParentData);
        break;
      case 'rolloverCheck':
        this.dorolloverCheck(pBtn, pParentData);
        break;
      case 'rolloverDisable':
        this.dorolloverDisable(pBtn, pParentData);
        break;
      case 'inventoryInput':
        this.doinventoryInput(pBtn, pParentData);
        break;
      case 'inventoryBegin':
        this.doinventoryBegin(pBtn, pParentData);
        break;
      case 'inventoryEnd':
        this.doinventoryEnd(pBtn, pParentData);
        break;
      case 'inventoryCancel':
        this.doinventoryCancel(pBtn, pParentData);
        break;
      case 'inventoryCorrect':
        this.doInventoryCorrect(pBtn, pParentData);
        break;
      case 'inventoryError':
        this.doInventoryError(pBtn, pParentData);
        break;
      case 'disbursementInput':
        this.doDisbursementInput(pBtn, pParentData);
        break;
      case 'disbursementCheck':
        this.doDisbursementCheck(pBtn, pParentData);
        break;
      case 'disbursementDisable':
        this.doDisbursementDisable(pBtn, pParentData);
        break;
      case 'incomeInput':
        this.doIncomeInput(pBtn, pParentData);
        break;
      case 'incomeCheck':
        this.doIncomeCheck(pBtn, pParentData);
        break;
      case 'incomeDisable':
        this.doIncomeDisable(pBtn, pParentData);
        break;
      case 'wagonCounterpoint':
        this.doWagonCounterpoint(pBtn, pParentData);
        break;
      case 'adjustmentDisable':
        this.doAdjustmentDisable(pBtn, pParentData);
        break;
      case 'storageScan':
        this.doStorageScan(pBtn, pParentData);
        break;
      case 'outboundScan':
        this.doOutboundScan(pBtn, pParentData);
        break;
      case 'saveEmptyQrcode':
        this.doSaveEmptyQrcode(pBtn, pParentData);
        break;
      case 'saveEmptyQrcodeForGoods':
        this.doSaveEmptyQrcodeForGoods(pBtn, pParentData);
        break;
      case 'strandedSms':
        this.doStrandedSms(pBtn, pParentData);
        break;
      case 'recalculate':
        this.doRecalculate(pBtn, pParentData);
        break;
      case 'recalculateOutbound':
        this.doRecalculateOutbound(pBtn, pParentData);
        break;
      case 'checkScan':
        this.doCheckScan(pBtn, pParentData);
        break;
      case 'modifyWagon':
        this.doModifyWagon(pBtn, pParentData);
        break;
    }
  }

  doModifyWagon(pBtn, parentData) {
    if (this.selectMore(parentData.data.data, false) !== 1) {
      return false;
    } else {
      parentData['detailCondition'] = this.setDetailConditons(parentData, parentData.data.data);
      parentData['data'] = _.filter(parentData.data.data, (item) => {
        return item['checked'];
      });
      parentData['btnName'] = pBtn.ACTION_VALUE;
      parentData['conditionColumns'] = {};
      parentData['formType'] = 'customForm';
      parentData['modeType'] = 'edit';
      parentData['actionArray'] = [];

      // 覆盖当前数据到data-center;
      _.each(this.dataCenterService._dataObject.list, (item) => {
        if (item['id'] === parentData['id']) {
          item = _.extend(item, parentData);
        }
      });
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }

  doCheckScan(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '盘点扫码',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'QRCODE_ID',
                'name': 'QRCODE_ID_NAME',
                'component': 'poplist',
                'lable': '二维码',
                'comment': '二维码',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择二维码',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/BASIC_QRCODE_USED_POPLIST.json',
                'dictionaryPF': null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'qrcodeId': this.findOneColumnValue(data, 'QRCODE_ID'),
            'id': selectList[0]['ID']
          };
          const bodyData = null;
          this.requestService.doActionByHdbsthor('AppApi/BasicQrcodeController', 'scanInventoryCorrect', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  doRecalculateOutbound(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let queryData = {
        'userId': this.user.USER_ID
      };
      const bodyData = {
        'ids': this.formatSelectEntityId(selectList, 'ID')
      };
      this.confirmModal.show('confirm', {
        title: '确认重新计算么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/MsModelController', 'calcMcOutboundDetail', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message},700);

            }
          });
        }
      });

    }
  }

  doRecalculate(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let queryData = {
        'userId': this.user.USER_ID
      };
      const bodyData = {
        'ids': this.formatSelectEntityId(selectList, 'ID')
      };
      this.confirmModal.show('confirm', {
        title: '确认重新计算么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/MsModelController', 'calc', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message},700);

            }
          });
        }
      });

    }
  }

  doStrandedSms(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let queryData = {
        'userId': this.user.USER_ID
      };

      const bodyData = {
        'ids': this.formatSelectEntityId(selectList, 'ID')
      };

      this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'deliveryTimeout', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
        if (response.toString() === 'true') {
          this.confirmModal.show('success', {'title': '执行成功'});
          pParentData.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    }
  }

  doOutboundScan(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '出库单-扫码',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'QRCODE_ID',
                'name': 'QRCODE_ID_NAME',
                'component': 'poplist',
                'lable': '二维码',
                'comment': '二维码',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择二维码',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/BASIC_OUTBOUND_QRCODE.json',
                'dictionaryPF': null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'qrcodeId': this.findOneColumnValue(data, 'QRCODE_ID'),
            'id': selectList[0]['ID']
          };
          const bodyData = null;
          this.requestService.doActionByHdbsthor('AppApi/BasicQrcodeController', 'scanMcOutboundQrcode', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }
  }

  doSaveEmptyQrcode(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '生成空二维码',
      nzContent: FormModalComponent,
      nzWidth: 500,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'lableSpan': 8,
              'itemSpan': 16,
              'customize': 24,
              'column': 'QRCODE_NUM',
              'name': 'QRCODE_NUM_NAME',
              'component': 'input',
              'lable': '数量',
              'comment': '数量',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'placeholder': '请输入数量',
              'model': null,
              'visible': true,
              'type': 'number',
              'require': true
            },
            {
              'lableSpan': 8,
              'itemSpan': 16,
              'customize': 24,
              'column': 'QRCODE_NOTE',
              'name': 'QRCODE_NOTE_NAME',
              'component': 'inputMulti',
              'lable': '备注',
              'comment': '备注',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'placeholder': '请输入备注',
              'model': null,
              'visible': true,
              'type': 'normal',
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        const queryData = {
          'userId': this.user.USER_ID,
          'num': this.findOneColumnValue(data, 'QRCODE_NUM'),
          'note': this.findOneColumnValue(data, 'QRCODE_NOTE')
        };
        this.requestService.doActionByHdbsthor('AppApi/BasicQrcodeController', 'saveEmptyQrcode', queryData, null, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });

      }
    });
  }

  doSaveEmptyQrcodeForGoods(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '生成入货品二维码',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'QRCODE_NUM',
              'name': 'QRCODE_NUM_NAME',
              'component': 'input',
              'lable': '数量',
              'comment': '数量',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'placeholder': '请输入数量',
              'model': null,
              'visible': true,
              'type': 'number',
              'require': true
            },
            {
              'column': 'GOODS_ID',
              'name': 'GOODS_ID_NAME',
              'component': 'poplist',
              'lable': '货品名称',
              'comment': '货品名称',
              'placeholder': '请输入货品名称',
              'insertValue': null,
              'model': null,
              'dictionary': 'dfmc/basic/dict/BASIC_GOODS_STORAGE.json',
              'dictionaryPF': null,
              'visible': true,
              'require': true,
              'columnGroup': [
                {
                  'column': 'GOODS_CODE',
                  'name': 'GOODS_CODE_NAME',
                  'component': 'input',
                  'lable': '编码',
                  'comment': '编码',
                  'placeholder': '请输入编码',
                  'insertValue': null,
                  'fromValue': 'GOODS_CODE',
                  'model': null,
                  'visible': false,
                  'require': false
                },
                {
                  'column': 'GOODS_CN_NAME',
                  'name': 'GOODS_CN_NAME_NAME',
                  'component': 'input',
                  'lable': '货品中文名称',
                  'comment': '货品中文名称',
                  'placeholder': '请输入货品中文名称',
                  'insertValue': null,
                  'fromValue': 'name',
                  'model': null,
                  'visible': false,
                  'require': false
                }
              ]
            },
            {
              'column': 'QRCODE_NOTE',
              'name': 'QRCODE_NOTE_NAME',
              'component': 'inputMulti',
              'lable': '备注',
              'comment': '备注',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'placeholder': '请输入备注',
              'model': null,
              'visible': true,
              'type': 'normal',
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        const queryData = {
          'userId': this.user.USER_ID,
          'goodsId': this.findOneColumnValue(data, 'GOODS_ID'),
          'num': this.findOneColumnValue(data, 'QRCODE_NUM'),
          'note': this.findOneColumnValue(data, 'QRCODE_NOTE')
        };
        this.requestService.doActionByHdbsthor('AppApi/BasicQrcodeController', 'saveEmptyQrcodeForGoods', queryData, null, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });

      }
    });
  }

  doStorageScan(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '入库钢坯号绑定二维码',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'QRCODE_ID',
                'name': 'QRCODE_ID_NAME',
                'component': 'poplist',
                'lable': '二维码',
                'comment': '二维码',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择二维码',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/BASIC_QRCODE_POPLIST.json',
                'dictionaryPF': null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'qrcodeId': this.findOneColumnValue(data, 'QRCODE_ID'),
            'id': selectList[0]['ID']
          };
          const bodyData = null;
          this.requestService.doActionByHdbsthor('AppApi/BasicQrcodeController', 'scanMcStorageQrcode', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doAdjustmentDisable(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let queryData = {
        'userId': this.user.USER_ID
      };
      const bodyData = {
        'ids': this.formatSelectEntityId(selectList, 'ID')
      };
      this.confirmModal.show('confirm', {
        title: '确认撤销收入结算单么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/McAdjustmentAccountController', 'disable', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doIncomeInput(pBtn, parentData) {
    parentData['btnName'] = pBtn.ACTION_VALUE;
    parentData['conditionColumns'] = {};
    parentData['formType'] = 'customForm';
    parentData['modeType'] = 'add';
    parentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doIncomeCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      const entity = {
        'mcIncomeAccount': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核收入结算单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McIncomeAccountController', 'check', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  findOneColumnValue(pColumns, pColumnName) {
    const oneOneColum = _.find(pColumns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }

  doWagonCounterpoint(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '车皮对位',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'TRACK_ID_COUNTERPOINT',
                'name': 'TRACK_ID_COUNTERPOINT_NAME',
                'component': 'poplist',
                'lable': '对位线',
                'comment': '对位线',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择对位线',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/MC_TRACK_SPECIAL_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'TRACK_NAME_ACCESS',
                    'name': 'TRACK_NAME_ACCESS_NAME',
                    'component': 'input',
                    'lable': '对位线名称',
                    'comment': '对位线名称',
                    'placeholder': '',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  },
                  {
                    'column': 'TRACK_SHORT_ACCESS',
                    'name': 'TRACK_SHORT_ACCESS_NAME',
                    'component': 'input',
                    'lable': '对位线简称',
                    'comment': '对位线简称',
                    'placeholder': '',
                    'fromValue': 'TRACK_SHORT',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'trackId': this.findOneColumnValue(data, 'TRACK_ID_COUNTERPOINT')
          };
          const bodyData = {
            'ids': this.formatSelectEntityId(selectList, 'ID')
          };

          this.requestService.doActionByHdbsthor('AppApi/McWagonController', 'counterpoint', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doIncomeDisable(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcIncomeAccount': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认撤销收入结算单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McIncomeAccountController', 'disable', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doDisbursementInput(pBtn, parentData) {
    parentData['btnName'] = pBtn.ACTION_VALUE;
    parentData['conditionColumns'] = {};
    parentData['formType'] = 'customForm';
    parentData['modeType'] = 'add';
    parentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doDisbursementCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      const entity = {
        'mcDisbursementAccount': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核收入结算单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McDisbursementAccountController', 'check', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doDisbursementDisable(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcDisbursementAccount': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核收入结算单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McDisbursementAccountController', 'disable', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doInventoryError(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '核对错误',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CF_FIND',
                'name': 'CF_FIND_NAME',
                'component': 'select',
                'lable': '是否找到',
                'comment': '是否找到',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否找到',
                'model': 1,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null,
              },
              {
                'column': 'CF_LOC_ID',
                'name': 'CF_LOC_ID_NAME',
                'component': 'poplist',
                'lable': '找到的库位',
                'comment': '找到的库位',
                'placeholder': '请选择找到的库位',
                'insertValue': null,
                'model': null,
                'dictionary': 'dfmc/basic/dict/BASIC_PLANT_LOCATOR.json',
                'dictionaryPF': null,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }

          let popData = this.formatMainToEntity(data);
          let entitySelect = this.formatSelectEntity(selectList);
          _.each(entitySelect, (oneRow) => {
            _.set(oneRow, 'cfFind', popData['cfFind']);
            _.set(oneRow, 'cfLocId', popData['cfLocId']);
          });

          // tslint:disable-next-line:triple-equals
          if ((popData['cfFind'] == 1) && (!this.util.Base_HasValue(popData['cfLocId']))) {
            this.confirmModal.show('warning', {
              'title': '温馨提示',
              'content': '如果找到了，请选择找到的库位！'
            });
            return false;
          }
          let entity = {};
          entity['mcInventoryDetailList'] = entitySelect;

          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'inventoryError', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doInventoryCorrect(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      const entity = {
        'mcInventoryDetailList': this.formatSelectEntity(selectList),
      };
      this.confirmModal.show('confirm', {
        title: '确认盘点核对正确么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'inventoryCorrect', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doinventoryCancel(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcInventory': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认取消盘点计划么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'inventoryCancel', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doinventoryEnd(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      const entity = {
        'mcInventory': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认结束盘点计划么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'inventoryEnd', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doinventoryBegin(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcInventory': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认开始盘点计划么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'inventoryBegin', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doinventoryInput(pBtn, parentData) {
    parentData['btnName'] = pBtn.ACTION_VALUE;
    parentData['conditionColumns'] = {};
    parentData['formType'] = 'customForm';
    parentData['modeType'] = 'add';
    parentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  dorolloverDisable(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcRollover': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核出库单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McRolloverController', 'rolloverDisable', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  dorolloverCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcRollover': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核转仓入库么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McRolloverController', 'rolloverCheck', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  dorolloverOut(pBtn, parentData) {
    parentData['btnName'] = pBtn.ACTION_VALUE;
    parentData['conditionColumns'] = {};
    parentData['formType'] = 'customForm';
    parentData['modeType'] = 'add';
    parentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doTakePictures(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '出库拍照',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'DRIVER_NAME',
                'name': 'DRIVER_NAME_NAME',
                'component': 'input',
                'lable': '司机姓名',
                'comment': '司机姓名',
                'placeholder': '请输入司机姓名',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'DRIVER_TEL',
                'name': 'DRIVER_TEL_NAME',
                'component': 'input',
                'lable': '司机手机号',
                'comment': '司机手机号',
                'placeholder': '请输入司机手机号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC01_OB',
                'name': 'PIC01_OB_NAME',
                'component': 'picture',
                'lable': '驾驶证/身份证',
                'comment': '驾驶证/身份证',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择驾驶证/身份证',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC02_OB',
                'name': 'PIC02_OB_NAME',
                'component': 'picture',
                'lable': '行驶证',
                'comment': '行驶证',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择行驶证',
                'model': null,
                'visible': true,
                'require': false
              },
              {
                'column': 'PIC03_OB',
                'name': 'PIC03_OB_NAME',
                'component': 'picture',
                'lable': '车辆照片',
                'comment': '车辆照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择车辆照片',
                'model': null,
                'visible': true,
                'require': false
              },
              {
                'column': 'PIC04_OB',
                'name': 'PIC03_OB_NAME',
                'component': 'picture',
                'lable': '货物照片',
                'comment': '货物照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择货物照片',
                'model': null,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }

          let driverName = null;
          let driverTel = null;

          let pic01_ob = null;
          let pic02_ob = null;
          let pic03_ob = null;
          let pic04_ob = null;

          _.each(data, oneColumn => {
            if (oneColumn.column === 'DRIVER_NAME') {
              driverName = oneColumn.model;
            }
            if (oneColumn.column === 'DRIVER_TEL') {
              driverTel = oneColumn.model;
            }
            if (oneColumn.column === 'PIC01_OB') {
              pic01_ob = oneColumn.model;
            }
            if (oneColumn.column === 'PIC02_OB') {
              pic02_ob = oneColumn.model;
            }
            if (oneColumn.column === 'PIC03_OB') {
              pic03_ob = oneColumn.model;
            }
            if (oneColumn.column === 'PIC03_OB') {
              pic04_ob = oneColumn.model;
            }
          });

          let entity = {
            'mcOutbound': this.formatSelectEntity(selectList)[0],
            'driverName': driverName,
            'driverTel': driverTel,
            'pic01Ob': pic01_ob,
            'pic02Ob': pic02_ob,
            'pic03Ob': pic03_ob,
            'pic04Ob': pic04_ob
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthorDfmc('AppApi/McOutboundController', 'takePictures', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doOutboundDisable(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcOutbound': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认撤销出库单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McOutboundController', 'disable', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  doOutboundCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'mcOutbound': this.formatSelectEntity(selectList)[0],
      };
      this.confirmModal.show('confirm', {
        title: '确认审核出库单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McOutboundController', 'check', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doOutboundInput(pBtn, parentData) {
    parentData['btnName'] = pBtn.ACTION_VALUE;
    parentData['conditionColumns'] = {};
    parentData['formType'] = 'customForm';
    parentData['modeType'] = 'add';
    parentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doWarehouseInput(pBtn, parentData) {
    if (this.selectMore(parentData.data.data, false) !== 1) {
      return false;
    } else {
      parentData['detailCondition'] = this.setDetailConditons(parentData, parentData.data.data);
      parentData['data'] = _.filter(parentData.data.data, (item) => {
        return item['checked'];
      });
      parentData['btnName'] = pBtn.ACTION_VALUE;
      parentData['conditionColumns'] = {};
      parentData['formType'] = 'customForm';
      parentData['modeType'] = 'edit';
      parentData['actionArray'] = [];

      // 覆盖当前数据到data-center;
      _.each(this.dataCenterService._dataObject.list, (item) => {
        if (item['id'] === parentData['id']) {
          item = _.extend(item, parentData);
        }
      });
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }

  dowarehouseCheck(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '列车卸空拍照并入库审核',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PIC01_EMPTY',
                'name': 'PIC01_EMPTY_NAME',
                'component': 'picture',
                'lable': '车种车号',
                'comment': '车种车号',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择图片01',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC02_EMPTY',
                'name': 'PIC02_EMPTY_NAME',
                'component': 'picture',
                'lable': '车内照片',
                'comment': '车内照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择图片02',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let pic01_empty = null;
          let pic02_empty = null;
          let mcStorage = this.formatSelectEntity(selectList);

          _.each(data, oneColumn => {
            if (oneColumn.column === 'PIC01_EMPTY') {
              pic01_empty = oneColumn.model;
            }
            if (oneColumn.column === 'PIC02_EMPTY') {
              pic02_empty = oneColumn.model;
            }
          });
          let entity = {
            'pic01Empty': pic01_empty,
            'pic02Empty': pic02_empty,
            'mcStorage': mcStorage[0]
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthorDfmc('AppApi/McStorageController', 'check', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  dowarehouseDisable(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'stoId': _.get(selectList, '0.ID', null)
      };
      this.confirmModal.show('confirm', {
        title: '确认撤销制单么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McStorageController', 'unInput', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  dowarehouseUncheck(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'stoId': _.get(selectList, '0.ID', null)
      };
      this.confirmModal.show('confirm', {
        title: '确认取消审核么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McStorageController', 'unCheck', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doEmpty(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '列车卸空拍照',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PIC01_EMPTY',
                'name': 'PIC01_EMPTY_NAME',
                'component': 'picture',
                'lable': '车种车号',
                'comment': '车种车号',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择车种车号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC02_EMPTY',
                'name': 'PIC02_EMPTY_NAME',
                'component': 'picture',
                'lable': '车内照片',
                'comment': '车内照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择车内照片',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC03_EMPTY',
                'name': 'PIC03_EMPTY_NAME',
                'component': 'picture',
                'lable': '破损异常照片',
                'comment': '破损异常照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择破损异常照片',
                'model': null,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let pic01_empty = null;
          let pic02_empty = null;
          let pic03_empty = null;

          _.each(data, oneColumn => {
            if (oneColumn.column === 'PIC01_EMPTY') {
              pic01_empty = oneColumn.model;
            }
            if (oneColumn.column === 'PIC02_EMPTY') {
              pic02_empty = oneColumn.model;
            }
            if (oneColumn.column === 'PIC03_EMPTY') {
              pic03_empty = oneColumn.model;
            }
          });
          const queryData = {
            'userId': this.user.USER_ID,
            'pic01Empty': pic01_empty,
            'pic02Empty': pic02_empty,
            'pic03Empty': pic03_empty,
          };
          const bodyData = {
            'ids': this.formatSelectEntityId(selectList, 'ID')
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthor('AppApi/McWagonController', 'empty', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  doFull(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '列车装车拍照',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PIC01_EMPTY',
                'name': 'PIC01_EMPTY_NAME',
                'component': 'picture',
                'lable': '车种车号',
                'comment': '车种车号',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择车种车号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC02_EMPTY',
                'name': 'PIC02_EMPTY_NAME',
                'component': 'picture',
                'lable': '车内照片',
                'comment': '车内照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择车内照片',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PIC03_EMPTY',
                'name': 'PIC03_EMPTY_NAME',
                'component': 'picture',
                'lable': '破损异常照片',
                'comment': '破损异常照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择破损异常照片',
                'model': null,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let pic01_empty = null;
          let pic02_empty = null;
          let pic03_empty = null;
          let wagon_id = _.get(selectList, '0.ID', null);

          _.each(data, oneColumn => {
            if (oneColumn.column === 'PIC01_EMPTY') {
              pic01_empty = oneColumn.model;
            }
            if (oneColumn.column === 'PIC02_EMPTY') {
              pic02_empty = oneColumn.model;
            }
            if (oneColumn.column === 'PIC03_EMPTY') {
              pic03_empty = oneColumn.model;
            }
          });
          const queryData = {
            'userId': this.user.USER_ID,
            'pic01Empty': pic01_empty,
            'pic02Empty': pic02_empty,
            'pic03Empty': pic03_empty,
          };
          const bodyData = {
            'ids': this.formatSelectEntityId(selectList, 'ID')
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthor('AppApi/McWagonController', 'full', queryData, bodyData, true, 'hdbsthor_dfmc', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  doReturnWagon(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '确定归还股道',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'TRACK_ID_DOWN',
                'name': 'TRACK_ID_DOWN_NAME',
                'component': 'poplist',
                'lable': '归还股道线',
                'comment': '归还股道线',
                'customize': 24,
                'placeholder': '请选择归还股道线',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/MC_TRACK_YESONNO_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'TRACK_NAME_DOWN',
                    'name': 'TRACK_NAME_DOWN_NAME',
                    'component': 'input',
                    'lable': '归还股道线',
                    'comment': '归还股道线',
                    'placeholder': '',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  },
                  {
                    'column': 'TRACK_SHORT_DOWN',
                    'name': 'TRACK_SHORT_DOWN_NAME',
                    'component': 'input',
                    'lable': '归还股道线简称',
                    'comment': '归还股道线简称',
                    'placeholder': '',
                    'fromValue': 'TRACK_SHORT',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }

          let entity = this.formatMainToEntity(data);
          entity['mcWagonList'] = this.formatSelectEntity(selectList);
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthorDfmc('AppApi/McWagonController', 'returnWagon', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  formatMainToEntity(datas) {
    let entity: any = {};
    _.each(datas, (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatSelectEntity(datas) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        const column = _.camelCase(oneKeys);
        obj[column] = OneData[oneKeys];
      });
      entity.push(obj);
    });
    return entity;
  }

  formatSelectEntityId(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }


  doLocomotiveArrivals(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '确定入站',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'TRACK_ID_DOWN',
                'name': 'TRACK_ID_DOWN_NAME',
                'component': 'poplist',
                'lable': '入站股道',
                'comment': '入站股道',
                'customize': 24,
                'placeholder': '请选择入站股道',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/MC_TRACK_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'TRACK_NAME_DOWN',
                    'name': 'TRACK_NAME_DOWN_NAME',
                    'component': 'input',
                    'lable': '入站股道',
                    'comment': '入站股道',
                    'placeholder': '',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  },
                  {
                    'column': 'TRACK_SHORT_DOWN',
                    'name': 'TRACK_SHORT_DOWN_NAME',
                    'component': 'input',
                    'lable': '入站股道简称',
                    'comment': '入站股道简称',
                    'placeholder': '',
                    'fromValue': 'TRACK_SHORT',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }

          const entity = {
            'locomotiveId': _.get(selectList, '0.ID', null),
            'trackId': _.get(data, '0.model', null)
          };
          // tslint:disable-next-line:no-shadowed-variable
          this.requestService.doActionByHdbsthorDfmc('AppApi/McLocomotiveController', 'arrivals', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  setDetailConditons(pParentData, pData) {
    function isCondtion(OneColumn) {
      let bFind = false;
      if (_.has(OneColumn, 'iscondition')) {
        bFind = OneColumn.iscondition;
      }
      return bFind;
    }

    let conData;
    _.each(pData, function (oneData) {
      if (oneData['checked']) {
        conData = oneData;
      }
    });
    let tmp = {};
    _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
      //只将 iscondition 为 true 的作为条件传入详情页面。
      if (isCondtion(OneColumn)) {
        tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
      }
    });
    return tmp;
  }

  doLocomotiveOut(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      const entity = {
        'locomotiveId': _.get(selectList, '0.ID', null)
      };
      this.confirmModal.show('confirm', {
        title: '确认出发么？',
        suc: () => {
          this.requestService.doActionByHdbsthorDfmc('AppApi/McLocomotiveController', 'out', entity).subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }
}
