import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {UtilsService} from '../../service/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  private dateFormat = 'YYYY-MM-DD';

  constructor(private utils: UtilsService) {
  }

  changeModel() {
    if (this.bindModel) {
      const date = this.bindModel[this.hulkColumn.column + 'Date'];
      if (date) {
        this.bindModel[this.hulkColumn.column] = moment(date).format(this.dateFormat);
      } else {
        this.bindModel[this.hulkColumn.column] = null;
      }
    } else {
      const date = this.hulkColumn.modelDate;
      if (date) {
        this.hulkColumn.model = moment(date).format(this.dateFormat);
      } else {
        this.hulkColumn.model = null;
      }
    }
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    if (this.hulkColumn.component === 'datetime') {
      this.dateFormat = 'YYYY-MM-DD HH:mm:ss';
    }
    // 加载数据时候讲model数据格式化为date类型
    if (this.utils.Base_HasValue(this.bindModel)) {
      this.bindModel[this.hulkColumn.column + 'Date'] = moment(this.bindModel[this.hulkColumn.column])['_d'];
    }
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.modelDate = moment(this.hulkColumn.model)['_d'];
    } else if (this.hulkColumn.dateOptions) {
      if (this.hulkColumn.dateOptions['default']['today']) {
        this.hulkColumn.modelDate = moment()['_d'];
        this.hulkColumn.model = moment().format(this.dateFormat);
      } else if (this.utils.Base_HasValue(this.hulkColumn.dateOptions['default']['pastDay'])) {
        let days =  this.hulkColumn.dateOptions['default']['pastDay']
        this.hulkColumn.modelDate = moment().subtract(days, 'days')['_d'];
        this.hulkColumn.model = moment().subtract(days, 'days').format(this.dateFormat);
      }
    }
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column + 'Date'] = moment()['_d'];
      this.bindModel[this.hulkColumn.column] = moment().format(this.dateFormat);
    }
  }

  ngDoCheck(): void {
    if (this.bindModel) {
      if (this.bindModel[this.hulkColumn.column] !== this.bindModel['old' + this.hulkColumn.column]) {
        if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
          this.bindModel[this.hulkColumn.column + 'Date'] = moment(this.bindModel[this.hulkColumn.column])['_d'];
        } else {
          this.bindModel[this.hulkColumn.column + 'Date'] = null;
        }
        this.bindModel['old' + this.hulkColumn.column] = this.bindModel[this.hulkColumn.column];
      }
    } else {
      if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
          this.hulkColumn.modelDate = moment(this.hulkColumn.model)['_d'];
        } else {
          this.hulkColumn.modelDate = null;
        }
        this.hulkColumn.oldModel = this.hulkColumn.model;
      }
    }
  }

}
