import {Component, OnInit} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import {AjaxService} from '../../service/ajax.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {UserInfoService} from '../../service/user-info.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {StorageService} from '../../service/storage.service';
import {DataCenterService} from '../../service/data-center.service';
import {Md5} from 'ts-md5';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {RequestDataService} from "../../service/request-data.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmModalService} from "../../service/confirm-modal.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    validateForm: FormGroup;
    public hasError: any;
    private errmsg: any;
    private tilp: any;
    private hidden: any;
    public userName: any;
    public password: any;
    public verificationCode: any;//验证码
    public uuid: any;//验证码id
    public svgTxt: any;//验证码id
    public showCaptcha: Boolean = false;//是否显示验证码
    public userLoginRole: Boolean = false;//是否显示验证码

    public companyInfo = this.appConfigService.config.companyInfo;
    public useIpCoinfg = {
        useIpRange: false,
        hasIpRangeError: false,
        hasIpRangeErrorMsg: "",
        ip: ""
    }
    public loginRoleList = []
    public selectedLoginRole: any;

    public config: any = {};
    sysTitle = '';
    loginLogo = '';
    versionInfo = this.appConfigService.version;


    submitForm(): void {
        for (const i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
    }

    constructor(private fb: FormBuilder,
                private ajaxService: AjaxService,
                public appConfigService: AppConfigService,
                private util: UtilsService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private storage: StorageService,
                private dataCenterService: DataCenterService,
                private requestDataService: RequestDataService,
                private sanitizer: DomSanitizer,
                private confirmModal: ConfirmModalService,
                private userInfo: UserInfoService) {
    }

    emitErr(pMsg) {
        this.hasError = true;
        this.errmsg = pMsg;
        this.tilp = pMsg;
        this.hidden = {
            'visibility': 'visible'
        };
    }

    emitSysErr(pMsg?) {
        this.emitErr('系统维护中，请联系管理员。' + pMsg);
    }

    emitPswErr() {
        this.emitErr('用户名或密码错误，请重新输入。');
    }

    addTodoCenter() {
        let useTodoCenter = _sh.get(this.appConfigService.config, ['useTodoCenter'], false);
        if (useTodoCenter) {
            let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
                return item['id'] === "todoCenterForm";
            });
            if (tabIndex > 0) {
                this.dataCenterService._dataObject.index = tabIndex;
                this.dataCenterService.refreshDataCenterInStorage();
            } else {
                this.dataCenterService.newTab({
                    id: "todoCenterForm", // 唯一标识
                    parentDataId: "todoCenterForm", // 唯一标识
                    nowMenu: "todoCenterForm", // 菜单ID 用于详情返回
                    title: "待办列表", // 菜单名称 用户tab展示
                    formType: 'todoCenterForm', // 菜单类型 用于tab过滤显示模板
                    custormFormType: 'app-todo-center',
                    data: {}, // 查询返回数据
                });
            }
        }
    }


    /*登录*/
    async DoLoginCheck() {
        if (!this.util.Base_HasValue(this.userName) || !this.util.Base_HasValue(this.password)) {
            return;
        }
        const userLoginRole = _sh.get(this.appConfigService.config, ['userLoginRole'], false);

        if (userLoginRole) {
            if (!this.util.Base_HasValue(this.selectedLoginRole)) {
                return;
            }
        }

        if (this.showCaptcha) {
            let res = await this.requestDataService.checkCaptcha(this.uuid, this.verificationCode).toPromise();
            if (res.code == 200) {
            } else if (res.error.code == 40030) {
                this.emitErr('超过重试次数，更换了验证码');
                this.getCaptcha();
                return;
            } else if (res.error.code == 40040) {
                this.emitErr('验证码错误');
                return;
            } else if (res.error.code == 40010) {
                this.emitErr('网络错误');
                return;
            }
        }

        this.hasError = false;
        let resLogin = await this.requestDataService.doLogin(this.userName, this.password, this.selectedLoginRole).toPromise();
        const status = _sh.get(resLogin, ['status'], 200);
        if (status == 200) {
            resLogin['loginRole'] = this.selectedLoginRole;
            this.userInfo.setUserInfo(resLogin);
            this.router.navigate(['/index']);
            this.addTodoCenter();
        } else if (status == 500) {
            const errorText = resLogin.error;
            this.emitErr(errorText);
        }
    }


    // 模拟登陆(教师简历)
    imitateLogin(pUserName , sqlfile? , menuId?) {
        if (!sqlfile) {
            sqlfile = 'resume/login_imitate.json';
        }
        let params = {
            'mongoPF': this.appConfigService.config.mongoPF,
            'PF': this.appConfigService.config.MysqlPF,
            'sqlfile': sqlfile,
            'userId': pUserName,
            'userName': pUserName,
            'conditions': {}
        };
        this.ajaxService.ajaxPost(this.appConfigService.getServer().NodeMongoSql, params).subscribe(response => {
            if (this.util.Base_resHasErr(response)) {
                this.emitSysErr(response['message']);
            } else {
                if (response['data'].length === 0) {
                    this.emitPswErr();
                } else {
                    this.userInfo.setUserInfo(response['data'][0]);
                    const navigationExtras = {
                        queryParams: menuId ? { menuId } : {}
                    };
                    this.router.navigate(['/index'], navigationExtras);
                }
            }
        }, error => {
            this.emitSysErr();
        });
    }


    uncompileStr(code) {
        code = unescape(code);
        let c = String.fromCharCode(code.charCodeAt(0) - code.length);
        for (let i = 1; i < code.length; i++) {
            c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
        }
        return c;
    }

    getCaptcha() {
        this.uuid = this.util.Base_getUuid();
        this.requestDataService.getCaptcha(this.uuid).subscribe(res => {
            this.svgTxt = this.sanitizer.bypassSecurityTrustHtml(res.error.text);
        });
    }

    getSystemLoginRoleMenu() {
        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_login_role.json', {}).subscribe((response) => {
            this.loginRoleList = _sh.get(response, ['data'], []);
        }, (error) => {
            this.util.Base_Errored(error);
        });
    }

    async ngOnInit() {
        let that = this;
        const useCaptcha = _sh.get(this.appConfigService.config, ['useCaptcha'], false);
        const useIpRange = _sh.get(this.appConfigService.config, ['useIpRange'], false);
        const userLoginRole = _sh.get(this.appConfigService.config, ['userLoginRole'], false);


        this.showCaptcha = useCaptcha;
        this.userLoginRole = userLoginRole;

        this.validateForm = this.fb.group({
            userName: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true]
        });

        if (useCaptcha) {
            this.validateForm.addControl('verificationCode', this.fb.control(null, Validators.required));
            this.getCaptcha();
        }
        if (userLoginRole) {
            this.validateForm.addControl('loginRole', this.fb.control(null, Validators.required));
            await this.getSystemLoginRoleMenu();
        }

        if (useIpRange) {
            const useIpCoinfgRes = await this.requestDataService.checkIp().toPromise();
            that.useIpCoinfg['hasIpRangeError'] = !useIpCoinfgRes.isIn;
            that.useIpCoinfg['hasIpRangeErrorMsg'] = `您的IP是 ${useIpCoinfgRes.ip} 地址不在我们的白名单内，请联系安全管理员`;
            that.useIpCoinfg['ip'] = useIpCoinfgRes.ip;
        }
        let userName = this.router['browserUrlTree'].queryParams['userName'];
        if (this.util.Base_HasValue(userName) && this.appConfigService.config.sql_path === 'resume') {
            // 解密三次
            userName = this.uncompileStr(this.uncompileStr(this.uncompileStr(userName)));
            this.imitateLogin(userName);
        }

        this.config = this.appConfigService.config.logoConfig;
        if (this.util.Base_HasValue(this.config['sysTitle'])) {
            this.sysTitle = this.config['sysTitle'];
        }
        if (this.util.Base_HasValue(this.config['loginLogo'])) {
            this.loginLogo = this.config['loginLogo'];
        }

        const params = await this.activatedRoute.queryParams['_value'];
        const {quickLogin, userId, token, menuId} = params;
        if (quickLogin) { // 如果是快速登录
            const exemptPasswordLogin = this.appConfigService.config.logoConfig.exemptPasswordLogin;
            const exemptPasswordLoginToken = this.appConfigService.config.logoConfig.exemptPasswordLoginToken;
            if (!exemptPasswordLogin) {
                this.emitErr('该系统，不支持快捷登录，需要进行在环境文件之中进行配置。');
                return;
            }
            if (!this.util.Base_HasValue(userId)) {
                this.emitErr('快速登录必须配置 userId');
                return;
            }
            if (!this.util.Base_HasValue(token)) {
                this.emitErr('快速登录必须配置 token');
                return;
            }
            if (token != exemptPasswordLoginToken) {
                this.emitErr('token 错误 , 请手工登录');
                return;
            }
            this.imitateLogin(userId,'login_imitate.json', menuId); // 快捷登录。
        }
    }
}
