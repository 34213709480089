import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import * as _ from "underscore";
import * as _sh from 'lodash';
@Component({
  selector: 'app-seat-view',
  templateUrl: './seat-view.component.html',
  styleUrls: ['./seat-view.component.scss']
})
export class SeatViewComponent implements OnInit {

  selectedValue = null;
  hallList = [];
  seatList = [];
  @Input() parentData;
  constructor(
    private requestDataService: RequestDataService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.getHallList();
  }
  getHallList(){
    observableForkJoin(
      this.requestDataService.getDataByCondition('JieLiv2/lt/lt_screen_list.json', {}),
    ).subscribe(res => {
      this.hallList = _sh.get(res,[0,'data'],[]);
    })
  }
  selectSeat(){
    let that = this;
    observableForkJoin(
      this.requestDataService.getDataByCondition('JieLiv2/lt/lt_seat_detail.json', {LS_ID: this.selectedValue}),
    ).subscribe(res => {
      this.seatList = _sh.get(res,[0,'data'],[]);

      var divConfig = {
        width: 50, // 盒子的宽，必须和CSS一致
        height: 50, //盒子的高度，必须和CSS一致
        left: 0, //一个单位的left距离
        top: 0, //一个单位的 top 距离
        mgR: 10,// 一个单位的标准间隙
        mgB: 10
      };
      _.each(this.seatList,  (OneStorage) => {
        OneStorage['selected'] = false;
        if(that.utils.Base_HasValue(OneStorage.LST_X) && that.utils.Base_HasValue(OneStorage.LST_Y)){
          OneStorage.x = ((OneStorage.LST_X * 1 - 1) * divConfig.width + (OneStorage.LST_X * 1 - 1) * divConfig.mgR + divConfig.left) + "px";
          OneStorage.y = ((OneStorage.LST_Y * 1 - 1) * divConfig.height + (OneStorage.LST_Y * 1 - 1) * divConfig.mgB + divConfig.top) + "px";
        }
      })
    })
  }
  showDetail(one){
    _.each(this.seatList,item => {
      if (item.ID == one.ID){
        item.selected = true;
        return;
      }
    })
  }
}
