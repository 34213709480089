
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {AppConfigService} from './../../service/app-config.service';
import {RequestDataService} from './../../service/request-data.service';
import {DataCenterService} from './../../service/data-center.service';
import {UtilsService} from './../../service/utils.service';
import {ListActionService} from '../../service/list-action.service';
import {FormComponent} from './../../basicComponent/form/form.component';

import * as _ from 'underscore';
import * as _sh from 'lodash';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {UUID} from "angular2-uuid";
import {switchMap} from "rxjs/operators";


@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.css']
})
export class ReportFormComponent implements OnInit {

  @ViewChild(FormComponent,{static: false}) childForm !: FormComponent;


  // table 滚动条宽度
  public scrollWidth: string = '';

  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = this.appConfigService.config.pageCount;
  // 总条数
  public total: any = 1;

  //父级组件的数据对象
  @Input() parentData: any;

  // 当前table的表头
  public showColumns: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };

  // 查询数据
  public conditionList: any[] = [];

  //排序字段的数组
  private sortArray: any[] = [];


  // 列表页帮助文档
  public listHelpUrl: string = '';

  noDataVisible = true;


  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private listAction: ListActionService,
              private confirmModal: ConfirmModalService) {
  }

  handleClose(removedCondition: {}): void {
    // form 中数据清空
    this.conditionList = this.conditionList.filter(tag => tag !== removedCondition);
    _.each(this.childForm._validateForm['columns'], (item) => {
      if (removedCondition['column'] === item['column']) {
        item['model'] = null;
        if (_.contains(['date', 'datetime', 'dateRange', 'dateTimeRange'], item['component'])) {
          item['modelDate'] = null;
        }
        if (_.contains(['poplist', 'multipopCondition'], item['component'])) {
          item['modelName'] = '';
        }
      }
    });
    // 重新查询
    this.searchFilter();
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    let error = {
      pass: true
    };
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
      if(!this.utils.Base_HasValue(item['model']) && item['require']){
        error['pass'] = false;
      }
    });
    condition.push(error);
    return condition;
  }

  /**
   * 过滤调用方法
   */
  searchFilter(reset: boolean = true) {
    this.conditionList = this.transformCondition(this.childForm['_validateForm']['columns']);
    if(!this.conditionList[this.conditionList.length - 1]['pass']){
      this.conditionList.splice(this.conditionList.length - 1, 1);
      return this.confirmModal.show('warning', {title: '请输入查询条件中的必填项'});
    }
    this.conditionList.splice(this.conditionList.length - 1, 1);
    this.noDataVisible = true;
    // 覆盖当前数据到data-center并重新查询
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item['searchCondition'] = this.conditionList;
      }
    });
    let condition = {};
    _.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    this.searchData(reset, condition, this.sortArray);
  }

  /**
   * 清除条件调用方法
   */
  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  initSearchCondition(parentData) {
    let data = parentData.data;
    let searchCondition = parentData.searchCondition;
    let searchForm = parentData.conditionColumns;
    // 如果有存储查询条件则需要将值合并到conditionColumns中
    if (this.utils.Base_HasValue(searchCondition)) {
      _.each(searchForm.columns, (item) => {
        const condition = _.find(searchCondition, (searchItem) => {
          return searchItem['column'] === item['column'];
        });
        if (this.utils.Base_HasValue(condition)) {
          item['model'] = condition['code'];
        }
      });
    }
    this.conditionColumns = searchForm;
    this.conditionList = this.transformCondition(searchForm.columns);
  }

  // 查询后的数据进行封装
  transformResult(data) {
    this.showColumns = data.showColumns.columns || [];
    this.scrollWidth = data.showColumns.scrollWidth || null;
    this.total = data.page.rowCount;
    this.pageIndex = data.page.pageNum;
    if (this.utils.Base_HasValue(this.parentData.sortArray)) {
      _.each(this.showColumns, oneColumn => {
        _.each(this.parentData.sortArray, oneSort => {
          if (oneColumn['column'] === oneSort['column']) {
            oneColumn['sortType'] = oneSort['type'];
          }
        });
      });
    }
  }

  // 通用封装数据结果
  transformData(data) {
    this.transformResult(data);
    // 重新覆盖 datacenter 数据
    this.parentData.data = data;
    this.parentData.pageIndex = this.pageIndex;
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  searchData(reset: boolean = false, condition?: {}, pSortBy?): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.requestDataService.getPaginationData(this.parentData.searchId, condition || {}, this.pageIndex, this.pageSize, pSortBy).subscribe((data: any) => {
      this.transformData(data);
    });
  }

  doAction(pBtn): void {
    if (this.utils.Base_HasValue(this.parentData.custormFormType)) {
      this.parentData.custormFormType = '';
    }

    this.listAction.doAction(pBtn, this.parentData, "reportForm", null)
        .pipe(
            switchMap((lv01: Observable<any>) => lv01),
            switchMap((lv02: Observable<any>) => lv02),
            switchMap((lv03: Observable<any>) => lv03)
        )
        .subscribe({
          next: lv04 => {
            const status = _sh.get(lv04, ['status'], 200);
            if (status === 500) {
              let errorMsg = _sh.get(lv04, [0, 'error'], null);
              errorMsg = _sh.get(lv04, ['error', 'message'], errorMsg);
              this.confirmModal.show('error', {
                'title': '执行错误',
                'content': errorMsg
              });
            } else if (status === 200) {
              const result = _sh.get(lv04, ['result'], 'success');
              if (result === 'success') {
                this.confirmModal.show('success', {'title': '执行成功'});
              } else if (result === 'error') {
                const errorMsg = _sh.get(lv04, ['message'], null);
                this.confirmModal.show('error', {
                  'title': '执行错误',
                  'content': errorMsg
                });
              }
              this.searchFilter();
            }
          },
          error: err => console.error('Something wrong occurred:', err),
          complete: () => console.log('Action completed')
        });
  }


  /**
   * 排序方法
   *
   * @param pType 排序类型
   * @param pColumn 排序字段
   */
  sortColumn(pType, pColumn): void {
    // let sortType = pType === "descend"?"DESC":"ASC";
    let sortObject = {
      'column': pColumn.column.replace('_DICT', ''),
      'type': pType
    };
    if (this.utils.Base_HasValue(this.sortArray)) {
      let object = _.find(this.sortArray, function (oneObject) {
        return oneObject['column'] === pColumn.column;
      });
      if (this.utils.Base_HasValue(object)) {
        //取消排序
        if (object['type'] === pType) {
          this.sortArray = _.without(this.sortArray, object);
        } else if (object['type'] !== pType) {
          object['type'] = pType;
        }
      } else {
        this.sortArray.push(sortObject);
      }
    } else {
      this.sortArray.push(sortObject);
    }
    //重新根据排序查询
    if (this.utils.Base_HasValue(this.sortArray)) {
      this.parentData['sortArray'] = this.sortArray;
      this.searchFilter();
    }
  }

  ngOnInit() {
    // 如果该tab数据不存在则需要查询
    if (!this.utils.Base_HasValue(this.parentData.data)) {
      let searchArray = [
        this.requestDataService.getConditionData(this.parentData.searchId),
        this.requestDataService.getBtnListJson(this.parentData.nowMenu.MENU_ID),
        this.requestDataService.getPaginationData(this.parentData.searchId, {}, this.pageIndex, this.pageSize)
      ];
      if(this.utils.Base_HasValue(this.parentData.nowMenu['MENU_MENU']) && this.parentData.nowMenu['MENU_MENU'] === 'noData'){
        this.noDataVisible = false;
        searchArray = [
          this.requestDataService.getConditionData(this.parentData.searchId),
          this.requestDataService.getBtnListJson(this.parentData.nowMenu.MENU_ID)
        ];
      }
      const parallel$ = observableForkJoin(searchArray);
      parallel$.subscribe(
        values => {
          this.conditionColumns = values[0]['conditionColumns'] ? (values[0]['conditionColumns'][0] || {}) : {};
          this.parentData.actionArray = values[1]['data'] || [];
          this.parentData.conditionColumns = this.conditionColumns;
          if(this.utils.Base_HasValue(values[2])){
            this.noDataVisible = true;
            this.transformData(values[2]);
          }
        }
      );
    } else {
      this.noDataVisible = true;
      this.transformResult(this.parentData.data);
      this.initSearchCondition(this.parentData);
    }
    this.listHelpUrl = this.parentData.listHelpUrl;
  }

}
