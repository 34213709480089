import {Component, Input, OnInit} from '@angular/core';
import {StorageService} from "../../service/storage.service";
import {UploadService} from "../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../service/data-center.service";
import {RequestDataService} from "../../service/request-data.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {AutoInsertService} from "../../service/auto-insert.service";

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css']
})


export class RoleFormComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any = {
    conditionColumns: {
      columns: []
    }
  };
  user: any;
  nowMenu: any;
  appList: any;
  selectUser: any; // 选中的用户
  selectRole: any; // 选择的用户
  allChecked = false;
  indeterminate = false;
  allChecked_fo = false;
  indeterminate_fo = false;
  modalVisible: any;
  userDatas: any = [];
  roleDatas: any = [];
  makeUpFunc: any = [];
  deleteMakeUpFunc: any = [];
  taskNow = 1;
  taskTotal = 1;
  taskProgress = 100;
  leftTitle = null;
  rightTitle = null;
  searchLeft :any;
  searchRight : any;

  // 总条数
  public total: any = 1;
  // 当前页数
  public pageIndex: any = 1;
  // 每页条数
  public pageSize: any = 10;
  public nzPageSizeOptions: any = [10, 20, 50, 100, 200, 400];

  // 总条数
  public rightTotal: any = 1;
  // 当前页数
  public rightPageIndex: any = 1;
  // 每页条数
  public rightPageSize: any = 10;
  public rightNzPageSizeOptions: any = [10, 20, 50, 100, 200, 400];

  public selectAll: boolean;

  constructor(
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    // private config: AppConfigService,
    private appConfigService: AppConfigService,
    private confirmModal: ConfirmModalService,
  ) {
    this.user = this.userInfo.getUserInfo();
  }


  ngOnInit() {
    this.appList = JSON.parse(this.storage.getData('appList'));
    if (this.util.Base_HasValue(this.appList)) {
    this.nowMenu = this.dataCenterService._dataObject.nowMenu;
    this.appList = _.find(this.appList,{'LIST_ID': _.get(this.nowMenu,['MENU_ID'])});
      if(this.appList['LIST_ID'] === "0613A3C15778AB30F61610EB701370B7"){
        this.rightPageSize = 400;
        this.pageSize = 400;
      }
      if (this.util.Base_HasValue(this.appList)) {
    this.doRefresh();
  }
    }
  }

  modalCancel() {
    this.modalVisible = false;
  }

  modelOk() {
    this.modalVisible = false;
    this.checkAll_fo(false);
    this.checkAll(false);
    _.each(this.roleDatas, item => {
      _.set(item, ['selected'], false);
    })
    _.each(this.userDatas, item => {
      _.set(item, ['selected'], false);
    })
  }

  getInsertValue(pOneUser,pOneRole,pInsertValue){
    let iFind = _.indexOf(['SYS-UUID','SYS_USER_ID','SYS_USER_NAME','SYS-DATETIME'],pInsertValue);
    if (iFind >= 0) {
      return pInsertValue;
    } else if (iFind == -1) {
      if (_.isNumber(pInsertValue)) {
        return pInsertValue;
      } else if (_.isInteger(pInsertValue)) {
        return pInsertValue;
      } else {
        let settArray = _.split(pInsertValue,'.');
        if (settArray.length == 0) {
          return pInsertValue
        } else if (settArray.length == 2) {
          if (settArray[0] == 'SYS-LEFT') {
            return pOneUser[settArray[1]];
          } else if (settArray[0] == 'SYS-RIGHT') {
            return pOneRole[settArray[1]];
          }
        }
      }
    }
  }

  makeUpRecordsArr(pOneUser) {
    const roleSelectList = _.filter(this.roleDatas, (item) => {
      return item['checked'];
    });
    let recordsArr = [];
    _.each(roleSelectList, oneRole => {
      let obj = {

      }
      _.each(this.makeUpFunc['columns'], oneColumn=>{
        obj[oneColumn['column']] = this.getInsertValue(pOneUser,oneRole,oneColumn['insertValue'])
      });
      recordsArr.push(
        obj
      )
    });
    let columnsArr = [];

    _.each(this.makeUpFunc['columns'], oneColumn=>{
      let obj = {

      }
      obj['COLUMN']= oneColumn['column'];
      columnsArr.push(
        obj
      )
    });
    return {
      'recordsArr':recordsArr,
      'columnsArr':columnsArr
    };
  }

  doSave() {
    const userSelectList = _.filter(this.userDatas, (item) => {
      return item['checked'];
    });
    const roleSelectList = _.filter(this.roleDatas, (item) => {
      return item['checked'];
    });
    if (userSelectList.length == 0) {
      this.confirmModal.show('error', {'title': '左边的数据框选择是0'});
    } else {
      if (roleSelectList.length == 0) {
        this.confirmModal.show('error', {'title': '右边的数据框选择是0'});
      } else {
        this.modalVisible = true;
        this.taskNow = 0;
        this.taskTotal = userSelectList.length;
        _.each(userSelectList, async oneUser => {
          this.taskNow++;
          this.taskProgress = _.round((this.taskNow / this.taskTotal) * 100, 2)
          await this.doSaveOne(oneUser);
        })
      }
    }
  }

  makeUpWhere(pOneUser) {
    let whereArr = [];
    _.each(this.deleteMakeUpFunc['columns'],oneColumn=>{
      let whereObj = {
        'COLUMN': oneColumn['column'],
        'VALUE': '?',
        'OPERATOR': '=',
        'VALUE_TYPE': 'string',
        'CONDITION_OPERATOR': 'AND',
        'GROUP': 'GROUP_01'
      }
      whereArr.push(whereObj);
    })
    let records = {

    }
    _.each(this.deleteMakeUpFunc['columns'],oneColumn=>{
      records[oneColumn['column']] = this.getInsertValue(pOneUser,null,oneColumn['insertValue'])
    })

    let columnsArr = [];
    _.each(this.deleteMakeUpFunc['columns'],oneColumn=>{
      let obj = {

      }
      obj['COLUMN']= oneColumn['column'];
      columnsArr.push(
        obj
      )
    });

    return {
      'whereArr':whereArr,
      'columnsArr':columnsArr,
      'recordsArr':records
    }
  }

  async doSaveOne(pOneUser) {

    let whereObj = this.makeUpWhere(pOneUser);
    let recordsObj = this.makeUpRecordsArr(pOneUser);

    const templateJson = {
      'LIST': [
        {
          'TABLE': this.makeUpFunc['tablename'],
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': whereObj['whereArr'],
          'COLUMNS': whereObj['columnsArr'],
          'RECORDS': [whereObj['recordsArr']]
        },
        {
          'TABLE': this.makeUpFunc['tablename'],
          'ACTION': 'Insert',
          'WHEREGROUP': [],
          'WHERE': [],
          'COLUMNS': recordsObj['columnsArr'],
          'RECORDS': recordsObj['recordsArr']
        },
      ]
    };
    this.autoInsert.ExecJson(templateJson).subscribe(response => {
      if (response.state === 'success') {
      } else {
        this.confirmModal.show('error', {'title': '取消失败，请刷新重试'});
      }
    });
  }

  doSelectUser(pItem): void {
    _.each(this.userDatas, item => {
      _.set(item, ['selected'], false);
    })
    this.selectUser = _.find(this.userDatas, pItem);
    _.set(this.selectUser, ['selected'], true);
    this.doSelectRoles(this.selectUser['userId']);
  }

  doSelectRoles(pUserId) {
    _.each(this.roleDatas, item => {
      // _.set(item, ['selected'], false);
      _.set(item, ['checked'], false);

    })

    const search = observableForkJoin(
      this.requestData.getDataByCondition(this.appList['CONDITION_ID'], {'USER_ID': pUserId}),
    );
    search.subscribe(
      values => {
        let datas = values[0]['data'] || [];
        _.each(datas, oneRole => {
          this.selectRole = _.find(this.roleDatas, {'roleId': oneRole['roleId']});
          // _.set(this.selectRole, ['selected'], true);
          _.set(this.selectRole, ['checked'], true);
        });
      }
    );
  }


  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  checkAll_fo(value: boolean): void {
    this.roleDatas.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus_fo();
  }

  checkAll(value: boolean): void {
    this.userDatas.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.userDatas.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  refreshStatus_fo(): void {
    const validData = this.roleDatas.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_fo = allChecked;
    this.indeterminate_fo = (!allChecked) && (!allUnChecked);
  }


  // 对左边网页查询后的数据进行封装
  transformResult(data) {
    let dataSet = data.data || [];
    this.userDatas = dataSet;
    this.refreshStatus();
    this.total = data.page.rowCount; //更新总条数
    this.pageIndex = data.page.pageNum; //更新总页数
  }

  // 对右边网页查询后的数据进行封装
  transformResultRight(data) {
    let dataSet = data.data || [];
    this.roleDatas = dataSet;
    this.refreshStatus_fo();
    this.rightTotal = data.page.rowCount; //更新总条数
    this.rightPageIndex = data.page.pageNum; //更新总页数
  }

  doRefresh(reset: boolean = false): void {
    if (reset) {
      this.searchLeft = null;
      this.searchRight = null;
      this.pageIndex = 1;
    }
    const search = observableForkJoin(
        this.requestData.getPaginationData(this.appList['SEARCH_ID'], {}, this.pageIndex, this.pageSize),
        this.requestData.getPaginationData(this.appList['UPDATE_ID'], {}, this.rightPageIndex, this.rightPageSize),

        this.requestData.getDataByJson(this.appList['SEARCH_ID']),
        this.requestData.getDataByJson(this.appList['UPDATE_ID'])
    );
    search.subscribe(
        values => {
          this.leftTitle = values[2]['_title'];
          this.rightTitle = values[3]['_title'];
          this.transformResult(values[0]|| []);

          this.deleteMakeUpFunc = values[0]['detailColumns'][0] || {};
          this.makeUpFunc = values[0]['detailColumns'][1] || {};
          this.transformResultRight(values[1]|| []);
        }
    );
  }

  searchDataLeft(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    } //true：改变page size，index此时为1，说明跳转到该页面
    this.requestData.getPaginationData(this.appList['SEARCH_ID'], {'USER_REAL_NAME':this.searchLeft}, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.transformResult(data);
    });
  }

  searchDataRight(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    } //true：改变page size，index此时为1，说明跳转到该页面
    this.requestData.getPaginationData(this.appList['UPDATE_ID'], {'ORG_NAME':this.searchRight}, this.rightPageIndex, this.rightPageSize).subscribe((data: any) => {
      this.transformResultRight(data);
    });
  }

}
