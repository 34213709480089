import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataCenterService} from '../../../service/data-center.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UserInfoService} from '../../../service/user-info.service';
import * as moment from 'moment';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {NzModalService} from 'ng-zorro-antd';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';


@Component({
  selector: 'app-wagon-formation',
  templateUrl: './wagon-formation.component.html',
  styleUrls: ['./wagon-formation.component.css']
})
export class WagonFormationComponent implements OnInit {

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  public searchForm: any = [{
    'title': '查询车皮与机车头',
    'type': 'normal',
    'tablename': 'wagon_formation',
    'formname': 'wagon_formation',
    'columns': [
      {
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 12,
        'column': 'STATION_ID',
        'name': 'STATION_ID_NAME',
        'component': 'poplist',
        'lable': '调度站点',
        'comment': '调度站点',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择调度站点',
        'model': null,
        'visible': true,
        'disable': false,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_TRAIN_STATION_FINISH.json',
        'dictionaryPF': null,
        'dictionaryParam': [
          {
            'clearValues': ['SYS.wagon_formation.LOCOMOTIVE_ID']
          }]
      },
      {
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 12,
        'column': 'LOCOMOTIVE_ID',
        'name': 'LOCOMOTIVE_ID_NAME',
        'component': 'poplist',
        'lable': '挂载机车头',
        'comment': '挂载机车头',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择挂载机车头',
        'model': null,
        'visible': true,
        'disable': false,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/LOCOMOTIVE_POPLIST.json',
        'dictionaryPF': null,
        'dictionaryParam': [
          {
            'column': 'STATION_ID',
            'value': 'SYS.wagon_formation.STATION_ID',
            'errormsg': '请先选择调度站点'
          }
        ],
      },
      {
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 12,
        'column': 'WAGON_STATE',
        'name': 'WAGON_STATE_NAME',
        'component': 'select',
        'lable': '待编组车辆状态',
        'comment': '车辆状态',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '车辆请选状态',
        'model': null,
        'visible': true,
        'disable': false,
        'type': 'normal',
        'require': false,
        'dictionary': 'dfmc/basic/dict/WAGON_STATE.json'
      }
    ]
  }];

  public allChecked = false;
  private indeterminate = false;

  public allChecked_fo = false;
  private indeterminate_fo = false;

  public wagonForm: any;
  public formationForm: any;
  private StationId: any;
  private LocomotiveId: any;
  private wagonFormationFlag: any;

  public disableSearchBtn = false;
  public disableClearBtn = true;


  refreshStatus(): void {
    const validData = this.wagonForm.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.wagonForm.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus_fo(): void {
    const validData = this.wagonForm.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_fo = allChecked;
    this.indeterminate_fo = (!allChecked) && (!allUnChecked);
  }

  checkAll_fo(value: boolean): void {
    this.formationForm.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus_fo();
  }

  clearData() {
    this.wagonForm = [];
    this.formationForm = [];
    this.StationId = null;
    this.LocomotiveId = null;
    _.set(this.searchForm, '0.columns.0.model', null);
    _.set(this.searchForm, '0.columns.1.model', null);
    _.set(this.searchForm, '0.columns.2.model', null);
    _.set(this.searchForm, '0.columns.0.disable', false);
    _.set(this.searchForm, '0.columns.1.disable', false);
    _.set(this.searchForm, '0.columns.2.disable', false);
    this.disableSearchBtn = false;
    this.disableClearBtn = true;
  }

  searchList() {
    this.StationId = _.get(this.searchForm, '0.columns.0.model', null);
    this.LocomotiveId = _.get(this.searchForm, '0.columns.1.model', null);
    if (!this.util.Base_HasValue(this.StationId)) {
      this.confirmModal.show('error', {title: '请先选择调度车站信息'});
      return false;
    }
    if (!this.util.Base_HasValue(this.LocomotiveId)) {
      this.confirmModal.show('error', {title: '请先选择列车信息'});
      return false;
    }
    let wagonState = _.get(this.searchForm, '0.columns.2.model', null);
    let waitFormation = {};
    if (this.util.Base_HasValue(wagonState)) {
      waitFormation = {
        'STATION_ID': this.StationId,
        'WAGON_STATE': wagonState
      };
    } else {
      waitFormation = {
        'STATION_ID': this.StationId
      };
    }

    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/mc/mc_formation_wagon.json', waitFormation),
      this.requestData.getDataByCondition('dfmc/mc/mc_formation_locomotive.json', {
        'LOCOMOTIVE_ID': this.LocomotiveId,
        'STATION_ID': this.StationId
      })
    );
    parallel$.subscribe(
      values => {
        this.wagonForm = values[0]['data'];
        this.formationForm = values[1]['data'];
        this.refreshStatus();
        this.refreshStatus_fo();
        this.disableClearBtn = false;
        this.disableSearchBtn = true;
        _.set(this.searchForm, '0.columns.0.disable', true);
        _.set(this.searchForm, '0.columns.1.disable', true);
        _.set(this.searchForm, '0.columns.2.disable', true);
      }
    );
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }

    return iCount;
  }

  doUpLocomotiveNumber() {
    if (this.selectMore(this.wagonForm, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(this.wagonForm, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '输入编组顺位',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'WAGON_SEQUENCE',
                'name': 'WAGON_SEQUENCE_NAME',
                'component': 'input',
                'lable': '编组顺位',
                'comment': '编组顺位',
                'customize': 24,
                'placeholder': '请选择编组顺位',
                'model': null,
                'visible': true,
                'type': 'number',
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let selectCount = selectList.length; // 选中数量
          let insertNumber = _.get(data, '0.model', null); // 插入位置
          let hasCount = this.formationForm.length; // 已有数量

          _.each(selectList, (oneRow) => {
            if (oneRow['WAGON_FORMATION_FLAG'] === 'INIT') {
              oneRow['WAGON_FORMATION_FLAG'] = 'UPLOAD';
              oneRow['WAGON_FORMATION_FLAG_DICT'] = '执行编组';
            } else if (oneRow['WAGON_FORMATION_FLAG'] === 'DOWNLOAD') {
              oneRow['WAGON_FORMATION_FLAG'] = 'FORMATION';
              oneRow['WAGON_FORMATION_FLAG_DICT'] = '编组完成';
            }
          });

          if (insertNumber > hasCount) {
            // 如果插入位置大于 已有数量，直接放到最后面
            this.formationForm = _.cloneDeep(_.concat(this.formationForm, selectList));
          } else if (insertNumber <= 1) {
            // 如果插入位置是 负数，0，1，直接放到最前面
            this.formationForm = _.cloneDeep(_.concat(selectList, this.formationForm));
          } else if (1 < insertNumber <= hasCount) {
            // 如果插入位置小于 已有数量 放到中间
            let firstArray = _.cloneDeep(_.take(this.formationForm, insertNumber - 1));
            let secondtArray = _.cloneDeep(_.takeRight(this.formationForm, hasCount - insertNumber + 1));
            this.formationForm = _.cloneDeep(_.concat(firstArray, selectList, secondtArray));
          }

          // 删除选中的。
          this.wagonForm = this.wagonForm.filter((item) => {
            return !item.checked;
          });
          this.reBuildWagonSequence(); // 编号
          this.refreshStatus(); // 修改左边的状态
          this.refreshStatus_fo(); // 修改右边的状态
        }
      });
    }
  }

  doUpLocomotive() {
    if (this.selectMore(this.wagonForm, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(this.wagonForm, (item) => {
        return item['checked'];
      });
      _.each(selectList, (oneRow) => {
        if (oneRow['WAGON_FORMATION_FLAG'] === 'INIT') {
          oneRow['WAGON_FORMATION_FLAG'] = 'UPLOAD';
          oneRow['WAGON_FORMATION_FLAG_DICT'] = '执行编组';
        } else if (oneRow['WAGON_FORMATION_FLAG'] === 'DOWNLOAD') {
          oneRow['WAGON_FORMATION_FLAG'] = 'FORMATION';
          oneRow['WAGON_FORMATION_FLAG_DICT'] = '编组完成';
        }
        this.formationForm.push(oneRow);
      });
      this.wagonForm = this.wagonForm.filter((item) => {
        return !item.checked;
      });
      this.reBuildWagonSequence();
      this.refreshStatus();
      this.refreshStatus_fo();
    }
  }

  doModifyNumber() {
    if (this.selectMore(this.formationForm, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(this.formationForm, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '输入编组顺位',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'WAGON_SEQUENCE',
                'name': 'WAGON_SEQUENCE_NAME',
                'component': 'input',
                'lable': '编组顺位',
                'comment': '编组顺位',
                'customize': 24,
                'placeholder': '请选择编组顺位',
                'model': null,
                'visible': true,
                'type': 'number',
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let selectCount = selectList.length; // 选中数量
          let insertNumber = _.get(data, '0.model', null); // 插入位置
          let hasCount = this.formationForm.length; // 已有数量


          if (insertNumber >= hasCount) {
            // 如果插入位置大于 已有数量，直接放到最后面
            this.formationForm = this.formationForm.filter((item) => {
              return !item.checked;
            });
            this.formationForm = _.cloneDeep(_.concat(this.formationForm, selectList));
          } else if (insertNumber <= 1) {
            // 如果插入位置是 负数，0，1，直接放到最前面
            this.formationForm = this.formationForm.filter((item) => {
              return !item.checked;
            });
            this.formationForm = _.cloneDeep(_.concat(selectList, this.formationForm));
          } else if (1 < insertNumber <= hasCount) {
            // 如果插入位置小于 已有数量 放到中间
            // let firstArray = _.cloneDeep(_.take(this.formationForm, insertNumber - 1));
            // let secondtArray = _.cloneDeep(_.takeRight(this.formationForm, hasCount - insertNumber + 1));
            // this.formationForm = _.cloneDeep(_.concat(firstArray, selectList, secondtArray));
            let findWagonSequence = _.indexOf(this.formationForm, insertNumber);

          }

          this.reBuildWagonSequence();
          this.refreshStatus();
          this.refreshStatus_fo();
        }
      });
    }
  }


  doDownLocomotive() {
    if (this.selectMore(this.formationForm, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(this.formationForm, (item) => {
        return item['checked'];
      });
      _.each(selectList, (oneRow) => {
        if (oneRow['WAGON_FORMATION_FLAG'] === 'FORMATION') {
          oneRow['WAGON_FORMATION_FLAG'] = 'DOWNLOAD';
          oneRow['WAGON_FORMATION_FLAG_DICT'] = '执行解编';
        } else if (oneRow['WAGON_FORMATION_FLAG'] === 'UPLOAD') {
          oneRow['WAGON_FORMATION_FLAG'] = 'INIT';
          oneRow['WAGON_FORMATION_FLAG_DICT'] = '可编组';
        }
        this.wagonForm.push(oneRow);
      });
      this.formationForm = this.formationForm.filter((item) => {
        return !item.checked;
      });
      this.reBuildWagonSequence();
      this.refreshStatus();
      this.refreshStatus_fo();
    }
  }

  reBuildWagonSequence() {
    let index = 1;
    _.each(this.formationForm, (oneRow) => {
      oneRow['WAGON_SEQUENCE'] = index;
      index += 1;
    });
  }


  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
    this.reBuildWagonSequence();
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
    this.reBuildWagonSequence();
  }

  formatToDetailEntity(detail) {
    let entityList = [];
    _.each(detail, (oneRow) => {
      let entity = {};
      let columns = _.keys(oneRow);
      _.each(columns, (column) => {
        entity[_.camelCase(column)] = oneRow[column];
      });
      entityList.push(entity);
    });
    return entityList;
  }

  saveList() {
    const data = {
      'stationId': this.StationId,
      'locomotiveId': this.LocomotiveId,
      'mcWagonListDown': this.formatToDetailEntity(this.wagonForm), // 获取主标数据，变成Java entity
      'mcWagonListUp': this.formatToDetailEntity(this.formationForm) // 获取明细表数据，变成Java entity
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McLocomotiveController', 'formation', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.searchList(); // 执行成功之后刷新数据
        this.confirmModal.show('success', {'title': '执行成功'});
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });

  }

  ngOnInit() {
    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/basic/dict/WAGON_FORMATION_FLAG.json', {
        'STATION_ID': this.StationId
      }),
    );
    parallel$.subscribe(
      values => {
        this.wagonFormationFlag = values[0]['data'];
      }
    );
  }

}
