import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-workflow-copy-man',
  templateUrl: './workflow-copy-man.component.html',
  styleUrls: ['./workflow-copy-man.component.scss']
})
export class WorkflowCopyManComponent implements OnInit {

  constructor() { }
  @Input() workflowData:any;
  @Input() oneNode: any;
  @Input() oneOption: any;

  ngOnInit() {
  }

}
