import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {NzFormatEmitEvent} from "ng-zorro-antd";
import * as _sh from 'lodash';
import * as _un from "underscore";

@Component({
    selector: 'app-hulk-config-poplist-dictionary-input',
    templateUrl: './hulk-config-poplist-dictionary-input.component.html',
    styleUrls: ['./hulk-config-poplist-dictionary-input.component.scss']
})
export class HulkConfigPoplistDictionaryInputComponent implements OnInit {

    @Input() oneColumn: any;
    @Input() attributes = null;
    @Input() dictionaryColumn = null; // sqlFile 文件路径的 字段；例如 dictionary

    isVisible = false;
    datas = []; // 显示值
    conditions = []; // 条件列表

    constructor(
        private _httpClient: HttpClient,
        private modalService: NzModalService,
        private _FileSaverService: FileSaverService,
        private requestDataService: RequestDataService,
        private appConfigService: AppConfigService,
        private notification: NzNotificationService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private clipboardService: ClipboardService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
    }

    showModal() {
        if (!this.utils.Base_HasValue(this.oneColumn[this.dictionaryColumn])) {
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': '请先选择字典'
            });
        } else {
            const search = observableForkJoin(
                this.requestDataService.getPaginationData(this.oneColumn[this.dictionaryColumn], {}, 1, 10, null
                ),
                this.requestDataService.getDataByJson(this.oneColumn[this.dictionaryColumn])
            );
            search.subscribe(
                values => {
                    this.conditions = [];
                    this.datas = _sh.get(values, ['0', 'data']);
                    let mainJson = _sh.get(values, ['1']);
                    let groupList = _sh.keys(mainJson['condition']);
                    _sh.each(groupList,oneGroup=>{
                        let columns = mainJson['condition'][oneGroup];
                        _sh.each(columns, oneColumn => {
                            this.conditions.push(
                                {
                                group: oneGroup,
                                column: oneColumn['column'],
                                value: oneColumn['value']}
                            );
                        })
                    })
                }
            );
            this.isVisible = true;
        }
    }

    removeValue() {
        this.oneColumn[this.attributes] = null;
    }


    selectData(data: any) {
        this.oneColumn[this.attributes] = data['value'];
        this.isVisible = false;
    }
}
