import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import * as _ from 'underscore';

@Component({
  selector: 'app-input-bid',
  templateUrl: './input-bid.component.html',
  styleUrls: ['./input-bid.component.scss']
})
export class InputBidComponent implements OnInit {

  @Input() parentData: any;
  bidList: any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit() {
    this.doSearch();
  }

  doSearch() {
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/JieLiv2/askbid/myBid/bid_list.json', {}),
      this.requestService.getDataByCondition('/JieLiv2/askbid/myBid/bid_detail_list.json', {})
    );
    search01$.subscribe(
      values => {
        // 我的可以参与的投标列表
        this.bidList = _sh.get(values, [0, 'data'], []);
        let bidDetialList = _sh.get(values, [1, 'data'], []);
        _sh.each(this.bidList, oneBid => {
          let findData = this.util.filterData(bidDetialList, 'BID_ID', oneBid['ID']);
          _sh.each(findData, oneDetail => {
            oneDetail['MY_PRICE'] = 0
          })
          oneBid['detail'] = findData;
        });
        this.doReloadData();

      }
    );
  }

  doReloadData() {
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/JieLiv2/askbid/myBid/bid_history_list.json', {}),
    );
    search01$.subscribe(
      values => {
        // 我的可以参与的投标列表
        let historyDatas = _sh.get(values, [0, 'data'], []);
        _sh.each(this.bidList, oneBid => {
          let oneBidhistory = _sh.filter(historyDatas, oneDate => {
            return oneDate['BID_ID'] == oneBid['ID'];
          });
          oneBid['history'] = _sh.orderBy(oneBidhistory, ['ADD_TIME'], ['desc']);
        });
      }
    );
  }

  checkInput(pOneBid) {
    let result = true;
    _sh.each(pOneBid['detail'], oneDetial => {
      let myPrice = _sh.get(oneDetial, ['MY_PRICE'], 0);
      if (myPrice < 0) {
        this.confirmModal.show('error', {'title': '错误提报', 'content': '报价不能小于0'});
      }
    })
    return result;
  }

  formatMainToEntity(datas) {
    let entityList: any = [];
    _sh.each(datas, oneData => {
      let tempObj = {}
      _sh.each(_sh.keys(oneData), oneColumn => {
        const value = _sh.get(oneData, oneColumn);
        const column = _sh.camelCase(oneColumn);
        tempObj[column] = value;
      })
      entityList.push(tempObj);
    })
    return entityList;
  }

  deleteColumns(pDataList, pDeleteColumns) {
    _sh.each(pDataList, oneData => {
      _sh.each(_sh.keys(oneData), oneColumn => {
        if (_.contains(pDeleteColumns, oneColumn)) {
          delete oneData[oneColumn];
        }
      })
    })
    return pDataList;
  }

  saveData(pOneBid) {
    if (!this.checkInput(pOneBid)) {
      return false;
    } else {
      let bidDatas = _sh.cloneDeep(pOneBid);
      let bidDetailDatas = _sh.cloneDeep(pOneBid['detail']);
      delete bidDatas['detail'];
      bidDatas = this.formatMainToEntity([bidDatas]);
      bidDatas = bidDatas[0];
      bidDetailDatas = this.formatMainToEntity(bidDetailDatas);
      bidDetailDatas = this.deleteColumns(bidDetailDatas, ['truckTypeDict', 'truckTypeDictArray', 'billTypeDict', 'billTypeDictArray', 'billWayDict', 'billWayDictArray'])

      const queryData = {
        'userId': this.userInfoService.getUserInfo().USER_ID
      };

      const bodyData = {
        'msBidCust': bidDatas,
        'msBidDetailCustList': bidDetailDatas
      };
      this.requestService.doActionByHdbsthor('AppApi/MsBidController', 'inputBid', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
        if (response == true) {
          this.confirmModal.show('success', {'title': '执行成功'});
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    }
  }
}
