import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-student-aid-info',
  templateUrl: './student-aid-info.component.html',
  styleUrls: ['./student-aid-info.component.css']
})
export class StudentAidInfoComponent implements OnInit {
  evaluationYear: any;
  year: any;
  aidInfo: any;
  studentCount: any;
  testType = [
    {
      code: '三年',
      name: '学术学位'
    },
    {
      code: '两年',
      name: '专业学位'
    }
  ];
  testTypeValue = '三年';

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [
      {
        '年份/项目': '项目总数（个）'
      },
      {
        '年份/项目': '总额（万元）'
      },
      {
        '年份/项目': '覆盖学生数'
      },
      {
        '年份/项目': '覆盖学生比例'
      }
    ];
    _.each(data1, oneData => {
      _.each(this.year, item => {
        if (oneData['年份/项目'] === '项目总数（个）') {
          oneData[item + '年奖学金'] = this.dataYear(this.aidInfo, item)[0]['PROJECT_NUM'] || 0;
          oneData[item + '年助学金'] = this.dataYear(this.aidInfo, item)[0]['GRANT_PROJECT_NUM'] || 0;
        } else if (oneData['年份/项目'] === '总额（万元）') {
          oneData[item + '年奖学金'] = this.dataYear(this.aidInfo, item)[0]['SCHOLARSHIP_MONEY'] || 0;
          oneData[item + '年助学金'] = this.dataYear(this.aidInfo, item)[0]['A_GRANT'] || 0;
        } else if (oneData['年份/项目'] === '覆盖学生数') {
          oneData[item + '年奖学金'] = this.dataYear(this.aidInfo, item)[0]['PROJECT_NUM'] || 0;
          oneData[item + '年助学金'] = this.dataYear(this.aidInfo, item)[0]['GRANT_PROJECT_NUM'] || 0;
        } else if (oneData['年份/项目'] === '覆盖学生比例') {
          oneData[item + '年奖学金'] = this.avgNum(this.dataYear(this.aidInfo, item)[0]['PROJECT_NUM'] || 0, this.dataYear(this.studentCount, item)[0]['ACOUNT'] || 0) + '%';
          oneData[item + '年助学金'] = this.avgNum(this.dataYear(this.aidInfo, item)[0]['GRANT_PROJECT_NUM'] || 0, this.dataYear(this.studentCount, item)[0]['ACOUNT'] || 0) + '%';
        }
      });
    });
    const opts = [
      {
        sheetid: this.evaluationYear - 4 + '~' + this.evaluationYear + '年奖助学金情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("V-3 近5年奖助学金情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  dataYear(pArray, year) {
    const data = _.filter(pArray, oneData => {
      return oneData['AWARD_YEAR'] === year;
    });
    if (data.length) {
      return data;
    } else {
      return [0];
    }
  }

  avgNum(num1, num2) {
    if (num2 === 0 || !this.utils.Base_HasValue(num2) || num1 > num2) {
      num2 = 1;
    }
    if (num1 > num2) {
      return 100;
    }
    return (this.utils.Base_accDiv(num1, num2) * 100).toFixed(2);
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  dataFormat(pIndex) {
    let arr = [];
    for(let oneYear of this.year ){
      let begin = +oneYear-+pIndex;
      let count  = alasql('select sum(ACOUNT) ACOUNT from ? WHERE 1=1 and INCOME_SCHOOL_YEAR >= '+begin+' and INCOME_SCHOOL_YEAR <= '+oneYear, [this.studentCount])[0].ACOUNT;
      arr.push(
        {
          AWARD_YEAR:oneYear,
          ACOUNT:count
        }
      );
    }
    this.studentCount = arr;
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    let index = 3;
    if (this.testTypeValue === '两年') {
      index = 2;
    }
    this.year = [this.evaluationYear - 4, this.evaluationYear - 3, this.evaluationYear - 2, this.evaluationYear - 1, this.evaluationYear - 0];
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_aid_info.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTME:this.testTypeValue
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/master_student_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4-index,
        EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTME:this.testTypeValue
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/doctor_student_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4-index,
        EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTME:this.testTypeValue
      })
    );
    parallel.subscribe(response => {
      this.aidInfo = response[0]['data'];
      this.studentCount = response[1]['data'].concat(response[2]['data']);
      this.dataFormat(index);
    });
  }

}
