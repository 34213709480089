import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import * as _ from 'lodash';

@Component({
  selector: 'app-input-service-input',
  templateUrl: './input-service-input.component.html',
  styleUrls: ['./input-service-input.component.css']
})
export class InputServiceInputComponent implements OnInit {

  @Input() oneComponent: any;
  @Input() componentIndex: any;
  @Input() oneSmallGroup: any;
  @Input() smallGroupIndex: any;
  @Input() groupIndex: any;
  @Input() serviceData: any;
  componentModalVisible: any;
  componentDatas = {};

  radioTypeList = [
    {
      "label": "普通",
      "value": "normal",
    },
    {
      "label": "带输入框",
      "value": "input",
    }
  ]

  radioWidth = [
    {
      "label": "1/3",
      "value": 8,
    },
    {
      "label": "1/2",
      "value": 12,
    },
    {
      "label": "2/3",
      "value": 16,
    },
    {
      "label": "1行",
      "value": 24,
    },
  ]

  pubItLastComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].push(pOneComponent);
  }

  pubItFirstComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].unshift(pOneComponent);
  }

  drop(small_, event: CdkDragDrop<string[]>): void {
    moveItemInArray(small_, event.previousIndex, event.currentIndex);
  }

  deleteOption(pOptionList, pOneOption) {
    pOptionList['list'] = _.without(pOptionList['list'], pOneOption);
  }

  public addOption(pOptionList) {
    pOptionList['list'].push({
      "label": "新选项",
      "type": "normal",
      "nzSpan": 24,
      "model": null
    })
  }

  componentCancel() {
    this.componentModalVisible = false;
  }

  componentEdit(pOneComponent) {
    this.componentModalVisible = true;
    this.componentDatas = pOneComponent;
  }

  /**
   * 删除组件
   * @param pOneSmallGroup
   * @param pOneComponents
   */
  public deleteComponents(pOneSmallGroup, pOneComponents) {
    pOneSmallGroup['components'] = _.without(pOneSmallGroup['components'], pOneComponents);
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

}
