import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import * as alasql from 'alasql';
import * as _ from 'underscore';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-teaching-award',
  templateUrl: './teaching-award.component.html',
  styleUrls: ['./teaching-award.component.css']
})
export class TeachingAwardComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1 = [];
    if (this.listData.length > 0) {
      _.each(this.listData, oneData => {
        const data = {
          '获奖类别': oneData['AWARD_CATEGORY'] || '',
          '特等奖': oneData['特等奖'] || 0,
          '一等奖': oneData['一等奖'] || 0,
          '二等奖': oneData['二等奖'] || 0
        };
        data1.push(data);
      });
    } else {
      const data = {
        '获奖类别': '',
        '特等奖': '',
        '一等奖': '',
        '二等奖': ''
      };
      data1.push(data);
    }

    const opts = [
      {
        sheetid: this.evaluationYear - 4 + '~' + this.evaluationYear + '年教学获奖情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-2-2 近5年教学获奖情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.requestDataService.getDataByCondition('subjectInfo/report/report_teaching_award.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe(response => {
      this.listData = response['data'];
    });
  }

}
