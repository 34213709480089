import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-instrument-equipment',
  templateUrl: './instrument-equipment.component.html',
  styleUrls: ['./instrument-equipment.component.css']
})
export class InstrumentEquipmentComponent implements OnInit {
  listData: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1: any = [
      {
        '仪器设备总值（万元）': this.listData[0]['INSTRUMENT_TOTAL'] || 0,
        '实验室总面积（平方米）': this.listData[0]['LABORATORY_AREA'] || 0,
        '最大实验室面积（平方米）': this.listData[0]['LABORATORY_AREA_MAX'] || 0
      }
    ];
    const opts = [
      {
        sheetid: 'V-2-2 仪器设备设施情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("V-2-2 仪器设备设施情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_instrument_equipment.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
