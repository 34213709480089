
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';

import {RequestDataService} from './../../../service/request-data.service';
import {UtilsService} from './../../../service/utils.service';
import {UserInfoService} from './../../../service/user-info.service';
import {ExcelExportService} from './../../../service/excel-export.service';

import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'app-site-report',
  templateUrl: './site-report.component.html',
  styleUrls: ['./site-report.component.css']
})
export class SiteReportComponent implements OnInit {

  public searchColumns: any = {};

  public dateRange: any = [];

  public chartOption: any = {};

  public category: any = [];

  public list: any = {
    category: [],
    data: []
  };

  public scrollWidth: any = null;

  public date: string = '';

  public tongJiData: any = [];

  public exportData: any = [];

  constructor(private requestData: RequestDataService,
              private utils: UtilsService,
              private userInfo: UserInfoService,
              private excelExport: ExcelExportService) {
  }


  export() {
    let category = this.list.category;
    let dataList = this.list.data;
    _.each(dataList, (data: any) => {
      let obj = {};
      _.each(data, (item, index) => {
        if (!category[index]) {
          category[index] = '机构';
        }
        obj[category[index]] = item;
      });
      this.exportData.push(obj);
    });
    this.excelExport.exportList(this.exportData, '站点访问统计报表');
  }

  // 根据当前选中区间回传查询参数
  getSearchData(model) {
    const today = moment(this.date).format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    const setDate = (data, category, value) => {
      data['ADD_TIME_BEGIN'] = moment(this.date).subtract(value - 1, 'days').format('YYYY-MM-DD');
      for (let i = value - 1; i > 0; i--) {
        category.push(moment(this.date).subtract(i, 'days').format('YYYY-MM-DD'));
      }
    };
    switch (model) {
      case 'NEARLY-SEVEN':
        setDate(data, category, 7);
        this.scrollWidth = null;
        break;
      case 'NEARLY-TEN':
        setDate(data, category, 10);
        this.scrollWidth = '1500px';
        break;
      case 'NEARLY-FIFTEEN':
        setDate(data, category, 15);
        this.scrollWidth = '2160px';
        break;
      case 'NEARLY-TWENTY':
        setDate(data, category, 20);
        this.scrollWidth = '2840px';
        break;
      case 'NEARLY-MONTH':
        data['ADD_TIME_BEGIN'] = moment(this.date).subtract(1, 'month').format('YYYY-MM-DD');
        for (let i = 1; i <= 30; i++) {
          const date = moment(this.date).subtract(i, 'days').format('YYYY-MM-DD');
          category.push(date);
          // 如果是上个月这个一天不需要在循环；
          if (date === data['ADD_TIME_BEGIN']) {
            break;
          }
        }
        category = category.reverse();
        this.scrollWidth = '4300px';
        break;
    }
    category.push(today);
    return {
      searchData: data,
      category: category
    };
  }

  setOption(categoryList, valueList) {
    this.chartOption = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '2%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: ['#C7C7C7']
          }
        },
        data: categoryList
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#A4A4A4',
            fontSize: 14
          }
        }
      },
      series: [
        {
          name: '访问量(次)',
          type: 'line',
          stack: '访问量(次)',
          symbol: 'circle',
          symbolSize: 10,
          color: '#6BA7FB',
          lineStyle: {
            color: '#6BA7FB',
            shadowColor: '#6BA7FB',
            shadowBlur: 5,
            shadowOffsetX: 0.5,
            shadowOffsetY: 0.5,
            width: 3
          },
          data: valueList
        }
      ]
    };
  }

  transformData(category, data) {
    // 先封装折线图需要数据
    // 根据时间分组，循环日期区间，筛入总值
    let valueList = [];
    let dateObj = _.groupBy(data, item => item['SELECT_DATE']);
    _.each(category, (cate: string) => {
      let num = 0;
      _.each(dateObj[cate], result => {
        num += +result['CLICK_NUM'];
      });
      valueList.push(num);
    });

    let list = {
      category: category,
      data: []
    };
    // 根据机构分组
    let orgObj = _.groupBy(data, item => item['ORG_ID']);
    _.each(orgObj, (item, key) => {
      let currentData = [item[0]['ORG_NAME']];
      let dateData = _.groupBy(item, v => v['SELECT_DATE']);
      _.each(category, (cate: string) => {
        if (this.utils.Base_HasValue(cate)) {
          let num = 0;
          _.each(dateData[cate], result => {
            num += +result['CLICK_NUM'];
          });
          if (num) {
            currentData.push(num);
          } else {
            currentData.push('/');
          }
        }
      });
      list.data.push(currentData);
    });
    // 封装表格需要数据
    list.category.unshift('');
    return {
      valueList: valueList,
      list: list
    };
  }


  mergeData(list1, list2) {
    // 如果都没有数据则返回[]
    // 如果1没有数据则直接放回2
    // 如果2没有数据则直接放回1
    // 如果都有数据则合并
    if (!list1.length && !list2.length) {
      return [];
    } else if (!list1.length && list2.length) {
      return list2;
    } else if (list1.length && !list2.length) {
      return list1;
    } else {
      // 先合并，先按照日期分组。循环某个日期对应数据在按照orgId分组 然后push
      let data = list1.concat(list2);
      const dataObj = _.groupBy(data, item => {
        return item['ORG_ID'] && item['SELECT_DATE'];
      });
      const dateObj = _.groupBy(data, item => item['SELECT_DATE']);
      let list = [];
      _.each(dataObj, (dateItem, date) => {
        let dataObj = _.groupBy(dateItem, item => item['ORG_ID']);
        _.each(dataObj, item => {
          let clickNum = 0;
          _.each(item, current => {
            clickNum += +current['CLICK_NUM'] || 0;
          });
          list.push({
            CLICK_NUM: clickNum,
            ORG_ID: item[0]['ORG_ID'],
            ORG_NAME: item[0]['ORG_NAME'],
            SELECT_DATE: date
          });
        });
      });
      return list;
    }
  }

  // 获取数据
  getRequestData() {
    let data = this.getSearchData(this.searchColumns.columns[0].model);
    let condition = data.searchData;
    this.category = data.category;
    let category = Object.assign([], data.category);
    let orgId = this.searchColumns.columns[1].model;
    if (this.utils.Base_HasValue(orgId)) {
      condition['ORG_ID'] = orgId;
    } else if (+this.userInfo.getUserInfo().USERTYPE === 5) { // 机构用户
      condition['ORG_ID'] = this.userInfo.getUserInfo().USER_ID;
    }

    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('headLine/hl/hl_views_report.json', condition),
      this.requestData.getDataByCondition('headLine/hl/hl_visit_report.json', condition)
    );
    parallel$.subscribe(values => {
      let result1 = values[0]['data'] || [];
      let result2 = values[1]['data'] || [];
      // 合并两个数组数据
      let data = this.mergeData(result1, result2);
      const transData = this.transformData(category, data);
      this.setOption(this.category, transData.valueList);
      this.list = transData.list;
    });
  }

  // 前一天
  prevDate() {
    this.date = moment(this.date).subtract(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  // 后一天
  nextDate() {
    this.date = moment(this.date).add(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    this.dateRange = [
      {
        code: 'NEARLY-SEVEN',
        name: '近7天'
      },
      {
        code: 'NEARLY-TEN',
        name: '近10天'
      },
      {
        code: 'NEARLY-FIFTEEN',
        name: '近15天'
      },
      {
        code: 'NEARLY-TWENTY',
        name: '近20天'
      },
      {
        code: 'NEARLY-MONTH',
        name: '近1个月'
      }
    ];
    let searchColumns = {
      'title': '站点访问统计报表',
      'columns': [
        {
          'column': 'DATE_RANGE',
          'name': 'DATE_RANGE',
          'component': 'select',
          'lable': '选择区间',
          'comment': '选择区间',
          'placeholder': '请选择区间',
          'model': 'NEARLY-SEVEN',
          'visible': true,
          'require': true,
          'itemArray': this.dateRange
        },
        {
          'column': 'ORG_ID',
          'name': 'ORG_ID',
          'component': 'poplist',
          'lable': '选择机构',
          'comment': '选择机构',
          'placeholder': '请选择机构',
          'model': null,
          'visible': true,
          'require': false,
          'dictionary': 'headLine/basic/dict/ORGID_NAME.json',
          'dictionaryPF': null
        }
      ]
    };

    if (+this.userInfo.getUserInfo().USERTYPE === 5) {
      searchColumns.columns[1].model = this.userInfo.getUserInfo().USER_ID;
      searchColumns.columns[1]['dictionaryParam'] = [
        {
          'column': 'ORG_ID',
          'value': this.userInfo.getUserInfo().USER_ID,
          'errormsg': '机构ID不存在'
        }
      ];
    }
    this.searchColumns = searchColumns;

    this.getRequestData();


  }

  ngDoCheck(): void {
    if (this.searchColumns.title) {
      _.each(this.searchColumns.columns, item => {
        if (item['model'] !== item['oldModel']) {
          item['oldModel'] = item['model'];
          this.date = moment().format('YYYY-MM-DD');
          this.getRequestData();
        }
      });
    }

  }
}
