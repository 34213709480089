import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-std-sku',
  templateUrl: './input-std-sku.component.html',
  styleUrls: ['./input-std-sku.component.scss']
})
export class InputStdSkuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
