import {Component, OnInit, ViewChild} from '@angular/core';
import * as _sh from 'lodash';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.css']
})
export class FormModalComponent implements OnInit {

  public modalFormData: any = {};
    modeType = 'add';
	 @ViewChild('childForm', {static: false}) childForm;

    constructor() {
    }

	ngOnInit() {
        this.modeType = _sh.get(this.modalFormData,['modalModeType'],'add');
	}
}
