import {Component, OnInit} from '@angular/core';
import {RequestDataService} from './../../../service/request-data.service';
import {UtilsService} from './../../../service/utils.service';
import {UserInfoService} from './../../../service/user-info.service';
import {ExcelExportService} from './../../../service/excel-export.service';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'app-grab-release',
  templateUrl: './grab-release.component.html',
  styleUrls: ['./grab-release.component.css']
})
export class GrabReleaseComponent implements OnInit {
  public searchColumns: any = {};

  public dateRange: any = [];

  public chartOption: any = {};

  public category: any = [];

  public list: any = {
    category: [],
    data: []
  };

  public scrollWidth: any = null;

  public date: string = '';

  public tongJiData: any = [];

  public exportData: any = [];

  subjectType = '';

  totalCount = [];

  constructor(private requestData: RequestDataService,
              private utils: UtilsService,
              private userInfo: UserInfoService,
              private excelExport: ExcelExportService) {
  }


  export() {
    const category = this.list.category;
    const dataList = this.list.data;
    _.each(dataList, (data: any) => {
      const obj = {};
      _.each(data, (item, index) => {
        if (!category[index]) {
          category[index] = '学科';
        }
        obj[category[index]] = item;
      });
      this.exportData.push(obj);
    });
    this.excelExport.exportList(this.exportData, '每日抓取报表');
  }

  // 根据当前选中区间回传查询参数
  getSearchData(model) {
    const today = moment(this.date).format('YYYY-MM-DD');
    let category = [];
    const data = {
      AUDITOR_TIME_END: today
    };
    const setDate = (data, category, value) => {
      data['AUDITOR_TIME_BEGIN'] = moment(this.date).subtract(value - 1, 'days').format('YYYY-MM-DD');
      for (let i = value - 1; i > 0; i--) {
        category.push(moment(this.date).subtract(i, 'days').format('YYYY-MM-DD'));
      }
    };
    switch (model) {
      case 'NEARLY-SEVEN':
        setDate(data, category, 7);
        this.scrollWidth = null;
        break;
      case 'NEARLY-TEN':
        setDate(data, category, 10);
        this.scrollWidth = '1500px';
        break;
      case 'NEARLY-FIFTEEN':
        setDate(data, category, 15);
        this.scrollWidth = '2160px';
        break;
      case 'NEARLY-TWENTY':
        setDate(data, category, 20);
        this.scrollWidth = '2840px';
        break;
      case 'NEARLY-MONTH':
        data['AUDITOR_TIME_BEGIN'] = moment(this.date).subtract(1, 'month').format('YYYY-MM-DD');
        for (let i = 1; i <= 30; i++) {
          const date = moment(this.date).subtract(i, 'days').format('YYYY-MM-DD');
          category.push(date);
          // 如果是上个月这个一天不需要在循环；
          if (date === data['AUDITOR_TIME_BEGIN']) {
            break;
          }
        }
        category = category.reverse();
        this.scrollWidth = '4300px';
        break;
    }
    category.push(today);
    return {
      searchData: data,
      category: category
    };
  }

  setOption(categoryList, valueList) {
    this.chartOption = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '2%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: ['#C7C7C7']
          }
        },
        data: categoryList
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#A4A4A4',
            fontSize: 14
          }
        }
      },
      series: [
        {
          name: '抓取数量(条)',
          type: 'line',
          stack: '抓取数量(条)',
          symbol: 'circle',
          symbolSize: 10,
          color: '#6BA7FB',
          lineStyle: {
            color: '#6BA7FB',
            shadowColor: '#6BA7FB',
            shadowBlur: 5,
            shadowOffsetX: 0.5,
            shadowOffsetY: 0.5,
            width: 3
          },
          data: valueList
        }
      ]
    };
  }

  transformData(category, data) {
    // 先封装折线图需要数据
    // 根据时间分组，循环日期区间，筛入总值
    let subjectDatas = _.filter(data, subject => {
      return subject['SUBJECT_TYPE'].indexOf(',') != -1;
    });
    // 如果存在多学科的新闻，拆分之后合并到数据里面
    if (subjectDatas.length) {
      let resultDatas = [];
      let dataObj: any;
      let subject = [];
      let subjectName = [];
      _.each(subjectDatas, s => {
        subject = s['SUBJECT_TYPE'].split(',');
        subjectName = s['SUBJECT_NAME'].split(';');
        for (let i = 0; i < subject.length; i++) {
          dataObj = {
            ADD_TIME: s['ADD_TIME'],
            SUBJECT_NAME: subjectName[i],
            SUBJECT_TYPE: subject[i],
            SUBJECT_TYPE_DICT: subjectName[i],
            SUBJECT_TYPE_DICT_ARRAY: s['SUBJECT_TYPE_DICT_ARRAY'][i],
            release_num: s['release_num'],
            checked: false
          };
          resultDatas.push(dataObj);
        }
      });
      _.each(resultDatas, oneData => {
        _.each(data, d => {
          if (oneData['SUBJECT_TYPE'] === d['SUBJECT_TYPE'] && oneData['ADD_TIME'] === d['ADD_TIME']) {
            d['release_num'] += oneData['release_num'];
            oneData['checked'] = true;
          }
        });
      });
      _.each(resultDatas, oneData => {
        if (!oneData.checked) {
          data.push(oneData);
        }
      });
      data = _.filter(data, subject => {
        if (this.utils.Base_HasValue(this.subjectType)) {
          return subject['SUBJECT_TYPE'] === this.subjectType;
        } else {
          return subject['SUBJECT_TYPE'].indexOf(',') === -1;
        }
      });
    }

    let valueList = [];
    let dateObj = _.groupBy(data, item => item['ADD_TIME']);
    _.each(category, (cate: string) => {
      let num = 0;
      _.each(dateObj[cate], result => {
        num += +result['release_num'];
      });
      valueList.push(num);
    });

    let list = {
      category: category,
      data: []
    };
    // 根据学科分组
    let orgObj = _.groupBy(data, item => item['SUBJECT_TYPE']);
    _.each(orgObj, (item, key) => {
      let currentData = [item[0]['SUBJECT_TYPE_DICT']];
      let dateData = _.groupBy(item, v => v['ADD_TIME']);
      _.each(category, (cate: string) => {
        if (this.utils.Base_HasValue(cate)) {
          let num = 0;
          _.each(dateData[cate], result => {
            num += +result['release_num'];
          });
          if (num) {
            currentData.push(num);
          } else {
            currentData.push('/');
          }

        }
      });
      list.data.push(currentData);
    });
    // 封装表格需要数据
    list.category.unshift('');
    return {
      valueList: valueList,
      list: list
    };
  }


  // 获取数据
  getRequestData() {
    let data = this.getSearchData(this.searchColumns.columns[0].model);
    let condition = data.searchData;
    this.category = data.category;
    let category = Object.assign([], data.category);
    let subjectId = this.searchColumns.columns[1].model ? this.searchColumns.columns[1].model.split(',') : '';
    if (this.utils.Base_HasValue(subjectId)) {
      condition['SUBJECT_TYPE'] = subjectId;
      this.subjectType = subjectId;
    } else {
      this.subjectType = '';
    }
    this.requestData.getDataByCondition('headLine/report/news_grab_report.json', condition).subscribe(result => {
      let data = result.data || [];
      const transData = this.transformData(category, data);
      this.totalCount = transData.valueList;
      this.setOption(this.category, transData.valueList);
      this.list = transData.list;
    });
  }

  // 前一天
  prevDate() {
    this.date = moment(this.date).subtract(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  // 后一天
  nextDate() {
    this.date = moment(this.date).add(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    this.dateRange = [
      {
        code: 'NEARLY-SEVEN',
        name: '近7天'
      },
      {
        code: 'NEARLY-TEN',
        name: '近10天'
      },
      {
        code: 'NEARLY-FIFTEEN',
        name: '近15天'
      },
      {
        code: 'NEARLY-TWENTY',
        name: '近20天'
      },
      {
        code: 'NEARLY-MONTH',
        name: '近1个月'
      }
    ];
    let searchColumns = {
      'title': '每日抓取报表',
      'columns': [
        {
          'column': 'ADD_TIME',
          'name': 'ADD_TIME',
          'component': 'select',
          'lable': '选择区间',
          'comment': '选择区间',
          'placeholder': '请选择区间',
          'model': 'NEARLY-SEVEN',
          'visible': true,
          'require': true,
          'itemArray': this.dateRange
        },
        {
          'column': 'SUBJECT_TYPE',
          'name': 'SUBJECT_TYPE',
          'component': 'multipop',
          'lable': '选择学科',
          'comment': '选择学科',
          'placeholder': '请选择学科',
          'model': null,
          'visible': true,
          'require': false,
          'dictionary': 'headLine/basic/dict/SUBJECT_NAME.json',
          'dictionaryPF': null
        }
      ]
    };

    this.searchColumns = searchColumns;

    this.getRequestData();


  }

  ngDoCheck(): void {
    if (this.searchColumns.title) {
      _.each(this.searchColumns.columns, item => {
        if (item['model'] !== item['oldModel']) {
          item['oldModel'] = item['model'];
          this.date = moment().format('YYYY-MM-DD');
          this.getRequestData();
        }
      });
    }

  }
}
