import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../service/request-data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {NzTreeNode} from 'ng-zorro-antd/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-user-group-management',
  templateUrl: './user-group-management.component.html',
  styleUrls: ['./user-group-management.component.css']
})
export class UserGroupManagementComponent implements OnInit {
  @Input() parentData: any;
  data = {
    GROUP_NAME: '',
    GROUP_STATE: 'ENABLE',
    GROUP_TYPE: '',
    CHANNELS: []
  };
  required = false;
  groupState = [];
  groupType = [];
  channelList = [];
  sitelList = [];
  channelListNode: any = [];
  nodes: any = [];
  activedNode: NzTreeNode;
  channelArray = [];
  modelType = '';
  checkedAll = false;

  constructor(private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private utils: UtilsService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService) {
  }

  checkAll(event) {
    this.checkedAll = event;
    const data = this.utils.DeepCopy(this.nodes);
    for (const oneNode of data) {
      this.checkedNode(oneNode, event);
    }
    this.nodes = data;
  }

  checkedNode(oneNode, event) {
    oneNode['checked'] = event;
    if (this.utils.Base_HasValue(oneNode.children)) {
      for (const one of oneNode.children) {
        this.checkedNode(one, event);
      }
    }
  }

  transformData(parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = 'listForm';
    parentData.actionArray = [];
    parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 递归获取栏目id
  getChannelId(pData, siteId) {
    _.each(pData.children, oneData => {
      if (this.utils.Base_HasValue(oneData['checked']) && oneData['checked']) {
        const data = {
          SITE_ID: siteId,
          CHANNEL_ID: oneData['key']
        };
        this.channelArray.push(data);
      }
    });
    _.each(pData.children, oneData => {
      if (this.utils.Base_HasValue(oneData['children']) && oneData['children'].length > 0) {
        this.getChannelId(oneData, siteId);
      }
    });
  }

  // 保存
  doSave() {
    this.required = true;
    if (!this.utils.Base_HasValue(this.data.GROUP_NAME) ||
      !this.utils.Base_HasValue(this.data.GROUP_STATE) ||
      !this.utils.Base_HasValue(this.data.GROUP_TYPE)) {
      return;
    }
    const condition = {
      GROUP_NAME: this.data.GROUP_NAME
    };
    if (this.modelType === 'modify') {
      condition['ID'] = this.data['ID'];
    }
    this.requestData.getDataByCondition('digitalResources/dr/dr_group_user_count.json', condition).subscribe(result => {
      if (result.data[0].acount > 0) {
        this.confirmModal.show('warning', {title: '用户组名称已经存在，请重新输入。'});
      } else {
        let ids = [];
        _.each(this.nodes, oneData => {
          if (this.utils.Base_HasValue(oneData['children']) && oneData['children'].length > 0) {
            this.getChannelId(oneData, oneData['siteId']);
            ids = ids.concat(this.channelArray);
            this.channelArray = [];
          }
        });
        this.data.CHANNELS = ids;
        this.dataService.requestQuickSliver('/digital/groupSave', this.data).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {title: '保存成功'});
            this.gotoBack();
          } else {
            this.confirmModal.show('error', {title: '保存失败，请稍后重试。'});
          }
        });
      }
    });
  }

  // 返回
  gotoBack(): void {
    this.transformData(this.parentData);
  }

  initData() {
    const copyNode = [];
    if (this.utils.Base_HasValue(this.channelList)) {
      this.channelListNode = this.utils.Base_ChannelData(this.channelList);
    }
    if (this.utils.Base_HasValue(this.sitelList)) {
      for (const oneSite of this.sitelList) {
        const node: any = {
          title: oneSite['SITE_NAME'],
          key: oneSite['ID'],
          siteId: oneSite['ID'],
          siteName: oneSite['SITE_NAME'],
          type: 'SITE'
        };
        if (this.modelType === 'detail') {
          node['disableCheckbox'] = true;
        }
        if (this.utils.Base_HasValue(this.channelListNode)) {
          for (const oneChennel of this.channelListNode) {
            if (oneChennel['siteId'] === oneSite['ID']) {
              if (!node['children']) {
                node['children'] = [];
              }
              node['children'].push(oneChennel);
            }
          }
        }
        copyNode.push(node);
      }
      this.nodes = this.utils.DeepCopy(copyNode);

    }
  }

  ngOnInit() {
    this.modelType = this.parentData.modeType;
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/ENABLE_DISABLE.json'),
      this.requestData.getDataByCondition('digitalResources/dict/USER_GROUP_TYPE.json'),
      this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_site_channel_add.json'),
    );
    search.subscribe(
      values => {
        this.groupState = values[0]['data'] || [];
        this.groupType = values[1]['data'] || [];
        this.channelList = values[2]['data'] || [];
        this.sitelList = values[3]['data'] || [];
        if (this.modelType === 'add') {
          this.initData();
        }
        if (this.modelType === 'modify' || this.modelType === 'detail') {
          let condition = {};
          if (this.modelType === 'modify') {
            condition = {
              ID: _.find(this.parentData.data.data, oneData => {
                return oneData['checked'];
              })['ID']
            };
          } else {
            condition = {
              ID: this.parentData.detailCondition.GROUP_ID
            };
          }
          this.dataService.requestQuickSliver('/digital/groupSearch', condition).subscribe(response => {
            this.data = response.drGroup[0];
            this.data['GROUP_STATE_DICT'] = _.find(this.groupState, g => {
              return g['code'] === this.data.GROUP_STATE;
            })['name'];
            this.data['GROUP_TYPE_DICT'] = _.find(this.groupType, g => {
              return g['code'] === this.data.GROUP_TYPE;
            })['name'];
            this.channelList = response.drGroupChannel;
            if (this.modelType === 'detail') {
              _.each(this.channelList, oneData => {
                oneData['disableCheckbox'] = true;
              });
            }
            this.initData();
          });
        }
      }
    );
  }

}
