import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {UtilsService} from '../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {DataService} from '../headLine/data.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {Router} from '@angular/router';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class WisdomHsService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private dataService: DataService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    let selectList = [];
    let flag = true;
    let ids = [];
    switch (pBtn.ACTION_VALUE) {

    }
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount == 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
