import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commonFilter'
})
export class CommonFilterPipe implements PipeTransform {
	/**
	 * 通用过滤
	 * @param  {any[]}  items [过滤数组数据] 
	 * @param  {string} filed [过滤字段]
	 * @param  {string} value [过滤值]
	 * @return {any}          [返回任意类型：数组]
	 */
	transform(items: any[],filed: string, value: any): any {
		if (!items || !filed) {
			return items;
		}
		if (!value || value.length == 0) return items;
		// filter items array, items which match and return true will be
		// kept, false will be filtered out
		return items.filter(item => (item[filed]+'').indexOf(value) !== -1);
	}

}
