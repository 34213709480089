
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild} from '@angular/core';

import {AppConfigService} from './../../service/app-config.service'
import {RequestDataService} from './../../service/request-data.service';
import {UtilsService} from './../../service/utils.service';

import * as _ from 'underscore';


@Component({
  selector: 'app-multi-popup-json',
  templateUrl: './multi-popup-json.component.html',
  styleUrls: ['./multi-popup-json.component.css']
})
export class MultiPopupJsonComponent implements OnInit {

  @Input() popupJson:any;
  @Input() form:any;

  // 是否显示弹框
  public isVisible: boolean = false;

  @Input() selectMulti:any;

  @ViewChild('childForm', {static: false}) childForm;

  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = this.appConfigService.config.pageCount;
  // 总条数
  public total: any = 1;

  // 绑定当前table的数组数据
  public dataSet: any = [];

  // 当前table的表头
  public showColumns: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };
  // 查询数据
  public conditionList: any[] = [];

  public allChecked = false;
  public indeterminate = false;

  // 选中列表 用于分页之后判断数据选中
  public selectedList:any = [];

  // 是否选中的判断key
  public checkKey:string;


  constructor(private requestDataService: RequestDataService,
    private appConfigService: AppConfigService,
    private utils: UtilsService) {
  }

  refreshStatus(data?): void {
    const validData = this.dataSet.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    // 根据选中状态。调整选中列表;
    if (data.checked) {
      this.selectedList.push(data);
    } else {
      this.selectedList = _.filter(this.selectedList, item=> data[this.checkKey]===item[this.checkKey])
    }
  }

  checkAll(value: boolean): void {
    this.dataSet.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item["model"])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
         let obj = {
           column: item["column"],
           title: item["lable"],
           code: item["model"],
           name: item["model"],
           component: item["component"]
         }
         if (_.contains(['select', 'poplist', 'inputDataFilter'], item["component"])) {
           const selected = _.find(item["itemArray"], (v) => {
             return v["code"] === item["model"];
           })
           obj["name"] = selected["name"];
         }  else if (_.contains(['multiSelect', 'multipopCondition'], item["component"])) {
           let nameList = [];
           _.each(item["itemArray"], (v)=> {
             if (_.contains(item["model"], v["code"])) {
               nameList.push(v["name"]);
             }
           });
           obj["name"] = nameList.join(' ');
         } else if (_.contains(['dateRange', 'dateTimeRange'], item["component"])) {
           obj["name"] = item["model"].join('~');
           obj["bindColumns"] = item["bindColumns"];
         }
         condition.push(obj);
       }
     })
    return condition;
  }

  searchFilter(reset: boolean = true) {
    this.conditionList = this.transformCondition(this.childForm._validateForm["columns"]);
    let condition = {};
    _.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item["component"])) {
        _.each(item.code, (value, index) => {
          const column = item["bindColumns"][index];
          condition[column] = value;
        })
      } else {
        condition[item.column] = item.code;
      }
    });
    if (this.popupJson.condition) {
      condition = _.extend({}, this.popupJson.condition, condition);
    }
    this.searchData(reset, condition);
  }

  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  // 查询后的数据进行封装
  transformResult(data) {
    this.dataSet = data.data || [];
    // 如果有选中数组 则checked已选数据
    if (this.selectedList.length) {
      _.each(this.dataSet, (item)=>{
        const selectedData = _.find(this.selectedList, selected => selected[this.checkKey] === item[this.checkKey]);
        if (this.utils.Base_HasValue(selectedData)) {
          item["checked"] = true;
        }
      })
    }
    this.showColumns = data.showColumns.columns || [];
    this.total = data.page.rowCount;
    this.pageIndex = data.page.pageNum;
  }

  searchData(reset: boolean = false, condition?: {}): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.requestDataService.getPaginationData(this.popupJson.jsonUrl, condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.transformResult(data);
    });
  }

  selectData() {
    this.selectMulti(this.selectedList);
    this.isVisible = false;
  }

  showPopupModal (): void {
    this.checkKey = this.popupJson.muliKey;
    let conditions = this.popupJson.condition;
    const parallel$ = observableForkJoin(
      this.requestDataService.getPaginationData(this.popupJson.jsonUrl, conditions, this.pageIndex, this.pageSize),
      this.requestDataService.getConditionData(this.popupJson.jsonUrl)
      );

    parallel$.subscribe(
      values => {
        let dataObject = values[0];
        this.conditionColumns = values[1]["conditionColumns"][0] || {};
        this.transformResult(dataObject);
        this.isVisible = true;
      }
      );
  }

  ngOnInit() {
  }

}
