import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UserInfoService} from '../../../service/user-info.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'lodash';
import * as moment from 'moment';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-input-disbursement',
  templateUrl: './input-disbursement.component.html',
  styleUrls: ['./input-disbursement.component.css']
})
export class InputDisbursementComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';

  public today = moment().format('YYYY-MM-DD');
  public itemData: any = []; // 明细表
  public itemData_ob: any = []; // 明细表
  public itemData_adj: any = []; // 明细表

  public datas: any;

  public formData: any = [{
    'title': '支出结算单',
    'type': 'normal',
    'tablename': 'mc_income_account',
    'formname': 'mc_income_account',
    'columns': [
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '基本信息',
        'customize': 24,
        'comment': '基本信息',
        'visible': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '基本信息',
        'model': null
      },
      {
        'column': 'CUST_ID',
        'name': 'CUST_ID_NAME',
        'component': 'poplist',
        'lable': '客户',
        'comment': '客户',
        'placeholder': '请选择客户',
        'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
        'dictionaryPF': null,
        'insertValue': null,
        'model': null,
        'visible': true,
        'disable': false,
        'require': true
      },
      {
        "column": "DISBURSEMENT_FILE",
        "name": "DISBURSEMENT_FILE_NAME",
        "component": "file",
        "lable": "支出结算附件",
        "comment": "支出结算附件",
        "placeholder": "请上传支出结算附件",
        "insertValue": null,
        "model": null,
        "visible": true,
        "require": true
      },
      {
        'column': 'DISBURSEMENT_TEXT',
        'name': 'DISBURSEMENT_TEXT_NAME',
        'component': 'input',
        'lable': '备注',
        'comment': '备注',
        'placeholder': '请输入备注',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'TM_WH_FEE',
        'name': 'TM_WH_FEE_NAME',
        'component': 'input',
        'lable': '运输仓储合计',
        'comment': '运输仓储合计',
        'placeholder': '请输入运输仓储合计',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'ADJ_FEE',
        'name': 'ADJ_FEE_NAME',
        'component': 'input',
        'lable': '调整金额',
        'comment': '调整金额',
        'placeholder': '请输入调整金额',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'ACCOUNT_FEE',
        'name': 'ACCOUNT_FEE_NAME',
        'component': 'input',
        'lable': '结算金额',
        'comment': '结算金额',
        'placeholder': '请输入结算金额',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '运输合计',
        'customize': 24,
        'comment': '运输合计',
        'visible': true,
        'disable': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '运输合计',
        'model': null
      },
      {
        'column': 'POSTPONEMENT_DIS_FEE',
        'name': 'POSTPONEMENT_DIS_FEE_NAME',
        'component': 'input',
        'lable': '货延费',
        'comment': '货延费',
        'placeholder': '请输入货延费',
        'insertValue': null,
        'model': null,
        'visible': true,
        'disable': true,
        'require': false
      },
      {
        'column': 'HR',
        'component': 'hr',
        'lable': '仓储合计',
        'customize': 24,
        'comment': '仓储合计',
        'visible': true,
        'disable': true,
        'line': false,
        'commitPoint': [
          'detail'
        ],
        'placeholder': '仓储合计',
        'model': null
      },
      {
        'column': 'CAR_SHIPPING_FEE',
        'name': 'CAR_SHIPPING_FEE_NAME',
        'component': 'input',
        'lable': '汽车运费',
        'comment': '汽车运费',
        'placeholder': '请输入汽车运费',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'OB_POSTPONEMENT_DIS_FEE',
        'name': 'OB_POSTPONEMENT_DIS_FEE_NAME',
        'component': 'input',
        'lable': '仓库货延费',
        'comment': '仓库货延费',
        'placeholder': '请输入仓库货延费',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      }
    ]
  }];

  public allChecked = false;
  private indeterminate = false;

  public allChecked_ob = false;
  private indeterminate_ob = false;

  public allChecked_adj = false;
  private indeterminate_adj = false;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  formatToEntity(pColumnList) {
    let entity: any = {};
    _.each(pColumnList, (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatToDetailEntity(detail) {
    let entityList = [];
    _.each(detail, (oneRow) => {
      let entity = {};
      let columns = _.keys(oneRow);
      _.each(columns, (column) => {
        entity[_.camelCase(column)] = oneRow[column];
      });
      entityList.push(entity);
    });
    return entityList;
  }

  findOneColumnValue(pColumnName) {
    const columns = _.get(this.formData, '0.columns', []);
    const oneOneColum = _.find(columns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput() {
    let result = true;
    result = this.checkOneColumn('CUST_ID', '客户');
    return result;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  summaryDetail() {
    let detailList = [];
    _.each(this.itemData, (oneRow) => {
      let temp = this.addOneRow(oneRow);
      temp['DOCUMENT_TYPE'] = 'MC_WAGON';
      detailList.push(temp);
    });

    _.each(this.itemData_ob, (oneRow) => {
      let temp = this.addOneRow(oneRow);
      temp['DOCUMENT_TYPE'] = 'MC_OUTBOUND';
      detailList.push(temp);
    });

    _.each(this.itemData_adj, (oneRow) => {
      let temp = this.addOneRow(oneRow);
      temp['DOCUMENT_TYPE'] = 'MC_ADJUSTMENT';
      detailList.push(temp);
    });

    return detailList;
  }

  saveData() {
    if (!this.checkInput()) {
      return false;
    }
    let detail = this.summaryDetail(); // 将 车皮数据、仓储出库单数据、调整单数据 汇总
    const data = {
      'mcDisbursementAccount': this.formatToEntity(_.get(this.formData, '0.columns')), // 获取主标数据，变成Java entity
      'mcDisbursementAccountDetailList': this.formatToDetailEntity(detail) // 获取明细表数据，变成Java entity
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McDisbursementAccountController', 'input', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }

  addOneRow(row) {
    let obj = {};
    const columns = _.keys(row);
    _.each(columns, (column) => {
      obj[column] = row[column];
    });
    return obj;
  }

  hasThisId(datas, row) {
    let bFind = false;
    _.each(datas, (oneRow) => {
      if (oneRow['ID'] === row['ID']) {
        bFind = true;
        return bFind;
      }
    });
    return bFind;
  }

  showDetail_ob(item) {
    let columns = [
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 24,
        'column': 'OB_ID',
        'name': 'OB_ID_NAME',
        'component': 'input',
        'lable': '出库单主键',
        'comment': '出库单主键',
        'placeholder': '请输入出库单主键',
        'insertValue': null,
        'model': null,
        'visible': false
      },
      {
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 24,
        'column': 'DET_BATCH',
        'name': 'DET_BATCH_NAME',
        'component': 'input',
        'lable': '钢卷（板）号',
        'comment': '钢卷（板）号',
        'placeholder': '请输入钢卷（板）号',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'CAR_SHIPPING_FEE',
        'name': 'CAR_SHIPPING_FEE_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '汽车运费',
        'comment': '汽车运费',
        'placeholder': '请输入汽车运费',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'CAR_SHIPPING_FEE_CONFIRM',
        'name': 'CAR_SHIPPING_FEE_CONFIRM_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '汽车运费-确认',
        'comment': '汽车运费-确认',
        'placeholder': '请输入汽车运费-确认',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'OB_POSTPONEMENT_DIS_FEE',
        'name': 'OB_POSTPONEMENT_DIS_FEE_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '仓库货延费',
        'comment': '仓库货延费',
        'placeholder': '请输入仓库货延费',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'OB_POSTPONEMENT_DIS_FEE_CONFIRM',
        'name': 'OB_POSTPONEMENT_DIS_FEE_CONFIRM_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '仓库货延费-确认',
        'comment': '仓库货延费-确认',
        'placeholder': '请输入仓库货延费-确认',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': true
      }
    ];
    _.each(columns, (oneColumn) => {
      oneColumn['model'] = item[oneColumn['column']];
    });
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': columns
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        _.each(data, (oneColumn) => {
          item[oneColumn['column']] = oneColumn['model'];
        });
        this.calculateTotal();
      }
    });


  }

  showDetail_adj(item) {
    let columns = [
      {
        'column': 'ADJ_ID',
        'name': 'ADJ_ID_NAME',
        'component': 'input',
        'lable': '调整单主键',
        'comment': '调整单主键',
        'placeholder': '请输入调整单主键',
        'insertValue': null,
        'model': null,
        'visible': false,
        'require': true
      },
      {
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 24,
        'column': 'ADJ_CODE',
        'name': 'ADJ_CODE_NAME',
        'component': 'input',
        'lable': '调整单号',
        'comment': '调整单号',
        'placeholder': '请输入调整单号',
        'insertValue': null,
        'disable': true,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'ADJ_FEE',
        'name': 'ADJ_FEE_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '调整金额',
        'comment': '调整金额',
        'placeholder': '请输入调整金额',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'ADJ_FEE_CONFIRM',
        'name': 'ADJ_FEE_CONFIRM_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '调整金额-确认',
        'comment': '调整金额-确认',
        'placeholder': '请输入调整金额-确认',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': true
      }
    ];
    _.each(columns, (oneColumn) => {
      oneColumn['model'] = item[oneColumn['column']];
    });
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': columns
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        _.each(data, (oneColumn) => {
          item[oneColumn['column']] = oneColumn['model'];
        });
        this.calculateTotal();
      }
    });


  }

  showDetail(item) {
    let columns = [
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'pk_column': true,
        'column': 'ID',
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'insertValue': 'SYS-UUID',
        'model': null,
        'visible': false,
        'require': true,
        'iscondition': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'WAGON_PK_ID',
        'name': 'WAGON_PK_ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '请输入主键',
        'insertValue': null,
        'model': null,
        'visible': false,
        'disable': true,
        'require': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'WAGON_ID',
        'name': 'WAGON_ID_NAME',
        'component': 'input',
        'lable': '车皮号',
        'comment': '车皮号',
        'placeholder': '请输入车皮号',
        'insertValue': null,
        'disable': true,
        'model': null,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'WAYBILL_ID',
        'name': 'WAYBILL_ID_NAME',
        'component': 'input',
        'lable': '货运单号',
        'comment': '货运单号',
        'placeholder': '请输入货运单号',
        'disable': true,
        'insertValue': null,
        'model': null,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'POSTPONEMENT_DIS_FEE',
        'name': 'POSTPONEMENT_DIS_FEE_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '货延费',
        'comment': '货延费',
        'placeholder': '请输入货延费',
        'insertValue': null,
        'model': null,
        'disable': true,
        'visible': true
      },
      {
        'lableSpan': 8,
        'itemSpan': 16,
        'customize': 12,
        'column': 'POSTPONEMENT_DIS_FEE_CONFIRM',
        'name': 'POSTPONEMENT_DIS_FEE_CONFIRM_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '货延费-确认',
        'comment': '货延费-确认',
        'placeholder': '请输入货延费-确认',
        'insertValue': null,
        'model': null,
        'visible': true,
        'require': true
      }
    ];

    _.each(columns, (oneColumn) => {
      oneColumn['model'] = item[oneColumn['column']];
    });
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': columns
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        _.each(data, (oneColumn) => {
          item[oneColumn['column']] = oneColumn['model'];
        });
        this.calculateTotal();
      }
    });
  }

  addItem() {
    const custId = _.get(this.formData, '0.columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请先选择客户信息'});
      return false;
    }

    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/mc/mc_disbursement_account_wagon.json', {
        'CUST_ID': custId
      })
    );
    parallel$.subscribe(
      values => {
        let datas = _.get(values, '0.data');
        _.each(datas, (oneRow) => {
          if (!this.hasThisId(this.itemData, oneRow)) {
            this.itemData.push(this.addOneRow(oneRow));
          }
        });
        this.calculateTotal();
      }
    );
  }

  addItem_adj() {
    const custId = _.get(this.formData, '0.columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请先选择客户信息'});
      return false;
    }

    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/mc/mc_disbursement_account_adjustment.json', {
        'CUST_ID': custId
      })
    );
    parallel$.subscribe(
      values => {
        let datas = _.get(values, '0.data');
        _.each(datas, (oneRow) => {
          if (!this.hasThisId(this.itemData_adj, oneRow)) {
            this.itemData_adj.push(this.addOneRow(oneRow));
          }
        });
        this.calculateTotal();
      }
    );
  }

  addItem_ob() {
    const custId = _.get(this.formData, '0.columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请先选择客户信息'});
      return false;
    }

    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/mc/mc_disbursement_account_outbound.json', {
        'CUST_ID': custId
      })
    );

    parallel$.subscribe(
      values => {
        let datas = _.get(values, '0.data');
        _.each(datas, (oneRow) => {
          if (!this.hasThisId(this.itemData_ob, oneRow)) {
            this.itemData_ob.push(this.addOneRow(oneRow));
          }
        });
        this.calculateTotal();
      }
    );
  }


  refreshStatus(): void {
    const validData = this.itemData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  refreshStatus_ob(): void {
    const validData = this.itemData_ob.filter(value => !value.disabled);
    const allChecked_ob = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_ob = allChecked_ob;
    this.indeterminate_ob = (!allChecked_ob) && (!allUnChecked);
  }

  refreshStatus_adj(): void {
    const validData = this.itemData_adj.filter(value => !value.disabled);
    const allChecked_ob = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_adj = allChecked_ob;
    this.indeterminate_adj = (!allChecked_ob) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.itemData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  checkAll_ob(value: boolean): void {
    this.itemData_ob.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus_ob();
  }

  checkAll_adj(value: boolean): void {
    this.itemData_adj.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus_adj();
  }


  // 明细table删除
  itemRemove(): void {
    const checkedList = _.filter(this.itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.itemData = this.itemData.filter((item) => {
            return !item.checked;
          });
          this.calculateTotal();
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }


  // 明细table删除
  itemRemove_ob(): void {
    const checkedList = _.filter(this.itemData_ob, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.itemData_ob = this.itemData_ob.filter((item) => {
            return !item.checked;
          });
          this.calculateTotal();
          this.refreshStatus_ob();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  /**
   * 删除明细值，调整单。
   */
  itemRemove_adj(): void {
    const checkedList = _.filter(this.itemData_adj, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.itemData_adj = this.itemData_adj.filter((item) => {
            return !item.checked;
          });
          this.calculateTotal();
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  fillValue(pColumn, columnName, value) {
    if (pColumn['column'] === columnName) {
      pColumn['model'] = value;
    }
  }

  /**
   * 计算合计值
   */
  calculateTotal() {
    let TM_WH_FEE = this.calculateTotal_wagon() + this.calculateTotal_ob();
    let ADJ_FEE = this.calculateTotal_adj();

    _.each(this.formData[0]['columns'], (column) => {
      this.fillValue(column, 'TM_WH_FEE', TM_WH_FEE);
      this.fillValue(column, 'ADJ_FEE', ADJ_FEE);
      this.fillValue(column, 'ACCOUNT_FEE', TM_WH_FEE + ADJ_FEE);
    });
  }

  /**
   * 计算合计值，车皮的部分
   */
  calculateTotal_wagon() {
    let POSTPONEMENT_DIS_FEE_CONFIRM = 0;

    _.each(this.itemData, (item) => {
      POSTPONEMENT_DIS_FEE_CONFIRM += item['POSTPONEMENT_DIS_FEE_CONFIRM'];
    });

    _.each(this.formData[0]['columns'], (column) => {
      this.fillValue(column, 'POSTPONEMENT_DIS_FEE', POSTPONEMENT_DIS_FEE_CONFIRM);
    });
    return POSTPONEMENT_DIS_FEE_CONFIRM;
  }

  calculateTotal_ob() {
    let CAR_SHIPPING_FEE_CONFIRM = 0;
    let OB_POSTPONEMENT_DIS_FEE_CONFIRM = 0;
    _.each(this.itemData_ob, (item) => {
      CAR_SHIPPING_FEE_CONFIRM += item['CAR_SHIPPING_FEE_CONFIRM'];
      OB_POSTPONEMENT_DIS_FEE_CONFIRM += item['OB_POSTPONEMENT_DIS_FEE_CONFIRM'];
    });

    _.each(this.formData[0]['columns'], (column) => {
      this.fillValue(column, 'CAR_SHIPPING_FEE', CAR_SHIPPING_FEE_CONFIRM);
      this.fillValue(column, 'OB_POSTPONEMENT_DIS_FEE', OB_POSTPONEMENT_DIS_FEE_CONFIRM);
    });
    return CAR_SHIPPING_FEE_CONFIRM + OB_POSTPONEMENT_DIS_FEE_CONFIRM;
  }

  /**
   * 计算合计值，调整单
   */
  calculateTotal_adj() {
    let ADJ_FEE_CONFIRM = 0;
    _.each(this.itemData_adj, (item) => {
      ADJ_FEE_CONFIRM += item['ADJ_FEE_CONFIRM'];
    });
    _.each(this.formData[0]['columns'], (column) => {
      this.fillValue(column, 'ADJ_FEE', ADJ_FEE_CONFIRM);
    });
    return ADJ_FEE_CONFIRM;
  }

  reverseWriting(pColumn, pData, pCheckedList): void {
    let columnName = pColumn['column'];
    let value = pColumn['model'];
    _.each(pCheckedList, oneItem => {
      if (this.util.Base_HasValue(value)) {
        oneItem[columnName] = value;
      }
    });
  }

  modifyItem(): void {
    const checkedList = _.filter(this.itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      let columns = [
        {
          'lableSpan': 8,
          'itemSpan': 16,
          'customize': 12,
          'column': 'POSTPONEMENT_DIS_FEE_CONFIRM',
          'name': 'POSTPONEMENT_DIS_FEE_CONFIRM_NAME',
          'component': 'input',
          'type': 'number',
          'lable': '货延费-确认',
          'comment': '货延费-确认',
          'placeholder': '请输入货延费-确认',
          'insertValue': null,
          'model': null,
          'visible': true,
          'require': true
        }
      ];

      this.modalService.create({
        nzTitle: '',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzMaskClosable: false,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': columns
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          _.each(columns, oneColumn => {
            this.reverseWriting(oneColumn, data, checkedList);
          });
          this.calculateTotal();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  modifyItem_ob(): void {
    const checkedList = _.filter(this.itemData_ob, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      let columns = [
        {
          'lableSpan': 8,
          'itemSpan': 16,
          'customize': 12,
          'column': 'CAR_SHIPPING_FEE_CONFIRM',
          'name': 'CAR_SHIPPING_FEE_CONFIRM_NAME',
          'component': 'input',
          'type': 'number',
          'lable': '汽车运费-确认',
          'comment': '汽车运费-确认',
          'placeholder': '请输入汽车运费-确认',
          'insertValue': null,
          'model': null,
          'visible': true,
          'require': true
        },
        {
          'lableSpan': 8,
          'itemSpan': 16,
          'customize': 12,
          'column': 'OB_POSTPONEMENT_DIS_FEE_CONFIRM',
          'name': 'OB_POSTPONEMENT_DIS_FEE_CONFIRM_NAME',
          'component': 'input',
          'type': 'number',
          'lable': '仓库货延费-确认',
          'comment': '仓库货延费-确认',
          'placeholder': '请输入仓库货延费-确认',
          'insertValue': null,
          'model': null,
          'visible': true,
          'require': true
        }
      ];

      this.modalService.create({
        nzTitle: '',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzMaskClosable: false,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': columns
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          _.each(columns, oneColumn => {
            this.reverseWriting(oneColumn, data, checkedList);
          });
          this.calculateTotal();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  modifyItem_adj(): void {
    const checkedList = _.filter(this.itemData_adj, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      let columns = [
        {
          'lableSpan': 8,
          'itemSpan': 16,
          'customize': 12,
          'column': 'ADJ_FEE_CONFIRM',
          'name': 'ADJ_FEE_CONFIRM_NAME',
          'component': 'input',
          'type': 'number',
          'lable': '调整金额-确认',
          'comment': '调整金额-确认',
          'placeholder': '请输入调整金额-确认',
          'insertValue': null,
          'model': null,
          'visible': true,
          'require': true
        }
      ];


      this.modalService.create({
        nzTitle: '',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzMaskClosable: false,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': columns
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          _.each(columns, oneColumn => {
            this.reverseWriting(oneColumn, data, checkedList);
          });
          this.calculateTotal();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  ngOnInit(): void {

  }
}
