import { Component, OnInit } from '@angular/core';
import {AutoInsertService} from '../../../service/auto-insert.service';
import * as _ from 'underscore';
import {UtilsService} from '../../../service/utils.service';
import {RequestDataService} from '../../../service/request-data.service';
import * as moment from '../../fury/data.service';
import {UserInfoService} from '../../../service/user-info.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-subject-visual-report-set',
  templateUrl: './subject-visual-report-set.component.html',
  styleUrls: ['./subject-visual-report-set.component.css']
})
export class SubjectVisualReportSetComponent implements OnInit {
  data: any;
  allChecked = false;
  indeterminate = true;
  showTime = 50000;
  checkOptionsOne = [
    { label: '师生情况', value: '师生情况', checked: true },
    { label: '人才培养', value: '人才培养', checked: true },
    { label: '科学研究', value: '科学研究', checked: true },
    { label: '培养环境与条件', value: '培养环境与条件', checked: true }
  ];

  constructor(private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private userInfo: UserInfoService,
              private util: UtilsService,
              private confirmModal: ConfirmModalService) { }

  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: true
        };
      });
    } else {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: false
        };
      });
    }
  }

  updateSingleChecked(): void {
    if (this.checkOptionsOne.every(item => item.checked === false)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.checkOptionsOne.every(item => item.checked === true)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  save() {
    const selectedList = _.filter(this.checkOptionsOne, oneData => {
      return oneData.checked;
    });
    if (selectedList.length === 0) {
      return this.confirmModal.show('warning', {title: '请至少选择一个展示模块'});
    }
    const user = this.userInfo.getUserInfo();
    this.requestDataService.getDataByCondition('subjectInfo/ms/ms_subject_visual_report_set.json').subscribe(response => {
      const templateJson = {
        'LIST': []
      };
      if (response.data.length > 0) {
        const deleteData: any = {
          'TABLE': 'ms_subject_visual_report_set',
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ID'}
          ],
          'RECORDS': [
            {
              'ID': response.data[0]['ID']
            }
          ]
        };
        templateJson.LIST.push(deleteData);
      }
      const insertData: any = {
        'TABLE': 'ms_subject_visual_report_set',
        'ACTION': 'Insert',
        'WHEREGROUP': [{}],
        'WHERE': [{}],
        'COLUMNS': [
          {'COLUMN': 'ID'},
          {'COLUMN': 'USER_ID'},
          {'COLUMN': 'USER_NAME'},
          {'COLUMN': 'SWIPER01_SHOW'},
          {'COLUMN': 'SWIPER02_SHOW'},
          {'COLUMN': 'SWIPER03_SHOW'},
          {'COLUMN': 'SWIPER04_SHOW'},
          {'COLUMN': 'SHOW_TIME'}
        ],
        'RECORDS': [
          {
            'ID': 'SYS-ACTION-UUID',
            'USER_ID': user.USER_ID,
            'USER_NAME': user['USERNAME'] || user['EMP_NAME'],
            'SWIPER01_SHOW': this.checkOptionsOne[0].checked ? '是' : '否',
            'SWIPER02_SHOW': this.checkOptionsOne[1].checked ? '是' : '否',
            'SWIPER03_SHOW': this.checkOptionsOne[2].checked ? '是' : '否',
            'SWIPER04_SHOW': this.checkOptionsOne[3].checked ? '是' : '否',
            'SHOW_TIME': this.showTime
          }
        ]
      };
      templateJson.LIST.push(insertData);
      return this.autoInsert.ExecJson(templateJson).subscribe(result => {
        if (result.state === 'success') {
          this.confirmModal.show('success', {title: '保存成功'});
        } else {
          this.confirmModal.show('error', {title: '保存失败，请稍后重试。'});
        }
      });
    });
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/ms/ms_subject_visual_report_set.json').subscribe(response => {
      this.data = response.data;
      if (this.data.length > 0) {
        if (this.data[0]['SWIPER01_SHOW'] === '是') {
          this.checkOptionsOne[0].checked = true;
        } else {
          this.checkOptionsOne[0].checked = false;
        }
        if (this.data[0]['SWIPER02_SHOW'] === '是') {
          this.checkOptionsOne[1].checked = true;
        } else {
          this.checkOptionsOne[1].checked = false;
        }
        if (this.data[0]['SWIPER03_SHOW'] === '是') {
          this.checkOptionsOne[2].checked = true;
        } else {
          this.checkOptionsOne[2].checked = false;
        }
        if (this.data[0]['SWIPER04_SHOW'] === '是') {
          this.checkOptionsOne[3].checked = true;
        } else {
          this.checkOptionsOne[3].checked = false;
        }
        this.showTime = this.data[0].SHOW_TIME;
      }
    });
  }

}
