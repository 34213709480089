import {Injectable} from '@angular/core';
import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from '../../service/request-data.service';
import {UserInfoService} from '../../service/user-info.service';
import {UtilsService} from '../../service/utils.service';
import {AppConfigService} from '../../service/app-config.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'lodash';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class AgricultureService {

  public user: any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  formatSelectEntity(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }

  findOneColumnValue(pColumns, pColumnName) {
    const oneOneColum = _.find(pColumns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }

  doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case 'customerApCheck': // 预付款审核
        this.doCustomerApCheck(pBtn, pParentData);
        break;
      case 'recharge': // 充值活动列表-客户自主充值
        this.doRecharge(pBtn, pParentData);
        break;
      case 'walletConsumption': // 充值活动列表-钱包消费
        this.doWalletConsumption(pBtn, pParentData);
        break;
      case 'modifyPaymentPasswordMessage': // 客户信息列表-发送修改支付密码短信
        this.doModifyPaymentPasswordMessage(pBtn, pParentData);
        break;
      case 'bindBanknoMessage': // 客户信息列表-发送绑定银行卡短信
        this.doBindBanknoMessage(pBtn, pParentData);
        break;
      case 'modifyPaymentPassword': // 客户信息列表-修改支付密码
        this.doModifyPaymentPassword(pBtn, pParentData);
        break;
      case 'bindBankno': // 客户信息列表-绑定银行卡
        this.doBindBankno(pBtn, pParentData);
        break;
      case 'completePayment': // 客户提现管理-提现审批
        this.doCompletePayment(pBtn, pParentData);
        break;
      case 'cashApproval': // 客户提现管理-完成打款
        this.doCashApproval(pBtn, pParentData);
        break;
      case 'bindAccount': // 店铺信息-绑定账号
        this.doBindAccount(pBtn, pParentData);
        break;
      case 'cancelAccount': // 店铺信息-解绑账号
        this.doCancelAccount(pBtn, pParentData);
        break;
      case 'bindAccountMessage': // 店铺信息-发送绑定验证码
        this.doBindAccountMessage(pBtn, pParentData);
        break;
      case 'cancelAccountMessage': // 店铺信息-发送解绑验证码
        this.doCancelAccountMessage(pBtn, pParentData);
        break;
      case 'payment': // 付款按钮
        this.doPayment(pBtn, pParentData);
        break;
      case 'read': // 我的消息-已读
        this.doRead(pBtn, pParentData);
        break;
      case 'verificationPassword': // 客户信息-验证密码
        this.doVerificationPassword(pBtn, pParentData);
        break;
      case 'applyCash': // 客户信息-申请提现
        this.doApplyCash(pBtn, pParentData);
        break;
      case 'couponsOnShelf': // 优惠券管理-优惠券上架
        this.doCouponsOnShelf(pBtn, pParentData);
        break;
      case 'couponsOffShelf': // 优惠券管理-优惠券下架
        this.doCouponsOffShelf(pBtn, pParentData);
        break;
      case 'grantCoupons': // 优惠券管理-发放优惠券
        this.doGrantCoupons(pBtn, pParentData);
        break;
      case 'withdrawalVerification': // 客户信息列表-提现验证码
        this.doWithdrawalVerification(pBtn, pParentData);
        break;
      case 'collectCoupons': // 接口可领用优惠券列表-领用优惠券
        this.doCollectCoupons(pBtn, pParentData);
        break;
      case 'platformRecharge': // 接口可领用优惠券列表-领用优惠券
        this.doPlatformRecharge(pBtn, pParentData);
        break;
      case 'customerStatement': // 客户管理-客户信息-客户对账单
        this.doCustomerStatement(pBtn, pParentData);
        break;
      case 'testSpeaker': // "测试音箱播报"
        this.doTestSpeaker(pBtn, pParentData);
        break;
      case 'bindSpeaker': // 绑定音箱
        this.doBindSpeaker(pBtn, pParentData);
        break;
      case 'unBindSpeaker': // 解除绑定音箱
        this.doUnBindSpeaker(pBtn, pParentData);
        break;
      case 'mandatoryBindSpeaker': // 强制绑定音箱
        this.doMandatoryBindSpeaker(pBtn, pParentData);
        break;
      case 'incomingParts': // 强制绑定音箱
        this.doIncomingParts(pBtn, pParentData);
        break;
      case 'withdrawalTest': // 钱包提现记录-提现测试
        this.doWithdrawalTest(pBtn, pParentData);
        break;
    }
  }

  doMandatoryBindSpeaker(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '绑定音箱',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'SPEAKER_ID',
                'name': 'SPEAKER_ID_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '音箱编码',
                'comment': '音箱编码',
                'showParam': true,
                'placeholder': '请输入音箱编码',
                'model': null,
                'visible': true,
                'require': true,
              },
              {
                'column': 'MOMEY',
                'name': 'MOMEY_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '播报金额',
                'comment': '播报金额',
                'showParam': true,
                'placeholder': '请输入播报金额',
                'model': null,
                'visible': true,
                'require': true,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esBusinessId': _.get(selectList,[0,'ID']),
            'speakerId': this.findOneColumnValue(data, 'SPEAKER_ID'),
            'money': this.findOneColumnValue(data, 'MOMEY'),
          };
          this.requestService.doActionByHdbsthor('AppApi/EsBusinessController', 'mandatoryBind', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  /**
   * 解除绑定音箱
   * @param pBtn
   * @param pParentData
   */
  doUnBindSpeaker(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '绑定音箱',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'MOMEY',
                'name': 'MOMEY_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '播报金额',
                'comment': '播报金额',
                'showParam': true,
                'placeholder': '请输入播报金额',
                'model': null,
                'visible': true,
                'require': true,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esBusinessId': _.get(selectList,[0,'ID']),
            'money': this.findOneColumnValue(data, 'MOMEY'),
          };
          this.requestService.doActionByHdbsthor('AppApi/EsBusinessController', 'unBind', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  /**
   * 绑定音箱
   * @param pBtn
   * @param pParentData
   */
  doBindSpeaker(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '绑定音箱',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'SPEAKER_ID',
                'name': 'SPEAKER_ID_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '音箱编码',
                'comment': '音箱编码',
                'showParam': true,
                'placeholder': '请输入音箱编码',
                'model': null,
                'visible': true,
                'require': true,
              },
              {
                'column': 'MOMEY',
                'name': 'MOMEY_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '播报金额',
                'comment': '播报金额',
                'showParam': true,
                'placeholder': '请输入播报金额',
                'model': null,
                'visible': true,
                'require': true,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esBusinessId': _.get(selectList,[0,'ID']),
            'speakerId': this.findOneColumnValue(data, 'SPEAKER_ID'),
            'money': this.findOneColumnValue(data, 'MOMEY'),
          };
          this.requestService.doActionByHdbsthor('AppApi/EsBusinessController', 'bind', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }
  /**
   * 测试音箱播报
   * @param pBtn
   * @param pParentData
   */
  doTestSpeaker(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '测试音箱播报',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'SPEAKER_ID',
              'name': 'SPEAKER_ID_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '音箱编码',
              'comment': '音箱编码',
              'showParam': true,
              'placeholder': '请输入音箱编码',
              'model': null,
              'visible': true,
              'require': true,
            },
            {
              'column': 'MOMEY',
              'name': 'MOMEY_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '播报金额',
              'comment': '播报金额',
              'showParam': true,
              'placeholder': '请输入播报金额',
              'model': null,
              'visible': true,
              'require': true,
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'speakerId': this.findOneColumnValue(data, 'SPEAKER_ID'),
          'money': this.findOneColumnValue(data, 'MOMEY'),
        };
        this.requestService.doActionByHdbsthor('AppApi/EsBusinessController', 'testSpeaker', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }

    });

  }
  /**
   * 预付款审核
   * @param pBtn
   * @param pParentData
   */
  // 预付款审核
  doCustomerApCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认预付款审核么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkCustomerAp', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 充值活动列表-客户自主充值
  doRecharge(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户确认充值',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '客户名称',
                'comment': '客户名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择客户名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID'),
            'payType': '江苏银行',
            'esRechargeActivitiesId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'customerRecharge', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  // 充值活动列表-钱包消费
  doWalletConsumption(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) !== 1 ) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '钱包消费',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'BUSI_ID',
                'name': 'BUSI_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'lable': '店铺名称',
                'comment': '店铺名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择店铺名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_BUSINESS_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'PAY_TYPE',
                'name': 'PAY_TYPE_NAME',
                'component': 'select',
                'customize': 24,
                'lable': '支付方式',
                'comment': '支付方式',
                'placeholder': '请输入支付方式',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/PAY_TYPE.json',
                'dictionaryPF': null
              },
              {
                'column': 'RECHARGE_PAID',
                'name': 'RECHARGE_PAID_NAME',
                'component': 'input',
                'type': 'number',
                'customize': 24,
                'lable': '消费金额',
                'comment': '消费金额',
                'placeholder': '请输入消费金额',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'COUPONS_MONEY',
                'name': 'COUPONS_MONEY_NAME',
                'component': 'input',
                'type': 'number',
                'customize': 24,
                'lable': '优惠金额',
                'comment': '优惠金额',
                'placeholder': '请输入优惠金额',
                'model': null,
                'visible': true,
                'require': false
              },
              {
                'column': 'CUSTOMER_COUPONS',
                'name': 'CUSTOMER_COUPONS_NAME',
                'component': 'poplist',
                'type': 'number',
                'customize': 24,
                'lable': '我的优惠券',
                'comment': '我的优惠券',
                'placeholder': '请输入我的优惠券',
                'model': null,
                'visible': true,
                'require': false,
                'dictionary': 'agriculture/basic/dict/ES_CUSTOMER_COUPONS_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'CAP_NOTE',
                'name': 'CAP_NOTE_NAME',
                'component': 'inputMulti',
                'customize': 24,
                'lable': '备注',
                'comment': '备注',
                'placeholder': '请输入备注',
                'model': null,
                'visible': true,
                'require': false
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'busiId':  this.findOneColumnValue(data, 'BUSI_ID'),
            'esCustomerId': _.get(selectList,[0,'ID']),
            'money': this.findOneColumnValue(data, 'RECHARGE_PAID'),
            'payType' : this.findOneColumnValue(data, 'PAY_TYPE'),
            'orderMemo': this.findOneColumnValue(data, 'CAP_NOTE'),
            'couponsMoney': this.findOneColumnValue(data, 'COUPONS_MONEY'),
            'esCustomerCouponsId': this.findOneColumnValue(data, 'CUSTOMER_COUPONS')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'faceOrderPay', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息列表-发送修改支付密码短信
  doModifyPaymentPasswordMessage(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认发送修改支付密码短信么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'paymentPassword', queryData, null, false, 'hdbsthor_agriculture','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息列表-发送绑定银行卡短信
  doBindBanknoMessage(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认发送绑定银行卡短信么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'boundBank', queryData, null, false, 'hdbsthor_agriculture','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息列表-修改支付密码
  doModifyPaymentPassword(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '修改支付密码',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PASSWORD01',
                'name': 'PASSWORD01_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '密码',
                'comment': '密码',
                'placeholder': '请输入密码',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PASSWORD02',
                'name': 'PASSWORD02_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '重复密码',
                'comment': '重复密码',
                'placeholder': '请输入重复密码',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '验证码',
                'comment': '验证码',
                'placeholder': '请输入验证码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let password01 = this.findOneColumnValue(data, 'PASSWORD01');
          password01 = Md5.hashStr(password01);
          password01 = Md5.hashStr(password01);
          let password02 = this.findOneColumnValue(data, 'PASSWORD02');
          password02 = Md5.hashStr(password02);
          password02 = Md5.hashStr(password02);

          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID']),
            'password01': password01,
            'password02': password02,
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'modifyPaymentPassword', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息列表-绑定银行卡
  doBindBankno(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '绑定银行卡',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_BANK',
                'name': 'CUST_BANK_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '开户银行',
                'comment': '开户银行',
                'placeholder': '请输入开户银行',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_PAID_BANKNO_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'CUSTOMER_CONTACTMAN',
                'name': 'CUSTOMER_CONTACTMAN_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '持卡人姓名',
                'comment': '持卡人姓名',
                'placeholder': '请输入持卡人姓名',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'CUST_BANKNO',
                'name': 'CUST_BANKNO_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '银行卡号',
                'comment': '银行卡号',
                'placeholder': '请输入银行卡号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'IDCARD',
                'name': 'IDCARD_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '身份证号',
                'comment': '身份证号',
                'placeholder': '请输入身份证号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '验证码',
                'comment': '验证码',
                'placeholder': '请输入验证码',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID']),
            'orderMemo': this.findOneColumnValue(data, 'ORDER_MEMO'),
            'custBank': this.findOneColumnValue(data, 'CUST_BANK'),
            'custBankno': this.findOneColumnValue(data, 'CUST_BANKNO'),
            'customerContactman': this.findOneColumnValue(data, 'CUSTOMER_CONTACTMAN'),
            'idcard': this.findOneColumnValue(data, 'IDCARD'),
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'boundBank', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户提现管理-完成打款
  doCompletePayment(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认操作完成打款么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'completeApplyCash', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户提现管理- 提现审批
  doCashApproval(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认审批么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'cashApproval', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 店铺信息-发送绑定验证码
  doBindAccountMessage(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认发送绑定验证码短信么？',
        suc: () => {
          let queryData = {
            'busiId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'boundEnterprise', queryData, null, false, 'hdbsthor_agriculture','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 店铺信息-发送解绑验证码
  doCancelAccountMessage(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认发送解绑验证码短信么？',
        suc: () => {
          let queryData = {
            'busiId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'unboundEnterprise', queryData, null, false, 'hdbsthor_agriculture','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 店铺信息-绑定账号
  doBindAccount(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '绑定账号',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '用户名',
                'comment': '用户名',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择用户名',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_CUSTOMER_ENTERPRISE_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '短信验证码',
                'comment': '短信验证码',
                'placeholder': '请输入短信验证码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'busiId': _.get(selectList,[0,'ID']),
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID'),
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'boundEnterprise', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 店铺信息-解绑账号
  doCancelAccount(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '解绑账号',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '短信验证码',
                'comment': '短信验证码',
                'placeholder': '请输入短信验证码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'busiId': _.get(selectList,[0,'ID']),
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'unBoundEnterprise', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 付款按钮
  doPayment(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '付款',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ADVANCE_PAYMENT',
                'name': 'ADVANCE_PAYMENT_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '付款金额',
                'comment': '付款金额',
                'placeholder': '请输入付款金额',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PASSWORD01',
                'name': 'PASSWORD01_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '密码',
                'comment': '密码',
                'placeholder': '请输入密码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        }
      });
    }
  }

  // 我的消息-已读
  doRead(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '已读',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'readMsg', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息-验证密码
  doVerificationPassword(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '验证密码',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PASSWORD01',
                'name': 'PASSWORD01_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '密码',
                'comment': '密码',
                'placeholder': '请输入密码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let password01 = this.findOneColumnValue(data, 'PASSWORD01');
          password01 = Md5.hashStr(password01);
          password01 = Md5.hashStr(password01);

          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID']),
            'password': password01
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkPaymentPassword', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息-申请提现
  doApplyCash(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '申请提现',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CASH_BALANCE',
                'name': 'CASH_BALANCE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '提现金额',
                'comment': '提现金额',
                'placeholder': '请输入提现金额',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '短信验证码',
                'comment': '短信验证码',
                'placeholder': '请输入短信验证码',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID']),
            'cash': this.findOneColumnValue(data, 'CASH_BALANCE'),
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'applyCash', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 优惠券管理-优惠券下架
  doCouponsOffShelf(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认优惠券下架么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCouponsController', 'offShelf', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 优惠券管理-优惠券上架
  doCouponsOnShelf(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认优惠券上架么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCouponsController', 'onShelf', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 优惠券管理-平台给用户发券
  doGrantCoupons(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '发放优惠券',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'COUPON_ID',
                'name': 'COUPON_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'lable': '优惠券',
                'comment': '优惠券',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择优惠券',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_COUPONS_PLATFORM_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'COUPON_CODE',
                    'name': 'COUPON_CODE_NAME',
                    'component': 'input',
                    'lable': '优惠券CODE',
                    'comment': '优惠券CODE',
                    'placeholder': '优惠券CODE',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          let queryData = {
            'userId': this.user.USER_ID,
            'couponId': this.findOneColumnValue(data, 'COUPON_ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCouponsController', 'adminGiveToCustomer', queryData, bodyData, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  // 客户信息列表-提现验证码
  doWithdrawalVerification(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1)
    {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '提现验证码',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList,[0,'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'applyCash', queryData, null, false, 'hdbsthor_agriculture','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //  可领用优惠券列表-领用优惠券
  doCollectCoupons(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '领用优惠券',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'couponId': _.get(selectList,[0,'ID']),
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCouponsController', 'customerGetCoupon', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息-平台充值
  doPlatformRecharge(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '平台充值',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'RECHARGE_ACTIVITIES',
                'name': 'RECHARGE_ACTIVITIES_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '充值活动',
                'comment': '充值活动',
                'placeholder': '请选择充值活动',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'agriculture/basic/dict/ES_RECHARGE_ACTIVITIES_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'esCustomerId': _.get(selectList,[0,'ID']),
            'esRechargeActivitiesId': this.findOneColumnValue(data, 'RECHARGE_ACTIVITIES')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'platformRecharge', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户管理-客户信息-客户对账单
  doCustomerStatement(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) < 1) {
      return false;
    }
    {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      pParentData['btnName'] = pBtn.ACTION_VALUE;
      pParentData['conditionColumns'] = {};
      pParentData['formType'] = 'customForm';
      pParentData['modeType'] = 'add';
      pParentData['actionArray'] = [];
      pParentData['id'] = ids[0]['id'];
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }

  // 商户用户进件-进件
  doIncomingParts(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) != 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认进件么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
        }
      });
    }
  }

  // 钱包提现记录-提现测试
  doWithdrawalTest(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '提现测试',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CASH_BALANCE',
                'name': 'CASH_BALANCE_NAME',
                'component': 'input',
                'customize': 36,
                'lable': '提现金额',
                'comment': '提现金额',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择提现金额',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'esCustomerId': _.get(selectList,[0,'CUST_ID']),
            'cash': this.findOneColumnValue(data, 'CASH_BALANCE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'applyCashTest', queryData, null, false, 'hdbsthor_agriculture', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }
}
