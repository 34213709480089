import {Component, Input, OnInit} from '@angular/core';

import {forkJoin as observableForkJoin} from "rxjs";
import * as _ from 'lodash';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";

@Component({
  selector: 'app-sto-goods-spilt',
  templateUrl: './sto-goods-spilt.component.html',
  styleUrls: ['./sto-goods-spilt.component.scss']
})
export class StoGoodsSpiltComponent implements OnInit {

  @Input() parentData: any;
  baseForm: any = {
    "type": "normal",
    "columns": [
      {
        "column": "STO_STATE",
        "name": "STO_STATE_NAME",
        "component": "select",
        "lable": "单据状态",
        "comment": "单据状态",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "单据状态",
        "model": null,
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/STORAGE_JIHUA_STATUS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TRAN_CUST_CODE",
        "name": "TRAN_CUST_CODE",
        "component": "input",
        "lable": "客户订单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "客户订单号",
        "placeholder": "请输入客户订单号",
        "model": null,
        "visible": true,
        "require": true,
        "disable": true
      },
      {
        "column": "CUST_ID_DICT",
        "name": "CUST_ID_DICT_NAME",
        "component": "input",
        "lable": "客户名称",
        "comment": "客户名称",
        "placeholder": "请输入客户名称",
        "model": null,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/BASIC_CUST.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "STO_CODE",
        "name": "STO_CODE_NAME",
        "component": "input",
        "lable": "运输计划单号",
        "comment": "运输计划单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入运输计划单号",
        "model": null,
        "showParam": true,
        "visible": true,
        "require": true,
        "disable": true
      },
      {
        "column": "SC_ID_DICT",
        "name": "SC_ID_DICT_NAME",
        "component": "input",
        "lable": "发货物流节点名称",
        "comment": "发货物流节点名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入发货物流节点名称",
        "model": null,
        "visible": true,
        "require": true,
        "input": "STIC_ID",
        "dictionary": "JieLiv2/basic/dict/BASIC_LOGISTIC_ADDRESS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "RC_ID_DICT",
        "name": "RC_ID_DICT_NAME",
        "component": "input",
        "lable": "收货物流节点名称",
        "comment": "收货物流节点名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入收货物流节点名称",
        "model": null,
        "visible": true,
        "require": true,
        "input": "STIC_ID",
        "dictionary": "JieLiv2/basic/dict/BASIC_LOGISTIC_ADDRESS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TRAN_LONG",
        "name": "TRAN_LONG_NAME",
        "component": "input",
        "lable": "公里",
        "comment": "公里",
        "placeholder": null,
        "model": null,
        "visible": true,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "require": false,
        "disable": true
      },
      {
        "column": "STO_VOLUME",
        "name": "STO_VOLUME",
        "component": "input",
        "lable": "总体积(立方米)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "总体积(立方米)",
        "placeholder": "请输入总体积(立方米)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "STO_WEIGHT",
        "name": "STO_WEIGHT_NAME",
        "component": "input",
        "lable": "总吨位(吨)",
        "comment": "总吨位(吨)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总吨位(吨)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "STO_QUANTITY",
        "name": "STO_QUANTITY_NAME",
        "component": "input",
        "lable": "总数(件)",
        "comment": "总数(件)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总数(件)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "STO_LEVEL",
        "name": "STO_LEVEL_NAME",
        "component": "select",
        "lable": "计划等级",
        "comment": "计划等级",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "计划等级",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TASK_LEVEL.json",
        "dictionaryPF": null,
        "disable": true
      }
    ]
  };
  conditionColumns: any = {
    "type": "normal",
    "columns": [
      {
        "column": "DET_QUANTITY_CONFIRM",
        "name": "DET_QUANTITY_CONFIRM_NAME",
        "component": "input",
        "typr": "number",
        "lable": null,
        "comment": null,
        "placeholder": "请输入拆分数量",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "DET_VOLUME_CONFIRM",
        "name": "DET_VOLUME_CONFIRM_NAME",
        "component": "input",
        "typr": "number",
        "lable": null,
        "comment": null,
        "placeholder": "请输入拆分体积",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "DET_WEIGHT_CONFIRM",
        "name": "DET_WEIGHT_CONFIRM_NAME",
        "component": "input",
        "typr": "number",
        "lable": null,
        "comment": null,
        "placeholder": "请输入拆分重量",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "GOODS_NAME",
      "title": "货品名称",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_VOLUME",
      "title": "总体积",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_WEIGHT",
      "title": "总重量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_QUANTITY",
      "title": "总数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_QUANTITY_CONFIRM",
      "title": "拆分数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_VOLUME_CONFIRM",
      "title": "拆分体积",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_WEIGHT_CONFIRM",
      "title": "拆分重量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    }
  ];
  planId = null;
  spiltList: any[];
  goodsSpiltList: any[];


  constructor(
    private requestService: RequestDataService,
    private dataCenterService: DataCenterService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService
  ) {
  }

  ngOnInit() {
    this.planId = _.cloneDeep(_.get(this.parentData, ['id']))
    this.doSearch();
  }


  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }


  doSave() {
    let that = this;
    let check = true;

    let checkListData = _.cloneDeep(this.goodsSpiltList);
    _.each(checkListData, oneData => {
      if (check) {
        if (!this.utils.Base_HasValue(oneData['DET_QUANTITY_CONFIRM'])) {
          check = false;
          this.confirmModal.show('error',
            {title: '拆分数量错误', content: '请先填写拆分数量'});
        }
      }

      if (check) {
        if (oneData['DET_QUANTITY_CONFIRM'] < 0) {
          check = false;
          this.confirmModal.show('error',
            {title: '拆分数量错误', content: '拆分数量不能小于0。'});
        }
      }

      if (check) {
        if (oneData['DET_QUANTITY_CONFIRM'] > oneData['DET_QUANTITY']) {
          check = false;
          this.confirmModal.show('error',
            {title: '拆分数量错误', content: '拆分数量必须小于已有数量！'});
        }
      }

    })


    if (check) {
      this.confirmModal.show('confirm', {
        title: '请确认货品拆分数量是否正确，确定提交？',
        suc: () => {
          var goodData = checkListData;
          var gDataId = "";
          var gDataNum = "";
          var gDataVolume = "";
          var gDataWeight = "";
          var gPkId = "";
          _.each(goodData, function (gdata) {
            var detId = gdata.OD_DET_ID;
            var goodNum = gdata.DET_QUANTITY_CONFIRM;
            var pkId = gdata.STO_ID;
            var goodVolume = gdata.DET_VOLUME_CONFIRM;
            var goodWeight = gdata.DET_WEIGHT_CONFIRM;

            gDataId += detId + ",";
            gDataNum += goodNum + ",";
            gDataVolume += goodVolume + ",";
            gDataWeight += goodWeight + ",";
            gPkId = pkId;
          });
          gDataId = gDataId.slice(0, gDataId.length - 1)
          gDataId = '\'' + gDataId + '\'';
          gDataNum = gDataNum.slice(0, gDataNum.length - 1)
          gDataNum = '\'' + gDataNum + '\'';
          gPkId = '\'' + gPkId + '\'';
          gDataVolume = gDataVolume.slice(0, gDataVolume.length - 1)
          gDataVolume = '\'' + gDataVolume + '\'';
          gDataWeight = gDataWeight.slice(0, gDataWeight.length - 1)
          gDataWeight = '\'' + gDataWeight + '\'';
          var param = "oddetId:" + gDataId + ",goodNum:" + gDataNum + ",pkId:" + gPkId + ",goodVolume:" + gDataVolume + ",goodWeight:" + gDataWeight + "";
          that.requestService.doActionByJavaPlatform('dataController', 'storageSplit', param).subscribe(values => {
            if (values['result'] == 'success') {
              this.confirmModal.show('success',
                {title: '拆分成功。', content: values['message']});
              this.gotoBack();
            } else {
              this.confirmModal.show('error',
                {title: '拆分失败。', content: values['message']});
            }
          })
        }
      });
    }
  }


  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_orders_list.json', {'STO_ID': this.planId}), // 0
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_orders_detail_detail.json', {'STO_ID': this.planId}), // 1
      this.requestService.getDataByCondition( 'JieLiv2/ms/ms_storage_orders_detail_copy.json', {'STO_ID': this.planId}), // 2
    );
    search.subscribe(values => {
      this.backfillData(this.baseForm, values[2]['data']);
      this.spiltList = _.get(values, [0, 'data'], []);
      this.goodsSpiltList = _.get(values, [1, 'data'], []);
      _.each(values, [1, 'data'], oneItem => {
        let obj = {
          'DET_QUANTITY_CONFIRM': _.get(oneItem, ['DET_QUANTITY_CONFIRM'], null),
          'DET_VOLUME_CONFIRM': _.get(oneItem, ['DET_VOLUME_CONFIRM'], null),
          'DET_WEIGHT_CONFIRM': _.get(oneItem, ['DET_WEIGHT_CONFIRM'], null)
        }
        this.goodsSpiltList.push(obj);
      })
    })
  }

  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        oneColumn['model'] = oneData[oneColumn['column']];
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']]
        })

      })
    })
  }

  changeQuantity($event, pOneData) {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/basic/basic_goods_detail.json', {'GOODS_ID': pOneData['GOODS_ID']}), // 0
    );
    search.subscribe(values => {
      var gWeight = _.get(values, [0, 'data', 0, 'GOODS_WEIGHT'], 0);
      var gVolume = _.get(values, [0, 'data', 0, 'GOODS_VOLUME'], 0);
      pOneData['DET_WEIGHT_CONFIRM'] = this.utils.Base_accMul(gWeight, $event);
      pOneData['DET_VOLUME_CONFIRM'] = this.utils.Base_accMul(gVolume, $event);
    })
  }
}

