import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";

@Component({
    selector: 'app-hulk-config-dictionary',
    templateUrl: './hulk-config-dictionary.component.html',
    styleUrls: ['./hulk-config-dictionary.component.scss']
})
export class HulkConfigDictionaryComponent implements OnInit {

    @Input() oneColumn: any;

    constructor(
        private _httpClient: HttpClient,
        private modalService: NzModalService,
        private _FileSaverService: FileSaverService,
        private requestDataService: RequestDataService,
        private appConfigService: AppConfigService,
        private notification: NzNotificationService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private clipboardService: ClipboardService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
    }

    mapOfExpandData: { [key: string]: boolean } = {};

    public isVisible = false;
    public allChecked = false;
    public indeterminate = false;

    showModal() {
        this.isVisible = true;
    }

    refreshStatus(): void {
        const validData = this.oneColumn.columnGroup.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }


    checkAll(value: boolean): void {
        this.oneColumn.columnGroup.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }
}
