import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UploadService} from "../../service/upload.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzModalService} from "ng-zorro-antd";
import {RequestDataService} from "../../service/request-data.service";
import {AjaxService} from "../../service/ajax.service";
import {UtilsService} from "../../service/utils.service";
import * as _sh from 'lodash';

@Component({
  selector: 'app-hulk-config-tinymce',
  templateUrl: './hulk-config-tinymce.component.html',
  styleUrls: ['./hulk-config-tinymce.component.scss']
})
export class HulkConfigTinymceComponent implements OnInit {

  @Input() oneColumn:any;
  @Input() attributes: any;
  @Input() label: any;
  @Input() nzSpanLable = 3;
  @Input() nzSpanInput = 21;

  apiKey: 'tz9q2op8zdy73lw8clfcn70muzvy4o4wk28c8na72bjv6ma8';
  initConfig: any;

  constructor(
    private uploadService: UploadService,
    private config: AppConfigService,
    private modalService: NzModalService,
    private requestService: RequestDataService,
    private ajaxService: AjaxService,
    private util: UtilsService
  ) {
    this.initConfig = {
      base_url: '/tinymce',
      language: 'zh_CN',
      language_url: '/assets/tinymce/langs/zh_CN.js',
      height: 500,
      font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;',
      plugins: [
        'advlist autolink image imagetools lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'table emoticons template paste help axupimgs bdmap importword fullscreen'
      ],
      toolbar: 'fullscreen | undo redo | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | image axupimgs | print preview media fullpage | ' +
        'forecolor backcolor emoticons | bdmap importword code help',
      images_upload_handler: (blobInfo, success, failure, progress) => {
        this.doUploadFile(blobInfo, success, failure, progress);
      },
      importword_handler: (editor, files, next) => {
        this.changeWord(editor, files, next);
      },
      importword_filter: function (result, insert, message) {
        // 自定义操作部分
        insert(result); //回插函数
      }
    };
  }

  changeWord(editor, files, next) {
    var file_name = files[0].name;
    if (file_name.substr(file_name.lastIndexOf('.') + 1) == 'docx') {
      // todo 可以加一个转化完成的提示。
      // editor.notificationManager.open({
      //   text: '正在转换中...',
      //   type: 'info',
      //   closeButton: false,
      // });
      next(files);
    } else {
      editor.notificationManager.open({
        text: '目前仅支持docx文件格式，若为doc111，请将扩展名改为docx',
        type: 'warning',
      });
    }
    // next(files);
  }

  isBlob(blob) {
    return typeof Blob !== 'undefined' && blob instanceof Blob;
  }

  isFile(obj) {
    return typeof File !== 'undefined' && obj instanceof File;
  };

  doUploadFile(blobInfo, success, failure, progress) {
    let file = blobInfo.blob();//转化为易于理解的file对象
    if (this.isFile(file)) {
      this.uploadFiletoLocal(file, success, failure, progress);
    } else {
      this.uploadFiletoServer(file, success);
    }
  }

  // 本地浏览器，直接传输到阿里云服务器。(或者腾讯云服务器）
  uploadFiletoLocal(file, success, failure, progress) {
    let fileName = this.util.Base_getFileName(file.name);
    let fileType = this.util.Base_getFileType(file.name);
    this.uploadService.doUpLoadSimple(file, fileName, fileType, (progressInfo) => {
      let percent = (progressInfo.loaded / progressInfo.total * 100).toFixed(0);
      if (this.util.Base_HasValue(progress)) {
        progress(percent);
      }
    }).then((result) => {
      let url = '';
      if (this.config.config['userOssOrCos'] === 'COS') {
        url = this.config.config['COS']['domain'] + '/' + result['url'];
      } else if (this.config.config['userOssOrCos'] === 'OSS') {
        url = this.config.config['OSS']['domain'] + '/' + result['name'];
      }
      success(url);
    }).then((result) => {
      failure(result);
    });
  }

  uploadFiletoServer(file, success) {
    this.uploadService.uploadFileCloud(file).subscribe(response => {
      if (this.util.Base_HasValue(response) && response.type === 4) {
        let url = _sh.get(response, ['body', 'url']);
        success(url);
      }
    });
  }

  ngOnInit() {

  }


}
