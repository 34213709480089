import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import * as _ from 'underscore';

@Component({
  selector: 'app-rank-bid',
  templateUrl: './rank-bid.component.html',
  styleUrls: ['./rank-bid.component.scss']
})
export class RankBidComponent implements OnInit {

  @Input() parentData: any;
  bidList: any;
  serverTime : any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.doSearch();
  }


  doSearch() {
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/JieLiv2/askbid/myRank/bid_list.json', {}),
      this.requestService.getDataByCondition('/JieLiv2/askbid/mysql_time.json', {}),
    );
    search01$.subscribe(
      values => {
        // 我的可以参与的投标列表
        this.bidList = _sh.get(values,[0,'data'],[]);
        this.serverTime =  _sh.get(values,[1,'data',0,'server_time'], null);
      }
    );
  }


}
