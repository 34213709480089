import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import * as _sh from "lodash";
import {hulkConfigProject} from "../hulk-config-project";
@Component({
  selector: 'app-hulk-config-file-center',
  templateUrl: './hulk-config-file-center.component.html',
  styleUrls: ['./hulk-config-file-center.component.scss']
})
export class HulkConfigFileCenterComponent implements OnInit {

  constructor(
    private _httpClient: HttpClient,
    private modalService: NzModalService,
    private _FileSaverService: FileSaverService,
    private requestDataService: RequestDataService,
    private appConfigService: AppConfigService,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private confirmModal: ConfirmModalService,
    private clipboardService: ClipboardService,
    private utils: UtilsService
  ) { }

  condition = {
    project: null,
    comment: null,
    tables: null,
    sqlFileName: null,
    type: null
  }

  baseData = [];
  showData = [];
  allChecked = false;
  indeterminate = false;
  selectedData = {};

  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = 10;
  // 总条数
  public total: any = 1;
  public nzPageSizeOptions: any = [5, 10, 20, 30, 50, 100, 200];

  projectData = hulkConfigProject;

  fileTypeData = [
    {
      "code": "normal",
      "name": "列表，详情，接口"
    },
    {
      "code": "dictionary",
      "name": "字典"
    },
    {
      "code": "tree",
      "name": "树形"
    }
  ]
  public isVisible = false;

  doFilter(temp) {
    let that = this;
    let condition = _sh.cloneDeep(this.condition);
    let result = _sh.cloneDeep(temp);
    _sh.each(_sh.keys(this.condition), oneKey => {
      if (that.utils.Base_HasValue(condition[oneKey])) {
        if (_.contains(['project', 'type'], oneKey)) {
          result = _sh.remove(result, function (oneData) {
            return oneData[oneKey] == condition[oneKey];
          });
        } else if (_.contains(['comment', 'tables', 'sqlFileName'], oneKey)) {
          result = _sh.remove(result, function (oneData) {
            let value = oneData[oneKey];
            let conditionStr = condition[oneKey];
            if (that.utils.Base_HasValue(value)) {
              let findIndex = value.indexOf(conditionStr);
              return findIndex != -1;
            } else {
              return false;
            }

          });
        }

      }
    })
    return result;
  }

  doSearch() {
    let that = this;
    this.requestDataService.getFileList().subscribe(rep => {
      let temp = _sh.cloneDeep(rep);
      _sh.each(temp, oneData => {
        oneData['project'] = this.getProject(oneData['sqlPath']);
        oneData['sqlFileName'] = this.getPath(oneData['sqlPath']);
        oneData['sqlFilePath'] = this.getFileName(oneData['sqlPath']);
      })

      temp = that.doFilter(temp);

      this.baseData = temp;

      this.total = this.baseData.length;
      this.searchFilter();
    });
  }

  ngOnInit() {
    this.doSearch();

  }

  doRefresh() {
    let that = this;
    this.confirmModal.show('confirm', {
      title: '是否需要刷新，所有的数据将不会保存。',
      suc: () => {
        this.doSearch();
      }
    });
  }

  checkAll(value: boolean): void {
    this.showData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.showData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  getPath(pValue) {
    let findIndex = _sh.findIndex(_sh.split(pValue, "/"), function (o) {
      return o == 'sqlFiles';
    });
    let result = _sh.chain(pValue)
      .split("/")
      .slice(findIndex + 1, 100)
      .join("/")
      .value();
    return result;
  }

  getProject(pValue) {
    let findIndex = _sh.findIndex(_sh.split(pValue, "/"), function (o) {
      return o == 'sqlFiles';
    });
    let result = _sh.chain(pValue)
      .split("/")
      .slice(findIndex + 1, findIndex + 2)
      .join("/")
      .value()
    return result;
  }

  getFileName(pValue) {
    let result = _sh.chain(pValue)
      .split("/")
      .takeRight(1)
      .join("")
      .value()
    return result;
  }

  gotoDesign(): void {
    let searchId = this.selectedData['sqlFileName'];
    if (!this.utils.Base_HasValue(searchId)) {
      this.confirmModal.show('error',
        {title: '请填写先选择一个需要修改的文件。', content: ""});
    } else {
      window.open('HulkConfigDesignList/?searchId=' + searchId, '_blank');
    }
  }

  gotoReadOnly() {
    let searchId = this.selectedData['sqlFileName'];
    if (!this.utils.Base_HasValue(searchId)) {
      this.confirmModal.show('error',
        {title: '请填写先选择一个需要修改的文件。', content: ""});
    } else {
      let searchId = this.selectedData['sqlFileName'];
      window.open('HulkConfigDesignReadOnly/?searchId=' + searchId, '_blank');
    }
  }

  searchFilter() {

    let begin = (this.pageIndex - 1) * this.pageSize;
    let end = this.pageIndex * this.pageSize;
    this.showData = _sh.slice(this.baseData, begin, end);
  }

  deleteCondition() {
    let keys = _sh.keys(this.condition);
    _.each(keys, oneKey => {
      // @ts-ignore
      this.condition[oneKey] = null;
    })
    this.selectedData = {};
    this.doSearch();
  }

  selectData(data: any) {
    // @ts-ignore
    this.selectedData = data;
  }

  doNewFile() {
    let checked = true;
    if (checked &&  !this.utils.Base_HasValue(this.condition['sqlFileName'])) {
      checked = false;
      this.confirmModal.show('error',
        {title: '请输入文件名。', content: ""});
    }
    if (checked) {
      let searchId = this.condition['sqlFileName'];
      if (!this.utils.Base_HasValue(searchId)) {
        this.confirmModal.show('error',
          {title: '请填写先选择一个需要修改的文件。', content: ""});
      } else {
        window.open('HulkConfigDesignList/?searchId=' + searchId, '_blank');
      }
    }
  }
}
