import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
import * as Mtils from 'mtils';
import * as _ from 'underscore';

@Component({
  selector: 'app-setting-columns',
  templateUrl: './setting-columns.component.html',
  styleUrls: ['./setting-columns.component.css']
})
export class SettingColumnsComponent implements OnInit {

  // 列数据
  @Input() columns: any = [];
  // 类型。是list 表头设置还是查询条件 通用和非通用设置
  @Input() type: string = '';

  public allChecked = true;
  public indeterminate = true;

  public checkedColumns: any = [];

  @Output('checkedChange') childFun = new EventEmitter();

  constructor(private utils: UtilsService) {
  }

  // 判断全选反选框的状态
  refreshStatus() {
    const validData = this.columns.filter(value => !value.disabled && value.visible);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.childFun.emit({
      checked: this.allChecked,
      indeterminate: this.indeterminate
    });
  }

  // 获取选中column数组
  updateSingleChecked(columns): void {
    this.checkedColumns = columns;
    this.refreshStatus();
  }

  // 表头排序
  sortName(type) {
    if (type === 'letter') {
      this.columns = _.sortBy(this.columns, 'sortFlag');
    } else {
      this.columns = _.sortBy(this.columns, 'index');
    }
  }

  ngOnInit() {
    // 如果是查询条件。则判断条件为当前的查询类型是否是通用
    // 如果是列表表头设置。则判断条件为当前列是否展示
    if (this.utils.Base_HasValue(this.type) && this.type === 'condition') {
      _.each(this.columns, (item, index) => {
        item['index'] = index;
        const showTitle = item['title'] || item['lable'];
        item['sortFlag'] = Mtils.utils.makePy(showTitle).slice(0, 1);
        if (item['type'] === 'common') {
          item['checked'] = true;
          this.checkedColumns.push(item['column']);
        } else {
          item['checked'] = false;
        }
      });
    } else {
      _.each(this.columns, (item, index) => {
        item['index'] = index;
        const showTitle = item['title'] || item['lable'];
        item['sortFlag'] = Mtils.utils.makePy(showTitle).slice(0, 1);
        if (_.isUndefined(item['show']) || item['show']) {
          item['checked'] = true;
          this.checkedColumns.push(item['column']);
        } else {
          item['checked'] = false;
        }
      });
    }
    this.refreshStatus();
  }
}
