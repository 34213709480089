import {Component, Input, OnInit} from '@angular/core';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {NzModalService} from 'ng-zorro-antd';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import * as _sh from 'lodash';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataCenterService} from '../../../service/data-center.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UserInfoService} from '../../../service/user-info.service';
import * as moment from 'moment';
import {uuid} from '@tinymce/tinymce-angular/utils/Utils';

@Component({
  selector: 'app-input-storage',
  templateUrl: './input-storage.component.html',
  styleUrls: ['./input-storage.component.css']
})
export class InputStorageComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';

  public today = moment().format('YYYY-MM-DD');

  public datas: any;
  public itemData: any = []; // wagon 明细表
  public stoCode; // 入库单编号

  public formData: any = {
    'title': '货物运单',
    'type': 'normal',
    'columns': [
      {
        'column': 'ID',
        'pk_column': true,
        'iscondition': true,
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'insertValue': 'SYS-UUID',
        'model': null,
        'visible': false,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'TIME_ACCESS',
        'name': 'TIME_ACCESS_NAME',
        'component': 'input',
        'lable': '接入时间',
        'comment': '',
        'placeholder': '请选择接入时间',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAGON_TYPE',
        'name': 'WAGON_TYPE_NAME',
        'component': 'select',
        'lable': '车皮属性',
        'comment': '车皮属性',
        'placeholder': '车皮属性',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'CHINARAILWAY',
        'insertValue': 'CHINARAILWAY',
        'visible': true,
        'require': false,
        'dictionary': 'dfmc/basic/dict/WAGON_TYPE.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify']
      },
      {
        'column': 'DEPARTURE_ID',
        'name': 'DEPARTURE_ID_NAME',
        'component': 'select',
        'lable': '发站',
        'comment': '发站',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入发站',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/DEPARTURE_STATION.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'STATION_CODE',
            'name': 'STATION_CODE_NAME',
            'component': 'input',
            'lable': '发站编号',
            'comment': '发站编号',
            'placeholder': '',
            'fromValue': 'STATION_CODE',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'DEPARTURE_STATION',
            'name': 'DEPARTURE_STATION_NAME',
            'component': 'input',
            'lable': '发站名称',
            'comment': '发站名称',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'STATION_SHORT_NAME',
            'name': 'STATION_SHORT_NAME_NAME',
            'component': 'input',
            'lable': '发站简称',
            'comment': '发站简称',
            'placeholder': '',
            'fromValue': 'STATION_SHORT_NAME',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'CUST_ID',
        'name': 'CUST_ID_NAME',
        'component': 'select',
        'lable': '客户信息',
        'comment': '客户信息',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择客户信息',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'CUST_NAME',
            'name': 'CUST_NAME_NAME',
            'component': 'input',
            'lable': '收货人',
            'comment': '收货人',
            'placeholder': '收货人',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'TRACK_ID_DEDICATED',
        'name': 'TRACK_ID_DEDICATED_NAME',
        'component': 'select',
        'lable': '专用线',
        'comment': '专用线',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择专用线',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': false,
        'dictionary': 'dfmc/basic/dict/MC_TRACK_SPECIAL_POPLIST.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'TRACK_NAME_DEDICATED',
            'name': 'TRACK_NAME_DEDICATED_NAME',
            'component': 'input',
            'lable': '专用线',
            'comment': '专用线',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'TRACK_SHORT_DEDICATED',
            'name': 'TRACK_SHORT_DEDICATED_NAME',
            'component': 'input',
            'lable': '专用线简称',
            'comment': '专用线简称',
            'placeholder': '专用线简称',
            'fromValue': 'TRACK_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'STATION_ID_ACCESS',
        'name': 'STATION_ID_ACCESS_NAME',
        'component': 'select',
        'lable': '接入站点',
        'comment': '接入站点',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入站点',
        'model': null,
        'visible': false,
        'type': 'normal',
        'require': false,
        'dictionary': 'dfmc/basic/dict/MC_TRAIN_STATION_FINISH.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'STATION_NAME_ACCESS',
            'name': 'STATION_NAME_ACCESS_NAME',
            'component': 'input',
            'lable': '接入站点',
            'comment': '接入站点',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'STATION_SHORT_ACCESS',
            'name': 'STATION_SHORT_ACCESS_NAME',
            'component': 'input',
            'lable': '列车站点简称',
            'comment': '列车站点简称',
            'placeholder': '',
            'fromValue': 'STATION_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'TRACK_ID_ACCESS',
        'name': 'TRACK_ID_ACCESS_NAME',
        'component': 'select',
        'lable': '接入股道',
        'comment': '接入股道',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入股道',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/MC_TRACK_POPLIST.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'TRACK_NAME_ACCESS',
            'name': 'TRACK_NAME_ACCESS_NAME',
            'component': 'input',
            'lable': '接入股道',
            'comment': '接入股道',
            'placeholder': '',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'TRACK_SHORT_ACCESS',
            'name': 'TRACK_SHORT_ACCESS_NAME',
            'component': 'input',
            'lable': '接入股道简称',
            'comment': '接入股道简称',
            'placeholder': '',
            'fromValue': 'TRACK_SHORT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'MAN_ACCESS',
        'name': 'MAN_ACCESS_NAME',
        'component': 'input',
        'lable': '接入操作人',
        'comment': '接入操作人',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择接入操作人',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': false,
        'dictionaryPF': null,
        'disable': ['add', 'modify']
      },
      {
        'column': 'TIME_ACCESS',
        'name': 'TIME_ACCESS_NAME',
        'component': 'datetime',
        'lable': '接入时间',
        'comment': '接入时间',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请输入接入时间',
        'visible': false,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'TIME_ACCESS_TIME',
        'name': 'TIME_ACCESS_TIME_NAME',
        'component': 'time',
        'lable': '接入时间',
        'comment': '',
        'placeholder': '请选择接入时间',
        'model': null,
        'visible': false,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAYBILL_ID',
        'name': 'WAYBILL_ID_NAME',
        'component': 'input',
        'lable': '货运单号',
        'comment': '货运单号',
        'placeholder': '请输入货运单号',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAGON_ID',
        'name': 'WAGON_ID_NAME',
        'component': 'input',
        'lable': '车种车号',
        'comment': '车种车号',
        'placeholder': '请输入车种车号',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAGON_STATE',
        'name': 'WAGON_STATE_NAME',
        'component': 'input',
        'lable': '车皮流程状态',
        'comment': '车皮流程状态',
        'placeholder': '车皮流程状态',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'TRACK',
        'insertValue': 'TRACK',
        'visible': false,
        'require': false,
        'dictionary': 'dfmc/basic/dict/WAGON_STATE.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAGON_RUN_STATE',
        'name': 'WAGON_RUN_STATE_NAME',
        'component': 'input',
        'lable': '车皮行进状态',
        'comment': '车皮行进状态',
        'placeholder': '车皮行进状态',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 'TRACK',
        'insertValue': 'TRACK',
        'visible': false,
        'require': false,
        'dictionary': 'dfmc/basic/dict/WAGON_RUN_STATE.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify']
      },
      {
        'column': 'STANDARD_W',
        'name': 'STANDARD_W_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '标重（吨）',
        'comment': '标重（吨）',
        'placeholder': '标重（吨）',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WEIGHING_W',
        'name': 'WEIGHING_W_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '过磅重（吨）',
        'comment': '过磅重（吨）',
        'placeholder': '过磅重（吨）',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'NET_W',
        'name': 'NET_W_NAME',
        'component': 'input',
        'type': 'number',
        'lable': '净重（吨）',
        'comment': '净重（吨）',
        'placeholder': '净重（吨）',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'TARPAULIN_ID',
        'name': 'TARPAULIN_ID_NAME',
        'component': 'input',
        'lable': '篷布号',
        'comment': '篷布号',
        'placeholder': '篷布号',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'WAGON_MEMO',
        'name': 'WAGON_MEMO_NAME',
        'component': 'textarea',
        'lable': '备注',
        'comment': '备注',
        'placeholder': '请输入备注',
        'class': 'col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'disable': ['add', 'modify']
      }
    ]
  };
  public storage_itemData: any = []; // 入库单明细表

  public allChecked = false;
  private indeterminate = false;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  private columnsJson: any = [
    {
      'column': 'CUST_ID',
      'name': 'CUST_ID_NAME',
      'component': 'poplist',
      'lable': '客户信息',
      'comment': '客户信息',
      'class': 'col-lg-4 col-sm-6 col-xs-12',
      'placeholder': '请选择客户信息',
      'model': null,
      'visible': true,
      'type': 'normal',
      'require': true,
      'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
      'dictionaryPF': null
    },
    {
      'column': 'LOC_ID',
      'name': 'LOC_ID_NAME',
      'component': 'poplist',
      'lable': '货位',
      'comment': '货位',
      'placeholder': '请选择货位',
      'insertValue': null,
      'model': null,
      'dictionary': 'dfmc/basic/dict/BASIC_PLANT_LOCATOR.json',
      'dictionaryPF': null,
      'visible': true,
      'require': true,
      'columnGroup': [
        {
          'column': 'LOC_CODE',
          'name': 'LOC_CODE_NAME',
          'component': 'input',
          'lable': '库位编号',
          'comment': '库位编号',
          'placeholder': '请输入库位编号',
          'insertValue': null,
          'fromValue': 'LOC_CODE',
          'model': null,
          'visible': false,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'LOC_NAME',
          'name': 'LOC_NAME_NAME',
          'component': 'input',
          'lable': '库位名称',
          'comment': '库位名称',
          'placeholder': '请输入库位名称',
          'insertValue': null,
          'fromValue': 'name',
          'model': null,
          'visible': false,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'PLANT_ID',
          'name': 'PLANT_ID_NAME',
          'component': 'input',
          'lable': '库位名称',
          'comment': '库位名称',
          'placeholder': '请输入库位名称',
          'insertValue': null,
          'fromValue': 'PLANT_ID',
          'model': null,
          'visible': false,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'AREA_ID',
          'name': 'AREA_ID_NAME',
          'component': 'input',
          'lable': '库区名称',
          'comment': '库区名称',
          'placeholder': '请输入库区名称',
          'insertValue': null,
          'fromValue': 'AREA_ID',
          'model': null,
          'visible': false,
          'require': false,
          'disable': ['add', 'modify'],
        }
      ]
    },
    {
      'column': 'GOODS_ID',
      'name': 'GOODS_ID_NAME',
      'component': 'poplist',
      'lable': '货品名称',
      'comment': '货品名称',
      'placeholder': '请输入货品名称',
      'insertValue': null,
      'model': null,
      'dictionary': 'dfmc/basic/dict/BASIC_GOODS_STORAGE.json',
      'dictionaryPF': null,
      'visible': true,
      'require': true,
      'columnGroup': [
        {
          'column': 'DET_SUPPLIER',
          'name': 'DET_SUPPLIER_NAME',
          'component': 'input',
          'lable': '生产商',
          'comment': '生产商',
          'placeholder': '请输入生产商',
          'insertValue': null,
          'fromValue': 'CUST_ID',
          'model': null,
          'visible': false,
          'require': false,
        },
        {
          'column': 'GOODS_CODE',
          'name': 'GOODS_CODE_NAME',
          'component': 'input',
          'lable': '编码',
          'comment': '编码',
          'placeholder': '请输入编码',
          'insertValue': null,
          'fromValue': 'GOODS_CODE',
          'model': null,
          'visible': false,
          'require': false
        },
        {
          'column': 'GOODS_CN_NAME',
          'name': 'GOODS_CN_NAME_NAME',
          'component': 'input',
          'lable': '货品中文名称',
          'comment': '货品中文名称',
          'placeholder': '请输入货品中文名称',
          'insertValue': null,
          'fromValue': 'name',
          'model': null,
          'visible': false,
          'require': false
        },
        {
          'column': 'GOODS_EN_NAME',
          'name': 'GOODS_EN_NAME_NAME',
          'component': 'input',
          'lable': '货品英文名称',
          'comment': '货品英文名称',
          'placeholder': '请输入货品英文名称',
          'insertValue': null,
          'fromValue': 'GOODS_EN_NAME',
          'model': null,
          'visible': false,
          'require': false
        }
      ]
    },
    {
      'column': 'CUST_NAME',
      'name': 'CUST_NAME_NAME',
      'component': 'input',
      'lable': '生产商',
      'comment': '生产商',
      'placeholder': '请输入生产商',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'GOODS_MODEL',
      'name': 'GOODS_MODEL_NAME',
      'component': 'input',
      'lable': '规格型号',
      'comment': '规格型号',
      'placeholder': '请输入规格型号',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'GOODS_MATERIAL',
      'name': 'GOODS_MATERIAL_NAME',
      'component': 'input',
      'lable': '材质',
      'comment': '材质',
      'placeholder': '请输入材质',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'DET_BATCH',
      'name': 'DET_BATCH_NAME',
      'component': 'input',
      'lable': '钢卷（板）号',
      'comment': '钢卷（板）号',
      'placeholder': '请输入钢卷（板）号',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': true
    },
    {
      'column': 'DET_PRODUCT',
      'name': 'DET_PRODUCT_NAME',
      'component': 'date',
      'lable': '生产日期',
      'comment': '生产日期',
      'class': 'col-lg-4 col-sm-6 col-xs-12',
      'placeholder': '请输入生产日期',
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'DET_QUANTITY',
      'name': 'DET_QUANTITY_NAME',
      'component': 'input',
      'lable': '数量',
      'comment': '数量',
      'placeholder': '请输入数量',
      'insertValue': null,
      'model': 1,
      'visible': false,
      'require': false
    },
    {
      'column': 'GROSS_WEIGHT',
      'name': 'GROSS_WEIGHT_NAME',
      'component': 'input',
      'type': 'number',
      'lable': '毛重（吨）',
      'comment': '毛重（吨）',
      'placeholder': '请输入毛重（吨）',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'NET_WEIGHT',
      'name': 'NET_WEIGHT_NAME',
      'component': 'input',
      'type': 'number',
      'lable': '净重（吨）',
      'comment': '净重（吨）',
      'placeholder': '请输入净重（吨）',
      'insertValue': null,
      'model': null,
      'visible': true,
      'require': false
    },
    {
      'column': 'DET_TEXT',
      'name': 'DET_TEXT_NAME',
      'component': 'input',
      'lable': '辅记号',
      'comment': '辅记号',
      'placeholder': '请输入辅记号',
      'insertValue': null,
      'model': null,
      'visible': false,
      'require': false,
      'disable': ['add', 'modify']
    }
  ];

  clearItemModel() {
    _sh.each(this.columnsJson, (oneColumn) => {
      _sh.set(oneColumn, 'model', null);
      let columnGroup = _sh.get(oneColumn, 'columnGroup', null);
      _sh.each(columnGroup, (oneGroupColumn) => {
        _sh.set(oneGroupColumn, 'model', null);
      });
    });
  }

  addItem() {
    this.clearItemModel(); // 清除 this.columnsJson 之上的 model
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1100,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': this.columnsJson
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _sh.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        // console.log(response);
        let oneRow = {};
        oneRow['ID'] = this.util.Base_getUuid();
        _sh.each(data, oneColumn => {
          oneRow[oneColumn['column']] = oneColumn['model'];
          let columnGroup = _sh.get(oneColumn, 'columnGroup', null);
          _sh.each(columnGroup, (oneGroupColumn) => {
            oneRow[oneGroupColumn['column']] = _sh.get(oneGroupColumn, 'model', null);
          });
        });
        oneRow['DET_QUANTITY'] = 1;
        this.storage_itemData.push(oneRow);
      }

    });
  }

  itemCopy() {
    if (this.selectMore(this.storage_itemData, false) < 1) {
      return false;
    } else {
      this.modalService.create({
        nzTitle: '复制数据',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'COPY_NUM',
                'name': 'COPY_NUM_NAME',
                'component': 'input',
                'lable': '复制数量',
                'comment': '复制数量',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '复制数量',
                'model': null,
                'visible': true,
                'type': 'number',
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _sh.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          const checkedList = _sh.filter(this.storage_itemData, (item) => {
            return item['checked'];
          });
          let copyNum = this.getValue(data, 'COPY_NUM');
          for (let i = 1; i <= copyNum; i++) {
            let temp01 = _sh.cloneDeep(checkedList[0]);
            temp01['ID'] = this.util.Base_getUuid();
            this.storage_itemData.push(temp01);
          }
        }
      });

    }
  }


  modifyDetatch() {
    const selectdNum = this.selectMore(this.storage_itemData, true);
    if (selectdNum < 1) {
      return false;
    } else {
      this.modalService.create({
        nzTitle: '批量修改钢卷（板）号,目前选中:' + selectdNum + ' 条数据',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'DET_BATCH',
                'name': 'DET_BATCH_NAME',
                'component': 'textarea',
                'lable': '钢卷（板）号',
                'comment': '钢卷（板）号',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '钢卷（板）号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _sh.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          const checkedList = _sh.filter(this.storage_itemData, (item) => {
            return item['checked'];
          });
          let DET_BATCH = this.getValue(data, 'DET_BATCH');
          let DET_BATCH_ARR = this.transToArray(DET_BATCH);
          if (selectdNum != DET_BATCH_ARR.length) {
            //this.confirmModal.show('error', {title: '目前选中:' + selectdNum + ' 条数据, 您的输入框输入的是:' + DET_BATCH_ARR.length + ' 条数据'});
            return false;
          } else {
            let rowIndex = 0;
            let tempRow = null
            _sh.forEach(checkedList, oneRow => {
              _sh.set(oneRow,['DET_BATCH'],_sh.cloneDeep(DET_BATCH_ARR[rowIndex]));
              let eq = _sh.eq(oneRow,tempRow);
              tempRow = oneRow;
              rowIndex++;
            });
          }
        }
      });
    }
  }

  transToArray(pStr) {
    //转换成数组
    let snsArr = pStr.split(/[(\r\n)\r\n]+/);
    snsArr.forEach((item, index) => {
      if (!item) {
        snsArr.splice(index, 1);
      }
    });
    snsArr = _sh.cloneDeep(snsArr);
    return snsArr;
  }

  // 明细table删除
  itemRemove(): void {
    const checkedList = _sh.filter(this.storage_itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.storage_itemData = this.storage_itemData.filter((item) => {
            return !item.checked;
          });
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  checkAll(value: boolean): void {
    this.storage_itemData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.storage_itemData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _sh.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  formatMainToEntity() {
    let entity: any = {};
    _sh.each(_sh.get(this.formData, 'columns'), (item) => {
      const column = _sh.camelCase(_sh.get(item, 'column'));
      const value = _sh.get(item, 'model');
      entity[column] = value;
      let columnGroup = _sh.get(item, 'columnGroup', null);
      _sh.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
        const itemValue = _sh.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatItemToEntity() {
    let entityList: any = [];
    _sh.each(this.storage_itemData, (oneRow) => {
      let entity: any = {};
      _sh.each(_sh.keys(oneRow), (key) => {
        const column = _sh.camelCase(key);
        const value = _sh.get(oneRow, key);
        entity[column] = value;
      });
      entityList.push(entity);
    });
    return entityList;
  }

  getValue(pColumns, pColumnName) {
    const oneOneColum = _sh.find(pColumns, {'column': pColumnName});
    const oneValue = _sh.get(oneOneColum, 'model');
    return oneValue;
  }

  findOneColumnValue(pColumnName) {
    const columns = _sh.get(this.formData, 'columns', []);
    const oneOneColum = _sh.find(columns, {'column': pColumnName});
    const oneValue = _sh.get(oneOneColum, 'model');
    return oneValue;
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput(pCheckItem) {
    let result = true;
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (pCheckItem) {
      // tslint:disable-next-line:triple-equals
      if (this.storage_itemData.length == 0) {
        this.confirmModal.show('error', {'title': '必填项', 'content': '货物明细数据为必填项'});
        result = false;
        return result;
      }
    }
    return result;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _sh.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _sh.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  saveData() {
    if (!this.checkInput(true)) {
      return false;
    }
    const data = {
      'userId': this.userInfoService.getUserInfo().USER_ID,
      'mcWagon': this.formatMainToEntity(),
      'mcStorageDetailList': this.formatItemToEntity()
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McStorageController', 'input', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }

  writebackFormData() {
    _sh.each(_sh.get(this.formData, 'columns'), (item) => {
      const column = _sh.get(item, 'column');
      const value = _sh.get(this.datas, column);
      _sh.set(item, 'model', value);
      let columnGroup = _sh.get(item, 'columnGroup', null);
      _sh.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _sh.get(oneGroupColumn, 'column');
        const itemValue = _sh.get(this.datas, itemColumn);
        _sh.set(oneGroupColumn, 'model', itemValue);
      });
    });
  }

  ngOnInit(): void {
    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('dfmc/mc/mc_wagon_mount_empty_list.json', this.parentData.detailCondition),
      this.requestData.getDataByCondition('dfmc/mc/mc_wagon_mount_empty_detail.json', this.parentData.detailCondition)
    );
    parallel$.subscribe(
      values => {
        this.datas = values[0]['data'][0];
        this.itemData = values[1]['data'];
        this.writebackFormData();
      }
    );
  }

}
