import {Pipe, PipeTransform} from '@angular/core';
import * as _sh from 'lodash';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(items: any[], filterObj: Array<{ column: string, hasValue: [any], condition?: String }>): any {

    // let test: Array<{ column: string, hasValue: [any], condition?: String }> =
    //   [{'column': 'component', 'hasValue': ['input'], 'condition': 'in'},{'column': 'show', 'hasValue': [true]}];

    let temp = items;
    _sh.each(filterObj, oneFilter => {
      temp = _sh.filter(temp, oneItem => {
        const findValue = _sh.get(oneItem, oneFilter.column);
        if (oneFilter.condition === 'in') {
          return _sh.indexOf(oneFilter.hasValue, findValue) > -1;
        } else if (oneFilter.condition === 'notIn') {
          return _sh.indexOf(oneFilter.hasValue, findValue) === -1;
        } else {
          return _sh.indexOf(oneFilter.hasValue, findValue) > -1; // 没有填写条件的也是 in
        }
      });
    });
    return temp;
  }

}
