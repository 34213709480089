import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, UploadFile, UploadXHRArgs} from 'ng-zorro-antd';
import {UploadService} from '../../../service/upload.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {Observable, Observer} from 'rxjs';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataService} from '../data.service';

@Component({
  selector: 'app-upload-picture-resume',
  templateUrl: './upload-picture-resume.component.html',
  styleUrls: ['./upload-picture-resume.component.css']
})
export class UploadPictureResumeComponent implements OnInit {

  @Input() dataObject: any;
  @Input() resumeType: any;

  loading = false;
  avatarUrl: string;

  constructor(private msg: NzMessageService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private data: DataService,
              private config: AppConfigService) {
  }

  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        // this.msg.error('You can only upload JPG file!');
        if (this.resumeType === 'CH') {
          this.confirmModal.show('error', {title: `请上传jpeg或者png格式的图片`});
        } else {
          this.confirmModal.show('error', {title: `Please upload pictures in jpeg or PNG format`});
        }
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 10MB!');
        if (this.resumeType === 'CH') {
          this.confirmModal.show('error', {title: `请上传小于10M的图片`});
        } else {
          this.confirmModal.show('error', {title: `Image must smaller than 10MB!`});
        }
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        // if (!dimensionRes) {
        //   this.msg.error('Image only 413x295 above');
        //   observer.complete();
        //   return;
        // }

        // observer.next(isJPG && isLt2M && dimensionRes);
        observer.next(isJPG && isLt2M);
        observer.complete();
      });
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(413 === height && width === 295);
        // resolve(true);
      };
    });
  }

  customReq = (item: UploadXHRArgs) => {
    if(this.utils.Base_HasValue(this.config.config['resumeType']) && this.config.config['resumeType'] === 'ccnu'){
      this.data.uploadFile(item.file).subscribe(rep => {
        if(rep['result'] === 'success'){
          item.onSuccess({}, item.file, {});
          this.dataObject.value = this.config.config.fileUpload+"/upload/"+rep['message'];
        }else{
          this.confirmModal.show('error',{title:'上传失败'})
        }
      });
    }else{
      if (this.config.config['userOssOrCos'] === 'COS') {
        return this.uploadService.doCosUpload(item.file, item['progress'], '', 'hulkbuster/resume/', null).then((result) => {
          item.onSuccess({}, item.file, {});
          this.dataObject.value = this.config.config['COS']['domain'] + '/' + result['url'];
        });
      } else {
        return this.uploadService.doAliOssUpload(item.file, item['progress'], '','hulkbuster/resume/').then((result) => {
          item.onSuccess({}, item.file, {});
          this.dataObject.value = this.config.config['OSS']['domain'] + '/' + result.name;
        });
      }
    }
  };

  handleChange(info: { file: UploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loading = false;
        break;
    }

  }

  ngOnInit() {

  }

}
