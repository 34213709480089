export const environment = {
  production: true,
  hmr: false,
  test: false,
  commonConfig: {
    'timeConfig': {  // 时间参数配置
      'localStorageTimeOut': 30  // 时间不操作,自动退出
    },
    'numberConfig': {    // 数值参数配置
      'exportDataNum': 50000   // 一次性导出的最大值
    },
    'pageNum': 1,
    'pageCount': 10,
    'userOssOrCos': 'OSS',
    'OSS': {
      'config': {
        region: 'oss-cn-qingdao',
        accessKeyId: 'LTAI5t5ZVqtvMwRzQi7vg3NE',
        accessKeySecret: 'Nzvuf6b5HrWWF9Oris0jPj5t7SAzz1',
        bucket: 'hdbs-oss',
        secure: true,
        timeout: 180000
      },
      'domain': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com',
      'ossPath': 'iron/JieLiv2/'
    }
  },
  'JieLiv2Pro': {
    'nacosPF': 'jieliv2pro.pro',
    'KingHulkServer': 'https://kinghulk.xueshu.ren',
    'conditionPageExpand': true,
    'MysqlPF': 'JieLiDataResources',
    'mongoPF': 'mongoAliRdsResumeTestDataResources',
    'NodeJsServer': 'https://dfmc83.xueshu.ren:4001/hulk',
    'ExportServer': 'https://dfmc83.xueshu.ren:4001/hulk',
    'hulkAppToken': 'HX8b9fp2X7@z8aa',
    'quickSliver': 'https://dfmc83.xueshu.ren:4001/quicksilver',
    'NodeJsApiVersion': 'v1',
    'AutoPlatform': 'https://dfmc83.xueshu.ren:4001/auto/HdbsAP',
    'javaPlatform': 'https://javaplatform.xueshu.ren/HdbsJavaPlatform',
    'hdbsthor': {
      'zuul': 'https://dfmc83.xueshu.ren:4001/hdbsthor_zuul',
      'accessToken': 'ed43c7fc5b1c8ee7e45d50977814bddf'
    },
    'hdbsthor_JieLiv2': {
      'getway': 'https://hdbsthor-jieliv2-pro.xueshu.ren',
      'accessToken': '90c0e25c0cc8070984023e3afa18a42c'
    },

    'hdbsthor_right': {
      'getway': 'https://dfmc83.xueshu.ren:4001/hdbsthor_right',
      'accessToken': '90c0e25c0cc8070984023e3afa18a42c'
    },
    'menu_list': 'JieLiv2/menu_list.json',
    'app_list': 'JieLiv2/app_list.json',
    'login_check': 'JieLiv2/login_check.json',
    'login_count': 'JieLiv2/login_count.json',
    'btn_list': 'JieLiv2/user_menu_action.json',
    'btn_detail': 'JieLiv2/user_menu_detail_action.json',
    'logoConfig': {
      companyInfo: '捷利供应链物流第三方服务平台',
      welcomeInfo: '捷利供应链物流第三方服务平台',
      versionInfo: 'V2021-09-14-1.0.5'
    },
    'sql_path': 'JieLiv2',
    'userOssOrCos': 'OSS',
    'defaultIcon':'windows',
    'debug': true,
    'OSS': {
      'config': {
        region: 'oss-cn-qingdao',
        accessKeyId: 'LTAI5t5ZVqtvMwRzQi7vg3NE',
        accessKeySecret: 'Nzvuf6b5HrWWF9Oris0jPj5t7SAzz1',
        bucket: 'hdbs-oss',
        secure: true,
        timeout: 180000
      },
      'domain': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com',
      'ossPath': 'iron/JieLiv2/'
    }
  }
};
