import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import {ConfirmModalService} from './confirm-modal.service';
import {AppConfigService} from './app-config.service';
import {OtmsActionService} from '../project/otms/otms-action.service';
import {DataCenterService} from './data-center.service';
import {AutoInsertService} from './auto-insert.service';
import {RequestDataService} from './request-data.service';
import {ShopManagementService} from '../project/shopManagement/shop-management.service';
import {HeadLineService} from '../project/headLine/head-line.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';

import {NzDrawerService, NzModalService} from 'ng-zorro-antd';
import {ImportComponent} from '../basicComponent/import/import.component';
import {UserInfoService} from './user-info.service';
import {ResumeService} from '../project/resume/resume.service';
import {FuryService} from '../project/fury/fury.service';
import {DigitalResourcesService} from '../project/digitalResources/digital-resources.service';
import {EducationResourcesService} from '../project/education/education-resources.service';
import {ComplainResourcesService} from '../project/complain/complain-resources.service';
import {WisdomHsService} from '../project/wisdomHs/wisdom-hs.service';
import {DfmcService} from '../project/dfmc/dfmc.service';
import {VolunteerService} from '../project/volunteer/volunteer.service';
import {GenecreateService} from '../project/genecreate/genecreate.service';
import {AgricultureService} from '../project/agriculture/agriculture.service';
import {forkJoin as observableForkJoin, from, Observable, of} from "rxjs";
import {FormModalComponent} from '../basicComponent/form-modal/form-modal.component';
import {DrawerBtnComponent} from '../basicForm/drawer-btn/drawer-btn.component';
import * as moment from 'moment';
import {Md5} from "ts-md5";
import {JumpCenterService} from "./jump-center.service";
import {ServiceCenterService} from "./service-center.service";

@Injectable({
    providedIn: 'root'
})
export class ListActionService {

    private actionArray: any;

    private showColumns: any;

    constructor(
        private util: UtilsService,
        private appConfig: AppConfigService,
        private otmsAction: OtmsActionService,
        private requestService: RequestDataService,
        private dataCenterService: DataCenterService,
        private autoService: AutoInsertService,
        private shopManagementService: ShopManagementService,
        private modalService: NzModalService,
        private confirmModal: ConfirmModalService,
        private userInfo: UserInfoService,
        private headLineAction: HeadLineService,
        private wisdomHsService: WisdomHsService,
        private resumeService: ResumeService,
        private furyService: FuryService,
        private digitalResources: DigitalResourcesService,
        private complainResources: ComplainResourcesService,
        private educationResources: EducationResourcesService,
        private dfmcService: DfmcService,
        private volunteerService: VolunteerService,
        private genecreateService: GenecreateService,
        private agricultureService: AgricultureService,
        private drawerService: NzDrawerService,
        private jumpCenterService: JumpCenterService,
        private serviceCenterService: ServiceCenterService
    ) {
    }

    selectOneData(pListData): number {
        let iCount = 0;
        _.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });
        // tslint:disable-next-line:triple-equals
        if (iCount == 0) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请至少选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        } else if (iCount > 1) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请只选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        }
        return iCount;
    }

    selectMore(pListData, multiple?): number {
        //计数，有多少条记录被勾选了。
        let iCount = 0;
        _.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });

        // tslint:disable-next-line:triple-equals
        if (multiple == true) {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请至少选择一条纪录！'
                });
                return iCount;
            }
        } else {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请选择一条纪录！'
                });
                return iCount;
            } else if (iCount > 1) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '只能选择一条纪录！'
                });
                return iCount;
            }
        }
        return iCount;
    }

    mackConditon(pParentData): void {
        function setDetailConditons(pData) {
            function isCondtion(OneColumn) {
                let bFind = false;
                if (_.has(OneColumn, 'iscondition')) {
                    bFind = OneColumn.iscondition;
                }
                return bFind;
            }

            let conData = {};
            _.each(pData, function (oneData) {
                if (oneData['checked']) {
                    conData = oneData;
                }
            });
            let tmp = {};
            _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
                //只将 iscondition 为 true 的作为条件传入详情页面。
                if (isCondtion(OneColumn)) {
                    tmp[OneColumn['conditionColumn']] = _sh.get(conData, [OneColumn['column']]);// conData[];
                }
            });
            return tmp;
        }

        pParentData.detailCondition = setDetailConditons(pParentData.data.data);
    }

    deleteData(parentData) {
        const pListData = parentData.data.data;
        if (this.selectMore(pListData, true) >= 1) {
            this.confirmModal.show('confirm', {
                'title': '温馨提示',
                'content': '您确定删除选中的数据?',
                suc: () => {
                    let tmpJson = this.autoService.AdapterLogicDelete(parentData.data);
                    this.autoService.ExecJson(tmpJson).subscribe(response => {
                        if (response['state'] === 'success') {
                            this.confirmModal.show('success', {
                                'title': '温馨提示',
                                'content': '执行成功'
                            });
                            parentData.needSearch = true;
                        } else {
                            this.confirmModal.show('error', {
                                'title': '温馨提示',
                                'content': '保存失败'
                            });
                        }
                    });
                }
            });
        }
    }

    export() {
        this.requestService.getExcel(null, 'all').subscribe(rep => {
            if (rep['state'] === 'success') {
                window.open(rep['text'], '_blank', '');
                this.util.Base_doShowConfirm('success', '导出成功');
            } else {
                this.util.Base_doShowConfirm('error', rep.error || '导出失败');
            }
        });
    }

    formatValue(oneOneColum, oneValue) {
        let result = oneValue;
        const format = _sh.get(oneOneColum, ['format'], null);
        if (format == 'md5') {
            result = Md5.hashStr(oneValue);
        }
        return result;
    }

    findOneColumnValue(pColumns, pColumnName) {
        const oneOneColum = _sh.find(pColumns, {'column': pColumnName});
        let oneValue = null;
        if (this.util.Base_HasValue(oneOneColum)) {
            oneValue = _sh.get(oneOneColum, 'model');
            oneValue = this.formatValue(oneOneColum, oneValue);
        } else {
            _sh.each(pColumns, oneColumn => {
                _sh.each(oneColumn['columnGroup'], oneGroup => {
                    if (oneGroup['column'] === pColumnName) {
                        oneValue = _sh.get(oneGroup, 'model');
                        oneValue = this.formatValue(oneOneColum, oneValue);
                    }
                })
            })
        }

        return oneValue;
    }


    // 处理自定义按钮
    doActionOperate(pBtn, pParentData, pInputForm, pReadOnlyData) {
        let that = this;

        var allObservable = Observable.create(
            function (allObserver) {

                function execSystemAction(pBtn) {
                    let observable;
                    switch (pBtn.actionValue) {
                        case 'add':
                            pParentData.detailCondition = {};
                            that.transformData('detailForm', 'add', pParentData);
                            observable = of(true);
                            break;
                        case 'edit':
                            const pListData = pParentData.data.data;
                            if (that.selectOneData(pListData) !== 1) {
                                observable = of(false);
                            } else {
                                that.mackConditon(pParentData);
                                that.transformData('detailForm', 'modify', pParentData);
                                observable = of(true);
                            }
                            break;
                        case 'delete':
                            that.deleteData(pParentData);
                            observable = of(true);
                            break;
                        case'hulkImport':
                            if (!that.util.Base_HasValue(pBtn['IMPORT_JSON_FILE'])) {
                                that.confirmModal.show('error', {title: '请先配置导入Json模版'});
                                return false;
                            }
                            let jumpId = _sh.get(pParentData, ['nowMenu', 'MENU_ID'], that.util.Base_getUuid()) + '_import';
                            let jumpConfig = {
                                "list": {
                                    "default": {
                                        "jumpPage": {
                                            "modeType": "modify",
                                            "component": "app-import-form",
                                            "formFlag": "modifyRentOtherContract",
                                            "formType": "customForm",
                                            "visibleBtn": {
                                                "back": true,
                                                "print": false
                                            },
                                            "titleTemplate": _sh.get(pParentData, ['nowMenu', 'MENU_NAME'], "导入") + '-导入',
                                            "menuId": _sh.get(pParentData, ['nowMenu', 'MENU_ID'], that.util.Base_getUuid()),
                                            "newPage": true,
                                        },
                                        "jumpData": {
                                            "id": jumpId,
                                            "importJsonFile": pBtn['IMPORT_JSON_FILE'],
                                            "importModelUrl": pBtn['IMPORT_MODEL_URL']
                                        }
                                    }
                                }
                            };
                            that.jumpCenterService.execJumpPage(jumpConfig, pParentData, null, null);
                            observable = of(true);
                            break;
                        case 'export':
                            that.export();
                            observable = of(true);
                            break;
                        case 'updateRelease':
                            that.updateRelease(pParentData, 'PUBLISHED');
                            observable = of(true);
                            break;
                        case 'updateTest':
                            that.updateRelease(pParentData, 'TEST');
                            observable = of(true);
                            break;
                        case 'appAutoJson':
                            that.appAutoJson(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'addWorkflow':
                            that.doAddWorkflow(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'modifyWorkflow':
                            that.doAddWorkflow(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'addWordTemplate':
                            that.doAddWordTemplate(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'modifyWordTemplate':
                            that.doModifyWordTemplate(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'addIgWordTemplate':
                            that.doAddIgWordTemplate(pBtn, pParentData);
                            observable = of(true);
                            break;
                        case 'modifyIgWordTemplate':
                            that.doModifyIgWordTemplate(pBtn, pParentData);
                            observable = of(true);
                            break;
                        default:
                            that.gotoProject(pBtn, pParentData);
                            observable = of(true);
                    }
                    return observable;
                }


                function execAction(actionType, buttonConfig, data) {
                    let observable;
                    if (actionType == 'execInterface') {
                        observable = execInterface(buttonConfig, data);
                    } else if (actionType == 'jumpPage') {
                        observable = execJumpPage(buttonConfig, null);
                    } else if (actionType == 'javaCheckBeforeJump') {
                        observable = execJavaCheckBeforeJump(buttonConfig, null);
                    } else if (actionType == 'system') {
                        observable = execSystemAction(buttonConfig);
                    }
                    return observable;
                }

                function doActionOperateExec(buttonConfig, queryData, bodyData) {
                    let ajaxResponse = that.requestService.doActionByHdbsthor(
                        buttonConfig['thorConfig']['controller'],
                        buttonConfig['thorConfig']['method'],
                        queryData, bodyData,
                        buttonConfig['thorConfig']['hasPF'],
                        buttonConfig['thorConfig']['thorConfig'],
                        buttonConfig['thorConfig']['appToken']);
                    return ajaxResponse;
                }

                function doActionOperateExecForJava(buttonConfig, queryData, bodyData) {
                    let param = [];
                    _sh.each(_sh.keys(bodyData), oneKey => {
                        let valueText = bodyData[oneKey];
                        if (_sh.isArray(valueText)) {
                            valueText = that.util.getArrayByPath(valueText, oneKey);
                            valueText = _sh.join(valueText, ',');
                        }
                        let text = oneKey + ':\'' + valueText + '\'';
                        param.push(text);
                    })
                    let bodyText = _sh.join(param, ',');
                    let oneAjax = that.requestService.doActionByJavaPlatform(
                        buttonConfig['javaConfig']['controller'],
                        buttonConfig['javaConfig']['method'],
                        bodyText)
                    return oneAjax;
                }

                function doActionOperateExecForHulk(buttonConfig, queryData, bodyData) {
                    let oneAjax = that.requestService.doActionByHdbsHulk(
                        buttonConfig['hulkConfig']['controller'],
                        buttonConfig['hulkConfig']['method'],
                        queryData, bodyData);
                    return oneAjax;
                }

                function checkHasValue(pRecords, pColumn) {
                    let count = 0;
                    _sh.each(pRecords, oneRecord => {
                        if (that.util.Base_HasValue(_sh.get(oneRecord, [pColumn], null))) {
                            count++;
                        }
                    });
                    return count > 0;
                }

                function nullDeleteColumns(pList, pColumn) {
                    _sh.each(pList['COLUMNS'], oneColumn => {
                        if (oneColumn['COLUMN'] == pColumn) {
                            pList['COLUMNS'] = _sh.without(pList['COLUMNS'], oneColumn);
                        }
                    })
                }

                function doActionOperateExecForAuto(buttonConfig, pModalData) {
                    let autoJsonList = _sh.get(buttonConfig, ['autoJson', 'LIST'], []);
                    _sh.each(autoJsonList, oneAction => {
                        let records = [];
                        let recordsDatasConfig = _sh.get(oneAction, ['RECORDS_DATAS'], null);
                        if (recordsDatasConfig == "SELECT") {
                            let rows = that.dataCenterService.getSpecialValue(recordsDatasConfig, pParentData, pModalData, pReadOnlyData, null);
                            _sh.each(rows, oneRow => {
                                let oneRecord = _sh.cloneDeep(oneAction['RECORDS'][0]);
                                let oneRecordKeys = _sh.keys(oneRecord);
                                _sh.each(oneRecordKeys, oneKey => {
                                    let oneRecordoneKey = oneRecord[oneKey];
                                    let value = that.dataCenterService.getSpecialValue(oneRecordoneKey, pParentData, pModalData, pReadOnlyData, oneRow);
                                    oneRecord[oneKey] = value;
                                })
                                records.push(oneRecord);
                            })
                            oneAction['RECORDS'] = records;
                        } else if (recordsDatasConfig == "MODAL") {
                            let oneRecord = _sh.cloneDeep(oneAction['RECORDS'][0]);
                            let oneRecordKeys = _sh.keys(oneRecord);
                            _sh.each(oneRecordKeys, oneKey => {
                                let oneRecordoneKey = oneRecord[oneKey];
                                let value = that.dataCenterService.getSpecialValue(oneRecordoneKey, pParentData, pModalData, pReadOnlyData, null);
                                oneRecord[oneKey] = value;
                            })
                            records.push(oneRecord);
                            oneAction['RECORDS'] = records;
                        }
                    });
                    let autoTemplate = {
                        "LIST": autoJsonList
                    }
                    _sh.each(autoTemplate['LIST'], oneList => {
                        _sh.each(oneList['NULL_DELETE_COLUMNS'], oneColumn => {
                            let hasValue = checkHasValue(oneList['RECORDS'], oneColumn);
                            if (!hasValue) {
                                nullDeleteColumns(oneList, oneColumn);
                            }
                        });
                    });
                    let oneAjax = that.autoService.ExecJson(autoTemplate);
                    return oneAjax;
                }


                /**
                 * 组织 query 参数的数据
                 * @param buttonConfig
                 * @param selectList
                 * @param data
                 */
                function getQueryData(buttonConfig, pModalData) {
                    let queryData = {};
                    _sh.each(_sh.keys(buttonConfig['queryData']), oneKey => {
                        let value = buttonConfig['queryData'][oneKey];
                        queryData[oneKey] = that.dataCenterService.getSpecialValue(value, pParentData, pModalData, pReadOnlyData);
                    });
                    return queryData;
                }

                /**
                 * 组织 body 参数的数据
                 * @param buttonConfig
                 * @param selectList
                 * @param data
                 */
                function getBodyData(buttonConfig, pModalData) {
                    let bodyData = {};
                    _sh.each(_sh.keys(buttonConfig['bodyData']), oneKey => {
                        let value = buttonConfig['bodyData'][oneKey];
                        let isArray = _sh.get(value, ['isArray'], false);
                        if (isArray) { // 如果按照数组的模式获取数据。
                            let keys = _sh.keys(value['columns']);
                            const selectList = _.filter(pParentData.data.data, (item) => {
                                return item['checked'];
                            });
                            let tempArray = [];
                            _sh.each(selectList, oneData => {
                                let tempObj = {};
                                _sh.each(keys, oneArrayKey => {
                                    let tempValue = value['columns'][oneArrayKey];
                                    tempValue = that.dataCenterService.getSpecialValue(tempValue, pParentData, pModalData, pReadOnlyData, oneData);
                                    tempObj[oneArrayKey] = tempValue
                                })
                                tempArray.push(tempObj);
                            })
                            bodyData[oneKey] = tempArray;
                        } else {
                            bodyData[oneKey] = that.dataCenterService.getSpecialValue(value, pParentData, pModalData, pReadOnlyData);
                        }
                    });
                    let bodyDataCopy01 = _sh.cloneDeep(bodyData);
                    _sh.each(_sh.keys(bodyDataCopy01), oneParam => {
                        let paramLv = _sh.split(oneParam, '.').length;
                        if (paramLv == 1) {
                            delete bodyDataCopy01[oneParam]
                        }
                    });
                    let otherParam = {}
                    _sh.each(_sh.keys(bodyDataCopy01), oneParam => {
                        _sh.set(otherParam, oneParam, bodyDataCopy01[oneParam])
                    });

                    let bodyDataCopy02 = _sh.cloneDeep(bodyData);
                    _sh.each(_sh.keys(bodyDataCopy02), oneParam => {
                        let paramLv = _sh.split(oneParam, '.').length;
                        if (paramLv > 1) {
                            delete bodyDataCopy02[oneParam]
                        }
                    });
                    bodyData = _sh.assignIn(bodyDataCopy02, otherParam);
                    return bodyData;
                }

                /**
                 *
                 * @param pParentData
                 * @param buttonConfig 按钮的配置文件
                 * @param selectList 选中的数据
                 * @param modalData 弹出框的数据
                 */
                function execInterface(buttonConfig, pModalData, pListDatas?) {
                    var observable = Observable.create(function (observer) {
                        let queryData = getQueryData(buttonConfig, pModalData);
                        let bodyData = getBodyData(buttonConfig, pModalData);
                        let interfaceType = _sh.get(buttonConfig, ['interfaceType'], "thor");
                        if (interfaceType == "thor") {
                            observer.next(doActionOperateExec(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "hulk") {
                            observer.next(doActionOperateExecForHulk(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "auto") {
                            observer.next(doActionOperateExecForAuto(buttonConfig, pModalData));
                        } else if (interfaceType == "java") {
                            observer.next(doActionOperateExecForJava(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "service") {
                            observer.next(that.serviceCenterService.doChangeListAction(pListDatas, null, buttonConfig['servicePath']));
                        }
                        observer.complete();
                    })
                    return observable;
                }

                function execJumpPage(buttonConfig, pModalData) {
                    var observable = Observable.create(function (observer) {
                        that.mackConditon(pParentData); // 创建传入值条件。
                        that.jumpCenterService.execJumpPage(buttonConfig['jumpConfig'], pParentData, pModalData, pReadOnlyData);
                        observer.complete();
                    })
                    return observable;
                }

                async function execThorCheck(oneCheck, modalDataAft) {
                    let queryData = getQueryData(oneCheck, modalDataAft);
                    let bodyData = getBodyData(oneCheck, modalDataAft);
                    let thorCheckRes = await doActionOperateExec(oneCheck, queryData, bodyData).toPromise();
                    var status = _sh.get(thorCheckRes, ['status'], 200);
                    if (status == 500) {
                        let errorMsg = _sh.get(thorCheckRes, [0, 'error'], null);
                        errorMsg = _sh.get(thorCheckRes, ['error', 'message'], errorMsg);
                        that.confirmModal.show('error', {
                            'title': '执行错误',
                            'content': errorMsg
                        });
                        return false;
                    } else {
                        return true
                    }
                }

                function execJavaCheckBeforeJump(buttonConfig, pModalData) {
                    var observable = Observable.create(function (observer) {
                        // 获取 query 参数的数据，作为URL参数
                        let queryData = getQueryData(buttonConfig, pModalData);
                        // 获取 body 参数的数据，作为POST参数
                        let bodyData = getBodyData(buttonConfig, pModalData);
                        // 默认使用thor类型
                        let interfaceType = _sh.get(buttonConfig, ['interfaceType'], "thor");
                        if (interfaceType == "thor") {
                            var res = doActionOperateExec(buttonConfig, queryData, bodyData);
                            res.subscribe({
                                next: res => {
                                    var status = _sh.get(res, ['status'], 200);
                                    if (status == 500) {
                                        let errorMsg = _sh.get(res, [0, 'error'], null);
                                        errorMsg = _sh.get(res, ['error', 'message'], errorMsg);
                                        that.confirmModal.show('error', {
                                            'title': '执行错误',
                                            'content': errorMsg
                                        });
                                    } else if (status == 200) {
                                        let result = _sh.get(res, ['result'], 'success');
                                        if (result == 'success') {
                                            that.mackConditon(pParentData); // 创建传入值条件。
                                            that.jumpCenterService.execJumpPage(buttonConfig['jumpConfig'], pParentData, pModalData, pReadOnlyData);
                                        } else if (result == 'error') {
                                            let errorMsg = _sh.get(res, ['message'], null);
                                            that.confirmModal.show('error', {
                                                'title': '执行错误',
                                                'content': errorMsg
                                            });
                                        }
                                    }
                                }
                            })
                        }
                        observer.complete();
                    })
                    return observable;
                }

                let actionOperate = _sh.get(pBtn, ['ACTION_OPERATE'], null);
                const search = observableForkJoin(
                    that.requestService.getDataByJson(actionOperate)
                );
                search.subscribe(
                    async values => {
                        const buttonConfig = values[0] || {};
                        let checked = false;

                        let needSelect = _sh.get(buttonConfig, ['needSelect'], true);

                        if (needSelect) {
                            const selectCount = that.selectMore(pParentData.data.data, buttonConfig['selectMore']);
                            if (buttonConfig['selectMore']) { // true 可多选，
                                checked = selectCount >= 1;
                            } else { // false 只可单选，
                                checked = selectCount == 1;
                            }
                        } else {
                            checked = true;
                        }
                        // 弹出窗口或者跳转页面之前，先来check一下，满足条件或者没有check条件时接着执行
                        if (checked) {
                            if (that.dataCenterService.doCheck(buttonConfig, null, "beforeOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                checked = true;
                            } else {
                                checked = false;
                            }
                        }
                        // 数据展开前，弹出窗口内赋予默认值
                        let modalColumns = _sh.get(buttonConfig, ['modalConfig', 'nzComponentParams', 'modalFormData', 'columns'], [])
                        let defValueList = _sh.filter(modalColumns, oneColumn => {
                            return that.util.Base_HasValue(_sh.get(oneColumn, ['fromValue'], null));
                        })
                        let modalData = _sh.get(buttonConfig, ['modalData']);
                        let modalDataAft = {};
                        if (that.util.Base_HasValue(modalData)) {
                            let modalDataList = _sh.keys(modalData);
                            _sh.each(modalDataList, oneModalConfig => {
                                let originalDatas = [];
                                if (modalData[oneModalConfig]['fromDataCenter'] == "SELECT") {
                                    originalDatas = _.filter(pParentData.data.data, (item) => {
                                        return item['checked'];
                                    });
                                }
                                if (modalData[oneModalConfig]['aggregateFunction'] == "sum") {
                                    if (that.util.Base_HasValue(originalDatas)) {
                                        let sum = 0;
                                        _sh.each(originalDatas, oneData => {
                                            let findLink = _sh.chain(modalData[oneModalConfig]['fromValue']).split(".").drop().value();
                                            let value = _sh.get(oneData, findLink, 0);
                                            sum = sum + value;
                                        })
                                        modalDataAft[oneModalConfig] = sum;
                                    } else {
                                        modalDataAft[oneModalConfig] = 0;
                                    }
                                }
                                if (modalData[oneModalConfig]['aggregateFunction'] == "count") {
                                    if (that.util.Base_HasValue(originalDatas)) {
                                        modalDataAft[oneModalConfig] = originalDatas.length;
                                    } else {
                                        modalDataAft[oneModalConfig] = 0;
                                    }
                                }

                            })
                        }

                        if (that.util.Base_HasValue(defValueList)) {
                            _.each(defValueList, oneColumn => {
                                const fromValue = oneColumn['fromValue'];
                                const value = that.dataCenterService.getSpecialValue(fromValue, pParentData, null, pReadOnlyData, modalDataAft);
                                oneColumn['model'] = value;
                            })
                        }

                        //  这里做的是雷神的弹窗检查模式。
                        let thorCheckList = _sh.filter(buttonConfig['check'], oneRow => {
                            return oneRow['checkPoint'] == 'beforeOpenModal' && oneRow['judgeThor']
                        })
                        if (that.util.Base_HasValue(thorCheckList)) {
                            for (let thorIndex = 0; thorIndex < thorCheckList.length; thorIndex++) {
                                let oneCheck = thorCheckList[thorIndex];
                                if (checked) {
                                    checked = await execThorCheck(oneCheck, modalDataAft);
                                }
                            }
                        }

                        // 无论有无弹窗，判断接下来的动作
                        const actionType = _sh.get(buttonConfig, 'actionType', 'execInterface');
                        if (checked) {
                            // list 列表页面，选择的数据。
                            let modalType = buttonConfig['modalType'];
                            let modalConfig = buttonConfig['modalConfig'];

                            if (modalType == 'yes_or_no' || modalType == 'noModal') {
                                if (that.dataCenterService.doCheck(buttonConfig, null, "afterOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                    if (modalType == 'yes_or_no') {
                                        that.confirmModal.show('confirm', {
                                            title: modalConfig['nzTitle'],
                                            content: modalConfig['nzContent'],
                                            suc: () => {
                                                var observable = execAction(actionType, buttonConfig, null);
                                                allObserver.next(observable);
                                            }
                                        });
                                    } else if (modalType == 'noModal') {
                                        var observable = execAction(actionType, buttonConfig, null);
                                        allObserver.next(observable);
                                    }
                                }
                            } else if (modalType == 'custom') {
                                that.modalService.create({
                                    nzTitle: modalConfig['nzTitle'],
                                    nzContent: FormModalComponent,
                                    nzWidth: modalConfig['nzWidth'],
                                    nzMaskClosable: _sh.get(modalConfig, ['nzMaskClosable'], false),
                                    nzWrapClassName: modalConfig['nzWrapClassName'],
                                    nzComponentParams: modalConfig['nzComponentParams'],
                                    nzOnOk: (response) => {
                                        const data = response.modalFormData.columns;
                                        let requireCheck = true;
                                        _.each(data, oneColumn => {
                                            if (!that.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
                                                requireCheck = false;
                                            }
                                        });
                                        if (!requireCheck) {
                                            that.confirmModal.show('error', {title: '请填写必填信息。'});
                                            return false;
                                        }
                                        if (that.dataCenterService.doCheck(buttonConfig, data, "afterOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                            var observable = execAction(actionType, buttonConfig, data);
                                            allObserver.next(observable);
                                        }
                                    }
                                });
                            }
                        }
                    }
                )
            });

        return allObservable;

    }

    doAction(pBtn, parentData, pInputForm, pReadOnlyData?) {

        const pListData = parentData.data.data;
        const handleEditAction = () => {
            if (this.selectOneData(pListData) !== 1) return false;
            this.mackConditon(parentData);
            this.transformData('detailForm', 'modify', parentData);
            return true;
        };
        const handleSystemCustom = (actionValueType) => {
            if (actionValueType !== 'systemCustom') return false;

            if (parentData.modeType === 'add') {
                Object.assign(parentData, {
                    btnName: pBtn.ACTION_VALUE,
                    conditionColumns: {},
                    formType: 'customForm',
                    modeType: parentData.modeType,
                    actionArray: []
                });
                this.dataCenterService.refreshDataCenterInStorage();
            } else if (parentData.modeType === 'modify') {
                if (this.selectMore(parentData.data.data, false) !== 1) return false;
                const selectList = _.filter(parentData.data.data, item => item['checked']);
                let ids = this.formatSelectEntity(selectList, 'ID');
                Object.assign(parentData, {
                    id: ids[0]['id'],
                    modeType: 'modify',
                    btnName: pBtn.ACTION_VALUE,
                    conditionColumns: {},
                    formType: 'customForm',
                    actionArray: []
                });
                this.dataCenterService.refreshDataCenterInStorage();
            }
            return true;
        };

        const handleStandardActions = (actionValue) => {
            switch (actionValue) {
                case 'add':
                    parentData.detailCondition = {};
                    this.transformData('detailForm', 'add', parentData);
                    return true;
                case 'edit':
                    return handleEditAction();
                case 'delete':
                    this.deleteData(parentData);
                    return true;
                case 'hulkImport':
                    if (!this.util.Base_HasValue(pBtn['IMPORT_JSON_FILE'])) {
                        this.confirmModal.show('error', {title: '请先配置导入Json模版'});
                        return false;
                    }
                    const jumpId = _sh.get(parentData, ['nowMenu', 'MENU_ID'], this.util.Base_getUuid()) + '_import';
                    const jumpConfig = {
                        "list": {
                            "default": {
                                "jumpPage": {
                                    "modeType": "modify",
                                    "component": "app-import-form",
                                    "formFlag": "modifyRentOtherContract",
                                    "formType": "customForm",
                                    "visibleBtn": {
                                        "back": true,
                                        "print": false
                                    },
                                    "titleTemplate": _sh.get(parentData, ['nowMenu', 'MENU_NAME'], "导入") + '-导入',
                                    "menuId": _sh.get(parentData, ['nowMenu', 'MENU_ID'], this.util.Base_getUuid()),
                                    "newPage": true,
                                },
                                "jumpData": {
                                    "id": jumpId,
                                    "importJsonFile": pBtn['IMPORT_JSON_FILE'],
                                    "importModelUrl": pBtn['IMPORT_MODEL_URL']
                                }
                            }
                        }
                    };
                    this.jumpCenterService.execJumpPage(jumpConfig, parentData, null, null);
                    break;
                case 'export':
                    this.export();
                    break;
                case 'updateRelease':
                    this.updateRelease(parentData, 'PUBLISHED');
                    break;
                case 'updateTest':
                    this.updateRelease(parentData, 'TEST');
                    break;
                case 'appAutoJson':
                    this.appAutoJson(pBtn, parentData);
                    break;
                case 'addWorkflow':
                    this.doAddWorkflow(pBtn, parentData);
                    break;
                case 'modifyWorkflow':
                    this.doAddWorkflow(pBtn, parentData);
                    break;
                case 'addWordTemplate':
                    this.doAddWordTemplate(pBtn, parentData);
                    break;
                case 'modifyWordTemplate':
                    this.doModifyWordTemplate(pBtn, parentData);
                    break;
                case 'addIgWordTemplate':
                    this.doAddIgWordTemplate(pBtn, parentData);
                    break;
                case 'modifyIgWordTemplate':
                    this.doModifyIgWordTemplate(pBtn, parentData);
                    break;
                default:
                    this.gotoProject(pBtn, parentData);
            }
            return true;
        };

        return new Observable(observer => {
            if (_.contains(['delete', 'edit'], pBtn.ACTION_VALUE)) {
                if (pBtn.ACTION_VALUE === 'edit' && this.selectOneData(pListData) !== 1) return;
                if (this.btnOpt(parentData.data, pBtn.ACTION_VALUE) === 0 && pBtn.ACTION_TYPE === 'list') {
                    this.confirmModal.show('warning', {
                        'title': '温馨提示',
                        content: '当前数据锁定状态,不可进行操作'
                    });
                    return;
                }
            }

            const actionOperate = _sh.get(pBtn, ['ACTION_OPERATE'], null);
            const actionValue = _sh.get(pBtn, ['ACTION_VALUE'], null);

            if (this.util.Base_HasValue(actionOperate) && (actionValue !== 'list')) {
                let obsdoActionOperate = this.doActionOperate(pBtn, parentData, pInputForm, pReadOnlyData);
                observer.next(obsdoActionOperate);
            } else {
                const actionValueArray = _sh.split(pBtn.ACTION_VALUE, ".");
                const actionValueType = actionValueArray[0];
                const modeType = actionValueArray[1];
                parentData.modeType = modeType;

                if (!handleSystemCustom(actionValueType)) {
                    handleStandardActions(pBtn.ACTION_VALUE);
                }
            }

            // 记录操作日志
            const optData = {
                'MENU_ID': pBtn.MENU_ID,
                'MENU_NAME': pBtn.MENU_NAME,
                'ROLE_ID': pBtn.ROLE_ID,
                'OPT_CONTENT': '页面[' + pBtn.ACTION_TYPE + ']',
                'OPT_TYPE': pBtn.ACTION_VALUE,
                'OPT_BUTTON': pBtn.ACTION_NAME
            };
            this.requestService.saveOptLog(optData).subscribe();

            observer.complete();
        });
    }

    formatSelectEntity(datas, pColumn) {
        let entity: any = [];
        _.each(datas, (OneData) => {
            let obj: any = {};
            const keys = _.keys(OneData);
            _.each(keys, (oneKeys) => {
                if (oneKeys === pColumn) {
                    obj['id'] = OneData[oneKeys];
                }
            });
            entity.push(obj);
        });
        return entity;
    }

    doModifyWordTemplate(pBtn, pParentData) {
        if (this.selectMore(pParentData.data.data, false) !== 1) {
            return false;
        } else {
            const selectList = _.filter(pParentData.data.data, (item) => {
                return item['checked'];
            });
            let ids = this.formatSelectEntity(selectList, 'ID');
            pParentData['id'] = ids[0]['id'];
            pParentData['modeType'] = 'modify';
            pParentData['btnName'] = pBtn.ACTION_VALUE;
            pParentData['conditionColumns'] = {};
            pParentData['formType'] = 'customForm';
            pParentData['actionArray'] = [];
            this.dataCenterService.refreshDataCenterInStorage();
        }
    }

    doModifyIgWordTemplate(pBtn, pParentData) {
        if (this.selectMore(pParentData.data.data, false) !== 1) {
            return false;
        } else {
            const selectList = _.filter(pParentData.data.data, (item) => {
                return item['checked'];
            });
            let ids = this.formatSelectEntity(selectList, 'ID');
            pParentData['id'] = ids[0]['id'];
            pParentData['modeType'] = 'modify';
            pParentData['btnName'] = pBtn.ACTION_VALUE;
            pParentData['conditionColumns'] = {};
            pParentData['formType'] = 'customForm';
            pParentData['actionArray'] = [];
            this.dataCenterService.refreshDataCenterInStorage();
        }
    }

    doAddWorkflow(pBtn, pParentData) {
        pParentData['modeType'] = 'add';
        pParentData['btnName'] = pBtn.ACTION_VALUE;
        pParentData['conditionColumns'] = {};
        pParentData['formType'] = 'customForm';
        pParentData['actionArray'] = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    doAddWordTemplate(pBtn, pParentData) {
        pParentData['modeType'] = 'add';
        pParentData['btnName'] = pBtn.ACTION_VALUE;
        pParentData['conditionColumns'] = {};
        pParentData['formType'] = 'customForm';
        pParentData['actionArray'] = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    doAddIgWordTemplate(pBtn, pParentData) {
        pParentData['modeType'] = 'add';
        pParentData['btnName'] = pBtn.ACTION_VALUE;
        pParentData['conditionColumns'] = {};
        pParentData['formType'] = 'customForm';
        pParentData['actionArray'] = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    appAutoJson(pBtn, parentData) {
        parentData.data = _.filter(parentData.data.data, (item) => {
            return item['checked'];
        });
        parentData.btnName = pBtn.ACTION_VALUE;
        parentData.conditionColumns = {};
        parentData.formType = 'customForm';
        parentData.modeType = 'edit';
        parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === parentData.id) {
                item = _.extend(item, parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    updateRelease(parentData, pState) {
        if (this.selectMore(parentData.data.data, true) === 0) {
            return false;
        }
        let selectList = [];
        _.each(parentData.data.data, (item) => {
            if (item['checked']) {
                selectList.push(item['ID']);
            }
        });
        this.confirmModal.show('confirm', {
            title: '您确定执行此操作吗？',
            suc: () => {
                const user = this.userInfo.getUserInfo();
                let ids = selectList.join('\',\'');
                ids = '(\'' + ids + '\')';
                const templateJson = {
                    'LIST': [
                        {
                            'TABLE': 'hdbs_json_dispose',
                            'ACTION': 'Update',
                            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                            'WHERE': [{
                                'COLUMN': 'ID',
                                'VALUE': ids,
                                'OPERATOR': 'in',
                                'VALUE_TYPE': 'int',
                                'CONDITION_OPERATOR': 'AND',
                                'GROUP': 'GROUP_01'
                            }],
                            'COLUMNS': [
                                {'COLUMN': 'JSON_STATUS'},
                                {'COLUMN': 'UPD_NAME'},
                                {'COLUMN': 'UPD_USERID'},
                            ],
                            'RECORDS': [
                                {
                                    'JSON_STATUS': pState,
                                    'UPD_NAME': user.USERNAME,
                                    'UPD_USERID': user.USER_ID,
                                }
                            ]
                        }
                    ]
                };
                this.autoService.ExecJson(templateJson).subscribe(result => {
                    if (result['state'] === 'success') {
                        this.confirmModal.show('success', {
                            title: '操作成功',
                            suc: () => {
                                parentData.needSearch = true;
                            }
                        });
                    } else {
                        this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
                    }
                });
            }
        });
    }

    transformData(pFormType, pModeType, parentData) {
        // 重新覆盖 datacenter 数据
        parentData.data = {};
        parentData.conditionColumns = {};
        parentData.formType = pFormType;
        parentData.modeType = pModeType;
        parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === parentData.id) {
                item = _.extend(item, parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    /**
     * 非通用按钮,去项目的按钮组件
     *
     * @param pBtn
     */
    gotoProject(pBtn, parentData): void {
        switch (this.appConfig.config.sql_path) {
            case'sino':
                break;
            case'otms':
                this.otmsAction.doAction(pBtn, parentData);
                break;
            case 'shopManagement':
                this.shopManagementService.doAction(pBtn, parentData);
                break;
            case 'headLine':
                this.headLineAction.doAction(pBtn, parentData);
                break;
            case 'resume':
                this.resumeService.doAction(pBtn, parentData);
                break;
            case 'fury':
                this.furyService.doAction(pBtn, parentData);
                break;
            case 'digitalResources':
                this.digitalResources.doAction(pBtn, parentData);
                break;
            case 'education':
                this.educationResources.doAction(pBtn, parentData);
                break;
            case 'complain':
                this.complainResources.doAction(pBtn, parentData);
                break;
            case 'wisdomHs':
                this.wisdomHsService.doAction(pBtn, parentData);
                break;
            case 'dfmc':
                this.dfmcService.doAction(pBtn, parentData);
                break;
            case 'volunteer':
                this.volunteerService.doAction(pBtn, parentData);
                break;
            case 'genecreate':
                this.genecreateService.doAction(pBtn, parentData);
                break;
            case 'agriculture':
                this.agricultureService.doAction(pBtn, parentData);
                break;
            case 'simulationTrain':
                this.headLineAction.doAction(pBtn, parentData);
                break;
        }
    }

    /**
     *  判断数据在指定的状态才能操作 1 代表可以操作 0 代表不可操作
     */
    btnOpt(dataObject, type) {
        let optState = 1;
        _.each(dataObject.data, (OneData) => {
            let selected = this.util.Base_getJsonValue(OneData, 'checked', false);
            if (selected) {
                _.each(dataObject.showColumns.columns, (OneColumn) => {
                    if (this.util.Base_HasValue(OneColumn['buttonOpt'])) {
                        let dataState = OneData[OneColumn['column']];
                        if (!_.contains(OneColumn['buttonOpt'], dataState)) {
                            optState = 0;
                        }
                    } else if (this.util.Base_HasValue(OneColumn['stateLimit'])) {
                        let dataState = OneData[OneColumn['column']];
                        if (!_.contains(OneColumn['stateLimit'][type], dataState)) {
                            optState = 0;
                        }
                    }
                });
            }
        });
        return optState;
    }
}
