import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMsgService {

  constructor() { }


  translateMsg(pMsg) {
    let result = pMsg;
    if (pMsg == "Corrupted zip : can't find end of central directory") {
      result = "上传文件压缩失败，请重试<br/>" + result;
    }
    return result;
  }
}
