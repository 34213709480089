import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as alasql from 'alasql';
import * as _ from 'underscore';

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.css']
})
export class PaperComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1: any = [];
    _.each(this.listData, oneData => {
      const data = {
        '收录类型': oneData['SOURCE_OR_TYPE'] || '',
        '论文篇数': oneData['ACOUNT'] || 0
      };
      data1.push(data);
    });
    if (this.listData.length === 0) {
      const data = {
        '收录类型': '',
        '论文篇数': ''
      };
      data1.push(data);
    }
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年学术论文发表情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("IV-3 近5年学术论文发表情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.requestDataService.getDataByCondition('subjectInfo/report/report_paper.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe(response => {
      this.listData = response.data;
    });
  }

}
