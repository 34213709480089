import {Pipe, PipeTransform} from '@angular/core';
import * as _sh from 'lodash';

@Pipe({
    name: 'cutString'
})
export class CutStringPipe implements PipeTransform {

    funcChina(pValue) {
        if (escape(pValue).indexOf("%u") < 0) {
            return false; // 没有包含中文
        }
        return true; // 包含中文
    }

    calcBeginMidEnd(pLengthObj, pValue, cutOption) {
        if (pLengthObj['length'] == 0) {
            pLengthObj = {
                'cutLength': 1000000,
                'length': 0,
                'begin': 0,
                'end': 0,
            }
        } else {
            pLengthObj['mid'] = pLengthObj['length'] - pLengthObj['begin'] - pLengthObj['end'];
            if (pLengthObj['mid'] <= 0) {
                pLengthObj['begin'] = pLengthObj['begin'] - 1;
                pLengthObj = this.calcBeginMidEnd(pLengthObj, pValue, cutOption);
            }
            if (pLengthObj['begin'] == 0) {
                pLengthObj['begin'] = 1
            }
        }
        return pLengthObj;
    }

    transform(value: any, cutOption: any): any {
        let result = _sh.cloneDeep(value);
        let used = _sh.get(cutOption, 'used', false);
        if (used)
            if (!_sh.isEmpty(result)) {
                let lengthObj = {
                    'length': result.length,
                    'begin': 0,
                    'end': 0
                }
                if (this.funcChina(result)) {
                    // 如果有中文
                    lengthObj['cutLength'] = _sh.get(cutOption, 'zhCutLength', 0);
                    lengthObj['begin'] = _sh.get(cutOption, 'zhBegin', 0);
                    lengthObj['end'] = _sh.get(cutOption, 'zhEnd', 0);
                } else {
                    lengthObj['cutLength'] = _sh.get(cutOption, 'enCutLength', 0);
                    lengthObj['begin'] = _sh.get(cutOption, 'enBegin', 0);
                    lengthObj['end'] = _sh.get(cutOption, 'enEnd', 0);
                }

                lengthObj = this.calcBeginMidEnd(lengthObj, result, cutOption);

                if (lengthObj['cutLength'] < result.length) { // 超过长度才cut
                    let valueArray = _sh.split(result, '');
                    let beginArray = [];
                    let endArray = [];
                    if (lengthObj['begin']) {
                        beginArray = _sh.take(valueArray, lengthObj['begin']);
                    }
                    if (lengthObj['end']) {
                        endArray = _sh.takeRight(valueArray, lengthObj['end']);
                    }
                    if (lengthObj['mid'] > 3)
                        lengthObj['mid'] = 3;

                    result = _sh.chain().concat(beginArray, _sh.repeat('*', lengthObj['mid']), endArray).join("").value();
                }
            }
        return result;
    }

}
