import {Injectable} from '@angular/core';
import {AutoInsertService} from '../../service/auto-insert.service';
import {UserInfoService} from '../../service/user-info.service';
import {RequestDataService} from '../../service/request-data.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {HttpClient} from '@angular/common/http';
import {AjaxService} from '../../service/ajax.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  user: any;

  constructor(private autoInsert: AutoInsertService,
              private userInfo: UserInfoService,
              private requestData: RequestDataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService,
              private util: UtilsService,
              private httpClient: HttpClient,
              private ajaxService: AjaxService) {
    this.user = this.userInfo.getUserInfo();
  }

  // 推送报道
  pushReport(pNews, pObject) { //推送新闻并分配小编主方法

    const user = this.userInfo.getUserInfo();

    function replaceNewLine(str) {
      const regList = [
        /<div style="?text-indent:\s*2em"?>[\r\n]\s*&nbsp;<\/div>/ig,
        /<p>[\r\n]?\s*&nbsp;\s*[\r\n]?<\/p>/ig,
        /<div>[\r\n]?\s*&nbsp;\s*[\r\n]?<\/div>/ig
      ];
      _.each(regList, function (reg) {
        str = str.replace(reg, '');
      });
      return str;
    }

    let ids = '';
    const records = [];
    _.each(pNews, (item, index) => {
      if (pNews.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
      const recordsValue = {
        'ID': 'SYS_UUID',
        'REPORT_TITLE': item['REPORT_TITLE'],
        'REPORT_AUTHOR': item['REPORT_AUTHOR'],
        'DATA_SOURCE_ID': item['DATA_SOURCE_ID'],
        'DATA_SOURCE_NAME': item['DATA_SOURCE_NAME'],
        'REPORT_SPECIFIC_SOURCE': item['REPORT_SPECIFIC_SOURCE'],
        'ORIGINAL_LINK': item['ORIGINAL_LINK'],
        'RELEASE_AREA': item['RELEASE_AREA'],
        'RELEASE_TIME': item['RELEASE_TIME'],
        'REPORT_CONTENT': replaceNewLine(item['REPORT_CONTENT']),
        'REPORT_KEYWORDS': item['REPORT_KEYWORDS'],
        'EMOTIONAL_ATTRIBUTES': item['EMOTIONAL_ATTRIBUTES'],
        'REPORT_STATE': 'STAY_INFORMED',
        'REPORT_PICTURE': item['REPORT_PICTURE'],
        'REPORT_ABSTRACT': item['REPORT_ABSTRACT'],
        'SEARCH_WORD_ID': item['SEARCH_WORD_ID'],
        'SEARCH_WORD_NAME': item['SEARCH_WORD_NAME'],
        'DOMAIN_PROJECT_ID': item['DOMAIN_PROJECT_ID'],
        'REPORT_HEAT': item['REPORT_HEAT'],
        'ADD_USERID': user.USER_ID,
        'ADD_NAME': user.USERNAME,
        'ADD_TIME': 'SYS-DATETIME',
        'U_DELETE': '1'
      };
      records.push(recordsValue);
    });
    ids = '(' + ids + ')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'edu_original_report',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'REPORT_STATE'},
            {'COLUMN': 'REPORT_PUSH_TIME'},
            {'COLUMN': 'REPORT_PUSH_USER_ID'},
            {'COLUMN': 'REPORT_PUSH_USER_NAME'}
          ],
          'RECORDS': [
            {
              'REPORT_STATE': 'PUSH',
              'REPORT_PUSH_TIME': 'SYS-DATETIME',
              'REPORT_PUSH_USER_ID': user.USER_ID,
              'REPORT_PUSH_USER_NAME': user.USERNAME,
            }
          ]
        },
        {
          'TABLE': 'edu_report',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'REPORT_TITLE'},
            {'COLUMN': 'REPORT_AUTHOR'},
            {'COLUMN': 'DATA_SOURCE_ID'},
            {'COLUMN': 'DATA_SOURCE_NAME'},
            {'COLUMN': 'REPORT_SPECIFIC_SOURCE'},
            {'COLUMN': 'ORIGINAL_LINK'},
            {'COLUMN': 'RELEASE_AREA'},
            {'COLUMN': 'RELEASE_TIME'},
            {'COLUMN': 'REPORT_CONTENT'},
            {'COLUMN': 'REPORT_KEYWORDS'},
            {'COLUMN': 'EMOTIONAL_ATTRIBUTES'},
            {'COLUMN': 'REPORT_STATE'},
            {'COLUMN': 'REPORT_PICTURE'},
            {'COLUMN': 'REPORT_ABSTRACT'},
            {'COLUMN': 'SEARCH_WORD_ID'},
            {'COLUMN': 'SEARCH_WORD_NAME'},
            {'COLUMN': 'DOMAIN_PROJECT_ID'},
            {'COLUMN': 'REPORT_HEAT'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_DELETE'}
          ],
          'RECORDS': records
        }
      ]
    };
    this.autoInsert.ExecJson(templateJson).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '推送成功'});
        pObject.needSearch = true;
      } else if (response.state === 'error') {
        this.confirmModal.show('error', {'title': '推送失败','content':response['ErrorText']});
        pObject.needSearch = true;
      }
    });
  }

  updateReport(pData) {
    const user = this.userInfo.getUserInfo();
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'edu_report',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'REPORT_STATE'},
            {'COLUMN': 'REPORT_TITLE'},
            {'COLUMN': 'REPORT_AUTHOR'},
            {'COLUMN': 'DATA_SOURCE_ID'},
            {'COLUMN': 'DOMAIN_PROJECT_ID'},
            {'COLUMN': 'DATA_SOURCE_NAME'},
            {'COLUMN': 'REPORT_SPECIFIC_SOURCE'},
            {'COLUMN': 'ORIGINAL_LINK'},
            {'COLUMN': 'RELEASE_AREA'},
            {'COLUMN': 'RELEASE_TIME'},
            {'COLUMN': 'REPORT_CONTENT'},
            {'COLUMN': 'REPORT_KEYWORDS'},
            {'COLUMN': 'EMOTIONAL_ATTRIBUTES'},
            {'COLUMN': 'SEARCH_WORD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'}
          ],
          'RECORDS': [
            {
              'ID': pData['ID'],
              'REPORT_STATE': 'PROCESSED',
              'REPORT_TITLE': pData['REPORT_TITLE'],
              'REPORT_AUTHOR': pData['REPORT_AUTHOR'],
              'DATA_SOURCE_ID': pData['DATA_SOURCE_ID'],
              'DOMAIN_PROJECT_ID': pData['DOMAIN_PROJECT_ID'],
              'DATA_SOURCE_NAME': pData['DATA_SOURCE_NAME'],
              'REPORT_SPECIFIC_SOURCE': pData['REPORT_SPECIFIC_SOURCE'],
              'ORIGINAL_LINK': pData['ORIGINAL_LINK'],
              'RELEASE_AREA': pData['RELEASE_AREA'],
              'RELEASE_TIME': pData['RELEASE_TIME'],
              'REPORT_CONTENT': pData['REPORT_CONTENT'],
              'REPORT_KEYWORDS': pData['REPORT_KEYWORDS'],
              'EMOTIONAL_ATTRIBUTES': pData['EMOTIONAL_ATTRIBUTES'],
              'SEARCH_WORD_NAME': pData['SEARCH_WORD_NAME'],
              'UPD_USERID': user.USER_ID,
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 内容编缉管理列表页提交审核按钮
  submitAudit(selectedList, pObject) {
    const user = this.userInfo.getUserInfo();
    let ids = '';
    let ok = true;
    _.each(selectedList, function (item, index) {
      if (item['REPORT_STATE'] !== 'PROCESSED') {
        ok = false;
        return false;
      }
      if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
    });
    if (!ok) {
      return this.confirmModal.show('warning', {title: '已编缉的报道才可以提交审核。'});
    }
    ids = '(' + ids + ')';
    this.confirmModal.show('confirm', {
      title: '您确定提交审核吗？',
      suc: () => {
        const templateJson = {
          'LIST': [
            {
              'TABLE': 'edu_report',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': ids,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'REPORT_STATE'}
              ],
              'RECORDS': [
                {
                  'REPORT_STATE': 'NO_AUDIT'
                }
              ]
            }
          ]
        };
        this.autoInsert.ExecJson(templateJson).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {'title': '提交成功成功'});
            pObject.needSearch = true;
          }
        });
      }
    });
  }

  cancelAudit(selectedList, pObject) {
    let ids = '';
    _.each(selectedList, (item, index) => {
      if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
    });
    ids = '(' + ids + ')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'edu_report',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'REPORT_STATE'},
            {'COLUMN': 'REPORT_AUDIT_TIME'},
            {'COLUMN': 'REPORT_AUDIT_USER_ID'},
            {'COLUMN': 'REPORT_AUDIT_USER_NAME'}
          ],
          'RECORDS': [
            {
              'REPORT_STATE': 'NO_PASS',
              'REPORT_AUDIT_TIME': 'SYS-DATETIME',
              'REPORT_AUDIT_USER_ID': this.user.USER_ID,
              'REPORT_AUDIT_USER_NAME': this.user.USERNAME
            }
          ]
        }
      ]
    };
    this.autoInsert.ExecJson(templateJson).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '取消成功'});
        pObject.needSearch = true;
      } else {
        this.confirmModal.show('error', {'title': '取消失败，请刷新重试'});
      }
    });
  }

  // 待审核新闻审核通过按钮
  adopt(selectList, pObject) {
    let ids = '';
    let ok = true;
    let idsArray = [];
    _.each(selectList, (item, index) => {
      if (item['REPORT_STATE'] !== 'NO_AUDIT') {
        ok = false;
      } else if (selectList.length === index + 1) {
        idsArray.push(item['ID']);
        ids += '\'' + item['ID'] + '\'';
      } else {
        idsArray.push(item['ID']);
        ids += '\'' + item['ID'] + '\',';
      }
    });
    ids = '(' + ids + ')';
    if (ok) {
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'edu_report',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'REPORT_STATE'},
              {'COLUMN': 'REPORT_AUDIT_TIME'},
              {'COLUMN': 'REPORT_AUDIT_USER_ID'},
              {'COLUMN': 'REPORT_AUDIT_USER_NAME'},
              {'COLUMN': 'REPORT_SHELF_TIME'},
              {'COLUMN': 'REPORT_SHELF_USER_ID'},
              {'COLUMN': 'REPORT_SHELF_USER_NAME'}
            ],
            'RECORDS': [
              {
                'REPORT_STATE': 'PUTAWAY',
                'REPORT_AUDIT_TIME': 'SYS-DATETIME',
                'REPORT_AUDIT_USER_ID': this.user.USER_ID,
                'REPORT_AUDIT_USER_NAME': this.user.USERNAME,
                'REPORT_SHELF_TIME': 'SYS-DATETIME',
                'REPORT_SHELF_USER_ID': this.user.USER_ID,
                'REPORT_SHELF_USER_NAME': this.user.USERNAME
              }
            ]
          }
        ]
      };
      const queue = {
        'TABLE': 'edu_order_queue',
        'ACTION': 'Insert',
        'WHEREGROUP': [],
        'WHERE': [],
        'COLUMNS': [
          {'COLUMN': 'ID'},
          {'COLUMN': 'MAIN_ID'},
          {'COLUMN': 'SEND_METHOD'},
          {'COLUMN': 'SEND_FLAG'},
          {'COLUMN': 'ADD_USERID'},
          {'COLUMN': 'ADD_TIME'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'U_DELETE'}
        ],
        'RECORDS': []
      };
      if (this.util.Base_HasValue(idsArray)) {
        for (const one of idsArray) {
          queue.RECORDS.push(
            {
              'ID': 'SYS-UUID',
              'MAIN_ID': one,
              'SEND_METHOD': 'insert_update',
              'SEND_FLAG': 'init',
              'ADD_TIME': 'SYS-DATETIME',
              'ADD_USERID': this.user.USER_ID,
              'ADD_NAME': this.user.USERNAME,
              'U_DELETE': 1
            });
        }
        templateJson.LIST.push(queue);
      }
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '审核成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '审核失败，请刷新重试'});
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '待审核数据才可进行通过审核操作'});
    }
  }

  reject(selectedList, pObject, result) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      const assignObj = result.modalFormData.columns[0];
      let ids = '';
      const idsArray = [];
      _.each(selectedList, (item, index) => {
        if (selectedList.length === index + 1) {
          ids += '\'' + item['ID'] + '\'';
        } else {
          ids += '\'' + item['ID'] + '\',';
        }
        idsArray.push(item['ID']);
      });
      ids = '(' + ids + ')';
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'edu_report',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'REPORT_STATE'},
              {'COLUMN': 'REPORT_SHELVES_DESC'}
            ],
            'RECORDS': [
              {
                'REPORT_STATE': 'SOLD_OUT',
                'REPORT_SHELVES_DESC': assignObj.model
              }
            ]
          }
        ]
      };
      const queue = {
        'TABLE': 'edu_order_queue',
        'ACTION': 'Insert',
        'WHEREGROUP': [],
        'WHERE': [],
        'COLUMNS': [
          {'COLUMN': 'ID'},
          {'COLUMN': 'MAIN_ID'},
          {'COLUMN': 'SEND_METHOD'},
          {'COLUMN': 'SEND_FLAG'},
          {'COLUMN': 'ADD_USERID'},
          {'COLUMN': 'ADD_TIME'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'U_DELETE'}
        ],
        'RECORDS': []
      };
      if (this.util.Base_HasValue(idsArray)) {
        for (const one of idsArray) {
          queue.RECORDS.push(
            {
              'ID': 'SYS-UUID',
              'MAIN_ID': one,
              'SEND_METHOD': 'delete',
              'SEND_FLAG': 'init',
              'ADD_TIME': 'SYS-DATETIME',
              'ADD_USERID': this.user.USER_ID,
              'ADD_NAME': this.user.USERNAME,
              'U_DELETE': 1
            });
        }
        templateJson.LIST.push(queue);
      }
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '取消成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '取消失败，请刷新重试'});
        }
      });
    } else {
      return false;
    }
  }

  // 审核通过管理再次上架
  releaseReport(selectList, pObject) {
    let ids = '';
    const idsArray = [];
    _.each(selectList, (item, index) => {
      if (selectList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
      idsArray.push(item['ID']);
    });
    ids = '(' + ids + ')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'edu_report',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'REPORT_STATE'},
            {'COLUMN': 'REPORT_SHELF_TIME'},
            {'COLUMN': 'REPORT_SHELF_USER_ID'},
            {'COLUMN': 'REPORT_SHELF_USER_NAME'}
          ],
          'RECORDS': [
            {
              'REPORT_STATE': 'PUTAWAY',
              'REPORT_SHELF_TIME': 'SYS-DATETIME',
              'REPORT_SHELF_USER_ID': this.user.USER_ID,
              'REPORT_SHELF_USER_NAME': this.user.USERNAME
            }
          ]
        }
      ]
    };
    const queue = {
      'TABLE': 'edu_order_queue',
      'ACTION': 'Insert',
      'WHEREGROUP': [],
      'WHERE': [],
      'COLUMNS': [
        {'COLUMN': 'ID'},
        {'COLUMN': 'MAIN_ID'},
        {'COLUMN': 'SEND_METHOD'},
        {'COLUMN': 'SEND_FLAG'},
        {'COLUMN': 'ADD_USERID'},
        {'COLUMN': 'ADD_TIME'},
        {'COLUMN': 'ADD_NAME'},
        {'COLUMN': 'U_DELETE'}
      ],
      'RECORDS': []
    };
    if (this.util.Base_HasValue(idsArray)) {
      for (const one of idsArray) {
        queue.RECORDS.push(
          {
            'ID': 'SYS-UUID',
            'MAIN_ID': one,
            'SEND_METHOD': 'insert_update',
            'SEND_FLAG': 'init',
            'ADD_TIME': 'SYS-DATETIME',
            'ADD_USERID': this.user.USER_ID,
            'ADD_NAME': this.user.USERNAME,
            'U_DELETE': 1
          });
      }
      templateJson.LIST.push(queue);
    }
    this.autoInsert.ExecJson(templateJson).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '上架成功'});
        pObject.needSearch = true;
      } else {
        this.confirmModal.show('error', {'title': '上架失败，请刷新重试'});
      }
    });
  }

  // 绑定事件
  bindingEvent(selectedList, pObject, result) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      const assignObj = result.modalFormData.columns[0]['model'].split(',');
      let reportIds = '';
      let incidentIds = '';
      const records = [];
      const idsArray = [];
      _.each(selectedList, (item, index) => {
        if (selectedList.length === index + 1) {
          reportIds += '\'' + item['ID'] + '\'';
        } else {
          reportIds += '\'' + item['ID'] + '\',';
        }
        idsArray.push(item['ID']);
        _.each(assignObj, (oneEvent) => {
          const data = {
            'ID': 'SYS_UUID',
            'INCIDENT_ID': oneEvent,
            'REPORT_ID': item['ID']
          };
          records.push(data);
        });
      });
      reportIds = '(' + reportIds + ')';
      _.each(assignObj, (item, index) => {
        if (assignObj.length === index + 1) {
          incidentIds += '\'' + item + '\'';
        } else {
          incidentIds += '\'' + item + '\',';
        }
      });
      incidentIds = '(' + incidentIds + ')';
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'edu_report_incident',
            'ACTION': 'Delete',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [
              {
                'COLUMN': 'INCIDENT_ID',
                'VALUE': incidentIds,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              },
              {
                'COLUMN': 'REPORT_ID',
                'VALUE': reportIds,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }
            ],
            'COLUMNS': [
              {'COLUMN': 'INCIDENT_ID'},
              {'COLUMN': 'REPORT_ID'}
            ],
            'RECORDS': [
              {
                'INCIDENT_ID': incidentIds,
                'REPORT_ID': reportIds
              }
            ]
          },
          {
            'TABLE': 'edu_report_incident',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'INCIDENT_ID'},
              {'COLUMN': 'REPORT_ID'}
            ],
            'RECORDS': records
          }
        ]
      };
      const queue = {
        'TABLE': 'edu_order_queue',
        'ACTION': 'Insert',
        'WHEREGROUP': [],
        'WHERE': [],
        'COLUMNS': [
          {'COLUMN': 'ID'},
          {'COLUMN': 'MAIN_ID'},
          {'COLUMN': 'SEND_METHOD'},
          {'COLUMN': 'SEND_FLAG'},
          {'COLUMN': 'ADD_USERID'},
          {'COLUMN': 'ADD_TIME'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'U_DELETE'}
        ],
        'RECORDS': []
      };
      if (this.util.Base_HasValue(idsArray)) {
        for (const one of idsArray) {
          queue.RECORDS.push(
            {
              'ID': 'SYS-UUID',
              'MAIN_ID': one,
              'SEND_METHOD': 'insert_update',
              'SEND_FLAG': 'init',
              'ADD_TIME': 'SYS-DATETIME',
              'ADD_USERID': this.user.USER_ID,
              'ADD_NAME': this.user.USERNAME,
              'U_DELETE': 1
            });
        }
        templateJson.LIST.push(queue);
      }
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '绑定成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '绑定失败，请刷新重试'});
        }
      });
    } else {
      return false;
    }
  }

  // 取消绑定事件
  cancelEvent(selectedList, pObject) {
    let reportIds = '';
    const idsArray = [];
    _.each(selectedList, (item, index) => {
      if (selectedList.length === index + 1) {
        reportIds += '\'' + item['ID'] + '\'';
      } else {
        reportIds += '\'' + item['ID'] + '\',';
      }
      idsArray.push(item['ID']);
    });
    reportIds = '(' + reportIds + ')';
    this.confirmModal.show('confirm', {
      title: '您确定解除这些报道绑定的事件吗？',
      suc: () => {
        const templateJson = {
          'LIST': [
            {
              'TABLE': 'edu_report_incident',
              'ACTION': 'Delete',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [
                {
                  'COLUMN': 'REPORT_ID',
                  'VALUE': reportIds,
                  'OPERATOR': 'in',
                  'VALUE_TYPE': 'int',
                  'CONDITION_OPERATOR': 'AND',
                  'GROUP': 'GROUP_01'
                }
              ],
              'COLUMNS': [
                {'COLUMN': 'REPORT_ID'}
              ],
              'RECORDS': [
                {
                  'REPORT_ID': reportIds
                }
              ]
            }
          ]
        };
        const queue = {
          'TABLE': 'edu_order_queue',
          'ACTION': 'Insert',
          'WHEREGROUP': [],
          'WHERE': [],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'MAIN_ID'},
            {'COLUMN': 'SEND_METHOD'},
            {'COLUMN': 'SEND_FLAG'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'U_DELETE'}
          ],
          'RECORDS': []
        };
        if (this.util.Base_HasValue(idsArray)) {
          for (const one of idsArray) {
            queue.RECORDS.push(
              {
                'ID': 'SYS-UUID',
                'MAIN_ID': one,
                'SEND_METHOD': 'insert_update',
                'SEND_FLAG': 'init',
                'ADD_TIME': 'SYS-DATETIME',
                'ADD_USERID': this.user.USER_ID,
                'ADD_NAME': this.user.USERNAME,
                'U_DELETE': 1
              });
          }
          templateJson.LIST.push(queue);
        }
        this.autoInsert.ExecJson(templateJson).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {'title': '取消成功'});
          } else {
            this.confirmModal.show('error', {'title': '操作失败，请刷新重试'});
          }
        });
      }
    });
  }

}
