import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../../service/request-data.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import {AppConfigService} from "../../../service/app-config.service";
import {NzFormatEmitEvent} from "ng-zorro-antd";
import {moveItemInArray, CdkDragDrop} from '@angular/cdk/drag-drop';
import * as _ from "underscore";
import {UserInfoService} from "../../../service/user-info.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {DataCenterService} from "../../../service/data-center.service";

@Component({
  selector: 'app-edit-book',
  templateUrl: './edit-book.component.html',
  styleUrls: ['./edit-book.component.scss'],
  styles: [
    `
      nz-select {
        width: 200px;
      }
    `
  ]
})
export class EditBookComponent implements OnInit {

  @Input() parentData: any;
  private check: boolean;

  constructor(
    private requestService: RequestDataService,
    private appConfigService: AppConfigService,
    private userInfoService: UserInfoService,
    private confirmModal: ConfirmModalService,
    public dataCenterService: DataCenterService,
  ) {
    this.user = userInfoService.getUserInfo();

  }

  public user: any;

  defaultSelectedKeys = ['62DD2FA90AAA73E6818D0311DF0A755F,4F21109BA57C4322017467EFC920509B'];
  nodes = [
    {
      title: '港澳台教育动态',
      key: '62DD2FA90AAA73E6818D0311DF0A755F',
      expanded: true,
      children: [
        {
          title: '官方视野',
          key: '62DD2FA90AAA73E6818D0311DF0A755F,4F21109BA57C4322017467EFC920509B',
          isLeaf: true
        },
        {
          title: '民间观点',
          key: '62DD2FA90AAA73E6818D0311DF0A755F,3C4A4B1503DB8206162242C9E088D3ED',
          isLeaf: true
        },
        {
          title: '高校动态',
          key: '62DD2FA90AAA73E6818D0311DF0A755F,3AF50FF12AC4CA0C481F77E438217632',
          isLeaf: true
        }
      ]
    },
    {
      title: '两岸教育动态',
      key: '344127958AF6C478ED04E05567FB96FB',
      expanded: true,
      children: [
        {
          title: '官方视野',
          key: '344127958AF6C478ED04E05567FB96FB,C680AEBDDB69E7491FF6A161FEAA0732',
          isLeaf: true
        },
        {
          title: '民间观点',
          key: '344127958AF6C478ED04E05567FB96FB,70BF760D59362575424DAF660979BF8C',
          isLeaf: true
        },
        {
          title: '基础教育动态',
          key: '344127958AF6C478ED04E05567FB96FB,91A2706C23EEA4CE3BA2E06F5D75C6A5',
          isLeaf: true
        }
      ]
    },
  ];
  //左右资料ID
  public leftDatas = [];
  public rightDatas = [];
  //简报ID
  public mainId;


  /**
   * 左边
   */
  public allCheckedLeft = false;
  public indeterminateLeft = false;

  // 分页下标
  public pageIndexLeft: any = 1;
  // 每页显示数据
  public pageSizeLeft: any = this.appConfigService.config.pageCount;
  // 总条数
  public totalLeft: any = 1;
  public nzPageSizeOptionsLeft: any = [10, 20, 30, 50, 100, 200];

  // public allChecked = false;
  // public indeterminate = false;
  /**
   * 右边
   */
  public allCheckedRight = false;
  public indeterminateRight = false;

  // 分页下标
  public pageIndexRight: any = 1;
  // 每页显示数据
  public pageSizeRight: any = this.appConfigService.config.pageCount;
  // 总条数
  public totalRight: any = 1;
  public nzPageSizeOptionsRight: any = [10, 20, 30, 50, 100, 200];

  //当前栏目ID
  public columnNameId = "";
  //左右搜索栏的内容
  public leftSearchValue;
  public rightSearchValue;

  ngOnInit() {
    this.mainId = this.parentData.id;
    this.columnNameId = this.nodes[0]['children'][0]['key'];
    this.getDataOne(this.columnNameId);
  }

  nzClick(event: NzFormatEmitEvent): void {
    this.columnNameId = event['keys'][0];
    this.getData(this.columnNameId);
  }

  //第一次获取数据，加载nodes数据，单独触发。
  getDataOne(condition) {
    if (condition == null) {
      //默认查询港澳台教育动态/官方视野
      condition = this.nodes[0]['children'][0]['key']
    }
    const conditionPart = {
      COLUMN_NAME_ID: condition,
      MAIN_ID: this.mainId
    };
    const conditionAll = {
      COLUMN_NAME_ID: condition
    };
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/simulationTrain/ig/info/ig_briefing_sheet_item_list.json', conditionPart),
      this.requestService.getPaginationData('/simulationTrain/ig/info/ig_assets_unused_list.json', conditionAll, this.pageIndexRight, this.pageSizeRight),
      this.requestService.getDataByCondition('/simulationTrain/ig/info/ig_section_tree.json', {}),
    );
    search01$.subscribe(
      values => {
        //该简报在该分栏下的所有资料
        this.leftDatas = _sh.get(values, [0, 'data'], []);
        //资料库中，审核通过，该分栏下的，未被使用的资料
        this.rightDatas = _sh.get(values, [1, 'data'], []);

        this.totalRight = _sh.get(values, [1, 'page', 'rowCount'], 0);
        this.pageIndexRight = _sh.get(values, [1, 'page', 'pageNum'], 0);

        let befData = [];
        befData = _sh.get(values, [2, 'data'], []);
        let lv01Data = [];
        _sh.each(befData, oneRow => {
          if (oneRow['ORG_LV'] == 1) {
            let temp = {
              title: oneRow['ORG_NAME'],
              key: oneRow['ID'],
              expanded: true
            }
            lv01Data.push(temp);
          }
        })

        _sh.each(lv01Data, oneLv01 => {
          let children = [];
          _sh.each(befData, oneRow => {
            if (oneRow['ORG_LV'] == 2 && oneLv01['key'] == oneRow['UP_ORG_ID']) {
              let temp = {
                title: oneRow['ORG_NAME'],
                key: oneRow['LINK_ID'],
                isLeaf: true
              }
              children.push(temp);
            }
          })
          oneLv01['children'] = children;
        })
        this.nodes = lv01Data;
      }
    );
  }

  //每次更新数据
  getData(condition) {
    if (condition == null) {
      //默认查询港澳台教育动态/官方视野
      condition = this.nodes[0]['children'][0]['key']
    }
    const conditionPart = {
      COLUMN_NAME_ID: condition,
      MAIN_ID: this.mainId
    };
    const conditionAll = {
      COLUMN_NAME_ID: condition
    };
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/simulationTrain/ig/info/ig_briefing_sheet_item_list.json', conditionPart),
      this.requestService.getPaginationData('/simulationTrain/ig/info/ig_assets_unused_list.json', conditionAll, this.pageIndexRight, this.pageSizeRight),
      this.requestService.getDataByCondition('/simulationTrain/ig/info/ig_section_tree.json', {}),
    );
    search01$.subscribe(
      values => {
        //该简报在该分栏下的所有资料
        this.leftDatas = _sh.get(values, [0, 'data'], []);
        //资料库中，审核通过，该分栏下的，未被使用的资料
        this.rightDatas = _sh.get(values, [1, 'data'], []);

        this.totalRight = _sh.get(values, [1, 'page', 'rowCount'], 0);
        this.pageIndexRight = _sh.get(values, [1, 'page', 'pageNum'], 0);
      }
    );
  }

  // refreshStatus(): void {
  //   const allChecked = this.leftDatas.length > 0 && this.leftDatas.every(value => value.checked === true);
  //   const allUnChecked = this.leftDatas.every(value => !value.checked);
  //   this.allChecked = allChecked;
  //   this.indeterminate = (!allChecked) && (!allUnChecked);
  // }


  // checkAll(value: boolean): void {
  //   this.leftDatas.forEach(data => {
  //     data['checked'] = value;
  //   });
  //   this.refreshStatus();
  // }


  refreshStatusRight(): void {
    const allChecked = this.rightDatas.length > 0 && this.rightDatas.every(value => value.checked === true);
    const allUnChecked = this.rightDatas.every(value => !value.checked);
    this.allCheckedRight = allChecked;
    this.indeterminateRight = (!allChecked) && (!allUnChecked);
  }


  checkAllRight(value: boolean): void {
    this.rightDatas.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusRight();
  }


  searchFilterRight(reset: boolean = true) {
    const condition = {
      COLUMN_NAME_ID: this.columnNameId,
      TITLE: this.rightSearchValue,
      CONTENT: this.rightSearchValue
    };
    this.searchDataRight(reset, condition);
  }

  searchDataRight(reset: boolean, condition?: {}) {
    if (reset) {
      this.pageIndexRight = 1;
    }
    const search01$ = observableForkJoin(
      this.requestService.getPaginationData('/simulationTrain/ig/info/ig_assets_unused_list.json', condition, this.pageIndexRight, this.pageSizeRight),
    );
    search01$.subscribe(
      values => {
        this.rightDatas = _sh.get(values, [0, 'data'], []);
        this.totalRight = _sh.get(values, [0, 'page', 'rowCount'], 0);
        this.pageIndexRight = _sh.get(values, [0, 'page', 'pageNum'], 0);
      }
    );
  }


  refreshStatusLeft(): void {
    const allChecked = this.leftDatas.length > 0 && this.leftDatas.every(value => value.checked === true);
    const allUnChecked = this.leftDatas.every(value => !value.checked);
    this.allCheckedLeft = allChecked;
    this.indeterminateLeft = (!allChecked) && (!allUnChecked);
  }


  checkAllLeft(value: boolean): void {
    this.leftDatas.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusLeft();
  }


  searchFilterLeft(reset: boolean = true) {
    const condition = {
      COLUMN_NAME_ID: this.columnNameId,
      TITLE: this.leftSearchValue,
      CONTENT: this.leftSearchValue
    };
    this.searchDataLeft(reset, condition);
  }

  private searchDataLeft(reset: boolean, condition?: {}) {
    if (reset) {
      this.pageIndexLeft = 1;
    }
    const search01$ = observableForkJoin(
      this.requestService.getDataByCondition('/simulationTrain/ig/info/ig_briefing_sheet_item_list.json', condition),
    );
    search01$.subscribe(
      values => {
        this.leftDatas = _sh.get(values, [0, 'data'], []);
        this.totalLeft = _sh.get(values, [0, 'page', 'rowCount'], 0);
        this.pageIndexLeft = _sh.get(values, [0, 'page', 'pageNum'], 0);
      }
    );
  }

  addAssets() {
    let check = true;
    //右边选中的
    let checkData = this.rightDatas.filter((e) => {
      return e.checked == true;
    })
    if (checkData.length == 0) {
      this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': "请选择资料库的资料。"});
      check = false;
    }
    if (check) {
      let idList = [];
      //左边原有的
      this.leftDatas.forEach((item) => {
        idList = _sh.concat(idList, item['ID'])
      })
      //右边选中的
      checkData.forEach((item) => {
        idList = _sh.concat(idList, item['ID'])
      });
      let assetsId = _sh.join(idList, ',');

      let queryData = {
        'briefId': this.mainId,
        'assetsId': assetsId,
        'userId': this.user.USER_ID,
        'columnNameId': this.columnNameId
      };

      this.requestService.doActionByHdbsthor('AppApi/IgBriefingSheetController', 'addOrDeleteAssetsToBrief', queryData, {}, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
        response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
          } else {
            this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': response.error.message});
          }
          this.getData(this.columnNameId);
        }
      );
    }
  }

  deleteAssets() {
    let check = true;
    let checkData = this.leftDatas.filter((e) => {
      return e.checked != true;
    })
    if (this.leftDatas.length - checkData.length == 0) {
      this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': "请选择简报下的资料。"});
      check = false;
    }
    if (check) {
      let idList = [];
      //左边未选中的
      checkData.forEach((item) => {
        idList = _sh.concat(idList, item['ID'])
      });
      let assetsId = _sh.join(idList, ',');

      let queryData = {
        'briefId': this.mainId,
        'assetsId': assetsId,
        'userId': this.user.USER_ID,
        'columnNameId': this.columnNameId
      };

      this.requestService.doActionByHdbsthor('AppApi/IgBriefingSheetController', 'addOrDeleteAssetsToBrief', queryData, {}, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
        response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
          } else {
            this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': response.error.message});
          }
          this.getData(this.columnNameId);
        }
      );
    }
  }

  jumpDetail(data: any) {
    let id = data['ID'];
    let that = this;
    let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
      return item['parentDataId'] === id;
    });

    if (tabIndex > 0) {
      this.dataCenterService._dataObject.index = tabIndex;
      this.dataCenterService._dataObject.nowMenu = id;
      this.dataCenterService.refreshDataCenterInStorage();
    } else {
      const searchAll = observableForkJoin(
        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': '2D02A2336AA6E7C5473800BBBB16CAD9'}),
        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/menu_name.json', {'MENU_ID': '2D02A2336AA6E7C5473800BBBB16CAD9'})
      );
      searchAll.subscribe(
        values => {
          let nowMenu = _sh.get(values, [1, 'data', 0], {});
          const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);
          that.dataCenterService.newTab({
            id: id, // 主键
            parentDataId: id, // 唯一标识
            nowMenu: nowMenu,
            searchId: searchId,
            title: "资料标题：" + data['TITLE'], // 菜单名称 用户tab展示
            formType: 'readOnlyForm',
            formClass: 'readOnlyForm',
            data: {}, // 查询返回数据
            detailCondition: {
              'ID': id // 传入主键
            }
          });
        }
      );
    }
  };


  isVisible = false;
  selectedValue = null;
  inputValue: string;


  showModal(data: any) {
    this.isVisible = true;
  };

  handleOk(): void {
    this.isVisible = false;


    let check = false;
    // 右边选中的
    let checkData = this.rightDatas.filter((e) => {
      return e.checked == true;
    })

    // 左边选中的
    let checkLeftData = this.leftDatas.filter((e) => {
      return e.checked == true;
    })



    // 右边
    if (checkData.length >= 1) {
      let idList = [];
      //左边原有的
      this.leftDatas.forEach((item) => {
        idList = _sh.concat(idList, item['ID'])
      })

      //右边选中的
      checkData.forEach((item) => {
        idList = item['ID']
      });
      let assetsId = checkData[0].ID

      let queryData = {
        'state': this.selectedValue,
        'reasonForApproval': this.inputValue,
        'userId': this.user.USER_ID,
        'id': assetsId
      };

      this.requestService.doActionByHdbsthor('AppApi/IgAssetsController', 'rollbackFromPresentationManagement', queryData,
        1, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
        response => {
        }
      );
    } else {

      let idList = [];

      // 左边选中的
      checkLeftData.forEach((item) => {
        idList = item['ID']
      });
      let assetsId = checkLeftData[0].ID

      let queryData = {
        'state': this.selectedValue,
        'reasonForApproval': this.inputValue,
        'userId': this.user.USER_ID,
        'id': assetsId
      };

      this.requestService.doActionByHdbsthor('AppApi/IgAssetsController', 'rollbackFromPresentationManagement', queryData,
        1, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
        response => {
        }
      );
    }
  };


  handleCancel(): void {
    this.isVisible = false;
  };


  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.leftDatas, event.previousIndex, event.currentIndex);

    let idList = [];
    this.leftDatas.forEach((item) => {
      idList = _sh.concat(idList, item['ID'])
    })
    let assetsId = _sh.join(idList, ',');

    let queryData = {
      'briefId': this.mainId,
      'assetsId': assetsId,
      'userId': this.user.USER_ID,
      'columnNameId': this.columnNameId
    };

    this.requestService.doActionByHdbsthor('AppApi/IgBriefingSheetController', 'addOrDeleteAssetsToBrief', queryData, {}, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
      response => {
        if (response.toString() === 'true') {
          // this.confirmModal.show('success', {'title': '执行成功'});
        } else {
          this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': response.error.message});
        }
        this.getData(this.columnNameId);
      }
    );

  }

  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  usedAndPublish() {
    let queryData = {
      'briefId': this.mainId,
      'userId': this.user.USER_ID,
    };
    this.requestService.doActionByHdbsthor('AppApi/IgBriefingSheetController', 'publishBrief', queryData, {}, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(
      response => {
        if (response.toString() === 'true') {
          this.confirmModal.show('success', {'title': '执行成功'});
          this.gotoBack();
        } else {
          this.confirmModal.show('error', {'title': '执行错误，原因如下', 'content': response.error.message});
        }
      }
    );
  }
}
