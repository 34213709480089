import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'lodash';
import * as alasql from 'alasql';
import {DataService} from '../data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';

@Component({
  selector: 'app-resource-group-detail',
  templateUrl: './resource-group-detail.component.html',
  styleUrls: ['./../resource-group-management/resource-group-management.component.css', './resource-group-detail.component.css']
})
export class ResourceGroupDetailComponent implements OnInit {
  //父级组件的数据对象
  @Input() parentData: any;
  labelsList = [];
  data: any = {
    resourcesName: '',
    siteId: '',
    channelId: '',
    labels: null,
    modelId: '',
    resourcesPicUrl: '',
    childResourcesIds: [],
    fileList: []
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;

  showModelImg = false;
  showModelImgUrl = '';
  basicModelList = [];

  tempArr = [];

  addModel = false;

  modelList = [];
  nowModel = {
    code: ''
  };
  models: any = [
    {modelName: null}
  ]; // 扩展模型
  _validateForm: FormGroup;
  selectedIndex = 0;

  constructor(private requestData: RequestDataService,
              private dataService: DataService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService,
              private formBuilder: FormBuilder,
              private modalService: NzModalService) {
  }

  // 切换扩展模型
  checkModel(oneData) {
    this.nowModel = oneData;
    const index = _.findIndex(this.models, (item) => {
      return item['modelId'] === this.nowModel['modelId'];
    });
    this.selectedIndex = index;
  }

  // 返回
  gotoBack(): void {
    this.transformData('customForm', this.parentData);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-grouping-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    const validatorList = [];
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    // if (this.utils.Base_HasValue(oneColumn.maxLength)) {
    //   validatorList.push(Validators.maxLength(oneColumn.maxLength));
    // }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    return new FormControl('', validatorList);
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    const group = {};
    _.each(this.models, model => {
      _.each(model['groups'], (item) => {
        const array = [];
        // 如果是文件上传需要将column添加到form里
        // 其他类型则按照data添加
        array.push(this.createItem(item));
        group[item['groupId']] = this.formBuilder.array(array);
      });
    });
    return this.formBuilder.group(group);
  }

  private createItem(group): FormGroup {
    const arrayGroup = {};
    _.each(group['columns'], column => {
      arrayGroup[column['column']] = this.addOneColumn(column);
    });
    return this.formBuilder.group(arrayGroup);
  }

  // 初始化数据。将group某个组没有data的默认给上值
  initModelData(modelList) {
    _.each(modelList, (model, index) => {
      if (index === 0) {
        model['active'] = true;
      }
      _.each(model['groups'], group => {
        // if (!this.utils.Base_HasValue(group['data']) && !_.contains(['pictureModel', 'fileModel'], group['type'])) {
        //   const data = {};
        //   _.each(group['columns'], column => {
        //     data[column['column']] = column['component'] === 'select' ? null : '';
        //   });
        //   data['row'] = 0;
        //   group['data'] = [data];
        // }
        _.each(group['columns'], column => {
          column['name'] = column['column'];
          column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
          switch (column['component']) {
            case 'input':
            case 'textarea':
              column['placeholder'] = '请输入' + column['columnName'];
              break;
            case 'date':
            case 'datetime':
            case 'poplist':
            case 'multipop':
            case 'multiSelect':
              if (column['column'] === 'keywordsName') {
                column['nzMode'] = 'tags';
              } else {
                column['nzMode'] = 'multiple';
              }
            case 'select':
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              column['placeholder'] = '请选择' + column['columnName'];
              column['comment'] = column['columnName'];
              column['lable'] = column['columnName'];
              if (column['columnGroup']) {
                _.each(column['columnGroup'], col => {
                  col['comment'] = col['columnName'];
                  col['lable'] = col['columnName'];
                });
              }
              break;
            case 'dateRange':
              column['placeholder'] = column['columnName'].split(',');
              column['comment'] = group['groupName'];
              column['lable'] = group['groupName'];
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              break;
          }
        });
      });
    });
    return modelList;
  }

  modifyDataFormat(data) {
    const jsonP = JSON.parse(data['RESOURCES_JSON']);
    this.models = jsonP.models;
    this.nowModel = jsonP.models[0];
    this.selectedIndex = 0;
    // this.models = this.initModelData(this.models);
    this._validateForm = this.addOneForm();
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json', {RESOURCES_TYPE: 'GROUP'})
    );
    search.subscribe(
      values => {
        this.siteList = values[0]['data'] || [];
        this.basicModelList = values[1]['data'] || [];
        this.requestData.getDataByCondition('digitalResources/dr/dr_group_list.json', {RESOURCES_ID: this.parentData.RESOURCES_ID}).subscribe(response => {
          this.data = response.data[0];
          this.modifyDataFormat(this.data);
        });
      }
    );
  }

}
