import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {UtilsService} from '../../service/utils.service';
import {RequestDataService} from '../../service/request-data.service';
import {AppConfigService} from '../../service/app-config.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _sh from 'lodash';
import * as _ from 'underscore';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {CascaderOption} from 'ng-zorro-antd/cascader';

@Component({
  selector: 'app-cascader',
  templateUrl: './cascader.component.html',
  styleUrls: ['./cascader.component.css']
})
export class CascaderComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;
  @Input() forms: any;
  @Input() oneForms: any;

  // 查询数据
  public conditions;
  public conditionList: any[] = [];
  public datas: any;
  public listDatas: any;

  constructor(
    private requestDataService: RequestDataService,
    private appConfigService: AppConfigService,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService
  ) {
  }

  // 移动数据 model - > data
  moveModelToData(): void {
    _.each(this.forms, OneGroup => {
      if (this.utils.Base_compare(OneGroup['type'], 'normal')) {
        if (!_.has(OneGroup, 'data')) {
          OneGroup['data'] = [];
        }
        OneGroup['data'][0] = {}; // 清空
        _.each(OneGroup['columns'], function (OneColumn) {
          OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
        });
      } else {
        const oneForm = this.oneForms;
        if (this.utils.Base_compare(OneGroup['tablename'], oneForm.tablename)) {
          if (!_.has(OneGroup, 'data')) {
            OneGroup['data'] = [];
          }
          OneGroup['data'][0] = {}; // 清空
          _.each(oneForm.columns, function (OneColumn) {
            OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
          });
        }
      }
    });
  }

  //查找目标值
  findValue(pInsertValue, pErrormsg) {
    const that = this;

    function findTarget(targetTable, targetColum) {
      let tmpValues = null;
      for (let i = 0; i < that.forms.length; i++) {
        if (that.utils.Base_compare(that.forms[i].tablename, targetTable)) {
          tmpValues = that.utils.Base_getJsonValue(that.forms[i].data[0], targetColum, null);
          if (that.utils.Base_HasValue(tmpValues)) {
            break;
          }
        }
      }
      return tmpValues;
    }

    let tmp = pInsertValue;
    if (that.utils.Base_HasValue(pInsertValue)) {
      const targetParam = pInsertValue.split('.');
      if (_.isArray(targetParam)) {
        if (that.utils.Base_compare(targetParam[0], 'SYS')) {
          const targetTable = targetParam[1];
          const targetColum = targetParam[2];
          const tmpValues = findTarget(targetTable, targetColum);
          if (that.utils.Base_HasValue(tmpValues)) {
            tmp = tmpValues;
          } else {
            that.confirmModal.show('warning', {
              'title': '温馨提示',
              'content': pErrormsg
            });
            return false;
          }
        }
      }
    }
    return tmp;
  }

  makeConditions() {
    const dictionaryParam = this.utils.Base_getJsonValue(this.hulkColumn, 'dictionaryParam', null);
    let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
    let conditions = {};
    if (this.utils.Base_HasValue(dictionaryParam)) {
      _.each(this.hulkColumn.dictionaryParam, OneGroup => {
        const dicColumn = this.utils.Base_getJsonValue(OneGroup, 'column', null);
        const dicErrormsg = this.utils.Base_getJsonValue(OneGroup, 'errormsg', null);
        if (this.utils.Base_HasValue(dicColumn) && this.utils.Base_HasValue(dicErrormsg)) {
          const tmpValue = this.findValue(OneGroup['value'], OneGroup['errormsg']);
          if (this.utils.Base_HasValue(tmpValue)) {
            conditions[OneGroup['column']] = tmpValue;
          }
        }
      });
    }
    conditions = _.assign(conditions, intputCondition);
    if (this.utils.Base_HasValue(conditions)) {
      return conditions;
    } else {
      return null;
    }
  }

  /**
   * 异步加载商品分类数据。
   * @param node
   * @param index
   */
  loadData = (node: CascaderOption, index: number): PromiseLike<void> => {
    return new Promise(resolve => {
      if (index < 0) {
        node.children = this.datas[0];
        resolve();
      } else {
        const rowIndex = index + 1;
        const dictionary = _sh.get(this.hulkColumn, ['columnGroup', rowIndex, 'dictionary'], null);
        const type = _sh.get(this.hulkColumn, ['columnGroup', rowIndex, 'type'], 'get');
        if (type == 'get') {
          let condition = {};
          const conditionColumns = _sh.get(this.hulkColumn, ['columnGroup', rowIndex, 'dictionaryConditionColumn'], null);
          if (this.utils.Base_HasValue(conditionColumns)) {
            condition[conditionColumns] = node.value;
          }
          if (this.utils.Base_HasValue(dictionary)) {
            const parallel$ = observableForkJoin(
              this.requestDataService.getPaginationData(dictionary, condition, null, 100000, null),
            );
            parallel$.subscribe(
              values => {
                let data = values[0]['data'] || [];
                this.listDatas = _sh.concat(this.listDatas, data);
                this.listDatas = _.uniq(this.listDatas);
                _sh.set(this.datas, [rowIndex, node.value], []);
                // @ts-ignore
                _.each(data, oneData => {
                  let obj = {
                    value: oneData['code'],
                    label: oneData['name'],
                    isLeaf: oneData['FINAL_MARK'] == 'LAST_STAGE'
                  };
                  this.datas[rowIndex][node.value].push(obj);
                });
                node.children = _sh.cloneDeep(this.datas[rowIndex][node.value]);
                resolve();
                this.onChanges(_sh.split(this.hulkColumn.model, ','));
              }
            );
          }
        }
      }
    });
  };

  getLinkObj(values) {
    let obj = {
      "linkId": "",
      "linkName": "",
      "linkIdArray": values,
      "linkNameArray": []
    }
    if (this.utils.Base_HasValue(values)) {
      let index = 0;
      _.each(values, oneValue => {
        let oneFilter = _sh.filter(this.listDatas, oneItem => {
          const find = oneItem['code'] == oneValue;
          return find;
        });
        obj['linkNameArray'].push(_sh.get(oneFilter, [0, 'name']));
      })
    }
    obj['linkId'] = _sh.join(obj['linkIdArray'], ',');
    obj['linkName'] = _sh.join(obj['linkNameArray'], ' / ');
    return obj;
  }

  /**
   * 当商品分类变化的时候，查询商品属性组
   * @param values
   */
  onChanges(values: string[]): void {
    if (this.utils.Base_HasValue(values)) {
      let linkObj = this.getLinkObj(values);
      _sh.set(this.hulkColumn, 'model', _sh.join(values, ','));
      // modelName 由绑定解决。
      let rowIndex = 0;
      _sh.each(this.hulkColumn.columnGroup, oneGroup => {
        const type = _sh.get(oneGroup, ['type'], "get");
        if (type == 'get') {
          // _sh.set(this.hulkColumn, ['columnGroup', rowIndex, 'model'], value);
          const value = _sh.cloneDeep(_sh.get(values, [rowIndex]));
          oneGroup['model'] = value;
        } else if (type == 'readOnly') {
          const value = _sh.cloneDeep(_sh.get(values, [oneGroup['fromValue'][0]]));
          const column = oneGroup['fromValue'][1];
          let oneFilter = _sh.filter(this.listDatas, oneItem => {
            const find = oneItem['code'] == value;
            return find;
          });
          oneGroup['model'] = _sh.get(oneFilter, [0, column]);
        } else if (type == 'system') {
          oneGroup['model'] = linkObj[oneGroup['fromValue']];
        }
        rowIndex++;
      })
    } else {
      _sh.set(this.hulkColumn, 'model', null);
      _sh.set(this.hulkColumn, 'modelName', null);
      let rowIndex = 0;
      _sh.each(this.hulkColumn.columnGroup, oneGroup => {
        _sh.set(this.hulkColumn, ['columnGroup', rowIndex, 'model'], null);
        rowIndex++;
      })
    }
  }

  onSelectChange(selectedOptions: CascaderOption[]): void {
    // this.formData._ID.modelName = selectedOptions.map(o => o.label).join(' / ');
  }

  clearDatas() {
    this.datas = {
      '0': []
    }
  }

  ngOnInit() {
    this.clearDatas();
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    this.moveModelToData(); // 移动数据 model - > data
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.modelName = _sh.split(this.hulkColumn.model, ',');
    }
    this.conditions = this.makeConditions();
    const dictionary = _sh.get(this.hulkColumn, ['columnGroup', 0, 'dictionary'], null);
    const parallel$ = observableForkJoin(
      this.requestDataService.getPaginationData(dictionary, null, null, 100, null)
    );
    parallel$.subscribe(
      values => {
        let data = values[0]['data'] || [];
        this.listDatas = _sh.cloneDeep(data);
        // @ts-ignore
        _.each(data, item => {
          let obj = {
            value: item['code'],
            label: item['name'],
            isLeaf: item['FINAL_MARK'] == 'LAST_STAGE'
          };
          this.datas[0].push(obj);
        });
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
          this.hulkColumn.modelName = _sh.split(this.hulkColumn.model, ',');
        }
      }
    );

  }

}
