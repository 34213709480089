import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {ScriptUploadComponent} from '../headLine/script-upload/script-upload.component';
import {UtilsService} from '../../service/utils.service';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {DataService} from './data.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import * as _ from 'underscore';
import * as $ from 'jquery';
import * as moment from 'moment';
import {EsRebuildComponent} from './es-rebuild/es-rebuild.component';

@Injectable({
  providedIn: 'root'
})
export class HeadLineService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private dataService: DataService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    let selectList = [];
    let flag = true;
    let ids = [];
    switch (pBtn.ACTION_VALUE) {
      case 'resetPasswd': // 普通用户列表重置密码
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        _.each(pParentData.data.data, (item) => {
          if (item['checked']) {
            selectList.push(item['USER_ID']);
          }
        });
        this.confirmModal.show('confirm', {
          title: '确认重置密码吗？',
          suc: () => {
            this.dataService.resetHlUserPwd(selectList).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '已重置',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '重置失败，请刷新重试！'});
              }
            });
          }
        });
        break;
      case 'auditPass': // 普通用户列表审核通过
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        let indexs = [];
        _.each(pParentData.data.data, (item, index) => {
          if (item['checked']) {
            if (item['AUDIT_STATE'] === 'AUDITING') {
              selectList.push(item['USER_ID']);
            } else {
              indexs.push(index + 1);
            }
          }
        });
        if (indexs.length) {
          this.confirmModal.show('warning', {
            title: '第' + indexs.join(',') + '条数据状态不能进行审核!',
          });
          return false;
        }
        this.confirmModal.show('confirm', {
          title: '确认审核通过吗？',
          suc: () => {
            this.dataService.auditPass(selectList).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '已通过',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '审核失败，请刷新重试！'});
              }
            });
          }
        });
        break;
      case 'auditReject': // 普通用户列表审核不通过
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        indexs = [];
        _.each(pParentData.data.data, (item, index) => {
          if (item['checked']) {
            if (item['AUDIT_STATE'] === 'AUDITING') {
              selectList.push(item['USER_ID']);
            } else {
              indexs.push(index + 1);
            }
          }
        });
        if (indexs.length) {
          this.confirmModal.show('warning', {
            title: '第' + indexs.join(',') + '条数据状态不能进行审核!',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '审核不通过',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REJECT_REASON',
                  'name': 'REJECT_REASON',
                  'component': 'textarea',
                  'customize': 24,
                  'lable': '不通过原因',
                  'comment': '审核不通过原因',
                  'placeholder': '请填写审核不通过原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => {
            let data = response.modalFormData.columns[0];
            this.dataService.auditReject(selectList, data.model).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '已审核',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '审核失败，请刷新重试！'});
              }
            });
          }
        });
        break;
      case'scriptUpload': // 脚本上传（提交审核）
        if (this.selectOneData(pParentData.data.data) != 1) {
          return false;
        }
        let selectData = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        if (selectData[0]['SCRIPT_STATE'] != 'ALEADY_DISTRIBUTION' && selectData[0]['SCRIPT_STATE'] != 'REJECT') {
          this.confirmModal.show('warning', {
            title: '只有已分配和审核未通过状态的数据才可以提交审核',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '提交审核',
          nzContent: ScriptUploadComponent,
          nzWidth: 800,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: selectData[0],
          nzOnOk: (response) => this.confirmAudit(response, pParentData)
        });
        return false;

      case'assign': // 站点分配人员
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && (item['SITE_STATE'] === 'PENDING' || item['SITE_STATE'] === 'ASSIGNED') && item['ENABLE_STATE'] === 'NO_ENABLE';
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有待分配状态、已分配且未启用时才可以分配人员',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '分配人员',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'ASSIGN_ID',
                  'name': 'ASSIGN_ID',
                  'component': 'poplist',
                  'customize': 24,
                  'lable': '选择人员',
                  'comment': '选择人员',
                  'placeholder': '请选择人员',
                  'model': null,
                  'visible': true,
                  'require': true,
                  'dictionary': 'headLine/basic/dict/GRAB_USER.json',
                  'dictionaryPF': null,
                  'columnGroup': [
                    {
                      'column': 'GRAB_COST',
                      'name': 'GRAB_COST_NAME',
                      'component': 'input',
                      'lable': '抓取费用',
                      'comment': '抓取费用',
                      'placeholder': '请输入抓取费用',
                      'fromValue': 'COST_MONEY',
                      'model': null,
                      'visible': false,
                      'require': false
                    }
                  ]
                }
              ]
            }
          },
          nzOnOk: (response) => this.assign(response, ids, pParentData)
        });

        return false;

      case'disableInSite': // 禁用
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && item['ENABLE_STATE'] === 'ENABLE';
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有已启用时才可以停用',
          });
          return false;
        }

        this.confirmModal.show('confirm', {
          title: '确认要停用该站点吗？',
          suc: () => {
            this.disableSite(ids, pParentData);
          }
        });

        return false;

      case'enableInSite': // 启用
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && item['ENABLE_STATE'] === 'STOP';
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有已停用时才可以启用',
          });
          return false;
        }

        this.confirmModal.show('confirm', {
          title: '确认要启用该站点吗？',
          suc: () => {
            this.enableSite(ids, pParentData);
          }
        });

        return false;
      case 'scriptPass':
        if (this.selectMore(pParentData.data.data) < 1) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        this.scriptPass(selectList, pParentData);
        break;
      case 'scriptStart':
        if (this.selectMore(pParentData.data.data) < 1) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        _.each(selectList, (oneData) => {
          if (oneData['SCRIPT_STATE'] === 'ALEADY_DISTRIBUTION' || oneData['SCRIPT_STATE'] === 'PENDING' || oneData['SCRIPT_STATE'] === 'CANCELED') {
            flag = false;
            return;
          } else {
            ids.push(oneData['ID']);
          }
        });
        if (!flag) {
          return this.confirmModal.show('error', {title: '已分配、已取消状态不可以执行此操作。'});
        }

        this.dataService.scriptStart(selectList, ids).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '保存成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '保存失败'});
          }
        });
        return false;
      case 'scriptStop':
        if (this.selectMore(pParentData.data.data) < 1) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        _.each(selectList, (oneData) => {
          if (oneData['SCRIPT_STATE'] === 'ALEADY_DISTRIBUTION' || oneData['SCRIPT_STATE'] === 'PENDING' || oneData['SCRIPT_STATE'] === 'CANCELED') {
            flag = false;
            return;
          } else {
            ids.push(oneData['ID']);
          }
        });
        if (!flag) {
          return this.confirmModal.show('error', {title: '已分配、已取消状态不可以执行此操作。'});
        }
        this.confirmModal.show('confirm', {
          title: '您确定停用选中的脚本吗？',
          suc: () => {
            this.dataService.scriptStop(selectList, ids).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '保存成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '保存失败'});
              }
            });
          }
        });
        break;
      case 'acriptAuditFailed': // 脚本管理审核不通过
        if (this.selectMore(pParentData.data.data) < 1) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        _.each(selectList, (oneData) => {
          if (oneData['SCRIPT_STATE'] != 'NO_AUDIT') {
            flag = false;
            return;
          } else {
            ids.push(oneData['ID']);
          }
        });
        if (!flag) {
          return this.confirmModal.show('error', {title: '状态为待审核的数据才可以执行此操作。'});
        }
        this.modalService.create({
          nzTitle: '审核不通过',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REJECTED_REASON',
                  'name': 'REJECTED_REASON',
                  'component': 'textarea',
                  'customize': 24,
                  'lable': '不通过原因',
                  'comment': '不通过原因',
                  'placeholder': '请输入不通过原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.dataService.acriptAuditFailed(selectList, response, ids).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '保存成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
            } else {
              this.confirmModal.show('error', {title: '保存失败'});
            }
          })
        });
        break;
      case 'clickVolumeBtn':
        this.modalService.create({
          nzTitle: '添加',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'ORG_ID',
                  'name': 'ORG_ID',
                  'component': 'poplist',
                  'customize': 24,
                  'lable': '选择机构',
                  'comment': '选择机构',
                  'placeholder': '请选择机构',
                  'model': null,
                  'visible': true,
                  'require': true,
                  'dictionary': 'headLine/basic/dict/ORGID_NAME.json',
                  'dictionaryPF': null
                },
                {
                  'column': 'CLICK_VOLUME',
                  'name': 'CLICK_VOLUME',
                  'component': 'input',
                  'customize': 24,
                  'type': 'number',
                  'lable': '点击量(次)',
                  'comment': '点击量(次)',
                  'placeholder': '请填写点击量(次)',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.clickVolume(response, pParentData)
        });
        break;
      case 'dataSetInsert':
        this.transformData('customForm', pParentData, 'add', 'dataSetInsert');
        break;
      case 'dataSetModify':
        if (this.selectOneData(pParentData.data.data) != 1) {
          return false;
        }
        this.mackConditon(pParentData);
        this.transformData('customForm', pParentData, 'modify', 'dataSetInsert');
        break;
      case 'dataSetRemove':
        this.dataSetRemove(pParentData);
        break;
      case 'scriptPay': // 抓取支付
        this.scriptPay(pParentData);
        break;
      case 'collectPay': // 收集支付
        this.collectPay(pParentData);
        break;

      case'siteAudit': // 站点审核通过
        this.siteAudit(pParentData);
        break;
      case'siteReject': // 站点审核不通过
        this.siteReject(pParentData);
        break;
      case 'scriptCancel': // 脚本取消
        this.scriptCancel(pParentData);
        break;
      case 'dispatch': // 调度
        this.dispatch(pParentData);
        break;
      case 'clearScriptCar': // 清空脚本车
        this.clearScriptCar(pParentData);
        break;
      case 'clearServer': // 清空服务器
        this.clearServer(pParentData);
        break;
      case 'transfer': // 更改脚本车
        this.transfer(pParentData);
        break;
      case 'pushNewsBtn': // 推送
        this.pushNews(pParentData);
        break;
      case 'cancelDispatch': // 取消调度
        this.cancelDispatch(pParentData);
        break;
      case 'pushNewsAuto':
        this.pushNewsAuto(); // 智能推送
        break;
      case 'submitAudit': // 待编缉内容管理提交审核
        this.submitAudit(pParentData);
        break;
      case 'previewNews': // 预览
        this.previewNews(pParentData);
        break;
      case 'updateScriptFrequency':
        this.updateScriptFrequency(pParentData);
        return false;
      case 'adopt': // 待审核管理 审核通过按钮
        this.adopt(pParentData);
        break;
      case 'newsOnline': // 审核管理 上架
        this.newsOnline(pParentData);
        break;
      case 'reject': // 通过审核管理 下架按钮
        this.reject(pParentData);
        break;
      case 'cancelAudit': // 待审核管理 取消审核按钮
        this.cancelAudit(pParentData);
        break;
      case 'preRelease': // 预发布
        this.preRelease(pParentData);
        break;
      case 'cancelPreRelease': // 取消预发布
        this.cancelPreRelease(pParentData);
        break;
      case 'modelAdd': // 模型添加
        this.transformData('customForm', pParentData, 'add', 'modelInsert');
        break;
      case 'dataverseInsert':
        this.dataverseInsert(pParentData);
        break;
      case 'dataverseModify':
        this.dataverseModify(pParentData);
        break;
      case 'dataverseRemove':
        this.dataverseRemove(pParentData);
        break;
      case 'editModel': // 修改模型
        if (this.selectOneData(pParentData.data.data) != 1) {
          return false;
        }
        this.mackConditon(pParentData);
        this.transformData('customForm', pParentData, 'modify', 'editModel');
        break;
      case 'deleteModel': // 删除模型
        this.deleteModel(pParentData);
        break;
      case 'publishDataset': // 发布数据集
        this.publishDataset(pParentData);
        break;
      case 'publishDataverse': // 发布数据空间
        this.publishDataverse(pParentData);
        break;
      case 'esRebuild': // 重建ES库
        this.esRebuild();
        break;
      case 'dataversePermission':
        this.dataversePermission(pParentData);
        break;
      case 'datasetPermission': // 数据集配置权限
        this.datasetPermission(pParentData);
        break;
      case 'filePowerPass':
        this.filePowerPass(pParentData);
        break;
      case 'filePowerReject':
        this.filePowerReject(pParentData);
        break;
      case 'createScriptAndDispatch': // 搜索池管理 生成脚本并调度
        this.createScriptAndDispatch(pParentData);
        break;
      case 'subscribeGzh': // 搜索池管理 订阅公众号
        this.subscribeGzh(pParentData);
        break;
      case 'unsubscribeGzh': // 搜索池管理 取消订阅公众号
        this.unsubscribeGzh(pParentData);
        break;
      case 'joinSearchPool': // 词汇管理 加入搜索池
        this.joinSearchPool(pParentData);
        break;
      case 'deleteWords': // 搜索池管理 删除
        this.deleteWords(pParentData);
        break;
      case 'selectChoice': // 分配精选人
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && (item['ORIGIN_STATE'] === 'PENDING' || item['ORIGIN_STATE'] === 'ASSIGNED');
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有待分配、已分配状态才可以分配人员',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '分配人员',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'ASSIGN_ID',
                  'name': 'ASSIGN_ID',
                  'component': 'poplist',
                  'customize': 24,
                  'lable': '选择人员',
                  'comment': '选择人员',
                  'placeholder': '请选择人员',
                  'model': null,
                  'visible': true,
                  'require': true,
                  'dictionary': 'headLine/basic/dict/EDIT_USER.json',
                  'dictionaryPF': null
                }
              ]
            }
          },
          nzOnOk: (response) => this.selectChoice(response, ids, pParentData)
        });
        break;
      case 'auditRejectChoice': // 精选审核不通过
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        const newsIds = [];
        ids = [];
        _.each(selectList, item => {
          flag = flag && item['AUDITING_STATE'] === 0 && this.util.Base_HasValue(item['ORIGINALLINK_ID']);
          if (flag) {
            ids.push(item['ORIGINALLINK_ID']);
            newsIds.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有未审核状态才可以审核不通过',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '审核不通过',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REASONS',
                  'name': 'REASONS',
                  'component': 'select',
                  'customize': 24,
                  'lable': '审核不通过原因',
                  'comment': '审核不通过原因',
                  'placeholder': '请选择审核不通过原因',
                  'model': null,
                  'visible': true,
                  'require': true,
                  'dictionary': 'headLine/basic/dict/AUDIT_REASON.json',
                  'dictionaryPF': null
                }
              ]
            }
          },
          nzOnOk: (response) => this.auditRejectChoice(response, newsIds, ids, pParentData)
        });
        break;
      case 'selectEditUser': // 待编辑内容管理-分配编辑人员
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && (item['AUDITING_STATE'] === 0);
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有待审核状态才可以分配人员',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '分配人员',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'ASSIGN_ID',
                  'name': 'ASSIGN_ID',
                  'component': 'poplist',
                  'customize': 24,
                  'lable': '选择人员',
                  'comment': '选择人员',
                  'placeholder': '请选择人员',
                  'model': null,
                  'visible': true,
                  'require': true,
                  'dictionary': 'headLine/basic/dict/NORMAL_EDIT_USER.json',
                  'dictionaryPF': null
                }
              ]
            }
          },
          nzOnOk: (response) => this.selectEditUser(response, ids, pParentData)
        });
        break;
      case 'auditPassCollectSite':// 采编站点收集管理-审核通过
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && (item['SITE_STATE'] === 'NO_AUDIT' || item['SITE_STATE'] === 'REJECT');
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有待审核、审核未通过数据才可进行审核通过操作',
          });
          return false;
        }
        this.confirmModal.show('confirm', {
          title: '您确定执行该操作吗？',
          suc: () => {
            this.dataService.auditPassCollectSite(ids).subscribe((response) => {
              if (response['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '审核成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '审核失败'});
              }
            });
          }
        });
        break;
      case 'auditRejectCollectSite': // 采编站点收集管理-审核拒绝
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        flag = true;
        ids = [];
        _.each(selectList, item => {
          flag = flag && (item['SITE_STATE'] === 'NO_AUDIT');
          if (flag) {
            ids.push(item['ID']);
          }
        });
        if (!flag) {
          this.confirmModal.show('warning', {
            title: '只有待审核数据才可进行审核拒绝操作',
          });
          return false;
        }
        this.modalService.create({
          nzTitle: '审核不通过',
          nzContent: FormModalComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REASONS',
                  'name': 'REASONS',
                  'component': 'textarea',
                  'customize': 24,
                  'lable': '审核不通过原因',
                  'comment': '审核不通过原因',
                  'placeholder': '请输入审核不通过原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.auditRejectCollectSite(response, ids, pParentData)
        });
        break;
      case 'addScriptToCarGzh':
        this.addScriptToCar(pParentData, 'BAZHUAYU_WX_GZH');
        break;
      case 'addScriptToCarKeyWord':
        this.addScriptToCar(pParentData, 'BAZHUAYU_WX_KEYWORD');
        break;
      case 'updateSiteSubject':
        this.updateSiteSubject(pParentData);
        break;
      case 'updateNewsSubject':
        this.updateNewsSubject(pParentData, 'originallink');
        break;
      case 'updateNewsSubjectEdit':
        this.updateNewsSubject(pParentData, 'hl_news');
        break;
      case 'replacePicture':
        this.replacePicture(pParentData);
        break;
      case 'editNews':
        if (this.selectMore(pParentData.data.data) === 0) {
          return false;
        }
        selectList = _.filter(pParentData.data.data, (item) => {
          return item['checked'];
        });
        pParentData.detailCondition = {ID: selectList[0].ID};
        pParentData.fromColumns = pParentData.conditionColumns;
        this.transformData('customForm', pParentData, 'modify', 'editNews');
        break;
    }
  }

  replacePicture(pParentData) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      ids.push(oneData['ID']);
    });
    this.dataService.replacePicture(ids).subscribe(rep => {
      if (this.util.Base_HasValue(rep) && rep !== 'OK') {
        this.confirmModal.show('success', {title: '[' + rep.length + ']新闻替换完成'});
      } else {
        this.confirmModal.show('success', {title: '没有新闻可以替换'});
      }
    });
  }

  // 修改新闻学科
  updateNewsSubject(pParentData, pTableName) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      ids.push(oneData['ID']);
    });
    this.modalService.create({
      nzTitle: '修改学科',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'SUBJECT_TYPE',
              'name': 'SUBJECT_TYPE_NAME',
              'component': 'multipop',
              'customize': 24,
              'lable': '学科分类',
              'comment': '学科分类',
              'placeholder': '学科分类',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/basic/dict/SUB_SHORT_NAME.json',
              'dictionaryPF': null,
              'columnGroup': [
                {
                  'column': 'SUBJECT_NAME',
                  'name': 'SUBJECT_NAME_NAME',
                  'component': 'input',
                  'lable': '学科简称',
                  'comment': '学科简称',
                  'placeholder': '请输入学科简称',
                  'fromValue': 'name',
                  'model': null,
                  'visible': false,
                  'require': false
                }
              ]
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const subjectType = response.modalFormData.columns[0].model;
          const subjectName = response.modalFormData.columns[0].columnGroup[0].model;
          if (this.util.Base_HasValue(subjectType)) {
            this.dataService.updateNewsSubject(ids, subjectType, subjectName, pTableName).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '修改成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '修改失败'});
              }
            });
          } else {
            this.confirmModal.show('error', {title: '修改失败'});
          }
        } else {
          return false;
        }
      }
    });
  }

  // 更改学科
  updateSiteSubject(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      ids.push(oneData['ID']);
    });
    this.modalService.create({
      nzTitle: '修改学科',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'SUBJECT_ID',
              'name': 'SUBJECT_ID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '所属学科',
              'comment': '所属学科',
              'placeholder': '所属学科',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/basic/dict/SUBJECT_NAME.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const model = response.modalFormData.columns[0].model;
          const itemArray = response.modalFormData.columns[0].itemArray;
          let oneData = _.find(itemArray, (item) => {
            return item['code'] === model;
          });
          if (this.util.Base_HasValue(oneData)) {
            this.dataService.updateSiteSubject(ids, oneData).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '修改成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '修改失败'});
              }
            });
          } else {
            this.confirmModal.show('error', {title: '修改失败'});
          }
        } else {
          return false;
        }
      }
    });
  }

  addScriptToCar(pParentData, pType) {
    let wordsId = [];
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    _.each(pParentData.data.data, oneData => {
      if (oneData['checked']) {
        wordsId.push(oneData['ID']);
      }
    });
    let data = {
      'keyword': wordsId,
      'serverType': pType
    };
    this.confirmModal.show('confirm', {
      title: '您确定执行该操作吗？',
      suc: () => {
        this.dataService.addScriptToCarService(data).subscribe(rep => {
          let message = rep.msg;
          if (rep.state === 'success') {
            if (!this.util.Base_HasValue(message)) {
              message = '执行成功';
            }
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: message});
          } else {
            if (!this.util.Base_HasValue(message)) {
              message = '执行失败';
            }
            this.confirmModal.show('error', {title: message});
          }
        });
      }
    });
  }

// 采编站点收集管理-审核拒绝
  auditRejectCollectSite(result, idList, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      this.dataService.auditRejectCollectSite(idList, assignObj).subscribe((response) => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            title: '审核不通过成功',
            suc: () => {
              pParentData.needSearch = true;
            }
          });
        } else {
          this.confirmModal.show('error', {title: '审核不通过失败'});
        }
      });
    } else {
      return false;
    }
  }

// 待编辑内容管理-分配编辑人员
  selectEditUser(result, idList, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      this.dataService.selectEditUser(idList, assignObj).subscribe((response) => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            title: '保存成功',
            suc: () => {
              pParentData.needSearch = true;
            }
          });
        } else {
          this.confirmModal.show('error', {title: '保存失败'});
        }
      });
    } else {
      return false;
    }
  }

  // 精选审核不通过
  auditRejectChoice(result, newsIds, ids, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      this.dataService.auditRejectChoice(newsIds, ids, assignObj).subscribe((response) => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            title: '审核不通过成功',
            suc: () => {
              pParentData.needSearch = true;
            }
          });
        } else {
          this.confirmModal.show('error', {title: '审核不通过失败'});
        }
      });
    } else {
      return false;
    }
  }

  selectChoice(result, idList, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      this.dataService.selectChoice(idList, assignObj).subscribe((response) => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            title: '保存成功',
            suc: () => {
              pParentData.needSearch = true;
            }
          });
        } else {
          this.confirmModal.show('error', {title: '保存失败'});
        }
      });
    } else {
      return false;
    }
  }

  // 删除搜索池
  deleteWords(pParentData) {
    let wordsId = [];
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    _.each(pParentData.data.data, oneData => {
      if (oneData['checked']) {
        wordsId.push(oneData['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定执行该操作吗？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptAutoController', 'deleteSearchWords', 'ids=' + wordsId.join()).subscribe((result) => {
          if (this.util.Base_HasValue(result['error'])) {
            return this.confirmModal.show('error', {title: result['error'].message});
          } else if (result) {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          } else {
            this.util.Base_JAVA_ERROR(result);
          }
        });
      }
    });
  };

  // 词汇管理 加入搜索池
  joinSearchPool(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let list = [];
    // 获取选中的词汇名称。查询搜索池表是否已添加
    const nameList = [];
    let flag = true;
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (+oneData['IS_ADD_SEARCH_POOL'] === 1) {
        flag = false;
        return;
      } else {
        list.push(oneData);
        nameList.push(oneData['WORDS_NAME']);
      }
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '已加入搜索池的数据不可以执行此操作。'});
    } else {
      this.requestDataService.getDataByCondition('headLine/hl/hl_search_pool_search.json', {
        WORDS_NAME: nameList
      }).subscribe(res => {
        const outList = [];
        const dataList = res['data'] || [];
        _.each(dataList, (item: any) => {
          if (+item.account > 0) {
            outList.push(item.SEARCH_WORDS_NAME);
          }
        });
        list = _.filter(list, item => {
          const current = _.find(outList, out => out === item['WORDS_NAME']);
          return !current;
        });
        const resultCb = function () {
          this.dataService.joinSearchPool(list).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '加入成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
            } else {
              this.confirmModal.show('error', {title: '加入失败，请刷新重试'});
            }
          });
        }.bind(this);
        if (outList.length) {
          this.confirmModal.show('confirm', {
            title: '温馨提示',
            content: '以下词汇已存在搜索池，点击确定将不会更新这些词汇到搜索池: ' + outList.join(','),
            suc: () => {
              resultCb();
            }
          });
        } else {
          resultCb();
        }
      });
    }
  }

  // 生成脚本并调度
  createScriptAndDispatch(pParentData) {
    let wordsId = [];
    let ok = true;
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    _.each(pParentData.data.data, oneData => {
      if (oneData['checked']) {
        wordsId.push(oneData['ID']);
        if (+oneData['IS_BUILD_SCRIPT'] === 1) {
          ok = false;
        }
      }
    });
    if (!ok) {
      return this.confirmModal.show('error', {title: '已生成脚本的数据不能执行此操作'});
    }
    this.confirmModal.show('confirm', {
      title: '您确定执行该操作吗？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptAutoController', 'buildScript', 'ids=' + wordsId.join()).subscribe((result) => {
          if (this.util.Base_HasValue(result['error'])) {
            return this.confirmModal.show('error', {title: result['error'].message});
          } else if (result) {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          } else {
            this.util.Base_JAVA_ERROR(result);
          }
        });
      }
    });
  }

  // 订阅公众号
  subscribeGzh(pParentData) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['IS_SUBSCRIBE'] === 0) {
        ids.push(oneData['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定执行该操作吗？',
      suc: () => {
        if (!this.util.Base_HasValue(ids)) {
          this.confirmModal.show('error', {title: '没有可订阅的数据'});
          return false;
        }
        this.dataService.subscribeGzh(ids).subscribe(rep => {
          if (rep.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: rep.data});
          } else {
            this.confirmModal.show('error', {title: rep.data});
          }
        });
      }
    });
  }

  //取消订阅
  unsubscribeGzh(pParentData) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['IS_SUBSCRIBE'] === 1) {
        ids.push(oneData['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定执行该操作吗？',
      suc: () => {
        if (!this.util.Base_HasValue(ids)) {
          this.confirmModal.show('error', {title: '没有可取消订阅的数据'});
          return false;
        }
        this.dataService.unsubscribeGzh(ids).subscribe(rep => {
          if (rep.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: rep.data});
          } else {
            this.confirmModal.show('error', {title: rep.data});
          }
        });
      }
    });
  }


  //文件权限申请列表审核通过按钮
  filePowerPass(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let list = [];
    let flag = true;
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['AUDIT_STATE'] !== 'AUDITING') {
        flag = false;
        return;
      } else {
        list.push({
          APPLY_ID: oneData['ID'],
          GROUP_ID: oneData['GROUP_ID'],
          GROUP_NAME: oneData['GROUP_NAME'],
          GROUP_DESC: oneData['GROUP_DESC'],
          USER_ID: oneData['ADD_USERID'],
          USER_NAME: oneData['ADD_NAME'],
          DATASET_ID: oneData['DATASET_ID'],
          DATASET_NAME: oneData['DATASET_NAME']
        });
      }
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为审核中的数据才可以执行此操作。'});
    }
    this.confirmModal.show('confirm', {
      title: '审核通过后，此用户将加入该用户组，且拥有该用户组的所有文件下载权限。若要取消，需要在数据空间权限配置中进行操作。',
      content: '是否继续？',
      suc: () => {
        this.dataService.filePowerPass(list).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '审核成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '审核失败，请刷新重试'});
          }
        });
      }
    });
  }

  //文件权限申请列表审核拒绝按钮
  filePowerReject(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    let flag = true;
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['AUDIT_STATE'] !== 'AUDITING') {
        flag = false;
        return;
      } else {
        ids.push(oneData['ID']);
      }
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为审核中的数据才可以执行此操作。'});
    }

    this.modalService.create({
      nzTitle: '审核不通过',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REJECTED_REASON',
              'name': 'REJECTED_REASON',
              'component': 'textarea',
              'customize': 24,
              'lable': '不通过原因',
              'comment': '不通过原因',
              'placeholder': '请输入不通过原因',
              'maxlength': 500,
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.status === 'PENDING') {
          return;
        }
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const data = response.modalFormData.columns[0];
          this.dataService.filePowerReject(ids, data.model).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '审核拒绝成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
            } else {
              this.confirmModal.show('error', {title: '审核失败，请刷新重试'});
            }
          });
        } else {
          return false;
        }
      }
    });
  }

  datasetPermission(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return false;
    }
    this.mackConditon(pParentData);
    const selected = _.filter(pParentData.data.data, item => item['checked']);
    pParentData.DATASET_ID = selected[0]['ID'];
    pParentData.DATASET_NAME = selected[0]['DATASET_NAME'];
    pParentData.DATASET_STATE = selected[0]['DATASET_STATE'];
    this.requestDataService.getDataByCondition('headLine/ds/ds_file_restricted.json', {DATASET_ID: pParentData.DATASET_ID}).subscribe(response => {
      if (response.data.length > 0) {
        this.transformData('customForm', pParentData, 'setting', 'datasetPermission');
      } else {
        this.confirmModal.show('warning', {'title': '该数据集没有受限文件。'});
      }
    });
  }


  // 数据空间权限配置按钮
  dataversePermission(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return false;
    }
    this.mackConditon(pParentData);
    const selected = _.filter(pParentData.data.data, item => item['checked']);
    pParentData.DATAVERSE_NAME = selected[0]['DATAVERSE_NAME'];
    pParentData.DATAVERSE_ID = selected[0]['ID'];
    this.transformData('customForm', pParentData, 'setting', 'dataversePermission');
  }

  // 重建ES库
  esRebuild() {
    this.confirmModal.show('confirm', {
      title: '重建搜索引擎将导致前台数据重新生成,您确定进行重建搜索引擎吗？',
      suc: () => {
        const modal = this.modalService.create({
          nzTitle: '重建搜索引擎',
          nzContent: EsRebuildComponent,
          nzWidth: 1000,
          nzWrapClassName: 'vertical-center-modal',
          nzFooter: [
            {
              label: 'Close',
              shape: 'default',
              onClick: () => modal.destroy()
            }
          ],
          nzOnOk: (response) => {

          }
        });
      }
    });

    // this.dataService.esRebuild(null);
  }

  // 发布数据集
  publishDataset(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    let flag = true;
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['DATASET_STATE'] !== 'OUTSALE') {
        flag = false;
        return;
      } else {
        ids.push({
          datasetId: oneData['ID'],
          dataverseId: oneData['DATAVERSE_ID']
        });
      }
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为未发布的数据才可以执行此操作。'});
    }
    this.confirmModal.show('confirm', {
      title: '您确定发布这些数据集吗？',
      suc: () => {
        this.dataService.publishDatasetNode(ids).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '发布成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '发布失败'});
          }
        });
      }
    });
  }

  // 发布数据空间
  publishDataverse(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    let flag = true;
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      if (oneData['DATAVERSE_STATE'] !== 'OUTSALE') {
        flag = false;
        return;
      } else {
        ids.push(oneData['ID']);
      }
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为未发布的数据才可以执行此操作。'});
    }
    this.confirmModal.show('confirm', {
      title: '您确定发布这些数据空间吗？',
      suc: () => {
        this.dataService.publishDataverse(ids).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '发布成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '发布失败'});
          }
        });
      }
    });
  }

  // 添加数据空间
  dataverseInsert(pParentData) {
    this.requestDataService.getDataByCondition('headLine/ds/ds_dataverse_count.json', {
      DATAVERSE_CATEGORY: 'root'
    }).subscribe(result => {
      if (result.data[0]['account'] > 0) {
        const selected = _.filter(pParentData.data.data, item => item['checked']);
        if (selected.length !== 1) {
          this.confirmModal.show('warning', {
            title: '温馨提示',
            content: '需要选择一个空间作为父级空间'
          });
          return false;
        }
        this.mackConditon(pParentData);
        pParentData.DATAVERSE_UP_NAME = selected[0]['DATAVERSE_NAME'];
        pParentData.DATAVERSE_UP_ID = selected[0]['ID'];
        pParentData.isRoot = false;
        this.transformData('customForm', pParentData, 'add', 'dataverseInsert');
      } else {
        this.confirmModal.show('confirm', {
          title: '您将添加该机构的第一个空间，此空间将作为本机构的根空间，用于前台首页和数据列表页展示，以及对应子空间添加的依据。添加后将不可删除，请谨慎操作！',
          content: '是否添加？',
          suc: () => {
            pParentData.isRoot = true;
            this.transformData('customForm', pParentData, 'add', 'dataverseInsert');
          }
        });
      }
    });
  }

  // 删除模型
  deleteModel(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    _.each(pParentData.data.data, item => {
      if (item['checked']) {
        ids.push(item['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定删除这些模型吗？',
      suc: () => {
        this.dataService.deleteModel({
          modelId: ids
        }).subscribe(result => {
          if (result) {
            this.confirmModal.show('success', {
              title: '温馨提示',
              content: '删除成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {
              title: '温馨提示',
              content: '操作失败，请刷新重试。'
            });
          }
        });
      }
    });
  }

  // 修改数据空间
  dataverseModify(pParentData) {
    if (this.selectOneData(pParentData.data.data) != 1) {
      return false;
    }
    this.mackConditon(pParentData);
    const selected = _.find(pParentData.data.data, item => item['checked']);
    if (selected['DATAVERSE_CATEGORY'] === 'root') {
      pParentData.isRoot = true;
      delete pParentData.DATAVERSE_UP_ID;
      delete pParentData.DATAVERSE_UP_NAME;
    } else {
      pParentData.isRoot = false;
      pParentData.DATAVERSE_UP_ID = selected['DATAVERSE_UP_ID'];
      pParentData.DATAVERSE_UP_NAME = selected['DATAVERSE_UP_NAME'];
    }
    this.transformData('customForm', pParentData, 'modify', 'dataverseInsert');
  }

  // 删除数据空间
  dataverseRemove(pParentData) {
    if (this.selectOneData(pParentData.data.data) != 1) {
      return false;
    }
    let ids = [];
    _.each(pParentData.data.data, (item, index) => {
      if (item['checked']) {
        ids.push(item['ID']);
      }
    });
    this.requestDataService.getDataByCondition('headLine/report/checkDataverseDelete/main.json', {
      DATAVERSE_ID: ids
    }).subscribe(result => {
      let count = 0;
      _.each(result['data'], item => {
        count += item['account'];
      });
      if (count > 0) {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '此空间已被绑定精品空间或者数据集，或者此空间下有数据空间，不允许删除'
        });
        return false;
      } else {
        this.confirmModal.show('confirm', {
          title: '您确定删除此空间吗？',
          suc: () => {
            this.dataService.removeDataverse({
              dataverseId: ids
            }).subscribe(res => {
              if (res) {
                this.confirmModal.show('success', {
                  title: '温馨提示',
                  content: '删除成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  // 删除数据集
  dataSetRemove(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    _.each(pParentData.data.data, item => {
      if (item['checked']) {
        ids.push(item['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定删除这些数据集吗？',
      suc: () => {
        this.dataService.removeDataSet({
          datasetId: ids
        }).subscribe(result => {
          if (result) {
            this.confirmModal.show('success', {
              title: '温馨提示',
              content: '删除成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          }
        });
      }
    });
  }

  // 取消预发布
  cancelPreRelease(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定取消预发布吗？',
      suc: () => {
        this.dataService.cancelPreRelease(selectList, pParentData);
      }
    });
  }

  // 修改脚本运行频率
  updateScriptFrequency(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, (oneData) => {
      ids.push(oneData['ID']);
    });
    this.requestDataService.getDataByCondition('headLine/hl/hl_car_num.json').subscribe(res => {
      const num = res.data[0].account || 0;
      this.modalService.create({
        nzTitle: '修改运行频率',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'SCRIPT_RUN_FREQUENCY',
                'name': 'SCRIPT_RUN_FREQUENCY',
                'component': 'input',
                'type': 'number',
                'customize': 24,
                'max': num,
                'lable': '运行频率',
                'comment': '运行频率',
                'placeholder': '最大运行频率为' + num,
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          if (response.childForm._validateForm.invalid) {
            for (const key in response.childForm._validateForm.controls) {
              response.childForm._validateForm.controls[key].markAsDirty();
              response.childForm._validateForm.controls[key].updateValueAndValidity();
            }
          }
          if (response.childForm._validateForm.valid) {
            const model = response.modalFormData.columns[0].model;
            this.dataService.updateScriptFrequency(ids, model).subscribe(result => {
              if (result['state'] === 'success') {
                this.confirmModal.show('success', {
                  title: '修改成功',
                  suc: () => {
                    pParentData.needSearch = true;
                  }
                });
              } else {
                this.confirmModal.show('error', {title: '修改失败'});
              }
            });
          } else {
            return false;
          }
        }
      });
    });

  }

  // 预发布
  preRelease(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.modalService.create({
      nzTitle: '预发布',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PRE_RELEASE_DATE',
              'name': 'PRE_RELEASE_DATE',
              'component': 'date',
              'customize': 24,
              'lable': '预发布日期',
              'comment': '预发布日期',
              'placeholder': '请选择日期',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'PRE_RELEASE_TIME',
              'name': 'PRE_RELEASE_TIME',
              'component': 'select',
              'customize': 24,
              'lable': '预发布时间',
              'comment': '预发布时间',
              'placeholder': '请选择时间',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/basic/dict/PRE_RELEASE_TIME.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (result) => {
        if (result.childForm._validateForm.invalid) {
          for (const key in result.childForm._validateForm.controls) {
            result.childForm._validateForm.controls[key].markAsDirty();
            result.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (result.childForm._validateForm.valid) {
          if (moment(result.modalFormData.columns[0].model + ' ' + result.modalFormData.columns[1].model).format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss')) {
            this.confirmModal.show('error', {'title': '预发布时间不能比现在时间早'});
            return false;
          }
          this.dataService.preRelease(selectList, result, pParentData);
        }
      }
    });
  }

// 待审核管理 取消审核按钮
  cancelAudit(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定取消审核？',
      suc: () => {
        this.dataService.cancelAudit(selectList, pParentData);
      }
    });
  }

  // 下架
  reject(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    _.each(selectList, item => {
      flag = flag && (item['AUDITING_STATE'] === 1);
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '只有审核通过状态才可以下架',
      });
      return false;
    }
    this.modalService.create({
      nzTitle: '下架',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REJECT_REASON',
              'name': 'REJECT_REASON',
              'component': 'select',
              'customize': 24,
              'lable': '下架原因',
              'comment': '下架原因',
              'placeholder': '请选择下架原因',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/basic/dict/REJECT_REASON.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => this.dataService.reject(selectList, pParentData, response)
    });
  }

  // 等审核新闻审核通过
  adopt(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定通过审核？',
      suc: () => {
        this.dataService.adopt(selectList, pParentData);
      }
    });
  }

  newsOnline(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.dataService.newsOnline(selectList, pParentData);
  }

  // 预览
  previewNews(pParentData) {
    if (this.selectOneData(pParentData.data.data) === 1) {
      let orgCode = this.user.ORG_MARK || this.config.config.defaultOrgMark;
      let selected = _.find(pParentData.data.data, function (item) {
        return item['checked'];
      });
      if (!this.util.Base_HasValue(orgCode)) {
        this.confirmModal.show('error', {'title': '机构标识不存在，不能预览'});
        return;
      }
      let url = this.config.config.previewUrl + '/index/preview/' + orgCode + '/' + selected['ID'] + '/';
      let element = $('<a href="' + url + '" target="_blank">预览</a>');
      let e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      element[0].dispatchEvent(e);
    }
  }

  // 待编缉内容管理提交审核
  submitAudit(pParentData) {
    if (this.selectMore(pParentData.data.data) > 0) {
      let selectedList = [];
      let indexList = [];
      let labelList = [];
      _.each(pParentData.data.data, (data, index) => {
        if (data['checked']) {
          selectedList.push(data);
          if (!this.util.Base_HasValue(data['SUBJECT_TYPE'])) {
            indexList.push(index + 1);
          }
          if (!this.util.Base_HasValue(data['LABEL'])) {
            labelList.push(index + 1);
          }
        }
      });
      if (indexList.length && labelList.length) {
        this.confirmModal.show('error', {'title': '第' + indexList.join(',') + '条新闻的学科分类未给值,第' + labelList.join(',') + '条新闻的标签未给值'});
        return;
      } else if (indexList.length) {
        this.confirmModal.show('error', {'title': '第' + indexList.join(',') + '条新闻的学科分类未给值'});
        return;
      } else if (labelList.length) {
        this.confirmModal.show('error', {'title': '第' + labelList.join(',') + '条新闻的标签未给值'});
        return;
      } else {
        this.dataService.submitAudit(selectedList, pParentData);
      }
    }
  }

  // 智能推送
  pushNewsAuto() {
    this.confirmModal.show('confirm', {
      title: '您确定要进行智能推送吗？',
      suc: () => {
        this.requestDataService.gotoHdbsThor('hdbsthor-headline', 'NewsController', 'pushNews').subscribe(response => {
          if (response === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
          } else {
            this.confirmModal.show('warning', {'title': response.error.message});
          }
        });
      }
    });
  }

  // 脚本取消调度
  cancelDispatch(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let ok = true;
    let ids = [];
    _.each(selectList, (oneData, key) => {
      if (oneData['DISPATCH_STATE'] != 'DISPATCH') {
        ok = false;
        return;
      } else {
        ids.push(oneData['ID']);
      }
    });
    if (!ok) {
      this.confirmModal.show('error', {title: '状态为已调度才可以执行该操作'});
      return;
    }
    this.confirmModal.show('confirm', {
      title: '您确定取消调度吗？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptCarController', 'cancelDispatch', 'ids=' + ids.join(',')).subscribe((result) => {
          if (this.util.Base_HasValue(result.error)) {
            this.confirmModal.show('error', {title: result.error.message});
          } else {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          }
        });
      }
    });

  }

  // 推送
  pushNews(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定推送这些新闻吗？',
      suc: () => {
        this.dataService.editUserToNews(selectList, pParentData);
      }
    });
  }

  // 更改脚本车
  transfer(pParentData) {
    if (this.selectOneData(pParentData.data.data) != 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.modalService.create({
      nzTitle: '更改脚本车',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'SCRIPT_CAR_ID',
              'name': 'SCRIPT_CAR_ID',
              'component': 'poplist',
              'customize': 24,
              'lable': '脚本车',
              'comment': '脚本车',
              'placeholder': '请选择脚本车',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/basic/dict/CAR_NAME.json',
              'dictionaryPF': null,
              'columnGroup': [
                {
                  'column': 'SERVER_ID',
                  'name': 'SERVER_ID',
                  'component': 'input',
                  'lable': '服务器',
                  'comment': '服务器',
                  'placeholder': '请选择服务器',
                  'fromValue': 'SERVER_ID',
                  'model': null,
                  'visible': false,
                  'require': true,
                }
              ]
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const resData = response.modalFormData.columns[0];
          this.requestDataService.doActionByTiger('scriptCarController', 'updataScripCar', 'scripCatId=' + selectList[0]['ID'] + '&carId=' + resData.model + '&serverId=' + resData.columnGroup[0].model).subscribe((result) => {
            if (this.util.Base_HasValue(result.error)) {
              this.confirmModal.show('error', {title: result.error.message});
            } else {
              pParentData.needSearch = true;
              this.util.Base_Checking_Java('执行成功');
            }
          });
        } else {
          return false;
        }

      }
    });
  }

  // 清空服务器
  clearServer(pParentData) {
    if (this.selectOneData(pParentData.data.data) != 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定清空该服务器吗？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptCarController', 'cleanServer', 'serverId=' + selectList[0]['ID']).subscribe((result) => {
          if (result) {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          } else {
            this.util.Base_JAVA_ERROR(result);
          }
        });
      }
    });
  }

  // 清空脚本车
  clearScriptCar(pParentData) {
    if (this.selectOneData(pParentData.data.data) != 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定清空该脚本车吗？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptCarController', 'clearScriptCar', 'id=' + selectList[0]['ID']).subscribe((result) => {
          if (this.util.Base_HasValue(result.error)) {
            this.confirmModal.show('error', {title: result.error.message});
          } else {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          }
        });
      }
    });
  }

  // 脚本调度
  dispatch(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let ok = true;
    let ids = [];
    _.each(selectList, (oneData, key) => {
      if (oneData['SCRIPT_STATE'] != 'PASS' || oneData['DISPATCH_STATE'] != 'NOT_DISPATCH') {
        ok = false;
        return;
      } else {
        ids.push(oneData['ID']);
      }
    });
    if (!ok) {
      this.confirmModal.show('error', {title: '状态为审核通过,调度状态为未调度才可以执行该操作'});
      return;
    }
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '是否进行脚本调度？',
      suc: () => {
        this.requestDataService.doActionByTiger('scriptCarController', 'scriptDispatch', 'ids=' + ids.join(',')).subscribe((result) => {
          if (this.util.Base_HasValue(result.error)) {
            this.confirmModal.show('error', {title: result.error.message});
          } else {
            pParentData.needSearch = true;
            this.util.Base_Checking_Java('执行成功');
          }
        });
      }
    });

  }

  // 脚本取消
  scriptCancel(pObject) {
    if (this.selectMore(pObject.data.data) < 1) {
      return false;
    }
    let selectList = _.filter(pObject.data.data, (item) => {
      return item['checked'];
    });
    let ok = true;
    let ids = [];
    let siteIds = [];
    let stopDatas = []; // 需要停用的脚本id;
    _.each(selectList, (oneData, key) => {
      if (oneData['SCRIPT_STATE'] === 'ALEADY_DISTRIBUTION' || oneData['SCRIPT_STATE'] === 'NO_AUDIT' || oneData['SCRIPT_STATE'] === 'REJECT') {
        ids.push(oneData['ID']);
        siteIds.push(oneData['SITE_ID']);
        if (oneData['ENABLE_STATE'] === 'ENABLE') {
          stopDatas.push(oneData);
        }
      } else {
        ok = false;
        return;
      }
    });
    if (!ok) {
      this.confirmModal.show('error', {title: '状态为已分配、待审核、或者审核未通过才可以执行该操作'});
      return;
    }
    let id = ids.join('\',\'');
    id = '(\'' + id + '\')';
    siteIds = _.uniq(siteIds);
    let siteId = siteIds.join('\',\'');
    siteId = '(\'' + siteId + '\')';
    this.modalService.create({
      nzTitle: '取消脚本',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'CANCEL_DESC',
              'name': 'CANCEL_DESC',
              'component': 'textarea',
              'customize': 24,
              'lable': '取消原因',
              'comment': '取消原因',
              'placeholder': '请输入取消原因',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        if (!this.util.Base_HasValue(data[0].model)) {
          this.confirmModal.show('error', {title: '取消原因为必填'});
          return false;
        }
        let records = [];
        _.each(stopDatas, item => {
          records.push({
            'ID': 'SYS_UUID',
            'SCRIPT_ID': item['ID'],
            'SITE_ID': item['SITE_ID'],
            'SITE_NAME': item['SITE_NAME'],
            'CHANNEL_NAME': item['CHANNEL_NAME'],
            'SUBJECT_ID': item['SUBJECT_ID'],
            'SUBJECT_NAME': item['SUBJECT_NAME'],
            'SCRIPT_NAME': item['SCRIPT_NAME'],
            'SCRIPT_URL': item['SCRIPT_URL'],
            'ORDER_TYPE': 'DISABLE',
            'ADD_USERID': this.user.USER_ID,
            'ADD_NAME': this.user.USERNAME,
            'ADD_TIME': 'SYS-DATETIME',
            'U_TIME': 'SYS-DATETIME'
          });
        });
        let templateJson = {
          'LIST': [
            {
              'TABLE': 'hl_script',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': id,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'SCRIPT_STATE'},
                {'COLUMN': 'ENABLE_STATE'},
                {'COLUMN': 'CANCEL_DESC'}
              ],
              'RECORDS': [
                {
                  'SCRIPT_STATE': 'CANCELED',
                  'ENABLE_STATE': 'NO_ENABLE',
                  'CANCEL_DESC': data[0].model
                }
              ]
            },
            {
              'TABLE': 'hl_order',
              'ACTION': 'Insert',
              'WHEREGROUP': [{}],
              'WHERE': [{}],
              'COLUMNS': [
                {'COLUMN': 'ID'},
                {'COLUMN': 'SCRIPT_ID'},
                {'COLUMN': 'SITE_ID'},
                {'COLUMN': 'SITE_NAME'},
                {'COLUMN': 'CHANNEL_NAME'},
                {'COLUMN': 'SUBJECT_ID'},
                {'COLUMN': 'SUBJECT_NAME'},
                {'COLUMN': 'SCRIPT_NAME'},
                {'COLUMN': 'SCRIPT_URL'},
                {'COLUMN': 'ORDER_TYPE'},
                {'COLUMN': 'ADD_USERID'},
                {'COLUMN': 'ADD_TIME'},
                {'COLUMN': 'ADD_NAME'},
                {'COLUMN': 'U_TIME'}
              ],
              'RECORDS': records
            }
          ]
        };
        return this.autoInsert.ExecJson(templateJson).subscribe((response) => {
          if (response['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '执行成功',
              suc: () => {
                // 判断站点下的脚本是否全部取消，如果全部取消则给站点做取消。
                this.dataService.siteUpdate(siteId, siteIds, ids);
                pObject.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '保存失败'});
          }
        });
      }
    });

  }


  // 站点审核通过
  siteAudit(pParentData) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    let ids = [];
    _.each(selectList, item => {
      flag = flag && item['SITE_STATE'] === 'NO_AUDIT';
      if (flag) {
        ids.push(item['ID']);
      }
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '只有待审核的站点才可以审核',
      });
      return false;
    }
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '是否审核通过？',
      suc: () => {
        this.dataService.siteAudit(ids).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '审核成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '审核失败，请刷新重试！'});
          }
        });
      }
    });

    return false;
  }

  // 站点审核不通过
  siteReject(pParentData) {
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    let ids = [];
    _.each(selectList, item => {
      flag = flag && item['SITE_STATE'] === 'NO_AUDIT';
      if (flag) {
        ids.push(item['ID']);
      }
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '只有待审核的站点才可以审核',
      });
      return false;
    }
    this.modalService.create({
      nzTitle: '是否审核不通过？',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REJECT_REASON',
              'name': 'REJECT_REASON',
              'component': 'textarea',
              'customize': 24,
              'lable': '原因',
              'comment': '原因',
              'placeholder': '请输入不通过原因',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (result) => {
        if (result.childForm._validateForm.invalid) {
          for (const key in result.childForm._validateForm.controls) {
            result.childForm._validateForm.controls[key].markAsDirty();
            result.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (result.childForm._validateForm.valid) {
          let data = result.modalFormData.columns;
          this.dataService.siteReject(ids, data[0].model).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '审核成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
            } else {
              this.confirmModal.show('error', {title: '审核失败，请刷新重试！'});
            }
          });
        } else {
          return false;
        }

      }
    });

    return false;
  }

  // 收集支付
  collectPay(pParentData): void {
    let flag = true;
    let selectList: any;
    let sumMoney = 0;
    if (this.selectMore(pParentData.data.data) < 1) {
      return;
    }
    selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, oneData => {
      if (oneData['COLLECT_PAY_STATE'] != 'NO_PAY') {
        flag = false;
        return false;
      }
      sumMoney += oneData['COLLECT_COST'];
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为未支付的数据才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '站点支付',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'COLLECT_COST',
              'name': 'COLLECT_COST',
              'component': 'text',
              'customize': 24,
              'lable': '预计支付费用',
              'comment': '预计支付费用',
              'placeholder': '预计支付费用',
              'model': sumMoney + '元',
              'className': 'grab-cost',
              'visible': true,
              'require': false
            },
            {
              'column': 'COLLECT_ACTUAL_COST',
              'name': 'COLLECT_ACTUAL_COST',
              'component': 'input',
              'customize': 24,
              'type': 'number',
              'lable': '实际支付费用',
              'comment': '实际支付费用',
              'placeholder': '请填写实际支付费用',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        if (!this.util.Base_HasValue(data[1].model)) {
          this.confirmModal.show('error', {title: '实际支付费用为必填'});
          return false;
        }
        if (data[1].model != sumMoney && selectList.length > 1) {
          this.confirmModal.show('error', {title: '实际支付费用不等于预计支付费用不可以执行批量操作。'});
          return false;
        }
        this.dataService.collectPay(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '支付成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '支付失败，请刷新重试！'});
          }
        });
      }
    });
  }

  // 抓取支付
  scriptPay(pParentData): void {
    let flag = true;
    let selectList: any;
    let sumMoney = 0;
    if (this.selectMore(pParentData.data.data) < 1) {
      return;
    }
    selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    _.each(selectList, oneData => {
      if (oneData['GRAB_PAY_STATE'] != 'NO_PAY') {
        flag = false;
        return false;
      }
      sumMoney += oneData['GRAB_COST'];
    });
    if (!flag) {
      return this.confirmModal.show('error', {title: '状态为未支付的数据才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '站点支付',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'GRAB_COST',
              'name': 'GRAB_COST',
              'component': 'text',
              'customize': 24,
              'lable': '预计支付费用',
              'comment': '预计支付费用',
              'placeholder': '预计支付费用',
              'model': sumMoney + '元',
              'className': 'grab-cost',
              'visible': true,
              'require': false
            },
            {
              'column': 'GRAB_ACTUAL_COST',
              'name': 'GRAB_ACTUAL_COST',
              'component': 'input',
              'customize': 24,
              'type': 'number',
              'lable': '实际支付费用',
              'comment': '实际支付费用',
              'placeholder': '请填写实际支付费用',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        if (!this.util.Base_HasValue(data[1].model)) {
          this.confirmModal.show('error', {title: '实际支付费用为必填'});
          return false;
        }
        if (data[1].model != sumMoney && selectList.length > 1) {
          this.confirmModal.show('error', {title: '实际支付费用不等于预计支付费用不可以执行批量操作。'});
          return false;
        }
        this.dataService.scriptPay(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '支付成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '审核失败，请刷新重试！'});
          }
        });
      }
    });
  }

  mackConditon(pParentData): void {
    function setDetailConditons(pData) {
      function isCondtion(OneColumn) {
        let bFind = false;
        if (_.has(OneColumn, 'iscondition')) {
          bFind = OneColumn.iscondition;
        }
        return bFind;
      }

      let conData;
      _.each(pData, function (oneData) {
        if (oneData['checked']) {
          conData = oneData;
        }
      });
      let tmp = {};
      _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
        //只将 iscondition 为 true 的作为条件传入详情页面。
        if (isCondtion(OneColumn)) {
          tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
        }
      });
      return tmp;
    }

    pParentData.detailCondition = setDetailConditons(pParentData.data.data);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doDetailAction(pBtn, parentData, mainForm) {
    switch (pBtn.ACTION_VALUE) {
      case 'siteSave': // 站点保存修改
        // 存在多个mainForm情况需要循环result
        let flag = true;
        for (let i = 0; i < mainForm['_results'].length; i++) {
          const form = mainForm['_results'][i];
          if (form['_validateForm']['status'] === 'PENDING') {
            flag = false;
            break;
          }
          if (form['_validateForm']['invalid']) {
            for (const key in form['_validateForm']['controls']) {
              form['_validateForm']['controls'][key].markAsDirty();
              form['_validateForm']['controls'][key].updateValueAndValidity();
            }
          }
          flag = form['_validateForm']['valid'] && flag;
        }
        if (flag) {
          let pass = true;
          // 深度拷贝后隔绝开。避免数据乱掉
          let forms = this.util.DeepCopy(parentData.data.detailColumns);
          let siteName = '';
          let subjectId = '';
          let subjectName = '';
          let siteClass = '';
          let siteUrl = '';
          let siteScriptUrl = '';
          _.each(forms, (form, index) => {
            if (index === 0) {
              let siteState = '';
              _.each(form['columns'], column => {
                if (column['column'] === 'SITE_NAME') {
                  siteName = column['model'];
                }
                if (column['column'] === 'SITE_CLASS') {
                  siteClass = column['model'];
                }
                if (column['column'] === 'SITE_URL') {
                  siteUrl = column['model'];
                }
                if (column['column'] === 'SITE_SCRIPT_URL') {
                  siteScriptUrl = column['model'];
                }
                // 如果审核不通过状态，修改状态为待审核并清空拒绝原因
                if (parentData.modeType === 'modify') {
                  if (column['column'] === 'SITE_STATE') {
                    siteState = column['model'];
                    if (siteState === 'REJECTED') {
                      column['model'] = 'NO_AUDIT';
                    }
                  }
                  if (column['column'] === 'REJECT_REASON' && siteState !== 'REJECTED') {
                    column['model'] = '';
                  }
                  if (column['column'] === 'SUBJECT_ID') {
                    subjectId = column['model'];
                  }
                  if (column['column'] === 'SUBJECT_NAME') {
                    subjectName = column['model'];
                  }
                }
              });
            }
            if (index > 0) {
              if (siteClass === 'SENIOR') {
                form['data'] = [];
              } else {
                _.each(form['data'], item => {
                  item['SCRIPT_NAME'] = siteName + '_' + item['CHANNEL_NAME'] + '_v1.1';
                  // 修改需要重置这些值
                  if (parentData.modeType === 'modify') {
                    item['SITE_NAME'] = siteName;
                    item['SUBJECT_ID'] = subjectId;
                    item['SUBJECT_NAME'] = subjectName;
                  }
                });
              }
            }
            if (form['type'] === 'tablelist' && form['require'] && siteClass === 'NORMAL') {
              if (!form['data'] || !form['data'].length) {
                this.confirmModal.show('error', {
                  title: '温馨提示',
                  content: form['title'] + '不能为空！'
                });
                pass = false;
                return;
              }
            }
          });
          if (siteClass === 'SENIOR') {
            if (!this.util.Base_HasValue(siteUrl)) {
              this.confirmModal.show('error', {
                title: '温馨提示',
                content: '站点URL不能为空！'
              });
              pass = false;
              return;
            }
            if (!this.util.Base_HasValue(siteScriptUrl)) {
              this.confirmModal.show('error', {
                title: '温馨提示',
                content: '站点脚本模板不能为空！'
              });
              pass = false;
              return;
            }
          }
          if (pass) {
            let tmpJson = '';
            if (parentData.modeType === 'add') {
              tmpJson = this.autoInsert.AdapterInsert(forms);
            } else {
              tmpJson = this.autoInsert.AdapterUpdate(forms);
            }
            this.autoInsert.ExecJson(tmpJson).subscribe(response => {
              if (response['state'] === 'success') {
                this.confirmModal.show('success', {
                  'title': '温馨提示',
                  'content': '执行成功'
                });
                this.transformData('listForm', parentData);
              } else {
                this.confirmModal.show('error', {
                  'title': '温馨提示',
                  'content': '保存失败'
                });
              }
              return;
            });
          }
        }
        break;
      case 'saveScriptCar': // 脚本车保存修改
        this.saveScriptCar(mainForm, parentData);
        break;
      case 'headUserSave': // 机构用户保存
        this.headUserSave(mainForm, parentData);
        break;
    }
  }

  headUserSave(mainForm, parentData) {
    // 存在多个mainForm情况需要循环result
    let flag = true;
    for (let i = 0; i < mainForm['_results'].length; i++) {
      const form = mainForm['_results'][i];
      if (form['_validateForm']['status'] === 'PENDING') {
        flag = false;
        break;
      }
      if (form['_validateForm']['invalid']) {
        for (const key in form['_validateForm']['controls']) {
          form['_validateForm']['controls'][key].markAsDirty();
          form['_validateForm']['controls'][key].updateValueAndValidity();
        }
      }
      flag = form['_validateForm']['valid'] && flag;
    }
    if (flag) {
      let dataArray = [];
      let pass = true;
      _.each(mainForm._results[0].forms, (form) => {
        if (form['type'] === 'tablelist' && form['require']) {
          if (!form['data'] || !form['data'].length) {
            this.confirmModal.show('warning', {title: form['title'] + '不能为空！'});
            pass = false;
            return false;
          }
        }
        if (form['type'] === 'tablelist' && form['tablename'] === 'hl_org_subscription') {
          _.each(form['data'], (oneData) => {
            dataArray.push(oneData['SUBJECT_ID']);
          });
        }
      });
      if (this.util.Base_isRepeat(dataArray)) {
        this.confirmModal.show('warning', {title: '请勿选择重复的学科!'});
        return false;
      }
      if (pass) {
        if (parentData.modeType === 'add') {
          var tmpJson = this.autoInsert.AdapterInsert(mainForm._results[0].forms);
          this.autoInsert.ExecJson(tmpJson).subscribe((response) => {
            if (response['state'] === 'success') {
              this.confirmModal.show('success', {
                'title': '温馨提示',
                'content': '保存成功'
              });
              this.transformData('listForm', parentData);
            } else {
              this.confirmModal.show('error', {
                'title': '温馨提示',
                'content': '保存失败'
              });
            }
            return;
          });
        } else {
          const tmpJson = this.autoInsert.AdapterUpdate(mainForm._results[0].forms);
          this.autoInsert.ExecJson(tmpJson).subscribe((response) => {
            if (response['state'] === 'success') {
              this.confirmModal.show('success', {
                'title': '温馨提示',
                'content': '保存成功'
              });
              this.transformData('listForm', parentData);
            } else {
              this.confirmModal.show('error', {
                'title': '温馨提示',
                'content': '保存失败'
              });
            }
            return;
          });
        }
      }
    }
  }

  // 脚本车保存修改
  saveScriptCar(mainForm, parentData) {
    // 存在多个mainForm情况需要循环result
    let flag = true;
    for (let i = 0; i < mainForm['_results'].length; i++) {
      const form = mainForm['_results'][i];
      if (form['_validateForm']['status'] === 'PENDING') {
        flag = false;
        break;
      }
      if (form['_validateForm']['invalid']) {
        for (const key in form['_validateForm']['controls']) {
          form['_validateForm']['controls'][key].markAsDirty();
          form['_validateForm']['controls'][key].updateValueAndValidity();
        }
      }
      flag = form['_validateForm']['valid'] && flag;
    }
    if (flag) {
      let pass = true;
      // 深度拷贝后隔绝开。避免数据乱掉
      let forms = this.util.DeepCopy(parentData.data.detailColumns);
      let siteName = '';
      let subjectId = '';
      let subjectName = '';
      _.each(forms, (form, index) => {
        if (form['type'] === 'tablelist' && form['require']) {
          if (!form['data'] || !form['data'].length) {
            this.confirmModal.show('error', {
              title: '温馨提示',
              content: form['title'] + '不能为空！'
            });
            pass = false;
            return;
          }
        }
      });
      if (pass) {
        // 服务器信息
        const serverColumn = _.find(forms[0].columns, item => item['column'] === 'SERVER_ID');
        const serverId = serverColumn['model'];
        const server = _.find(serverColumn['itemArray'], item => item['code'] === serverId);
        const max = server['MAX_NUM'] || 0;
        // 当前填写最大容量
        const maxNum: any = _.find(forms[0].columns, item => item['column'] === 'MAX_SCRIPT_NUM');
        const currentNum = maxNum['model'] || 0;
        if (currentNum <= 0) {
          this.confirmModal.show('warning', {
            'title': '温馨提示',
            'content': '最大脚本容量必须大于0'
          });
          return;
        }
        // 查询该服务器下已有脚本车容量
        // 如果是修改。查询的时候需要剔除掉当前数据
        const condition = {
          ID: serverColumn['model']
        };
        if (parentData.modeType === 'modify') {
          // 服务器信息
          let column = _.find(forms[0].columns, item => item['column'] === 'ID');
          condition['CAR_ID'] = column['model'];
        }
        this.requestDataService.getDataByCondition('headLine/hl/hl_server_num.json', condition).subscribe(result => {
          let data = result.data[0] || {};
          let sum = data['MAX_SCRIPT_NUM'] || 0;
          // 最大运力-先有数量-总量
          let rest = max - sum - currentNum;
          if (rest >= 0) {
            let tmpJson = '';
            if (parentData.modeType === 'add') {
              tmpJson = this.autoInsert.AdapterInsert(forms);
              tmpJson['LIST'].push({
                'TABLE': 'hl_server',
                'ACTION': 'Update',
                'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                'WHERE': [{
                  'COLUMN': 'ID',
                  'VALUE': serverColumn['model'],
                  'OPERATOR': '=',
                  'VALUE_TYPE': 'string',
                  'CONDITION_OPERATOR': 'AND',
                  'GROUP': 'GROUP_01'
                },
                  {
                    'COLUMN': 'SERVER_STATE',
                    'VALUE': 'ADD',
                    'OPERATOR': '=',
                    'VALUE_TYPE': 'string',
                    'CONDITION_OPERATOR': 'AND',
                    'GROUP': 'GROUP_01'
                  }],
                'COLUMNS': [
                  {'COLUMN': 'SERVER_STATE'},
                  {'COLUMN': 'UPD_TIME'},
                  {'COLUMN': 'UPD_NAME'},
                  {'COLUMN': 'UPD_USERID'},
                  {'COLUMN': 'U_TIME'}
                ],
                'RECORDS': [
                  {
                    'SERVER_STATE': 'NOT_RUN',
                    'UPD_TIME': 'SYS-DATETIME',
                    'UPD_NAME': this.user.USERNAME,
                    'UPD_USERID': this.user.USER_ID,
                    'U_TIME': 'SYS-DATETIME'
                  }
                ]
              });
            } else {
              tmpJson = this.autoInsert.AdapterUpdate(forms);
            }
            this.autoInsert.ExecJson(tmpJson).subscribe(response => {
              if (response['state'] === 'success') {
                this.confirmModal.show('success', {
                  'title': '温馨提示',
                  'content': '保存成功'
                });
                this.transformData('listForm', parentData);
              } else {
                this.confirmModal.show('error', {
                  'title': '温馨提示',
                  'content': '保存失败'
                });
              }
              return;
            });
          } else {
            let num = +currentNum + rest;
            let content = '最大脚本容量必须小于等于' + num;
            if (num === 0) {
              content = '该服务器下脚本车容量已满，不能再添加';
            }
            this.confirmModal.show('warning', {
              title: '温馨提示',
              content: content
            });
          }
        });
      }
    }
  }

  clickVolume(result, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let data = result.modalFormData.columns;
      let dataObject = {
        'dataObject': {
          'PF': this.config.config['MysqlPF'],
          'userId': this.userInfo.getUserInfo().USER_ID,
          'userName': this.userInfo.getUserInfo().USERNAME,
          'orgId': data[0].model,
          'orgName': data[0].modelName,
          'viewsNum': data[1].model
        }
      };
      this.requestDataService.doActionByHdbsthorJson('hdbsthor-headline', 'UserController', this.config.config['MysqlPF'] + '/pageView', dataObject).subscribe(
        (response) => {
          if (response) {
            this.confirmModal.show('success', {
              title: '保存成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '保存失败，请刷新重试！'});
          }
        });
    } else {
      return false;
    }
  }

  // 脚本管理列表通过审核并调度
  scriptPass(pListData, pParentData) {
    let ok = true;
    let ids = [];
    _.each(pListData, (oneData, key) => {
      if (oneData['SCRIPT_STATE'] != 'NO_AUDIT') {
        ok = false;
        return;
      } else {
        ids.push(oneData['ID']);
      }
    });
    if (!ok) {
      this.confirmModal.show('error', {title: '状态为待审核才可以执行该操作'});
      return;
    }
    this.confirmModal.show('confirm',
      {
        title: '所选脚本审核通过并调度',
        suc: () => {
          this.requestDataService.doActionByTiger('scriptCarController', 'saveScriptCar', 'ids=' + ids.join(',')).subscribe((result) => {
            if (this.util.Base_HasValue(result.error)) {
              this.confirmModal.show('error', {title: result.error.message});
            } else {
              pParentData.needSearch = true;
              this.util.Base_Checking_Java('执行成功');
            }
          });
        }
      });
  }


  // 启用站点
  enableSite(siteIds, pParentData) {
    this.requestDataService.getDataByCondition('headLine/hl/hl_script_detail.json', {
      'SITE_ID_ARRY': siteIds,
      'DISPATCH_STATE': 'NOT_DISPATCH',
      'SCRIPT_STATE': 'PASS'
    }).subscribe(result => {
      let ids = [];
      _.each(result.data, item => {
        ids.push(item['ID']);
      });
      if (!ids.length) {
        this.confirmModal.show('success', {
          title: '保存成功',
          content: '站点下没有脚本需要调度'
        });
        return;
      }
      // 调用脚本调度接口
      this.requestDataService.doActionByTiger('scriptCarController', 'scriptDispatch', 'ids=' + ids.join(',')).subscribe((result) => {
        if (this.util.Base_HasValue(result.error)) {
          this.confirmModal.show('error', {title: result.error.message});
        } else {
          pParentData.needSearch = true;
          this.util.Base_Checking_Java('执行成功');
        }
      });
    });
  }

  // 停用站点
  disableSite(siteIds, pParentData) {
    this.requestDataService.getDataByCondition('headLine/hl/hl_script_detail.json', {
      'SITE_ID_ARRY': siteIds,
      'DISPATCH_STATE': 'DISPATCH',
      'SCRIPT_STATE': 'PASS'
    }).subscribe(result => {
      let ids = [];
      _.each(result.data, item => {
        ids.push(item['ID']);
      });
      if (!ids.length) {
        this.confirmModal.show('success', {
          title: '保存成功',
          content: '站点下没有脚本需要取消调度'
        });
        return;
      }
      // 调用脚本调度接口
      this.requestDataService.doActionByTiger('scriptCarController', 'cancelDispatch', 'ids=' + ids.join(',')).subscribe((result) => {
        if (this.util.Base_HasValue(result.error)) {
          this.confirmModal.show('error', {title: result.error.message});
        } else {
          pParentData.needSearch = true;
          this.util.Base_Checking_Java('执行成功');
        }
      });
    });
  }

  // 分配人员
  assign(result, idList, pParentData) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      this.dataService.assign(idList, assignObj).subscribe((response) => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            title: '保存成功',
            suc: () => {
              pParentData.needSearch = true;
            }
          });
        } else {
          this.confirmModal.show('error', {title: '保存失败'});
        }
      });
    } else {
      return false;
    }
  }

  // 提交审核 上传脚本
  confirmAudit(pObject, pParentData): boolean {
    if (this.util.Base_HasValue(pObject.SCRIPT_URL)) {
      this.confirmModal.show('confirm', {
        title: '提交审核后，脚本不能再修改请确保脚本已通过自测。是否提交审核？',
        suc: () => {
          let templateJson = {
            'LIST': [
              {
                'TABLE': 'hl_script',
                'ACTION': 'Update',
                'WHEREGROUP': [{
                  'GROUP': 'GROUP_01',
                  'CONDITION_OPERATOR': 'AND'
                }],
                'WHERE': [{
                  'COLUMN': 'ID',
                  'VALUE': pObject.ID,
                  'OPERATOR': '=',
                  'VALUE_TYPE': 'string',
                  'CONDITION_OPERATOR': 'AND',
                  'GROUP': 'GROUP_01'
                }],
                'COLUMNS': [
                  {'COLUMN': 'SCRIPT_STATE'},
                  {'COLUMN': 'SCRIPT_URL'},
                  {'COLUMN': 'SCRIPT_UPLOAD_TIME'},
                  {'COLUMN': 'SCRIPT_UPLOAD_USER'},
                  {'COLUMN': 'SCRIPT_UPLOAD_USER_NAME'}
                ],
                'RECORDS': [
                  {
                    'SCRIPT_STATE': 'NO_AUDIT',
                    'SCRIPT_URL': pObject['SCRIPT_URL'],
                    'SCRIPT_UPLOAD_TIME': 'SYS-DATETIME',
                    'SCRIPT_UPLOAD_USER': this.userInfo.getUserInfo().USER_ID,
                    'SCRIPT_UPLOAD_USER_NAME': this.userInfo.getUserInfo().USERNAME
                  }
                ]
              }
            ]
          };
          this.autoInsert.ExecJson(templateJson).subscribe((response) => {
            if (response['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '保存成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
              this.modalService.closeAll();
            } else {
              this.confirmModal.show('error', {title: '保存失败'});
            }
          });
        }
      });

    } else {
      this.confirmModal.show('error', {title: '请上传文件'});
      return false;
    }
    return false;
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount == 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
