import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
import {RequestDataService} from '../../service/request-data.service';

@Component({
  selector: 'app-select-json',
  templateUrl: './select-json.component.html',
  styleUrls: ['./select-json.component.css']
})
export class SelectJsonComponent implements OnInit {

  /**
   *"id": "college",
   "title": "学院",
   "type": "select",
   "value": "",
   "dictionary": "resume/basic/basic_ogra_two.json"
   */
  @Input() dataObject: any;
  selectData = [];

  constructor(private util: UtilsService,
              private requestService: RequestDataService) {

  }

  ngOnInit() {
    let fileUrl = this.dataObject.dictionary;
    if(this.util.Base_HasValue(fileUrl)){
      this.requestService.getDataByCondition(fileUrl).subscribe(res => {
        if (this.util.Base_HasValue(res) && this.util.Base_HasValue(res['data'])) {
          this.selectData = [...res['data']];
        }
      });
    }
  }

}
