import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import * as _ from "lodash";
import {forkJoin as observableForkJoin} from "rxjs";
import {AutoInsertService} from "../../service/auto-insert.service";
import {StorageService} from "../../service/storage.service";
import {UserInfoService} from "../../service/user-info.service";
import {UploadService} from "../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../service/data-center.service";
import {NzFormatEmitEvent} from "ng-zorro-antd";
import {UtilsService} from "../../service/utils.service";

@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.css']
})
export class TreeSelectComponent implements OnInit {

  @Input() isMulti = false;
  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  @Input() forms: any;
  @Input() oneForms: any;

  // 是否显示弹框
  public isVisible = false;
  nodes: any = [];
  expandAll = false;
  treeData: any;
  treeDict: any;
  selectNode: any;
  defaultSelectedKeys = [];
  searchValue = '';
  @ViewChild('childForm', {static: false}) childForm;

  modelOk() {
    const name = _.get(this.selectNode,['origin','linkNameStr']);
    const code = _.get(this.selectNode,['origin','linKeyStr']);
    this.hulkColumn.model = code;
    this.hulkColumn.modelName =name;
    if (this.util.Base_HasValue(this.hulkColumn.columnGroup)
      && this.hulkColumn.columnGroup.length) {
      _.each(this.hulkColumn.columnGroup, (item) => {
        item['model'] = _.get(this.selectNode,item['fromValue']);
      });
    }
    this.isVisible = false;
  }

  modelCancel() {
    this.isVisible = false;
  }

  showModal() {
    if (this.hulkColumn.disable) {
      return false;
    }
    observableForkJoin(
      this.requestDataService.getDataByJson(this.hulkColumn.dictionary),
      this.requestDataService.getDataByCondition(this.hulkColumn.dictionary, {})
    ).subscribe(
      values => {
        this.treeData = values[0]['detailColumns'];
        this.treeDict = values[0]['treeDict'];
        this.isVisible = true;
        this.makeTreeData(values[1]['data']);
        this.selectNode = null;
        this.defaultSelectedKeys = [];
      }
    );
  }

  constructor(
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService
  ) {

  }

  ngOnInit() {

  }

  makeTreeData(pData) {
    let that = this;

    function makeUpLeftData(oneItem, pParentsNode?) {
      let linKey = _.split(oneItem[that.treeDict['linKey']], ',');
      let obj = {
        title: oneItem[that.treeDict['title']],
        key: oneItem[that.treeDict['key']],
        isLeaf: oneItem[that.treeDict['isLeaf']] == 'LAST_STAGE',
        level: parseInt(oneItem[that.treeDict['level']]) - 1,
        upKey: oneItem[that.treeDict['upKey']],
        upName: oneItem[that.treeDict['upName']],
        linKey: linKey,
        linKeyStr: _.join(linKey),
        expanded: true,
        datas: oneItem
      }
      return obj;
    }

    /**
     * 组织一级数据
     * @param pData
     */
    function getNodeDatas(pData) {
      let nodeDatas = [];
      if (that.util.Base_HasValue(that.treeDict)) {
        let list = _.filter(pData, (item) => {
          let level = item[that.treeDict['level']];
          if (typeof (level) == 'number') {
            level = level.toString();
          }
          return level == '1';
        });
        _.each(list, oneItem => {
          let children = getChildren(pData, oneItem);
          let obj = makeUpLeftData(oneItem);
          obj['children'] = children;
          nodeDatas.push(obj);
        });
      }
      return nodeDatas;
    }

    /**
     * 组织二级，及二级一下的数据
     * @param pData
     * @param pParentsNode
     */
    function getChildren(pData?, pParentsNode?) {
      let list = _.filter(pData, (item) => {
        return item[that.treeDict['upKey']] == pParentsNode[that.treeDict['key']];
      });
      let nodeDatas = [];
      _.each(list, oneItem => {
        let obj = makeUpLeftData(oneItem, pParentsNode);
        let children = getChildren(pData, oneItem);
        obj['children'] = children;
        nodeDatas.push(obj);
      });
      return nodeDatas;
    }

    function setLinkNameMain(pNodeList, pParentsNode?) {
      _.each(pNodeList, oneNode => {
        setLinkName(oneNode, pParentsNode);
        let childNoneList = _.get(oneNode, ['children'], null);
        if (that.util.Base_HasValue(childNoneList)) {
          setLinkNameMain(childNoneList, oneNode);
        }
      })
    }

    function setLinkName(pOneNode, pParentsNode?) {
      if (!that.util.Base_HasValue(pParentsNode)) {
        pOneNode['linkName'] = [pOneNode['title']];
      } else {
        pOneNode['linkName'] = _.concat(pParentsNode['linkName'],[pOneNode['title']]);
      }
      pOneNode['linkNameStr'] = _.join(pOneNode['linkName'], " / ");
    }

    this.nodes = [];
    let nodeDatas = getNodeDatas(pData);
    this.nodes = nodeDatas;
    setLinkNameMain(this.nodes);
  }





  //清空poplist值
  removeValue(): void {
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = null;
      this.bindModel[this.hulkColumn.column + 'Name'] = '';
    } else {
      this.hulkColumn.model = null;
      this.hulkColumn.modelName = '';
    }
    // 先判断是否有属性配置，如果有下一级，直接清空
    const dictParam = this.util.Base_getJsonValue(this.hulkColumn, 'dictionaryParam', null);
    if (this.util.Base_HasValue(dictParam)) {
      _.each(this.hulkColumn.dictionaryParam, oneDict => {
        const clearValue = this.util.Base_getJsonValue(oneDict, 'clearValues', null);
        if (this.util.Base_HasValue(clearValue)) {
          _.each(oneDict['clearValues'], oneClearColumn => {
            this.clearChildColumnValue(oneClearColumn);
          });
        }
      });
    }
    // 判断columnGroup
    if (this.util.Base_HasValue(this.hulkColumn['columnGroup']) && this.hulkColumn['columnGroup'].length > 0) {
      _.each(this.hulkColumn.columnGroup, oneData => {
        oneData['model'] = null;
      });
    }
  }


  // 变更上级，自动清空下级字段值
  clearChildColumnValue(clearValue): void {
    const that = this;

    function clearForm(targetTable, targetColum) {
      for (let i = 0; i < that.forms.length; i++) {
        if (that.util.Base_compare(that.forms[i].tablename, targetTable)) {
          _.each(that.forms[i].columns, function (oneColumn) {
            if (that.util.Base_compare(oneColumn['column'], targetColum)) {
              oneColumn['model'] = null;
              oneColumn['dicVal'] = null;
              if (that.util.Base_HasValue(oneColumn['columnGroup'])) { //如果被清空字段有子级字段,一并清空
                _.each(oneColumn['columnGroup'], function (oneCol) {
                  oneCol['model'] = null;
                });
              }
            }
          });
        }
      }
    }

    if (that.util.Base_HasValue(clearValue)) {
      const targetParam = clearValue.split('.');
      if (_.isArray(targetParam)) {
        if (that.util.Base_compare(targetParam[0], 'SYS')) {
          const targetTable = targetParam[1];
          const targetColum = targetParam[2];
          clearForm(targetTable, targetColum);
        }
      }
    }
  }

  doSelect(event: NzFormatEmitEvent) {
    let id = _.get(event, ['keys', 0]);
    this.selectNode = _.get(event, ['node']);
  }

  nzClick(event: NzFormatEmitEvent): void {
    this.doSelect(event);
  }


  ngDoCheck(): void {
    if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
      if (this.util.Base_HasValue(this.hulkColumn.model)) {
        const currentData = _.find(this.hulkColumn.itemArray, (item) => {
          return item['code'] === this.hulkColumn.model;
        });
        if (this.util.Base_HasValue(currentData)) {
          this.hulkColumn.modelName = currentData['name'];
        }
      } else {
        this.removeValue();
      }
      this.hulkColumn.oldModel = this.hulkColumn.model;
    }
  }

}
