import {Component, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';
import * as _ from 'underscore';
import * as echarts from 'echarts';
import {AjaxService} from '../../../service/ajax.service';
import {DataService} from '../data.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../service/request-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-digital-visual-report',
  templateUrl: './digital-visual-report.component.html',
  styleUrls: ['./digital-visual-report.component.css']
})
export class DigitalVisualReportComponent implements OnInit {
  isfull: any = false;
  relationList = [];
  entityLabels = [];
  attributesL: any;
  attributesR: any;
  data: any = {
    nodes: [],
    links: []
  };
  colors = ['#00EBFF', '#F79767', '#AF00FC', '#323FFE', '#4981eb', '#6cf0cd', '#f8bd53', '#f1825b', '#4b53d8'];
  colorGroup: any = {};
  echarRelation: any;
  sevenDays = [moment().subtract(6, 'days').format('MM-DD'),
    moment().subtract(5, 'days').format('MM-DD'),
    moment().subtract(4, 'days').format('MM-DD'),
    moment().subtract(3, 'days').format('MM-DD'),
    moment().subtract(2, 'days').format('MM-DD'),
    moment().subtract(1, 'days').format('MM-DD'),
    moment().format('MM-DD')];
  familyTree = 0; // 家谱
  stone = 0; // 碑刻
  documents = 0; // 文书
  poetry = 0; // 诗文
  multimedia = 0; // 多媒体
  left1: any; // 左1饼图
  left2: any; // 左2条形图
  left3: any; // 左3词云
  right1: any; // 右1柱状图
  right2: any; // 右2折线图
  right3: any; // 右3折线图

  constructor(private ajaxService: AjaxService,
              private dataService: DataService,
              private util: UtilsService,
              private requestData: RequestDataService) {
  }

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  diagram(pType?) {
    this.relationList = [];
    this.attributesL = null;
    this.attributesR = null;
    //ajax使用get方式请求api数据
    this.dataService.requestNeo4j('/Neo4jController/getLabels').subscribe(response => {
      this.entityLabels = response;
    });
    const data = {
      data: [
        {
          'type': 'SearchAll'
        }
      ]
    };
    this.dataService.requestNeo4j('/Neo4jController/autoExecute', data).subscribe(response => {
      if (this.util.Base_HasValue(response[0] && response[0]['data'])) {
        this.data = response[0]['data'];
        const labelGroup = _.groupBy(this.data.nodes, oneData => {
          return oneData['label'];
        });
        this.colorGroup = {};
        let i = 0;
        _.each(labelGroup, (value, key) => {
          if (i < this.colors.length) {
            this.colorGroup[key] = this.colors[i];
            i++;
          } else {
            i = 0;
            this.colorGroup[key] = this.colors[i];
          }
        });
        _.each(this.data.nodes, oneData => {
          oneData['itemStyle'] = {
            normal: {
              color: this.colorGroup[oneData['label']]
            }
          };
          oneData['showName'] = oneData['name'];
          oneData['name'] = oneData['uuidhdbs'];
        });
        _.each(this.data.links, item => {
          item['source'] = _.find(this.data.nodes, (n) => {
            return n['nodeId'] === item['sourceId'];
          })['name'];
          item['target'] = _.find(this.data.nodes, (n) => {
            return n['nodeId'] === item['targetId'];
          })['name'];
        });
        this.echarRelation = this.echarRelationshipDiagra();
      }
    });
  }

  /**
   * echarts关系图
   */
  echarRelationshipDiagra() {
    const option = {
      tooltip: {
        formatter: function (x) {
          return x.data.des;
        }
      },
      series: [
        {
          type: 'graph',
          layout: 'force',
          symbolSize: 50,
          roam: true,
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            normal: {
              textStyle: {
                fontSize: 12
              },
              show: true,
              formatter: function (x) {
                return x.data.type;
              }
            }
          },
          force: {
            repulsion: 500,
            edgeLength: [10, 50]
          },
          draggable: true,
          focusNodeAdjacency: true,
          itemStyle: {
            normal: {
              color: '#fff'
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#fff',
              curveness: 0.2
            }
          },
          label: {
            normal: {
              show: true,
              textStyle: {},
              formatter: function (params) {
                return params.data.showName;
              }
            }
          },
          nodes: this.data.nodes,
          links: this.data.links
        }
      ]
    };
    return option;
  }

  // 饼图
  pieChart(pData) {
    const option = {
      title: {
        text: pData['text'],
        left: 'left',
        padding: 15,
        textStyle: {
          color: '#fff',
          rich: {
            a: {
              color: '#FDCC28',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      color: this.colors,
      series: [
        {
          type: 'pie',
          radius: ['30%', '60%'],
          center: ['50%', '55%'],
          label: {
            normal: {
              show: true,
              formatter: '{b}:{d}%'
            }
          },
          data: pData['data']
        }
      ]
    };
    return option;
  }

  // 柱状图
  histogram(data) {
    const option = {
      title: {
        text: data.text,
        padding: 15,
        textStyle: {
          color: '#fff',
          rich: {
            a: {
              color: '#FDCC28',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        top: '20%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.name,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            rotate: 30,
            textStyle: {
              color: '#fff'
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#cbcbcb'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        }
      ],
      series: [
        {
          barMaxWidth: 20,
          type: 'bar',
          itemStyle: {
            normal: {
              barBorderRadius: 20,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#FDB628'},
                  {offset: 1, color: '#FDD528'}
                ]
              )
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#FDD528'},
                  {offset: 1, color: '#FDB628'}
                ]
              )
            }
          },
          data: data.value,
          label: {
            normal: {
              show: true,
              color: '#fff',
              position: 'top'
            }
          }
        }
      ]
    };
    return option;
  }

  // 折线图
  lineChart(pData) {
    const option = {
      title: {
        text: pData.text,
        padding: 15,
        textStyle: {
          color: '#fff',
          rich: {
            a: {
              color: '#FDCC28',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        top: '20%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          interval: 0,
          rotate: 30,
          textStyle: {
            color: '#fff'
          }
        },
        data: pData.name
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#cbcbcb'
          }
        }
      },
      series: [{
        smooth: true,
        data: pData.value,
        type: 'line',
        areaStyle: {
          color: pData.areaValue
        },
        itemStyle: {
          normal: {
            color: pData.colorValue
          }
        },
        label: {
          normal: {
            show: true,
            color: '#fff',
            position: 'top'
          }
        }
      }]
    };
    return option;
  }

  // 多条折线图
  multipleLineChart(pData) {
    const option = {
      title: {
        text: pData.text,
        padding: 15,
        textStyle: {
          color: '#fff',
          rich: {
            a: {
              color: '#FDCC28',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      color: this.colors,
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        bottom: 10,
        left: 'center',
        textStyle: {
          color: '#fff'
        },
        data: pData.legendData
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.sevenDays,
        axisLabel: {
          interval: 0,
          rotate: 30,
          textStyle: {
            color: '#fff'
          }
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#cbcbcb'
          }
        }
      },
      series: pData.series
    };
    return option;
  }

  // 词云
  setWordsCloudOption(data) {
    const option = {
      tooltip: {},
      series: [{
        type: 'wordCloud',
        gridSize: 8,
        sizeRange: [13, 40],
        rotationRange: [-90, 90],
        shape: 'pentagon',
        left: 'center',
        top: 'center',
        textStyle: {
          normal: {
            color: function () {
              return 'rgb(' + [
                Math.round(Math.random() * 255),
                Math.round(Math.random() * 255),
                Math.round(Math.random() * 255)
              ].join(',') + ')';
            }
          },
          emphasis: {
            shadowBlur: 10,
            shadowColor: '#333'
          }
        },
        data: data
      }]
    };
    return option;
  }

  leftOne(pData) {
    const data = {
      text: '各{a|站点}资源分布占比',
      data: []
    };
    _.each(pData['data'], oneData => {
      const d = {
        value: oneData['acount'],
        name: oneData['SITE_ID_DICT']
      };
      data.data.push(d);
    });
    this.left1 = this.pieChart(data);
  }

  // 右1柱状图
  rightOne(pData) {
    const data = {
      text: '{a|图片}资源分布',
      value: [],
      name: []
    };
    _.each(pData['data'], oneData => {
      data.value.push(oneData['acount']);
      data.name.push(oneData['SITE_ID_DICT']);
    });
    this.right1 = this.histogram(data);
  }

  // 左二折线图
  leftTwo(pData) {
    const data = {
      text: '每日{a|资源新增}情况',
      value: [],
      name: [],
      colorValue: '#E34958',
      areaColor: '#FE9560',
    };
    _.each(this.sevenDays, date => {
      let ok = false;
      data.name.push(date);
      _.each(pData['data'], oneData => {
        if (oneData['date'] === date) {
          ok = true;
          data.value.push(oneData['acount']);
        }
      });
      if (!ok) {
        data.value.push(0);
      }
    });
    this.left2 = this.lineChart(data);
  }

  // 右二折线图
  rightTwo(pData) {
    const data = {
      text: '各站点{a|每日发布}数量',
      legendData: [],
      name: [],
      series: [],
    };
    const objectArray = _.groupBy(pData['data'], oneData => {
      return oneData['SITE_ID_DICT'];
    });
    _.each(objectArray, (value, key) => {
      data.legendData.push(key);
      const lineData = {
        smooth: true,
        name: key,
        type: 'line',
        data: []
      };
      _.each(this.sevenDays, date => {
        let ok = false;
        _.each(value, oneData => {
          if (oneData['date'] === date) {
            ok = true;
            lineData.data.push(oneData['acount']);
          }
        });
        if (!ok) {
          lineData.data.push(0);
        }
      });
      data.series.push(lineData);
    });
    this.right2 = this.multipleLineChart(data);
  }

  // 左三词云
  leftThree(pData) {
    const data = {
      data: []
    };
    const arrayData = [];
    _.each(pData['data'], oneData => {
      _.each(oneData['RESOURCES_LABEL'].split('；'), item => {
        arrayData.push(item);
      });
    });
    const countNum = _.countBy(arrayData, oneData => {
      return oneData;
    });
    _.each(countNum, (value, key) => {
      const i = {
        value: value,
        name: key
      };
      data.data.push(i);
    });
    this.left3 = echarts.init(document.getElementById('wordsCloud'));
    this.left3.setOption(this.setWordsCloudOption(data.data));
  }

  ngOnInit() {
    require('echarts-wordcloud');
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dr/dr_resources_site_count.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_resources_site_count.json', {RESOURCES_TYPE: 'PICTURE'}),
      this.requestData.getDataByCondition('digitalResources/dr/dr_resources_add_count.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_resources_push_day.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_resources_label.json'),
    );
    search.subscribe(
      values => {
        this.diagram();
        _.each(values[0]['data'], oneData => {
          switch (oneData['SITE_ID_DICT']) {
            case '家谱':
              this.familyTree = oneData['acount'];
              break;
            case '碑刻':
              this.stone = oneData['acount'];
              break;
            case '文书':
              this.documents = oneData['acount'];
              break;
            case '诗文集':
              this.poetry = oneData['acount'];
              break;
            case '多媒体':
              this.multimedia = oneData['acount'];
              break;
          }
        });
        this.leftOne(values[0]);
        this.leftTwo(values[2]);
        this.rightOne(values[1]);
        this.rightTwo(values[3]);
        this.leftThree(values[4]);
      }
    );
  }

}
