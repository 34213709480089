import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UploadService} from '../../../service/upload.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {DataService} from '../../digitalResources/data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import * as Mtils from 'mtils';

@Component({
  selector: 'app-model-management',
  templateUrl: './model-management.component.html',
  styleUrls: ['./model-management.component.css']
})
export class ModelManagementComponent implements OnInit {

  @Input() parentData: any;

  modelState = []; // 状态

  modelType = []; // 类型

  columnType = []; // 字段类型

  required: boolean = false;

  selectedValue: any = null;

  basicModel: any = []; // 基础模型字典

  resourceType: any = []; // 资源类型字典

  enNameCheck = false;

  chooseThumbnail = false;

  data: any = {
    'modelName': null,
    'modelType': null,
    'modelState': 'ENABLE',
    'modelOrder': 1,
    'modelEnName': null,
    'modelId': '',
    'basicModelId': '',
    'groups': [
      {
        'type': null,
        'add': null,
        'groupOrder': null,
        'groupName': null,
        'groupId': null,
        'require': false,
        'columns': [],
        'data': []
      }]
  };

  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService,
              private fb: FormBuilder) {
  }

  chooseThumbnailFun(event) {
    if (event === 'isThumbnail') {
      this.chooseThumbnail = true;
    } else {
      this.chooseThumbnail = false;
    }
  }

  chooseType(event, group) {
    if (event === 'muchResourcesSelect' && group.isThumbnail === 'isThumbnail') {
      this.chooseThumbnail = false;
      group.isThumbnail = '';
    }
  }

  // 添加一个组
  addGroup() {
    let group: any = {
      'type': null,
      'add': null,
      'groupOrder': null,
      'groupName': null,
      'groupId': null,
      'require': false,
      'columns': [],
      'isThumbnail': '',
      'data': []
    };
    this.data.groups.push(group);
  }

  // 校验模型标识
  codeEnCheck() {
    const str = /^[a-zA-Z-_0-9]+$/;
    if (str.test(this.data.modelEnName)) {
      this.enNameCheck = false;
    } else {
      this.enNameCheck = true;
    }
  }

  // 删除一个组
  deleteGroup(pIndex) {
    if (pIndex > 0) {
      this.data.groups.splice(pIndex, 1);
    }
  }

  // 上移组
  upShiftGroup(pIndex, pObject) {
    if (pIndex > 0) {
      let group = this.data.groups[pIndex - 1];
      this.data.groups[pIndex - 1] = pObject;
      this.data.groups[pIndex] = group;
    }
  }

  // 下移组
  downShiftGroup(pIndex, pObject) {
    if (this.data.groups.length - 1 > pIndex) {
      let group = this.data.groups[pIndex + 1];
      this.data.groups[pIndex + 1] = pObject;
      this.data.groups[pIndex] = group;
    }
  }

  addColumn(pObject) {
    const column: any = {
      'column': null,
      'columnName': null,
      'component': null,
      'require': false,
      'showDetail': true,
      'maxLength': 0,
      'showContent': 0,
      'showAbstract': 0,
      'dictionary': null
    };
    pObject.columns.push(column);
  }

  // 添加一个字段
  editColumnType(pObject, pIndex) {
    if (pObject.component = 'input') {
      pObject.maxLength = 50;

    } else if (pObject.component === 'textarea') {
      pObject.maxLength = 500;
    } else if (pObject.component === 'multiSelect') {
      pObject.limitLength = 5;
    } else if (pObject.component === 'multipop') {
      pObject.limitLength = 5;
    }
  }

  // 添加当前字段
  addThisColumn(pIndex, pObject) {
    let column: any = {
      'column': null,
      'columnName': null,
      'component': null,
      'require': false,
      'showDetail': true,
      'maxLength': 0,
      'showContent': 0,
      'showAbstract': 0,
      'dictionary': null
    };
    column.component = pObject.component;
    column.maxLength = pObject.maxLength;
    column.require = pObject.require;
    if (this.parentData.MODEL_TYPE === 'extend') {
      column.showAbstract = pObject.showAbstract;
      column.showContent = pObject.showContent;
    }
    if (this.parentData.MODEL_TYPE === 'basic') {
      column.showAbstract = pObject.showAbstract;
      column.showContent = pObject.showContent;
      column.canEdit = pObject.canEdit;
    }
    this.data.groups[pIndex].columns.push(column);
  }

  // 删除一个字段
  deleteColumn(pIndex, num) {
    this.data.groups[pIndex].columns.splice(num, 1);
    if (!this.data.groups[pIndex].columns.length) {
      this.data.groups[pIndex]['columnType'] = null;
    }
  }

  // 上移字段
  upShiftColumn(pIndex, num, pObject) {
    if (num > 0) {
      let column = this.data.groups[pIndex].columns[num - 1];
      this.data.groups[pIndex].columns[num - 1] = pObject;
      this.data.groups[pIndex].columns[num] = column;
    }
  }

  // 下移字段
  downShiftColumn(pIndex, num, pObject) {
    if (this.data.groups[pIndex].columns.length - 1 > num) {
      let column = this.data.groups[pIndex].columns[num + 1];
      this.data.groups[pIndex].columns[num + 1] = pObject;
      this.data.groups[pIndex].columns[num] = column;
    }
  }

  setColumnTypeExtend() {
    this.columnType = [
      {
        code: 'input',
        name: '输入框'
      },
      {
        code: 'textarea',
        name: '长文本输入'
      },
      {
        code: 'date',
        name: '日期选择'
      }
    ];
  }

  setColumnTypeBasic() {
    this.columnType = [
      {
        code: 'input',
        name: '输入框'
      },
      {
        code: 'textarea',
        name: '长文本输入'
      },
      {
        code: 'date',
        name: '日期选择'
      },
      {
        code: 'datetime',
        name: '时间选择'
      }, {
        code: 'dateRange',
        name: '起止日期选择框'
      },
      {
        code: 'select',
        name: '下拉单选框'
      },
      {
        code: 'multiSelect',
        name: '下拉多选框'
      },
      {
        code: 'poplist',
        name: '单选弹出框'
      },
      {
        code: 'multipop',
        name: '多选弹出框'
      }
    ];
    _.each(this.data.groups, group => {
      const current = _.find(group['columns'], column => {
        return column['column'] === 'keywordsName'
          || column['column'] === 'subjectName'
          || column['column'] === 'datasetDesc';
      });
      if (current) {
        group['isDefault'] = true;
      }
    });
  }

  // 类型变更
  updateModelType() {
    switch (this.data.modelType) {
      case 'extend':
        this.setColumnTypeExtend();
        _.each(this.data.groups, (oneGroups) => {
          if (oneGroups['type'] === 'pictureModel' || oneGroups['type'] === 'fileModel' || oneGroups['isDefault']) {
            this.data.groups = _.without(this.data.groups, oneGroups);
          }
        });
        break;
      case 'basic':
        this.setColumnTypeBasic();
        _.each(this.data.groups, (oneGroups) => {
          if (oneGroups['type'] === 'pictureModel' || oneGroups['type'] === 'fileModel' || oneGroups['isDefault']) {
          }
        });
        break;
    }
  }

  // 保存
  doSave() {
    this.required = true;
    this.codeEnCheck();
    let ok = true;
    let group = true;
    let column = true;
    let copyData = this.utils.DeepCopy(this.data);
    if (!this.utils.Base_HasValue(this.data.modelEnName)
      || !this.utils.Base_HasValue(this.data.modelName)
      || this.data.modelName.length > 50
      || !this.utils.Base_HasValue(this.data.modelType)
      || !this.utils.Base_HasValue(this.data.modelState)
      || !this.utils.Base_HasValue(this.data.modelOrder)
      || this.enNameCheck) {
      return;
    }
    if (this.data.modelType === 'extend' && !this.utils.Base_HasValue(this.data.modelType)) {
      return;
    }
    if (this.data.modelType === 'basic' && !this.utils.Base_HasValue(this.data.resourcesType)) {
      return;
    }
    copyData.modelEnName = copyData.modelEnName.replace(/\s/g, ''); // 去掉模型标识里的空格
    _.each(copyData.groups, (oneGroup, index) => {
      if (!this.utils.Base_HasValue(oneGroup['groupName'])) {
        ok = false;
        return false;
      }
      if (!_.contains(['fileModel', 'pictureModel'], oneGroup['type'])
        && !oneGroup['columns'].length) {
        ok = false;
        return false;
      }
      oneGroup['groupOrder'] = index + 1;
      if (!_.contains(['fileModel', 'pictureModel'], oneGroup['type'])) {
        if (oneGroup['chooseType'] === 'singleResourcesSelect') {
          oneGroup['type'] = 'list';
          oneGroup['data'] = [];
        } else {
          oneGroup['type'] = 'form';
        }
      }
      if (oneGroup['require']) {
        oneGroup['require'] = 1;
      } else {
        oneGroup['require'] = 0;
      }
      if (this.utils.Base_HasValue(oneGroup['chooseType'])) {
        oneGroup['singleResourcesSelect'] = oneGroup['chooseType'] === 'singleResourcesSelect' ? 1 : 0;
        oneGroup['muchResourcesSelect'] = oneGroup['chooseType'] === 'muchResourcesSelect' ? 1 : 0;
        if (oneGroup['chooseType'] === 'muchResourcesSelect') {
          oneGroup['type'] = 'list';
          oneGroup['data'] = [];
        } else {
          oneGroup['type'] = 'form';
        }
      } else {
        oneGroup['singleResourcesSelect'] = 0;
        oneGroup['muchResourcesSelect'] = 0;
      }
      if (this.utils.Base_HasValue(oneGroup['isThumbnail'])) {
        if (oneGroup['isThumbnail'] === 'isThumbnail') {
          oneGroup['isThumbnail'] = 1;
        }
      } else {
        oneGroup['isThumbnail'] = 0;
      }
      // 验证组内的字段名称是否重复
      let columnCount = _.countBy(oneGroup['columns'], column => {
        return column['columnName'];
      });
      _.each(columnCount, (value, key) => {
        if (value > 1) {
          column = false;
          return false;
        }
      });
      _.each(oneGroup['columns'], (oneColumn, num) => {
        if (!this.utils.Base_HasValue(oneColumn['columnName'])) {
          ok = false;
          return false;
        }
        // if (_.contains(['select', 'poplist', 'multiSelect', 'multipop'], oneColumn['component'])
        //   && !this.utils.Base_HasValue(oneColumn['dictionary'])) {
        //   ok = false;
        //   return false;
        // }
        if (!_.contains(['keywordsName', 'subjectName', 'datasetDesc'], oneColumn['column'])) {
          if (!this.utils.Base_HasValue(oneColumn['column'])) {
            oneColumn['column'] = Mtils.utils.makePy(oneColumn['columnName']) + this.utils.Base_getUuid().slice(0, 8);
          }
        } else {
          if (oneColumn['column'] === 'keywordsName') {
            oneColumn['limitLength'] = 10;
          }
        }
        if (oneColumn['component'] === 'dateRange') {
          oneColumn['column'] = oneColumn['column'] + '_START,' + oneColumn['column'] + '_END';
          oneColumn['columnName'] = oneColumn['columnName'] + '_起,' + oneColumn['columnName'] + '_止';
        } else if (oneColumn['component'] === 'input') {
          oneColumn['maxLength'] = 50;
        } else if (oneColumn['component'] === 'textarea') {
          oneColumn['maxLength'] = 500;
        }
        oneColumn['order'] = num + 1;
        if (oneColumn['require']) {
          oneColumn['require'] = 1;
        } else {
          oneColumn['require'] = 0;
        }
        if (oneColumn['showDetail']) {
          oneColumn['showDetail'] = 1;
        } else {
          oneColumn['showDetail'] = 0;
        }
        if (this.parentData.MODEL_TYPE === 'extend') {
          if (oneColumn['showContent']) {
            oneColumn['showContent'] = 1;
          } else {
            oneColumn['showContent'] = 0;
          }
          if (oneColumn['showAbstract']) {
            oneColumn['showAbstract'] = 1;
          } else {
            oneColumn['showAbstract'] = 0;
          }
        }
        if (this.parentData.MODEL_TYPE === 'basic' && this.utils.Base_HasValue(oneGroup['chooseType'])) {
          if (oneColumn['canEdit']) {
            oneColumn['canEdit'] = 1;
          } else {
            oneColumn['canEdit'] = 0;
          }
        } else {
          oneColumn['canEdit'] = 0;
        }
      });
    });
    // 验证分组名称不能重复
    let groupCount = _.countBy(this.data.groups, groups => {
      return groups['groupName'];
    });
    _.each(groupCount, (value, key) => {
      if (value > 1) {
        group = false;
        return;
      }
    });
    if (!ok) {
      return;
    }
    if (!group) {
      this.confirmModal.show('warning', {title: '分组名称不能重复'});
      return;
    }
    if (!column) {
      this.confirmModal.show('warning', {title: '同一个分组内字段描述不能重复'});
      return;
    }
    let verification = observableForkJoin([this.requestData.getDataByCondition('digitalResources/dr/dr_model_count.json', {'MODEL_NAME': this.data.modelName}),
      this.requestData.getDataByCondition('digitalResources/dr/dr_model_count.json', {'MODEL_EN_NAME': this.data.modelEnName})]);
    if (this.parentData.modeType === 'modify') {
      verification = observableForkJoin([this.requestData.getDataByCondition('digitalResources/dr/dr_model_count.json', {
        'MODEL_NAME': this.data.modelName,
        'ID': this.data.modelId
      }),
        this.requestData.getDataByCondition('digitalResources/dr/dr_model_count.json', {
          'MODEL_EN_NAME': this.data.modelEnName,
          'ID': this.data.modelId
        })]);
    }
    verification.subscribe(([result1, result2]) => {
      if (this.utils.Base_HasValue(result1.data[0].acount) && result1.data[0].acount > 0) {
        this.confirmModal.show('warning', {title: '模型名称已存在'});
        return;
      }
      if (this.utils.Base_HasValue(result2.data[0].acount) && result2.data[0].acount > 0) {
        this.confirmModal.show('warning', {title: '模型标识已存在'});
        return;
      }
      this.dataService.insertModel(copyData).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {
            title: '温馨提示',
            content: '保存成功',
            suc: () => {
              this.gotoBack();
            }
          });
        } else {
          this.confirmModal.show('error', {title: '保存失败请刷新重试。'});
        }
      });
    });
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    this.parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  ngOnInit() {
    this.modelState = [
      {
        code: 'ENABLE',
        name: '可用'
      },
      {
        code: 'DISABLE',
        name: '禁用'
      }
    ];
    this.modelType = [
      {
        code: 'basic',
        name: '基础模型'
      },
      {
        code: 'extend',
        name: '扩展模型'
      }
    ];
    this.data.modelType = this.parentData.MODEL_TYPE;
    this.updateModelType();
    // this.requestData.getDataByCondition('headLine/ds/ds_stridic_list.json').subscribe(result => {
    //   this.dataTypeSelect = result['data'] || [];
    // });
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json'),
      this.requestData.getDataByCondition('digitalResources/dict/RESOURCES_TYPE.json')
    );
    search.subscribe(
      values => {
        this.basicModel = values[0]['data'] || [];
        this.resourceType = values[1]['data'] || [];
      });
    if (this.parentData.modeType === 'modify') {
      this.dataService.searchModel({modelId: this.parentData.detailCondition.MODEL_ID}).subscribe(response => {
        if (this.utils.Base_HasValue(response.error)) {
          this.confirmModal.show('error', {title: response.error.message});
        } else {
          this.data = response;
          if (this.data.modelType === 'extend') {
            this.setColumnTypeExtend();
          } else {
            this.setColumnTypeBasic();
          }
          _.each(this.data.groups, group => {
            if (group['type'] === 'list') {
              group['add'] = true;
            } else {
              group['add'] = false;
            }
            if (this.data.modelType === 'basic') {
              group['chooseType'] = group['singleResourcesSelect'] === 1 ? 'singleResourcesSelect' : group['muchResourcesSelect'] === 1 ? 'muchResourcesSelect' : '';
              group['isThumbnail'] = group['isThumbnail'] === 1 ? 'isThumbnail' : '';
              if (group['isThumbnail'] === 'isThumbnail') {
                this.chooseThumbnail = true;
              }
              _.each(group['columns'], column => {
                if (column['canEdit']) {
                  column['canEdit'] = true;
                } else {
                  column['canEdit'] = false;
                }
                if (column['require'] === 1) {
                  column['require'] = true;
                } else {
                  column['require'] = false;
                }
              });
            }
            if (this.data.modelType === 'extend') {
              _.each(group['columns'], column => {
                if (column['require'] === 1) {
                  column['require'] = true;
                } else {
                  column['require'] = false;
                }
                if (column['showContent'] === 1) {
                  column['showContent'] = true;
                } else {
                  column['showContent'] = false;
                }
                if (column['showAbstract'] === 1) {
                  column['showAbstract'] = true;
                } else {
                  column['showAbstract'] = false;
                }
              });
            }
          });
        }
      });
    }
  }

}
