import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import * as _ from 'lodash';
import {RequestDataService} from "../../service/request-data.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {AutoInsertService} from "../../service/auto-insert.service";
import localForage from "localforage";
import {DataCenterService} from "../../service/data-center.service";
import * as _sh from 'underscore';
import {FormModalComponent} from "../../basicComponent/form-modal/form-modal.component";
import {NzModalService} from "ng-zorro-antd";
@Injectable({
  providedIn: 'root'
})
export class OtmsActionService {

  public _dataObject: any = {
    // tab信息 index为当前选中tab的下标（修改该属性可以动态选中tab）
    index: 0,
    // tab 数组
    list: [],
    // 当前menu
    nowMenu: {}
  };
  constructor(private confirmModel: ConfirmModalService,
              private requestService: RequestDataService,
              private userInfoService: UserInfoService,
              private userInfo: UserInfoService,
              private util: UtilsService,
              private autoService: AutoInsertService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService) {
  }

  doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case'transQrCode':
        this.confirmModel.show('success', {
          'title': 'Hi',
          'content': pBtn.ACTION_NAME
        });
        break;
      case'accountSave':
        const accountMainData = _.get(pParentData,['data','detailColumns'],[]);
        const accountEntity = this.formatMainToEntity(accountMainData);
        const accountDetailArray = _.get(accountMainData,[1,'data'],[]);
        const accountDetail={
          detail:{
            detailArray: accountDetailArray
          }
        }
        const accountParam = "entity:"+"'ms_tran_account'"+",entityColumn:"+JSON.stringify(accountEntity)+",detail:"+JSON.stringify(accountDetail.detail);
        this.requestService.doActionByJavaPlatform("jieLiController","accountAddData",accountParam).subscribe(res=>{
            if(res.status == 500){
              this.confirmModel.show('error', {
                'title': '执行错误',
              });
            }
            if(res.result == "success"){
              this.confirmModel.show('success', {
                'title': '保存成功',
              });
                this.transformData('listForm',pParentData);
            }

        });
        break;
      case'borrowSave':
        const borrowMainData = _.get(pParentData,['data','detailColumns'],[]);
        const borrowEntity = this.formatMainToEntity(borrowMainData);
        const borrowDetailArray = _.get(borrowMainData,[1,'data'],[]);


        if (!this.util.Base_HasValue(borrowEntity.truckId)) {
          this.confirmModel.show('error',{
            'title':"必须选择一个司机!"
          })
          return;
        }
        if (!this.util.Base_HasValue(borrowEntity.COUNT_ACTOR_USERID)) {
          this.confirmModel.show('error',{
            'title':"必须填写经手人!"
          })
          return;
        }

        if (!this.util.Base_HasValue(borrowEntity.COUNT_VOUCHER_USERID)) {
          this.confirmModel.show('error',{
            'title':"必须填写证明人!"
          })
          return;
        }


        if (!this.util.Base_HasValue(borrowDetailArray)) {
          this.confirmModel.show('error',{
            'title':"请添加至少一条借支明细数据!"
          })
          return;
        }

        const detail={
          detail:{
            detailArray: borrowDetailArray
          }
        }
        let checkMoney = false;
        let count = 0;
        let tempMoney = 0;
        _.each(borrowDetailArray,oneItem=>{
            if(oneItem['DET_MONEY']<(oneItem['DISP_TOTALSFEE']-oneItem['DISP_LOCK_MONEY'])){
               count +=1;
            }
          tempMoney += parseFloat(oneItem['DET_MONEY'])
        })
        borrowEntity['COUNT_MONEY'] = tempMoney;
        if(count == borrowDetailArray.length){
          checkMoney = true
        }
        else{
          this.confirmModel.show('error',{
            'title':"借支金额不能超过可借支金额。"
          })
        }
        if(checkMoney){
          const borrowParam = "entity:"+"'ms_tran_account'"+",entityColumn:"+JSON.stringify(borrowEntity)+",detail:"+JSON.stringify(detail.detail);
          this.requestService.doActionByJavaPlatform("jieLiController","advanceAddData",borrowParam).subscribe(res=>{
            if(res.status == 500){
              this.confirmModel.show('error', {
                'title': '执行错误',
              });
            }
            if(res.result == "success"){
              this.confirmModel.show('success', {
                'title': '保存成功',
              });
              this.transformData('listForm',pParentData);
            }

          });
        }

        break;
      case "driverReviewAdopt":
        if (pParentData.data.detailColumns[0].data[0].AUDIT_STATE == "ADOPT"){
          this.confirmModal.show('error', {
            'title': '该司机信息已审核，无需重复操作！',
          });
          return;
        }


        this.confirmModal.show('confirm', {
          title: '确认审核通过该司机信息吗？',
          suc: () => {
            let autoJsonList = [
              {
                "TABLE": "basic_driver",
                "ACTION": "Update",
                "WHEREGROUP": [
                  {
                    "GROUP": "GROUP_01",
                    "CONDITION_OPERATOR": "AND"
                  }
                ],
                "WHERE": [
                  {
                    "COLUMN": "DRIVER_ID",
                    "VALUE": "?",
                    "OPERATOR": "=",
                    "VALUE_TYPE": "string",
                    "CONDITION_OPERATOR": "AND",
                    "GROUP": "GROUP_01"
                  }
                ],
                "COLUMNS": [
                  {
                    "COLUMN": "AUDIT_STATE"
                  }
                ],
                "RECORDS": [
                  {
                    "DRIVER_ID": pParentData.detailCondition.DRIVER_ID,
                    "AUDIT_STATE": "ADOPT"
                  }
                ]
              }
            ];
            _.each(autoJsonList, oneAction => {
              let records = [];
              let recordsDatasConfig = _.get(oneAction, ['RECORDS_DATAS'], null);
              if (this.util.Base_HasValue(recordsDatasConfig)) {
                let rows = this.dataCenterService.getSpecialValue(recordsDatasConfig, pParentData, null, null, null);
                _.each(rows, oneRow => {
                  let oneRecord = _.cloneDeep(oneAction['RECORDS'][0]);
                  let oneRecordKeys = _.keys(oneRecord);
                  _.each(oneRecordKeys, oneKey => {
                    let oneRecordoneKey = oneRecord[oneKey];
                    let value = this.dataCenterService.getSpecialValue(oneRecordoneKey, pParentData, null, null, oneRow);
                    oneRecord[oneKey] = value;
                  })
                  records.push(oneRecord);
                })
                oneAction['RECORDS'] = records;
              }
            });
            let autoTemplate = {
              "LIST": autoJsonList
            }
            _sh.each(autoTemplate['LIST'], oneList => {
              _sh.each(oneList['NULL_DELETE_COLUMNS'], oneColumn => {
                let hasValue = this.checkHasValue(oneList['RECORDS'], oneColumn);
                if (!hasValue) {
                  this.nullDeleteColumns(oneList, oneColumn);
                }
              });
            });
            this.autoService.ExecJson(autoTemplate).subscribe(res => {
              if (res.state == "success"){
                this.confirmModal.show('success', {
                  'title': '保存成功',
                });
                this.transformData('listForm',pParentData);
              }else {
                this.confirmModal.show('error', {
                  'title': '执行错误',
                });
              }
            });
          }
        });
        break;
      case "driverReviewNotGo":
        if (pParentData.data.detailColumns[0].data[0].AUDIT_STATE == "NO_ADOPT"){
          this.confirmModal.show('error', {
            'title': '该司机信息已被审核不通过，无需重复操作！',
          });
          return;
        }
        this.modalService.create({
          nzTitle: '审核不通过原因',
          nzContent: FormModalComponent,
          nzWidth: 500,
          "nzWrapClassName": "vertical-center-modal",
          "nzComponentParams": {
            "modalFormData": {
              "columns": [
                {
                  "column": "AUDIT_BZ",
                  "name": "AUDIT_BZ_NAME",
                  "component": "textarea",
                  "customize": 24,
                  "lable": "原因",
                  "comment": "原因",
                  "placeholder": "请输入原因",
                  "model": null,
                  "visible": true,
                  "require": false
                }
              ]
            }
          },
          nzOnOk: (response) => {
            const data = response.modalFormData.columns;
            let AUDIT_BZ = _.get(data, [0, 'model'], '');
            let autoJsonList = [
              {
                "TABLE": "basic_driver",
                "ACTION": "Update",
                "WHEREGROUP": [
                  {
                    "GROUP": "GROUP_01",
                    "CONDITION_OPERATOR": "AND"
                  }
                ],
                "WHERE": [
                  {
                    "COLUMN": "DRIVER_ID",
                    "VALUE": "?",
                    "OPERATOR": "=",
                    "VALUE_TYPE": "string",
                    "CONDITION_OPERATOR": "AND",
                    "GROUP": "GROUP_01"
                  }
                ],
                "COLUMNS": [
                  {
                    "COLUMN": "AUDIT_STATE"
                  },
                  {
                    "COLUMN": "AUDIT_BZ"
                  }
                ],
                "RECORDS": [
                  {
                    "DRIVER_ID": pParentData.detailCondition.DRIVER_ID,
                    "AUDIT_STATE": "NO_ADOPT",
                    "AUDIT_BZ": AUDIT_BZ
                  }
                ]
              }
            ];
            _.each(autoJsonList, oneAction => {
              let records = [];
              let recordsDatasConfig = _.get(oneAction, ['RECORDS_DATAS'], null);
              if (this.util.Base_HasValue(recordsDatasConfig)) {
                let rows = this.dataCenterService.getSpecialValue(recordsDatasConfig, pParentData, null, null, null);
                _.each(rows, oneRow => {
                  let oneRecord = _.cloneDeep(oneAction['RECORDS'][0]);
                  let oneRecordKeys = _.keys(oneRecord);
                  _.each(oneRecordKeys, oneKey => {
                    let oneRecordoneKey = oneRecord[oneKey];
                    let value = this.dataCenterService.getSpecialValue(oneRecordoneKey, pParentData, null, null, oneRow);
                    oneRecord[oneKey] = value;
                  })
                  records.push(oneRecord);
                })
                oneAction['RECORDS'] = records;
              }
            });
            let autoTemplate = {
              "LIST": autoJsonList
            }
            _sh.each(autoTemplate['LIST'], oneList => {
              _sh.each(oneList['NULL_DELETE_COLUMNS'], oneColumn => {
                let hasValue = this.checkHasValue(oneList['RECORDS'], oneColumn);
                if (!hasValue) {
                  this.nullDeleteColumns(oneList, oneColumn);
                }
              });
            });
            this.autoService.ExecJson(autoTemplate).subscribe(res => {
              if (res.state == "success"){
                this.confirmModal.show('success', {
                  'title': '保存成功',
                });
                this.transformData('listForm',pParentData);
              }else {
                this.confirmModal.show('error', {
                  'title': '执行错误',
                });
              }
            });
          }
        });
        break;
    }
  }
  checkHasValue(pRecords, pColumn) {
    let count = 0;
    _.each(pRecords, oneRecord => {
      if (this.util.Base_HasValue(_.get(oneRecord, [pColumn], null))) {
        count++;
      }
    });
    return count > 0;
  }

  nullDeleteColumns(pList, pColumn) {
    _.each(pList['COLUMNS'], oneColumn => {
      if (oneColumn['COLUMN'] == pColumn) {
        pList['COLUMNS'] = _.without(pList['COLUMNS'], oneColumn);
      }
    })
  }
  formatMainToEntity(pForm) {
    let entity: any = {};
    _.each(_.get(pForm, [0,'columns']), (item) => {
      const column = _.get(item, 'column');
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }
  transformData(pFormType, parentData) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _sh.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }
  // 刷新缓存中的data center
  refreshDataCenterInStorage(param?: string) {
    localForage.setItem('dataCenter', JSON.stringify(this._dataObject));
  }
}
