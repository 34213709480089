import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UserInfoService} from '../../../service/user-info.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-input-inventory',
  templateUrl: './input-inventory.component.html',
  styleUrls: ['./input-inventory.component.css']
})
export class InputInventoryComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';

  public today = moment().format('YYYY-MM-DD');

  public datas: any;

  public formData: any = [{
    'title': '盘点计划',
    'type': 'normal',
    'tablename': 'mc_inventory',
    'formname': 'mc_inventory',
    'columns': [
      {
        'column': 'ID',
        'pk_column': true,
        'iscondition': true,
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'insertValue': 'SYS-UUID',
        'model': null,
        'visible': false,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'PLANT_ID',
        'name': 'PLANT_ID_NAME',
        'component': 'poplist',
        'lable': '盘点仓库',
        'comment': '盘点仓库',
        'placeholder': '请输入盘点仓库',
        'model': null,
        'visible': true,
        'require': true,
        'dictionary': 'dfmc/basic/dict/BASIC_PLANT.json',
        'dictionaryPF': null,
        'dictionaryParam': [
          {
            'clearValues': ['SYS.mc_inventory.AREA_ID']
          }]
      },
      {
        'column': 'AREA_ID',
        'name': 'AREA_ID_NAME',
        'component': 'poplist',
        'lable': '盘点库区',
        'comment': '盘点库区',
        'placeholder': '请输入盘点库区',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': null,
        'visible': true,
        'require': false,
        'dictionary': 'dfmc/basic/dict/BASIC_PLANT_AREA.json',
        'dictionaryPF': null,
        'dictionaryParam': [
          {
            'column': 'PLANT_ID',
            'value': 'SYS.mc_inventory.PLANT_ID',
            'errormsg': '请先选择盘点仓库'
          }
        ]
      },
    ]
  }];

  private indeterminate = false;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.formData, '0.columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  findOneColumnValue(pColumnName) {
    const columns = _.get(this.formData, '0.columns', []);
    const oneOneColum = _.find(columns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput() {
    let result = true;
    result = this.checkOneColumn('PLANT_ID', '盘点仓库');
    return result;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  saveData() {
    if (!this.checkInput()) {
      return false;
    }
    const data = {
      'mcInventory': this.formatMainToEntity()
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McInventoryController', 'input', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }

  ngOnInit(): void {

  }
}
