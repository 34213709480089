import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {JumpCenterService} from "../../../service/jump-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import * as moment from "moment";

@Component({
    selector: 'app-descriptions-edit-date',
    templateUrl: './descriptions-edit-date.component.html',
    styleUrls: ['./descriptions-edit-date.component.scss']
})
export class DescriptionsEditDateComponent implements OnInit {

    @Input() oneTab: any;
    @Input() oneGroup: any;
    @Input() oneColumn: any;
    @Input() oneData: any;
    @Input() aggregateListData: any;
    @Input() sqlFileListData: any;
    @Input() execButton: any;

    constructor(private utils: UtilsService,
                private dataCenterService: DataCenterService,
                private confirmModal: ConfirmModalService,
                private jumpCenterService: JumpCenterService,
                private requestService: RequestDataService) {
    }

    ngOnInit() {
    }

    removeValue() {
        this.oneData[this.oneColumn['column']] = null;
    }

    changeModel(model) {
        let dateFormat = 'YYYY-MM-DD';
        if (this.oneColumn.component==='datetime')
            dateFormat = 'YYYY-MM-DD';
        else if (this.oneColumn.component==='datetime')
            dateFormat = 'YYYY-MM-DD HH:mm:ss';
        if (this.utils.Base_HasValue(model)) {
            this.oneData[this.oneColumn['column']] = moment(model).format(dateFormat);
        }
    }
}
