import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService, UploadXHRArgs} from "ng-zorro-antd";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {UploadService} from "../../../service/upload.service";
import * as moment from 'moment';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-es-order-files',
  templateUrl: './es-order-files.component.html',
  styleUrls: ['./es-order-files.component.css']
})
export class EsOrderFilesComponent implements OnInit {

  fileModelHandler(event, pItem) {
    pItem['FILE_URL'] = event;
  }

  @Input() parentData: any;
  @Input() modeType = 'add';
  esOrderId: String = "";
  public user: any;
  public allChecked = false;
  private indeterminate = false;
  dateFormat = 'yyyy-MM-dd';
  datas = {
    esOrder: {
      ORDER_CODE: null,
      ORDER_STATE_DICT: null,
      CUST_CONTACTMAN: null,
      CUST_NAME: null,
      CUST_TIME: null,
      ORIGINAL_AMOUNT: null,
      PROVINCE: null,
      CITY: null,
      AREA: null,
      ADDR_DETAIL: null,
      GOODS_ID_DICT_ARRAY: {
        ORG_LV02_DICT: null,
        CATEGORIES_LV01_DICT: null,
        CATEGORIES_LV02_DICT: null,
        CATEGORIES_LV03_DICT: null,
        SERVICE_CODE: null
      },
      GOODS_NAME: null,
      OR_PRINCIPAL_NAME: null,
      OR_PRINCIPAL_TIME: null,
      INVOICE_STATE_DICT: null,
      INVOICE_CODE: null,
      INVOICE_NAME: null,
      INVOICE_TIME: null,
      IE_CUST_NAME: null,
      INVOICE_LICENCE: null,
      BUSINESS_ADDRESS: null,
      INVOICE_MOBILE: null,
      INVOICE_BANK: null,
      INVOICE_BANKNO: null,
      CONTRACT_STATE_DICT: null,
      CONTRACT_NAME: null,
      CONTRACT_TIME: null,
      SHIPPED_NAME: null,
      SHIPPED_MOBILE: null,
      SHIPPED_TIME: null,
      EXPRESS_NAME: null,
      EXPRESS_CODE: null,
      GOT_NAME: null,
      GOT_MOBILE: null,
      CONFIRM_TIME: null,
      CONFIRM_NAME: null,
      CONFIRM_MEMO: null,
      LOGISTICS_STATE_DICT: undefined,
      CUST_MOBILE: undefined,
      PAYMENT_AMOUNT: undefined
    },
    esOrderFiles: []
  }

  fileState = [
    {
      "label": "不可见",
      "value": "INVISIBLE",
    },
    {
      "label": "可见",
      "value": "VISIBLE",
    }
  ]

  constructor(
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }

  drop(small_, event: CdkDragDrop<string[]>): void {
    moveItemInArray(small_, event.previousIndex, event.currentIndex);
  }

  checkAll(value: boolean): void {
    this.datas['esOrderFiles'].forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.datas['esOrderFiles'].filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  ngOnInit() {
    this.esOrderId = this.parentData['id'];
    this.doRefresh();
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

  formatMainToEntity(pData) {
    let entity: any = [];
    _.each(pData, oneData => {
      let oneEntity: any = {}
      const columns = _.keys(oneData);
      _.each(columns, OneColumns => {
        const camelCaseColumn = _.camelCase(OneColumns);
        const value = oneData[OneColumns];
        oneEntity[camelCaseColumn] = value
      })
      entity.push(oneEntity);
    })
    return entity;
  }

  showError(pOneItem, pIndex, pColumn, pColumnName) {
    if (!this.util.Base_HasValue(pOneItem[pColumn])) {
      var compiled = _.template('第 <%= index %> 行 ，<%= columnName %> ，是必填项！');
      const msg = compiled({'index': pIndex, 'columnName': pColumnName});
      this.confirmModal.show('error', {'title': '错误如下', 'content': msg});
      return false;
    } else {
      return true;
    }
  }

  checkInputDatas() {
    let result = true;
    // tslint:disable-next-line:triple-equals
    if (this.datas['esOrderFiles'].length == 0) {
      this.confirmModal.show('error', {'title': '必填项', 'content': '文件列表数据为必填项'});
      result = false;
      return result;
    }
    let index = 1;
    _.each(this.datas['esOrderFiles'], oneItem => {
      result = this.showError(oneItem, index, 'FILE_NAME', '报告名称');
      if (!result) return result;
      result = this.showError(oneItem, index, 'FILE_URL', '报告文件');
      if (!result) return result;
      result = this.showError(oneItem, index, 'FILE_UPLOAD_TIME', '报告上传时间');
      if (!result) return result;
      index += 1;
    })
    return result;
  }

  doSave() {

    if (this.checkInputDatas()) {
      let fileSort = 1
      _.each(this.datas['esOrderFiles'], oneFile => {
        oneFile['FILE_SORT'] = fileSort;
        fileSort += 1;
      })
      const bodyData = this.formatMainToEntity(this.datas['esOrderFiles']);
      let queryData = {
        'userId': this.user.USER_ID,
        'orderId': this.datas['esOrder']['ID'],
      };
      this.requestService.doActionByHdbsthor('AppApi/EsOrderFilesController', 'inputEsOrderFiles', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
        if (response.toString() === 'true') {
          this.confirmModal.show('success', {'title': '执行成功'});
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    }
  }

  doRefresh() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('genecreate/report/esOrderDetail/es_order.json', {'ID': this.esOrderId}),
      this.requestData.getDataByCondition('genecreate/report/esOrderDetail/es_order_files.json', {'ORDER_ID': this.esOrderId}),
    );
    search.subscribe(
      values => {
        this.datas['esOrder'] = values[0]['data'][0] || [];
        this.datas['esOrderFiles'] = values[1]['data'] || [];
      }
    );
  }

  // addItem
  addItem(): void {
    // visible
    let tempObj = {
      "FILE_NAME": null,
      "FILE_STATE_DICT": "不可见",
      "FILE_STATE": "INVISIBLE",
      "FILE_UPLOAD_TIME": moment().format('YYYY-MM-DD'),
      "FILE_URL": null,
      "FILE_SORT": 0,
      "FILE_URL_fileList": []
    }
    this.datas['esOrderFiles'].push(tempObj);
  }

  // 明细table删除
  itemRemove(): void {
    const checkedList = _.filter(this.datas['esOrderFiles'], (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.datas['esOrderFiles'] = this.datas['esOrderFiles'].filter((item) => {
            return !item.checked;
          });
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }


  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }
}
