import {Component, Input, OnInit} from '@angular/core';
import {AppConfigService} from "../../service/app-config.service";
import {RequestDataService} from "../../service/request-data.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {DataCenterService} from "../../service/data-center.service";
import * as _ from 'lodash';
import PubSub from 'pubsub-js';
import {UtilsService} from "../../service/utils.service";

@Component({
    selector: 'app-generate-settlement-statement',
    templateUrl: './generate-settlement-statement.html',
    styleUrls: ['./generate-settlement-statement.scss'],
})
export class generateSettlementStatementComponent implements OnInit {
    @Input() parentData: any;
    searchForm: any = {
      "type": "normal",
      "columns": [
        {
          "column": "CUST_ID",
          "name": "CUST_ID_NAME",
          "component": "poplist",
          "lable": "客户名称",
          "comment": "客户名称",
          "placeholder": "请输入客户名称",
          "model": null,
          "visible": true,
          "require": true,
          "dictionary": "JieLiv2/basic/dict/BASIC_CUST.json",
          "dictionaryPF": null,
          "type":"common"
        },
        {
          "column": "SE_INTERVAL",
          "name": "SE_INTERVAL_NAME",
          "component": "dateMonths",
          "lable": "结算区间",
          "comment": "结算区间",
          "placeholder": "请选择结算区间",
          "model": null,
          "visible": true,
          "require": true,
          "type":"common"
        },
        {
            "column": "ADD_DATE",
            "component": "dateTimeRange",
            "name": "ADD_DATE_NAME",
            "lable": "制单起止时间",
            "comment": "制单起止时间",
            "placeholder": [
                "开始时间",
                "结束时间"
            ],
            "model": null,
            "visible": true,
            "require": false,
            "bindColumns": [
                "ADD_DATE_BEGIN",
                "ADD_DATE_END"
            ]
        }
      ]
    };

    constructor(
        private confirmModal: ConfirmModalService,
        private requestService: RequestDataService,
        private dataCenterService: DataCenterService,
        private utils: UtilsService,
        private appConfigService: AppConfigService,
    ) {
    }

    public fileList: any = [];
    public fileUrl: string = null;
    public fileNamePefix: string = null;
    public downloadAvailable: boolean = false;// 初始状态为过滤
    public modeType: any; // trans 运输收入结算导入; loadUnload 装卸收入结算导入;
    //一开始请求到的数据

    async generateSettlementStatement() {
        if (!this.checkInput()) {
          return;
        }
        let response = null;
        let myDate = new Date();//获取系统当前时间
        let custId = '';
        let seInterval = '';
        let startDate = '';
        let endDate = '';
        console.log("this.searchForm.columns");
        console.log(this.searchForm.columns);
        this.searchForm.columns.forEach(item => {
            if (item['column'] == "CUST_ID") {
                custId = item['model'];
            }
            if (item['column'] == "SE_INTERVAL") {
                seInterval = item['model'];
            }
            if (item['column'] == "ADD_DATE") {
                startDate = item['model'][0];
                endDate = item['model'][1];
            }
        });
        this.fileNamePefix = myDate.getFullYear() + '' + (myDate.getMonth() + 1) + '' + myDate.getDate() + '' + myDate.getHours() + '' + myDate.getMinutes() + '' + myDate.getSeconds();

        if (this.modeType === 'loadUnload') {
            // 处理核对按钮的逻辑
            //  response = await this.requestService.doActionByHdbsthor('AppApi/LAndUExpenditureController', 'check', {fileUrl: this.fileUrl}, null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        } else if (this.modeType === 'trans') {
             response = await this.requestService.doActionByHdbsthor('AppApi/MsTranOrdersController', 'generateSettlement',
               {custId: custId,
                 seInterval: seInterval,
                 startDate: startDate,
                 endDate: endDate,
                 fileNamePefix: this.fileNamePefix},
               null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        }

        if (response == true) {
            // this.confirmModal.show('success', {'title': '执行成功'});
            this.downloadAvailable = true;
        } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
    }

    closePage() {
        let menuId = _.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
        if (this.utils.Base_HasValue(menuId)) {
            PubSub.publish('listRefresh', menuId);
        }
        this.dataCenterService.closeTab(this.parentData);
    }

    downloadSettlementStatement() {
        window.location.href = this.appConfigService.config.OSS.domain
            + '/' + this.appConfigService.config.OSS.ossPath
            + this.fileNamePefix + '.xlsx';
    }

    ngOnInit() {
        this.modeType = _.get(this.parentData, ['modeType'], 'loadUnload');
    }

    //必填项校验
    checkInput() {
      let result = true;

      this.searchForm['columns'].forEach((item) => {
        if (result && item['require'] == true && !this.utils.Base_HasValue(item['model'])) {
          result = false;
          this.confirmModal.show('error', {'title': '必填项', 'content': item['comment'] + '为必填项'});
        }
      })
      return result;
    }
}
