import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions} from "ng-zorro-antd";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {ClipboardService} from 'ngx-clipboard'
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UUID} from "angular2-uuid";
import {AutoInsertService} from "../../../service/auto-insert.service";
import {StorageService} from "../../../service/storage.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UploadService} from "../../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
@Component({
  selector: 'app-ig-word-template',
  templateUrl: './ig-word-template.component.html',
  styleUrls: ['./ig-word-template.component.scss']
})
export class IgWordTemplateComponent implements OnInit {
  nowMenu: any;
  appList: any;
  user: any;
  @Input() parentData: any;
  @ViewChild('nzTreeComponent', {static: false}) nzTreeComponent: NzTreeComponent;

  selectedIndex = 0;
  allChecked = false;
  indeterminate = false;
  treeDict: any = {};
  selectNode: any;
  defaultCheckedKeys = [];
  defaultSelectedKeys = [];
  defaultExpandedKeys = [];
  nodes: NzTreeNodeOptions[] = [];

  constructor(
    // private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private autoInsert: AutoInsertService,
    private notification: NzNotificationService,
    private clipboardService: ClipboardService,
    private confirmModal: ConfirmModalService,
  ) {
    this.user = this.userInfo.getUserInfo();
  }


  createNotification(type: string, title: string, content: string): void {
    this.notification.create(
      type,
      title,
      content
    );
  }

  doSelect(event: NzFormatEmitEvent): void {
    let level = _.get(event, ['node', 'level']);
    if (level == 0) {
      this.confirmModal.show('error', {'title': '请选择二级数据进行复制。'});
    } else {
      let text = "【" + _.get(event, ['node', 'origin', 'title']) + "】";
      let dsType = _.get(event, ['node', 'origin', 'datas', 'DS_TYPE']);
      if (dsType == 'DS_TABLE') {
        text = "【" + _.get(event, ['node', 'parentNode', 'title']) + '.' + _.get(event, ['node', 'origin', 'title']) + "】";
      }
      this.clipboardService.copyFromContent(text);
      this.createNotification('success', '成功复制到剪切板，Ctrl+V 即可使用。', text);
    }
  }


  ngOnInit() {
    this.nowMenu = this.dataCenterService._dataObject.nowMenu;
    this.appList = _.find(JSON.parse(this.storage.getData('appList')), {'LIST_ID': _.get(this.nowMenu, ['MENU_ID'])});
    const search = observableForkJoin(
      this.requestDataService.getDataByJson('simulationTrain/ig/wordConfig/system_datasource_tree.json')
    );
    search.subscribe(
      values => {
        this.treeDict = values[0]['treeDict'];
      }
    );
    if (this.parentData['modeType'] == 'modify') {
      let id = this.parentData['id'];
      observableForkJoin(
        this.requestDataService.getDataByCondition('simulationTrain/ig/wordConfig/word_config_list.json', {'ID': id})
      ).subscribe(
        values => {
          _.each(this.formData['columns'], oneColumn => {
            let value = _.get(values, [0, 'data', 0, oneColumn['column']]);
            oneColumn['model'] = value;
            if (this.util.Base_HasValue(oneColumn['columnGroup'])) {
              _.each(oneColumn['columnGroup'], oneColumnGroup => {
                oneColumnGroup['model'] = value;
              })
            }
            if ((this.util.Base_HasValue(value)) && (_.indexOf(['file', 'picture'], oneColumn['component']) > -1)) {
              let fileName = this.util.Base_getFileNameOnly(value);
              _.set(oneColumn, ['fileList', 0], {
                uid: UUID.UUID(),
                name: fileName,
                status: 'done',
                url: value
              });
            }
          })
        }
      );
    }
    this.doRefresh();
  }


  doRefresh() {

  }

  makeTreeData(pData, pRoleDatas?) {
    let that = this;

    function makeUpLeftData(oneItem, pParentsNode?) {
      let linKey = [];
      if (!_.isEmpty(pParentsNode)) {

        linKey = _.concat(pParentsNode['linKey'], [oneItem[that.treeDict['upKey']], oneItem[that.treeDict['key']]]);
      } else {
        linKey = [oneItem[that.treeDict['upKey']], oneItem[that.treeDict['ID']]];
      }
      linKey = _.uniq(_.compact(linKey));
      let obj = {
        title: oneItem[that.treeDict['title']],
        key: oneItem[that.treeDict['key']],
        isLeaf: oneItem[that.treeDict['isLeaf']] == 'LAST_STAGE',
        level: parseInt(oneItem[that.treeDict['level']]) - 1,
        upKey: oneItem[that.treeDict['upKey']],
        upName: oneItem[that.treeDict['upName']],
        linKey: linKey,
        linKeyStr: _.join(linKey),
        expanded: true,
        datas: oneItem
      }
      return obj;
    }

    /**
     * 组织一级数据
     * @param pData
     */
    function getNodeDatas(pData) {
      let list = _.filter(pData, (item) => {
        let level = item[that.treeDict['level']];
        if (typeof (level) == 'number') {
          level = level.toString();
        }
        return level == '1';
      });
      if (that.util.Base_HasValue(pRoleDatas)) {
        let roleArray = [];
        _.each(pRoleDatas, item => {
          roleArray.push(item['roleId']);
        });
        list = _.filter(list, (item) => {
          const iFind = _.indexOf(roleArray, item['ID']);
          return iFind > -1;
        })
      }
      let nodeDatas = [];
      _.each(list, oneItem => {
        let children = getChildren(pData, oneItem);
        let obj = makeUpLeftData(oneItem);
        obj['children'] = children;
        nodeDatas.push(obj);
      });
      return nodeDatas;
    }

    /**
     * 组织二级，及二级一下的数据
     * @param pData
     * @param pParentsNode
     */
    function getChildren(pData?, pParentsNode?) {
      let list = _.filter(pData, (item) => {
        return item[that.treeDict['upKey']] == pParentsNode[that.treeDict['key']];
      });
      let nodeDatas = [];
      _.each(list, oneItem => {
        let obj = makeUpLeftData(oneItem, pParentsNode);
        let children = getChildren(pData, oneItem);
        obj['children'] = children;
        nodeDatas.push(obj);
      });
      return nodeDatas;
    }

    this.nodes = [];
    let nodeDatas = getNodeDatas(pData);
    this.nodes = nodeDatas;
  }

  findOneColumnValue(pColumnName) {
    let columns = _.get(this.formData, 'columns', []); // 找到 columns 层级
    let oneValue = null; // 定义一个返回值，给予默认值为控制

    _.each(columns, (oneOneColum) => { // 循环 columns 层级
      if (oneOneColum['column'] === pColumnName) { // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
        oneValue = _.get(oneOneColum, 'model');
      }
      let columnGroup = _.get(oneOneColum, 'columnGroup', null); // 找到 columnGroup 层级
      _.each(columnGroup, (oneGroupColumn) => {
        if (oneGroupColumn['column'] === pColumnName) {  // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
          oneValue = _.get(oneGroupColumn, 'model', null);
        }
      });
    });
    return oneValue; // 返回值
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput() {
    let result = true;
    result = this.checkOneColumn('WORD_NAME', '模板名称');
    if (!result) {
      return result;
    }
    // result = this.checkOneColumn('WORD_PROCESS', '适用流程');
    // if (!result) {
    //   return result;
    // }
    result = this.checkOneColumn('WORD_FILE01', '模板文档');
    if (!result) {
      return result;
    }
    // result = this.checkOneColumn('WORD_FILE02', '印章图片');
    // if (!result) {
    //   return result;
    // }
    // result = this.checkOneColumn('WORD_FILE03', '印章占位图片');
    // if (!result) {
    //   return result;
    // }
    return result;
  }

  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.formData, 'columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doInsert() {
    let template = {
      'LIST': [
        {
          'TABLE': 'WORD_CONFIG',
          'ACTION': 'Insert',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'WORD_NAME'},
            {'COLUMN': 'CONTRACT_DEFAULT_NAME'},
            {'COLUMN': 'WORD_PROCESS'},
            {'COLUMN': 'WORD_PROCESS_JSON'},
            {'COLUMN': 'WORD_FILE01'},
            {'COLUMN': 'WORD_FILE02'},
            {'COLUMN': 'WORD_FILE03'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_TIME'},
            {'COLUMN': 'U_DELETE'}
          ],
          'RECORDS': [
            {
              'ID': 'SYS-ACTION-UUID',
              'WORD_NAME': this.getOneCloumnValue("WORD_NAME"),
              'CONTRACT_DEFAULT_NAME':this.getOneCloumnValue("CONTRACT_DEFAULT_NAME"),
              'WORD_PROCESS': this.getOneCloumnValue("WORD_PROCESS"),
              'WORD_PROCESS_JSON': this.getOneCloumnValue("WORD_PROCESS_JSON"),
              'WORD_FILE01': this.getOneCloumnValue("WORD_FILE01"),
              'WORD_FILE02': this.getOneCloumnValue("WORD_FILE02"),
              'WORD_FILE03': this.getOneCloumnValue("WORD_FILE03"),
              'ADD_USERID': 'SYS_USER_ID',
              'ADD_NAME': 'SYS_USER_NAME',
              'ADD_TIME': 'SYS-DATETIME',
              'U_TIME': 'SYS-DATETIME',
              'U_DELETE': 1
            }
          ]
        }
      ]
    };
    this.autoInsert.ExecJson(template).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '保存成功'});
      } else {
        this.confirmModal.show('error', {'title': '保存失败，请刷新重试'});
      }
    });
  }

  doUpdate() {
    let template = {
      'LIST': [
        {
          'TABLE': 'WORD_CONFIG',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': this.parentData['id'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'WORD_NAME'},
            {'COLUMN': 'CONTRACT_DEFAULT_NAME'},
            {'COLUMN': 'WORD_PROCESS'},
            {'COLUMN': 'WORD_PROCESS_JSON'},
            {'COLUMN': 'WORD_FILE01'},
            {'COLUMN': 'WORD_FILE02'},
            {'COLUMN': 'WORD_FILE03'},
            {'COLUMN': 'CON_TYPE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'WORD_NAME': this.getOneCloumnValue("WORD_NAME"),
              'CONTRACT_DEFAULT_NAME':this.getOneCloumnValue("CONTRACT_DEFAULT_NAME"),
              'WORD_PROCESS': this.getOneCloumnValue("WORD_PROCESS"),
              'WORD_PROCESS_JSON': this.getOneCloumnValue("WORD_PROCESS_JSON"),
              'CON_TYPE': this.getOneCloumnValue("CON_TYPE"),
              'WORD_FILE01': this.getOneCloumnValue("WORD_FILE01"),
              'WORD_FILE02': this.getOneCloumnValue("WORD_FILE02"),
              'WORD_FILE03': this.getOneCloumnValue("WORD_FILE03"),
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': 'SYS_USER_NAME',
              'UPD_USERID': 'SYS_USER_ID'
            }
          ]
        }
      ]
    };
    this.autoInsert.ExecJson(template).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '保存成功'});
      } else {
        this.confirmModal.show('error', {'title': '保存失败，请刷新重试'});
      }
    });
  }

  getOneCloumnValue(pColumnName) {
    let bFind = false;
    let result = null;
    _.each(this.formData['columns'], oneColumn => {
      if ((oneColumn['column'] == pColumnName) && (!bFind)) {
        result = oneColumn['model']
        bFind = true;
      }

      if (this.util.Base_HasValue(oneColumn['columnGroup'])) {
        _.each(oneColumn['columnGroup'], oneItemColum => {
          if ((oneItemColum['column'] == pColumnName) && (!bFind)) {
            result = oneItemColum['model']
            bFind = true;
          }
        })
      }
    });
    return result;
  }

  doSave() {
    if (!this.checkInput()) {
      return false;
    }
    if (this.parentData['modeType'] == 'modify') {
      this.doUpdate();
    } else if (this.parentData['modeType'] == 'add') {
      this.doInsert();
    }
  }

  doDataSource() {
    let wordProcess = _.get(this.formData, ['columns', 1, 'model']);
    const ds = observableForkJoin(
      this.requestDataService.getDataByCondition('simulationTrain/ig/wordConfig/system_datasource_config.json', {'USER_ID': wordProcess})
    );
    ds.subscribe(
      datasource => {
        const roleDatas = _.get(datasource, [0, ['data']]);
        const search = observableForkJoin(
          this.requestDataService.getDataByCondition('simulationTrain/ig/wordConfig/system_datasource_tree.json', {})
        );
        search.subscribe(
          values => {
            this.makeTreeData(values[0]['data'], roleDatas);
            this.selectNode = null;
            this.defaultSelectedKeys = [];
          }
        );
      }
    );


  }

  doTest() {
    // this.executionTree();
    let fileName = "http://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/genecreate/%E5%8D%8E%E5%A4%A7%E5%8D%9A%E6%80%9D-%E5%8D%8E%E4%B8%AD%E5%B8%88%E8%8C%83%E5%A4%A7%E5%AD%A6-%E6%99%BA%E6%85%A7%E5%AE%9E%E9%AA%8C%E5%AE%A4%E6%99%BA%E8%83%BD%E7%BB%88%E7%AB%AF%E4%B8%8E%E5%B9%B3%E5%8F%B0%E5%BC%80%E5%8F%91%20V1.0_1834e9c6c0a7371347d763757381ff85_7b2305dabb755392aebce747de01a34b.docx";
    fileName = this.util.Base_getFileNameOnly(fileName);
    let id = "06283ddb73cd47deb1fead46e2031493";
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition('genecreate/es/es_order_list.json', {'ID': id}),
    );
    search.subscribe(
      values => {
      }
    );
  }

  getChild(pNode, pParentsId) {
    let bFind: Boolean = false;
    let children = [];
    _.each(pNode, oneNode => {
      if (!bFind) {
        let thisChildren = oneNode['children'];
        if (oneNode['key'] == pParentsId) {
          children = thisChildren;
          bFind = true;
        } else {
          if (this.util.Base_HasValue(thisChildren)) {
            children = this.getChild(thisChildren, pParentsId);
          }
        }
      }
    })
    return children;
  }

  public formData: any = {
    'type': 'normal',
    'columns': [
      {
        'column': 'WORD_NAME',
        'name': 'WORD_NAME_NAME',
        'component': 'input',
        'lable': '模板名称',
        'comment': '模板名称',
        'placeholder': '请输入模板名称',
        'lableSpan': 3,
        'itemSpan': 21,
        'customize': 24,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'WORD_PROCESS',
        'name': 'WORD_PROCESS_NAME',
        'component': 'select',
        'lable': '适用流程',
        'comment': '适用流程',
        'placeholder': '请输入适用流程',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 12,
        'model': null,
        'visible': true,
        'require': true,
        'dictionary': 'wordConfig/dict/WORD_PROCESS.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            'column': 'WORD_PROCESS_JSON',
            'name': 'WORD_PROCESS_JSON_NAME',
            'component': 'input',
            'lable': '编码',
            'comment': '编码',
            'placeholder': '请输入编码',
            'insertValue': null,
            'fromValue': 'WORD_PROCESS_JSON',
            'model': null,
            'visible': false,
            'require': false
          }
        ]
      },
      {
        'column': 'CON_TYPE',
        'name': 'CON_TYPE_NAME',
        'component': 'select',
        'lable': '适用合同',
        'comment': '适用合同',
        'placeholder': '请输入适用合同类型',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 12,
        'model': null,
        'visible': false,
        'require': false,
        'dictionary': 'assets/basic/dict/CON_TYPE.json',
        'dictionaryPF': null
      },
      {
        'column': 'CONTRACT_DEFAULT_NAME',
        'name': 'CONTRACT_DEFAULT_NAME_NAME',
        'component': 'input',
        'lable': '文件默认名称',
        'comment': '文件默认名称',
        'placeholder': '请输入文件默认名称',
        'lableSpan': 3,
        'itemSpan': 21,
        'customize': 24,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'WORD_FILE01',
        'name': 'WORD_FILE01_NAME',
        'component': 'file',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 12,
        'lable': '模板文档',
        'comment': '模板文档',
        'placeholder': '模板文档',
        'model': null,
        'disable': true,
        'visible': true,
        'require': true
      },
      {
        'column': 'WORD_FILE02',
        'name': 'WORD_FILE02_NAME',
        'component': 'file',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 12,
        'lable': '印章图片',
        'comment': '印章图片',
        'placeholder': '印章图片',
        'model': null,
        'disable': true,
        'visible': false,
        'require': false
      },
      {
        'column': 'WORD_FILE03',
        'name': 'WORD_FILE03_NAME',
        'component': 'file',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 12,
        'lable': '印章占位图片',
        'comment': '印章占位图片',
        'placeholder': '印章占位图片',
        'model': null,
        'disable': true,
        'visible': false,
        'require': false
      }
    ]
  };

}
