import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-subject-teacher',
  templateUrl: './subject-teacher.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './subject-teacher.component.css']
})
export class SubjectTeacherComponent implements OnInit {
  listData: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1 = [];
    if (this.listData.length > 0) {
      _.each(this.listData, oneData => {
        const data = {
          '方向名称': oneData['SUBJECT_DIRECTION_NAME'] || '',
          '学术带头人姓名': oneData['MAIN_LEADER_NAME'] || '',
          '学术带头人出生年月': oneData['MAIN_BIRTHDAY'] || '',
          '学术带头人专业技术职务': oneData['MAIN_POSITION'] || '',
          '专任教师数': oneData['ACOUNT'] || 0,
          '正高职专任教师数': oneData['PROFESSOR_COUNT'] || 0,
          '博士学位专任教师数': oneData['DOCTOR_COUNT'] || 0,
        };
        data1.push(data);
      });
    } else {
      const data = {
        '方向名称': '',
        '学术带头人姓名': '',
        '学术带头人出生年月': '',
        '学术带头人专业技术职务': '',
        '专任教师数': '',
        '正高职专任教师数': '',
        '博士学位专任教师数': ''
      };
      data1.push(data);
    }

    const opts = [
      {
        sheetid: 'II-2 各学科方向专任教师情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("II-2 各学科方向专任教师情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_subject_teacher.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
