import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {UtilsService} from '../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {DataService} from '../education/data.service';
import {AppConfigService} from '../../service/app-config.service';
import {Router} from '@angular/router';
import * as _ from 'underscore';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ComplainResourcesService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case 'audit':
        this.audit(pParentData);
        break;
      case 'backText':
        this.backText(pParentData);
        break;
    }
  }


  backText(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    // let flag = true;
    // _.each(selectList, item => {
    //   flag = flag && (item['COMPLAIN_STATE'] === 'AUDITING');
    // });
    // if (!flag) {
    //   this.confirmModal.show('warning', {
    //     title: '只有待审核状态才可以进行审核',
    //   });
    //   return false;
    // }
    this.modalService.create({
      nzTitle: '回复',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'COMPLAIN_BACK',
              'name': 'COMPLAIN_BACK',
              'component': 'textarea',
              'customize': 24,
              'lable': '回复',
              'comment': '回复',
              'placeholder': '请选择回复',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => this.doBackText(selectList, pParentData, response)
    });
  }

  audit(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    _.each(selectList, item => {
      flag = flag && (item['COMPLAIN_STATE'] === 'AUDITING');
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '只有待审核状态才可以进行审核',
      });
      return false;
    }
    this.modalService.create({
      nzTitle: '审核',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'COMPLAIN_STATE',
              'name': 'COMPLAIN_STATE',
              'component': 'select',
              'customize': 24,
              'lable': '状态',
              'comment': '状态',
              'placeholder': '请选择状态',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'complain/basic/dict/COMPLAIN_STATE_CHECK.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => this.doAudit(selectList, pParentData, response)
    });
  }

  doBackText(selectedList, pObject, result) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      const assignObj = result.modalFormData.columns[0];
      let ids = '';
      const idsArray = [];
      _.each(selectedList, (item, index) => {
        if (selectedList.length === index + 1) {
          ids += '\'' + item['ID'] + '\'';
        } else {
          ids += '\'' + item['ID'] + '\',';
        }
        idsArray.push(item['ID']);
      });
      ids = '(' + ids + ')';
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'ms_complain',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'COMPLAIN_BACK'},
              {'COLUMN': 'UPD_TIME'},
              {'COLUMN': 'UPD_NAME'},
              {'COLUMN': 'UPD_USERID'}
            ],
            'RECORDS': [
              {
                'COMPLAIN_BACK': assignObj.model,
                'UPD_TIME': 'SYS-DATETIME',
                'UPD_NAME': this.user.USERNAME,
                'UPD_USERID': this.user.USER_ID
              }
            ]
          }
        ]
      };
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '回复成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '回复失败，请刷新重试'});
        }
      });
    } else {
      return false;
    }
  }

  doAudit(selectedList, pObject, result) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      const assignObj = result.modalFormData.columns[0];
      let ids = '';
      const idsArray = [];
      _.each(selectedList, (item, index) => {
        if (selectedList.length === index + 1) {
          ids += '\'' + item['ID'] + '\'';
        } else {
          ids += '\'' + item['ID'] + '\',';
        }
        idsArray.push(item['ID']);
      });
      ids = '(' + ids + ')';
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'ms_complain',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'COMPLAIN_STATE'},
              {'COLUMN': 'UPD_TIME'},
              {'COLUMN': 'UPD_NAME'},
              {'COLUMN': 'UPD_USERID'}
            ],
            'RECORDS': [
              {
                'COMPLAIN_STATE': assignObj.model,
                'UPD_TIME': 'SYS-DATETIME',
                'UPD_NAME': this.user.USERNAME,
                'UPD_USERID': this.user.USER_ID
              }
            ]
          }
        ]
      };
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '审核成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '审核失败，请刷新重试'});
        }
      });
    } else {
      return false;
    }
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }
}
