import {Component, Input, OnInit,} from '@angular/core';
import * as _ from "underscore";
import * as _sh from 'lodash';
import {DataCenterService} from "../../../service/data-center.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {AppConfigService} from "../../../service/app-config.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
import {NzModalService} from "ng-zorro-antd";
import {UserInfoService} from "../../../service/user-info.service";
import {AutoInsertService} from "../../../service/auto-insert.service";

@Component({
  selector: 'app-settlement-amount',
  templateUrl: './settlement-amount.component.html',
  styleUrls: ['./settlement-amount.component.scss']
})
export class SettlementAmountComponent implements OnInit {

  @Input() parentData;
  msTranAccountForm: any = {
    "tablename": "ms_tran_account",
    "formname": "ms_tran_account",
    "title": "结算单管理",
    "type": "normal",
    "add_link": null,
    "modify_link": "JieLiv2/ms/ms_tran_account_detail.json",
    "columns": [
      {
        "column": "ID",
        "pk_column": true,
        "iscondition": true,
        "name": "ID_NAME",
        "component": "input",
        "lable": "主键",
        "comment": "主键",
        "placeholder": "主键",
        "insertValue": "SYS-ACTION-UUID",
        "model": "",
        "visible": false,
        "require": false
      },
      {
        "column": "COUNT_STATE",
        "name": "COUNT_STATE_NAME",
        "component": "select",
        "lable": "状态",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "状态",
        "placeholder": "状态",
        "model": "ADD",
        "require": false,
        "dictionary": "JieLiv2/basic/dict/COUNT_STATE.json",
        "dictionaryPF": null,
        "visible": false,
        "disable": [
          "add",
          "modify"
        ]
      },
      {
        "column": "COUNT_CODE",
        "name": "COUNT_CODE_NAME",
        "component": "input",
        "lable": "结算单号",
        "comment": "结算单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入结算单号",
        "model": null,
        "visible": [
          "detail",
          "modify"
        ],
        "require": false,
        "disable": [
          "modify"
        ]
      },
      {
        "column": "COUNT_TYPE",
        "name": "COUNT_TYPE_NAME",
        "component": "select",
        "lable": "结算类型",
        "comment": "结算类型",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "结算类型",
        "model": null,
        "visible": false,
        "require": false,
        "insertValue": "ACCOUNT"
      },
      {
        "column": "CARRIER_ID",
        "name": "CARRIER_ID_NAME",
        "component": "poplist",
        "lable": "承运商名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "承运商名称",
        "placeholder": "请选择承运商名称",
        "model": "",
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_CARRIER.json",
        "dictionaryPF": null,
        "dictionaryParam": [
          {
            "clearValues": [
              "SYS.ms_tran_account.DR_ID",
              "SYS.ms_tran_account.DR_NAME",
              "SYS.ms_tran_account.DR_MOBILE",
              "SYS.ms_tran_account.TRUCK_ID",
              "SYS.ms_tran_account.TRUCK_CODE"
            ]
          }
        ],
        "columnGroup": [
          {
            "column": "CARRIER_NAME",
            "name": "CARRIER_NAME_NAME",
            "component": "input",
            "lable": "承运商名称",
            "class": "col-lg-4 col-sm-6 col-xs-12",
            "comment": "承运商名称",
            "placeholder": "承运商名称",
            "fromValue": "name",
            "model": "",
            "visible": false,
            "require": false
          }
        ],
        "disable": [
          "revocation",
          "audit",
          "auditCancel",
          "confirm",
          "confirmCancel",
          "reCheck"
        ],
      },
      {
        "column": "DR_ID",
        "name": "DR_ID_NAME",
        "component": "poplist",
        "lable": "司机姓名",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "司机姓名",
        "placeholder": "请选择司机姓名",
        "model": "",
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TRAN_DISPATCH_DRIVER.json",
        "dictionaryPF": null,
        "disable": [
          "revocation",
          "audit",
          "auditCancel",
          "confirm",
          "confirmCancel",
          "reCheck"
        ],
        "columnGroup": [
          {
            "column": "DR_NAME",
            "name": "DR_NAME_NAME",
            "component": "input",
            "lable": "司机姓名",
            "comment": "司机姓名",
            "placeholder": "司机姓名",
            "fromValue": "name",
            "model": "",
            "visible": false,
            "require": false
          },
          {
            "column": "DR_MOBILE",
            "name": "DR_MOBILE_NAME",
            "component": "input",
            "lable": "司机电话",
            "comment": "司机电话",
            "placeholder": "司机电话",
            "fromValue": "DRIVER_MOBILE",
            "model": "",
            "visible": true,
            "require": false,
            "disable": [
              "revocation",
              "audit",
              "auditCancel",
              "confirm",
              "confirmCancel",
              "reCheck"
            ],
          },
          {
            "column": "TRUCK_ID",
            "name": "TRUCK_ID_NAME",
            "component": "input",
            "lable": "车牌号",
            "comment": "车牌号",
            "placeholder": "车牌号",
            "fromValue": "TRUCK_ID",
            "model": "",
            "visible": false,
            "require": false
          },
          {
            "column": "TRUCK_CODE",
            "name": "TRUCK_CODE_NAME",
            "component": "input",
            "lable": "车牌号",
            "comment": "车牌号",
            "placeholder": "车牌号",
            "fromValue": "TRUCK_CODE",
            "model": "",
            "visible": true,
            "require": false,
            "disable": [
              "revocation",
              "audit",
              "auditCancel",
              "confirm",
              "confirmCancel",
              "reCheck"
            ],
          }
        ]
      },
      {
        "column": "COUNT_MONEY",
        "name": "COUNT_MONEY_NAME",
        "component": "input",
        "type": "number",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "总结算金额",
        "comment": "总结算金额",
        "placeholder": "总结算金额",
        "model": 0,
        "visible": false,
        "require": false
      },
      {
        "column": "COUNT_ACTOR",
        "name": "COUNT_ACTOR_NAME",
        "component": "input",
        "lable": "经手人",
        "comment": "经手人",
        "placeholder": "请输入经手人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": "",
        "visible": true,
        "require": true,
        "disable": [
          "revocation",
          "audit",
          "auditCancel",
          "confirm",
          "confirmCancel",
          "reCheck"
        ],
      },
      {
        "column": "COUNT_VOUCHER",
        "name": "COUNT_VOUCHER_NAME",
        "component": "input",
        "lable": "证明人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "证明人",
        "placeholder": "请输入证明人",
        "model": "",
        "visible": true,
        "require": true,
        "disable": [
          "revocation",
          "audit",
          "auditCancel",
          "confirm",
          "confirmCancel",
          "reCheck"
        ],
      },
      {
        "column": "COUNT_USER",
        "name": "COUNT_USER",
        "component": "poplist",
        "lable": "结算人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "结算人",
        "placeholder": "结算人",
        "model": "",
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_EMP.json",
        "dictionaryPF": null
      },
      {
        "column": "COUNT_TIME",
        "name": "COUNT_TIME",
        "component": "datetime",
        "lable": "结算时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "结算时间",
        "placeholder": "结算时间",
        "insertValue": "SYS-DATETIME",
        "model": "",
        "visible": false,
        "require": false
      },
      {
        "column": "PAYMENT_METHOD",
        "name": "PAYMENT_METHOD",
        "component": "select",
        "lable": "支付方式",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "支付方式",
        "placeholder": "支付方式",
        "model": "",
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/PAYMENT_METHOD.json",
        "dictionaryPF": null
      },
      {
        "column": "FILE_NUM",
        "name": "FILE_NUM_NAME",
        "component": "input",
        "type": "number",
        "lable": "附件张数",
        "comment": "附件张数",
        "placeholder": "附件张数",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": [
          "revocation",
          "auditCancel",
          "confirmCancel",
          "reCheck"
        ]
      },
      {
        "column": "SERIAL_NUMBER",
        "name": "SERIAL_NUMBER_NAME",
        "component": "input",
        "type": "number",
        "lable": "结算序号",
        "comment": "结算序号",
        "placeholder": "结算序号",
        "model": null,
        "visible": true,
        "require": true,
        "disable": [
          "revocation",
          "auditCancel",
          "confirmCancel",
          "reCheck"
        ]
      },
      {
        "column": "AMOUNT_PAYABLE",
        "name": "AMOUNT_PAYABLE_NAME",
        "component": "input",
        "type": "number",
        "lable": "应付金额",
        "comment": "应付金额",
        "placeholder": "应付金额",
        "model": null,
        "require": false,
        "disable": [
          "detail",
          "audit",
          "auditCancel",
          "confirm",
          "reCheck",
        ],
        "visible": [
          "detail",
          "audit",
          "auditCancel",
          "confirm",
          "reCheck",
        ],
      },
      {
        "column": "COUNT_MONEY",
        "name": "COUNT_MONEY_NAME",
        "component": "input",
        "type": "number",
        "lable": "实付金额",
        "comment": "实付金额",
        "placeholder": "实付金额",
        "model": null,
        "require": false,
        "disable": [
          "detail",
          "audit",
          "auditCancel",
          "confirm",
          "reCheck",
        ],
        "visible": [
          "detail",
          "audit",
          "auditCancel",
          "confirm",
          "reCheck",
        ],
      },
      {
        "column": "COUNT_TEXT",
        "name": "COUNT_TEXT_NAME",
        "component": "textarea",
        "lable": "备注",
        "class": "col-xs-12",
        "comment": "备注",
        "lableSpan": 3,
        "itemSpan": 21,
        "customize": 24,
        "placeholder": "请输入备注",
        "model": "",
        "visible": true,
        "require": false,
        "disable": [
          "revocation",
          "auditCancel",
          "confirmCancel"
        ],
      },
      {
        "column": "ADD_USERID",
        "name": "ADD_USERID_NAME",
        "component": "input",
        "lable": "添加人账户ID",
        "comment": "添加人账户ID",
        "placeholder": "请输入添加人账户ID",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_ID",
        "model": null,
        "visible": false,
        "require": false,
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
      },
      {
        "column": "ADD_TIME",
        "name": "ADD_TIME_NAME",
        "component": "input",
        "lable": "制单时间",
        "comment": "制单时间",
        "placeholder": "制单时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
        "model": null,
        "visible": ["detail"]
      },
      {
        "column": "ADD_NAME",
        "name": "ADD_NAME_NAME",
        "component": "input",
        "lable": "制单人",
        "comment": "制单人",
        "placeholder": "请输入制单人姓名",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_NAME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
        "model": "",
        "visible": ["detail"]
      },
      {
        "column": "UPD_NAME",
        "name": "UPD_NAME_NAME",
        "component": "input",
        "lable": "更新人",
        "comment": "更新人",
        "placeholder": "请输入更新人姓名",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_NAME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "model": "",
        "visible": false
      },
      {
        "column": "UPD_TIME",
        "name": "UPD_TIME_NAME",
        "component": "input",
        "lable": "更新时间",
        "comment": "更新时间",
        "placeholder": "请输入更新时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "model": "",
        "visible": false
      },
      {
        "column": "UPD_USERID",
        "name": "UPD_USERID_NAME",
        "component": "input",
        "lable": "更新人ID",
        "comment": "更新人ID",
        "placeholder": "请输入添加人账户ID",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_ID",
        "model": "",
        "require": false,
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "visible": false
      },
      {
        "column": "U_TIME",
        "name": "U_TIME_NAME",
        "component": "input",
        "lable": "更新时间",
        "comment": "更新时间",
        "placeholder": "请选择更新时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
        "model": null,
        "visible": false
      }
    ]
  };

  public searchState: boolean = false;//false表示未开始查询
  //按钮和输入框状态
  btnAndInputState = {
    accountSaveVisit: false,//保存
    revocationVisit: false,//撤销
    auditVisit: false,//审核
    auditCancelVisit: false,//弃核
    confirmVisit: false,//确认结算
    confirmCancelVisit: false,//取消结算
    inputVisit: false,//结算明细的结算金额输入框
    exceptionBtnVisit: false,//异常处理按钮
    addAccountBtnVisit: false,//结算明细新增按钮
    deleteAccountBtnVisit: false,//结算明细删除按钮
    reCheckVisit: false,//复核

  }

  //结算主信息
  msTranAccount: any = {};
  //结算明细
  msTranDispatch: any = [];

  //运单信息
  msTranOrders: any = [];

  //异常单
  msTaskException: any = [];

  public msTranAccountDetailVisible = false;

  msTranAccountDetailModalForm: any = {
    "tablename": "ms_tran_dispatch",
    "formname": "ms_tran_dispatch",
    "title": "运输计划调度查询",
    "type": "normal",
    "columns": [
      {
        "column": "DISP_CODE",
        "name": "DISP_CODE_NAME",
        "component": "input",
        "lable": "调度单号",
        "comment": "调度单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入调度单号",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "ADD_NAME",
        "name": "ADD_NAME_NAME",
        "component": "input",
        "lable": "调度人",
        "comment": "调度人",
        "placeholder": "请输入调度人",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "DISP_STATE",
        "name": "DISP_STATE_NAME",
        "component": "select",
        "lable": "调度状态",
        "comment": "调度状态",
        "placeholder": "调度状态",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/MS_TRAN_DISPATCH_STATE.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_LEVEL",
        "name": "DISP_LEVEL_NAME",
        "component": "select",
        "lable": "计划等级",
        "comment": "计划等级",
        "placeholder": "计划等级",
        "model": null,
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TASK_LEVEL.json",
        "dictionaryPF": null
      },
      {
        "column": "TRUCK_ID",
        "name": "TRUCK_ID_NAME",
        "component": "poplist",
        "lable": "车牌号",
        "comment": "车牌号",
        "placeholder": "请选择车牌号",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TRUCK_ID.json",
        "dictionaryPF": null
      },
      {
        "column": "DR_ID",
        "name": "DR_ID_NAME",
        "component": "poplist",
        "lable": "司机",
        "comment": "司机",
        "placeholder": "请选择司机",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_DRIVER.json",
        "dictionaryPF": null
      },
      {
        "column": "ADD_DATE_BEGIN",
        "name": "ADD_DATE_BEGIN_NAME",
        "component": "date",
        "lable": "添加时间-起",
        "comment": "添加时间-起",
        "placeholder": "请选择添加时间-起",
        "dateOptions": {
          "default": {
            "today": false,
            "pastDay": 30
          }
        },
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "ADD_DATE_END",
        "name": "ADD_DATE_END_NAME",
        "component": "date",
        "lable": "添加时间-止",
        "comment": "添加时间-止",
        "placeholder": "请选择添加时间-止",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  }

  otherMoneyModalForm: any = {
    "tablename": "ms_account_set",
    "formname": "ms_account_set",
    "title": "金额名称查询",
    "type": "normal",
    "columns": [
      {
        "column": "NAME",
        "name": "NAME_NAME",
        "component": "input",
        "lable": "费用名称",
        "comment": "费用名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入名称",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "KINGDEE_ACCOUNT_CODE",
        "name": "KINGDEE_ACCOUNT_CODE_NAME",
        "component": "input",
        "lable": "金蝶科目代码",
        "comment": "金蝶科目代码",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入金蝶科目代码",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  }

  otherMoneyShow: any = false;
  otherModalData;
  otherTotalModal: any = 1;
  otherPageIndexModal: any = 1;
  otherPageSizeModal: any = this.appConfigService.config.pageCount;
  otherMoneyDataList: any = [];
  allCheckedOther = false;
  indeterminateOther = false;
  otherAll = 0;
  accountAll = 0;
  borrowAll = 0;
  lockAll = 0;
  sumAll = 0;
  /**
   * 弹出框
   */
    //明细全选
  public allCheckedMsTranDispatch = false;
  public indeterminateMsTranDispatch = false;
  public modalDatas;
  // 分页下标
  public pageIndexModal: any = 1;
  // 每页显示数据
  public pageSizeModal: any = this.appConfigService.config.pageCount;
  // 总条数
  public totalModal: any = 1;
  public nzPageSizeOptionsModal: any = [10, 20, 30, 50, 100, 200];
  //弹窗的有值的条件
  private modalConditionList: any[];

  //异常全选
  public allCheckedMsTaskException = false;
  public indeterminateMsTaskException = false;

  constructor(
    private autoService: AutoInsertService,
    private dataCenterService: DataCenterService,
    private appConfigService: AppConfigService,
    private requestService: RequestDataService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService,
    private userInfoService: UserInfoService
  ) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  //显示弹窗信息
  showMsTranAccountDetailVisible() {
    //显示弹窗
    this.msTranAccountDetailVisible = true;
    this.searchFilter(true);
  }

  // searchFilterModal(reset: boolean = true) {
  //   let condition = {};
  //   this.searchDataModal(reset, condition);
  // }

  //弹窗数据
  searchDataModal(reset: boolean, condition?: {}) {
    this.searchState = true;
    if (reset) {
      this.pageIndexModal = 1;
    }
    const search01$ = observableForkJoin(
      this.requestService.getPaginationData('/JieLiv2/basic/dict/DISPATCH_CODE_BACK.json', condition, this.pageIndexModal, this.pageSizeModal),
    );
    search01$.subscribe(
      values => {
        this.modalDatas = _sh.get(values, [0, 'data'], []);
        this.totalModal = _sh.get(values, [0, 'page', 'rowCount'], 0);
        this.pageIndexModal = _sh.get(values, [0, 'page', 'pageNum'], 0);
        this.searchState = false;
      }
    );
  }

  //过滤按钮
  searchFilter(reset: boolean = true) {
    this.modalConditionList = this.transformCondition(this.msTranAccountDetailModalForm['columns']);
    let condition = {};
    _.each(this.modalConditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    this.searchDataModal(reset, condition);
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  resetFilter() {
    _.each(this.msTranAccountDetailModalForm['columns'], (item) => {
      item['model'] = null;
    });
    this.searchFilter();
  }

  //选择一条结算明细数据
  selectOneModalData(oneModalData) {
    //  判断这个调度单，是否在 this.msTranDispatch 里面了，如果在报错。
    let check = true;
    this.msTranDispatch.forEach((item) => {
      if (item.DISP_ID == oneModalData.DISP_ID) {
        check = false;
      }
    })
    //  如果不在添加，并关闭。
    if (check) {
      oneModalData['checked'] = false;
      let finishNum = _sh.get(oneModalData, ['EXP_UN_COUNT_DICT'], 0);
      let sumNum = _sh.get(oneModalData, ['EXP_ALL_COUNT_DICT'], 0);
      oneModalData['redText'] = finishNum < sumNum;
      this.msTranDispatch.push(oneModalData);
      this.accountAll = 0;
      this.borrowAll = 0;
      this.lockAll = 0;
      this.sumAll = 0;
      _sh.each(this.msTranDispatch, item => {
        let tempAccount = 0;
        let tempBorrow = 0;
        let tempLock = 0;
        let tempSum = 0;
        if (item['DET_MONEY'] != null) {
          tempAccount = item['DET_MONEY']
          this.accountAll = this.accountAll + tempAccount;
        }
        if (item['DISP_BORROW'] != null) {
          tempBorrow = item['DISP_BORROW']
          this.borrowAll = this.borrowAll + tempBorrow;
        }
        if (item['DISP_LOCK_MONEY'] != null) {
          tempLock = item['DISP_LOCK_MONEY']
          this.lockAll = this.lockAll + tempLock;
        }
        if (item['DISP_TOTALSFEE'] != null) {
          tempSum = item['DISP_TOTALSFEE']
          this.sumAll = this.sumAll + tempSum;
        }
      })
      this.msTranAccountDetailVisible = false;
      observableForkJoin(
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_plant_detail.json', {'DISP_ID': oneModalData.DISP_ID}),
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_task_exception_search.json', {'DISP_ID': oneModalData.DISP_ID}),
      ).subscribe(res => {
        this.msTranOrders = _sh.get(res, [0, 'data']);
        this.msTaskException = _sh.get(res, [1, 'data']);
      })
    } else {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "该调度单已经添加到明细中，无需反复添加！"
      });
    }
  }


  refreshStatusMsTranDispatch(): void {
    const allChecked = this.msTranDispatch.length > 0 && this.msTranDispatch.every(value => value.checked === true);
    const allUnChecked = this.msTranDispatch.every(value => !value.checked);
    this.allCheckedMsTranDispatch = allChecked;
    this.indeterminateMsTranDispatch = (!allChecked) && (!allUnChecked);
  }


  checkAllMsTranDispatch(value: boolean): void {
    this.msTranDispatch.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusMsTranDispatch();
  }

  checkAllMsTaskException(value: boolean): void {
    this.msTaskException.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusMsTaskException();
  }

  refreshStatusMsTaskException(): void {
    const allChecked = this.msTaskException.length > 0 && this.msTaskException.every(value => value.checked === true);
    const allUnChecked = this.msTaskException.every(value => !value.checked);
    this.allCheckedMsTaskException = allChecked;
    this.indeterminateMsTaskException = (!allChecked) && (!allUnChecked);
  }

  //删除选中的结算明细数据
  deleteMsTranDispatch() {
    // let newMsTranDispatch = [];
    let newMsTranDispatch = this.msTranDispatch.filter((e) => {
      return e.checked == false;
    })
    if (newMsTranDispatch.length == this.msTranDispatch.length) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择一条数据进行删除！"
      });
    }
    this.msTranDispatch = newMsTranDispatch;
    this.accountAll = 0;
    this.borrowAll = 0;
    this.lockAll = 0;
    this.sumAll = 0;
    _sh.each(this.msTranDispatch, item => {
      let tempAccount = 0;
      let tempBorrow = 0;
      let tempLock = 0;
      let tempSum = 0;
      if (item['DET_MONEY'] != null) {
        tempAccount = item['DET_MONEY']
        this.accountAll = this.accountAll + tempAccount;
      }
      if (item['DISP_BORROW'] != null) {
        tempBorrow = item['DISP_BORROW']
        this.borrowAll = this.borrowAll + tempBorrow;
      }
      if (item['DISP_LOCK_MONEY'] != null) {
        tempLock = item['DISP_LOCK_MONEY']
        this.lockAll = this.lockAll + tempLock;
      }
      if (item['DISP_TOTALSFEE'] != null) {
        tempSum = item['DISP_TOTALSFEE']
        this.sumAll = this.sumAll + tempSum;
      }
    })
  }

  //异常处理按钮
  handleException() {
    let newMsTaskException = this.msTaskException.filter((e) => {
      return e.checked == true;
    })
    if (newMsTaskException.length == 0) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择至少一条数据！"
      });
      return;
    }
    newMsTaskException.forEach((item) => {
      if (item.EXP_STATE == 1) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "请选择异常未处理的数据！"
        });
        return;
      }
    })
    //  弹窗
    this.modalService.create({
      nzTitle: '请处理异常作业',
      nzContent: FormModalComponent,
      nzWidth: 500,
      nzWrapClassName: 'vertical-center-modal',
      "nzComponentParams": {
        "modalFormData": {
          "columns": [
            {
              "column": "EXP_RESULT",
              "name": "EXP_RESULT_NAME",
              "component": "input",
              "customize": 24,
              "lable": "处理结果",
              "comment": "处理结果",
              "placeholder": "请输入处理结果",
              "model": null,
              "visible": true,
              "require": true
            },
            {
              "column": "EXP_DEAL_MAN",
              "name": "EXP_DEAL_MAN_NAME",
              "component": "poplist",
              "customize": 24,
              "lable": "处理人",
              "comment": "处理人",
              "placeholder": "请选择处理人",
              "model": null,
              "visible": true,
              "require": true,
              "dictionary": "JieLiv2/basic/dict/BASIC_EMP.json",
              "dictionaryPF": null,
              "columnGroup": [
                {
                  "column": "EXP_DEAL_MAN_NAME",
                  "name": "EXP_DEAL_MAN_NAME_NAME",
                  "component": "input",
                  "lable": "处理人",
                  "comment": "处理人",
                  "placeholder": "处理人",
                  "fromValue": "name",
                  "model": null,
                  "visible": false,
                  "require": false
                }
              ]
            },
            {
              "column": "EXP_DEAL_TIME",
              "name": "EXP_DEAL_TIME_NAME",
              "component": "datetime",
              "customize": 24,
              "lable": "处理时间",
              "comment": "处理时间",
              "placeholder": "请选择时间",
              "model": null,
              "visible": true,
              "require": true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        //  调用异常处理接口
        const data = response.modalFormData.columns;
        const user = this.userInfoService.getUserInfo();
        let queryData = {
          "expDealMan": _sh.get(data, [1, 'model'], ''),
          "expDealManName": _sh.get(data, [1, 'columnGroup', 0, 'model'], ''),
          "expDealTime": _sh.get(data, [2, 'model'], ''),
          "expResult": _sh.get(data, [0, 'model'], ''),
          "updName": user.USERNAME
        };

        let ids = this.formatSelectEntity(newMsTaskException, 'EXP_ID');
        let bodyData = {
          "ids": ids
        }

        this.requestService.doActionByHdbsthor('AppApi/MsTaskExceptionController', 'batchHandleTaskException', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.ngOnInit();

            this.confirmModal.show('success', {'title': '执行成功'});
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });

      }
    });
  }

  //获取ids
  formatSelectEntity(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }

  //点击调度单数据更新运单信息和异常信息
  getTranAndExcData(oneModalData) {
    observableForkJoin(
      this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_plant_detail.json', {'DISP_ID': oneModalData.DISP_ID}),
      this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_task_exception_search.json', {'DISP_ID': oneModalData.DISP_ID}),
    ).subscribe(res => {
      this.msTranOrders = _sh.get(res, [0, 'data']);
      this.msTaskException = _sh.get(res, [1, 'data']);
    })
  }

  //保存/新增结算单
  accountSave() {
    if (!this.checkData()) {
      return;
    }

    //  调用保存接口
    const accountMainData = _sh.get(this.msTranAccountForm, ['columns'], []);
    const accountEntity = this.formatMainToEntity(accountMainData);

    const accountDetailArray = [];

    this.msTranDispatch.forEach(item => {
      accountDetailArray.push({
        "DISP_ID": item.DISP_ID,
        "DISP_CODE": item.DISP_CODE,
        "DET_MONEY": item.DET_MONEY ? item.DET_MONEY : 0,
        "DISP_TOTALSFEE": item.DISP_TOTALSFEE ? item.DISP_TOTALSFEE : 0,
        "DISP_ACCOUNT": item.DISP_ACCOUNT ? item.DISP_ACCOUNT : 0,
        "DISP_BORROW": item.DISP_BORROW ? item.DISP_BORROW : 0,
        "DISP_LOCK_MONEY": item.DISP_LOCK_MONEY ? item.DISP_LOCK_MONEY : 0,
        "DET_TEXT": item.DET_TEXT ? item.DET_TEXT : null
      })
    })
    //  将结算明细的数据重新查询，能封装成对象。
    const accountDetail = {
      detail: {
        detailArray: accountDetailArray
      }
    }
    const otherList = {
      detailOther: {
        detailArray: this.otherMoneyDataList
      }
    }
    const accountParam = "entity:" + "'ms_tran_account'" + ",entityColumn:" + JSON.stringify(accountEntity) + ",detail:" + JSON.stringify(accountDetail.detail) + ",detailOther:" + JSON.stringify(otherList.detailOther);
    this.requestService.doActionByJavaPlatform("jieLiController", "accountAddData", accountParam).subscribe(res => {
      if (res.status == 500) {
        this.confirmModal.show('error', {
          'title': '执行错误',
        });
      }
      if (res.result == "success") {
        this.gotoBack();
        this.confirmModal.show('success', {
          'title': '保存成功',
        });
      }

    });
  }

  //整理保存/新增数据
  formatMainToEntity(pForm) {
    let entity: any = {};
    _sh.each(pForm, (item) => {
      const column = _sh.get(item, 'column');
      entity[column] = _sh.get(item, 'model');
      let columnGroup = _sh.get(item, 'columnGroup', null);
      _sh.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
        entity[itemColumn] = _sh.get(oneGroupColumn, 'model');
      });
    });
    return entity;
  }

  //必填项校验
  checkInput() {
    let result = true;

    this.msTranAccountForm['columns'].forEach((item) => {
      if (result && item['require'] == true && !this.utils.Base_HasValue(item['model'])) {
        result = false;
        this.confirmModal.show('error', {'title': '必填项', 'content': item['comment'] + '为必填项'});
      }
    })
    return result;
  }

  //反写值到输入框
  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        oneColumn['model'] = oneData[oneColumn['column']];

        if (_.contains(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
          oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
        }
        if (oneColumn['component'] == 'file') {
          let url = oneData[oneColumn['column']];
          let fileName = this.utils.Base_getFileNameOnly(url);
          oneColumn['fileList'] = [];
          oneColumn['fileList'].push(
            {
              uid: this.utils.Base_getUuid(),
              name: fileName,
              status: 'done',
              url: url
            }
          )

        }
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
          if (_.contains(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
            oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
          }
        })
      })
    })
  }

  ngOnInit(): void {

    //按钮和输入框的权限
    this.updateVisit();
    if (this.utils.Base_HasValue(this.parentData.id) && this.parentData.modeType != 'add' ) {
      observableForkJoin(
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_account_settlement_list.json', {'ID': this.parentData.id}),//查询结算单信息
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_account_detail_detail.json', {'COUNT_ID': this.parentData.id}),//查询结算单从表信息
      ).subscribe(res => {


        //填充结算主表数据
        res[0]['data'][0]['DR_ID_DICT'] = res[0]['data'][0]['DR_NAME'];
        res[0]['data'][0]['CARRIER_ID_DICT'] = res[0]['data'][0]['CARRIER_NAME'];
        this.msTranAccount = _sh.get(res, [0, 'data']);
        let msTranAccountDetail = _sh.get(res, [1, 'data']);

        //todo 支出结算管理
        this.backfillData(this.msTranAccountForm, res[0]['data']);


        //todo 结算明细
        let countId = msTranAccountDetail[msTranAccountDetail.length - 1]['COUNT_ID'];
        let dispId = msTranAccountDetail[msTranAccountDetail.length - 1]['DISP_ID'];
        observableForkJoin(
          this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_account_detail_search.json', {'COUNT_ID': countId}),//查询结算明细调度单信息
          this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_plant_detail.json', {'DISP_ID': dispId}),//运单信息
          this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_task_exception_search.json', {'DISP_ID': dispId}),//异常信息
          this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_account_other_and_tran_account.json', {'COUNT_ID': this.parentData.id}),//查询其他费用信息
        ).subscribe(res => {
          this.msTranDispatch = _sh.get(res, [0, 'data']);
          this.msTranDispatch.forEach(item => {
            let finishNum = _sh.get(item, ['EXP_UN_COUNT_DICT'], 0);
            let sumNum = _sh.get(item, ['EXP_ALL_COUNT_DICT'], 0);
            item['redText'] = finishNum < sumNum;
            item.checked = false;
            this.accountAll = this.accountAll + item['DET_MONEY'];
            this.borrowAll = this.borrowAll + item['DISP_BORROW'];
            this.lockAll = this.lockAll + item['DISP_LOCK_MONEY'];
            this.sumAll = this.sumAll + item['DISP_TOTALSFEE'];
          })



          this.msTranOrders = _sh.get(res, [1, 'data']);//运单信息
          this.msTaskException = _sh.get(res, [2, 'data']);//异常信息
          //todo 其他费用明细
          this.otherMoneyDataList = _sh.get(res, [3, 'data']);//其他费用明细
          _sh.each(this.otherMoneyDataList, item => {
            item['ID'] = item['SET_ID'];
            item['NAME'] = item['SET_NAME'];
            item['KINGDEE_ACCOUNT_CODE'] = item['SET_CODE'];
            this.otherAll = item['MONEY'] + this.otherAll;
            item.checked = false
          })
        })
      })
    }
  }

  //审核
  audit() {
    if (!this.checkData()) {
      return;
    }

    this.confirmModal.show('confirm', {
      title: '确认审核该结算单吗？',
      suc: () => {
        let fileNum = 0;
        let countText = '';
        let serialNumber = '';
        this.msTranAccountForm.columns.forEach(item => {
          if (item['column'] == "FILE_NUM") {
            fileNum = item['model'];
          }
          if (item['column'] == "COUNT_TEXT") {
            countText = item['model'];
          }
          if (item['column'] == "SERIAL_NUMBER") {
            serialNumber = item['model'];
          }
        })
        //  发送请求

        const accountDetailArray = [];

        this.msTranDispatch.forEach(item => {
          accountDetailArray.push({
            "DISP_ID": item.DISP_ID,
            "DISP_CODE": item.DISP_CODE,
            "DET_MONEY": item.DET_MONEY ? item.DET_MONEY : 0,
            "DISP_TOTALSFEE": item.DISP_TOTALSFEE ? item.DISP_TOTALSFEE : 0,
            "DISP_ACCOUNT": item.DISP_ACCOUNT ? item.DISP_ACCOUNT : 0,
            "DISP_BORROW": item.DISP_BORROW ? item.DISP_BORROW : 0,
            "DISP_LOCK_MONEY": item.DISP_LOCK_MONEY ? item.DISP_LOCK_MONEY : 0,
            "DET_TEXT": item.DET_TEXT ? item.DET_TEXT : null
          })
        })
        //  将结算明细的数据重新查询，能封装成对象。
        const accountDetail = {
          detail: {
            detailArray: accountDetailArray
          }
        }
        const otherList = {
          detailOther: {
            detailArray: this.otherMoneyDataList
          }
        }
        // const accountParam = "id:'" + this.parentData.id + "',userId:'SYS_USER_ID',userName: 'SYS_USER_NAME',fileNum:" + fileNum + ",countText:'" + countText + "',detail:" + JSON.stringify(accountDetail.detail)+",detailOther:"+JSON.stringify(otherList.detailOther);
        const accountParam = `id:'${this.parentData.id}',userId:'SYS_USER_ID',userName: 'SYS_USER_NAME',serialNumber:${serialNumber},fileNum:${fileNum},countText:'${countText}',detail:${JSON.stringify(accountDetail.detail)},detailOther:${JSON.stringify(otherList.detailOther)}`;

        this.requestService.doActionByJavaPlatform("jieLiController", "accountAudit", accountParam).subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
          if (res.result == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          }
        })
      }
    });
  }

  //撤销
  revocation() {
    this.confirmModal.show('confirm', {
      title: '确认撤销该结算单吗？',
      suc: () => {
        let fileNum = 0;
        this.msTranAccountForm.columns.forEach(item => {
          if (item['column'] == "FILE_NUM") {
            fileNum = item['model'];
          }
        })
        //  发送请求
        const accountParam = "accountId:'" + this.parentData.id + "'";
        this.requestService.doActionByJavaPlatform("jieLiController", "accAdcCancel", accountParam).subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
          if (res.result == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          }
        })
      }
    });
  }

  //弃核
  auditCancel() {
    if (!this.checkData()) {
      return;
    }
    this.confirmModal.show('confirm', {
      title: '确认弃核该结算单吗？',
      suc: () => {
        let fileNum = 0;
        this.msTranAccountForm.columns.forEach(item => {
          if (item['column'] == "FILE_NUM") {
            fileNum = item['model'];
          }
        })
        //  发送请求
        const accountParam = "id:'" + this.parentData.id + "',userId:'SYS_USER_ID',userName: 'SYS_USER_NAME'";
        this.requestService.doActionByJavaPlatform("jieLiController", "accountAuditCancel", accountParam).subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
          if (res.result == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          }
        })
      }
    });
  }

  //确认结算
  confirm() {
    if (!this.checkData()) {
      return;
    }
    let fileNum = 0;
    let countText = '';
    this.msTranAccountForm.columns.forEach(item => {
      if (item['column'] == "FILE_NUM") {
        fileNum = item['model'];
      }
      if (item['column'] == "COUNT_TEXT") {
        countText = item['model'];
      }
    });
    this.modalService.create({
      nzTitle: '结算确认',
      nzContent: FormModalComponent,
      nzWidth: 500,
      "nzWrapClassName": "vertical-center-modal",
      "nzComponentParams": {
        "modalFormData": {
          "columns": [
            {
              "column": "PAYMENT_METHOD",
              "name": "PAYMENT_METHOD_NAME",
              "component": "select",
              "type": "number",
              "customize": 24,
              "lable": "支付方式",
              "comment": "支付方式",
              "placeholder": "请选择支付方式",
              "model": null,
              "visible": true,
              "require": true,
              "dictionary": "JieLiv2/basic/dict/PAYMENT_METHOD.json"
            },
            {
              "column": "COUNT_TEXT",
              "name": "COUNT_TEXT_NAME",
              "component": "textarea",
              "customize": 24,
              "lable": "备注",
              "comment": "备注",
              "placeholder": "请输入备注",
              "model": countText,
              "visible": true,
              "require": false
            }
          ]
        }
      },
      nzOnOk: (response) => {
        //  调用确认结算处理接口
        const data = response.modalFormData.columns;
        let PAYMENT_METHOD = _sh.get(data, [0, 'model'], '');
        let COUNT_TEXT = _sh.get(data, [1, 'model'], '');

        //  发送请求
        const accountParam = "accountId:'" + this.parentData.id + "',userId:'SYS_USER_ID',userName: 'SYS_USER_NAME',paymentMethod:'" + PAYMENT_METHOD + "',countText:'" + COUNT_TEXT + "',fileNum:'" + fileNum + "'";
        this.requestService.doActionByJavaPlatform("jieLiController", "accountConfirm", accountParam).subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
          if (res.result == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          }
        })
      }
    });
  }

  //取消结算
  confirmCancel() {
    this.confirmModal.show('confirm', {
      title: '确认取消结算该结算单吗？',
      suc: () => {
        let fileNum = 0;
        this.msTranAccountForm.columns.forEach(item => {
          if (item['column'] == "FILE_NUM") {
            fileNum = item['model'];
          }
        })

        //  发送请求
        const accountParam = "accountId:'" + this.parentData.id + "',userId:'SYS_USER_ID',userName: 'SYS_USER_NAME'";
        this.requestService.doActionByJavaPlatform("jieLiController", "accountRevoke", accountParam).subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
          if (res.result == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          }
        })
      }
    });
  }

  reCheck() {
    let countText = '';
    this.msTranAccountForm.columns.forEach(item => {
      if (item['column'] == "COUNT_TEXT") {
        countText = item['model'];
      }
    });
    let that = this;
    this.confirmModal.show('confirm', {
      title: '确认复核该结算单吗？',
      suc: () => {
        let autoJsonList = [
          {
            "TABLE": "ms_tran_account",
            "ACTION": "Update",
            "WHEREGROUP": [
              {
                "GROUP": "GROUP_01",
                "CONDITION_OPERATOR": "AND"
              }
            ],
            "WHERE": [
              {
                "COLUMN": "ID",
                "VALUE": "?",
                "OPERATOR": "=",
                "VALUE_TYPE": "string",
                "CONDITION_OPERATOR": "AND",
                "GROUP": "GROUP_01"
              }
            ],
            "COLUMNS": [
              {
                "COLUMN": "COUNT_TEXT"
              },
              {
                "COLUMN": "UPD_USERID"
              },
              {
                "COLUMN": "UPD_NAME"
              },
              {
                "COLUMN": "UPD_TIME"
              }
            ],
            "RECORDS_DATAS": "SELECT",
            "RECORDS": [
              {
                "ID": "SPECIAL_DATA.ID",
                "COUNT_TEXT": countText,
                "UPD_USERID": "SYS.USER_ID",
                "UPD_NAME": "SYS.USER_NAME",
                "UPD_TIME": "SYS.DATETIME"
              }
            ]
          }
        ];
        _sh.each(autoJsonList, oneAction => {
          let records = [];
          let recordsDatasConfig = _sh.get(oneAction, ['RECORDS_DATAS'], null);
          if (that.utils.Base_HasValue(recordsDatasConfig)) {
            let rows = that.dataCenterService.getSpecialValue(recordsDatasConfig, this.parentData, null, null, null);
            _sh.each(rows, oneRow => {
              let oneRecord = _sh.cloneDeep(oneAction['RECORDS'][0]);
              let oneRecordKeys = _sh.keys(oneRecord);
              _sh.each(oneRecordKeys, oneKey => {
                let oneRecordoneKey = oneRecord[oneKey];
                oneRecord[oneKey] = that.dataCenterService.getSpecialValue(oneRecordoneKey, this.parentData, null, null, oneRow);
              })
              records.push(oneRecord);
            })
            oneAction['RECORDS'] = records;
          }
        });
        let autoTemplate = {
          "LIST": autoJsonList
        }
        _sh.each(autoTemplate['LIST'], oneList => {
          _sh.each(oneList['NULL_DELETE_COLUMNS'], oneColumn => {
            let hasValue = that.checkHasValue(oneList['RECORDS'], oneColumn);
            if (!hasValue) {
              that.nullDeleteColumns(oneList, oneColumn);
            }
          });
        });
        that.autoService.ExecJson(autoTemplate).subscribe(res => {
          if (res.state == "success") {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '保存成功',
            });
          } else {
            this.confirmModal.show('error', {
              'title': '执行错误',
            });
          }
        });
      }
    });
  }

  checkHasValue(pRecords, pColumn) {
    let count = 0;
    _sh.each(pRecords, oneRecord => {
      if (this.utils.Base_HasValue(_sh.get(oneRecord, [pColumn], null))) {
        count++;
      }
    });
    return count > 0;
  }

  nullDeleteColumns(pList, pColumn) {
    _sh.each(pList['COLUMNS'], oneColumn => {
      if (oneColumn['COLUMN'] == pColumn) {
        pList['COLUMNS'] = _sh.without(pList['COLUMNS'], oneColumn);
      }
    })
  }

  //输入框方法-没啥用
  startEdit(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  //检查数据
  checkData() {
    //承运商和司机至少选一个
    let carrierFlag = true;
    let drFlag = true;
    this.msTranAccountForm['columns'].forEach((item) => {
      if (item.column == "CARRIER_ID" && !this.utils.Base_HasValue(item.model)) {
        carrierFlag = false;
      }
      if (item.column == "DR_ID" && !this.utils.Base_HasValue(item.model)) {
        drFlag = false;
      }
    })

    if (!carrierFlag && !drFlag) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择承运商或者司机！"
      });
      return false;
    }

    //必填字段
    if (!this.checkInput()) {
      return false;
    }

    //检查结算明细数据
    if (this.msTranDispatch.length == 0) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请添加至少一条结算明细数据！"
      });
      return false;
    }

    if (this.otherMoneyDataList.length > 0) {
      let temp = _sh.filter(this.otherMoneyDataList, function (e) {
        return e.MONEY == null;
      })
      if (temp.length > 0) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "其他费用明细请填写金额"
        });
        return false;
      }
    }

    //调度单结算金额>0
    let amountFlag = false;
    this.msTranDispatch.forEach((item) => {
      if (item['DET_MONEY'] <= 0) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "调度单号为" + item['DISP_CODE'] + "的结算金额必须大于0！"
        });
        amountFlag = true;
        return false;
      }
    })
    return !amountFlag;

  }

  formVisable(_columnList, _visable, _require) {
    _sh.each(_columnList, oneVisColumnName => {
      _sh.each(this.msTranAccountForm.columns, oneColumn => {
        if (oneColumn.lable == oneVisColumnName) {
          oneColumn.visible = _visable
          oneColumn.require = _require;
        }
      })
    })
  }

  //按钮和输入框的权限
  updateVisit() {
    // 根据按钮的类型区分
    this.parentData.modeType  // 按钮是什么，
    if (this.parentData.modeType == 'add' || this.parentData.modeType == 'modify') {//添加修改
      this.btnAndInputState.accountSaveVisit = true;
      this.btnAndInputState.addAccountBtnVisit = true;
      this.btnAndInputState.deleteAccountBtnVisit = true;
      this.formVisable(['结算序号'], false, false);
    } else if (this.parentData.modeType == 'revocation') {//撤销
      this.btnAndInputState.revocationVisit = true;
    } else if (this.parentData.modeType == 'audit') {//审核
      this.btnAndInputState.auditVisit = true;
      this.btnAndInputState.inputVisit = true;
    } else if (this.parentData.modeType == 'auditCancel') {//取消审核
      this.btnAndInputState.auditCancelVisit = true;
    } else if (this.parentData.modeType == 'confirm') {//确认结算
      this.btnAndInputState.confirmVisit = true;
      this.btnAndInputState.exceptionBtnVisit = true;
    } else if (this.parentData.modeType == 'confirmCancel') {//取消结算
      this.btnAndInputState.confirmCancelVisit = true;
    } else if (this.parentData.modeType == 'reCheck') {//复核
      this.btnAndInputState.reCheckVisit = true;
    }
  }

  //显示弹窗信息
  addOtherMoney() {
    this.searchOtherMoney(true);
  }

  searchOtherMoney(reset: boolean, condition?: {}) {
    if (reset) {
      this.pageIndexModal = 1;
    }
    const search01$ = observableForkJoin(
      this.requestService.getPaginationData('JieLiv2/basic/dict/MS_ACCOUNT_SET.json', condition, this.otherPageIndexModal, this.otherPageSizeModal),
    );
    search01$.subscribe(
      values => {
        this.otherModalData = _sh.get(values, [0, 'data'], []);
        this.otherTotalModal = _sh.get(values, [0, 'page', 'rowCount'], 0);
        this.otherPageIndexModal = _sh.get(values, [0, 'page', 'pageNum'], 0);
        //显示弹窗
        this.otherMoneyShow = true;
      }
    );
  }

  //过滤按钮
  searchFilterOtherMoney(reset: boolean = true) {
    this.modalConditionList = this.transformCondition(this.otherMoneyModalForm['columns']);
    console.log('this.modalConditionList');
    console.log(this.modalConditionList);
    let condition = {};
    _.each(this.modalConditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    console.log('condition');
    console.log(condition);
    this.searchOtherMoney(reset, condition);
  }

  resetFilterOtherMoney() {
    _.each(this.otherMoneyModalForm['columns'], (item) => {
      item['model'] = null;
    });
    this.searchFilterOtherMoney();
  }

  deleteOtherMoney() {
    this.otherMoneyDataList = this.otherMoneyDataList.filter((e) => {
      return e.checked == false;
    });
    this.otherAll = 0
    _sh.each(this.otherMoneyDataList, item => {
      let temp = 0;
      if (item['MONEY'] != null) {
        temp = item['MONEY']
      }
      this.otherAll = temp + this.otherAll
    })
  }

  checkAllOther(value: boolean): void {
    this.otherMoneyDataList.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusOther();
  }

  refreshStatusOther(): void {
    const allChecked = this.otherMoneyDataList.length > 0 && this.otherMoneyDataList.every(value => value.checked === true);
    const allUnChecked = this.otherMoneyDataList.every(value => !value.checked);
    this.allCheckedOther = allChecked;
    this.indeterminateOther = (!allChecked) && (!allUnChecked);
  }

  selectOneOtherMoneyModalData(oneModalData) {
    let check = true;
    if (this.otherMoneyDataList.length > 0) {
      _sh.each(this.otherMoneyDataList, item => {
        if (item['ID'] == oneModalData['ID']) {
          this.confirmModal.show("warning", {title: "已添加该类型金额，请勿重复添加"})
          check = false;
        }
      })
    }
    if (check) {
      oneModalData.MONEY = null;
      oneModalData.checked = false;
      this.otherMoneyDataList.push(oneModalData)
      this.otherMoneyShow = false;
    }
  }

  inputChange($event) {
    this.otherAll = 0
    _sh.each(this.otherMoneyDataList, item => {
      let temp = 0;
      if (item['MONEY'] != null) {
        temp = item['MONEY']
      }
      this.otherAll = temp + this.otherAll
    })
  }

  inputChangeDet($event: any) {
    this.accountAll = 0;
    this.borrowAll = 0;
    this.lockAll = 0;
    this.sumAll = 0;
    _sh.each(this.msTranDispatch, item => {
      let tempAccount = 0;
      let tempBorrow = 0;
      let tempLock = 0;
      let tempSum = 0;
      if (item['DET_MONEY'] != null) {
        tempAccount = item['DET_MONEY']
        this.accountAll = this.accountAll + tempAccount;
      }
      if (item['DISP_BORROW'] != null) {
        tempBorrow = item['DISP_BORROW']
        this.borrowAll = this.borrowAll + tempBorrow;
      }
      if (item['DISP_LOCK_MONEY'] != null) {
        tempLock = item['DISP_LOCK_MONEY']
        this.lockAll = this.lockAll + tempLock;
      }
      if (item['DISP_TOTALSFEE'] != null) {
        tempSum = item['DISP_TOTALSFEE']
        this.sumAll = this.sumAll + tempSum;
      }
    })
  }
}
