import {Component, enableProdMode, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../service/request-data.service';
import {UtilsService} from '../../service/utils.service';
import {DataCenterService} from '../../service/data-center.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {forkJoin as observableForkJoin, Observable, Subject} from "rxjs";
import {FormModalComponent} from "../../basicComponent/form-modal/form-modal.component";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ListActionService} from "../../service/list-action.service";
import {AppConfigService} from "../../service/app-config.service";
import {TodoCenterComponent} from "../todo-center/todo-center.component";
import PubSub from 'pubsub-js';
import {switchMap} from "rxjs/operators";

enableProdMode();

@Component({
    selector: 'app-read-only-form',
    templateUrl: './read-only-form.component.html',
    styleUrls: ['./read-only-form.component.css']
})
export class ReadOnlyFormComponent implements OnInit {

    public forms: any = [];

    private showPicutre: boolean = false;

    private OverPlay: boolean = false;

    private current: number = 0;

    //父级组件的数据对象
    @Input() parentData: any;

    public helpUrl: string;

    public switchValue: boolean = true;

    public switchText: string = '显示所有信息';

    showType: String = "collapse"; // 默认采用折页
    selectedIndex = 0;
    tabList = {
        tabs: []
    };
    actionArray = [];
    formClass = "";
    readOnlyVisibleBtn = {
        "back": true,
        "print": false
    }

    wfUserAwaitId = null;

    constructor(private requestDataService: RequestDataService,
                private listAction: ListActionService,
                private utils: UtilsService,
                private appConfigService: AppConfigService,
                private confirmModal: ConfirmModalService,
                private todoCenterComponent: TodoCenterComponent,
                private dataCenterService: DataCenterService) {
    }

    checkSwtich(value) {
        this.switchText = value ? '显示所有信息' : '隐藏没有数据的信息';
        _.each(this.forms, (form) => {
            if (form['type'] === 'normal' && form['showDetail']) {
                _.each(form['columns'], (column) => {
                    if (value) {
                        // 如果是数组传参。则定义需要detail并且要有值
                        // 如果是boolean传参。则定义需要有值并且定义为展示
                        if (_.isArray(column['visible'])) {
                            column['show'] = _.contains(column['visible'], 'detail') && this.utils.Base_HasValue(column['model']);
                        } else if (this.utils.Base_HasValue(column['visible'])) {
                            column['show'] = column['visible'] && (this.utils.Base_HasValue(column['model'] || column['component'] === 'hr'));
                        }
                    } else {
                        column['show'] = (_.isArray(column['visible']) && _.contains(column['visible'], 'detail')) || (_.isBoolean(column['visible']) && column['visible']);
                    }
                });
            }
        });
    }

    /**
     * [该方法主要是存在分组情况的时候将columns里面没有在groupModel中穷尽到的column加入group分组中]
     */
    transformGroupColumn(oneForm) {
        let noGroupColumns = [];
        _.each(oneForm.columns, (item) => {
            let inGroup = false;
            _.each(oneForm.groupModel, (group) => {
                if (_.contains(group['column'], item['column'])) {
                    inGroup = true;
                }
            });
            if (!inGroup) {
                noGroupColumns.push(item['column']);
            }
        });
        if (noGroupColumns.length) {
            oneForm.groupModel.unshift({
                title: '未分组信息',
                column: noGroupColumns
            });
        }
    }

    // 查询后的数据进行封装
    transformResult(data) {
        this.forms = data.detailColumns || [];
        this.showPicutre = false;
        _.each(this.forms, (OneForm) => {
            OneForm['showModel'] = 'list'; //默认显示列表页面
            // 判断form表单是否在修改页面显示，如果没有配置showPage属性，默认显示
            let showPage = this.utils.Base_getJsonValue(OneForm, 'showPage', false);
            if (showPage) {
                let showPageArray = OneForm['showPage'];
                if (showPageArray.indexOf('detail') > -1) {
                    OneForm['showDetail'] = true;
                }
            } else {
                OneForm['showDetail'] = true;
            }
            OneForm['scrollWidth'] = data.scrollWidth || null;
            // 如果有group分组。则捡漏
            if (this.utils.Base_HasValue(OneForm['groupModel'])) {
                this.transformGroupColumn(OneForm);
            }
            // 冗余 visible 字段。用于切换开关
            _.each(OneForm['columns'], (OneColumn) => {
                if (OneForm['type'] === 'normal'
                    && (OneColumn['component'] === 'pictureLocal' || OneColumn['component'] === 'picture')) {
                    this.showPicutre = true;
                }
                if (OneForm['type'] === 'normal' && OneForm['showDetail']) {
                    // 如果是数组传参。则定义需要detail并且要有值
                    // 如果是boolean传参。则定义需要有值并且定义为展示
                    if (_.isArray(OneColumn['visible'])) {
                        OneColumn['show'] = _.contains(OneColumn['visible'], 'detail') && this.utils.Base_HasValue(OneColumn['model']);
                    } else if (this.utils.Base_HasValue(OneColumn['visible'])) {
                        OneColumn['show'] = OneColumn['visible'] && (this.utils.Base_HasValue(OneColumn['model']) || OneColumn['component'] === 'hr');
                    }
                }
                if (_.contains(['dateRange', 'dateTimeRange'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model'])) {
                    OneColumn['model'] = OneColumn['model'].join('~');
                }
                if (_.contains(['multiSelect'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model'])) {
                    if (OneColumn['model'].indexOf(";") !== -1) {
                        OneColumn['model'] = OneColumn['model'].split(';');
                    } else {
                        OneColumn['model'] = [OneColumn['model']];
                    }
                }
                if (_.contains(['multipop'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model']) && OneColumn['model'].indexOf(",") !== -1) {
                    OneColumn['model'] = OneColumn['model'].split(',');
                }
            });
        });
    }

    getOneRowValue(pOneRow, fromValue, fromValue_02) {
        let result = null;
        if (fromValue_02) {
            let d = [_sh.get(pOneRow, fromValue, null), _sh.get(pOneRow, fromValue_02, null)];
            result = d;
        } else {
            result = _sh.get(pOneRow, fromValue, null)
        }
        return result;
    }

    getTemplate(pData, oneColumn) {
        let that = this;
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        const msgTemplate = _sh.get(fromValue, ['msgTemplate'], null); // 格式化模板
        let msgValue = _sh.get(fromValue, ['msgValue'], null);
        let isNull = _sh.get(fromValue, ['isNull'], null);

        let datas = [];
        if (this.utils.Base_HasValue(msgTemplate)) { // 如果有格式化模板
            if (_sh.isArray(msgValue)) {
                let msgValueObj = {}; // 最后的格式化参数的结果
                // 如果是第二代，数组模式
                _sh.each(msgValue, oneKey => {
                    let readOnlyData = {
                        'sqlFileListData': _sh.cloneDeep(this.tabList['sqlFileListData']),
                        'aggregateListData': _sh.cloneDeep(this.tabList['aggregateListData'])
                    }
                    msgValueObj[oneKey['column']] = that.dataCenterService.getSpecialValue(oneKey['fromValue'], this.parentData, null, readOnlyData);
                    let oneKeyIsNull = _sh.get(oneKey, ['isNull'], null);
                    if (that.utils.Base_HasValue(oneKeyIsNull)) {
                        if (!that.utils.Base_HasValue(msgValueObj[oneKey['column']])) {
                            msgValueObj[oneKey['column']] = oneKeyIsNull;
                        }
                    }
                    let befCalc = _sh.get(oneKey, ['befCalc'], null);
                    if (that.utils.Base_HasValue(befCalc)) {
                        let tempTemplate = {
                            fromValue: befCalc
                        }
                        let tempValue = that.getTemplate(pData, tempTemplate);
                        if (that.utils.Base_HasValue(tempValue)) {
                            msgValueObj[oneKey['column']] = eval(tempValue[0]);
                        }
                    }

                    let befFormat = _sh.get(oneKey, ['befFormat'], null); // 格式化, 目前支持转大写，转千分位+小数点
                    if (that.utils.Base_HasValue(befFormat)) {
                        if (befFormat == "TO_CHINESE_BLOCK_LETTER") {
                            msgValueObj[oneKey['column']] = that.utils.changeToChineseMoney(msgValueObj[oneKey['column']]);
                        } else if (befFormat == "THOUSANDS") {
                            msgValueObj[oneKey['column']] = that.utils.thousands(msgValueObj[oneKey['column']], oneKey['dplaces']);
                        }
                    }
                })
                let compiled = _.template(msgTemplate);
                let result = compiled(msgValueObj);
                datas.push(result);
            } else {
            _sh.each(pData, pOneRow => {
                let msgValue = _sh.get(fromValue, ['msgValue'], null);
                _sh.each(_sh.keys(msgValue), oneKey => {
                    msgValue[oneKey] = this.getOneRowValue(pOneRow, oneKey, null);
                })
                let compiled = _.template(msgTemplate);
                let result = compiled(msgValue);
                datas.push(result);
            });
            }
        }
        return datas;
    }

    getOneValue(pData, oneColumn) {
        let that = this;
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        const fromValue_02 = _sh.get(oneColumn, ['fromValue_02'], null);
        const msgTemplate = _sh.get(fromValue, ['msgTemplate'], null); // 格式化模板
        let datas = [];

        if (this.utils.Base_HasValue(msgTemplate)) { // 如果有格式化模板
            let result = this.getTemplate(pData, oneColumn);
            _sh.each(result, oneRow => {
                datas.push(oneRow);
            });
        } else {
            let isNewLinkType = false;
            if (_.isArray(fromValue)) {
                isNewLinkType = false
            } else if (_.isString(fromValue)) {
                isNewLinkType = true
            }
            if (isNewLinkType) { // 新的链接类型。
                let readOnlyData = {
                    'sqlFileListData': _sh.cloneDeep(this.tabList['sqlFileListData']),
                    'aggregateListData': _sh.cloneDeep(this.tabList['aggregateListData'])
                }
                let tempValue = that.dataCenterService.getSpecialValue(fromValue, this.parentData, null, readOnlyData);
                datas.push(tempValue);
        } else {
            _sh.each(pData, pOneRow => {
                datas.push(this.getOneRowValue(pOneRow, fromValue, fromValue_02));
            });
        }
        }
        return datas;
    }

    mergeData(oneGroupData, oneColumn, oneColumnValue) {
        let rowIndex = 0
        _sh.each(oneColumnValue, oneRow => {
            _sh.set(oneGroupData, [rowIndex, oneColumn['column']], oneRow);
            rowIndex++;
        })

        return oneGroupData;
    }

    mergeData_appendix(oneGroupData, oneColumn, oneColumnValue) {
        let gd = {}
        _sh.each(oneColumnValue, oneRow => {
            gd['lable'] = oneColumn['lable']
            gd['url'] = oneRow
            gd['fileName'] = this.utils.Base_getFileNameOnly(oneRow)
            gd['nzSpan'] = oneColumn['nzSpan']
            gd['nz-badge'] = oneColumn['nz-badge']
        })
        oneGroupData.push(gd)
        return oneGroupData;
    }

    showError(oneColumn) {
        if (oneColumn['component'] == 'button') {
          return;
        }
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        if (!this.utils.Base_HasValue(fromValue)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromValue ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }

        const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
        if (!this.utils.Base_HasValue(fromDataCenter)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromDataCenter ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
    }

    getOneGroupData(pData, pAggregateListData, pOneGroup) {
        let oneGroupData: any = [];
        let columns = pOneGroup['columns'];
        const groupShowType = _sh.get(pOneGroup, ['groupShowType'], null);
        const mainData = _sh.get(pOneGroup, ['mainData'], null);
        if (!this.utils.Base_HasValue(mainData)) {
            const errMsg = pOneGroup['groupTitle'] + '; 没有设置：mainData ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
        _sh.each(columns, oneColumn => {

            this.showError(oneColumn);
            oneColumn['visible'] = _sh.get(oneColumn, ['visible'], true);
            let mainDataLength = 0;
            if (mainData[0] == 'sqlFileList') {
                mainDataLength = pData[mainData[1]].length;
            } else if (mainData[0] == 'aggregateList') {
                mainDataLength = 1;
            }

            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            if (fromDataCenter[0] == 'sqlFileList') {


                let oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);

                // 如果超过主数据的行数，删除
                if (oneColumnValue.length > mainDataLength) {
                    oneColumnValue = _sh.drop(oneColumnValue, oneColumnValue.length - mainDataLength);
                } else if (oneColumnValue.length < mainDataLength) {
                    // 如果小于主数据的行数，增加。
                    let tempArr = oneColumnValue;
                    if (mainDataLength == 0) {
                        tempArr = [null]
                    } else if (mainDataLength > 0) {
                        for (var i = 0; i < mainDataLength - oneColumnValue.length; i++) {
                            tempArr.push(oneColumnValue[0])
                        }
                    }
                    oneColumnValue = tempArr;
                }

                if (groupShowType == 'appendixShow') {
                    // 如果是附件表格类型，要对groupData做取文件名的处理
                    oneGroupData = this.mergeData_appendix(oneGroupData, oneColumn, oneColumnValue);
                } else {
                    oneGroupData = this.mergeData(oneGroupData, oneColumn, oneColumnValue);
                }

            } else if (fromDataCenter[0] == 'aggregateList') {
                const oneColumnValue = _sh.get(pAggregateListData, oneColumn['column']);
                let tempArr = [];
                if (mainDataLength == 0) {
                    tempArr = [null]
                } else if (mainDataLength > 0) {
                    for (var i = 0; i < mainDataLength; i++) {
                        tempArr.push(oneColumnValue)
                    }
                }
                oneGroupData = this.mergeData(oneGroupData, oneColumn, tempArr);
            }
        })
        return oneGroupData;
    }

    execAggregate(pAggregateList, pData) {
        let result = {};
        _sh.each(pAggregateList, oneColumn => {
            this.showError(oneColumn);
            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            const aggregateFunction = _sh.get(oneColumn, ['aggregateFunction'], null);

            if (!this.utils.Base_HasValue(aggregateFunction)) {
                const errMsg = 'aggregateList 之中的，' + oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：aggregateFunction ， 请配置';
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': errMsg
                });
            }

            if (fromDataCenter[0] == 'sqlFileList') {
                const oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);
                if (aggregateFunction == 'count') {
                    result[oneColumn['column']] = oneColumnValue.length;
                } else if (aggregateFunction == 'sum') {
                    result[oneColumn['column']] = _sh.round(_sh.sum(oneColumnValue), 2);
                } else if (aggregateFunction == 'min') {
                    result[oneColumn['column']] = _sh.round(_sh.min(oneColumnValue), 2);
                } else if (aggregateFunction == 'max') {
                    result[oneColumn['column']] = _sh.round(_sh.max(oneColumnValue), 2);
                } else if (aggregateFunction == 'avg') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                } else if (aggregateFunction == 'mean') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                }
            } else {
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': "聚合函数的值只能在 sqlFileList 里面取值"
                });
            }
        })
        return result;
    }

    async doAction(pOneBtn) {
        let buttonConfig = {};
        const actionOperate = _sh.get(pOneBtn, ['ACTION_OPERATE'], null);

        if (_sh.endsWith(actionOperate, '.json')) {
            buttonConfig = await this.requestDataService.getDataByJson(actionOperate).toPromise();
            const status = _sh.get(buttonConfig, ['status'], 200);
            if (status !== 200) {
                return;
            }
        }

        const data = {
            'sqlFileListData': _sh.cloneDeep(this.tabList['sqlFileListData']),
            'aggregateListData': _sh.cloneDeep(this.tabList['aggregateListData'])
        };

        this.listAction.doAction(pOneBtn, this.parentData, "readOnlyForm", data)
            .pipe(
                switchMap((lv01: Observable<any>) => lv01),
                switchMap((lv02: Observable<any>) => lv02),
                switchMap((lv03: Observable<any>) => lv03)
            )
            .subscribe({
                next: lv04 => {
                    const status = _sh.get(lv04, ['status'], 200);
                    if (status === 500) {
                        let errorMsg = _sh.get(lv04, [0, 'error'], null);
                        errorMsg = _sh.get(lv04, ['error', 'message'], errorMsg);
                        if (!this.utils.Base_HasValue(errorMsg)) {
                            errorMsg = _sh.get(lv04, ['error'], null);
                        }
                        this.confirmModal.show('error', {
                            'title': '执行错误',
                            'content': errorMsg
                        });
                    } else if (status === 200) {
                        const afterCompletionWindow = _sh.get(buttonConfig, ['afterCompletionWindow'], 'hold');
                        const afterCompletionAction = _sh.get(buttonConfig, ['afterCompletionAction'], 'nothing');
                        if (afterCompletionWindow === 'hold') {
                            this.confirmModal.show('success', {'title': '执行成功'});
                            this.searchData();
                        } else if (afterCompletionWindow === 'close') {
                            this.dataCenterService.closeTab(this.parentData);
                        }
                        if (afterCompletionAction === 'refreshTodoCenter') {
                            this.todoCenterComponent.doRefreshTodoCenter();
                        }
                    }
                },
                error: err => console.error('Something wrong occurred:', err),
                complete: () => console.log('Action completed')
            });
    }


    buildData(pData, pType, pRoleIdList) {
        let data = _sh.uniqBy(pData, 'ACTION_ID');
        let arr = [];
        _sh.each(data, oneData => {
            let obj = {}
            let oneColumnData = _sh.get(oneData, 'ACTION_ID_DICT_ARRAY');
            let columns = _sh.keys(oneColumnData);
            _sh.each(columns, oneColumn => {
                obj[oneColumn] = oneColumnData[oneColumn];
            });
            let actionTypeList = _sh.split(obj['ACTION_TYPE'], ',');
            if (_.contains(actionTypeList, pType)) {
                obj['MENU_ID'] = oneData['MENU_ID'];
                obj['MENU_NAME'] = oneData['MENU_ID_DICT'];
                obj['ROLE_ID'] = _sh.join(pRoleIdList, ',');
                arr.push(obj);
            }
        });
        return arr;
    }



    searchData() {
        return new Promise(resolve => {
            const searchId = _sh.get(this.parentData, ['searchId']);
            this.formClass = _sh.get(this.parentData, ['formClass'], 'readOnlyForm');
            const menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
            const detailCondition = _sh.get(this.parentData, ['detailCondition']);
            this.readOnlyVisibleBtn = _sh.get(this.parentData, ['visibleBtn'], {
                "back": true,
                "print": true
            });
            const btnBack = _sh.get(this.readOnlyVisibleBtn, 'back', true);
            const printBack = _sh.get(this.readOnlyVisibleBtn, 'print', false);
            _sh.set(this.readOnlyVisibleBtn, 'back', btnBack);
            _sh.set(this.readOnlyVisibleBtn, 'print', printBack);

            const role$ = observableForkJoin(
                this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
            );
            role$.subscribe(
                roleValues => {
                    let roleIdList = [];
                    _sh.each(roleValues[0]['data'] || [], oneRole => {
                        roleIdList.push(oneRole['ROLE_ID']);
                    })
                    const search = observableForkJoin(
                        this.requestDataService.getDataByJson(searchId),    // 0
                        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu_action.json', {
                            'ROLE_ID': roleIdList,
                            'MENU_ID': menuId
                        })
                    );
                    search.subscribe(
                        values => {
                            if (this.formClass == 'readOnlyForm') {
                                this.actionArray = this.buildData(_sh.get(values, ['1', 'data'], []), 'readOnly', roleIdList);
                            } else if (this.formClass == 'workflowForm') {
                                this.actionArray = this.buildData(_sh.get(values, ['1', 'data'], []), 'workflow', roleIdList);
                            }
                            _.each(this.actionArray, oneBtn => {
                                oneBtn['visible'] = _sh.get(this.readOnlyVisibleBtn, [oneBtn['ACTION_VALUE']], true);
                            });


                            this.showType = _sh.get(values, [0, 'readOnlyColumns', 'showType'], 'collapse');
                            const defaultIndex = _sh.get(values, [0, 'readOnlyColumns', 'defaultIndex'], 0);
                            this.tabList['tabs'] = _sh.get(values, [0, 'readOnlyColumns', 'showList', defaultIndex, 'tabList'], []);


                            let aggregateList = _sh.get(values, [0, 'readOnlyColumns', 'showList', defaultIndex, 'aggregateList'], []);
                            let sqlFileList = _sh.get(values, [0, 'readOnlyColumns', 'showList', defaultIndex, 'sqlFileList'], []);
                            let descriptionsRequest = [];
                            _sh.each(sqlFileList, oneSqlFile => {
                                if (_sh.isString(oneSqlFile)) {
                                    let oneRequest = this.requestDataService.getDataByCondition(oneSqlFile, detailCondition)
                                    descriptionsRequest.push(oneRequest);
                                } else if (_sh.isObject(oneSqlFile)) {
                                    let oneRequest = this.requestDataService.getDataByCondition(oneSqlFile['sqlFile'], detailCondition)
                                    descriptionsRequest.push(oneRequest);
                                }
                            })
                            if (this.showType == 'collapse') {
                                this.requestDataService.getFormJson('modify', this.parentData).subscribe((data: any) => {
                                    this.transformResult(data);
                                    // 重新覆盖 datacenter 数据
                                    this.parentData.data = data;
                                    // 覆盖当前数据到data-center;
                                    _.each(this.dataCenterService._dataObject.list, (item) => {
                                        if (item['id'] === this.parentData.id) {
                                            item = _.extend(item, this.parentData);
                                        }
                                    });
                                    this.dataCenterService.refreshDataCenterInStorage();
                                    resolve("complete");
                                });
                            } else if (this.showType == 'descriptions') {
                                const searchAll = observableForkJoin(descriptionsRequest);
                                searchAll.subscribe(
                                    deValues => {
                                        let dataList = [];
                                        _.each(deValues, oneValue => {
                                            const oneData = _sh.get(oneValue, ['data'], []);
                                            dataList.push(oneData);
                                        });

                                        this.tabList['sqlFileListData'] = dataList;
                                        this.tabList['aggregateListData'] = this.execAggregate(aggregateList, dataList);
                                        let tabTempt = []
                                        _sh.each(this.tabList['tabs'], oneTab => {
                                            let hasValue = 0
                                            _sh.each(oneTab['group'], oneGroup => {
                                                const oneGroupData = this.getOneGroupData(dataList, this.tabList['aggregateListData'], oneGroup);
                                                oneGroup['groupData'] = oneGroupData;
                                                oneGroup['echartOptions'] = this.dataCenterService.setEchartOptions(oneGroup);
                                                if (oneGroup['groupData'].length > 0) {
                                                    hasValue++;
                                                }
                                            })
                                            if (hasValue > 0) {
                                                tabTempt.push(oneTab);
                                            }
                                        });

                                        this.tabList['tabs'] = tabTempt;

                                        _sh.each(this.tabList['tabs'], oneTab => {
                                            let groupList = oneTab['group'];
                                            _.each(groupList, oneGroup => {
                                                let count = 0;
                                                _.each(oneGroup['columns'], oneColumn => {
                                                    if (this.utils.Base_HasValue(oneGroup['groupData'])) {
                                                        if (this.utils.Base_HasValue(oneGroup['groupData'][0][oneColumn['column']])) {
                                                            count++;
                                                        }
                                                    }
                                                })
                                                oneGroup['rowCount'] = count;
                                                oneGroup['hideGroup'] = false;
                                                if (_sh.get(oneGroup, ['hideWithNull'], false)) {
                                                    if (oneGroup['rowCount'] == 0) {
                                                        oneGroup['hideGroup'] = true
                                                    }
                                                }

                                            })
                                        });

                                        _sh.each(this.tabList['tabs'], oneTab => {
                                            _.each(oneTab['group'], oneGroup => {
                                                oneGroup['visible'] = true; // 默认都显示。
                                                oneGroup['showExecButton'] = false;
                                                if (oneGroup['groupShowType'] == 'table') {
                                                    let columnWidth = this.calcColumnWidth(oneGroup);
                                                    let hasAgg = false
                                                    _.each(oneGroup['columns'], oneColumn => {
                                                        if (this.utils.Base_HasValue(oneGroup['scrollWidth'])) {
                                                            if (!this.utils.Base_HasValue(oneColumn['width'])) {
                                                                oneColumn['width'] = columnWidth;
                                                            }
                                                        }
                                                        if (!hasAgg && oneColumn['aggregateFunction']) {
                                                            hasAgg = true;
                                                        }
                                                    })
                                                    oneGroup['hasAgg'] = hasAgg; // 是否有小计
                                                    oneGroup['widthConfig'] = ['50px'];
                                                    oneGroup['']
                                                    _.each(oneGroup['columns'], oneColumn => {
                                                        if (oneColumn['visible'])  oneGroup['widthConfig'].push(oneColumn['width']);
                                                    })
                                                }
                                            })
                                        })


                                        resolve("complete");
                                    }
                                );


                            }
                        }
                    );

                }
            );
        });
    }

    calcColumnWidth(oneGroup) {
        // 计算每个栏目宽度 总宽度-左边菜单宽度-间距值
        let tableWidth = document.documentElement.clientWidth - 240 - 44;
        if (oneGroup['scrollWidth']) {
            tableWidth = +oneGroup['scrollWidth'].split('px')[0];
        }
        // 表格宽度-CheckBox-序号-详情列宽度
        let showColumnList = _sh.filter(oneGroup['columns'], oneColumn => {
            let columnVisible = _sh.get(oneColumn, ['visible'], true);
            return columnVisible
        });
        let columnWidth = (tableWidth - 50 - 50) / showColumnList.length;
        return columnWidth.toFixed(0) + 'px';
    }

    doRefresh(): void {
        PubSub.publish('descriptionRefresh', this.parentData.id);
        this.searchData();
    }

    doFilter() {
    }


    gotoBack() {
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL) && !_.isEmpty(item['nowMenu'].MENU_URL) && item['nowMenu'].MENU_URL === 'operate.table') {
                    item = _.extend(item, this.parentData);
                    item['formType'] = 'customForm';
                    item['data'] = {};
                    // item["detailCondition"] = {};
                    item['conditionColumns'] = {};
                } else if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL)) {
                    item = _.extend(item, this.parentData);
                    item['formType'] = 'listForm';
                    item['data'] = {};
                    // item["detailCondition"] = {};
                    item['conditionColumns'] = {};
                }
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    showOverPlay(pUrl): void {
        window.open(pUrl);
    }

    printWindows(): void {
        window.print();
    }

    getClassNo(num) {
        let raClass = '';
        switch (num) {
            case 0:
                raClass = 'c1';
                break;
            case 1:
                raClass = 'c2';
                break;
            case 2:
                raClass = 'c3';
                break;
            case 3:
                raClass = 'c4';
                break;
            case 4:
                raClass = 'c5';
                break;
            case 5:
                raClass = 'c6';
                break;
            case 6:
                raClass = 'c7';
                break;
            case 7:
                raClass = 'c8';
                break;
            case 8:
                raClass = 'c9';
                break;
        }
        return raClass;
    }

    randomNo() {
        const num = Math.floor(Math.random() * 8);
        let raClass = '';
        switch (num) {
            case 0:
                raClass = 'c1';
                break;
            case 1:
                raClass = 'c2';
                break;
            case 2:
                raClass = 'c3';
                break;
            case 3:
                raClass = 'c4';
                break;
            case 4:
                raClass = 'c5';
                break;
            case 5:
                raClass = 'c6';
                break;
            case 6:
                raClass = 'c7';
                break;
            case 7:
                raClass = 'c8';
                break;
            case 8:
                raClass = 'c9';
                break;
        }
        return raClass;
    }


    ngOnInit() {
        let pass = true;
        let id = _sh.get(this.parentData, ['id'], null);
        if (_sh.get(this.parentData, ['formClass'], null) == 'workflowForm') {
            this.wfUserAwaitId = _sh.get(this.parentData, ['workflowData', 'wfUserAwaitId']);
            if (!this.utils.Base_HasValue(this.wfUserAwaitId)) {
                this.confirmModal.show('error', {
                    'title': '执行错误',
                    'content': "未传入wfUserAwaitId数据。"
                });
            }
        }
        if (!this.utils.Base_HasValue(id)) {
            pass = false;
            this.confirmModal.show('error', {
                'title': '执行错误',
                'content': "未传入id数据。"
            });
        }
        if (pass) {
            this.searchData();
            this.helpUrl = this.parentData.addHelpUrl;
        }

    }

}
