import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "./request-data.service";
import {AppConfigService} from "./app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "./confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "./utils.service";
import * as _sh from 'lodash';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class HulkConfigServiceService {

  constructor(
      private _httpClient: HttpClient,
      private modalService: NzModalService,
      private _FileSaverService: FileSaverService,
      private requestDataService: RequestDataService,
      private appConfigService: AppConfigService,
      private notification: NzNotificationService,
      private activatedRoute: ActivatedRoute,
      private confirmModal: ConfirmModalService,
      private clipboardService: ClipboardService,
      private utils: UtilsService
  ) { }


  saveStandardFile(tablename,filePath) {
    let jsonObject = {
      '_type': 'normal',
      'selectText': ' select a.* ',
      'formText': 'from ' + tablename + ' a',
      'whereText': ' where 1 = 1 ',
      'orderbyText': '',
      'countText': '',
      "conditionGroup": {
        "group_01": "and"
      },
      "condition": {
        "group_01": [
          {
            "alias": "a",
            "column": "ID",
            "type": "string",
            "value": "ID",
            "direction": "input",
            "operator": "=",
            "condition_operator": "and"
          }
        ]
      },
      "columns": [],
      "showColumns": {
        "tablename": tablename,
        "columns": []
      },
      "conditionColumns": [
        {
          "tablename": tablename,
          "formname": tablename,
          "title": "查询",
          "type": "normal",
          "columns": []
        }
      ],
      "tableList": [
        {
          "alias": "a",
          "tablename": tablename,
          "columns": []
        }
      ]
    }
    this.saveFile(jsonObject, filePath);
  }

  saveFile(jsonObject, filePath) {
    let hulkJsonUploadCenter = _sh.get(this.appConfigService.config,['hulkJsonUploadCenter']);
    let check = true;
    let envList = [];
    _sh.each(hulkJsonUploadCenter,oneHulk=>{
      envList.push(oneHulk.env);
    })
    envList = _sh.uniq(envList);
    if (!_.contains(envList,'local')) {
      check = false;
      this.confirmModal.show('success', {
        title: "环境配置问题",
        content: "上传Json环境，必须有一个本地版本; hulkJsonUploadCenter"
      });
    }
    if (check) {
      _sh.each(hulkJsonUploadCenter,oneHulk=>{
        let str = JSON.stringify(jsonObject);
        this.requestDataService.saveJsonToLocal(oneHulk.url,str, filePath).subscribe(rep => {
          this.confirmModal.show('success', {
            title: oneHulk.env + ":保存成功",
            content: rep
          });
        });
      })
    }

  }


}
