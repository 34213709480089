import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UEditorComponent} from 'ngx-ueditor';
import {UploadPictureResumeComponent} from '../upload-picture-resume/upload-picture-resume.component';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';

declare const UE: any;

@Component({
  selector: 'app-ritch-text-resume',
  templateUrl: './ritch-text-resume.component.html',
  styleUrls: ['./ritch-text-resume.component.css']
})
export class RitchTextResumeComponent implements OnInit {


  @ViewChild('full', {static: false}) full: UEditorComponent;
  @Input() oneColumn: any;
  @Input() resumeType: any;

  constructor(private dataCenterService: DataCenterService,
              private util: UtilsService,
              private modalService: NzModalService) {
  }

  _ready(event) {
    setInterval(() => {
      if (event.Instance) {
        event.Instance.setHeight(400);
      }
    }, 10);
  }

  uploadPicture() {
    const modal = this.modalService.create({
      nzTitle: this.resumeType === 'CH' ? '上传图片' : 'Upload Picture',
      nzContent: UploadPictureResumeComponent,
      nzWidth: 450,
      nzMaskClosable: false,
      nzComponentParams: {
        resumeType: 'CH',
        dataObject: {
          value: '',
          title: this.resumeType === 'CH' ? '选择图片' : 'Choose',
        }
      },
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: (response) => {
        if (this.util.Base_HasValue(response['dataObject']['value'])) {
          modal.destroy();
          this.full.Instance.execCommand('insertimage', {
            src: response['dataObject']['value']
          });
        }
      }
    });
  }

  onPreReady(comp: UEditorComponent) {

  }

  ngOnInit() {
  }

}
