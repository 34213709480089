import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";

@Component({
  selector: 'app-es-order-detail',
  templateUrl: './es-order-detail.component.html',
  styleUrls: ['./es-order-detail.component.css']
})
export class EsOrderDetailComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';
  serviceData = [];
  selectedIndex = 0;

  datas = {
    esOrder: {
      ORDER_CODE: null,
      ORDER_STATE_DICT: null,
      CUST_CONTACTMAN: null,
      CUST_NAME: null,
      CUST_TIME: null,
      ORIGINAL_AMOUNT: null,
      PROVINCE: null,
      CITY: null,
      AREA: null,
      ADDR_DETAIL: null,
      GOODS_ID_DICT_ARRAY: {
        ORG_LV02_DICT: null,
        CATEGORIES_LV01_DICT:null,
        CATEGORIES_LV02_DICT:null,
        CATEGORIES_LV03_DICT:null,
        SERVICE_CODE:null
      },
      GOODS_NAME: null,
      OR_PRINCIPAL_NAME: null,
      OR_PRINCIPAL_TIME: null,
      INVOICE_STATE_DICT: null,
      INVOICE_CODE: null,
      INVOICE_NAME: null,
      INVOICE_TIME: null,
      IE_CUST_NAME: null,
      INVOICE_LICENCE: null,
      BUSINESS_ADDRESS: null,
      INVOICE_MOBILE: null,
      INVOICE_BANK: null,
      INVOICE_BANKNO: null,
      CONTRACT_STATE_DICT: null,
      CONTRACT_NAME: null,
      CONTRACT_TIME: null,
      SHIPPED_NAME: null,
      SHIPPED_MOBILE: null,
      SHIPPED_TIME: null,
      EXPRESS_NAME: null,
      EXPRESS_CODE: null,
      GOT_NAME: null,
      GOT_MOBILE: null,
      CONFIRM_TIME: null,
      CONFIRM_NAME: null,
      CONFIRM_MEMO: null,
      LOGISTICS_STATE_DICT: null,
      CONTRACT_FILE: null,
      EXPRESS_ITEM_DICT: [],
      PAID_BANKNO_DICT: null,
      PAID_CODE: null,
      PAID_NAME: null,
      PAID_TIME: null,
      RETURN_STATE_DICT: null,
      INITIATE_RETURN_TIME: null,
      REASONS_FOR_RETURN: null,
      RE_SHIPPING_TIME: null,
      RE_EXPRESS_CODE: null,
      RE_EXPRESS_NAME: null,
      RE_APPROVE_STATE_DICT: null,
      RE_APPROVER_NAME: null,
      RE_APPROVER_TIME: null,
      RE_APPROVER_RESULT: null,
      CONFIRM_RE_RECEIPT_NAME: null,
      CONFIRM_RE_RECEIPT_TIME: null,
      REFUND_STATE_DICT: null,
      REFUND_DEDUCTION_MONEY: null,
      REFUND_MONEY: null,
      REFUND_VERIFICATION_NAME: null,
      REFUND_VERIFICATION_TIME: null,
      REFUND_BANK: null,
      REFUND_CODE: null,
      RE_EXPRESS_ITEM_DICT: [],
      GOODS_UP_FILE01: null,
      GOODS_FILE01: null,
      EXPRESS_DICT: {
        LOGISTICS_STATE_DICT: null
      },
      ORG_ID_DICT: null
    },
    esOrderLog: [],
    esOrderSample: {
      SAMPLE_STATE_DICT: null,
      SAMPLE_MEMO: null,
      DELIVERY_LOGISTICS_COMPANY: null,
      DELIVERY_LOGISTICS_CODE: null,
      ADD_TIME: null,
      DELIVERY_LOGISTICS_DICT: {
        LOGISTICS_STATE_DICT: 'init'
      },
      DELIVERY_LOGISTICS_ITEM_DICT: [],
      RETURN_LOGISTICS_CODE: null,
      RETURN_LOGISTICS_COMPANY: null,
      RETURN_LOGISTICS_DICT: [],
      RETURN_LOGISTICS_ITEM_DICT: [],
      RETURN_TIME: null,
      USED_NAME: null,
      USED_TIME: null,
      SAMPLE_STATE: null,
      RETURN_NAME: null
    },
    esOrderSampleDetail: [],
    esOrderSamplePic: [],
    esOrderFiles:[],
    geOrderProcess:[],
    geOrderU8: []
  }
  esOrderId : String = "";

  constructor(
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {

  }

  doRefresh() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('genecreate/report/esOrderDetail/es_order.json',{'ID':this.esOrderId})
    );
    search.subscribe(
      values => {
        this.datas['esOrder'] = values[0]['data'][0] || [];
        this.serviceData = JSON.parse(this.datas['esOrder']['GOODS_JSON']);
      }
    );
  }

  ngOnInit() {
    this.esOrderId = this.parentData['esOrderId'];
    this.doRefresh();
  }

}
