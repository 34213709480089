import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  constructor() {
  }

  ngOnInit() {

  }
}
