import {Component, Input, OnInit, ViewChild} from '@angular/core';
import printJS from 'print-js';
import * as _sh from 'lodash';
import {forkJoin as observableForkJoin, Subject} from "rxjs";
import {RequestDataService} from "../../service/request-data.service";
import * as _ from "underscore";
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";

@Component({
    selector: 'app-print-result',
    templateUrl: './print-result.component.html',
    styleUrls: ['./print-result.component.scss']
})
export class PrintResultComponent implements OnInit {

    tabList = {
        tabs: []
    };

    readonlyData = {

    }

    showPage = true;
    showPrintForm = false;
    private searchStream = new Subject<Boolean>();

    @Input() parentData: any;
    @ViewChild('printArea', {static: false}) childForm;

    constructor(private requestDataService: RequestDataService,
                private utils: UtilsService,
                private dataCenterService: DataCenterService,
                private appConfigService: AppConfigService,
                private confirmModal: ConfirmModalService
    ) {
    }

    showError(oneColumn) {
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        if (!this.utils.Base_HasValue(fromValue)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromValue ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }

        const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
        if (!this.utils.Base_HasValue(fromDataCenter)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromDataCenter ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
    }

    execAggregate(pAggregateList, pData) {
        let result = {};
        _sh.each(pAggregateList, oneColumn => {
            this.showError(oneColumn);
            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            const aggregateFunction = _sh.get(oneColumn, ['aggregateFunction'], null);

            if (!this.utils.Base_HasValue(aggregateFunction)) {
                const errMsg = 'aggregateList 之中的，' + oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：aggregateFunction ， 请配置';
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': errMsg
                });
            }

            if (fromDataCenter[0] == 'sqlFileList') {
                const oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);
                if (aggregateFunction == 'count') {
                    result[oneColumn['column']] = oneColumnValue.length;
                } else if (aggregateFunction == 'sum') {
                    result[oneColumn['column']] = _sh.round(_sh.sum(oneColumnValue), 2);
                } else if (aggregateFunction == 'min') {
                    result[oneColumn['column']] = _sh.round(_sh.min(oneColumnValue), 2);
                } else if (aggregateFunction == 'max') {
                    result[oneColumn['column']] = _sh.round(_sh.max(oneColumnValue), 2);
                } else if (aggregateFunction == 'avg') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                } else if (aggregateFunction == 'mean') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                }
            } else {
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': "聚合函数的值只能在 sqlFileList 里面取值"
                });
            }
        })
        return result;
    }

    getOneGroupData(pData, pAggregateListData, pOneGroup) {
        let oneGroupData: any = [];
        let columns = pOneGroup['columns'];
        const groupShowType = _sh.get(pOneGroup, ['groupShowType'], null);
        const mainData = _sh.get(pOneGroup, ['mainData'], null);
        if (!this.utils.Base_HasValue(mainData)) {
            const errMsg = pOneGroup['groupTitle'] + '; 没有设置：mainData ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
        _sh.each(columns, oneColumn => {

            this.showError(oneColumn);
            oneColumn['visible'] = _sh.get(oneColumn, ['visible'], true);
            let mainDataLength = 0;
            if (mainData[0] == 'sqlFileList') {
                mainDataLength = pData[mainData[1]].length;
            } else if (mainData[0] == 'aggregateList') {
                mainDataLength = 1;
            }

            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            if (fromDataCenter[0] == 'sqlFileList') {


                let oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);

                // 如果超过主数据的行数，删除
                if (oneColumnValue.length > mainDataLength) {
                    oneColumnValue = _sh.drop(oneColumnValue, oneColumnValue.length - mainDataLength);
                } else if (oneColumnValue.length < mainDataLength) {
                    // 如果小于主数据的行数，增加。
                    let tempArr = oneColumnValue;
                    if (mainDataLength == 0) {
                        tempArr = [null]
                    } else if (mainDataLength > 0) {
                        for (var i = 0; i < mainDataLength - oneColumnValue.length; i++) {
                            tempArr.push(oneColumnValue[0])
                        }
                    }
                    oneColumnValue = tempArr;
                }

                if (groupShowType == 'appendixShow') {
                    // 如果是附件表格类型，要对groupData做取文件名的处理
                    oneGroupData = this.mergeData_appendix(oneGroupData, oneColumn, oneColumnValue);
                } else {
                    oneGroupData = this.mergeData(oneGroupData, oneColumn, oneColumnValue);
                }

            } else if (fromDataCenter[0] == 'aggregateList') {
                const oneColumnValue = _sh.get(pAggregateListData, oneColumn['column']);
                let tempArr = [];
                if (mainDataLength == 0) {
                    tempArr = [null]
                } else if (mainDataLength > 0) {
                    for (var i = 0; i < mainDataLength; i++) {
                        tempArr.push(oneColumnValue)
                    }
                }
                oneGroupData = this.mergeData(oneGroupData, oneColumn, tempArr);
            }
        })
        return oneGroupData;
    }

    mergeData(oneGroupData, oneColumn, oneColumnValue) {
        let rowIndex = 0
        _sh.each(oneColumnValue, oneRow => {
            _sh.set(oneGroupData, [rowIndex, oneColumn['column']], oneRow);
            rowIndex++;
        })

        return oneGroupData;
    }

    mergeData_appendix(oneGroupData, oneColumn, oneColumnValue) {
        let gd = {}
        _sh.each(oneColumnValue, oneRow => {
            gd['lable'] = oneColumn['lable']
            gd['url'] = oneRow
            gd['fileName'] = this.utils.Base_getFileNameOnly(oneRow)
            gd['nzSpan'] = oneColumn['nzSpan']
            gd['nz-badge'] = oneColumn['nz-badge']
        })
        oneGroupData.push(gd)
        return oneGroupData;
    }


    doFormat(pValue, pFunc) {
        let oldVale = pValue
        if (pFunc == "TO_CHINESE_BLOCK_LETTER") {
            if (this.utils.Base_HasValue(pValue)) oldVale = this.utils.changeToChineseMoney(pValue);
        }
        return oldVale
    }

    getOneRowValue(pOneRow, fromValue, fromValue_02) {
        let result = null;
        if (fromValue_02) {
            let d = [_sh.get(pOneRow, fromValue, null), _sh.get(pOneRow, fromValue_02, null)];
            result = d;
        } else {
            result = _sh.get(pOneRow, fromValue, null)
        }
        return result;
    }

    getOneValue(pData, oneColumn) {
        let that = this;
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        const fromValue_02 = _sh.get(oneColumn, ['fromValue_02'], null);
        const format = _sh.get(oneColumn, ['format'], null);
        const msgTemplate = _sh.get(oneColumn, ['fromValue','msgTemplate'], null);
        let datas = []
        if (that.utils.Base_HasValue(msgTemplate)) {
            // 模拟一个空值。
            let pParentData = {
                data:{
                    data:[]
                }
            }
            let value = that.dataCenterService.getSpecialValue(fromValue,pParentData,null,this.readonlyData);
            datas.push(value);
        } else {
        _sh.each(pData, pOneRow => {
            if (fromValue_02) {
                let d = [_sh.get(pOneRow, fromValue, null), _sh.get(pOneRow, fromValue_02, null)]
                datas.push(d)
            } else {
                datas.push(_sh.get(pOneRow, fromValue, null));
            }
        });
        }

        if (format == "TO_CHINESE_BLOCK_LETTER") {
            datas = _sh.map(datas, oneData => {
                return this.doFormat(oneData, format);
            })
        }
        return datas;
    }

    ngOnInit() {
        this.searchStream.pipe(
            debounceTime(400))
            .subscribe(async(searchContent) => {
                let dom = document.getElementById('printArea');
                printJS({
                    printable: dom,
                    type: 'html',
                    // style: style,// 亦可使用引入的外部css;
                    scanStyles: false,
                    onPrintDialogClose: () => {
                        this.showPrintForm = false;
                    }
                });
            });
        this.doRefresh();
    }

    printWindows() {
        this.showPrintForm = true;
        this.searchStream.next(this.showPrintForm);
    }

    doRefresh() {
        this.showPrintForm = false;
        let printJson = _sh.get(this.parentData, ['inputData', 'printJson']);
        let idArray = [];
        _sh.each(_sh.get(this.parentData, ['inputData', 'ID'], []), oneRow => {
            idArray.push(oneRow['id'])
        })
        let detailCondition = {'ID': idArray};
        const search = observableForkJoin(
            this.requestDataService.getDataByJson(printJson)
        );
        search.subscribe(
            values => {
                let aggregateList = _sh.get(values, [0, 'printConfig', 'aggregateList'], []);
                let sqlFileList = _sh.get(values, [0, 'printConfig', 'sqlFileList'], []);
                this.tabList['tabs'] = _sh.get(values, [0, 'printConfig', 'tabList'], []);
                _sh.each(this.tabList['tabs'],oneTab=>{
                    _sh.each(oneTab['group'],oneGroup=>{
                         oneGroup['receiptTemplateUrl'];
                        let seed = 'hulkServer'
                        let bFind = _sh.startsWith(oneGroup['receiptTemplateUrl'], seed);
                        if (bFind) {
                            oneGroup['receiptTemplateUrl'] = _sh.replace(oneGroup['receiptTemplateUrl'], seed, this.appConfigService.config.NodeJsServer);
                        }
                    })
                })

                let descriptionsRequest = [];
                _sh.each(sqlFileList, oneSqlFile => {
                    let oneSqlFileText = "";
                    if (_sh.isObject(oneSqlFile)) {
                        oneSqlFileText = _sh.get(oneSqlFile,['sqlFile'])
                    } else {
                        oneSqlFileText = oneSqlFile;
                    }
                    let oneRequest = this.requestDataService.getDataByCondition(oneSqlFileText, detailCondition)
                    descriptionsRequest.push(oneRequest);
                })
                const searchAll = observableForkJoin(descriptionsRequest);
                searchAll.subscribe(
                    deValues => {
                        let dataList = [];
                        _.each(deValues, oneValue => {
                            const oneData = _sh.get(oneValue, ['data'], []);
                            dataList.push(oneData);
                        });

                        this.tabList['sqlFileListData'] = dataList;
                        this.tabList['aggregateListData'] = this.execAggregate(aggregateList, dataList);


                        this.readonlyData = {
                            'sqlFileListData': _sh.cloneDeep(this.tabList['sqlFileListData']),
                            'aggregateListData': _sh.cloneDeep(this.tabList['aggregateListData'])
                        }

                        let tabTemp = []
                        _sh.each(this.tabList['tabs'], oneTab => {
                            let hasValue = 0
                            _sh.each(oneTab['group'], oneGroup => {
                                const oneGroupData = this.getOneGroupData(dataList, this.tabList['aggregateListData'], oneGroup);
                                oneGroup['groupData'] = oneGroupData;
                                if (oneGroup['groupData'].length > 0) {
                                    hasValue++;
                                }
                            })
                            if (hasValue > 0) {
                                tabTemp.push(oneTab);
                            }
                        });

                        this.tabList['tabs'] = tabTemp;
                    }
                );
            }
        );
    }
}
