import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-hulk-config-poplist-model',
    templateUrl: './hulk-config-poplist-model.component.html',
    styleUrls: ['./hulk-config-poplist-model.component.scss']
})
export class HulkConfigPoplistModelComponent implements OnInit {

    @Input() oneColumn: any;
    public isVisible = false;

    constructor() {
    }

    removeValue(): void {
        this.oneColumn['model'] = null
    }

    ngOnInit() {
    }

    // 弹出框
    showModal() {
        this.isVisible = true;
    }

    // 弹出框
    closeModal() {
        this.isVisible = false;
    }

    // 单选框 点击行事件
    selectData(data) {
        this.isVisible = false;
    }


}
