import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AutoInsertService} from "../../service/auto-insert.service";
import {StorageService} from "../../service/storage.service";
import {UserInfoService} from "../../service/user-info.service";
import {UploadService} from "../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../service/data-center.service";
import {RequestDataService} from "../../service/request-data.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import * as _ from 'lodash';
import {UUID} from "angular2-uuid";
import {forkJoin as observableForkJoin} from "rxjs";
import {ListFormComponent} from "../list-form/list-form.component";
import {FormComponent} from "../../basicComponent/form/form.component";
import * as moment from 'moment';

@Component({
  selector: 'app-workbench-form',
  templateUrl: './workbench-form.component.html',
  styleUrls: ['./workbench-form.component.css']
})
export class WorkbenchFormComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any = {
    conditionColumns: {
      columns: []
    }
  };

  @ViewChildren(ListFormComponent) childListForm: QueryList<ListFormComponent>;


  deadline = Date.now() + 1000 * 60 * 5;
  deadlineConfig = 0;
  user: any;
  nowMenu: any;
  appList: any;
  mainJson: any;
  topList: any = [];
  timer: any;
  formUuid: any;

  public tabData: any = {
    index: 0,
    list: [],
    nowMenu: {}
  };


  constructor(
    private requestDataService: RequestDataService,
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
  ) {
    this.user = this.userInfo.getUserInfo();
  }

  /**
   * 刷新页面
   */
  doRefresh() {
    this.getTopDatas();
    _.each(this.childListForm['_results'], oneList => {
      oneList.searchFilter();
    })
    this.deadline = Date.now() + this.deadlineConfig * 1000;
  }

  getTopDatas() {
    let ajaxList = [];
    // 获取数据
    let topDatas = _.keys(this.mainJson['topDatas']);
    _.each(topDatas, oneData => {
      ajaxList.push(this.requestDataService.getDataByCondition(this.mainJson['topDatas'][oneData], {}))
    })

    if (this.util.Base_HasValue(ajaxList)) {
      const search = observableForkJoin(ajaxList);
      search.subscribe(
        datas => {
          _.each(this.topList, oneTop => {
            let value01 = _.get(oneTop, ['value01'], null);
            if (this.util.Base_HasValue(value01)) {
              if (_.isArray(value01)) {
                _.set(oneTop, 'value01_data', _.get(datas, oneTop['value01']));
              } else {
                _.set(oneTop, 'value01_data', value01);
              }
            }
            let value02 = _.get(oneTop, ['value02'], null);
            if (this.util.Base_HasValue(value02)) {
              if (_.isArray(value02)) {
                _.set(oneTop, 'value02_data', "/ " + _.get(datas, oneTop['value02']));
              } else {
                _.set(oneTop, 'value02_data', "/ " + value01);
              }
            }
          });
        }
      );
    }
  }

  ngOnInit() {
    this.nowMenu = this.dataCenterService._dataObject.nowMenu;
    this.appList = _.find(JSON.parse(this.storage.getData('appList')), {'LIST_ID': _.get(this.nowMenu, ['MENU_ID'])});
    this.formUuid = UUID.UUID();
    this.requestDataService.getDataByJson(this.appList['SEARCH_ID']).subscribe(values => {
      _.each(values['list'], oneTab => {
        let obj = {
          id: UUID.UUID(), // 唯一标识
          parentDataId: this.formUuid,
          nowMenu: this.nowMenu, // 菜单ID 用于详情返回
          title: null, // 菜单名称 用户tab展示, // 菜单名称 用户tab展示 _.get(rep,['_comment'])
          formType: 'listForm', // 菜单类型 用于tab过滤显示模板
          searchId: oneTab['data01'], // 查询json 用户列表分页查询
          data: {}, // 查询返回数据
          listHelpUrl: this.appList.HELP_URL,
          addHelpUrl: this.appList.ADD_HELP_URL,
          pageSize: 5,
          nzPageSizeOptions: [5, 10, 20, 30, 100]
        }
        this.tabData.list.push(obj);
      })
      this.topList = values['top'];
      this.deadlineConfig = values['deadline'];
      this.deadline = Date.now() + this.deadlineConfig * 1000;
      this.mainJson = values;
      this.getTopDatas(); // 查询top 信息。
      this.doRestTime();
      this.tabData.index =  _.get(this.dataCenterService._dataObject, ['nowTab', 'itemTabName'], 0);
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  doRestTime() {
    this.timer = setInterval(() => {
      this.doRefresh();
    }, this.deadlineConfig * 1000);
  }

}
