import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './../../service/app-config.service';
import {AjaxService} from './../../service/ajax.service';
import {UserInfoService} from './../../service/user-info.service';
import {DataCenterService} from './../../service/data-center.service';
import {RequestDataService} from './../../service/request-data.service';
import {StorageService} from './../../service/storage.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {UUID} from 'angular2-uuid';

import * as _ from 'underscore';
import * as _sh from 'lodash';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})

export class MenusComponent implements OnInit {

  @Input() searchText: string;

  public menuList = [];
  private menuAllList = [];
  private appList = [];

  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService,
              private ajaxService: AjaxService,
              private userInfoService: UserInfoService,
              private activatedRoute: ActivatedRoute,
              private dataCenterService: DataCenterService,
              private requestDataService: RequestDataService,
              private storage: StorageService,
              private utils: UtilsService,
              private confirmModel: ConfirmModalService) {
  }

  newTab(item) {
    let menuUrl = _sh.get(item, 'MENU_URL', null);
    if (menuUrl == 'index.outForm') {
      let menuUpdateUrl = _sh.get(item, 'MENU_UPDATE_URL', null);
      window.open(menuUpdateUrl, '_blank');

    } else {
      this.dataCenterService.newTabWk(item);
    }
  }


  openHandler(value: string): void {
    _.each(this.menuList, (item) => {
      item.active = false;
    });
    value['active'] = true;
  }

  transformMenuList(menuList) {
    let resultList = _.filter(menuList, (OneData) => {
      return OneData['MENU_LV'] === 1;
    });
    _.each(resultList, (LvOne) => {
      LvOne['LvTwo'] = _.filter(menuList, (OneData) => {
        return OneData['UP_ID'] === LvOne['MENU_ID'];
      });
    });
    return resultList;
  }


  async ngOnInit() {
    const params = await this.activatedRoute.queryParams['_value'];
    const {menuId} = params;

    // 获取菜单。菜单json信息 并行调用并放入缓存
    const parallel$ = observableForkJoin(
        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
        this.requestDataService.getDataByCondition(this.appConfigService.config.app_list, {})
    );
    parallel$.subscribe(
        values => {
          let appList = values[1]['data'] || [];
          this.appList = appList;
          this.storage.setData('appList', JSON.stringify(appList));
          let roleIdList = [];
          _sh.each(values[0]['data'] || [], oneRole => {
            roleIdList.push(oneRole['ROLE_ID']);
          })

          const findMeulist$ = observableForkJoin(
              this.requestDataService.doGetRole()
          );
          findMeulist$.subscribe(findMeulistValues => {
            const [menuValue] = findMeulistValues;
            this.menuAllList = _sh.get(menuValue, 'menuAllList', null);
            this.storage.setData('menuList', JSON.stringify(this.menuAllList)); // 假设 storage.setData 处理了深拷贝
            this.menuList = _sh.get(menuValue, 'menuList', null);

            const performNewTab = (menu) => {
              if (this.utils.Base_HasValue(menu) && menu['MENU_LV'] === 2) {
                setTimeout(() => this.newTab(menu), 500);
              }
            };

            // 提取共同的查找逻辑到一个函数
            const findMenu = (condition) => _.find(this.menuAllList, condition);

            if (menuId) {
              performNewTab(findMenu(oneData => oneData.MENU_ID === menuId));
            } else {
              const defaultGotoMenu = this.appConfigService.config.defaultGotoMenu;
              if (this.utils.Base_HasValue(defaultGotoMenu)) {
                performNewTab(findMenu(oneData => oneData.MENU_URL === defaultGotoMenu));
              }
            }
          });

        }
    );
  }

  // 过滤菜单
  filterMeunList() {
    this.menuList = [];
    // 如果有搜索值则搜索。没有还原为所有菜单
    if (this.utils.Base_HasValue(this.searchText)) {
      // 过滤菜单
      let menuList = this.utils.DeepCopy(this.menuAllList);
      let list = _.filter(menuList, (item) => {
        return item['MENU_NAME'].indexOf(this.searchText) > -1;
      });
      // 循环 一级目录直接设置为active true
      // 二级菜单 先在已过滤列表里寻找有没有父级菜单（存在父级菜单里也有当前过滤值得情况）
      // 如果有 则不管 没有则去全部列表里找父级菜单并添加进入该过滤列表中
      _.each(list, (item) => {
        if (+item['MENU_LV'] === 1) {
          item['active'] = true;
        } else if (+item['MENU_LV'] === 2) {
          var up_menu = _.filter(list, (menu) => {
            return item['UP_ID'] === menu['MENU_ID'];
          });
          if (!up_menu.length) {
            up_menu = _.filter(menuList, (menu) => {
              return item['UP_ID'] === menu['MENU_ID'];
            });
            var menu = up_menu[0];
            if (this.utils.Base_HasValue(menu)) {
              menu['active'] = true;
              list.push(menu);
            }
          }
        }
      });
      this.menuList = this.transformMenuList(list);
    } else {
      let menuList = this.utils.DeepCopy(this.menuAllList);
      this.menuList = this.transformMenuList(menuList);
    }
  }

}
