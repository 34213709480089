import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../../service/request-data.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";

@Component({
  selector: 'app-aiocr',
  templateUrl: './aiocr.component.html',
  styleUrls: ['./aiocr.component.scss']
})
export class AiocrComponent implements OnInit {

  @Input() parentData: any;

  constructor(
    private requestDataService: RequestDataService,
    private utils: UtilsService
  ) {
  }

  type = null;

  fileUrl = `https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/assets/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230403174201_881c10684344435db5b0462c7b34d49e.jpg`;

  infos = [
    {
      "Name": "机构",
      "Value": "华中市大学国治医学院时属同院",
      "Row": -1
    },
    {
      "Name": "company",
      "Value": "TONGJI HOSPITAL",
      "Row": -1
    },
    {
      "Name": "号码",
      "Value": "DFRCOG平BRYUR(ND 02505100200",
      "Row": -1
    },
    {
      "Name": "标题",
      "Value": "门诊电子病历(初诊)",
      "Row": -1
    },
    {
      "Name": "科室",
      "Value": "儿童内科门诊",
      "Row": -1
    },
    {
      "Name": "患者ID",
      "Value": "60013596305",
      "Row": -1
    },
    {
      "Name": "年龄",
      "Value": "10岁",
      "Row": -1
    },
    {
      "Name": "性别",
      "Value": "女",
      "Row": -1
    },
    {
      "Name": "姓名",
      "Value": "汪雨兮",
      "Row": -1
    },
    {
      "Name": "就诊时间",
      "Value": "2023年03月15日14时13分",
      "Row": -1
    },
    {
      "Name": "主诉",
      "Value": "咨询",
      "Row": -1
    },
    {
      "Name": "现病史",
      "Value": "上周头休息好转。昨天头晕呕吐一次,今晨起头晕,精神食欲好,有时伴有腹痛。有时有视觉症状",
      "Row": -1
    },
    {
      "Name": "既往史",
      "Value": "体检:一般可,神志清楚,心肺无明显异常",
      "Row": -1
    },
    {
      "Name": "初步诊断",
      "Value": "头晕",
      "Row": -1
    },
    {
      "Name": "治疗意见",
      "Value": "鱼腥草滴鼻液0.1ml外用2/日(Bid) (共5支)1.0天",
      "Row": -1
    },
    {
      "Name": "姓名",
      "Value": "刘艳",
      "Row": -1
    },
    {
      "Name": "日期",
      "Value": "2023年03月15日 14时13分",
      "Row": -1
    },
    {
      "Name": "微信关注",
      "Value": "“华中科技大学同济医院”官方微信或下载安装“掌上同济”app,可直接预约挂号。",
      "Row": -1
    }
  ]


  templateDatas = {
    "mixedInvoiceOCR": {
      "fileUrl": `https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/assets/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230403174201_881c10684344435db5b0462c7b34d49e.jpg`,
      "infos": [
        {
          "Name": "机构",
          "Value": "华中市大学国治医学院时属同院",
          "Row": -1
        },
        {
          "Name": "company",
          "Value": "TONGJI HOSPITAL",
          "Row": -1
        },
        {
          "Name": "号码",
          "Value": "DFRCOG平BRYUR(ND 02505100200",
          "Row": -1
        },
        {
          "Name": "标题",
          "Value": "门诊电子病历(初诊)",
          "Row": -1
        },
        {
          "Name": "科室",
          "Value": "儿童内科门诊",
          "Row": -1
        },
        {
          "Name": "患者ID",
          "Value": "60013596305",
          "Row": -1
        },
        {
          "Name": "年龄",
          "Value": "10岁",
          "Row": -1
        },
        {
          "Name": "性别",
          "Value": "女",
          "Row": -1
        },
        {
          "Name": "姓名",
          "Value": "汪雨兮",
          "Row": -1
        },
        {
          "Name": "就诊时间",
          "Value": "2023年03月15日14时13分",
          "Row": -1
        },
        {
          "Name": "主诉",
          "Value": "咨询",
          "Row": -1
        },
        {
          "Name": "现病史",
          "Value": "上周头休息好转。昨天头晕呕吐一次,今晨起头晕,精神食欲好,有时伴有腹痛。有时有视觉症状",
          "Row": -1
        },
        {
          "Name": "既往史",
          "Value": "体检:一般可,神志清楚,心肺无明显异常",
          "Row": -1
        },
        {
          "Name": "初步诊断",
          "Value": "头晕",
          "Row": -1
        },
        {
          "Name": "治疗意见",
          "Value": "鱼腥草滴鼻液0.1ml外用2/日(Bid) (共5支)1.0天",
          "Row": -1
        },
        {
          "Name": "姓名",
          "Value": "刘艳",
          "Row": -1
        },
        {
          "Name": "日期",
          "Value": "2023年03月15日 14时13分",
          "Row": -1
        },
        {
          "Name": "微信关注",
          "Value": "“华中科技大学同济医院”官方微信或下载安装“掌上同济”app,可直接预约挂号。",
          "Row": -1
        }
      ]
    },
    "GeneralHandwritingOCR": {
      "fileUrl": `https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/JieLiv2/001-%E4%B8%AA%E4%BA%BA-%E5%AE%B6%E5%BA%AD%E6%88%90%E5%91%98%E6%83%85%E5%86%B5-%E6%89%93%E5%8D%B0%E7%89%88%E8%A1%A8%E6%A0%BC-%E6%89%8B%E5%86%99%E5%A1%AB%E8%A1%A8_7ce423cb07f05a2a5d584274a62cc132.png`,
      "infos": [
        {
          "Name": null,
          "Value": "家庭成员",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "情况",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "称师",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "姓名",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "性别",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "解放前、",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "后的",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "解放前、后与",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "现在何处任何工作",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "政治面目",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "和职业",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "你本人的联系",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "母親质董氏",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "女解前出贤劳动当顾工。",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "家庭妇女",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "弟信纫连男解放前炊事工人",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "永定路十一中学",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "I人",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "员子侯新华男解放后1970年参加工做",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "北京齿输厂",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "次子侯老华男",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "1972年参加工做",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "针织總厂",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "三约信立华男九十二中学生",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "子增华男104中学生",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "弟味修淑秀女",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "农民",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "北京酒厂工人",
          "Row": -1
        }
      ]
    },
    "RecognizeTableOCR" : {
      "fileUrl": `https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/JieLiv2/003-01-%E8%A1%A8%E6%A0%BC%E8%AF%86%E5%88%AB-%E6%8A%A5%E9%94%80%E6%A0%87%E5%87%86_653955d24798d35c148bc1c8da03272d.png`,
      "infos": [
        {
          "Name": null,
          "Value": "北京市城镇职工基本医疗保险医疗费用 报 销比例一览表",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "城 镇 职 工",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "门 人 诊 乡 类",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "参保人员类别",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "起付线",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "封顶线",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "报销比例",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "社区 医院",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "其他 医院",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "在职",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "1800元",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "2万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "90%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "70%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "退休",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "70岁以下",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "1300元",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "85%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "70岁以上",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "90%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "住 院 类",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "参保人 员类别",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "起付线",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "报销比例",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "医疗费用金额段",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "一级 医院",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "二级 医院",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "三级 医院",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "在职",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "本年度第一次 住院1300 元,第二次及 以后每次650 元",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "1300元一3万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "90%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "87%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "85%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "3万一4万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "95%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "92%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "90%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "4万一10万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "97%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "97%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "95%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "- 退休",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "10万一50万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "85%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "1300元一3万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "97%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "96.1%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "95.5%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "3万一4万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "98.5%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "97.6%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "97%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "4万一10万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "99.1%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "99.1%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "98.5%",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "10万一50万",
          "Row": -1
        },
        {
          "Name": null,
          "Value": "90%",
          "Row": -1
        }
      ]
    }
  }


  ngOnInit() {
    // nowMenu.MENU_UPDATE_URL
    this.type = _sh.get(this.parentData, 'nowMenu.MENU_UPDATE_URL', null);
    this.fileUrl = _sh.get(this.templateDatas, [this.type, 'fileUrl'], null);
    this.infos = _sh.get(this.templateDatas, [this.type, 'infos'], null);
  }

  fileModelHandler(event) {
    let that = this;
    that.infos = [];
    this.fileUrl = event;
    const search = observableForkJoin(
      this.requestDataService.getOcrResult(this.fileUrl, this.type)
    );
    search.subscribe(values => {
      that.infos = _sh.get(values, '0.MixedInvoiceItems[0].SingleInvoiceInfos', []);
      if (!that.utils.Base_HasValue(this.infos)) {
        let temp01 = _sh.get(values, '[0].TextDetections', []);
        _sh.each(temp01, oneRow => {
          that.infos.push({
            "Name": null,
            "Value": oneRow['DetectedText'],
            "Row": -1
          })
        })
      }

      if (!that.utils.Base_HasValue(this.infos)) {
        let temp01 = _sh.get(values, [0,'TableDetections', 0, 'Cells'], []);
        _sh.each(temp01, oneRow => {
          that.infos.push({
            "Name": null,
            "Value": oneRow['Text'],
            "Row": -1
          })
        })
      }

    })
  }

}
