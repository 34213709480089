import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../service/utils.service';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {DataService} from './data.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import * as _ from 'underscore';
import * as $ from 'jquery';
import * as moment from 'moment';
import {EsRebuildComponent} from '../headLine/es-rebuild/es-rebuild.component';
import {AuditContractComponent} from '../shopManagement/audit-contract/audit-contract.component';
import {MultiPopComponent} from './multi-pop/multi-pop.component';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    let flag = true;
    let ids = [];
    switch (pBtn.ACTION_VALUE) {
      case 'addUser':
        this.addUser(pParentData);
        break;
      case 'rebuildResume':
        this.rebuildResume();
        break;
      case 'finishHandle':
        this.finishHandle(pParentData);
        break;
      case 'publishTeachersResume':
        this.publishTeachersResume(pParentData, 'OPEN');
        break;
      case 'closeTeachersResume':
        this.publishTeachersResume(pParentData, 'CLOSE');
        break;
      case 'OutstandingTalent':
        this.OutstandingTalent(pParentData);
        break;
      case 'setCollegeAdmin':
        this.setCollegeAdmin(pParentData, 'SET');
        break;
      case 'cancelCollegeAdmin':
        this.setCollegeAdmin(pParentData, 'CANCEL');
        break;
    }
  }

  // 分配杰出人才
  OutstandingTalent(pParentData) {
    const selectList = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked']) {
        selectList.push(item['ID']);
      }
    });
    this.modalService.create({
      nzTitle: '设置为杰出人才',
      nzContent: MultiPopComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        hulkColumn: {
          'id': 'outstandingTalent',
          'column': 'outstandingTalent',
          'name': 'outstandingTalent_NAME',
          'type': 'multipop',
          'lable': '人才类型',
          'title': '人才类型',
          'placeholder': '请选择人才类型',
          'value': '',
          'visible': true,
          'require': false,
          'dictionary': 'resume/basic/dict/OUTSTANDING_TALENT_CH.json'
        }
      },
      nzOnOk: (response) => this.OutstandingTalentConfirm(response, selectList, pParentData)
    });
  }

  // 设置为院系管理员
  setCollegeAdmin(pParentData, pType) {
    const selectList = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked']) {
        selectList.push(item['ID']);
      }
    });
    let data = {
      ids: selectList,
      type: pType
    };
    let text = '您确定设置选中教师为所属院系的管理员吗？';
    if (pType === 'CANCEL') {
      text = '您确定取消选中教师为所属院系的管理员吗？';
    }
    this.confirmModal.show('confirm', {
      title: text,
      suc: () => {
        this.dataService.setCollegeAdmin(data).subscribe(rep => {
          if (rep.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: '执行完成'});
          } else {
            this.confirmModal.show('error', {title: '执行失败'});
          }
        });
      }
    });
  }

  OutstandingTalentConfirm(response, selectData, pParentData) {
    let valueCodeArr = [];
    let valueNameArr = [];
    let valueCodeStr = '';
    let valueNameStr = '';
    if (this.util.Base_HasValue(response['totalDataSet'])) {
      let findData = _.filter(response.totalDataSet, (item) => {
        return item['checked'];
      });
      for (let oneData of findData) {
        if (oneData['checked']) {
          valueCodeArr.push(oneData['code']);
          valueNameArr.push(oneData['name']);
        }
      }
      valueCodeStr = valueCodeArr.join('；');
      valueNameStr = valueNameArr.join('；');
    }
    let data = {
      ids: selectData,
      valueCodeStr: valueCodeStr,
      valueNameStr: valueNameStr
    };
    this.dataService.outstandingTalent(data).subscribe(rep => {
      if (rep.state === 'success') {
        pParentData.needSearch = true;
        this.confirmModal.show('success', {title: '执行完成'});
      } else {
        this.confirmModal.show('error', {title: '执行失败'});
      }
    });
  }

  finishHandle(pParentData) {
    const selectList = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked']) {
        selectList.push(item['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '确认所选择的数据都已处理了吗？',
      suc: () => {
        this.dataService.updateFeedback(selectList).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
          }
        });
      }
    });
  }

  addUser(pParentData) {
    let selectList = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked'] && item['IS_USER'] === 0) {
        selectList.push(item);
      }
    });
    if (selectList.length === 0) {
      return this.confirmModal.show('warning', {title: '请选择还没有生成账号的教师。'});
    }
    this.confirmModal.show('confirm', {
      title: '确认生成账号吗？',
      suc: () => {
        this.dataService.insertTeacherUser(selectList).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
          }
        });
      }
    });
  }

  publishTeachersResume(pParentData, pResumeState) {
    let selectList = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return false;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked']) {
        selectList.push(item['ID']);
      }
    });
    let data = {
      teacherIds: selectList,
      resumeState: pResumeState
    };
    let text = '您确定开通选中教师的简历吗？';
    if (pResumeState === 'CLOSE') {
      text = '您确定关闭选中教师的简历吗？';
    }
    this.confirmModal.show('confirm', {
      title: text,
      suc: () => {
        this.dataService.publishTeachersResume(data).subscribe(rep => {
          if (rep.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: '执行完成'});
          }
        });
      }
    });
  }

  rebuildResume() {
    this.confirmModal.show('confirm', {
      title: '重建搜索引擎将导致前台数据重新生成,您确定进行重建搜索引擎吗？',
      suc: () => {
        this.dataService.resumeRebuild().subscribe(rep => {
          if (rep.state === 'success') {
            this.confirmModal.show('success', {title: '重建完成'});
          }
        });
      }
    });
  }

  mackConditon(pParentData): void {
    function setDetailConditons(pData) {
      function isCondtion(OneColumn) {
        let bFind = false;
        if (_.has(OneColumn, 'iscondition')) {
          bFind = OneColumn.iscondition;
        }
        return bFind;
      }

      let conData;
      _.each(pData, function (oneData) {
        if (oneData['checked']) {
          conData = oneData;
        }
      });
      let tmp = {};
      _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
        //只将 iscondition 为 true 的作为条件传入详情页面。
        if (isCondtion(OneColumn)) {
          tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
        }
      });
      return tmp;
    }

    pParentData.detailCondition = setDetailConditons(pParentData.data.data);
  }

  transformData(pFormType, parentData, pModeType?, btnName?, custormFormType?) {
    // 重新覆盖 datacenter 数据
    // parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.custormFormType = custormFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doDetailAction(pBtn, parentData, mainForm) {
    switch (pBtn.ACTION_VALUE) {

    }
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (iCount == 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
