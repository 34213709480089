import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


@Injectable({
	providedIn: 'root'
})
export class ExcelExportService {

	constructor() { }


	saveAsExcelFile(buffer: any, fileName: string) {
		const data: Blob = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		})
		FileSaver.saveAs(data, fileName + '.xls')
		// 如果写成.xlsx,可能不能打开下载的文件，这可能与Excel版本有关
	}

	exportList (dataList, fileName) {
		//这个nameList (随便起的名字)，是要导出的json数据
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataList);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		//这里类型如果不正确，下载出来的可能是类似xml文件的东西或者是类似二进制的东西等
		this.saveAsExcelFile(excelBuffer, fileName);
	}
}
