import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataService} from '../data.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd';

@Component({
  selector: 'app-set-point-modal',
  templateUrl: './set-point-modal.component.html',
  styleUrls: ['./set-point-modal.component.css']
})
export class SetPointModalComponent implements OnInit {

  point = 0;

  constructor(private confirmModal: ConfirmModalService,
              private modal: NzModalRef,
              private dataService: DataService) {
  }

  confirm(): void {
    // const ids = [];
    // _.filter(this.parentData.data.data, oneData => {
    //   if (oneData['checked']) {
    //     ids.push(oneData['ID']);
    //   }
    //   return oneData['checked'];
    // });
    // this.dataService.requestQuickSliver('/digital/setResourcePoint', {pointNum: this.point, resourceIds: ids}).subscribe(response => {
    //   if (response.state === 'success') {
    //     this.confirmModal.show('success', {title: '执行成功'});
    //     this.modal.close();
    //     this.parentData.needSearch = true;
    //   } else {
    //     this.confirmModal.show('error', {title: '执行失败'});
    //   }
    // });
  }

  ngOnInit() {
  }

}
