import {Component, Input, OnInit} from '@angular/core';
import { JumpCenterService } from 'src/app/service/jump-center.service';
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {DataCenterService} from "../../../service/data-center.service";

@Component({
  selector: 'app-descriptions-edit-input',
  templateUrl: './descriptions-edit-input.component.html',
  styleUrls: ['./descriptions-edit-input.component.scss']
})
export class DescriptionsEditInputComponent implements OnInit {

  @Input() oneTab: any;
  @Input() oneGroup: any;
  @Input() oneColumn: any;
  @Input() oneData:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() execButton: any;

  constructor(private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private jumpCenterService: JumpCenterService,
              private requestService: RequestDataService) { }

  ngOnInit() {
  }

  removeValue() {
    this.oneData[this.oneColumn['column']] = null;
  }
}
