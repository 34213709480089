import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../service/request-data.service';
import {UtilsService} from '../../service/utils.service';
import {DataCenterService} from '../../service/data-center.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';

@Component({
  selector: 'app-description-form',
  templateUrl: './description-form.component.html',
  styleUrls: ['./description-form.component.css']
})
export class DescriptionFormComponent implements OnInit {

  public forms: any = [];

  private showPicutre: boolean = false;

  private OverPlay: boolean = false;

  private current: number = 0;

  //父级组件的数据对象
  @Input() parentData: any;

  public helpUrl: string;

  public switchValue: boolean = true;

  public switchText: string = '显示所有信息';

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private dataCenterService: DataCenterService) {
  }


  checkSwtich(value) {
    this.switchText = value ? '显示所有信息' : '隐藏没有数据的信息';
    _.each(this.forms, (form) => {
      if (form['type'] === 'normal' && form['showDetail']) {
        _.each(form['columns'], (column) => {
          if (value) {
            // 如果是数组传参。则定义需要detail并且要有值
            // 如果是boolean传参。则定义需要有值并且定义为展示
            if (_.isArray(column['visible'])) {
              column['show'] = _.contains(column['visible'], 'detail') && this.utils.Base_HasValue(column['model']);
            } else if (this.utils.Base_HasValue(column['visible'])) {
              column['show'] = column['visible'] && (this.utils.Base_HasValue(column['model'] || column['component'] === 'hr'));
            }
          } else {
            column['show'] = (_.isArray(column['visible']) && _.contains(column['visible'], 'detail')) || (_.isBoolean(column['visible']) && column['visible']);
          }
        });
      }
    });
  }

  /**
   * [该方法主要是存在分组情况的时候将columns里面没有在groupModel中穷尽到的column加入group分组中]
   */
  transformGroupColumn(oneForm) {
    let noGroupColumns = [];
    _.each(oneForm.columns, (item) => {
      let inGroup = false;
      _.each(oneForm.groupModel, (group) => {
        if (_.contains(group['column'], item['column'])) {
          inGroup = true;
        }
      });
      if (!inGroup) {
        noGroupColumns.push(item['column']);
      }
    });
    if (noGroupColumns.length) {
      oneForm.groupModel.unshift({
        title: '未分组信息',
        column: noGroupColumns
      });
    }
  }

  // 查询后的数据进行封装
  transformResult(data) {
    this.forms = data.detailColumns || [];
    this.showPicutre = false;
    _.each(this.forms, (OneForm) => {
      OneForm['showModel'] = 'list'; //默认显示列表页面
      // 判断form表单是否在修改页面显示，如果没有配置showPage属性，默认显示
      let showPage = this.utils.Base_getJsonValue(OneForm, 'showPage', false);
      if (showPage) {
        let showPageArray = OneForm['showPage'];
        if (showPageArray.indexOf('detail') > -1) {
          OneForm['showDetail'] = true;
        }
      } else {
        OneForm['showDetail'] = true;
      }
      OneForm['scrollWidth'] = data.scrollWidth || null;
      // 如果有group分组。则捡漏
      if (this.utils.Base_HasValue(OneForm['groupModel'])) {
        this.transformGroupColumn(OneForm);
      }
      // 冗余 visible 字段。用于切换开关
      _.each(OneForm['columns'], (OneColumn) => {
        if (OneForm['type'] === 'normal'
          && (OneColumn['component'] === 'pictureLocal' || OneColumn['component'] === 'picture')) {
          this.showPicutre = true;
        }
        if (OneForm['type'] === 'normal' && OneForm['showDetail']) {
          // 如果是数组传参。则定义需要detail并且要有值
          // 如果是boolean传参。则定义需要有值并且定义为展示
          if (_.isArray(OneColumn['visible'])) {
            OneColumn['show'] = _.contains(OneColumn['visible'], 'detail') && this.utils.Base_HasValue(OneColumn['model']);
          } else if (this.utils.Base_HasValue(OneColumn['visible'])) {
            OneColumn['show'] = OneColumn['visible'] && (this.utils.Base_HasValue(OneColumn['model']) || OneColumn['component'] === 'hr');
          }
        }
        if (_.contains(['dateRange', 'dateTimeRange'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model'])) {
          OneColumn['model'] = OneColumn['model'].join('~');
        }
        if (_.contains(['multiSelect'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model'])) {
          if (OneColumn['model'].indexOf(';') !== -1) {
            OneColumn['model'] = OneColumn['model'].split(';');
          } else {
            OneColumn['model'] = [OneColumn['model']];
          }
        }
        if (_.contains(['multipop'], OneColumn['component']) && this.utils.Base_HasValue(OneColumn['model']) && OneColumn['model'].indexOf(',') !== -1) {
          OneColumn['model'] = OneColumn['model'].split(',');
        }
      });
    });
  }

  searchData(): void {
    this.requestDataService.getFormJson('modify', this.parentData).subscribe((data: any) => {
      this.transformResult(data);
      // 重新覆盖 datacenter 数据
      this.parentData.data = data;
      // 覆盖当前数据到data-center;
      _.each(this.dataCenterService._dataObject.list, (item) => {
        if (item['id'] === this.parentData.id) {
          item = _.extend(item, this.parentData);
        }
      });
      this.dataCenterService.refreshDataCenterInStorage();
    });
  }

  gotoBack() {
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL) && !_.isEmpty(item['nowMenu'].MENU_URL) && item['nowMenu'].MENU_URL === 'operate.table') {
          item = _.extend(item, this.parentData);
          item['formType'] = 'customForm';
          item['data'] = {};
          // item["detailCondition"] = {};
          item['conditionColumns'] = {};
        } else if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL)) {
          item = _.extend(item, this.parentData);
          item['formType'] = 'listForm';
          item['data'] = {};
          // item["detailCondition"] = {};
          item['conditionColumns'] = {};
        }
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  showOverPlay(pUrl): void {
    window.open(pUrl);
  }

  printWindows(): void {
    window.print();
  }

  readOnlyFilter(oneColumn: any[], filterObj: Array<{ column: string, values?: any[], hasValues?: Boolean }>): any {

    // let test: Array<{ column: string, hasValue: [any], condition?: String }> =
    //   [{'column': 'component', 'hasValue': ['input'], 'condition': 'in'},{'column': 'show', 'hasValue': [true]}];
    let result = true;
    _sh.each(filterObj, oneFilter => {
      const findValue = _sh.get(oneColumn, oneFilter.column);
      if (this.utils.Base_HasValue(oneFilter.hasValues)) {
        if (oneFilter.hasValues) {
          result = result && this.utils.Base_HasValue(findValue);
        } else {
          result = result && !this.utils.Base_HasValue(findValue);
        }
      } else {
        result = result && _sh.indexOf(oneFilter.values, findValue) > -1;
      }
    });
    return result;
  }

  randomNo() {
    const num = Math.floor(Math.random() * 8);
    let raClass = '';
    switch (num) {
      case 0:
        raClass = 'c1';
        break;
      case 1:
        raClass = 'c2';
        break;
      case 2:
        raClass = 'c3';
        break;
      case 3:
        raClass = 'c4';
        break;
      case 4:
        raClass = 'c5';
        break;
      case 5:
        raClass = 'c6';
        break;
      case 6:
        raClass = 'c7';
        break;
      case 7:
        raClass = 'c8';
        break;
      case 8:
        raClass = 'c9';
        break;
    }
    return raClass;
  }

  ngOnInit() {
    this.randomNo();
    // 如果该tab数据不存在则需要查询
    if (!this.utils.Base_HasValue(this.parentData.data)) {
      this.searchData();
    } else {
      this.transformResult(this.parentData.data);
    }
    this.helpUrl = this.parentData.addHelpUrl;
  }

}
