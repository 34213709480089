import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-students-exam',
  templateUrl: './students-exam.component.html',
  styleUrls: ['./students-exam.component.css']
})
export class StudentsExamComponent implements OnInit {
  listData: any;
  year = [];
  objectKeys: any = Object.keys;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [];
    _.each(this.listData, (value, key) => {
      const data = {
        '考试名称': key
      };
      _.each(this.year, item => {
        data[item + '年报考人数'] = this.dataYear(value, item)[0]['PEOPLE_NUM'] || 0;
        data[item + '年通过人数'] = this.dataYear(value, item)[0]['PASS_NUM'] || 0;
      });
      data1.push(data);
    });
    if (!this.utils.Base_HasValue(this.listData)) {
      return this.confirmModal.show('warning', {title: '特殊报表没有数据不支持导出。'});
    }
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年在校生参加相关资格证书及培训考试情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-4 近5年在校生参加相关资格证书及培训考试情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  dataYear(pArray, year) {
    const data = _.filter(pArray, oneData => {
      return oneData['TRAINING_TIME'] - 0 === year;
    });
    if (data.length) {
      return data;
    } else {
      return [0];
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  dataFormat() {
    this.listData = _.groupBy(this.listData, oneData => {
      return oneData['TRAINING_NAME'];
    });
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.year = [this.evaluationYear - 4, this.evaluationYear - 3, this.evaluationYear - 2, this.evaluationYear - 1, this.evaluationYear - 0];
    this.requestDataService.getDataByCondition('subjectInfo/report/report_student_training.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe(response => {
      this.listData = response.data;
      this.dataFormat();
    });
  }

}
