import {Component, OnInit, Input, ViewChild, TemplateRef} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../service/utils.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {ValidatorService} from '../../service/validator.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {SettingColumnService} from './../../service/setting-column.service';
import {ServiceCenterService} from './../../service/service-center.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {AppConfigService} from "../../service/app-config.service";
import * as $ from 'jquery';
import {JumpCenterService} from "../../service/jump-center.service";
import {Md5} from "ts-md5";
import {UUID} from "angular2-uuid";
import {debounceTime, distinctUntilChanged, filter, map, startWith} from 'rxjs/internal/operators';
import {forkJoin as observableForkJoin, Observable, Subject, Subscription} from "rxjs";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {


  // 1. 如果查询条件里面都没有 type=common 则全部隐藏 并在标题处显示展开收起按钮
  // 2. 如果全部 type=common 则标题不需要展示下拉按钮 展示type=common 内容不需要展开收起按钮
  // 3. 如果部分有 则需要展示 type=common 其余需要点击展开展示


  public _validateForm: FormGroup;


  @ViewChild('conditionColumn', {static: false}) conditionColumn;

  @Input() tabId: string = '';

  // 是否展开
  public isCollapse = false;

  @Input() modeType: string = '';
  @Input() isModal: Boolean = false; // 该窗体是否是模态框

  // 列表帮助文档连接
  @Input() helpUrl: string = '';

  @Input() forms: any = [];

  // form 组件数据
  @Input() formData: any = {
    columns: [],
    changeTimes: 0
  };

  // form 如果存在该字段则为需要有展开收起
  @Input() needCollapse: boolean = false;
  // titleCollapse:boolean = false 控制标题展开收起
  public titleCollapse: boolean = false;
  // showCollapse:boolean = false; 控制内容部分展开收起
  public showCollapse: boolean = false;
  // 是否显示form面板
  public showForm = false;

  // 配置查询列menuId 如果有该字段则需要配置查询列
  @Input() needSettingMenuId: string = '';
  public settingConditionColumn: any = [];


  public allConColChecked = true;
  public conColIndeterminate = false;


  // 配置列表显示项弹框排序方式
  public columnSortType: any = false;


  constructor(private formBuilder: FormBuilder,
              private utils: UtilsService,
              private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private serviceCenterService: ServiceCenterService,
              private validatorService: ValidatorService,
              private modalService: NzModalService,
              private settingSerive: SettingColumnService,
              private jumpCenterService: JumpCenterService,
              private confirmModal: ConfirmModalService,
              private dataCenterService: DataCenterService) {
  }

  // 点击配置弹框表头排序方式
  sortConditionColumn() {
    this.conditionColumn.sortName(this.columnSortType ? 'letter' : 'index');
  }


  // 弹框标题全选反选
  updateAllConColChecked() {
    this.conColIndeterminate = false;
    if (this.allConColChecked) {
      this.formData.columns.forEach(item => item.checked = true);
    } else {
      this.formData.columns.forEach(item => item.checked = false);
    }
    this.conditionColumn.checkedColumns = [];
    _.each(this.formData.columns, item => {
      if (item['checked']) {
        this.conditionColumn.checkedColumns.push(item['column']);
      }
    });
  }

  // 修改标题全选框选中状态
  updateCheckAndIndeter(model): void {
    this.allConColChecked = model.checked;
    this.conColIndeterminate = model.indeterminate;
  }

  // 弹框设置列表显示列
  settingColumns(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>) {
    this.columnSortType = false;
    let wrapper = $('#setting-condition' + this.tabId);
    let rightLen = document.documentElement.clientWidth - 20 - wrapper.offset().left;
    let styleObj = {position: 'absolute', top: wrapper.offset().top + 25 + 'px', right: rightLen + 'px'};
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzStyle: styleObj,
      nzOnOk: (response) => this.confirmSettingColumns(this.conditionColumn)
    });
  }

  // 配置列表显示列
  confirmSettingColumns(result) {
    if (this.utils.Base_HasValue(result)) {
      this.settingConditionColumn = result.checkedColumns;
      // 需要重新刷新columns显示
      this.transformSettingCondition();
      let settings = this.settingSerive['settings'][this.needSettingMenuId];
      if (!this.utils.Base_HasValue(settings)) {
        this.settingSerive['settings'][this.needSettingMenuId] = {};
      }
      this.settingSerive['settings'][this.needSettingMenuId]['conditionColumns'] = this.settingConditionColumn;

      this.settingSerive.refreshSettingsInStorage();
    }
  }


  // 设置了通用条件之后 重新刷新展示状态
  transformSettingCondition() {
    _.each(this.formData.columns, item => {
      if (_.contains(this.settingConditionColumn, item['column'])) {
        item['type'] = 'common';
      } else {
        item['type'] = 'normal';
      }
    });
    // 切换展开收起
    this.transformDisplay();
    // 刷新column是否展示属性
    _.each(this.formData.columns, (item) => {
      if (_.isArray(item['visible'])) {
        item['visible'] = _.contains(item['visible'], this.modeType);
      }
      if (item['visible']) {
        if (this.showCollapse) {
          item['show'] = item['type'] === 'common' && !!item['visible'];
        } else {
          item['show'] = !!item['visible'];
        }
      }
    });
    this._validateForm['columns'] = this.formData.columns;
    // 获取到datacenter conditionColumns 并重新复制
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 展开/收起
  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
    _.each(this.formData.columns, (item, index) => {
      item['show'] = !!item['visible'];
      if (!!this.isCollapse) {
        item['show'] = item['type'] === 'common' && !!item['visible'];
      }
    });
    this._validateForm['columns'] = this.formData.columns;
  }


  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    let validatorList = [];
    let asyncValidatorList = [];
    let needBlur = false;
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    if (this.utils.Base_HasValue(oneColumn.maxlength)) {
      validatorList.push(Validators.maxLength(oneColumn.maxlength));
    }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    if (this.utils.Base_HasValue(oneColumn.uniqueCheck)) {
      needBlur = true;
      asyncValidatorList.push(this.validatorService.uniqueCheck(this.modeType, this.formData, oneColumn).bind(this));
    }
    if (this.utils.Base_HasValue(oneColumn.actions)) {
      // TODO
    }
    if (this.utils.Base_HasValue(oneColumn.timeCheck)) {
      needBlur = true;
      validatorList.push(this.validatorService.timeCheckValidator(oneColumn.timeCheck.column).bind(this));
    }
    if (needBlur) {
      if (asyncValidatorList.length) {
        return new FormControl('', {
          validators: validatorList,
          asyncValidators: asyncValidatorList,
          updateOn: 'blur'
        });
      } else {
        return new FormControl('', {validators: validatorList, updateOn: 'blur'});
      }
    } else {
      return new FormControl('', validatorList);
    }
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    let group = {};
    _.each(this.formData.columns, (item) => {
      if (_.isArray(item['visible'])) {
        item['visible'] = _.contains(item['visible'], this.modeType);
      }
      if (item['visible']) {
        group[item['name']] = this.addOneColumn(item);
        if (this.showCollapse) {
          item['show'] = item['type'] === 'common' && !!item['visible'];
        } else {
          item['show'] = !!item['visible'];
        }
        if (this.utils.Base_HasValue(item['columnGroup'])
          && item['columnGroup'].length) {
          _.each(item['columnGroup'], (groupItem) => {
            group[groupItem['name']] = this.addOneColumn(groupItem);
          });
        }
      }
      if (_.isArray(item['disable'])) {
        item['disable'] = _.contains(item['disable'], this.modeType);
      }
    });

    const validateForm = this.formBuilder.group(group);
    validateForm['columns'] = this.formData.columns;
    return validateForm;
  }

  // 重置form
  resetForm(): void {

    _.each(this.formData.columns, (item) => {
      if(item['noReSet'] != true){
        item['model'] = null;
        item['modelName'] = null;
      }
    });
    let temp = _sh.cloneDeep(this.formData.columns);
    //this._validateForm.reset();
    this._validateForm['columns'] = temp;
  }

  /**
   * [该方法主要是存在分组情况的时候将columns里面没有在groupModel中穷尽到的column加入group分组中]
   */
  transformGroupColumn() {
    let noGroupColumns = [];
    _.each(this.formData.columns, (item) => {
      let inGroup = false;
      _.each(this.formData.groupModel, (group) => {
        if (_.contains(group['column'], item['column'])) {
          inGroup = true;
        }
      });
      if (!inGroup) {
        noGroupColumns.push(item['column']);
      }
    });
    if (noGroupColumns.length) {
      this.formData.groupModel.unshift({
        title: '未分组信息',
        column: noGroupColumns
      });
    }
  }

  // 根据目前类型状态判断该查询面板显示状态 主要用于查询条件
  transformDisplay() {
    // 获取 type=common 数组
    const commonColumns = this.formData.columns.filter((item) => {
      return item.type === 'common';
    });
    if (commonColumns.length === this.formData.columns.length) {
      this.titleCollapse = false;
      this.showCollapse = false;
      this.isCollapse = false;
      this.showForm = true;
    } else if (!commonColumns.length) {
      this.titleCollapse = true;
      this.showCollapse = false;
      this.isCollapse = false;
    } else {
      this.showCollapse = true;
      this.isCollapse = true;
      this.titleCollapse = false;
    }
  }

  init() {
    const conditionPageExpand = _sh.get(this.appConfigService.config, ['conditionPageExpand'], false);
    if (conditionPageExpand) {
      _.each(this.formData.columns, item => {
        item['type'] = 'common';
      });
    }
    if (this.needCollapse) {
      this.transformDisplay();
    } else {
      this.showForm = true;
    }
    // 如果有group分组。则捡漏
    if (this.utils.Base_HasValue(this.formData.groupModel)) {
      this.transformGroupColumn();
    }
    this._validateForm = this.addOneForm();
  }

  execChange() {
    let that = this;

    function doCheck(check) {
      let result = true;
      _sh.each(check, oneCheck => {
        let valueA = getValue(oneCheck['fromValue']);
        let valueB = oneCheck['judgeValue'];
        let judgeOperator = oneCheck['judgeOperator'];
        let judgmentResult = that.dataCenterService.judgeOperatorFuntion(valueA, judgeOperator, valueB);
        if (judgmentResult) { // 如果判断出来，有问题的。
          let msgValue = {};
          _sh.each(oneCheck['msgValue'], oneMsg => {
            let formValue = getValue(_sh.get(oneMsg, ['fromValue']));
            let isNull = _sh.get(oneMsg, ['isNull'], null);
            let temp = formValue;
            if (!that.utils.Base_HasValue(temp)) {
              temp = isNull;
            }
            msgValue[oneMsg['column']] = temp;
          });
          const msgTemplate = _sh.get(oneCheck, ['msgTemplate'], null); // 格式化模板
          let compiled = _.template(msgTemplate);
          let msgText = compiled(msgValue);
          result = false;
          that.confirmModal.show(oneCheck['msgType'], {
            'title': oneCheck['msgTitle'],
            'content': msgText
          });
        }
      });
      return result;
    }

    function getValue(fromValue) {
      let result = null;

      function getInOneForm(oneForm, tableName, columnName) {
        if (oneForm['tablename'] == tableName && !that.utils.Base_HasValue(result)) {
          _sh.each(oneForm['columns'], oneColumn => {
            if (oneColumn['column'] == columnName && !that.utils.Base_HasValue(result))
              result = _sh.get(oneColumn, ['model'], null);
            let columnGroup = _sh.get(oneColumn, ['columnGroup'], []);
            _sh.each(columnGroup, oneColumnGroup => {
              if (oneColumnGroup['column'] == columnName && !that.utils.Base_HasValue(result))
                result = _sh.get(oneColumnGroup, ['model'], null);
            })
          });
        }
      }

      let forms = _sh.cloneDeep(that.forms);
      // 如果是模态框，需要用formData 进行替换。
      if (that.utils.Base_HasValue(fromValue)) {
        let tableName = _sh.split(fromValue, '.')[1];
        let columnName = _sh.split(fromValue, '.')[2];

        if (that.isModal) {
          _sh.each([that.formData], oneForm => {
            getInOneForm(oneForm, tableName, columnName);
          });
        } else {
          _sh.each(forms, oneForm => {
            getInOneForm(oneForm, tableName, columnName);
          })
        }
      }
      return result;
    }

    function findChangedColumn(tableName, pre: any, curr: any) {
      let columnList = _sh.keys(pre);
      let changedColumn = [];
      _sh.each(columnList, oneColumn => {
        let preValue = _sh.get(pre, [oneColumn], null);
        let currValue = _sh.get(curr, [oneColumn], null);
        if (preValue != currValue) {
          let columnName = 'SYS.' + tableName + '.' + _sh.trimEnd(oneColumn, '_NAME')
          changedColumn.push(columnName);
        }
      })
      return changedColumn;
    }

    function fillValue(toValue, value) {
      let tableName = _sh.split(toValue, '.')[1];
      let columnName = _sh.split(toValue, '.')[2];

      function fillOneFormValue(oneForm) {

        if (oneForm['tablename'] == tableName) {
          _sh.each(oneForm['columns'], oneColumn => {
            if (oneColumn['column'] == columnName)
              _sh.set(oneColumn, ['model'], value);
            let columnGroup = _sh.get(oneColumn, ['columnGroup'], []);
            _sh.each(columnGroup, oneColumnGroup => {
              if (oneColumnGroup['column'] == columnName)
                _sh.set(oneColumnGroup, ['model'], value);
            })
          });
        }
      }

      if (that.isModal) {
        _sh.each([that.formData], oneForm => {
          fillOneFormValue(oneForm);
        })
      } else {
        _sh.each(that.forms, oneForm => {
          fillOneFormValue(oneForm);
        })
      }
    }

    function doFillback(fillback, sqlValues) {
      _.each(fillback, oneFillback => {
        let fillbackType = _sh.get(oneFillback, ['fillbackType'], "normal");
        if (fillbackType == "normal") {
          let msgValue = {};
          _sh.each(oneFillback['msgValue'], oneMsg => {
            let formValue = _sh.get(oneMsg, ['fromValue']);
            let formValueType = _sh.split(formValue, '.')[0];
            if (formValueType == 'execList') { // 数据来源是 执行列表 。
              let isNull = _sh.get(oneMsg, ['isNull'], null); // 空值处理。
              let findLinkTable = _sh.chain(formValue).split(".").drop().take(1).value(); // 表名
              let findLinkColumn = _sh.chain(formValue).split(".").drop(2).value(); //
              let findLink = _sh.concat(findLinkTable, ['data', 0], findLinkColumn);
              let findValue = _sh.get(sqlValues, findLink, isNull);
              msgValue[oneMsg['column']] = findValue;
            } else if (formValueType == 'SYS') {
              msgValue[oneMsg['column']] = getValue(formValue);
            }
          });
          const msgTemplate = _sh.get(oneFillback, ['msgTemplate'], null); // 格式化模板
          let compiled = _.template(msgTemplate);
          let result = compiled(msgValue); // 通过模板计算的值。
          let msgTemplateType = _sh.get(oneFillback, ['calc'], false); // 格式化模板，是否需要计算。
          if (msgTemplateType) {
            result = eval(result); // 通过模板计算的值。
          }
          fillValue(oneFillback['toValue'], result); // 回填某一个值。
        } else if (fillbackType == "service") {
          let servicePath = _sh.get(oneFillback, ['servicePath'], null);
          that.serviceCenterService.doChangeListAction(that.forms, sqlValues, servicePath);
        }
      });
    }

    function checkValueColumn(valueColumn) {
      let valueKeys = _sh.keys(valueColumn);
      let readyCount = 0;
      _sh.each(valueKeys, oneKey => {
        let value = getValue(oneKey);
        if (that.utils.Base_HasValue(value))
          readyCount++;
      });
      return valueKeys.length == readyCount;
    }

    function doExec(execList, fillback) {
      let requestList = [];
      _sh.each(execList, oneExec => {
        let sqlFilePath = _sh.get(oneExec, ['dictionary'], null);
        let execType = _sh.get(oneExec, ['execType'], 'hulk');
        if (execType == 'hulk') {
          let sqlCondition = {};
          _sh.each(_sh.get(oneExec, ['condition'], []), oneCondition => {
            sqlCondition[oneCondition['column']] = getValue(oneCondition['fromValue'])
          });
          requestList.push(that.requestDataService.getDataByCondition(sqlFilePath, sqlCondition))
        } else if (execType == 'normal') {
          let datas = [];
          _sh.each(_sh.get(oneExec, ['datas'], []), oneColumn => {
            let modalData = that.formData.columns;
            // 模拟一个空值。
            let pParentData = {
              data: {
                data: []
              }
            }
            let value = that.dataCenterService.getSpecialValue(oneColumn['fromValue'], pParentData, modalData, null, null);
            datas[oneColumn['column']] = value;
          });
          let result = {
            "data": [datas]
          }
          const normalSource = Observable.create(function (observer) {
            observer.next(result);
            observer.complete();
          });
          requestList.push(normalSource);
        }
      });
      if (that.utils.Base_HasValue(requestList)) {
        const search = observableForkJoin(
          requestList
        );
        search.subscribe(values => {
          // 执行回填
          doFillback(fillback, values);
        })
      }
    }

    //  监听整个表单
    this._validateForm.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(
        (pre: any, curr: any) => {
          // 写当前被修改了哪些字段。
          let changedColumn = findChangedColumn(this.formData['formname'], pre, curr);
          this.formData['changedColumn'] = changedColumn;
          // console.log("----------------------------");
          // console.log(changedColumn);
          // console.log(pre);
          // console.log(curr);
          // console.log(that.formData.changeTimes);
          // console.log("----------------------------");
          return pre === curr;
        }
      )
    ).subscribe(formGroup => {
      if (!this.utils.Base_HasValue(this.modeType)) {
        this.modeType = _sh.get(this.formData, ['modalModeType'], null);
      }
      if (_.contains(['add', 'modify'], this.modeType)) {
        let hasInitForm = _sh.get(this.formData, ['hasInitForm'], false);
        if (isNaN(that.formData.changeTimes)) that.formData.changeTimes = 0;
        // console.log(hasInitForm);
        if (hasInitForm) {
          let changeList = _sh.get(this.formData, ['changeList'], []);
          _sh.each(changeList, oneChange => {

            if (hasInitForm) {
              that.formData.changeTimes++;
            }
            // 只有满足前置，条件才继续。
            if (doCheck(oneChange['check'])) {
              // 修改字段是触发字段 triggerColumn 字段 , 才继续。
              // 当一些自定义页面是空的时候
              if (that.forms.length == 0) {
                that.forms.push(this.formData);
              }
              let intersection = _sh.intersection(_sh.get(this.formData, ['changedColumn'], []), oneChange['triggerColumn']);
              if (that.utils.Base_HasValue(intersection)) {
                // valueColumn触发值都都不为空，才继续
                if (checkValueColumn(_sh.get(this.formData, ['valueColumn'])) && that.formData.changeTimes > 0) {
                  doExec(oneChange['execList'], oneChange['fillback']); // 执行
                }
              }
            }
          });
        } else {
          _sh.set(this.formData, ['hasInitForm'], true);
        }
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  ngAfterContentInit() {
    this.execChange();
  }

  // 监听 needSearch 主要用于列表页的按钮点击之后刷新列表
  ngDoCheck(): void {
    if (!!this.formData.needInit) {
      this.init();
      this.formData.needInit = false;
    }
  }

  // 帮助文档跳转
  jumpHelpDocument(helpUrl) {
    let id = Md5.hashStr(helpUrl)
    this.dataCenterService.newTab({
      id: id, // 唯一标识
      parentDataId: 'id', // 唯一标识
      nowMenu: 'id', // 菜单ID 用于详情返回
      title: "帮助文档:" + this.formData.title, // 菜单名称 用户tab展示
      formType: 'customForm', // 菜单类型 用于tab过滤显示模板
      custormFormType: 'app-help-document',
      data: {}, // 查询返回数据
      inputData: {
        helpUrl: helpUrl
      }
    });

  }


}
