import {Component, Input, OnInit} from '@angular/core';
import {JumpCenterService} from "../../../service/jump-center.service";
import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";

@Component({
  selector: 'app-descriptions-table',
  templateUrl: './descriptions-table.component.html',
  styleUrls: ['./descriptions-table.component.scss']
})
export class DescriptionsTableComponent implements OnInit {

  @Input() oneTab: any;
  @Input() oneGroup:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() execButton: any;

  conditionColumns: [];

  public allChecked = false;
  public indeterminate = false;

  constructor(
    private utilsService: UtilsService,
    private jumpCenterService: JumpCenterService
  ) { }

  ngOnInit() {
    this.conditionColumns = _sh.get(this.oneGroup,['conditionColumns'],[]);
  }

  doJump(pOneColumn, pData) {
    pData['checked'] = true;
    let simulationParentData = {
      data: {
        data: [pData]
      }
    }
    let readOnlyData = {
      'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
      'aggregateListData': _sh.cloneDeep(this.aggregateListData)
    }
    this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
  }

  doJumpUrl(url){
    window.open(url,'_blank')
  }

  checkAll(value: boolean) {
    this.oneGroup['groupData'].forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus() {
    const validData = this.oneGroup['groupData'].filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }
}
