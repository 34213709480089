import {Component, Input, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import * as _sh from "lodash";

@Component({
    selector: 'app-hulk-config-msg-template',
    templateUrl: './hulk-config-msg-template.component.html',
    styleUrls: ['./hulk-config-msg-template.component.scss']
})
export class HulkConfigMsgTemplateComponent implements OnInit {
    @Input() label: any;
    @Input() oneColumn: any;
    @Input() attributes: any;

    isVisible = false;
    tempJson = {
        msgValueList:[]
    }

    constructor(
        private modalService: NzModalService,
        private requestDataService: RequestDataService,
        private appConfigService: AppConfigService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {

    }

    showModal() {
        this.isVisible = true;
        this.tempJson.msgValueList = [];
        _sh.each(_sh.keys(this.oneColumn['msgValue']), oneKey => {
            this.tempJson.msgValueList.push({
                'column': oneKey,
                'fromValue': this.oneColumn['msgValue'][oneKey]
            });
        })

    }

    removeValueMain() {

    }

    addColumn() {
        this.tempJson.msgValueList.push({
            'column': "ABC",
            'fromValue': "ABC"
        });
    }
}
