import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-graduate-programs',
  templateUrl: './graduate-programs.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './graduate-programs.component.css']
})
export class GraduateProgramsComponent implements OnInit {
  listData: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1 = [];
    if (this.listData.length > 0) {
      _.each(this.listData, oneData => {
        const data = {
          '课程名称': oneData['COURSE_NAME'] || '',
          '总学时': oneData['TOTAL_HOURS'] || 0,
          '总学分': oneData['CREDIT'] || 0,
          '任课教师': oneData['TEACHER_NAME'] || ''
        };
        data1.push(data);
      });
    } else {
      const data = {
        '课程名称': '',
        '总学时': '',
        '总学分': '',
        '任课教师': ''
      };
      data1.push(data);
    }
    const data2 = [
      {
        '课程类型': '基础理论课',
        '课程数': '',
        '总学时': '',
        '总学分': '',
        '任课教师数': ''
      },
      {
        '课程类型': '独立开设的实践性课程',
        '课程数': '',
        '总学时': '',
        '总学分': '',
        '任课教师数': ''
      },
      {
        '课程类型': '与企业联合开设的实践性课程',
        '课程数': '',
        '总学时': '',
        '总学分': '',
        '任课教师数': ''
      },
    ];
    const opts = [
      {
        sheetid: '博士研究生核心学位课（仅博士学位授权点填写）',
        header: true
      },
      {
        sheetid: '硕士研究生课程',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-2-1 开设的研究生主要课程.xlsx",?) FROM ?', [opts, [data1, data2]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_doctoral_program.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
