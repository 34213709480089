import { Component, OnInit } from '@angular/core';
import * as _sh from 'lodash';
import {TableModalComponent} from '../table-modal/table-modal.component';
import {RequestDataService} from '../../service/request-data.service';
import {UtilsService} from '../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {ConfirmModalService} from '../../service/confirm-modal.service';

@Component({
  selector: 'app-column-group',
  templateUrl: './column-group.component.html',
  styleUrls: ['./column-group.component.css']
})
export class ColumnGroupComponent implements OnInit {
  groupData: any = {};
  dataSource: '';
  dataBaseName: '';
  tableName: '';

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService) { }

  disableType = [
    {
      'code': '',
      'name': '不禁用',
    },
    {
      'code': 'add',
      'name': '添加',
    },
    {
      'code': 'modify',
      'name': '修改',
    },
    {
      'code': 'add,modify',
      'name': '添加、修改',
    }
  ];

  //新增字段
  addConditionGroupColumn() {
    this.requestDataService.getTableColumns(this.dataSource, this.dataBaseName, this.tableName).subscribe(rep => {
      if (this.utils.Base_HasValue(rep)) {
        let headTitle = [
          {
            key: 'COLUMN_NAME',
            value: '字段名称'
          }, {
            key: 'COLUMN_COMMENT',
            value: '描述'
          }
        ];
        let tableData = rep;
        if(this.groupData['columnGroup'].length > 0){
          _sh.forEach(tableData, (item) => {
            let findData = _sh.find(this.groupData.columnGroup, (oneData) => {
              return oneData['column'] === item['COLUMN_NAME'];
            });
            if (this.utils.Base_HasValue(findData)) {
              item['checked'] = true;
            }
          });
        }
        this.modalService.create({
          nzTitle: '选择字段',
          nzContent: TableModalComponent,
          nzWidth: 800,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            headTitle: headTitle,
            tableData: tableData
          },
          nzOnOk: (response) => {
            let selectTableData = [];
            selectTableData = _sh.filter(response.tableData, (item) => {
                return item['checked'];
              }
            );
            if (this.utils.Base_HasValue(selectTableData)) {
              for (let oneData of selectTableData) {
                let column = _sh.find(this.groupData['columnGroup'], item => {
                  return item['column'] === oneData['COLUMN_NAME'];
                });
                if(!this.utils.Base_HasValue(column)){
                  this.groupData['columnGroup'].push({
                    "column": oneData['COLUMN_NAME'],
                    "name": oneData['COLUMN_COMMENT'] + '_NAME',
                    "component": "input",
                    "lable": oneData['COLUMN_COMMENT'],
                    "comment": oneData['COLUMN_COMMENT'],
                    "placeholder": '请输入' + oneData['COLUMN_COMMENT'],
                    "fromValue": "",
                    "model": null,
                    "visible": false
                  });
                }
              }
            }
          }
        });
      } else {
        this.confirmModal.show('warning', {
          title: '数据库或表不存在',
        });
      }
    });
  }

  ngOnInit() {
    if(!this.utils.Base_HasValue(this.groupData.columnGroup)){
      this.groupData.columnGroup = [];
    }
  }

}
