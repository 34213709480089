import { Component, OnInit, Input } from '@angular/core';
import {
	FormGroup
} from '@angular/forms';

@Component({
	selector: 'app-input-multi',
	templateUrl: './input-multi.component.html',
	styleUrls: ['./input-multi.component.css']
})
export class InputMultiComponent implements OnInit {

	@Input() hulkForm: FormGroup;
	@Input() hulkColumn: any;
	constructor() { }

	ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
	}

}
