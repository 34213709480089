import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'groupFilter'
})
export class GroupFilterPipe implements PipeTransform {

	/**
	 * [用于form columns 分组过滤 description]
	 * @param  {any[]}    items        [form columns 数组]
	 * @param  {string[]} groupColumns [from groupModel 对应的columns]
	 * @return {any}                   [description]
	 */
  transform(items: any[], groupColumns: string[]): any {
    if (!items || !groupColumns) {
		return items;
	}
	// filter items array, items which match and return true will be
	// kept, false will be filtered out
	return items.filter(item => item.visible && _.contains(groupColumns, item["column"]));
  }

}
