import { Injectable } from '@angular/core';
import * as localForage from "localforage";

@Injectable({
  providedIn: 'root'
})
export class SettingColumnService {

  public settings:any = {};

  constructor() { }

  // 刷新缓存中的setting column
  refreshSettingsInStorage() {
    localForage.setItem("settingColumns", JSON.stringify(this.settings))
  }

  // 获取setting column
  getSettingsInfoStorage() {
  	return localForage.getItem('settingColumns');
  }

  clearSettingsInfoStorage() {
  	this.settings = {};
  	this.refreshSettingsInStorage();
  }
}
