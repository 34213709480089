import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-academic-communication',
  templateUrl: './academic-communication.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './academic-communication.component.css']
})
export class AcademicCommunicationComponent implements OnInit {
  evaluationYear: any;
  hostMeeting: any;
  attendMeeting: any;
  academicExchange: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [
      {
        '计数/项目': '累计',
        '主办、承办国际或全国性学术年会（次）': this.hostMeeting['acount'] || 0,
        '在国际或全国性学术年会上做主题、主旨报告（次）': this.attendMeeting['acount'] || 0,
        '邀请境外专家讲座报告（次）': this.academicExchange['acount'] || 0,
        '资助师生参加国际国内学术交流专项经费（万元）': this.attendMeeting['money'] || 0
      },
      {
        '计数/项目': '年均',
        '主办、承办国际或全国性学术年会（次）': this.hostMeeting['avg'] || 0,
        '在国际或全国性学术年会上做主题、主旨报告（次）': this.attendMeeting['avg'] || 0,
        '邀请境外专家讲座报告（次）': this.academicExchange['avg'] || 0,
        '资助师生参加国际国内学术交流专项经费（万元）': this.attendMeeting['money_avg'] || 0
      }
    ];
    const opts = [
      {
        sheetid: this.evaluationYear - 4 + '~' + this.evaluationYear + '年国际国内学术交流情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("V-1 近5年国际国内学术交流情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_host_meeting_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_attend_meeting_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_academic_exchange_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_attend_meeting_money.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0
      })
    );
    parallel.subscribe(response => {
      this.hostMeeting = response[0]['data'][0];
      this.hostMeeting['avg'] = (this.hostMeeting['acount'] / 5).toFixed(2);
      this.attendMeeting = response[1]['data'][0];
      this.attendMeeting['avg'] = (this.attendMeeting['acount'] / 5).toFixed(2);
      this.academicExchange = response[2]['data'][0];
      this.academicExchange['avg'] = (this.academicExchange['acount'] / 5).toFixed(2);
      this.attendMeeting['money'] = response[3]['data'][0]['money'];
      this.attendMeeting['money_avg'] = (this.attendMeeting['money'] / 5).toFixed(2);
    });
  }

}
