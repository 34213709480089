import {Component, Input, OnInit} from '@angular/core';
import Swiper from 'swiper';
import * as screenfull from 'screenfull';
import * as echarts from 'echarts';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../../service/request-data.service';
import {UtilsService} from '../../../../service/utils.service';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import {AppConfigService} from '../../../../service/app-config.service';

@Component({
  selector: 'app-subject-confer-degree-i',
  templateUrl: './subject-confer-degree-I.component.html',
  styleUrls: ['../subject-visual-report.component.css', './subject-confer-degree-I.component.css']
})
export class SubjectConferDegreeIComponent implements OnInit {

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService) {
  }
  nowDate:any;
  isfull: any;
  swiper: any;
  alaSql: any;
  moment: any;
  teacherData = []; // 教师数据
  internationalTeacher = []; // 外籍教师数据
  areaHeight = '34vh';
  model1CenterHeight = '57vh';
  master5YearCount = [];
  doctor5YearCount = [];
  year = [];
  // 第一屏
  model1: any = {
    left1: {}, // 教师年龄分布情况
    left2: {
      masterTeacher: {}, // 硕导人数饼图
      doctorTeacher: {} // 博导人数饼图
    },
    center: {
      doctorTeacherCount: 0, // 博士学位教师人数
      goingAbroadTeacher: 0, // 出国经历老师人数
      foreignTeacher: 0, // 外籍教师人数
      degreeUnitNo: 0, // 最高学历非本单位授予
      teacherType: {} // 中间下面的空心饼图
    },
    right1: {
      undergraduate: 0,
      masterCount: 0,
      doctorCount: 0,
      master: {}
    },
    right2: {}
  };


  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  // 折线图
  lineChart(data, pType) {
    let color = '#FF9B60';
    if (pType === 'master') {
      color = '#08E87F';
    }
    const option = {
      grid: {
        left: '4%',
        top: '8%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: data.year,
        axisLabel: {
          color: '#98b5ff'
        }
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#303743'
          }
        },
        axisLabel: {
          color: '#98b5ff',
          formatter: '{value}'
        }
      },
      series: [
        {
          type: 'line',
          data: data.code,
          smooth: true,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}个'
            }
          },
          lineStyle: {
            width: 5,
            color: color
          },
        }
      ]
    };
    return option;
  }

  // 南丁格尔图
  florenceNightingaleFigure(data) {
    let teacherCount = 0;
    for (let oneData of data) {
      teacherCount += oneData['value'];
    }
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>{c}人',
        right: 0
      },
      graphic: [{
        type: 'text',
        left: 'center',
        top: '40%',
        style: {
          text: '教师总人数' + '\n' + teacherCount + '人',
          textAlign: 'center',
          fill: '#8995CB',
          width: 30,
          height: 30,
          fontSize: 30
        }
      }],
      series: [
        {
          type: 'pie',
          radius: ['48%', '60%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            normal: {
              // 定制显示（按顺序）
              color: function (params) {
                let colorList = ['#F6465A', '#02EC7E', '#0965E3', '#0CC4FF', '#FDB628', '#02EC7E', '#0965E3', '#0CC4FF', '#FDB628'];
                return colorList[params.dataIndex];
              }
            },
          },
          label: {
            normal: {
              show: true,
              position: 'outside',
              formatter: '{a|{b}}\n{b|{c}人}',
              rich: {
                a: {
                  fontWeight: 'bolder',
                  fontSize: 19,
                  lineHeight: 30,
                  align: 'center'
                },
                b: {
                  fontWeight: 'bolder',
                  fontSize: 26,
                  align: 'center'
                }
              }
            }
          },
          labelLine: {
            normal: {
              show: true,
              length: 30,
              length2: 50
            }
          },
          data: data
        }
      ]
    };
    return option;
  }

  // 空心饼图
  hollowPieChart(data) {
    const option = {
      grid: {
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}人'
      },
      color: ['#4372c5', '#fcc206'],
      series: [
        {
          type: 'pie',
          radius : '55%',
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          data: data.data
        }
      ]
    };
    return option;
  }

  // 柱状图
  histogram(data) {
    const option = {
      grid: {
        left: '8%',
        top: '10%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.name,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
            color: '#98b5ff',
            margin: 10
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLabel: {
            color: '#98b5ff'
          }
        }
      ],
      series: [
        {
          barMaxWidth: 20,
          type: 'bar',
          color: '#ffffff',
          itemStyle: {
            normal: {
              color: function (params) {
                let colorList = ['#266BFB', '#F66791', '#6A66E4', '#FDB628', '#00EE7E', '#0CC4FF', '#F74C5A'];
                return colorList[params.dataIndex];
              }
            }
          },
          data: data.code,
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#BFCBFF',
              formatter: '{c}个'
            }
          }
        }
      ]
    };
    return option;
  }

  // 屏1中间上面的教师人数
  teacherCount() {
    function teacherTypeCount(pCondition, teacherList) {
      const alaSql = require('alasql');
      // tslint:disable-next-line:max-line-length
      return alaSql('SELECT count(*) acount FROM ? WHERE ' + pCondition, [teacherList]);
    }

    // 左1
    _.each(this.teacherData, oneData => {
      if (this.utils.Base_HasValue(oneData['TEACHER_BIRTHDAY'])) {
        oneData['AGE'] = this.moment().diff(oneData['TEACHER_BIRTHDAY'], 'years');
      }
    });
    const age35 = teacherTypeCount(' AGE <= 35 ', this.teacherData);
    const age36 = teacherTypeCount(' AGE >= 36 AND AGE <= 40', this.teacherData);
    const age41 = teacherTypeCount(' AGE >= 41 AND AGE <= 45', this.teacherData);
    const age46 = teacherTypeCount(' AGE >= 46 AND AGE <= 50', this.teacherData);
    const age51 = teacherTypeCount(' AGE >= 51 AND AGE <= 55', this.teacherData);
    const age56 = teacherTypeCount(' AGE >= 56 AND AGE <= 60', this.teacherData);
    const age61 = teacherTypeCount(' AGE >= 61', this.teacherData);
    const doctor = teacherTypeCount('TUTOR_CATEGORY = "博导"', this.teacherData);
    const master = teacherTypeCount('TUTOR_CATEGORY = "硕导"', this.teacherData);
    const totalCount = teacherTypeCount('1 = 1', this.teacherData);
    const data: any = {
      name: ['35岁以下', '35岁-40岁', '41岁-45岁', '46岁-50岁', '51岁-55岁', '56岁-60岁', '61岁以上'],
      code: [age35[0].acount, age36[0].acount, age41[0].acount, age46[0].acount, age51[0].acount, age56[0].acount, age61[0].acount]
    };
    this.model1.left1 = this.histogram(data);

    // 硕导人数
    const masterCount = {
      name: '硕导人数',
      data: [
        {
          value: master[0].acount, name: '硕导', label: {
            normal: {
              show: true,
              position: 'outside',
              fontWeight: 'bold',
              fontSize: '14',
              formatter: '{b}:{c}人'
            }
          }
        },
        {value: doctor[0].acount , name: '博导', label: {
            normal: {
              show: true,
              position: 'outside',
              fontWeight: 'bold',
              fontSize: '14',
              formatter: '{b}:{c}人'
            }
          }}
      ]
    };
    this.model1.left2 = this.hollowPieChart(masterCount);

    // 中间上部数据
    const teacherCount = this.alaSql('select sum(case TEACHER_MAX_DEGREE when "博士学位" then 1 else 0 end) DOCTOR_COUNT,' +
      'sum(case VISITING_ABROAD when "是" then 1 else 0 end) VISITING_ABROAD_COUNT,' +
      'sum(case IS_UNIT when "否" then 1 else 0 end) IS_UNIT_COUNT from ?', [this.teacherData]);
    this.model1.center.doctorTeacherCount = teacherCount[0].DOCTOR_COUNT;
    this.model1.center.goingAbroadTeacher = teacherCount[0].VISITING_ABROAD_COUNT;
    this.model1.center.degreeUnitNo = teacherCount[0].IS_UNIT_COUNT;
    this.model1.center.foreignTeacher = this.internationalTeacher.length;

    // 中间下面教师职称人数
    const jobTitle = this.alaSql('select count(*) acount, JOB_TITLE from ? group by JOB_TITLE', [this.teacherData]);
    const jobTitleCount = [];
    _.each(jobTitle, oneData => {
      if (this.utils.Base_HasValue(oneData['JOB_TITLE'])) {
        const jobData = {
          value: 0,
          name: '',
        };
        jobData.value = oneData['acount'];
        jobData.name = oneData['JOB_TITLE'];
        jobTitleCount.push(jobData);
      }
    });
    this.model1.center.teacherType = this.florenceNightingaleFigure(jobTitleCount);


    const masterYearCount = {
      year: this.year,
      value: {},
      code: []
    };
    _.each(this.master5YearCount, oneData => {
      masterYearCount.value[oneData['INCOME_SCHOOL_YEAR'] + ''] = oneData['ACOUNT'];
      this.model1.right1.masterCount += oneData['ACOUNT'];
    });
    const doctorYearCount = {
      year: this.year,
      value: {},
      code: []
    };
    _.each(this.doctor5YearCount, oneData => {
      doctorYearCount.value[oneData['INCOME_SCHOOL_YEAR'] + ''] = oneData['ACOUNT'];
      this.model1.right1.doctorCount += oneData['ACOUNT'];
    });
    _.each(this.year, item => {
      masterYearCount.code.push(masterYearCount.value[item + ''] || 0);
      doctorYearCount.code.push(doctorYearCount.value[item + ''] || 0);
    });
    this.model1.right1.master = this.lineChart(masterYearCount, 'master');
    this.model1.right2 = this.lineChart(doctorYearCount, 'normal');
  }

  ngOnInit() {
    this.alaSql = require('alasql');
    this.moment = require('moment');
    this.nowDate = this.moment().format('YYYY-MM-DD HH:mm:ss');
    setInterval(() => {
      this.nowDate = this.moment().format('YYYY-MM-DD HH:mm:ss');
    },1000);
    const nowYear = this.moment().format('YYYY');
    this.year = [nowYear - 4, nowYear - 3, nowYear - 2, nowYear - 1, nowYear - 0];
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_teacher.json'),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_teacher_international.json'),
      this.requestDataService.getDataByCondition('subjectInfo/report/undergraduate_5year_count.json'),
      this.requestDataService.getDataByCondition('subjectInfo/report/master_5year_count.json'),
      this.requestDataService.getDataByCondition('subjectInfo/report/doctor_5year_count.json'),
    );
    parallel.subscribe(response => {
      this.teacherData = response[0]['data'];
      this.internationalTeacher = response[1]['data'];
      this.model1.right1.undergraduate = response[2]['data'][0].acount;
      this.master5YearCount = response[3]['data']; // 近五年硕士生招生人数
      this.doctor5YearCount = response[4]['data']; // 近五年博士生招生人数
      this.teacherCount(); // 屏1数据处理
    });
  }
}
