import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {HttpClient} from '@angular/common/http';
import * as XLSX from 'xlsx';
import {UploadXHRArgs} from "ng-zorro-antd";
import {AppConfigService} from "../../service/app-config.service";
import {UploadService} from "../../service/upload.service";
import {RequestDataService} from "../../service/request-data.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {DataCenterService} from "../../service/data-center.service";
import * as _ from 'lodash';
import PubSub from 'pubsub-js';
import {UtilsService} from "../../service/utils.service";

@Component({
    selector: 'app-loading-expenditure',
    templateUrl: './loading-expenditure.component.html',
    styleUrls: ['./loading-expenditure.component.scss'],
})
export class loadingExpenditureComponent implements OnInit {
    @Input() parentData: any;

    constructor(
        private confirmModal: ConfirmModalService,
        private requestService: RequestDataService,
        private message: NzMessageService,
        private dataCenterService: DataCenterService,
        private http: HttpClient,
        private utils: UtilsService,
        private appConfigService: AppConfigService,
        private uploadService: UploadService,
        private msg: NzMessageService,
    ) {
    }

    filterError: any;
    public fileList: any = [];
    public importState: boolean = false;//false表示未开始导入
    public fileUrl: string = null;
    public filterErr: boolean = false;// 初始状态为过滤
    public dataSetFilter: any = [];// 过滤后的数据
    public modeType: any; // trans 运输收入结算导入; loadUnload 装卸收入结算导入;
    //一开始请求到的数据
    public dataSet: any = [];

    beforeUpload = (file: File) => {
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXLSX) {
            this.msg.error('You can only upload xlsx file!');
        }
        return isXLSX;
    }

    customReq = (item: UploadXHRArgs) => {
        this.importState = true;
        if (this.appConfigService.config['userOssOrCos'] === 'COS') {
            return this.uploadService.doCosUpload(item.file, item['progress'], null, null, null).then((result) => {
                item.onSuccess({}, item.file, event);
                this.fileUrl = this.appConfigService.config['COS']['domain'] + '/' + result['url'];
                this.getImportData();
            });
        } else {
            return this.uploadService.doAliOssUpload(item.file, item['progress'], null).then((result) => {
                item.onSuccess({}, item.file, event);
                this.fileUrl = this.appConfigService.config['OSS']['domain'] + '/' + result.name;
                this.getImportData();
            });
        }
    }

    async getImportData() {
        if (!this.utils.Base_HasValue(this.fileUrl)) {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': '请先选择Excel文件。'});
            return;
        }
        let res = null;
        if (this.modeType === 'loadUnload') {
            res = await this.requestService.doActionByHdbsthor('AppApi/LAndUExpenditureController', 'verificationOfLoadingAndUnloadingRevenue', {fileUrl: this.fileUrl}, null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        } else if (this.modeType === 'trans') {
            res = await this.requestService.doActionByHdbsthor('AppApi/MsTranOrdersController', 'verificationOfTransRevenue', {fileUrl: this.fileUrl}, null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        }
        this.dataSet = res;// 这是请求到的数据
        this.dataSetFilter = this.dataSet;//表格数据，一开始就给全部数据到表格
    }

    async verifyClicked() {
        let response = null;
        if (this.modeType === 'loadUnload') {
            // 处理核对按钮的逻辑
             response = await this.requestService.doActionByHdbsthor('AppApi/LAndUExpenditureController', 'check', {fileUrl: this.fileUrl}, null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        } else if (this.modeType === 'trans') {
             response = await this.requestService.doActionByHdbsthor('AppApi/MsTranOrdersController', 'check', {fileUrl: this.fileUrl}, null, false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
        }

        if (response == true) {
            this.confirmModal.show('success', {'title': '执行成功'});
            this.closePage();
        } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
    }

    closePage() {
        let menuId = _.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
        if (this.utils.Base_HasValue(menuId)) {
            PubSub.publish('listRefresh', menuId);
        }
        this.dataCenterService.closeTab(this.parentData);
    }

    downloadTemplate() {
        if (this.modeType === 'loadUnload') {
            window.location.href = `${this.appConfigService.config.NodeJsServer}/JieLiv2/import/装卸收入结算导入模版V1.0_20230815.xlsx`;
        } else if (this.modeType === 'trans') {
            window.location.href = `${this.appConfigService.config.NodeJsServer}/JieLiv2/import/运输收入结算导入模版V1.0_20230829.xlsx`;
        }
    }


    ngOnInit() {
        this.modeType = _.get(this.parentData, ['modeType'], 'loadUnload');
    }

    //过滤器
    filterMatches(array) {
        if (this.filterErr) {
            this.dataSetFilter = array.filter(item => item.matches !== "不相符");
        } else {
            this.dataSetFilter = this.dataSet;
        }
    }


    filterErrorData() {
        this.filterErr = !this.filterErr;
        this.filterMatches(this.dataSet);//使用请求的数据过滤到表格
    }

}
