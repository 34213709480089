import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'lodash';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataService} from '../data.service';
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-group-user-management',
  templateUrl: './group-user-management.component.html',
  styleUrls: ['./group-user-management.component.css']
})
export class GroupUserManagementComponent implements OnInit {
  @Input() parentData: any;
  userList = [];
  checkedAll = false;
  groupId = '';
  groupUser = [];
  searchName = '';

  constructor(private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private utils: UtilsService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService) {
  }

  transformData(parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = 'listForm';
    parentData.actionArray = [];
    parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  changeSelected(pObject) {
    if (!pObject['selected']) {
      this.checkedAll = false;
    }
  }

  // 返回
  gotoBack(): void {
    this.transformData(this.parentData);
  }

  // 全选
  selectedAll() {
    _.each(this.userList, oneData => {
      oneData['selected'] = this.checkedAll;
    });
  }

  doSave() {
    const data: any = {
      GROUP_ID: this.groupId,
      userList: []
    };
    _.each(this.userList, item => {
      if (item['selected']) {
        data.userList.push({USER_ID: item['USER_ID']});
      }
    });
    if (data.userList.length < 1) {
      return this.confirmModal.show('warning', {title: '请选择用户'});
    }
    this.dataService.requestQuickSliver('/digital/saveGroupUser', data).subscribe(response => {
      if (response['state'] === 'success') {
        this.confirmModal.show('success', {title: '保存成功'});
        this.gotoBack();
      }
    });
  }

  ngOnInit() {
    this.groupId = _.find(this.parentData.data.data, oneData => {
      return oneData['checked'];
    })['ID'];
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/system/system_user_list.json', {
        USER_STATE: 'PASS',
        IN_BLACKLIST: 0
      }),
      this.requestData.getDataByCondition('digitalResources/dr/dr_group_user.json', {GROUP_ID: this.groupId}),
    );
    search.subscribe(
      values => {
        this.userList = values[0]['data'] || [];
        this.groupUser = values[1]['data'] || [];
        _.each(this.groupUser, group => {
          _.each(this.userList, user => {
            if (group['USER_ID'] === user['USER_ID']) {
              user['selected'] = true;
            }
          });
        });
      }
    );
  }

}
