import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppConfigService} from '../../../service/app-config.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

import * as _ from 'underscore';

@Component({
  selector: 'app-multi-pop',
  templateUrl: './multi-pop.component.html',
  styleUrls: ['./multi-pop.component.css']
})
export class MultiPopComponent implements OnInit {
  @Input() hulkColumn: any;

  @Output('selectModel') childFun = new EventEmitter();

  // 改变绑定对象
  @Input() bindModel: any;

  // 是否显示弹框
  public isVisible = false;

  @ViewChild('childForm', {static: false}) childForm;

  // 绑定当前table的数组数据
  public dataSet: any = [];
  public totalDataSet: any = [];

  // 当前table的表头
  public showColumns: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };

  // 查询数据
  public conditionList: any[] = [];

  public allChecked = false;
  public indeterminate = false;
  public itemArray: any = [];

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService) {
  }

  // checkbox 选中或未选中之后刷新总checkbox的状态
  refreshStatus(data?): void {
    const validData = this.dataSet.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  // 将total选中数据和搜索彀中数据合并
  mergeTotalSelect() {
    _.each(this.totalDataSet, total => {
      const current = _.find(this.dataSet, data => total['code'] === data['code']);
      if (current) {
        total['checked'] = current['checked'];
      }
    });
  }

  checkAll(value: boolean): void {
    this.dataSet.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
    this.mergeTotalSelect();
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  searchFilter() {
    this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
    let condition = {};
    _.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    this.searchData(condition);
  }

  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  // 查询后的数据进行封装
  transformResult(data) {
    let dataSet = data.data || [];
    // 如果是修改。则之前选的数据要选中
    if (this.bindModel) {
      if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
        _.each(dataSet, set => {
          if (this.bindModel[this.hulkColumn.column].indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    } else {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        _.each(dataSet, set => {
          if (this.hulkColumn.model.indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    }

    this.dataSet = dataSet;
    this.refreshStatus();
    this.showColumns = data.showColumns.columns || [];
  }

  // 将total选中数据和搜索彀中数据合并
  mergeDataSelect() {
    _.each(this.dataSet, data => {
      const current = _.find(this.totalDataSet, total => total['code'] === data['code']);
      if (current) {
        data['checked'] = current['checked'];
      }
    });
  }

  searchData(condition?: {}): void {
    this.requestDataService.getDataByCondition(this.hulkColumn.dictionary, condition || {}).subscribe((data: any) => {
      this.transformResult(data);
      this.mergeDataSelect();
    });
  }

  showModal() {
    const parallel$ = observableForkJoin(
      this.requestDataService.getDataByCondition(this.hulkColumn.dictionary, {}),
      this.requestDataService.getConditionData(this.hulkColumn.dictionary)
    );

    parallel$.subscribe(
      values => {
        let dataObject = values[0];
        let itemData = dataObject['data'] || [];
        if (this.utils.Base_HasValue(itemData)) {
          if (this.utils.Base_HasValue(this.itemArray)) {
            _.each(this.itemArray, (item) => {
              let oneData = _.find(itemData, (value) => {
                return item['code'] === value['code'];
              });
              if (this.utils.Base_HasValue(oneData)) {
                itemData = _.without(itemData, oneData);
              }
            });
            this.itemArray = this.itemArray.concat(itemData);
          } else {
            this.itemArray = itemData;
          }
        }
        this.conditionColumns = values[1]['conditionColumns'][0] || {};
        this.conditionColumns.needInit = true;
        this.transformResult(dataObject);
        this.isVisible = true;
        // 缓存所有数据
        this.totalDataSet = this.utils.DeepCopy(this.dataSet);
      }
    );
  }

  removeValue() {
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = null;
      this.bindModel[this.hulkColumn.column + 'Name'] = '';
    } else {
      this.hulkColumn.model = null;
      this.hulkColumn.value = '';
    }
    this.childFun.emit({
      modelList: []
    });
  }


  // 多选点击确定按钮事件
  selectMulti() {
    let modelList = [];
    let nameList = [];
    let choiceObjects = [];
    _.each(this.totalDataSet, (item) => {
      if (item['checked']) {
        modelList.push(item['code']);
        nameList.push(item['name']);
        choiceObjects.push(item);
      }
    });
    if (this.hulkColumn.limitLength && modelList.length > this.hulkColumn.limitLength) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '最多选择' + this.hulkColumn.limitLength + '条数据'
      });
      return;
    }
    if (this.hulkColumn.component === 'multipopCondition') {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList;
      } else {
        this.hulkColumn.model = modelList;
      }
    } else {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList.join(',');
      } else {
        this.hulkColumn.model = modelList.join(',');
      }
    }
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join(' ');
    } else {
      this.hulkColumn.value = nameList.join(' ');
    }
    if (this.hulkColumn.columnGroup) {
      _.each(this.hulkColumn.columnGroup, oneGroup => {
        let fromValue = oneGroup['fromValue'];
        let values = [];
        _.each(choiceObjects, item => {
          values.push(item[fromValue]);
        });
        oneGroup['model'] = values.join(';');
      });
    }
    this.isVisible = false;
    this.childFun.emit({
      modelList: _.filter(this.dataSet, item => item['checked'])
    });
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 6;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 18;
  }

  // 监听model值得改变
  ngDoCheck(): void {
    if (this.bindModel) {
      if (this.bindModel[this.hulkColumn.column] !== this.bindModel['old' + this.hulkColumn.column]) {
        if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
          if (this.itemArray && this.itemArray.length) {
            let nameList = [];
            _.each(this.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_.contains(this.bindModel[this.hulkColumn.column], item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.bindModel[this.hulkColumn.column].indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join(' ');
          }
        }
        this.bindModel['old' + this.hulkColumn.column] = this.bindModel[this.hulkColumn.column];
      }
    } else {
      if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
          if (this.itemArray && this.itemArray.length) {
            let nameList = [];
            _.each(this.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_.contains(this.hulkColumn.model, item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.hulkColumn.model.indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.hulkColumn.value = nameList.join(' ');
          }
        }
        this.hulkColumn.oldModel = this.hulkColumn.model;
      }
    }
  }
}
