import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import * as _ from 'lodash';
import {UEditorComponent} from "ngx-ueditor";
declare const UE: any;

@Component({
  selector: 'app-input-service-describe',
  templateUrl: './input-service-describe.component.html',
  styleUrls: ['./input-service-describe.component.css']
})
export class InputServiceDescribeComponent implements OnInit {

  @Input() oneComponent: any;
  @Input() componentIndex: any;
  @Input() oneSmallGroup: any;
  @Input() smallGroupIndex: any;
  @Input() groupIndex: any;
  @Input() serviceData: any;
  componentModalVisible: any;
  componentDatas = {};

  radioTypeList = [
    {
      "label": "普通",
      "value": "normal",
    },
    {
      "label": "带输入框",
      "value": "input",
    }
  ]

  radioWidth = [
    {
      "label": "1/3",
      "value": 8,
    },
    {
      "label": "1/2",
      "value": 12,
    },
    {
      "label": "2/3",
      "value": 16,
    },
    {
      "label": "1行",
      "value": 24,
    },
  ]

  pubItLastComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].push(pOneComponent);
  }

  pubItFirstComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].unshift(pOneComponent);
  }

  drop(small_, event: CdkDragDrop<string[]>): void {
    moveItemInArray(small_, event.previousIndex, event.currentIndex);
  }

  deleteOption(pOptionList, pOneOption) {
    pOptionList['list'] = _.without(pOptionList['list'], pOneOption);
  }

  public addOption(pOptionList) {
    pOptionList['list'].push({
      "label": "新选项",
      "type": "normal",
      "nzSpan": 24,
      "model": null
    })
  }

  componentCancel() {
    this.componentModalVisible = false;
  }

  componentEdit(pOneComponent) {
    this.componentModalVisible = true;
    this.componentDatas = pOneComponent;
  }

  /**
   * 删除组件
   * @param pOneSmallGroup
   * @param pOneComponents
   */
  public deleteComponents(pOneSmallGroup, pOneComponents) {
    pOneSmallGroup['components'] = _.without(pOneSmallGroup['components'], pOneComponents);
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  _ready(event) {
    setInterval(() => {
      if (event.Instance) {
        event.Instance.setHeight(400);
      }
    }, 10);
  }

  onPreReady(comp: UEditorComponent) {
    UE.registerUI('autoclearstyle', function (editor, uiName) {
      //注册按钮执行时的command命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand: function () {
        }
      });

      //创建一个button
      var btn = new UE.ui.Button({
        //按钮的名字
        name: "autoclearstyle",
        //提示
        title: "自动生成样式",
        //添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-position: -200px 0;',
        //点击时执行的命令
        onclick: function () {
          editor.execCommand('autotypeset');
          editor.execCommand('selectall');//选中所有
          editor.execCommand('removeformat');//清除样式
          editor.execCommand('unlink');//清除链接
          editor.execCommand('fontfamily', '微软雅黑');
          editor.execCommand('rowspacing', 15, 'top');//上间距
          editor.execCommand('rowspacing', 25, 'bottom');//下间距
          editor.execCommand('lineheight', 2);
          editor.execCommand('indent');//首行缩进
        }
      });
      //当点到编辑内容上时，按钮要做的状态反射
      editor.addListener('selectionchange', function () {
        var state = editor.queryCommandState(uiName);
        if (state == -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });
      //因为你是添加button,所以需要返回这个button
      return btn;
    }, 5, comp.id);

    // comp.id 是指当前UEditor实例Id
  }

  constructor() {
  }

  ngOnInit() {
  }


}
