import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as alasql from 'alasql';

@Component({
  selector: 'app-science-innovation',
  templateUrl: './science-innovation.component.html',
  styleUrls: ['./science-innovation.component.css']
})
export class ScienceInnovationComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService) {
  }

  export() {
    const data1: any = [
      {
        '国家级科技创新基地数': this.listData[0]['COUNTRY_SCIENCE'] || 0,
        '省部级科技创新基地数': this.listData[0]['PROVINCE_SCIENCE'] || 0,
      }
    ];
    const opts = [
      {
        sheetid: ' V-2-1 科技创新基地情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX(" V-2-1 科技创新基地情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_science_innovation.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
