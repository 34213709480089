import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import * as _un from "underscore";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
import {NzModalService} from "ng-zorro-antd";
import {UserInfoService} from "../../../service/user-info.service";

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit {

  @Input() parentData: any;
  initDispCode = null;
  dispId = null;
  ordersList: any[];
  baseForm: any = {
    "type": "normal",
    "columns": [
      {
        "column": "DISP_ID",
        "name": "DISP_ID_NAME",
        "component": "input",
        "lable": "调度单号",
        "comment": "调度单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入调度单号",
        "model": null,
        "visible": false,
        "require": false
      },
      {
        "column": "DISP_CODE",
        "name": "DISP_CODE_NAME",
        "component": "input",
        "lable": "调度单号",
        "comment": "调度单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入调度单号",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "EXCESSIVE_AUDIT",
        "name": "EXCESSIVE_AUDIT_NAME",
        "component": "input",
        "lable": "超标状态",
        "comment": "超标状态",
        "placeholder": "请输入超标状态",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": false,
        "require": false
      },
      {
        "column": "DISP_STATE",
        "name": "DISP_STATE",
        "component": "input",
        "lable": "DISP_STATE",
        "comment": "DISP_STATE",
        "placeholder": "请输入DISP_STATE",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": false,
        "require": false
      },
      {
        "column": "DISP_DELIVER_ADDR",
        "name": "DISP_DELIVER_ADDR_NAME",
        "component": "poplist",
        "lable": "发货从属区域",
        "comment": "发货从属区域",
        "placeholder": "请输入发货从属区域",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_REGION.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_RECEIPT_ADDR",
        "name": "DISP_RECEIPT_ADDR_NAME",
        "component": "poplist",
        "lable": "收货从属区域",
        "comment": "收货从属区域",
        "placeholder": "请输入收货从属区域",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_REGION.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_QUANTITY",
        "name": "DISP_QUANTITY_NAME",
        "component": "input",
        "lable": "总数量(件)",
        "comment": "总数量(件)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总数量(件)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_VOLUME",
        "name": "DISP_VOLUME_NAME",
        "component": "input",
        "lable": "总体积(立方米)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "总体积(立方米)",
        "placeholder": "请输入总体积(立方米)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_WEIGHT",
        "name": "DISP_WEIGHT_NAME",
        "component": "input",
        "lable": "总重量(吨)",
        "comment": "总重量(吨)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总重量(吨)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_PLAN_DEPART",
        "name": "DISP_PLAN_DEPART_NAME",
        "component": "date",
        "lable": "计划发车时间",
        "comment": "计划发车时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入计划发车时间",
        "model": null,
        "visible": true,
        "require": true
      },
      {
        "column": "DISP_D_ARRDATE",
        "name": "DISP_D_ARRDATE_NAME",
        "component": "date",
        "lable": "要求到达日期",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "要求到达日期",
        "placeholder": "请输入要求到达日期",
        "model": null,
        "visible": true,
        "require": true
      },
      {
        "column": "DISP_BORROW",
        "name": "DISP_BORROW_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "已借支金额(元)",
        "type": "number",
        "comment": "已借支金额(元)",
        "placeholder": "请输入已借支金额(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_ACCOUNT",
        "name": "DISP_ACCOUNT_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "已结算金额(元)",
        "type": "number",
        "comment": "已结算金额(元)",
        "placeholder": "请输入已结算金额(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_LOCK_MONEY",
        "name": "DISP_LOCK_MONEY_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "锁定金额(元)",
        "type": "number",
        "comment": "锁定金额(元)",
        "placeholder": "请输入锁定金额(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_PRE_TRANSFEE",
        "name": "DISP_PRE_TRANSFEE_NAME",
        "component": "input",
        "lable": "预付金额(元)",
        "type": "number",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "预付金额(元)",
        "placeholder": "请输入预付金额(元)",
        "model": 0,
        "visible": true,
        "require": false
      },
      {
        "column": "DISP_TOTALSFEE",
        "name": "DISP_TOTALSFEE_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "总运费(元)",
        "comment": "总运费(元)",
        "type": "number",
        "placeholder": "请输入总运费",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_TOTALSFEE_GUIDE",
        "name": "DISP_TOTALSFEE_GUIDE_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "运费指导价",
        "comment": "运费指导价",
        "type": "number",
        "placeholder": "运费指导价",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_TRANSFEE",
        "name": "DISP_TRANSFEE_NAME",
        "component": "input",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "lable": "运费(元)",
        "comment": "运费(元)",
        "type": "number",
        "placeholder": "请输入运费(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_DELIVERY_GUIDE",
        "name": "DISP_DELIVERY_GUIDE_NAME",
        "component": "input",
        "lable": "卸车费指导价(元)",
        "type": "number",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "卸车费指导价(元)",
        "placeholder": "请输入卸车费指导价(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_DELIVERY",
        "name": "DISP_DELIVERY_NAME",
        "component": "input",
        "lable": "卸车费(元)",
        "type": "number",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "卸车费(元)",
        "placeholder": "请输入卸车费(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_INFO_PRICE",
        "name": "DISP_INFO_PRICE_NAME",
        "component": "input",
        "lable": "信息费(元)",
        "comment": "信息费(元)",
        "type": "number",
        "placeholder": "请输入信息费(元)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_OTHER",
        "name": "DISP_OTHER_NAME",
        "component": "input",
        "lable": "其他费用(元)",
        "type": "number",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "其他费用(元)",
        "placeholder": "请输入其他费用(元)",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "CUST_ID",
        "name": "CUST_ID_CARRIER_NAME",
        "component": "poplist",
        "lable": "承运商",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "承运商",
        "placeholder": "请输入承运商",
        "model": null,
        "visible": true,
        "require": true,
        "disable": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_CARRIER.json",
        "dictionaryPF": null
      },
      {
        "column": "DR_ID",
        "name": "DR_ID_NAME",
        "component": "poplist",
        "lable": "司机",
        "comment": "司机",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入司机",
        "model": null,
        "visible": true,
        "require": true,
        "disable": false,
        "dictionary": "JieLiv2/ms/driverTruck/driver_truck_list.json",
        "dictionaryPF": null,
        "columnGroup": [
          {
            "column": "TRUCK_ID",
            "name": "TRUCK_ID_NAME",
            "component": "input",
            "lable": "ID",
            "comment": "ID",
            "placeholder": "请选择ID",
            "fromValue": "TRUCK_ID",
            "model": null,
            "visible": false,
            "require": true
          },
          {
            "column": "TRUCK_CODE",
            "name": "TRUCK_CODE_NAME",
            "component": "input",
            "lable": "车牌号",
            "fromValue": "TRUCK_CODE",
            "comment": "车牌号",
            "placeholder": "请输入车牌号",
            "model": null,
            "visible": true,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          },
          {
            "column": "DRIVER_MOBILE",
            "name": "DRIVER_MOBILE_NAME",
            "component": "input",
            "lable": "联系方式",
            "fromValue": "DRIVER_MOBILE",
            "comment": "联系方式",
            "placeholder": "请输入联系方式",
            "model": null,
            "visible": true,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          }
        ]
      },
      {
        "column": "DISP_SUPERVISOR",
        "name": "DISP_SUPERVISOR_NAME",
        "component": "poplist",
        "lable": "监装人员",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "监装人员",
        "placeholder": "请输入监装人员",
        "model": null,
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/ms/tranDispatchJz/ms_jz_data_main.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_TRACK_USER_A",
        "name": "DISP_TRACK_USER_A_NAME",
        "component": "poplist",
        "lable": "跟踪人员A",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "跟踪人员A",
        "placeholder": "请输入跟踪人员A",
        "model": null,
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/ms/tranDispatchGz/ms_gz_data_main.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_TRACK_USER_B",
        "name": "DISP_TRACK_USER_B_NAME",
        "component": "poplist",
        "lable": "跟踪人员B",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "跟踪人员B",
        "placeholder": "请输入跟踪人员B",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/ms/tranDispatchGz/ms_gz_data_main.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_TEXT",
        "name": "DISP_TEXT_NAME",
        "component": "textarea",
        "lable": "备注",
        "comment": "备注",
        "placeholder": "请输入备注",
        "class": "col-xs-12",
        "visible": true,
        "require": false
      }
    ]
  };
  conditionColumns: any = {
    "type": "normal",
    "columns": [
      {
        "column": "SE_TRANS_PAY",
        "name": "SE_TRANS_PAY_NAME",
        "component": "input",
        "lable": "运费",
        "comment": "运费",
        "placeholder": "请输入运费",
        "fromValue": "SE_TRANS_PAY",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "SE_UNLOAD_PAY",
        "name": "SE_UNLOAD_PAY_NAME",
        "component": "input",
        "lable": "卸车费",
        "comment": "卸车费",
        "placeholder": "请输入卸车费",
        "fromValue": "SE_UNLOAD_PAY",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "SE_INFO_PAY",
        "name": "SE_INFO_PAY_NAME",
        "component": "input",
        "lable": "信息费",
        "comment": "信息费",
        "placeholder": "请输入信息费",
        "fromValue": "SE_INFO_PAY",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "SE_OTHER_PAY",
        "name": "SE_OTHER_PAY_NAME",
        "component": "input",
        "lable": "其它费用",
        "comment": "其它费用",
        "placeholder": "请输入其它费用",
        "fromValue": "SE_OTHER_PAY",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "TRAN_CUST_CODE",
      "title": "客户订单号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "CUST_ID_DICT",
      "title": "客户名称",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_TRAN_PAY_GUIDE",
      "title": "运费指导价",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_TRANS_PAY",
      "title": "运费",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_UNLOAD_PAY_GUIDE",
      "title": "卸车费指导价",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_UNLOAD_PAY",
      "title": "卸车费",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_INFO_PAY",
      "title": "信息费",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SE_OTHER_PAY",
      "title": "其它费用",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DISP_TOTALSFEE",
      "title": "总运费",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DISP_VOLUME",
      "title": "总体积(立方米)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DISP_WEIGHT",
      "title": "总吨位(吨)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DISP_QUANTITY",
      "title": "总件数(件)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "TRAN_B_REGION_DICT",
      "title": "发货区域",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "TRAN_E_REGION_DICT",
      "title": "收货区域",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "TRAN_LONG",
      "title": "公里",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SC_ID_DICT",
      "title": "发货节点",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "RC_ID_DICT",
      "title": "收货节点",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "TRAN_CODE",
      "title": "计划流水单号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    }
  ];
  public user: any;
  modeType = "add"

  private titleDict = {
    "add": "新运输计划调度-新增",
    "modify": "新运输计划调度-修改",
    "check": "新运输计划调度-审核"
  }
  title = this.titleDict[this.modeType]

  constructor(
    private dataCenterService: DataCenterService,
    private requestService: RequestDataService,
    private userInfoService: UserInfoService,
    private utils: UtilsService,
    private modalService: NzModalService,
    private confirmModal: ConfirmModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }



  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  findMainDataByColumn(pColumn) {
    let findColumns = _.filter(this.baseForm['columns'], oneColumn => {
      return oneColumn['column'] == pColumn;
    })
    if (this.utils.Base_HasValue(findColumns)) {
      return _.get(findColumns, [0, 'model'], null);
    } else {
      return null
    }
  }

  checkMainData(pColumn, pCheckRequire, pCompare, pMsg) {
    let check = true;
    let findValue = this.findMainDataByColumn(pColumn);
    let msg = '';
    if (pCheckRequire) // 如果是检查必填项
    {
      if (!this.utils.Base_HasValue(findValue)) {
        check = false;
        msg = pMsg + ': 是必填项';
        this.confirmModal.show('error',
          {title: '请填写必填信息。', content: msg});
      }

    } else {
      // 如果是比较值
      if (findValue == pCompare) {
        check = false;
        msg = pMsg;
      }
      if (!check) {
        this.confirmModal.show('error',
          {title: '错误', content: msg});
      }
    }
    return check;
  }


  doSave() {
    let that = this;
    let check = true;
    // 重新计算总金额
    _.each(this.ordersList, oneItemData => {
      this.changeQuantity(null, oneItemData);
    });
    if (check) check = this.checkMainData('EXCESSIVE_AUDIT', false, 'AUDITING', '调度单在超标审核中，不能保存。');
    if (check) check = this.checkMainData('DISP_CODE', true, null, '调度单号');
    if (check) check = this.checkMainData('DISP_QUANTITY', true, null, '总数量');
    if (check) check = this.checkMainData('DISP_VOLUME', true, null, '总体积');
    if (check) check = this.checkMainData('DISP_WEIGHT', true, null, '总重量');
    if (check) check = this.checkMainData('DISP_PLAN_DEPART', true, null, '计划发车时间');
    if (check) {
      let custId = this.findMainDataByColumn('CUST_ID');
      let drId = this.findMainDataByColumn('DR_ID');
      if (!this.utils.Base_HasValue(custId) && !this.utils.Base_HasValue(drId)) {
        check = false;
        this.confirmModal.show('error',
          {title: '错误', content: '（承运商和司机至少有一个）'});
      }
    }
    if (check) check = this.checkMainData('DISP_SUPERVISOR', true, null, '监装人员');
    if (check) check = this.checkMainData('DISP_TRACK_USER_A', true, null, '跟踪人员A');

    //总运费大于0
    if (check){
      let sumPay = 0;
      this.ordersList.forEach((item,index) => {
        sumPay += item['SE_TRANS_TOTAL_PAY'];
      })
      if (sumPay <= 0){
        check = false;
        this.confirmModal.show('error', {title: '错误', content: '运单总运费不能小于等于0！'});
      }
      //  每一条都不小于等于0
      // let errorText = '';
      // this.ordersList.forEach((item,index) => {
      //   if (item['SE_TRANS_TOTAL_PAY'] <= 0){
      //     check = false;
      //     errorText += (index + 1) + '，';
      //   }
      // })
      // if (errorText != ''){
      //   errorText = _.trimEnd(errorText, '，');
      //   this.confirmModal.show('error', {title: '错误', content: '第' + errorText + '条运单总运费不能小于等于0！'});
      // }
    }

    let standards = this.getStandards(); // 获取是否超标.
    let stdObj = this.getDetailStandards(); // 获取明细是否超标
    if (!standards) {
      standards = stdObj['standards'];
    }
    let standardsData = stdObj['standardsData'];
    if (check) {
      if (standards) {
        // 如果超标，弹窗，输入超标原因，然后保存。
        this.modalService.create({
          nzTitle: '运费/卸车费超出指导价',
          nzContent: FormModalComponent,
          nzWidth: 400,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'CUST_ID',
                  'name': 'CUST_ID_NAME',
                  'component': 'inputMulti',
                  "customize": 24,
                  'lable': '超标原因',
                  'comment': '超标原因',
                  'placeholder': '请输入超标原因',
                  'model': null,
                  'visible': true,
                  'require': true,
                }
              ]
            }
          },
          nzOnOk: (response) => {
            const data = response.modalFormData.columns;
            let pursueReason = _.get(data, [0, 'model'], '');
            // this.saveToJava(pursueReason, standardsData);
            this.saveToThur(pursueReason);
          }
        });

      } else {
        // 如果不超标，直接保存。
        // this.saveToJava('', standardsData);
        this.saveToThur('');
      }
    }
  }


  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.baseForm, 'columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatItemToEntity() {
    let entityList: any = [];
    _.each(this.ordersList, (oneRow) => {
      let entity: any = {};
      _.each(_.keys(oneRow), (key) => {
        const column = _.camelCase(key);
        const value = _.get(oneRow, key);
        entity[column] = value;
      });
      entityList.push(entity);
    });
    return entityList;
  }

  saveToThur(pPursueReason) {

    let queryData = {
      'userId': this.user.USER_ID
    };

    let bodyData = {
      dispCode: this.initDispCode,
      pursueReason: pPursueReason,
      msTranDispatch: this.formatMainToEntity(),
      msTranOrdersList: this.formatItemToEntity()
    }

    if (this.parentData['modeType'] === 'add') {
      this.requestService.doActionByHdbsthor('AppApi/MsTranDispatchController', 'dispatchTranSave', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
        if (response.toString() === 'true') {
          this.confirmModal.show('success', {'title': '执行成功'});
          this.gotoBack(); // 回到菜单页面
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    } else if (this.parentData['modeType'] === 'modify') {
      this.requestService.doActionByHdbsthor('AppApi/MsTranDispatchController', 'dispatchTranEdit', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
        if (response.toString() === 'true') {
          this.confirmModal.show('success', {'title': '执行成功'});
          this.gotoBack(); // 回到菜单页面
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '未定义卡片操作类型modeType'});
      this.gotoBack(); // 回到菜单页面
    }

  }

  saveToJava(pPursueReason, pStandardsData) {
    let that = this;
    let dispatchStr = '';
    _.each(this.baseForm['columns'], oneColumn => {
      dispatchStr += oneColumn['column'] + ':\'' + encodeURI(oneColumn['model']) + '\',';
      let columnGroup = _.get(oneColumn, ['columnGroup'], []);
      _.each(columnGroup, oneColumnGroup => {
        dispatchStr += oneColumnGroup['column'] + ':\'' + encodeURI(oneColumnGroup['model']) + '\',';
      })
    })
    var param = 'pursueReason:\'' + pPursueReason + '\',transModel:\'' + JSON.stringify(pStandardsData) + '\',id:\'' + this.dispId + '\',entity:\'ms_tran_dispatch\',' + encodeURI(dispatchStr);
    that.requestService.doActionByJavaPlatform('dataController', 'dispatchTranEdit', param).subscribe(values => {
      if (values['result'] == 'success') {
        this.confirmModal.show('success',
          {title: '成功。', content: values['message']});
        this.gotoBack(); // 回到菜单页面
      } else {
        this.confirmModal.show('error',
          {title: '失败', content: values['message']});
      }
    })
  }


  getStandards() {
    let standards = false;
    let DISP_TRANSFEE = this.findMainDataByColumn('DISP_TRANSFEE');
    let DISP_TOTALSFEE_GUIDE = this.findMainDataByColumn('DISP_TOTALSFEE_GUIDE');
    if (this.utils.Base_HasValue(DISP_TRANSFEE) && this.utils.Base_HasValue(DISP_TOTALSFEE_GUIDE) && DISP_TRANSFEE > DISP_TOTALSFEE_GUIDE && DISP_TOTALSFEE_GUIDE > 0) {
      standards = true;
    }
    return standards;
  }

  getDetailStandards() {
    let standards = false;
    let standardsData = [];
    _.each(this.ordersList, (oneData) => {
      // 判断明细是否超标
      if (!this.utils.Base_HasValue(oneData.SE_TRANS_PAY)) {
        oneData.SE_TRANS_PAY = 0;
      }
      if ((this.utils.Base_HasValue(oneData.SE_TRANS_PAY) && this.utils.Base_HasValue(oneData.SE_TRAN_PAY_GUIDE) && oneData.SE_TRAN_PAY_GUIDE > 0 && oneData.SE_TRANS_PAY > oneData.SE_TRAN_PAY_GUIDE) || (this.utils.Base_HasValue(oneData.SE_UNLOAD_PAY) && this.utils.Base_HasValue(oneData.SE_UNLOAD_PAY_GUIDE) && oneData.SE_UNLOAD_PAY_GUIDE > 0 && oneData.SE_UNLOAD_PAY > oneData.SE_UNLOAD_PAY_GUIDE)) {
        standards = true;
      }
      var data = {
        TRAN_ID: oneData.TRAN_ID,
        SE_TRANS_TOTAL_PAY: oneData.SE_TRANS_TOTAL_PAY,
        SE_TRANS_PAY: oneData.SE_TRANS_PAY,
        SE_UNLOAD_PAY: oneData.SE_UNLOAD_PAY,
        SE_INFO_PAY: oneData.SE_INFO_PAY,
        SE_OTHER_PAY: oneData.SE_OTHER_PAY
      };
      standardsData.push(data);
    });
    return {
      standards: standards,
      standardsData: standardsData
    }
  }


  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/ms/ms_tran_dispatch_list.json', {'DISP_ID': this.dispId}), // 1
      this.requestService.getDataByCondition('JieLiv2/ms/ms_tran_plant_detail.json', {'DISP_ID': this.dispId}), //2
    );
    search.subscribe(values => {
      //运输调度计划信息
      this.backfillData(this.baseForm, values[0]['data']);  // this.dispatchData 被 this.baseForm 给替换掉了。

      //调度计划管理
      this.ordersList = values[1]['data'];
      _.each(values[1]['data'], oneItem => {
        let obj = {
          'SE_TRANS_PAY': _.get(oneItem, ['SE_TRANS_PAY'], null),
          'SE_UNLOAD_PAY': _.get(oneItem, ['SE_UNLOAD_PAY'], null),
          'SE_INFO_PAY': _.get(oneItem, ['SE_INFO_PAY'], null),
          'SE_OTHER_PAY': _.get(oneItem, ['SE_OTHER_PAY'], null),
        }
      })
    })
  }

  backfillDataByOne(_column, _value) {
    _.each(this.baseForm['columns'], oneColumn => {
      if (oneColumn['column'] == _column) {
        oneColumn['model'] = _value
      }
    })
  }

  backfillData(pForm, pDataArray) {
    this.initDispCode = _.get(pDataArray, [0, 'DISP_CODE'], null)
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        if (oneData[oneColumn['column']] == 'null') {
          oneData[oneColumn['column']] = null
        }
        oneColumn['model'] = oneData[oneColumn['column']];

        if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
          oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
        }

        if (oneColumn['component'] == 'file') {
          let url = oneData[oneColumn['column']];
          let fileName = this.utils.Base_getFileNameOnly(url);
          oneColumn['fileList'] = [];
          oneColumn['fileList'].push(
            {
              uid: this.utils.Base_getUuid(),
              name: fileName,
              status: 'done',
              url: url
            }
          )
        }

        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
          if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
            oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
          }
        })
      })
    })
  }


  getArrayNumber(_oneData, _column) {
    let result = _.get(_oneData, [_column], 0);
    return Number(result)
  }

  changeQuantity($event, pOneData) {
    let that = this;
    let transPay = 0;  // 运费
    let unloadPay = 0; // 卸车费
    let infoPay = 0; // 信息费
    let otherPay = 0; // 其它费用
    let totalPay = 0; // 总运费
    _.each(this.ordersList, function (oneData) {
      transPay += that.getArrayNumber(oneData, 'SE_TRANS_PAY'); // 运费
      unloadPay += that.getArrayNumber(oneData, 'SE_UNLOAD_PAY'); // 卸车费
      infoPay += that.getArrayNumber(oneData, 'SE_INFO_PAY'); // 信息费
      otherPay += that.getArrayNumber(oneData, 'SE_OTHER_PAY'); // 其它费用

      oneData.SE_TRANS_TOTAL_PAY = that.getArrayNumber(oneData, 'SE_TRANS_PAY') + that.getArrayNumber(oneData, 'SE_UNLOAD_PAY')
        + that.getArrayNumber(oneData, 'SE_INFO_PAY') + that.getArrayNumber(oneData, 'SE_OTHER_PAY');
      totalPay += (+oneData.SE_TRANS_TOTAL_PAY); // 总运费
    });
    this.backfillDataByOne('DISP_TRANSFEE', transPay);
    this.backfillDataByOne('DISP_DELIVERY', unloadPay);
    this.backfillDataByOne('DISP_INFO_PRICE', infoPay);
    this.backfillDataByOne('DISP_OTHER', otherPay);
    this.backfillDataByOne('DISP_TOTALSFEE', totalPay);
  }

  updateColumnValue(columnName: string, value: any, perptyName: string): void {
    let column = this.baseForm.columns.find(c => c.column === columnName);
    if (column) {
      column[perptyName] = value;
    }
  }

  ngOnInit() {
    let that = this;
    this.dispId = _.cloneDeep(_.get(this.parentData, ['id']))
    that.modeType = this.parentData.modeType;
    this.doSearch();
    if (that.parentData.modeType == 'check') {
      that.updateColumnValue("CUST_ID", true, 'disable'); // 承运商
      that.updateColumnValue("DR_ID", true, 'disable'); // 司机
    }
    that.title = that.titleDict[that.parentData.modeType];
  }

}
