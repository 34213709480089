import {Component, Input, OnInit} from '@angular/core';
import {GroupingModelComponent} from '../grouping-model/grouping-model.component';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'underscore';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-group-single',
  templateUrl: './group-single.component.html',
  styleUrls: ['./group-single.component.css']
})
export class GroupSingleComponent implements OnInit {

  @Input() oneGroup;
  @Input() modelType;

  resourcesUrl: string;
  data = {};

  constructor(private modalService: NzModalService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  addResources() {
    const data: any = {
      fileList: [this.data],
      type: 'single'
    };
    this.modalService.create({
      nzTitle: '资源选择',
      nzContent: GroupingModelComponent,
      nzComponentParams: data,
      nzWidth: 1200,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: (response) => {
        const selectList = _.filter(response.listData, oneData => {
          return oneData['checked'];
        });
        if (!this.utils.Base_HasValue(selectList)) {
          this.confirmModal.show('warning', {
            title: '请选择一个资源'
          });
          return false;
        }
        if (selectList.length > 1) {
          this.confirmModal.show('warning', {
            title: '请只选择一个资源'
          });
          return false;
        }
        this.resourcesUrl = selectList[0]['resourcesPicUrl'];
        if (this.utils.Base_HasValue(this.oneGroup['columns'])) {
          for (const oneColumn of this.oneGroup['columns']) {
            if (oneColumn['column'] === 'resourceId') {
              oneColumn['model'] = selectList[0]['resourceId'];
            } else if (oneColumn['column'] === 'resourceUrl') {
              oneColumn['model'] = selectList[0]['resourcesPicUrl'];
            } else if (oneColumn['column'] === 'resourceName') {
              oneColumn['model'] = selectList[0]['resourcesName'];
            }
            this.data[oneColumn['column']] = oneColumn['model'];
          }
        }
      }
    });
  }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.oneGroup['columns'])) {
      for (const oneColumn of this.oneGroup['columns']) {
        this.data[oneColumn['column']] = oneColumn['model'];
        if (oneColumn['column'] === 'resourceUrl') {
          this.resourcesUrl = oneColumn['model'];
        }
      }
    }
  }

}
