import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {DataService} from '../data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';

@Component({
  selector: 'app-word-add-edit',
  templateUrl: './word-add-edit.component.html',
  styleUrls: ['../picture-edit/picture-edit.component.css', './word-add-edit.component.css']
})
export class WordAddEditComponent implements OnInit {

  @Input() parentData: any;
  labelsList = [];
  data: any = {
    resourcesType: 'WORD',
    siteId: '',
    channelId: '',
    labels: null,
    basicModelId: '',
    fileList: []
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;
  modelList = [];
  nowModel = {
    code: ''
  };
  addModel = false;
  models: any = [
    {modelName: null}
  ]; // 扩展模型

  _validateForm: FormGroup;
  showModel = true;
  basicModelList = [];
  selectedIndex = 0;
  constructor(
    private formBuilder: FormBuilder,
    private requestData: RequestDataService,
    private dataService: DataService,
    private dataCenterService: DataCenterService,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService) {
  }

  // 选择基础模型
  changeBasicModel() {
    if (!this.utils.Base_HasValue(this.data.basicModelId)) {
      return;
    }
    this.dataService.searchModel({modelId: this.data.basicModelId}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json', {BASIC_MODEL_ID: response['modelId']}).subscribe(resp => {
          this.modelList = resp['data'] || [];
        });
        this.models[0] = response;
        this.transformModifyData();
        this.models = this.initModelData(this.models);
        this._validateForm = this.addOneForm();
        this.nowModel = response;
        this.showModel = true;
      }
    });
  }

  // 切换扩展模型
  checkModel(oneData) {
    this.nowModel = oneData;
    let index = _.findIndex(this.models, (item) => {
      return item['modelId'] === this.nowModel['modelId'];
    });
    this.selectedIndex = index;
  }

  deleteOneModel(pModel) {
    if (this.utils.Base_HasValue(this.nowModel) && this.nowModel['modelType'] === 'basic') {
      this.confirmModal.show('warning', {title: '当前模型为基础模型,不能被删除!'});
      return false;
    }
    this.confirmModal.show('confirm', {
      title: '您确定删除该模型吗？', suc: () => {
        let index = this.models.indexOf(pModel);
        // 如果是选中后删除 则最后一个删除后index-1
        if (this.selectedIndex === index) {
          // 如果是删除掉最后一个tab则选中删除后的最后一个tab
          if (this.selectedIndex === this.models.length - 1) {
            this.selectedIndex = this.selectedIndex - 1;
          }
        } else if (this.selectedIndex > index) { //在当前选中tab前面删除则当前dataINdex -1 nowMenu
          this.selectedIndex = this.selectedIndex - 1;
        }
        this.models.splice(index, 1);
        for (let oneData of this.modelList) {
          if (oneData['modelId'] === pModel['modelId']) {
            oneData.checked = false;
          }
        }
        if (this.models.length) {
          this.nowModel = this.models[this.selectedIndex];
        } else {
          this.selectedIndex = 0;
          this.nowModel = {
            code: ''
          };
        }
        // this.models = _.without(this.models, pModel);
      }
    });
  }

  handleOk(): void {
    let ids = '';
    _.each(this.modelList, oneData => {
      if (oneData['checked']) {
        let ok = true;
        _.each(this.models, item => {
          if (oneData['code'] === item['modelId']) {
            ok = false;
          }
        });
        if (ok) {
          ids += oneData['code'] + '；';
        }
      }
    });
    if (ids.length < 1) {
      this.addModel = false;
      return;
    }
    ids = ids.slice(0, ids.length - 1);
    this.dataService.searchModel({modelId: ids}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        if (_.isArray(response)) {
          this.models = this.models.concat(response);
        } else {
          this.models.push(response);
        }
        this.transformModifyData();
        this.models = this.initModelData(this.models);
        this._validateForm = this.addOneForm();
      }
    });
    this.addModel = false;
  }

  // 封装多选组件数据
  transformMultiSelect(itemArray, model) {
    let insertList = [];
    let ids = [];
    let names = [];
    _.each(model, (value: string) => {
      let keywordsObj = _.groupBy(itemArray, item => item['code']);
      if (keywordsObj.hasOwnProperty(value)) {
        ids.push(value);
        names.push(keywordsObj[value][0]['name']);
      } else {
        let id = this.utils.Base_getUuid();
        ids.push(id);
        names.push(value);
        insertList.push({
          id: id,
          name: value
        });
      }
    });
    return {
      insertList: insertList,
      ids: ids.join('<,>'),
      names: names.join('<,>')
    };
  }

  handleCancel(): void {
    this.addModel = false;
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME.json', {
      SITE_ID: pSiteId,
      CHANNEL_STATE: 'ENABLE'
    }).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        for (let oneData of this.channelList) {
          if (oneData['code'] === this.data.channelId) {
            oneData['selected'] = true;
          }
        }
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  gotoBack() {
    this.transformData('customForm', this.parentData);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-word-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doSave() {
    this.required = true;
    if (!this.utils.Base_HasValue(this.data.resourcesName) ||
      !this.utils.Base_HasValue(this.data.siteId) ||
      !this.utils.Base_HasValue(this.data.channelId)) {
      return;
    }
    if (this._validateForm && this._validateForm.invalid) {
      _.each(this._validateForm.controls, (controls, groupKey) => {
        _.each(controls['controls'], groupCon => {
          for (const key in groupCon['controls']) {
            groupCon['controls'][key].markAsDirty();
            groupCon['controls'][key].updateValueAndValidity();
          }
        });
      });
    }
    if (this._validateForm && this._validateForm.valid) {
      // 封装关键词数据 如果字典没有的数据需要新增到关键词表
      // 封装学科名称数据
      const model = this.utils.DeepCopy(this.models);
      _.each(model, item => {
        _.each(item['groups'], group => {
          if (!_.contains(['pictureModel', 'fileModel'], group['type'])) {
            _.each(group['data'], data => {
              _.each(group['columns'], column => {
                if (_.contains(['multipop', 'poplist'], column['component'])) {
                  delete data['old' + column['column']];
                  delete data[column['column'] + 'showName'];
                  if (column['columnGroup']) {
                    _.each(column['columnGroup'], colg => {
                      if (colg['model']) {
                        data[colg['column']] = colg['model'];
                      }
                    });
                  }
                }
                if (_.contains(['date', 'datetime'], column['component'])) {
                  delete data[column['column'] + 'Date'];
                  delete data['old' + column['column']];
                }
                if (_.contains(['dateRange'], column['component'])) {
                  if (data[column['column']]) {
                    const bindColumns = column['column'].split(',');
                    _.each(bindColumns, (col: any, colIndex) => {
                      if (item) {
                        data[col] = data[column['column']][colIndex];
                      }
                    });
                    data[column['column']] = data[column['column']].join(',');
                    delete data['old' + column['column']];
                    delete data[column['column'] + 'Date'];
                  }
                }
                if (column['component'] === 'multiSelect') {
                  const multiData = this.transformMultiSelect(column['itemArray'], data[column['column']]);
                  data[column['column']] = multiData ? multiData.ids : [];
                  data[column['column'] + 'Name'] = multiData ? multiData.names : [];
                  if (column['column'] === 'keywordsName') {
                    data['insertList'] = multiData ? multiData.insertList : [];
                  }
                }
                if (column['component'] === 'select') {
                  const multiData = _.find(column['itemArray'], itemArray => itemArray['code'] === data[column['column']]);
                  data[column['column'] + 'Name'] = multiData ? multiData['name'] : '';
                }
              });
            });
            _.each(group['columns'], column => {
              delete column['placeholder'];
              delete column['name'];
              delete column['lableSpan'];
              delete column['itemSpan'];
              delete column['lable'];
              delete column['comment'];
              // delete column['columnGroup'];
              delete column['itemArray'];
            });
          }
        });
      });
      this.data.models = this.models;
      const data = {
        data: JSON.stringify(this.data)
      };
      this.dataService.wordDrSave(data).subscribe(result => {
        if (result.state === 'success') {
          this.confirmModal.show('success', {
            title: '温馨提示',
            content: '保存成功',
            suc: () => {
              this.transformData('customForm', this.parentData);
            }
          });
        }
      });

    }
  }

  // 根据当前数据json内的modelId去查出模型，把JSON里的值合并到查出来的模型里。这样做为了避免后面模型有修改
  groupModelValue(modelList) {
    function marageModel(pResponse) {
      _.each(pResponse, list => {
        _.each(list['groups'], group => {
          _.each(group['columns'], column => {
            _.each(modelList, itemList => {
              if (itemList['modelId'] === list['modelId']) {
                _.each(itemList['groups'], itemGroup => {
                  if (itemGroup['groupId'] === group['groupId']) {
                    _.each(itemGroup['columns'], itemColumn => {
                      if (itemColumn['modelColumnId'] === column['modelColumnId']) {
                        column['model'] = itemColumn['model'];
                      }
                    });
                  }
                });
              }
            });
          });
        });
      });
      return pResponse;
    }
    let ids = '';
    _.each(modelList, oneData => {
      ids += oneData['modelId'] + '；';
    });
    ids = ids.slice(0, ids.length - 1);
    this.dataService.searchModel({modelId: ids}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        if (_.isArray(response)) {
          this.models = marageModel(response);
        } else {
          this.models = marageModel([response]);
        }
      }
      this.transformModifyData();
      this.models = this.initModelData(this.models);
      this._validateForm = this.addOneForm();
      this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json', {BASIC_MODEL_ID: this.models[0]['modelId']}).subscribe(response => {
        this.modelList = response['data'] || [];
      });
    });
  }

  // 初始化数据。将group某个组没有data的默认给上值
  initModelData(modelList) {
    _.each(modelList, (model, index) => {
      if (index === 0) {
        model['active'] = true;
      }
      _.each(model['groups'], group => {
        // if (!this.utils.Base_HasValue(group['data']) && !_.contains(['pictureModel', 'fileModel'], group['type'])) {
        //   const data = {};
        //   _.each(group['columns'], column => {
        //     data[column['column']] = column['component'] === 'select' ? null : '';
        //   });
        //   data['row'] = 0;
        //   group['data'] = [data];
        // }
        _.each(group['columns'], column => {
          column['name'] = column['column'];
          column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
          switch (column['component']) {
            case 'input':
            case 'textarea':
              column['placeholder'] = '请输入' + column['columnName'];
              break;
            case 'date':
            case 'datetime':
            case 'poplist':
            case 'multipop':
            case 'multiSelect':
              if (column['column'] === 'keywordsName') {
                column['nzMode'] = 'tags';
              } else {
                column['nzMode'] = 'multiple';
              }
            case 'select':
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              column['placeholder'] = '请选择' + column['columnName'];
              column['comment'] = column['columnName'];
              column['lable'] = column['columnName'];
              if (column['columnGroup']) {
                _.each(column['columnGroup'], col => {
                  col['comment'] = col['columnName'];
                  col['lable'] = col['columnName'];
                });
              }
              break;
            case 'dateRange':
              column['placeholder'] = column['columnName'].split(',');
              column['comment'] = group['groupName'];
              column['lable'] = group['groupName'];
              column['lableSpan'] = 8;
              column['itemSpan'] = 16;
              break;
          }
        });
      });
    });
    return modelList;
  }

  // 组件添加formControl
  private addOneColumn(oneColumn): FormControl {
    const validatorList = [];
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    // if (this.utils.Base_HasValue(oneColumn.maxLength)) {
    //   validatorList.push(Validators.maxLength(oneColumn.maxLength));
    // }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    if(oneColumn.column === 'Title'){
      return new FormControl({value: '', disabled: true}, validatorList);
    }
    return new FormControl('', validatorList);
  }

  private createItem(group): FormGroup {
    const arrayGroup = {};
    _.each(group['columns'], column => {
      arrayGroup[column['column']] = this.addOneColumn(column);
    });
    return this.formBuilder.group(arrayGroup);
  }

  // form 添加组件controls
  private addOneForm(): FormGroup {
    const group = {};
    _.each(this.models, model => {
      _.each(model['groups'], (item) => {
        const array = [];
        // 如果是文件上传需要将column添加到form里
        // 其他类型则按照data添加
        array.push(this.createItem(item));
        group[item['groupId']] = this.formBuilder.array(array);
      });
    });
    return this.formBuilder.group(group);
  }

  transformModifyData() {
    _.each(this.models, list => {
      _.each(list['groups'], group => {
        _.each(group['columns'], column => {
          if (!this.utils.Base_HasValue(column['model'])) {
            column['model'] = null;
          }
        });
      });
    });
  }

  dataFormat(pData) {
    this.data = JSON.parse(pData.RESOURCES_JSON);
    this.data['resourceId'] = pData['ID'];
    this.nowModel = this.data.models[0];
    this.data.basicModelId = this.data.models[0]['modelId'];
    this.groupModelValue(this.data.models);
  }

  ngOnInit() {
    if (this.parentData.modeType === 'add') {
      this.showModel = false;
      const search = observableForkJoin(
        this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
        this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json', {MODEL_EN_NAME: 'Word-BasedModel'})
      );
      search.subscribe(
        values => {
          this.siteList = values[0]['data'] || [];
          this.basicModelList = values[1]['data'] || [];
          this.data.basicModelId = this.basicModelList[0]['code'];
          this.changeBasicModel();
        });
    } else if (this.parentData.modeType === 'modify') {
      const search = observableForkJoin(
        this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
        this.requestData.getDataByCondition('digitalResources/dr/dr_word_list.json', {'RESOURCES_ID': this.parentData.RESOURCES_ID}),
        this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json', {MODEL_EN_NAME: 'Word-BasedModel'})
      );
      search.subscribe(
        values => {
          this.siteList = values[0]['data'] || [];
          const data = values[1]['data'][0] || [];
          this.basicModelList = values[2]['data'] || [];
          this.dataFormat(data);
          this.searchChannel(data['SITE_ID']);
        });
    }
  }

}
