import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from 'underscore';
import * as _sh from 'lodash';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-hulk-config-batch-btn',
  templateUrl: './hulk-config-batch-btn.component.html',
  styleUrls: ['./hulk-config-batch-btn.component.scss']
})
export class HulkConfigBatchBtnComponent implements OnInit {
  public isVisible = false;
  @Input() label: any;
  @Input() oneForm:any;
  @Input() attributes: any;
  @Input() code:any;
  @Input() name:any;
  @Input() codeLabel:any;
  @Input() nameLabel:any;
  @Input() canMove: boolean = true;
  @Input() callBack:any;

  constructor(
      private _httpClient: HttpClient,
      private modalService: NzModalService,
      private hulkconfigserviceservice:HulkConfigServiceService,
      private _FileSaverService: FileSaverService,
      private requestDataService: RequestDataService,
      private appConfigService: AppConfigService,
      private notification: NzNotificationService,
      private activatedRoute: ActivatedRoute,
      private confirmModal: ConfirmModalService
  ) { }

  ngOnInit(

  ) {
  }

  // 弹出框
  showModal() {
    this.isVisible = true;
  }

  // 弹出框
  closeModal() {
    this.isVisible = false;
  }

  checkAll(loopData, value: boolean): void {
    loopData.forEach(data => {
      data.checked = value;
    });
    this.refreshStatus(loopData);
  }

  selectMore(pListData, multiple?): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });

    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }


  refreshStatus(loopData): void {
    const validData = loopData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    loopData.allChecked = allChecked;
    loopData.indeterminate = (!allChecked) && (!allUnChecked);
  }

  // 放到第一位
  pubItFirst(oneColumn: any) {
    this.oneForm[this.attributes] = _.without(this.oneForm[this.attributes], oneColumn);
    this.oneForm[this.attributes].unshift(oneColumn);
    if (this.callBack) this.callBack();

  }

  putForward(oneColumn) {
    if (this.oneForm[this.attributes] instanceof Array) {
      let num = this.oneForm[this.attributes].indexOf(oneColumn);
      let a = this.oneForm[this.attributes][num - 1];
      this.oneForm[this.attributes][num - 1] = oneColumn;
      this.oneForm[this.attributes][num] = a;
      if (this.callBack) this.callBack();

    }
  }

  putItBack(oneColumn) {
    if (this.oneForm[this.attributes] instanceof Array) {
      let num = this.oneForm[this.attributes].indexOf(oneColumn);
      let a = this.oneForm[this.attributes][num + 1];
      this.oneForm[this.attributes][num + 1] = oneColumn;
      this.oneForm[this.attributes][num] = a;
      if (this.callBack) this.callBack();

    }
  }


  pubItLast(oneColumn) {
    this.oneForm[this.attributes] = _.without(this.oneForm[this.attributes], oneColumn);
    this.oneForm[this.attributes].push(oneColumn);
    if (this.callBack) this.callBack();

  }

  deleteComponent(oneColumn) {
    this.confirmModal.show('confirm', {
      title: '是否删除该数据。',
      suc: () => {
        this.oneForm[this.attributes] = _sh.without(this.oneForm[this.attributes],oneColumn);
        if (this.callBack) this.callBack();

      }
    });
  }

  deleteAll() {
    if (this.selectMore(this.oneForm[this.attributes], true) >= 1) {
      const selectList = _.filter(this.oneForm[this.attributes], (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '是否删除这些数据。',
        suc: () => {
          this.oneForm[this.attributes] = _.without(this.oneForm[this.attributes], selectList);
          _.each(selectList, oneColumn => {
            this.oneForm[this.attributes] = _.without(this.oneForm[this.attributes], oneColumn);
          })
          if (this.callBack) this.callBack();
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.oneForm[this.attributes], event.previousIndex, event.currentIndex);
  }

}
