import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-descriptions-edit-more-btn',
  templateUrl: './descriptions-edit-more-btn.component.html',
  styleUrls: ['./descriptions-edit-more-btn.component.scss']
})
export class DescriptionsEditMoreBtnComponent implements OnInit {


  @Input() oneColumn: any;
  @Input() oneTab: any;
  @Input() oneGroup:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() oneRow:any;

  public readonlyDatas = { }

  constructor() { }

  ngOnInit() {

    this.readonlyDatas = {
      sqlFileListData: this.sqlFileListData,
      aggregateListData:this.aggregateListData,
      selectData: {
        oneTab: this.oneTab,
        oneGroup: this.oneGroup,
        oneRow: this.oneRow,
        oneColumn: this.oneColumn
      }
    }

  }

}
