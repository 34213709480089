import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-es-rebuild',
  templateUrl: './es-rebuild.component.html',
  styleUrls: ['./es-rebuild.component.css']
})
export class EsRebuildComponent implements OnInit {

  time: any = 0;

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    let interval = setInterval(() => {
      this.time += 5;
      if (this.time === 100) {
        clearInterval(interval);
      }
    }, 500);
    this.dataService.esRebuild().subscribe(rep => {
      if (rep.state === 'success') {
        this.time = 100;
        clearInterval(interval);
      }
    });

  }

}
