import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import {FormBuilder} from '@angular/forms';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {DataService} from '../data.service';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';

@Component({
  selector: 'app-dataset-permission',
  templateUrl: './dataset-permission.component.html',
  styleUrls: ['./dataset-permission.component.css']
})
export class DatasetPermissionComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;
  fileData = [];
  deleteModelShow = false; // 删除用户组弹是出框显示
  showModelGroup = false; // 查看用户组弹出框显示
  volumeAuthorization = false; // 批量授权弹出框显示
  fileList = []; // 批量授权的文件
  groupData: any;
  nowFileData: any;
  userArray = []; // 已选择的用户组

  hulkColumn = {
    "title": "选择用户组",
    "columns": [
      {
        'lableSpan': 6,
        'itemSpan': 18,
        'column': 'GROUP_NAME',
        'name': 'GROUP_NAME',
        'component': 'multipop',
        'customize': 24,
        'lable': '用户组',
        'comment': '用户组',
        'placeholder': '请选择用户组',
        'visible': true,
        'require': true,
        'dictionary': 'headLine/ds/dict/GROUP_NAME.json'
      }
    ]
  };

  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private utils: UtilsService,
              private modalService: NzModalService,
              private confirmModal: ConfirmModalService,
              private dataService: DataService) {
  }

  // 添加组
  fileAddGroup(data) {
    data = data || {};
    this.modalService.create({
      nzTitle: '添加组',
      nzContent: FormModalComponent,
      nzWidth: 1200,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'tablename': 'ds_group',
          'columns': [
            {
              'lableSpan': 6,
              'itemSpan': 18,
              'column': 'GROUP_NAME',
              'name': 'GROUP_NAME',
              'component': 'multipop',
              'lable': '组名',
              'comment': '组名',
              'placeholder': '请选择组名',
              'model': this.userArray || null,
              'visible': true,
              'require': true,
              'dictionary': 'headLine/ds/dict/GROUP_NAME.json'
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.status === 'PENDING') {
          return;
        }
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          data.groupList = [];
          this.userArray = [];
          const dict = response.modalFormData.columns[0].itemArray;
          const model = response.modalFormData.columns[0].model;
          const selected = _.filter(dict, item => model.indexOf(item['code']) > -1);
          _.each(selected, oneData => {
            let group = {
              GROUP_ID: oneData['code'],
              GROUP_NAME: oneData['name']
            };
            this.userArray.push(oneData['code']);
            data.groupList.push(group);
          });
          data.groupCount = data.groupList.length;
        } else {
          return false;
        }
      }
    });
  }

  // 查看组
  lookGroup(pGroup) {
    if (pGroup.groupCount === 0) {
      return this.confirmModal.show('warning', {title: '还没有授权用户组'});
    }
    this.showModelGroup = true;
    this.groupData = pGroup.groupList;
  }

  showGroupModelCancel() {
    this.showModelGroup = false;
  }

  // 删除组
  deleteGroup(pGroup) {
    if (pGroup.groupCount === 0) {
      return this.confirmModal.show('warning', {title: '还没有授权用户组'});
    }
    this.deleteModelShow = true;
    this.groupData = pGroup.groupList;
    this.nowFileData = pGroup;
  }

  // 删除用户组确认方法
  handleOk(pData) {
    let select = _.filter(pData, item => item['checked'] === true);
    if (select.length === 0) {
      return this.confirmModal.show('warning', {title: '您还没有选择要删除的用户组！'});
    }
    this.confirmModal.show('confirm', {
      'title': '您确定要删除这些用户组吗。',
      suc: () => {
        this.userArray = [];
        this.nowFileData.groupList = _.filter(pData, item => !item['checked']);
        this.nowFileData.groupCount = this.nowFileData.groupList.length;
        _.each(this.nowFileData.groupList, oneData => {
          this.userArray.push(oneData['GROUP_ID']);
        });
        this.deleteModelShow = false;
      }
    });
  }

  // 删除用户组取消方法
  handleCancel() {
    this.deleteModelShow = false;
    _.each(this.groupData, item => {
      item['checked'] = false;
    });
  }

  // 打开批量授权弹框
  volumeAuthorizationModal() {
    this.fileList = _.filter(this.fileData, oneData => {
      return oneData.checked;
    });
    if (this.fileList.length > 0){
      this.volumeAuthorization = true;
    } else {
      this.confirmModal.show('warning', {title: '请选择文件'});
    }
  }

  // 批量授权用户组确认方法
  confirmVolumeAuthorization() {
    if (this.hulkColumn.columns[0]['model']){
      const dict = this.hulkColumn.columns[0]['itemArray'];
      const model = this.hulkColumn.columns[0]['model'];
      const selected = _.filter(dict, item => model.indexOf(item['code']) > -1);
      _.each(this.fileList, oneData => {
        _.each(selected, item => {
          let group = {
            GROUP_ID: item['code'],
            GROUP_NAME: item['name']
          };
          oneData.groupList.push(group);
        });
        oneData.groupList = _.uniq(oneData.groupList, item => {
          return item['GROUP_ID'];
        });
        oneData.groupCount = oneData.groupList.length;
      });
      this.volumeAuthorization = false;
      this.hulkColumn.columns[0]['model'] = null;
      this.hulkColumn.columns[0]['modelName'] = null;
    } else {
      return this.confirmModal.show('warning', {title: '请选择用户组!'});
    }
  }

  // 取消批量授权用户组
  cancelVolumeAuthorization() {
    this.volumeAuthorization = false;
    this.hulkColumn.columns[0]['model'] = null;
    this.hulkColumn.columns[0]['modelName'] = null;
  }

  checkAll(e, pList) {
    _.each(pList, item => {
      item['checked'] = e;
    });
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 保存
  doSave() {
    let data = {
      'DATASET_ID': this.parentData.DATASET_ID,
      'DATASET_NAME': this.parentData.DATASET_NAME,
      'DATASET_STATE': this.parentData.DATASET_STATE,
      'dataList': JSON.stringify(this.fileData)
    };
    this.dataService.fileGroupPermission(data).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {
          title: '温馨提示',
          content: '保存成功',
          suc: () => {
            this.gotoBack();
          }
        });
      } else {
        this.confirmModal.show('error', {title: '保存失败请刷新重试。'});
      }
    });
  }

  ngOnInit() {
    this.requestData.getDataByCondition('headLine/ds/ds_file_restricted.json', {DATASET_ID: this.parentData.DATASET_ID}).subscribe(response => {
      let fileGroup = _.groupBy(response.data, oneData => {
        return oneData['FILE_NAME'];
      });
      let data = [];
      _.each(fileGroup, (value, key) => {
        let file = {
          FILE_ID: value[0]['ID'],
          FILE_NAME: key,
          groupList: [],
          groupCount: 0
        };
        _.each(value, oneData => {
          if (this.utils.Base_HasValue(oneData['GROUP_ID'])) {
            let group = {
              GROUP_ID: oneData['GROUP_ID'],
              GROUP_NAME: oneData['GROUP_NAME']
            };
            this.userArray.push(oneData['GROUP_ID']);
            file.groupList.push(group);
            file.groupCount = file.groupList.length;
          }
        });
        data.push(file);
      });
      this.fileData = data;
    });
  }

}
