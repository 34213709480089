
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {DataCenterService} from '../../../service/data-center.service';
import {DataService} from '../../../project/headLine/data.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-dataset-detail',
  templateUrl: './dataset-detail.component.html',
  styleUrls: ['./dataset-detail.component.css']
})
export class DatasetDetailComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;

  public stateArray: any = [];
  public model: any = {};

  public previewImage: any = '';
  public previewVisible: any = false;

  // 一键展开手气
  public expendAll: any = false;

  versionNumber = []; // 版本号
  nowVersion = null;

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private dataService: DataService) {
  }

  gotoBack() {
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL)) {
          item = _.extend(item, this.parentData);
          item['formType'] = 'listForm';
          item['data'] = {};
          // item["detailCondition"] = {};
          item['conditionColumns'] = {};
        }
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 一键展开一键收起
  expendFun() {
    this.expendAll = !this.expendAll;
    _.each(this.model.modelList, model => {
      model['active'] = this.expendAll;
    });
  }

  handlePreview = (url) => {
    this.previewImage = url;
    this.previewVisible = true;
    return false;
  };

  // 格式化数据
  transShowName(name) {
    if (this.utils.Base_HasValue(name)) {
      return name.split('<,>').join(' ');
    } else {
      return '';
    }
  }

  // 查看不同版本
  searchVersion() {
    this.ngOnInit(this.nowVersion);
  }

  ngOnInit(pVersion?) {
    const condition = this.parentData['detailCondition'];
    const id = condition['DATASET_ID'];
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition('headLine/basic/dict/DATASET_STATE.json'),
      this.dataService.searchDsDetailByIdVersion({
        datasetId: id,
        version: pVersion
      }),
      this.requestDataService.getDataByCondition('headLine/ds/ds_search_version.json', {'DATASET_ID': id})
    );
    search.subscribe(
      values => {
        this.stateArray = values[0]['data'] || [];
        this.model = values[1];
        const data = _.find(this.stateArray, item => item['code'] === this.model['datasetState']);
        this.model['datasetState'] = data['name'];
        this.nowVersion = this.model['curVersion'];
        _.each(this.model.modelList, model => {
          if (model['modelType'] === 'basic') {
            model['active'] = true;
          }
        });
        this.versionNumber = values[2]['data'];
      });
  }

}
