import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {NzModalService} from "ng-zorro-antd";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _un from "underscore";
import * as _ from 'lodash';
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
import {UserInfoService} from "../../../service/user-info.service";


@Component({
  selector: 'app-sto-dispatch',
  templateUrl: './sto-dispatch.component.html',
  styleUrls: ['./sto-dispatch.component.scss']
})
export class StoDispatchComponent implements OnInit {
  @Input() parentData: any;
  dispId = null;
  planId = '';
  ordersList: any[];
  baseForm: any = {
    "type": "normal",
    "columns": [
      {
        "column": "DISP_ID",
        "name": "DISP_ID_NAME",
        "component": "input",
        "lable": "调度单ID",
        "comment": "调度单ID",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入调度单号",
        "model": '',
        "visible": false,
        "require": true,
        "disable": false
      },
      {
        "column": "DISP_CODE",
        "name": "DISP_CODE_NAME",
        "component": "input",
        "lable": "调度单号",
        "comment": "调度单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入调度单号",
        "model": '',
        "visible": true,
        "require": true
      },
      {
        "column": "DISP_STATE",
        "name": "DISP_STATE",
        "component": "input",
        "lable": "DISP_STATE",
        "comment": "DISP_STATE",
        "placeholder": "请输入DISP_STATE",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": '',
        "visible": false,
        "require": false
      },
      {
        "column": "SERVICE_ID",
        "name": "SERVICE_ID_NAME",
        "component": "select",
        "lable": "业务类型",
        "comment": "业务类型",
        "placeholder": "请输入业务类型",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": '',
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_SERVICE_UP.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TASK_FORK",
        "name": "TASK_FORK_NAME",
        "component": "poplist",
        "lable": "叉车工",
        "comment": "叉车工",
        "placeholder": "请输入叉车工",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": '',
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_EMP_FORK.json",
        "dictionaryPF": null
      },
      {
        "column": "TASK_MANAGER",
        "name": "TASK_MANAGER_NAME",
        "component": "poplist",
        "lable": "库管员",
        "comment": "库管员",
        "placeholder": "请输入库管员",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": '',
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/BASIC_EMP_MANAGER.json",
        "dictionaryPF": null
      },
      {
        "column": "TASK_TEAM",
        "name": "TASK_TEAM_NAME",
        "component": "poplist",
        "lable": "装卸队",
        "comment": "装卸队",
        "placeholder": "请输入装卸队",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": '',
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_TEAM.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_LEVEL",
        "name": "DISP_LEVEL_NAME",
        "component": "select",
        "lable": "计划等级",
        "comment": "计划等级",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入计划等级",
        "model": 2,
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TASK_LEVEL.json",
        "dictionaryPF": null
      },
      {
        "column": "PLANT_ID",
        "name": "PLANT_ID_NAME",
        "component": "poplist",
        "lable": "仓库",
        "comment": "仓库",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入仓库",
        "model": '',
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_PLANT.json",
        "dictionaryPF": null
      },
      {
        "column": "DISP_QUANTITY",
        "name": "DISP_QUANTITY_NAME",
        "component": "input",
        "lable": "总数量(件)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "总数量(件)",
        "placeholder": "总数量(件)",
        "model": '',
        "visible": true,
        "require": true,
        "disable": true
      },
      {
        "column": "DISP_VOLUME",
        "name": "DISP_VOLUME_NAME",
        "component": "input",
        "lable": "总体积(立方米)",
        "comment": "总体积(立方米)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总体积(立方米)",
        "model": '',
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DISP_WEIGHT",
        "name": "DISP_WEIGHT_NAME",
        "component": "input",
        "lable": "总重量(吨)",
        "comment": "总重量(吨)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总重量(吨)",
        "model": '',
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        'column': 'DISP_PLAN_DEPART',
        'name': 'DISP_PLAN_DEPART_NAME',
        'component': 'date',
        'lable': "业务日期",
        'comment': '业务日期',
        'placeholder': '请输入业务日期',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': true,
        'require': true,
      },
      {
        "column": "DISP_TEXT",
        "name": "DISP_TEXT_NAME",
        "component": "textarea",
        "lable": "备注",
        "comment": "备注",
        "placeholder": "请输入备注",
        "class": "col-xs-12",
        "visible": true,
        "require": false
      },
      {
        'column': 'NW_ID',
        'name': 'NW_ID_NAME',
        'component': 'input',
        'lable': '网点ID',
        'comment': '网点ID',
        'placeholder': '请输入网点ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': false,
        'require': false
      },
      {
        'column': 'COM_ID',
        'name': 'COM_ID_NAME',
        'component': 'input',
        'lable': "公司ID",
        'comment': '公司ID',
        'placeholder': '请输入公司ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': false,
        'require': false
      },
      {
        'column': 'DR_ID',
        'name': 'DR_ID_NAME',
        'component': 'input',
        'lable': "司机ID",
        'comment': '司机ID',
        'placeholder': '请输入司机ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': false,
        'require': false
      },
      {
        'column': 'TRUCK_ID',
        'name': 'TRUCK_ID_NAME',
        'component': 'input',
        'lable': "车辆ID",
        'comment': '车辆ID',
        'placeholder': '请输入车辆ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': false,
        'require': false
      },
      {
        'column': 'CARRI_ID',
        'name': 'CARRI_ID_NAME',
        'component': 'input',
        'lable': "承运商ID",
        'comment': '承运商ID',
        'placeholder': '请输入承运商ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': '',
        'visible': false,
        'require': false
      },
      {
        'column': 'ADD_USERID',
        'name': 'ADD_USERID_NAME',
        'component': 'input',
        'lable': '添加人账户ID',
        'comment': '添加人账户ID',
        'placeholder': '请输入添加人账户ID',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'insertValue': 'SYS_USER_ID',
        'model': null,
        'visible': false,
        'require': false
      },
      {
        'column': 'ADD_DATE',
        'name': 'ADD_DATE_NAME',
        'component': 'input',
        'lable': '添加时间',
        'comment': '添加时间',
        'placeholder': '添加时间',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'insertValue': 'SYS-DATETIME',
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'add'
        ],
        'model': null,
        'visible': false
      },
      {
        'column': 'ADD_NAME',
        'name': 'ADD_NAME_NAME',
        'component': 'input',
        'lable': '添加人',
        'comment': '添加人',
        'placeholder': '请输入添加人姓名',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'insertValue': 'SYS_USER_NAME',
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'add'
        ],
        'model': null,
        'visible': false
      },
      {
        'column': 'UPD_NAME',
        'name': 'UPD_NAME_NAME',
        'component': 'input',
        'lable': '修改人',
        'comment': '修改人',
        'placeholder': '修改人',
        'insertValue': 'SYS_USER_NAME',
        'model': null,
        'visible': false,
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'modify'
        ]
      },
      {
        'column': 'U_DELETE',
        'name': 'U_DELETE_NAME',
        'component': 'input',
        'lable': '删除表识',
        'comment': '删除表识',
        'placeholder': '请输入删除表识',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'model': 1,
        'visible': false,
        'require': false
      }
    ]
  };
  conditionColumns: any = {
    "type": "normal",
    "columns": [
      {
        "column": "STO_QUANTITY",
        "name": "STO_QUANTITY_NAME",
        "component": "input",
        "lable": "总数量",
        "comment": "总数量",
        "placeholder": "请输入总数量",
        "fromValue": "STO_QUANTITY",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "STO_VOLUME",
        "name": "STO_VOLUME_NAME",
        "component": "input",
        "lable": "总体积",
        "comment": "总体积",
        "placeholder": "请输入总体积",
        "fromValue": "STO_VOLUME",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "STO_WEIGHT",
        "name": "STO_WEIGHT_NAME",
        "component": "input",
        "lable": "总重量",
        "comment": "总重量",
        "placeholder": "请输入总重量",
        "fromValue": "STO_WEIGHT",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "TRAN_CUST_CODE",
      "title": "客户订单号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "SERVICE_TYPE_DICT",
      "title": "出入库类型",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "CUST_ID_DICT",
      "title": "客户名称",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "STO_CODE",
      "title": "计划流水号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "STO_QUANTITY",
      "title": "总数量(件)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "STO_VOLUME",
      "title": "总体积(立方米)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "STO_WEIGHT",
      "title": "总重量(吨)",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    }
  ];
  public allChecked = false;
  public indeterminate = false;

  constructor(
    private dataCenterService: DataCenterService,
    private requestService: RequestDataService,
    private utils: UtilsService,
    private modalService: NzModalService,
    private confirmModal: ConfirmModalService,
    private userInfoService: UserInfoService
  ) {
  }


  ngOnInit() {
    // 新增调度单
    this.dispId = _.cloneDeep(_.get(this.parentData, ['id']))
    this.doSearch();
    // todo: 新增调度单时，业务日期默认值为当天
    // let dispPlanDepart = this.baseForm.columns.find(column => column.column === 'DISP_PLAN_DEPART');
    // let text = this.baseForm.columns.find(column => column.column === 'DISP_TEXT');
    // if (!this.utils.Base_HasValue(dispPlanDepart.modal)) {
    //   dispPlanDepart.modal = new Date()
    // }
    // text.modal = 'new Date()'
  }

  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  findMainDataByColumn(pColumn) {
    let findColumns = _.filter(this.baseForm['columns'], oneColumn => {
      return oneColumn['column'] == pColumn;
    })
    if (this.utils.Base_HasValue(findColumns)) {
      return _.get(findColumns, [0, 'model'], null);
    } else {
      return null
    }
  }

  checkMainData(pColumn, pCheckRequire, pCompare, pMsg) {
    let check = true;
    let findValue = this.findMainDataByColumn(pColumn);
    let msg = '';
    if (pCheckRequire) // 如果是检查必填项
    {
      if (!this.utils.Base_HasValue(findValue)) {
        check = false;
        msg = pMsg + ': 是必填项';
        this.confirmModal.show('error',
          {title: '请填写必填信息。', content: msg});
      }

    } else {
      // 如果是比较值
      if (!this.utils.Base_HasValue(findValue)) {
        check = false;
        msg = pMsg + ': 是必填项';
      } else {
        if (findValue == pCompare) {
          check = false;
          msg = pMsg;
        }
      }
      if (!check) {
        this.confirmModal.show('error',
          {title: '错误', content: msg});
      }
    }
    return check;
  }


  doSave() {
    let check = true;
    // 重新计算总金额
    _.each(this.ordersList, oneItemData => {
      this.changeQuantity(null, oneItemData);
    });
    if (check) check = this.checkMainData('DISP_CODE', true, null, '调度单号');
    if (check) check = this.checkMainData('TASK_MANAGER', true, null, '保管员');
    if (check) check = this.checkMainData('DISP_PLAN_DEPART', true, null, '业务日期');
    if (check) {
        this.saveToJava();
    }
  }

  saveToJava() {
    let dispCode = this.baseForm.columns.find(column => column.column === 'DISP_CODE').model;
    let taskForkModel = this.baseForm.columns.find(column => column.column === 'TASK_FORK').model;
    let taskManagerModel = this.baseForm.columns.find(column => column.column === 'TASK_MANAGER').model;
    let taskTeamModel = this.baseForm.columns.find(column => column.column === 'TASK_TEAM').model;
    let dispLevelModel = this.baseForm.columns.find(column => column.column === 'DISP_LEVEL').model;
    let plantIdModel = this.baseForm.columns.find(column => column.column === 'PLANT_ID').model;
    let dispPlanDepartModel = this.baseForm.columns.find(column => column.column === 'DISP_PLAN_DEPART').model;
    let textModel = this.baseForm.columns.find(column => column.column === 'DISP_TEXT').model;

    let queryData = {
      'dispatchId': this.dispId,
      'dispCode': dispCode,
      'userId': this.ordersList[0].ADD_USERID,
      'taskFork': taskForkModel,
      'taskManager': taskManagerModel,
      'taskTeam': taskTeamModel,
      'dispLevel': dispLevelModel ? dispLevelModel : 2,
      'plantId': plantIdModel,
      'dispPlanDepart': dispPlanDepartModel,
      'text': textModel
    };
    const bodyData = null;
    this.requestService.doActionByHdbsthor('AppApi/MsTranDispatchController', 'stoDispatchSave', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    })
  }

  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_dispatch_list.json', {'DISP_ID': this.dispId}), // 1
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_order_detail_detail.json', {'DISP_ID': this.dispId}), //2
    );
    search.subscribe(values => {
      //运输调度计划信息
      this.backfillData(this.baseForm, values[0]['data']);  // this.dispatchData 被 this.baseForm 给替换掉了。

      //调度计划管理
      this.ordersList = values[1]['data'];
      _.each(this.ordersList,oneData=>{
        this.planId += oneData['STO_ID'] + ','
      })
    })
  }

  backfillDataByOne(_column, _value) {
    _.each(this.baseForm['columns'], oneColumn => {
      if (oneColumn['column'] == _column) {
        oneColumn['model'] = _value
      }
    })
  }

  findValue(pForm, pColumnName) {
    let bFind = false;
    let value = null;
    _.each(pForm['columns'], oneColumn => {
      if ((oneColumn['column'] == pColumnName) && (!bFind)) {
        value = oneColumn['model'];
        bFind = true;
      }
    });
    return value;
  }

  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        if (oneData[oneColumn['column']] == 'null') {
          oneData[oneColumn['column']] = null
        }
        oneColumn['model'] = oneData[oneColumn['column']];

        if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
          oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
        }
        if (oneColumn['component'] == 'file') {
          let url = oneData[oneColumn['column']];
          let fileName = this.utils.Base_getFileNameOnly(url);
          oneColumn['fileList'] = [];
          oneColumn['fileList'].push(
            {
              uid: this.utils.Base_getUuid(),
              name: fileName,
              status: 'done',
              url: url
            }
          )
        }
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
          if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
            oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
          }
        })
      })
    })
  }


  getArrayNumber(_oneData, _column) {
    let result = _.get(_oneData, [_column], 0);
    return Number(result)
  }

  changeQuantity($event, pOneData) {
    let that = this;
    let stoQuantity = 0;//总数量
    let stoVolume = 0;//总体积
    let stoWeight = 0;//总重量
    _.each(this.ordersList, function (oneData) {
      stoQuantity += that.getArrayNumber(oneData,'STO_QUANTITY');//总数量
      stoVolume += that.getArrayNumber(oneData,'STO_VOLUME');//总体积
      stoWeight += that.getArrayNumber(oneData,'STO_WEIGHT');//总重量
    });
    this.backfillDataByOne('DISP_QUANTITY', stoQuantity);
    this.backfillDataByOne('DISP_VOLUME', stoVolume);
    this.backfillDataByOne('DISP_WEIGHT', stoWeight);
  }

  // 多条选择修改
  changeServiceType(){
    let ids: any = [];
    _.each(this.ordersList, (OneData) => {
      if (OneData['checked']) {
        let obj: any = {};
        obj['id'] = OneData['STO_ID'];
        ids.push(obj);
      }
    });
    if (ids.length) {
      this.modalService.create({
        nzTitle: '修改出入库类型',
        nzContent: FormModalComponent,
        nzWidth: 500,
        "nzWrapClassName": "vertical-center-modal",
        "nzComponentParams": {
          "modalFormData": {
            "columns": [
              {
                "column": "SERVICE_TYPE",
                "name": "SERVICE_TYPE_NAME",
                "component": "select",
                "customize": 24,
                "lable": "出入库类型",
                "comment": "出入库类型",
                "placeholder": "出入库类型",
                "model": null,
                "visible": true,
                "require": true,
                "dictionary": "JieLiv2/basic/dict/TASK_SERVICE_TYPE.json",
                "dictionaryPF": null,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          //  调用确认结算处理接口
          const data = response.modalFormData.columns;
          let serviceType = _.get(data, [0, 'model'], '');
          if (!this.utils.Base_HasValue(serviceType)) {
            this.confirmModal.show('error', {'title': '错误', 'content': '出入库类型是必填项'});
            return false;
          }
          //  发送请求
          let queryData = {
            'updUserId': this.userInfoService.getUserInfo().USER_ID,
            'serviceType': serviceType,
          }
          const bodyData = {
            'ids': ids,
          };
          this.requestService.doActionByHdbsthor("AppApi/MsTranDispatchController", "stoChangeServiceTypeM", queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
            if (res.status == 500) {
              this.confirmModal.show('error', {
                'title': '执行错误',
                'content': res.error.message
              });
            } else if (res) {
              // 刷新页面
              this.doSearch();
            }
          });
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }
  // 单条修改出入库类型
  changeServiceTypeOne(oneData){
    this.modalService.create({
      nzTitle: '修改出入库类型',
      nzContent: FormModalComponent,
      nzWidth: 500,
      "nzWrapClassName": "vertical-center-modal",
      "nzComponentParams": {
        "modalFormData": {
          "columns": [
            {
              "column": "SERVICE_TYPE",
              "name": "SERVICE_TYPE_NAME",
              "component": "select",
              "customize": 24,
              "lable": "出入库类型",
              "comment": "出入库类型",
              "placeholder": "出入库类型",
              "model": null,
              "visible": true,
              "require": true,
              "dictionary": "JieLiv2/basic/dict/TASK_SERVICE_TYPE.json",
              "dictionaryPF": null,
            }
          ]
        }
      },
      nzOnOk: (response) => {
        //  调用确认结算处理接口
        const data = response.modalFormData.columns;
        let serviceType = _.get(data, [0, 'model'], '');
        // console.log('serviceType:',serviceType);
        if (!this.utils.Base_HasValue(serviceType)) {
          this.confirmModal.show('error', {'title': '错误', 'content': '出入库类型是必填项'});
          return false;
        }
        //  发送请求
        let queryData = {
          'updUserId': this.userInfoService.getUserInfo().USER_ID,
          'serviceType': serviceType,
          'id': oneData['STO_ID']
        }
        const bodyData = {};
        this.requestService.doActionByHdbsthor("AppApi/MsTranDispatchController", "stoChangeServiceType", queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            // 刷新页面
            this.doSearch();
          }
        });
      }
    });
  }

  refreshStatus(): void {
    const validData = this.ordersList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.ordersList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
}
