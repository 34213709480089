import {Component, OnInit} from '@angular/core';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UploadXHRArgs, NzMessageService} from "ng-zorro-antd";
import {UploadService} from '../../../service/upload.service';

@Component({
  selector: 'app-script-upload',
  templateUrl: './script-upload.component.html',
  styleUrls: ['./script-upload.component.css']
})
export class ScriptUploadComponent implements OnInit {
  public data: Object;
  public fileList: any = [];
  public fileUrl: string;

  constructor(private appConfigService: AppConfigService,
              private confirmModal: ConfirmModalService,
              private uploadService: UploadService,
              private msg: NzMessageService
  ) {}

  customReq = (item: UploadXHRArgs) => {

    if (this.appConfigService.config["userOssOrCos"] === 'COS') {
      return this.uploadService.doCosUpload(item.file, item["progress"], null, this.appConfigService.config["COS"]["scriptPath"] + this.data["SUBJECT_NAME"] + "/", this.data["SCRIPT_NAME"]).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config["COS"]["domain"] + '/' + result["url"];
        this.data["SCRIPT_URL"] = this.fileUrl;
      })
    } else {
      return this.uploadService.doAliOssUpload(item.file, item["progress"], null).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config["OSS"]["domain"] + '/' + result.name;
        this.data["SCRIPT_URL"] = this.fileUrl;
      })
    }
  };

  changeFileList = (result) => {
    if (result.type === 'success' && result.fileList.length === 2) {
      // 只传一个文件
      result.fileList.splice(0, 1);
    }
  };

  beforeUpload = (file: File) => {
    const isJPG = file.type === 'text/xml';
    if (!isJPG) {
      this.msg.error('You can only upload XML file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.msg.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
  }

  ngOnInit() {
    this.data = this;
  }

}
