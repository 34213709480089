import {Component, OnInit} from '@angular/core';
import * as echarts from 'echarts';
import {RequestDataService} from '../../../service/request-data.service';
import {UserInfoService} from '../../../service/user-info.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import {forkJoin as observableForkJoin} from 'rxjs';
import {UtilsService} from '../../../service/utils.service';
import * as alaSql from 'alasql';

@Component({
  selector: 'app-resume-chart',
  templateUrl: './resume-chart.component.html',
  styleUrls: ['./resume-chart.component.css']
})
export class ResumeChartComponent implements OnInit {
  listData = [];
  days: any = [];
  values: any = [];
  options: any;
  rank: any;
  orgId;

  constructor(private requestService: RequestDataService,
              private util: UtilsService,
              private userInfo: UserInfoService) {
  }

  teacherCount() {
    function teacherTypeCount(pCondition, teacherList) {
      return alaSql('SELECT count(*) acount FROM ? WHERE ' + pCondition, [teacherList]);
    }
  }

  dataformat() {
    this.options = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['最高访问量']
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.days
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} 次'
        }
      },
      series: [
        {
          type: 'line',
          data: this.values,
          markPoint: {
            data: [
              {type: 'max', name: '最大值'},
              {type: 'min', name: '最小值'}
            ]
          }
        }
      ]
    };
  }

  weekData(pWeek, pListData) {
    let num = 0;
    const weekOfday = parseInt(moment().format('E')) - 1; // 计算今天是这周第几天
    const last_monday = moment().week(pWeek).subtract(weekOfday, 'days').format('YYYY-MM-DD'); // 周一/开始日期
    const last_sunday = moment(last_monday).add(6, 'days').format('YYYY-MM-DD'); // 周日日期
    _.each(pListData, oneData => {
      if (oneData['ACCESS_TIME'] >= last_monday && oneData['ACCESS_TIME'] <= last_sunday) {
        num += oneData['acount'];
      }
    });
    return num;
  }

  ngOnInit() {
    const nowWeek = moment().week();
    const weekOfday = parseInt(moment().format('E')) - 1; // 计算今天是这周第几天
    const last_monday = moment().week(nowWeek - 6).subtract(weekOfday, 'days').format('YYYY-MM-DD'); // 八周前的周一/开始日期
    const weeks = [
      nowWeek - 7,
      nowWeek - 6,
      nowWeek - 5,
      nowWeek - 4,
      nowWeek - 3,
      nowWeek - 2,
      nowWeek - 1,
      nowWeek
    ];
    this.requestService.getDataByCondition('resume/re/re_access_records.json', {JOB_CODE: this.userInfo.getUserInfo().USER_NAME, BEGIN_DATE: last_monday}).subscribe(response => {
      this.listData = response.data;
      _.each(weeks, oneData => {
        this.values.push(this.weekData(oneData, this.listData));
        const monday = moment().week(oneData).subtract(weekOfday, 'days').format('YYY-MM-DD'); // 周一日期
        const sunday = moment(monday).add(6, 'days').format('MM-DD'); // 周日日期
        const week = '第' + oneData + '周(' + moment(monday).format('MM-DD') + '至' + sunday + ')';
        this.days.push(week);
      });
      this.days[this.days.length - 1] = '本' + this.days[this.days.length - 1].slice(this.days[this.days.length - 1].indexOf('周'));
      this.dataformat();
    });
    //ajax获取
    this.requestService.getDataByCondition('resume/re/re_teacher_ranking_getorg.json').subscribe(response => {
      if (this.util.Base_HasValue(response.data[0])) {
        //从response数组中获取ORG_ID
        this.orgId = response.data[0].ORG_ID;
        //定义teacherID变量（const为带值）,并获取
        const teacherID = response.data[0].ID;
        //ajax获取（获取匹配ORG_ID的表数据）
        this.requestService.getDataByCondition('resume/re/re_teacher_ranking.json', {ORG_ID: this.orgId}).subscribe(rep => {
          //从rep对象中获取data数组
          const array = rep.data;
          //find方法，找到这个data数组中匹配TEACHER_ID这个值的对象
          const teacherData = _.find(array, (item) => {
            return item['TEACHER_ID'] === teacherID;
          });
          //util.Base_HasValue（判断不为空）或大于0
          if (this.util.Base_HasValue(teacherData) && teacherData['PAGE_VIEW'] > 0) {
            let rankValue = 1;
            for (const oneData of array) {
              //取出数组中的访问量跟其他兄弟数组中的访问量对比，若大于则排名+1
              if (oneData['PAGE_VIEW'] > teacherData['PAGE_VIEW']) {
                rankValue += 1;
              }
            }
            this.rank = rankValue;
          } else {
            this.rank = '暂无排名';
          }
        });
      }
    });
  }
}
