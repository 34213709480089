import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../service/utils.service";

@Component({
  selector: 'app-descriptions-file',
  templateUrl: './descriptions-file.component.html',
  styleUrls: ['./descriptions-file.component.scss']
})
export class DescriptionsFileComponent implements OnInit {

  fileName: any;
  fileType: any;
  @Input() url: any;
  @Input() hideFileName: Boolean = true;

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.url)) {
      this.fileType = this.utils.Base_getFileType(this.url);
      this.fileName = this.utils.Base_getFileNameOnly(this.url);
    }
  }

}
