import {Component, OnInit} from '@angular/core';

import {RequestDataService} from './../../../service/request-data.service';
import {UtilsService} from './../../../service/utils.service';
import {UserInfoService} from './../../../service/user-info.service';
import {ExcelExportService} from './../../../service/excel-export.service';
import * as _ from 'underscore';
import * as moment from 'moment';


@Component({
  selector: 'app-collect-report',
  templateUrl: './collect-report.component.html',
  styleUrls: ['./collect-report.component.css']
})
export class CollectReportComponent implements OnInit {

  public searchColumns: any = {};

  public dateRange: any = [];

  public chartOption: any = {};

  public category: any = [];

  public date: string = '';

  public series: any = [];

  public exportData: any = [];

  constructor(private requestData: RequestDataService,
              private utils: UtilsService,
              private userInfo: UserInfoService,
              private excelExport: ExcelExportService) {
  }


  export() {
    this.excelExport.exportList(this.exportData, '站点收集报表');
  }


  // 根据当前选中区间回传查询参数
  getSearchData(model) {
    const today = moment(this.date).format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    const setDate = (data, category, value) => {
      data['ADD_TIME_BEGIN'] = moment(this.date).subtract(value - 1, 'days').format('YYYY-MM-DD');
      for (let i = value - 1; i > 0; i--) {
        category.push(moment(this.date).subtract(i, 'days').format('YYYY-MM-DD'));
      }
    };
    switch (model) {
      case 'NEARLY-SEVEN':
        setDate(data, category, 7);
        break;
      case 'NEARLY-TEN':
        setDate(data, category, 10);
        break;
      case 'NEARLY-FIFTEEN':
        setDate(data, category, 15);
        break;
      case 'NEARLY-TWENTY':
        setDate(data, category, 20);
        break;
      case 'NEARLY-MONTH':
        data['ADD_TIME_BEGIN'] = moment(this.date).subtract(1, 'month').format('YYYY-MM-DD');
        for (let i = 1; i <= 30; i++) {
          const date = moment(this.date).subtract(i, 'days').format('YYYY-MM-DD');
          category.push(date);
          // 如果是上个月这个一天不需要在循环；
          if (date === data['ADD_TIME_BEGIN']) {
            break;
          }
        }
        category = category.reverse();
        break;
    }
    category.push(today);
    return {
      searchData: data,
      category: category
    };
  }

  setOption() {
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false
        },
        formatter: (params) => {
          let str = params[0].axisValueLabel + '</br>';
          for (let i = 0; i < params.length; i++) {
            str += params[i].marker + params[i].seriesName + ': ' + params[i].value + '</br>';
          }
          return str;
        }
      },
      grid: [{
        left: 80,
        right: 60,
        height: '40%'
      }, {
        left: 80,
        right: 60,
        top: '51.5%',
        height: '40%'
      }],
      xAxis: [
        {
          type: 'category',
          data: this.category,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#A4A4A4',
              fontSize: 14
            }
          }
        },
        {
          gridIndex: 1,
          type: 'category',
          data: this.category,
          position: 'top',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#A4A4A4',
              fontSize: 14
            }
          }
        }
      ],
      yAxis: [
        {
          name: '总量(个)',
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: ['#C7C7C7']
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: ['#EFEFEF']
            }
          }
        },
        {
          gridIndex: 1,
          name: '总量(个)',
          type: 'value',
          inverse: true,
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: ['#C7C7C7']
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: ['#EFEFEF']
            }
          }
        }
      ],
      series: this.series
    };
  }

  // 封装插件需要的数据
  transformData(list) {
    let dateObj = _.groupBy(list, item => item['SELECT_DATE']);
    let lineList = [];
    _.each(this.category, (cate: string) => {
      let num = 0;
      if (dateObj[cate]) {
        _.each(dateObj[cate], item => {
          num += +item['SITE_NUM'];
        });
      }
      lineList.push(num);
    });
    let series: any = [{
      name: '总数',
      type: 'line',
      data: lineList,
      symbol: 'circle',
      symbolSize: 6,
      color: '#6BA7FB',
      lineStyle: {
        color: '#6BA7FB',
        shadowColor: '#6BA7FB',
        shadowBlur: 5,
        shadowOffsetX: 0.5,
        shadowOffsetY: 0.5,
        width: 2
      }
    }];
    let levelObj = _.groupBy(list, item => item['SITE_LEVEL_DICT']);
    let colorList = ['#60C3C8', '#F4BF85', '#B5A0DB', '#6BA6FB'];
    let i = 0;
    _.each(levelObj, (level, key) => {
      let dataList = [];
      let insideDateObj = _.groupBy(level, item => item['SELECT_DATE']);
      _.each(this.category, (cate: string) => {
        let num = 0;
        if (insideDateObj[cate] && insideDateObj[cate].length) {
          _.each(insideDateObj[cate], v => {
            num += +v['SITE_NUM'];
          });
        }
        dataList.push(num);
      });

      series.push({
        name: key,
        type: 'bar',
        barWidth: '45%',
        stack: '等级',
        data: dataList,
        //配置样式
        itemStyle: {
          //通常情况下：
          normal: {
            //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
            color: colorList[i]
          }
        }
      });
      i++;
    });
    colorList = ['#90736E', '#9AB65A', '#9096B2', '#CD8182', '#F4BF85', '#72ABEC', '#60C3C8', '#B5A1DB'];
    i = 0;
    let subjectObj = _.groupBy(list, item => item['SUBJECT_NAME']);
    _.each(subjectObj, (subject, key) => {
      let dataList = [];
      let subjectDateObj = _.groupBy(subject, item => item['SELECT_DATE']);
      _.each(this.category, (cate: string) => {
        let num = 0;
        if (subjectDateObj[cate] && subjectDateObj[cate].length) {
          _.each(subjectDateObj[cate], v => {
            num += +v['SITE_NUM'];
          });
        }
        dataList.push(num);
      });
      series.push({
        name: key,
        type: 'bar',
        barWidth: '45%',
        stack: '学科',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: dataList,
        itemStyle: {
          normal: {
            color: colorList[i]
          }
        }
      });
      i++;
    });
    this.series = series;
  }

  transformExportData(data) {
    let exportData = [];
    let sumObj = {
      '类别': '总数'
    };
    let selectObj = _.groupBy(data, item => item['SELECT_DATE']);
    _.each(this.category, (category: string) => {
      let num = 0;
      if (selectObj[category]) {
        _.each(selectObj[category], item => {
          num += +item['SITE_NUM'];
        });
      }
      sumObj[category] = num;
    });
    exportData.push(sumObj);
    let levelObj = _.groupBy(data, item => item['SITE_LEVEL_DICT']);
    _.each(levelObj, (levelList, level) => {
      let obj = {
        '类别': level
      };
      let dateObj = _.groupBy(levelList, date => date['SELECT_DATE']);
      _.each(this.category, (category: string) => {
        let num = 0;
        if (dateObj[category]) {
          _.each(dateObj[category], item => {
            num += +item['SITE_NUM'];
          });
        }
        obj[category] = num;
      });
      exportData.push(obj);
    });
    let subObj = _.groupBy(data, item => item['SUBJECT_NAME']);
    _.each(subObj, (subList, sub) => {
      let obj = {
        '类别': sub
      };
      let dateObj = _.groupBy(subList, date => date['SELECT_DATE']);
      _.each(this.category, (category: string) => {
        let num = 0;
        if (dateObj[category]) {
          _.each(dateObj[category], item => {
            num += +item['SITE_NUM'];
          });
        }
        obj[category] = num;
      });
      exportData.push(obj);
    });
    return exportData;
  }


  // 获取数据
  getRequestData() {
    let data = this.getSearchData(this.searchColumns.columns[0].model);
    this.category = data.category;
    let condition = data.searchData;
    _.each(this.searchColumns.columns, column => {
      if (_.contains(['SUBJECT_ID', 'SITE_LEVEL'], column['column'])) {
        if (this.utils.Base_HasValue(column['model'])) {
          condition[column['column']] = column['model'];
        }
      }
    });
    this.requestData.getDataByCondition('headLine/hl/hl_site_collect_report.json', condition).subscribe(result => {
      let data = result.data || [];
      const transData = this.transformData(data);
      // 第一步封装线图总数
      this.setOption();
      this.exportData = this.transformExportData(data);
    });
  }

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    this.dateRange = [
      {
        code: 'NEARLY-SEVEN',
        name: '近7天'
      },
      {
        code: 'NEARLY-TEN',
        name: '近10天'
      },
      {
        code: 'NEARLY-FIFTEEN',
        name: '近15天'
      },
      {
        code: 'NEARLY-TWENTY',
        name: '近20天'
      },
      {
        code: 'NEARLY-MONTH',
        name: '近1个月'
      }
    ];
    this.searchColumns = {
      'title': '站点收集报表',
      'columns': [
        {
          'column': 'DATE_RANGE',
          'name': 'DATE_RANGE_NAME',
          'component': 'select',
          'lable': '选择区间',
          'comment': '选择区间',
          'placeholder': '请选择区间',
          'model': 'NEARLY-SEVEN',
          'visible': true,
          'require': true,
          'itemArray': this.dateRange
        },
        {
          'column': 'SUBJECT_ID',
          'name': 'SUBJECT_ID_NAME',
          'component': 'poplist',
          'lable': '选择学科',
          'comment': '选择学科',
          'placeholder': '请选择学科',
          'model': null,
          'visible': true,
          'require': false,
          'dictionary': 'headLine/basic/dict/SUBJECT_NAME.json',
          'dictionaryPF': null
        },
        {
          'column': 'SITE_LEVEL',
          'name': 'SITE_LEVEL_NAME',
          'component': 'select',
          'lable': '选择等级',
          'comment': '选择等级',
          'placeholder': '请选择等级',
          'model': null,
          'visible': true,
          'require': false,
          'dictionary': 'headLine/basic/dict/SITE_LEVEL.json',
          'dictionaryPF': null
        }
      ]
    };
    this.requestData.getDataByCondition('headLine/basic/dict/SITE_LEVEL.json').subscribe(result => {
      let itemArray = result.data || [];
      this.searchColumns.columns[2].itemArray = itemArray;
      this.getRequestData();
    });

  }

  ngDoCheck(): void {
    if (this.searchColumns.title) {
      _.each(this.searchColumns.columns, item => {
        if (item['model'] !== item['oldModel']) {
          item['oldModel'] = item['model'];
          this.date = moment().format('YYYY-MM-DD');
          this.getRequestData();
        }
      });
    }

  }

}
