import {Component, OnInit, ViewChildren, QueryList, HostListener} from '@angular/core';
import {DataCenterService} from './../../service/data-center.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import * as _ from 'underscore';
import * as localForage from 'localforage';
import {ReadOnlyFormComponent} from '../../basicForm/read-only-form/read-only-form.component';
import {AppConfigService} from '../../service/app-config.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})

export class TabsComponent implements OnInit {

  @ViewChildren(ReadOnlyFormComponent) readonlyForm: QueryList<ReadOnlyFormComponent>;
  public tabData: any = {
    index: 0,
    list: [],
    nowTab: {},
    nowMenu: {}
  };
  public welcomeTitle = '';
  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    // backspace
    if (event.keyCode === 8) {
      const nowMenu = this.dataCenterService._dataObject.nowMenu;
      _.each(this.readonlyForm['_results'], (form: any) => {
        const formNowMenu = form['parentData'].nowMenu;
        if (nowMenu['MENU_ID'] === formNowMenu['MENU_ID']) {
          if (_.isFunction(form.gotoBack)) {
            // form.gotoBack();
          }
        }
      });
    }
  }

  constructor(private dataCenterService: DataCenterService,
              private utils: UtilsService,
              public config: AppConfigService,
              public appConfigService: AppConfigService,
              private confirmModal: ConfirmModalService) {

  }

  ngOnInit() {
    // 如果缓存中有data-center数据。则获取之后加载
    localForage.getItem('dataCenter').then((result: string) => {
      let storageDataCenter = {};
      if (this.utils.Base_HasValue(result)) {
        storageDataCenter = JSON.parse(result);
      }
      if (this.utils.Base_HasValue(storageDataCenter)) {
        this.dataCenterService._dataObject = storageDataCenter;
      }
      this.tabData = storageDataCenter;
    });
    this.welcomeTitle = this.config.config.logoConfig['welcomeInfo'];
  }

  closeTab(tab: string): void {
    this.dataCenterService.closeTab(tab);
  }

  changeTab(tab): void {
    let index = _.findIndex(this.tabData.list, (item) => {
      return item['id'] === tab['id'];
    });
    if (index != -1) {
      this.tabData.index = index;
      this.dataCenterService._dataObject.nowTab = this.dataCenterService._dataObject.list[this.dataCenterService._dataObject.index];
      this.dataCenterService._dataObject.nowMenu = this.dataCenterService._dataObject.list[this.dataCenterService._dataObject.index].nowMenu;
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }

  clearTabs() {
    let tab = {
      'id': 'todoCenterForm'
    }
    this.changeTab(tab);
    this.dataCenterService.clearDataExceptTodo();
    this.tabData = this.dataCenterService._dataObject;
  }

  clearAllTabs() {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要关闭所有标签页吗？',
      suc: () => {
        this.clearTabs();
      }
    });
  }
}
