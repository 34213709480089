import {Injectable} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {UserInfoService} from '../../service/user-info.service';
import {RequestDataService} from '../../service/request-data.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {AppConfigService} from '../../service/app-config.service';
import {AjaxService} from '../../service/ajax.service';
import {Md5} from 'ts-md5/dist/md5';
import * as _ from 'underscore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private autoInsert: AutoInsertService,
              private userInfo: UserInfoService,
              private requestData: RequestDataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService,
              private util: UtilsService,
              private userInfoService: UserInfoService,
              private ajaxService: AjaxService) {

  }


  // 审核不通过
  auditReject(selectList, rejectReason) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_user',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'USER_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'AUDIT_STATE'},
            {'COLUMN': 'REJECT_REASON'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'AUDIT_STATE': 'REJECT',
              'REJECT_REASON': rejectReason,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 审核通过
  auditPass(selectList) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_user',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'USER_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'AUDIT_STATE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'AUDIT_STATE': 'PASS',
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 重置密码
  resetHlUserPwd(selectList) {
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_user',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'USER_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PASSWD'}
          ],
          'RECORDS': [
            {
              'PASSWD': Md5.hashStr('123456')
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 分配
  assign(idList, data) {
    let ids = idList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SITE_STATE'},
            {'COLUMN': 'GRAB_USER'},
            {'COLUMN': 'GRAB_USER_NAME'},
            {'COLUMN': 'GRAB_COST'}
          ],
          'RECORDS': [
            {
              'SITE_STATE': 'ASSIGNED',
              'GRAB_USER': data.model,
              'GRAB_USER_NAME': data.modelName,
              'GRAB_COST': data.columnGroup[0].model
            }
          ]
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'SITE_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SCRIPT_STATE'}
          ],
          'RECORDS': [
            {
              'SCRIPT_STATE': 'ALEADY_DISTRIBUTION'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 精选审核不通过
  auditRejectChoice(newsIdsList, idList, data) {
    if (this.util.Base_HasValue(newsIdsList) && this.util.Base_HasValue(idList)) {
      let ids = idList.join('\',\'');
      ids = '(\'' + ids + '\')';
      let newsIds = newsIdsList.join('\',\'');
      newsIds = '(\'' + newsIds + '\')';
      const templateJson = {
        'LIST': []
      };
      templateJson.LIST.push({
        'TABLE': 'originallink',
        'ACTION': 'Update',
        'WHEREGROUP': [{
          'GROUP': 'GROUP_01',
          'CONDITION_OPERATOR': 'AND'
        }],
        'WHERE': [{
          'COLUMN': 'ID',
          'VALUE': ids,
          'OPERATOR': 'in',
          'VALUE_TYPE': 'int',
          'CONDITION_OPERATOR': 'AND',
          'GROUP': 'GROUP_01'
        }],
        'COLUMNS': [
          {'COLUMN': 'IS_NEWS'},
          {'COLUMN': 'AUDIT_REASON'},
          {'COLUMN': 'ORIGIN_STATE'}
        ],
        'RECORDS': [
          {
            'IS_NEWS': 0,
            'AUDIT_REASON': data.model,
            'ORIGIN_STATE': 'REJECTED'
          }
        ]
      });
      _.each(idList, (oneId) => {
        let obejct = {
          'TABLE': 'hl_news',
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ORIGINALLINK_ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ORIGINALLINK_ID'}
          ],
          'RECORDS': [
            {
              'ORIGINALLINK_ID': oneId
            }
          ]
        };
        templateJson.LIST.push(obejct);
      });
      return this.autoInsert.ExecJson(templateJson);
    }
  }

  // 待分配内容管理-分配人员
  selectChoice(idList, data) {
    let ids = idList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'originallink',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ORIGIN_STATE'},
            {'COLUMN': 'ASSIGN_USER_ID'},
            {'COLUMN': 'ASSIGN_USER_NAME'},
            {'COLUMN': 'ASSIGN_DATE'}
          ],
          'RECORDS': [
            {
              'ORIGIN_STATE': 'ASSIGNED',
              'ASSIGN_USER_ID': data.model,
              'ASSIGN_USER_NAME': data.modelName,
              'ASSIGN_DATE': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

// 待编辑内容管理-分配编辑人员
  selectEditUser(idList, data) {
    let ids = idList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_news',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'EDIT_USER_ID'},
            {'COLUMN': 'EDIT_NAME'}
          ],
          'RECORDS': [
            {
              'EDIT_USER_ID': data.model,
              'EDIT_NAME': data.modelName
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 停用站点
  disableSite(result, siteIds) {
    const user = this.userInfo.getUserInfo();
    let ids = siteIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    const scriptList = result.data || [];
    let records = [];
    _.each(scriptList, item => {
      if (item['ENABLE_STATE'] === 'ENABLE') {
        records.push({
          'ID': 'SYS_UUID',
          'SCRIPT_ID': item['ID'],
          'SITE_ID': item['SITE_ID'],
          'SITE_NAME': item['SITE_NAME'],
          'CHANNEL_NAME': item['CHANNEL_NAME'],
          'SUBJECT_ID': item['SUBJECT_ID'],
          'SUBJECT_NAME': item['SUBJECT_NAME'],
          'SCRIPT_NAME': item['SCRIPT_NAME'],
          'SCRIPT_URL': item['SCRIPT_URL'],
          'ORDER_TYPE': 'DISABLE',
          'ADD_USERID': user.USER_ID,
          'ADD_NAME': user.USERNAME,
          'ADD_TIME': 'SYS-DATETIME',
          'U_TIME': 'SYS-DATETIME'
        });
      }
    });
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_STOP',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'SITE_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }, {
            'COLUMN': 'ENABLE_STATE',
            'VALUE': 'ENABLE',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_STOP',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
        {
          'TABLE': 'hl_order',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'SCRIPT_ID'},
            {'COLUMN': 'SITE_ID'},
            {'COLUMN': 'SITE_NAME'},
            {'COLUMN': 'CHANNEL_NAME'},
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'SCRIPT_NAME'},
            {'COLUMN': 'SCRIPT_URL'},
            {'COLUMN': 'ORDER_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 启用站点
  enableSite(result, siteIds) {
    let ids = siteIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    const user = this.userInfo.getUserInfo();
    const scriptList = result.data || [];
    let records = [];
    _.each(scriptList, item => {
      if (item['ENABLE_STATE'] !== 'ENABLE') {
        records.push({
          'ID': 'SYS_UUID',
          'SCRIPT_ID': item['ID'],
          'SITE_ID': item['SITE_ID'],
          'SITE_NAME': item['SITE_NAME'],
          'CHANNEL_NAME': item['CHANNEL_NAME'],
          'SUBJECT_ID': item['SUBJECT_ID'],
          'SUBJECT_NAME': item['SUBJECT_NAME'],
          'SCRIPT_NAME': item['SCRIPT_NAME'],
          'SCRIPT_URL': item['SCRIPT_URL'],
          'ORDER_TYPE': 'ENABLE',
          'ADD_USERID': user.USER_ID,
          'ADD_NAME': user.USERNAME,
          'ADD_TIME': 'SYS-DATETIME',
          'U_TIME': 'SYS-DATETIME'
        });
      }
    });
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_ENABLE',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'SITE_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }, {
            'COLUMN': 'ENABLE_STATE',
            'VALUE': 'ENABLE',
            'OPERATOR': '<>',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_ENABLE',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
        {
          'TABLE': 'hl_order',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'SCRIPT_ID'},
            {'COLUMN': 'SITE_ID'},
            {'COLUMN': 'SITE_NAME'},
            {'COLUMN': 'CHANNEL_NAME'},
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'SCRIPT_NAME'},
            {'COLUMN': 'SCRIPT_URL'},
            {'COLUMN': 'ORDER_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 启用脚本
  scriptStart(pList, pIds) {
    const user = this.userInfo.getUserInfo();
    let ids = pIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    let records = [];
    _.each(pList, item => {
      records.push({
        'ID': 'SYS_UUID',
        'SCRIPT_ID': item['ID'],
        'SITE_ID': item['SITE_ID'],
        'SITE_NAME': item['SITE_NAME'],
        'CHANNEL_NAME': item['CHANNEL_NAME'],
        'SUBJECT_ID': item['SUBJECT_ID'],
        'SUBJECT_NAME': item['SUBJECT_NAME'],
        'SCRIPT_NAME': item['SCRIPT_NAME'],
        'SCRIPT_URL': item['SCRIPT_URL'],
        'ORDER_TYPE': 'ENABLE',
        'ADD_USERID': user.USER_ID,
        'ADD_NAME': user.USERNAME,
        'ADD_TIME': 'SYS-DATETIME',
        'U_TIME': 'SYS-DATETIME'
      });
    });
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_order',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'SCRIPT_ID'},
            {'COLUMN': 'SITE_ID'},
            {'COLUMN': 'SITE_NAME'},
            {'COLUMN': 'CHANNEL_NAME'},
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'SCRIPT_NAME'},
            {'COLUMN': 'SCRIPT_URL'},
            {'COLUMN': 'ORDER_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_ENABLE',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 停用脚本
  scriptStop(pList, pIds) {
    const user = this.userInfo.getUserInfo();
    let ids = pIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    let records = [];
    _.each(pList, item => {
      records.push({
        'ID': 'SYS_UUID',
        'SCRIPT_ID': item['ID'],
        'SITE_ID': item['SITE_ID'],
        'SITE_NAME': item['SITE_NAME'],
        'CHANNEL_NAME': item['CHANNEL_NAME'],
        'SUBJECT_ID': item['SUBJECT_ID'],
        'SUBJECT_NAME': item['SUBJECT_NAME'],
        'SCRIPT_NAME': item['SCRIPT_NAME'],
        'SCRIPT_URL': item['SCRIPT_URL'],
        'ORDER_TYPE': 'DISABLE',
        'ADD_USERID': user.USER_ID,
        'ADD_NAME': user.USERNAME,
        'ADD_TIME': 'SYS-DATETIME',
        'U_TIME': 'SYS-DATETIME'
      });
    });
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_order',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'SCRIPT_ID'},
            {'COLUMN': 'SITE_ID'},
            {'COLUMN': 'SITE_NAME'},
            {'COLUMN': 'CHANNEL_NAME'},
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'SCRIPT_NAME'},
            {'COLUMN': 'SCRIPT_URL'},
            {'COLUMN': 'ORDER_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'ENABLE_STATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'ENABLE_STATE': 'SEND_STOP',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 审核不通过
  acriptAuditFailed(pList, response, pIds) {
    const user = this.userInfo.getUserInfo();
    if (response.childForm._validateForm.invalid) {
      for (const key in response.childForm._validateForm.controls) {
        response.childForm._validateForm.controls[key].markAsDirty();
        response.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (!response.childForm._validateForm.valid) {
      return false;
    }
    let ids = pIds.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SCRIPT_STATE'},
            {'COLUMN': 'REJECTED_REASON'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'SCRIPT_STATE': 'REJECT',
              'REJECTED_REASON': response.modalFormData.columns[0].model,
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }


  // 添加数据集
  insertDataSet(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataSet/doSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 查询数据集
  searchDataSet(params) {
    const tmpUrl = this.config.config.quickSliver + '/dataSet/search?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  searchDsDetailByIdVersion(params) {
    const config = this.config.config;
    const tmpUrl = config.quickSliver + '/dataSet/searchByVersion?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 删除数据集
  removeDataSet(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataSet/delete?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 添加数据空间
  insertDataverse(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/doSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 查询数据空间
  searchDataverse(params) {
    const tmpUrl = this.config.config.quickSliver + '/dataverse/search?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 删除数据空间
  removeDataverse(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/delete?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 添加模型
  insertModel(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/model/doSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 模型查询
  searchModel(params) {
    const tmpUrl = this.config.config.quickSliver + '/model/search?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 删除模型
  deleteModel(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/model/delete?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  searchAllDataverse(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/searchAll?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 根据空间ID查询模型
  searchModelByDataverseId(params) {
    const tmpUrl = this.config.config.quickSliver + '/model/searchModelByDataverseId?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 重建EX库
  esRebuild() {
    const user = this.userInfo.getUserInfo();
    let params = {
      userId: user.USER_ID,
      userName: user.USERNAME
    };
    const tmpUrl = this.config.config.quickSliver + '/elasticsearch/doRebuild?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  addScriptToCarService(params) {
    const config = this.config.config;
    const tmpUrl = config.quickSliver + '/bazhuayu/addScriptToCar?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 脚本抓取结算
  scriptPay(pList, pData) {
    const user = this.userInfo.getUserInfo();
    let updateColumnArray = [];
    if (pList.length === 1) {
      let updateColumn: any = {
        'ID': pList[0].ID,
        'GRAB_PAY_STATE': 'PAY',
        'GRAB_ACTUAL_COST': pData[1].model,
        'GRAB_PAYMENT_NAME': user.USERNAME,
        'GRAB_PAYMENT_TIME': 'SYS-DATETIME',
        'GRAB_PAYMENT_USER_ID': user.USER_ID
      };
      updateColumnArray.push(updateColumn);
    } else {
      let updateColumn: any;
      _.each(pList, oneData => {
        updateColumn = {
          'ID': oneData['ID'],
          'GRAB_PAY_STATE': 'PAY',
          'GRAB_ACTUAL_COST': oneData['GRAB_COST'],
          'GRAB_PAYMENT_NAME': user.USERNAME,
          'GRAB_PAYMENT_TIME': 'SYS-DATETIME',
          'GRAB_PAYMENT_USER_ID': user.USER_ID
        };
        updateColumnArray.push(updateColumn);
      });
    }
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'GRAB_PAY_STATE'},
            {'COLUMN': 'GRAB_ACTUAL_COST'},
            {'COLUMN': 'GRAB_PAYMENT_NAME'},
            {'COLUMN': 'GRAB_PAYMENT_TIME'},
            {'COLUMN': 'GRAB_PAYMENT_USER_ID'}
          ],
          'RECORDS': updateColumnArray
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 脚本抓取结算
  collectPay(pList, pData) {
    const user = this.userInfo.getUserInfo();
    let updateColumnArray = [];
    if (pList.length === 1) {
      let updateColumn: any = {
        'ID': pList[0].ID,
        'COLLECT_PAY_STATE': 'PAY',
        'COLLECT_ACTUAL_COST': pData[1].model,
        'COLLECT_PAYMENT_NAME': user.USERNAME,
        'COLLECT_PAYMENT_TIME': 'SYS-DATETIME',
        'COLLECT_PAYMENT_USER_ID': user.USER_ID
      };
      updateColumnArray.push(updateColumn);
    } else {
      let updateColumn: any;
      _.each(pList, oneData => {
        updateColumn = {
          'ID': oneData['ID'],
          'COLLECT_PAY_STATE': 'PAY',
          'COLLECT_ACTUAL_COST': oneData['COLLECT_COST'],
          'COLLECT_PAYMENT_NAME': user.USERNAME,
          'COLLECT_PAYMENT_TIME': 'SYS-DATETIME',
          'COLLECT_PAYMENT_USER_ID': user.USER_ID
        };
        updateColumnArray.push(updateColumn);
      });
    }
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'COLLECT_PAY_STATE'},
            {'COLUMN': 'COLLECT_ACTUAL_COST'},
            {'COLUMN': 'COLLECT_PAYMENT_NAME'},
            {'COLUMN': 'COLLECT_PAYMENT_TIME'},
            {'COLUMN': 'COLLECT_PAYMENT_USER_ID'}
          ],
          'RECORDS': updateColumnArray
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  siteAudit(selectList) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SITE_STATE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'SITE_STATE': 'PENDING',
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  siteReject(selectList, reason) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SITE_STATE'},
            {'COLUMN': 'REJECT_REASON'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'SITE_STATE': 'REJECTED',
              'REJECT_REASON': reason,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 脚本取消
  scriptCanceled(siteId, pIds, pSiteIds) {
    this.requestData.getDataByCondition('headLine/hl/hl_site_script.json', {'SITE_ID': siteId}).subscribe((result) => {
      let data = result.data;
      // 将取消的脚本标记为u_delete = 0;
      _.each(data, oneData => {
        _.each(pIds, item => {
          if (oneData['ID'] === item) {
            oneData['U_DELETE'] = 0;
          }
        });
      });
      // 将取消的脚本以及站点id过滤掉，
      let listData = _.filter(data, oneData => {
        return oneData['U_DELETE'] === 1;
      });
      // 得到剩下的站点，过滤掉的站点就是需要取消的站点.
      let siteScriptArray = _.groupBy(listData, oneData => {
        return oneData['SITE_ID'];
      });
      let siteIds = [];
      _.each(siteScriptArray, (value, key) => {
        siteIds.push(key);
      });
      // 将传入的参数pSiteIds过滤掉剩下的站点,得到要取消的站点id
      let cancelSiteIds = _.difference(pSiteIds, siteIds);
      let ids = cancelSiteIds.join('\',\'');
      ids = '(\'' + ids + '\')';
      if (this.util.Base_HasValue(cancelSiteIds.length > 0)) {
        let templateJson = {
          'LIST': [
            {
              'TABLE': 'hl_site',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': ids,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'SITE_STATE'},
                {'COLUMN': 'ENABLE_STATE'}
              ],
              'RECORDS': [
                {
                  'SITE_STATE': 'CANCELED',
                  'ENABLE_STATE': 'NO_ENABLE'
                }
              ]
            }
          ]
        };
        this.autoInsert.ExecJson(templateJson).subscribe(response => {
        });
      }
    });
  }

  //站点下的脚本审核全通过后修改站点状态为已完成
  siteUpdate(pSiteId, pIdArray, pIds) {
    this.requestData.getDataByCondition('headLine/hl/hl_site_script_pass_count.json', {'SITE_ID': pSiteId}).subscribe(response => {
      let countData = response.data;
      let siteIds = [];
      _.each(countData, oneData => {
        siteIds.push(oneData['SITE_ID']);
      });
      let cancelSiteIds = _.difference(pIdArray, siteIds);
      if (cancelSiteIds.length > 0) {
        let ids = cancelSiteIds.join('\',\'');
        ids = '(\'' + ids + '\')';
        let siteSql = {
          'LIST': [
            {
              'TABLE': 'hl_site',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': ids,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'SITE_STATE'}
              ],
              'RECORDS': [
                {
                  'SITE_STATE': 'COMPLETED'
                }
              ]
            }
          ]
        };
        this.autoInsert.ExecJson(siteSql).subscribe((item) => {
          this.scriptCanceled(pSiteId, pIds, pIdArray);
        });
      } else {
        this.scriptCanceled(pSiteId, pIds, pIdArray);
      }
    });
  }

  editUserToNews(pNews, pObject) { //推送新闻并分配小编主方法
    let that = this;

    const user = this.userInfo.getUserInfo();

    function replaceNewLine(str) {
      const regList = [
        /<div style="?text-indent:\s*2em"?>[\r\n]\s*&nbsp;<\/div>/ig,
        /<p>[\r\n]?\s*&nbsp;\s*[\r\n]?<\/p>/ig,
        /<div>[\r\n]?\s*&nbsp;\s*[\r\n]?<\/div>/ig
      ];
      _.each(regList, function (reg) {
        str = str.replace(reg, '');
      });
      return str;
    }

    function Base_ManageDate(pDate, pStart, pEnd) {
      if (!that.util.Base_HasValue(pStart)) {
        pStart = 0;
      }
      if (!that.util.Base_HasValue(pEnd)) {
        pEnd = 10;
      }
      if (that.util.Base_HasValue(pDate)) {
        pDate = pDate.replace(/(^\s*)|(\s*$)/g, '').substr(pStart, pEnd);
        if (pDate.indexOf('年') != -1) {
          pDate = pDate.replace('年', '-');
        }
        if (pDate.indexOf('月') != -1) {
          pDate = pDate.replace('月', '-');
        }
        if (pDate.indexOf('日') != -1) {
          pDate = pDate.replace('日', '-');
        }
        if (pDate.indexOf('/') != -1) {
          pDate = pDate.replace('/', '-');
        }
        if (pDate.indexOf('／') != -1) {
          pDate = pDate.replace('／', '-');
        }
      }
      return pDate;
    }


    const condition = {
      'USERTYPE': 6,
      'EDIT_TYPE': 'SEDIT'
    };
    return this.requestData.getDataByCondition('headLine/system/system_user_detail.json', condition).subscribe((res) => {
      let editUserData = res.data;
      if (!this.util.Base_HasValue(editUserData[0])) {
        this.confirmModal.show('error', {title: '未查询到编辑用户'});
        return false;
      }
      if (!this.util.Base_HasValue(pNews[0])) {
        return false;
      }
      let index = 0;
      let check = true;
      let error = '';
      for (let i = 0; i < pNews.length; i++) {
        let oneNews = pNews[i];
        for (let j = 0; j < editUserData.length; j++) {
          oneNews.RELEASE_DATETIME = Base_ManageDate(oneNews.RELEASE_DATETIME, 0, 10);
          oneNews.RELEASE_DATETIME = oneNews.RELEASE_DATETIME.trim();
          if (oneNews.RELEASE_DATETIME.length < 10) {
            check = false;
            error = oneNews.TITLE + '-原文发布时间格式错误;';
          }
          if (index < editUserData.length) {
            oneNews.EDIT_USER_ID = editUserData[index].USER_ID;
            oneNews.EDIT_NAME = editUserData[index].EMP_NAME;
            index++;
          } else {
            oneNews.EDIT_USER_ID = editUserData[0].USER_ID;
            oneNews.EDIT_NAME = editUserData[0].EMP_NAME;
            index = 1;
          }
          break;
        }
      }
      if (!check) {
        this.confirmModal.show('error', {
          'title': error
        });
        return false;
      }
      let ids = '';
      let records = [];
      _.each(pNews, (item, index) => {
        if (pNews.length === index + 1) {
          ids += '\'' + item['ID'] + '\'';
        } else {
          ids += '\'' + item['ID'] + '\',';
        }
        let recordsValue = {
          'ID': 'SYS_UUID',
          'TITLE': item['TITLE'],
          'CONTENT': replaceNewLine(item['CONTENT']),
          'RELEASE_DATETIME': item['RELEASE_DATETIME'],
          'SOURCE': item['SOURCE'] || '',
          'AUTHOR': item['AUTHOR'],
          'EDITOR': item['EDITOR'],
          'AUDITOR': item['AUDITOR'],
          'CORRESPONDENT': item['CORRESPONDENT'],
          'WORDS': item['WORDS'],
          'ORIGINAL_PUBLISHER': item['ORIGINAL_PUBLISHER'],
          'ORIGINAL_LINK': item['ORIGINAL_LINK'],
          'REPRINT_STATEMENT': item['REPRINT_STATEMENT'],
          'HITS': item['HITS'],
          'TAGS': item['TAGS'],
          'SUBJECT_TYPE': item['SUBJECT_TYPE'],
          'SUBJECT_NAME': item['SUBJECT_TYPE_DICT'],
          'CONTENT_TYPE': item['CONTENT_TYPE'],
          'CONTENT_NAME': item['CONTENT_NAME'],
          'ORIGINAL_URL': item['ORIGINAL_URL'],
          'HASH_CODE': item['HASH_CODE'],
          'ADD_USERID': user.USER_ID,
          'ADD_NAME': user.USERNAME,
          'ADD_TIME': 'SYS-DATETIME',
          'UPD_USERID': user.USER_ID,
          'UPD_NAME': user.USERNAME,
          'UPD_TIME': 'SYS-DATETIME',
          'CRAWL_TIME': item['ADD_TIME'],
          'U_TIME': 'SYS-DATETIME',
          'U_DELETE': '1',
          'EDIT_NAME': item['EDIT_NAME'],
          'EDIT_USER_ID': item['EDIT_USER_ID'],
          'SOURCE_MENU_LV1': item['SOURCE_MENU_LV1'],
          'SOURCE_MENU_LV2': item['SOURCE_MENU_LV2'],
          'NEWS_PICTURES': item['NEWS_PICTURES'],
          'SOURCE_REAL': item['SOURCE_REAL'],
          'BZY_SCRIPT_VERSION': item['BZY_SCRIPT_VERSION'],
          'SEARCH_WORDS_ID': item['SEARCH_WORDS_ID'],
          'SEARCH_WORDS_NAME': item['SEARCH_WORDS_NAME'],
          'PUSH_USER_ID': user.USER_ID,
          'PUSH_USER_NAME': user.USERNAME,
          'PUSH_TIME': 'SYS-DATETIME'
        };
        records.push(recordsValue);
      });
      ids = '(' + ids + ')';
      let templateJson = {
        'LIST': [
          {
            'TABLE': 'originallink',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'IS_NEWS'}
            ],
            'RECORDS': [
              {
                'IS_NEWS': '1'
              }
            ]
          },
          {
            'TABLE': 'hl_news',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'TITLE'},
              {'COLUMN': 'CONTENT'},
              {'COLUMN': 'RELEASE_DATETIME'},
              {'COLUMN': 'SOURCE'},
              {'COLUMN': 'AUTHOR'},
              {'COLUMN': 'EDITOR'},
              {'COLUMN': 'AUDITOR'},
              {'COLUMN': 'CORRESPONDENT'},
              {'COLUMN': 'WORDS'},
              {'COLUMN': 'ORIGINAL_PUBLISHER'},
              {'COLUMN': 'ORIGINAL_LINK'},
              {'COLUMN': 'REPRINT_STATEMENT'},
              {'COLUMN': 'HITS'},
              {'COLUMN': 'TAGS'},
              {'COLUMN': 'SUBJECT_TYPE'},
              {'COLUMN': 'SUBJECT_NAME'},
              {'COLUMN': 'CONTENT_TYPE'},
              {'COLUMN': 'CONTENT_NAME'},
              {'COLUMN': 'ORIGINAL_URL'},
              {'COLUMN': 'HASH_CODE'},
              {'COLUMN': 'ADD_USERID'},
              {'COLUMN': 'ADD_NAME'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'UPD_USERID'},
              {'COLUMN': 'UPD_NAME'},
              {'COLUMN': 'UPD_TIME'},
              {'COLUMN': 'CRAWL_TIME'},
              {'COLUMN': 'U_TIME'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'EDIT_NAME'},
              {'COLUMN': 'EDIT_USER_ID'},
              {'COLUMN': 'SOURCE_MENU_LV1'},
              {'COLUMN': 'SOURCE_MENU_LV2'},
              {'COLUMN': 'SOURCE_REAL'},
              {'COLUMN': 'NEWS_PICTURES'},
              {'COLUMN': 'BZY_SCRIPT_VERSION'},
              {'COLUMN': 'SEARCH_WORDS_ID'},
              {'COLUMN': 'SEARCH_WORDS_NAME'},
              {'COLUMN': 'PUSH_USER_ID'},
              {'COLUMN': 'PUSH_USER_NAME'},
              {'COLUMN': 'PUSH_TIME'},
            ],
            'RECORDS': records
          }
        ]
      };
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '推送成功'});
          pObject.needSearch = true;
        }
      });
    });
  }

  // 内容编缉管理列表页审核通过按钮
  submitAudit(selectedList, pObject) {
    const user = this.userInfo.getUserInfo();
    let ok = true;
    let ids = '';
    let editIds = '';
    _.each(selectedList, function (item, index) {
      if (item['AUDITING_STATE'] === 1) {
        ok = false;
      } else if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
      editIds += ',\'' + item['ID'] + '\'';
    });
    ids = '(' + ids + ')';
    editIds = '(' + editIds.replace(',', '') + ')';
    if (ok) {
      this.confirmModal.show('confirm', {
        title: '您确定提交审核吗？',
        suc: () => {
          let templateJson = {
            'LIST': [
              {
                'TABLE': 'hl_news',
                'ACTION': 'Update',
                'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                'WHERE': [{
                  'COLUMN': 'ID',
                  'VALUE': ids,
                  'OPERATOR': 'in',
                  'VALUE_TYPE': 'int',
                  'CONDITION_OPERATOR': 'AND',
                  'GROUP': 'GROUP_01'
                }],
                'COLUMNS': [
                  {'COLUMN': 'AUDITING_STATE'}
                ],
                'RECORDS': [
                  {
                    'AUDITING_STATE': 2
                  }
                ]
              }
            ]
          };
          // 修改编辑人，提交审核时间
          if (editIds !== '()') {
            let editUpdateSql: any = {
              'TABLE': 'hl_news',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': editIds,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'EDIT_NAME'},
                {'COLUMN': 'SUBMIT_AUDIT_TIME'}

              ],
              'RECORDS': [
                {
                  'EDIT_NAME': user.USERNAME,
                  'SUBMIT_AUDIT_TIME': 'SYS-DATETIME'
                }
              ]
            };
            templateJson.LIST.push(editUpdateSql);
          }
          this.autoInsert.ExecJson(templateJson).subscribe(response => {
            if (response.state === 'success') {
              this.confirmModal.show('success', {'title': '审核成功'});
              pObject.needSearch = true;
            }
          });
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '审核通过的数据不允许再提交审核。'});
    }
  }

  auditRejectCollectSite(selectList, result) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_collect_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SITE_STATE'},
            {'COLUMN': 'AUDIT_USER_ID'},
            {'COLUMN': 'AUDIT_USER_NAME'},
            {'COLUMN': 'AUDIT_DATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'REJECT_REASON'}
          ],
          'RECORDS': [
            {
              'SITE_STATE': 'REJECT',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID,
              'AUDIT_USER_ID': user.USER_ID,
              'AUDIT_USER_NAME': user.USER_ID,
              'AUDIT_DATE': 'SYS-DATETIME',
              'REJECT_REASON': result.model,
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 采集站点收集管理--审核通过
  auditPassCollectSite(selectList) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_collect_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SITE_STATE'},
            {'COLUMN': 'AUDIT_USER_ID'},
            {'COLUMN': 'AUDIT_USER_NAME'},
            {'COLUMN': 'AUDIT_DATE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'REJECT_REASON'}
          ],
          'RECORDS': [
            {
              'SITE_STATE': 'PASS',
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID,
              'AUDIT_USER_ID': user.USER_ID,
              'AUDIT_USER_NAME': user.USER_ID,
              'AUDIT_DATE': 'SYS-DATETIME',
              'REJECT_REASON': '',
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 修改脚本运行频率
  updateScriptFrequency(selectList, model) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SCRIPT_RUN_FREQUENCY'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'SCRIPT_RUN_FREQUENCY': model,
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  updateNewsSubject(selectList, subjectType, subjectName, pTableName) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': pTableName,
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SUBJECT_TYPE'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'SUBJECT_TYPE': subjectType,
              'SUBJECT_NAME': subjectName,
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  updateSiteSubject(selectList, oneData) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_site',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_CODE'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'SUBJECT_ID': oneData['code'],
              'SUBJECT_CODE': oneData['SUB_CODE'],
              'SUBJECT_NAME': oneData['name'],
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        },
        {
          'TABLE': 'hl_script',
          'ACTION': 'Update',
          'WHEREGROUP': [{
            'GROUP': 'GROUP_01',
            'CONDITION_OPERATOR': 'AND'
          }],
          'WHERE': [{
            'COLUMN': 'SITE_ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'SUBJECT_ID': oneData['code'],
              'SUBJECT_NAME': oneData['name'],
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  newsOnline(selectList, pObject) {
    const user = this.userInfo.getUserInfo();
    let ids = '';
    let javaIds = '';
    let onlineIds = '';
    let ok = true;
    _.each(selectList, (item, index) => {
      if (item['AUDITING_STATE'] !== 4) {
        ok = false;
      } else if (selectList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
      if (!this.util.Base_HasValue(item['AUDITOR_TIME'])) {
        onlineIds += ',\'' + item['ID'] + '\'';
      }
    });
    javaIds = ids;
    ids = '(' + ids + ')';
    onlineIds = '(' + onlineIds.replace(',', '') + ')';
    if (ok) {
      let templateJson = {
        'LIST': [
          {
            'TABLE': 'hl_news',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'AUDITING_STATE'},
              {'COLUMN': 'PASS_AUDIT_TIME'},
              {'COLUMN': 'AUDITOR_USER_ID'},
              {'COLUMN': 'AUDITOR_NAME'}
            ],
            'RECORDS': [
              {
                'AUDITING_STATE': 1,
                'PASS_AUDIT_TIME': 'SYS-DATETIME',
                'AUDITOR_USER_ID': user.USER_ID,
                'AUDITOR_NAME': user.USERNAME
              }
            ]
          }
        ]
      };
      // 如果上架时间不存在，修改为通过审核时间
      let hlNewsUpdate: any = {
        'TABLE': 'hl_news',
        'ACTION': 'Update',
        'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
        'WHERE': [{
          'COLUMN': 'ID',
          'VALUE': onlineIds,
          'OPERATOR': 'in',
          'VALUE_TYPE': 'int',
          'CONDITION_OPERATOR': 'AND',
          'GROUP': 'GROUP_01'
        }],
        'COLUMNS': [
          {'COLUMN': 'AUDITOR_TIME'}

        ],
        'RECORDS': [
          {
            'AUDITOR_TIME': 'SYS-DATETIME'
          }
        ]
      };
      if (onlineIds !== '()') {
        templateJson.LIST.push(hlNewsUpdate);
      }
      let param = {
        newsIds: javaIds,
        'userId': this.userInfoService.getUserInfo().USER_ID,
        'userName': this.userInfoService.getUserInfo().USERNAME
      };
      this.confirmModal.show('confirm', {
        title: '您确定上架吗？',
        suc: () => {
          this.requestData.doActionByHdbsthorJson('hdbsthor-headline', 'NewsController', this.config.config.MysqlPF + '/' + this.config.config.EsPF + '/saveNewsToEsByIds', param).subscribe((result) => {
            if (result === true) {
              this.autoInsert.ExecJson(templateJson).subscribe(response => {
                if (response.state === 'success') {
                  this.confirmModal.show('success', {'title': '上架成功'});
                  pObject.needSearch = true;
                } else {
                  this.confirmModal.show('error', {'title': '上架失败，请刷新重试'});
                }
              });
            } else {
              this.confirmModal.show('error', {'title': '上架不通过，请检查数据格式是否正确。'});
            }
          });
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '已下架的数据才可进行上架操作'});
    }
  }

  // 待审核新闻审核通过按钮
  adopt(selectList, pObject) {
    const user = this.userInfo.getUserInfo();
    let ids = '';
    let javaIds = '';
    let onlineIds = '';
    let ok = true;
    _.each(selectList, (item, index) => {
      if (item['AUDITING_STATE'] !== 2) {
        ok = false;
      } else if (selectList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
      if (!this.util.Base_HasValue(item['AUDITOR_TIME'])) {
        onlineIds += ',\'' + item['ID'] + '\'';
      }
    });
    javaIds = ids;
    ids = '(' + ids + ')';
    onlineIds = '(' + onlineIds.replace(',', '') + ')';
    if (ok) {
      let templateJson = {
        'LIST': [
          {
            'TABLE': 'hl_news',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'AUDITING_STATE'},
              {'COLUMN': 'PASS_AUDIT_TIME'},
              {'COLUMN': 'AUDITOR_USER_ID'},
              {'COLUMN': 'AUDITOR_NAME'}
            ],
            'RECORDS': [
              {
                'AUDITING_STATE': 1,
                'PASS_AUDIT_TIME': 'SYS-DATETIME',
                'AUDITOR_USER_ID': user.USER_ID,
                'AUDITOR_NAME': user.USERNAME
              }
            ]
          }
        ]
      };
      // 如果上架时间不存在，修改为通过审核时间
      let hlNewsUpdate: any = {
        'TABLE': 'hl_news',
        'ACTION': 'Update',
        'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
        'WHERE': [{
          'COLUMN': 'ID',
          'VALUE': onlineIds,
          'OPERATOR': 'in',
          'VALUE_TYPE': 'int',
          'CONDITION_OPERATOR': 'AND',
          'GROUP': 'GROUP_01'
        }],
        'COLUMNS': [
          {'COLUMN': 'AUDITOR_TIME'}

        ],
        'RECORDS': [
          {
            'AUDITOR_TIME': 'SYS-DATETIME'
          }
        ]
      };
      if (onlineIds !== '()') {
        templateJson.LIST.push(hlNewsUpdate);
      }
      let param = {
        newsIds: javaIds,
        'userId': this.userInfoService.getUserInfo().USER_ID,
        'userName': this.userInfoService.getUserInfo().USERNAME
      };
      this.requestData.doActionByHdbsthorJson('hdbsthor-headline', 'NewsController', this.config.config.MysqlPF + '/' + this.config.config.EsPF + '/saveNewsToEsByIds', param).subscribe((result) => {
        if (result === true) {
          this.autoInsert.ExecJson(templateJson).subscribe(response => {
            if (response.state === 'success') {
              this.confirmModal.show('success', {'title': '审核成功'});
              pObject.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '审核失败，请刷新重试'});
            }
          });
        } else {
          this.confirmModal.show('error', {'title': '审核不通过，请检查数据格式是否正确。'});
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '审核中数据才可进行通过审核操作'});
    }
  }

  cancelAudit(selectedList, pObject) {
    let ids = '';
    _.each(selectedList, (item, index) => {
      if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
    });
    ids = '(' + ids + ')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_news',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'AUDITING_STATE'}
          ],
          'RECORDS': [
            {
              'AUDITING_STATE': 0
            }
          ]
        }
      ]
    };
    this.autoInsert.ExecJson(templateJson).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {'title': '取消成功'});
        pObject.needSearch = true;
      } else {
        this.confirmModal.show('error', {'title': '取消失败，请刷新重试'});
      }
    });
  }

  //审核驳回：修改审核状态为不通过，并且把是否提交审核改为否
  reject(selectedList, pObject, result) {
    if (result.childForm._validateForm.invalid) {
      for (const key in result.childForm._validateForm.controls) {
        result.childForm._validateForm.controls[key].markAsDirty();
        result.childForm._validateForm.controls[key].updateValueAndValidity();
      }
    }
    if (result.childForm._validateForm.valid) {
      let assignObj = result.modalFormData.columns[0];
      let ids = '';
      let ok = true;
      let javaIds = '';
      _.each(selectedList, (item, index) => {
        if (selectedList.length === index + 1) {
          ids += '\'' + item['ID'] + '\'';
        } else {
          ids += '\'' + item['ID'] + '\',';
        }
      });
      javaIds = ids;
      ids = '(' + ids + ')';
      let templateJson = {
        'LIST': [
          {
            'TABLE': 'hl_news',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'AUDITING_STATE'},
              {'COLUMN': 'REASON_FOR_REMOVAL'}
            ],
            'RECORDS': [
              {
                'AUDITING_STATE': 4,
                'REASON_FOR_REMOVAL': assignObj.model
              }
            ]
          }
        ]
      };
      let param = {
        'newsIds': javaIds,
        'userId': this.userInfoService.getUserInfo().USER_ID,
        'userName': this.userInfoService.getUserInfo().USERNAME
      };
      this.requestData.doActionByHdbsthorJson('hdbsthor-headline', 'NewsController', this.config.config.MysqlPF + '/' + this.config.config.EsPF + '/deleteNewsEs', param).subscribe((result) => {
        if (result === true) {
          this.autoInsert.ExecJson(templateJson).subscribe(response => {
            if (response.state === 'success') {
              this.confirmModal.show('success', {'title': '取消成功'});
              pObject.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '取消失败，请刷新重试'});
            }
          });
        } else {
          this.confirmModal.show('error', {'title': result.error.message});
        }
      });
    } else {
      return false;
    }
  }

  // 预发布
  preRelease(selectedList, pData, pObject) {
    let listData = pData.modalFormData.columns;
    let ok = true;
    let ids = '';
    _.each(selectedList, (item, index) => {
      if (item['AUDITING_STATE'] !== 2) {
        ok = false;
      } else if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
    });
    ids = '(' + ids + ')';
    if (ok) {
      this.confirmModal.show('confirm', {
        title: '您确定执行预发布吗？',
        suc: () => {
          let templateJson = {
            'LIST': [
              {
                'TABLE': 'hl_news',
                'ACTION': 'Update',
                'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                'WHERE': [{
                  'COLUMN': 'ID',
                  'VALUE': ids,
                  'OPERATOR': 'in',
                  'VALUE_TYPE': 'int',
                  'CONDITION_OPERATOR': 'AND',
                  'GROUP': 'GROUP_01'
                }],
                'COLUMNS': [
                  {'COLUMN': 'AUDITING_STATE'},
                  {'COLUMN': 'PRE_RELEASE_DATE'},
                  {'COLUMN': 'PASS_AUDIT_TIME'},
                  {'COLUMN': 'AUDITOR_TIME'},
                  {'COLUMN': 'PRE_RELEASE_TIME'}
                ],
                'RECORDS': [
                  {
                    'AUDITING_STATE': 3,
                    'PRE_RELEASE_DATE': listData[0].model,
                    'PASS_AUDIT_TIME': 'SYS-DATETIME',
                    'AUDITOR_TIME': moment((listData[0].model + ' ' + listData[1].model), 'YYYY-MM-DD HH:mm:ss').add(8, 'h'),
                    'PRE_RELEASE_TIME': listData[1].model
                  }
                ]
              }
            ]
          };
          this.autoInsert.ExecJson(templateJson).subscribe((response) => {
            if (response.state === 'success') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pObject.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行失败，请刷新重试。'});
            }
          });
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '审核中新闻才允许进行预发布'});
    }
  }

  // 取消预发布
  cancelPreRelease(selectedList, pObject) {
    var ok = true;
    var ids = '';
    _.each(selectedList, (item, index) => {
      if (item['AUDITING_STATE'] !== 3) {
        ok = false;
      } else if (selectedList.length === index + 1) {
        ids += '\'' + item['ID'] + '\'';
      } else {
        ids += '\'' + item['ID'] + '\',';
      }
    });
    ids = '(' + ids + ')';
    if (ok) {
      let templateJson = {
        'LIST': [
          {
            'TABLE': 'hl_news',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': ids,
              'OPERATOR': 'in',
              'VALUE_TYPE': 'int',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'AUDITING_STATE'},
              {'COLUMN': 'PRE_RELEASE_DATE'},
              {'COLUMN': 'PASS_AUDIT_TIME'},
              {'COLUMN': 'AUDITOR_TIME'},
              {'COLUMN': 'PRE_RELEASE_TIME'}
            ],
            'RECORDS': [
              {
                'AUDITING_STATE': 2,
                'PRE_RELEASE_DATE': null,
                'PASS_AUDIT_TIME': null,
                'PRE_RELEASE_TIME': null,
                'AUDITOR_TIME': null
              }
            ]
          }
        ]
      };
      this.autoInsert.ExecJson(templateJson).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {'title': '执行成功'});
          pObject.needSearch = true;
        } else {
          this.confirmModal.show('error', {'title': '执行失败，请刷新重试。'});
        }
      });
    } else {
      this.confirmModal.show('error', {'title': '预发布数据才允许进行取消预发布操作'});
    }
  }

  // 发布数据集
  publishDataset(ids) {
    const user = this.userInfo.getUserInfo();
    let datasetIds = '';
    let records = [];
    _.each(ids, (item: any) => {
      datasetIds += '\',\'' + item.datasetId;
      records.push({
        'ID': 'SYS_UUID',
        'DATASET_ID': item.datasetId,
        'DATAVERSE_ID': item.dataverseId,
        'DATA_TYPE': 'dataset',
        'ADD_USERID': user.USER_ID,
        'ADD_NAME': user.USERNAME,
        'ADD_TIME': 'SYS-DATETIME',
        'U_TIME': 'SYS-DATETIME'
      });
    });
    datasetIds = '(\'' + datasetIds + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'ds_dataset',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': datasetIds,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'DATASET_STATE'},
            {'COLUMN': 'ON_DATE'},
            {'COLUMN': 'ON_DATETIME'},
            {'COLUMN': 'ON_USER_ID'},
            {'COLUMN': 'ON_USER_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'DATASET_STATE': 'ONSALE',
              'ON_DATE': 'SYS-DATETIME',
              'ON_DATETIME': 'SYS-DATETIME',
              'ON_USER_ID': user.USER_ID,
              'ON_USER_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        },
        {
          'TABLE': 'ds_dataset_queue',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'DATASET_ID'},
            {'COLUMN': 'DATAVERSE_ID'},
            {'COLUMN': 'DATA_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 发布数据集
  publishDatasetNode(ids) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      dataset: ids
    };
    const tmpUrl = this.config.config.quickSliver + '/dataSet/publishDataset?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 发布数据空间
  publishDataverse(ids) {
    const user = this.userInfo.getUserInfo();
    let idstr = ids.join('\',\'');
    idstr = '(\'' + idstr + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'ds_dataverse',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': idstr,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'DATAVERSE_STATE'},
            {'COLUMN': 'ON_DATE'},
            {'COLUMN': 'ON_DATETIME'},
            {'COLUMN': 'ON_USER_ID'},
            {'COLUMN': 'ON_USER_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'DATAVERSE_STATE': 'ONSALE',
              'ON_DATE': 'SYS-DATETIME',
              'ON_DATETIME': 'SYS-DATETIME',
              'ON_USER_ID': user.USER_ID,
              'ON_USER_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        },
        {
          'TABLE': 'ds_dataset_queue',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'DATASET_ID'},
            {'COLUMN': 'DATAVERSE_ID'},
            {'COLUMN': 'DATA_TYPE'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'ID': 'SYS_UUID',
              'DATASET_ID': '',
              'DATAVERSE_ID': ids[0],
              'DATA_TYPE': 'dataverse',
              'ADD_USERID': user.USER_ID,
              'ADD_NAME': user.USERNAME,
              'ADD_TIME': 'SYS-DATETIME',
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 数据集添加用户组
  addGroupAndUser(model) {
    const user = this.userInfo.getUserInfo();
    let records = [];
    _.each(model.USER_NAME.split(','), (item, index) => {
      records.push({
        'ID': 'SYS_UUID',
        'GROUP_ID': model['GROUP_ID'],
        'GROUP_NAME': model['GROUP_NAME'],
        'USER_ID': item,
        'USER_NAME': model['USER_NAMEName'].split(' ')[index],
        'ADD_USERID': user.USER_ID,
        'ADD_NAME': user.USERNAME,
        'ADD_TIME': 'SYS-DATETIME',
        'U_TIME': 'SYS-DATETIME'
      });
    });
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'ds_group',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'GROUP_NAME'},
            {'COLUMN': 'GROUP_DESC'},
            {'COLUMN': 'DATAVERSE_ID'},
            {'COLUMN': 'DATAVERSE_NAME'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'ID': model['GROUP_ID'],
              'GROUP_NAME': model['GROUP_NAME'],
              'GROUP_DESC': model['GROUP_DESC'],
              'DATAVERSE_ID': model['DATAVERSE_ID'],
              'DATAVERSE_NAME': model['DATAVERSE_NAME'],
              'ADD_USERID': user.USER_ID,
              'ADD_NAME': user.USERNAME,
              'ADD_TIME': 'SYS-DATETIME',
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        },
        {
          'TABLE': 'ds_group_user',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'GROUP_ID'},
            {'COLUMN': 'GROUP_NAME'},
            {'COLUMN': 'USER_ID'},
            {'COLUMN': 'USER_NAME'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 数据空间配置权限保存接口
  saveDataverseGroup(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/saveDataversePower?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 文件权限配置审核通过
  filePowerPass(list) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      applyList: list
    };
    const tmpUrl = this.config.config.quickSliver + '/dataverse/userGroupApplyPass?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 文件权限配置审核不通过
  filePowerReject(selectList, reason) {
    const user = this.userInfo.getUserInfo();
    let ids = selectList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson = {
      'LIST': [
        {
          'TABLE': 'ds_file_apply',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'AUDIT_STATE'},
            {'COLUMN': 'AUDIT_USER_ID'},
            {'COLUMN': 'AUDIT_USER_NAME'},
            {'COLUMN': 'AUDIT_TIME'},
            {'COLUMN': 'REFUSAL_REASON'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'AUDIT_STATE': 'AUDIT_REJECT',
              'AUDIT_USER_ID': user.USER_ID,
              'AUDIT_USER_NAME': user.USERNAME,
              'AUDIT_TIME': 'SYS-DATETIME',
              'REFUSAL_REASON': reason,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  editGroupAndUser(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/userGroupEdit?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 数据集文件配置权限
  fileGroupPermission(data) {
    const user = this.userInfo.getUserInfo();
    let params = Object.assign(data, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/dataverse/saveFilePower?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 数据空间删除自建用户组
  removeGroupUser(dataverseId, groupId) {
    const user = this.userInfo.getUserInfo();
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'ds_group',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'DATAVERSE_ID',
            'VALUE': dataverseId,
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }, {
            'COLUMN': 'ID',
            'VALUE': groupId,
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'U_DELETE': 0,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        },
        {
          'TABLE': 'ds_group_user',
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'GROUP_ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [{
            'COLUMN': 'GROUP_ID'
          }],
          'RECORDS': [{
            'GROUP_ID': groupId
          }]
        },
        {
          'TABLE': 'ds_group_file',
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'GROUP_ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [{
            'COLUMN': 'GROUP_ID'
          }],
          'RECORDS': [{
            'GROUP_ID': groupId
          }]
        },
        {
          'TABLE': 'ds_group_dataverse',
          'ACTION': 'Delete',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'GROUP_ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [{
            'COLUMN': 'GROUP_ID'
          }],
          'RECORDS': [{
            'GROUP_ID': groupId
          }]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 图书馆信息录入
  saveLibInfo(libId, modeType, data) {
    const user = this.userInfo.getUserInfo();
    if (modeType === 'modify') {
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'ds_lib_info',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': libId,
              'OPERATOR': '=',
              'VALUE_TYPE': 'string',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'TOTAL_AREA'},
              {'COLUMN': 'BOOKS'},
              {'COLUMN': 'E_BOOKDS'},
              {'COLUMN': 'ANCIENT_BOODS'},
              {'COLUMN': 'CHINESE_DATABASE'},
              {'COLUMN': 'ENGLISH_DATABASE'},
              {'COLUMN': 'FEATURED_DATABASE'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'UPD_TIME'},
              {'COLUMN': 'UPD_NAME'},
              {'COLUMN': 'UPD_USERID'},
              {'COLUMN': 'U_TIME'}
            ],
            'RECORDS': [
              {
                'TOTAL_AREA': data['TOTAL_AREA'],
                'BOOKS': data['BOOKS'],
                'E_BOOKDS': data['E_BOOKDS'],
                'ANCIENT_BOODS': data['ANCIENT_BOODS'],
                'CHINESE_DATABASE': data['CHINESE_DATABASE'],
                'ENGLISH_DATABASE': data['ENGLISH_DATABASE'],
                'FEATURED_DATABASE': data['FEATURED_DATABASE'],
                'UPD_TIME': 'SYS-DATETIME',
                'UPD_NAME': user.USERNAME,
                'UPD_USERID': user.USER_ID,
                'U_TIME': 'SYS-DATETIME',
                'U_DELETE': 1
              }
            ]
          }
        ]
      };
      return this.autoInsert.ExecJson(templateJson);
    } else {
      const templateJson = {
        'LIST': [
          {
            'TABLE': 'ds_lib_info',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'TOTAL_AREA'},
              {'COLUMN': 'BOOKS'},
              {'COLUMN': 'E_BOOKDS'},
              {'COLUMN': 'ANCIENT_BOODS'},
              {'COLUMN': 'CHINESE_DATABASE'},
              {'COLUMN': 'ENGLISH_DATABASE'},
              {'COLUMN': 'FEATURED_DATABASE'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'ADD_NAME'},
              {'COLUMN': 'ADD_USERID'},
              {'COLUMN': 'U_TIME'}
            ],
            'RECORDS': [
              {
                'ID': libId,
                'TOTAL_AREA': data['TOTAL_AREA'],
                'BOOKS': data['BOOKS'],
                'E_BOOKDS': data['E_BOOKDS'],
                'ANCIENT_BOODS': data['ANCIENT_BOODS'],
                'CHINESE_DATABASE': data['CHINESE_DATABASE'],
                'ENGLISH_DATABASE': data['ENGLISH_DATABASE'],
                'FEATURED_DATABASE': data['FEATURED_DATABASE'],
                'ADD_TIME': 'SYS-DATETIME',
                'ADD_NAME': user.USERNAME,
                'ADD_USERID': user.USER_ID,
                'U_TIME': 'SYS-DATETIME',
                'U_DELETE': 1
              }
            ]
          }
        ]
      };
      return this.autoInsert.ExecJson(templateJson);
    }
  }

  joinSearchPool(list) {
    const user = this.userInfo.getUserInfo();
    const records = [];
    const ids = [];
    _.each(list, data => {
      ids.push(data['ID']);
      records.push({
        'ID': 'SYS_UUID',
        'SEARCH_WORDS_NAME': data['WORDS_NAME'],
        'SITE_ID': data['SITE_ID'],
        'SITE_NAME': data['SITE_NAME'],
        'SUBJECT_ID': data['SUBJECT_ID'],
        'SUBJECT_NAME': data['SUBJECT_NAME'],
        'SEARCH_WORDS_TYPE': 'NORMAL',
        'UP_SEARCH_WORDS_ID': data['SEARCH_WORDS_ID'],
        'UP_SEARCH_WORDS_NAME': data['SEARCH_WORDS_NAME'],
        'IS_BUILD_SCRIPT': 0,
        'ADD_TIME': 'SYS-DATETIME',
        'ADD_NAME': user.USERNAME,
        'ADD_USERID': user.USER_ID,
        'U_TIME': 'SYS-DATETIME',
        'U_DELETE': 1
      });
    });
    const idstr = '(\'' + ids.join('\',\'') + '\')';
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'hl_search_pool',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'SEARCH_WORDS_NAME'},
            {'COLUMN': 'SITE_ID'},
            {'COLUMN': 'SITE_NAME'},
            {'COLUMN': 'SUBJECT_ID'},
            {'COLUMN': 'SUBJECT_NAME'},
            {'COLUMN': 'SEARCH_WORDS_TYPE'},
            {'COLUMN': 'UP_SEARCH_WORDS_ID'},
            {'COLUMN': 'UP_SEARCH_WORDS_NAME'},
            {'COLUMN': 'IS_BUILD_SCRIPT'},
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': records
        },
        {
          'TABLE': 'hl_words_pool',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': idstr,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'IS_ADD_SEARCH_POOL'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'},
            {'COLUMN': 'U_TIME'}
          ],
          'RECORDS': [
            {
              'IS_ADD_SEARCH_POOL': 1,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': user.USERNAME,
              'UPD_USERID': user.USER_ID,
              'U_TIME': 'SYS-DATETIME'
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  replacePicture(ids) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      newsIds: ids
    };
    const tmpUrl = this.config.config.quickSliver + '/headline/replacePicture?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  subscribeGzh(ids) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      id: ids
    };
    const tmpUrl = this.config.config.quickSliver + '/xiguaji/addGzh?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  unsubscribeGzh(ids) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      id: ids
    };
    const tmpUrl = this.config.config.quickSliver + '/xiguaji/deleteGzh?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }


  getXiguajiHtml(pUrl) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      url: pUrl
    };
    const tmpUrl = this.config.config.quickSliver + '/xiguaji/getGzhHtml?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  replaceNewsPicture(pContent,pNewsPicture) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      content: pContent,
      newsPicture: pNewsPicture
    };
    const tmpUrl = this.config.config.quickSliver + '/xiguaji/replaceNewsPicture?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }
}
