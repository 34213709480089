import {Component, OnInit, ViewChild, HostListener, TemplateRef, ElementRef} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd';
import {UserInfoService} from './../../service/user-info.service';
import {AppConfigService} from './../../service/app-config.service';
import {StorageService} from './../../service/storage.service';
import {UtilsService} from './../../service/utils.service';
import {MenusComponent} from './../../components/menus/menus.component';
import * as _ from 'lodash';
import {NzNotificationService} from 'ng-zorro-antd/notification';

import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from "../../service/request-data.service";
import {UUID} from "angular2-uuid";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  @ViewChild('tpl', {static: false}) tpl: TemplateRef<any>;

  @ViewChild(MenusComponent, {static: false}) menu: MenusComponent;

  public userInfo: any = {};
  public searchText: string;
  public hasLogin: boolean = false;
  public rightConfig: any = {};
  public hasWtbp: boolean = false;
  public wbtpDatas: any = [];
  public wbtpCount = 0;

  timer: any;
  deadline = Date.now() + 1000 * 60 * 5;
  deadlineConfig = 3600;
  isCollapsed: boolean = false;

  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    // enter
    if (event.keyCode === 13) {
      if (this.modalService.openModals.length) {
        _.each(this.modalService.openModals, modal => {
          modal.triggerOk();
        });
      }
    }
    // esc
    if (event.keyCode === 27) {
      this.modalService.closeAll();
    }
  }


  constructor(public config: AppConfigService,
              private userInfoService: UserInfoService,
              private notificationService: NzNotificationService,
              private storage: StorageService,
              private utils: UtilsService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private modalService: NzModalService) {
  }

  ngOnInit() {
    this.rightConfig = this.config.config.logoConfig;
    this.userInfo = this.userInfoService.getUserInfo();
    this.hasLogin = this.utils.Base_HasValue(this.userInfo);
    this.hasWtbp = _.get(this.config, ['config', 'wtbp_json'], false);
    if (this.hasWtbp) {
      this.doRefresh(true);
    }
  }


  doJump(pOneItem) {
        if (this.utils.Base_HasValue(pOneItem['menuId'])) {
            let jumpMenuId = pOneItem['menuId'];
            if (jumpMenuId == 'system.todoCenter') {
                let useTodoCenter = _.get(this.config.config, ['useTodoCenter'], false);
                if (useTodoCenter) {
                    let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
                        return item['id'] === "todoCenterForm";
                    });
                    if (tabIndex >= 0) {
                        this.dataCenterService._dataObject.index = tabIndex;
                        this.dataCenterService.refreshDataCenterInStorage();
                    }
                }
            } else {
      let itemTabName = pOneItem['itemTabName'] || 0;
      this.dataCenterService.newTabWk(pOneItem['menuData'], itemTabName);
    }
  }
    }

  doRefresh(pStartTimer?) {
    this.requestDataService.waitingToBeProcessedServlet().subscribe(values => {
      this.wbtpDatas = values;
      this.wbtpCount = this.wbtpDatas['allCount'];
      this.deadlineConfig = values['deadline'];
      this.deadline = Date.now() + this.deadlineConfig * 1000;

      _.each(this.wbtpDatas['list'], oneItem => {
        if (_.get(oneItem, ['hasNotification'], true) && oneItem['visible']) {
          this.notificationService.config({
            nzPlacement: 'bottomRight'
          });
          this.notificationService.template(this.tpl, {
            nzData: oneItem,
            nzDuration: _.get(oneItem, ['notification_Duration'], 5) * 1000
          });
        }
      });
      if (pStartTimer) {
        this.doRestTime();
      }
    });
  }

  logout() {
    this.userInfoService.logout();
  }

  logoutResume() {
    this.storage.removeData("userInfo");
    this.dataCenterService.clearData();
    const url = "https://account.ccnu.edu.cn/cas/logout?service=http://faculty.ccnu.edu.cn:8050";
    let save_link = document.createElement('a');
    save_link.href = url;
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
  }

  searchMenu() {
    this.menu.filterMeunList();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  doRestTime() {
    this.timer = setInterval(() => {
      this.hasWtbp = _.get(this.config, ['config', 'wtbp_json'], false);
      if (this.hasWtbp) {
        this.doRefresh(false);
      }
    }, this.deadlineConfig * 1000);
  }

}
