import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmModalService} from './confirm-modal.service';
import {LoadingService} from './loading.service';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as $ from 'jquery';
import * as _sh from "lodash";
import {StorageService} from './storage.service';
import {UtilsService} from "./utils.service";


@Injectable({
    providedIn: 'root'
})
export class AjaxService {

    constructor(private httpClient: HttpClient,
                private confirmModal: ConfirmModalService,
                private loading: LoadingService,
                private utils: UtilsService,
                private storage: StorageService) {
    }

    private serializeData(data) {
        if (!_sh.isObject(data)) {
            return ((data == null) ? '' : data.toString());
        }
        let buffer = [];
        for (let name in data) {
            if (!data.hasOwnProperty(name)) {
                continue;
            }
            let value = data[name];
            if (_sh.isObject(value)) {
                value = JSON.stringify(value);
            }
            buffer.push(encodeURIComponent(name) + '=' + encodeURIComponent((value == null) ? '' : value));
        }
        const source = buffer.join('&').replace(/%20/g, '+');
        return (source);
    };

    ajaxGet(url) {
        this.loading.loading();

        // 获取用户token
        const userInfo = this.storage.getData('userInfo');
        const hulkUserToken = _sh.get(userInfo, 'hulkUserToken.hulkUserToken');

        // 构造请求头
        const headers = {
            ...(this.utils.Base_HasValue(hulkUserToken) ? {
                'Authorization': `Bearer ${hulkUserToken}`
            } : {})
        };

        const observable = Observable.create((observer) => {
            this.httpClient.get(url, {
                observe: 'response',
                headers: new HttpHeaders(headers)  // 使用包含token的请求头
            }).subscribe((result) => {
                if (result) {
                    this.loading.hide();
                    observer.next(result);
                }
                observer.complete();
            }, (error) => {
                this.loading.hide();
                if (error.status === 500) {
                    observer.next(error);
                } else {
                    console.error(error);
                    this.confirmModal.show('error', {
                        title: '温馨提示',
                        content: '76 系统错误，请检查您的网络设置！'
                    });
                }
                observer.complete();
            });
        });
        return observable;
    }

    ajaxPost(url, body, hideLoading?) {
        if (hideLoading) {
            this.loading.loading();
        }
        console.log(
            'ajaxPost',
            this.storage.getData('userInfo')
        )
        // 获取用户token
        const userInfo = this.storage.getData('userInfo');
        const hulkUserToken = _sh.get(userInfo, 'hulkUserToken.hulkUserToken');

        // 构造请求头
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...(this.utils.Base_HasValue(hulkUserToken) ? {
                'Authorization': `Bearer ${hulkUserToken}`
            } : {})
        };

        const observable = Observable.create((observer) => {
            this.httpClient.post(url, $.param(body), {
                headers: new HttpHeaders(headers)  // 使用包含token的请求头
            }).subscribe((result) => {
                if (result) {
                    this.loading.hide();
                    observer.next(result);
                }
                observer.complete();
            }, (error) => {
                this.loading.hide();
                if (error.status === 500) {
                    observer.next(error);
                } else {
                    console.error(error);
                    // 如果 url 包含 loginServlet/captcha , 不要弹框报错。
                    if (!url.includes('loginServlet/captcha')) {
                        this.confirmModal.show('error', {
                            title: '温馨提示',
                            content: '123 系统错误，请检查您的网络设置！'
                        });
                    }
                    observer.next(error);
                }
                observer.complete();
            });
        });
        return observable;
    }

    ajaxPostToJava(url, body, hideLoading?) {
        if (hideLoading) {
            this.loading.loading();
        }

        // 获取用户token
        const userInfo = this.storage.getData('userInfo');
        const hulkUserToken = _sh.get(userInfo, 'hulkUserToken.hulkUserToken');

        // 构造请求头
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...(this.utils.Base_HasValue(hulkUserToken) ? {
                'Authorization': `Bearer ${hulkUserToken}`
            } : {})
        };

        const observable = Observable.create((observer) => {
            this.httpClient.post(url, body, {
                headers: new HttpHeaders(headers)
            }).subscribe((result) => {
                if (result) {
                    this.loading.hide();
                    observer.next(result);
                }
                observer.complete();
            }, (error) => {
                this.loading.hide();
                if (error.status === 500) {
                    observer.next(error);
                } else {
                    console.error(error);

                    this.confirmModal.show('error', {
                        title: '温馨提示',
                        content: '168 系统错误，请检查您的网络设置！'
                    });
                    observer.next(error);
                }
                observer.complete();
            });
        });
        return observable;
    }


    ajaxUpload(url, body, hideLoading) {
        if (hideLoading) {
            this.loading.loading();
        }

        // 获取用户token
        const userInfo = this.storage.getData('userInfo');
        const hulkUserToken = _sh.get(userInfo, 'hulkUserToken.hulkUserToken');

        // 构造请求头
        const headers = {
            ...(this.utils.Base_HasValue(hulkUserToken) ? {
                'Authorization': `Bearer ${hulkUserToken}`
            } : {})
        };

        let formData = new FormData();
        formData.append('file', body, body.name);

        const observable = Observable.create((observer) => {
            this.httpClient.post(url, formData, {
                headers: new HttpHeaders(headers)
            }).subscribe((result) => {
                if (result) {
                    this.loading.hide();
                    observer.next(result);
                }
                observer.complete();
            }, (error) => {
                this.loading.hide();
                if (error.status === 500) {
                    observer.next(error);
                } else {
                    console.error(error);

                    this.confirmModal.show('error', {
                        title: '温馨提示',
                        content: '216 系统错误，请检查您的网络设置！'
                    });
                }
                observer.complete();
            });
        });
        return observable;
    }

    ajaxPostJson(url, body, hideLoading?) {
        if (hideLoading) {
            this.loading.loading();
        }

        // 获取用户token
        const userInfo = this.storage.getData('userInfo');
        const hulkUserToken = _sh.get(userInfo, 'hulkUserToken.hulkUserToken');

        // 构造请求头
        const headers = {
            'Content-Type': 'application/json',
            ...(this.utils.Base_HasValue(hulkUserToken) ? {
                'Authorization': `Bearer ${hulkUserToken}`
            } : {})
        };

        const observable = Observable.create((observer) => {
            this.httpClient.post(url, body, {
                headers: new HttpHeaders(headers)
            }).subscribe((result) => {
                if (result) {
                    this.loading.hide();
                    observer.next(result);
                }
                observer.complete();
            }, (error) => {
                this.loading.hide();
                if (error.status === 500) {
                    observer.next(error);
                } else {
                    console.error(error);
                    this.confirmModal.show('error', {
                        title: '温馨提示',
                        content: '259 系统错误，请检查您的网络设置！'
                    });
                }
                observer.complete();
            });
        });
        return observable;
    }
}
