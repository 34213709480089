import {Injectable} from '@angular/core';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {RequestDataService} from "../../service/request-data.service";

@Injectable({
  providedIn: 'root'
})
export class JieliService {

  constructor(
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private requestService: RequestDataService
  ) {
  }

  selectMore(pListData, multiple?): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });

    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  getService(pForms, pExecList) {
    var tempArray = [];
    _sh.each(_sh.get(pExecList, [0, 'data'], []), oneData => {
      var obj = {
        'SERVICE_NAME': _sh.get(oneData, ['SERVICE_NAME'], null),
        'SERVICE_ID': _sh.get(oneData, ['SERVICE_ID'], null),
        'SERVICE_ORDER': _sh.get(oneData, ['SERVICE_ORDER'], null),
        'SERVICE_TYPE': _sh.get(oneData, ['SERVICE_TYPE'], null)
      };
      tempArray.push(obj);
    })
    _.each(pForms, function (oneForm) {
      if (oneForm['tablename'] === "MS_CUST_ORDERS_SERVICE") {
        oneForm['data'] = tempArray;
      }
    })
  }

  splitOrders(pListDatas) {
    let that = this;
    let pk_id = '';
    let showColumns = _sh.get(pListDatas, ['data', 'showColumns', 'columns'], []);
    let datas = _sh.get(pListDatas, ['data', 'data'], []);
    let selectDatas = _sh.filter(datas, {'checked': true});
    _sh.each(selectDatas, oneData => {
      _.each(showColumns, function (OneColumn) {
        // 判断，只有在 初始状态下的订单 才能拆分
        if (OneColumn['column'] === 'ORDER_ID') {
          pk_id += oneData[OneColumn['column']] + ',';
        }
      });
    });
    var param = 'id:\'' + pk_id + '\'';
    return that.requestService.doActionByJavaPlatform('jieLiController', 'categoryData', param);
  }

}
