import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setData (key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getData (key) {
    return JSON.parse(localStorage.getItem(key));
  }

  removeData (key) {
    localStorage.removeItem(key)
  }

  clearCache () {
    localStorage.clear();
  }

}
