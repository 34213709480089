import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, UploadXHRArgs} from 'ng-zorro-antd';
import {UploadService} from '../../../service/upload.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {Observable, Observer} from 'rxjs';
import {DataService} from '../data.service';

@Component({
  selector: 'app-upload-file-resume-normal',
  templateUrl: './upload-file-resume-normal.component.html',
  styleUrls: ['./upload-file-resume-normal.component.css']
})
export class UploadFileResumeNormalComponent implements OnInit {

  @Input() dataObject: any;
  @Input() resumeType: any;

  loading = false;

  constructor(private msg: NzMessageService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private data: DataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService) {
  }

  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      let checkFileType = false;
      let notFileArr = ['.bat', '.sh', '.exe'];
      for (let fileType of notFileArr) {
        if (file.name.indexOf(fileType) !== -1) {
          checkFileType = true;
        }
      }
      if (checkFileType) {
        // this.msg.error('You can only upload JPG file!');
        if (this.resumeType === 'CH') {
          this.confirmModal.show('error', {title: `请不要上传【bat、exe、sh】后缀的文件`});
        } else {
          this.confirmModal.show('error', {title: `Please do not upload files with the suffix [.bat, .exe, .sh].`});
        }
        observer.complete();
        return;
      }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   this.msg.error('Image must smaller than 2MB!');
      //   if(this.resumeType === 'CH'){
      //     this.confirmModal.show("error",{title: `请上传小于2M的文件`});
      //   }else{
      //     this.confirmModal.show("error",{title: `file must smaller than 2MB!`});
      //   }
      //   observer.complete();
      //   return;
      // }
      observer.next(true);
      observer.complete();
    });
  };

  customReq = (item: UploadXHRArgs) => {
    this.loading = true;
    if(this.utils.Base_HasValue(this.config.config['resumeType']) && this.config.config['resumeType'] === 'ccnu'){

      /**
       * 断点续传
       */
      this.data.uploadFile(item.file).subscribe(rep => {
        if(rep['result'] === 'success'){
          item.onSuccess({}, item.file, {});
          this.dataObject.value = this.config.config.fileUpload+"/upload/"+rep['message'];
        }else{
          this.confirmModal.show('error',{title:'上传失败'})
        }
      });
    }else{
      if (this.config.config['userOssOrCos'] === 'COS') {
        return this.uploadService.doCosUpload(item.file, item['progress'], '', 'hulkbuster/resume/', null).then((result) => {
          item.onSuccess({}, item.file, {});
          this.dataObject.fileUrl = this.config.config['COS']['domain'] + '/' + result['url'];
        });
      } else {
        return this.uploadService.doAliOssUpload(item.file, item['progress'], '', 'hulkbuster/resume/').then((result) => {
          item.onSuccess({}, item.file, {});
          this.dataObject.fileUrl = this.config.config['OSS']['domain'] + '/' + result.name;
        });
      }
    }
  };

  handleChange({file, fileList}: { [key: string]: any }): void {
    const status = file.status;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      this.loading = false;
      this.dataObject.fileName = file.name;
      if(this.resumeType === 'CH'){
        this.confirmModal.show('success', {title: file.name +` 文件上传成功`});
      }else{
        this.confirmModal.show('success', {title: file.name +` File uploaded successfully`});
      }

    } else if (status === 'error') {
      if(this.resumeType === 'CH'){
        this.confirmModal.show('success', {title: file.name +` 文件上传失败`});
      }else{
        this.confirmModal.show('success', {title: file.name +` File upload failed`});
      }
    }
  }

  removeFile = (file: File) => {
    this.dataObject.fileName = '';
    this.dataObject.fileList = [];
    return true;
  };

  ngOnInit() {
    if(!this.utils.Base_HasValue( this.dataObject.fileList)){
      this.dataObject.fileList = [];
    }
  }

}
