import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-hulk-config-select-disable',
    templateUrl: './hulk-config-select-disable.component.html',
    styleUrls: ['./hulk-config-select-disable.component.scss']
})
export class HulkConfigSelectDisableComponent implements OnInit {

    @Input() oneColumn: any;
    @Input() attributes: any;
    @Input() label: any;

    constructor() {
    }

    ngOnInit() {
    }

    // 组件
    componentModel = [
        {
            'code': 'add',
            'name': '新增页面'
        },
        {
            'code': 'modify',
            'name': '修改页面'
        }
    ];

    modelChange(model) {
    }


}
