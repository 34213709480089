import { Component, OnInit } from '@angular/core';
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {DataCenterService} from "../../service/data-center.service";
import {AppConfigService} from "../../service/app-config.service";
import {UserInfoService} from "../../service/user-info.service";
import {AjaxService} from "../../service/ajax.service";
import {RequestDataService} from "../../service/request-data.service";
import * as _ from "underscore";
import * as _sh from 'lodash';

@Component({
  selector: 'app-login-role-menu',
  templateUrl: './login-role-menu.component.html',
  styleUrls: ['./login-role-menu.component.scss']
})
export class LoginRoleMenuComponent implements OnInit {

  public listRole: any = [];//所有角色
  public listMenu: any = [];//所有菜单
  public listAction: any = [];//所有按钮
  public roleId: string = "";//被选中的角色id
  public uniqIndex: any;
  public helpUrl: any;//帮助文档地址
  public menuActionList: any = [];
  public checkedMenu: any = [];
  public checkedAction: any;
  public searchRole: any;
  public searchMenu: any;
  public user: any;


  constructor(private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private dataCenter: DataCenterService,
              private appConfigService: AppConfigService,
              private userInfo: UserInfoService,
              private ajaxService: AjaxService,
              private requestData: RequestDataService) {
    this.user = userInfo.getUserInfo();
  }

  /**
   * 选中角色之后获取对应菜单数据和按钮数据进行选中或者取消CheckBox
   * @param pOneRole
   */
  doCheckRole(pOneRole) {
    this.selectAllMenu();
    // 获取角色对应菜单数据
    this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_login_role_menu.json', {"ROLE_ID": pOneRole.ROLE_ID}).subscribe((response) => {
      this.checkedMenu = _sh.get(response,['data'],[]);
      let checkedMenuIds = new Set(this.checkedMenu.map(oneMenu => oneMenu["MENU_ID"]));
      _sh.each(this.menuActionList, (pMenu) => {
        // 循环二级菜单数据
        _sh.each(pMenu["children"], (sMenu) => {
          // 查找 sMenu 的 value 是否在 checkedMenuIds 中
          sMenu["selected"] = checkedMenuIds.has(sMenu["value"]);
        });
      });

    }, (error) => {
      this.utils.Base_Errored(error);
    });
  };

  /**
   *  循环角色菜单 选中菜单
   */
  selectedActionAndMenu() {
    // 循环菜单按钮数据
    _.each(this.menuActionList, (pMenu) => {
      // 循环二级菜单数据
      _.each(pMenu["children"], (sMenu) => {
        _sh.each(this.checkedMenu,oneMenu => {
          sMenu["selected"] = oneMenu["MENU_ID"] === sMenu["value"];
        })
      });
    });
  }


  /**
   * 反选checkbox的递归方法
   * @param list
   */
  falseSelected(list) {
    _.each(list, (data) => {
      data["selected"] = false;
      if (this.utils.Base_HasValue(data["children"])) {
        this.falseSelected(data["children"]);
      }
    });
  };

  /**
   * 取消全选
   */
  selectAllMenu() {
    this.falseSelected(this.menuActionList);
  };

  /**
   * 循环按钮选中
   * @param menu
   */
  doSelectAction = (menu) => {
    if (this.utils.Base_HasValue(menu.children)) {
      _.each(menu.children, (sMenu) => {
        sMenu["selected"] = menu.selected;
      });
    }
  };

  /**
   * 循环二级菜单并选中
   * @param pDatas
   */
  doSelectAll(pDatas) {
    _.each(pDatas.children, (sMenu) => {
      sMenu["selected"] = pDatas.selected;
      this.doSelectAction(sMenu);
    });
  };


  async newMenuSave() {
    let roleId = this.roleId; // 选中角色ID

    // 没有角色弹出提示
    if (!this.utils.Base_HasValue(roleId)) {
      return this.confirmModal.show('error', {title: '请选择角色'})
    }


    let menuList = [];
    let menuActionList = [];
    _sh.each(this.menuActionList, menuLv01 => {
      let menuLv02List = _sh.get(menuLv01, ['children'], []);
      if (this.utils.Base_HasValue(menuLv02List)) {
        _sh.each(menuLv02List, menuLv02 => {
          let lv02Selected = _sh.get(menuLv02, ['selected'], false);
          if (lv02Selected) {
            let menuObj = {
              'menuId': menuLv02['value'],
              'upId': menuLv02['up_id']
            }
            menuList.push(menuObj);

            let actionList = _sh.get(menuLv02, ['children'], []);
            if (this.utils.Base_HasValue(actionList)) {
              _sh.each(actionList, oneAction => {
                let actionSelected = _sh.get(oneAction, ['selected'], false);
                if (actionSelected) {
                  let actionObj = {
                    'menuId': menuLv02['value'],
                    'actionId': oneAction['value'],
                    'actionOrder': oneAction['order']
                  }
                  menuActionList.push(actionObj);
                }
              })
            }
          }
        });
      }
    })

    _sh.each(menuList, menuLv02 => {
      let menuObj = {
        'menuId': menuLv02['upId'],
        'upId': null
      }
      menuList.push(menuObj);
    })
    menuList = _sh.uniqBy(menuList, 'menuId'); // 去掉重复的数据

    // 没有菜单按钮信息弹出提示
    if (!menuList.length) {
      return this.confirmModal.show('error', {title: '请选择菜单'})
    }

    const res = await this.requestData.doSaveLoginRoleMenu(roleId, menuList).toPromise();
    if(res.state === 'success'){
      this.confirmModal.show('success', {'title': '保存成功'});
    }else {
      this.confirmModal.show('error', {'title': '保存失败，请刷新重试'});
    }
  }

  ngOnInit() {
    let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
    if (this.utils.Base_HasValue(currentMenu)) {
      this.helpUrl = currentMenu.HELP_URL;
    }

    const userInfo = this.userInfo.getUserInfo();
    const sqlPath = this.appConfigService.config.sql_path;
    let menuBtnJson = sqlPath + '/system/system_menu_action.json';//菜单按钮
    let menuJson = sqlPath + '/system/system_app_menu.json';//菜单
    let roleJson = sqlPath + '/system/system_login_role.json';//角色


    this.requestData.getDataByCondition(roleJson).subscribe(response => {
      this.listRole = response["data"];
    })

    this.requestData.getDataByCondition(menuJson, {"MENU_LV": 1}).subscribe(dataLv01 => {

      let menuLv01List = dataLv01["data"];

      this.requestData.getDataByCondition(menuJson, {"MENU_LV": 2}).subscribe(response => {
        this.listMenu = response["data"];
        this.requestData.getDataByCondition(menuBtnJson).subscribe(response => {
          this.listAction = response["data"];
          let array = []; // 定义一个空数组
          // 获取菜单对象、按钮对象
          let menuObj = _.groupBy(this.listMenu, (oneData) => {
            return oneData["UP_ID"];
          });
          let actionObj = _.groupBy(this.listAction, (action) => {
            return action["MENU_ID"];
          });
          // 定义某些没有一级菜单的菜单
          if (menuObj['null']) {
            array = array.concat(menuObj['null']);
            delete menuObj['null'];
          }
          if (menuObj['']) {
            array = array.concat(menuObj['']);
            delete menuObj[''];
          }
          menuObj['parentMenu'] = array;

          //循环菜单对象 分级push 并且将按钮push到对应二级菜单下
          _.each(menuObj, (value, key) => {
            let childMenu = value || [];
            if (!childMenu.length) {
              return;
            }
            let findObj = _sh.filter(menuLv01List, oneLv01 => {
              return oneLv01['MENU_ID'] == key;
            })
            let obj = {
              value: key,
              label: childMenu[0]["UP_ID_DICT"] || '按钮类型菜单',
              type: "LEVEL1",
              order: _sh.get(findObj, [0, 'MENU_ORDER'], 0),
              selected: false,
              children: []
            };

            _.each(childMenu, (cv) => {
              let actionBtn = actionObj[cv["MENU_ID"]] || [];
              let actionChildren = [];
              _.each(actionBtn, (av) => {
                actionChildren.push({
                  label: av["ACTION_NAME"],
                  value: av["ACTION_ID"],
                  order: av["ACTION_ORDER"],
                  menu_id: av["MENU_ID"],
                  selected: false,
                  type: 'LEVEL3'
                })
              });
              obj.children.push({
                value: cv["MENU_ID"],
                label: cv["MENU_NAME"],
                up_id: cv["UP_ID"],
                selected: false,
                type: "LEVEL2",
                children: actionChildren
              })
            });
            this.menuActionList.push(obj);
          })
          this.menuActionList = _sh.orderBy(this.menuActionList, ['order'], ['asc']);
        })
      })

    })

  }


}
