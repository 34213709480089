import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import * as _sh from 'lodash';

@Component({
    selector: 'app-hulk-config-new-file',
    templateUrl: './hulk-config-new-file.component.html',
    styleUrls: ['./hulk-config-new-file.component.scss']
})
export class HulkConfigNewFileComponent implements OnInit {

    //父级组件的数据对象
    @Input() parentData: any = {
        conditionColumns: {
            columns: []
        },
        pageSize: null,
        nzPageSizeOptions: null
    };

    isVisible = false;
    selectData = {};

    oneTable = {
        'tablename': null,
        'filename': null,
    }

    constructor(private _httpClient: HttpClient,
                private modalService: NzModalService,
                private hulkconfigserviceservice: HulkConfigServiceService,
                private _FileSaverService: FileSaverService,
                private requestDataService: RequestDataService,
                public appConfigService: AppConfigService,
                private notification: NzNotificationService,
                private activatedRoute: ActivatedRoute,
                private confirmModal: ConfirmModalService,
                private clipboardService: ClipboardService,
                private utils: UtilsService) {
    }

    ngOnInit() {
    }

    // 弹出框
    showModal() {
        let temp = _sh.cloneDeep(this.parentData.data.data);
        if (this.selectMore(temp, false) == 1) {
            const selectList = _.filter(temp, (item) => {
                return item['checked'];
            });
            temp = _.without(temp, selectList);
            _.each(selectList, oneColumn => {
                temp = _.without(temp, oneColumn);
            })
            this.selectData = _sh.get(selectList, [0], {});
            this.isVisible = true;
            this.oneTable['filename'] = _sh.cloneDeep(this.selectData['SEARCH_ID']);
        }
    }

    // 弹出框
    closeModal() {
        this.isVisible = false;
    }

    selectMore(pListData, multiple?): number {
        //计数，有多少条记录被勾选了。
        let iCount = 0;
        _.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });

        // tslint:disable-next-line:triple-equals
        if (multiple == true) {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请至少选择一条纪录！'
                });
                return iCount;
            }
        } else {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请选择一条纪录！'
                });
                return iCount;
            } else if (iCount > 1) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '只能选择一条纪录！'
                });
                return iCount;
            }
        }
        return iCount;
    }


    handleCancel() {
        this.isVisible = false;
    }

    doSave() {

        if (!this.utils.Base_HasValue(this.oneTable['filename'])) {
            this.confirmModal.show('error', {
                'title': '错误',
                'content': '请先填写路径名称。'
            });
        } else {
            let tablename = this.oneTable.tablename;
            this.hulkconfigserviceservice.saveStandardFile(tablename, this.oneTable['filename']);
        }

    }

    handleOk() {

        if (!this.utils.Base_HasValue(this.oneTable['filename'])) {
            this.confirmModal.show('error', {
                'title': '错误',
                'content': '请先填写路径名称。'
            });
        } else {
            // 判断文件是否存在
            this.requestDataService.getDataByJson(this.oneTable['filename']).subscribe(jsons => {
                if (_sh.get(jsons, ['status'], null) == 500) {
                    if (!this.utils.Base_HasValue(this.oneTable.tablename)) {
                        this.confirmModal.show('error', {
                            'title': '错误',
                            'content': '请先选择表名。'
                        });
                    } else {
                        this.doSave();
                    }
                } else {
                    this.confirmModal.show('error', {
                        'title': '错误文件已经存在',
                        'content': '该文件已经存在不能使用快速保存方式：' + this.selectData['SEARCH_ID']
                    });

                }
            });
        }


    }
}
