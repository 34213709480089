import {Component, Input, OnInit, ViewChild} from '@angular/core';
import * as _ from 'underscore';
import {DataCenterService} from '../../../service/data-center.service';
import {DataService} from '../data.service';
import {UtilsService} from '../../../service/utils.service';
import {RequestDataService} from '../../../service/request-data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {NzModalService} from 'ng-zorro-antd';
import * as moment from 'moment';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {AppConfigService} from '../../../service/app-config.service';
import {FormGroup} from '@angular/forms';
import {UEditorComponent} from 'ngx-ueditor';
import {FileDescComponent} from '../../headLine/file-desc/file-desc.component';
import {UploadPictureResumeComponent} from '../upload-picture-resume/upload-picture-resume.component';

@Component({
  selector: 'app-cn-resume',
  templateUrl: './cn-resume.component.html',
  styleUrls: ['./cn-resume.component.css']
})
export class CnResumeComponent implements OnInit {
  @Input() parentData: any;
  @ViewChild('full', {static: false}) full: UEditorComponent;

  constructor(private dataCenterService: DataCenterService,
              private util: UtilsService,
              private modalService: NzModalService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private requestService: RequestDataService,
              private dataService: DataService) {
  }

  _validateForm: FormGroup;
  resultData: {};
  // 简历结果数据
  resumeResult = {
    groupTextDesc: []
  };
  // 左侧菜单栏
  menuList = [];
  // 当前选中的菜单
  nowChoiceMenu = {};
  // 教师数据
  teacherData = {};
  // 添加模块弹出框
  isAuthorize = false;
  modalName = '';
  listAddModal = false; // list添加模板
  objectKeys: any = Object.keys;
  groupList: any = [];
  groupArray: any = [];



  // list添加listDataPush
  addList(pOneModel) {
    pOneModel.listAddModal = true;
  }

  // list 添加模板关闭
  closeListModal(pObject) {
    pObject.listAddModal = false;
    _.each(pObject.columns, column => {
      column['value'] = null;
    });
  }

  //选择菜单
  choiceMenu(item) {
    if (this.util.Base_HasValue(item)) {
      for (let oneGroup of this.groupArray) {
        for (let oneData of oneGroup['models']) {
          oneData.active = false;
          if (oneData['id'] === item['id']) {
            oneData.active = true;
            this.nowChoiceMenu = oneData;
          }
        }
      }
      item.active = true;
    }
  }

  gotoBack() {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'customForm';
    this.parentData.custormFormType = 'app-resume';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 大文本框自动生成数据
  autoListData(oneModel) {
    if (!this.util.Base_HasValue(oneModel.value)) {
      return this.confirmModal.show('warning', {title: '请先填写数据'});
    }
    this.confirmModal.show('confirm', {
      title: '您确定数据输入完成并生成格式化数据吗？',
      suc: () => {
        let data = oneModel.value.split('\n');
        if (this.util.Base_HasValue(data)) {
          let oldListData = this.util.DeepCopy(oneModel['data']);
          let i = 0;
          oneModel['data'] = [];
          let manageListData = [];
          for (let oneData of data) {
            if (this.util.Base_HasValue(oneData)) {
              i++;
              let object = this.util.DeepCopy(oneModel.columns);
              object['index'] = i;
              object['title'] = oneData;
              manageListData.push(object);
              oneModel['data'].push(object);
            }
          }
          // 判断之前是否已存在数据
          if (this.util.Base_HasValue(oldListData)) {
            oneModel['data'] = [];
            _.each(manageListData, (oneData) => {
              let oldData = _.find(oldListData, (old) => {
                return oneData['title'] === old['title'];
              });
              if (this.util.Base_HasValue(oldData)) {
                oneModel['data'].push(oldData);
              } else {
                oneModel['data'].push(oneData);
              }
            });
          }
        }
      }
    });
  }

  // list保存
  listDataPush(pObject) {
    let data: any = {
      index: pObject['data'].length + 1,
      text: ''
    };
    let ok = false;
    _.each(pObject.columns, column => {
      data[column['id']] = column['value'] || '';
      if (this.util.Base_HasValue(column['value'])) {
        ok = true;
      }
      column['value'] = null;
    });
    if (!ok) {
      return this.confirmModal.show('warning', {title: '不能保存空对象。'});
    }
    _.each(pObject['previewRule'], item => {
      let columnText = data[item['code']];
      let columnTextOperator = '';
      if ((item['operator'] === '年,' || item['operator'] === '月,') && !this.util.Base_HasValue(columnText)) {
        columnTextOperator = '今,';
      }
      if (this.util.Base_HasValue(data[item['code']])) {
        columnTextOperator = columnText;
        if (this.util.Base_HasValue(item['operator'])) {
          columnTextOperator = columnText + item['operator'];
        }
      }
      data.text += columnTextOperator;
    });
    if (this.util.Base_HasValue(pObject.columns.objectNum)) { // 如果是修改
      data.index = pObject.columns.objectNum;
      pObject['data'][pObject.columns.objectNum - 1] = data;
      delete(pObject.columns.objectNum);
    } else {
      pObject['data'].push(data);
    }
    pObject.listAddModal = false;
  }

  // 时间格式化
  changeDateModel(pColumn, pType) {
    if (this.util.Base_HasValue(pColumn['value'])) {
      switch (pType) {
        case 'year':
          pColumn['value'] = moment(pColumn['value']).format('YYYY');
          break;
        case 'date':
          pColumn['value'] = moment(pColumn['value']).format('YYYY-MM-DD');
          break;
        case 'month':
          pColumn['value'] = moment(pColumn['value']).format('YYYY-MM');
          break;
      }

    }
  }

  // 往前
  forth(pObject, pColumn) {
    let spareTire: any;
    let num = pObject.indexOf(pColumn);
    spareTire = pObject[num - 1];
    pObject[num - 1] = pColumn;
    pObject[num] = spareTire;
    if (this.util.Base_HasValue(pColumn['index'])) {
      pColumn['index'] = pColumn['index'] - 0 - 1;
      pObject[num]['index'] = pObject[num]['index'] - 0 + 1;
    }
  }

  // 往后
  later(pObject, pColumn) {
    let spareTire: any;
    let num = pObject.indexOf(pColumn);
    spareTire = pObject[num + 1];
    pObject[num + 1] = pColumn;
    pObject[num] = spareTire;
    if (this.util.Base_HasValue(pColumn['index'])) {
      pColumn['index'] = pColumn['index'] - 0 + 1;
      pObject[num]['index'] = pObject[num]['index'] - 0 - 1;
    }
  }

  // 模板排序往前
  modalForth(pObject, pColumn) {
    let item: any = {};
    this.forth(pObject, pColumn);
    _.each(this.resumeResult['resumeData'], oneData => {
      if (oneData['id'] === pColumn['id']) {
        item = oneData;
      }
    });
    this.forth(this.resumeResult['resumeData'], item);
  }

  // 模板排序往后
  modalLater(pObject, pColumn) {
    let item: any = {};
    this.later(pObject, pColumn);
    _.each(this.resumeResult['resumeData'], oneData => {
      if (oneData['id'] === pColumn['id']) {
        item = oneData;
      }
    });
    this.later(this.resumeResult['resumeData'], item);
  }

  // 删除数组中某个元素
  deleteColumn(pObject, pColumn) {
    pObject = pObject.splice(pObject.indexOf(pColumn), 1);
  }

  // list列表删除
  deleteOneColumn(pObject, pColumn) {
    this.confirmModal.show('confirm', {
      title: '您确定删除该条数据吗？',
      suc: () => {
        this.deleteColumn(pObject, pColumn);
      }
    });
  }

  // 修改数据
  updateColumn(oneModel, pArray, pObject) {
    _.each(pArray, item => {
      item['value'] = pObject[item['id']];
    });
    pArray['objectNum'] = pObject.index; // 修改时将当前修改对像的index保存到pArray，以便保存时做判断
    oneModel.listAddModal = true;
  }

  // 添加模块
  addModal() {
    this.isAuthorize = true;
  }

  handleOk(): void {
    if (!this.util.Base_HasValue(this.modalName)) {
      return this.confirmModal.show('warning', {title: '请填写模块名称'});
    }
    let newModal: any = {
      'id': 'introduction' + this.resumeResult['resumeData'].length,
      'name': this.modalName,
      'type': 'form',
      'index': this.resumeResult['resumeData'].length,
      'canBeDeleted': true,
      'visible': true,
      'columns': [
        {
          'type': 'richtextarea',
          'data': [],
          'value': ''
        }
      ]
    };
    this.resumeResult['resumeData'].push(newModal);
    let object = {
      'id': newModal['id'],
      'title': newModal['name'],
      'active': false,
      'GROUP_EN_NAME': 'Other',
      'GROUP_NAME': '其他'
    };
    for (let oneGroup of this.groupArray) {
      if (oneGroup['GROUP_EN_NAME'] === 'Other') {
        oneGroup['models'].push(object);
      }
    }
    this.choiceMenu(object);
    this.isAuthorize = false;
    this.modalName = '';
  }

  // 删除模块
  deleteModal(pModel) {
    this.confirmModal.show('confirm', {
      'title': '您确定删除该模块吗？',
      suc: () => {
        this.deleteColumn(this.resumeResult['resumeData'], pModel);
        let copyNowChoiceMenu = this.nowChoiceMenu;
        let findData = _.find(this.groupArray, (item) => {
          return item['GROUP_EN_NAME'] === copyNowChoiceMenu['GROUP_EN_NAME'];
        });
        if (this.util.Base_HasValue(findData)) {
          this.choiceMenu(findData['models'][findData['models'].indexOf(this.nowChoiceMenu) - 1]);
        }
        this.deleteColumn(findData['models'], copyNowChoiceMenu);
      }
    });
  }

  handleCancel(): void {
    this.isAuthorize = false;
  }

  // 处理基本信息
  manageBasicData(oneData) {
    if (oneData['id'] === 'information') {
      _.each(oneData['columns'], (oneColumns) => {
        switch (oneColumns['id']) {
          case 'nameChinese':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_NAME'])) {
              oneColumns['value'] = this.teacherData['TEACHER_NAME'];
            }
            break;
          case 'workUtil':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_WORK_UTIL_NAME'])) {
              oneColumns['value'] = this.teacherData['TEACHER_WORK_UTIL_NAME'];
            }
            break;
          case 'birthDate':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_BIRTHDAY'])) {
              oneColumns['value'] = this.teacherData['TEACHER_BIRTHDAY'];
            }
            break;
          case 'email':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_EMAIL']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['TEACHER_EMAIL'];
            }
            break;
          case 'phone':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_PHONE']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['TEACHER_PHONE'];
            }
            break;
          case 'headPortraitUrl':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_PICTURE']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['TEACHER_PICTURE'];
            }
            break;
          case 'college':
            if (this.util.Base_HasValue(this.teacherData['ORG_NAME']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['ORG_NAME'];
            }
            break;
          case 'jobTitle':
            if (this.util.Base_HasValue(this.teacherData['JOB_TITLE_DICT_ARRAY']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['JOB_TITLE_DICT_ARRAY']['name'];
            }
            break;
          case 'jobLevel':
            if (this.util.Base_HasValue(this.teacherData['JOB_LEVEL']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['JOB_LEVEL'];
            }
            break;
          case 'teacherType':
            if (this.util.Base_HasValue(this.teacherData['TEACHER_TYPE_DICT_ARRAY']) && !this.util.Base_HasValue(oneColumns['value'])) {
              oneColumns['value'] = this.teacherData['TEACHER_TYPE_DICT_ARRAY']['name'];
            }
            break;
          // case 'boMasterGuide':
          //   if (!this.util.Base_HasValue(oneColumns['data'])) {
          //     _.each(oneColumns['group'], (oneGroup) => {
          //       if (oneGroup['id'] === 'masterGuide') {
          //         if (this.teacherData['IS_MASTER_GUIDE'] === 1) {
          //           oneGroup['value'] = true;
          //         }
          //       } else if (oneGroup['id'] === 'boGuide') {
          //         if (this.teacherData['IS_BO_GUIDE'] === 1) {
          //           oneGroup['value'] = true;
          //         }
          //       }
          //     });
          //   }
          //   break;
        }

      });
    }
  }

  // 初始化数据处理方法
  transform() {
    if (this.util.Base_HasValue(this.resumeResult) && this.util.Base_HasValue(this.resumeResult['resumeData'])) {
      this.resumeResult['teacherId'] = this.teacherData['ID'];
      this.resumeResult['jobCode'] = this.teacherData['JOB_CODE'];
      this.resumeResult['teacherName'] = this.teacherData['TEACHER_NAME'];
      this.resumeResult['resumeData'] = _.filter(this.resumeResult['resumeData'], oneData => {
        return oneData['visible'] !== false && oneData['id'] !== 'uploadCv';
      });
      for (let oneData of this.resumeResult['resumeData']) {
        if (this.util.Base_HasValue(oneData['visible']) && !oneData['visible']) {
          continue;
        }
        if (oneData.id === 'uploadCv') {
          continue;
        }
        let object = {
          'id': oneData['id'],
          'title': oneData['name'],
          'active': false
        };
        if (oneData.id === 'information') {
          object['active'] = true;
          // 处理基础信息
          this.manageBasicData(oneData);
        }
        this.menuList.push(object);
      }
    }
  }

  getMenuList() {
    let group = this.util.DeepCopy(this.groupList);
    let index = 1;
    for (let oneMenu of this.menuList) {
      let findData = _.find(group, (item) => {
        return item['MODULE_CODE'] === oneMenu['id'];
      });
      if (this.util.Base_HasValue(findData)) {
        findData['id'] = oneMenu['id'];
        findData['title'] = oneMenu['title'];
        findData['active'] = oneMenu['active'];
        findData['index'] = index;
      } else {
        group.push({
          GROUP_NAME: '其他',
          GROUP_EN_NAME: 'Other',
          id: oneMenu['id'],
          title: oneMenu['title'],
          active: oneMenu['active'],
          index: index
        });
      }
      index++;
    }
    group = _.sortBy(group, (item) => {
      return +item['index'];
    });
    let data = _.groupBy(group, oneData => {
      return oneData['GROUP_NAME'];
    });
    let arrGroup = [];
    let resultData = this.resumeResult.groupTextDesc;
    let hasOther = false;
    _.each(data, (item, key) => {
      let arr = [];
      for (let one of item) {
        if (one['id'] === 'information') {
          this.nowChoiceMenu = one;
        }
        arr.push({
          'id': one['id'],
          'title': one['title'],
          'active': one['active'],
          'GROUP_EN_NAME': one['GROUP_EN_NAME'],
          'GROUP_NAME': one['GROUP_NAME']
        });
      }
      let model = '';
      if (this.util.Base_HasValue(resultData)) {
        for (let oneValue of resultData) {
          if (oneValue['key'] === item[0]['GROUP_EN_NAME']) {
            model = oneValue['value'];
          }
        }
      }
      if (key === '其他') {
        hasOther = true;
      }
      arrGroup.push({
        GROUP_EN_NAME: item[0]['GROUP_EN_NAME'],
        GROUP_NAME: key,
        models: arr,
        model: model
      });
    });
    if (!hasOther) {
      arrGroup.push({
        GROUP_EN_NAME: 'Other',
        GROUP_NAME: '其他',
        models: [],
        model: ''
      });
    }
    this.groupArray = arrGroup;
  }

  // 获取结果数据
  getResultData() {
    this.resumeResult.groupTextDesc = [];
    let index = 1;
    for (let oneGroup of this.groupArray) {
      this.resumeResult.groupTextDesc.push({
        key: oneGroup['GROUP_EN_NAME'],
        value: oneGroup['model']
      });
      for (let model of oneGroup['models']) {
        let findData = _.find(this.resumeResult['resumeData'], (item) => {
          return item['id'] === model['id'];
        });
        if (this.util.Base_HasValue(findData)) {
          findData['index'] = index;
          index++;
        }
      }
    }
    this.resumeResult['resumeData'] = _.sortBy(this.resumeResult['resumeData'], (item) => {
      return +item['index'];
    });
    if (this.util.Base_HasValue(this.resumeResult) && this.util.Base_HasValue(this.resumeResult['resumeData'])) {
      for (let oneData of this.resumeResult['resumeData']) {
        if (oneData['type'] === 'form') {
          for (let oneColumn of oneData['columns']) {
            oneColumn['data'] = oneColumn['value'];
            let arr = [];
            if (this.util.Base_HasValue(oneColumn['split'])) {
              arr = oneColumn['value'].split(oneColumn['split']);
            }
            if (oneColumn['type'] === 'textarea' && !oneColumn['notSplit']) {
              arr = oneColumn['value'].split('\n');
            }
            if (this.util.Base_HasValue(arr)) {
              oneColumn['data'] = [];
              let i = 0;
              for (let one of arr) {
                if (this.util.Base_HasValue(one)) {
                  oneColumn['data'].push({
                    'index': i,
                    'title': one
                  });
                  i++;
                }
              }
            }
            if (oneColumn['type'] === 'checkBox') {
              oneColumn['data'] = [];
              let i = 0;
              for (let oneGroup of oneColumn['group']) {
                if (oneGroup['value']) {
                  oneColumn['data'].push({
                    'index': i,
                    'id': oneGroup['id'],
                    'title': oneGroup['title']
                  });
                  i++;
                }
              }
            }
          }
        }
      }
    }
  }

  basicSave() {
    this.getResultData();
    const object = {
      JOB_CODE: this.teacherData['JOB_CODE'],
      TEACHER_ID: this.teacherData['ID'],
      TEACHER_NAME: this.teacherData['TEACHER_NAME'],
      RESUME_TYPE: 'CH',
      resumeData: JSON.stringify(this.resumeResult)
    };
    if (this.util.Base_HasValue(this.resultData)) {
      object['ID'] = this.resultData['ID'] || '';
    }
    return this.dataService.saveResumeResult(object);
  }

  // 下一步保存
  nextSave(modelIndex, oneModel) {
    let ok = true;
    if (oneModel.type === 'autoTextarea' && this.util.Base_HasValue(oneModel.value) && !this.util.Base_HasValue(oneModel.data)) {
      ok = false;
      this.confirmModal.show('confirm', {
        title: '您还没有生成数据，确定要保存吗？',
        suc: () => {
          ok = true;
        }
      });
    }
    if (!ok) {
      return;
    }
    if (oneModel.listAddModal) {
      return this.confirmModal.show('warning', {title: '您还有未编辑完成的数据'});
    }
    const condition = {
      RESUME_URL_ALIAS: '',
      NOW_JOB_CODE: this.teacherData['JOB_CODE']
    };
    if (oneModel['id'] === 'information') {
      _.each(oneModel['columns'], column => {
        if (column['id'] === 'homePageUrl') {
          condition.RESUME_URL_ALIAS = column['value'];
        }
      });
    }
    if (this.util.Base_HasValue(condition.RESUME_URL_ALIAS)) {
      this.requestService.getDataByCondition('resume/basic/basic_teacher_detail.json', condition).subscribe(response => {
        if (response.data.length > 0) {
          return this.confirmModal.show('warning', {title: '主页访问地址已经存在，请重新输入'});
        } else {
          this.basicSave().subscribe(rep => {
            if (rep.state === 'success') {
              this.choiceMenu(this.resumeResult['resumeData'][modelIndex + 1]);
            } else {
              let errorText = '执行失败';
              if (this.util.Base_HasValue(rep.text)) {
                errorText = rep.text;
              }
              this.confirmModal.show('warning', {title: errorText});
            }
          });
        }
      });
    } else {
      this.basicSave().subscribe(rep => {
        if (rep.state === 'success') {
          this.choiceMenu(this.resumeResult['resumeData'][modelIndex + 1]);
        } else {
          let errorText = '执行失败';
          if (this.util.Base_HasValue(rep.text)) {
            errorText = rep.text;
          }
          this.confirmModal.show('warning', {title: errorText});
        }
      });
    }
  }

  // 模块保存
  modelSave(oneModel) {
    let ok = true;
    if (oneModel.type === 'autoTextarea' && this.util.Base_HasValue(oneModel.value) && !this.util.Base_HasValue(oneModel.data)) {
      ok = false;
      this.confirmModal.show('confirm', {
        title: '您还没有生成数据，确定要进行下一步吗？',
        suc: () => {
          ok = true;
        }
      });
    }
    if (!ok) {
      return;
    }
    if (oneModel.listAddModal) {
      return this.confirmModal.show('warning', {title: '您还有未编辑完成的数据'});
    }
    const condition = {
      RESUME_URL_ALIAS: '',
      NOW_JOB_CODE: this.teacherData['JOB_CODE']
    };
    if (oneModel['id'] === 'information') {
      _.each(oneModel['columns'], column => {
        if (column['id'] === 'homePageUrl') {
          condition.RESUME_URL_ALIAS = column['value'];
        }
      });
    }
    if (this.util.Base_HasValue(condition.RESUME_URL_ALIAS)) {
      this.requestService.getDataByCondition('resume/basic/basic_teacher_detail.json', condition).subscribe(response => {
        if (response.data.length > 0) {
          return this.confirmModal.show('warning', {title: '主页访问地址已经存在，请重新输入'});
        } else {
          this.basicSave().subscribe(rep => {
            if (rep.state === 'success') {
              return this.confirmModal.show('success', {title: '保存成功'});
            } else {
              let errorText = '执行失败';
              if (this.util.Base_HasValue(rep.text)) {
                errorText = rep.text;
              }
              this.confirmModal.show('warning', {title: errorText});
            }
          });
        }
      });
    } else {
      this.basicSave().subscribe(rep => {
        if (rep.state === 'success') {
          return this.confirmModal.show('success', {title: '保存成功'});
        } else {
          let errorText = '执行失败';
          if (this.util.Base_HasValue(rep.text)) {
            errorText = rep.text;
          }
          this.confirmModal.show('warning', {title: errorText});
        }
      });
    }
  }

  // 编辑完成方法
  saveMain() {
    let checkState = true;
    let errorText = '';
    const condition = {
      RESUME_URL_ALIAS: '',
      NOW_JOB_CODE: this.teacherData['JOB_CODE']
    };
    _.each(this.resumeResult['resumeData'], (oneModel) => {
      if (oneModel['listAddModal']) {
        checkState = false;
        errorText += oneModel['name'] + ',';
      }
      if (oneModel['id'] === 'information') {
        _.each(oneModel['columns'], column => {
          if (column['id'] === 'homePageUrl') {
            condition.RESUME_URL_ALIAS = column['value'];
          }
        });
      }
    });
    errorText = errorText + '还有未编辑完成的数据';
    if (!checkState) {
      return this.confirmModal.show('warning', {title: errorText});
    }
    if (this.util.Base_HasValue(condition.RESUME_URL_ALIAS)) {
      // const str = /^[0-9a-zA-Z-_]*$/;
      // if (!str.test(condition.RESUME_URL_ALIAS)) {
      //   return this.confirmModal.show('warning', {title: '主页访问地址格式错误，请重新输入'});
      // }
      this.requestService.getDataByCondition('resume/basic/basic_teacher_detail.json', condition).subscribe(response => {
        if (response.data.length > 0) {
          return this.confirmModal.show('warning', {title: '主页访问地址已经存在，请重新输入'});
        } else {
          this.confirmModal.show('confirm', {
            'title': '您确定已编辑完成吗？',
            'suc': () => {
              this.basicSave().subscribe(rep => {
                if (rep.state === 'success') {
                  this.gotoBack();
                } else {
                  let errorText = '执行失败';
                  if (this.util.Base_HasValue(rep.text)) {
                    errorText = rep.text;
                  }
                  this.confirmModal.show('warning', {title: errorText});
                }
              });
            }
          });
        }
      });
    } else {
      this.confirmModal.show('confirm', {
        'title': '您确定已编辑完成吗？',
        'suc': () => {
          this.basicSave().subscribe(rep => {
            if (rep.state === 'success') {
              this.gotoBack();
            } else {
              let errorText = '执行失败';
              if (this.util.Base_HasValue(rep.text)) {
                errorText = rep.text;
              }
              this.confirmModal.show('warning', {title: errorText});
            }
          });
        }
      });
    }
  }

  preview() {
    const url = this.config.config.resumeWebCh + this.resultData['JOB_CODE'] + '/temp';
    let save_link = document.createElement('a');
    save_link.href = url;
    save_link.target = '_blank';
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
  }

  ngOnInit() {
    this.teacherData = this.parentData.data;
    const parallel$ = observableForkJoin(
      this.dataService.getResumeJsonModel('CH'),
      this.requestService.getDataByCondition('resume/re/re_resume_result.json', {
        'TEACHER_ID': this.teacherData['ID'],
        'RESUME_TYPE': 'CH'
      }),
      this.requestService.getDataByCondition('resume/app/re_grouping.json')
    );
    parallel$.subscribe(response => {
      this.groupList = response[2]['data'];
      if (this.util.Base_HasValue(response[1]['data']) && this.util.Base_HasValue(response[1]['data'][0])) {
        this.resultData = response[1]['data'][0];
        this.resumeResult = JSON.parse(this.resultData['RESUME_RESULT']);
        this.transform();
      } else if (this.util.Base_HasValue(response[0]['body'])) {
        this.resumeResult = response[0]['body'];
        this.transform();
      }
      this.resumeResult['resumeData'] = _.sortBy(this.resumeResult['resumeData'], (item) => {
        return +item['index'];
      });
      this.getMenuList();
    });
  }

}
