import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import * as _ from 'lodash';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";


@Component({
  selector: 'app-order-spilt',
  templateUrl: './order-spilt.component.html',
  styleUrls: ['./order-spilt.component.scss']
})
export class OrderSpiltComponent implements OnInit {

  @Input() parentData: any;
  baseForm: any = {
    "type": "normal",
    "columns": [
      {
        "column": "TRAN_STATE",
        "name": "TRAN_STATE_NAME",
        "component": "select",
        "lable": "单据状态",
        "comment": "单据状态",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "单据状态",
        "model": null,
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/TRAN_JIHUA_STATUS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TRAN_CUST_CODE",
        "name": "TRAN_CUST_CODE_NAME",
        "component": "input",
        "lable": "客户订单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "客户订单号",
        "placeholder": "请输入客户订单号",
        "model": null,
        "visible": true,
        "require": true,
        "disable": true
      },
      {
        "column": "CUST_ID_DICT",
        "name": "CUST_ID_DICT_NAME",
        "component": "input",
        "lable": "客户名称",
        "comment": "客户名称",
        "placeholder": "请输入客户名称",
        "model": null,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/COMPANY_NAME.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TRAN_CODE",
        "name": "TRAN_CODE_NAME",
        "component": "input",
        "lable": "运输计划单号",
        "comment": "运输计划单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入运输计划单号",
        "model": null,
        "showParam": true,
        "visible": true,
        "require": true,
        "disable": true
      },
      {
        "column": "SC_ID_DICT",
        "name": "SC_ID_DICT_NAME",
        "component": "input",
        "lable": "发货物流节点名称",
        "comment": "发货物流节点名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入发货物流节点名称",
        "model": null,
        "visible": true,
        "require": true,
        "input": "STIC_ID",
        "dictionary": "JieLiv2/basic/dict/BASIC_LOGISTIC_ADDRESS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "RC_ID_DICT",
        "name": "RC_ID_DICT_NAME",
        "component": "input",
        "lable": "收货物流节点名称",
        "comment": "收货物流节点名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入收货物流节点名称",
        "model": null,
        "visible": true,
        "require": true,
        "input": "STIC_ID",
        "dictionary": "JieLiv2/basic/dict/BASIC_LOGISTIC_ADDRESS.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "TRAN_LONG",
        "name": "TRAN_LONG_NAME",
        "component": "input",
        "lable": "公里",
        "comment": "公里",
        "placeholder": null,
        "model": null,
        "visible": true,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "require": false,
        "disable": true
      },
      {
        "column": "TRAN_VOLUME",
        "name": "TRAN_VOLUME_NAME",
        "component": "input",
        "lable": "总体积(立方米)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "总体积(立方米)",
        "placeholder": "请输入总体积(立方米)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "TRAN_WEIGHT",
        "name": "TRAN_WEIGHT_NAME",
        "component": "input",
        "lable": "总吨位(吨)",
        "comment": "总吨位(吨)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总吨位(吨)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "TRAN_QUANTITY",
        "name": "TRAN_QUANTITY_NAME",
        "component": "input",
        "lable": "总数(件)",
        "comment": "总数(件)",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入总数(件)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "TRAN_LEVEL",
        "name": "TRAN_LEVEL_NAME",
        "component": "select",
        "lable": "计划等级",
        "comment": "计划等级",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "计划等级",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TASK_LEVEL.json",
        "dictionaryPF": null,
        "disable": true
      }
    ],
    'dataList': [],
  };
  conditionColumns: any = {
    "type": "normal",
    "columns": [
      {
        "column": "CUST_ID",
        "name": "CUST_ID_NAME",
        "component": "poplist",
        "lable": "承运商",
        "comment": "承运商",
        "placeholder": "请选择承运商",
        "showParam": true,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_CARRIER.json",
        "dictionaryPF": null,
        "columnGroup": [
          {
            "column": "CUST_NAME",
            "name": "CUST_NAME_NAME",
            "component": "input",
            "lable": "承运商",
            "comment": "承运商",
            "placeholder": "请输入承运商",
            "fromValue": "name",
            "model": null,
            "visible": false,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          }
        ]
      },
      {
        "column": "LOGISTIC_ID",
        "name": "LOGISTIC_ID_NAME",
        "component": "poplist",
        "lable": "物流节点",
        "comment": "物流节点",
        "placeholder": "请选择物流节点",
        "showParam": true,
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_LOGISTIC.json",
        "dictionaryPF": null,
        "columnGroup": [
          {
            "column": "LOGISTIC_ID_DICT",
            "name": "LOGISTIC_ID_DICT_NAME",
            "component": "input",
            "lable": "物流节点",
            "comment": "物流节点",
            "placeholder": "请输入物流节点",
            "fromValue": "name",
            "model": null,
            "visible": false,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          }
        ]
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "CUST_NAME",
      "title": "承运商",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "LOGISTIC_ID_DICT",
      "title": "物流节点",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    }
  ];
  ModalVisible: Boolean = false;
  public dataSet: any = [];
  planId = null;
  condition = {};

  constructor(
    private requestService: RequestDataService,
    private dataCenterService: DataCenterService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService
  ) {
  }

  ngOnInit() {
    this.planId = _.cloneDeep(_.get(this.parentData, ['id']))
    this.doSearch();
  }

  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  getDetailList() {
    let arr = []
    let custSort = 1
    _.each(this.baseForm['dataList'], oneItem => {
      let obj = {
        'CUST_SORT': custSort,
        'CUST_ID': oneItem['CUST_ID'],
        'LOGISTIC_ID': oneItem['LOGISTIC_ID']
      }
      custSort++;
      arr.push(obj);
    })
    return JSON.stringify(arr);
  }

  doSave() {
    let that = this;
    let check = true;
    let checkListData = _.cloneDeep(this.baseForm['dataList']);
    if (!this.utils.Base_HasValue(checkListData)) {
      check =false;
      this.confirmModal.show('error',
        {title: '错误。', content: '请先填写物流节点'});
    }

    _.each(this.baseForm['dataList'], oneItem => {
      if (check) {
        let findDatas = _.filter(checkListData,oneCheckData=>{
          return oneCheckData['LOGISTIC_ID'] === oneItem['LOGISTIC_ID']
        })
        if (findDatas.length > 1) {
          check = false;
          this.confirmModal.show('error',
            {title: '不可有重复的物流节点。', content: oneItem['LOGISTIC_ID_DICT']});
        }
      }
    })
    if (check) {
      this.confirmModal.show('confirm', {
        title: '您是否要提交节点拆分。',
        suc: () => {
          var param = 'id:\'' + that.planId + '\'';
          param = param + ',msTranOrdersSplit:\'' + that.getDetailList() + '\'';
          that.requestService.doActionByJavaPlatform('dataController', 'plantLogisticSplit', param).subscribe(values => {
            if (values['result'] == 'success') {
              this.confirmModal.show('success',
                {title: '拆分成功。', content: values['message']});
              this.gotoBack();
            } else {
              this.confirmModal.show('error',
                {title: '拆分失败。', content: values['message']});
            }
          })
        }
      });
    }
  }


  addOrderSpilt() {
    this.dataSet = [];
    _.each(this.conditionColumns['columns'], oneColum => {
      oneColum['model'] = null;
      _.each(oneColum['columnGroup'], oneColumn => {
        oneColumn['model'] = null;
      })
    })
    this.ModalVisible = true;
  }

  doCancel() {
    this.ModalVisible = false;
  }

  selectMulti() {
    let custIdObj = this.utils.filterOneData(this.conditionColumns['columns'], 'column', 'CUST_ID');
    let logisticIdObj = this.utils.filterOneData(this.conditionColumns['columns'], 'column', 'LOGISTIC_ID');
    let obj = {
      'CUST_ID': _.get(custIdObj, ['model'], null),
      'CUST_NAME': _.get(custIdObj, ['modelName'], null),
      'LOGISTIC_ID': _.get(logisticIdObj, ['model'], null),
      'LOGISTIC_ID_DICT': _.get(logisticIdObj, ['modelName'], null)
    }
    this.baseForm.dataList.push(obj);
    this.ModalVisible = false;
  }


  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/ms/ms_tran_plant_list.json', {'TRAN_ID': this.planId}), // 0
      this.requestService.getDataByCondition('JieLiv2/ms/ms_tran_orders_split_list.json', {'TRAN_ID': this.planId}), // 1
    );
    search.subscribe(values => {
      this.backfillData(this.baseForm, values[0]['data']);
      this.baseForm.dataList = []; // 初始化
      _.each(values[1]['data'],oneItem=>{
        let obj = {
          'CUST_ID': _.get(oneItem, ['CUST_ID'], null),
          'CUST_NAME': _.get(oneItem, ['CUST_ID_DICT'], null),
          'LOGISTIC_ID': _.get(oneItem, ['LOGISTIC_ID'], null),
          'LOGISTIC_ID_DICT': _.get(oneItem, ['LOGISTIC_ID_DICT'], null)
        }
        this.baseForm.dataList.push(obj);
      })

    })
  }

  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        oneColumn['model'] = oneData[oneColumn['column']];
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']]
        })

      })
    })
  }

}
