import {Injectable} from '@angular/core';
import {AutoInsertService} from '../../service/auto-insert.service';
import {UserInfoService} from '../../service/user-info.service';
import {RequestDataService} from '../../service/request-data.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {AjaxService} from '../../service/ajax.service';
import * as _ from 'underscore';
import {visit} from '@angular/compiler-cli/src/ngtsc/util/src/visitor';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpEventType, HttpRequest} from '@angular/common/http';
import {Md5} from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  user: any;

  constructor(private autoInsert: AutoInsertService,
              private userInfo: UserInfoService,
              private requestData: RequestDataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService,
              private util: UtilsService,
              private httpClient: HttpClient,
              private ajaxService: AjaxService) {
    this.user = this.userInfo.getUserInfo();
  }

  // 添加模型
  insertModel(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/modelAdd?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 模型查询
  searchModel(params) {
    const tmpUrl = this.config.config.quickSliver + '/digital/modelSearch?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 删除模型
  deleteModel(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/modelDelete?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  pictureSave(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/pictureSave';
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 更改资源状态
  updateResourcesState(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/resourceUpdateState?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 图片资源保存
  resourceRecordSave(jSonStr) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      data: jSonStr
    };
    const tmpUrl = this.config.config.quickSliver + '/digital/resourceRecordSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 删除资源
  resourcePicDelete(pArray) {
    const user = this.userInfo.getUserInfo();
    const params = {
      userId: user.USER_ID,
      userName: user.USERNAME,
      id: pArray
    };
    const tmpUrl = this.config.config.quickSliver + '/digital/resourceDelete?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 分类资源添加查询资源
  columnsDataSearch(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/columnsDataSearch?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 分类添加保存
  classifyResourceSave(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/classifyResourceSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 分类著录保存
  classifyResourceRecordSave(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/classifyResourceRecordSave?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  // 文字资源保存
  wordDrSave(params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + '/digital/textResourceSaveRecord?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }

  deleteChannel(pId) {
    const user = this.userInfo.getUserInfo();
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'dr_channel',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': '?',
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_USERID'},
          ],
          'RECORDS': [
            {
              'ID': pId,
              'U_DELETE': 0,
              'UPD_NAME': user.USERNAME,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_USERID': user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  addChannel(pData) {
    const user = this.userInfo.getUserInfo();
    let templateJson = {};
    if (this.util.Base_HasValue(pData['code'])) {
      templateJson = {
        'LIST': [
          {
            'TABLE': 'dr_channel',
            'ACTION': 'Update',
            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
            'WHERE': [{
              'COLUMN': 'ID',
              'VALUE': '?',
              'OPERATOR': '=',
              'VALUE_TYPE': 'string',
              'CONDITION_OPERATOR': 'AND',
              'GROUP': 'GROUP_01'
            }],
            'COLUMNS': [
              {'COLUMN': 'CHANNEL_NAME'},
              {'COLUMN': 'SITE_ID'},
              {'COLUMN': 'UP_CHANNEL_ID'},
              {'COLUMN': 'CHANNEL_STATE'},
              {'COLUMN': 'PUBLISH_STATE'},
              {'COLUMN': 'CHANNEL_ORDER'},
              {'COLUMN': 'CHANNEL_DESC'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'UPD_NAME'},
              {'COLUMN': 'UPD_TIME'},
              {'COLUMN': 'UPD_USERID'},
            ],
            'RECORDS': [
              {
                'ID': pData['code'],
                'CHANNEL_NAME': pData['CHANNEL_NAME'],
                'SITE_ID': pData['SITE_ID'],
                'UP_CHANNEL_ID': pData['UP_CHANNEL_ID'],
                'CHANNEL_STATE': pData['CHANNEL_STATE'],
                'PUBLISH_STATE': pData['PUBLISH_STATE'],
                'CHANNEL_ORDER': pData['CHANNEL_ORDER'],
                'CHANNEL_DESC': pData['CHANNEL_DESC'],
                'U_DELETE': 1,
                'UPD_USERID': user.USER_ID,
                'UPD_NAME': user.USERNAME,
                'UPD_TIME': 'SYS-DATETIME',
              }
            ]
          }
        ]
      };
    } else {
      templateJson = {
        'LIST': [
          {
            'TABLE': 'dr_channel',
            'ACTION': 'Insert',
            'WHEREGROUP': [],
            'WHERE': [],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'CHANNEL_NAME'},
              {'COLUMN': 'SITE_ID'},
              {'COLUMN': 'UP_CHANNEL_ID'},
              {'COLUMN': 'CHANNEL_STATE'},
              {'COLUMN': 'PUBLISH_STATE'},
              {'COLUMN': 'CHANNEL_ORDER'},
              {'COLUMN': 'CHANNEL_DESC'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'ADD_USERID'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'ADD_NAME'}
            ],
            'RECORDS': [
              {
                'ID': 'SYS-UUID',
                'CHANNEL_NAME': pData['CHANNEL_NAME'],
                'SITE_ID': pData['SITE_ID'],
                'UP_CHANNEL_ID': pData['UP_CHANNEL_ID'],
                'CHANNEL_STATE': pData['CHANNEL_STATE'],
                'PUBLISH_STATE': pData['PUBLISH_STATE'],
                'CHANNEL_ORDER': pData['CHANNEL_ORDER'],
                'CHANNEL_DESC': pData['CHANNEL_DESC'],
                'U_DELETE': 1,
                'ADD_USERID': user.USER_ID,
                'ADD_NAME': user.USERNAME,
                'ADD_TIME': 'SYS-DATETIME',
              }
            ]
          }
        ]
      };
    }
    return this.autoInsert.ExecJson(templateJson);
  }

  requestNeo4j(pUrl, data?) {
    const tmpUrl = this.config.config.hdbsthor.zuul + '/hdbsthor-neo4j' + pUrl + '?accessToken=' + this.config.config.hdbsthor.accessToken + '&random=' + Math.random();
    return this.ajaxService.ajaxPostJson(tmpUrl, data, true);
  }

  requestQuickSliver(url, params) {
    const user = this.userInfo.getUserInfo();
    params = Object.assign(params, {
      userId: user.USER_ID,
      userName: user.USERNAME
    });
    const tmpUrl = this.config.config.quickSliver + url + '?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params, true);
  }
}
