import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'funcFilter'
})
export class FuncFilterPipe implements PipeTransform {

	/**
	 * [transform 方法类型过滤]
	 * @param  {any[]}   items [需要过滤的数组数据]
	 * @param  {any) =>    boolean}     callback [过滤数组的方法-需要在使用的组件里面定义并传入]
	 * @return {any}           [返回任意类型：数组]
	 */
	transform(items: any[], callback: (item: any) => boolean): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => callback(item));
    }


}
