
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {DataService} from './../data.service';

import * as _ from 'underscore';

@Component({
  selector: 'app-dataverse-permission',
  templateUrl: './dataverse-permission.component.html',
  styleUrls: ['./dataverse-permission.component.css']
})
export class DataversePermissionComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;
  public _validateForm: FormGroup;

  public groupList: any = [];
  public ownGroupList: any = [];

  public showModel: any = false;
  public viewUserList: any = [];

  // 搜索用户组poplist封装
  public groupColumn: any = {
    lableSpan: 4,
    itemSpan: 10,
    name: 'groupList',
    column: 'groupList',
    lable: '选择用户组',
    comment: '选择用户组',
    placeholder: '请选择用户组',
    visible: true,
    require: false,
    dictionary: 'headLine/ds/dict/GROUP_NAME.json',
    dictionaryPF: null,
    model: '',
    modelName: ''
  };


  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private utils: UtilsService,
              private modalService: NzModalService,
              private confirmModal: ConfirmModalService,
              private dataService: DataService) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 查看组
  lookUser(pGroup) {
    this.showModel = true;
    this.requestData.getDataByCondition('headLine/ds/ds_group_user_list.json', {
      GROUP_ID: pGroup['GROUP_ID']
    }).subscribe(result => {
      this.viewUserList = result.data || [];
    });
  }

  // 选择用户组之后回显列表数据
  operateGroup(event) {
    let data = {
      DATAVERSE_ID: this.parentData.DATAVERSE_ID,
      DATAVERSE_NAME: this.parentData.DATAVERSE_NAME,
      groupList: []
    };
    _.each(event.modelList, (model: any) => {
      data.groupList.push({
        GROUP_ID: model.code,
        GROUP_NAME: model.name
      });
    });
    this.dataService.saveDataverseGroup(data).subscribe(result => {
      if (result.state === 'success') {
        this.groupList = [];
        _.each(event.modelList, (model: any) => {
          this.groupList = [...this.groupList, {
            GROUP_ID: model.code,
            GROUP_NAME: model.name,
            GROUP_DESC: model.GROUP_DESC
          }];
        });
      } else {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '添加失败，请刷新重试!'
        });
      }
    });
  }

  // 删除已分配的用户组
  removeGroup(group, i) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该用户组吗？',
      suc: () => {
        let data = {
          DATAVERSE_ID: this.parentData.DATAVERSE_ID,
          DATAVERSE_NAME: this.parentData.DATAVERSE_NAME,
          groupList: []
        };
        _.each(this.groupList, (model: any) => {
          if (group.GROUP_ID !== model['GROUP_ID']) {
            data.groupList.push({
              GROUP_ID: model.GROUP_ID,
              GROUP_NAME: model.GROUP_NAME
            });
          }
        });
        this.dataService.saveDataverseGroup(data).subscribe(result => {
          if (result.state === 'success') {
            this.groupList = this.groupList.filter((d, index) => index !== i);
            let modelList = this.groupColumn.model.split(',');
            modelList = _.without(modelList, group.GROUP_ID);
            if (modelList.length) {
              this.groupColumn.model = modelList.join(',');
            } else {
              this.groupColumn.model = '';
              this.groupColumn.modelName = '';
            }
          } else {
            this.confirmModal.show('warning', {
              title: '温馨提示',
              content: '删除失败，请刷新重试!'
            });
          }
        });
      }
    });
  }

  transformOperateGroup(data, confirmCb) {
    data = data || {};
    const formData: any = {
      'tablename': 'ds_group',
      'columns': [
        {
          'lableSpan': 6,
          'itemSpan': 18,
          'column': 'GROUP_NAME',
          'name': 'GROUP_NAME',
          'component': 'input',
          'lable': '组名',
          'comment': '组名',
          'placeholder': '请填写组名',
          'maxlength': 20,
          'model': data['GROUP_NAME'] || null,
          'visible': true,
          'require': true,
          'uniqueCheck': ['GROUP_NAME', 'U_DELETE']
        },
        {
          'lableSpan': 6,
          'itemSpan': 18,
          'column': 'GROUP_DESC',
          'name': 'GROUP_DESC',
          'component': 'textarea',
          'lable': '描述',
          'comment': '描述',
          'placeholder': '请填写描述',
          'maxlength': 500,
          'model': data['GROUP_DESC'] || null,
          'visible': true,
          'require': false
        },
        {
          'lableSpan': 6,
          'itemSpan': 18,
          'column': 'USER_NAME',
          'name': 'USER_NAME',
          'component': 'multipop',
          'lable': '用户',
          'comment': '用户',
          'placeholder': '请选择用户',
          'model': data['userIds'] || null,
          'modelName': data['userNames'] || null,
          'visible': true,
          'require': true,
          'dictionary': 'headLine/ds/dict/USER_NAME.json'
        },
        {
          'column': 'U_DELETE',
          'name': 'U_DELETE_NAME',
          'component': 'input',
          'lable': '删除标识',
          'comment': '删除标识',
          'placeholder': '删除标识',
          'model': 1,
          'insertValue': '1',
          'visible': false
        }
      ]
    };
    if (data['GROUP_ID']) {
      formData.columns.push({
        'column': 'ID',
        'pk_column': true,
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '',
        'comment': '',
        'placeholder': '请输入',
        'model': data['GROUP_ID'],
        'visible': false,
        'require': false
      });
    }
    this.modalService.create({
      nzTitle: '添加组',
      nzContent: FormModalComponent,
      nzWidth: 1200,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: formData
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const data = response.modalFormData.columns;
          confirmCb(data);
        } else {
          return false;
        }
      }
    });
  }

  // 添加组
  addGroup() {
    this.transformOperateGroup({}, (data) => {
      let model = {
        DATAVERSE_NAME: this.parentData.DATAVERSE_NAME,
        DATAVERSE_ID: this.parentData.DATAVERSE_ID,
        GROUP_ID: this.utils.Base_getUuid()
      };
      _.each(data, (item: any) => {
        model[item.column] = item.model;
        if (this.utils.Base_HasValue(item['modelName'])) {
          model[item.column + 'Name'] = item['modelName'];
          model['USER_NAME_COUNT'] = item.model.split(',').length;
        }
      });
      this.dataService.addGroupAndUser(model).subscribe(result => {
        if (result['state'] === 'success') {
          this.ownGroupList = [...this.ownGroupList, model];
        } else {
          this.confirmModal.show('error', {title: '添加失败，请刷新重试！'});
          return false;
        }
      });
    });
  }

  // 删除自建的组
  removeOwnGroup(data, i) {
    this.requestData.getDataByCondition('headLine/ds/ds_group_dataverse_count.json', {
      GROUP_ID: data['GROUP_ID']
    }).subscribe(result => {
      if (result.data[0].account > 0) {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '该用户组已关联数据空间权限用户组，不能删除!'
        });
      } else {
        this.confirmModal.show('confirm', {
          title: '温馨提示',
          content: '确认删除该用户组吗？',
          suc: () => {
            this.dataService.removeGroupUser(this.parentData.DATAVERSE_ID, data['GROUP_ID']).subscribe(res => {
              if (res.state === 'success') {
                this.ownGroupList = this.ownGroupList.filter((d, index) => index !== i);
              } else {
                this.confirmModal.show('warning', {
                  title: '温馨提示',
                  content: '删除失败，请刷新重试!'
                });
              }
            });
          }
        });
      }
    });
  }

  // 编辑用户组
  editGroup(data) {
    this.requestData.getDataByCondition('headLine/ds/ds_group_user_list.json', {
      GROUP_ID: data['GROUP_ID']
    }).subscribe(result => {
      const userList = result.data || [];
      let userIds = [];
      let userNames = [];
      _.each(userList, user => {
        userIds.push(user['USER_ID']);
        userNames.push(user['USER_NAME']);
      });
      this.transformOperateGroup({
        GROUP_ID: data['GROUP_ID'],
        GROUP_NAME: data['GROUP_NAME'],
        GROUP_DESC: data['GROUP_DESC'],
        userIds: userIds.join(','),
        userNames: userNames.join(' '),
      }, (resultData) => {
        let model = {
          GROUP_ID: data['GROUP_ID'],
          GROUP_NAME: resultData[0].model,
          GROUP_DESC: resultData[1].model,
          DATAVERSE_ID: this.parentData.DATAVERSE_ID,
          DATAVERSE_NAME: this.parentData.DATAVERSE_NAME,
          userList: []
        };
        const userIdList = resultData[2].model;
        const userNameList = resultData[2].modelName;
        _.each(userIdList.split(','), (user, index) => {
          model.userList.push({
            'USER_ID': user,
            'USER_NAME': userNameList.split(' ')[index]
          });
        });
        this.dataService.editGroupAndUser(model).subscribe(res => {
          if (res['state'] === 'success') {
            model['USER_NAME_COUNT'] = model['userList'].length || 0;
            data['GROUP_NAME'] = model['GROUP_NAME'];
            data['GROUP_DESC'] = model['GROUP_DESC'];
            data['USER_NAME_COUNT'] = model['userList'].length || 0;
          } else {
            this.confirmModal.show('error', {title: '添加失败，请刷新重试！'});
            return false;
          }
        });
      });
    });
  }

  ngOnInit() {
    this._validateForm = this.formBuilder.group({
      groupList: [[], [Validators.required]]
    });
    const search = observableForkJoin(
      this.requestData.getDataByCondition('headLine/ds/ds_group_dataverse_search.json', {
        DATAVERSE_ID: this.parentData.DATAVERSE_ID
      }),
      this.requestData.getDataByCondition('headLine/ds/ds_group_user_search.json', {
        DATAVERSE_ID: this.parentData.DATAVERSE_ID
      })
    );
    search.subscribe(
      values => {
        this.groupList = values[0]['data'] || [];
        let groupIds = [], groupNames = [];
        _.each(this.groupList, group => {
          groupIds.push(group['GROUP_ID']);
          groupNames.push(group['GROUP_NAME']);
        });
        this.groupColumn.model = groupIds.join(',');
        this.groupColumn.modelName = groupNames.join(' ');
        this.ownGroupList = values[1]['data'] || [];
      });
  }

}
