import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  constructor() {
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    this.hulkColumn.count = this.hulkColumn.count || 5;
  }

}
