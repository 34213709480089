import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {UtilsService} from '../../service/utils.service';
import {NzModalService} from 'ng-zorro-antd';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {DataService} from '../education/data.service';
import {AppConfigService} from '../../service/app-config.service';
import {Router} from '@angular/router';
import * as _ from 'underscore';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';

@Injectable({
  providedIn: 'root'
})
export class EducationResourcesService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    let selectList = [];
    let idArray = [];
    let checkState = true;
    switch (pBtn.ACTION_VALUE) {
      case 'analyze': // 分析
        this.analyze(pParentData);
        break;
      case 'analyzePass': // 分析通过
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        selectList = _.filter(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        idArray = [];
        checkState = true;
        _.each(selectList, oneData => {
          if (oneData['INCIDENT_STATE'] !== 'ANALYZED' && oneData['INCIDENT_STATE'] !== 'ANALYZE_FAILS') {
            checkState = false;
          }
          idArray.push(oneData['ID']);
        });
        if (!checkState) {
          this.confirmModal.show('warning', {title: '只有状态为已分析或分析不通过的数据才可以执行此操作。'});
          return false;
        }
        this.confirmModal.show('confirm', {
          title: '您确定执行分析通过操作吗？',
          suc: () => {
            this.changeIncidentState(pParentData, idArray, 'STAY_ON');
          }
        });
        break;
      case 'analyzeReject': // 分析不通过
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        selectList = _.filter(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        idArray = [];
        checkState = true;
        _.each(selectList, oneData => {
          if (oneData['INCIDENT_STATE'] !== 'ANALYZED') {
            checkState = false;
          }
          idArray.push(oneData['ID']);
        });
        if (!checkState) {
          this.confirmModal.show('warning', {title: '只有状态为已分析的数据才可以执行此操作。'});
          return false;
        }
        this.analyzeReject(pParentData, idArray, 'ANALYZE_FAILS');
        break;
      case 'shelves': // 上架
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        selectList = _.filter(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        idArray = [];
        checkState = true;
        _.each(selectList, oneData => {
          if (oneData['INCIDENT_STATE'] !== 'STAY_ON' && oneData['INCIDENT_STATE'] !== 'SOLD_OUT') {
            checkState = false;
          }
          idArray.push(oneData['ID']);
        });
        if (!checkState) {
          this.confirmModal.show('warning', {title: '只有状态为待上架或已下架的数据才可以执行此操作。'});
          return false;
        }
        this.confirmModal.show('confirm', {
          title: '您确定执行上架操作吗？',
          suc: () => {
            this.changeIncidentState(pParentData, idArray, 'HAS_BEEN_ON');
          }
        });
        break;
      case 'soldOut': // 下架
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        selectList = _.filter(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        idArray = [];
        checkState = true;
        _.each(selectList, oneData => {
          if (oneData['INCIDENT_STATE'] !== 'HAS_BEEN_ON') {
            checkState = false;
          }
          idArray.push(oneData['ID']);
        });
        if (!checkState) {
          this.confirmModal.show('warning', {title: '只有状态为已上架的数据才可以执行此操作。'});
          return false;
        }
        this.analyzeReject(pParentData, idArray, 'SOLD_OUT');
        break;
      case 'pushReport': // 推送
        this.pushReport(pParentData);
        break;
      case 'editReport': // 报道编缉
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        this.mackConditon(pParentData);
        this.transformData('customForm', pParentData, 'modify', 'editReport');
        break;
      case 'submitAudit': // 报道提交审核
        this.submitAudit(pParentData);
        break;
      case 'noPass': // 报道取消审核
        this.cancelAudit(pParentData);
        break;
      case 'approved': // 报道审核通过并上架
        this.adopt(pParentData);
        break;
      case 'recall': // 报道下架
        this.reject(pParentData);
        break;
      case 'releaseReport': // 报道再次上架
        this.releaseReport(pParentData);
        break;
      case 'bindingEvent': // 已上架报道绑定事件
        this.bindingEvent(pParentData);
        break;
      case 'cancelEvent': // 已上架报道取消绑定事件
        this.cancelEvent(pParentData);
        break;
    }
  }

  // 解除绑定事件
  cancelEvent(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.dataService.cancelEvent(selectList, pParentData);
  }

  // 绑定事件
  bindingEvent(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    _.each(selectList, item => {
      flag = flag && (item['REPORT_STATE'] !== 'SOLD_OUT');
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '已下架状态不可以绑定事件',
      });
      return false;
    }
    this.modalService.create({
      nzTitle: '绑定事件',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'INCIDENT_ID',
              'name': 'INCIDENT_ID',
              'component': 'multipop',
              'customize': 24,
              'lable': '热点事件',
              'comment': '热点事件',
              'placeholder': '请选择热点事件',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'education/basic/dict/INCIDENT_NAME.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => this.dataService.bindingEvent(selectList, pParentData, response)
    });
  }

  // 报道上架
  releaseReport(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    _.each(selectList, item => {
      flag = flag && (item['REPORT_STATE'] === 'SOLD_OUT');
    });
    if (!flag) {
      return this.confirmModal.show('warning', {title: '只有已下架的数据才可以再次上架。'});
    }
    this.dataService.releaseReport(selectList, pParentData);
  }

  // 报道下架
  reject(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    let flag = true;
    _.each(selectList, item => {
      flag = flag && (item['REPORT_STATE'] === 'PUTAWAY');
    });
    if (!flag) {
      this.confirmModal.show('warning', {
        title: '只有已上架状态才可以下架',
      });
      return false;
    }
    this.modalService.create({
      nzTitle: '下架',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REPORT_SHELVES_DESC',
              'name': 'REPORT_SHELVES_DESC',
              'component': 'textarea',
              'customize': 24,
              'lable': '下架原因',
              'comment': '下架原因',
              'placeholder': '请输入下架原因',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => this.dataService.reject(selectList, pParentData, response)
    });
  }

  adopt(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定通过审核？',
      suc: () => {
        this.dataService.adopt(selectList, pParentData);
      }
    });
  }

  // 待审核管理 取消审核按钮
  cancelAudit(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定取消审核？',
      suc: () => {
        this.dataService.cancelAudit(selectList, pParentData);
      }
    });
  }

  // 待编缉内容管理提交审核
  submitAudit(pParentData) {
    if (this.selectMore(pParentData.data.data) > 0) {
      const selectedList = [];
      _.each(pParentData.data.data, (data, index) => {
        if (data['checked']) {
          selectedList.push(data);
        }
      });
      this.dataService.submitAudit(selectedList, pParentData);
    }
  }

  // 推送
  pushReport(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.confirmModal.show('confirm', {
      title: '您确定推送这些报道吗？',
      suc: () => {
        this.dataService.pushReport(selectList, pParentData);
      }
    });
  }

  // 分析不通过 + 下架
  analyzeReject(pParentData, idArray, state) {
    let id = idArray.join('\',\'');
    id = '(\'' + id + '\')';

    this.modalService.create({
      nzTitle: '原因',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REASON_TEXT',
              'name': 'REASON_TEXT',
              'customize': 24,
              'component': 'textarea',
              'lable': '原因',
              'comment': '原因',
              'placeholder': '请输入原因',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (result) => {
        let reason = {};
        if (this.util.Base_HasValue(result)) {
          if (result.childForm._validateForm.invalid) {
            for (const key in result.childForm._validateForm.controls) {
              result.childForm._validateForm.controls[key].markAsDirty();
              result.childForm._validateForm.controls[key].updateValueAndValidity();
            }
          }
          if (result.childForm._validateForm.valid) {
            reason = result.modalFormData.columns[0];
          } else {
            return false;
          }
        }
        const templateJsonPass = {
          'LIST': [
            {
              'TABLE': 'edu_incident',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': id,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'INCIDENT_STATE'},
                {'COLUMN': 'REASON_TEXT'},
                {'COLUMN': 'UPD_TIME'},
                {'COLUMN': 'UPD_NAME'},
                {'COLUMN': 'UPD_USERID'}
              ],
              'RECORDS': [
                {
                  'INCIDENT_STATE': state,
                  'REASON_TEXT': reason['model'],
                  'UPD_TIME': 'SYS-DATETIME',
                  'UPD_NAME': this.user.USERNAME,
                  'UPD_USERID': this.user.USER_ID
                }
              ]
            }
          ]
        };
        this.autoInsert.ExecJsonNoUser(templateJsonPass).subscribe(response => {
          if (response.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: '执行成功'});
          } else {
            this.confirmModal.show('error', {title: '执行失败'});
          }
        });
      }
    });
  }

  // 更改事件状态
  changeIncidentState(pParentData, idArray, state) {
    let id = idArray.join('\',\'');
    id = '(\'' + id + '\')';
    const columns = [
      {'COLUMN': 'INCIDENT_STATE'},
      {'COLUMN': 'REASON_TEXT'},
      {'COLUMN': 'UPD_TIME'},
      {'COLUMN': 'UPD_NAME'},
      {'COLUMN': 'UPD_USERID'}
    ];
    const records = [
      {
        'INCIDENT_STATE': state,
        'REASON_TEXT': '',
        'UPD_TIME': 'SYS-DATETIME',
        'UPD_NAME': this.user.USERNAME,
        'UPD_USERID': this.user.USER_ID
      }
    ];
    // 如果是上架,则记录上架人
    if (state === 'HAS_BEEN_ON') {
      columns.push({'COLUMN': 'INCIDENT_SHELF_TIME'});
      columns.push({'COLUMN': 'INCIDENT_SHELF_USER_NAME'});
      columns.push({'COLUMN': 'INCIDENT_SHELF_USER_ID'});
      records[0]['INCIDENT_SHELF_TIME'] = 'SYS-DATETIME';
      records[0]['INCIDENT_SHELF_USER_NAME'] = this.user.USERNAME;
      records[0]['INCIDENT_SHELF_USER_ID'] = this.user.USER_ID;
    }
    const templateJsonPass = {
      'LIST': [
        {
          'TABLE': 'edu_incident',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': id,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': columns,
          'RECORDS': records
        }
      ]
    };

    this.autoInsert.ExecJsonNoUser(templateJsonPass).subscribe(response => {
      if (response.state === 'success') {
        pParentData.needSearch = true;
        this.confirmModal.show('success', {title: '执行成功'});
      } else {
        this.confirmModal.show('error', {title: '执行失败'});
      }
    });
  }

  // 分析
  analyze(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return;
    }
    const selectList = _.filter(pParentData.data.data, item => {
      return item['checked'] === true;
    });
    const idArray = [];
    let checkState = true;
    const recordsArr = [];
    _.each(selectList, oneData => {
      if (oneData['INCIDENT_STATE'] !== 'NOT_ANALYZE'
        && oneData['INCIDENT_STATE'] !== 'ANALYZE_FAILS'
        && oneData['INCIDENT_STATE'] !== 'SOLD_OUT') {
        checkState = false;
      }
      idArray.push(oneData['ID']);
      // 添加分析指令
      recordsArr.push({
          'ID': 'SYS-UUID',
          'MAIN_ID': oneData['ID'],
          'SEND_METHOD': 'visaul-incident',
          'SEND_FLAG': 'init',
          'ADD_USERID': this.user.USER_ID,
          'ADD_TIME': 'SYS-DATETIME',
          'ADD_NAME': this.user.USERNAME,
          'U_DELETE': 1
        }
      );
    });
    if (!checkState) {
      this.confirmModal.show('warning', {title: '只有状态为待分析、分析不通过或已下架的数据才可以执行此操作。'});
      return false;
    }
    let id = idArray.join('\',\'');
    id = '(\'' + id + '\')';
    this.confirmModal.show('confirm', {
      title: '您确定执行分析操作吗？',
      suc: () => {
        const templateJsonPass = {
          'LIST': [
            {
              'TABLE': 'edu_incident',
              'ACTION': 'Update',
              'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
              'WHERE': [{
                'COLUMN': 'ID',
                'VALUE': id,
                'OPERATOR': 'in',
                'VALUE_TYPE': 'int',
                'CONDITION_OPERATOR': 'AND',
                'GROUP': 'GROUP_01'
              }],
              'COLUMNS': [
                {'COLUMN': 'INCIDENT_STATE'},
                {'COLUMN': 'REASON_TEXT'},
                {'COLUMN': 'UPD_TIME'},
                {'COLUMN': 'UPD_NAME'},
                {'COLUMN': 'UPD_USERID'}
              ],
              'RECORDS': [
                {
                  'INCIDENT_STATE': 'ANALYZING',
                  'REASON_TEXT': '',
                  'UPD_TIME': 'SYS-DATETIME',
                  'UPD_NAME': this.user.USERNAME,
                  'UPD_USERID': this.user.USER_ID
                }
              ]
            },
            {
              'TABLE': 'edu_order_queue',
              'ACTION': 'Insert',
              'WHEREGROUP': [],
              'WHERE': [],
              'COLUMNS': [
                {'COLUMN': 'ID'},
                {'COLUMN': 'MAIN_ID'},
                {'COLUMN': 'SEND_METHOD'},
                {'COLUMN': 'SEND_FLAG'},
                {'COLUMN': 'ADD_USERID'},
                {'COLUMN': 'ADD_TIME'},
                {'COLUMN': 'ADD_NAME'},
                {'COLUMN': 'U_DELETE'}
              ],
              'RECORDS': recordsArr
            },
          ]
        };
        this.autoInsert.ExecJsonNoUser(templateJsonPass).subscribe(response => {
          if (response.state === 'success') {
            pParentData.needSearch = true;
            this.confirmModal.show('success', {title: '执行成功'});
          } else {
            this.confirmModal.show('error', {title: '执行失败'});
          }
        });
      }
    });
  }

  doDetailAction(pBtn, parentData, mainForm) {
    switch (pBtn.ACTION_VALUE) {

    }
  }

  mackConditon(pParentData): void {
    function setDetailConditons(pData) {
      function isCondtion(OneColumn) {
        let bFind = false;
        if (_.has(OneColumn, 'iscondition')) {
          bFind = OneColumn.iscondition;
        }
        return bFind;
      }

      let conData;
      _.each(pData, function (oneData) {
        if (oneData['checked']) {
          conData = oneData;
        }
      });
      const tmp = {};
      _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
        //只将 iscondition 为 true 的作为条件传入详情页面。
        if (isCondtion(OneColumn)) {
          tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
        }
      });
      return tmp;
    }

    pParentData.detailCondition = setDetailConditons(pParentData.data.data);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }
}
