import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from '../graduate-job-unit/graduate-job-unit.component';
import * as alasql from "alasql";

@Component({
  selector: 'app-research-results',
  templateUrl: './research-results.component.html',
  styleUrls: ['./research-results.component.css']
})
export class ResearchResultsComponent implements OnInit {
  evaluationYear: any;
  scientificReward: any;
  patentInfo: any;
  researchResults: any;
  book: any;
  paper: any;
  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1: any = [
      {
        '国家级科研奖励一等奖（及以上）获奖数': this.scientificReward['NATIONAL_FIRST_PRIZE'] || 0,
        '省部级科研获奖数一等奖（及以上）获奖数': this.scientificReward['PROVINCE_FIRST_PRIZE'] || 0,
        '国家级科研奖励二等奖（及以下）获奖数': this.scientificReward['NATIONAL_SECOND_PRIZE'] || 0,
        '省部级科研获奖数二等奖（及以下）获奖数': this.scientificReward['PROVINCE_SECOND_PRIZE'] || 0,
        '已授权发明专利数': this.patentInfo['PATENTS'] || 0,
        '被省部级以上党政机关采纳的咨询报告数': this.researchResults['PROVINCE_USIING'] || 0,
        '被国家标准采用的成果数': this.researchResults['MATIONAL_USIING'] || 0,
        '技术转让实际收入（万元）': this.patentInfo['REAL_INCOME'] || 0,
        '出版专著数': this.book['ACOUNT'] || 0,
        '公开发表学术论文总篇数': this.paper['ACOUNT'] || 0
      }
    ];
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年科研成果及转化情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("IV-2 近5年科研成果及转化情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_scientific_reward_info.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_patent_real.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_research_results.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_book_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_paper_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
    );
    parallel.subscribe(response => {
      this.scientificReward = response[0]['data'][0];
      this.patentInfo = response[1]['data'][0];
      this.researchResults = response[2]['data'][0];
      this.book = response[3]['data'][0];
      this.paper = response[4]['data'][0];
    });
  }

}
