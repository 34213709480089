import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {UtilsService} from '../../../service/utils.service';
import * as _ from "underscore";
import {RequestDataService} from '../../../service/request-data.service';

@Component({
  selector: 'app-pre-release',
  templateUrl: './pre-release.component.html',
  styleUrls: ['./pre-release.component.css']
})
export class PreReleaseComponent implements OnInit {
  selectedValue = new Date();
  listData:any;
  preDateList:any;
  showTable:boolean = false;
  nowDate = moment(this.selectedValue).format("YYYY-MM-DD");
  choiceData:any;
  listArray = [];

  constructor(private utils: UtilsService,
              private requestService: RequestDataService) { }

  reRearch(){
    this.requestService.getDataByCondition("headLine/hl/hl_pre_release_news.json",{}).subscribe((res) => {
      this.listData = res.data || [];
      if (this.utils.Base_HasValue(this.listData[0])) {
        _.each(this.listData, (oneData) => {
          oneData["date"] = oneData["PRE_RELEASE_DATE"] + ' ' + oneData["PRE_RELEASE_TIME"];
        });
        this.listData = _.groupBy(this.listData, "PRE_RELEASE_DATE");
        this.preDateList = Object.keys(this.listData);
        this.toDay();
      }
    })
  }

  toDay() {
    this.listArray = [];
    this.nowDate = moment(this.selectedValue).format("YYYY-MM-DD");
    let nowdate:any = this.nowDate;
    this.choiceData = _.filter(this.listData, function (item, key) {
      return key === nowdate;
    });
    if (this.utils.Base_HasValue(this.choiceData[0])) {
      this.choiceData = _.groupBy(this.choiceData[0], "SUBJECT_TYPE_DICT");
      _.each(this.choiceData,(value,key) => {
        this.listArray.push(value);
      });
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  ngOnInit() {
    this.reRearch();
  }

}
