import {Component, OnInit, Input} from '@angular/core';
import {
	FormGroup
} from '@angular/forms';

@Component({
	selector: 'app-validate-form',
	templateUrl: './validate-form.component.html',
	styleUrls: ['./validate-form.component.css']
})
export class ValidateFormComponent implements OnInit {

	@Input() hulkForm: FormGroup;
	@Input() hulkColumn: any;
	constructor() { }

	ngOnInit() {
	}

}
