import {Injectable} from '@angular/core';
import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from '../../service/request-data.service';
import {UserInfoService} from '../../service/user-info.service';
import {UtilsService} from '../../service/utils.service';
import {AppConfigService} from '../../service/app-config.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'lodash';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {Md5} from "ts-md5";
import {UUID} from "angular2-uuid";

@Injectable({
  providedIn: 'root'
})
export class GenecreateService {

  public user: any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  formatSelectEntity(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }

  findOneColumnValue(pColumns, pColumnName) {
    const oneOneColum = _.find(pColumns, {'column': pColumnName});
    let oneValue = null;
    if (this.util.Base_HasValue(oneOneColum)) {
      oneValue = _.get(oneOneColum, 'model');
    } else {
      _.each(pColumns, oneColumn => {
        _.each(oneColumn['columnGroup'], oneGroup => {
          if (oneGroup['column'] === pColumnName) {
            oneValue = _.get(oneGroup, 'model');
          }
        })
      })
    }
    return oneValue;
  }

  setOneColumnValue(pColumns, pColumnName, pValue) {
    _.each(pColumns['columns'], oneOneColum => {
      if (oneOneColum['column'] === pColumnName) {
        _.set(oneOneColum, 'model', pValue);
      }
    })
  }

  public doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case 'orAllocation': // 等待分配的订单:分配按钮
        this.doOrAllocation(pBtn, pParentData);
        break;
      case 'cancelOrder':// 客户经理，我的跟踪订单:取消
        this.doCancelOrder(pBtn, pParentData);
        break;
      case 'returnOrder':// 我的跟踪订单:退回分配
        this.doReturnOrder(pBtn, pParentData);
        break;
      case 'uploadContract':// 我的跟踪订单:上传合同
        this.doUploadContract(pBtn, pParentData);
        break;
      case 'invoicingOrder':// 等待开发票的订单:开票
        this.doInvoicingOrder(pBtn, pParentData);
        break;
      case 'shipOrder':// 等待发货的订单:发货
        this.doShipOrder(pBtn, pParentData);
        break;
      case 'writeoffOrder':// 等待核销的订单:核销
        this.doWriteoffOrder(pBtn, pParentData);
        break;
      case 'productionOrder':// 确认生产完成
        this.doProductionOrder(pBtn, pParentData);
        break;
      case 'contractCustConfirm':// 合同客户确认
        this.doContractCustConfirm(pBtn, pParentData);
        break;
      case 'custConfirmReceiving':// 客户确认收货
        this.doCustConfirmReceiving(pBtn, pParentData);
        break;
      case 'customerReturnShipment':// 客户发起退货
        this.doCustomerReturnShipment(pBtn, pParentData);
        break;
      case 'customerInitiatesAReturn':// 客户退货发货
        this.doCustomerInitiatesAReturn(pBtn, pParentData);
        break;
      case 'returnApproval':// 退货审批
        this.doCeturnApproval(pBtn, pParentData);
        break;
      case 'confirmReturnReceipt':// 确认收货
        this.doConfirmReturnReceipt(pBtn, pParentData);
        break;
      case 'refundVerification':// 退款核销
        this.doRefundVerification(pBtn, pParentData);
        break;
      case 'modifiedQuantity':// 修改数量
        this.doModifiedQuantity(pBtn, pParentData);
        break;
      case 'deleteGoods':// 删除商品
        this.doDeleteGoods(pBtn, pParentData);
        break;
      case 'settlement':// 结算
        this.doSettlement(pBtn, pParentData);
        break;
      case 'placeOrder':// 提交订单
        this.doPlaceOrder(pBtn, pParentData);
        break;
      case 'buyAgain':// 再次购买
        this.doBuyAgain(pBtn, pParentData);
        break;
      case 'addGoods':// 加入购物车
        this.doAddGoods(pBtn, pParentData);
        break;
      case 'newlyBuild':// 商品列表新建
        this.doNewlyBuild(pBtn, pParentData);
        break;
      case 'offShelf':// 商品列表上架
        this.doOffShelf(pBtn, pParentData);
        break;
      case 'onShelf':// 商品列表下架
        this.doOnShelf(pBtn, pParentData);
        break;
      case 'stockPrice':// 商品列表修改库存/价格
        this.doStockPrice(pBtn, pParentData);
        break;
      case 'custCancelOrder':// 客户取消订单
        this.doCustCancelOrder(pBtn, pParentData);
        break;
      case 'inputService':// 新增服务产品
        this.doInputService(pBtn, pParentData);
        break;
      case 'updateService':// 修改服务产品
        this.doUpdateService(pBtn, pParentData);
        break;
      case 'customerApCheck': // 预付款审核
        this.doCustomerApCheck(pBtn, pParentData);
        break;
      case 'workflow': // 工作流引擎管理
        this.doWorkflow(pBtn, pParentData);
        break;
      case 'testSample': // 测试样品
        this.doTestSample(pBtn, pParentData);
        break;
      case 'usedUp': // 样品管理-已用完
        this.doUsedUp(pBtn, pParentData);
        break;
      case 'returnSample': // 回寄样品
        this.doReturnSample(pBtn, pParentData);
        break;
      case 'confirmReceipt': // 确认收货
        this.doConfirmReceipt(pBtn, pParentData);
        break;
      case 'completeTheProcess': // 待跟踪订单-完成本工序
        this.doCompleteTheProcess(pBtn, pParentData);
        break;
      case 'onShelfService': // 产品服务上架
        this.doOnShelfService(pBtn, pParentData);
        break;
      case 'downShelfService': // 产品服务上架
        this.doDownShelfService(pBtn, pParentData);
        break;
      case 'phoneRegisterVerification': // 客户信息列表-手机号注册验证码
        this.doPhoneRegisterVerification(pBtn, pParentData);
        break;
      case 'usePhoneRegister': // 客户信息列表-使用手机号注册
        this.doUsePhoneRegister(pBtn, pParentData);
        break;
      case 'modifyPasswordVerification': // 客户信息列表-修改密码验证码
        this.doModifyPasswordVerification(pBtn, pParentData);
        break;
      case 'userModifyPassword': //客户信息列表-用户修改密码
        this.doUserModifyPassword(pBtn, pParentData);
        break;
      case 'useVerificationLogin': //客户信息列表-使用验证码登录
        this.doUseVerificationLogin(pBtn, pParentData);
        break;
      case 'phoneWhethereExistence': //客户信息列表-判断手机是否存在
        this.doPhoneWhethereExistence(pBtn, pParentData);
        break;
      case 'sendLoginSms': //   客户信息列表-发送登陆验证码
        this.doSendLoginSms(pBtn, pParentData);
        break;
      case 'newSamples': //   样品管理列表-新增样品信息
        this.doNewSamples(pBtn, pParentData);
        break;
      case 'invoiceDefault': //   前端接口测试-我的发票列表
        this.doInvoiceDefault(pBtn, pParentData);
        break;
      case 'addrDefault': //   前端接口测试-默认地址
        this.doAddrDefault(pBtn, pParentData);
        break;
      case 'newServiceOrder': //   前端接口测试-服务产品列表
        this.doNewServiceOrder(pBtn, pParentData);
        break;
      case 'invoiceAdd': //   我的发票列表-新建发票
        this.doInvoiceAdd(pBtn, pParentData);
        break;
      case 'invoiceEdit': //   我的发票列表-修改发票
        this.doInvoiceEdit(pBtn, pParentData);
        break;
      case 'addrAdd': //   我的地址列表-新建地址
        this.doAddrAdd(pBtn, pParentData);
        break;
      case 'addrEdit': //   我的地址列表-修改地址
        this.doAddrEdit(pBtn, pParentData);
        break;
      case 'accountManager': //   客户信息-分配客户经理
        this.doAccountManager(pBtn, pParentData);
        break;
      case 'removeAddress': // 我的地址管理-删除地址
        this.doRemoveAddress(pBtn, pParentData);
        break;
      case 'removeInvoice': //我的发票管理-删除发票
        this.doRemoveInvoice(pBtn, pParentData);
        break;
      case 'verificationPassword': // 客户信息-验证密码
        this.doVerificationPassword(pBtn, pParentData);
        break;
      case 'deleteDraft': // 客户信息-验证密码
        this.doDeleteDraft(pBtn, pParentData);
        break;
      case 'submitFormalOrder': // 客户信息-验证密码
        this.doSubmitFormalOrder(pBtn, pParentData);
        break;
      case 'choiceInvoice': // 草稿箱-选择发票
        this.doChoiceInvoice(pBtn, pParentData);
        break;
      case 'choiceAddress': // 草稿箱-选择地址
        this.doChoiceAddress(pBtn, pParentData);
        break;
      case 'submitDraftOrder': // 草稿箱-提交草稿订单
        this.doSubmitDraftOrder(pBtn, pParentData);
        break;
      case 'esOrderDetail': // 订单列表-订单详情
        this.doEsOrderDetail(pBtn, pParentData);
        break;
      case 'esOrderFiles': // 订单列表-技术报告
        this.doEsOrderFiles(pBtn, pParentData);
        break;
      case 'approved': // 客户信息-通过审核
        this.doApproved(pBtn, pParentData);
        break;
      case 'approvalFailed': // 客户信息-不通过审核
        this.doApprovalFailed(pBtn, pParentData);
        break;
      case 'gotGeOrder': // 技术支持工作台
        this.doGotGeOrder(pBtn, pParentData);
        break;
      case 'receivingProduction': // 接单生产订单
        this.doReceivingProduction(pBtn, pParentData);
        break;
      case 'completeProduction': // 完成生产订单
        this.doCompleteProduction(pBtn, pParentData);
        break;

    }
  }

  doGotGeOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认接单么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'gotGeOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doEsOrderFiles(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
        return item['id'] === pParentData['id'];
      });
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      if (tabIndex >= 0) {
        this.dataCenterService._dataObject.index = tabIndex;
        this.dataCenterService._dataObject.nowMenu = pParentData['id'];
        this.dataCenterService.refreshDataCenterInStorage();
      } else {
        let id = selectList['0']['ID'];
        let title = pParentData['title'] + ":" + selectList['0']['ORDER_CODE'];
        this.dataCenterService.newTab({
          id: id, // 唯一标识
          nowMenu: id, // 菜单ID 用于详情返回
          title: title, // 菜单名称 用户tab展示
          formType: 'customForm', // 菜单类型 用于tab过滤显示模板
          custormFormType: 'app-es-order-files',
          data: {}, // 查询返回数据
        });
      }
    }
  }

  doEsOrderDetail(pBtn, pParentData) {
    let tabIndex = _.findIndex(this.dataCenterService._dataObject.list, (item) => {
      return item['parentDataId'] === pParentData['id'];
    });
    if (tabIndex > 0) {
      this.dataCenterService._dataObject.index = tabIndex;
      this.dataCenterService._dataObject.nowMenu = pParentData['id'];
      this.dataCenterService.refreshDataCenterInStorage();
    } else {
      let title = pParentData['title'] + ":" + pParentData['selectData']['ORDER_CODE'];
      this.dataCenterService.newTab({
        id: UUID.UUID(), // 唯一标识
        parentDataId: pParentData['id'], // 唯一标识
        nowMenu: pParentData['id'], // 菜单ID 用于详情返回
        title: title, // 菜单名称 用户tab展示
        formType: 'customForm', // 菜单类型 用于tab过滤显示模板
        custormFormType: 'app-es-order-detail',
        data: {}, // 查询返回数据
      });
    }
  }

  doChoiceAddress(pBtn, pParentData) {

    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户选择地址',
        nzContent: FormModalComponent,
        nzWidth: 1200,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ADDRESS_ID',
                'name': 'ADDRESS_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '地址信息',
                'comment': '地址信息',
                'placeholder': '请选择地址信息',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_ADDRESS_POPLIST.json',
                'dictionaryPF': null
              }
            ],
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'addressId': this.findOneColumnValue(data, 'ADDRESS_ID'),
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'orderId': _.get(selectList, [0, 'ID'])
          };

          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doChoiceAddress', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 产品服务上架
  doOnShelfService(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认将该服务上架么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/GeServiceController', 'doOnShelfService', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 产品服务上架
  doDownShelfService(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认将该服务下架么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/GeServiceController', 'doDownShelfService', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }


  // 服务列表:新增服务
  doWorkflow(pBtn, pParentData) {
    pParentData['btnName'] = pBtn.ACTION_VALUE;
    pParentData['conditionColumns'] = {};
    pParentData['formType'] = 'customForm';
    pParentData['modeType'] = 'add';
    pParentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  /**
   * 预付款审核
   * @param pBtn
   * @param pParentData
   */
  doCustomerApCheck(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认预付款审核么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkCustomerAp', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 加入购物车 ok
  doAddGoods(pBtn, pParentData) {
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    this.modalService.create({
      nzTitle: '加入购物车',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'CUST_ID',
              'name': 'CUST_ID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '客户',
              'comment': '客户',
              'placeholder': '请选择客户',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
              'dictionaryPF': null
            },
            {
              'column': 'SKU_ID',
              'name': 'SKU_ID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '商品',
              'comment': '商品',
              'placeholder': '请选择商品',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'genecreate/basic/dict/ES_SKU_POPLIST.json',
              'dictionaryPF': null
            },
            {
              'column': 'SKU_NUMBER',
              'name': 'SKU_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '商品数量',
              'comment': '商品数量',
              'placeholder': '请输入商品数量',
              'model': null,
              'visible': true,
              'require': true,
              'dictionaryPF': null
            },
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'custId': this.findOneColumnValue(data, 'CUST_ID'),
          'skuId': this.findOneColumnValue(data, 'SKU_ID'),
          'skuNumber': this.findOneColumnValue(data, 'SKU_NUMBER')
        };
        this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'addGoods', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  // 等待分配的订单:分配按钮 ok
  doOrAllocation(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '分配客户经理',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'OR_PRINCIPAL_USERID',
                'name': 'OR_PRINCIPAL_USERID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户经理',
                'comment': '客户经理',
                'placeholder': '请选择客户经理',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/role/system_user_technical_support.json',
                'dictionaryPF': null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'assignedUserid': this.findOneColumnValue(data, 'OR_PRINCIPAL_USERID')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doOrAllocat', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  // 我的跟踪订单:作废
  doCancelOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认订单作废么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID,
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doCancelOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 我的跟踪订单:退回分配 ok
  doReturnOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '退回分配',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ORDER_MEMO',
                'name': 'ORDER_MEMO_NAME',
                'component': 'textarea',
                'customize': 24,
                'lable': '退回分配备注',
                'comment': '退回分配备注',
                'placeholder': '请输入退回分配备注',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'orderMemo': this.findOneColumnValue(data, 'ORDER_MEMO')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doReturnOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }


  }

  // 我的跟踪订单:上传合同 ok
  doUploadContract(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '上传合同',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ATT_URL01',
                'name': 'ATT_URL01_NAME',
                'component': 'file',
                'customize': 24,
                'lable': '上传合同',
                'comment': '上传合同',
                'placeholder': '请上传合同',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'CONTRACT_CODE',
                'name': 'CONTRACT_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '合同号',
                'comment': '合同号',
                'placeholder': '请输入合同号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'CONTRACT_MONEY',
                'name': 'CONTRACT_MONEY_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '合同金额',
                'comment': '合同金额',
                'placeholder': '请输入合同金额',
                'model': 0,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'attUrl01': this.findOneColumnValue(data, 'ATT_URL01'),
            'contractCode': this.findOneColumnValue(data, 'CONTRACT_CODE'),
            'contractMoney': this.findOneColumnValue(data, 'CONTRACT_MONEY')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doUploadContract', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }


  }

  // 等待开发票的订单:开票 ok
  doInvoicingOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '发票信息',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'INVOICE_CODE',
                'name': 'INVOICE_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '发票号',
                'comment': '发票号',
                'placeholder': '请输入发票号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'invoiceCode': this.findOneColumnValue(data, 'INVOICE_CODE')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doInvoicingOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }


  }

  // 等待发货的订单:发货 ok
  doShipOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.modalService.create({
        nzTitle: '发货信息',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'EXPRESS_NAME',
                'name': 'EXPRESS_NAME_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '快递公司',
                'comment': '快递公司',
                'placeholder': '请输入快递公司',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_EXPRESS_NAME_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'EXPRESS_CODE',
                'name': 'EXPRESS_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '快递单号',
                'comment': '快递单号',
                'placeholder': '请输入快递单号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'expressName': this.findOneColumnValue(data, 'EXPRESS_NAME'),
            'expressCode': this.findOneColumnValue(data, 'EXPRESS_CODE')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doShipOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  // 等待核销的订单:核销 ok
  doWriteoffOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '核销信息',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PAID_BANKNO',
                'name': 'PAID_BANKNO_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '银行名称',
                'comment': '银行名称',
                'placeholder': '请输入银行名称',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PAID_CODE',
                'name': 'PAID_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '银行单号',
                'comment': '银行单号',
                'placeholder': '请输入银行单号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'paidBankno': this.findOneColumnValue(data, 'PAID_BANKNO'),
            'paidCode': this.findOneColumnValue(data, 'PAID_CODE'),
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doWriteoffOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 确认生产完成 ok
  doProductionOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认订单生产完成么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doProductionOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 客户确认收货 ok
  doCustConfirmReceiving(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户确认收货',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '客户名称',
                'comment': '客户名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择客户名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/BASIC_CUST.json',
                'dictionaryPF': null
              },
              {
                'column': 'CONFIRM_NAME',
                'name': 'CONFIRM_NAME_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '签收人',
                'comment': '签收人',
                'placeholder': '请输入签收人',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'CONFIRM_MEMO',
                'name': 'CONFIRM_MEMO_NAME',
                'component': 'textarea',
                'customize': 24,
                'lable': '签收备注',
                'comment': '签收备注',
                'placeholder': '请输入签收备注',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID'),
            'confirmName': this.findOneColumnValue(data, 'CONFIRM_NAME'),
            'confirmMemo': this.findOneColumnValue(data, 'CONFIRM_MEMO')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doCompleted', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });

    }


  }

  // 合同客户确认 ok
  doContractCustConfirm(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '合同客户确认',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '客户名称',
                'comment': '客户名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择客户名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/BASIC_CUST.json',
                'dictionaryPF': null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doCustomerConfirm', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  // 客户发起退货 ok
  doCustomerInitiatesAReturn(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户发起退货',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '客户名称',
                'comment': '客户名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择客户名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/BASIC_CUST.json',
                'dictionaryPF': null
              },
              {
                'column': 'REASONS_FOR_RETURN',
                'name': 'REASONS_FOR_RETURN_NAME',
                'component': 'textarea',
                'customize': 24,
                'lable': '退货原因',
                'comment': '退货原因',
                'placeholder': '请输入退货原因',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID'),
            'reasonsForReturn': this.findOneColumnValue(data, 'REASONS_FOR_RETURN')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doCustomerInitiatesReturn', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }

  }

  // 客户退货发货 ok
  doCustomerReturnShipment(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户退货发货',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 36,
                'searchColumn': ['CUSTOMER_NAME'],
                'uniqueCheck': ['ID', 'U_DELETE'],
                'lable': '客户名称',
                'comment': '客户名称',
                'showParam': true,
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择客户名称',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/BASIC_CUST.json',
                'dictionaryPF': null
              },
              {
                'column': 'EXPRESS_NAME',
                'name': 'EXPRESS_NAME_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '快递公司',
                'comment': '快递公司',
                'placeholder': '请输入快递公司',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_EXPRESS_NAME_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'EXPRESS_CODE',
                'name': 'EXPRESS_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '快递单号',
                'comment': '快递单号',
                'placeholder': '请输入快递单号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID'),
            'expressName': this.findOneColumnValue(data, 'EXPRESS_NAME'),
            'expressCode': this.findOneColumnValue(data, 'EXPRESS_CODE')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doReExpress', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  // 退货审批 ok
  doCeturnApproval(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '退货审批',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'RE_APPROVE_STATE',
                'name': 'RE_APPROVE_STATE_NAME',
                'component': 'select',
                'customize': 24,
                'lable': '退货审批状态',
                'comment': '退货审批状态',
                'placeholder': '请选择退货审批状态',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/RE_APPROVE_STATE.json',
                'dictionaryPF': null
              },
              {
                'column': 'RE_APPROVER_RESULT',
                'name': 'RE_APPROVER_RESULT_NAME',
                'component': 'textarea',
                'customize': 24,
                'lable': '退货审批备注',
                'comment': '退货审批备注',
                'placeholder': '请输入退货审批备注',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'reApproveState': this.findOneColumnValue(data, 'RE_APPROVE_STATE'),
            'reApproverResult': this.findOneColumnValue(data, 'RE_APPROVER_RESULT')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doReturnAgreed', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });

    }


  }

  // 退货,确认收货 ok
  doConfirmReturnReceipt(pBtn, pParentData) {
    // 这个地方之能一个个的操作。
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '确认收货',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'REFUND_DEDUCTION_MONEY',
                'name': 'REFUND_DEDUCTION_MONEY_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '退款扣款金额',
                'comment': '退款扣款金额',
                'placeholder': '请输入退款扣款金额',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'refundDeductionMoney': this.findOneColumnValue(data, 'REFUND_DEDUCTION_MONEY')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doConfirmReReceipt', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 退款核销 ok
  doRefundVerification(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '退款信息',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'REFUND_BANK',
                'name': 'REFUND_BANK_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '退款银行',
                'comment': '退款银行',
                'placeholder': '请输入退款银行',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_PAID_BANKNO_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'REFUND_BANKNO',
                'name': 'REFUND_BANKNO_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '退款银行单号',
                'comment': '退款银行单号',
                'placeholder': '请输入退款银行单号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'refundBank': this.findOneColumnValue(data, 'REFUND_BANK'),
            'refundCode': this.findOneColumnValue(data, 'REFUND_BANKNO')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderController', 'doWriteoffRefund', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 客户选择开票信息
  doChoiceInvoice(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '客户选择开票',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'INVOICE_ID',
                'name': 'INVOICE_ID_NAME',
                'component': 'poplist',
                'lable': '发票信息',
                'comment': '发票信息',
                'placeholder': '请选择发票信息',
                'customize': 36,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_INVOICE_ID_POPLIST.json',
                'dictionaryPF': null
              }
            ],
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'invoiceId': this.findOneColumnValue(data, 'INVOICE_ID'),
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'orderId': _.get(selectList, [0, 'ID'])
          };

          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'doChoiceInvoice', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 删除商品
  doDeleteGoods(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认删除商品么？',
        suc: () => {
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'deleteGoods', null, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 修改数量
  doModifiedQuantity(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '修改数量',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'GOODS_NUMBER',
                'name': 'GOODS_NUMBER_NAME',
                'component': 'input',
                'customize': 24,
                'type': 'number',
                'lable': '商品数量',
                'comment': '商品数量',
                'placeholder': '请输入商品数量',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let ids = this.formatSelectEntity(selectList, 'ID');
          let shoppingCartDetailId = _.get(ids, [0, 'id']);
          let queryData = {
            'shoppingCartDetailId': shoppingCartDetailId,
            'skuNumber': this.findOneColumnValue(data, 'GOODS_NUMBER')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'modifiedQuantity', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  // 结算 ok
  doSettlement(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '结算',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'accountEsShoppingCart', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 提交订单
  doPlaceOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '提交订单',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'INVOICE_ID',
                'name': 'INVOICE_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '发票信息',
                'comment': '发票信息',
                'placeholder': '请选择发票信息',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_INVOICE_ID_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'ADDRESS_ID',
                'name': 'ADDRESS_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '地址信息',
                'comment': '地址信息',
                'placeholder': '请选择地址信息',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_ADDRESS_ID_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'custId': this.findOneColumnValue(data, 'CUST_ID'),
            'invoiceId': this.findOneColumnValue(data, 'INVOICE_ID'),
            'addressId': this.findOneColumnValue(data, 'ADDRESS_ID')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'submitOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 再次购买 todo
  doBuyAgain(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认再次购买么？',
        suc: () => {

        }
      });
    }
  }

  // 商品列表新建
  doNewlyBuild(pBtn, pParentData) {
    pParentData['btnName'] = pBtn.ACTION_VALUE;
    pParentData['conditionColumns'] = {};
    pParentData['formType'] = 'customForm';
    pParentData['modeType'] = 'add';
    pParentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 商品列表上架
  doOffShelf(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认上架么？',
        suc: () => {

        }
      });
    }
  }

  // 商品列表下架
  doOnShelf(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认下架么？',
        suc: () => {

        }
      });
    }
  }

  // 商品列表修改库存/价格
  doStockPrice(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '修改库存/价格',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'SKU_PRICE',
                'name': 'SKU_PRICE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': 'SKU价格',
                'comment': 'SKU价格',
                'placeholder': '请输入SKU价格',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'SKU_STOCK',
                'name': 'SKU_STOCK_NAME',
                'component': 'input',
                'customize': 24,
                'lable': 'SKU库存',
                'comment': 'SKU库存',
                'placeholder': '请输入SKU库存',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'orderMemo': this.findOneColumnValue(data, 'ORDER_MEMO')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
        }
      });

    }


  }

  // 客户取消订单
  doCustCancelOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '提交订单',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
      });
    }
  }

  // 服务列表:新增服务
  doInputService(pBtn, pParentData) {
    pParentData['btnName'] = pBtn.ACTION_VALUE;
    pParentData['conditionColumns'] = {};
    pParentData['formType'] = 'customForm';
    pParentData['modeType'] = 'add';
    pParentData['actionArray'] = [];
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 服务列表:修改服务
  doUpdateService(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) < 1) {
      return false;
    }
    {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      pParentData['btnName'] = pBtn.ACTION_VALUE;
      pParentData['conditionColumns'] = {};
      pParentData['formType'] = 'customForm';
      pParentData['modeType'] = 'add';
      pParentData['actionArray'] = [];
      pParentData['id'] = ids[0]['id'];
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }

  // 全部订单-测试样品
  doTestSample(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认测试订单样品么？',
        suc: () => {
          let esOrderSampleId = this.util.Base_getUuid();
          let esOrderSample01 = this.util.Base_getUuid();
          let esOrderSample02 = this.util.Base_getUuid();

          let queryData = {
            'esCustmerId': _.get(selectList, ['0', 'CUST_ID']),
          };
          const bodyData = {
            'esOrderSample': {
              'id': esOrderSampleId,
              'orderId': _.get(selectList, ['0', 'ID']),
              'deliveryLogisticsCompany': '申通快递',
              'deliveryLogisticsCode': '223123232',
              'sampleMemo': '注意保管'
            },
            'esOrderSampleDetailList': [
              {
                'id': esOrderSample01,
                'sampleId': esOrderSampleId,
                'species': 'TISSUE',
                'sampleType': '人体组织',
                'sample': '头发',
                'infectious': 0,
                'quantity': 100
              },
              {
                'id': esOrderSample02,
                'sampleId': esOrderSampleId,
                'species': 'TISSUE',
                'sampleType': '人体组织',
                'sample': '指甲',
                'infectious': 1,
                'quantity': 2
              }
            ],
            'esOrderSamplePicList': [
              {
                'id': this.util.Base_getUuid(),
                'sampleDetailId': esOrderSample01,
                'picAddress': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/genecreate/030a1033535f10a1daa72b5fae7e300c.jpg',
              },
              {
                'id': this.util.Base_getUuid(),
                'sampleDetailId': esOrderSample01,
                'picAddress': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/genecreate/030a1033535f10a1daa72b5fae7e300c.jpg',
              },
              {
                'id': this.util.Base_getUuid(),
                'sampleDetailId': esOrderSample02,
                'picAddress': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com/iron/genecreate/030a1033535f10a1daa72b5fae7e300c.jpg',
              }
            ]
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderSampleController', 'input', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 样品管理-已用完
  doUsedUp(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认样品已用完么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID,
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderSampleController', 'usedUp', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 样品管理-回寄样品
  doReturnSample(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '回寄样品',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'RETURN_LOGISTICS_COMPANY',
                'name': 'RETURN_LOGISTICS_COMPANY_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '回寄物流公司',
                'comment': '回寄物流公司',
                'placeholder': '请输入回寄物流公司',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_EXPRESS_NAME_POPLIST.json',
                'dictionaryPF': null
              },
              {
                'column': 'RETURN_LOGISTICS_CODE',
                'name': 'RETURN_LOGISTICS_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '回寄物流单号',
                'comment': '回寄物流单号',
                'placeholder': '请输入回寄物流单号',
                'model': null,
                'visible': true,
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'returnLogisticsCompany': this.findOneColumnValue(data, 'RETURN_LOGISTICS_COMPANY'),
            'returnLogisticsCode': this.findOneColumnValue(data, 'RETURN_LOGISTICS_CODE')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderSampleController', 'returnExpress', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }

  }

  // 样品管理-确认收货
  doConfirmReceipt(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '确认收货',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'RECEIVE_PIC01',
                'name': 'RECEIVE_PIC01_NAME',
                'component': 'picture',
                'lable': '收件单号照片',
                'comment': '收件单号照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择收件单号照片',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'RECEIVE_PIC02',
                'name': 'RECEIVE_PIC02_NAME',
                'component': 'picture',
                'lable': '样品照片',
                'comment': '样品照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择样品照片',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'RECEIVE_PIC03',
                'name': 'RECEIVE_PIC03_NAME',
                'component': 'picture',
                'lable': '样品异常破损照片',
                'comment': '样品异常破损照片',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择样品异常破损照片',
                'model': null,
                'visible': true,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'receivePic01': this.findOneColumnValue(data, 'RECEIVE_PIC01'),
            'receivePic02': this.findOneColumnValue(data, 'RECEIVE_PIC02'),
            'receivePic03': this.findOneColumnValue(data, 'RECEIVE_PIC03')
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsOrderSampleController', 'gotExpress', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 待跟踪订单-完成本工序
  doCompleteTheProcess(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      this.confirmModal.show('confirm', {
        title: '确认完成本工序么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID,
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/EsProductionController', 'doCompleteCurrentOrderProcess', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息列表-手机号注册验证码
  doPhoneRegisterVerification(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '手机号注册验证码',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PHONE_NUMBER',
              'name': 'PHONE_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '手机号',
              'comment': '手机号',
              'placeholder': '请输入手机号',
              'model': null,
              'visible': true,
              'require': true
            },
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'phoneNumber': this.findOneColumnValue(data, 'PHONE_NUMBER')
        };
        this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'phoneRegisterVerification', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });

      }
    });

  }

  // 客户信息列表-使用手机号注册
  doUsePhoneRegister(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '使用手机号注册',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PHONE_NUMBER',
              'name': 'PHONE_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '手机号',
              'comment': '手机号',
              'placeholder': '请输入手机号',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'PASSWORD01',
              'name': 'PASSWORD01_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '密码',
              'comment': '密码',
              'placeholder': '请输入密码',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'PASSWORD02',
              'name': 'PASSWORD02_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '重复密码',
              'comment': '重复密码',
              'placeholder': '请输入重复密码',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'VERIFICATION_CODE',
              'name': 'VERIFICATION_CODE_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '验证码',
              'comment': '验证码',
              'placeholder': '请输入验证码',
              'model': null,
              'visible': true,
              'require': true
            },
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let password01 = this.findOneColumnValue(data, 'PASSWORD01');
        password01 = Md5.hashStr(password01);
        password01 = Md5.hashStr(password01);
        let password02 = this.findOneColumnValue(data, 'PASSWORD02');
        password02 = Md5.hashStr(password02);
        password02 = Md5.hashStr(password02);

        let queryData = {
          'phoneNumber': this.findOneColumnValue(data, 'PHONE_NUMBER'),
          'password01': password01,
          'password02': password02,
          'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
        };
        this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'registeredByPhoneNumber', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  // 客户信息列表-修改密码验证码
  doModifyPasswordVerification(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '修改密码验证码',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'changePassword', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //客户信息列表-用户修改密码
  doUserModifyPassword(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '用户修改密码',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PASSWORD01',
                'name': 'PASSWORD01_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '密码',
                'comment': '密码',
                'placeholder': '请输入密码',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'PASSWORD02',
                'name': 'PASSWORD02_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '重复密码',
                'comment': '重复密码',
                'placeholder': '请输入重复密码',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'VERIFICATION_CODE',
                'name': 'VERIFICATION_CODE_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '验证码',
                'comment': '验证码',
                'placeholder': '请输入验证码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let password01 = this.findOneColumnValue(data, 'PASSWORD01');
          password01 = Md5.hashStr(password01);
          password01 = Md5.hashStr(password01);
          let password02 = this.findOneColumnValue(data, 'PASSWORD02');
          password02 = Md5.hashStr(password02);
          password02 = Md5.hashStr(password02);

          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'ID']),
            'password01': password01,
            'password02': password02,
            'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'modifyLoginPassword', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //客户信息列表-使用验证码登录
  doUseVerificationLogin(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '使用验证码登录',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PHONE_NUMBER',
              'name': 'PHONE_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '手机号',
              'comment': '手机号',
              'placeholder': '请输入手机号',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'VERIFICATION_CODE',
              'name': 'VERIFICATION_CODE_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '验证码',
              'comment': '验证码',
              'placeholder': '请输入验证码',
              'model': null,
              'visible': true,
              'require': true
            },
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'phoneNumber': this.findOneColumnValue(data, 'PHONE_NUMBER'),
          'verificationCode': this.findOneColumnValue(data, 'VERIFICATION_CODE')
        };
        this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'loginWithPhoneNumber', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  //客户信息列表-判断手机是否存在
  doPhoneWhethereExistence(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '判断手机是否存在',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PHONE_NUMBER',
              'name': 'PHONE_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '手机号',
              'comment': '手机号',
              'placeholder': '请输入手机号',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'phoneNumber': this.findOneColumnValue(data, 'PHONE_NUMBER')
        };
        this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'existPhoneNumber', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  //   客户信息列表-发送登陆验证码
  doSendLoginSms(pBtn, pParentData) {
    this.modalService.create({
      nzTitle: '发送登陆验证码',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PHONE_NUMBER',
              'name': 'PHONE_NUMBER_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '手机号',
              'comment': '手机号',
              'placeholder': '请输入手机号',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let queryData = {
          'phoneNumber': this.findOneColumnValue(data, 'PHONE_NUMBER')
        };
        this.requestService.doActionByHdbsthor('AppApi/SendSMSController', 'sendLoginSms', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  // 样品管理列表-新增样品信息
  doNewSamples(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认新增样品信息么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
        }
      });
    }
  }

  //  前端接口测试-我的发票列表
  doInvoiceDefault(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '默认发票',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },

        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esInvoiceId': _.get(selectList, [0, 'ID']),
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsInvoiceController', 'updateDefault', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //   前端接口测试-我的地址列表
  doAddrDefault(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '默认地址',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esAddressId': _.get(selectList, [0, 'ID']),
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsAddressController', 'updateDefault', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //   前端接口测试-服务产品列表
  doNewServiceOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '新增服务订单',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'geServiceId': _.get(selectList, [0, 'ID']),
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };

          this.requestService.doActionByHdbsthor('AppApi/GeServiceController', 'addServiceOrder', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //   我的发票列表-新建发票
  doInvoiceAdd(pBtn, pParentData) {
    {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '新建发票',
        nzContent: FormModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'CUST_NAME',
                    'name': 'CUST_NAME_NAME',
                    'component': 'input',
                    'lable': '客户名称',
                    'comment': '客户名称',
                    'placeholder': '请输入客户名称',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false
                  }
                ]
              },
              {
                'column': 'INVOICE_NAME',
                'name': 'INVOICE_NAME_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '发票抬头',
                'comment': '发票抬头',
                'placeholder': '请输入发票抬头',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'INVOICE_LICENCE',
                'name': 'INVOICE_LICENCE_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '单位税号',
                'comment': '单位税号',
                'placeholder': '请输入单位税号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'BUSINESS_ADDRESS',
                'name': 'BUSINESS_ADDRESS_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '营业地址',
                'comment': '营业地址',
                'placeholder': '请输入营业地址',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'INVOICE_MOBILE',
                'name': 'INVOICE_MOBILE_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '注册电话',
                'comment': '注册电话',
                'placeholder': '请输入注册电话',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'INVOICE_BANK',
                'name': 'INVOICE_BANK_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '开户银行',
                'comment': '开户银行',
                'placeholder': '请输入开户银行',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'INVOICE_BANKNO',
                'name': 'INVOICE_BANKNO_NAME',
                'component': 'input',
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                'lable': '银行账号',
                'comment': '银行账号',
                'placeholder': '请输入银行账号',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                "column": "INVOICE_DEFAULT",
                "name": "INVOICE_DEFAULT_NAME",
                "component": "select",
                'lableSpan': 7,
                'itemSpan': 17,
                'customize': 12,
                "lable": "默认标记",
                "comment": "默认标记",
                "placeholder": "默认标记",
                "insertValue": null,
                "model": "DEFAULT",
                "visible": true,
                "require": true,
                "dictionary": "agriculture/basic/dict/INVOICE_DEFAULT.json",
                "dictionaryPF": null
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };

          const bodyData = {
            'invoiceBank': this.findOneColumnValue(data, 'INVOICE_BANK'),
            'invoiceName': this.findOneColumnValue(data, 'INVOICE_NAME'),
            'invoiceBankno': this.findOneColumnValue(data, 'INVOICE_BANKNO'),
            'bankCode': this.findOneColumnValue(data, 'BANK_CODE'),
            'billingCode': this.findOneColumnValue(data, 'BILLING_CODE'),
            'invoiceLicence': this.findOneColumnValue(data, 'INVOICE_LICENCE'),
            'businessAddress': this.findOneColumnValue(data, 'BUSINESS_ADDRESS'),
            'invoiceMobile': this.findOneColumnValue(data, 'INVOICE_MOBILE'),
            'invoiceLegalPerson': this.findOneColumnValue(data, 'INVOICE_LEGAL_PERSON'),
            'invoiceRegid': this.findOneColumnValue(data, 'INVOICE_REGID'),
            'invoiceTax': this.findOneColumnValue(data, 'INVOICE_TAX'),
            'invoiceRegidUrl': this.findOneColumnValue(data, 'INVOICE_REGID_URL'),
            'invoiceTaxUrl': this.findOneColumnValue(data, 'INVOICE_TAX_URL'),
            'invoiceLicenceUrl': this.findOneColumnValue(data, 'INVOICE_LICENCE_URL'),
            'invoiceDefault': this.findOneColumnValue(data, 'INVOICE_DEFAULT')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsInvoiceController', 'addInvoice', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  //   我的发票列表-修改发票
  doInvoiceEdit(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });

      let columnsDatas = {
        'columns': [
          {
            'column': 'ID',
            'name': 'ID_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '主键',
            'comment': '主键',
            'placeholder': '请输入主键',
            'model': null,
            'visible': false,
            'disable': true,
            'require': true
          },
          {
            'column': 'CUST_ID',
            'name': 'CUST_ID_NAME',
            'component': 'poplist',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '客户',
            'comment': '客户',
            'placeholder': '请选择客户',
            'model': null,
            'visible': true,
            'require': true,
            'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
            'dictionaryPF': null,
            'columnGroup': [
              {
                'column': 'CUST_NAME',
                'name': 'CUST_NAME_NAME',
                'component': 'input',
                'lable': '客户名称',
                'comment': '客户名称',
                'placeholder': '请输入客户名称',
                'fromValue': 'name',
                'model': null,
                'visible': false,
                'require': false
              }
            ]
          },
          {
            'column': 'INVOICE_NAME',
            'name': 'INVOICE_NAME_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '发票抬头',
            'comment': '发票抬头',
            'placeholder': '请输入发票抬头',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'INVOICE_LICENCE',
            'name': 'INVOICE_LICENCE_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '单位税号',
            'comment': '单位税号',
            'placeholder': '请输入单位税号',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'BUSINESS_ADDRESS',
            'name': 'BUSINESS_ADDRESS_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '营业地址',
            'comment': '营业地址',
            'placeholder': '请输入营业地址',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'INVOICE_MOBILE',
            'name': 'INVOICE_MOBILE_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '注册电话',
            'comment': '注册电话',
            'placeholder': '请输入注册电话',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'INVOICE_BANK',
            'name': 'INVOICE_BANK_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '开户银行',
            'comment': '开户银行',
            'placeholder': '请输入开户银行',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'INVOICE_BANKNO',
            'name': 'INVOICE_BANKNO_NAME',
            'component': 'input',
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            'lable': '银行账号',
            'comment': '银行账号',
            'placeholder': '请输入银行账号',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            "column": "INVOICE_DEFAULT",
            "name": "INVOICE_DEFAULT_NAME",
            "component": "select",
            'lableSpan': 7,
            'itemSpan': 17,
            'customize': 12,
            "lable": "默认标记",
            "comment": "默认标记",
            "placeholder": "默认标记",
            "insertValue": null,
            "model": "DEFAULT",
            "visible": true,
            "require": true,
            "dictionary": "agriculture/basic/dict/INVOICE_DEFAULT.json",
            "dictionaryPF": null
          },
        ]
      }

      // 反写弹出框数据。
      this.setOneColumnValue(columnsDatas, 'ID', _.get(selectList, [0, 'ID']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_NAME', _.get(selectList, [0, 'INVOICE_NAME']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_BANK', _.get(selectList, [0, 'INVOICE_BANK']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_BANKNO', _.get(selectList, [0, 'INVOICE_BANKNO']));
      this.setOneColumnValue(columnsDatas, 'BANK_CODE', _.get(selectList, [0, 'BANK_CODE']));
      this.setOneColumnValue(columnsDatas, 'BILLING_CODE', _.get(selectList, [0, 'BILLING_CODE']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_LICENCE', _.get(selectList, [0, 'INVOICE_LICENCE']));
      this.setOneColumnValue(columnsDatas, 'BUSINESS_ADDRESS', _.get(selectList, [0, 'BUSINESS_ADDRESS']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_MOBILE', _.get(selectList, [0, 'INVOICE_MOBILE']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_LEGAL_PERSON', _.get(selectList, [0, 'INVOICE_LEGAL_PERSON']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_REGID', _.get(selectList, [0, 'INVOICE_REGID']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_TAX', _.get(selectList, [0, 'INVOICE_TAX']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_REGID_URL', _.get(selectList, [0, 'INVOICE_REGID_URL']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_TAX_URL', _.get(selectList, [0, 'INVOICE_TAX_URL']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_LICENCE_URL', _.get(selectList, [0, 'INVOICE_LICENCE_URL']));
      this.setOneColumnValue(columnsDatas, 'INVOICE_DEFAULT', _.get(selectList, [0, 'INVOICE_DEFAULT']));

      this.modalService.create({
        nzTitle: '修改发票',
        nzContent: FormModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: columnsDatas
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID'])
          };
          const bodyData = {
            'id': this.findOneColumnValue(data, 'ID'),
            'invoiceBank': this.findOneColumnValue(data, 'INVOICE_BANK'),
            'invoiceName': this.findOneColumnValue(data, 'INVOICE_NAME'),
            'invoiceBankno': this.findOneColumnValue(data, 'INVOICE_BANKNO'),
            'bankCode': this.findOneColumnValue(data, 'BANK_CODE'),
            'billingCode': this.findOneColumnValue(data, 'BILLING_CODE'),
            'invoiceLicence': this.findOneColumnValue(data, 'INVOICE_LICENCE'),
            'businessAddress': this.findOneColumnValue(data, 'BUSINESS_ADDRESS'),
            'invoiceMobile': this.findOneColumnValue(data, 'INVOICE_MOBILE'),
            'invoiceLegalPerson': this.findOneColumnValue(data, 'INVOICE_LEGAL_PERSON'),
            'invoiceRegid': this.findOneColumnValue(data, 'INVOICE_REGID'),
            'invoiceTax': this.findOneColumnValue(data, 'INVOICE_TAX'),
            'invoiceRegidUrl': this.findOneColumnValue(data, 'INVOICE_REGID_URL'),
            'invoiceTaxUrl': this.findOneColumnValue(data, 'INVOICE_TAX_URL'),
            'invoiceLicenceUrl': this.findOneColumnValue(data, 'INVOICE_LICENCE_URL'),
            'invoiceDefault': this.findOneColumnValue(data, 'INVOICE_DEFAULT')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsInvoiceController', 'modifyInvoice', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  //   我的地址列表-新建地址
  doAddrAdd(pBtn, pParentData) {
    {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '新建地址',
        nzContent: FormModalComponent,
        nzWidth: 600,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'lable': '客户',
                'comment': '客户',
                'placeholder': '请选择客户',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/ES_CUSTOMER_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'CUST_NAME',
                    'name': 'CUST_NAME_NAME',
                    'component': 'input',
                    'lable': '客户名称',
                    'comment': '客户名称',
                    'placeholder': '请输入客户名称',
                    'fromValue': 'name',
                    'model': null,
                    'visible': false,
                    'require': false
                  }
                ]
              },
              {
                'column': 'PCA',
                'name': 'PCA_NAME',
                'component': 'cascader',
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'lable': '省市区',
                'comment': '省市区',
                'placeholder': '请选择省市区',
                'model': null, // 这个里面会保存为逗号为间隔的字符串 湖北省，武汉市，洪山区
                'visible': true,
                'require': true,
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    'column': 'PROVINCE',
                    'name': 'PROVINCE_NAME',
                    'component': 'input',
                    'lable': '省',
                    'comment': '省',
                    'placeholder': '请输入省',
                    'fromValue': 'name',
                    'model': null,
                    'dictionary': 'genecreate/basic/dict/ES_PROVINCE.json',
                    'dictionaryPF': null,
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  },
                  {
                    'column': 'CITY',
                    'name': 'CITY_NAME',
                    'component': 'input',
                    'lable': '市',
                    'comment': '市',
                    'placeholder': '请输入市',
                    'fromValue': 'name',
                    'model': null,
                    'dictionary': 'genecreate/basic/dict/ES_CITY.json',
                    'dictionaryPF': null,
                    'dictionaryConditionColumn': 'PROVINCE', //这个代表使用 ES_CITY.json 之中的 作为查询条件 PROVINCE ，where PROVINCE = '第一个选中的值'
                    'visible': false,
                    'require': false,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  },
                  {
                    'column': 'AREA',
                    'name': 'AREA_NAME',
                    'component': 'input',
                    'lable': '县区',
                    'comment': '县区',
                    'placeholder': '请输入县区',
                    'model': null,
                    'visible': false,
                    'require': false,
                    'dictionary': 'genecreate/basic/dict/ES_AREA_END.json',
                    'dictionaryPF': null,
                    'dictionaryConditionColumn': 'CITY',
                    // 这个代表使用 ES_DISTRICT.json 之中的 作为查询条件 CITY ，where CITY = '第二个选中的值'
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              },
              {
                'column': 'GOT_NAME',
                'name': 'GOT_NAME_NAME',
                'component': 'input',
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'lable': '收货人',
                'comment': '收货人',
                'placeholder': '请输入收货人',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'GOT_MOBILE',
                'name': 'GOT_MOBILE_NAME',
                'component': 'input',
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'lable': '收货人电话',
                'comment': '收货人电话',
                'placeholder': '请输入收货人电话',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                'column': 'ADDR_DETAIL',
                'name': 'ADDR_DETAIL_NAME',
                'component': 'inputMulti',
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'lable': '详细地址',
                'comment': '详细地址',
                'placeholder': '请输入详细地址',
                'model': null,
                'visible': true,
                'require': true
              },
              {
                "column": "ADDR_DEFAULT",
                "name": "ADDR_DEFAULT_NAME",
                "component": "select",
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                "lable": "默认标记",
                "comment": "默认标记",
                "placeholder": "默认标记",
                "insertValue": null,
                "model": "DEFAULT",
                "visible": true,
                "require": true,
                "dictionary": "agriculture/basic/dict/ADDR_DEFAULT.json",
                "dictionaryPF": null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': this.findOneColumnValue(data, 'CUST_ID')
          };

          const bodyData = {
            'pca': this.findOneColumnValue(data, 'PCA'),
            'province': this.findOneColumnValue(data, 'PROVINCE'),
            'city': this.findOneColumnValue(data, 'CITY'),
            'area': this.findOneColumnValue(data, 'AREA'),
            'gotName': this.findOneColumnValue(data, 'GOT_NAME'),
            'gotMobile': this.findOneColumnValue(data, 'GOT_MOBILE'),
            'addrDetail': this.findOneColumnValue(data, 'ADDR_DETAIL'),
            'addrDefault': this.findOneColumnValue(data, 'ADDR_DEFAULT'),
          };
          this.requestService.doActionByHdbsthor('AppApi/EsAddressController', 'addAddress', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //   我的地址列表-修改地址
  doAddrEdit(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let columnsDatas = {
        'columns': [
          {
            'column': 'ID',
            'name': 'ID_NAME',
            'component': 'input',
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            'lable': '主键',
            'comment': '主键',
            'placeholder': '请输入主键',
            'model': null,
            'visible': true,
            'disable': true,
            'require': true
          },
          {
            'column': 'PCA',
            'name': 'PCA_NAME',
            'component': 'cascader',
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            'lable': '省市区',
            'comment': '省市区',
            'placeholder': '请选择省市区',
            'model': null, // 这个里面会保存为逗号为间隔的字符串 湖北省，武汉市，洪山区
            'visible': true,
            'require': true,
            'dictionaryPF': null,
            'columnGroup': [
              {
                'column': 'PROVINCE',
                'name': 'PROVINCE_NAME',
                'component': 'input',
                'lable': '省',
                'comment': '省',
                'placeholder': '请输入省',
                'fromValue': 'name',
                'model': null,
                'dictionary': 'genecreate/basic/dict/ES_PROVINCE.json',
                'dictionaryPF': null,
                'visible': false,
                'require': false,
                'disable': [
                  'add',
                  'modify'
                ]
              },
              {
                'column': 'CITY',
                'name': 'CITY_NAME',
                'component': 'input',
                'lable': '市',
                'comment': '市',
                'placeholder': '请输入市',
                'fromValue': 'name',
                'model': null,
                'dictionary': 'genecreate/basic/dict/ES_CITY.json',
                'dictionaryPF': null,
                'dictionaryConditionColumn': 'PROVINCE', //这个代表使用 ES_CITY.json 之中的 作为查询条件 PROVINCE ，where PROVINCE = '第一个选中的值'
                'visible': false,
                'require': false,
                'disable': [
                  'add',
                  'modify'
                ]
              },
              {
                'column': 'AREA',
                'name': 'AREA_NAME',
                'component': 'input',
                'lable': '县区',
                'comment': '县区',
                'placeholder': '请输入县区',
                'model': null,
                'visible': false,
                'require': false,
                'dictionary': 'genecreate/basic/dict/ES_AREA_END.json',
                'dictionaryPF': null,
                'dictionaryConditionColumn': 'CITY',
                // 这个代表使用 ES_DISTRICT.json 之中的 作为查询条件 CITY ，where CITY = '第二个选中的值'
                'disable': [
                  'add',
                  'modify'
                ]
              }
            ]
          },
          {
            'column': 'GOT_NAME',
            'name': 'GOT_NAME_NAME',
            'component': 'input',
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            'lable': '收货人',
            'comment': '收货人',
            'placeholder': '请输入收货人',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'GOT_MOBILE',
            'name': 'GOT_MOBILE_NAME',
            'component': 'input',
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            'lable': '收货人电话',
            'comment': '收货人电话',
            'placeholder': '请输入收货人电话',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            'column': 'ADDR_DETAIL',
            'name': 'ADDR_DETAIL_NAME',
            'component': 'inputMulti',
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            'lable': '详细地址',
            'comment': '详细地址',
            'placeholder': '请输入详细地址',
            'model': null,
            'visible': true,
            'require': true
          },
          {
            "column": "ADDR_DEFAULT",
            "name": "ADDR_DEFAULT_NAME",
            "component": "select",
            'lableSpan': 8,
            'itemSpan': 16,
            'customize': 24,
            "lable": "默认标记",
            "comment": "默认标记",
            "placeholder": "默认标记",
            "insertValue": null,
            "model": "DEFAULT",
            "visible": true,
            "require": true,
            "dictionary": "agriculture/basic/dict/ADDR_DEFAULT.json",
            "dictionaryPF": null
          }
        ]
      }
      // 反写弹出框数据。
      this.setOneColumnValue(columnsDatas, 'ID', _.get(selectList, [0, 'ID']));
      this.setOneColumnValue(columnsDatas, 'PCA', _.get(selectList, [0, 'PCA']));
      this.setOneColumnValue(columnsDatas, 'GOT_NAME', _.get(selectList, [0, 'GOT_NAME']));
      this.setOneColumnValue(columnsDatas, 'GOT_MOBILE', _.get(selectList, [0, 'GOT_MOBILE']));
      this.setOneColumnValue(columnsDatas, 'ADDR_DETAIL', _.get(selectList, [0, 'ADDR_DETAIL']));
      this.setOneColumnValue(columnsDatas, 'ADDR_DEFAULT', _.get(selectList, [0, 'ADDR_DEFAULT']));


      this.modalService.create({
        nzTitle: '修改地址',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: columnsDatas
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID'])
          };
          const bodyData = {
            'id': this.findOneColumnValue(data, 'ID'),
            'pca': this.findOneColumnValue(data, 'PCA'),
            'province': this.findOneColumnValue(data, 'PROVINCE'),
            'city': this.findOneColumnValue(data, 'CITY'),
            'area': this.findOneColumnValue(data, 'AREA'),
            'gotName': this.findOneColumnValue(data, 'GOT_NAME'),
            'gotMobile': this.findOneColumnValue(data, 'GOT_MOBILE'),
            'addrDetail': this.findOneColumnValue(data, 'ADDR_DETAIL'),
            'addrDefault': this.findOneColumnValue(data, 'ADDR_DEFAULT'),

          };
          this.requestService.doActionByHdbsthor('AppApi/EsAddressController', 'modifyAddress', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  // 客户信息-分配客户经理
  doAccountManager(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '分配客户经理',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ACCOUNT_MANAGER_NAME',
                'name': 'ACCOUNT_MANAGER_NAME_NAME',
                'component': 'poplist',
                'customize': 24,
                'lable': '客户经理',
                'comment': '客户经理',
                'placeholder': '请选择客户经理',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/SYSTEM_USER_POPLIST.json',
                'dictionaryPF': null,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'ID']),
            'userId': this.user.USER_ID,
            'orPrincipalUserid': this.findOneColumnValue(data, 'ACCOUNT_MANAGER_NAME')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'accountManager', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //我的发票管理-删除发票
  doRemoveInvoice(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认删除发票么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'esInvoiceId': _.get(selectList, [0, 'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/EsInvoiceController', 'deleteInvoice', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //我的发票管理-删除地址
  doRemoveAddress(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认删除地址么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'esAddressId': _.get(selectList, [0, 'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/EsAddressController', 'deleteAddress', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 客户信息-验证密码
  doVerificationPassword(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '验证密码',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'PASSWORD01',
                'name': 'PASSWORD01_NAME',
                'component': 'input',
                'customize': 24,
                'lable': '密码',
                'comment': '密码',
                'placeholder': '请输入密码',
                'model': null,
                'visible': true,
                'require': true
              },
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let password01 = this.findOneColumnValue(data, 'PASSWORD01');
          password01 = Md5.hashStr(password01);
          password01 = Md5.hashStr(password01);

          let queryData = {
            'phoneNumber': _.get(selectList, [0, 'PHONE_NUMBER']),
            'password': password01
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkLoginPassword', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  //我的草稿箱-删除草稿
  doDeleteDraft(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认删除草稿么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'orderId': _.get(selectList, [0, 'ID'])
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'deleteDraft', queryData, null, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }


  formatMainToEntity(oneRow) {
    let entity = {};
    const columns = _.keys(oneRow);
    _.each(columns, oneColumn => {
      const data = oneRow[oneColumn];
      if (!_.isArray(data)) {
        const camelCaseColumn = _.camelCase(oneColumn);
        entity[camelCaseColumn] = data;
      }
    })
    return entity;
  }

  //我的草稿箱-提交正式订单
  doSubmitFormalOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认提交为正式订单么？',
        suc: () => {
          const queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID'])
          };
          const bodyData = this.formatMainToEntity(_.get(selectList, [0]));
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'submitDraftToOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 草稿箱-提交草稿订单
  doSubmitDraftOrder(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认提交草稿订单么？',
        suc: () => {
          let queryData = {
            'esCustomerId': _.get(selectList, [0, 'CUST_ID']),
            'esAddressId': _.get(selectList, [0, 'ID'])
          };
        }
      });
    }
  }

  // 客户信息-通过审核
  doApproved(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '用户审批',
        nzContent: FormModalComponent,
        nzWidth: 400,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'CUSTOMER_CHECK_TYPE',
                'name': 'CUSTOMER_CHECK_TYPE_NAME',
                'component': 'select',
                'customize': 24,
                'lable': '审核方式',
                'comment': '审核方式',
                'placeholder': '请选择审核方式',
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'genecreate/basic/dict/CUSTOMER_CHECK_TYPE.json',
                'dictionaryPF': null
              },
              {
                'column': 'CUSTOMER_CHECK_TEXT',
                'name': 'CUSTOMER_CHECK_TEXT_NAME',
                'component': 'textarea',
                'customize': 24,
                'lable': '审核情况',
                'comment': '审核情况',
                'placeholder': '请输入审核情况',
                'model': '无',
                'visible': false,
                'require': false
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          const bodyData = {
            'ids': this.formatSelectEntity(selectList, 'ID')
          };
          const queryData = {
            'userId': this.user.USER_ID,
            'customerCheckType': this.findOneColumnValue(data, 'CUSTOMER_CHECK_TYPE'),
            'customerCheckText': this.findOneColumnValue(data, 'CUSTOMER_CHECK_TEXT')
          }
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkCustomer', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }

      });
    }
  }

  // 客户信息-不通过审核
  doApprovalFailed(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, true) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认拒绝么？',
        suc: () => {
          const queryData = {
            'userId': this.user.USER_ID
          }
          const bodyData = {
            'ids': this.formatSelectEntity(selectList, 'ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsCustomerController', 'checkRefuseCustomer', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }


  // 接单生产订单
  doReceivingProduction(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认接单么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          const bodyData = {
            'ids': this.formatSelectEntity(selectList, 'ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsProductionController', 'receivingProduction', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  // 生产订单完成
  doCompleteProduction(pBtn, pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.confirmModal.show('confirm', {
        title: '确认订单完成么？',
        suc: () => {
          let queryData = {
            'userId': this.user.USER_ID
          };
          const bodyData = {
            'ids': this.formatSelectEntity(selectList, 'ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/EsProductionController', 'doProductionOrder', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

}
