import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UserInfoService} from '../../../service/user-info.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-input-outbound',
  templateUrl: './input-outbound.component.html',
  styleUrls: ['./input-outbound.component.css']
})
export class InputOutboundComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType = 'add';

  public today = moment().format('YYYY-MM-DD');

  public datas: any;
  public itemData: any = []; // wagon 明细表

  public formData: any = {
    'title': '出库单',
    'type': 'normal',
    'columns': [
      {
        'column': 'ID',
        'pk_column': true,
        'iscondition': true,
        'name': 'ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'insertValue': 'SYS-UUID',
        'model': null,
        'visible': false,
        'require': false,
        'disable': ['add', 'modify']
      },
      {
        'column': 'CUST_ID',
        'name': 'CUST_ID_NAME',
        'component': 'poplist',
        'lable': '客户信息',
        'comment': '客户信息',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'placeholder': '请选择客户信息',
        'model': null,
        'visible': true,
        'type': 'normal',
        'require': true,
        'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
        'dictionaryPF': null,
        'disable': ['add', 'modify'],
        'columnGroup': [
          {
            'column': 'CUST_NAME',
            'name': 'CUST_NAME_NAME',
            'component': 'input',
            'lable': '客户信息',
            'comment': '客户信息',
            'placeholder': '客户信息',
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      }
    ]
  };

  public allChecked = false;
  private indeterminate = false;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
  }

  private columnsJson: any = [
    {
      'column': 'AC_CUST_ID',
      'name': 'AC_CUST_ID_NAME',
      'component': 'poplist',
      'lable': '结算客户',
      'comment': '结算客户',
      'class': 'col-lg-4 col-sm-6 col-xs-12',
      'placeholder': '请选择结算客户',
      'model': null,
      'visible': true,
      'type': 'normal',
      'require': true,
      'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
      'dictionaryPF': null,
      'columnGroup': [
        {
          'column': 'AC_CUST_NAME',
          'name': 'AC_CUST_NAME_NAME',
          'component': 'input',
          'lable': '结算客户',
          'comment': '结算客户',
          'placeholder': '结算客户',
          'fromValue': 'name',
          'model': null,
          'visible': false,
          'require': false,
          'disable': [
            'add',
            'modify'
          ]
        }
      ]
    },
    {
      'column': 'STOCK_ID',
      'name': 'STOCK_ID_NAME',
      'component': 'poplist',
      'lable': '钢坯（卷）号',
      'comment': '钢坯（卷）号',
      'placeholder': '请选择钢坯（卷）号',
      'fromValue': 'code',
      'insertValue': null,
      'model': null,
      'dictionary': 'dfmc/basic/dict/MC_OUTBOUND_STOCK.json',
      'dictionaryPF': null,
      'visible': true,
      'require': true,
      'columnGroup': [
        {
          'column': 'DET_BATCH',
          'name': 'DET_BATCH_NAME',
          'component': 'input',
          'lable': '钢坯（卷）号',
          'comment': '钢坯（卷）号',
          'placeholder': '请输入钢坯（卷）号',
          'insertValue': null,
          'fromValue': 'name',
          'model': null,
          'visible': false,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'LOC_NAME',
          'name': 'LOC_NAME_NAME',
          'component': 'input',
          'lable': '库位名称',
          'comment': '库位名称',
          'placeholder': '请输入库位名称',
          'insertValue': null,
          'fromValue': 'LOC_NAME',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'DET_SUPPLIER',
          'name': 'DET_SUPPLIER_NAME',
          'component': 'input',
          'lable': '厂家',
          'comment': '厂家',
          'placeholder': '请输入厂家',
          'insertValue': null,
          'fromValue': 'DET_SUPPLIER_DICT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'DET_TEXT',
          'name': 'DET_TEXT_NAME',
          'component': 'input',
          'lable': '辅记码',
          'comment': '辅记码',
          'placeholder': '请输入辅记码',
          'insertValue': null,
          'fromValue': 'DET_TEXT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'GOODS_MODEL',
          'name': 'GOODS_MODEL_NAME',
          'component': 'input',
          'lable': '货品型号',
          'comment': '货品型号',
          'placeholder': '请输入货品型号',
          'insertValue': null,
          'fromValue': 'MODEL_DICT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'GOODS_MATERIAL',
          'name': 'GOODS_MATERIAL_NAME',
          'component': 'input',
          'lable': '材质',
          'comment': '材质',
          'placeholder': '请输入材质',
          'insertValue': null,
          'fromValue': 'MATERIAL_DICT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        },
        {
          'column': 'GROSS_WEIGHT',
          'name': 'GROSS_WEIGHT_NAME',
          'component': 'input',
          'lable': '毛重（实重）',
          'comment': '毛重（实重）',
          'placeholder': '请输入毛重（实重）',
          'insertValue': null,
          'fromValue': 'GROSS_WEIGHT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        }
        ,
        {
          'column': 'NET_WEIGHT',
          'name': 'NET_WEIGHT_NAME',
          'component': 'input',
          'lable': '净重',
          'comment': '净重',
          'placeholder': '请输入净重',
          'insertValue': null,
          'fromValue': 'NET_WEIGHT',
          'model': null,
          'visible': true,
          'require': false,
          'disable': ['add', 'modify'],
        }
      ]
    }
  ];

  clearItemModel(pColumnJson) {
    _.each(pColumnJson, (oneColumn) => {
      _.set(oneColumn, 'model', null);
      let columnGroup = _.get(oneColumn, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        _.set(oneGroupColumn, 'model', null);
      });
    });
  }

  checkDuplicateDetBatch(detBatch) {
    let result = true;
    let duplicateCount = 0;
    _.each(this.itemData, oneRow => {
      const oneDetBatch = oneRow['DET_BATCH'];
      if (this.util.Base_HasValue(detBatch) && this.util.Base_HasValue(oneDetBatch)) {
        if (detBatch === oneDetBatch) {
          duplicateCount += 1;
        }
      }
    });
    if (duplicateCount >= 1) {
      this.confirmModal.show('error', {title: '该钢批号' + detBatch + '已经选用了'});
      result = false;
      return false;
    }
    return result;
  }

  addItem() {
    const custId = _.get(this.formData, 'columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请先选择客户信息'});
      return false;
    }
    _.set(this.columnsJson, '1.inputCondition', {'CUST_ID': custId});
    this.clearItemModel(this.columnsJson); // 清除 this.columnsJson 之上的 model
    this.modalService.create({
      nzTitle: '',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzMaskClosable: false,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': this.columnsJson
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        const detBatch = _.get(data, '0.modelName', null);
        if (!this.checkDuplicateDetBatch(detBatch)) {
          return false;
        }

        let oneTmpRow = [];
        _.each(data, oneColumn => {
          oneTmpRow[oneColumn['column']] = oneColumn['model'];
          let columnGroup = _.get(oneColumn, 'columnGroup', null);
          _.each(columnGroup, (oneGroupColumn) => {
            oneTmpRow[oneGroupColumn['column']] = _.get(oneGroupColumn, 'model', null);
          });
        });
        this.itemData.push(oneTmpRow);
      }

    });
  }

  // 明细table删除
  itemRemove(): void {
    const checkedList = _.filter(this.itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.itemData = this.itemData.filter((item) => {
            return !item.checked;
          });
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  checkAll(value: boolean): void {
    this.itemData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.itemData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.formData, 'columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }

  formatItemToEntity() {
    let entityList: any = [];
    _.each(this.itemData, (oneRow) => {
      let entity: any = {};
      _.each(_.keys(oneRow), (key) => {
        const column = _.camelCase(key);
        const value = _.get(oneRow, key);
        entity[column] = value;
      });
      entityList.push(entity);
    });
    return entityList;
  }

  findOneColumnValue(pColumnName) {
    const columns = _.get(this.formData, 'columns', []);
    const oneOneColum = _.find(columns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }

  checkInput(pCheckItem) {
    let result = true;

    const custId = _.get(this.formData, 'columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请选择客户信息'});
      result = false;
      return result;
    }

    if (pCheckItem) {
      // tslint:disable-next-line:triple-equals
      if (this.itemData.length == 0) {
        this.confirmModal.show('error', {'title': '必填项', 'content': '货物明细数据为必填项'});
        result = false;
        return result;
      }
    }
    return result;
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  saveData() {
    if (!this.checkInput(true)) {
      return false;
    }
    const data = {
      'userId': this.userInfoService.getUserInfo().USER_ID,
      'mcOutbound': this.formatMainToEntity(),
      'mcOutboundDetailList': this.formatItemToEntity()
    };
    this.requestService.doActionByHdbsthorDfmc('AppApi/McOutboundController', 'input', data).subscribe(response => {
      if (response.toString() === 'true') {
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
      }
    });
  }

  batchAddAcCustId() {
    const checkedList = _.filter(this.itemData, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      let batchAddColumn = [
        {
          'column': 'AC_CUST_ID',
          'name': 'AC_CUST_ID_NAME',
          'lableSpan': 4,
          'itemSpan': 20,
          'customize': 24,
          'component': 'poplist',
          'lable': '结算客户',
          'comment': '结算客户',
          'class': 'col-lg-4 col-sm-6 col-xs-12',
          'placeholder': '请选择结算客户',
          'model': null,
          'visible': true,
          'type': 'normal',
          'require': true,
          'dictionary': 'dfmc/basic/dict/BASIC_COMPANY_CUSTOMER.json',
          'dictionaryPF': null,
          'disable': ['add', 'modify'],
          'columnGroup': [
            {
              'column': 'AC_CUST_NAME',
              'name': 'AC_CUST_NAME_NAME',
              'component': 'input',
              'lable': '结算客户',
              'comment': '结算客户',
              'placeholder': '结算客户',
              'fromValue': 'name',
              'model': null,
              'visible': false,
              'require': false,
              'disable': [
                'add',
                'modify'
              ]
            }
          ]
        }
      ]
      this.clearItemModel(batchAddColumn); // 清除 columnsJson 之上的 model
      this.modalService.create({
        nzTitle: '批量选择结算客户',
        nzContent: FormModalComponent,
        nzWidth: 600,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': batchAddColumn
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          _.each(checkedList,oneRow=>{
            _.each(data, oneColumn => {
              oneRow[oneColumn['column']] = oneColumn['model'];
              let columnGroup = _.get(oneColumn, 'columnGroup', null);
              _.each(columnGroup, (oneGroupColumn) => {
                oneRow[oneGroupColumn['column']] = _.get(oneGroupColumn, 'model', null);
              });
            });
          });
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }



  }

  batchAddDetBatch() {
    const custId = _.get(this.formData, 'columns.1.model', null);
    if (!this.util.Base_HasValue(custId)) {
      this.confirmModal.show('error', {title: '请先选择客户信息'});
      return false;
    }
    let batchAddColumn = [
      {
        'column': 'STOCK_ID',
        'name': 'STOCK_ID_NAME',
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 24,
        'component': 'multipop',
        'lable': '钢坯（卷）号',
        'comment': '钢坯（卷）号',
        'placeholder': '请选择钢坯（卷）号',
        'fromValue': 'code',
        'insertValue': null,
        'model': null,
        'dictionary': 'dfmc/basic/dict/MC_OUTBOUND_STOCK.json',
        'dictionaryPF': null,
        'visible': true,
        'require': true,
        'columnGroup': [
          {
            'column': 'DET_BATCH',
            'name': 'DET_BATCH_NAME',
            'component': 'input',
            'lable': '钢坯（卷）号',
            'comment': '钢坯（卷）号',
            'placeholder': '请输入钢坯（卷）号',
            'insertValue': null,
            'fromValue': 'name',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'LOC_NAME',
            'name': 'LOC_NAME_NAME',
            'component': 'input',
            'lable': '库位名称',
            'comment': '库位名称',
            'placeholder': '请输入库位名称',
            'insertValue': null,
            'fromValue': 'LOC_NAME',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'DET_SUPPLIER',
            'name': 'DET_SUPPLIER_NAME',
            'component': 'input',
            'lable': '厂家',
            'comment': '厂家',
            'placeholder': '请输入厂家',
            'insertValue': null,
            'fromValue': 'DET_SUPPLIER_DICT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'DET_TEXT',
            'name': 'DET_TEXT_NAME',
            'component': 'input',
            'lable': '辅记码',
            'comment': '辅记码',
            'placeholder': '请输入辅记码',
            'insertValue': null,
            'fromValue': 'DET_TEXT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'GOODS_MODEL',
            'name': 'GOODS_MODEL_NAME',
            'component': 'input',
            'lable': '货品型号',
            'comment': '货品型号',
            'placeholder': '请输入货品型号',
            'insertValue': null,
            'fromValue': 'MODEL_DICT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'GOODS_MATERIAL',
            'name': 'GOODS_MATERIAL_NAME',
            'component': 'input',
            'lable': '材质',
            'comment': '材质',
            'placeholder': '请输入材质',
            'insertValue': null,
            'fromValue': 'MATERIAL_DICT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          },
          {
            'column': 'GROSS_WEIGHT',
            'name': 'GROSS_WEIGHT_NAME',
            'component': 'input',
            'lable': '毛重（实重）',
            'comment': '毛重（实重）',
            'placeholder': '请输入毛重（实重）',
            'insertValue': null,
            'fromValue': 'GROSS_WEIGHT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          }
          ,
          {
            'column': 'NET_WEIGHT',
            'name': 'NET_WEIGHT_NAME',
            'component': 'input',
            'lable': '净重',
            'comment': '净重',
            'placeholder': '请输入净重',
            'insertValue': null,
            'fromValue': 'NET_WEIGHT',
            'model': null,
            'visible': false,
            'require': false,
            'disable': ['add', 'modify'],
          }
        ]
      }
    ]

    _.set(batchAddColumn, '0.inputCondition', {'CUST_ID': custId});
    this.clearItemModel(batchAddColumn); // 清除 columnsJson 之上的 model
    this.modalService.create({
      nzTitle: '批量选择钢坯（卷）号',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': batchAddColumn
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        let requireCheck = true;
        _.each(data, oneColumn => {
          if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
            requireCheck = false;
          }
        });
        if (!requireCheck) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let rowCount = _.split(_.get(data,[0,'model']), ',').length;
        let oneTmpRow = [];
        _.each(data, oneColumn => {
          oneTmpRow[oneColumn['column']] = oneColumn['model'];
          let columnGroup = _.get(oneColumn, 'columnGroup', null);
          _.each(columnGroup, (oneGroupColumn) => {
            oneTmpRow[oneGroupColumn['column']] = _.get(oneGroupColumn, 'model', null);
          });
        });
        let columns = _.keys(oneTmpRow);
        _.each(columns, oneColumnName => {
          oneTmpRow[oneColumnName] = _.split(oneTmpRow[oneColumnName], ';');
        })
        for (var i = 0; i < rowCount; i++) {
          let item = {}
          _.each(columns, oneColumnName => {
            item[oneColumnName] = oneTmpRow[oneColumnName][i];
          })
          item['STOCK_ID'] = _.split(_.get(data,[0,'model']), ',')[i];
          this.itemData.push(item);
        }
      }
    });

  }

  ngOnInit(): void {

  }

}
