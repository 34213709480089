import {Injectable} from '@angular/core';
import {AutoInsertService} from '../../service/auto-insert.service';
import {UserInfoService} from '../../service/user-info.service';
import {RequestDataService} from '../../service/request-data.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {AjaxService} from '../../service/ajax.service';
import * as _sh from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  user: any;

  constructor(private autoInsert: AutoInsertService,
              private userInfo: UserInfoService,
              private requestData: RequestDataService,
              private confirmModal: ConfirmModalService,
              private config: AppConfigService,
              private util: UtilsService,
              private ajaxService: AjaxService) {
    this.user = this.userInfo.getUserInfo();
  }

  // 生成拜访计划
  insertCustVisit(pCust, pModel, pFilters) {
    let templateJson: any = {};
    let ok = '';
    if (this.util.Base_HasValue(pFilters)) {
      ok = _sh.find(pFilters, result => {
        return result['CUST_ID'] === pCust['ID'] && result['VISIT_DATE_PLAN'] === pModel[1]['model'];
      });
    }
    // 判断拜访计划是否存在，存在只加明细，不存在直接新增，
    if (this.util.Base_HasValue(ok)) {
      templateJson = {
        'LIST': [
          {
            'TABLE': 'fury_visit_object',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'VISIT_ID'},
              {'COLUMN': 'VISIT_STATE'},
              {'COLUMN': 'CUST_ID'},
              {'COLUMN': 'PRODUCT'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'ADD_NAME'},
              {'COLUMN': 'ADD_USERID'}
            ],
            'RECORDS': []
          }
        ]
      };
      _sh.each(pModel[0].model, item => {
        let visitDetail = {
          'ID': 'SYS_UUID',
          'VISIT_ID': pFilters[0]['ID'],
          'VISIT_STATE': 'PLAN',
          'CUST_ID': pCust['ID'],
          'PRODUCT': item,
          'U_DELETE': 1,
          'ADD_TIME': 'SYS-DATETIME',
          'ADD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
          'ADD_USERID': this.user.USER_ID
        };
        templateJson.LIST[0].RECORDS.push(visitDetail);
      });
    } else {
      templateJson = {
        'LIST': [
          {
            'TABLE': 'fury_visit',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'CUST_ID'},
              {'COLUMN': 'CUST_CODE'},
              {'COLUMN': 'CUST_NAME'},
              {'COLUMN': 'PLAN_PERSON_CHARGE'},
              {'COLUMN': 'PLAN_PERSON_CHARGEID'},
              {'COLUMN': 'VISIT_YEAR'},
              {'COLUMN': 'VISIT_MONTH'},
              {'COLUMN': 'VISIT_DATE_PLAN'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'ADD_NAME'},
              {'COLUMN': 'ADD_USERID'}
            ],
            'RECORDS': [
              {
                'ID': 'SYS-ACTION-UUID',
                'CUST_ID': pCust['ID'],
                'CUST_CODE': pCust['CUST_CODE'],
                'CUST_NAME': pCust['CUST_NAME'],
                'PLAN_PERSON_CHARGE': pModel[2]['modelName'],
                'PLAN_PERSON_CHARGEID': pModel[2]['model'],
                'VISIT_YEAR': moment(moment().isoWeek(pModel[1]['model'])).format('YYYY') || '',
                'VISIT_MONTH': moment(moment().isoWeek(pModel[1]['model'])).format('MM') || '',
                'VISIT_DATE_PLAN': pModel[1]['model'],
                'U_DELETE': 1,
                'ADD_TIME': 'SYS-DATETIME',
                'ADD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
                'ADD_USERID': this.user.USER_ID
              }
            ]
          },
          {
            'TABLE': 'fury_visit_object',
            'ACTION': 'Insert',
            'WHEREGROUP': [{}],
            'WHERE': [{}],
            'COLUMNS': [
              {'COLUMN': 'ID'},
              {'COLUMN': 'VISIT_ID'},
              {'COLUMN': 'VISIT_STATE'},
              {'COLUMN': 'CUST_ID'},
              {'COLUMN': 'PRODUCT'},
              {'COLUMN': 'U_DELETE'},
              {'COLUMN': 'ADD_TIME'},
              {'COLUMN': 'ADD_NAME'},
              {'COLUMN': 'ADD_USERID'}
            ],
            'RECORDS': []
          }
        ]
      };
      _sh.each(pModel[0].model, item => {
        let visitDetail = {
          'ID': 'SYS_UUID',
          'VISIT_ID': 'SYS-ACTION-UUID',
          'VISIT_STATE': 'PLAN',
          'CUST_ID': pCust['ID'],
          'PRODUCT': item,
          'U_DELETE': 1,
          'ADD_TIME': 'SYS-DATETIME',
          'ADD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
          'ADD_USERID': this.user.USER_ID
        };
        templateJson.LIST[1].RECORDS.push(visitDetail);
      });
    }
    return this.autoInsert.ExecJson(templateJson);
  }

  // 生成销售计划
  salePlan(pCust, pModel, pFilters) {
    const templateJson = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Insert',
          'WHEREGROUP': [{}],
          'WHERE': [{}],
          'COLUMNS': [
            {'COLUMN': 'ID'},
            {'COLUMN': 'CUST_ID'},
            {'COLUMN': 'CUST_CODE'},
            {'COLUMN': 'CUST_NAME'},
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'PRODUCT'},
            {'COLUMN': 'PLAN_PERSON_CHARGE'},
            {'COLUMN': 'PLAN_PERSON_CHARGEID'},
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'ADD_TIME'},
            {'COLUMN': 'ADD_NAME'},
            {'COLUMN': 'ADD_USERID'}
          ],
          'RECORDS': []
        }
      ]
    };
    let state = 'ASSIGNED';
    if (!this.util.Base_HasValue(pModel[1]['model'])) {
      state = 'PENDING';
    }
    _sh.each(pModel[0].model, item => {
      let ok = '';
      if (this.util.Base_HasValue(pFilters)) {
        ok = _sh.find(pFilters, result => {
          return result['CUST_ID'] === pCust['ID'] && result['PRODUCT'] === item;
        });
      }
      if (this.util.Base_HasValue(ok)) {
        return true;
      }
      let data = {
        'ID': 'SYS_UUID',
        'CUST_ID': pCust['ID'],
        'CUST_CODE': pCust['CUST_CODE'],
        'CUST_NAME': pCust['CUST_NAME'],
        'PLAN_STATE': state,
        'PRODUCT': item,
        'PLAN_PERSON_CHARGE': pModel[1]['modelName'],
        'PLAN_PERSON_CHARGEID': pModel[1]['model'],
        'U_DELETE': 1,
        'ADD_TIME': 'SYS-DATETIME',
        'ADD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
        'ADD_USERID': this.user.USER_ID
      };
      templateJson.LIST[0].RECORDS.push(data);
    });
    return this.autoInsert.ExecJson(templateJson);
  }

  agreeTrial(pObject, pData) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject[0]['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'TRIAL_AGREE_NAME'},
            {'COLUMN': 'TRIAL_POST'},
            {'COLUMN': 'TRIAL_PHONE'},
            {'COLUMN': 'TRIAL_DATE'},
            {'COLUMN': 'TRIAL_DESC'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PLAN_STATE': 'AGREE_TRIAL',
              'TRIAL_AGREE_NAME': pData[0]['model'],
              'TRIAL_POST': pData[1]['model'],
              'TRIAL_PHONE': pData[2]['model'],
              'TRIAL_DATE': pData[3]['model'],
              'TRIAL_DESC': pData[4]['model'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }


  deliver(pObject, pData) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject[0]['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'DELIVER_DATE'},
            {'COLUMN': 'DELIVER_CONFIRM_ID'},
            {'COLUMN': 'DELIVER_CONFIRM_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PLAN_STATE': 'DELIVER',
              'DELIVER_DATE': pData[0]['model'],
              'DELIVER_CONFIRM_ID': pData[1]['model'],
              'DELIVER_CONFIRM_NAME': pData[1]['modelName'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  speadNet(pObject, pData) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject[0]['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'SPREAD_NET_DATE'},
            {'COLUMN': 'SPREADNET_URL'},
            {'COLUMN': 'SPREADNET_CONFIRM_ID'},
            {'COLUMN': 'SPREADNET_CONFIRM_NAME'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PLAN_STATE': 'HANGED_NET',
              'SPREAD_NET_DATE': pData[0]['model'],
              'SPREADNET_URL': pData[1]['model'],
              'SPREADNET_CONFIRM_ID': pData[2]['model'],
              'SPREADNET_CONFIRM_NAME': pData[2]['modelName'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  planCancel(pObject, pData) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject[0]['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'PLAN_CANCEL_DESC'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PLAN_STATE': 'CANCEL',
              'PLAN_CANCEL_DESC': pData[0]['model'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  distributionSales(pObject, pData) {
    let idList = [];
    _sh.each(pObject, oneData => {
      idList.push(oneData['ID']);
    });
    let ids = idList.join('\',\'');
    ids = '(\'' + ids + '\')';
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': ids,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'PLAN_PERSON_CHARGEID'},
            {'COLUMN': 'PLAN_PERSON_CHARGE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PLAN_STATE': 'ASSIGNED',
              'PLAN_PERSON_CHARGEID': pData[0]['model'],
              'PLAN_PERSON_CHARGE': pData[0]['modelName'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 修改客户信息
  editCust(pObject) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'CUST_NAME'},
            {'COLUMN': 'CUST_CODE'},
            {'COLUMN': 'CUST_TYPE'},
            {'COLUMN': 'CUST_ATTRIBUTE'},
            {'COLUMN': 'PROVINCE'},
            {'COLUMN': 'CITY'},
            {'COLUMN': 'ADDRESS'},
            {'COLUMN': 'CUST_LEVEL'},
            {'COLUMN': 'CUST_PEOPLE_NUM'},
            {'COLUMN': 'CUST_RESEARCH_NUM'},
            {'COLUMN': 'CUST_COMPETITION_USAGE'},
            {'COLUMN': 'CUST_IP'},
            {'COLUMN': 'CUST_DESC'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'CUST_NAME': pObject['CUST_NAME'],
              'CUST_CODE': pObject['CUST_CODE'],
              'CUST_TYPE': pObject['CUST_TYPE'],
              'CUST_ATTRIBUTE': pObject['CUST_ATTRIBUTE'],
              'PROVINCE': pObject['PROVINCE'],
              'CITY': pObject['CITY'],
              'ADDRESS': pObject['ADDRESS'],
              'CUST_LEVEL': pObject['CUST_LEVEL'],
              'CUST_PEOPLE_NUM': pObject['CUST_PEOPLE_NUM'],
              'CUST_RESEARCH_NUM': pObject['CUST_RESEARCH_NUM'],
              'CUST_COMPETITION_USAGE': pObject['CUST_COMPETITION_USAGE'],
              'CUST_IP': pObject['CUST_IP'],
              'CUST_DESC': pObject['CUST_DESC'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 修改客户产品信息
  editCustProduct(pObject) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_cust_product',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'PRODUCT'},
            {'COLUMN': 'PLAN_STATE'},
            {'COLUMN': 'PLAN_PERSON_CHARGEID'},
            {'COLUMN': 'PLAN_PERSON_CHARGE'},
            {'COLUMN': 'TRIAL_AGREE_NAME'},
            {'COLUMN': 'TRIAL_PHONE'},
            {'COLUMN': 'TRIAL_POST'},
            {'COLUMN': 'TRIAL_DATE'},
            {'COLUMN': 'TRIAL_DESC'},
            {'COLUMN': 'DELIVER_DATE'},
            {'COLUMN': 'DELIVER_CONFIRM_NAME'},
            {'COLUMN': 'DELIVER_CONFIRM_ID'},
            {'COLUMN': 'SPREAD_NET_DATE'},
            {'COLUMN': 'SPREADNET_CONFIRM_NAME'},
            {'COLUMN': 'SPREADNET_CONFIRM_ID'},
            {'COLUMN': 'SPREADNET_URL'},
            {'COLUMN': 'SPREADNET_PROVER'},
            {'COLUMN': 'PLAN_CANCEL_DESC'},
            {'COLUMN': 'TRIAL_FIRST_VISIT'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'PRODUCT': pObject['PRODUCT'],
              'PLAN_STATE': pObject['PLAN_STATE'],
              'PLAN_PERSON_CHARGEID': pObject['PLAN_PERSON_CHARGEID'],
              'PLAN_PERSON_CHARGE': pObject['PLAN_PERSON_CHARGE'],
              'TRIAL_AGREE_NAME': pObject['TRIAL_AGREE_NAME'],
              'TRIAL_PHONE': pObject['TRIAL_PHONE'],
              'TRIAL_POST': pObject['TRIAL_POST'],
              'TRIAL_DATE': pObject['TRIAL_DATE'],
              'TRIAL_DESC': pObject['TRIAL_DESC'],
              'DELIVER_DATE': pObject['DELIVER_DATE'],
              'DELIVER_CONFIRM_NAME': pObject['DELIVER_CONFIRM_NAME'],
              'DELIVER_CONFIRM_ID': pObject['DELIVER_CONFIRM_ID'],
              'SPREAD_NET_DATE': pObject['SPREAD_NET_DATE'],
              'SPREADNET_CONFIRM_NAME': pObject['SPREADNET_CONFIRM_NAME'],
              'SPREADNET_CONFIRM_ID': pObject['SPREADNET_CONFIRM_ID'],
              'SPREADNET_URL': pObject['SPREADNET_URL'],
              'SPREADNET_PROVER': pObject['SPREADNET_PROVER'],
              'PLAN_CANCEL_DESC': pObject['PLAN_CANCEL_DESC'],
              'TRIAL_FIRST_VISIT': pObject['TRIAL_FIRST_VISIT'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  // 修改拜访明细
  editVisitObject(pObject,pProduct,pVisitCount?) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_visit_object',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pObject['ID'],
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'VISIT_STATE'},
            {'COLUMN': 'VISIT_RESULT'},
            {'COLUMN': 'VISIT_NAME'},
            {'COLUMN': 'VISIT_DEPT'},
            {'COLUMN': 'VISIT_POST'},
            {'COLUMN': 'VISIT_PHONE'},
            {'COLUMN': 'VISIT_OFFICE'},
            {'COLUMN': 'VISIT_DESC'},
            {'COLUMN': 'VISIT_DATE'},
            {'COLUMN': 'VISIT_MODE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'VISIT_STATE': pObject['VISIT_STATE'],
              'VISIT_RESULT': pObject['VISIT_RESULT'],
              'VISIT_NAME': pObject['VISIT_NAME'],
              'VISIT_DEPT': pObject['VISIT_DEPT'],
              'VISIT_POST': pObject['VISIT_POST'],
              'VISIT_PHONE': pObject['VISIT_PHONE'],
              'VISIT_OFFICE': pObject['VISIT_OFFICE'],
              "VISIT_DESC": pObject['VISIT_DESC'],
              "VISIT_DATE": pObject['VISIT_DATE'],
              "VISIT_MODE": pObject['VISIT_MODE'],
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    // 如果拜访结果为同意试用修改产品关系表
    if (this.util.Base_HasValue(pObject['VISIT_RESULT'] === 'AGREE_TRIAL')) {
      let custProductSql: any = {
        'TABLE': 'fury_cust_product',
        'ACTION': 'Update',
        'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
        'WHERE': [{
          'COLUMN': 'ID',
          'VALUE': pProduct['ID'],
          'OPERATOR': '=',
          'VALUE_TYPE': 'string',
          'CONDITION_OPERATOR': 'AND',
          'GROUP': 'GROUP_01'
        }],
        'COLUMNS': [
          {'COLUMN': 'TRIAL_FIRST_VISIT'},
          {'COLUMN': 'UPD_TIME'},
          {'COLUMN': 'UPD_NAME'},
          {'COLUMN': 'UPD_USERID'}
        ],
        'RECORDS': [
          {
            'TRIAL_FIRST_VISIT': pVisitCount === 0 ? 1 : pProduct['TRIAL_FIRST_VISIT'],
            'UPD_TIME': 'SYS-DATETIME',
            'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
            'UPD_USERID': this.user.USER_ID
          }
        ]
      };
      templateJson.LIST.push(custProductSql);
    }
    return this.autoInsert.ExecJson(templateJson);
  }

  // 删除拜该明细
  deleteVisitObject(pId) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_visit_object',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pId,
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'U_DELETE': 0,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  deleteVisit(pId) {
    let templateJson: any = {
      'LIST': [
        {
          'TABLE': 'fury_visit',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'ID',
            'VALUE': pId,
            'OPERATOR': '=',
            'VALUE_TYPE': 'string',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'U_DELETE'},
            {'COLUMN': 'UPD_TIME'},
            {'COLUMN': 'UPD_NAME'},
            {'COLUMN': 'UPD_USERID'}
          ],
          'RECORDS': [
            {
              'U_DELETE': 0,
              'UPD_TIME': 'SYS-DATETIME',
              'UPD_NAME': this.user['USERNAME'] || this.user['EMP_NAME'],
              'UPD_USERID': this.user.USER_ID
            }
          ]
        }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  //
  furyProductVisitAdd(data) {
    data['userId'] = this.user.USER_ID;
    data['userName'] = this.user['USERNAME'] || this.user['EMP_NAME'];
    let params = Object.assign(data, {
      userId: this.user.USER_ID,
      userName: this.user['USERNAME'] || this.user['EMP_NAME']
    });
    const tmpUrl = this.config.config.quickSliver + '/fury/doVisitPlan?random=' + Math.random();
    return this.ajaxService.ajaxPost(tmpUrl, params);
  }
}
