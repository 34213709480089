import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as alasql from 'alasql';
import * as _ from 'underscore';

@Component({
  selector: 'app-graduate-employment',
  templateUrl: './graduate-employment.component.html',
  styleUrls: ['./graduate-employment.component.css']
})
export class GraduateEmploymentComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1: any = [];
    _.each(this.listData, oneData => {
      const data = {
        '年份': oneData['YEAR'] ? oneData['YEAR'] + '年' : '',
        '学位类别': oneData['EDUCATION'] || 0,
        '毕业生总数': oneData['毕业生总数'] || 0,
        '签订就业协议、劳动合同': oneData['签订就业协议、劳动合同'] || 0,
        '升学国内': oneData['国内'] || 0,
        '升学国外': oneData['国外'] || 0,
        '自主创业': oneData['自主创业'] || 0,
        '其它形式创业': oneData['其它形式就业'] || 0,
        '未就业': oneData['未就业'] || 0
      };
      data1.push(data);
    });
    if (this.listData.length === 0) {
      const data = {
        '年份': '',
        '学位类别': '',
        '毕业生总数': '',
        '签订就业协议、劳动合同': '',
        '升学国内': '',
        '升学国外': '',
        '自主创业': '',
        '其它形式创业': '',
        '未就业': ''
      };
      data1.push(data);
    }
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年毕业生就业基本情况',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-6-1 近5年毕业生就业基本情况.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.requestDataService.getDataByCondition('subjectInfo/report/report_graduate_employment.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}).subscribe( response => {
      this.listData = response['data'];
    });
  }

}
