import {Component, Input, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd";
import * as _ from "underscore";
import {DataCenterService} from "../../../service/data-center.service";
import * as _sh from 'lodash';
import {forkJoin as observableForkJoin, forkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
@Component({
  selector: 'app-edit-approval',
  templateUrl: './edit-approval.component.html',
  styleUrls: ['./edit-approval.component.scss']
})
export class EditApprovalComponent implements OnInit {

  @Input() parentData: any;
  dataList:any[];

  approvalDatas = {
    submitterId:null,
    state:null,
    submitName:null,
    submissonTime:null,
    reasonForApproval:null,
  }
  stateDict = null;
  sourceDict = null;
  articleAreaDict = null;
  columnNameDict = null;

  oldDatas = {
    id:null,
    title:null,
    source:null,
    articleArea:null,
    sourceUrl:null,
    columnName:null,
    content:null,
  }


  modifyDatas = {
    title: null,
    source:null,
    articleArea:null,
    sourceUrl:null,
    columnName:null,
    content:null
  }

  sourceOption = [];
  articleAreaOption = [];
  columnNameOption = [];

  constructor(
  private dataCenterService: DataCenterService,
  private requestService: RequestDataService,
  private userInfoService: UserInfoService,
  private confirmModal: ConfirmModalService
  ) { }

  ngOnInit() {
    const id = _sh.get(this.parentData,'id',null);
    this.doSearch(id);
  }

  doSearch(id){
    const search = observableForkJoin(
      this.requestService.getDataByCondition("simulationTrain/ig/info/ig_assets_detail.json",{'ID':id}),
      this.requestService.getDataByCondition("simulationTrain/ig/dict/SOURCE.json",{}),  //来源字典
      this.requestService.getDataByCondition("simulationTrain/ig/dict/ARTICLE_AREA.json",{}),  //文章地区字典
      this.requestService.getDataByCondition("simulationTrain/ig/dict/COLUMN_NAME.json",{}),    //栏目名称字典
    );
    search.subscribe(value => {
      this.dataList = _sh.get(value,[0,'data',0],[]);
      this.approvalDatas['state'] = this.dataList['STATE'];
      this.stateDict = this.dataList['STATE_DICT'];
      this.approvalDatas['submitterId'] = this.dataList['SUBMITTER_ID'];
      this.approvalDatas['submitName'] = this.dataList['SUBMIT_NAME'];
      this.approvalDatas['submissonTime'] = this.dataList['SUBMISSION_TIME'];
      this.approvalDatas['reasonForApproval'] = this.dataList['REASON_FOR_APPROVAL'];
      this.oldDatas['id'] = this.dataList['ID'];
      this.oldDatas['title'] = this.dataList['TITLE'];
      this.oldDatas['source'] = this.dataList['SOURCE'];
      this.sourceDict = this.dataList['SOURCE_DICT'];
      this.oldDatas['articleArea'] = this.dataList['ARTICLE_AREA'];
      this.articleAreaDict = this.dataList['ARTICLE_AREA_DICT'];
      this.oldDatas['sourceUrl'] = this.dataList['SOURCE_URL'];
      this.oldDatas['columnName'] = this.dataList['COLUMN_NAME'];
      this.columnNameDict = this.dataList['COLUMN_NAME'];
      this.oldDatas['content'] = this.dataList['CONTENT'];

      this.sourceOption = _sh.get(value,[1,'data'],[]);
      this.articleAreaOption = _sh.get(value,[2,'data'],[]);
      this.columnNameOption = _sh.get(value,[3,'data'],[]);
    })
  }

  gotoBack(){
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doPass(){
    let queryData = {
      'userId': this.userInfoService.getUserInfo().USER_ID
    }

    let bodyData = {
      'approvalDatas': this.approvalDatas,
      'oldDatas':this.oldDatas,
      'modifyDatas': this.modifyDatas
    }
    this.requestService.doActionByHdbsthor('AppApi/IgAssetsController', 'doPassApproval', queryData, bodyData, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(result =>{
      if(result == true){
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack()
      }
      else if(result.status == 500){
        this.confirmModal.show('error', {'title': '执行错误','content':result['error'].message});
      }
    })
  }

  doReject(){
    let queryData = {
      'userId': this.userInfoService.getUserInfo().USER_ID
    }

    let bodyData = {
      'approvalDatas': this.approvalDatas,
      'oldDatas':this.oldDatas,
      'modifyDatas': this.modifyDatas
    }
    this.requestService.doActionByHdbsthor('AppApi/IgAssetsController', 'doRejectApproval', queryData, bodyData, false, 'hdbsthor_simulationTrain', 'appToken').subscribe(result =>{
      if(result == true){
        this.confirmModal.show('success', {'title': '执行成功'});
        this.gotoBack()
      }
      else if(result.status == 500){
        this.confirmModal.show('error', {'title': '执行错误','content':result['error'].message});
      }
    })
  }

}
