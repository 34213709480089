import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {UEditorComponent} from "ngx-ueditor";
import * as _ from 'lodash';

@Component({
  selector: 'app-read-service-describe',
  templateUrl: './read-service-describe.component.html',
  styleUrls: ['./read-service-describe.component.css']
})
export class ReadServiceDescribeComponent implements OnInit {

  @Input() oneComponent: any;
  @Input() componentIndex: any;
  @Input() oneSmallGroup: any;
  @Input() smallGroupIndex: any;
  @Input() groupIndex: any;
  @Input() serviceData: any;
  componentModalVisible: any;
  componentDatas = {};

  constructor() {
  }

  ngOnInit() {
  }
}
