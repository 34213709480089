import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../service/utils.service';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {DataService} from './data.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import * as _ from 'underscore';
import * as $ from 'jquery';
import * as moment from 'moment';
import {EsRebuildComponent} from '../headLine/es-rebuild/es-rebuild.component';

@Injectable({
  providedIn: 'root'
})
export class DigitalResourcesService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    const flag = true;
    const ids = [];
    switch (pBtn.ACTION_VALUE) {
      case 'addBasicModel':
        pParentData.MODEL_TYPE = 'basic';
        this.transformData('customForm', pParentData, 'add', 'modelManagement');
        break;
      case 'editBasicModel':
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        pParentData['checkedData'] = _.find(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        this.mackConditon(pParentData);
        pParentData.MODEL_TYPE = 'basic';
        this.transformData('customForm', pParentData, 'modify', 'modelManagement');
        break;
      case 'addExtendModel':
        pParentData.MODEL_TYPE = 'extend';
        this.transformData('customForm', pParentData, 'add', 'modelManagement');
        break;
      case 'editExtendModel':
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        pParentData['checkedData'] = _.find(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        this.mackConditon(pParentData);
        pParentData.MODEL_TYPE = 'extend';
        this.transformData('customForm', pParentData, 'modify', 'modelManagement');
        break;
      case 'deleteModel':
        this.deleteModel(pParentData);
        break;
      //审核通过
      case 'userStatePass':
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        const selectList = _.filter(pParentData.data.data, item => {
          return item['checked'] === true && item['USER_STATE'] !== 'NO_PASS' && item['USER_STATE'] !== 'PASSING';
        });
        if (this.util.Base_HasValue(selectList)) {
          this.confirmModal.show('warning', {title: '只有状态为待审核或审核不通过的数据才可以执行此操作。'});
          return false;
        }
        this.confirmModal.show('confirm', {
          title: '您确定执行审核通过操作吗？',
          suc: () => {
            this.changeUserState(pParentData, 'PASS');
          }
        });
        break;
      //审核不通过
      case 'userStateNoPass':
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        const selectCheckPass = _.filter(pParentData.data.data, item => {
          return item['checked'] === true && item['USER_STATE'] !== 'NO_PASS' && item['USER_STATE'] !== 'PASS';
        });
        if (this.util.Base_HasValue(selectCheckPass)) {
          this.confirmModal.show('warning', {title: '只有状态为待审核或审核通过的数据才可以执行此操作。'});
          return false;
        }
        this.modalService.create({
          nzTitle: '原因',
          nzContent: FormModalComponent,
          nzWidth: 400,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REASON_TEXT',
                  'name': 'REASON_TEXT',
                  'customize': 24,
                  'component': 'textarea',
                  'lable': '原因',
                  'comment': '原因',
                  'placeholder': '请输入原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.changeUserState(pParentData, 'NO_PASS', response)
        });
        break;
      //冻结
      case 'userStateFreeze':
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        const selectCheckFree = _.filter(pParentData.data.data, item => {
          return item['checked'] === true && item['USER_STATE'] !== 'PASS';
        });
        if (this.util.Base_HasValue(selectCheckFree)) {
          this.confirmModal.show('warning', {title: '只有状态为审核通过的数据才可以执行此操作。'});
          return false;
        }
        this.modalService.create({
          nzTitle: '原因',
          nzContent: FormModalComponent,
          nzWidth: 400,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REASON_TEXT',
                  'name': 'REASON_TEXT',
                  'customize': 24,
                  'component': 'textarea',
                  'lable': '原因',
                  'comment': '原因',
                  'placeholder': '请输入原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.changeUserState(pParentData, 'FREEZE', response)
        });
        break;
      //拉黑
      case 'blacklist':
        if (this.selectMore(pParentData.data.data) < 1) {
          return;
        }
        const selectCheckBlack = _.filter(pParentData.data.data, item => {
          return item['checked'] === true && item['USER_STATE'] !== 'PASS';
        });
        if (this.util.Base_HasValue(selectCheckBlack)) {
          this.confirmModal.show('warning', {title: '只有状态为审核通过的数据才可以执行此操作。'});
          return false;
        }
        this.modalService.create({
          nzTitle: '原因',
          nzContent: FormModalComponent,
          nzWidth: 400,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            modalFormData: {
              'columns': [
                {
                  'column': 'REASON_TEXT',
                  'name': 'REASON_TEXT',
                  'customize': 24,
                  'component': 'textarea',
                  'lable': '原因',
                  'comment': '原因',
                  'placeholder': '请输入原因',
                  'model': null,
                  'visible': true,
                  'require': true
                }
              ]
            }
          },
          nzOnOk: (response) => this.blackListFun(pParentData, response, 1)
        });
        break;
      case 'removeBlacklist': // 移除黑名单
        const selectCheckRemove= _.filter(pParentData.data.data, item => {
          return item['checked'] === true && item['IN_BLACKLIST'] !== 1;
        });
        if (this.util.Base_HasValue(selectCheckRemove)) {
          this.confirmModal.show('warning', {title: '只有已拉黑的用户才可以执行此操作。'});
          return false;
        }
        this.blackListFun(pParentData, {}, 0);
        break;
      case 'userGroupAdd': // 用户组管理添加
        this.transformData('customForm', pParentData, 'add', 'userGroupAdd');
        break;
      case 'userGroupEdit': // 用户组管理修改
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        this.transformData('customForm', pParentData, 'modify', 'userGroupEdit');
        break;
      case 'managementUserGroup': // 管理用户组成员
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        this.transformData('customForm', pParentData, null, 'managementUserGroup');
        break;
      case 'deleteUserGroup': //删除用户组
        if (this.selectMore(pParentData.data.data) < 1) {
          return false;
        }
        this.deleteUserGroup(pParentData);
        break;
      case 'distributionUserGroup': // 贡献者管理分配用户组
        if (this.selectOneData(pParentData.data.data) !== 1) {
          return false;
        }
        const selectData = _.find(pParentData.data.data, oneData => {
          return oneData['checked'];
        });
        if (selectData['USER_STATE'] !== 'PASS') {
          return this.confirmModal.show('warning', {title: '只有状态为审核通过的数据才可以执行此操作。'});
        }
        this.transformData('customForm', pParentData, null, 'distributionUserGroup');
        break;
    }
  }

  blackListFun(pParentData, result, value) {
    let reason = {};
    if (this.util.Base_HasValue(result)) {
      if (result.childForm._validateForm.invalid) {
        for (const key in result.childForm._validateForm.controls) {
          result.childForm._validateForm.controls[key].markAsDirty();
          result.childForm._validateForm.controls[key].updateValueAndValidity();
        }
      }
      if (result.childForm._validateForm.valid) {
        reason = result.modalFormData.columns[0];
      } else {
        return false;
      }
    }
    const selectList = _.filter(pParentData.data.data, item => {
      return item['checked'] === true;
    });
    const idArray = [];
    _.each(selectList, oneData => {
      idArray.push(oneData['USER_ID']);
    });
    let id = idArray.join('\',\'');
    id = '(\'' + id + '\')';
    const templateJsonPass = {
      'LIST': [
        {
          'TABLE': 'system_user',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'USER_ID',
            'VALUE': id,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': [
            {'COLUMN': 'IN_BLACKLIST'},
            {'COLUMN': 'REASON_TEXT'},
          ],
          'RECORDS': [
            {
              'IN_BLACKLIST': value,
              'REASON_TEXT': reason['model'] || '',
            }
          ]
        }
      ]
    };
    this.autoInsert.ExecJsonNoUser(templateJsonPass).subscribe(response => {
      if (response.state === 'success') {
        pParentData.needSearch = true;
        this.confirmModal.show('success', {title: '执行成功'});
      } else {
        this.confirmModal.show('error', {title: '执行失败'});
      }
    });
  }

  // 删除用户组
  deleteUserGroup(pParentData) {
    const data = {
      ids: []
    };
    _.each(pParentData.data.data, oneData => {
      if (oneData['checked']) {
        data.ids.push(oneData['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '删除用户组将取消关联用户的相应权限，请确认用户组没有关联活跃用户后再执行此操作。是否继续？',
      suc: () => {
        this.dataService.requestQuickSliver('/digital/deleteGroup', data).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {title: '删除成功'});
            pParentData.needSearch = true;
          }
        });
      }
    });
  }

  //更改用户状态
  changeUserState(pParentData, userState, result?) {
    let reason = {};
    if (this.util.Base_HasValue(result)) {
      if (result.childForm._validateForm.invalid) {
        for (const key in result.childForm._validateForm.controls) {
          result.childForm._validateForm.controls[key].markAsDirty();
          result.childForm._validateForm.controls[key].updateValueAndValidity();
        }
      }
      if (result.childForm._validateForm.valid) {
        reason = result.modalFormData.columns[0];
      } else {
        return false;
      }
    }
    const user = this.userInfo.getUserInfo();
    const selectList = _.filter(pParentData.data.data, item => {
      return item['checked'] === true;
    });
    const idArray = [];
    _.each(selectList, oneData => {
      idArray.push(oneData['USER_ID']);
    });
    let id = idArray.join('\',\'');
    id = '(\'' + id + '\')';
    const columns = [
      {'COLUMN': 'USER_STATE'},
      {'COLUMN': 'UPD_TIME'},
      {'COLUMN': 'UPD_NAME'},
      {'COLUMN': 'UPD_USERID'},
    ];
    const records = [
      {
        'USER_STATE': userState,
        'UPD_TIME': 'SYS-DATETIME',
        'UPD_NAME': user.USERNAME,
        'UPD_USERID': user.USER_ID
      }
    ];
    if (this.util.Base_HasValue(reason) && this.util.Base_HasValue(reason['model'])) {
      columns.push(
        {'COLUMN': 'REASON_TEXT'}
      );
      records[0]['REASON_TEXT'] = reason['model'];
    }
    if (userState === 'PASS' || userState === 'NO_PASS') {
      columns.push(
        {'COLUMN': 'AUDIT_TIME'},
        {'COLUMN': 'AUDIT_USER_ID'},
        {'COLUMN': 'AUDIT_USER_NAME'},
      );
      records[0]['AUDIT_TIME'] = 'SYS-DATETIME';
      records[0]['AUDIT_USER_ID'] = user.USER_ID;
      records[0]['AUDIT_USER_NAME'] = user.USERNAME;
    }
    const templateJsonPass = {
      'LIST': [
        {
          'TABLE': 'system_user',
          'ACTION': 'Update',
          'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
          'WHERE': [{
            'COLUMN': 'USER_ID',
            'VALUE': id,
            'OPERATOR': 'in',
            'VALUE_TYPE': 'int',
            'CONDITION_OPERATOR': 'AND',
            'GROUP': 'GROUP_01'
          }],
          'COLUMNS': columns,
          'RECORDS': records
        }
      ]
    };
    this.autoInsert.ExecJsonNoUser(templateJsonPass).subscribe(response => {
      if (response.state === 'success') {
        pParentData.needSearch = true;
        this.confirmModal.show('success', {title: '执行成功'});
      } else {
        this.confirmModal.show('error', {title: '执行失败'});
      }
    });
  }

  // 删除模型
  deleteModel(pParentData) {
    if (this.selectMore(pParentData.data.data) < 1) {
      return false;
    }
    let ids = [];
    _.each(pParentData.data.data, item => {
      if (item['checked']) {
        ids.push(item['ID']);
      }
    });
    this.confirmModal.show('confirm', {
      title: '您确定删除这些模型吗？',
      suc: () => {
        this.dataService.deleteModel({
          modelId: ids
        }).subscribe(result => {
          if (result) {
            this.confirmModal.show('success', {
              title: '温馨提示',
              content: '删除成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {
              title: '温馨提示',
              content: '操作失败，请刷新重试。'
            });
          }
        });
      }
    });
  }

  mackConditon(pParentData): void {
    function setDetailConditons(pData) {
      function isCondtion(OneColumn) {
        let bFind = false;
        if (_.has(OneColumn, 'iscondition')) {
          bFind = OneColumn.iscondition;
        }
        return bFind;
      }

      let conData;
      _.each(pData, function (oneData) {
        if (oneData['checked']) {
          conData = oneData;
        }
      });
      const tmp = {};
      _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
        //只将 iscondition 为 true 的作为条件传入详情页面。
        if (isCondtion(OneColumn)) {
          tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
        }
      });
      return tmp;
    }

    pParentData.detailCondition = setDetailConditons(pParentData.data.data);
  }

  transformData(pFormType, parentData, pModeType?, btnName?, custormFormType?) {
    // 重新覆盖 datacenter 数据
    // parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.custormFormType = custormFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doDetailAction(pBtn, parentData, mainForm) {
    switch (pBtn.ACTION_VALUE) {

    }
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
