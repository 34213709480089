import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {UtilsService} from "../../service/utils.service";
import {RequestDataService} from "../../service/request-data.service";
import * as _sh from 'lodash';
import {
    Animation,
    BMarker,
    BNavigationControl,
    ControlAnchor,
    MapOptions,
    MapTypeControlOptions, MapTypeControlType, MapTypeEnum, MarkerOptions,
    NavigationControlOptions, NavigationControlType,
    OverviewMapControlOptions, Point,
    ScaleControlOptions
} from "angular2-baidu-map";
import AMapLoader from "@amap/amap-jsapi-loader";

@Component({
    selector: 'app-pop-amap',
    templateUrl: './pop-amap.component.html',
    styleUrls: ['./pop-amap.component.css']
})
export class PopAmapComponent implements OnInit {
    public options: MapOptions;
    public controlOpts: NavigationControlOptions;
    public overviewmapOpts: OverviewMapControlOptions;
    public scaleOpts: ScaleControlOptions;
    public mapTypeOpts: MapTypeControlOptions;
    public tplModal: NzModalRef;
    public mapLng;
    public mapLat;
    public searchText;
    public markers: Array<{ point: Point; options?: MarkerOptions }>
    // 是否显示弹框
    public isVisible = false;
    local: any;
    AMap: any;// 控件
    map: any; //
    @ViewChild('popAMap', {static: true}) eleRef: ElementRef;

    // 改变绑定对象
    @Input() isMulti = false;
    @Input() hulkForm: FormGroup;
    @Input() hulkColumn: any;
    @Input() bindModel: any;
    @Input() forms: any;
    @Input() oneForms: any;


    @ViewChild('childForm', {static: false}) childForm;

    constructor(private el: ElementRef,
                private modalService: NzModalService,
                private utils: UtilsService,
                private requestDataService: RequestDataService
    ) {

    }

    setPoint(arr) {
        this.mapLat = _sh.toNumber(arr[0]);
        this.mapLng = _sh.toNumber(arr[1]);

        if (!this.utils.Base_HasValue(this.mapLat)) {

        } else {

        }

    }

    getPointFromValue() {
        if (this.bindModel) {
            if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
                let arr = _sh.split(this.bindModel[this.hulkColumn.column], ",");
                this.setPoint(arr);
            }
        } else {
            let arr = _sh.split(this.hulkColumn.model, ",");
            this.setPoint(arr);
        }
    }

    ngOnInit() {
        this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
        this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    }

    showModal(tplContent: TemplateRef<{}>) {
        this.setMap();
        if (this.hulkColumn.disable) {
            return false;
        }
        this.getPointFromValue();
        this.tplModal = this.modalService.create({
            nzTitle: '选择地址',
            nzContent: tplContent,
            nzWidth: 850,
            nzWrapClassName: 'vertical-center-modal',
            nzOnOk: (response) => {
                if (this.bindModel) {
                    this.bindModel[this.hulkColumn.column] = this.mapLat + "," + this.mapLng;
                    this.bindModel[this.hulkColumn.column + 'Name'] = this.mapLat + "," + this.mapLng;
                } else {
                    this.hulkColumn.model = this.mapLat + "," + this.mapLng;
                    this.hulkColumn.modelName = this.mapLat + "," + this.mapLng;
                }
            }
        });
    }

    public mapClick(e: any) {
        this.mapLng = e.point.lng.toFixed(6);
        this.mapLat = e.point.lat.toFixed(6);
        this.markers = [
            {
                point: {
                    lat: this.mapLat,
                    lng: this.mapLng
                }
            }
        ]
    }

    public controlLoaded(control: BNavigationControl): void {
    }

    getAddress() {
        if (this.utils.Base_HasValue(this.searchText)) {
            this.requestDataService.baiduMapSearch(this.searchText, null, null).subscribe(rep => {
                if (this.utils.Base_HasValue(rep)) {
                    const message = _sh.get(rep, 'message');
                    if (message === "ok") {
                        const oneAddrObj = _sh.get(rep, ['results', 0]);
                        this.mapLat = oneAddrObj.location.lat;
                        this.mapLng = oneAddrObj.location.lng;
                        this.options = {
                            centerAndZoom: {
                                lat: this.mapLat,
                                lng: this.mapLng,
                                zoom: 16
                            }
                        };
                        this.markers = [
                            {
                                point: {
                                    lat: this.mapLat,
                                    lng: this.mapLng
                                }
                            }
                        ]
                    }
                }
            });
        }
    }

    public setAnimation(marker: BMarker): void {
        marker.setAnimation(Animation.BMAP_ANIMATION_BOUNCE)
    }

    addAMapControl() {
        var toolbar = new this.AMap.ToolBar({
            position: {
                top: '110px',
                right: '40px'
            }
        });
        var controlBar = new this.AMap.ControlBar({
            position: {
                top: '10px',
                right: '10px'
            }
        });
        var scale = new this.AMap.Scale();

        var overView = new this.AMap.HawkEye();
        this.map.addControl(toolbar);
        this.map.addControl(scale);
        this.map.addControl(controlBar);
        this.map.addControl(overView);
    }

    setMap() {

        AMapLoader.load({ //首次调用 load
            key: '7d4ef5abd3fe75f2496fa1fd717a69fa',//首次load key为必填
            version: '2.0',
            plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.DistrictSearch', 'AMap.CircleEditor', 'AMap.MarkerCluster', 'AMap.ControlBar', 'AMap.HawkEye'],
            Loca: {
                version: '2.0.0',
            }
        }).then((AMap) => {
            this.AMap = AMap;
            this.map = new AMap.Map('select-pop-amap', {
                zoom: 12,
                center: [110.798921, 32.629057]
            });
            let map = this.map;
            this.addAMapControl();
            //@ts-ignore
            var loca = new Loca.Container({
                map,
            });
        }).catch((e) => {
            console.error(e);
        });
    }


    //清空poplist值
    removeValue(): void {
        if (this.bindModel) {
            this.bindModel[this.hulkColumn.column] = null;
            this.bindModel[this.hulkColumn.column + 'Name'] = '';
        } else {
            this.hulkColumn.model = null;
            this.hulkColumn.modelName = '';
        }
    }
}
