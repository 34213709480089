import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {UtilsService} from '../../service/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-week',
  templateUrl: './date-week.component.html',
  styleUrls: ['./date-week.component.css']
})
export class DateWeekComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  data: any = null;

  constructor(private utils: UtilsService) {
  }

  changeModel () {
    if(this.utils.Base_HasValue(this.data)){
      this.hulkColumn.model = moment(this.data).isoWeek();
    } else {
      this.data = null;
    }
  }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.data = moment().isoWeek(this.hulkColumn.model)['_d'];
    }
  }

  ngDoCheck(): void {
    if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        this.data = moment().isoWeek(this.hulkColumn.model)['_d'];
      } else {
        this.data = null;
      }
      this.hulkColumn.oldModel = this.hulkColumn.model;
    }
  }
}
