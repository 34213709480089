import {Component, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../../service/utils.service";
import * as _sh from 'lodash';
import {getInstanceByDom, connect} from 'echarts';


@Component({
  selector: 'app-hulk-thanos',
  templateUrl: './hulk-thanos.component.html',
  styleUrls: ['./hulk-thanos.component.scss']
})
export class HulkThanosComponent implements OnInit {


  public symbol = 'SHFE.zn2305';
  public isDataSync = false;
  public needDeleteSignle = true;
  public dataList = [];
  public option = {
    animation:false,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['卖成交量', '挂卖量', '买成交量', '挂买量']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'value'
      }
    ],
    yAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      }
    ],
    series: [
      {
        name: '卖成交量',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'inside'
        },
        emphasis: {
          focus: 'series'
        },
        data: [-120, -132, -101, -134, -190, -230, -210]
      },
      {
        name: '挂卖量',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'inside'
        },
        emphasis: {
          focus: 'series'
        },
        data: [-120, -132, -101, -134, -190, -230, -210]
      },
      {
        name: '买成交量',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'inside'
        },
        emphasis: {
          focus: 'series'
        },
        data: [200, 170, 240, 244, 200, 220, 210]
      },
      {
        name: '挂买量',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'inside'
        },
        emphasis: {
          focus: 'series'
        },
        data: [320, 302, 341, 374, 390, 450, 420]
      },
    ]
  };
  public intervalId: any;

  constructor(private requestDataService: RequestDataService,
              private http: HttpClient,
              private utils: UtilsService) {
  }

  ngOnInit() {

  }


  execEchartOption() {
    let yAxisData = [];
    let tempList = _sh.cloneDeep(this.dataList);
    tempList = _sh.reverse(tempList);

    _sh.each(tempList, oneRow => {
      yAxisData.push(oneRow['price']);
    })
    _sh.set(this.option, ['yAxis', 0, 'data'], yAxisData);

    let 卖成交量 = []
    let 买成交量 = []
    let 挂卖量 = []
    let 挂买量 = []
    _sh.each(tempList, oneRow => {
      卖成交量.push(oneRow['sell'] * -1)
      买成交量.push(oneRow['buy'])
      挂卖量.push(oneRow['ask_volume'] * -1)
      挂买量.push(oneRow['bid_volume'])
    })
    _sh.set(this.option, ['series', 0, 'data'], 卖成交量);
    _sh.set(this.option, ['series', 1, 'data'], 挂卖量);
    _sh.set(this.option, ['series', 2, 'data'], 买成交量);
    _sh.set(this.option, ['series', 3, 'data'], 挂买量);
    const chart1 = getInstanceByDom(document.getElementById('mychart'));
    chart1.clear();
    chart1.setOption(this.option, true);
  }

  execDataList(_response) {
    // 删除单数
    if (this.needDeleteSignle) {
      let tempList = [];
      _sh.each(_response, oneRow => {
        if (oneRow['price'] % 5 === 0) {
          // 偶数
          tempList.push(oneRow);
        }
      })
      this.dataList = tempList;
    } else {
      this.dataList = _response;
    }
    this.execEchartOption();
  }

  doGetRedis() {
    let that = this;
    that.requestDataService.redisGet(that.symbol + ':orderflow').subscribe(response => {
      this.execDataList(response);
    })
  }

  doDataSync() {
    let that = this;
    if (this.isDataSync) {
      this.intervalId = setInterval(function () {
        that.doGetRedis()
      }, 500);
    }
  }

  doClose() {
    clearInterval(this.intervalId);
    this.isDataSync = false;
  }
}
