import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as screenfull from 'screenfull';
import * as echarts from 'echarts';
import {interval} from 'rxjs';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {forkJoin as observableForkJoin} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import Swiper from 'swiper';
import * as alasql from 'alasql';

@Component({
  selector: 'app-fury-cockpit',
  templateUrl: './fury-cockpit.component.html',
  styleUrls: ['./fury-cockpit.component.css']
})
export class FuryCockpitComponent implements OnInit, OnDestroy {

  constructor(private requestData: RequestDataService,
              private http: HttpClient,
              private utils: UtilsService) {
  }

  productData: any = []; // 产品信息
  nowProduct: any; // 当前产品
  agreeToTestShare: any; // 同意试用占比
  netConversionRatio: any; // 挂网转化占比
  onInternet: any; // 挂网情况
  handOver: any; // 交付情况
  visitTop5: any; // 机构拜访top5
  weekPlannedVisit: any = []; // 周计划拜访客户
  weekAgreeToTry: any; // 周同意试用客户
  weekOnInternet: any; // 周挂网客户
  salesConversionRate: any; // 销售人员转化
  visitSevenDays: any; // 近七日拜访情况
  weekVisit: any; // 周每人拜访数量
  productDistributionArea: any; // 产品散布
  isfull: any = false;
  custSum: any = 0; // 客户总数
  custAgreeTrySum: any = 0; // 同意试用总数
  custOnInternetSum: any = 0; // 已挂网总数
  thisWeekTrial: any = 0; // 本周试用的数据
  thisWeekSpreadNet: any = 0; // 本周挂网的数据
  thisWeekDeliver: any = 0; // 本周交付的数据
  deliveryEfficiency: any = 0; // 交付效率
  visitCustCount = 0; // 已拜该的客户总数
  visitCount = 0; // 总共拜访次数
  weekBegin: any; // 本周开始日期
  weekEnd: any; // 本周结束日期
  today: any;
  public hollowCircle: any = '130px';
  public solidCircle: any = '266px';
  public visitTopHight: any = '260px';
  public mapHeight: any = '707px';
  salesStatus: any = [
    {name: '同意试用未交付', code: 'AGREE_TRIAL'},
    {name: '已交付未挂网', code: 'DELIVER'},
    {name: '已挂网', code: 'HANGED_NET'},
  ];
  radioValue: any = 'AGREE_TRIAL';
  timer: any;
  productionTimer: any;
  custProductList: any = [];
  countByState: any;
  swiper: any;

  ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.productionTimer);
  }

  getFileSize(size) {
    return this.utils.conver(size * 1024 * 1024);
  }

  fullscreen() {
    const el = document.getElementById('target');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
        if (this.isfull) {

        } else {

        }
      });
      if (this.isfull) {
        let i = 0;
        this.productionTimer = setInterval(() => {
          if (i >= this.productData.length) {
            i = 0;
          }
          if (!this.isfull) {
            clearInterval(this.productionTimer);
          }
          this.nowProduct = this.productData[i];
          i++;
          this.dataFormat();
        }, 70000);
      }
    }
  }

  // 销售状态切换
  switchSalesStatus() {
    const data: any = [
      {name: '北京', value: 0},
      {name: '天津', value: 0},
      {name: '重庆', value: 0},
      {name: '上海', value: 0},
      {name: '湖南', value: 0},
      {name: '广东', value: 0},
      {name: '福建', value: 0},
      {name: '江西', value: 0},
      {name: '四川', value: 0},
      {name: '广西', value: 0},
      {name: '新疆', value: 0},
      {name: '西藏', value: 0},
      {name: '青海', value: 0},
      {name: '甘肃', value: 0},
      {name: '宁夏', value: 0},
      {name: '内蒙古', value: 0},
      {name: '海南', value: 0},
      {name: '山西', value: 0},
      {name: '陕西', value: 0},
      {name: '云南', value: 0},
      {name: '贵州', value: 0},
      {name: '湖北', value: 0},
      {name: '浙江', value: 0},
      {name: '安徽', value: 0},
      {name: '河南', value: 0},
      {name: '山东', value: 0},
      {name: '江苏', value: 0},
      {name: '河北', value: 0},
      {name: '辽宁', value: 0},
      {name: '吉林', value: 0},
      {name: '黑龙江', value: 0},
      {name: '台湾', value: 0}
    ];
    const stateLists = alasql('select PROVINCE, concat(sum(CASE PLAN_STATE when "AGREE_TRIAL" then 1 else 0 end), "-", sum(CASE PLAN_STATE when "DELIVER" then 1 else 0 end), "-", sum(CASE PLAN_STATE when "HANGED_NET" then 1 else 0 end)) STATE_COUNT,sum(CASE PLAN_STATE when "HANGED_NET" then 1 else 0 end) HANGED_NET FROM ? WHERE PLAN_STATE IN ( "AGREE_TRIAL", "DELIVER", "HANGED_NET" ) GROUP BY PROVINCE', [this.custProductList]);
    _.each(stateLists, oneData => {
      _.each(data, item => {
        if (oneData['PROVINCE'] === item['name']) {
          item['value'] = oneData['HANGED_NET'];
          item['label'] = {
            normal: {
              show: true,
              fontSize: '12',
              formatter: '{b}:' + oneData['STATE_COUNT'],
              color: '#000000'
            }
          };
        }
      });
    });
    this.productDistributionArea = this.map(data);
  }

  // switchSalesStatus() {
  //   let provinceCounts: any = {};
  //   let stateLists = [];
  //   let data: any = [
  //     {name: '北京', value: 0},
  //     {name: '天津', value: 0},
  //     {name: '重庆', value: 0},
  //     {name: '上海', value: 0},
  //     {name: '湖南', value: 0},
  //     {name: '广东', value: 0},
  //     {name: '福建', value: 0},
  //     {name: '江西', value: 0},
  //     {name: '四川', value: 0},
  //     {name: '广西', value: 0},
  //     {name: '新疆', value: 0},
  //     {name: '西藏', value: 0},
  //     {name: '青海', value: 0},
  //     {name: '甘肃', value: 0},
  //     {name: '宁夏', value: 0},
  //     {name: '内蒙古', value: 0},
  //     {name: '海南', value: 0},
  //     {name: '山西', value: 0},
  //     {name: '陕西', value: 0},
  //     {name: '云南', value: 0},
  //     {name: '贵州', value: 0},
  //     {name: '湖北', value: 0},
  //     {name: '浙江', value: 0},
  //     {name: '安徽', value: 0},
  //     {name: '河南', value: 0},
  //     {name: '山东', value: 0},
  //     {name: '江苏', value: 0},
  //     {name: '河北', value: 0},
  //     {name: '辽宁', value: 0},
  //     {name: '吉林', value: 0},
  //     {name: '黑龙江', value: 0},
  //     {name: '台湾', value: 0}
  //   ];
  //   // 先执行一次再去走定时任务
  //   if (this.radioValue === 'AGREE_TRIAL') {
  //     stateLists = lodash.filter(this.custProductList, (oneData) => {
  //       return oneData['PLAN_STATE'] === 'AGREE_TRIAL';
  //     });
  //   } else if (this.radioValue === 'DELIVER') {
  //     stateLists = lodash.filter(this.custProductList, (oneData) => {
  //       return oneData['PLAN_STATE'] === 'DELIVER';
  //     });
  //   } else {
  //     stateLists = lodash.filter(this.custProductList, (oneData) => {
  //       return oneData['PLAN_STATE'] === 'HANGED_NET';
  //     });
  //   }
  //   provinceCounts = lodash.countBy(stateLists, 'PROVINCE');
  //   _.each(provinceCounts, (value, key) => {
  //     _.each(data, item => {
  //       if (key === item['name']) {
  //         item['value'] = value;
  //         item['label'] = {
  //           normal: {
  //             show: true,
  //             fontSize: '12',
  //             formatter: '{b}:{c}',
  //             color: '#000000'
  //           }
  //         };
  //       }
  //     });
  //   });
  //   this.productDistributionArea = this.map(data);
  //   let count = this.salesStatus.length;
  //   let i = 0;
  //   this.timer = setInterval(() => {
  //     data = [
  //       {name: '北京', value: 0},
  //       {name: '天津', value: 0},
  //       {name: '重庆', value: 0},
  //       {name: '上海', value: 0},
  //       {name: '湖南', value: 0},
  //       {name: '广东', value: 0},
  //       {name: '福建', value: 0},
  //       {name: '江西', value: 0},
  //       {name: '四川', value: 0},
  //       {name: '广西', value: 0},
  //       {name: '新疆', value: 0},
  //       {name: '西藏', value: 0},
  //       {name: '青海', value: 0},
  //       {name: '甘肃', value: 0},
  //       {name: '宁夏', value: 0},
  //       {name: '内蒙古', value: 0},
  //       {name: '海南', value: 0},
  //       {name: '山西', value: 0},
  //       {name: '陕西', value: 0},
  //       {name: '云南', value: 0},
  //       {name: '贵州', value: 0},
  //       {name: '湖北', value: 0},
  //       {name: '浙江', value: 0},
  //       {name: '安徽', value: 0},
  //       {name: '河南', value: 0},
  //       {name: '山东', value: 0},
  //       {name: '江苏', value: 0},
  //       {name: '河北', value: 0},
  //       {name: '辽宁', value: 0},
  //       {name: '吉林', value: 0},
  //       {name: '黑龙江', value: 0},
  //       {name: '台湾', value: 0}
  //     ];
  //     if (i >= count) {
  //       i = 0;
  //     }
  //     this.radioValue = this.salesStatus[i].code;
  //     i++;
  //     if (this.radioValue === 'AGREE_TRIAL') {
  //       stateLists = lodash.filter(this.custProductList, (oneData) => {
  //         return oneData['PLAN_STATE'] === 'AGREE_TRIAL';
  //       });
  //     } else if (this.radioValue === 'DELIVER') {
  //       stateLists = lodash.filter(this.custProductList, (oneData) => {
  //         return oneData['PLAN_STATE'] === 'DELIVER';
  //       });
  //     } else {
  //       stateLists = lodash.filter(this.custProductList, (oneData) => {
  //         return oneData['PLAN_STATE'] === 'HANGED_NET';
  //       });
  //     }
  //     provinceCounts = lodash.countBy(stateLists, 'PROVINCE');
  //     _.each(provinceCounts, (value, key) => {
  //       _.each(data, item => {
  //         if (key === item['name']) {
  //           item['value'] = value;
  //           item['label'] = {
  //             normal: {
  //               show: true,
  //               fontSize: '12',
  //               formatter: '{b}:{c}',
  //               color: '#000000'
  //             }
  //           };
  //         }
  //       });
  //     });
  //     this.productDistributionArea = this.map(data);
  //   }, 20000);
  // }

  // 折线图
  lineChart(data) {
    const option = {
      title: {
        text: '近4周{a|拜访}情况',
        textStyle: {
          rich: {
            a: {
              color: '#ff0000',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      color: ['#2819f5', '#ff0000'],
      legend: {
        data: ['计划拜访', '实际拜访'],
        right: 0
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: data.week
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value}'
        }
      },
      series: [
        {
          name: '计划拜访',
          type: 'line',
          data: data.plan,
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0.2, color: '#0da7f1' // 0% 处的颜色
                },
                {
                  offset: 0.5, color: '#090cf1' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#0da7f1' // 100% 处的颜色
                }],
              globalCoord: true // 缺省为 false
            }
          },
        },
        {
          name: '实际拜访',
          type: 'line',
          data: data.finish,
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0.2, color: '#ff4c39' // 0% 处的颜色
                },
                {
                  offset: 0.5, color: '#f10a12' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#ff4c39' // 100% 处的颜色
                }],
              globalCoord: true // 缺省为 false
            }
          }
        }
      ]
    };
    return option;
  }

  fourWeekVisit(dataList, week) {
    let weeks = [week - 3, week - 2, week - 1, week];
    let data: any = {
      week: [],
      plan: [],
      finish: []
    };
    let planOk = false;
    let finishOk = false;
    const plan = alasql('select count(*) acount,VISIT_DATE_PLAN from ? where VISIT_DATE_PLAN >= ' + weeks[0] + ' and VISIT_DATE_PLAN <= ' + weeks[3] + ' group by VISIT_DATE_PLAN', [dataList]);
    const finish = alasql('select count(*) acount,ACTUAL_WEEK from ? where VISIT_STATE = "FINISH" AND ACTUAL_WEEK >= ' + weeks[0] + ' and ACTUAL_WEEK <= ' + weeks[3] + ' group by ACTUAL_WEEK', [dataList]);
    lodash.each(weeks, item => {
      data.week.push('W' + item);
      planOk = true;
      finishOk = true;
      lodash.each(plan, oneData => {
        if (item === oneData['VISIT_DATE_PLAN'] - 0) {
          planOk = false;
          data.plan.push(oneData['acount']);
        }
      });
      if (planOk) {
        data.plan.push(0);
      }
      lodash.each(finish, oneData => {
        if (item === oneData['ACTUAL_WEEK'] - 0) {
          finishOk = false;
          data.finish.push(oneData['acount']);
        }
      });
      if (finishOk) {
        data.finish.push(0);
      }
    });
    data.week[3] = '本周';
    return this.lineChart(data);
  }

  //地图
  map(data) {
    const option = {
      title: {
        text: '  全国{a|试用}及{a|拜访}情况',
        textStyle: {
          rich: {
            a: {
              color: '#ff0000',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}：{c}'
      },
      visualMap: {
        show: false,
        min: 0,
        max: 10,
        text: ['High', 'Low'],
        realtime: false,
        calculable: true,
        inRange: {
          color: ['lightskyblue', 'yellow', 'orangered']
        },
        itemStyle: {
          normal: {label: {show: true}},
          emphasis: {label: {show: true}}
        },
      },
      series: [
        {
          type: 'map',
          mapType: 'China',
          label: {
            show: true
          },
          itemStyle: {
            normal: {
              areaColor: '#AAD5FF',
              borderColor: 'white',
              label: {show: true, color: 'white'}
            },
            emphasis: {
              areaColor: '#A5DABB'
            }
          },
          zoom: 1.2,
          data: data
        }
      ]
    };
    return option;
  }

  // 柱状图
  histogram(data) {
    const option = {
      title: {
        text: data.text,
        textStyle: {
          rich: {
            a: {
              color: '#ff0000',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.name,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        }
      ],
      series: [
        {
          barMaxWidth: 20,
          type: 'bar',
          itemStyle: {
            normal: {
              barBorderRadius: 20,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6F49FC'},
                  {offset: 1, color: '#83bff6'}
                ]
              )
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#83bff6'},
                  {offset: 1, color: '#6F49FC'}
                ]
              )
            }
          },
          data: data.code,
          label: {
            normal: {
              show: true,
              position: 'top'
            }
          }
        }
      ]
    };
    return option;
  }

  // 机构拜访量top5
  custVisitNum(pList) {
    let data: any = {
      text: '客户{a|拜访}量{a|TOP5}',
      code: [],
      name: []
    };
    lodash.each(pList, item => {
      data.code.push(item['acount']);
      data.name.push(item['CUST_NAME']);
    });
    this.visitTop5 = this.histogram(data);
  }

  // 横向柱状图
  horizontalHistogram(data) {
    let option = {
      title: {
        text: data.text,
        textStyle: {
          rich: {
            a: {
              color: '#ff0000',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['挂网数', '拜访数'],
        right: 0
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: data.name
      },
      series: [
        {
          name: '挂网数',
          barMaxWidth: 20,
          type: 'bar',
          z: 10,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                1, 0, 0, 0,
                [
                  {offset: 0, color: '#6F49FC'},
                  {offset: 1, color: '#83bff6'}
                ]
              )
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(
                1, 0, 0, 0,
                [
                  {offset: 0, color: '#83bff6'},
                  {offset: 1, color: '#6F49FC'}
                ]
              )
            }
          },
          data: data.onInternetCount
        },
        {
          name: '拜访数',
          type: 'bar',
          barMaxWidth: 20,
          barGap: '-100%',
          itemStyle: {
            normal: {color: '#eaeaea'}
          },
          data: data.visitCount
        }
      ]
    };
    return option;
  }

  // 销售人员转化率
  salesConversionRateFormat(pList) {
    let data = {
      text: '销售人员{a|转化率}情况',
      name: [],
      visitCount: [],
      onInternetCount: []
    };
    // 获取每人拜访数量
    let visitCounts = lodash.countBy(pList, 'PLAN_PERSON_CHARGE');
    lodash.each(visitCounts, (value, key) => {
      data.visitCount.push(value);
      data.name.push(key);
    });
    let datas = [];
    // 得到每人挂网数
    lodash.each(data.name, item => {
      datas = lodash.filter(pList, {
        'PLAN_PERSON_CHARGE': item,
        'PLAN_STATE': 'HANGED_NET'
      });
      data.onInternetCount.push(datas.length);
    });
    this.salesConversionRate = this.horizontalHistogram(data);
  }

  // 空心饼图
  hollowPieChart(data) {
    let option = {
      title: {
        text: data.title,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
        right: 0
      },
      color: ['#FE5D94', '#797AEE'],
      series: [
        {
          type: 'pie',
          radius: ['70%', '85%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center',
              formatter: '{b}\n{d}%'
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '15',
                fontWeight: 'bold',
                backgroundColor: '#ffffff'
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: data.data
        }
      ]
    };
    return option;
  }

  // 实心饼图
  solidPieChart(pData) {
    const option = {
      title: {
        text: pData.text,
        textStyle: {
          rich: {
            a: {
              color: '#ff0000',
              fontSize: 19,
              fontWeight: 'bold'
            }
          }
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      legend: pData['legend'],
      grid: {
        top: '4%',
        containLabel: true
      },
      color: ['#00EBFF', '#FF8900', '#AF00FC', '#323FFE', '#4981eb', '#6cf0cd', '#f8bd53', '#f1825b', '#4b53d8'],
      series: [
        {
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          label: {
            normal: {
              show: true,
              formatter: '{b}: {d}%'
            }
          },
          data: pData.data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    return option;
  }

  // 周每人计划拜访
  weekPlanVisit(pList) {
    let weekVisit = {
      text: '本周{a|计划拜访' + this.weekPlannedVisit['length'] + '家}',
      legend: {
        orient: 'vertical',
        left: 'right',
      },
      data: []
    };

    const userCount = lodash.countBy(pList, 'PLAN_PERSON_CHARGE');
    lodash.each(userCount, (key, value) => {
      const data: any = {
        value: key,
        name: value
      };
      weekVisit.data.push(data);
    });
    this.weekVisit = this.solidPieChart(weekVisit);
  }

  // 获取7天的起始日期
  getSearchData() {
    this.today = moment().format('YYYY-MM-DD');
    const category = [];
    const data = {
      ADD_TIME_END: this.today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(7 - 1, 'days').format('YYYY-MM-DD');
    for (let i = 7 - 1; i >= 0; i--) {
      category.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }

    const weekOfday = parseInt(moment().format('E')); // 计算今天是这周第几天
    const last_monday = moment().subtract(weekOfday, 'days').format('YYYY/MM/DD'); // 周一日期
    const last_sunday = moment(last_monday).add(6, 'days').format('YYYY/MM/DD'); // 周日日期
    return {
      searchData: data,
      category: category,
      today: this.today,
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      week: moment().week(),
      weekBegin: last_monday,
      weekEnd: last_sunday
    };
  }

  switchProduct(pObject) {
    this.nowProduct = pObject;
    this.dataFormat();
  }

  dataFormat() {
    clearInterval(this.timer);
    this.custSum = 0; // 客户总数
    this.custAgreeTrySum = 0; // 同意试用总数
    this.custOnInternetSum = 0; // 已挂网总数
    this.thisWeekTrial = 0; // 本周试用的数据
    this.thisWeekSpreadNet = 0; // 本周挂网的数据
    this.thisWeekDeliver = 0; // 本周交付的数据
    this.deliveryEfficiency = 0; // 交付效率
    this.visitCustCount = 0; // 已拜该的客户总数
    this.visitCount = 0; // 总共拜访次数
    const searchData = this.getSearchData();  //  设置多个变量，取当前周。和当前年。 当前周的 todo 开始日期，结束日期。
    this.weekBegin = moment(searchData.weekBegin).format('DD');
    this.weekEnd = moment(searchData.weekEnd).format('DD');
    const parallel = observableForkJoin(
      this.requestData.getDataByCondition('fury/report/report_cust_product.json', {PRODUCT: this.nowProduct['code']}), // 客户产品
      this.requestData.getDataByCondition('fury/report/report_visit_top5.json', {PRODUCT: this.nowProduct['code']}), // 客户拜访top
      this.requestData.getDataByCondition('fury/report/week_planned_visit.json', {
        PRODUCT: this.nowProduct['code'],
        YEAR: searchData.year,
        WEEK: searchData.week
      }), // 周计划拜访客户
      this.requestData.getDataByCondition('fury/report/week_agre_try.json', {
        PRODUCT: this.nowProduct['code'],
        YEAR: searchData.year,
        WEEK: searchData.week
      }), // 周同意试用客户
      this.requestData.getDataByCondition('fury/report/week_on_internet.json', {
        PRODUCT: this.nowProduct['code'],
        YEAR: searchData.year,
        WEEK: searchData.week
      }), // 周挂网客户
      this.requestData.getDataByCondition('fury/report/count_by_state_seller.json', {PRODUCT: this.nowProduct['code']}), // 按照 人员 和 状态 分组
      // 拜访次数
      this.requestData.getDataByCondition('fury/report/report_visit_count.json', {
        PRODUCT: this.nowProduct['code']
      }),
      // 近四周拜访情况
      this.requestData.getDataByCondition('fury/report/weeks_visit.json', {
        PRODUCT: this.nowProduct['code'],
        YEAR: searchData.year
      }),
      // 平均交付效率
      this.requestData.getDataByCondition('fury/report/delivery_efficiency.json', {PRODUCT: this.nowProduct['code']})
    );

    parallel.subscribe(
      values => {
        this.custProductList = lodash.get(values, [0, 'data']); // 所有的学校的产品的数据。 = 》 A
        this.custSum = this.custProductList.length; // 有产品计划的机构总数
        // 本地循环一个增加各种状态的 周数 ， 年数。
        lodash.each(this.custProductList, (item) => {
          const trialDate = lodash.get(item, 'TRIAL_DATE', null);
          if (this.utils.Base_HasValue(trialDate)) {
            lodash.set(item, 'TRIAL_YEAR', moment(trialDate).format('YYYY'));
            lodash.set(item, 'TRIAL_MONTH', moment(trialDate).format('MM'));
            lodash.set(item, 'TRIAL_WEEK', moment(trialDate).week());
          }
          const spreadNetDate = lodash.get(item, 'SPREAD_NET_DATE', null);
          if (this.utils.Base_HasValue(spreadNetDate)) {
            lodash.set(item, 'SPREAD_NET_YEAR', moment(spreadNetDate).format('YYYY'));
            lodash.set(item, 'SPREAD_NET_MONTH', moment(spreadNetDate).format('MM'));
            lodash.set(item, 'SPREAD_NET_WEEK', moment(spreadNetDate).week());
          }

          const deliverDate = lodash.get(item, 'DELIVER_DATE', null);
          if (this.utils.Base_HasValue(deliverDate)) {
            lodash.set(item, 'DELIVER_YEAR', moment(deliverDate).format('YYYY'));
            lodash.set(item, 'DELIVER_MONTH', moment(deliverDate).format('MM'));
            lodash.set(item, 'DELIVER_WEEK', moment(deliverDate).week());
          }

        });
        // 所有的数据按照状态进行分组，解决 左上角的 饼图1#,2# , 3#
        this.countByState = lodash.countBy(this.custProductList, 'PLAN_STATE');
        // 同意试用总数
        lodash.each(this.countByState, (value, key) => {
          if (key !== 'ASSIGNED' && key !== 'CANCEL' && key !== 'DISAGREE_TRIAL' && key !== 'PENDING' && key !== 'CONSIDERING') {
            this.custAgreeTrySum += value;
          }
        });
        // 已挂网总数
        lodash.each(this.countByState, (value, key) => {
          if (key !== 'ASSIGNED' && key !== 'CANCEL' && key !== 'DISAGREE_TRIAL' && key !== 'PENDING' && key !== 'AGREE_TRIAL' && key !== 'DELIVER' && key !== 'CONSIDERING') {
            this.custOnInternetSum += value;
          }
        });

        const stateListCount = {
          text: '{a|挂网}情况',
          data: [
            {value: this.countByState['PENDING'] > 0 ? this.countByState['PENDING'] : 0, name: '待分配'},
            {value: this.countByState['ASSIGNED'] > 0 ? this.countByState['ASSIGNED'] : 0, name: '已分配'},
            {value: this.countByState['CONSIDERING'] > 0 ? this.countByState['CONSIDERING'] : 0, name: '暂未同意试用'},
            {value: this.countByState['AGREE_TRIAL'] > 0 ? this.countByState['AGREE_TRIAL'] : 0, name: '同意试用'},
            {value: this.countByState['DELIVER'] > 0 ? this.countByState['DELIVER'] : 0, name: '已交付'},
            {value: this.countByState['MORATORIUM_NET'] > 0 ? this.countByState['MORATORIUM_NET'] : 0, name: '暂缓挂网'},
            {value: this.countByState['HANGED_NET'] > 0 ? this.countByState['HANGED_NET'] : 0, name: '已挂网'}
          ]
        };
        this.onInternet = this.solidPieChart(stateListCount);
        /**
         * 挂网试用占比和同意试用转化占比参数
         * data = {
              name: '同意试用占比',
              data: [
                {value:1, name:'同意试用'},
                {value:8, name:'拜访中'},
              ]
            }
         */
        const agree = {
          name: '同意试用占比',
          data: [
            {
              value: this.custAgreeTrySum > 0 ? this.custAgreeTrySum : 0,
              name: '同意试用',
              label: {
                normal: {
                  show: true,
                  position: 'center',
                  fontWeight: 'bold',
                  fontSize: '14',
                  formatter: '{b}\n{d}%',
                  color: '#000000'
                }
              }
            },
            {value: this.custSum - (this.custAgreeTrySum > 0 ? this.custAgreeTrySum : 0), name: '暂未同意'},
          ]
        };
        this.agreeToTestShare = this.hollowPieChart(agree);

        // 挂网转化占比
        const netConversion = {
          name: '挂网转化占比',
          data: [
            {
              value: this.custOnInternetSum > 0 ? this.custOnInternetSum : 0,
              name: '挂网',
              label: {
                normal: {
                  show: true,
                  position: 'center',
                  fontWeight: 'bold',
                  fontSize: '14',
                  formatter: '{b}\n{d}%',
                  color: '#000000'
                }
              }
            },
            {value: this.custSum - (this.custOnInternetSum > 0 ? this.custOnInternetSum : 0), name: '暂未挂网'}
          ]
        };
        this.netConversionRatio = this.hollowPieChart(netConversion);
        //todo 查询多有的 产品表 做一个sqlFile 做右下角的柱状图。 select 销售员，状态，count（id） from 产品表 group by 销售员，状态

        // 地图
        this.http.get('assets/lib/china.json')
          .subscribe(geoJson => {
            echarts.registerMap('China', geoJson);
            this.switchSalesStatus();
          });

        // 本周试用的数据
        this.thisWeekTrial = lodash.filter(this.custProductList, {
          'TRIAL_YEAR': searchData.year,
          'TRIAL_WEEK': searchData.week
        });
        // 本周挂网的数据
        this.thisWeekSpreadNet = lodash.filter(this.custProductList, {
          'SPREAD_NET_YEAR': searchData.year,
          'SPREAD_NET_WEEK': searchData.week
        });

        // 本周交付的数据 左边的 lable 1#
        this.thisWeekDeliver = lodash.filter(this.custProductList, {
          'DELIVER_YEAR': searchData.year,
          'DELIVER_WEEK': searchData.week
        });

        // todo 总数 - 已经交付的数据做  左边的lable  ？？？？？
        // todo lable 3# ？？？？？

        //  访问前五的数据，左下脚的的条形图
        const top5Data = lodash.take(lodash.get(values, [1, 'data']), 5);
        this.custVisitNum(top5Data);
        // 中部列表1 本周计划拜访的机构
        this.weekPlannedVisit = lodash.uniqWith(lodash.get(values, [2, 'data']), (arrVal, otherVal) =>
          arrVal['CUST_NAME'] === otherVal['CUST_NAME'] && arrVal['PROVINCE'] === otherVal['PROVINCE']
        );
        this.weekAgreeToTry = lodash.get(values, [3, 'data']);  // 中部列表2 本周同意试用的机构
        this.weekOnInternet = lodash.get(values, [4, 'data']);  // 中部列表3 本周挂网的机构

        // 右上饼图，week_planned_visit.length
        this.weekPlanVisit(lodash.get(values, [2, 'data']));

        // 近四周拜访情况
        this.visitSevenDays = this.fourWeekVisit(lodash.get(values, [7, 'data']), searchData.week);

        // 销售人员转化率
        this.salesConversionRateFormat(this.custProductList);

        const visitDetail = lodash.get(values, [6, 'data']);
        // 拜访总次数
        this.visitCount = visitDetail[0]['VISIT_COUNT'];

        // 拜该总客户数
        this.visitCustCount = visitDetail[0]['CUST_COUNT'];

        const delivery = lodash.get(values, [8, 'data']);
        if (this.utils.Base_HasValue(delivery[0]['acount'])) {
          this.deliveryEfficiency = delivery[0]['acount'].toFixed(1);
        }
      }
    );
  }

  ngAfterViewInit () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        slidesPerView: 6,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      });
    }, 1000);
  }

  ngOnInit() {
    this.requestData.getDataByCondition('fury/basic/dict/ONLINE_PRODUCT_NAME.json').subscribe(response => {
      this.productData = response.data;
      this.nowProduct = this.productData[0];
      this.dataFormat();
    });
  }

}
