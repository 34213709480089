import { Component,  Input, OnInit } from '@angular/core';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {DataService} from '../data.service';
import * as _ from 'lodash';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-distribution-user-group',
  templateUrl: './distribution-user-group.component.html',
  styleUrls: ['./distribution-user-group.component.css']
})
export class DistributionUserGroupComponent implements OnInit {
  @Input() parentData: any;
  checkedAll = false;
  groupList = [];
  searchName = '';
  userData: any;
  viewGoupPer = false;
  nowGroupName: any;
  channelList = [];
  sitelList = [];
  channelListNode: any = [];
  nodes: any = [];

  constructor(private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private utils: UtilsService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService) { }


  transformData(parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = 'listForm';
    parentData.actionArray = [];
    parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  handleCancelMiddle(): void {
    this.viewGoupPer = false;
  }

  changeSelected(pObject) {
    if (!pObject['selected']) {
      this.checkedAll = false;
    }
  }

  openGroupPer(pObject) {
    this.nowGroupName = pObject['GROUP_NAME'];
    this.dataService.requestQuickSliver('/digital/groupSearch', {ID: pObject['ID']}).subscribe(response => {
      this.channelList = response.drGroupChannel;
      _.each(this.channelList, oneData => {
        oneData['disableCheckbox'] = true;
      });
      this.initData();
      this.viewGoupPer = true;
    });
  }

  // 返回
  gotoBack(): void {
    this.transformData(this.parentData);
  }

  // 全选
  selectedAll() {
    _.each(this.groupList, oneData => {
      oneData['selected'] = this.checkedAll;
    });
  }

  doSave() {
    const data = {
      USER_ID: this.userData['USER_ID'],
      groupList: []
    };
    _.each(this.groupList, oneData => {
      if (oneData['selected']) {
        data.groupList.push({GROUP_ID: oneData['ID']});
      }
    });
    this.dataService.requestQuickSliver('/digital/assignUserGroup', data).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {title: '保存成功'});
        this.gotoBack();
      } else {
        this.confirmModal.show('error', {title: '保存失败，请稍后重试。'});
      }
    });
  }

  initData() {
    const copyNode = [];
    if (this.utils.Base_HasValue(this.channelList)) {
      this.channelListNode = this.utils.Base_ChannelData(this.channelList);
    }
    if (this.utils.Base_HasValue(this.sitelList)) {
      for (const oneSite of this.sitelList) {
        const node: any = {
          title: oneSite['SITE_NAME'],
          key: oneSite['ID'],
          siteId: oneSite['ID'],
          siteName: oneSite['SITE_NAME'],
          type: 'SITE',
          disableCheckbox: true
        };
        if (this.utils.Base_HasValue(this.channelListNode)) {
          for (const oneChennel of this.channelListNode) {
            if (oneChennel['siteId'] === oneSite['ID']) {
              if (!node['children']) {
                node['children'] = [];
              }
              node['children'].push(oneChennel);
            }
          }
        }
        copyNode.push(node);
      }
      this.nodes = this.utils.DeepCopy(copyNode);

    }
  }

  ngOnInit() {
    this.userData = _.find(this.parentData.data.data, oneData => {
      return oneData['checked'];
    });
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dr/dr_group_user_group.json'),
      this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_site_channel_add.json'),
      this.requestData.getDataByCondition('digitalResources/dr/dr_group_user.json', {USER_ID: this.userData['USER_ID']}),
    );
    search.subscribe(
      values => {
        this.groupList = values[0]['data'] || [];
        this.channelList = values[1]['data'] || [];
        this.sitelList = values[2]['data'] || [];
        const userGroup = values[3]['data'] || [];
        _.each(userGroup, user => {
          _.each(this.groupList, group => {
            if (group['ID'] === user['GROUP_ID']) {
              group['selected'] = true;
            }
          });
        });
        this.initData();
      }
    );
  }

}
