import {Injectable} from '@angular/core';
import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from '../../service/request-data.service';
import {UserInfoService} from '../../service/user-info.service';
import {UtilsService} from '../../service/utils.service';
import {AppConfigService} from '../../service/app-config.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import * as _ from 'lodash';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';

@Injectable({
  providedIn: 'root'
})
export class VolunteerService {
  public user: any;

  constructor(
    private dataCenterService: DataCenterService,
    private requestData: RequestDataService,
    private userInfoService: UserInfoService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService
  ) {
    this.user = userInfoService.getUserInfo();
  }

  selectMore(pListData, multiple): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  formatSelectEntity(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }

  doAction(pBtn, pParentData) {
    switch (pBtn.ACTION_VALUE) {
      case 'orgApply':
        this.doOrgApply(pParentData);
        break;
      case 'orgUnapply':
        this.doOrgUnapply(pParentData);
        break;
      case 'messageCheck':
        this.doMessageCheck(pParentData);
        break;
      case 'orderExchange':
        this.doOrderExchange(pParentData);
        break;
      case 'giveGoods':
        this.doGiveGoods(pParentData);
        break;
      case 'activityChallenge':
        this.doActivityChallenge(pParentData);
        break;
      case 'activityNegative':
        this.doActivityNegative(pParentData);
        break;
      case 'activityUnApply':
        this.doActivityUnApply(pParentData);
        break;
      case 'activityUserSignin':
        this.doActivityUserSignin(pParentData);
        break;
      case 'activityUserSignout':
        this.doActivityUserSignout(pParentData);
        break;
      case 'activityCheck':
        this.doActivityCheck(pParentData);
        break;
      case 'activityBegin':
        this.doActivityBegin(pParentData);
        break;
      case 'activityPrepare':
        this.doActivityPrepare(pParentData);
        break;
      case 'activityEnd':
        this.doActivityEnd(pParentData);
        break;
      case 'activityApply':
        this.doActivityApply(pParentData);
        break;
      case 'checkVlOrg':
        this.doCheckVlOrg(pParentData);
        break;
      case 'newsCheck':
        this.doNewsCheck(pParentData);
        break;
    }
  }

  doNewsCheck(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '新闻审批',
        nzContent: FormModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'REPLY_STATUS',
                'name': 'REPLY_STATUS_NAME',
                'component': 'select',
                'lable': '是否同意加入',
                'comment': '是否同意加入',
                'placeholder': '请选择是否同意加入',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 24,
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'column': 'REPLY_MESSAGE',
                'name': 'REPLY_MESSAGE_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 24,
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'replyStatus': this.findOneColumnValue(data, 'REPLY_STATUS'),
            'replyMessage': this.findOneColumnValue(data, 'REPLY_MESSAGE')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'doCheckNews', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  findOneColumnValue(pColumns, pColumnName) {
    const oneOneColum = _.find(pColumns, {'column': pColumnName});
    const oneValue = _.get(oneOneColum, 'model');
    return oneValue;
  }


  doOrgApply(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '加入组织审核',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'REPLY_STATUS',
                'name': 'REPLY_STATUS_NAME',
                'component': 'select',
                'lable': '是否同意加入',
                'comment': '是否同意加入',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否同意加入',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'column': 'REPLY_CONTENT',
                'name': 'REPLY_CONTENT_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'replyStatus': this.findOneColumnValue(data, 'REPLY_STATUS'),
            'replyContent': this.findOneColumnValue(data, 'REPLY_CONTENT')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'applyOrgReply', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doOrgUnapply(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '退出组织审核',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'REPLY_STATUS',
                'name': 'REPLY_STATUS_NAME',
                'component': 'select',
                'lable': '是否同意退出',
                'comment': '是否同意退出',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否同意退出',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'column': 'REPLY_CONTENT',
                'name': 'REPLY_CONTENT_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'replyStatus': this.findOneColumnValue(data, 'REPLY_STATUS'),
            'replyContent': this.findOneColumnValue(data, 'REPLY_CONTENT')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'unApplyOrgReply', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doMessageCheck(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '活动留言审核',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 12,
                'column': 'REPLY_STATUS',
                'name': 'REPLY_STATUS_NAME',
                'component': 'select',
                'lable': '是否同意留言',
                'comment': '是否同意留言',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否同意留言',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 12,
                'column': 'REPLY_MESSAGE',
                'name': 'REPLY_MESSAGE_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'replyStatus': this.findOneColumnValue(data, 'REPLY_STATUS'),
            'replyMessage': this.findOneColumnValue(data, 'REPLY_MESSAGE')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'checkActivityMessage', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doOrderExchange(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '兑换的商品核销',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'EXCHANGE_CODE',
                'name': 'EXCHANGE_CODE_NAME',
                'component': 'input',
                'lable': '兑换代码',
                'comment': '兑换代码',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入兑换代码',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'id': _.get(selectList, '0.ID'),
            'exchangeCode': this.findOneColumnValue(data, 'EXCHANGE_CODE'),
            'userId': this.user.USER_ID
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'orderExchange', queryData, null, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doGiveGoods(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '赠与商品',
        nzContent: FormModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ID',
                'name': 'ID_NAME',
                'component': 'poplist',
                'lable': '志愿者姓名',
                'comment': '志愿者姓名',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择志愿者',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'volunteer/basic/volunteer_list_poplist.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    "column": "VL_CODE",
                    "name": "VL_CODE_NAME",
                    "component": "input",
                    "lable": "志愿者编号",
                    "comment": "志愿者编号",
                    "placeholder": "志愿者编号",
                    'fromValue': 'VL_CODE',
                    "insertValue": null,
                    "model": null,
                    "visible": true,
                    "require": true,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'goodsId': _.get(selectList, '0.ID'),
            'vlUserId': this.findOneColumnValue(data, 'ID'),
            'userId': this.user.USER_ID,
            'num': 1
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'giveGoods', queryData, null, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doActivityChallenge(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '处理争议',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ACTIVITY_CHALLENGE_RESULT',
                'name': 'ACTIVITY_CHALLENGE_RESULT_NAME',
                'component': 'select',
                'lable': '是否同意补时',
                'comment': '是否同意补时',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否同意补时',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'column': 'ACTIVITY_REWARD_ADD',
                'name': 'ACTIVITY_REWARD_ADD_NAME',
                'component': 'input',
                'lable': '补时',
                'comment': '补时',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'column': 'ACTIVITY_CHALLENGE_REPLY',
                'name': 'ACTIVITY_CHALLENGE_REPLY_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
          };
          let bodyData = {
            'activityId': _.get(selectList, '0.ACTIVITY_ID'),
            'vlUserId': _.get(selectList, '0.VL_USER_ID'),
            'activityUserId': _.get(selectList, '0.ID'),
            'activityChallengeResult': this.findOneColumnValue(data, 'ACTIVITY_CHALLENGE_RESULT'),
            'activityRewardAdd': this.findOneColumnValue(data, 'ACTIVITY_REWARD_ADD'),
            'activityChallengeReply': this.findOneColumnValue(data, 'ACTIVITY_CHALLENGE_REPLY'),
            'replyUserid': this.user.USER_ID
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'replyChallenge', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }


  }

  doActivityNegative(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '处理黑名单',
        nzContent: FormModalComponent,
        nzWidth: 500,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 24,
                'column': 'NEGATIVE',
                'name': 'NEGATIVE_NAME',
                'component': 'inputMulti',
                'lable': '负面原因',
                'comment': '负面原因',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入负面原因',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'negative': this.findOneColumnValue(data, 'NEGATIVE')
          };
          const bodyData = {
            'ids': this.formatSelectEntity(selectList, 'ID')
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'saveVlActivityNegative', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }

  }

  doActivityUnApply(pParentData) {
    if (this.selectMore(pParentData.data.data, false) !== 1) {
      return false;
    }
    const selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });

    let ids = this.formatSelectEntity(selectList, 'ID');
    const bodyData = {
      'ids': ids
    };
    this.confirmModal.show('confirm', {
      title: '确认退出报名么？',
      suc: () => {
        this.requestService.doActionByHdbsthor('AppApi/BackendController', 'unApplyActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.confirmModal.show('success', {'title': '执行成功'});
            pParentData.needSearch = true;
            const bodyData = {
              'ids': ids
            };
          } else {
            this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
          }
        });
      }
    });
  }

  doActivityUserSignin(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      const bodyData = {
        'ids': ids
      };
      this.confirmModal.show('confirm', {
        title: '确认签到么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'signInActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doActivityUserSignout(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      const bodyData = {
        'ids': ids
      };
      this.confirmModal.show('confirm', {
        title: '确认签到么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'signOutActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doActivityCheck(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      let queryData = {
        'userId': this.user.USER_ID
      };
      const bodyData = {
        'ids': ids
      };
      this.confirmModal.show('confirm', {
        title: '确认审核活动么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'checkActivity', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });

    }
  }

  doActivityBegin(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      const bodyData = {
        'ids': ids
      };

      this.confirmModal.show('confirm', {
        title: '确认开始活动么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'beginActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doActivityPrepare(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      const bodyData = {
        'ids': ids
      };
      this.confirmModal.show('confirm', {
        title: '确认准备结束么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'prepareActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });


    }
  }

  doActivityEnd(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      let ids = this.formatSelectEntity(selectList, 'ID');
      const bodyData = {
        'ids': ids
      };
      this.confirmModal.show('confirm', {
        title: '确认结束活动么？',
        suc: () => {
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'endActivity', null, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doActivityApply(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '选择志愿者加入活动',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'column': 'ID',
                'name': 'ID_NAME',
                'component': 'poplist',
                'lable': '志愿者姓名',
                'comment': '志愿者姓名',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择志愿者',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'volunteer/basic/volunteer_list_poplist.json',
                'dictionaryPF': null,
                'columnGroup': [
                  {
                    "column": "VL_CODE",
                    "name": "VL_CODE_NAME",
                    "component": "input",
                    "lable": "志愿者编号",
                    "comment": "志愿者编号",
                    "placeholder": "志愿者编号",
                    'fromValue': 'VL_CODE',
                    "insertValue": null,
                    "model": null,
                    "visible": true,
                    "require": true,
                    'disable': [
                      'add',
                      'modify'
                    ]
                  }
                ]
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'vlUserId': this.findOneColumnValue(data, 'ID')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'applyActivity', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }

  doCheckVlOrg(pParentData) {
    if (this.selectMore(pParentData.data.data, true) < 1) {
      return false;
    } else {
      const selectList = _.filter(pParentData.data.data, (item) => {
        return item['checked'];
      });
      this.modalService.create({
        nzTitle: '创建组织审核',
        nzContent: FormModalComponent,
        nzWidth: 1000,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          modalFormData: {
            'columns': [
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 12,
                'column': 'REPLY_STATUS',
                'name': 'REPLY_STATUS_NAME',
                'component': 'select',
                'lable': '是否同意加入',
                'comment': '是否同意加入',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请选择是否同意加入',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              },
              {
                'lableSpan': 8,
                'itemSpan': 16,
                'customize': 12,
                'column': 'REPLY_CONTENT',
                'name': 'REPLY_CONTENT_NAME',
                'component': 'inputMulti',
                'lable': '回复内容',
                'comment': '回复内容',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'placeholder': '请输入回复内容',
                'model': null,
                'visible': true,
                'type': 'normal',
                'require': true,
                'dictionary': 'dfmc/basic/dict/YES_OR_NO.json',
                'dictionaryPF': null
              }
            ]
          }
        },
        nzOnOk: (response) => {
          const data = response.modalFormData.columns;
          let requireCheck = true;
          _.each(data, oneColumn => {
            if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
              requireCheck = false;
            }
          });
          if (!requireCheck) {
            this.confirmModal.show('error', {title: '请填写必填信息。'});
            return false;
          }
          let queryData = {
            'userId': this.user.USER_ID,
            'replyStatus': this.findOneColumnValue(data, 'REPLY_STATUS'),
            'replyContent': this.findOneColumnValue(data, 'REPLY_CONTENT')
          };

          let ids = this.formatSelectEntity(selectList, 'ID');
          const bodyData = {
            'ids': ids
          };
          this.requestService.doActionByHdbsthor('AppApi/BackendController', 'checkVlOrg', queryData, bodyData, false, 'hdbsthor_volunteer','appToken').subscribe(response => {
            if (response.toString() === 'true') {
              this.confirmModal.show('success', {'title': '执行成功'});
              pParentData.needSearch = true;
            } else {
              this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
            }
          });
        }
      });
    }
  }
}
