import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {UtilsService} from '../../../service/utils.service';
import Swiper from 'swiper';
import * as screenfull from 'screenfull';
import * as moment from 'moment';
@Component({
  selector: 'app-subject-visual-report',
  templateUrl: './subject-visual-report.component.html',
  styleUrls: ['./subject-visual-report.component.css']
})
export class SubjectVisualReportComponent implements OnInit {

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService) { }
  swiper: any;
  configuration: any;
  isfull: any = false;
  slide01 = true;
  slide02 = true;
  slide03 = true;
  slide04 = true;
  nowDate: any;
  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  ngAfterViewInit () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        loop: false,
        pagination: {
          el: '.swiper-pagination',
        },
        effect: 'cube',
        speed:1000,
        autoplay: this.configuration && this.configuration['SHOW_TIME'] === 0 ? false : {
          delay: this.configuration ? this.configuration['SHOW_TIME'] * 1000 : 54500,
          disableOnInteraction: false,
        }
      });
    }, 1000);
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/ms/ms_subject_visual_report_set.json').subscribe(response => {
      if (this.utils.Base_HasValue(response.data) && response.data.length > 0) {
        this.configuration = response.data[0];
        if (this.configuration['SWIPER01_SHOW'] === '否') {
          this.slide01 = false;
        }
        if (this.configuration['SWIPER02_SHOW'] === '否') {
          this.slide02 = false;
        }
        if (this.configuration['SWIPER03_SHOW'] === '否') {
          this.slide03 = false;
        }
        if (this.configuration['SWIPER04_SHOW'] === '否') {
          this.slide04 = false;
        }
      }
    });
  }

}
