import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {UtilsService} from '../../service/utils.service';
import * as moment from 'moment';
import * as _sh from 'lodash';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  private dateFormat = 'YYYY-MM-DD';

  constructor(private utils: UtilsService) {
  }

  //日期选择框 将时间改为默认值，而不是当前值
  onChange($event,type?){
    if(type){
      this.hulkColumn.modelDate = []
      $event[0]=this.Format(moment($event[0]).set({'hours':0,'minute': 0, 'second': 0}))
      if($event.length>1){
        $event[1]=this.Format(moment($event[1]).set({'hours':23,'minute': 59, 'second': 59}))
      }
    }
    this.hulkColumn.modelDate = $event
  }

  Format(time){//返回指定类型的日期
    return moment(time).format('YYYY-MM-DD HH:mm')
  }

  // 修改model之后格式化为string后赋值model
  changeModel() {
    if (this.bindModel) {
      const date = this.bindModel[this.hulkColumn.column + 'Date'];
      if (this.utils.Base_HasValue(date)) {
        let model = [];
        _sh.each(date, item => {
          model.push(moment(item).format(this.dateFormat));
        });
        this.bindModel[this.hulkColumn.column] = model;
      } else {
        this.bindModel[this.hulkColumn.column] = null;
      }
    } else {
      const date = this.hulkColumn.modelDate;
      if (this.utils.Base_HasValue(date)) {
        let model = [];
        _sh.each(date, item => {
          model.push(moment(item).format(this.dateFormat));
        });
        this.hulkColumn.model = model;
      } else {
        this.hulkColumn.model = null;
      }
    }
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;

    if (this.hulkColumn.component === 'dateTimeRange') {
      this.dateFormat = 'YYYY-MM-DD HH:mm:ss';
    }
    // 加载数据时候讲model数据格式化为date类型
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.modelDate = this.hulkColumn.model;
    } else if (this.hulkColumn.dateOptions) {
      let model = [];
      let dateBegin;
      if (this.hulkColumn.dateOptions['default']['today']) {
        dateBegin = moment().startOf('day');
      } else if (this.utils.Base_HasValue(this.hulkColumn.dateOptions['default']['pastDay'])) {
        let days =  this.hulkColumn.dateOptions['default']['pastDay']
        dateBegin = moment().subtract(days, 'days').startOf('day');
      }
      // modelDate = ["2024-09-01","2024-09-07"]
      model.push(dateBegin.format(this.dateFormat));
      // 只插入开始时间
      // model.push(moment().endOf('day').format(this.dateFormat));
      // this.hulkColumn.model = model;
      this.hulkColumn.modelDate = model;
    }
    // 加载数据时候讲model数据格式化为date类型
    if (this.utils.Base_HasValue(this.bindModel)) {
      this.bindModel[this.hulkColumn.column + 'Date'] = this.bindModel[this.hulkColumn.column];
    }
  }

}
