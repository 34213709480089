import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UploadService} from '../../../service/upload.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {DataService} from '../../digitalResources/data.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-dr-model-detail',
  templateUrl: './dr-model-detail.component.html',
  styleUrls: ['./dr-model-detail.component.css']
})
export class DrModelDetailComponent implements OnInit {

  @Input() parentData: any;

  data: any = {
    modelStateDict: '',
    modelTypeDict: ''
  };

  modelState = []; // 状态

  modelType = []; // 类型

  columnType = {}; // 字段类型
  chooseThumbnail = false;

  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService,
              private fb: FormBuilder) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  ngOnInit() {
    this.modelState = [
      {
        code: 'ENABLE',
        name: '可用'
      },
      {
        code: 'DISABLE',
        name: '禁用'
      }
    ];
    this.columnType = {
      'input': '输入框',
      'textarea': '长文本输入',
      'date': '日期选择',
      'datetime': '时间选择',
      'dateRange': '起止日期选择框',
      'select': '下拉单选框',
      'multiSelect': '下拉多选框',
      'poplist': '单选弹出框',
      'multipop': '多选弹出框'
    };
    this.modelType = [
      {
        code: 'basic',
        name: '基础模型'
      },
      {
        code: 'extend',
        name: '扩展模型'
      }
    ];
    this.dataService.searchModel({modelId: this.parentData.detailCondition.MODEL_ID}).subscribe(response => {
      if (this.utils.Base_HasValue(response.error)) {
        this.confirmModal.show('error', {title: response.error.message});
      } else {
        this.data = response;
        if (this.data.modelType) {
          switch (this.data.modelType) {
            case 'basic':
              this.data.modelTypeDict = '基础模型';
              break;
            case 'extend':
              this.data.modelTypeDict = '扩展模型';
              break;
          }
        }
        if (this.data.modelState) {
          switch (this.data.modelState) {
            case 'ENABLE':
              this.data.modelStateDict = '可用';
              break;
            case 'DISABLE':
              this.data.modelStateDict = '禁用';
              break;
          }
        }
        if (this.data.resourcesType) {
          switch (this.data.resourcesType) {
            case 'PICTURE':
              this.data.resourcesTypeDict = '图片';
              break;
            case 'VIDEO':
              this.data.resourcesTypeDict = '视频';
              break;
            case 'WORD':
              this.data.resourcesTypeDict = '文字';
              break;
            case 'AUDIO':
              this.data.resourcesTypeDict = '音频';
              break;
            case 'GROUP':
              this.data.resourcesTypeDict = '分组资源';
              break;
          }
        }
        _.each(this.data.groups, group => {
          if (group['type'] === 'list') {
            group['add'] = true;
          } else {
            group['add'] = false;
          }
          group['isThumbnail'] = group['isThumbnail'] === 1 ? 'isThumbnail' : '';
          if (group['isThumbnail'] === 'isThumbnail') {
            this.chooseThumbnail = true;
          }
          const current = _.find(group['columns'], column => column['column'] === 'keywordsName' || column['column'] === 'subjectName' || column['column'] === 'datasetDesc');
          if (current) {
            group['isDefault'] = true;
          }
        });
      }
    });
  }
}
