import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {RequestDataService} from './../../service/request-data.service';
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";

@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.css']
})
export class SelectMultiComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  constructor(
    private utils: UtilsService,
    private requestDataService: RequestDataService) {
  }


  modelChange(model) {
    let arr01 = _sh.split(model,[',']);
    arr01 = this.utils.Base_ArrayDelEmptyAndUniq(arr01);

    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = arr01;
    } else {
      this.hulkColumn.model = arr01;
    }
  }


  ngOnInit() {
    this.hulkColumn.nzMode = this.hulkColumn.nzMode || 'multiple';
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    this.hulkColumn.limitLength = this.hulkColumn.limitLength || 99999999;
    if (!this.hulkColumn.itemArray && this.hulkColumn.dictionary) {
      this.requestDataService.getDataByCondition(this.hulkColumn.dictionary).subscribe(result => {
        this.hulkColumn.itemArray = result['data'] || [];
      });
    }
    if (this.bindModel && this.bindModel[this.hulkColumn.column] === '') {
      this.bindModel[this.hulkColumn.column] = null;
    }
  }

}
