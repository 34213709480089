import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {IndexComponent} from './components/index/index.component';
import {CheckLoginGuard} from './guard/check-login.guard';
import {HulkConfigDetailComponent} from './hulkConifg/hulk-config-detail/hulk-config-detail.component';
import {HulkConfigListSqlfileComponent} from './hulkConifg/hulk-config-list-sqlfile/hulk-config-list-sqlfile.component';
import {HulkConfigDesignListComponent} from './hulkConifg/hulk-config-design-list/hulk-config-design-list.component';
import {
  HulkConfigDesignReadonlyComponent
} from './hulkConifg/hulk-config-design-readonly/hulk-config-design-readonly.component';
import {HulkConfigDataBuildComponent} from "./hulkConifg/hulk-config-data-build/hulk-config-data-build.component";
import {HulkConfigTableDesignComponent} from "./hulkConifg/hulk-config-table-design/hulk-config-table-design.component";
import {HulkThanosComponent} from "./hulkConifg/hulk-thanos/hulk-thanos.component";
import {HulkConfigSdserverComponent} from "./hulkConifg/hulk-config-sdserver/hulk-config-sdserver.component";
import {HulkConfigSdClientComponent} from "./hulkConifg/hulk-config-sd-client/hulk-config-sd-client.component";

const routes: Routes = [
  {
    path: 'index',
    component: IndexComponent,
    canActivate: [CheckLoginGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'HulkConfigDetail',
    component: HulkConfigDetailComponent
  },
  {
    path: 'HulkConfigDesignReadOnly',
    component: HulkConfigDesignReadonlyComponent
  },
  {
    path: 'HulkConfigDataBuild',
    component: HulkConfigDataBuildComponent
  },
  {
    path: 'HulkConfigDesignList',
    component: HulkConfigDesignListComponent
  },
  {
    path: 'HulkConfig',
    component: HulkConfigListSqlfileComponent
  },
  {
    path: 'HulkConfigTableDesign',
    component: HulkConfigTableDesignComponent
  },
  {
    path: 'Thanos',
    component: HulkThanosComponent
  },
  {
    path: 'stableDiffusionServer',
    component: HulkConfigSdserverComponent
  },
  {
    path: 'stableDiffusionClient',
    component: HulkConfigSdClientComponent
  },
  {
    path: '',
    redirectTo: '/index',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/index'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
