
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import {UploadXHRArgs, UploadFile} from 'ng-zorro-antd';
import {NzModalService} from 'ng-zorro-antd';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UploadService} from './../../../service/upload.service';
import {UtilsService} from './../../../service/utils.service';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import {AppConfigService} from './../../../service/app-config.service';
import {DataService} from './../data.service';
import {ViewModelComponent} from '../view-model/view-model.component';
import {ModifyColumnComponent} from '../modify-column/modify-column.component';

import * as _ from 'underscore';

@Component({
  selector: 'app-dataverse-insert',
  templateUrl: './dataverse-insert.component.html',
  styleUrls: ['./dataverse-insert.component.css']
})
export class DataverseInsertComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;
  public modeType: any = 'add';
  public isRoot: any = false;

  public _validateForm: FormGroup;
  public spaceForm: FormGroup;

  // 空间类型字典
  public typeArray: any = [];
  public dataverse: any = {
    DATAVERSE_NAME: '',
    DATAVERSE_TYPE: null,
    CONTRACT: '',
    EMAIL: '',
    DATAVERSE_DESC: '',
    modelFiled: 'all',
    DATAVERSE_LOGO: '',
    DATAVERSE_WORDS: '',
    DATAVERSE_WEBSIT: '',
    SORT: null,
    logoList: []
  };
  // 元数据配置
  public model: any = {
    allModel: [], // 所有模型数据
    parentList: [], // 父级模型数据
    modelList: [], // 页面显示模型数据
    dataList: [], // 已绑定模型数据
    useParent: false,
    result: []
  };
  // 搜索配置
  public field: any = {
    useParent: false,
    fieldList: [], // 元数据列表用于选择
    columnList: []
  };

  // 精品空间设置
  public space: any = {
    spaceList: [],
    boutiqueList: []
  };


  public previewVisible = false;
  public previewImage = '';

  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 上传图片回调
  customReq = (item: UploadXHRArgs) => {
    if (this.config.config['userOssOrCos'] === 'COS') {
      return this.uploadService.doCosUpload(item.file, item['progress'], null, null, null).then((result) => {
        this.dataverse['DATAVERSE_LOGO'] = this.config.config['COS']['domain'] + '/' + result['url'];
        item.onSuccess({}, item.file, {});
      });
    } else {
      return this.uploadService.doAliOssUpload(item.file, item['progress'], null).then((result) => {
        this.dataverse['DATAVERSE_LOGO'] = this.config.config['OSS']['domain'] + '/' + result['name'];
        item.onSuccess({}, item.file, {});
      });
    }
  };

  filterOption(inputValue: string, item: any): boolean {
    return item.title.indexOf(inputValue) > -1 || item.MODEL_NAME.indexOf(inputValue) > -1;
  }

  // 图片预览
  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
    return false;
  };

  selectModel(model) {
    // 分组是否展示根据当前model是否选中
    _.each(model['groups'], group => {
      group['visible'] = model['visible'];
    });
  }

  // 更新最新配置
  refreshModel() {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要更新到最新元数据配置吗？',
      suc: () => {
        this.model.modelList = this.utils.DeepCopy(this.model.allModel);
        _.each(this.model.modelList, model => {
          // 如果是基础模型。则默认全部选中
          if (model['modelType'] === 'basic') {
            model['visible'] = true;
            _.each(model['groups'], group => {
              group['visible'] = true;
            });
          }
        });
      }
    });
  }

  // 元数据配置使用父级
  setModel() {
    if (this.model.useParent) {
      this.model.modelList = this.utils.DeepCopy(this.model.parentList);
    } else {
      this.model.modelList = this.utils.DeepCopy(this.model.allModel);
      _.each(this.model.modelList, model => {
        // 如果是基础模型。则默认全部选中
        if (model['modelType'] === 'basic') {
          model['visible'] = true;
          _.each(model['groups'], group => {
            group['visible'] = true;
          });
        }
      });
    }
  }

  // 搜索配置选择使用父级
  selectField(model) {
    if (this.field.useParent) {
      this.field.columnList = [];
      const fieldList = this.utils.DeepCopy(this.field.fieldList);
      _.each(fieldList, field => {
        field['direction'] = '';
        field['disabled'] = true;
        if (field['linkParent']) {
          field['direction'] = 'right';
          this.field.columnList.push(field);
        }
      });
      this.field.fieldList = fieldList;
    } else {
      _.each(this.field.fieldList, field => {
        field['disabled'] = false;
      });
    }
  }

  removeLogo = (file: UploadFile) => {
    this.dataverse.logoList = [];
    this.dataverse['DATAVERSE_LOGO'] = null;
  };

  change(event) {
    if (event.from === 'left' && event.to === 'right') {
      this.field.columnList = this.field.columnList.concat(event.list);
    } else {
      let ids = [];
      _.each(event.list, item => {
        ids.push(item['ID']);
      });
      this.field.columnList = _.filter(this.field.columnList, item => !_.contains(ids, item['ID']));
    }
  }

  spaceChange(event) {
    if (event.from === 'left' && event.to === 'right') {
      this.space.boutiqueList = this.space.boutiqueList.concat(event.list);
    } else {
      let ids = [];
      _.each(event.list, item => {
        ids.push(item['ID']);
      });
      this.space.boutiqueList = _.filter(this.space.boutiqueList, item => !_.contains(ids, item['ID']));
    }
  }

  // 元数据配置查看元数据字段 设置隐藏展示可选必填
  operateModel(model) {
    this.modalService.create({
      nzTitle: model.modelName,
      nzContent: ViewModelComponent,
      nzWidth: 1200,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modelData: this.utils.DeepCopy(model)
      },
      nzCancelText: null,
      nzOnOk: () => {
        return true;
      }
    });
  }

  // 编辑字段
  editModel(model) {
    this.modalService.create({
      nzTitle: model.modelName,
      nzContent: ModifyColumnComponent,
      nzWidth: 1200,
      nzWrapClassName: 'vertical-center-modal',
      nzMaskClosable: false,
      nzComponentParams: {
        modelData: this.utils.DeepCopy(model)
      },
      nzOnOk: (response) => {
        const modelList = response.modelData || {};
        if (!response.selectedGroup.length) {
          this.confirmModal.show('warning', {
            title: '温馨提示',
            content: '请至少添加一条元数据字段'
          });
          return false;
        }
        let flag = true;
        _.each(modelList['groups'], group => {
          if (group['isAdd']) {
            if (!this.utils.Base_HasValue(group['groupName'])) {
              flag = false;
              group['hasError'] = true;
            } else {
              group['hasError'] = false;
              _.each(group['columns'], column => {
                const check = this.utils.Base_HasValue(column['columnName']);
                flag = flag && check;
                column['hasError'] = !check;
              });
            }
          }
        });
        if (flag) {
          const index = _.findIndex(this.model.modelList, m => m['modelId'] === modelList['modelId']);
          this.model.modelList[index] = modelList;
          return true;
        } else {
          return false;
        }
      }
    });
  }

  // 数据集名称字段唯一性校验
  uniqueCheck() {
    return (control: FormControl) => {
      const q = new Promise((resolve, reject) => {
        if (control.dirty && this.utils.Base_HasValue(control.value)) {
          let data = {
            DATAVERSE_NAME: control.value
          };
          let conditons = this.parentData.detailCondition || {};
          data = Object.assign(conditons, data);
          this.requestData.getDataByCondition('headLine/ds/ds_dataverse_count.json', data).subscribe((result) => {
            if (result.data[0]['account'] > 0) {
              resolve({'uniqueCheck': true});
            } else {
              resolve(null);
            }
          }, () => {
            reject({error: true, uniqueCheck: true});
          });
        } else {
          resolve(null);
        }

      });
      return q;
    };
  }

  // 封装parentData数据用于返回列表
  transformData(pFormType, parentData) {
    // 重新覆盖 datacenter 数据
    delete parentData.DATAVERSE_UP_ID;
    delete parentData.DATAVERSE_UP_NAME;
    parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 保存
  saveOrModify() {
    if (this._validateForm.status === 'PENDING') {
      return;
    }
    if (this._validateForm.invalid) {
      for (const key in this._validateForm.controls) {
        this._validateForm.controls[key].markAsDirty();
        this._validateForm.controls[key].updateValueAndValidity();
      }
      for (const key in this.spaceForm.controls) {
        this.spaceForm.controls[key].markAsDirty();
        this.spaceForm.controls[key].updateValueAndValidity();
      }
    }
    if (this._validateForm.valid && this.spaceForm.valid) {
      const visibleModel = _.filter(this.model.modelList, v => v['visible']);
      if (!visibleModel.length) {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '请至少选择一条元数据配置'
        });
        return;
      }
      if (!this.field.columnList.length) {
        this.confirmModal.show('warning', {
          title: '温馨提示',
          content: '请至少选择一条浏览或搜索设置'
        });
        return;
      }
      let model = {
        DATAVERSE_NAME: this.dataverse['DATAVERSE_NAME'],
        DATAVERSE_TYPE: this.dataverse['DATAVERSE_TYPE'],
        DATAVERSE_STATE: this.dataverse['DATAVERSE_STATE'] || 'OUTSALE',
        CONTRACT: this.dataverse['CONTRACT'],
        EMAIL: this.dataverse['EMAIL'],
        DATAVERSE_DESC: this.dataverse['DATAVERSE_DESC'],
        DATAVERSE_LOGO: this.dataverse['DATAVERSE_LOGO'],
        DATAVERSE_WORDS: this.dataverse['DATAVERSE_WORDS'],
        DATAVERSE_WEBSIT: this.dataverse['DATAVERSE_WEBSIT'],
        SORT: this.dataverse['SORT']
      };
      if (this.modeType === 'modify') {
        model['ID'] = this.dataverse['ID'];
      }
      if (!this.isRoot) {
        model['DATAVERSE_UP_ID'] = this.dataverse['DATAVERSE_UP_ID'];
        model['DATAVERSE_UP_NAME'] = this.dataverse['DATAVERSE_UP_NAME'];
      }
      // 元数据配置数据
      // 搜索项配置数据
      const data = {
        ds_dataverse: model,
        model: {
          useParent: !!this.model.useParent,
          modelVersion: this.model.modelVersion,
          modelList: JSON.stringify(this.utils.DeepCopy(this.model.modelList))
        },
        field: {
          useParent: !!this.field.useParent,
          fieldList: JSON.stringify(this.field.columnList)
        },
        boutiqueList: this.space.boutiqueList
      };
      // return;
      this.dataService.insertDataverse(data).subscribe(result => {
        if (result.state === 'success') {
          this.confirmModal.show('success', {
            title: '温馨提示',
            content: '保存成功',
            suc: () => {
              this.transformData('listForm', this.parentData);
            }
          });
        }
      });

    }
  }

  // 新增子空间封装数据
  transformInsertData(data) {
    this.model.useParent = true;
    this.model.allModel = data['all_enable_model'] || [];
    this.model.parentList = data['parent_dataverse_model'] || [];
    this.model.modelList = this.utils.DeepCopy(this.model.parentList);
    this.model.useParent = true;
    const fieldList = data['all_enable_column'] || [];
    const dataverseColumn = data['parent_dataverse_column'] || [];
    _.each(fieldList, field => {
      field['key'] = field['ID'];
      field['title'] = field['COLUMN_NAME'];
      field['disabled'] = true;
      const current = _.find(dataverseColumn, item => item['BM_ID'] === field['BM_ID'] && item['COLUMN_EN'] === field['COLUMN_EN']);
      if (current) {
        field['linkParent'] = true;
        field['direction'] = 'right';
        this.field.columnList.push(field);
      }
    });
    this.field.useParent = true;
    this.field.fieldList = fieldList;
  }

  // 修改时候会写数据
  transformModifyData(data) {
    this.model.allModel = data['all_enable_model'] || [];
    this.model.dataList = data['dataverse_model'] || [];
    // 如果是修改。新添加的模型要添加到绑定的数据里面
    _.each(this.model.allModel, model => {
      const current = _.find(this.model.dataList, bind => bind['modelEnName'] === model['modelEnName']);
      if (!current) {
        this.model.dataList.push(model);
      }
    });
    this.model.parentList = data['parent_dataverse_model'] || [];
    this.model.modelList = this.utils.DeepCopy(this.model.dataList);
    this.model.modelVersion = this.model.modelList[0]['modelV'];
    this.model.useParent = this.model.modelList[0]['useParent'] === 'true' || this.model.modelList[0]['useParent'] === true || this.model.modelList[0]['useParent'] === 'true';
    if (this.model.useParent) {
      this.model.modelList = this.utils.DeepCopy(this.model.parentList);
    }
    // 有可能存在新添加了基础模型。修改根空间的时候需要将基础模型都选中。。
    _.each(this.model.modelList, model => {
      if (model['modelType'] === 'basic') {
        model['visible'] = true;
        _.each(model['groups'], group => {
          group['visible'] = true;
        });
      }
    });
    const fieldList = data['all_enable_column'] || [];
    const dataverseColumn = data['dataverse_column'] || [];
    const parentDataverseColumn = data['parent_dataverse_column'] || [];
    this.field.useParent = dataverseColumn[0]['USE_PARENT'] === 'true';
    _.each(fieldList, field => {
      field['key'] = field['ID'];
      field['title'] = field['COLUMN_NAME'];
      field['disabled'] = this.field.useParent;
      const parent = _.find(parentDataverseColumn, item => item['BM_ID'] === field['BM_ID'] && item['COLUMN_EN'] === field['COLUMN_EN']);
      if (parent) {
        field['linkParent'] = true;
      }
      const current = _.find(dataverseColumn, item => item['BM_ID'] === field['BM_ID'] && item['COLUMN_EN'] === field['COLUMN_EN']);
      if (current) {
        field['direction'] = 'right';
        this.field.columnList.push(field);
      }
    });
    this.field.fieldList = fieldList;

    const spaceList = data['dataverse_son_all'] || [];
    const boutiqueList = data['dataverse_boutique'] || [];
    _.each(spaceList, space => {
      space['key'] = space['ID'];
      space['title'] = space['DATAVERSE_NAME'];
      const current = _.find(boutiqueList, item => item['DATAVERSE_ID'] === space['ID']);
      if (current) {
        space['direction'] = 'right';
        this.space.boutiqueList.push(space);
      }
    });
    this.space.spaceList = spaceList;
  }

  ngOnInit() {
    this.modeType = this.parentData.modeType;
    this.isRoot = this.parentData.isRoot;
    let condition = {};
    if (!this.isRoot) {
      this.dataverse['DATAVERSE_UP_ID'] = this.parentData.DATAVERSE_UP_ID;
      this.dataverse['DATAVERSE_UP_NAME'] = this.parentData.DATAVERSE_UP_NAME;
      condition['dataverseUpId'] = this.dataverse['DATAVERSE_UP_ID'];
    }
    if (this.modeType === 'add') {
      const search = observableForkJoin(
        this.requestData.getDataByCondition('headLine/basic/dict/DATAVERSE_TYPE.json'),
        this.dataService.searchDataverse(condition)
      );
      search.subscribe(
        values => {
          this.typeArray = values[0]['data'] || [];
          if (this.isRoot) {
            this.model.modelList = values[1]['all_enable_model'] || [];
            _.each(this.model.modelList, model => {
              model['visible'] = model['modelType'] === 'basic';
              _.each(model['groups'], group => {
                group['visible'] = model['visible'];
              });
            });
            const fieldList = values[1]['all_enable_column'] || [];
            _.each(fieldList, field => {
              field['key'] = field['ID'];
              field['title'] = field['COLUMN_NAME'];
            });
            this.field.fieldList = fieldList;
          } else {
            this.transformInsertData(values[1]);
          }
        });
    } else {
      const id = this.parentData['detailCondition']['DATAVERSE_ID'];
      condition['dataverseId'] = id;
      const search = observableForkJoin(
        this.requestData.getDataByCondition('headLine/basic/dict/DATAVERSE_TYPE.json'),
        this.dataService.searchDataverse(condition)
      );
      search.subscribe(
        values => {
          this.typeArray = values[0]['data'] || [];
          const data = values[1] || {};
          this.dataverse = Object.assign(this.dataverse, data['ds_dataverse'][0]);
          if (this.utils.Base_HasValue(this.dataverse.DATAVERSE_LOGO)) {
            this.dataverse.logoList = [{
              url: this.dataverse.DATAVERSE_LOGO
            }];
          }
          this.transformModifyData(data);
        });
    }

    this._validateForm = this.formBuilder.group({
      dataverseName: new FormControl('datasetName', {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: [this.uniqueCheck().bind(this)],
        updateOn: 'blur'
      }),
      dataverseType: [null, [Validators.required]],
      contract: [null, [Validators.maxLength(20)]],
      email: [null, [Validators.required, Validators.email, Validators.maxLength(50)]],
      dataverseDesc: [null, [Validators.required, Validators.maxLength(500)]],
      sort: [null, [Validators.required, Validators.maxLength(10)]]
    });

    this.spaceForm = this.formBuilder.group({
      words: [null, [Validators.maxLength(50)]],
      website: [null, [Validators.maxLength(50)]]
    });
  }

}
