import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {ListActionService} from '../../../service/list-action.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as moment from 'moment';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-student-achievement',
  templateUrl: './student-achievement.component.html',
  styleUrls: ['../report-teacher/report-teacher.component.css', './student-achievement.component.css']
})
export class StudentAchievementComponent implements OnInit {
  paper: any = {
    DOCTOR: 0,
    MASTER: 0
  };
  patent: any = {
    DOCTOR: 0,
    MASTER: 0
  };
  entry: any = {
    DOCTOR: 0,
    MASTER: 0
  };
  scientific: any = {
    DOCTOR: 0,
    MASTER: 0
  };
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1 = [
      {
        '学生层次': '博士研究生',
        '发表论文篇数': this.paper['DOCTOR'] || 0,
        '已获授权专利数': this.patent['DOCTOR'] || 0,
        '创新创业获奖数': this.entry['DOCTOR'] || 0,
        '行业竞赛获奖数': this.entry['DOCTOR1'] || 0,
        '科研成果获奖数': this.scientific['DOCTOR'] || 0
      },
      {
        '学生层次': '硕士研究生',
        '发表论文篇数': this.paper['MASTER'] || 0,
        '已获授权专利数': this.patent['MASTER'] || 0,
        '创新创业获奖数': this.entry['MASTER'] || 0,
        '行业竞赛获奖数': this.entry['MASTER1'] || 0,
        '科研成果获奖数': this.scientific['MASTER'] || 0
      }
    ];
    const opts = [
      {
        sheetid: this.evaluationYear - 4 + '~' + this.evaluationYear + '年在校生取得成果',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-3 近5年在校生取得成果.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/paper_student_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/patent_student_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/entry_student_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0}),
      this.requestDataService.getDataByCondition('subjectInfo/report/scientific_student_count.json', {EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0})
    );
    parallel.subscribe(response => {
      this.paper = response[0]['data'][0];
      this.patent = response[1]['data'][0];
      this.entry = response[2]['data'][0];
      this.scientific = response[3]['data'][0];
    });
  }

}
