import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hulk-config-textarea',
  templateUrl: './hulk-config-textarea.component.html',
  styleUrls: ['./hulk-config-textarea.component.scss']
})
export class HulkConfigTextareaComponent implements OnInit {

  @Input() oneColumn:any;
  @Input() attributes: any;
  @Input() label: any;
  @Input() row = 4;

  constructor() {
  }

  ngOnInit() {
  }

  removeValue() {
    this.oneColumn[this.attributes] = null;
  }

}
