import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {DataCenterService} from './data-center.service';
import {SettingColumnService} from './setting-column.service';
import {ConfirmModalService} from './confirm-modal.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  public userInfo:any = {};

  constructor(private router: Router,
    private storage: StorageService,
    private dataCenterService: DataCenterService,
    private settingService: SettingColumnService,
    private confirmModal: ConfirmModalService) {
  }

  getUserInfo() {
    return this.storage.getData('userInfo');
  }

  setUserInfo(value) {
    this.userInfo = value;
    this.storage.setData('userInfo', this.userInfo);
  }

  gotoLogin() {
    this.storage.removeData("userInfo");
    this.dataCenterService.clearData();
    this.router.navigate(['/login']);
  }

  clearAll() {
    this.storage.removeData("userInfo");
    this.dataCenterService.clearData();
    this.settingService.clearSettingsInfoStorage();
    this.router.navigate(['/login']);
  }

  logout() {
    this.confirmModal.show('confirm', {
      title: '您确认要退出系统吗？',
      content: '',
      suc: ()=>{
        this.gotoLogin();
      }
    })
  }
}
