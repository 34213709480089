import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-hulk-config-poplist-insertvalue',
    templateUrl: './hulk-config-poplist-insertvalue.component.html',
    styleUrls: ['./hulk-config-poplist-insertvalue.component.scss']
})
export class HulkConfigPoplistInsertvalueComponent implements OnInit {

    @Input() oneColumn: any;

    constructor() {
    }

    // 组件
    componentModel = [
        {
            'code': 'SYS-DATETIME',
            'name': '时间'
        },
        {
            'code': 'SYS-NULL',
            'name': '空值'
        },
        {
            'code': 'SYS-UUID',
            'name': '子表主健'
        },
        {
            'code': 'SYS-LIST-UUID',
            'name': '主表主健'
        },
        {
            'code': 'SYS-ACTION-UUID',
            'name': '事件主健'
        },
        {
            'code': 'SYS_EMP_ID_IPT',
            'name': '员工id'
        },
        {
            'code': 'SYS_EMP_ID',
            'name': '员工工号'
        },
        {
            'code': 'SYS_USER_ID',
            'name': '登录，操作人员的id'
        },
        {
            'code': 'SYS_USER_NAME',
            'name': '登录，操作人员的姓名'
        },
        {
            'code': 'SYS-NO-END',
            'name': '流水单号'
        }
    ];

    public isVisible = false;


    // 弹出框
    showModal() {
        this.isVisible = true;
    }

    // 单选框 点击行事件
    selectData(data) {
        this.oneColumn['insertValue'] = data['code'];
        this.isVisible = false;
    }


    //清空poplist值
    removeValue(): void {
        this.oneColumn['insertValue'] = "input"
    }

    ngOnInit() {
    }

    closeModal() {
        this.isVisible = false;
    }
}
