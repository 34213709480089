import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import * as _sh from 'lodash';
import {UploadService} from '../../service/upload.service';
import {RequestDataService} from '../../service/request-data.service';
import {AjaxService} from '../../service/ajax.service';

@Component({
  selector: 'app-tinymce',
  templateUrl: './tinymce.component.html',
  styleUrls: ['./tinymce.component.css']
})
export class TinymceComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;
  @Input() forms: any;
  @Input() oneForms: any;

  apiKey: 'tz9q2op8zdy73lw8clfcn70muzvy4o4wk28c8na72bjv6ma8';
  initConfig: any;

  constructor(
    private uploadService: UploadService,
    private config: AppConfigService,
    private modalService: NzModalService,
    private requestService: RequestDataService,
    private ajaxService: AjaxService,
    private util: UtilsService
  ) {
    this.initConfig = {
      base_url: '/tinymce',
      language: 'zh_CN',
      language_url: '/assets/tinymce/langs/zh_CN.js',
      height: 500,
      font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;楷体=KaiTi,sans-serif;华文隶书=STLiti,sans-serif;方正小标宋简体=FZXBSJW—GB1-07156,sans-serif;Arial=arial,helvetica,sans-serif;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;',
      plugins: [
        'advlist autolink image imagetools lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'table emoticons template paste help axupimgs bdmap importword fullscreen'
      ],
      toolbar: [
        'fullscreen undo redo | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify',
        'selectLineHeight indentFirstLine indentAllFirstLines | bullist numlist outdent indent | image axupimgs | print preview media fullpage | insertdatetime link unlink table media',
        'forecolor backcolor emoticons  bdmap importword code help',
      ],
      fontsize_formats: '5号=10.5pt 8pt 10pt 12pt 13pt 14pt 18pt 24pt 36pt', // 字体大小
      setup: function(editor) {
        editor.ui.registry.addButton('indentFirstLine', {
          text: '首行缩进',
          onAction: function(_) {
            let selectedText = editor.selection.getContent();
            let newNode = editor.dom.create('p', {style: 'text-indent:2em;'}, selectedText);
            editor.selection.setNode(newNode);
          }
        });

        // 添加首行缩进按钮
        editor.ui.registry.addButton('indentAllFirstLines', {
          text: '全文首行缩进',
          onAction: function(_) {
            editor.undoManager.transact(function() {
              editor.getBody().querySelectorAll('p').forEach(function(p) {
                editor.dom.setStyle(p, 'text-indent', '2em');
              });
            });
          }
        });

        editor.ui.registry.addSplitButton('selectLineHeight', {
          text: '选择行间距',
          onAction: function() {
            // 默认的操作
          },
          onItemAction: function(_, value) {
            let selectedText = editor.selection.getContent();
            let newNode;
            if (value === '18pt') {
              newNode = editor.dom.create('p', {style: 'line-height:18pt;'}, selectedText);  // 18磅等于18pt
            } else {
              newNode = editor.dom.create('p', {style: 'line-height:' + value + 'em;'}, selectedText);
            }
            editor.selection.setNode(newNode);
          },
          fetch: function(callback) {
            var items = [
              { type: 'choiceitem', text: '默认', value: '1' },
              { type: 'choiceitem', text: '1.5 倍', value: '1.5' },
              { type: 'choiceitem', text: '2 倍', value: '2' },
              { type: 'choiceitem', text: '3 倍', value: '3' },
              { type: 'choiceitem', text: '4 倍', value: '4' },
              { type: 'choiceitem', text: '18 磅', value: '18pt' }  // 添加18磅的选项
            ];
            callback(items);
          }
        });

      },
      images_upload_handler: (blobInfo, success, failure, progress) => {
        this.doUploadFile(blobInfo, success, failure, progress);
      },
      importword_handler: (editor, files, next) => {
        this.changeWord(editor, files, next);
      },
      importword_filter: function (result, insert, message) {
        // 自定义操作部分
        insert(result); //回插函数
      }
    };

  }

  changeWord(editor, files, next) {
    var file_name = files[0].name;
    if (file_name.substr(file_name.lastIndexOf('.') + 1) == 'docx') {
      // todo 可以加一个转化完成的提示。
      // editor.notificationManager.open({
      //   text: '正在转换中...',
      //   type: 'info',
      //   closeButton: false,
      // });
      next(files);
    } else {
      editor.notificationManager.open({
        text: '目前仅支持docx文件格式，若为doc111，请将扩展名改为docx',
        type: 'warning',
      });
    }
    // next(files);
  }

  isBlob(blob) {
    return typeof Blob !== 'undefined' && blob instanceof Blob;
  }

  isFile(obj) {
    return typeof File !== 'undefined' && obj instanceof File;
  };

  doUploadFile(blobInfo, success, failure, progress) {
    let file = blobInfo.blob();//转化为易于理解的file对象
    if (this.isFile(file)) {
      this.uploadFiletoLocal(file, success, failure, progress);
    } else {
      this.uploadFiletoServer(file, success);
    }
  }

  // 本地浏览器，直接传输到阿里云服务器。(或者腾讯云服务器）
  uploadFiletoLocal(file, success, failure, progress) {
    let fileName = this.util.Base_getFileName(file.name);
    let fileType = this.util.Base_getFileType(file.name);
    this.uploadService.doUpLoadSimple(file, fileName, fileType, (progressInfo) => {
      let percent = (progressInfo.loaded / progressInfo.total * 100).toFixed(0);
      if (this.util.Base_HasValue(progress)) {
        progress(percent);
      }
    }).then((result) => {
      let url = '';
      if (this.config.config['userOssOrCos'] === 'COS') {
        url = this.config.config['COS']['domain'] + '/' + result['url'];
      } else if (this.config.config['userOssOrCos'] === 'OSS') {
        url = this.config.config['OSS']['domain'] + '/' + result['name'];
      }
      success(url);
    }).then((result) => {
      failure(result);
    });
  }

  uploadFiletoServer(file, success) {
    this.uploadService.uploadFileCloud(file).subscribe(response => {
      if (this.util.Base_HasValue(response) && response.type === 4) {
        let url = _sh.get(response, ['body', 'url']);
        success(url);
      }
    });
  }

  ngOnInit() {

  }

}
