import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, UploadChangeParam, UploadFile, UploadXHRArgs} from 'ng-zorro-antd';
import {UploadService} from '../../../../service/upload.service';
import {UtilsService} from '../../../../service/utils.service';
import {ConfirmModalService} from '../../../../service/confirm-modal.service';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {DataService} from '../../data.service';
import {AppConfigService} from '../../../../service/app-config.service';
import * as _ from 'underscore';
import {map} from 'rxjs/operators';
import {forkJoin, Subscription} from 'rxjs';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-file-upload-digital',
  templateUrl: './file-upload-digital.component.html',
  styleUrls: ['./file-upload-digital.component.css']
})
export class FileUploadDigitalComponent implements OnInit {


  @Input() dataObject: any;
  fileList: UploadFile[] = [];
  // 上传进度
  progress = 0;
  uploadFileList = [];
  isError = false;

  constructor(private msg: NzMessageService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private httpClient: HttpClient,
              private data: DataService,
              private config: AppConfigService) {
  }

  beforeUpload = (file: UploadFile) => {
    this.fileList = this.fileList.concat(file);
    if (!this.isError) {
      this.uploadFileList = [];
      this.fileList.forEach((fileData: any) => {
        this.uploadFileList.push({
          file: fileData,
          fragment: 1048576,
          fragmentSum: [],
          fragmentUploadedSum: [],
          progress: 0,
          fragmentSumAll: [],
          fragmentList: [],
          state: 'uploading',
          isDelete: false
        });
      });
    }
    return false;
  };

  /**
   * 计算文件分片数
   */
  calculateFragment(pOneFile) {
    for (let i = 0; i < Math.ceil(pOneFile.file.size / pOneFile.fragment); i++) {
      pOneFile.fragmentSum.push(i);
      pOneFile.fragmentSumAll.push(i);
    }
  }

  /**
   * 开始上传
   */
  handleUpload() {
    this.dataObject.loading = true;
    if (!this.isError) {
      for (const oneFile of this.uploadFileList) {
        if (oneFile.state === 'uploading' || oneFile.state === 'error') {
          // 计算分片
          this.calculateFragment(oneFile);
          this.uploadDo(oneFile, oneFile.file);
        }
      }
    } else {
      this.isError = false;
      for (const oneFile of this.uploadFileList) {
        if (oneFile.state === 'error') {
          oneFile.state = 'uploading';
        }
        if (oneFile.state === 'uploading') {
          this.uploadDo(oneFile, oneFile.file);
        }
      }
    }
  }

  /**
   * 单个文件上传
   */
  uploadDo(oneFile, file: any) {
    if (oneFile.isDelete) {
      return false;
    }
    const frag = oneFile.fragmentSum.shift();
    // 当前分片开始下标
    const start = frag * oneFile.fragment;
    // 当前分片结束下标
    const end = Math.min(oneFile.file.size, start + oneFile.fragment);
    const fileType = this.utils.Base_getFileType(file.name);
    const fileName = Md5.hashStr(file.name + frag) + '.' + fileType;
    // 校验文件是否已存在
    this.checkFileExit(fileName, frag).subscribe(repsonse => {
      if (repsonse.state === 'exit') {
        oneFile.fragmentUploadedSum.push(frag);
        this.uploadSuccess(oneFile, repsonse.url);
      } else if (repsonse.state === 'not-exit') {
        const formData = new FormData();
        formData.append('file', file.slice(start, end), fileName);
        const param = '?start=' + start + '&end=' + end + '&frag=' + frag;
        const req = new HttpRequest('POST', this.config.config.quickSliver + '/digital/uploadFile' + param, formData);
        this.httpClient.request(req).subscribe((rep) => {
            if (this.utils.Base_HasValue(rep) && rep.type === 4) {
              oneFile.fragmentUploadedSum.push(frag);
              this.uploadSuccess(oneFile, rep['body'].url);
            }
          },
          (error) => {
            this.uploadError(oneFile, frag);
            this.confirmModal.show('error', {
              title: '文件上传失败,请重新上传'
            });
          }
        );
      } else {
        this.uploadError(oneFile, frag);
      }
    }, (error) => {
      this.uploadError(oneFile, frag);
    });
  }

  /**
   * 上传失败
   */
  uploadError(oneFile, frag) {
    this.isError = true;
    this.dataObject.loading = false;
    oneFile.state = 'error';
    oneFile.fragmentSum.unshift(frag);
  }

  /**
   * 上传成功
   */
  uploadSuccess(oneFile, url) {
    oneFile.fragmentList.push(url);
    if (oneFile.fragmentSum.length <= 0) {
      //  上传完成合并文件
      this.mergeFile(oneFile);
      this.checkUploadIsOver();
    } else {
      if (oneFile.fragmentUploadedSum.length > oneFile.fragmentSumAll.length) {
        oneFile.progress = 100;
      } else {
        oneFile.progress = Math.round(100 * oneFile.fragmentUploadedSum.length / oneFile.fragmentSumAll.length);
      }
      this.uploadDo(oneFile, oneFile.file);
    }
  }

  /**
   * 校验文件是否已上传完成
   */
  checkUploadIsOver() {
    let check = true;
    for (const oneData of this.uploadFileList) {
      if (oneData.fragmentSum.length > 0) {
        check = false;
      }
    }
    if (check) {
      this.fileList = [];
      this.dataObject.loading = false;
    }
  }

  /**
   * 校验文件是否存在
   */
  checkFileExit(filename, frag) {
    const data = {
      filename: filename,
      frag: frag
    };
    return this.data.requestQuickSliver('/digital/checkFileExit', data);
  }

  /**
   * 删除文件
   */
  deleteFile(oneFile) {
    oneFile.isDelete = true;
    for (const oneData of this.uploadFileList) {
      if (oneFile.file.name === oneData.file.name) {
        this.uploadFileList = _.without(this.uploadFileList, oneData);
      }
    }
    if (oneFile.fragmentList.length > 0) {
      let delData = {};
      if (oneFile.state === 'finish') {
        delData = {
          fragmentList: [oneFile.fileUrl]
        };
      } else {
        delData = {
          fragmentList: oneFile.fragmentList
        };
      }
      this.data.requestQuickSliver('/digital/mergeErrorDelFile', delData).subscribe((rep) => {
      });
    }
    for (const oneData of this.fileList) {
      if (oneFile.file.name === oneData.name) {
        this.fileList = _.without(this.fileList, oneData);
        if (this.fileList.length === 0) {
          this.dataObject.loading = false;
        }
      }
    }
  }

  /**
   * 发送合并文件请求
   */
  mergeFile(oneFile) {
    const data = {
      fragmentList: oneFile.fragmentList,
      fileName: oneFile.file.name
    };
    return this.data.requestQuickSliver('/digital/mergeFile', data).subscribe((rep) => {
        oneFile.progress = 100;
        oneFile.fileUrl = rep.url;
        oneFile.state = 'finish';
      },
      (error) => {
        const delData = {
          fragmentList: oneFile.fragmentList
        };
        this.data.requestQuickSliver('/digital/mergeErrorDelFile', delData).subscribe((reps) => {
          oneFile.fragmentList = [];
          oneFile.fragmentUploadedSum = [];
          oneFile.progress = 0;
          oneFile.state = 'error';
        });
      }
    );
  }

  ngOnInit() {
    this.dataObject.loading = false;
    if (!this.utils.Base_HasValue(this.dataObject.fileList)) {
      this.dataObject.fileList = [];
    }
  }
}
