import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'underscore';
import {NzModalService} from 'ng-zorro-antd';
import {TableModalComponent} from '../../../basicComponent/table-modal/table-modal.component';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ColumnGroupComponent} from '../../../basicComponent/column-group/column-group.component';
import {DataCenterService} from '../../../service/data-center.service';
import * as localForage from 'localforage';

@Component({
  selector: 'app-auto-json',
  templateUrl: './auto-json.component.html',
  styleUrls: ['./auto-json.component.css']
})
export class AutoJsonComponent implements OnInit {

  constructor(private modalService: NzModalService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private requestDataService: RequestDataService) {
  }

  @Input() parentData: any;
  /**
   * 常量数据
   */
    //字段类型
  columnType = [
    {
      'code': 'string',
      'name': '字符串',
    },
    {
      'code': 'int',
      'name': '数字',
    },
    {
      'code': 'datetime',
      'name': '时间',
    }
  ];
  //字段传入方式
  columnDirection = [
    {
      'code': 'input',
      'name': 'input-输入',
    },
    {
      'code': 'sys',
      'name': 'sys-系统值',
    },
    {
      'code': 'sqlFileInput',
      'name': '权限json',
    }
  ];
  //字段比较方式
  columnOperator = [
    {
      'code': '=',
      'name': '= 等于',
    }, {
      'code': 'in',
      'name': 'in 介于',
    },
    {
      'code': 'like',
      'name': 'like 包含',
    },
    {
      'code': '>=',
      'name': '>= 大于等于',
    },
    {
      'code': '<=',
      'name': '<= 小于等于',
    }
  ];
  //条件拼接方式
  conditionOperator = [
    {
      'code': 'and',
      'name': 'and 并且',
    },
    {
      'code': 'or',
      'name': 'or 或者',
    }
  ];

  //提交值
  commitPointArr = [
    {
      'code': 'add',
      'name': '添加提交值',
    },
    {
      'code': 'modify',
      'name': '修改提交值',
    },
    {
      'code': 'all',
      'name': '全提交值',
    },
    {
      'code': 'detail',
      'name': '不提交值',
    }
  ];

  // 组件
  componentModel = [
    {
      'code': 'input',
      'name': '输入框',
    },
    {
      'code': 'inputMulti',
      'name': '多行输入框',
    },
    {
      'code': 'text',
      'name': '文本框',
    }, {
      'code': 'textarea',
      'name': '大文本框',
    }, {
      'code': 'singleTextarea',
      'name': '富文本框',
    }, {
      'code': 'picture',
      'name': '图片上传',
    }, {
      'code': 'file',
      'name': '文件上传',
    },
    {
      'code': 'select',
      'name': '下拉框',
    }
    ,
    {
      'code': 'poplist',
      'name': '弹出框',
    },
    {
      'code': 'multiSelect',
      'name': '多选下拉框',
    }, {
      'code': 'multipop',
      'name': '多选弹出框',
    },
    {
      'code': 'date',
      'name': '日期',
    },
    {
      'code': 'datetime',
      'name': '日期时间',
    },
    {
      'code': 'dateRange',
      'name': '日期起止框',
    },
    {
      'code': 'dateTimeRange',
      'name': '日期时间起止框',
    },
    {
      'code': 'time',
      'name': '时分秒',
    },
    {
      'code': 'dateMonths',
      'name': '月份',
    }
  ];

  fileTypeArr = [
    {
      'code': 'normal',
      'name': 'normal',
    },
    {
      'code': 'detail',
      'name': 'detail',
    },
    {
      'code': 'dictionary',
      'name': 'dictionary',
    }
  ];
  // 数据源
  dataResourceArr = [];

  panels = [
    {
      active: false,
      name: '查询语句',
      disabled: false,
      id: 'selectModel'
    },
    {
      active: false,
      disabled: false,
      name: '列表页',
      id: 'showColumns'
    },
    {
      active: false,
      disabled: false,
      name: '列表页字典',
      id: 'showColumnsDict'
    },
    {
      active: false,
      disabled: false,
      name: '过滤条件',
      id: 'conditions'
    }, {
      active: false,
      disabled: false,
      name: '权限组',
      id: 'power'
    },
    {
      active: false,
      disabled: false,
      name: '列表页查询字段',
      id: 'conditionColumns'
    },
    {
      active: true,
      disabled: false,
      name: '详情页',
      id: 'detailColumns'
    }
  ];
  showColumnsTableName = '';

  tableName: any = '';
  dataBaseName: any = '';
  dataSource: any = '';
  filePath: any = '';
  fileDesc: any = '';

  switchValueSelect: false;
  switchValueShowColumn: false;
  switchValueShowColumnDict: false;
  switchValueConditions: false;
  switchValuePower: false;
  switchValueDetail: false;
  switchConditionColumns: false;
  switchValueJson: false;


  baseData = [
    {
      'column': 'U_DELETE',
      'name': 'U_DELETE_NAME',
      'component': 'input',
      'lable': '删除标识',
      'comment': '删除标识',
      'placeholder': '删除标识',
      'model': 1,
      'insertValue': '1',
      'visible': false
    },
    {
      'column': 'ADD_USERID',
      'name': 'ADD_USERID_NAME',
      'component': 'input',
      'lable': '添加人',
      'comment': '添加人',
      'placeholder': '添加人',
      'insertValue': 'SYS_USER_ID',
      'model': null,
      'visible': false,
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'add'
      ]
    },
    {
      'column': 'ADD_NAME',
      'name': 'ADD_NAME_NAME',
      'component': 'input',
      'lable': '添加人',
      'comment': '添加人',
      'placeholder': '添加人',
      'insertValue': 'SYS_USER_NAME',
      'model': null,
      'visible': false,
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'add'
      ]
    },
    {
      'column': 'ADD_TIME',
      'name': 'ADD_TIME_NAME',
      'component': 'input',
      'lable': '添加时间',
      'comment': '添加时间',
      'placeholder': '添加时间',
      'model': null,
      'visible': false,
      'insertValue': 'SYS-DATETIME',
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'add'
      ]
    },
    {
      'column': 'UPD_USERID',
      'name': 'UPD_USERID_NAME',
      'component': 'input',
      'lable': '修改人',
      'comment': '修改人',
      'placeholder': '修改人',
      'insertValue': 'SYS_USER_ID',
      'model': null,
      'visible': false,
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'modify'
      ]
    },
    {
      'column': 'UPD_NAME',
      'name': 'UPD_NAME_NAME',
      'component': 'input',
      'lable': '修改人',
      'comment': '修改人',
      'placeholder': '修改人',
      'insertValue': 'SYS_USER_NAME',
      'model': null,
      'visible': false,
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'modify'
      ]
    },
    {
      'column': 'UPD_TIME',
      'name': 'UPD_TIME_NAME',
      'component': 'input',
      'lable': '更新时间',
      'comment': '更新时间',
      'placeholder': '更新时间',
      'class': 'col-lg-4 col-sm-6 col-xs-12',
      'insertValue': 'SYS-DATETIME',
      'disable': [
        'add',
        'modify'
      ],
      'commitPoint': [
        'modify'
      ],
      'model': null,
      'visible': false
    },
    {
      'column': 'U_TIME',
      'name': 'U_TIME_NAME',
      'component': 'input',
      'lable': '更新时间',
      'comment': '更新时间',
      'placeholder': '更新时间',
      'class': 'col-lg-4 col-sm-6 col-xs-12',
      'insertValue': 'SYS-DATETIME',
      'model': null,
      'visible': false
    }
  ];

  /**
   * 各模块json处理数据
   */
    // 列表页字段处理数据
  showColumnsData: any = [];
  // 查询条件字段处理数据
  conditionsData = [
    {
      'groupName': 'group_01',
      'groupSplic': 'and',
      'listData': []
    }
  ];
  // 详情页字段处理数据
  detailColumnsData = [
    {
      'tablename': '',
      'formname': '',
      'title': '主表',
      'type': 'normal',
      'add_link': null,
      'modify_link': null,
      'columns': []
    }
  ];

  /**
   * 各模块json展示数据
   */
    // 查询模块结果展示数据

  selectModel = '';
  // 列表页结果展示数据
  showColumnModel = '';
  // 列表页字典展示数据
  showColumnDictModel = '';
  // 查询条件展示数据
  conditionsModel = '';
  // 权限组展示数据
  powerModel = '';
  // 详情模块展示数据
  detailColumnsModel = '';
  // 查询字段模块展示数据
  conditionColumnsModel = '';
  //json展示数据
  jsonObjectModel = '';

  /**
   * 各模块结果数据
   */
  jsonObject: any = {
    _type: '',
    _comment: '',
    selectText: '',
    formText: '',
    whereText: 'where 1=1',
    groupbyText: '',
    orderbyText: '',
    countText: ''
  };
  // 列表页结果数据
  showColumnModelValue = {
    'showColumns': {}
  };
  // 列表页字典结果数据
  showColumnsDictData: any = [];
  // 查询条件结果数据
  conditionsModelValue = {
    'conditionGroup': {},
    'condition': {}
  };
  // 权限组结果数据
  powerList: any = [];
  conditionColumns: any = [
    {
      'tablename': '',
      'formname': '',
      'title': '',
      'type': 'normal',
      'columns': []
    }
  ];
  // 详情结果数据
  detailColumnsModelValue = [];

  switchSelect(pType) {
    switch (pType) {
      case 'selectModel':
        this.selectModel = this.utils.syntaxHighlight(this.jsonObject);
        break;
      case 'showColumnModel':
        if (!this.switchValueShowColumn) {
          break;
        }
        this.manageShowColumn();
        this.showColumnModel = this.utils.syntaxHighlight(this.showColumnModelValue);
        break;
      case 'showColumnDictModel':
        if (!this.switchValueShowColumnDict) {
          break;
        }
        this.showColumnDictModel = this.utils.syntaxHighlight(this.showColumnsDictData);
        break;
      case 'conditionsModel':
        if (!this.switchValueConditions) {
          break;
        }
        this.manageConditions();
        this.conditionsModel = this.utils.syntaxHighlight(this.conditionsModelValue);
        break;
      case 'powerModel':
        if (!this.switchValuePower) {
          break;
        }
        this.powerModel = this.utils.syntaxHighlight(this.powerList);
        break;
      case 'detailColumnsModel':
        if (!this.switchValueDetail) {
          break;
        }
        this.manageDetailColumn();
        this.detailColumnsModel = this.utils.syntaxHighlight(this.detailColumnsModelValue);
        break;
      case 'conditionColumns':
        if (!this.switchConditionColumns) {
          break;
        }
        this.manageConditionColumns();
        this.conditionColumnsModel = this.utils.syntaxHighlight(this.conditionColumns);
        break;
      case 'switchValueJsonModel':
        if (!this.switchValueJson) {
          break;
        }
        this.manageShowColumn();
        this.manageConditions();
        this.manageDetailColumn();
        this.manageConditionColumns();
        this.getJsonObjectResult();
        this.jsonObjectModel = this.utils.syntaxHighlight(this.jsonObject);
        break;
    }
  }

  // 获取json结果数据
  getJsonObjectResult() {
    this.jsonObject._comment = this.fileDesc;
    this.jsonObject['conditionGroup'] = this.conditionsModelValue['conditionGroup'];
    this.jsonObject['condition'] = this.conditionsModelValue['condition'];
    this.jsonObject['columns'] = this.showColumnsDictData;
    if (this.jsonObject._type !== 'detail') {
      this.jsonObject['role_group'] = this.powerList;
      this.jsonObject['showColumns'] = this.showColumnModelValue['showColumns'];
      this.jsonObject['detailColumns'] = this.detailColumnsModelValue;
      this.jsonObject['conditionColumns'] = this.conditionColumns;
    }
  }

  // 获取selectText中的字段
  getSelectTextColumns() {
    let selectSplit = '';
    if (this.jsonObject.selectText.indexOf('SELECT') !== -1) {
      selectSplit = this.jsonObject.selectText.split('SELECT');
    } else {
      selectSplit = this.jsonObject.selectText.split('select');
    }
    if (!this.utils.Base_HasValue(selectSplit[1])) {
      this.confirmModal.show('warning', {
        title: '请先填写查询字段',
      });
      return false;
    }
    return selectSplit[1].trim().split(',') || [];
  }

  //新增字段
  addConditionGroupColumn(pOneConditions, pType) {
    if (!this.utils.Base_HasValue(this.dataBaseName)) {
      this.confirmModal.show('warning', {
        title: '请填写数据库名称',
      });
      return false;
    }
    if (!this.utils.Base_HasValue(this.tableName) && !this.utils.Base_HasValue(pOneConditions['tablename'])) {
      this.confirmModal.show('warning', {
        title: '请填写数据库表名',
      });
      return false;
    }
    let tableName = pOneConditions['tablename'] ? pOneConditions['tablename'] : this.tableName;
    this.requestDataService.getTableColumns(this.dataSource, this.dataBaseName, tableName).subscribe(rep => {
      if (this.utils.Base_HasValue(rep)) {
        let headTitle = [
          {
            key: 'COLUMN_NAME',
            value: '字段名称'
          }, {
            key: 'COLUMN_COMMENT',
            value: '描述'
          }
        ];
        let tableData = rep;
        let checkData = [];
        switch (pType) {
          case 'condition':
            checkData = pOneConditions.listData;
            break;
          case 'power':
            checkData = this.powerList;
            break;
          case 'detail_column':
          case 'conditons_column':
            checkData = pOneConditions.columns;
            break;
        }
        _.each(tableData, (item) => {
          let findData = _.find(checkData, (oneData) => {
            return oneData['column'] === item['COLUMN_NAME'];
          });
          if (this.utils.Base_HasValue(findData)) {
            item['checked'] = true;
          }
        });

        this.modalService.create({
          nzTitle: '选择字段',
          nzContent: TableModalComponent,
          nzWidth: 800,
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            headTitle: headTitle,
            tableData: tableData
          },
          nzOnOk: (response) => {
            let selectTableData = [];
            selectTableData = _.filter(response.tableData, (item) => {
                return item['checked'];
              }
            );
            let column = '';
            if (this.utils.Base_HasValue(selectTableData)) {
              if (pType === 'condition') {
                for (let oneData of selectTableData) {
                  column = _.find(pOneConditions.listData, item => {
                    return oneData['COLUMN_NAME'] === item['column'];
                  });
                  if (!this.utils.Base_HasValue(column)) {
                    pOneConditions.listData.push({
                      'column': oneData['COLUMN_NAME'],
                      'value': oneData['COLUMN_NAME'],
                      'alias': 'a',
                      'type': 'string',
                      'direction': 'input',
                      'operator': '=',
                      'columnDesc': oneData['COLUMN_COMMENT'],
                      'condition_operator': 'and'
                    });
                  }
                }
              } else if (pType === 'power') {
                for (let oneData of selectTableData) {
                  column = _.find(this.powerList, item => {
                    return oneData['COLUMN_NAME'] === item['column'];
                  });
                  if (!this.utils.Base_HasValue(column)) {
                    this.powerList.push({
                      'alias': 'a',
                      'column': oneData['COLUMN_NAME'],
                      'type': 'string',
                      'sqlFile': '',
                      'value': 'SYS_COM_ID',
                      'isRequirePower': true,
                      'direction': 'sqlFileInput',
                      'operator': 'in',
                      'condition_operator': 'and'
                    });
                  }
                }
              } else if (pType === 'detail_column') {
                for (let oneData of selectTableData) {
                  column = _.find(pOneConditions.columns, item => {
                    return oneData['COLUMN_NAME'] === item['column'];
                  });
                  if (!this.utils.Base_HasValue(column)) {
                    pOneConditions.columns.push({
                      'column': oneData['COLUMN_NAME'],
                      'name': oneData['COLUMN_NAME'] + '_NAME',
                      'component': 'input',
                      'lable': '',
                      'comment': '',
                      'placeholder': '',
                      'insertValue': null,
                      'model': null,
                      'visible': true,
                      'require': true
                    });
                  }
                }
              } else if (pType === 'conditons_column') {
                for (let oneData of selectTableData) {
                  column = _.find(pOneConditions.columns, item => {
                    return oneData['COLUMN_NAME'] === item['column'];
                  });
                  if (!this.utils.Base_HasValue(column)) {
                    pOneConditions.columns.push({
                      'column': oneData['COLUMN_NAME'],
                      'name': oneData['COLUMN_NAME'] + '_NAME',
                      'component': 'input',
                      'lable': '',
                      'comment': '',
                      'placeholder': '',
                      'model': null,
                      'visible': true,
                      'require': true
                    });
                  }
                }
              }
            }
          }
        });
      } else {
        this.confirmModal.show('warning', {
          title: '数据库或表不存在',
        });
      }
    });
  }

  //选择字典
  searchDict(oneData, pType) {
    this.requestDataService.getFileList(this.appConfigService.config.sql_path).subscribe(rep => {
      if (!this.utils.Base_HasValue(rep)) {
        this.confirmModal.show('warning', {
          title: '当前没有json文件可以选择',
        });
        return false;
      }
      let headTitle = [
        {
          key: 'sqlPath',
          value: 'json地址'
        }, {
          key: 'comment',
          value: '描述'
        }, {
          key: 'type',
          value: '类型'
        },
      ];
      let searchColumn = [
        {
          key: 'sqlPath',
          value: 'json地址',
          model: null
        },
        {
          key: 'comment',
          value: '描述',
          model: null
        },
        {
          key: 'type',
          value: '类型',
          model: null
        },
      ];
      let tableData = rep || [];
      this.modalService.create({
        nzTitle: '选择字段',
        nzContent: TableModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          headTitle: headTitle,
          tableData: tableData,
          searchColumn: searchColumn
        },
        nzOnOk: (response) => {
          let selectTableData = [];
          selectTableData = _.filter(response.tableData, (item) => {
              return item['checked'];
            }
          );
          if (this.utils.Base_HasValue(selectTableData)) {
            if (selectTableData.length > 1) {
              this.confirmModal.show('warning', {
                title: '请只选择一条数据',
              });
              return false;
            }
            if (pType === 'role_power') {
              oneData.sqlFile = selectTableData[0].sqlPath;
            } else if (pType === 'show_column_dict' || pType === 'detail_column') {
              oneData.dictionary = selectTableData[0].sqlPath;
              oneData._comment = selectTableData[0].comment;
              oneData.dictionaryPF = null;
            } else if (pType === 'modify_link') {
              oneData.modify_link = selectTableData[0].sqlPath;
            }
          }
        }
      });
    });
  }

  /**
   *
   * 列表页字段处理
   */
  // 列表页字段删除
  deleteShowColumnData(oneData) {
    this.showColumnsData = _.without(this.showColumnsData, oneData);
  }

  // 列表页字段新增
  addShowColumn() {
    if (!this.utils.Base_HasValue(this.jsonObject.selectText)) {
      this.confirmModal.show('warning', {
        title: '请先填写selectText',
      });
      return false;
    }
    let headTitle = [
      {
        key: 'columnId',
        value: '字段标识'
      }
    ];
    let tableData = [];
    let columnSplit = this.getSelectTextColumns() || [];
    for (let oneColumn of columnSplit) {
      let index = oneColumn.indexOf('.');
      oneColumn = oneColumn.substr(index + 1, oneColumn.length - 2);
      tableData.push({
        columnId: oneColumn
      });
    }
    _.each(tableData, (item) => {
      let findData = _.find(this.showColumnsData, (value) => {
        return value['column'] === item['columnId'];
      });
      if (this.utils.Base_HasValue(findData)) {
        item['checked'] = true;
      }
    });
    this.modalService.create({
      nzTitle: '选择字段',
      nzContent: TableModalComponent,
      nzWidth: 600,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        headTitle: headTitle,
        tableData: tableData
      },
      nzOnOk: (response) => {
        let result = [];
        this.showColumnsData = [];
        let selectTableData = _.filter(response.tableData, (item) => {
            return item['checked'];
          }
        );
        if (this.utils.Base_HasValue(selectTableData)) {
          for (let oneData of selectTableData) {
            result.push({
              'column': oneData['columnId'],
              'export': true
            });
          }
        }
        this.showColumnsData = result;
      }
    });
  }

  // 获取列表页结果数据
  manageShowColumn() {
    this.showColumnModelValue['showColumns']['tablename'] = this.showColumnsTableName;
    this.showColumnModelValue['showColumns']['columns'] = this.utils.DeepCopy(this.showColumnsData);
    if (this.utils.Base_HasValue(this.showColumnsData)) {
      _.each(this.showColumnModelValue['showColumns']['columns'], (item) => {
        if (item['isDict'] && _.indexOf(item['column'], '_DICT') !== -1) {
          item['column'] = item['column'] + '_DICT';
        }
        if (item['pk_column']) {
          item['iscondition'] = true;
          item['title'] = '主键';
          item['class'] = '';
          item['type'] = 'head';
          item['conditionColumn'] = item['column'];
        } else {
          item['class'] = '';
          item['type'] = 'normal';
          delete item['pk_column'];
        }
        delete item['isDict'];
      });
    }
  }

  /**
   *
   * 列表页字典处理
   */
  // 列表页字典删除
  deleteShowColumnDictData(oneData) {
    this.showColumnsDictData = _.without(this.showColumnsDictData, oneData);
  }

  // 列表字典新增
  addShowColumnDict() {
    if (!this.utils.Base_HasValue(this.jsonObject.selectText)) {
      this.confirmModal.show('warning', {
        title: '请先填写selectText',
      });
      return false;
    }
    let headTitle = [
      {
        key: 'columnId',
        value: '字段标识'
      }
    ];
    let tableData = [];
    let columnSplit = this.getSelectTextColumns() || [];
    for (let oneColumn of columnSplit) {
      let index = oneColumn.indexOf('.');
      oneColumn = oneColumn.substr(index + 1, oneColumn.length - 2);
      tableData.push({
        columnId: oneColumn
      });
    }
    _.each(tableData, (item) => {
      let findData = _.find(this.showColumnsDictData, (value) => {
        return value['column'] === item['columnId'];
      });
      if (this.utils.Base_HasValue(findData)) {
        item['checked'] = true;
      }
    });
    this.modalService.create({
      nzTitle: '选择字段',
      nzContent: TableModalComponent,
      nzWidth: 600,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        headTitle: headTitle,
        tableData: tableData
      },
      nzOnOk: (response) => {
        let result = [];
        this.showColumnsDictData = [];
        let selectTableData = _.filter(response.tableData, (item) => {
            return item['checked'];
          }
        );
        if (this.utils.Base_HasValue(selectTableData)) {
          let column: string;
          for (let oneData of selectTableData) {
            column = _.find(this.showColumnsDictData, item => {
              return oneData['columnId'] === item['column'];
            });
            if (!this.utils.Base_HasValue(column)) {
              this.showColumnsDictData.push({
                'column': oneData['columnId'],
                'input': 'DICT_ITEM_ID'
              });
            }
          }
        }
      }
    });
  }

  /**
   *
   * 查询条件组处理
   */
  // 查询条件组新增
  conditionsGroupAdd() {
    this.conditionsData.push({
      'groupName': '',
      'groupSplic': '',
      'listData': []
    });
  }

  // 查询条件组删除
  deleteConditionGroup(pOneCondition) {
    this.conditionsData = _.without(this.conditionsData, pOneCondition);
  }


  // 查询条件组删除字段
  deleteConditionGroupColumn(pOneConditions, pOndeData) {
    pOneConditions.listData = _.without(pOneConditions.listData, pOndeData);
  }

  // 获取查询条件结果数据
  manageConditions() {
    this.conditionsModelValue = {
      'conditionGroup': {},
      'condition': {}
    };
    if (this.utils.Base_HasValue(this.conditionsData)) {
      for (let oneData of this.conditionsData) {
        this.conditionsModelValue.conditionGroup[oneData.groupName] = oneData.groupSplic;
        this.conditionsModelValue.condition[oneData.groupName] = [];
        for (let item of oneData.listData) {
          this.conditionsModelValue.condition[oneData.groupName].push(item);
          delete this.conditionsModelValue.condition[oneData.groupName]['columnDesc'];
        }
      }
    }
  }

  /**
   *  权限组处理
   */
  // 删除权限组数据
  deleteColumn(pColumn) {
    this.powerList = _.without(this.powerList, pColumn);
  }

  /**
   *  查询条件组处理
   */
  manageConditionColumns() {
    if (this.utils.Base_HasValue(this.conditionColumns)) {
      _.each(this.conditionColumns, (oneColumn) => {
        oneColumn['formname'] = oneColumn['tablename'];
        for (let item of oneColumn['columns']) {
          item['comment'] = item['lable'];
          if(!this.utils.Base_HasValue(item['placeholder'])){
            item['placeholder'] = item['lable'];
          }
          // item['placeholder'] = item['lable'];
          if (item['component'].indexOf('Range') !== -1) {
            item['bindColumns'] = [item['column'] + '_BEGIN', item['column'] + '_END'];
            item['placeholder'] = ['开始日期','结束日期'];
          }
        }
      });
    }
  }

  /**
   *  详情页处理
   */
  //新增明细表
  detailTableAdd() {
    this.detailColumnsData.push(
      {
        'tablename': '',
        'formname': '',
        'title': '明细表',
        'type': 'tablelist',
        'add_link': null,
        'modify_link': null,
        'columns': []
      }
    );
  }

  // 删除明细表
  deleteDetailTable(oneTable) {
    this.detailColumnsData = _.without(this.detailColumnsData, oneTable);
  }

  // 字典带值
  getColumnGroup(pObject, pOneConditions) {
    if (!this.utils.Base_HasValue(this.dataBaseName)) {
      this.confirmModal.show('warning', {
        title: '请填写数据库名称',
      });
      return false;
    }
    if (!this.utils.Base_HasValue(this.tableName) && !this.utils.Base_HasValue(pOneConditions['tablename'])) {
      this.confirmModal.show('warning', {
        title: '请填写数据库表名',
      });
      return false;
    }
    let tableName = pOneConditions['tablename'] ? pOneConditions['tablename'] : this.tableName;
    this.modalService.create({
      nzTitle: '选择字段',
      nzContent: ColumnGroupComponent,
      nzWidth: 900,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        groupData: pObject,
        dataSource: this.dataSource,
        dataBaseName: this.dataBaseName,
        tableName: tableName
      },
      nzOnOk: (response) => {
        _.each(response.groupData.columnGroup, item => {
          if (typeof item['disable'] === 'string') {
            item['disable'] = item['disable'].split(',');
          }
        });
        pObject = response.groupData;
      }
    });
  }

  //删除表字段
  deleteDetailTableColumn(oneTable, data) {
    oneTable.columns = _.without(oneTable.columns, data);
  }

  // 获取详情页结果数据
  manageDetailColumn() {
    let hasBasc = false;
    this.detailColumnsModelValue = this.utils.DeepCopy(this.detailColumnsData);
    if (this.utils.Base_HasValue(this.detailColumnsData)) {
      _.each(this.detailColumnsModelValue, (oneTable) => {
        oneTable['tablename'] = oneTable['formname'];
        for (let item of oneTable.columns) {
          if (_.contains(['ADD_TIME', 'ADD_NAME', 'ADD_USERID'], item['column'])) {
            hasBasc = true;
          }
          if (this.utils.Base_HasValue(item['commitPoint'])) {
            if (item['commitPoint'] === 'all') {
              delete item['commitPoint'];
            } else {
              item['commitPoint'] = [item['commitPoint']];
            }
          }
          item['comment'] = item['lable'];
          if(!this.utils.Base_HasValue(item['placeholder'])){
            item['placeholder'] = item['lable'];
          }
          if (item['pk_column']) {
            if (oneTable.type === 'normal') {
              if (this.detailColumnsModelValue.length > 1) {
                item['insertValue'] = 'SYS-ACTION-UUID';
              } else {
                item['insertValue'] = 'SYS-UUID';
              }
            } else {
              item['insertValue'] = 'SYS-UUID';
            }
            item['iscondition'] = true;
            item['lable'] = '主键';
            item['comment'] = '主键';
            item['placeholder'] = '主键';
            item['visible'] = false;
            item['component'] = 'input';
          } else {
            delete item['pk_column'];
          }
          if (this.utils.Base_HasValue(item.uniqueCheck)) {
            if (item.uniqueCheck.indexOf(',') !== -1) {
              item.uniqueCheck = item.uniqueCheck.split(',');
            } else {
              item.uniqueCheck = [item.uniqueCheck];
            }
          }
          if (item.component.indexOf('Range') !== -1) {
            item['bindColumns'] = [item.column + '_BEGIN', item.column + '_END'];
          }
        }
        if (this.utils.Base_HasValue(oneTable.deleteParam)) {
          let dp = oneTable.deleteParam.split(';');
          oneTable.deleteParam = [
            {
              'column': dp[0],
              'value': dp[1]
            }
          ];
        }
        if (oneTable['type'] === 'tablelist') {
          if (this.utils.Base_HasValue(oneTable['uniqueItems'])) {
            oneTable['uniqueItems'] = oneTable['uniqueItems'].split(',');
          }
        }
        if (!hasBasc) {
          oneTable.columns = oneTable.columns.concat(this.baseData);
        }
      });
    }
  }

  autoJson() {
    if (!this.utils.Base_HasValue(this.filePath)) {
      this.confirmModal.show('warning', {
        title: '请先填写文件存放地址',
      });
      return false;
    }
    if (!this.utils.Base_HasValue(this.fileDesc)) {
      this.confirmModal.show('warning', {
        title: '请先填写文件描述',
      });
      return false;
    }
    this.confirmModal.show('confirm', {
      title: '您确定上传json文件？',
      suc: () => {
        let configInfo = {
          tableName: this.tableName,
          dataBaseName: this.dataBaseName,
          dataSource: this.dataSource,
          filePath: this.filePath,
          fileDesc: this.fileDesc
        };
        localForage.setItem('autoJsonConfig', JSON.stringify(configInfo));
        this.manageShowColumn();
        this.manageConditions();
        this.manageDetailColumn();
        this.manageConditionColumns();
        this.getJsonObjectResult();
        let str = JSON.stringify(this.jsonObject);
        this.requestDataService.saveJsonUpload(str, this.filePath, this.fileDesc).subscribe(rep => {
          this.confirmModal.show('success', {
            title: rep.data,
          });
        });
      }
    });
  }

  // 查询语句快速生成
  autoSelectText() {
    if (!this.utils.Base_HasValue(this.jsonObject['formText'])) {
      this.confirmModal.show('warning', {
        title: '请先填写fromText语句',
      });
      return false;
    }
    let jsonObjectStr = JSON.stringify({
      'selectText': this.jsonObject['selectText'],
      'formText': this.jsonObject['formText']
    });
    this.requestDataService.getSelectTextColumn(jsonObjectStr, this.dataBaseName, this.dataSource).subscribe(rep => {
      if (!this.utils.Base_HasValue(rep)) {
        this.confirmModal.show('warning', {
          title: '未查询到字段',
        });
        return false;
      }
      let headTitle = [
        {
          key: 'TABLE_ALIAS',
          value: '别名',
          model: null
        },
        {
          key: 'COLUMN_NAME',
          value: '字段标识'
        }, {
          key: 'COLUMN_COMMENT',
          value: '描述'
        }, {
          key: 'TABLE_NAME',
          value: '所属表名'
        }
      ];
      let searchColumn = [
        {
          key: 'COLUMN_NAME',
          value: '字段标识',
          model: null
        },
        {
          key: 'COLUMN_COMMENT',
          value: '描述',
          model: null
        },
        {
          key: 'TABLE_NAME',
          value: '所属表名',
          model: null
        },
      ];
      let tableData = rep || [];
      this.modalService.create({
        nzTitle: '选择字段',
        nzContent: TableModalComponent,
        nzWidth: 800,
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          headTitle: headTitle,
          tableData: tableData,
          searchColumn: searchColumn
        },
        nzOnOk: (response) => {
          this.jsonObject.selectText = '';
          let selectTableData = [];
          selectTableData = _.filter(response.tableData, (item) => {
              return item['checked'];
            }
          );
          if (this.utils.Base_HasValue(selectTableData)) {
            for (let i = 0; i < selectTableData.length; i++) {
              let oneData = selectTableData[i];
              if (i === selectTableData.length - 1) {
                this.jsonObject.selectText += oneData['TABLE_ALIAS'] + '.' + oneData['COLUMN_NAME'];
              } else {
                this.jsonObject.selectText += oneData['TABLE_ALIAS'] + '.' + oneData['COLUMN_NAME'] + ',';
              }
            }
            this.jsonObject.selectText = 'SELECT ' + this.jsonObject.selectText;
          }
        }
      });
    });
  }

  // 列表页字段快速生成
  autoShowColumns() {
    if (!this.utils.Base_HasValue(this.jsonObject['formText'])) {
      this.confirmModal.show('warning', {
        title: '请先填写fromText语句',
      });
      return false;
    }
    let jsonObjectStr = JSON.stringify({
      'selectText': this.jsonObject['selectText'],
      'formText': this.jsonObject['formText']
    });
    this.requestDataService.getShowColumns(jsonObjectStr, this.dataBaseName, this.dataSource).subscribe(rep => {
      if (!this.utils.Base_HasValue(rep['result'])) {
        this.confirmModal.show('warning', {
          title: '未查询到字段',
        });
        return false;
      }
      this.showColumnsData = rep['result'];
    });
  }

  // 详情页字段快速生成
  detailColumnsGet(pOneTable) {
    if (!this.utils.Base_HasValue(pOneTable['tablename'])) {
      this.confirmModal.show('warning', {
        title: '请先填写表名',
      });
      return false;
    }
    this.requestDataService.detailColumnsGet(pOneTable['tablename'], this.dataBaseName, this.dataSource).subscribe(rep => {
      if (!this.utils.Base_HasValue(rep['result'])) {
        this.confirmModal.show('warning', {
          title: '未查询到字段',
        });
        return false;
      }
      pOneTable.columns = rep['result'];
    });
  }

  // 自动生成详情页json
  autoDetailJson() {
    if (!this.utils.Base_HasValue(this.dataBaseName)) {
      this.confirmModal.show('warning', {
        title: '请填写数据库名称',
      });
      return false;
    }
    if (!this.utils.Base_HasValue(this.tableName)) {
      this.confirmModal.show('warning', {
        title: '请填写数据库表名',
      });
      return false;
    }
    this.jsonObject._type  = 'detail';
    this.jsonObject = {
      _type: 'detail',
      _comment: '',
      selectText: 'select a.*',
      formText: 'from ' + this.tableName + ' a',
      whereText: 'where 1=1',
      groupbyText: '',
      orderbyText: '',
      countText: ''
    };
    this.conditionsData = [{
      'groupName': 'group_01',
      'groupSplic': 'and',
      'listData': [
        {
          'alias': 'a',
          'column': 'ID',
          'type': 'string',
          'value': 'ID',
          'direction': 'input',
          'operator': '=',
          'condition_operator': 'and'
        }
      ]
    }];
  }

  saveLocalFile() {
    let str = JSON.stringify(this.jsonObject);
    this.requestDataService.jsonUploadToOss(str).subscribe(async rep => {
      if (rep.status === 'success') {
        let ossUrl = rep.data;
      }
    });
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  ngOnInit(localJson?) {
    localForage.getItem('autoJsonConfig').then((result: string) => {
      if (this.utils.Base_HasValue(result)) {
        let configInfo = JSON.parse(result);
        this.tableName = configInfo['tableName'];
        this.dataBaseName = configInfo['dataBaseName'];
        this.dataSource = configInfo['dataSource'];
        this.filePath = configInfo['filePath'];
        this.fileDesc = configInfo['fileDesc'];
      }
      if (this.utils.Base_HasValue(this.parentData.data) || localJson) {
        this.filePath = this.parentData.data[0].SEARCH_ID || localJson;
        this.requestDataService.getDataByJson(this.filePath).subscribe(rep => {
          if (this.utils.Base_HasValue(rep)) {
            this.jsonObject = {
              selectText: rep['selectText'],
              formText: rep['formText'],
              whereText: rep['whereText'],
              groupbyText: rep['groupbyText'],
              orderbyText: rep['orderbyText'],
              countText: rep['countText'],
              _type:rep['_type'],
              _comment:rep['_comment'],
            };
            this.jsonObject['conditionGroup'] = rep['conditionGroup'];
            this.jsonObject['condition'] = rep['condition'];
            this.jsonObject['columns'] = rep['columns'];
            if (this.jsonObject._type  !== 'detail') {
              this.jsonObject['role_group'] = rep['role_group'];
              this.jsonObject['showColumns'] = rep['showColumns'];
              this.jsonObject['detailColumns'] = rep['detailColumns'];
              this.jsonObject['conditionColumns'] = rep['conditionColumns'];
            }
            if (this.utils.Base_HasValue(rep.detailColumns)) {
              this.detailColumnsData = rep.detailColumns;
              _.each(this.detailColumnsData, (item) => {
                if (item.type === 'tablelist') {
                  item['deleteParam'] = item['deleteParam'][0].column + ';' + item['deleteParam'][0].value;
                }
                for (let oneData of item.columns) {
                  if (this.utils.Base_HasValue(oneData.uniqueCheck)) {
                    oneData.uniqueCheck = oneData.uniqueCheck.join(',');
                  }
                  if (this.utils.Base_HasValue(oneData.commitPoint)) {
                    if (_.contains(oneData.commitPoint, 'add') && _.contains(oneData.commitPoint, 'modify')) {
                      oneData.commitPoint = 'all';
                    } else if (_.contains(oneData.commitPoint, 'add')) {
                      oneData.commitPoint = 'add';
                    } else if (_.contains(oneData.commitPoint, 'modify')) {
                      oneData.commitPoint = 'modify';
                    } else if (_.contains(oneData.commitPoint, 'detail')) {
                      oneData.commitPoint = 'detail';
                    }
                  }
                }
              });
            }
            if (this.utils.Base_HasValue(rep.showColumns)) {
              this.showColumnsTableName = rep.showColumns.tablename;
              if (this.utils.Base_HasValue(rep.showColumns.columns)) {
                this.showColumnsData = rep.showColumns.columns;
                for (let oneColumn of this.showColumnsData) {
                  if (oneColumn['column'].indexOf('_DICT') !== -1) {
                    oneColumn.isDict = true;
                  }
                }
              }
            }
            this.showColumnsDictData = rep['columns'] || [];
            this.powerList = rep['role_group'] || [];
            if (this.utils.Base_HasValue(rep.conditionColumns)) {
              this.conditionColumns = rep.conditionColumns;
            }
            if (this.utils.Base_HasValue(rep.condition)) {
              this.conditionsData = [];
              for (let key in rep.condition) {
                if (rep.condition.hasOwnProperty(key)) {
                  this.conditionsData.push(
                    {
                      'groupName': key,
                      'groupSplic': rep.conditionGroup[key],
                      'listData': rep.condition[key]
                    }
                  );
                }
              }
            }
          }
        });
      }
    });
    this.requestDataService.getDataByConditionToPf('headLine/basic/dict/DATA_RESOURCE.json', {}, 'hdbsDataResources').subscribe(rep => {
      this.dataResourceArr = rep.data || [];
    });
  }
}
