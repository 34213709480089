import { Component, OnInit } from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-teaching-infrastructure',
  templateUrl: './teaching-infrastructure.component.html',
  styleUrls: ['./teaching-infrastructure.component.css']
})
export class TeachingInfrastructureComponent implements OnInit {
  listData: any;

  constructor(private requestDataService: RequestDataService) {
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.requestDataService.getDataByCondition('subjectInfo/report/report_subject_teacher.json').subscribe(response => {
      this.listData = response.data;
    });
  }

}
