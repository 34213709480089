import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import { UtilsService } from './../../service/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.css']
})
export class TimeComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  constructor(private utils: UtilsService) {
  }

  changeModel () {
    const date = this.hulkColumn.modelDate;
    if (date) {
      this.hulkColumn.model = moment(date).format("HH:mm:ss");
    } else {
      this.hulkColumn.model = null;
    }
    
  }

  ngOnInit() {
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
    	let date = moment().format('YYYY-MM-DD') + ' ' + this.hulkColumn.model;
      this.hulkColumn.modelDate = moment(date)["_d"];
    }
  }

  ngDoCheck(): void {
    if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        let date = moment().format('YYYY-MM-DD') + ' ' + this.hulkColumn.model;
      this.hulkColumn.modelDate = moment(date)["_d"];
      } else {
        this.hulkColumn.modelDate = null;
      }
      this.hulkColumn.oldModel = this.hulkColumn.model;
    }
  }

}
