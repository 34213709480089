import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-descriptions-timeline',
  templateUrl: './descriptions-timeline.component.html',
  styleUrls: ['./descriptions-timeline.component.scss']
})
export class DescriptionsTimelineComponent implements OnInit {

  @Input() oneGroup: any;

  constructor() { }

  ngOnInit() {
  }

}
