import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  loading ():void {
  	$('.loadOverLay').css('display', 'block');
    $('.loadOverLay .text').css('display', 'block');
  }

  hide () {
  	$('.loadOverLay').hide();
    $('.loadOverLay .text').css('display', 'none');
  }
}
