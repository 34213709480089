import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-future-high-speed',
  templateUrl: './future-high-speed.component.html',
  styleUrls: ['./future-high-speed.component.css']
})
export class FutureHighSpeedComponent implements OnInit {

  upIsLoading = false;
  downIsLoading = false;
  shockIsLoading = false;
  cutIsLoading = false;
  getIsLoading = false;
  setIsLoading = false;
  cancelIsLoading = false;
  upDownIsLoading = false;
  refreshing = true;
  freshIcon = 'loading';
  freshState_Zh = '刷新中';

  last_price: any;
  last_datetime: any;

  symbol = 'SHFE.ni2010';
  broker = 'simnow';
  state = 'init';
  state_zh = '初始化';
  profitLine: any;
  lossLine: any;
  initNzType = 'default';
  upNzType = 'default';
  downNzType = 'default';
  shockNzType = 'default';
  cutNzType = 'default';
  cancelNzType = 'default';


  constructor(private requestDataService: RequestDataService,
              private http: HttpClient,
              private utils: UtilsService) {
  }

  doChangeBtnTyep() {
    switch (this.state) {
      case 'init':
        this.initNzType = 'primary';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.shockNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        break;
      case 'up':
        this.initNzType = 'default';
        this.upNzType = 'primary';
        this.downNzType = 'default';
        this.shockNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        break;
      case 'down':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'primary';
        this.shockNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';

        break;
      case 'shock':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.shockNzType = 'primary';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        break;
      case 'cut':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.shockNzType = 'default';
        this.cutNzType = 'primary';
        this.cancelNzType = 'default';
        break;
      case 'cancel':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.shockNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'primary';
        break;
    }
  }

  doUp() {
    this.upIsLoading = true;
    this.state = 'up', this.state_zh = '上涨';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.upIsLoading = false;
    });
  }

  doDown() {
    this.downIsLoading = true;
    this.state = 'down', this.state_zh = '下跌';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.downIsLoading = false;
    });
  }

  doShock() {
    this.shockIsLoading = true;
    this.state = 'shock', this.state_zh = '震荡';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.shockIsLoading = false;
    });
  }

  doCut() {
    this.cutIsLoading = true;
    this.state = 'cut', this.state_zh = '砍单';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doCancel() {
    this.cutIsLoading = true;
    this.state = 'cancel', this.state_zh = '撤单';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doInit() {
    this.cutIsLoading = true;
    this.state = 'init', this.state_zh = '观望';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doSet() {
    this.setIsLoading = true;
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.setIsLoading = false;
    });
  }


  doGet() {
    this.getIsLoading = true;
    this.requestDataService.redisGet(this.symbol + this.broker).subscribe(response => {
      let obj = JSON.parse(response);
      this.state = obj.state;
      this.state_zh = obj.state_zh;
      this.profitLine = obj.profitLine;
      this.lossLine = obj.lossLine;
      this.getIsLoading = false;
      this.doChangeBtnTyep();
    });
  }

  doRefresh() {
    this.refreshing = !this.refreshing;
    if (this.refreshing) {
      this.freshIcon = 'loading';
      this.freshState_Zh = '刷新中';
    }
    else {
      this.freshIcon = 'loading-3-quarters';
      this.freshState_Zh = '启动刷新';
    }

  }

  ngAfterViewInit() {
    setInterval(() => {
      if (this.refreshing)
      {
        this.doGet(); // 每一秒，获取redis的数据一次。
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.refreshing = false
  }


  ngOnInit() {
  }

}
