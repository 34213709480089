import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {ConfirmModalService} from './confirm-modal.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {AppConfigService} from "./app-config.service";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private storageLocal: StorageService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService) {
  }

  hashedText(text: string): string {
    const sha256Hash = CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
    const md5Hash = CryptoJS.MD5(sha256Hash).toString(CryptoJS.enc.Hex);
    const sha512Hash = CryptoJS.SHA512(md5Hash).toString(CryptoJS.enc.Hex);
    return sha512Hash;
  }

  getLinks(node) {
    let keyArr = [];
    let oneKey = _sh.get(node, ['key'])
    keyArr.push(oneKey);
    let parentNode = _sh.get(node, ['parentNode']);
    if (!_sh.isNull(parentNode)) {
      keyArr = _sh.concat(keyArr, this.getLinks(parentNode))
    }
    return keyArr
  }

    getArrayByPath(pData, pPath) {
        let result = [];
        _sh.each(pData, oneData => {
            let oneValue = _sh.get(oneData, pPath, null);
            result.push(oneValue)
        })
        return result;
    }

    getHasValuesCount(pArrayData) {
        let count = _sh.sumBy(pArrayData, oneData => {
            let oneArray = []
            _sh.each(_sh.keys(oneData), oneKey => {
                oneArray.push(oneData[oneKey]);
            })
            oneArray = _sh.compact(oneArray);
            let oneDataHasValueCount = oneArray.length;
            if (oneDataHasValueCount == 0)
                return 0
            else if (oneDataHasValueCount > 0) {
                return 1
            }
        })
        return count;
    }

    getArrayByPathArray(pData, pPathList) {
        let result = [];
        _sh.each(pData, oneData => {
            let obj = {}
            _sh.each(pPathList, onePath => {
                obj[onePath] = _sh.get(oneData, onePath, null);
            })
            result.push(obj)
        })
        return result;
    }

    filterHasData(pDatas, pCompareField) {
        return _sh.filter(pDatas, oneData => {
            let found = false;
            let oneValue = _sh.get(oneData, pCompareField, null);
            if (this.Base_HasValue(oneValue)) {
                found = true;
            }
            return found;
        })
    }

    filterData(pDatas, pCompareField, pCompareValue) {
        return _sh.filter(pDatas, oneData => {
            let found = false;
            let oneValue = _sh.get(oneData, pCompareField, null);
            if (this.Base_HasValue(oneValue)) {
                if (oneValue == pCompareValue) {
                    found = true;
                }
            }
            return found;
        })
    }


    filterOneData(pDatas, pCompareField, pCompareValue) {
        let list = _sh.filter(pDatas, oneData => {
            let found = false;
            let oneValue = _sh.get(oneData, pCompareField, null);
            if (this.Base_HasValue(oneValue)) {
                if (oneValue == pCompareValue) {
                    found = true;
                }
            }
            return found;
        })
        if (this.Base_HasValue(list)) {
            return list[0]
        } else {
            return {}
        }
    }

    findData(pDatas, pCompareField, pCompareValue) {
        let foundData = this.filterData(pDatas, pCompareField, pCompareValue);
        return this.Base_HasValue(foundData);
    }

  toTree(data) {
    let keys = _sh.keys(data);
    let nodeList = [];
    _sh.each(keys, oneKey => {
      let oneNode = {
        title: oneKey,
        key: oneKey
      }
      let values = data[oneKey]
      if (_sh.isArray(values)) {
        let tempNodes = []
        _sh.each(values, oneValue => {
          tempNodes.push(this.toTree(oneValue));
        })
        let index = 0
        let formatObjs = []
        _sh.each(tempNodes, oneTempNode => {
          let tempObj = {
            title: index.toString(),
            key: index.toString(),
            children: oneTempNode
          }
          formatObjs.push(tempObj);
          index++
        })
        oneNode['children'] = formatObjs
      } else if (_sh.isObject(values)) {
        oneNode['children'] = this.toTree(values);
      } else {
        oneNode['title'] = oneNode['title'] + ' : ' + values
        oneNode['title'] = _sh.truncate(oneNode['title'], {'length': 60});
        oneNode['isLeaf'] = true
      }
      nodeList.push(oneNode)
    })
    return nodeList;
  }

  getMenulist(menuId) {
    let menuList = JSON.parse(this.storageLocal.getData('menuList'));
    let find = null;
    if (!_.isEmpty(menuList)) {
      _.each(menuList, function (oneList) {
        if (oneList['MENU_ID'] === menuId) {
          find = oneList;
        }
      });
    }
    return find;
  };

  getAppList(listId) {
    var appList = JSON.parse(this.storageLocal.getData('appList'));
    var find = null;
    if (!_.isEmpty(appList)) {
      _.each(appList, function (oneList) {
        if (oneList['LIST_ID'] === listId) {
          find = oneList;
        }
      });
    }
    return find;
  }

    thousands(value: any, dplaces: any) {
    let result = null;
    if (this.Base_HasValue(value)) {
      if (_sh.isNumber(value)) {
        value = parseFloat(value);
        value = value.toFixed(dplaces);
        value = value.toString().split(".");  // 分隔小数点
        let arr = value[0].split("").reverse();  // 转换成字符数组并且倒序排列
        let res = [];
        for (let i = 0, len = arr.length; i < len; i++) {
          if (i % 3 === 0 && i !== 0) {
            res.push(",");   // 添加分隔符
          }
          res.push(arr[i]);
        }
        res.reverse(); // 再次倒序成为正确的顺序
        if (value[1]) {  // 如果有小数的话添加小数部分
          result = res.join("").concat("." + value[1]);
        } else {
          result = res.join("");
        }
      }
    }
    return result;
  }

  changeToChineseMoney(Num) {
    //判断如果传递进来的不是字符的话转换为字符
    if (typeof Num == "number") {
      Num = new String(Num);
        }
        ;
    Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
    Num = Num.replace(/ /g, "") //替换tomoney()中的空格
    Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
    if (isNaN(Num)) { //验证输入的字符是否为数字
      //alert("请检查小写金额是否正确");
      return "";
        }
        ;
    //字符处理完毕后开始转换，采用前后两部分分别转换
    var part = String(Num).split(".");
    var newchar = "";
    //小数点前进行转化
    for (var i = part[0].length - 1; i >= 0; i--) {
      if (part[0].length > 10) {
        return "";
        //若数量超过拾亿单位，提示
      }
      var tmpnewchar = ""
      var perchar = part[0].charAt(i);
      switch (perchar) {
        case "0":
          tmpnewchar = "零" + tmpnewchar;
          break;
        case "1":
          tmpnewchar = "壹" + tmpnewchar;
          break;
        case "2":
          tmpnewchar = "贰" + tmpnewchar;
          break;
        case "3":
          tmpnewchar = "叁" + tmpnewchar;
          break;
        case "4":
          tmpnewchar = "肆" + tmpnewchar;
          break;
        case "5":
          tmpnewchar = "伍" + tmpnewchar;
          break;
        case "6":
          tmpnewchar = "陆" + tmpnewchar;
          break;
        case "7":
          tmpnewchar = "柒" + tmpnewchar;
          break;
        case "8":
          tmpnewchar = "捌" + tmpnewchar;
          break;
        case "9":
          tmpnewchar = "玖" + tmpnewchar;
          break;
      }
      switch (part[0].length - i - 1) {
        case 0:
          tmpnewchar = tmpnewchar + "元";
          break;
        case 1:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
          break;
        case 2:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
          break;
        case 3:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
          break;
        case 4:
          tmpnewchar = tmpnewchar + "万";
          break;
        case 5:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
          break;
        case 6:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
          break;
        case 7:
          // @ts-ignore
          if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
          break;
        case 8:
          tmpnewchar = tmpnewchar + "亿";
          break;
        case 9:
          tmpnewchar = tmpnewchar + "拾";
          break;
      }
      var newchar = tmpnewchar + newchar;
    }
    //小数点之后进行转化
    if (Num.indexOf(".") != -1) {
      if (part[1].length > 2) {
        // alert("小数点之后只能保留两位,系统将自动截断");
        part[1] = part[1].substr(0, 2)
      }
      for (i = 0; i < part[1].length; i++) {
        tmpnewchar = ""
        perchar = part[1].charAt(i)
        switch (perchar) {
          case "0":
            tmpnewchar = "零" + tmpnewchar;
            break;
          case "1":
            tmpnewchar = "壹" + tmpnewchar;
            break;
          case "2":
            tmpnewchar = "贰" + tmpnewchar;
            break;
          case "3":
            tmpnewchar = "叁" + tmpnewchar;
            break;
          case "4":
            tmpnewchar = "肆" + tmpnewchar;
            break;
          case "5":
            tmpnewchar = "伍" + tmpnewchar;
            break;
          case "6":
            tmpnewchar = "陆" + tmpnewchar;
            break;
          case "7":
            tmpnewchar = "柒" + tmpnewchar;
            break;
          case "8":
            tmpnewchar = "捌" + tmpnewchar;
            break;
          case "9":
            tmpnewchar = "玖" + tmpnewchar;
            break;
        }
        if (i == 0) tmpnewchar = tmpnewchar + "角";
        if (i == 1) tmpnewchar = tmpnewchar + "分";
        newchar = newchar + tmpnewchar;
      }
    }
    //替换所有无用汉字
    while (newchar.search("零零") != -1)
      newchar = newchar.replace("零零", "零");
    newchar = newchar.replace("零亿", "亿");
    newchar = newchar.replace("亿万", "亿");
    newchar = newchar.replace("零万", "万");
    newchar = newchar.replace("零元", "元");
    newchar = newchar.replace("零角", "");
    newchar = newchar.replace("零分", "");
    if (newchar.charAt(newchar.length - 1) == "元") {
      newchar = newchar + "整"
    }
    if (newchar == "元整") newchar = "零元整";
    return newchar;
  }

  getFormType(pMenuObj) {
    let formType = '';
    switch (pMenuObj.MENU_URL) {
      case 'index.list':
        formType = 'listForm';
        break;
      case 'index.report':
        formType = 'reportForm';
        break;
      case 'index.onePageReport':
        formType = 'onePageReportForm';
        break;
      case 'index.roleForm':
        formType = 'roleForm';
        break;
      case 'index.workbenchForm':
        formType = 'workbenchForm';
        break;
      case 'index.treeForm':
        formType = 'treeForm';
        break;
      case 'index.dashboardForm':
        formType = 'dashboardForm';
        break;
            case 'index.outForm':
                formType = 'outForm';
                break;
      case 'index.reportTableau':
        this.confirmModal.show('warning', {
          'title': '提示',
          'content': '暂未开放tablue',
        });
        break;
      default:
        formType = 'customForm';
        break;
    }
    return formType;
  }

  Base_HasValue(pValue) {
    if (_.isBoolean(pValue)) {
      return true;
    } else if (_.isNumber(pValue)) {
      return true;
    } else if (_.isNull(pValue)) {
      return false;
    } else if (_.isDate(pValue)) {
      return true;
    } else if (_.isUndefined(pValue)) {
      return false;
    } else if (pValue == "") {
      return false;
    } else {
      return !_.isEmpty(pValue);
    }
  }


  Base_Contains(pArr, pVal) {
    return pArr.indexOf(pVal) > -1;
  }


  Base_getApplist(pListID) {
    let appList = this.storageLocal.getData('appList');
    let find = null;
    if (!_.isEmpty(appList)) {
      appList = JSON.parse(appList);
      _.each(appList, function (oneList) {
        if (oneList['LIST_ID'] === pListID) {
          find = oneList;
        }
      });
    }
    return find;
  }

  Base_getAppListSearchId(pListID) {
    let appList = this.Base_getApplist(pListID);
    let SEARCH_ID = null;
    if (_.has(appList, 'SEARCH_ID')) {
      SEARCH_ID = appList.SEARCH_ID;
    }
    return SEARCH_ID;
  };

  Base_doShowConfirm(pType, pMessage) {
    this.confirmModal.show(pType, {
      'title': '温馨提示',
      'content': pMessage
    });
  }


  Base_getAppListUpdateId(pListID) {
    let appList = this.Base_getApplist(pListID);
    let SEARCH_ID = null;
    if (_.has(appList, 'UPDATE_ID')) {
      SEARCH_ID = appList.UPDATE_ID;
    }
    return SEARCH_ID;
  }


  /**
   * 删除数组的空元素，并且压缩
   * @param pArr
   * @constructor
   */
  Base_ArrayDelEmptyAndUniq(pArr) {
    let arr01 = _sh.uniq(pArr);
    let temp01 = [];
    _sh.each(arr01, oneRow=>{
      if (this.Base_HasValue(oneRow)) {
        temp01.push(oneRow);
      }
    })
    return temp01;
  }

  Base_getMenulist(pListID) {
    let appList = this.storageLocal.getData('menuList');
    let find = null;
    if (!_.isEmpty(appList)) {
      appList = JSON.parse(appList);
      _.each(appList, function (oneList) {
        if (oneList['MENU_ID'] === pListID) {
          find = oneList;
        }
      });
    }
    return find;
  };

  Base_compare(A, B) {
    let tmpA = A;
    let tmpB = B;

    if (_.isUndefined(tmpA)) {
      tmpA = '';
    }

    if (_.isUndefined(tmpB)) {
      tmpB = '';
    }

    if (_.isNull(tmpA)) {
      tmpA = '';
    }
    if (_.isNull(tmpB)) {
      tmpB = '';
    }
    if (_.isNumber(tmpA)) {
      tmpA = String(tmpA);
    }

    if (_.isNumber(tmpB)) {
      tmpB = String(tmpB);
    }

    if (_.isArray(tmpA)) {
      tmpA = String(tmpA);
    }

    if (_.isArray(tmpB)) {
      tmpB = String(tmpB);
    }

    tmpA = tmpA.toUpperCase();
    tmpB = tmpB.toUpperCase();

    return tmpA === tmpB;
  };

  Base_getParamCondtions(pCondtions, pMENU_ID) {
    let con = {};
    _.each(pCondtions, function (OneGroup) {
      _.each(OneGroup['columns'], function (OneColumns) {
        if (this.Base_HasValue(OneColumns['model'])) {
          if (this.Base_compare(OneColumns['MENU_ID'], pMENU_ID)) {
            con[OneColumns['column']] = OneColumns['model'];
          }
        }
      });
    });
    return con;
  };

  Base_getJsonValue(pObj, pPer, pDef) {
    let tmpValue = null;
    if (_.has(pObj, pPer)) {
      if (this.Base_HasValue(pObj[pPer])) {
        tmpValue = pObj[pPer];
      }
    }
    if (!this.Base_HasValue(tmpValue)) {
      if (this.Base_HasValue(pDef)) {
        tmpValue = pDef;
      }
    }
    return tmpValue;
  };

  Base_getUuid() {
    let len = 32;//32长度
    let radix = 16;//16进制
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | Math.random() * radix];
      }
    } else {
      let r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    let result = uuid.join('');
    result.toLocaleLowerCase();
    return result;
  }

  Base_resHasErr(res) {
    let err = false;
    if (_.has(res, 'status')) {
      if (res.status === 200) {
        err = false;
      } else {
        err = true;
      }
    } else {
      err = false;
    }
    return err;
  }

  Base_commitPoint_Def() {
    return ['add', 'modify'];
  }

  DeepCopy(oldObj: any) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      for (let i in oldObj) {
        newObj[i] = this.DeepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  Base_getFileType(fileName) {
    let sInit = '';
    let array = fileName.split('.');
    if (this.Base_HasValue(array)) {
      sInit = array[array.length - 1];
    }

    return sInit;
  }

  Base_getFileNameOnly(pFileName) {
    let fileName = pFileName;
    try {
            if (_sh.startsWith(fileName, "http"))
      fileName = decodeURI(fileName);

    if (this.Base_HasValue(fileName)) {
      let fileType = this.Base_getFileType(fileName);
      fileName = _sh.chain(fileName)
        .split('/')
        .takeRight(1)
                    .replace('.' + fileType, '')
        .split('_')
        .take(1)
        .join('')
        .value();
      if (this.Base_HasValue(fileType)) fileName = fileName + '.' + fileType;
    }
    } catch (e) {
      console.error(e)
    }
    return fileName;
  }

  Base_getFileName(fileName) {
    let sInit = '';
    let array = fileName.split('.');
    if (this.Base_HasValue(array)) {
      sInit = array[array.length - 1];
      if (this.Base_HasValue(sInit)) {
        fileName = this.Base_replace(fileName, '.' + sInit, '');
      }
    }
    return fileName;
  }

  Base_getDef(pValue, pDef) {
    if (!this.Base_HasValue(pValue)) {
      return pDef;
    } else {
      return pValue;
    }
  }

  Base_replace(pValue, pOld, pNew) {
    const reg = new RegExp(pOld, 'g'); //创建正则RegExp对象
    let stringObj = pValue;
    return stringObj.replace(reg, pNew);
  };

  DoChecking(pStr) {
    if (pStr == null) {
      pStr = '成功!';
    }
    return this.confirmModal.show('success', {
      title: pStr
    });
  }

  DoErroring(pStr) {
    if (pStr == null) {
      pStr = '错误!';
    } else if (pStr === 'cancel dismiss') {
      return;
    }
    return this.confirmModal.show('error', {
      title: pStr
    });
  }

  DoChecked(pStr) {
    if (pStr == null) {
      pStr = '成功!';
    }
    return this.confirmModal.show('success', {title: pStr});
  }

  DoJavaChecked(pStr) {
    if (pStr == null) {
      pStr = '成功!';
    }
    return this.confirmModal.show('success', {title: pStr});
  }

  DoJavaErrored(pStr) {
    if (pStr == null) {
      pStr = '失败!';
    }
    return this.confirmModal.show('error', {title: pStr});
  }

  DoErrored(pStr) {
    if (pStr == null) {
      pStr = '失败!';
    }
    return this.confirmModal.show('error', {title: pStr});
  }

  Base_Checking(pStr) {
    return this.DoChecking(pStr);
  };

  Base_Checking_Java = function (pStr) {
    return this.DoJavaChecked(pStr);
  };

  Base_Erroring_Java = function (pStr) {
    return this.DoJavaErrored(pStr);
  };

  Base_Erroring = function (pStr) {
    return this.DoErroring(pStr);
  };

  Base_Checked = function (pStr) {
    return this.DoChecked(pStr);
  };

  Base_Errored = function (pStr) {
    return this.DoErrored(pStr);
  };

  // 调用java后台方法返回的json对象
  Base_JAVA_Checked(repJson) {
    let pStr = '网络异常';
    if (repJson.data != null && repJson.data.status === 500) {
      pStr = repJson.data.message;
      return this.DoJavaErrored(pStr);
    } else if (repJson.data != null) {
      pStr = repJson.data.message;
      return this.DoJavaChecked(pStr);
    }
    return this.DoJavaErrored(pStr);
  };

  // 调用java后台方法返回的失败信息
  Base_JAVA_ERROR(repJson) {
    let pStr = '网络异常';
    if (repJson.data != null) {
      pStr = repJson.data.message;
    }
    return this.DoJavaErrored(pStr);
  };

  /*
  数字转中文
  @number {Integer} 形如123的数字
  @return {String} 返回转换成的形如 一百二十三 的字符串
  */
  NumberToChinese(number) {
    let units = '个十百千万@#%亿^&~';
    let chars = '零一二三四五六七八九';
    let a = (number + '').split(''), s = [], t = this;
    if (a.length > 12) {
      throw new Error('too big');
    } else {
      for (let i = 0, j = a.length - 1; i <= j; i++) {
        if (j == 1 || j == 5 || j == 9) {//两位数 处理特殊的 1*
          if (i == 0) {
            if (a[i] != '1') {
              s.push(chars.charAt(+a[i]));
            }
          } else {
            s.push(chars.charAt(+a[i]));
          }
        } else {
          s.push(chars.charAt(+a[i]));
        }
        if (i != j) {
          s.push(units.charAt(j - i));
        }
      }
    }
    //return s;
    return s.join('').replace(/零([十百千万亿@#%^&~])/g, (m, d, b) => {//优先处理 零百 零千 等
      b = units.indexOf(d);
      if (b != -1) {
        if (d == '亿') {
          return d;
        }
        if (d == '万') {
          return d;
        }
        if (a[b] == '0') {
          return '零';
        }
      }
      return '';
    }).replace(/零+/g, '零').replace(/零([万亿])/g, (m, b) => {// 零百 零千处理后 可能出现 零零相连的 再处理结尾为零的
      return b;
    }).replace(/亿[万千百]/g, '亿').replace(/[零]$/, '').replace(/[@#%^&~]/g, (m) => {
      return {'@': '十', '#': '百', '%': '千', '^': '十', '&': '百', '~': '千'}[m];
    }).replace(/([亿万])([一-九])/g, (m, d, b, c) => {
      c = units.indexOf(d);
      if (c != -1) {
        if (a[c] == '0') {
          return d + '零' + b;
        }
      }
      return m;
    });
  }

  conver(limit) {
    let size = '';
    if (limit < 0.1 * 1024) { //如果小于0.1KB转化成B
      size = limit.toFixed(2) + 'B';
    } else if (limit < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB
      size = (limit / 1024).toFixed(2) + 'KB';
    } else if (limit < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + 'MB';
    } else if (limit < 0.1 * 1024 * 1024 * 1024 * 1024) { //如果小于0.1T转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    } else { //其他转化成T
      size = (limit / (1024 * 1024 * 1024 * 1024)).toFixed(2) + 'T';
    }

    let sizestr = size + '';
    let len = sizestr.indexOf('\.');
    let dec = sizestr.substr(len + 1, 2);
    if (dec == '00') {//当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
  }

  // 转为MB
  converToMb(size) {
    return (size / (1024 * 1024)).toFixed(3);
  }

  //判断数组中是否有重复 true 有重复
  Base_isRepeat(arr) {
    let hash = {};
    for (let i in arr) {
      if (hash[arr[i]]) {
        return true;
      }
      hash[arr[i]] = true;
    }
    return false;
  }

  syntaxHighlight(json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  //javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
  Base_accAdd(arg1, arg2) {
    let r1, r2, m;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  }

////给Number类型增加一个add方法，调用起来更加方便。
//    Number.prototype.add = function (arg){
//        return Base_accAdd(arg,this);
//    } ;
  //javascript的减法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
  Base_accSub(arg1, arg2) {
    let r1, r2, m, n;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    //last modify by deeka
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  }

  //乘法,用来得到精确的乘法结果；说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
  Base_accMul(arg1, arg2) {
    let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {
    }
    try {
      m += s2.split('.')[1].length;
    } catch (e) {
    }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
  }

  ///除法函数，用来得到精确的除法结果;说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
  Base_accDiv(arg1, arg2) {
    let t1 = 0, t2 = 0, r1, r2;
    try {
      t1 = arg1.toString().split('.')[1].length;
    } catch (e) {
    }
    try {
      t2 = arg2.toString().split('.')[1].length;
    } catch (e) {
    }
    r1 = Number(arg1.toString().replace('.', ''));
    r2 = Number(arg2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  }

  getChildren(pNode, pNodeList) {
    let nodeResult = {
      siteId: pNode.SITE_ID,
      siteName: pNode.SITE_ID_DICT,
      title: pNode.name,
      key: pNode.code,
      selected: pNode['selected'] || false,
      checked: pNode['checked'] || false,
      disableCheckbox: pNode['disableCheckbox'] || false
    };
    let childNodes = _.filter(pNodeList, (item) => {
      return item['UP_CHANNEL_ID'] === pNode['code'];
    });
    childNodes = _.sortBy(childNodes, 'CHANNEL_ORDER');
    if (this.Base_HasValue(childNodes)) {
      nodeResult['children'] = [];
      for (let oneData of childNodes) {
        nodeResult['children'].push(this.getChildren(oneData, pNodeList));
      }
    }
    return nodeResult;
  }

  Base_ChannelData(pData) {
    let nodeData = [];
    if (this.Base_HasValue(pData)) {
      let firstNodes = _.filter(pData, (item) => {
        return !item['UP_CHANNEL_ID'];
      });
      firstNodes = _.sortBy(firstNodes, 'CHANNEL_ORDER');
      // 获取第一节点
      if (this.Base_HasValue(firstNodes)) {
        for (let oneNode of firstNodes) {
          let oneFirstNode = this.getChildren(oneNode, pData);
          nodeData.push(oneFirstNode);
        }
      }
    }
    return nodeData;
  }


}
