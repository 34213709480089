import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";
import {RequestDataService} from "../../../service/request-data.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {JumpCenterService} from "../../../service/jump-center.service";
import {DataCenterService} from "../../../service/data-center.service";

@Component({
    selector: 'app-descriptions-edit-table',
    templateUrl: './descriptions-edit-table.component.html',
    styleUrls: ['./descriptions-edit-table.component.scss']
})
export class DescriptionsEditTableComponent implements OnInit {

    @Input() oneTab: any;
    @Input() oneGroup: any;
    @Input() aggregateListData: any;
    @Input() sqlFileListData: any;
    @Input() execButton: any;

    lv02Count = 0;
    lv02ShowColspan = "";
    visible: any;

    constructor(private utils: UtilsService,
                private dataCenterService: DataCenterService,
                private confirmModal: ConfirmModalService,
                private jumpCenterService: JumpCenterService,
                private requestService: RequestDataService) {
    }

    ngOnInit() {
        if (this.utils.Base_HasValue(this.execButton)) {
            let groupEditType = _sh.get(this.execButton,['groupEditType'], null);
            if (!this.utils.Base_HasValue(groupEditType)) {
                this.confirmModal.show('error', {
                    'title': '执行错误',
                    'content': "请填写 buttons 之下的 groupEditType"
                });
            }
            this.visible = _.contains(['editInNewTable', 'editInOldTable'], this.execButton['groupEditType']);
            _sh.each(this.execButton['columns'], oneColumn => {
                let inMoreBtn = _sh.get(oneColumn, ['inMoreBtn'], false);
                let visible = _sh.get(oneColumn, ['visible'], true);
                _sh.set(oneColumn, ['lv02Visible'], false); // 默认初始化2级的都不显示
                if (!inMoreBtn) {
                    // inMoreBtn = false , 代表在表面，一级数据
                    _sh.set(oneColumn, ['lv01Visible'], visible);
                } else {
                    // inMoreBtn = true , 代表在展开数据层，二级数据
                    _sh.set(oneColumn, ['lv01Visible'], false); // 在一级层，不显示 二级数据
                    _sh.set(oneColumn, ['lv02Visible'], visible); // 二级数据，根据 visible 的属性来控制
                }
            });

            let lv01Count = _sh.filter(this.execButton['columns'], oneColumn => {
                return _sh.get(oneColumn, ['lv01Visible'], false);
            });
            lv01Count = lv01Count.length;

            let lv02Count = _sh.filter(this.execButton['columns'], oneColumn => {
                return _sh.get(oneColumn, ['lv02Visible'], false);
            });
            lv02Count = lv02Count.length;
            this.lv02Count = lv02Count;
            this.lv02ShowColspan = _sh.toString(lv01Count + 1); // 第二行的显示总宽度等于签名所有行
        }
    }
}
