import {Component, Input, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';
import {RequestDataService} from '../../../../service/request-data.service';
import {UtilsService} from '../../../../service/utils.service';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _sh from 'lodash';

@Component({
  selector: 'app-subject-confer-degree-iiii',
  templateUrl: './subject-confer-degree-IIII.component.html',
  styleUrls: ['../subject-visual-report.component.css', './subject-confer-degree-IIII.component.css']
})
export class SubjectConferDegreeIIIIComponent implements OnInit {

  isfull: any;
  nowYear: any = '';
  nowDate =null;
  areaHeight = '34vh';
  centerAreaHeight = '70vh';
  // 仪器设备设施情况
  insturmentEquipmentData = {};
  // 科技创新基地情况
  scienceInnovationData = {};
  // 近5年奖助学金情况
  aidInfoData = [];

  // 近5年参加国际国内学术交流情况
  academicComData = {
    // 主办、承办国际或全国性学术年会（次)
    hostMetting: 0,
    // 在国际或全国性学术年会上做主题、主旨报告（次）
    attendMetting: 0,
    // 邀请境外专家讲座报告（次）
    academicExchange: 0,
    // 资助师生参加国际国内学术交流专项经费（万元）
    specialFunds: 0,
    specialFundsPingJun:0,
  };

  model: any = {
    left1: {},
    center: {},
    right1: {},
    right2: {}
  };

  constructor(
    private requestDataService: RequestDataService,
    private utils: UtilsService) {
  }

  formaterStr(params, index, fontCount) {
    let newParamsName = '';// 最终拼接成的字符串
    let paramsNameNumber = params.length;// 实际标签的个数
    let provideNumber = fontCount;// 每行能显示的字的个数
    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
    // 条件等同于rowNumber>1
    if (paramsNameNumber > provideNumber) {
      /** 循环每一行,p表示行 */
      for (let p = 0; p < rowNumber; p++) {
        let tempStr = '';// 表示每一次截取的字符串
        let start = p * provideNumber;// 开始截取的位置
        let end = start + provideNumber;// 结束截取的位置
        // 此处特殊处理最后一行的索引值
        if (p == rowNumber - 1) {
          // 最后一次不换行
          tempStr = params.substring(start, paramsNameNumber);
        } else {
          // 每一次拼接字符串并换行
          tempStr = params.substring(start, end) + '\n';
        }
        newParamsName += tempStr;// 最终拼成的字符串
      }
    } else {
      // 将旧标签的值赋给新标签
      newParamsName = params;
    }
    //将最终的字符串返回
    return newParamsName;
  }

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  // 科技创新基地情况
  scienceInnovation(pListData) {
    let downTitle = ['国家级科技创新基地数', '省部级科技创新基地数'];
    let option = {
      grid: {
        show: false,
        left: '2%',
        itemHeight: 10,
        top: '6%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: (params, index) => {
              return this.formaterStr(params, index, 6);
            },
            margin: 14
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
        }
      ],
      series: [
        {
          type: 'bar',
          data: [pListData['COUNTRY_SCIENCE'], pListData['PROVINCE_SCIENCE']],
          barWidth: '20%',
          yAxisIndex: 0,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          },
          itemStyle: {
            normal: {
              // 定制显示（按顺序）
              color: function (params) {
                let colorList = ['#2887ED', '#F8525A'];
                return colorList[params.dataIndex];
              }
            },
          }
        }
      ]
    };
    return option;
  }

  // 近五年奖学金情况
  scholarship(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    // 图例
    let legend = ['奖学金', '项目数'];
    let awardData = [];
    let projectData = [];
    for (let one of downTitle) {
      let project = 0;
      let waard = 0;
      let findData = _sh.find(pListData, (item) => {
        return item['AWARD_YEAR'] === one;
      });
      if (this.utils.Base_HasValue(findData)) {
        project = findData['PROJECT_NUM'] || 0;
        waard = findData['SCHOLARSHIP_MONEY'] || 0;
      }
      awardData.push(waard);
      projectData.push(project);
    }
    let option = {
      legend: {
        data: legend,
        icon: 'roundRect',
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: '{value}年'
          }
        }
      ],
      yAxis: [
        {
          name: '奖学金（万元）',
          type: 'value',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 0, 25]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: '项目数（个）',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 0, 0]
          },
          splitLine: {show: false},
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          type: 'bar',
          data: awardData,
          name: '奖学金',
          barWidth: '40%',
          yAxisIndex: 0,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          },
          itemStyle: {
            normal: {
              color: '#3090EF'
            },
          }
        },
        {
          name: '项目数',
          type: 'line',
          yAxisIndex: 1,
          data: projectData,
          smooth: true,
          lineStyle: {
            width: 5
          },
          itemStyle: {
            normal: '#FF9960',
          }
        }
      ]
    };
    return option;
  }

  // 近五年助学金情况
  financialAid(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    // 图例
    let legend = ['助学金', '项目数'];
    let awardData = [];
    let projectData = [];
    for (let one of downTitle) {
      let project = 0;
      let waard = 0;
      let findData = _sh.find(pListData, (item) => {
        return item['AWARD_YEAR'] === one;
      });
      if (this.utils.Base_HasValue(findData)) {
        project = findData['GRANT_PROJECT_NUM'] || 0;
        waard = findData['A_GRANT'] || 0;
      }
      awardData.push(waard);
      projectData.push(project);
    }
    let option = {
      legend: {
        data: legend,
        icon: 'roundRect',
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: '{value}年'
          }
        }
      ],
      yAxis: [
        {
          name: '助学金（万元）',
          type: 'value',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 0, 25]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: '项目数（个）',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 0, 0]
          },
          splitLine: {show: false},
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          type: 'bar',
          data: awardData,
          name: '助学金',
          barWidth: '40%',
          yAxisIndex: 0,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          },
          itemStyle: {
            normal: {
              color: '#FC7C5E'
            },
          }
        },
        {
          name: '项目数',
          type: 'line',
          yAxisIndex: 1,
          data: projectData,
          smooth: true,
          lineStyle: {
            width: 5
          },
          itemStyle: {
            normal: {
              color: '#3BC985'
            },
          }
        }
      ]
    };
    return option;
  }

  // 近5年参加国际国内学术交流情况
  academicCom(pListData) {
    let downTitle = ['主办、承办国际或全国性学术年会（次）', '在国际或全国性学术年会上做主题、主旨报告（次）', '邀请境外专家讲座报告（次）'];
    // 图例
    let legend = ['累计', '年均'];
    let option = {
      legend: {
        data: legend,
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 40, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: (params, index) => {
              return this.formaterStr(params, index, 10);
            },
          }
        }
      ],
      yAxis: [
        {
          name: '次数',
          type: 'value',
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 20, 0, 0]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          type: 'bar',
          data: [pListData['hostMetting'], pListData['attendMetting'], pListData['academicExchange']],
          name: '累计',
          yAxisIndex: 0,
          barWidth: '20%',
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          },
          itemStyle: {
            normal: {
              color: '#2A89EE'
            },
          }
        },
        {
          name: '年均',
          type: 'bar',
          yAxisIndex: 0,
          barWidth: '20%',
          data: [+(pListData['hostMetting'] / 5).toFixed(1), +(pListData['attendMetting'] / 5).toFixed(1),
            +(pListData['academicExchange'] / 5).toFixed(1)],
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          },
          itemStyle: {
            normal: {
              color: '#FB6D5C'
            },
          }
        }
      ]
    };
    return option;
  }

  initData() {
    this.model.left1 = this.scienceInnovation(this.scienceInnovationData);
    this.model.right1 = this.scholarship(this.aidInfoData);
    this.model.right2 = this.financialAid(this.aidInfoData);
    this.model.center = this.academicCom(this.academicComData);
  }

  ngOnInit() {
    this.nowYear = moment().format('YYYY');
    this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    setInterval(() => {
      this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    },1000);
    let conditon = {
      EVALUATION_YEAR_BEGIN: +this.nowYear - 5,
      EVALUATION_YEAR_END: +this.nowYear
    };
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_instrument_equipment.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_science_innovation.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_aid_info.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_host_meeting_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_attend_meeting_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_academic_exchange_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/ms_attend_meeting_money.json', conditon),
    );
    parallel.subscribe(response => {
      if (this.utils.Base_HasValue(response[0]['data'])) {
        this.insturmentEquipmentData = response[0]['data'][0];
      }
      if (this.utils.Base_HasValue(response[1]['data'])) {
        this.scienceInnovationData = response[1]['data'][0];
      }
      if (this.utils.Base_HasValue(response[2]['data'])) {
        this.aidInfoData = response[2]['data'];
      }
      if (this.utils.Base_HasValue(response[3]['data'])) {
        this.academicComData.hostMetting = response[3]['data'][0]['acount'];
      }
      if (this.utils.Base_HasValue(response[4]['data'])) {
        this.academicComData.attendMetting = response[4]['data'][0]['acount'];
      }
      if (this.utils.Base_HasValue(response[5]['data'])) {
        this.academicComData.academicExchange = response[5]['data'][0]['acount'];
      }
      if (this.utils.Base_HasValue(response[6]['data'])) {
        this.academicComData.specialFunds = response[6]['data'][0]['money'];
        this.academicComData.specialFundsPingJun = +(this.academicComData.specialFunds / 5).toFixed(1);
      }
      // 初始化数据
      this.initData();
    });
  }

}
