import {Injectable} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  public modalRef: any = null;

  constructor(private modalService: NzModalService) {
  }

  /**
   * [show 通用提示框]
   * @param {[type]} type     [提示框类型 confirm/info/success/error/warning]
   * @param {[type]} alertObj [title: 提示标题 content: 提示内容 suc: 确认回调 cancel: 取消回调]
   */
  show(type, alertObj, nzWidth?) {
    let modalObj = {
      nzTitle: alertObj.title,
      nzWidth: nzWidth || 520,
      nzContent: alertObj.content,
      nzWrapClassName: 'vertical-center-modal'
    };
    if (alertObj.suc && typeof (alertObj.suc) === 'function') {
      modalObj['nzOnOk'] = alertObj.suc;
    }
    if (alertObj.cancel && typeof (alertObj.cancel) === 'function') {
      modalObj['nzOnCancel'] = alertObj.cancel;
    }
    switch (type) {
      case 'confirm':
        this.modalRef = this.modalService.confirm(modalObj);
        break;
      case 'info':
        this.modalRef = this.modalService.info(modalObj);
        break;
      case 'success':
        this.modalRef = this.modalService.success(modalObj);
        break;
      case 'error':
        this.modalRef = this.modalService.error(modalObj);
        break;
      case 'warning':
        this.modalRef = this.modalService.warning(modalObj);
        break;
      default:
        this.modalRef = this.modalService.info(modalObj);
        break;
    }

    this.modalRef.afterOpen.subscribe(result => {
    });

    if (alertObj.timeout) {
      window.setTimeout(() => this.modalService.closeAll(), alertObj.timeout);
    }
  }
}
