import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {DataService} from '../data.service';
import {DataCenterService} from '../../../service/data-center.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {UtilsService} from '../../../service/utils.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';

@Component({
  selector: 'app-picture-detail',
  templateUrl: './picture-detail.component.html',
  styleUrls: ['../picture-edit/picture-edit.component.css', './picture-detail.component.css']
})
export class PictureDetailComponent implements OnInit {

  @Input() parentData: any;
  modelList = [];
  nowModel = {
    code: ''
  };
  models: any = [];
  data: any = {};
  selectedIndex = 0;
  constructor(
    private requestData: RequestDataService,
    private dataService: DataService,
    private dataCenterService: DataCenterService,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService) {
  }

  // 切换扩展模型
  checkModel(oneData) {
    this.nowModel = oneData;
    let index = _.findIndex(this.models, (item) => {
      return item['modelId'] === this.nowModel['modelId'];
    });
    this.selectedIndex = index;
  }

  gotoBack() {
    this.transformData('customForm', this.parentData);
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-picture-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  dataFormat() {
    const data = JSON.parse(this.data.RESOURCES_JSON);
    this.nowModel = data.models[0];
    this.models = data.models;
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dr/dr_picture_list.json', {RESOURCES_ID: this.parentData.RESOURCES_ID}),
      this.requestData.getDataByCondition('digitalResources/dict/MODEL_NAME.json')
    );
    search.subscribe(
      values => {
        this.data = values[0]['data'][0];
        this.modelList = values[1]['data'] || [];
        this.dataFormat();
      });
  }

}
