
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {DataCenterService} from '../../../service/data-center.service';
import {DataService} from '../../../project/headLine/data.service';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import {UserInfoService} from '../../../service/user-info.service';
import {FormModalComponent} from '../../../basicComponent/form-modal/form-modal.component';
import {NzModalService} from 'ng-zorro-antd';

@Component({
  selector: 'app-apply-file-detail',
  templateUrl: './apply-file-detail.component.html',
  styleUrls: ['./apply-file-detail.component.css']
})
export class ApplyFileDetailComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;

  // 申请人信息
  public userData: any = {};
  // 申请信息
  public applyData: any = {};
  // 文件列表
  public fileList: any = [];

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private confirmModal: ConfirmModalService,
              private userInfo: UserInfoService,
              private modalService: NzModalService) {
  }

  gotoBack() {
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        if (!_.isEmpty(item['nowMenu'].MENU_UPDATE_URL)) {
          item = _.extend(item, this.parentData);
          item['formType'] = 'listForm';
          item['data'] = {};
          // item["detailCondition"] = {};
          item['conditionColumns'] = {};
        }
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 审核通过
  pass() {
    const params = {
      APPLY_ID: this.applyData['ID'],
      GROUP_ID: this.applyData['GROUP_ID'],
      GROUP_NAME: this.applyData['GROUP_NAME'],
      GROUP_DESC: this.applyData['GROUP_DESC'],
      USER_ID: this.applyData['ADD_USERID'],
      USER_NAME: this.applyData['ADD_NAME'],
      DATASET_ID: this.applyData['DATASET_ID'],
      DATASET_NAME: this.applyData['DATASET_NAME']
    };
    this.confirmModal.show('confirm', {
      title: '审核通过后，此用户将加入该用户组，且拥有该用户组的所有文件下载权限。若要取消，需要在数据空间权限配置中进行操作。',
      content: '是否继续？',
      suc: () => {
        this.dataService.filePowerPass([params]).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '审核成功',
              suc: () => {
                const user = this.userInfo.getUserInfo();
                this.applyData['AUDIT_STATE'] = 'AUDIT_PASS';
                this.applyData['AUDIT_STATE_DICT'] = '审核通过';
                this.applyData['AUDIT_USER_ID'] = user['USER_ID'];
                this.applyData['AUDIT_USER_NAME'] = user['USERNAME'];
                this.applyData['AUDIT_TIME'] = moment().format('YYYY-MM-DD HH:mm:ss');
              }
            });
          } else {
            this.confirmModal.show('error', {title: '审核失败，请刷新重试'});
          }
        });
      }
    });
  }

  // 审核拒绝
  reject() {
    this.modalService.create({
      nzTitle: '审核不通过',
      nzContent: FormModalComponent,
      nzWidth: 1000,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'REJECTED_REASON',
              'name': 'REJECTED_REASON',
              'component': 'textarea',
              'lable': '不通过原因',
              'comment': '不通过原因',
              'placeholder': '请输入不通过原因',
              'maxlength': 500,
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        if (response.childForm._validateForm.status === 'PENDING') {
          return;
        }
        if (response.childForm._validateForm.invalid) {
          for (const key in response.childForm._validateForm.controls) {
            response.childForm._validateForm.controls[key].markAsDirty();
            response.childForm._validateForm.controls[key].updateValueAndValidity();
          }
        }
        if (response.childForm._validateForm.valid) {
          const data = response.modalFormData.columns[0];
          this.dataService.filePowerReject([this.applyData['ID']], data.model).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '审核拒绝成功',
                suc: () => {
                  const user = this.userInfo.getUserInfo();
                  this.applyData['AUDIT_STATE'] = 'AUDIT_REJECT';
                  this.applyData['AUDIT_STATE_DICT'] = '审核不通过';
                  this.applyData['REFUSAL_REASON'] = data.model;
                  this.applyData['AUDIT_USER_ID'] = user['USER_ID'];
                  this.applyData['AUDIT_USER_NAME'] = user['USERNAME'];
                  this.applyData['AUDIT_TIME'] = moment().format('YYYY-MM-DD HH:mm:ss');
                }
              });
            } else {
              this.confirmModal.show('error', {title: '审核失败，请刷新重试'});
            }
          });
        } else {
          return false;
        }
      }
    });
  }

  ngOnInit() {
    const condition = this.parentData['detailCondition'];
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition('headLine/system/system_user_detail.json', {
        USER_ID: condition['APPLY_USERID']
      }),
      this.requestDataService.getDataByCondition('headLine/ds/ds_file_apply_detail.json', {
        APPLY_ID: condition['APPLY_ID']
      }),
      this.requestDataService.getDataByCondition('headLine/ds/ds_group_file_dataverse.json', {
        GROUP_ID: condition['GROUP_ID']
      })
    );
    search.subscribe(values => {
      this.applyData = values[1]['data'][0] || {};
      this.userData = values[0]['data'][0] || {};
      this.fileList = values[2]['data'] || [];
    });

  }

}
