import {Component, OnInit} from '@angular/core';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as d3 from 'd3';
import * as $ from 'jquery';
import * as _ from 'underscore';
import * as echarts from 'echarts';
import {UtilsService} from '../../../service/utils.service';
import {AjaxService} from '../../../service/ajax.service';
import {DataService} from '../data.service';
import {RequestDataService} from '../../../service/request-data.service';

@Component({
  selector: 'app-knowledge-map-management',
  templateUrl: './knowledge-map-management.component.html',
  styleUrls: ['./knowledge-map-management.component.css']
})
export class KnowledgeMapManagementComponent implements OnInit {
  relationList = [];
  entityLabels = [];
  attributesL: any;
  attributesR: any;
  neo4jApiData = {
    data: []
  };
  data: any = {
    nodes: [],
    links: []
  };
  colors = ['rgb(87, 199, 227)', 'rgb(247, 151, 103)'];
  colorGroup: any = {};
  echarRelation: any;
  mapIsModify = false;

  constructor(
    private ajaxService: AjaxService,
    private dataService: DataService,
    private requestService: RequestDataService,
    private util: UtilsService,
    private confirmModal: ConfirmModalService) {
  }

  initData(pType?) {
    this.relationList = [];
    this.attributesL = null;
    this.attributesR = null;
    //ajax使用get方式请求api数据
    this.requestService.getDataByCondition('digitalResources/dr/dr_label.json').subscribe(response => {
      this.entityLabels = response.data;
    });
    // this.dataService.requestNeo4j('/Neo4jController/getLabels').subscribe(response => {
    //   this.entityLabels = response;
    // });
    const data = {
      data: [
        {
          'type': 'SearchAll'
        }
      ]
    };
    this.dataService.requestNeo4j('/Neo4jController/autoExecute', data).subscribe(response => {
      if (this.util.Base_HasValue(response[0] && response[0]['data'])) {
        this.data = response[0]['data'];
        const labelGroup = _.groupBy(this.data.nodes, oneData => {
          return oneData['label'];
        });
        this.colorGroup = {};
        let i = 0;
        _.each(labelGroup, (value, key) => {
          if (i < this.colors.length) {
            this.colorGroup[key] = this.colors[i];
            i++;
          } else {
            i = 0;
            this.colorGroup[key] = this.colors[i];
          }
        });
        _.each(this.data.nodes, oneData => {
          oneData['itemStyle'] = {
            normal: {
              color: this.colorGroup[oneData['label']]
            }
          };
          oneData['showName'] = oneData['name'];
          oneData['name'] = oneData['uuidhdbs'];
        });
        _.each(this.data.links, item => {
          item['source'] = _.find(this.data.nodes, (n) => {
            return n['nodeId'] === item['sourceId'];
          })['name'];
          item['target'] = _.find(this.data.nodes, (n) => {
            return n['nodeId'] === item['targetId'];
          })['name'];
        });
        this.echarRelationshipDiagra();
      }
    });
  }

  clearData() {
    this.relationList = [];
    this.attributesL = null;
    this.attributesR = null;
  }

  /**
   * 添加属性
   */
  addAttribute(pdata) {
    pdata.push({
      key: '',
      value: ''
    });
  }

  addAttributesL() {
    this.attributesL = {
      'type': 'InsertOrUpdate',
      'label': '',
      'data': [],
      'listData': [
        {
          'key': 'uuidhdbs',
          'value': this.util.Base_getUuid()
        },
        {
          'key': 'name',
          'value': ''
        }
      ],
      'unique': 'uuidhdbs'
    };
  }

  addAttributesR() {
    this.attributesR = {
      'type': 'InsertOrUpdate',
      'label': '',
      'data': [],
      'listData': [
        {
          'key': 'uuidhdbs',
          'value': this.util.Base_getUuid()
        },
        {
          'key': 'name',
          'value': ''
        }
      ],
      'unique': 'uuidhdbs'
    };
  }

  addRelationList() {
    this.relationList.push({
      'value': '',
      'listData': []
    });
  }

  /**
   * 删除属性
   */
  removeAttributeL(pOneDate) {
    this.attributesL.listData = _.without(this.attributesL.listData, pOneDate);
  }

  removeAttributeR(pOneDate) {
    this.attributesR.listData = _.without(this.attributesR.listData, pOneDate);
  }

  removeAttributeC(j, pData) {
    pData.splice(j, 1);
  }

  removeAentityL() {
    this.attributesL = null;
  }

  removeAentityR() {
    this.attributesR = null;
  }

  removeRelationList(i?) {
    this.relationList.splice(i, 1);
  }

  manageData() {
    this.neo4jApiData.data = [];
    const leftData = this.changeData(this.attributesL);
    const rightData = this.changeData(this.attributesR);
    const relationData = this.changeData(this.relationList);
    if (this.util.Base_HasValue(leftData.data)) {
      this.neo4jApiData.data.push(leftData);
    }
    if (this.util.Base_HasValue(rightData.data)) {
      this.neo4jApiData.data.push(rightData);
    }
    if (this.util.Base_HasValue(leftData.data) && this.util.Base_HasValue(rightData.data)
      && this.util.Base_HasValue(relationData[0]) && this.util.Base_HasValue(relationData[0].value)) {
      const relation = {
        'type': 'InsertOrUpdateRelation',
        'relation': relationData,
        'where': [
          {
            'alias': 'n',
            'label': this.attributesL.label,
            'data': {
              'uuidhdbs': leftData.data[0].uuidhdbs
            }
          },
          {
            'alias': 'm',
            'label': this.attributesR.label,
            'data': {
              'uuidhdbs': rightData.data[0].uuidhdbs
            }
          }
        ],
        'return': 'm,n'
      };
      this.neo4jApiData.data.push(relation);
    }
  }

  /**
   * 转换数据格式
   */
  changeData(pData) {
    if (!this.util.Base_HasValue(pData)) {
      return false;
    }
    if (_.isArray(pData)) {
      const deepArr = this.util.DeepCopy(pData);
      for (const oneObj of deepArr) {
        oneObj['data'] = {};
        oneObj.listData.forEach((val) => {
          if (this.util.Base_HasValue(val['value'])) {
            oneObj['data'][val['key']] = val['value'];
          }
        });
        delete oneObj.listData;
      }
      return deepArr;
    } else {
      const deepObJ = this.util.DeepCopy(pData);
      const obj = {};
      deepObJ['data'] = [];
      deepObJ.listData.forEach((val) => {
        if (this.util.Base_HasValue(val['value'])) {
          obj[val['key']] = val['value'];
        }
      });
      if (this.util.Base_HasValue(obj)) {
        deepObJ['data'].push(obj);
      }
      delete deepObJ.listData;
      return deepObJ;
    }
  }

  /**
   * 保存
   */
  doSave() {
    this.manageData();
    if (this.util.Base_HasValue(this.neo4jApiData.data)) {
      this.dataService.requestNeo4j('/Neo4jController/autoExecute', this.neo4jApiData).subscribe(response => {
        this.mapIsModify = true;
        this.initData();
      });
    }
  }

  /**
   * 查询实体详细数据
   */
  searchEntity(pUuidhdbs, pLabel) {
    const data = {
      data: [
        {
          'type': 'SearchEntityData',
          'where': [
            {
              'label': pLabel,
              'data': {
                'uuidhdbs': pUuidhdbs
              }
            }
          ]
        }
      ]
    };
    return this.dataService.requestNeo4j('/Neo4jController/autoExecute', data);
  }

  /**
   * 查询两个实体的关系
   */
  searchRelationByEntity() {
    const data = {
      data: [
        {
          'type': 'SearchByEntity',
          'where': [
            {
              'label': this.attributesL.label,
              'data': {
                'uuidhdbs': this.attributesL.data[0].uuidhdbs
              }
            },
            {
              'label': this.attributesR.label,
              'data': {
                'uuidhdbs': this.attributesR.data[0].uuidhdbs
              }
            }
          ]
        }
      ]
    };
    return this.dataService.requestNeo4j('/Neo4jController/autoExecute', data);
  }

  makeNodeData(pNode) {
    const data = [];
    for (const key in pNode) {
      if (key !== 'nodeId') {
        const obj = {};
        obj['key'] = key;
        obj['value'] = pNode[key];
        data.push(obj);
      }
    }
    return data;
  }

  /**
   * 选择实体
   */
  choiceEntity(pOneData) {
    if ((this.attributesL && this.attributesR) ||
      (this.attributesL && pOneData.uuidhdbs === this.attributesL.data[0]['uuidhdbs']) ||
      (this.attributesR && pOneData.uuidhdbs === this.attributesR.data[0]['uuidhdbs'])
    ) {
      return false;
    }
    this.searchEntity(pOneData.uuidhdbs, pOneData.label).subscribe(rep => {
      if (this.util.Base_HasValue(rep) && rep[0].state === 'success') {
        const node = rep[0].data.nodes[0];
        delete node.id;
        delete node.label;
        if (!this.attributesL || !this.attributesL.listData) {
          this.attributesL = {
            'type': 'InsertOrUpdate',
            'label': pOneData.label,
            'data': [node],
            'listData': this.makeNodeData(node),
            'unique': 'uuidhdbs'
          };
        } else if (!this.attributesR || !this.attributesR.listData) {
          this.attributesR = {
            'type': 'InsertOrUpdate',
            'label': pOneData.label,
            'data': [node],
            'listData': this.makeNodeData(node),
            'unique': 'uuidhdbs'
          };
        }
        if (this.attributesR && this.attributesL) {
          this.relationList = [];
          this.searchRelationByEntity().subscribe(result => {
            if (this.util.Base_HasValue(result[0].data) && this.util.Base_HasValue(result[0].data.links) && result[0].state === 'success') {
              for (const data of result[0].data.links) {
                this.relationList.push({
                  'value': data.type,
                  'listData': data.data || []
                });
              }
            }
          });
        }
      }
    });
  }

  /**
   * 删除
   */
  deleteAttribute(pNode) {
    this.confirmModal.show('confirm', {
      title: '您确定删除【' + pNode.type + '】关系吗？', suc: () => {
        const data = {
          data: [
            {
              'type': 'DeleteRelation',
              'label': _.find(this.data.nodes, (n) => {
                return n['nodeId'] === pNode['sourceId'];
              })['label'],
              'relation': {
                'value': pNode.type
              },
              'where': {
                'uuidhdbs': _.find(this.data.nodes, (n) => {
                  return n['nodeId'] === pNode['sourceId'];
                })['uuidhdbs']
              }
            }
          ]
        };
        this.dataService.requestNeo4j('/Neo4jController/autoExecute', data).subscribe(response => {
          if (_.isArray(response) && response[0].state === 'success') {
            this.mapIsModify = true;
            this.initData('modify');
            this.confirmModal.show('success', {title: '删除成功'});
          } else {
            this.confirmModal.show('warning', {title: response.error.message});
          }
        });
      }
    });
  }

  deleteEntity(pEntity) {
    this.confirmModal.show('confirm', {
      title: '您确定删除【' + pEntity.showName + '】吗？', suc: () => {
        const data = {
          data: [
            {
              'type': 'Delete',
              'label': pEntity.label,
              'where': {
                'uuidhdbs': pEntity.uuidhdbs
              }
            }
          ]
        };
        this.dataService.requestNeo4j('/Neo4jController/autoExecute', data).subscribe(response => {
          if (_.isArray(response) && response[0].state === 'success') {
            this.mapIsModify = true;
            this.initData();
            this.confirmModal.show('success', {title: '删除成功'});
          } else {
            this.confirmModal.show('warning', {title: response.error.message});
          }
        });
      }
    });
  }

  /**
   * echarts关系图
   */
  echarRelationshipDiagra() {
    this.echarRelation = echarts.init(document.getElementById('echartsMap'), null, {renderer: 'svg'});
    const option = {
      tooltip: {
        formatter: function (x) {
          return x.data.des;
        }
      },
      series: [
        {
          type: 'graph',
          layout: 'force',
          symbolSize: 50,
          roam: true,
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            normal: {
              textStyle: {
                fontSize: 12
              },
              show: true,
              formatter: function (x) {
                return x.data.type;
              }
            }
          },
          force: {
            repulsion: 500,
            edgeLength: [10, 50]
          },
          draggable: true,
          focusNodeAdjacency: true,
          itemStyle: {
            normal: {
              color: '#4b565b'
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#4b565b',
              curveness: 0.2
            }
          },
          label: {
            normal: {
              show: true,
              textStyle: {},
              formatter: function (params) {
                return params.data.showName;
              }
            }
          },
          nodes: this.data.nodes,
          links: this.data.links
        }
      ]
    };
    this.echarRelation.setOption(option);
    if (!this.mapIsModify) {
      this.echarRelation.on('click', (params) => {
        if (params.componentType === 'series') {
          if (params.seriesType === 'graph') {
            if (params.dataType === 'edge') {
              // 点击到了线上。
            } else {
              // 点击到了node 实体上。
              this.choiceEntity(params.data);
              event.stopPropagation();
            }
          }
        }
      });
      this.echarRelation.on('dblclick', (params) => {
        if (params.componentType === 'series') {
          if (params.seriesType === 'graph') {
            if (params.dataType === 'edge') {
              // 点击到了线上。
              this.deleteAttribute(params.data);
              event.stopPropagation();
            } else {
              // 点击到了node 实体上。
              this.deleteEntity(params.data);
              event.stopPropagation();
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    this.initData();
  }

}
