import {Component, Input, OnInit} from '@angular/core';
import {AjaxService} from '../../../service/ajax.service';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {UserInfoService} from '../../../service/user-info.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _ from 'lodash';
import * as alasql from 'alasql';
import {DataService} from '../data.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd';
import {GroupingModelComponent} from '../grouping-model/grouping-model.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-grouping-add-edit',
  templateUrl: './grouping-add-edit.component.html',
  styleUrls: ['./grouping-add-edit.component.css']
})
export class GroupingAddEditComponent implements OnInit {
  //父级组件的数据对象
  @Input() parentData: any;

  labelsList = [];
  data: any = {
    resourcesName: '',
    siteId: '',
    channelId: '',
    labels: null,
    modelId: '',
    resourcesPicUrl: '',
    childResourcesIds: [],
    fileList: []
  };
  // 站点字典
  siteList = [];
  expandKeys = [];
  // 栏目字典
  channelList = [];
  required = false;

  showModelImg = false;
  showModelImgUrl = '';
  basicModelList = [];

  resourcesNameSort = [
    {code: 'asc', name: '升序', selected: true},
    {code: 'desc', name: '降序'}
  ];
  listSort = 'asc';

  tempArr = [];

  constructor(private requestData: RequestDataService,
              private dataService: DataService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService,
              private modalService: NzModalService) {
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      for (let i = 0; i < this.tempArr.length; i++) {
        // 小于6就从下个数组拿一个对象来
        if (this.tempArr[i]['key'].length < 6 && i !== this.tempArr.length - 1 && this.tempArr[i + 1]['key'].length > 1) {
          let nextObj = this.tempArr[i + 1]['key'].shift();
          this.tempArr[i]['key'].push(nextObj);
        } else if (this.tempArr[i]['key'].length > 6 && i !== this.tempArr.length - 1) {
          // 大于6就拿一个对象去下一个数组
          let nowObj = this.tempArr[i]['key'].pop();
          this.tempArr[i + 1]['key'].unshift(nowObj);
        }
      }
    }
  }


  manageTempArr() {
    if (this.utils.Base_HasValue(this.data.fileList)) {
      let lengthArr = this.data.fileList.length;
      let sizeNum = Math.ceil(lengthArr / 6);
      let start = 0;
      let end = 6;
      this.tempArr = [];
      for (let i = 0; i < sizeNum; i++) {
        let obj = {};
        obj['key'] = this.data.fileList.slice(start, end);
        this.tempArr.push(obj);
        start += 6;
        end += 6;
      }
    }
  }

  manageFileList() {
    if (this.utils.Base_HasValue(this.tempArr)) {
      this.data.fileList = [];
      for (let oneArr  of this.tempArr) {
        for (let one of oneArr['key']) {
          this.data.fileList.push(one);
        }
      }
    }
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestData.getDataByCondition('digitalResources/dict/CHANNEL_NAME_SHOW.json', {
      SITE_ID: pSiteId,
      CHANNEL_STATE: 'ENABLE'
    }).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        for (const oneData of this.channelList) {
          if (oneData['code'] === this.data.channelId) {
            oneData['selected'] = true;
          }
        }
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  // 排序
  listDataSort() {
    this.manageFileList();
    this.data.fileList = alasql('select * from ? where 1 = 1 order by resourcesName ' + this.listSort, [this.data.fileList]);
    this.manageTempArr();
  }

  // 返回
  gotoBack(): void {
    this.transformData('customForm', this.parentData);
  }

  // 保存
  doSave() {
    this.required = true;
    if (!this.utils.Base_HasValue(this.data.resourcesName) ||
      !this.utils.Base_HasValue(this.data.siteId) ||
      !this.utils.Base_HasValue(this.data.channelId) ||
      !this.utils.Base_HasValue(this.data.modelId)) {
      return;
    }
    if (!this.utils.Base_HasValue(this.data.childResourcesIds)) {
      return this.confirmModal.show('warning', {title: '请添加资源'});
    }
    if (!this.utils.Base_HasValue(this.data.resourcesPicUrl)) {
      return this.confirmModal.show('warning', {title: '请选择封面图片'});
    }
    this.childResourcesIds();
    const data = {
      data: this.utils.DeepCopy(this.data)
    };
    delete data.data.fileList;
    this.dataService.classifyResourceSave(data).subscribe(response => {
      if (response.state === 'success') {
        this.confirmModal.show('success', {
          title: '温馨提示',
          content: '保存成功',
          suc: () => {
            this.transformData('customForm', this.parentData);
          }
        });
      }
    });
  }

  // 添加资源
  addResources() {
    this.manageFileList();
    const data: any = {
      fileList: this.data.fileList
    };
    this.modalService.create({
      nzTitle: '资源选择',
      nzContent: GroupingModelComponent,
      nzComponentParams: data,
      nzWidth: 1200,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: (response) => {
        const selectList = _.filter(response.listData, oneData => {
          return oneData['checked'];
        });
        const concatList = this.data.fileList.concat(selectList);
        this.data.fileList = _.uniqWith(concatList, (arrVal, otherVal) =>
          arrVal['resourceId'] === otherVal['resourceId']
        );
        this.manageTempArr();
        this.childResourcesIds();
      }
    });
  }

  // 获取资源id
  childResourcesIds() {
    const ids = [];
    let i = 0;
    for (let oneArr  of this.tempArr) {
      for (let oneData of oneArr['key']) {
        ids.push(
          {
            resourcesId: oneData['resourceId'],
            order: i++
          });
      }
    }
    this.data.childResourcesIds = ids;
  }

  // 设为封面
  setCover(pObject) {
    pObject['selected'] = true;
    this.data.resourcesPicUrl = pObject['resourcesPicUrl'];
    for (let oneArr  of this.tempArr) {
      for (let oneData of oneArr['key']) {
        if (oneData['resourceId'] !== pObject['resourceId']) {
          oneData['selected'] = false;
        }
      }
    }
  }

  previewImg(pImgUrl) {
    this.showModelImg = true;
    this.showModelImgUrl = pImgUrl;
  }

  deleteImg(pOneData) {
    for (let oneArr  of this.tempArr) {
      for (let oneData of oneArr['key']) {
        if (oneData['resourceId'] === pOneData['resourceId']) {
          oneArr['key'] = _.without(oneArr['key'], oneData);
        }
      }
    }
    if (pOneData.selected) {
      this.data.resourcesPicUrl = '';
    }
    this.childResourcesIds();
  }

  transformData(pFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = 'app-grouping-list';
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  modifyDataFormat(data) {
    const jsonP = JSON.parse(data['RESOURCES_JSON']);
    this.data = {
      resourceId: data['ID'],
      resourcesName: data['RESOURCES_NAME'],
      siteId: data['SITE_ID'],
      channelId: data['CHANNEL_ID'],
      labels: data['RESOURCES_LABEL'],
      modelId: jsonP.models[0]['modelId'],
      resourcesPicUrl: data['RESOURCES_PIC_URL'],
      childResourcesIds: jsonP.childResourcesIds,
      fileList: []
    };
    this.searchChannel(this.data.siteId);
    const ids = [];
    _.each(this.data.childResourcesIds, oneData => {
      ids.push(oneData['resourcesId']);
    });
    if(this.utils.Base_HasValue(ids)){
      this.requestData.getDataByCondition('digitalResources/dr/dr_group_edit.json', {RESOURCES_ID: ids}).subscribe(response => {
        _.each(response.data, item => {
          if (this.data.resourcesPicUrl === item['resourcesPicUrl']) {
            item['selected'] = true;
          }
        });
        this.data.fileList = response.data;
        for (let oneData of this.data.fileList) {
          for (let item of jsonP.childResourcesIds) {
            if (oneData['resourceId'] === item['resourcesId']) {
              oneData['order'] = item['order'];
            }
          }
          this.data.fileList = _.sortBy(this.data.fileList, (item) => {
            return +item['order'];
          });
        }
        this.manageTempArr();
      });
    }
  }

  ngOnInit() {
    const search = observableForkJoin(
      this.requestData.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestData.getDataByCondition('digitalResources/dict/BASIC_MODEL.json', {RESOURCES_TYPE: 'GROUP'})
    );
    search.subscribe(
      values => {
        this.siteList = values[0]['data'] || [];
        this.basicModelList = values[1]['data'] || [];
        if (this.parentData.modeType === 'modify') {
          this.requestData.getDataByCondition('digitalResources/dr/dr_group_list.json', {RESOURCES_ID: this.parentData.RESOURCES_ID}).subscribe(response => {
            const data = response.data[0];
            this.modifyDataFormat(data);
          });
        }
      }
    );
  }

}
