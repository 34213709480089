import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import * as _ from 'underscore';
import {UtilsService} from '../../../service/utils.service';
import * as moment from 'moment';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {forkJoin as observableForkJoin} from 'rxjs';

@Component({
  selector: 'app-number-of-master',
  templateUrl: './number-of-master.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './number-of-master.component.css']
})
export class NumberOfMasterComponent implements OnInit {
  listData: any;
  year = [];
  objectKeys: any = Object.keys;
  sumCount: any;
  evaluationYear: any;
  testType = [
    {
      code: '三年',
      name: '学术学位'
    },
    {
      code: '两年',
      name: '专业学位'
    }
  ];
  testTypeValue = '三年';

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  modelChange(model) {
    this.testTypeValue = model;
  }

  export() {
    let data1: any = [];
    const data2: any = [
      {
        '人数/年度': '招生总人数'
      },
      {
        '人数/年度': '分流淘汰总人数'
      },
      {
        '人数/年度': '授予学位总人数'
      },
    ];
    const data: any = [
      {
        '方向名称': '',
        '类别': '全日制招生人数'
      },
      {
        '方向名称': '',
        '类别': '非全日制招生人数'
      },
      {
        '方向名称': '',
        '类别': '分流淘汰人数'
      },
      {
        '方向名称': '',
        '类别': '授予学位人数'
      }
    ];
    if (!this.utils.Base_HasValue(this.listData)) {
      return this.confirmModal.show('warning', {title: '特殊报表没有数据不支持导出。'});
    }
    _.each(this.listData, (value, key) => {
      _.each(this.year, item => {
        data[0]['方向名称'] = key;
        data[1]['方向名称'] = key;
        data[2]['方向名称'] = key;
        data[3]['方向名称'] = key;
        data[0][item + '年'] = this.dataYear(value, item)[0]['FULLTIME_COUNT'] || 0;
        data[1][item + '年'] = this.dataYear(value, item)[0]['PARTTIME_COUNT'] || 0;
        data[2][item + '年'] = this.dataYear(value, item)[0]['ELIMINATE_COUNT'] || 0;
        data[3][item + '年'] = this.dataYear(value, item)[0]['ACHIEVE_DEGREE_COUNT'] || 0;
      });
      data1 = data1.concat(data);
    });
    _.each(data2, oneData => {
      _.each(this.year, item => {
        if (oneData['人数/年度'] === '招生总人数') {
          oneData[item + '年'] = this.dataYear(this.sumCount, item)[0]['TOTAL_COUNT'] || 0;
        }
        if (oneData['人数/年度'] === '分流淘汰总人数') {
          oneData[item + '年'] = this.dataYear(this.sumCount, item)[0]['ELIMINATE_COUNT'] || 0;
        }
        if (oneData['人数/年度'] === '授予学位总人数') {
          oneData[item + '年'] = this.dataYear(this.sumCount, item)[0]['ACHIEVE_DEGREE_COUNT'] || 0;
        }
      });
    });
    const opts = [
      {
        sheetid: this.evaluationYear - 4 + '~' + this.evaluationYear + '年硕士研究生招生与学位授予情况',
        header: true
      },
      {
        sheetid: '总人数',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-1-2 硕士研究生招生与学位授予情况.xlsx",?) FROM ?', [opts, [data1, data2]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  dataFormat() {
    const alaSql = require('alasql');
    this.sumCount = alaSql('select INCOME_SCHOOL_YEAR, sum(FULLTIME_COUNT) + sum(PARTTIME_COUNT) TOTAL_COUNT, sum(ELIMINATE_COUNT) ELIMINATE_COUNT, sum(ACHIEVE_DEGREE_COUNT) ACHIEVE_DEGREE_COUNT from ? group by INCOME_SCHOOL_YEAR', [this.listData]);
    this.listData = alaSql('select INCOME_SCHOOL_YEAR, SUBJECT_DIRECTION_NAME, sum(FULLTIME_COUNT) FULLTIME_COUNT, sum(PARTTIME_COUNT) PARTTIME_COUNT, sum(ELIMINATE_COUNT) ELIMINATE_COUNT, sum(ACHIEVE_DEGREE_COUNT) ACHIEVE_DEGREE_COUNT from ? group by SUBJECT_DIRECTION_NAME,INCOME_SCHOOL_YEAR', [this.listData]);
    this.listData = _.groupBy(this.listData, (oneData) => {
      return oneData['SUBJECT_DIRECTION_NAME'];
    });
  }

  dataYear(pArray, year) {
    const data = _.filter(pArray, oneData => {
      return oneData['INCOME_SCHOOL_YEAR'] === year;
    });
    if (data.length) {
      return data;
    } else {
      return [0];
    }
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.year = [this.evaluationYear - 4, this.evaluationYear - 3, this.evaluationYear - 2, this.evaluationYear - 1, this.evaluationYear - 0];
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_master_info.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTEM: this.testTypeValue
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_master_awarded_degree.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4,
        EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTEM: this.testTypeValue
      }),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_master_move_count.json', {
        EVALUATION_YEAR_BEGIN: this.evaluationYear - 4, EVALUATION_YEAR_END: this.evaluationYear - 0,
        EDUCTIONAL_SYSTEM: this.testTypeValue
      })
    );
    parallel.subscribe(response => {
      this.listData = response[0]['data'].concat(response[1]['data']).concat(response[2]['data']);
      this.dataFormat();
    });
  }
}
