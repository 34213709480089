import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { AuditContractComponent } from './audit-contract/audit-contract.component';
import { UtilsService } from '../../service/utils.service';
import { ConfirmModalService } from './../../service/confirm-modal.service';
import { AutoInsertService } from '../../service/auto-insert.service';
import * as _ from 'underscore';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ShopManagementService {

  constructor(private modalService: NzModalService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private autoInsert: AutoInsertService) { }

  doAction(pBtn,pParentData){
    switch (pBtn.ACTION_VALUE) {
      case "auditContract":
      this.auditContract(pBtn,pParentData);
      break;
      case "auditSkxx":
      this.auditContract(pBtn,pParentData);
      break;
      case "submitAudit"://合同和收款单提交审核
      this.submitAudit(pBtn,pParentData);
      break;
    }
  }

  //合同和收款单提交审核
  submitAudit (pData,pObject) {
    if (this.selectOneData(pObject.data.data) != 1){
      return false;
    }
    let selectData = _.filter(pObject.data.data, (item) => {
      return item["checked"];
    });
    if(selectData[0]["AUDIT_STATE"] === "ADOPT" || selectData[0]["AUDIT_STATE"] === "NO_AUDIT"){
      this.confirmModal.show('warning', {
        title: '只有未审核和审核不通过状态的数据才可以提交审核',
      })
      return false;
    }

    let tableName = pObject.conditionColumns.tablename;
    let templateJson = {};
    if(tableName === "t_htxx"){
      templateJson = {
        "LIST": [
        {
          "TABLE": 't_htxx',
          "ACTION": "Update",
          "WHEREGROUP": [{
            "GROUP": "GROUP_01",
            "CONDITION_OPERATOR": "AND"
          }],
          "WHERE": [{
            "COLUMN": "HT_ID",
            "VALUE": selectData[0]["HT_ID"],
            "OPERATOR": "=",
            "VALUE_TYPE": "string",
            "CONDITION_OPERATOR": "AND",
            "GROUP": "GROUP_01"
          }],
          "COLUMNS": [
          {"COLUMN": "AUDIT_STATE"},
          ],
          "RECORDS": [
          {
            "AUDIT_STATE": "NO_AUDIT"
          }
          ]
        }
        ]
      }
    }else if(tableName === "t_skxx"){
      templateJson = {
        "LIST": [
        {
          "TABLE": 't_skxx',
          "ACTION": "Update",
          "WHEREGROUP": [{
            "GROUP": "GROUP_01",
            "CONDITION_OPERATOR": "AND"
          }],
          "WHERE": [{
            "COLUMN": "SK_ID",
            "VALUE": selectData[0]["SK_ID"],
            "OPERATOR": "=",
            "VALUE_TYPE": "string",
            "CONDITION_OPERATOR": "AND",
            "GROUP": "GROUP_01"
          }],
          "COLUMNS": [
          {"COLUMN": "AUDIT_STATE"}
          ],
          "RECORDS": [
          {
            "AUDIT_STATE": "NO_AUDIT"
          }
          ]
        }
        ]
      };
    }

    return this.autoInsert.ExecJson(templateJson).subscribe((response) => {
      if(response["state"] === "success"){
        this.confirmModal.show('success', {
          title: '提交成功',
          suc: () => {
            pObject.needSearch = true;
          }
        })
      } else {
        this.confirmModal.show('error', {title: '保存失败' })
      }
    })

  }

  //合同审核
  auditContract (pData,pObject) {
    if (this.selectOneData(pObject.data.data) != 1){
      return false;
    }
    let selectData = _.filter(pObject.data.data, (item) => {
      return item["checked"];
    });
    if(selectData[0]["AUDIT_STATE"] != "NO_AUDIT"){
      this.confirmModal.show('warning', {
        title: '只有待审核状态的数据才可以做审核操作',
      })
      return false;
    }
    this.modalService.create({
      nzTitle:'审核',
      nzContent: AuditContractComponent,
      nzWrapClassName: "vertical-center-modal",
      nzOnOk: (response) => this.confirmAudit(response,selectData,pObject)
    });
   
    return false;
  }

  //审核确认
  confirmAudit(response,selectData,pParentData){
    if(!this.utils.Base_HasValue(response["auditState"])){
      this.confirmModal.show('error', {
        title: '请选择审核状态',
      });
      return;
    }
    let tableName = pParentData.conditionColumns.tablename;
    let templateJson = {};
    if(tableName === "t_htxx"){
      templateJson = {
        "LIST": [
        {
          "TABLE": 't_htxx',
          "ACTION": "Update",
          "WHEREGROUP": [{
            "GROUP": "GROUP_01",
            "CONDITION_OPERATOR": "AND"
          }],
          "WHERE": [{
            "COLUMN": "HT_ID",
            "VALUE": selectData[0]["HT_ID"],
            "OPERATOR": "=",
            "VALUE_TYPE": "string",
            "CONDITION_OPERATOR": "AND",
            "GROUP": "GROUP_01"
          }],
          "COLUMNS": [
          {"COLUMN": "AUDIT_STATE"},
          {"COLUMN": "AUDIT_EXPLAIN"}
          ],
          "RECORDS": [
          {
            "AUDIT_STATE": response["auditState"],
            "AUDIT_EXPLAIN": response["reason"] || ""
          }
          ]
        }
        ]
      }
    }else if(tableName === "t_skxx"){
      templateJson = {
        "LIST": [
        {
          "TABLE": 't_skxx',
          "ACTION": "Update",
          "WHEREGROUP": [{
            "GROUP": "GROUP_01",
            "CONDITION_OPERATOR": "AND"
          }],
          "WHERE": [{
            "COLUMN": "SK_ID",
            "VALUE": selectData[0]["SK_ID"],
            "OPERATOR": "=",
            "VALUE_TYPE": "string",
            "CONDITION_OPERATOR": "AND",
            "GROUP": "GROUP_01"
          }],
          "COLUMNS": [
          {"COLUMN": "AUDIT_STATE"},
          {"COLUMN": "AUDIT_EXPLAIN"}
          ],
          "RECORDS": [
          {
            "AUDIT_STATE": response["auditState"],
            "AUDIT_EXPLAIN": response["reason"] || ""
          }
          ]
        }
        ]
      };
    }

    return this.autoInsert.ExecJson(templateJson).subscribe((response) => {
      if(response["state"] === "success"){
        this.confirmModal.show('success', {
          title: '保存成功',
          suc: () => {
            pParentData.needSearch = true;
          }
        })
      } else {
        this.confirmModal.show('error', {title: '修保存失败' })
      }
    })

  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData["checked"]) {
        iCount++;
      }
    });
    if (iCount == 0) {
      this.confirmModal.show("warning", {
        "title": "温馨提示",
        "content": "请至少选择一条纪录！"
      });
      console.error("this action must select only one record ");
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show("warning", {
        "title": "温馨提示",
        "content": "请只选择一条纪录！"
      });
      console.error("this action must select only one record ");
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData["checked"]) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show("warning", {
        "title": "温馨提示",
        "content": "请至少选择一条纪录！"
      });
      return iCount;
    }
    return iCount;
  }

}
