import {Component, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {__values} from "tslib";
import {result} from "underscore";
import {ClipboardService} from "ngx-clipboard";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-hulk-config-table-design',
  templateUrl: './hulk-config-table-design.component.html',
  styleUrls: ['./hulk-config-table-design.component.scss']
})
export class HulkConfigTableDesignComponent implements OnInit {


  public mainConfig = {
    itemConfig: {
      en: "TASK_TABLE",
      zh: "任务表",
      notTrans: false,
      synchronization: true,
      notTransColumn: false,
      isNotNullColumn: false,
      addPkColumn: true,
      addStdColumn: true,
      deleteTable: false,
      pf: "",
      filterStandardColumn: true,
    },
    bigText: "任务开始时间\n" +
      "任务结束时间\n" +
      "任务说明\n" +
      "任务说明图片列表\n" +
      "是否有奖励\n" +
      "奖励金\n" +
      "任务数量\n" +
      "已接任务数量\n" +
      "已完成任务数",
    ddlText: "",
    columnList: [
      {
        "comment": "任务开始时间",
        "column": "TASK_START_TIME",
        "notTrans": false,
        "type": "dt",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "任务结束时间",
        "column": "TASK_END_TIME",
        "notTrans": false,
        "type": "dt",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "任务说明",
        "column": "TASK_DESCRIPTION",
        "notTrans": false,
        "type": "v",
        "length": "256",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "任务说明图片列表",
        "column": "TASK_DESCRIPTION_PICTURE_LIST",
        "notTrans": false,
        "type": "j",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "是否有奖励",
        "column": "WHETHER_THERE_ARE_REWARDS",
        "notTrans": false,
        "type": "v",
        "length": "32",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "奖励金",
        "column": "BONUS",
        "notTrans": false,
        "type": "dc",
        "length": "10",
        "accuracy": "2",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "任务数量",
        "column": "NUMBER_OF_TASKS",
        "notTrans": false,
        "type": "i",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "已接任务数量",
        "column": "NUMBER_OF_TASKS_RECEIVED",
        "notTrans": false,
        "type": "i",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      },
      {
        "comment": "已完成任务数",
        "column": "NUMBER_OF_TASKS_COMPLETED",
        "notTrans": false,
        "type": "i",
        "length": "n",
        "accuracy": "n",
        "defaultValue": "n",
        "isNotNull": false
      }
    ]
  }


  public typeKvList = {
    'v': 'varchar',
    'dt': 'datetime',
    'd': 'date',
    'dc': 'decimal',
    't': 'text',
    'j': 'json',
    'i': 'integer'
  }

  public lengthKvList = {
    'n': 'n',
    '10': '10',
    '16': '16',
    '32': '32',
    '128': '128',
    '256': '256',
    '1024': '1024'
  }

  public accuracyKvList = {
    'n': 'n',
    '2': '2',
    '4': '4',
    '8': '8'
  }

  public defaultValueKvList = {
    'n': 'n',
    '0': '0'
  }

  public pfList = {
    "simulationDataResources": "华中师范大学模拟实训平台",
    "dpcaDataResources": "神龙汽车安保点检系统",
    "jsbPro05DataResources": "悦跑体育俱乐部"
  }


  public defColumns = ['comment', 'type', 'length', 'accuracy', 'defaultValue', 'isNotNull', 'column'];
  public type_options = [];
  public length_options = [];
  public accuracy_options = [];
  public defaultValue_options = [];
  public pf_options = [];

  async doTransformationTable() {
    if (this.mainConfig.itemConfig.notTrans) return;
    let text = this.mainConfig.itemConfig.zh;
    let transRes = await this.doTranslate(text);
    let dst = _sh.get(transRes, "[0].text.trans_result[0].dst", "");
    let dstCamelCase = _sh.chain(dst).upperCase().split(" ").join("_").value();
    this.mainConfig.itemConfig.en = dstCamelCase;
  }

  getReverseProcessing() {
    let result = [];
    _sh.each(this.mainConfig.columnList, oneColumn => {
      let oneRow = [];
      _sh.each(this.defColumns, oneColumnName => {
        let value = _sh.get(oneColumn, [oneColumnName], '');

        if (!this.utilsService.Base_HasValue(value)) {
          if (this.utilsService.Base_Contains(['length', 'accuracy', ''], oneColumnName)) {
            value = 'n'
          }

          if (this.utilsService.Base_Contains(['isNotNull'], oneColumnName)) {
            value = 0
          }
        } else {
          if (this.utilsService.Base_Contains(['isNotNull'], oneColumnName)) {
            value = value ? 1 : 0;
          }
        }
        oneRow.push(value);
      });
      let oneRowStr = _sh.join(oneRow, ' ');
      result.push(oneRowStr);
    })
    this.mainConfig.bigText = _sh.join(result, "\n")
  }

  /**
   *
   * @param _attribute
   * @param _value
   */
  getDefaultValue(_oneColumn, _attribute, _value) {
    if (_attribute == "type") {
      if (!this.utilsService.Base_HasValue(_value)) _value = 'v';
      // _value = this.typeKvList[_value];
    } else if (_attribute == "length") {
      if (!this.utilsService.Base_HasValue(_value)) {
        if (_oneColumn['type'] == "v") _value = '32';
        else if (_oneColumn['type'] == "dc") _value = '10';
        else _value = 'n';
      }
    } else if (_attribute == "accuracy") {
      if (!this.utilsService.Base_HasValue(_value)) {
        if (_oneColumn['type'] == "decimal")
          _value = '2'
        else _value = 'n'
      } else {
        if (_oneColumn['type'] == "decimal" && _value == "n")
          _value = '2'
      }
    } else if (_attribute == "isNotNull") {
      if (!this.utilsService.Base_HasValue(_value)) {
        _value = false
      } else {
        if (_value = "0") _value = false; else if (_value = "1") _value = true;
      }
    } else if (_attribute == "defaultValue") {
      if (!this.utilsService.Base_HasValue(_value)) {
        _value = "n"
      }
    }
    if (this.utilsService.Base_Contains(['type', 'length', 'accuracy', 'decimal'], _attribute))
      _oneColumn[_attribute] = _value.toString();
    else
      _oneColumn[_attribute] = _value;

    return _oneColumn;
  }

  fillValue(_oneColumn, _attribute, _value) {
    let oldValue = _sh.get(_oneColumn, _attribute, null);
    if (!this.utilsService.Base_HasValue(oldValue)) {
      _sh.set(_oneColumn, _attribute, _value);
    }
    return _oneColumn
  }

  async doTransformationColumn(_oneColumn, _notTrans = false) {
    if (!_notTrans) {
      let transRes = await this.doTranslate(_oneColumn.comment);
      let dst = _sh.get(transRes, "[0].text.trans_result[0].dst", "");
      let dstCamelCase = _sh.chain(dst).upperCase().split(" ").join("_").value();
      _oneColumn = this.fillValue(_oneColumn, 'column', dstCamelCase);
    } else {
      _oneColumn = this.getDefaultValue(_oneColumn, "column", _oneColumn['column']);
    }
    _oneColumn = this.getDefaultValue(_oneColumn, "type", _oneColumn['type']);
    _oneColumn = this.getDefaultValue(_oneColumn, "length", _oneColumn['length']);
    _oneColumn = this.getDefaultValue(_oneColumn, "accuracy", _oneColumn['accuracy']);
    _oneColumn = this.getDefaultValue(_oneColumn, "isNotNull", _oneColumn['isNotNull']);
    _oneColumn = this.getDefaultValue(_oneColumn, "defaultValue", _oneColumn['defaultValue']);

    return _oneColumn;
  }

  async doTransformationAll() {

    this.deleteErrorRow();

    this.doTransformationTable(); // 翻译表名

    let tempArr = _sh.chain(this.mainConfig.bigText).split("\n").map(oneMap => {
      let oneRowArr = _sh.split(oneMap, "\t");
      oneRowArr = _sh.trim(oneRowArr);
      oneRowArr = _sh.split(oneRowArr, " ");
      let columns = this.defColumns;
      columns = _sh.take(columns, oneRowArr.length);
      let obj = _sh.zipObject(columns, oneRowArr)
      return obj
    }).value();

    let tempList = [];
    for (let rowIndex = 0; rowIndex < tempArr.length; rowIndex++) {
      let findColumn = _sh.filter(this.mainConfig.columnList, oneColumn => {
        return oneColumn['comment'] == tempArr[rowIndex]['comment'];
      });
      let notTrans = _sh.get(findColumn, ['0', 'notTrans'], false);
      tempArr[rowIndex]['column'] = _sh.get(findColumn, ['0', 'column'], null);
      tempArr[rowIndex]['notTrans'] = _sh.get(findColumn, ['0', 'notTrans'], notTrans);

      let tempColumn = await this.doTransformationColumn(tempArr[rowIndex], notTrans);
      tempList.push(tempColumn);
    }
    this.mainConfig.columnList = tempList;
    if (this.mainConfig.itemConfig.synchronization) this.getReverseProcessing();
  }

  doTranslate(_translateText) {
    const tslRes = observableForkJoin(
      this.requestDataService.getTranslate(_translateText, "en")
    )
    return tslRes.toPromise();
  }

  constructor(
    private requestDataService: RequestDataService,
    private utilsService: UtilsService,
    private clipboardService: ClipboardService,
    private notification: NzNotificationService
  ) {
  }

  createNotification(type: string, title: string, content: string): void {
    this.notification.create(
      type,
      title,
      content
    );
  }

  buildCodeName(_kvList) {
    let result = [];
    _sh.each(_sh.keys(_kvList), oneKey => {
      let oneValue = _kvList[oneKey];
      result.push({
        'code': oneKey,
        'name': oneValue
      })
    });
    return result;
  }

  ngOnInit() {
    this.type_options = this.buildCodeName(this.typeKvList);
    this.length_options = this.buildCodeName(this.lengthKvList);
    this.accuracy_options = this.buildCodeName(this.accuracyKvList);
    this.defaultValue_options = this.buildCodeName(this.defaultValueKvList);
    this.pf_options = this.buildCodeName(this.pfList);
  }

  deleteErrorRow() {
    let result = [];
    _sh.chain(this.mainConfig.bigText).split("\n").map(oneMap => {
      if (this.utilsService.Base_HasValue(oneMap)) {
        result.push(oneMap);
      }
      return oneMap
    }).value();
    result = _sh.uniq(result);
    this.mainConfig.bigText = _sh.join(result, "\n");
  }

  checkAll(value: boolean, attributes): void {
    this.mainConfig.columnList.forEach(oneColumn => {
      oneColumn[attributes] = value;
    });
  }

  buildDll() {
    let reusltDDL = [];
    let that = this;

    function addDropTable() {
      if (!that.mainConfig.itemConfig.deleteTable) return;
      let templateStr = "DROP TABLE `<%= tableName %>` ;";
      let compiled = _sh.template(templateStr);
      let beginStr = compiled({
        tableName: that.mainConfig.itemConfig.en
      });
      reusltDDL.unshift(beginStr);
    }

    function addIdColumn() {
      if (!that.mainConfig.itemConfig.addPkColumn) return;
      reusltDDL.push("`ID` varchar(32) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL COMMENT '主键', ");
    }

    function addTableName() {
      let templateStr = "CREATE TABLE `<%= tableName %>` (";
      let compiled = _sh.template(templateStr);
      let beginStr = compiled({
        tableName: that.mainConfig.itemConfig.en
      });
      reusltDDL.unshift(beginStr);

      templateStr = "  PRIMARY KEY (`ID`) \n ) COMMENT = '<%= tableName %>';";
      compiled = _sh.template(templateStr);
      let endStr = compiled({
        tableName: that.mainConfig.itemConfig.zh
      });
      reusltDDL.push(endStr);
    }

    function addNormalColumn(oneColumn) {
      let columnObj = _sh.cloneDeep(oneColumn);
      let templateStr = "";
      columnObj['type'] = that.typeKvList[columnObj['type']];
      columnObj['isNotNull'] = columnObj['isNotNull'] ? "NOT NULL" : "NULL";

      if (columnObj['type'] == 'varchar') {
        templateStr = " `<%= column %>` <%= type %>(<%= length %>) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci <%= isNotNull %> COMMENT '<%= comment %>',";
      } else if (that.utilsService.Base_Contains(['datetime', 'date', 'json'], columnObj['type'])) {
        templateStr = " `<%= column %>` <%= type %> <%= isNotNull %>  COMMENT '<%= comment %>',";
      } else if (columnObj['type'] == 'text') {
        templateStr = " `<%= column %>` <%= type %> CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci <%= isNotNull %> COMMENT '<%= comment %>',";
      } else if (columnObj['type'] == 'decimal') {
        if (columnObj['length'] == 'n') columnObj['length'] = "10";
        if (columnObj['accuracy'] == 'n') columnObj['accuracy'] = "2";
        templateStr = " `<%= column %>` decimal(<%= length %>, <%= accuracy %>) <%= isNotNull %>  COMMENT '<%= comment %>', ";
      } else if (columnObj['type'] == 'integer') {
        templateStr = "  `<%= column %>` integer <%= isNotNull %> COMMENT '<%= comment %>', ";
      }
      var compiled = _sh.template(templateStr);
      var oneColumnStr = compiled(columnObj);
      reusltDDL.push(oneColumnStr);
    }

    function addStdColumn() {
      if (!that.mainConfig.itemConfig.addStdColumn) return;
      let templateStr = "  `ADD_USERID` varchar(32) CHARACTER SET utf8 COLLATE utf8_bin NOT NULL COMMENT '添加用户ID',\n" +
        "  `ADD_TIME` datetime NOT NULL COMMENT '添加人时间',\n" +
        "  `ADD_NAME` varchar(32) CHARACTER SET utf8mb4 COLLATE utf8mb4_unicode_ci NOT NULL COMMENT '添加人',\n" +
        "  `UPD_USERID` varchar(32) CHARACTER SET utf8 COLLATE utf8_bin NOT NULL COMMENT '修改人ID',\n" +
        "  `UPD_TIME` datetime NULL DEFAULT NULL COMMENT '修改人时间',\n" +
        "  `UPD_NAME` varchar(32) CHARACTER SET utf8mb4 COLLATE utf8mb4_unicode_ci NULL DEFAULT NULL COMMENT '修改人ID',\n" +
        "  `U_TIME` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP COMMENT '时间戳',\n" +
        "  `U_DELETE` int(10) NOT NULL DEFAULT 1 COMMENT '删除标记',";
      reusltDDL.push(templateStr);
    }

    addIdColumn(); // 新增主键
    _sh.each(this.mainConfig.columnList, oneColumn => {
      addNormalColumn(oneColumn); // 新增一般性字段
    })
    addStdColumn(); // 新增标准字段
    addTableName(); // 处理表名、备注
    addDropTable(); // 删除表
    this.mainConfig.ddlText = _sh.join(reusltDDL, '\n');

    this.clipboardService.copyFromContent(this.mainConfig.ddlText);
    this.createNotification('success', '成功复制到剪切板，Ctrl+V 即可使用。', 'DDL_TEXT');

  }

  doReadTable() {

  }
}
