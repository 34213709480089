import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as _ from 'underscore';
import {DataCenterService} from '../../../service/data-center.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {FormComponent} from '../../../basicComponent/form/form.component';
import {RequestDataService} from '../../../service/request-data.service';
import {AppConfigService} from '../../../service/app-config.service';
import {UtilsService} from '../../../service/utils.service';
import {ListActionService} from '../../../service/list-action.service';
import {NzModalService} from 'ng-zorro-antd';
import {SettingColumnService} from '../../../service/setting-column.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as localForage from 'localforage';
import {DataService} from '../data.service';
import {AuditContractComponent} from '../../shopManagement/audit-contract/audit-contract.component';
import {SetPointModalComponent} from '../set-point-modal/set-point-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-picture-list',
  templateUrl: './picture-list.component.html',
  styleUrls: ['./picture-list.component.css']
})
export class PictureListComponent implements OnInit {

  @ViewChild(FormComponent, {static: false}) childForm !: FormComponent;

  @ViewChild('listColumn', {static: false}) listColumn;


  // table 滚动条宽度
  public scrollWidth = '';

  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = 12;
  // 总条数
  public total: any = 1;

  //父级组件的数据对象
  @Input() parentData: any = {
    conditionColumns: {
      columns: []
    }
  };

  // 当前table的表头
  public showColumns: any = [];

  // 查询数据
  public conditionList: any[] = [];

  //排序字段的数组
  private sortArray: any[] = [];

  public checkbox = true;
  private allChecked = false;
  private indeterminate = false;

  private visibleCheck = {
    type: 'common'
  };

  // 列表展示列字段
  public settingListColumn: any = [];
  public settingConditionColumn: any = [];

  // 列表页帮助文档
  private listHelpUrl = '';

  public allListColChecked = true;
  public listColIndeterminate = false;

  // 配置列表显示项弹框排序方式
  public columnSortType: any = false;
  smallPlan = false;
  checkedAll = false;

  // 查询条件
  conditionColumn: any = {
    RESOURCES_STATE: '',
    RESOURCES_NAME: '',
    SITE_ID: '',
    CHANNEL_ID: '',
    AUDIT_USER_NAME: '',
    AUDIT_TIME: '',
    RESOURCES_TYPE: '',
    RESOURCES_LABEL: null
  };

  siteList = [];
  resourcesType = [];
  stateList = [];
  labelsList = [];
  // 栏目字典
  channelList = [];
  expandKeys = [];

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private listAction: ListActionService,
              private modalService: NzModalService,
              private settingSerive: SettingColumnService,
              private confirmModal: ConfirmModalService,
              private dataService: DataService) {
  }

  // 时间框
  onChange (result) {
    if (result.length === 2) {
      this.conditionColumn.AUDIT_TIME_BEGIN = moment(result[0]).format('YYYY-MM-DD');
      this.conditionColumn.AUDIT_TIME_END = moment(result[1]).format('YYYY-MM-DD');
    } else {
      this.conditionColumn.AUDIT_TIME_BEGIN = '';
      this.conditionColumn.AUDIT_TIME_END = '';
    }
  }

  // 修改状态
  updateState(pType) {
    let ok = true;
    let errorText = '';
    const ids = [];
    if (this.selectMore(this.parentData.data.data) < 1) {
      return;
    }
    const selectList = _.filter(this.parentData.data.data, oneData => {
      if (oneData['checked']) {
        ids.push(oneData['ID']);
      }
      return oneData['checked'];
    });
    switch (pType) {
      case 'PASS':
        _.each(selectList, oneData => {
          if (oneData['RESOURCES_STATE'] !== 'NO_AUDIT') {
            ok = false;
          }
        });
        if (!ok) {
          errorText = '只有待审核状态的数据才能执行该操作。';
        }
        break;
      case 'NO_PASS':
        _.each(selectList, oneData => {
          if (oneData['RESOURCES_STATE'] !== 'NO_AUDIT') {
            ok = false;
          }
        });
        if (!ok) {
          errorText = '只有待审核状态的数据才能执行该操作。';
        }
        break;
      case 'PUSH':
        _.each(selectList, oneData => {
          if (oneData['RESOURCES_STATE'] !== 'PASS' && oneData['RESOURCES_STATE'] !== 'OFF_SHELF') {
            ok = false;
          }
        });
        if (!ok) {
          errorText = '只有审核通过状态的数据才能执行该操作。';
        }
        break;
      case 'OFF_SHELF':
        _.each(selectList, oneData => {
          if (oneData['RESOURCES_STATE'] !== 'PUSH') {
            ok = false;
          }
        });
        if (!ok) {
          errorText = '只有已发布状态的数据才能执行该操作。';
        }
        break;
    }
    if (!ok) {
      return this.confirmModal.show('warning', {title: errorText});
    }
    const data = {
      id: ids,
      resourceState: pType
    };
    this.dataService.updateResourcesState(data).subscribe((rep) => {
      if (rep.state === 'success') {
        this.confirmModal.show('success', {title: '执行成功'});
        this.searchData();
      } else {
        this.confirmModal.show('error', {title: '执行失败'});
      }
    });
  }

  // 删除
  deleteResources() {
    let ok = true;
    const ids = [];
    if (this.selectMore(this.parentData.data.data) < 1) {
      return;
    }
    _.each(this.parentData.data.data, oneData => {
      if (oneData['checked'] && oneData['RESOURCES_STATE'] !== 'PUSH') {
        ids.push(oneData['ID']);
      } else if (oneData['checked'] && oneData['RESOURCES_STATE'] === 'PUSH') {
        ok = false;
        return false;
      }
    });
    if (!ok) {
      return this.confirmModal.show('warning', {title: '已发布的数据不可以删除。'});
    }
    this.confirmModal.show('confirm', {
      title: '您确定删除吗？',
      suc: () => {
        this.dataService.resourcePicDelete(ids).subscribe((rep) => {
          if (rep.state === 'success') {
            this.confirmModal.show('success', {title: '执行成功'});
            this.searchData();
          } else {
            this.confirmModal.show('error', {title: '执行失败'});
          }
        });
      }
    });
  }

  pageSearch(num) {
    this.pageIndex = num;
    this.searchData();
  }

  uploadPic() {
    this.switchPage('customForm', 'app-picture-upload', this.parentData, 'pictureUpload', 'pictureUpload');
  }

  editPic() {
    if (this.selectOneData(this.parentData.data.data) !== 1) {
      return;
    }
    const resourcesId = _.find(this.parentData.data.data, oneData => {
      if (oneData['checked']) {
        this.parentData['RESOURCES_ID'] = oneData['ID'];
      }
      return oneData['checked'];
    });
    if (resourcesId['RESOURCES_STATE'] === 'PUSH') {
      return this.confirmModal.show('warning', {title: '已发布的资源不可以著录，若需著录请先下架。'});
    }
    this.switchPage('customForm', 'app-picture-edit', this.parentData, 'pictureEidt', 'pictureEidt');
  }

  // 通用封装数据结果
  transformData(data) {
    // 重新覆盖 datacenter 数据
    this.parentData.pageSize = this.pageSize;
    this.parentData.data = data;
    this.total = data.page.rowCount;
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  switchPage(pFormType, pCustomFormType, parentData, pModeType?, btnName?) {
    // 重新覆盖 datacenter 数据
    parentData.data = {};
    parentData.custormFormType = pCustomFormType;
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  refreshStatus(): void {
    const validData = this.parentData.data.data.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.parentData.data.data.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  handleClose(removedCondition: {}): void {
    // form 中数据清空
    this.conditionList = this.conditionList.filter(tag => tag !== removedCondition);
    _.each(this.childForm._validateForm['columns'], (item) => {
      if (removedCondition['column'] === item['column']) {
        item['model'] = null;
        if (_.contains(['date', 'datetime', 'dateRange', 'dateTimeRange'], item['component'])) {
          item['modelDate'] = null;
        }
        if (_.contains(['poplist', 'multipopCondition'], item['component'])) {
          item['modelName'] = '';
        }
      }
    });
    // 重新查询
    this.searchData();
  }

  // 合并查询条件
  transformCondition(columns) {
    const condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        const obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          // poplist 存在 itemArray 不存在的情况（因为是需要点击在去查询JSON）
          // 所以在查询条件会写的时候需要讲内容赋值
          if (item['itemArray'] && item['itemArray'].length) {
            const selected = _.find(item['itemArray'], (v) => {
              return v['code'] === item['model'];
            });
            obj['name'] = selected['name'];
          } else {
            obj['name'] = item['modelName'];
          }
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          const nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }


  // 将searchCondition放入localForage
  setSearchCondition(parentData, conditionList) {
    localForage.getItem('conditionForage').then((result: string) => {
      let conditionForage = {};
      if (this.utils.Base_HasValue(result)) {
        conditionForage = JSON.parse(result);
      }
      conditionForage = conditionForage || {};
      conditionForage['searchCondition'] = conditionForage['searchCondition'] || {};
      conditionForage['searchCondition'][parentData.nowMenu.MENU_ID] = conditionList;
      localForage.setItem('conditionForage', JSON.stringify(conditionForage));
    });
  }

  /**
   * 清除条件调用方法
   */
  resetFilter() {
    this.conditionColumn = {
        RESOURCES_STATE: '',
        RESOURCES_NAME: '',
        SITE_ID: null,
        CHANNEL_ID: '',
        AUDIT_USER_NAME: '',
        AUDIT_TIME: '',
        RESOURCES_TYPE: '',
        RESOURCES_LABEL: null
    };
    this.searchData();
  }

  searchChannel(pSiteId) {
    if (!this.utils.Base_HasValue(pSiteId)) {
      return false;
    }
    this.requestDataService.getDataByCondition('digitalResources/dict/CHANNEL_NAME_SHOW.json', {SITE_ID: pSiteId}).subscribe((rep) => {
      this.channelList = rep['data'] || [];
      if (this.utils.Base_HasValue(this.channelList)) {
        this.channelList = this.utils.Base_ChannelData(this.channelList);
        for (const oneData of this.channelList) {
          this.expandKeys.push(oneData['key']);
        }
      }
    });
  }

  searchData(reset: boolean = false, condition?: {}, pSortBy?): void {
    if (reset) {
      this.parentData.pageIndex = 1;
    }
    this.requestDataService.getPaginationData('digitalResources/dr/dr_picture_list.json', this.conditionColumn || {}, this.pageIndex, this.pageSize, pSortBy).subscribe((data: any) => {
      this.transformData(data);
      this.checkedAll = false;
    });
  }

  gotoDetail(oneData: string): void {
    this.parentData.RESOURCES_ID = oneData['ID'];
    this.switchPage('customForm', 'app-picture-detail', this.parentData, 'pictureDetail', 'pictureDetail');
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

  //积分设置
  setPoints() {
    if (this.selectMore(this.parentData.data.data) < 1) {
      return;
    }
    this.modalService.create({
      nzTitle: null,
      nzWidth: 300,
      nzContent: SetPointModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: (response) => {
        const ids = [];
        _.filter(this.parentData.data.data, oneData => {
          if (oneData['checked']) {
            ids.push(oneData['ID']);
          }
          return oneData['checked'];
        });
        this.dataService.requestQuickSliver('/digital/setResourcePoint', {pointNum: response.point, resourceIds: ids}).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {title: '执行成功'});
            this.searchData();
            return true;
          } else {
            this.confirmModal.show('error', {title: '执行失败'});
          }
        });
      }
    });
  }

  ngOnInit() {
    const parallel$ = observableForkJoin(
      this.requestDataService.getDataByCondition('digitalResources/dict/SITE_NAME.json'),
      this.requestDataService.getDataByCondition('digitalResources/dict/RESOURCES_TYPE.json'),
      this.requestDataService.getDataByCondition('digitalResources/dict/RESOURCES_STATE.json')
    );
    parallel$.subscribe(
      values => {
        this.siteList = values[0]['data'];
        this.resourcesType = values[1]['data'];
        this.stateList = values[2]['data'];
        // this.init();
        this.searchData();
      }
    );
  }

  // 监听 needSearch 主要用于列表页的按钮点击之后刷新列表
  ngDoCheck(): void {
    if (!!this.parentData.needSearch) {
      this.searchData();
      this.parentData.needSearch = false;
    }
  }

}
