import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import * as screenfull from 'screenfull';
import * as echarts from 'echarts';
import {interval} from 'rxjs';
import * as _un from 'underscore';
import * as moment from 'moment';
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import Swiper from 'swiper';
import * as alasql from 'alasql';
import {AppConfigService} from "../../../service/app-config.service";

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-dashboard-one',
  templateUrl: './dashboard-one.component.html',
  styleUrls: ['./dashboard-one.component.css']
})
export class DashboardOneComponent implements OnInit {

  swiper: any;
  efficient: any; // 工作效率，日工作效率
  efficientOption: any;

  topCustomer: any; // 重点客户
  topCustomerOption: any;

  wagonInOut: any; // 车辆出入
  wagonInOutOption: any;

  cargoOptionYear: any;
  cargoOptionQuarter: any;
  cargoOptionMonth: any;
  cargoOptionNotReturn: any;

  cargoWeightOption: any;

  // 年度，4年
  public years: any = [];
  // 月度 ，12个月, 以本月上12个月
  public month: any = [];
  // 本年度
  public thisYear: any;
  // 月度，本年度的，12个月
  public thisYearMonth: any = [];
  // 上个月
  public lastMonth: any;
  // 上个季度
  public lastQuarter: any;

  // 日 ， 7 天
  public day: any = [];

  locomotive: [];
  locomotive_log: any = [];


  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService) {
  }


  isfull: any = false;

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        loop: false,
        pagination: {
          el: '.swiper-pagination',
        },
        effect: 'cube',
        speed: 1000,
        autoplay: false
      });
    }, 1000);
  }

  doRefresh() {
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_list.json', {}), // 0 列车头列表
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_count.json', {}), // 1 编组数量
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_log.json', {'LOCOMOTIVE_ID': '7285cbe2d3df44bd87af30db79407fa0'}), // 2
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_log.json', {'LOCOMOTIVE_ID': 'd8ee76cb4d5d472f860d7fa2731e0fa8'}), // 3
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_log.json', {'LOCOMOTIVE_ID': 'b863d84f21ee44288e0a920522c32cd2'}), // 4
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_log.json', {'LOCOMOTIVE_ID': 'e0146d67e7234b3b9cad7583e9312473'}), // 5
      this.requestDataService.getDataByCondition('dfmc/dashborad/locomotive/mc_locomotive_log.json', {'LOCOMOTIVE_ID': '9458e934727d4505b1a8214da80388ad'}), // 6
      this.requestDataService.getDataByCondition('dfmc/dashborad/wagon/dataYear.json', { // 7
        'TIME_RETURN_YEAR': this.years[this.years.length - 1]
      }),
      this.requestDataService.getDataByCondition('dfmc/dashborad/wagon/dataQuarter.json', { // 8
        'TIME_RETURN_QUARTER': this.lastQuarter
      }),
      this.requestDataService.getDataByCondition('dfmc/dashborad/wagon/dataYearMonth.json', { // 9
        'TIME_RETURN_MONTH': this.lastMonth
      }),
      this.requestDataService.getDataByCondition('dfmc/dashborad/wagon/mc_return_efficient_month.json', { // 10
        'TIME_RETURN_YEAR': this.years[this.years.length - 1]
      }),
      this.requestDataService.getDataByCondition('dfmc/dashborad/wagon/dataNotReturn.json', {}) // 11
    );
    search.subscribe(
      values => {
        this.locomotive = _.get(values, [0, 'data'], []);
        let locomotive_count = _.get(values, [1, 'data'], []);
        _.each(this.locomotive, oneLocomotive => {
          _.set(oneLocomotive, ['ACCOUNT'], 0);
        })
        _.each(locomotive_count, oneCount => {
          let findData = _.filter(this.locomotive, {'ID': oneCount['LOCOMOTIVE_ID']});
          _.set(findData, [0, 'ACCOUNT'], oneCount['ACCOUNT']);
        });
        let locomotive_01 = _.get(values, [2, 'data'], []);
        let locomotive_02 = _.get(values, [3, 'data'], []);
        let locomotive_03 = _.get(values, [4, 'data'], []);
        // let locomotive_04 = _.get(values, [5, 'data'], []);
        // let locomotive_05 = _.get(values, [6, 'data'], []);
        this.locomotive_log = [];
        this.locomotive_log.push(locomotive_01, locomotive_02, locomotive_03);

        const yearData = _.get(values, [7, 'data'], []);
        this.formatYear(yearData);

        const quarterData = _.get(values, [8, 'data'], []);
        this.formatQuarter(quarterData);


        const monthData = _.get(values, [9, 'data'], []);
        this.formatMonth(monthData);

        this.efficient = _.get(values, [10, 'data'], []);
        this.formatEfficient();

        this.formatNotReturn(_.get(values, [11, 'data'], []));
      }
    );
  }

  formatYear(pInputData) {
    let data = [];
    _.each(pInputData, oneClass => {
      data.push({value: oneClass['CONVERTED'], name: oneClass['GOODS_VAL_LV01_DICT']})
    })
    this.cargoOptionYear = {
      legend: {
        top: 'bottom'
      },
      series: [
        {
          type: 'pie',
          radius: [12, 68],
          center: ['50%', '25%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: data,
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{time|{c}（车）}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              time: {
                fontSize: 10,
                color: '#0EFCFF'
              }
            }
          }
        }
      ]
    };
  }

  formatQuarter(pInputData) {
    let data = [];
    _.each(pInputData, oneClass => {
      data.push({value: oneClass['CONVERTED'], name: oneClass['GOODS_VAL_LV01_DICT']})
    })
    this.cargoOptionQuarter = {
      legend: {
        top: 'bottom'
      },
      series: [
        {
          type: 'pie',
          radius: [12, 68],
          center: ['50%', '25%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: data,
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{time|{c}（车）}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              time: {
                fontSize: 10,
                color: '#0EFCFF'
              }
            }
          }
        }
      ]
    };
  }

  formatMonth(pInputData) {
    let data = [];
    _.each(pInputData, oneClass => {
      data.push({value: oneClass['CONVERTED'], name: oneClass['GOODS_VAL_LV01_DICT']})
    })
    this.cargoOptionMonth = {
      legend: {
        top: 'bottom'
      },
      series: [
        {
          type: 'pie',
          radius: [12, 68],
          center: ['50%', '25%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: data,
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{time|{c}（车）}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              time: {
                fontSize: 10,
                color: '#0EFCFF'
              }
            }
          }
        }
      ]
    };
  }

  formatNotReturn(pInputData) {
    let data = [];
    _.each(pInputData, oneClass => {
      data.push({value: oneClass['CONVERTED'], name: oneClass['GOODS_VAL_LV01_DICT']})
    })
    this.cargoOptionNotReturn = {
      legend: {
        top: 'bottom'
      },
      series: [
        {
          type: 'pie',
          radius: [12, 68],
          center: ['50%', '25%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: data,
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{time|{c}（车）}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              time: {
                fontSize: 10,
                color: '#0EFCFF'
              }
            }
          }
        }
      ]
    };
  }


  formatWagonInOut() {
    let yAxis = [];
    let seriesIn = [];
    let seriesOut = [];
    let seriesSave = [];
    this.wagonInOut = _.orderBy(this.wagonInOut, ['RP_DATE'], ['asc']);

    _.each(this.wagonInOut, oneInOut => {
      yAxis.push(oneInOut['RP_DATE']);
      seriesIn.push(oneInOut['ACCESS_NUM']);
      seriesOut.push(-1 * oneInOut['RETURN_NUM']);
      seriesSave.push(oneInOut['PREVIOUS_NUM']);
    })

    this.wagonInOutOption = {
      color: ["#3398DB"],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        data: ['接入', '归还', '上日结存'],
        textStyle: {
          color: '#0EFCFF'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#0EFCFF'
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#0EFCFF'
            }
          },
          data: yAxis
        }
      ],
      series: [
        {
          name: '接入',
          type: 'bar',
          label: {
            show: true,
            position: 'inside',
            formatter: '{@score} (车)'
          },
          itemStyle: {
            color: '#536dbb'
          },
          emphasis: {
            focus: 'series'
          },
          data: seriesIn
        },
        {
          name: '归还',
          type: 'bar',
          stack: '总量',
          label: {
            show: true,
            position: 'inside',
            formatter: '{@score} (车)'
          },
          emphasis: {
            focus: 'series'
          },
          data: seriesOut
        }
      ]
    };
  }


  formatEfficient() {
    let xAxisDatas = [];
    let seriesDatas = [];

    _.each(this.thisYearMonth, oneMonth => {
      let found = false;
      _.each(this.efficient, oneEfficient => {
        if (oneMonth == oneEfficient['TIME_RETURN_MONTH']) {
          xAxisDatas.push(oneEfficient['TIME_RETURN_MONTH']);
          seriesDatas.push(oneEfficient['EFFICIENT']);
          found = true
        }
      })

      if (!found) {
        xAxisDatas.push(oneMonth);
        seriesDatas.push(0);
      }

    })
    this.efficientOption = {
      color: ["#3398DB"],
      title: {
        text: '年度工作效率变化',
        subtext: "车辆归还用时的平均值",
        textStyle: {
          color: '#0EFCFF'
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['归还国铁用时(小时)'],
        textStyle: {
          color: '#0EFCFF'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisDatas,
        axisLine: {
          lineStyle: {
            color: '#0EFCFF'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} 小时'
        },
        axisLine: {
          lineStyle: {
            color: '#0EFCFF'
          }
        }
      },
      series: [
        {
          name: '最大用时',
          type: 'line',
          data: seriesDatas,
          markPoint: {
            data: [
              {type: 'max', name: '最大用时'},
              {type: 'min', name: '最少用时'}
            ]
          },
          markLine: {
            data: [
              {type: 'average', name: '平均用时'}
            ]
          }
        }
      ]
    };

  }

  // 获取7天的起始日期
  getSearchDayData() {
    const today = moment().format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(7 - 1, 'days').format('YYYY-MM-DD');
    for (let i = 8 - 1; i >= 0; i--) {
      category.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return {
      searchData: data,
      category: category
    };
  }

  // 获取近现在这个月，往回12个月的起始日期
  getSearchMonthMData() {
    const today = moment().format('YYYY-MM');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'month').format('YYYY-MM');
    for (let i = 12 - 1; i >= 0; i--) {
      years.push(moment().subtract(i, 'month').format('YYYY-MM'));
    }
    return {
      searchData: data,
      category: years
    };
  }

  getSearchThisYearMonthMData() {
    const today = moment().format('YYYY');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'month').format('YYYY-MM');
    for (let i = 1; i <= 12; i++) {
      let oneMonth = today + "-" + _.padStart(i, 2, '0');
      years.push(oneMonth)
    }
    return {
      searchData: data,
      category: years
    };
  }


  // 获取近四年的起始日期
  getSearchYearData() {
    const today = moment().format('YYYY');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'year').format('YYYY');
    for (let i = 4 - 1; i >= 0; i--) {
      years.push(moment().subtract(i, 'year').format('YYYY'));
    }
    return {
      searchData: data,
      category: years
    };
  }

  getLastQuarter(thisMonthNumber) {
    let quarter = 0;
    if (1 <= thisMonthNumber && thisMonthNumber <= 3) {
      quarter = 1;
    } else if (4 <= thisMonthNumber && thisMonthNumber <= 6) {
      quarter = 2;
    } else if (7 <= thisMonthNumber && thisMonthNumber <= 9) {
      quarter = 3;
    } else if (10 <= thisMonthNumber && thisMonthNumber <= 12) {
      quarter = 4;
    }
    quarter = quarter - 1

    if (quarter == 0) {
      return this.years[this.years.length - 2] + "-" + 4;
    } else {
      return this.years[this.years.length - 1] + "-" + quarter;
    }
  }

  getLastMonth() {
    let dayNumber = this.day[this.day.length - 1];
    dayNumber = _.toInteger(_.split(dayNumber, "-")[-1]);
    let lastMonth = 0;
    if (dayNumber > 18) {
      lastMonth = this.month[this.month.length - 1];
    } else if (dayNumber <= 18) {
      lastMonth = this.month[this.month.length - 2];
    }
    return lastMonth;
  }

  ngOnInit() {
    const yearData = this.getSearchYearData();
    this.years = yearData.category || [];
    const monthData = this.getSearchMonthMData();
    this.month = monthData.category || [];
    const dayData = this.getSearchDayData();
    this.day = dayData.category || [];
    this.thisYear = this.years[this.years.length - 1];
    this.thisYearMonth = this.getSearchThisYearMonthMData().category || [];
    this.lastMonth = this.getLastMonth();
    const thisMonthNumber = _.toInteger(_.split(this.month[this.month.length - 1], '-')[1]);
    this.lastQuarter = this.getLastQuarter(thisMonthNumber);
    this.doRefresh();
  }
}
