import {Component, Input, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';
import * as moment from 'moment';
import {forkJoin as observableForkJoin} from 'rxjs';
import {RequestDataService} from '../../../../service/request-data.service';
import {UtilsService} from '../../../../service/utils.service';
import * as _sh from 'lodash';

@Component({
  selector: 'app-subject-confer-degree-iii',
  templateUrl: './subject-confer-degree-III.component.html',
  styleUrls: ['../subject-visual-report.component.css', './subject-confer-degree-III.component.css']
})
export class SubjectConferDegreeIIIComponent implements OnInit {

  isfull: any;
  nowYear: any = '';
  nowDate = null;
  areaHeight = '34vh';
  centerAreaHeight = '72vh';
  // 近五年科研项目数
  researchProjectData = [];
  researchProjectCount = 0;
  researchProjectCountMoney = 0;
  // 近五年科研成果转化情况
  researchResultsData = {
    reward: {},
    patents: {},
    usiing: {},
    book: {},
    paper: {}
  };

  // 近5年学术论文发表情况
  paperPublishData = [];
  paperPublishCount = 0;
  model: any = {
    left1: {},
    left2: {},
    center: {},
    right1: {},
    right2: {}
  };

  constructor(
    private requestDataService: RequestDataService,
    private utils: UtilsService) {
  }

  formaterStr(params, index, fontCount) {
    let newParamsName = '';// 最终拼接成的字符串
    let paramsNameNumber = params.length;// 实际标签的个数
    let provideNumber = fontCount;// 每行能显示的字的个数
    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
    // 条件等同于rowNumber>1
    if (paramsNameNumber > provideNumber) {
      /** 循环每一行,p表示行 */
      for (let p = 0; p < rowNumber; p++) {
        let tempStr = '';// 表示每一次截取的字符串
        let start = p * provideNumber;// 开始截取的位置
        let end = start + provideNumber;// 结束截取的位置
        // 此处特殊处理最后一行的索引值
        if (p == rowNumber - 1) {
          // 最后一次不换行
          tempStr = params.substring(start, paramsNameNumber);
        } else {
          // 每一次拼接字符串并换行
          tempStr = params.substring(start, end) + '\n';
        }
        newParamsName += tempStr;// 最终拼成的字符串
      }
    } else {
      // 将旧标签的值赋给新标签
      newParamsName = params;
    }
    //将最终的字符串返回
    return newParamsName;
  }

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

  // 近五年科研项目数
  researchProject(pListData) {
    let downTitle = [+this.nowYear - 4, +this.nowYear - 3, +this.nowYear - 2, +this.nowYear - 1, +this.nowYear];
    let seriesData = [];
    // 图例
    let legend = ['国家级', '省部级', '其他政府项目', '非政府项目（横向项目）'];
    for (let oneLe of legend) {
      let seriesObj = {
        name: oneLe,
        type: 'line',
        data: [],
        yAxisIndex: 0,
        label: {
          normal: {
            position: 'top',
            show: true,
            color: '#BFCBFF',
            formatter: '{c}'
          }
        },
        smooth: true,
        lineStyle: {
          width: 5
        },
      };
      for (let year of downTitle) {
        let findData = _sh.find(pListData, (item) => {
          return item['YEAR'] === year && item['PROJECT_LEVEL'] === oneLe;
        });
        if (this.utils.Base_HasValue(findData)) {
          let value = findData['START_COUNT'] || 0;
          this.researchProjectCount += value;
          seriesObj.data.push(value);
        } else {
          seriesObj.data.push(0);
        }
      }
      seriesData.push(seriesObj);
    }
    let option = {
      color: ['#F74C5A', '#FD9F28', '#2887ED', '#0AE77F'],
      legend: {
        data: legend,
        top: 0,
        icon: 'roundRect',
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: '{value}年',
            margin: 14
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
        }],
      series: seriesData
    };
    return option;
  }

  // 近五年科研项目情况
  researchProjectQk(pListData) {
    let downTitle = ['国家级', '省部级', '其他政府项目', '横向项目'];
    let seriesData = [];
    // 图例
    let legend = ['总数（个）', '总经费（万元）'];
    for (let oneLe of legend) {
      let seriesObj = {
        name: oneLe,
        type: 'bar',
        data: [],
        barWidth: '30%',
        yAxisIndex: 0,
        label: {
          normal: {
            position: 'top',
            show: true,
            color: '#BFCBFF',
            formatter: '{c}'
          }
        }
      };
      if (oneLe === '总经费（万元）') {
        seriesObj.yAxisIndex = 1;
      }
      let groupByData = _sh.groupBy(pListData, 'PROJECT_LEVEL');
      for (let oneTitle of downTitle) {
        let oneValue = 0;
        _sh.each(groupByData, (value, key) => {
          if (key === '非政府项目（横向项目）') {
            key = '横向项目';
          }
          if (key === oneTitle) {
            for (let item of value) {
              if (oneLe === '总经费（万元）') {
                oneValue += item['PROJECT_TOTAL'] || 0;
                this.researchProjectCountMoney += item['PROJECT_TOTAL'];
              } else {
                oneValue += item['START_COUNT'] || 0;
              }
            }
          }
        });
        seriesObj.data.push(oneValue);
      }
      seriesData.push(seriesObj);
    }
    let option = {
      color: ['#FDD728', '#07E97F'],
      legend: {
        data: legend,
        top: 0,
        icon: 'roundRect',
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            rotate: '45',
            formatter: (params, index) => {
              return this.formaterStr(params, index, 4);
            },
            margin: 14
          }
        }
      ],
      yAxis: [
        {
          name: '总数',
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 22, 10, 0]
          },
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
        },
        {
          type: 'value',
          name: '总经费',
          nameTextStyle: {
            color: '#BFCBFF',
            padding: [0, 0, 10, 22]
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#303743'
            }
          },
        }
      ],
      series: seriesData
    };
    return option;
  }

  // 近五年科研成果转化情况
  researchResult(pListData) {
    let downTitle = ['已授权发明专利数', '被省部级以上采纳的咨询报告数', '被国家标准采用的成果数', '出版专著数', '公开发表学术论文总篇数'];
    let seriesData = [
      {
        type: 'bar',
        barWidth: '40%',
        label: {
          normal: {
            position: 'right',
            show: true,
            color: '#BFCBFF'
          }
        },
        itemStyle: {
          normal: {
            // 定制显示（按顺序）
            color: function (params) {
              let colorList = ['#249CF9', '#FDB628', '#F9605C', '#0CC4FF', '#3CC885'];
              return colorList[params.dataIndex];
            }
          },
        },
        data: [pListData['patents']['PATENTS'], pListData['usiing']['PROVINCE_USIING'], pListData['usiing']['MATIONAL_USIING'], pListData['book']['ACOUNT'], pListData['paper']['ACOUNT']]
      }
    ];
    let option = {
      grid: {
        show: false,
        left: '2%',
        top: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12
          }
        }
      ],
      yAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            fontSize: 12,
            formatter: (params, index) => {
              return this.formaterStr(params, index, 7);
            }
          }
        }
      ],
      series: seriesData
    };
    return option;
  }

  // 近五年科研奖励情况
  researchAward(pListData) {
    let downTitle = ['国家级科研奖励', '省部级科研获奖数'];
    // 图例
    let legend = ['一等奖及以上', '二等奖及以下'];
    let option = {
      color: ['#FB6D5C', '#2A89EE'],
      legend: {
        data: legend,
        top: 0,
        itemHeight: 10,
        textStyle: {
          color: '#BFCBFF',
          padding: [0, 20, 0, 0]
        }
      },
      grid: {
        show: false,
        left: '2%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: downTitle,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {show: false},
          axisLabel: {
            color: '#BFCBFF',
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#BFCBFF',
            formatter: '{value}'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#303743'
            }
          },
        }
      ],
      series: [
        {
          name: '一等奖及以上',
          type: 'bar',
          data: [pListData['reward']['NATIONAL_FIRST_PRIZE'], pListData['reward']['PROVINCE_FIRST_PRIZE']],
          barWidth: '20%',
          yAxisIndex: 0,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          }
        },
        {
          name: '二等奖及以下',
          type: 'bar',
          data: [pListData['reward']['NATIONAL_SECOND_PRIZE'], pListData['reward']['PROVINCE_SECOND_PRIZE']],
          barWidth: '20%',
          yAxisIndex: 0,
          label: {
            normal: {
              position: 'top',
              show: true,
              color: '#BFCBFF',
              formatter: '{c}'
            }
          }
        }
      ]
    };
    return option;
  }

  // 近五年论文发表情况
  paperPublish(pListData) {
    let data = [];
    for (let oneData of pListData) {
      data.push({
        name: oneData['SOURCE_OR_TYPE'],
        value: oneData['ACOUNT']
      });
      this.paperPublishCount += oneData['ACOUNT'];
    }
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      graphic: [{
        type: 'text',
        left: 'center',
        top: '42%',
        style: {
          text: '近五年论文发表总数' + '\n' + this.paperPublishCount + '篇',
          textAlign: 'center',
          fill: '#8995CB',
          width: 30,
          height: 30,
          fontSize: 30
        }
      }],
      series: [
        {
          name: '收录类型',
          type: 'pie',
          radius: ['48%', '60%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            normal: {
              // 定制显示（按顺序）
              color: function (params) {
                let colorList = ['#F6465A', '#02EC7E', '#0965E3', '#0CC4FF', '#FDB628', '#02EC7E', '#0965E3', '#0CC4FF', '#FDB628'];
                return colorList[params.dataIndex];
              }
            },
          },
          label: {
            normal: {
              show: true,
              position: 'outside',
              formatter: '{a|{b}}\n{b|{c}篇}',
              rich: {
                a: {
                  fontWeight: 'bolder',
                  fontSize: 24,
                  lineHeight: 30,
                  align: 'center'
                },
                b: {
                  fontWeight: 'bolder',
                  fontSize: 28,
                  align: 'center'
                }
              }
            }
          },
          labelLine: {
            normal: {
              show: true,
              length: 30,
              length2: 50
            }
          },
          data: data
        }
      ]
    };
    return option;
  }

  initData() {
    if (this.utils.Base_HasValue(this.researchProjectData)) {
      this.model.left1 = this.researchProject(this.researchProjectData);
      this.model.right2 = this.researchProjectQk(this.researchProjectData);
    }
    this.model.left2 = this.researchResult(this.researchResultsData);
    this.model.right1 = this.researchAward(this.researchResultsData);
    if (this.utils.Base_HasValue(this.paperPublishData)) {
      this.model.center = this.paperPublish(this.paperPublishData);
    }
  }

  ngOnInit() {
    this.nowYear = moment().format('YYYY');
    this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    setInterval(() => {
      this.nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
    },1000);
    let conditon = {
      EVALUATION_YEAR_BEGIN: +this.nowYear - 5,
      EVALUATION_YEAR_END: +this.nowYear
    };
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_project_start_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_scientific_reward_info.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_patent_real.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_research_results.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_book_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_paper_count.json', conditon),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_paper.json', conditon),
    );
    parallel.subscribe(response => {
      this.researchProjectData = response[0]['data'];
      if (this.utils.Base_HasValue(response[1]['data'])) {
        this.researchResultsData.reward = response[1]['data'][0];
      }
      if (this.utils.Base_HasValue(response[2]['data'])) {
        this.researchResultsData.patents = response[2]['data'][0];
      }
      if (this.utils.Base_HasValue(response[3]['data'])) {
        this.researchResultsData.usiing = response[3]['data'][0];
      }
      if (this.utils.Base_HasValue(response[4]['data'])) {
        this.researchResultsData.book = response[4]['data'][0];
      }
      if (this.utils.Base_HasValue(response[5]['data'])) {
        this.researchResultsData.paper = response[5]['data'][0];
      }
      if (this.utils.Base_HasValue(response[6]['data'])) {
        this.paperPublishData = response[6]['data'];
      }
      // 初始化数据
      this.initData();
    });
  }

}
