import {Component, Input, OnInit,} from '@angular/core';
import * as _ from "underscore";
import * as _sh from 'lodash';
import {DataCenterService} from "../../../service/data-center.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {AppConfigService} from "../../../service/app-config.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
import {NzModalService} from "ng-zorro-antd";
import {UserInfoService} from "../../../service/user-info.service";
import {AutoInsertService} from "../../../service/auto-insert.service";

@Component({
  selector: 'app-storage-settlement-amount',
  templateUrl: './storage-settlement-amount.component.html',
  styleUrls: ['./storage-settlement-amount.component.scss']
})
export class StorageSettlementAmountComponent implements OnInit {

  @Input() parentData;

  msTranAccountForm: any = {
    "tablename": "ms_storage_expenditure_settlement",
    "formname": "ms_storage_expenditure_settlement",
    "title": "结算单管理",
    "type": "normal",
    "add_link": null,
    "modify_link": "JieLiv2/ms/ms_tran_account_detail.json",
    "columns": [
      {
        "column": "ID",
        "pk_column": true,
        "iscondition": true,
        "name": "ID_NAME",
        "component": "input",
        "lable": "主键",
        "comment": "主键",
        "placeholder": "主键",
        "insertValue": "SYS-ACTION-UUID",
        "model": "",
        "visible": false,
        "require": false
      },
      {
        "column": "MSES_CODE",
        "name": "MSES_CODE_NAME",
        "component": "input",
        "lable": "结算单号",
        "comment": "结算单号",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "结算单号自动生成",
        "model": null,
        "require": false,
        "visible": [
          "audit",
          "confirm",
          "reCheck"
        ],
        "disable": [
          "audit",
          "confirm",
          "reCheck"
        ]
      },
      {
        "column": "MSES_STATE",
        "name": "MSES_STATE_NAME",
        "component": "select",
        "lable": "状态",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "状态",
        "placeholder": "状态",
        "model": "ADD",
        "require": false,
        "dictionary": "JieLiv2/basic/dict/COUNT_STATE.json",
        "dictionaryPF": null,
        "visible": false
      },
      {
        "column": "COUNT_TYPE",
        "name": "COUNT_TYPE_NAME",
        "component": "select",
        "lable": "结算类型",
        "comment": "结算类型",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "结算类型",
        "model": null,
        "visible": false,
        "require": false,
        "insertValue": "ACCOUNT"
      },
      {
        "column": "LOADING_AND_UNLOADING_TEAM_NAME",
        "name": "LOADING_AND_UNLOADING_TEAM_NAME",
        "component": "poplist",
        "lable": "装卸队名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "装卸队名称",
        "placeholder": "请选择装卸队名称",
        "model": null,
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/basic_team_list.json",
        "dictionaryPF": null,
        "disable": [
          "audit",
          "confirm",
          "reCheck"
        ],
        "columnGroup": [
          {
            "column": "TEAM_MOBILE",
            "name": "TEAM_MOBILE",
            "component": "input",
            "lable": "电话",
            "comment": "电话",
            "placeholder": "电话",
            "fromValue": "TEAM_MOBILE",
            "model": null,
            "visible": true,
            "require": false,
            "disable": true
          }
        ],
      },
      {
        "column": "COUNT_ACTOR",
        "name": "COUNT_ACTOR_NAME",
        "component": "poplist",
        "lable": "经手人",
        "comment": "经手人",
        "placeholder": "请输入经手人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "model": "",
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/SYSTEM_USER_EMP_ALL.json",
        "disable": [
          "audit",
          "confirm",
          "reCheck"
        ]
      },
      {
        "column": "COUNT_VOUCHER",
        "name": "COUNT_VOUCHER_NAME",
        "component": "poplist",
        "lable": "证明人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "证明人",
        "placeholder": "请输入证明人",
        "model": "",
        "visible": true,
        "require": true,
        "dictionary": "JieLiv2/basic/dict/SYSTEM_USER_EMP_ALL.json",
        "disable": [
          "audit",
          "confirm",
          "reCheck"
        ]
      },
      {
        "column": "COUNT_USER",
        "name": "COUNT_USER",
        "component": "poplist",
        "lable": "结算人",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "结算人",
        "placeholder": "结算人",
        "model": "",
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_EMP.json",
        "dictionaryPF": null
      },
      {
        "column": "COUNT_TIME",
        "name": "COUNT_TIME",
        "component": "datetime",
        "lable": "结算时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "结算时间",
        "placeholder": "结算时间",
        "insertValue": "SYS-DATETIME",
        "model": "",
        "visible": false,
        "require": false
      },
      {
        "column": "PAYMENT_METHOD",
        "name": "PAYMENT_METHOD",
        "component": "select",
        "lable": "支付方式",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "comment": "支付方式",
        "placeholder": "支付方式",
        "model": "",
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/PAYMENT_METHOD.json",
        "dictionaryPF": null
      },
      {
        "column": "NUMBER_OF_ATTACHMENTS",
        "name": "NUMBER_OF_ATTACHMENTS_NAME",
        "component": "input",
        "type": "number",
        "lable": "附件张数",
        "comment": "附件张数",
        "placeholder": "附件张数",
        "model": 0,
        "visible": true,
        "require": false,
        "disable": [
          "audit",
          "confirm",
          "reCheck"
        ]
      },
      {
        "column": "SERIAL_NUMBER",
        "name": "SERIAL_NUMBER_NAME",
        "component": "input",
        "type": "number",
        "lable": "结算序号",
        "comment": "结算序号",
        "placeholder": "结算序号",
        "model": 1,
        "visible": [
          "audit",
          "confirm",
          "reCheck"
        ],
        "require": false,
        "disable": [
          "add",
          "modify",
          "confirm",
          "reCheck"
        ]
      },
      {
        "column": "PAYABLE_AMOUNT",
        "name": "PAYABLE_AMOUNT_NAME",
        "component": "input",
        "type": "number",
        "lable": "应付金额",
        "comment": "应付金额",
        "placeholder": "应付金额",
        "model": null,
        "require": false,
        "disable": [
          "detail",
          "audit",
          "confirm",
          "reCheck",
        ],
        "visible": [
          "detail",
          "audit",
          "confirm",
          "reCheck",
        ],
      },
      {
        "column": "PAID_IN_AMOUNT",
        "name": "PAID_IN_AMOUNT_NAME",
        "component": "input",
        "type": "number",
        "lable": "实付金额",
        "comment": "实付金额",
        "placeholder": "实付金额",
        "model": null,
        "require": false,
        "disable": [
          "detail",
          "audit",
          "confirm",
          "reCheck",
        ],
        "visible": [
          "detail",
          "audit",
          "auditCancel",
          "confirm",
          "reCheck",
        ],
      },
      {
        "column": "REMARKS",
        "name": "REMARKS_NAME",
        "component": "textarea",
        "lable": "备注",
        "class": "col-xs-12",
        "comment": "备注",
        "lableSpan": 3,
        "itemSpan": 21,
        "customize": 24,
        "placeholder": "请输入备注",
        "model": "",
        "visible": true,
        "require": false,
        "disable": [
          "confirm",
          "reCheck"
        ],
      },
      {
        "column": "ADD_USERID",
        "name": "ADD_USERID_NAME",
        "component": "input",
        "lable": "添加人账户ID",
        "comment": "添加人账户ID",
        "placeholder": "请输入添加人账户ID",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_ID",
        "model": null,
        "visible": false,
        "require": false,
        "commitPoint": [
          "add"
        ],
      },
      {
        "column": "ADD_TIME",
        "name": "ADD_TIME_NAME",
        "component": "input",
        "lable": "制单时间",
        "comment": "制单时间",
        "placeholder": "制单时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "commitPoint": [
          "add"
        ],
        "model": null,
        "visible": ["detail"]
      },
      {
        "column": "ADD_NAME",
        "name": "ADD_NAME_NAME",
        "component": "input",
        "lable": "制单人",
        "comment": "制单人",
        "placeholder": "请输入制单人姓名",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_NAME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
        "model": "",
        "visible": ["detail"]
      },
      {
        "column": "UPD_NAME",
        "name": "UPD_NAME_NAME",
        "component": "input",
        "lable": "更新人",
        "comment": "更新人",
        "placeholder": "请输入更新人姓名",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_NAME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "model": "",
        "visible": false
      },
      {
        "column": "UPD_TIME",
        "name": "UPD_TIME_NAME",
        "component": "input",
        "lable": "更新时间",
        "comment": "更新时间",
        "placeholder": "请输入更新时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "model": "",
        "visible": false
      },
      {
        "column": "UPD_USERID",
        "name": "UPD_USERID_NAME",
        "component": "input",
        "lable": "更新人ID",
        "comment": "更新人ID",
        "placeholder": "请输入添加人账户ID",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS_USER_ID",
        "model": "",
        "require": false,
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ],
        "visible": false
      },
      {
        "column": "U_TIME",
        "name": "U_TIME_NAME",
        "component": "input",
        "lable": "更新时间",
        "comment": "更新时间",
        "placeholder": "请选择更新时间",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "insertValue": "SYS-DATETIME",
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "add"
        ],
        "model": null,
        "visible": false
      }
    ]
  };

  //按钮和输入框状态
  btnAndInputState = {
    accountSaveVisit: false,//保存
    revocationVisit: false,//撤销
    auditVisit: false,//审核
    auditCancelVisit: false,//弃核
    confirmVisit: false,//确认结算
    confirmCancelVisit: false,//取消结算
    inputVisit: false,//结算明细的结算金额输入框
    exceptionBtnVisit: false,//异常处理按钮
    addAccountBtnVisit: false,//结算明细新增按钮
    deleteAccountBtnVisit: false,//结算明细删除按钮
    reCheckVisit: false,//复核
  }

  //结算主信息
  msTranAccount: any = {};
  //结算明细
  msTranDispatch: any = [];

  //运单信息
  msTranOrders: any = [];

  //异常单
  msTaskException: any = [];

  public msTranAccountDetailVisible = false;

  //添加 结算明细弹窗筛选输入项
  msTranAccountDetailModalForm: any = {
    "tablename": "ms_storage_orders",
    "formname": "ms_storage_orders",
    "title": "仓储订单查询",
    "type": "normal",
    "columns": [
      {
        "column": "DISP_CODE",
        "name": "DISP_CODE_NAME",
        "component": "input",
        "lable": "调度单号",
        "comment": "调度单号",
        "placeholder": "请输入调度单号",
        "model": null,
        "visible": true,
        "require": false,
        "type": "common"
      },
      {
        "column": "CUST_ORDER_CODE",
        "name": "CUST_ORDER_CODE_NAME",
        "component": "input",
        "lable": "客户订单号",
        "comment": "客户订单号",
        "placeholder": "请输入客户订单号",
        "model": null,
        "visible": true,
        "require": false,
        "type": "common"
      },
      {
        "column": "SERVICE_ID",
        "name": "SERVICE_ID_NAME",
        "component": "select",
        "lable": "业务类型",
        "comment": "业务类型",
        "placeholder": "业务类型",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_SERVICE_UP.json",
        "dictionaryPF": null,
        "type": "common"
      },
      {
        "column": "ADD_DATE_BEGIN",
        "name": "ADD_DATE_BEGIN_NAME",
        "component": "date",
        "lable": "调度时间-起",
        "comment": "调度时间-起",
        "placeholder": "请选择调度时间-起",
        "model": null,
        "visible": true,
        "require": false
      },
      {
        "column": "ADD_DATE_END",
        "name": "ADD_DATE_END_NAME",
        "component": "date",
        "lable": "调度时间-止",
        "comment": "调度时间-止",
        "placeholder": "请选择调度时间-止",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  }

  // 计算值
  summaryDatas: {
    EXPENDITURE_AMOUNT: number,
    EXPENDITURE: number,
    DISP_QUANTITY: number;
    DISP_VOLUME: number;
    DISP_WEIGHT: number;
    MONEY: number;
  } = {
      EXPENDITURE_AMOUNT: 0,//实际支出
      EXPENDITURE: 0,//核算支出
      DISP_QUANTITY: 0,//调度总数量
      DISP_VOLUME: 0,//调度总体积
      DISP_WEIGHT: 0,//调度总体重
      MONEY: 0,
    }


  otherMoneyModalForm: any = {
    "tablename": "ms_account_set",
    "formname": "ms_account_set",
    "title": "金额名称查询",
    "type": "normal",
    "columns": [
      {
        "column": "NAME",
        "name": "NAME_NAME",
        "component": "input",
        "lable": "名称",
        "comment": "名称",
        "class": "col-lg-4 col-sm-6 col-xs-12",
        "placeholder": "请输入名称",
        "model": null,
        "visible": true,
        "require": false
      }
    ]
  }

  otherMoneyShow: any = false;
  otherModalData;
  otherTotalModal: any = 1;
  otherPageIndexModal: any = 1;
  otherPageSizeModal: any = this.appConfigService.config.pageCount;
  otherMoneyDataList: any = [];
  allCheckedOther = false;
  indeterminateOther = false;
  otherAll = 0;
  accountAll = 0;
  borrowAll = 0;
  lockAll = 0;
  sumAll = 0;


  /**
   * 弹出框
   */
  //明细全选
  public allCheckedMsTranDispatch = false;
  public indeterminateMsTranDispatch = false;
  public modalDatas;
  // 分页下标
  public pageIndexModal: any = 1;
  // 每页显示数据
  public pageSizeModal: any = this.appConfigService.config.pageCount;
  // 总条数
  public totalModal: any = 1;
  public nzPageSizeOptionsModal: any = [10, 20, 30, 50, 100, 200];
  //弹窗的有值的条件
  private modalConditionList: any[];

  //异常全选
  public allCheckedMsTaskException = false;
  public indeterminateMsTaskException = false;

  constructor(
    private autoService: AutoInsertService,
    private dataCenterService: DataCenterService,
    private appConfigService: AppConfigService,
    private requestService: RequestDataService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private modalService: NzModalService,
    private userInfoService: UserInfoService
  ) {
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  //显示弹窗信息
  showMsTranAccountDetailVisible() {
    this.searchDataModal(true);
  }

  searchFilterModal(reset: boolean = true) {
    let condition = {};
    this.searchDataModal(reset, condition);
  }

  //弹窗数据
  searchDataModal(reset: boolean, condition?: {}) {
    if (reset) {
      this.pageIndexModal = 1;
    }
    const search01$ = observableForkJoin(
      this.requestService.getPaginationData('/JieLiv2/ms/ms_loading_unloading_expenditure_storage.json', condition, this.pageIndexModal, this.pageSizeModal),
    );
    search01$.subscribe(
      values => {


        this.modalDatas = _sh.get(values, [0, 'data'], []);//表格数据
        this.totalModal = _sh.get(values, [0, 'page', 'rowCount'], 0);//总页数
        this.pageIndexModal = _sh.get(values, [0, 'page', 'pageNum'], 0);//当前页

        //显示弹窗
        this.msTranAccountDetailVisible = true;
      }
    );
  }

  //过滤按钮
  searchFilter() {
    this.modalConditionList = this.transformCondition(this.msTranAccountDetailModalForm['columns']);
    let condition = {};
    _.each(this.modalConditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    this.searchDataModal(true, condition);
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  resetFilter() {
    _.each(this.msTranAccountDetailModalForm['columns'], (item) => {
      item['model'] = null;
    });
    this.searchFilter();
  }

  //选择一条结算明细数据
  selectOneModalData(oneModalData) {
    //  判断这个调度单，是否在 this.msTranDispatch 里面了，如果在报错。
    let check = true;
    this.msTranDispatch.forEach((item) => {
      if (item.DISP_ID == oneModalData.DISP_ID) {
        check = false;
      }
    })
    //  如果不在添加，并关闭。
    if (check) {
      oneModalData['checked'] = false;
      let finishNum = _sh.get(oneModalData, ['EXP_UN_COUNT_DICT'], 0);//已处理异常数量
      let sumNum = _sh.get(oneModalData, ['EXP_ALL_COUNT_DICT'], 0);//总异常数量
      oneModalData['redText'] = finishNum < sumNum;//存在异常则红色显示
      this.msTranDispatch.push(oneModalData);
      this.accountAll = 0;
      this.borrowAll = 0;
      this.lockAll = 0;
      this.sumAll = 0;
      // 重新求和
      // _sh.each(this.msTranDispatch, item => {
      //   let tempAccount = 0;
      //   let tempBorrow = 0;
      //   let tempLock = 0;
      //   let tempSum = 0;
      //   if (item['DET_MONEY'] != null) {
      //     tempAccount = item['DET_MONEY']
      //     this.accountAll = this.accountAll + tempAccount;
      //   }
      //   if (item['DISP_BORROW'] != null) {
      //     tempBorrow = item['DISP_BORROW']
      //     this.borrowAll = this.borrowAll + tempBorrow;
      //   }
      //   if (item['DISP_LOCK_MONEY'] != null) {
      //     tempLock = item['DISP_LOCK_MONEY']
      //     this.lockAll = this.lockAll + tempLock;
      //   }
      //   if (item['DISP_TOTALSFEE'] != null) {
      //     tempSum = item['DISP_TOTALSFEE']
      //     this.sumAll = this.sumAll + tempSum;
      //   }
      // })
      this.msTranAccountDetailVisible = false;
      observableForkJoin(
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_plant_detail.json', { 'DISP_ID': oneModalData.DISP_ID }),
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_task_exception_search.json', { 'DISP_ID': oneModalData.DISP_ID }),
      ).subscribe(res => {
        this.msTranOrders = _sh.get(res, [0, 'data']);
        this.msTaskException = _sh.get(res, [1, 'data']);
      })
    } else {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "该调度单已经添加到明细中，无需反复添加！"
      });
    }
    this.calcularSummary();
  }


  calcularSummary() {

    function calcOne(_list, _column) {
      let total = 0;
      for (const row of _list) {
        total += _sh.get(row, [_column], 0)
      }
      return total;
    }

    this.summaryDatas = {
      EXPENDITURE_AMOUNT: calcOne(this.msTranDispatch,'EXPENDITURE_AMOUNT'),
      EXPENDITURE: calcOne(this.msTranDispatch, 'EXPENDITURE'),
      DISP_WEIGHT: calcOne(this.msTranDispatch, 'DISP_WEIGHT'),
      DISP_QUANTITY: calcOne(this.msTranDispatch, 'DISP_QUANTITY'),
      DISP_VOLUME: calcOne(this.msTranDispatch, 'DISP_VOLUME'),
      MONEY: calcOne(this.otherMoneyDataList, 'MONEY'),
    }
  }

  refreshStatusMsTranDispatch(): void {
    const allChecked = this.msTranDispatch.length > 0 && this.msTranDispatch.every(value => value.checked === true);
    const allUnChecked = this.msTranDispatch.every(value => !value.checked);
    this.allCheckedMsTranDispatch = allChecked;
    this.indeterminateMsTranDispatch = (!allChecked) && (!allUnChecked);
  }


  checkAllMsTranDispatch(value: boolean): void {
    this.msTranDispatch.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusMsTranDispatch();
  }

  checkAllMsTaskException(value: boolean): void {
    this.msTaskException.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusMsTaskException();
  }

  refreshStatusMsTaskException(): void {
    const allChecked = this.msTaskException.length > 0 && this.msTaskException.every(value => value.checked === true);
    const allUnChecked = this.msTaskException.every(value => !value.checked);
    this.allCheckedMsTaskException = allChecked;
    this.indeterminateMsTaskException = (!allChecked) && (!allUnChecked);
  }

  //删除选中的结算明细数据
  deleteMsTranDispatch() {
    // let newMsTranDispatch = [];
    console.log('this.msTranDispatch:');
    console.log(this.msTranDispatch);
    let newMsTranDispatch = this.msTranDispatch.filter((e) => {
      return e.checked == false;
    })
    console.log('newMsTranDispatch:');
    console.log(newMsTranDispatch);
    if (newMsTranDispatch.length == this.msTranDispatch.length) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择一条数据进行删除！"
      });
    }
    this.msTranDispatch = newMsTranDispatch;
    this.calcularSummary();
  }

  //异常处理按钮
  handleException() {
    let newMsTaskException = this.msTaskException.filter((e) => {
      return e.checked == true;
    })
    if (newMsTaskException.length == 0) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择至少一条数据！"
      });
      return;
    }
    newMsTaskException.forEach((item) => {
      if (item.EXP_STATE == 1) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "请选择异常未处理的数据！"
        });
        return;
      }
    })
    //  弹窗
    this.modalService.create({
      nzTitle: '请处理异常作业',
      nzContent: FormModalComponent,
      nzWidth: 500,
      nzWrapClassName: 'vertical-center-modal',
      "nzComponentParams": {
        "modalFormData": {
          "columns": [
            {
              "column": "EXP_RESULT",
              "name": "EXP_RESULT_NAME",
              "component": "input",
              "customize": 24,
              "lable": "处理结果",
              "comment": "处理结果",
              "placeholder": "请输入处理结果",
              "model": null,
              "visible": true,
              "require": true
            },
            {
              "column": "EXP_DEAL_MAN",
              "name": "EXP_DEAL_MAN_NAME",
              "component": "poplist",
              "customize": 24,
              "lable": "处理人",
              "comment": "处理人",
              "placeholder": "请选择处理人",
              "model": null,
              "visible": true,
              "require": true,
              "dictionary": "JieLiv2/basic/dict/BASIC_EMP.json",
              "dictionaryPF": null,
              "columnGroup": [
                {
                  "column": "EXP_DEAL_MAN_NAME",
                  "name": "EXP_DEAL_MAN_NAME_NAME",
                  "component": "input",
                  "lable": "处理人",
                  "comment": "处理人",
                  "placeholder": "处理人",
                  "fromValue": "name",
                  "model": null,
                  "visible": false,
                  "require": false
                }
              ]
            },
            {
              "column": "EXP_DEAL_TIME",
              "name": "EXP_DEAL_TIME_NAME",
              "component": "datetime",
              "customize": 24,
              "lable": "处理时间",
              "comment": "处理时间",
              "placeholder": "请选择时间",
              "model": null,
              "visible": true,
              "require": true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        //  调用异常处理接口
        const data = response.modalFormData.columns;
        const user = this.userInfoService.getUserInfo();
        let queryData = {
          "expDealMan": _sh.get(data, [1, 'model'], ''),
          "expDealManName": _sh.get(data, [1, 'columnGroup', 0, 'model'], ''),
          "expDealTime": _sh.get(data, [2, 'model'], ''),
          "expResult": _sh.get(data, [0, 'model'], ''),
          "updName": user.USERNAME
        };

        let ids = this.formatSelectEntity(newMsTaskException, 'EXP_ID');
        let bodyData = {
          "ids": ids
        }

        this.requestService.doActionByHdbsthor('AppApi/MsTaskExceptionController', 'batchHandleTaskException', queryData, bodyData, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(response => {
          if (response.toString() === 'true') {
            this.ngOnInit();

            this.confirmModal.show('success', { 'title': '执行成功' });
          } else {
            this.confirmModal.show('error', {
              'title': '执行成功错误如下',
              'content': response.error.message
            });
          }
        });

      }
    });
  }

  //获取ids
  formatSelectEntity(datas, pColumn) {
    let entity: any = [];
    _.each(datas, (OneData) => {
      let obj: any = {};
      const keys = _.keys(OneData);
      _.each(keys, (oneKeys) => {
        if (oneKeys === pColumn) {
          obj['id'] = OneData[oneKeys];
        }
      });
      entity.push(obj);
    });
    return entity;
  }

  //点击调度单数据更新运单信息和异常信息
  getTranAndExcData(oneModalData) {
    observableForkJoin(
      this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_tran_plant_detail.json', { 'DISP_ID': oneModalData.DISP_ID }),
      this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_task_exception_search.json', { 'DISP_ID': oneModalData.DISP_ID }),
    ).subscribe(res => {
      this.msTranOrders = _sh.get(res, [0, 'data']);
      this.msTaskException = _sh.get(res, [1, 'data']);
    })
  }


  getValueFromData(_form, _columns) {

    let findValue = null;

    if (this.parentData.modeType == "add" || this.parentData.modeType == "audit") {
      _sh.each(_form.columns, oneColumn => {
        if (oneColumn.column == _columns) {
          findValue = oneColumn.model;
        }
      });
    } else if (this.parentData.modeType == "modify") {

      _sh.each(_form.columns, oneColumn => {
        if (oneColumn.component == "poplist") {//如果是点击框
          // modelName
          if (oneColumn.column == _columns) {

            //把column中 id返回
            findValue = oneColumn[`${oneColumn['column']}_USERID`];
            if (oneColumn['column'] == "LOADING_AND_UNLOADING_TEAM_NAME") {
              findValue = oneColumn['LOADING_AND_UNLOADING_TEAM_ID'];
            }
          }
        } else {//如果是点击输入框
          // model
          if (oneColumn.column == _columns) {
            findValue = oneColumn.model;
          }
        }
      });

    }
    return findValue;
  }

  // 保存
  accountSave() {
    if (!this.checkData()) {
      return;
    }

    let interfaceBody = {
      msStorageExpenditureSettlement: {
        countActorUserid: this.getValueFromData(this.msTranAccountForm, 'COUNT_ACTOR'),//经手人
        countVoucherUserid: this.getValueFromData(this.msTranAccountForm, 'COUNT_VOUCHER'),//证明人
        remarks: this.getValueFromData(this.msTranAccountForm, 'REMARKS'),//备注
        loadingAndUnloadingTeamId: this.getValueFromData(this.msTranAccountForm, 'LOADING_AND_UNLOADING_TEAM_NAME'),//装卸队名称
        addUserid: this.userInfoService.getUserInfo().USER_ID,
      },
      msStorageExpenditureSettlementDetailList: [],
      msStorageExpenditureSettlementOtherList: []
    }
    _sh.each(this.otherMoneyDataList, oneRow => {
      interfaceBody.msStorageExpenditureSettlementOtherList.push({
        "setId": oneRow['SET_ID'],
        "money": oneRow['MONEY']
      })
    });
    _sh.each(this.msTranDispatch, oneRow => {
      interfaceBody.msStorageExpenditureSettlementDetailList.push({
        "dispId": oneRow['DISP_ID'],
        "expenditureAmount": oneRow['EXPENDITURE_AMOUNT']
      })
    })


    //修改
    if (this.parentData.modeType == "modify") {
      let queryData = {
        'id': this.parentData.id
      }
      this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "modifySettlement", queryData, interfaceBody, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
        if (res.status == 500) {
          this.confirmModal.show('error', {
            'title': '执行错误',
            'content': res.error.message
          });
        } else if (res) {
          this.gotoBack();
          this.confirmModal.show('success', {
            'title': '保存成功',
          });
        }
      });
    }

    //
    if (this.parentData.modeType == "add") {
      this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "addSettlement", {}, interfaceBody, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
        if (res.status == 500) {
          this.confirmModal.show('error', {
            'title': '执行错误',
            'content': res.error.message
          });
        } else if (res) {
          this.gotoBack();
          this.confirmModal.show('success', {
            'title': '保存成功',
          });
        }
      });
    }
  }

  //整理保存/新增数据
  formatMainToEntity(pForm) {
    let entity: any = {};
    _sh.each(pForm, (item) => {
      const column = _sh.camelCase(_sh.get(item, 'column'));
      entity[column] = _sh.get(item, 'model');
      let columnGroup = _sh.get(item, 'columnGroup', null);
      _sh.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
        entity[itemColumn] = _sh.get(oneGroupColumn, 'model');
      });
    });
    return entity;
  }

  //必填项校验
  checkInput() {
    let result = true;

    this.msTranAccountForm['columns'].forEach((item) => {
      if (result && item['require'] == true && !this.utils.Base_HasValue(item['model'])) {
        result = false;
        this.confirmModal.show('error', { 'title': '必填项', 'content': item['comment'] + '为必填项' });
      }
    })
    return result;
  }

  //反写值到输入框
  // pForm 表  pDataArray 请求到的
  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        oneColumn['model'] = oneData[oneColumn['column']];

        oneColumn[`${oneColumn['column']}_USERID`] = oneData[`${oneColumn['column']}_USERID`]

        if (oneColumn['column'] == "LOADING_AND_UNLOADING_TEAM_NAME") {
          oneColumn['LOADING_AND_UNLOADING_TEAM_ID'] = oneData['LOADING_AND_UNLOADING_TEAM_ID'];
        }


        // oneColumn[`${pForm['columns']}_USERID`] = oneData[oneColumn[`${pForm['columns']}_USERID`]];

        //每一个column对应的id也得有值
        if (_.contains(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
          oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
        }

        if (oneColumn['component'] == 'file') {
          let url = oneData[oneColumn['column']];
          let fileName = this.utils.Base_getFileNameOnly(url);
          oneColumn['fileList'] = [];
          oneColumn['fileList'].push(
            {
              uid: this.utils.Base_getUuid(),
              name: fileName,
              status: 'done',
              url: url
            }
          )
        }
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
          if (_.contains(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
            oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
          }
        })
      })
    })
  }

  async ngOnInit() {
    //按钮和输入框的权限
    this.updateVisit();

    if (this.parentData.modeType == "modify" || this.parentData.modeType == "audit" || this.parentData.modeType == "reCheck" || this.parentData.modeType == "confirm" || this.parentData.modeType == "revocation" || this.parentData.modeType == "auditCancel" || this.parentData.modeType == "confirmCancel") {
      const res = await observableForkJoin(
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/storage_settlement_amount_list.json', { 'ID': this.parentData.id }),//仓储作业支出结算管理
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_storage_expenditure_settlement_detail.json', { 'MAIN_ID': this.parentData.id }),//仓储作业支出结算明细
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_storage_expenditure_settlement_other.json', { 'MAIN_ID': this.parentData.id }),//仓储作业支出结算其他费用明细
      ).toPromise();
      // 通过装卸队id查询在装卸队表查装卸队手机号
      const disburseRes = await observableForkJoin(
        this.requestService.getPaginationData('/JieLiv2/basic/basic_team_list.json', { 'TEAM_ID': res[0]['data'][0]['LOADING_AND_UNLOADING_TEAM_ID'] }),
      ).toPromise();

      //填充结算主表数据
      // oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
      res[0]['data'][0]['LOADING_AND_UNLOADING_TEAM_NAME_DICT'] = res[0]['data'][0]['LOADING_AND_UNLOADING_TEAM_NAME'];//装卸队
      res[0]['data'][0]['COUNT_ACTOR_DICT'] = res[0]['data'][0]['COUNT_ACTOR'];//经手人
      res[0]['data'][0]['COUNT_VOUCHER_DICT'] = res[0]['data'][0]['COUNT_VOUCHER'];//证明人
      res[0]['data'][0]['TEAM_MOBILE'] = disburseRes[0]['data'][0]['TEAM_MOBILE'];//电话

      // this.msTranAccountForm.columns[4].modelName = "1212";
      // 获取仓储作业支出结算管理
      this.backfillData(this.msTranAccountForm, res[0]['data']);

      // 结算明细
      let msTranAccountDetail = _sh.get(res, [1, 'data']);

      let MAIN_ID = msTranAccountDetail[msTranAccountDetail.length - 1]['MAIN_ID'];

      const resDetail = await observableForkJoin(
        this.requestService.getDataByCondition(this.appConfigService.config.sql_path + '/ms/ms_loading_unloading_expenditure_storage_select.json', { 'MAIN_ID': MAIN_ID }),
      ).toPromise();
      this.msTranDispatch = _sh.get(resDetail, [0, 'data']);

      // 计算
      this.msTranDispatch.forEach(item => {
        item.checked = false;
        this.summaryDatas.EXPENDITURE_AMOUNT = this.summaryDatas.EXPENDITURE_AMOUNT + item['EXPENDITURE_AMOUNT'];//实际支出
        this.summaryDatas.EXPENDITURE = this.summaryDatas.EXPENDITURE + item['EXPENDITURE'];//核算支出
        this.summaryDatas.DISP_QUANTITY = this.summaryDatas.DISP_QUANTITY + item['DISP_QUANTITY'];//调度总数量
        this.summaryDatas.DISP_VOLUME = this.summaryDatas.DISP_VOLUME + item['DISP_VOLUME'];//调度总体积
        this.summaryDatas.DISP_WEIGHT = this.summaryDatas.DISP_WEIGHT + item['DISP_WEIGHT'];//调度总体重
      })

      // 其他费用明细
      this.otherMoneyDataList = _sh.get(res, [2, 'data']);
      _sh.each(this.otherMoneyDataList, item => {
        item['ID'] = item['SET_ID'];
        item['NAME'] = item['SET_NAME'];
        item['KINGDEE_ACCOUNT_CODE'] = item['SET_CODE'];
        this.summaryDatas.MONEY = item['MONEY'] + this.summaryDatas.MONEY;
        item.checked = false
      })
    }
  }

  //审核
  audit() {
    if (!this.checkData()) {
      return;
    }

    this.confirmModal.show('confirm', {
      title: '确认审核该结算单吗？',
      suc: () => {
        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID,
          'serialNumber': this.getValueFromData(this.msTranAccountForm, 'SERIAL_NUMBER'), //结算序号
          'countText': this.getValueFromData(this.msTranAccountForm, 'REMARKS'),//备注
        }

        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "reviewSettlement", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '审核成功',
            });
          }
        });
      }
    });
  }

  //撤销
  revocation() {
    if (!this.checkData()) {
      return;
    }

    this.confirmModal.show('confirm', {
      title: '确认撤销该结算单吗？',
      suc: () => {
        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID
        }
        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "rescind", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '撤销成功',
            });
          }
        });
      }
    });
  }

  //弃核
  auditCancel() {
    if (!this.checkData()) {
      return;
    }
    this.confirmModal.show('confirm', {
      title: '确认弃核该结算单吗？',
      suc: () => {

        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID
        }
        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "cancelReview", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '弃核成功',
            });
          }
        });

      }
    });
  }


  confirm() {
    let fileNum = 0;
    let countText = '';
    this.msTranAccountForm.columns.forEach(item => {
      if (item['column'] == "FILE_NUM") {
        fileNum = item['model'];
      }
      if (item['column'] == "REMARKS") {
        countText = item['model'];
      }
    });

    this.modalService.create({
      nzTitle: '结算确认',
      nzContent: FormModalComponent,
      nzWidth: 500,
      "nzWrapClassName": "vertical-center-modal",
      "nzComponentParams": {
        "modalFormData": {
          "columns": [
            {
              "column": "PAYMENT_METHOD",
              "name": "PAYMENT_METHOD_NAME",
              "component": "select",
              "type": "number",
              "customize": 24,
              "lable": "支付方式",
              "comment": "支付方式",
              "placeholder": "请选择支付方式",
              "model": null,
              "visible": true,
              "require": true,
              "dictionary": "JieLiv2/basic/dict/PAYMENT_METHOD.json"
            },
            {
              "column": "COUNT_TEXT",
              "name": "COUNT_TEXT_NAME",
              "component": "textarea",
              "customize": 24,
              "lable": "备注",
              "comment": "备注",
              "placeholder": "请输入备注",
              "model": countText,
              "visible": true,
              "require": false
            }
          ]
        }
      },
      nzOnOk: (response) => {
        //  调用确认结算处理接口
        const data = response.modalFormData.columns;
        let PAYMENT_METHOD = _sh.get(data, [0, 'model'], '');
        let COUNT_TEXT = _sh.get(data, [1, 'model'], '');

        //  发送请求
        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID,
          'paymentMethod': PAYMENT_METHOD,
          'countText': COUNT_TEXT
        }

        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "confirmSettlement", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '结算成功',
            });
          }
        });
      }
    });
  }

  //取消结算
  confirmCancel() {
    if (!this.checkData()) {
      return;
    }

    this.confirmModal.show('confirm', {
      title: '确认取消结算该结算单吗？',
      suc: () => {
        //  发送请求
        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID
        }

        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "cancelSettlement", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '已取消结算',
            });
          }
        });
      }
    });
  }

  //复核
  reCheck() {
    if (!this.checkData()) {
      return;
    }

    this.confirmModal.show('confirm', {
      title: '确认复核该结算单吗？',
      suc: () => {

        let queryData = {
          'id': this.parentData.id,
          'updUserId': this.userInfoService.getUserInfo().USER_ID
        }

        this.requestService.doActionByHdbsthor("AppApi/MsStorageExpenditureSettlementController", "reReview", queryData, {}, false, 'hdbsthor_JieLiv2', 'appToken').subscribe(res => {
          if (res.status == 500) {
            this.confirmModal.show('error', {
              'title': '执行错误',
              'content': res.error.message
            });
          } else if (res) {
            this.gotoBack();
            this.confirmModal.show('success', {
              'title': '审核成功',
            });
          }
        });
      }
    });



  }

  checkHasValue(pRecords, pColumn) {
    let count = 0;
    _sh.each(pRecords, oneRecord => {
      if (this.utils.Base_HasValue(_sh.get(oneRecord, [pColumn], null))) {
        count++;
      }
    });
    return count > 0;
  }

  nullDeleteColumns(pList, pColumn) {
    _sh.each(pList['COLUMNS'], oneColumn => {
      if (oneColumn['COLUMN'] == pColumn) {
        pList['COLUMNS'] = _sh.without(pList['COLUMNS'], oneColumn);
      }
    })
  }

  //检查数据
  checkData() {

    //判断装卸队
    let teamFlag = true;//装卸队
    this.msTranAccountForm['columns'].forEach((item) => {
      // 装卸队
      if (item.column == "LOADING_AND_UNLOADING_TEAM_NAME" && !this.utils.Base_HasValue(item.modelName)) {
        teamFlag = false;
      }
    })

    if (!teamFlag) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请选择装卸队！"
      });
      return false;
    }

    //必填字段
    if (!this.checkInput()) {
      return false;
    }

    //检查结算明细数据
    if (this.msTranDispatch.length == 0) {
      this.confirmModal.show('error', {
        'title': '执行错误',
        'content': "请添加至少一条结算明细数据！"
      });
      return false;
    }

    if (this.otherMoneyDataList.length > 0) {
      let temp = _sh.filter(this.otherMoneyDataList, function (e) {
        return e.MONEY == null;
      })
      if (temp.length > 0) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "其他费用明细请填写金额"
        });
        return false;
      }
    }

    //调度单结算金额>0
    let amountFlag = false;
    this.msTranDispatch.forEach((item) => {
      if (item['EXPENDITURE_AMOUNT'] <= 0) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "调度单号为" + item['DISP_CODE'] + "的实际支出必须大于0！"
        });
        amountFlag = true;
        return false;
      }
      if (item['EXPENDITURE_AMOUNT'] > item['EXPENDITURE']) {
        this.confirmModal.show('error', {
          'title': '执行错误',
          'content': "调度单号为" + item['DISP_CODE'] + "的实际支出大于核算支出！"
        });
        amountFlag = true;
        return false;
      }
    })
    return !amountFlag;

  }

  formVisable(_columnList, _visable, _require) {
    _sh.each(_columnList, oneVisColumnName => {
      _sh.each(this.msTranAccountForm.columns, oneColumn => {
        if (oneColumn.lable == oneVisColumnName) {
          oneColumn.visible = _visable
          oneColumn.require = _require;
        }
      })
    })
  }

  //按钮和输入框的权限
  updateVisit() {
    // 根据按钮的类型区分
    // this.parentData.modeType
    if (this.parentData.modeType == 'add' || this.parentData.modeType == 'modify') {//添加修改
      this.btnAndInputState.accountSaveVisit = true;
      this.btnAndInputState.addAccountBtnVisit = true;
      this.btnAndInputState.deleteAccountBtnVisit = true;
      // this.formVisable(['结算单号','结算序号'], false, false);
    } else if (this.parentData.modeType == 'revocation') {//撤销
      this.btnAndInputState.revocationVisit = true;
    } else if (this.parentData.modeType == 'audit') {//审核
      this.btnAndInputState.auditVisit = true;
      this.btnAndInputState.inputVisit = true;
    } else if (this.parentData.modeType == 'auditCancel') {//取消审核
      this.btnAndInputState.auditCancelVisit = true;
    } else if (this.parentData.modeType == 'confirm') {//确认结算
      this.btnAndInputState.confirmVisit = true;
      this.btnAndInputState.exceptionBtnVisit = true;
    } else if (this.parentData.modeType == 'confirmCancel') {//取消结算
      this.btnAndInputState.confirmCancelVisit = true;
    } else if (this.parentData.modeType == 'reCheck') {//复核
      this.btnAndInputState.reCheckVisit = true;
    }
  }

  addOtherMoney() {
    const search01$ = observableForkJoin(
      this.requestService.getPaginationData('JieLiv2/ms/ms_account_set.json', {}, this.pageIndexModal, this.pageSizeModal),
    );
    search01$.subscribe(
      values => {
        this.otherModalData = _sh.get(values, [0, 'data'], []);
        this.otherTotalModal = _sh.get(values, [0, 'page', 'rowCount'], 0);
        this.otherPageIndexModal = _sh.get(values, [0, 'page', 'pageNum'], 0);
        //显示弹窗
        this.otherMoneyShow = true;
      }
    );
  }

  deleteOtherMoney() {
    this.otherMoneyDataList = this.otherMoneyDataList.filter((e) => {
      return e.checked == false;
    });
    this.otherAll = 0
    _sh.each(this.otherMoneyDataList, item => {
      let temp = 0;
      if (item['MONEY'] != null) {
        temp = item['MONEY']
      }
      this.otherAll = temp + this.otherAll
    })
    this.calcularSummary();
  }

  checkAllOther(value: boolean): void {
    this.otherMoneyDataList.forEach(data => {
      data['checked'] = value;
    });
    this.refreshStatusOther();
  }

  refreshStatusOther(): void {
    const allChecked = this.otherMoneyDataList.length > 0 && this.otherMoneyDataList.every(value => value.checked === true);
    const allUnChecked = this.otherMoneyDataList.every(value => !value.checked);
    this.allCheckedOther = allChecked;
    this.indeterminateOther = (!allChecked) && (!allUnChecked);
  }

  selectOneOtherMoneyModalData(oneModalData) {
    let check = true;
    if (this.otherMoneyDataList.length > 0) {
      _sh.each(this.otherMoneyDataList, item => {
        if (item['SET_ID'] == oneModalData['ID']) {
          this.confirmModal.show("warning", { title: "已添加该类型金额，请勿重复添加" })
          check = false;
        }
      })
    }
    if (check) {
      oneModalData.MONEY = null;
      oneModalData.checked = false;
      oneModalData['SET_ID'] = oneModalData['ID']
      this.otherMoneyDataList.push(oneModalData)
      this.otherMoneyShow = false;
    }
    this.calcularSummary();
  }

  inputChange($event) {
    this.calcularSummary();
  }

}

