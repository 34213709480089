import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {DataService} from '../data.service';
import * as _ from 'underscore';
import {FormComponent} from '../../../basicComponent/form/form.component';
import {MultiPopupJsonComponent} from '../../../basicComponent/multi-popup-json/multi-popup-json.component';
import {AjaxService} from '../../../service/ajax.service';
import {UserInfoService} from '../../../service/user-info.service';
import {DetailActionService} from '../../../service/detail-action.service';
import {AutoInsertService} from '../../../service/auto-insert.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {ValidatorService} from '../../../service/validator.service';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements OnInit {

  @ViewChildren('mainForm') mainForm;
  @ViewChildren(FormComponent) childListForm: QueryList<FormComponent>;
  @ViewChildren(MultiPopupJsonComponent) popupComponent: QueryList<MultiPopupJsonComponent>;
  @Input() parentData: any;
  public helpUrl: string;

  // 用来判断当前明细是添加还是修改
  private itemIndex: any;
  // 将原始数据备份一下,为修改日志判断服务
  prvForms: any;
  _validateForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private ajaxService: AjaxService,
              private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private userInfoService: UserInfoService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private detailAction: DetailActionService,
              private validatorService: ValidatorService,
              private data: DataService,
              private autoService: AutoInsertService,
              private confirmModal: ConfirmModalService) {
  }

  // 组件添加formControl
  private addOneColumn(oneColumn, columns): FormControl {
    const validatorList = [];
    const asyncValidatorList = [];
    let needBlur = false;
    if (!!oneColumn.require) {
      validatorList.push(Validators.required);
    }
    if (oneColumn.type === 'email') {
      validatorList.push(Validators.email);
    }
    if (oneColumn.type === 'url') {
      validatorList.push(Validators.email);
    }
    if (this.utils.Base_HasValue(oneColumn.min)) {
      validatorList.push(Validators.min(oneColumn.min));
    }
    if (this.utils.Base_HasValue(oneColumn.max)) {
      validatorList.push(Validators.max(oneColumn.max));
    }
    if (this.utils.Base_HasValue(oneColumn.minlength)) {
      validatorList.push(Validators.minLength(oneColumn.minlength));
    }
    if (this.utils.Base_HasValue(oneColumn.maxlength)) {
      validatorList.push(Validators.maxLength(oneColumn.maxlength));
    }
    if (this.utils.Base_HasValue(oneColumn.pattern)) {
      validatorList.push(Validators.pattern(oneColumn.pattern));
    }
    if (this.utils.Base_HasValue(oneColumn.uniqueCheck)) {
      needBlur = true;
      asyncValidatorList.push(this.validatorService.uniqueCheck(this.parentData.modeType, columns, oneColumn).bind(this));
    }
    if (this.utils.Base_HasValue(oneColumn.actions)) {
      // TODO
    }
    if (this.utils.Base_HasValue(oneColumn.timeCheck)) {
      needBlur = true;
      validatorList.push(this.validatorService.timeCheckValidator(oneColumn.timeCheck.column).bind(this));
    }
    if (needBlur) {
      if (asyncValidatorList.length) {
        return new FormControl('', {validators: validatorList, asyncValidators: asyncValidatorList, updateOn: 'blur'});
      } else {
        return new FormControl('', {validators: validatorList, updateOn: 'blur'});
      }
    } else {
      return new FormControl('', validatorList);
    }
  }

  // form 添加组件controls
  private addOneForm(oneData): FormGroup {
    const group = {};
    _.each(oneData.columns, (item) => {
      if (_.isArray(item['visible'])) {
        item['visible'] = _.contains(item['visible'], this.parentData.modeType);
      }
      if (item['visible']) {
        group[item['name']] = this.addOneColumn(item, oneData);
        item['show'] = item['type'] === 'common' && !!item['visible'];
        if (this.utils.Base_HasValue(item['columnGroup'])
          && item['columnGroup'].length) {
          _.each(item['columnGroup'], (groupItem) => {
            group[groupItem['name']] = this.addOneColumn(groupItem, oneData);
          });
        }
      }
      if (_.isArray(item['disable'])) {
        item['disable'] = _.contains(item['disable'], this.parentData.modeType);
      }
    });

    const validateForm = this.formBuilder.group(group);
    validateForm['columns'] = oneData.columns;
    return validateForm;
  }

  // 格式化数据
  async transformData(data) {
    let linkUrl = data.detailColumns[0]['data'][0]['ORIGINAL_LINK'];
    let source = data.detailColumns[0]['data'][0]['SOURCE_REAL'];
    let content = data.detailColumns[0]['data'][0]['CONTENT'];
    if (this.utils.Base_HasValue(linkUrl) && source === '微信' && content.length < 20) {
      await this.data.getXiguajiHtml(linkUrl).subscribe((rep) => {
        if (this.utils.Base_HasValue(rep)) {
          _.each(data.detailColumns[0]['columns'], (oneData) => {
            if (oneData['column'] === 'CONTENT') {
              oneData['model'] = rep.html;
            }
          });
          data.detailColumns[0]['data'][0]['CONTENT'] = rep.html;
        }
      });
    }
    // 重新覆盖 datacenter 数据
    this.parentData.data = data;
    this.prvForms = this.utils.DeepCopy(this.parentData);
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this._validateForm = this.addOneForm(this.parentData.data.detailColumns);
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  // 按钮保存事件
  doAction(pBtn): void {
    let content = "";
    let newsPicture = this.parentData.data.detailColumns[0].data[0]['NEWS_PICTURES'];
    let source = this.parentData.data.detailColumns[0].data[0]['SOURCE_REAL'];
    _.each(this.parentData.data.detailColumns[0]['columns'], (item) => {
      if (item['column'] === 'CONTENT') {
        content = item['model'];
      }
      if (item['column'] === 'NEWS_PICTURES') {
        newsPicture = item['model'];
      }
    });
    if (pBtn.ACTION_ID === 'save' && source === '微信') {
      this.data.replaceNewsPicture(encodeURIComponent(content), newsPicture).subscribe((rep) => {
        if (this.utils.Base_HasValue(rep) && rep.state === 'success') {
          _.each(this.parentData.data.detailColumns[0]['columns'], (item) => {
            if (item['column'] === 'CONTENT' && this.utils.Base_HasValue(rep['content'])) {
              item['model'] = rep['content'];
            } else if (item['column'] === 'NEWS_PICTURES' && this.utils.Base_HasValue(rep['newsPicture'])) {
              item['model'] = rep['newsPicture'];
            }
          });
          this.detailAction.doAction(pBtn, this.parentData, this.mainForm, this.prvForms);
        } else {
          this.confirmModal.show('error', {title: '图片替换失败'});
        }
      });
    } else {
      this.detailAction.doAction(pBtn, this.parentData, this.mainForm, this.prvForms);
    }
  }

  // 该方法将popupJson中对应配置的column值获取到。没有就弹出提示。有的话放入condition
  transParamsToData(params) {
    const dataObj = {};
    const errorList = [];
    _.each(params, (item) => {
      const tablename = item['value'].split('.')[1];
      const column = item['value'].split('.')[2];
      const form = _.find(this.childListForm['_results'], v => v['formData']['type'] === 'normal' && v['formData']['tablename'] === tablename);
      if (this.utils.Base_HasValue(form)) {
        const data = _.find(form['_validateForm'].columns, (c) => {
          return c['column'] === column;
        });
        if (this.utils.Base_HasValue(data)) {
          if (this.utils.Base_HasValue(data['model'])) {
            dataObj[item['column']] = data['model'];
          } else {
            errorList.push('请先选择' + data['lable']);
          }
        }
      }
    });
    return {
      dataObj: dataObj,
      errorList: errorList
    };
  }

  getChildForm(oneForm): FormComponent {
    return _.find(this.childListForm['_results'], (item) => {
      return item.formData['formname'] === oneForm.formname;
    });
  }

  ngOnInit(): void {
    // 如果该tab数据不存在则需要查询
    if (!this.utils.Base_HasValue(this.parentData.data)) {
      const parallel$ = observableForkJoin(
        this.requestDataService.getFormJson(this.parentData.modeType, this.parentData),
        this.requestDataService.getBtnDetailJson(this.parentData.nowMenu.MENU_ID)
      );
      parallel$.subscribe(
        values => {
          const dataObject = values[0];
          this.parentData.actionArray = values[1]['data'];
          this.transformData(dataObject);
        }
      );
    } else {
      this.prvForms = this.utils.DeepCopy(this.parentData);
    }
    this.helpUrl = this.parentData.addHelpUrl;
  }

}
