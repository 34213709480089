import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hulk-config-select',
  templateUrl: './hulk-config-select.component.html',
  styleUrls: ['./hulk-config-select.component.scss']
})
export class HulkConfigSelectComponent implements OnInit {

  @Input() oneColumn: any;
  @Input() attributes: any;

  @Input() selectOptions = [];
  @Input() label: any;
  @Input() code = 'code';
  @Input() name = 'name';
  @Input() nzSpanLable = 3;
  @Input() nzSpanInput = 21;

  constructor() {
  }

  ngOnInit() {
  }

  modelChange(model) {
    this.oneColumn[this.attributes] = model;
  }

}
