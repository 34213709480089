import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AppConfigService} from '../../../service/app-config.service';
import {UtilsService} from '../../../service/utils.service';
import {UploadService} from '../../../service/upload.service';
import {RequestDataService} from '../../../service/request-data.service';
import {AjaxService} from '../../../service/ajax.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {DataCenterService} from '../../../service/data-center.service';
import {UserInfoService} from '../../../service/user-info.service';
import {forkJoin as observableForkJoin} from 'rxjs';


@Component({
  selector: 'app-input-service',
  templateUrl: './input-service.component.html',
  styleUrls: ['./input-service.component.css']
})
export class InputServiceComponent implements OnInit {

  @Input() parentData: any;
  @Input() modeType: string = '';

  serviceId: String;

  selectIndex = {
    'groupIndex': -1,
    'samllGroupIndex': -1
  };

  groupModalVisible = false;
  groupModalDatas = {
    'group_name': null
  };
  componentModalVisible = false;
  componentDatas = {};

  groupEdit(pOne) {
    this.groupModalDatas = pOne;
    this.groupModalVisible = true;
  }

  groupModelCancel() {
    this.groupModalVisible = false;
  }

  componentEdit(pOneComponent) {
    this.componentModalVisible = true;
    this.componentDatas = pOneComponent;
  }

  componentCancel() {
    this.componentModalVisible = false;
  }

  showDetail() {
  }

  formatMainToEntity() {
    let entity: any = {};
    _.each(_.get(this.formData, 'columns'), (item) => {
      const column = _.camelCase(_.get(item, 'column'));
      const value = _.get(item, 'model');
      entity[column] = value;
      let columnGroup = _.get(item, 'columnGroup', null);
      _.each(columnGroup, (oneGroupColumn) => {
        const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
        const itemValue = _.get(oneGroupColumn, 'model');
        entity[itemColumn] = itemValue;
      });
    });
    return entity;
  }


  findOneColumnValue(pColumnName) {
    let columns = _.get(this.formData, 'columns', []); // 找到 columns 层级
    let oneValue = null; // 定义一个返回值，给予默认值为控制

    _.each(columns, (oneOneColum) => { // 循环 columns 层级
      if (oneOneColum['column'] === pColumnName) { // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
        oneValue = _.get(oneOneColum, 'model');
      }
      let columnGroup = _.get(oneOneColum, 'columnGroup', null); // 找到 columnGroup 层级
      _.each(columnGroup, (oneGroupColumn) => {
        if (oneGroupColumn['column'] === pColumnName) {  // 如果循环体之中遇到名称与传入的参数的名称是一样的。把model的值赋予给 返回值
          oneValue = _.get(oneGroupColumn, 'model', null);
        }
      });
    });
    return oneValue; // 返回值
  }

  checkOneColumn(pColumnName, pContent) {
    let result = true;
    if (!this.util.Base_HasValue(this.findOneColumnValue(pColumnName))) {
      result = false;
      this.confirmModal.show('error', {'title': '必填项', 'content': pContent + '为必填项'});
    }
    return result;
  }

  checkInput() {
    let result = true;

    result = this.checkOneColumn('SERVICE_NAME', '服务产品名称');
    if (!result) {
      return result;
    }
    result = this.checkOneColumn('SERVICE_FILE01', '文档下载01');
    if (!result) {
      return result;
    }
    return result;
  }

  saveGeService() {
    if (!this.checkInput()) {
      return false;
    } else {
      const queryData = {
        'userId': this.userInfoService.getUserInfo().USER_ID,
      };
      let bodyData = this.formatMainToEntity();
      bodyData['id'] = this.serviceId;
      bodyData['serviceJson'] = JSON.stringify(this.serviceData);
      this.requestService.doActionByHdbsthor('AppApi/GeServiceController', 'input', queryData, bodyData, false, 'hdbsthor_genecreate', 'appToken').subscribe(response => {
        if (response['result']) {
          this.serviceId = response['geService']['id'];
          this.confirmModal.show('success', {'title': '执行成功'});
        } else {
          this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
        }
      });
    }

  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  /**
   * 删除大分组
   * @param pDatas
   */
  public deleteGroup(pOne) {
    this.serviceData = _.without(this.serviceData, pOne);
  }

  /**
   * 删除组件
   * @param pOneSmallGroup
   * @param pOneComponents
   */
  public deleteComponents(pOneSmallGroup, pOneComponents) {
    pOneSmallGroup['components'] = _.without(pOneSmallGroup['components'], pOneComponents);
  }


  /**
   * 删除一小组
   * @param pOneGroup
   * @param pOneSmallGroup
   */
  public deleteSmallGroups(pOneGroup, pOneSmallGroup) {
    pOneGroup['small_groups'] = _.without(pOneGroup['small_groups'], pOneSmallGroup);
  }

  drop(small_, event: CdkDragDrop<string[]>): void {
    moveItemInArray(small_, event.previousIndex, event.currentIndex);
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  pubItLastComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].push(pOneComponent);
  }

  pubItFirstComponent(pGroupIndex, pSmallGroupIndex, pOneComponent) {
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'] = _.without(this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'], pOneComponent);
    this.serviceData[pGroupIndex]['small_groups'][pSmallGroupIndex]['components'].unshift(pOneComponent);
  }


  putItLastGroup(pOneGroup) {
    this.serviceData = _.without(this.serviceData, pOneGroup);
    this.serviceData.push(pOneGroup);
  }

  pubItFirstGroup(pOneGroup) {
    this.serviceData = _.without(this.serviceData, pOneGroup);
    this.serviceData.unshift(pOneGroup);
  }

  doAddGroup() {
    let oneGroup = {
      'group_name': '新建的大分组名称',
      'visible': true,
      'small_groups': []
    };
    this.serviceData.push(oneGroup);
    this.createNotification('success', '成功', '成功添加大分组组件');

  }

  doAddSmallGroup() {
    if (this.selectIndex.groupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个大分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个大分组。'});
    } else {
      let newObj = {
        'small_group_name': '新建小分组名称',
        'components': []
      };
      this.serviceData[this.selectIndex.groupIndex]['small_groups'].push(newObj);
      this.createNotification('success', '成功', '成功添加小分组组件');
    }
  }

  doAddCheckboxs() {
    if (this.selectIndex.groupIndex == -1 || this.selectIndex.samllGroupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else {
      let newObj = {
        'type': 'checkboxs',
        'label': '新建的多选框',
        'model': null,
        'require': false,
        'list': [
          {
            'label': '新建选项',
            'type': 'normal',
            'nzSpan': 8,
            'model': null,
            'inputModel': null
          }
        ]
      };
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].push(newObj);
      this.createNotification('success', '成功', '成功添加多选框组件');
    }
  }

  doAddRadios() {
    if (this.selectIndex.groupIndex == -1 || this.selectIndex.samllGroupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else {
      let newObj = {
        'type': 'radios',
        'label': '新建的单选框',
        'model': null,
        'list': [
          {
            'label': '新建选项',
            'type': 'normal',
            'nzSpan': 8,
            'model': null,
            'inputModel': null
          }
        ]
      };
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].push(newObj);
      this.createNotification('success', '成功', '成功添加多选框组件');
    }
  }

  doAddInput() {
    if (this.selectIndex.groupIndex == -1 || this.selectIndex.samllGroupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else {
      let newObj = {
        'type': 'input',
        'label': '新建的输入框',
        'model': null,
        'list': []
      };
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].push(newObj);
      this.createNotification('success', '成功', '成功添加输入框组件');

    }
  }

  doAddTable() {
    if (this.selectIndex.groupIndex == -1 || this.selectIndex.samllGroupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else {
      let newObj = {
        'type': 'table',
        'label': '新建表格',
        'model': null,
        'list': []
      };
      newObj['model'] = [
        {
          '第一列': 'a11',
          '第二列': 'b11'
        }
      ];
      newObj['list'] = [
        {
          'label': '第一列',
        },
        {
          'label': '第二列',
        }
      ];
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].push(newObj);
      this.createNotification('success', '成功', '成功添加输入框组件');

    }
  }

  doAddDescribe() {
    if (this.selectIndex.groupIndex == -1 || this.selectIndex.samllGroupIndex == -1) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else if (!this.util.Base_HasValue(this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex])) {
      this.confirmModal.show('error', {title: '请先选中某一个小分组。'});
    } else {
      let newObj = {
        'type': 'describe',
        'label': '',
        'model': null,
        'list': []
      };
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].push(newObj);
      let componentLen = this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'].length;
      this.serviceData[this.selectIndex.groupIndex]['small_groups'][this.selectIndex.samllGroupIndex]['components'][componentLen - 1]['model'] = '新建的说明文字';
      this.createNotification('success', '成功', '成功添加说明文字组件');
    }
  }

  /**
   * 将所有的选中清空
   */
  doSmallGroupClearSelected() {
    _.each(this.serviceData, oneGroup => {
      oneGroup['checked'] = false;
      _.each(oneGroup['small_groups'], oneSamllGroup => {
        oneSamllGroup['checked'] = false;
      });
    });
  }

  /**
   * 选择一个小分组
   * @param pOneGroup
   * @param pOneSmallGroup
   */
  doSmallGroupSelected(pOneGroup, pOneSmallGroup, pGroupIndex, pSmallGroupIndex) {
    this.doSmallGroupClearSelected();
    this.selectIndex = {
      'groupIndex': pGroupIndex,
      'samllGroupIndex': pSmallGroupIndex
    };
    pOneGroup['checked'] = true;
    pOneSmallGroup['checked'] = true;
  }

  /**
   * 选择一个大分组
   * @param pOneGroup
   * @param pGroupIndex
   */
  doGroupSelected(pOneGroup, pGroupIndex) {
    this.doSmallGroupClearSelected();
    this.selectIndex = {
      'groupIndex': pGroupIndex,
      'samllGroupIndex': -1
    };
    pOneGroup['checked'] = true;
  }

  createNotification(type: string, title: string, content: string): void {
    this.notification.create(
      type,
      title,
      content
    );
  }

  constructor(private modalService: NzModalService,
              private config: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private uploadService: UploadService,
              private requestService: RequestDataService,
              private ajaxService: AjaxService,
              private notification: NzNotificationService,
              private userInfoService: UserInfoService,
              private confirmModal: ConfirmModalService,
              private util: UtilsService
  ) {
  }

  backfill(pRECORDS, pColumnName) {
    _.each(this.formData['columns'], oneColumn => {
      if (oneColumn['column'] === pColumnName) {
        const value = _.get(pRECORDS, [0, pColumnName])
        _.set(oneColumn, ['model'], value);
        if ((this.util.Base_HasValue(value)) && (_.indexOf(['file', 'picture'], oneColumn['component']) > -1)) {
          _.set(oneColumn, ['fileList', 0], {
            uid: 1,
            name: '文件',
            status: 'done',
            url: value
          });
        }
      }
    })
  }

  buildRequireMsg(pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex, pLabel) {
    let compiled = _.template('第<%= bigGroupIndex %><%= bigGroupName %>项的，第<%= smallGroupIndex %><%= smallGroupName %>小项的，第<%= componentIndex %><%= label %> 子项 ，是必填项。');
    let bigGroupName = _.isEmpty(pBigGroupName) ? '' : ' [' + pBigGroupName + '] ';
    let smallGroupName = _.isEmpty(pSmallGroupName) ? '' : ' [' + pSmallGroupName + '] ';
    let label = _.isEmpty(pLabel) ? '' : ' [' + pLabel + '] ';
    let text = compiled({
      'bigGroupIndex': pBigGroupIndex,
      'bigGroupName': bigGroupName,
      'smallGroupIndex': pSmallGroupIndex,
      'smallGroupName': smallGroupName,
      'componentIndex': pComponentIndex,
      'label': label
    });
    return text;
  }

  checkInputComponent(pOneComponent, pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex) {
    let result = true;
    const componentsType = _.get(pOneComponent, 'type', '');
    if (componentsType == 'input') {
      let isRequire = _.get(pOneComponent, 'require', false);
      if (isRequire) {
        const value = _.get(pOneComponent, 'model', null);
        if (_.isEmpty(value)) {
          const label = _.get(pOneComponent, 'label', null);
          const msgText = this.buildRequireMsg(pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex, label);
          this.confirmModal.show('error', {title: '请注意必填项', content: msgText});
          result = false;
          return result;
        }
      }
    }
    return result;
  }

  checkRadiosComponent(pOneComponent, pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex) {
    let result = true;
    const componentsType = _.get(pOneComponent, 'type', '');
    if (componentsType == 'radios') {
      let isRequire = _.get(pOneComponent, 'require', false);
      if (isRequire) {
        const value = _.get(pOneComponent, 'model', null);
        if (_.isEmpty(value)) {
          const label = _.get(pOneComponent, 'label', null);
          const msgText = this.buildRequireMsg(pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex, label);
          this.confirmModal.show('error', {title: '请注意必填项', content: msgText});
          result = false;
          return result;
        }
      }
    }
    return result;
  }

  checkTableComponent(pOneComponent, pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex) {
    let result = true;
    const componentsType = _.get(pOneComponent, 'type', '');
    if (componentsType == 'table') {
      let isRequire = _.get(pOneComponent, 'require', false);
      if (isRequire) {
        let list = _.get(pOneComponent, 'list', false);
        _.each(list, oneList=> {
          const column = _.get(oneList,['label']);
          let models = _.get(pOneComponent,['model']);
          _.each(models, oneModel=>{
            let value = _.get(oneModel,column);
            if (result && _.isEmpty(value)) {
              const label = _.get(pOneComponent, 'label', null);
              const msgText = this.buildRequireMsg(pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex, label);
              this.confirmModal.show('error', {title: '请注意必填项', content: msgText});
              result = false;
              return result;
            }
          })
        })
      }
    }
    return result;
  }

  checkCheckboxComponent(pOneComponent, pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex) {
    let result = true;
    const componentsType = _.get(pOneComponent, 'type', '');
    if (componentsType == 'checkboxs') {
      let isRequire = _.get(pOneComponent, 'require', false);
      if (isRequire) {
        let list = _.get(pOneComponent, ['list']);
        let valuesArr = []
        _.each(list, oneList => {
          const model = _.get(oneList, ['model']);
          valuesArr.push(model)
        });
        valuesArr = _.compact(valuesArr);
        if (_.isEmpty(valuesArr)) {
          const label = _.get(pOneComponent, 'label', null);
          const msgText = this.buildRequireMsg(pBigGroupIndex, pBigGroupName, pSmallGroupIndex, pSmallGroupName, pComponentIndex, label);
          this.confirmModal.show('error', {title: '请注意必填项', content: msgText});
          result = false;
          return result;
        }
      }
    }
    return result;
  }

  checkCommitDatas() {
    let result = true;
    let bigGroupIndex = 1;
    let bigGroupName = "";
    _.each(this.serviceData, oneGroup => {
      bigGroupName = _.get(oneGroup, 'group_name', '');
      let smallGroupIndex = 1;
      let smallGroupName = "";
      _.each(_.get(oneGroup, 'small_groups', []), oneSmallGroup => {
        smallGroupName = _.get(oneSmallGroup, 'small_group_name', '');
        let componentIndex = 1;
        _.each(_.get(oneSmallGroup, 'components', []), oneComponent => {
          if (result)
            result = this.checkInputComponent(oneComponent, bigGroupIndex, bigGroupName, smallGroupIndex, smallGroupName, componentIndex);
          if (result)
            result = this.checkCheckboxComponent(oneComponent, bigGroupIndex, bigGroupName, smallGroupIndex, smallGroupName, componentIndex);
          if (result)
            result = this.checkRadiosComponent(oneComponent, bigGroupIndex, bigGroupName, smallGroupIndex, smallGroupName, componentIndex);
          if (result)
            result = this.checkTableComponent(oneComponent, bigGroupIndex, bigGroupName, smallGroupIndex, smallGroupName, componentIndex);

          componentIndex++;
        })
        smallGroupIndex++;
      })
      bigGroupIndex++;
    })
    return result;
  }

  ngOnInit() {
    if (this.parentData['modeType'] === 'modify') {
      this.serviceId = this.parentData['id'];
      const search = observableForkJoin(
        this.requestService.getDataByCondition('genecreate/es/ge_service_list_detail.json', {'ID': this.serviceId}),
      );
      search.subscribe(
        values => {
          let RECORDS = _.get(values, [0, 'data']);
          if (this.util.Base_HasValue(RECORDS)) {
            let SERVICE_JSON = _.get(RECORDS, [0, 'SERVICE_JSON']);
            if (this.util.Base_HasValue(SERVICE_JSON)) {
              this.serviceData = JSON.parse(SERVICE_JSON);
            }
            this.backfill(RECORDS, 'SERVICE_NAME');
            this.backfill(RECORDS, 'CATEGORIES_ID');
            this.backfill(RECORDS, 'PROCESS_ID');
            this.backfill(RECORDS, 'SERVICE_NAME');
            this.backfill(RECORDS, 'ORG_ID');
            this.backfill(RECORDS, 'ENTER_PRODUCTION');
            this.backfill(RECORDS, 'HOME_PAGE_FLAG');
            this.backfill(RECORDS, 'SERVICE_PIC01');
            this.backfill(RECORDS, 'SERVICE_PIC02');
            this.backfill(RECORDS, 'SERVICE_FILE01');
            this.backfill(RECORDS, 'SERVICE_FILE02');
            this.backfill(RECORDS, 'SERVICE_NOTE');
            this.backfill(RECORDS, 'SERVICE_CONTENT');
            this.backfill(RECORDS, 'DELIVERY_CONTENT');
            this.backfill(RECORDS, 'SERVICE_CYCLE');
          }
        }
      );
    }
  }

  public formData: any = {
    'type': 'normal',
    'columns': [
      {
        'column': 'SERVICE_NAME',
        'name': 'SERVICE_NAME_NAME',
        'component': 'input',
        'lable': '服务名称',
        'comment': '服务名称',
        'placeholder': '请输入服务名称',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'model': null,
        'visible': true,
        'require': true
      },
      {
        'column': 'ORG_ID',
        'name': 'ORG_ID_NAME',
        'component': 'cascader',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'lable': '销售部门',
        'comment': '销售部门',
        'placeholder': '销售部门',
        'model': null,
        'visible': true,
        'require': true,
        'columnGroup': [
          {
            'column': 'ORG_LV01',
            'name': 'ORG_LV01_NAME',
            'component': 'input',
            'lable': '销售部门',
            'comment': '销售部门',
            'placeholder': '请输入销售部门',
            'fromValue': 'name',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_ORG_LV01_TECH.json',
            'dictionaryPF': null,
            'visible': false,
            'require': false
          },
          {
            'column': 'ORG_LV02',
            'name': 'ORG_LV02_NAME',
            'component': 'input',
            'lable': '销售部门',
            'comment': '销售部门',
            'placeholder': '请输入销售部门',
            'fromValue': 'name',
            'dictionaryConditionColumn': 'UP_ORG_ID',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_ORG_LV02.json',
            'dictionaryPF': null,
            'visible': false,
            'require': false
          },
          {
            'column': 'ORG_LV03',
            'name': 'ORG_LV03_NAME',
            'component': 'input',
            'lable': '销售部门',
            'comment': '销售部门',
            'placeholder': '请输入销售部门',
            'fromValue': 'name',
            'dictionaryConditionColumn': 'UP_ORG_ID',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_ORG_LV03.json',
            'dictionaryPF': null,
            'visible': false,
            'require': false
          }
        ]
      },
      {
        'column': 'CATEGORIES_ID',
        'name': 'CATEGORIES_ID_NAME',
        'component': 'cascader',
        'lable': '服务分类',
        'comment': '服务分类',
        'placeholder': '请选择服务分类',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'model': null,
        'visible': true,
        'require': true,
        'columnGroup': [
          {
            'column': 'CATEGORIES_LV01',
            'name': 'CATEGORIES_LV01_NAME',
            'component': 'input',
            'lable': '一级分类',
            'comment': '一级分类',
            'placeholder': '请输入一级分类',
            'fromValue': 'name',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_CATEGORIES_CASCADER_LV1.json',
            'dictionaryPF': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'CATEGORIES_LV02',
            'name': 'CATEGORIES_LV02_NAME',
            'component': 'input',
            'lable': '二级分类',
            'comment': '二级分类',
            'placeholder': '请输入二级分类',
            'fromValue': 'name',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_CATEGORIES_CASCADER_LV2.json',
            'dictionaryPF': null,
            'dictionaryConditionColumn': 'UP_ID',
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'column': 'CATEGORIES_LV03',
            'name': 'CATEGORIES_LV03_NAME',
            'component': 'input',
            'lable': '三级分类',
            'comment': '三级分类',
            'placeholder': '请输入三级分类',
            'fromValue': 'name',
            'model': null,
            'dictionary': 'genecreate/basic/dict/ES_CATEGORIES_CASCADER_LV3.json',
            'dictionaryPF': null,
            'dictionaryConditionColumn': 'UP_ID',
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          }
        ]
      },
      {
        'column': 'PROCESS_ID',
        'name': 'PROCESS_ID_NAME',
        'component': 'select',
        'lable': '生产工序',
        'comment': '生产工序',
        'placeholder': '请选择生产工序',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'model': null,
        'visible': true,
        'require': true,
        'dictionary': 'genecreate/basic/dict/GE_PROCESS_POPLIST.json',
        'dictionaryPF': null
      },
      {
        'column': 'ENTER_PRODUCTION',
        'name': 'ENTER_PRODUCTION_NAME',
        'component': 'select',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'lable': '无需分配',
        'comment': '无需分配',
        'placeholder': '无需分配',
        'dictionary': 'genecreate/basic/dict/YES_OR_NO.json',
        'dictionaryPF': null,
        'model': 0,
        'visible': true,
        'require': true
      },
      {
        'column': 'HOME_PAGE_FLAG',
        'name': 'HOME_PAGE_FLAG_NAME',
        'component': 'select',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'lable': '是否上主页',
        'comment': '是否上主页',
        'placeholder': '是否上主页',
        'dictionary': 'genecreate/basic/dict/YES_OR_NO.json',
        'dictionaryPF': null,
        'model': 0,
        'visible': true,
        'require': true
      },
      {
        'column': 'SERVICE_CONTENT',
        'name': 'SERVICE_CONTENT_NAME',
        'component': 'input',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'lable': '服务内容',
        'comment': '服务内容',
        'placeholder': '服务内容（注意控制内容字数）',
        'dictionaryPF': null,
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'DELIVERY_CONTENT',
        'name': 'DELIVERY_CONTENT_NAME',
        'component': 'input',
        'lableSpan': 4,
        'itemSpan': 18,
        'customize': 12,
        'lable': '交付内容',
        'comment': '交付内容',
        'placeholder': '交付内容',
        'dictionaryPF': null,
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'SERVICE_CYCLE',
        'name': 'SERVICE_CYCLE_NAME',
        'component': 'input',
        'lableSpan': 4,
        'itemSpan': 18,
        'customize': 12,
        'lable': '服务周期',
        'comment': '服务周期',
        'placeholder': '服务周期',
        'dictionaryPF': null,
        'model': null,
        'visible': true,
        'require': false
      },
      {
        'column': 'SERVICE_PIC01',
        'name': 'SERVICE_PIC01_NAME',
        'component': 'picture',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 8,
        'lable': '服务产品图片',
        'comment': '服务产品图片',
        'placeholder': '服务产品图片',
        'model': null,
        'disable': true,
        'visible': true,
        'require': true
      },
      {
        'column': 'SERVICE_PIC02',
        'name': 'SERVICE_PIC02_NAME',
        'component': 'picture',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 8,
        'lable': '服务产品图片-选中',
        'comment': '服务产品图片-选中',
        'placeholder': '服务产品图片-选中',
        'model': null,
        'disable': true,
        'visible': true,
        'require': true
      },
      {
        'column': 'SERVICE_FILE01',
        'name': 'SERVICE_FILE01_NAME',
        'component': 'file',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 8,
        'lable': '文档下载01',
        'comment': '文档下载01',
        'placeholder': '文档下载01',
        'model': null,
        'disable': true,
        'visible': true,
        'require': true
      },
      {
        'column': 'SERVICE_FILE02',
        'name': 'SERVICE_FILE02_NAME',
        'component': 'file',
        'lableSpan': 6,
        'itemSpan': 18,
        'customize': 8,
        'lable': '文档下载02',
        'comment': '文档下载02',
        'placeholder': '文档下载02',
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      },
      {
        'column': 'SERVICE_NOTE',
        'name': 'SERVICE_NOTE_NAME',
        'component': 'tinymce',
        'lableSpan': 2,
        'itemSpan': 22,
        'customize': 24,
        'lable': '注意事项',
        'comment': '注意事项',
        'placeholder': '注意事项',
        'model': null,
        'disable': true,
        'visible': true,
        'require': false
      }
    ]
  };

  public serviceData = [
    {
      'group_name': '1、细胞信息及服务类型：',
      'visible': true,
      'small_groups': [
        {
          'small_group_name': '物种信息',
          'components': [
            {
              'type': 'input',
              'label': '物种：',
              'model': null
            }
          ]
        },
        {
          'small_group_name': '样品信息',
          'components': [
            {
              'type': 'checkboxs',
              'label': null,
              'list': [
                {
                  'label': '细胞类',
                  'type': 'normal',
                  'nzSpan': 24,
                  'model': null
                },
                {
                  'label': '组织样（动植物组织/血清/全血/菌体）',
                  'type': 'normal',
                  'nzSpan': 24,
                  'model': null
                },
                {
                  'label': '粪便肠道内容物',
                  'type': 'normal',
                  'nzSpan': 24,
                  'model': null
                },
                {
                  'label': '尿液',
                  'type': 'normal',
                  'nzSpan': 24,
                  'model': null
                },
                {
                  'label': '其他',
                  'type': 'normal',
                  'nzSpan': 24,
                  'model': null
                }
              ]
            }
          ]
        },
        {
          'small_group_name': '生物学重复',
          'components': [
            {
              'type': 'describe',
              'model': '一般建议大于等于6重'
            }
          ]
        },
        {
          'small_group_name': '有无传染性',
          'components': [
            {
              'type': 'radios',
              'label': null,
              'model': null,
              'list': [
                {
                  'label': '有',
                  'type': 'normal',
                  'nzSpan': 8,
                  'inputModel': null
                },
                {
                  'label': '无',
                  'type': 'normal',
                  'nzSpan': 8,
                  'inputModel': null
                },
                {
                  'label': '其他',
                  'type': 'input',
                  'nzSpan': 8,
                  'inputModel': null
                }
              ]
            }
          ]
        },
        {
          'small_group_name': '检测及分析',
          'components': [
            {
              'type': 'checkboxs',
              'label': '检测平台：',
              'list': [
                {
                  'label': 'GC-MS',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': 'LC-MS',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '其他',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                }
              ]
            },
            {
              'type': 'checkboxs',
              'label': '靶向类型：',
              'list': [
                {
                  'label': '靶向代谢组学',
                  'type': 'input',
                  'nzSpan': 16,
                  'model': null,
                  'inputModel': null
                },
                {
                  'label': '非靶向代谢组学',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '靶向检测物质',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '重点关注检测物质',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '是否提供标品',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '代谢通路或生物学问题',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '建立标准曲线',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '原始数据预处理',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '基础对比分析PCA/OPLS-DA',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '检测物定量分析',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '性化分析-通路',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '个性化分析-聚类',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '性化分析-通路',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '个性化分析-ROC',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                },
                {
                  'label': '高级数据分析另算',
                  'type': 'normal',
                  'nzSpan': 8,
                  'model': null
                }
              ]
            },
            {
              'type': 'table',
              'label': '表格：',
              'model': [
                {
                  '第一列': 'a11',
                  '第二列': 'b11'
                }
              ],
              'list': [
                {
                  'label': '第一列',
                },
                {
                  'label': '第二列',
                }
              ]
            }

          ]
        }
      ]
    }
  ];

}
