import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import * as _sh from "lodash";
import {forkJoin as observableForkJoin} from "rxjs";

@Component({
  selector: 'app-hulk-config-poplist',
  templateUrl: './hulk-config-poplist.component.html',
  styleUrls: ['./hulk-config-poplist.component.scss']
})
export class HulkConfigPoplistComponent implements OnInit {


  @Input() hulkColumn: any;
  @Input() label: any;
  @Input() canEdit: Boolean = true;

  @ViewChild('childForm', {static: false}) childForm;

  conditions = {};
  // 查询对象
  public conditionColumns: any = {
    columns: []
  };
  // 当前table的表头
  public showColumns: any = [];

  // 绑定当前table的数组数据
  public dataSet: any = [];
  // 查询数据
  public conditionList: any[] = [];

  constructor(
    private _httpClient: HttpClient,
    private modalService: NzModalService,
    private _FileSaverService: FileSaverService,
    private requestDataService: RequestDataService,
    private appConfigService: AppConfigService,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private confirmModal: ConfirmModalService,
    private clipboardService: ClipboardService,
    private utils: UtilsService
  ) {
  }

  condition = {
    project: null,
    comment: null,
    tables: null,
    sqlFileName: null,
    type: null
  }

  baseData = [];
  showData = [];
  allChecked = false;
  indeterminate = false;


// 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = 5;
  // 总条数
  public total: any = 1;
  public nzPageSizeOptions: any = [5, 10, 20, 30, 50, 100, 200];

  projectData = [
    {
      "code": "assets",
      "name": "东风天成资产",
    },
    {
      "code": "wordConfig",
      "name": "数据源核心",
    },
    {
      "code": "workflow",
      "name": "工作流核心",
    }
  ]

  fileTypeData = [
    {
      "code": "normal",
      "name": "列表，详情"
    },
    {
      "code": "dictionary",
      "name": "字典"
    },
    {
      "code": "tree",
      "name": "树形"
    }
  ]
  public isVisible = false;


  doFilter(temp) {
    let that = this;
    let condition = _sh.cloneDeep(this.condition);
    let result = _sh.cloneDeep(temp);
    _sh.each(_sh.keys(this.condition), oneKey => {
      if (that.utils.Base_HasValue(condition[oneKey])) {
        if (_.contains(['project', 'type'], oneKey)) {
          result = _sh.remove(result, function (oneData) {
            return oneData[oneKey] == condition[oneKey];
          });
        } else if (_.contains(['comment', 'tables', 'sqlFileName'], oneKey)) {
          result = _sh.remove(result, function (oneData) {
            let value = oneData[oneKey];
            let conditionStr = condition[oneKey];
            if (that.utils.Base_HasValue(value)) {
              let findIndex = value.indexOf(conditionStr);
              return findIndex != -1;
            } else {
              return false;
            }

          });
        }

      }
    })
    return result;
  }

  doSearch() {
    let that = this;

    const parallel$ = observableForkJoin(
      this.requestDataService.getPaginationData(this.hulkColumn.dictionary, this.conditions, this.pageIndex, this.pageSize),
      this.requestDataService.getConditionData(this.hulkColumn.dictionary)
    );
    parallel$.subscribe(
      values => {
        const dataObject = values[0];
        let itemData = dataObject['data'] || [];
        if (this.utils.Base_HasValue(itemData)) {
          if (this.utils.Base_HasValue(this.hulkColumn.itemArray)) {
            _.each(this.hulkColumn.itemArray, (item) => {
              const oneData = _.find(itemData, (value) => {
                return item['code'] === value['code'];
              });
              if (this.utils.Base_HasValue(oneData)) {
                itemData = _.without(itemData, oneData);
              }
            });
            this.hulkColumn.itemArray = this.hulkColumn.itemArray.concat(itemData);
          } else {
            this.hulkColumn.itemArray = itemData;
          }
        }
        this.conditionColumns = values[1]['conditionColumns'][0] || {};
        this.conditionColumns.needInit = true;
        this.transformResult(dataObject);
        this.isVisible = true;
      }
    );

  }

  // 查询后的数据进行封装
  isMulti: any;
  transformResult(data) {
    this.dataSet = data.data || [];
    this.showColumns = data.showColumns.columns || [];
    this.total = data.page.rowCount;
    this.pageIndex = data.page.pageNum;
  }

  ngOnInit() {
  }

  doRefresh() {
    let that = this;
    this.confirmModal.show('confirm', {
      title: '是否需要刷新，所有的数据将不会保存。',
      suc: () => {
        this.doSearch();
      }
    });
  }

  checkAll(value: boolean): void {
    this.showData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.showData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  getPath(pValue) {
    let findIndex = _sh.findIndex(_sh.split(pValue, "/"), function (o) {
      return o == 'sqlFiles';
    });
    let result = _sh.chain(pValue)
      .split("/")
      .slice(findIndex + 1, 100)
      .join("/")
      .value();
    return result;
  }

  getProject(pValue) {
    let findIndex = _sh.findIndex(_sh.split(pValue, "/"), function (o) {
      return o == 'sqlFiles';
    });
    let result = _sh.chain(pValue)
      .split("/")
      .slice(findIndex + 1, findIndex + 2)
      .join("/")
      .value()
    return result;
  }

  getFileName(pValue) {
    let result = _sh.chain(pValue)
      .split("/")
      .takeRight(1)
      .join("")
      .value()
    return result;
  }

  // 合并查询条件
  transformCondition(columns) {
    const condition = [];
    _.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        const obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_.contains(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_.contains(['multiSelect', 'multipopCondition'], item['component'])) {
          const nameList = [];
          _.each(item['itemArray'], (v) => {
            if (_.contains(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  searchFilter(reset: boolean = true) {
    this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
    let condition = {};
    _.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
        _.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
    condition = _.assign(condition, intputCondition);
    condition = _.assign(condition, this.conditions);
    this.searchData(reset, condition);
  }

  deleteCondition() {
    let keys = _sh.keys(this.condition);
    _.each(keys, oneKey => {
      // @ts-ignore
      this.condition[oneKey] = null;
    })
    this.doSearch();
  }

  removeValue(type: string) {
    this.condition[type] = null;
  }

  removeValueMain() {
    // @ts-ignore
    this.hulkColumn[this.attributes] = null;
  }

  showModal() {
    this.isVisible = true;
    this.doSearch();
  }

  selectData(data: any) {
    // @ts-ignore
    this.hulkColumn[this.attributes] = data['sqlFileName'];
    this.isVisible = false;
  }

  resetFilter() {

  }

  searchData(reset: boolean = false, condition?: {}) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.requestDataService.getPaginationData(this.hulkColumn.dictionary, condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
      let itemData = data['data'] || [];
      if (this.utils.Base_HasValue(itemData)) {
        if (this.utils.Base_HasValue(this.hulkColumn.itemArray)) {
          _.each(this.hulkColumn.itemArray, (item) => {
            const oneData = _.find(itemData, (value) => {
              return item['code'] === value['code'];
            });
            if (this.utils.Base_HasValue(oneData)) {
              itemData = _.without(itemData, oneData);
            }
          });
          this.hulkColumn.itemArray = this.hulkColumn.itemArray.concat(itemData);
        } else {
          this.hulkColumn.itemArray = itemData;
        }
      }
      this.transformResult(data);
    });
  }
}
