import {Injectable} from '@angular/core';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd';
import {UtilsService} from '../../service/utils.service';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {UserInfoService} from '../../service/user-info.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {RequestDataService} from '../../service/request-data.service';
import {DataCenterService} from '../../service/data-center.service';
import {AppConfigService} from '../../service/app-config.service';
import {DataService} from './data.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import * as _ from 'underscore';
import * as $ from 'jquery';
import * as moment from 'moment';
import {EsRebuildComponent} from '../headLine/es-rebuild/es-rebuild.component';

@Injectable({
  providedIn: 'root'
})
export class FuryService {
  public user: any;

  constructor(private confirmModal: ConfirmModalService,
              private util: UtilsService,
              private modalService: NzModalService,
              private userInfo: UserInfoService,
              private autoInsert: AutoInsertService,
              private requestDataService: RequestDataService,
              private dataCenterService: DataCenterService,
              private dataService: DataService,
              private config: AppConfigService,
              private router: Router) {
    this.user = userInfo.getUserInfo();
  }

  doAction(pBtn, pParentData) {
    let flag = true;
    let ids = [];
    switch (pBtn.ACTION_VALUE) {
      case 'saleVisitPlan': // 生成销售计划
        this.saleVisitPlan(pParentData);
        break;
      case 'agreeTrial': // 同意试用
        this.agreeTrial(pParentData);
        break;
      case 'deliver': // 交付
        this.deliver(pParentData);
        break;
      case 'spreadNet': // 挂网
        this.speadNet(pParentData);
        break;
      case 'planCancel': // 客户计划管理取消
        this.planCancel(pParentData);
        break;
      case 'distributionSales': // 客户计划管理分配销售
        this.distributionSales(pParentData);
        break;
      case 'editPlan':
        if (this.selectOneData(pParentData.data.data) != 1) {
          return false;
        }
        pParentData['checkedData'] = _.find(pParentData.data.data, item => {
          return item['checked'] === true;
        });
        this.mackConditon(pParentData);
        this.transformData('customForm', pParentData, 'modify', 'custplanModify');
        break;
    }
  }

  // 客户计划管理分配销售
  distributionSales(pParentData) {
    let nextStep = true;
    if (this.selectMore(pParentData.data.data) === 0) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      if (item['PLAN_STATE'] !== 'PENDING' && item['checked']) {
        nextStep = false;
      }
      return item['checked'];
    });
    if (!nextStep) {
      return this.confirmModal.show('error', {'title': '状态为待分配才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '分配销售人员',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_USER.json',
              'dictionaryPF': null
            },
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require'] === true) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        this.dataService.distributionSales(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 客户计划管理取消
  planCancel(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'];
    });
    if (selectList.length === 0) {
      return this.confirmModal.show('error', {title: '状态为已交付才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '取消',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PLAN_CANCEL_DESC',
              'name': 'PLAN_CANCEL_DESC_NAME',
              'component': 'textarea',
              'customize': 24,
              'lable': '取消原因',
              'comment': '取消原因',
              'placeholder': '请输入取消原因',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model'])) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '以上信息为必填。'});
          return false;
        }
        this.dataService.planCancel(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 挂网
  speadNet(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'] && item['PLAN_STATE'] === 'DELIVER';
    });
    if (selectList.length === 0) {
      return this.confirmModal.show('error', {title: '状态为已交付才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '挂网',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'SPREAD_NET_DATE',
              'name': 'SPREAD_NET_DATE_NAME',
              'component': 'date',
              'customize': 24,
              'lable': '挂网日期',
              'comment': '挂网日期',
              'placeholder': '请选择挂网日期',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'SPREADNET_URL',
              'name': 'SPREADNET_URL_NAME',
              'component': 'textarea',
              'customize': 24,
              'lable': '挂网地址',
              'comment': '挂网地址',
              'placeholder': '请输入地址',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_USER.json',
              'dictionaryPF': null
            },
            {
              'column': 'SPREADNET_CONFIRM_ID',
              'name': 'SPREADNET_CONFIRM_ID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '挂网确认人',
              'comment': '挂网确认人',
              'placeholder': '请选择挂网确认人',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_USER.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model'])) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '以上信息为必填。'});
          return false;
        }
        this.dataService.speadNet(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 交付
  deliver(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'] && item['PLAN_STATE'] === 'AGREE_TRIAL';
    });
    if (selectList.length === 0) {
      return this.confirmModal.show('error', {title: '状态为同意试用才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '交付',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'DELIVER_DATE',
              'name': 'DELIVER_DATE_NAME',
              'component': 'date',
              'customize': 24,
              'lable': '交付日期',
              'comment': '交付日期',
              'placeholder': '请选择交付日期',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'DELIVER_CONFIRM_ID',
              'name': 'DELIVER_CONFIRM_ID_NAME',
              'component': 'poplist',
              'customize': 24,
              'lable': '交付确认人',
              'comment': '交付确认人',
              'placeholder': '请选择交付确认人',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/BASIC_USER.json',
              'dictionaryPF': null
            }
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model'])) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '以上信息为必填。'});
          return false;
        }
        this.dataService.deliver(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 同意试用
  agreeTrial(pParentData) {
    if (this.selectOneData(pParentData.data.data) !== 1) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      return item['checked'] && item['PLAN_STATE'] === 'ASSIGNED';
    });
    if (selectList.length === 0) {
      return this.confirmModal.show('error', {title: '状态为已分配才可以执行此操作。'});
    }
    this.modalService.create({
      nzTitle: '同意试用',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'TRIAL_AGREE_NAME',
              'name': 'TRIAL_AGREE_NAME_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '同意试用人',
              'comment': '同意试用人',
              'placeholder': '请输入同意试用人姓名',
              'model': null,
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_POST',
              'name': 'TRIAL_POST_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '职务',
              'comment': '职务',
              'placeholder': '请输入职务',
              'model': null,
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_PHONE',
              'name': 'TRIAL_PHONE_NAME',
              'component': 'input',
              'customize': 24,
              'lable': '联系方式',
              'comment': '联系方式',
              'placeholder': '请输入联系方式',
              'model': null,
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_DATE',
              'name': 'TRIAL_DATE_NAME',
              'component': 'date',
              'customize': 24,
              'lable': '同意时间',
              'comment': '同意时间',
              'placeholder': '请选择同意时间',
              'model': null,
              'visible': true,
              'require': false
            },
            {
              'column': 'TRIAL_DESC',
              'name': 'TRIAL_DESC_NAME',
              'component': 'textarea',
              'customize': 24,
              'lable': '备注',
              'comment': '备注',
              'placeholder': '请输入备注',
              'model': null,
              'visible': true,
              'require': false
            },
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        this.dataService.agreeTrial(selectList, data).subscribe(result => {
          if (result['state'] === 'success') {
            this.confirmModal.show('success', {
              title: '操作成功',
              suc: () => {
                pParentData.needSearch = true;
              }
            });
          } else {
            this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
            return false;
          }
        });
      }
    });
  }

  // 生成产品拜访计划
  saleVisitPlan(pParentData) {
    let user = this.userInfo.getUserInfo();
    let custList = [];
    let cust: any = {};
    if (this.selectMore(pParentData.data.data) === 0) {
      return;
    }
    _.each(pParentData.data.data, (item) => {
      if (item['checked']) {
        cust = {
          CUST_ID: item['ID'],
          CUST_CODE: item['CUST_CODE'],
          CUST_NAME: item['CUST_NAME']
        };
        custList.push(cust);
      }
    });
    this.modalService.create({
      nzTitle: '生成拜访计划',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PRODUCT',
              'name': 'PRODUCT_NAME',
              'component': 'multiSelect',
              'customize': 24,
              'lable': '产品名称',
              'comment': '产品名称',
              'placeholder': '请输入产品名称',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/PRODUCT_NAME.json',
              'dictionaryPF': null
            },
            {
              'column': 'VISIT_DATE_PLAN',
              'name': 'VISIT_DATE_PLAN_NAME',
              'customize': 24,
              'component': 'dateWeek',
              'lable': '拜访时间',
              'comment': '拜访时间',
              'placeholder': '请选择拜访时间',
              'model': null,
              'visible': true,
              'require': true
            },
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'component': 'select',
              'customize': 24,
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': user.USER_ID,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            },
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require'] === true) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let seller = _.find(data[2].itemArray, item => {return item['code'] === data[2]['model']});
        if(!this.util.Base_HasValue(seller)){
          return false;
        }
        let condition = {
          PF: this.config.config['MysqlPF'],
          sellerId: data[2]['model'],
          sellerName: seller['name'],
          productList: JSON.stringify(data[0]['model']),
          custList: JSON.stringify(custList),
          year: moment(moment().isoWeek(data[1]['model'])).format('YYYY'),
          month: moment(moment().isoWeek(data[1]['model'])).format('MM'),
          week: data[1]['model'] + ''
        };
        this.dataService.furyProductVisitAdd(condition).subscribe(response => {
          if (response.state === 'success') {
            this.confirmModal.show('success', {title: '操作成功！'});
          }
        });
      }
    });
  };

  // 生成销售计划
  salePlan(pParentData) {
    let user = this.userInfo.getUserInfo();
    let ids = [];
    if (this.selectMore(pParentData.data.data) === 0) {
      return;
    }
    let selectList = _.filter(pParentData.data.data, (item) => {
      if (item['checked']) {
        ids.push(item['ID']);
      }
      return item['checked'];
    });
    this.modalService.create({
      nzTitle: '生成销售计划',
      nzContent: FormModalComponent,
      nzWidth: 400,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PRODUCT',
              'name': 'PRODUCT_NAME',
              'component': 'multiSelect',
              'customize': 24,
              'lable': '产品名称',
              'comment': '产品名称',
              'placeholder': '请输入产品名称',
              'model': null,
              'visible': true,
              'require': true,
              'dictionary': 'fury/basic/dict/PRODUCT_NAME.json',
              'dictionaryPF': null
            },
            {
              'column': 'PLAN_PERSON_CHARGEID',
              'name': 'PLAN_PERSON_CHARGEID_NAME',
              'customize': 24,
              'component': 'select',
              'lable': '销售人员',
              'comment': '销售人员',
              'placeholder': '请选择销售人员',
              'model': user.USER_ID,
              'visible': true,
              'require': false,
              'dictionary': 'fury/basic/dict/SALES_USER.json',
              'dictionaryPF': null
            },
          ]
        }
      },
      nzOnOk: (response) => {
        let data = response.modalFormData.columns;
        let ok = true;
        _.each(data, oneData => {
          if (!this.util.Base_HasValue(oneData['model']) && oneData['require'] === true) {
            ok = false;
          }
        });
        if (!ok) {
          this.confirmModal.show('error', {title: '请填写必填信息。'});
          return false;
        }
        let condition = {
          CUST_ID: ids,
          PRODUCT: data[0]['model']
        };
        this.requestDataService.getDataByCondition('fury/cust/cust_plant_list.json', condition).subscribe(response => {
          this.dataService.salePlan(selectList, data, response.data).subscribe(result => {
            if (result['state'] === 'success') {
              this.confirmModal.show('success', {
                title: '操作成功',
                suc: () => {
                  pParentData.needSearch = true;
                }
              });
            } else {
              this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
              return false;
            }
          });
        });
      }
    });
  }

  mackConditon(pParentData): void {
    function setDetailConditons(pData) {
      function isCondtion(OneColumn) {
        let bFind = false;
        if (_.has(OneColumn, 'iscondition')) {
          bFind = OneColumn.iscondition;
        }
        return bFind;
      }

      let conData;
      _.each(pData, function (oneData) {
        if (oneData['checked']) {
          conData = oneData;
        }
      });
      let tmp = {};
      _.each(pParentData.data.showColumns['columns'], function (OneColumn) {
        //只将 iscondition 为 true 的作为条件传入详情页面。
        if (isCondtion(OneColumn)) {
          tmp[OneColumn['conditionColumn']] = conData[OneColumn['column']];
        }
      });
      return tmp;
    }

    pParentData.detailCondition = setDetailConditons(pParentData.data.data);
  }

  transformData(pFormType, parentData, pModeType?, btnName?, custormFormType?) {
    // 重新覆盖 datacenter 数据
    // parentData.data = {};
    parentData.conditionColumns = {};
    parentData.formType = pFormType;
    parentData.modeType = pModeType;
    parentData.btnName = btnName;
    parentData.custormFormType = custormFormType;
    parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === parentData.id) {
        item = _.extend(item, parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doDetailAction(pBtn, parentData, mainForm) {
    switch (pBtn.ACTION_VALUE) {

    }
  }

  selectOneData(pListData): number {
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount == 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    } else if (iCount > 1) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请只选择一条纪录！'
      });
      console.error('this action must select only one record ');
      return iCount;
    }
    return iCount;
  }

  selectMore(pListData): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    if (iCount === 0) {
      this.confirmModal.show('warning', {
        'title': '温馨提示',
        'content': '请至少选择一条纪录！'
      });
      return iCount;
    }
    return iCount;
  }

}
