import {Component, OnInit} from '@angular/core';
import {RequestDataService} from "../../../service/request-data.service";
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../../../service/utils.service";
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-future-signal-list',
  templateUrl: './future-signal-list.component.html',
  styleUrls: ['./future-signal-list.component.css']
})
export class FutureSignalListComponent implements OnInit {

  public tick = {
    ask_price1:0,
    ask_volume1:0,
    bid_price1:0,
    bid_volume1:0,
    ask_price2:0,
    ask_volume2:0,
    bid_price2:0,
    bid_volume2:0,
    ask_price3:0,
    ask_volume3:0,
    bid_price3:0,
    bid_volume3:0,
    ask_price4:0,
    ask_volume4:0,
    bid_price4:0,
    bid_volume4:0,
    ask_price5:0,
    ask_volume5:0,
    bid_price5:0,
    bid_volume5:0,
    delta_price_1:0,
    delta_price_2:0,
    delta_price_3:0,
    delta_price_4:0,
    delta_price_5:0,
  }
  public roi = 0;

  public tickWorkFlow : [];

  upIsLoading = false;
  downIsLoading = false;
  shockIsLoading = false;
  cutIsLoading = false;
  getIsLoading = false;
  setIsLoading = false;
  cancelIsLoading = false;
  bigDownIsLoading = false;
  bigUpIsLoading = false;

  refreshing = true;
  freshIcon = 'loading';
  freshState_Zh = '刷新中';

  symbol = 'SHFE.ni2107';
  broker = 'simnow';
  state = 'init';
  state_zh = '初始化';
  profitLine = 10;
  lossLine = 30;
  big_profitLine = 50;
  big_lossLine = 100;
  small_profitLine = 10;
  small_lossLine = 30;

  initNzType = 'default';
  upNzType = 'default';
  downNzType = 'default';
  cutNzType = 'default';
  cancelNzType = 'default';
  bigUpNzType = 'default';
  bigDownNzType = 'default';

  constructor(private requestDataService: RequestDataService,
              private http: HttpClient,
              private utils: UtilsService) {
  }

  doChangeBtnTyep() {
    switch (this.state) {
      case 'init':
        this.initNzType = 'primary';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'up':
        this.initNzType = 'default';
        this.upNzType = 'primary';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'down':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'primary';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'shock':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'cut':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'primary';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'cancel':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'primary';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'default';
        break;
      case 'bigUp':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'primary';
        this.bigDownNzType = 'default';
        break;
      case 'bigDown':
        this.initNzType = 'default';
        this.upNzType = 'default';
        this.downNzType = 'default';
        this.cutNzType = 'default';
        this.cancelNzType = 'default';
        this.bigUpNzType = 'default';
        this.bigDownNzType = 'primary';
        break;
    }
  }

  doUp() {
    this.upIsLoading = true;
    this.state = 'up', this.state_zh = '小涨';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.small_profitLine,
      'lossLine': this.small_lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.upIsLoading = false;
    });
  }

  doDown() {
    this.downIsLoading = true;
    this.state = 'down', this.state_zh = '小跌';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.small_profitLine,
      'lossLine': this.small_lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.downIsLoading = false;
    });
  }

  doCut() {
    this.cutIsLoading = true;
    this.state = 'cut', this.state_zh = '砍单';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }



  doBigUp() {
    this.cutIsLoading = true;
    this.state = 'bigUp', this.state_zh = '大涨';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.big_profitLine,
      'lossLine': this.big_lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doBigDown() {
    this.cutIsLoading = true;
    this.state = 'bigDown', this.state_zh = '大跌';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.big_profitLine,
      'lossLine': this.big_lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doCancel() {
    this.cutIsLoading = true;
    this.state = 'cancel', this.state_zh = '撤单';
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.doChangeBtnTyep();
      this.cutIsLoading = false;
    });
  }

  doInit() {
    this.cutIsLoading = false;
    this.state = 'init', this.state_zh = '观望';
  }

  doSet() {
    this.setIsLoading = true;
    let obj = {
      'state': this.state,
      'state_zh': this.state_zh,
      'profitLine': this.profitLine,
      'lossLine': this.lossLine,
      'big_profitLine': this.big_profitLine,
      'big_lossLine': this.big_lossLine,
      'small_profitLine' : this.small_profitLine,
      'small_lossLine' : this.small_lossLine
    };
    let value = JSON.stringify(obj);
    this.requestDataService.redisSet(this.symbol + this.broker, value).subscribe(response => {
      this.setIsLoading = false;
    });
  }


  doGet() {
    this.getIsLoading = true;
    this.requestDataService.redisGet(this.symbol).subscribe(response => {
      response = this.utils.Base_replace(response,"'",'"');
      this.tick = JSON.parse(response);
      this.getIsLoading = false;
      this.doChangeBtnTyep();
    });
    this.requestDataService.redisGet(this.symbol+":workflow").subscribe(response => {
      this.tickWorkFlow = response;
      let sumBuy = 0;
      let sumSell = 0;
      _.each(this.tickWorkFlow, item=>{
        if (this.utils.Base_HasValue(item['tickDateTime'])) {
          item['tickDateTimeStr'] = moment.unix(item['tickDateTime'].toString().substring(0,10)).format('YYYY-MM-DD HH:mm:ss');
        }
        sumBuy += _.get(item,'buy',0);
        sumSell += _.get(item,'sell',0);
      })
      this.roi = (sumBuy - sumSell) / (sumBuy + sumSell);
      this.getIsLoading = false;
      this.doChangeBtnTyep();
    });
  }

  doRefresh() {
    this.refreshing = !this.refreshing;
    if (this.refreshing) {
      this.freshIcon = 'loading';
      this.freshState_Zh = '刷新中';
    } else {
      this.freshIcon = 'loading-3-quarters';
      this.freshState_Zh = '启动刷新';
    }
  }

  ngAfterViewInit() {
    setInterval(() => {
      if (this.refreshing)
        this.doGet(); // 每一秒，获取redis的数据一次。
    }, 500);
  }

  ngOnDestroy() {
    this.refreshing = false
  }

  ngOnInit() {
  }


}
