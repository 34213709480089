import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {AutoInsertService} from "../../service/auto-insert.service";
import {StorageService} from "../../service/storage.service";
import {UserInfoService} from "../../service/user-info.service";
import {UploadService} from "../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../service/data-center.service";
import {RequestDataService} from "../../service/request-data.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";


@Component({
    selector: 'app-workflow-management',
    templateUrl: './workflow-management.component.html',
    styleUrls: ['./workflow-management.component.css']
})
export class WorkflowManagementComponent implements OnInit {


    @Input() parentData: any;
    @Input() modeType: string = '';

    public workflowId = '';
    private completeWorkflowJsonId: string;
    completeWorkflowJson = {};

    tempJson = {
        updateJsonColumnList: [],
        processJsonColumnList: [],
        approveButtonTitle: "",
        execButtonTitle: "",
        documentId: null,
        updateTableName: null,
        fileName: null
    }

    constructor(
        private autoInsert: AutoInsertService,
        private storage: StorageService,
        private userInfo: UserInfoService,
        private uploadService: UploadService,
        private httpClient: HttpClient,
        private dataCenterService: DataCenterService,
        private userInfoService: UserInfoService,
        private autoService: AutoInsertService,
        private requestDataService: RequestDataService,
        private util: UtilsService,
        private config: AppConfigService,
        private hulkconfigserviceservice: HulkConfigServiceService,
        private confirmModal: ConfirmModalService
    ) {
    }


    BackfillData(datas) {
        _.each(_.get(this.formData, 'columns'), (item) => {
            const column = _.get(item, 'column');
            const value = _.get(datas, column);
            _.set(item, 'model', value);
            if (item['component'] == 'poplist') {
                item['modelName'] = datas[item['column'] + '_DICT'];
            }

            let columnGroup = _.get(item, 'columnGroup', null);
            _.each(columnGroup, (oneGroupColumn) => {
                const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
                const itemValue = _.get(datas, itemColumn);
                _.set(oneGroupColumn, 'model', itemValue);
                if (oneGroupColumn['component'] == 'poplist') {
                    oneGroupColumn['modelName'] = datas[oneGroupColumn['column'] + '_DICT'];
                }
            });
        });
    }

    formatMainToEntity() {
        let entity: any = {};
        _.each(_.get(this.formData, 'columns'), (item) => {
            const column = _.camelCase(_.get(item, 'column'));
            const value = _.get(item, 'model');
            entity[column] = value;
            let columnGroup = _.get(item, 'columnGroup', null);
            _.each(columnGroup, (oneGroupColumn) => {
                const itemColumn = _.camelCase(_.get(oneGroupColumn, 'column'));
                const itemValue = _.get(oneGroupColumn, 'model');
                entity[itemColumn] = itemValue;
            });
        });
        return entity;
    }

    // 返回
    gotoBack(): void {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    showData() {
    }

    checkInputMain() {
        let bHasValue = true;
        _.each(this.formData['columns'], oneColumn => {
            if (bHasValue && oneColumn['require']) {
                bHasValue = this.util.Base_HasValue(oneColumn['model']) || this.util.Base_HasValue(oneColumn['insertValue']);
                if (!bHasValue) {
                    this.confirmModal.show('error', {title: '请填写必填信息。', content: oneColumn['lable']});
                }
            }
        })
        return bHasValue;
    }

    saveData() {
        if (this.checkInputMain()) {

            const queryData = {
                'userId': this.userInfoService.getUserInfo().USER_ID,
            };
            let bodyData = this.formatMainToEntity();
            _.set(bodyData, ['wfJson'], JSON.stringify(this.workflowData));
            this.requestDataService.doActionByHdbsthor('AppApi/WorkflowController', 'saveWfConfig', queryData, bodyData, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                if (response['result']) {
                    this.workflowId = response['geService']['id'];
                    this.confirmModal.show('success', {'title': '执行成功'});
                } else {
                    this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
                }
            });

        }
    }

    workflowData = {
        "nodeList": []
    }

    findValue(pForm, pColumnName) {
        let bFind = false;
        let value = null;
        _.each(pForm['columns'], oneColumn => {
            if ((oneColumn['column'] == pColumnName) && (!bFind)) {
                value = oneColumn['model'];
                bFind = true;
            }
        });
        return value;
    }

    public formData: any = {
        'type': 'normal',
        'columns': [
            {
                "pk_column": true,
                "column": "ID",
                "name": "ID_NAME",
                "component": "input",
                "lable": "主键",
                "comment": "主键",
                "placeholder": "主键",
                "insertValue": "SYS-UUID",
                "model": null,
                "visible": false,
                "require": true,
                "iscondition": true
            },
            {
                'column': 'WF_CODE',
                'name': 'WF_CODE_NAME',
                'component': 'input',
                'lable': '工作流编码',
                'comment': '工作流编码',
                'placeholder': '请输入工作流编码',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                'model': null,
                'visible': true,
                'require': false,
                'disable': true
            },
            {
                'column': 'WF_NAME',
                'name': 'WF_NAME_NAME',
                'component': 'input',
                'lable': '工作流名称',
                'comment': '工作流名称',
                'placeholder': '请输入工作流名称',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'MENU_ID',
                'name': 'MENU_ID_NAME',
                'component': 'poplist',
                'lable': '选择菜单',
                'comment': '选择菜单',
                'placeholder': '请输入选择菜单',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": false,
                "dictionary": "assets/basic/dict/MENU_NAME.json",
                "dictionaryPF": null,
                "columnGroup": [
                    {
                        "column": "MENU_NAME",
                        "name": "MENU_NAME_NAME",
                        "component": "input",
                        "lable": "菜单名称",
                        "comment": "菜单名称",
                        "placeholder": "菜单名称",
                        "fromValue": "name",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                'column': 'WORD_PROCESS',
                'name': 'WORD_PROCESS_NAME',
                'component': 'select',
                'lable': '适配的流程',
                'comment': '适配的流程',
                'placeholder': '请输入适配的流程',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                "input": "WORD_PROCESS",
                "dictionary": "wordConfig/dict/WORD_PROCESS.json",
                "dictionaryPF": null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'WORD_CONFIG_ID',
                'name': 'WORD_CONFIG_ID_NAME',
                'component': 'select',
                'lable': '文档模板',
                'comment': '文档模板',
                'placeholder': '请输入文档模板',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                "input": "WORD_CONFIG_ID",
                "dictionary": "wordConfig/dict/word_config_poplist.json",
                "dictionaryPF": null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'WF_TITEL',
                'name': 'WF_TITEL_NAME',
                'component': 'input',
                'lable': '工作流标题',
                'comment': '工作流标题',
                'placeholder': '请输入工作流标题',
                'lableSpan': 4,
                'itemSpan': 18,
                'customize': 12,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'WF_TITEL_CONFIG',
                'name': 'WF_TITEL_CONFIG_NAME',
                "component": "cascader",
                'lable': '工作流标题设置',
                'comment': '工作流标题设置',
                'placeholder': '请输入工作流标题设置',
                'lableSpan': 2,
                'itemSpan': 21,
                'customize': 24,
                'model': null,
                'visible': true,
                'require': true,
                "columnGroup": [
                    {
                        "type": "get",
                        "column": "OPERATING_LV01",
                        "name": "OPERATING_LV01_NAME",
                        "component": "input",
                        "lable": "经营性资产类型一级",
                        "comment": "经营性资产类型一级",
                        "placeholder": "请输入经营性资产类型一级",
                        "fromValue": "name",
                        "model": null,
                        "dictionary": "assets/basic/dict/SYSTEM_DATASOURCE_LV1.json",
                        "dictionaryPF": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    },
                    {
                        "type": "get",
                        "column": "OP_STATE_LV02",
                        "name": "OP_STATE_LV02_NAME",
                        "component": "input",
                        "lable": "经营性资产类型二级",
                        "comment": "经营性资产类型二级",
                        "placeholder": "请输入经营性资产类型二级",
                        "fromValue": "name",
                        "model": null,
                        "lastLv": true,
                        "dictionary": "assets/basic/dict/SYSTEM_DATASOURCE_LV2.json",
                        "dictionaryPF": null,
                        "dictionaryConditionColumn": "UP_DS_ID",
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                'column': 'WF_NOTE',
                'name': 'WF_NOTE_NAME',
                'component': 'textarea',
                'lableSpan': 2,
                'itemSpan': 21,
                'customize': 24,
                'lable': '备注',
                'comment': '备注',
                'placeholder': '备注（注意控制内容字数）',
                'dictionaryPF': null,
                'model': null,
                'visible': true,
                'require': false
            }
        ]
    };

    private getJsonColumnList(pPath, pTarget) {
        let keys = _.keys(this.completeWorkflowJson);
        let temp01 = _.filter(keys, oneKey => {
            let found = false;

            if (_.startsWith(oneKey, pPath)) {
                found = true;
            }
            if (oneKey == pPath + '.ID') {
                found = false;
            }
            return found;
        })
        this.tempJson[pTarget] = [];
        _.each(temp01, oneKey => {
            let obj = {
                "columnName": _.trimStart(oneKey, pPath),
                "value": _.get(this.completeWorkflowJson, oneKey)
            }
            this.tempJson[pTarget].push(obj);
        });
    }

    private getCompleteWorkflowTemplate() {
        return {
            "completeInterface.0.type": "auto",
            "completeInterface.0.pf": "SYS_INPUT.pf",
            "completeInterface.0.autoJson": null,
            "completeInterface.0.bodyData.record.0.ID": "SYS_INPUT.documentId",
            "completeInterface.1.type": "hulk",
            "completeInterface.1.interfaceUrl": {
                "msgTemplate": "<%= GETWAY %>/v1/wordProcessServlet/execWordProcess",
                "msgValue": {
                    "GETWAY": "SYS.environment.NodeJsServer"
                }
            },
            "completeInterface.1.bodyData.PF": "SYS_INPUT.pf",
            "completeInterface.1.bodyData.userId": "SYS_INPUT.execUserId",
            "completeInterface.1.bodyData.wordConfigId": null,
            "completeInterface.1.bodyData.documentsId": "SYS_INPUT.wfUserAwait.DOCUMENT_ID",
            "completeInterface.1.bodyData.getway": {
                "msgTemplate": "<%= HDBSTHOR_ASSETS %>/AppApi/WordProcessController/execWordProcess",
                "msgValue": {
                    "HDBSTHOR_ASSETS": "SYS.environment.hdbsthor_assets.getway"
                }
            },
            "completeInterface.1.bodyData.accessToken": "SYS.environment.hdbsthor_assets.accessToken",
            "completeInterface.1.bodyData.wordProcessType": null,
            "completeInterface.1.bodyData.pdfCallBackUrl": {
                "msgTemplate": "<%= GETWAY_CALL_BACK %>/AppApi/WordProcessController/writeContractPdf?appToken=<%= ACCESS_TOKEN %>",
                "msgValue": {
                    "GETWAY_CALL_BACK": "SYS.environment.hdbsthor_assets.getway_callBack",
                    "ACCESS_TOKEN": "SYS.environment.hdbsthor_assets.accessToken"
                }
            },
            "completeInterface.1.bodyData.autoJson": null,
            "completeInterface.1.bodyData.debug": true,
            "completeInterface.1.bodyData.fileName": {
                "msgTemplate": "审批表-<%= DATETIME %>",
                "msgValue": {
                    "DATETIME": "SYS.DATETIME"
                }
            },
            "processInterface.0.type": "auto",
            "processInterface.0.pf": "SYS_INPUT.pf",
            "processInterface.0.bodyData.record.0.ID": "SYS_INPUT.documentId",
            "processInterface.0.autoJson": "assets/workflow/completeWorkflow/h45ed04310b1471e896a486986c89756_auto_process.json"
        };
    }

    addUpdateColumn() {
        this.tempJson.updateJsonColumnList.push(
            {
                "columnName": 'ABC',
                "value": 'CHECKED'
            }
        )
    }

    addProcessColumn() {
        this.tempJson.processJsonColumnList.push(
            {
                "columnName": 'WF_STATE_01',
                "value": 'SYS_INPUT.wfState'
            }
        )
    }

    /**
     * 保存完成状态的文件
     */
    saveComplteFile() {
        let check = true;
        if (!this.util.Base_HasValue(this.tempJson.updateTableName)) {
            check = false;
            this.confirmModal.show('error', {
                title: '更新表名是必填项'
            });
        }

        if (!this.util.Base_HasValue(this.completeWorkflowJson['completeInterface.0.autoJson'])) {
            check = false;
            this.confirmModal.show('error', {
                title: '过程更新状态Json地址是必填项'
            });
        }

        let columns = [];
        _.each(this.tempJson.updateJsonColumnList, oneColumn => {
            columns.push({
                "COLUMN": oneColumn['columnName']
            })
        })
        let templateJson = this.getUpdateTemplate(this.tempJson.updateTableName, columns);

        if (check) {
            let temp = _.cloneDeep(templateJson);
            this.hulkconfigserviceservice.saveFile(temp, this.completeWorkflowJson['completeInterface.0.autoJson']);
        }

    }

    autoSaveProcessFile() {
    }


    /**
     * 保存过程状态文件
     */
    saveProcessFile() {
        let check = true;

        if (!this.util.Base_HasValue(this.tempJson.updateTableName)) {
            check = false;
            this.confirmModal.show('error', {
                title: '更新表名是必填项'
            });
        }

        if (!this.util.Base_HasValue(this.completeWorkflowJson['processInterface.0.autoJson'])) {
            check = false;
            this.confirmModal.show('error', {
                title: '过程更新状态Json地址是必填项'
            });
        }

        let columns = [];
        _.each(this.tempJson.processJsonColumnList, oneColumn => {
            columns.push({
                "COLUMN": oneColumn['columnName']
            })
        })
        let templateJson = this.getUpdateTemplate(this.tempJson.updateTableName, columns);

        if (check) {
            let temp = _.cloneDeep(templateJson);
            this.hulkconfigserviceservice.saveFile(temp, this.completeWorkflowJson['processInterface.0.autoJson']);

        }
    }

    getUpdateTemplate(pTableName, pColumns) {
        let template = {
            "autoJson": {
                "LIST": [
                    {
                        "TABLE": pTableName,
                        "ACTION": "Update",
                        "WHEREGROUP": [
                            {
                                "GROUP": "GROUP_01",
                                "CONDITION_OPERATOR": "AND"
                            }
                        ],
                        "WHERE": [
                            {
                                "COLUMN": "ID",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            }
                        ],
                        "COLUMNS": pColumns,
                        "RECORDS": []
                    }
                ]
            }
        }
        return template;
    }


    async getApproveTemplate(pJsonPath) {
        let oldJson = await this.requestDataService.getDataByJson(pJsonPath).toPromise();
        let template = {};
        let status = _.get(oldJson, ['status'], 200);
        if (status == 200) {
            template = oldJson;
            _.set(template, ['modalConfig', 'nzTitle'], this.tempJson.approveButtonTitle);
            _.set(template, ['bodyData', 'documentId'], this.tempJson.documentId);
            _.set(template, ['bodyData', 'documentType'], this.findValue(this.formData, 'WORD_PROCESS'));
            _.set(template, ['bodyData', 'wfConfigId'], this.workflowId);
        } else {
            template = {
                "selectMore": false,
                "modalType": "yes_or_no",
                "modalConfig": {
                    "nzTitle": this.tempJson.approveButtonTitle
                },
                "interfaceType": "hulk",
                "hulkConfig": {
                    "controller": "workflowSpServlet",
                    "method": "startWorkflow",
                    "hasPF": false
                },
                "bodyData": {
                    "pf": "SYS.pf",
                    "jsonId": "SYS.environment.jsonId",
                    "documentId": this.tempJson.documentId,
                    "documentType": this.findValue(this.formData, 'WORD_PROCESS'),
                    "wfConfigId": this.workflowId,
                    "execId": null,
                    "execUserId": "SYS.USER_ID"
                }
            }
        }

        return template;
    }


    autoSaveApproveButtonFile() {
        this.completeWorkflowJson['approveButtonJsonPath'] = this.completeWorkflowJsonId + '_button_approve.json';
        this.saveApproveButtonFile();
    }

    async saveApproveButtonFile() {
        let check = true;

        if (!this.util.Base_HasValue(this.tempJson.documentId) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '唯一ID是必填项'
            });
        }

        if (!this.util.Base_HasValue(this.completeWorkflowJson['approveButtonJsonPath']) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '按钮地址是必填项'
            });
        }

        if (!this.util.Base_HasValue(this.tempJson.approveButtonTitle) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '弹出框标题是必填项'
            });
        }

        let documentType = this.findValue(this.formData, 'WORD_PROCESS');
        if (!this.util.Base_HasValue(documentType) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '适配的流程是必填项'
            });
        }
        let templateJson = await this.getApproveTemplate(this.completeWorkflowJson['approveButtonJsonPath']);
        if (check) {
            let temp = _.cloneDeep(templateJson);
            this.hulkconfigserviceservice.saveFile(temp, this.completeWorkflowJson['approveButtonJsonPath']);
        }

    }

    async getExecTemplate(pJsonPath) {
        let oldJson = await this.requestDataService.getDataByJson(pJsonPath).toPromise();
        let template = {};
        let status = _.get(oldJson, ['status'], 200);
        if (status == 200) {
            template = oldJson;
            _.set(template, ['modalConfig', 'nzTitle'], this.tempJson.execButtonTitle);
        } else if (status == 500) {
            template = {
                "selectMore": false,
                "modalType": "custom",
                "modalConfig": {
                    "nzTitle": this.tempJson.execButtonTitle,
                    "nzWidth": 700,
                    "nzWrapClassName": "vertical-center-modal",
                    "nzComponentParams": {
                        "modalFormData": {
                            "columns": [
                                {
                                    "column": "APPROVAL_RESULT",
                                    "name": "APPROVAL_RESULT_NAME",
                                    "component": "radio",
                                    "lableSpan": 6,
                                    "itemSpan": 16,
                                    "customize": 24,
                                    "lable": "是否同意",
                                    "comment": "是否同意",
                                    "placeholder": "请选择是否同意",
                                    "model": null,
                                    "visible": true,
                                    "require": true,
                                    "dictionary": "assets/basic/dict/AGREE.json",
                                    "dictionaryPF": null
                                },
                                {
                                    "column": "APPROVAL_CONTENT",
                                    "name": "APPROVAL_CONTENT_NAME",
                                    "component": "inputMulti",
                                    "lableSpan": 6,
                                    "itemSpan": 16,
                                    "customize": 24,
                                    "lable": "审批意见",
                                    "comment": "审批意见",
                                    "placeholder": "请选择审批意见",
                                    "model": null,
                                    "visible": true,
                                    "require": false,
                                    "dictionaryPF": null
                                }
                            ]
                        }
                    }
                },
                "interfaceType": "hulk",
                "hulkConfig": {
                    "controller": "workflowSpServlet",
                    "method": "execWorkflowAgree",
                    "hasPF": false
                },
                "afterCompletionAction": "close",
                "bodyData": {
                    "pf": "SYS.pf",
                    "jsonId": "SYS.environment.jsonId",
                    "execUserId": "SYS.USER_ID",
                    "execUserName": "SYS.USER_NAME",
                    "wfUserAwaitId": "SYS.wfUserAwaitId",
                    "approvalResult": "MODAL.APPROVAL_RESULT",
                    "approvalContent": "MODAL.APPROVAL_CONTENT"
                }
            }
        }

        return template;
    }

    autoSaveExecButtonFile() {
        this.completeWorkflowJson['execButtonJsonPath'] = this.completeWorkflowJsonId + '_button_exec.json';
        this.saveExecButtonFile();
    }

    async saveExecButtonFile() {
        let check = true;

        if (!this.util.Base_HasValue(this.completeWorkflowJson['execButtonJsonPath']) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '按钮地址是必填项'
            });
        }

        if (!this.util.Base_HasValue(this.tempJson.execButtonTitle) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '弹出框标题是必填项'
            });
        }

        let documentType = this.findValue(this.formData, 'WORD_PROCESS');
        if (!this.util.Base_HasValue(documentType) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '适配的流程是必填项'
            });
        }
        let templateJson = await this.getExecTemplate(this.completeWorkflowJson['execButtonJsonPath']);

        if (check) {
            let temp = _.cloneDeep(templateJson);
            this.hulkconfigserviceservice.saveFile(temp, this.completeWorkflowJson['execButtonJsonPath']);
        }
    }

    async fillBackBtnInfo() {
        if (!this.util.Base_HasValue(this.completeWorkflowJson['execButtonJsonPath'])) this.completeWorkflowJson['execButtonJsonPath'] = this.completeWorkflowJsonId + '_button_exec.json';
        if (!this.util.Base_HasValue(this.completeWorkflowJson['approveButtonJsonPath'])) this.completeWorkflowJson['approveButtonJsonPath'] = this.completeWorkflowJsonId + '_button_approve.json';
        if (!this.util.Base_HasValue(this.completeWorkflowJson['deleteButtonJsonPath'])) this.completeWorkflowJson['deleteButtonJsonPath'] = this.completeWorkflowJsonId + '_button_delete.json';
        if (!this.util.Base_HasValue(this.completeWorkflowJson['pdfButtonJsonPath'])) this.completeWorkflowJson['pdfButtonJsonPath'] = this.completeWorkflowJsonId + '_button_pdf.json';

        let approveButtonJson = await this.requestDataService.getDataByJson(this.completeWorkflowJson['approveButtonJsonPath']).toPromise();
        let execButtonJson = await this.requestDataService.getDataByJson(this.completeWorkflowJson['execButtonJsonPath']).toPromise();
        _.set(this.tempJson, ['approveButtonTitle'], _.get(approveButtonJson, ['modalConfig', 'nzTitle'], null));
        _.set(this.tempJson, ['execButtonTitle'], _.get(execButtonJson, ['modalConfig', 'nzTitle'], null));
        _.set(this.tempJson, ['documentId'], _.get(this.completeWorkflowJson, ['documentId']));
        _.set(this.tempJson, ['updateTableName'], _.get(this.completeWorkflowJson, ['updateTableName']));
        _.set(this.tempJson, ['fileName'], _.get(this.completeWorkflowJson, ['completeInterface.1.bodyData.fileName']));

        _.each(_.get(this.formData, 'columns'), (item) => {
            if (item['column'] == 'WORD_CONFIG_ID') {
                _.set(item, ['model'], this.completeWorkflowJson['completeInterface.1.bodyData.wordConfigId']);
            }
        });

    }

    saveMainFile() {
        let check = true;
        if (!this.util.Base_HasValue(_.get(this.tempJson, ['fileName'])) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '审批文件的文件名是必填项'
            });
        }

        if (!this.util.Base_HasValue(_.get(this.tempJson, ['documentId'])) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '主键ID是必填项'
            });
        }

        if (!this.util.Base_HasValue(_.get(this.tempJson, ['updateTableName'])) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '更新表是必填项'
            });
        }

        let wordConfigId = this.findValue(this.formData, 'WORD_CONFIG_ID');
        if (!this.util.Base_HasValue(wordConfigId) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '文档模板是必填项'
            });
        }
        _.set(this.completeWorkflowJson, ['completeInterface.1.bodyData.fileName'], _.get(this.tempJson, ['fileName']));
        _.set(this.completeWorkflowJson, ['updateTableName'], _.get(this.tempJson, ['updateTableName']));
        _.set(this.completeWorkflowJson, ['documentId'], _.get(this.tempJson, ['documentId']));
        _.set(this.completeWorkflowJson, ['completeInterface.1.bodyData.wordConfigId'], wordConfigId);
        _.set(this.completeWorkflowJson, ['completeInterface.1.bodyData.wordProcessType'], this.findValue(this.formData, 'WORD_PROCESS'));
        _.set(this.completeWorkflowJson, ['completeInterface.1.bodyData.autoJson'], this.completeWorkflowJsonId + '_auto_pdf.json');
        _.set(this.completeWorkflowJson, ['completeInterface.0.autoJson'], this.completeWorkflowJsonId + '_auto_complte.json');
        _.set(this.completeWorkflowJson, ['processInterface.0.autoJson'], this.completeWorkflowJsonId + '_auto_process.json');

        _.each(this.tempJson.updateJsonColumnList, oneColumn => {
            _.set(this.completeWorkflowJson, ['completeInterface.0.bodyData.record.0.' + oneColumn['columnName']], oneColumn['value']);
        })

        _.each(this.tempJson.processJsonColumnList, oneColumn => {
            _.set(this.completeWorkflowJson, ['processInterface.0.bodyData.record.0.' + oneColumn['columnName']], oneColumn['value']);
        })

        if (check) {
            let temp = _.cloneDeep(this.completeWorkflowJson);
            let jsonPath = this.config.config.sql_path + '/workflow/completeWorkflow/' + this.workflowId + '.json';
            this.hulkconfigserviceservice.saveFile(temp, jsonPath);
        }
        this.saveProcessFile();
        this.saveComplteFile();
        this.autoSaveApproveButtonFile();
        this.autoSaveExecButtonFile();
        this.autoSaveDeleteButtonFile();
        this.autoSavePdfButtonFile();
    }

    autoSaveButtonFile() {
        this.autoSaveApproveButtonFile();
        this.autoSaveExecButtonFile();
        this.autoSaveDeleteButtonFile();
        this.autoSavePdfButtonFile();
    }

    autoSaveDeleteButtonFile() {
        let jsonPath = this.completeWorkflowJsonId + '_button_delete.json';
        let template = {
            "selectMore": true,
            "modalType": "yes_or_no",
            "modalConfig": {
                "nzTitle": "确认删除" + this.findValue(this.formData, 'WF_NAME') + '么？'
            },
            "interfaceType": "auto",
            "autoJson": {
                "LIST": [
                    {
                        "TABLE": "wf_await",
                        "ACTION": "Delete",
                        "WHEREGROUP": [
                            {
                                "GROUP": "GROUP_01",
                                "CONDITION_OPERATOR": "AND"
                            }
                        ],
                        "WHERE": [
                            {
                                "COLUMN": "DOCUMENT_ID",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            },
                            {
                                "COLUMN": "DOCUMENT_TYPE",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            }
                        ],
                        "COLUMNS": [
                            {
                                "COLUMN": "DOCUMENT_ID"
                            },
                            {
                                "COLUMN": "DOCUMENT_TYPE"
                            }
                        ],
                        "RECORDS_DATAS": "READONLY.sqlFileListData.0",
                        "RECORDS": [
                            {
                                "DOCUMENT_ID": "SPECIAL_DATA.ID",
                                "DOCUMENT_TYPE": this.findValue(this.formData, 'WORD_PROCESS')
                            }
                        ]
                    },
                    {
                        "TABLE": "wf_user_await",
                        "ACTION": "Delete",
                        "WHEREGROUP": [
                            {
                                "GROUP": "GROUP_01",
                                "CONDITION_OPERATOR": "AND"
                            }
                        ],
                        "WHERE": [
                            {
                                "COLUMN": "DOCUMENT_ID",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            },
                            {
                                "COLUMN": "DOCUMENT_TYPE",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            }
                        ],
                        "COLUMNS": [
                            {
                                "COLUMN": "DOCUMENT_ID"
                            },
                            {
                                "COLUMN": "DOCUMENT_TYPE"
                            }
                        ],
                        "RECORDS_DATAS": _.chain(this.tempJson.documentId).split(".").dropRight(2).join(".").value(),
                        "RECORDS": [
                            {
                                "DOCUMENT_ID": "SPECIAL_DATA.ID",
                                "DOCUMENT_TYPE": this.findValue(this.formData, 'WORD_PROCESS')
                            }
                        ]
                    }
                ]
            }
        }
        this.hulkconfigserviceservice.saveFile(template, jsonPath);
    }

    autoSavePdfButtonFile() {
        let jsonPath = this.completeWorkflowJsonId + '_button_pdf.json';
        let autoPath = this.completeWorkflowJsonId + '_auto_pdf.json';
        let template = {
            "selectMore": true,
            "modalType": "yes_or_no",
            "modalConfig": {
                "nzTitle": "确认生成" + this.findValue(this.formData, 'WF_NAME') + '的Pdf么？'
            },
            "interfaceType": "hulk",
            "hulkConfig": {
                "controller": "wordProcessServlet",
                "method": "execWordProcess",
                "hasPF": false
            },
            "queryData": {},
            "bodyData": {
                "PF": "SYS.pf",
                "userId": "SYS.USER_ID",
                "wordConfigId": this.findValue(this.formData, 'WORD_CONFIG_ID'),
                "documentsId": _.get(this.completeWorkflowJson, ['documentId']),
                "fileName": this.tempJson.fileName,
                "getway": {
                    "msgTemplate": "<%= HDBSTHOR_ASSETS %>/AppApi/WordProcessController/execWordProcess",
                    "msgValue": {
                        "HDBSTHOR_ASSETS": "SYS.environment.hdbsthor_assets.getway"
                    }
                },
                "accessToken": "SYS.environment.hdbsthor_assets.accessToken",
                "pdfCallBackUrl": {
                    "msgTemplate": "<%= GETWAY_CALL_BACK %>/AppApi/WordProcessController/writeContractPdf?appToken=<%= ACCESS_TOKEN %>",
                    "msgValue": {
                        "GETWAY_CALL_BACK": "SYS.environment.hdbsthor_assets.getway_callBack",
                        "ACCESS_TOKEN": "SYS.environment.hdbsthor_assets.accessToken"
                    }
                },
                "autoJson": autoPath,
                "debug": true
            }
        }
        this.hulkconfigserviceservice.saveFile(template, jsonPath);

        let autoTemplate = {
            "autoJson": {
                "LIST": [
                    {
                        "TABLE": this.tempJson.updateTableName,
                        "ACTION": "Update",
                        "WHEREGROUP": [
                            {
                                "GROUP": "GROUP_01",
                                "CONDITION_OPERATOR": "AND"
                            }
                        ],
                        "WHERE": [
                            {
                                "COLUMN": "ID",
                                "VALUE": "?",
                                "OPERATOR": "=",
                                "VALUE_TYPE": "string",
                                "CONDITION_OPERATOR": "AND",
                                "GROUP": "GROUP_01"
                            }
                        ],
                        "COLUMNS": [
                            {
                                "COLUMN": "WORD_PROCESS_ID"
                            }
                        ],
                        "RECORDS": [
                            {
                                "ID": "ID",
                                "WORD_PROCESS_ID": "WORD_PROCESS_ID"
                            }
                        ]
                    }
                ]
            }
        }

        this.hulkconfigserviceservice.saveFile(autoTemplate, autoPath);


    }

    ngOnInit() {
        this.workflowId = _.cloneDeep(this.parentData['id']);
        this.completeWorkflowJsonId = this.config.config.sql_path + '/workflow/completeWorkflow/' + this.workflowId;
        let jsonPath = this.config.config.sql_path + '/workflow/completeWorkflow/' + this.workflowId + '.json';
        this.modeType = _.cloneDeep(this.parentData['modeType']);
        if (this.modeType == 'modify') {
            const search = observableForkJoin(
                this.requestDataService.getDataByCondition('workflow/wf_config_list.json', {'ID': this.workflowId}),
                this.requestDataService.getDataByJson(jsonPath)
            );
            search.subscribe(
                values => {
                    let datas = _.get(values, [0, 'data', 0], {});
                    this.BackfillData(datas);
                    this.workflowData = JSON.parse(_.get(datas, 'WF_JSON', {}));
                    let status = _.get(values, [1, 'status'], 200);
                    if (status == 200) {
                        this.completeWorkflowJson = _.get(values, [1], {});
                    } else if (status == 500) {
                        this.completeWorkflowJson = this.getCompleteWorkflowTemplate();
                    }
                    this.getJsonColumnList('completeInterface.0.bodyData.record.0', 'updateJsonColumnList');
                    this.getJsonColumnList('processInterface.0.bodyData.record.0', 'processJsonColumnList');
                    this.fillBackBtnInfo();
                }
            );
        }
    }

    addNewMoreInfo() {
        let obj = {
            "title": "更多信息的标题01",
            "msgTemplate": "时间01：<%= DATETIME %>",
            "msgValue": {
                "DATETIME": "SYS.DATETIME"
            }
        }
        if (!this.util.Base_HasValue(_.get(this.completeWorkflowJson,'moreInfo',null))) {
            this.completeWorkflowJson['moreInfo'] = [];
        }
        this.completeWorkflowJson['moreInfo'].push(obj);
    }
}
