import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {RequestDataService} from "../../service/request-data.service";
import {UtilsService} from "../../service/utils.service";
import * as _ from 'lodash';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  @Input() bindModel: any;

  constructor(private requestDataService: RequestDataService,
              private utils: UtilsService) { }


  modelChange(model) {
    if (this.utils.Base_HasValue(model)) {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = model;
      } else {
        this.hulkColumn.model = model;
      }
      if (this.utils.Base_HasValue(this.hulkColumn.columnGroup)
          && this.hulkColumn.columnGroup.length) {
        let findValue = _.find(this.hulkColumn['itemArray'],{'code': model});
        _.each(this.hulkColumn.columnGroup, (item) => {
          item['model'] = findValue[item['fromValue']];
        });
      }
    }
  }

  ngOnInit(): void {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    if (!this.hulkColumn.itemArray && this.hulkColumn.dictionary) {
      this.requestDataService.getDataByCondition(this.hulkColumn.dictionary).subscribe(result => {
        this.hulkColumn.itemArray = result['data'] || [];
      });
    }
  }
}
