import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  constructor() {
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    this.hulkColumn.row = this.hulkColumn.row || 2;
  }

}
