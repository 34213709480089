import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {UserInfoService} from '../../../service/user-info.service';
import {ExcelExportService} from '../../../service/excel-export.service';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'app-site-release',
  templateUrl: './site-release.component.html',
  styleUrls: ['./site-release.component.css']
})
export class SiteReleaseComponent implements OnInit {
  public searchColumns: any = {};

  public dateRange: any = [];

  public chartOption: any = {};

  public category: any = [];

  public list: any = {
    category: [],
    data: []
  };

  public scrollWidth: any = null;

  public date: string = '';

  public tongJiData: any = [];

  public exportData: any = [];

  public searchType: any;

  constructor(private requestData: RequestDataService,
              private utils: UtilsService,
              private userInfo: UserInfoService,
              private excelExport: ExcelExportService) {
  }


  export() {
    let category = [];
    category = this.utils.DeepCopy(this.list.category);
    category.unshift('站点名称');
    let dataList = this.list.data;
    _.each(dataList, (data: any) => {
      let obj = {};
      _.each(data, (item, index) => {
        obj[category[index]] = item;
      });
      this.exportData.push(obj);
    });
    this.excelExport.exportList(this.exportData, '站点新闻发布统计报表');
  }

  // 根据当前选中区间回传查询参数
  getSearchData(model) {
    const today = moment(this.date).format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    const setDate = (data, category, value) => {
      data['ADD_TIME_BEGIN'] = moment(this.date).subtract(value - 1, 'days').format('YYYY-MM-DD');
      for (let i = value - 1; i > 0; i--) {
        category.push(moment(this.date).subtract(i, 'days').format('YYYY-MM-DD'));
      }
    };
    switch (model) {
      case 'NEARLY-SEVEN':
        setDate(data, category, 7);
        this.scrollWidth = null;
        break;
      case 'NEARLY-TEN':
        setDate(data, category, 10);
        this.scrollWidth = '1500px';
        break;
      case 'NEARLY-FIFTEEN':
        setDate(data, category, 15);
        this.scrollWidth = '2160px';
        break;
      case 'NEARLY-TWENTY':
        setDate(data, category, 20);
        this.scrollWidth = '2840px';
        break;
      case 'NEARLY-MONTH':
        data['ADD_TIME_BEGIN'] = moment(this.date).subtract(1, 'month').format('YYYY-MM-DD');
        for (let i = 1; i <= 30; i++) {
          const date = moment(this.date).subtract(i, 'days').format('YYYY-MM-DD');
          category.push(date);
          // 如果是上个月这个一天不需要在循环；
          if (date === data['ADD_TIME_BEGIN']) {
            break;
          }
        }
        category = category.reverse();
        this.scrollWidth = '4300px';
        break;
    }
    category.push(today);
    return {
      searchData: data,
      category: category
    };
  }

  setOption(categoryList, valueList, releaseList) {
    this.chartOption = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['抓取数', '发布数']
      },
      grid: {
        left: '2%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: ['#C7C7C7']
          }
        },
        data: categoryList
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#A4A4A4',
            fontSize: 14
          }
        }
      },
      series: [
        {
          name: '抓取数量(条)',
          type: 'line',
          stack: '抓取数量(条)',
          symbol: 'circle',
          symbolSize: 10,
          color: '#6BA7FB',
          lineStyle: {
            color: '#6BA7FB',
            shadowColor: '#6BA7FB',
            shadowBlur: 5,
            shadowOffsetX: 0.5,
            shadowOffsetY: 0.5,
            width: 3
          },
          data: valueList
        },
        {
          name: '发布数量(条)',
          type: 'line',
          stack: '发布数量(条)',
          symbol: 'circle',
          symbolSize: 10,
          color: '#D98313',
          lineStyle: {
            color: '#D98313',
            shadowColor: '#D98313',
            shadowBlur: 5,
            shadowOffsetX: 0.5,
            shadowOffsetY: 0.5,
            width: 3
          },
          data: releaseList
        }
      ]
    };
  }

  transformData(category, data) {
    // 先封装折线图需要数据
    // 根据时间分组，循环日期区间，筛入总值
    let valueList = [];
    let releaseList = [];
    let dateObj = _.groupBy(data, item => item['ADD_TIME']);
    _.each(category, (cate: string) => {
      let num = 0;
      let releaseNum = 0;
      _.each(dateObj[cate], result => {
        num = +result['GRASP_NUM'];
        releaseNum = +result['RELEASE_NUM'];
      });
      valueList.push(num);
      releaseList.push(releaseNum);
    });

    let list = {
      category: category,
      data: []
    };
    // 根据站点分组
    let orgObj = _.groupBy(data, item => item['SOURCE']);
    _.each(orgObj, (item, key) => {
      let currentData = [item[0]['SOURCE']];
      let dateData = _.groupBy(item, v => v['ADD_TIME']);
      _.each(category, (cate: string) => {
        if (this.utils.Base_HasValue(cate)) {
          let num = 0;
          let releaseNum = 0;
          _.each(dateData[cate], result => {
            num = +result['GRASP_NUM'];
            releaseNum = +result['RELEASE_NUM'];
          });
          if (!this.utils.Base_HasValue(this.searchType) || this.searchType === 'GRASE') {
            if (num) {
              currentData.push(num);
            } else {
              currentData.push('/');
            }
          }
          if (!this.utils.Base_HasValue(this.searchType) || this.searchType === 'RELEASE') {
            if (releaseNum) {
              currentData.push(releaseNum);
            } else {
              currentData.push('/');
            }
          }
        }
      });
      list.data.push(currentData);
    });
    // 封装表格需要数据
    return {
      valueList: valueList,
      releaseList: releaseList,
      list: list
    };
  }


  // 获取数据
  getRequestData() {
    let data = this.getSearchData(this.searchColumns.columns[0].model);
    let condition = data.searchData;
    this.category = data.category;
    let category = Object.assign([], data.category);
    let source = this.searchColumns.columns[1].model;
    this.searchType = this.searchColumns.columns[2].model;
    if (this.utils.Base_HasValue(source)) {
      condition['SOURCE'] = source;
    }
    this.requestData.getDataByCondition('headLine/report/site_new_release/main.json', condition).subscribe(result => {
      let data = result.data || [];
      const transData = this.transformData(category, data);
      this.setOption(this.category, transData.valueList, transData.releaseList);
      this.list = transData.list;
      if (this.searchType === 'RELEASE') {
        this.chartOption.series.splice(0, 1);
      } else if (this.searchType === 'GRASE') {
        this.chartOption.series.splice(1, 1);
      }
    });
  }

  // 前一天
  prevDate() {
    this.date = moment(this.date).subtract(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  // 后一天
  nextDate() {
    this.date = moment(this.date).add(1, 'days').format('YYYY-MM-DD');
    this.getRequestData();
  }

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    let searchType = [
      {
        code: 'GRASE',
        name: '抓取数'
      },
      {
        code: 'RELEASE',
        name: '发布数'
      }
    ];
    this.dateRange = [
      {
        code: 'NEARLY-SEVEN',
        name: '近7天'
      },
      {
        code: 'NEARLY-TEN',
        name: '近10天'
      },
      {
        code: 'NEARLY-FIFTEEN',
        name: '近15天'
      },
      {
        code: 'NEARLY-TWENTY',
        name: '近20天'
      },
      {
        code: 'NEARLY-MONTH',
        name: '近1个月'
      }
    ];
    let searchColumns = {
      'title': '站点新闻发布统计报表',
      'columns': [
        {
          'column': 'ADD_TIME',
          'name': 'ADD_TIME',
          'component': 'select',
          'lable': '选择区间',
          'comment': '选择区间',
          'placeholder': '请选择区间',
          'model': 'NEARLY-SEVEN',
          'visible': true,
          'require': true,
          'itemArray': this.dateRange
        },
        {
          'column': 'SOURCE',
          'name': 'SOURCE',
          'component': 'input',
          'lable': '站点名称',
          'comment': '站点名称',
          'placeholder': '请输入站点名称',
          'model': null,
          'visible': true,
          'require': false
        },
        {
          'column': 'SEARCH_TYPE',
          'name': 'SEARCH_TYPE',
          'component': 'select',
          'lable': '类型',
          'comment': '类型',
          'placeholder': '类型',
          'model': null,
          'visible': true,
          'require': false,
          'itemArray': searchType
        }
      ]
    };
    this.searchColumns = searchColumns;

    this.getRequestData();


  }

  ngDoCheck(): void {
    if (this.searchColumns.title) {
      _.each(this.searchColumns.columns, item => {
        if (item['model'] !== item['oldModel']) {
          item['oldModel'] = item['model'];
          this.date = moment().format('YYYY-MM-DD');
          this.getRequestData();
        }
      });
    }

  }
}
