import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-audit-contract',
	templateUrl: './audit-contract.component.html',
	styleUrls: ['./audit-contract.component.css']
})
export class AuditContractComponent implements OnInit {

	public auditState:string;
	public reason:string;
	constructor() { }

	ngOnInit() {
	}
}
