import {Component, Input, OnInit} from '@angular/core';
import {NzMessageService, UploadFile} from "ng-zorro-antd";
import {UploadService} from "../../service/upload.service";
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from "@angular/common/http";
import {AppConfigService} from "../../service/app-config.service";
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-picture-upload-se',
  templateUrl: './picture-upload-se.component.html',
  styleUrls: ['./picture-upload-se.component.scss']
})
export class PictureUploadSeComponent implements OnInit {

  @Input() hulkForm: any;
  @Input() hulkColumn: any;

  loading = false;
  fileList: UploadFile[] = [];
  // 上传进度
  progress = 0;
  uploadFileList = [];
  componentConfig = {
    title: "组件标题",
    col_nzSpan: 12,
    lable_nzSm: 8,
    control_nzSm: 16,
    nzLimit: 50,
    info: true,
    infoTemplate: [
      {
        text: "1、支持上传的图片类型：jpg、png、jpeg、gif。"
      },
      {
        text: "2、单张图片大小不超过20M，每次上传图片总数不超过500张。"
      },
      {
        text: "3、添加完成后、上传前可以删除待上传的图片，上传成功后只能在查询页面删除。"
      },
      {
        text: "4、图片上传成功后，需要保存，否则不会显示在系统中。"
      }
    ]
  };

  constructor(private msg: NzMessageService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private httpClient: HttpClient,
              private config: AppConfigService) {
  }

  beforeUpload = (file: UploadFile) => {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJPG) {
      this.confirmModal.show('error', {title: `请上传jpeg或者png格式的图片`});
      return false;
    }
    this.fileList = _sh.concat(this.fileList, file)
    const img = new Image(); // create image
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      this.uploadFileList.push({
        pictureName: file.name,
        pictureType: file.type,
        pictureSize: this.utils.conver(file.size),
        pictureWidth: width,
        pictureHeight: height,
      });
    };

    return false;
  };

  removeFile = (file: UploadFile) => {
    for (let oneFile of this.uploadFileList) {
      if (file.name === oneFile['pictureName']) {
        this.uploadFileList = _.without(this.uploadFileList, oneFile);
      }
    }
    return true;
  };

  handleUpload() {
    const formData = new FormData();
    this.fileList.forEach((file: any) => {
      formData.append('files[]', file);
    });
    this.loading = true;
    this.progress = 0;
    let url = this.config.config.quickSliver + '/uploadMultipleFileCloud';
    if (this.config.config['userOssOrCos'] === 'OSS') {
      let ossPath = this.config.config.OSS.ossPath;
      url += "?server=oss&path=" + ossPath;
    } else if (this.config.config['userOssOrCos'] === 'COS') {
      let cosPath = this.config.config.COS.cosPath;
      url += "?server=oss&path=" + cosPath;
    }
    const req = new HttpRequest('POST', url, formData, {
        reportProgress: true
      }
    );
    this.httpClient
      .request(req)
      .pipe(
        map(event => this.getEventMessage(event))
      )
      .subscribe((rep) => {
          if (this.utils.Base_HasValue(rep)) {
            this.loading = false;
            this.fileList = [];
            for (let oneData of rep.result) {
              for (let oneFile of this.uploadFileList) {
                if (oneData['fileName'] === oneFile['pictureName']) {
                  oneFile['pictureUrl'] = oneData['url'];
                }
              }
            }
            this.hulkColumn.fileList = this.hulkColumn.fileList.concat(this.uploadFileList);
            this.uploadFileList = [];
            // this.confirmModal.show('success', {title: '上传成功'});
          }
        },
        (error) => {
          this.uploadFileList = [];
          this.fileList = [];
          this.loading = false;
          this.confirmModal.show('success', {title: '上传失败'});
        }
      );
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return null;
      case HttpEventType.UploadProgress:
        this.progress = Math.round(100 * event.loaded / event.total);
        return null;
      // 上传完毕
      case HttpEventType.Response:
        return event.body;
      default:
        return null;
    }
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }


  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  putItLast(pOneData) {
    this.hulkColumn.fileList = _.without(this.hulkColumn.fileList, pOneData);
    this.hulkColumn.fileList.push(pOneData);
  }

  pubItFirst(pOneData) {
    this.hulkColumn.fileList = _.without(this.hulkColumn.fileList, pOneData);
    this.hulkColumn.fileList.unshift(pOneData);
  }

  deleteImg(pOneData) {
    this.hulkColumn.fileList = _.without(this.hulkColumn.fileList, pOneData);
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.hulkColumn.fileList)) {
      this.hulkColumn.fileList = [];
    }
    this.componentConfig = {
      title: _sh.get(this.hulkColumn, ['componentConfig', 'title'], _sh.get(this.componentConfig, ['componentConfig', 'title'])),
      col_nzSpan: _sh.get(this.hulkColumn, ['componentConfig', 'col_nzSpan'], _sh.get(this.componentConfig, ['componentConfig', 'col_nzSpan'])),
      lable_nzSm: _sh.get(this.hulkColumn, ['componentConfig', 'lable_nzSm'], _sh.get(this.componentConfig, ['componentConfig', 'lable_nzSm'])),
      control_nzSm: _sh.get(this.hulkColumn, ['componentConfig', 'control_nzSm'], _sh.get(this.componentConfig, ['componentConfig', 'control_nzSm'])),
      nzLimit: _sh.get(this.hulkColumn, ['componentConfig', 'nzLimit'], _sh.get(this.componentConfig, ['componentConfig', 'nzLimit'])),
      info: _sh.get(this.hulkColumn, ['componentConfig', 'info'], _sh.get(this.componentConfig, ['componentConfig', 'info'])),
      infoTemplate: _sh.get(this.hulkColumn, ['componentConfig', 'infoTemplate'], _sh.get(this.componentConfig, ['componentConfig', 'infoTemplate']))
    };

  }

}
