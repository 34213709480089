import {Component, OnInit} from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-view-model',
  templateUrl: './view-model.component.html',
  styleUrls: ['./view-model.component.css']
})
export class ViewModelComponent implements OnInit {

  // 字段数据
  public modelData: any = {};

  constructor() {
  }

  filterVisible(group) {
    return group['visible'] === 'true' || group['visible'] === true || group['visible'] === 1;
  }


  ngOnInit() {
    _.each(this.modelData.groups, group => {
      _.each(group['columns'], column => {
        column['require'] = column['require'] === 'true' || column['require'] === true || column['require'] === 1;
      });
    });
  }

}
