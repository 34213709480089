import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {forkJoin as observableForkJoin} from 'rxjs';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {RequestDataService} from '../../service/request-data.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {FormModalComponent} from '../../basicComponent/form-modal/form-modal.component';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ClipboardService} from 'ngx-clipboard';
import {FileSaverService} from 'ngx-filesaver';
import {HttpClient} from '@angular/common/http';
import {TableModalComponent} from '../../basicComponent/table-modal/table-modal.component';
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";

@Component({
  selector: 'app-hulk-config-detail',
  templateUrl: './hulk-config-detail.component.html',
  styleUrls: ['./hulk-config-detail.component.scss']
})
export class HulkConfigDetailComponent implements OnInit {

  nodes = [];


  public searchId: String = null;
  public detailColumns = [];
  public detailColumnsText: any = '';

  tabIndex = 0;
  sqlFilePath: String = null;
  mainJson: any;
  tableType = [
    {
      'code': 'normal',
      'name': '主表'
    },
    {
      'code': 'tablelist',
      'name': '明细多行表'
    }
  ];

  constructor(
    private _httpClient: HttpClient,
    private modalService: NzModalService,
    private hulkconfigserviceservice: HulkConfigServiceService,
    private _FileSaverService: FileSaverService,
    private requestDataService: RequestDataService,
    public appConfigService: AppConfigService,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private confirmModal: ConfirmModalService,
    private clipboardService: ClipboardService,
    private utils: UtilsService
  ) {
  }

  mapOfExpandData: { [key: string]: boolean } = {};


  refreshStatus(oneForm): void {
    const validData = oneForm.columns.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    oneForm.allChecked = allChecked;
    oneForm.indeterminate = (!allChecked) && (!allUnChecked);
  }

  // 放到第一位
  pubItFirstGroup(formIndex, oneColumn) {
    this.detailColumns[formIndex]['columns'] = _.without(this.detailColumns[formIndex]['columns'], oneColumn);
    this.detailColumns[formIndex]['columns'].unshift(oneColumn);
  }

  // 放置到最后一位
  pubItLastComponent(formIndex, oneColumn) {
    this.detailColumns[formIndex]['columns'] = _.without(this.detailColumns[formIndex]['columns'], oneColumn);
    this.detailColumns[formIndex]['columns'].push(oneColumn);
  }

  deleteComponent(formIndex, oneColumn) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该字段吗？',
      suc: () => {
        this.detailColumns[formIndex]['columns'] = _.without(this.detailColumns[formIndex]['columns'], oneColumn);
      }
    });
  }

  deleteForm(oneForm) {
    this.confirmModal.show('confirm', {
      title: '是否将表，从列表之中删除',
      suc: () => {
        this.detailColumns = _.without(this.detailColumns, oneForm);
      }
    });
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }

  checkAll(oneForm, value: boolean): void {
    oneForm.columns.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus(oneForm);
  }


  rebuildJson(temp) {
    let result = {
      'detailColumns': temp
    };
    return result;
  }

  makeUp(temp) {
    let that = this;

    function makeUpMain() {
      _sh.each(temp, oneForm => {
        oneForm['formname'] = oneForm['tablename'];
        delete oneForm['allChecked'];
        delete oneForm['indeterminate'];
      })
    }

    function makeUpColumnName(pColumn) {
      pColumn['name'] = pColumn['column'] + '_NAME';
      delete pColumn['class'];
    }

    function makeUpColumnPk(pColumn) {
      if (_sh.get(pColumn, ['pk_column'], false)) {
        pColumn['iscondition'] = true;
        pColumn['component'] = 'input';
        pColumn['pk_column'] = true;
        pColumn['lable'] = '主键';
        pColumn['comment'] = '主键';
        pColumn['placeholder'] = '主键';
        pColumn['insertValue'] = 'SYS-ACTION-UUID';
        pColumn['model'] = null;
        pColumn['visible'] = false;
        pColumn['require'] = true;
      }
    }

    function makeUpColumnWidth(pColumn) {
      let customize = _sh.get(pColumn, ['customize'], null)
      if (that.utils.Base_HasValue(customize)) {
        if (customize == 24) {
          pColumn['lableSpan'] = 3;
          pColumn['itemSpan'] = 21;
        } else {
          pColumn['lableSpan'] = 4;
          pColumn['itemSpan'] = 20;
        }
      } else {
        delete pColumn['customize'];
        delete pColumn['lableSpan'];
        delete pColumn['itemSpan'];
      }
    }

    function makeUpColumnHR(pColumn) {
      if (pColumn.component == 'hr') {
        pColumn['customize'] = 24;
        delete pColumn['lableSpan'];
        delete pColumn['itemSpan'];
      }
    }

    function makeUpColumns() {
      _sh.each(temp, oneForm => {
        _sh.each(oneForm.columns, oneColumn => {
          makeUpColumnName(oneColumn); // 处理名称，去掉 class
          makeUpColumnPk(oneColumn); // 处理主键
          makeUpColumnWidth(oneColumn); // 处理宽度
          makeUpColumnHR(oneColumn); // 处理横线
        })
      })
    }

    makeUpMain();
    makeUpColumns();
    return temp;
  }

  doShowJson() {
    let temp = _sh.cloneDeep(this.detailColumns);
    temp = this.makeUp(temp);
    temp = this.rebuildJson(temp);
    this.detailColumnsText = this.utils.syntaxHighlight(temp);
    this.detailColumnsText = _sh.trimStart(this.detailColumnsText, '{');
    this.detailColumnsText = _sh.trimEnd(this.detailColumnsText, '}');
    this.detailColumnsText = _sh.trim(this.detailColumnsText);
  }

  createNotification(type: string, title: string, content: string): void {
    this.notification.create(
      type,
      title,
      content
    );
  }

  doCopyJson() {
    let result = JSON.stringify({
      'detailColumns': this.detailColumns
    });
    result = _sh.trimStart(result, '{');
    result = _sh.trimEnd(result, '}');
    this.clipboardService.copyFromContent(result);
    this.createNotification('success', '成功复制到剪切板，Ctrl+V 即可使用。', 'detailColumns');
  }

  deleteColumns(oneForm) {
    if (this.selectMore(oneForm.columns, true) >= 1) {
      const selectList = _.filter(oneForm.columns, (item) => {
        return item['checked'];
      });
      oneForm.columns = _.without(oneForm.columns, selectList);
      _.each(selectList, oneColumn => {
        oneForm.columns = _.without(oneForm.columns, oneColumn);
      })
    }
  }

  createForm() {
    let template = {
      'tablename': 'system_user',
      'formname': 'system_user',
      'title': '用户管理',
      'type': 'normal',
      'add_link': null,
      'modify_link': null,
      'columns': []
    }
    this.detailColumns.push(template);
  }


  doSave() {
    this.confirmModal.show('confirm', {
      title: '是否需要保存，如果原文件存在会覆盖源文件。',
      suc: () => {
        let temp = _sh.cloneDeep(this.detailColumns);
        this.makeUp(temp);
        this.mainJson['detailColumns'] = this.detailColumns;
        this.saveFile(this.mainJson, this.searchId)
      }
    });
  }

  selectMore(pListData, multiple?): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });

    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }


  doRefresh() {
    this.confirmModal.show('confirm', {
      title: '是否需要刷新，所有的数据将不会保存。',
      suc: () => {
        this.ngOnInit();
      }
    });
  }

  saveFile(jsonObject, filePath) {
    this.hulkconfigserviceservice.saveFile(jsonObject, filePath);
  }

  // 自动生成详情页json
  async autoDetailJson(oneForm) {
    if (!this.utils.Base_HasValue(oneForm['modify_link'])) {
      this.confirmModal.show('error', {title: '需要先填写，修改查询的Json的路径。'});
    } else {
      this.confirmModal.show('confirm', {
        title: '是否需要保存，如果原文件存在会覆盖源文件。',
        suc: () => {
          let tablename = oneForm.tablename;
          this.hulkconfigserviceservice.saveStandardFile(tablename, oneForm['modify_link']);
        }
      });
    }

  }

  createNewCloumn(oneForm) {
    let thatOneForm = oneForm;
    let that = this;
    this.requestDataService.getTableColumnsThisPf(oneForm.tablename).subscribe(rep => {
      if (this.utils.Base_HasValue(rep)) {
        let headTitle = [
          {
            key: 'COLUMN_NAME',
            value: '字段名称',
            width: '50px'
          }, {
            key: 'COLUMN_COMMENT',
            value: '描述',
            width: '100px'
          }
        ];
        let tableData = rep;
        let checkData = [];
        _.each(tableData, (item) => {
          let findData = _.find(checkData, (oneData) => {
            return oneData['column'] === item['COLUMN_NAME'];
          });
          if (this.utils.Base_HasValue(findData)) {
            item['checked'] = true;
          }
        });
        this.modalService.create({
          nzTitle: '选择字段',
          nzContent: TableModalComponent,
          nzWidth: '800',
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            headTitle: headTitle,
            tableData: tableData,
            nzScroll: '800px'
          },
          nzOnOk: (response) => {
            let selectTableData = [];
            selectTableData = _.filter(response.tableData, (item) => {
                return item['checked'];
              }
            );
            _sh.each(selectTableData, oneColumn => {
              let obj = that.createOneColumn(oneColumn);
              thatOneForm.columns.push(obj)
            })
          }
        })
      } else {
        this.confirmModal.show('warning', {
          title: '数据库或表不存在',
        });
      }
    });

  }

  createOneColumn(oneColumn) {
    let result = {};
    let find = false;
    if (_sh.get(oneColumn, ['COLUMN_KEY']) == 'PRI') {
      find = true;
      result = {
        'column': oneColumn['COLUMN_NAME'],
        'name': oneColumn['COLUMN_NAME'] + '_NAME',
        'iscondition': true,
        'pk_column': true,
        'component': 'input',
        'lable': oneColumn['COLUMN_COMMENT'],
        'comment': oneColumn['COLUMN_COMMENT'],
        'placeholder': '请输入' + oneColumn['COLUMN_COMMENT'],
        'insertValue': "SYS-ACTION-UUID",
        'model': null,
        'visible': false,
        'require': false
      };
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'ADD_USERID') {
      find = true;
      result = {
        'column': 'ADD_USERID',
        'name': 'ADD_USERID_NAME',
        'component': 'input',
        'lable': '添加人',
        'comment': '添加人',
        'placeholder': '添加人',
        'insertValue': 'SYS_USER_ID',
        'model': null,
        'visible': false,
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'add'
        ]
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'ADD_NAME') {
      find = true;
      result = {
        'column': 'ADD_NAME',
        'name': 'ADD_NAME_NAME',
        'component': 'input',
        'lable': '添加人',
        'comment': '添加人',
        'placeholder': '添加人',
        'insertValue': 'SYS_USER_NAME',
        'model': null,
        'visible': false,
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'add'
        ]
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'ADD_TIME') {
      find = true;
      result = {
        'column': 'ADD_TIME',
        'name': 'ADD_TIME_NAME',
        'component': 'input',
        'lable': '添加时间',
        'comment': '添加时间',
        'placeholder': '添加时间',
        'model': null,
        'visible': false,
        'insertValue': 'SYS-DATETIME',
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'add'
        ]
      }
    }


    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'UPD_USERID') {
      find = true;
      result = {
        "column": "UPD_USERID",
        "name": "UPD_USERID_NAME",
        "component": "input",
        "lable": "修改人",
        "comment": "修改人",
        "placeholder": "修改人",
        "insertValue": "SYS_USER_ID",
        "model": null,
        "visible": false,
        "disable": [
          "add",
          "modify"
        ],
        "commitPoint": [
          "modify"
        ]
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'UPD_TIME') {
      find = true;
      result = {
        'column': 'UPD_TIME',
        'name': 'UPD_TIME_NAME',
        'component': 'input',
        'lable': '更新时间',
        'comment': '更新时间',
        'placeholder': '更新时间',
        'insertValue': 'SYS-DATETIME',
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'modify'
        ],
        'model': null,
        'visible': false
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'UPD_NAME') {
      find = true;
      result = {
        'column': 'UPD_NAME',
        'name': 'UPD_NAME_NAME',
        'component': 'input',
        'lable': '修改人',
        'comment': '修改人',
        'placeholder': '修改人',
        'insertValue': 'SYS_USER_NAME',
        'model': null,
        'visible': false,
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'modify'
        ]
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'UPD_TIME') {
      find = true;
      result = {
        'column': 'UPD_TIME',
        'name': 'UPD_TIME_NAME',
        'component': 'input',
        'lable': '更新时间',
        'comment': '更新时间',
        'placeholder': '更新时间',
        'insertValue': 'SYS-DATETIME',
        'disable': [
          'add',
          'modify'
        ],
        'commitPoint': [
          'modify'
        ],
        'model': null,
        'visible': false
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'U_DELETE') {
      find = true;
      result = {
        'column': 'U_DELETE',
        'name': 'U_DELETE_NAME',
        'component': 'input',
        'lable': '删除标识',
        'comment': '删除标识',
        'placeholder': '删除标识',
        'model': 1,
        'insertValue': '1',
        'visible': false
      }
    }

    if (_sh.get(oneColumn, ['COLUMN_NAME']) == 'U_TIME') {
      find = true;
      result = {
        'column': 'U_TIME',
        'name': 'U_TIME_NAME',
        'component': 'input',
        'lable': '更新时间',
        'comment': '更新时间',
        'insertValue': 'SYS-DATETIME',
        'model': null,
        'visible': false
      }
    }

    if (!find && _sh.get(oneColumn, ['DATA_TYPE']) == 'datetime') {
      find = true;
      result = {
        'column': oneColumn['COLUMN_NAME'],
        'name': oneColumn['COLUMN_NAME'] + '_NAME',
        'component': 'datetime',
        'lable': oneColumn['COLUMN_COMMENT'],
        'comment': oneColumn['COLUMN_COMMENT'],
        'placeholder': '请输入' + oneColumn['COLUMN_COMMENT'],
        'insertValue': 'SYS-DATETIME',
        'model': null,
        'visible': false
      }
    }

    if (!find && _sh.get(oneColumn, ['DATA_TYPE']) == 'date') {
      find = true;
      result = {
        'column': oneColumn['COLUMN_NAME'],
        'name': oneColumn['COLUMN_NAME'] + '_NAME',
        'component': 'date',
        'lable': oneColumn['COLUMN_COMMENT'],
        'comment': oneColumn['COLUMN_COMMENT'],
        'placeholder': '请输入' + oneColumn['COLUMN_COMMENT'],
        'insertValue': 'SYS-DATETIME',
        'model': null,
        'visible': false
      }
    }

    if (!find && _sh.get(oneColumn, ['DATA_TYPE']) == 'datetime') {
      find = true;
      result = {
        'column': oneColumn['COLUMN_NAME'],
        'name': oneColumn['COLUMN_NAME'] + '_NAME',
        'component': 'datetime',
        'lable': oneColumn['COLUMN_COMMENT'],
        'comment': oneColumn['COLUMN_COMMENT'],
        'placeholder': '请输入' + oneColumn['COLUMN_COMMENT'],
        'insertValue': 'SYS-DATETIME',
        'model': null,
        'visible': false
      }
    }

    if (!find) {
      result = {
        'column': oneColumn['COLUMN_NAME'],
        'name': oneColumn['COLUMN_NAME'] + '_NAME',
        'component': 'input',
        'lable': oneColumn['COLUMN_COMMENT'],
        'comment': oneColumn['COLUMN_COMMENT'],
        'placeholder': '请输入' + oneColumn['COLUMN_COMMENT'],
        'insertValue': null,
        'model': null,
        'visible': true
      };
    }
    result['require'] = oneColumn['IS_NULLABLE'] == 'NO';
    return result;
  }

  createAllNewCloumn(oneForm) {
    this.requestDataService.getTableColumnsThisPf(oneForm.tablename).subscribe(rep => {
      if (this.utils.Base_HasValue(rep)) {
        _.each(rep, oneColumn => {
          let index = _sh.findIndex(oneForm['columns'], function (o) {
            return o.column == oneColumn['COLUMN_NAME'];
          });
          if (index == -1) {
            let obj = this.createOneColumn(oneColumn);
            oneForm['columns'].push(obj);
          }
        })
      } else {
        this.confirmModal.show('warning', {
          title: '数据库或表不存在',
        });
      }
    });

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.searchId = _sh.get(res, ['searchId']);
      this.requestDataService.getDataByJson(this.searchId).subscribe(jsons => {
        this.mainJson = jsons;
        this.sqlFilePath = _sh.get(jsons, 'sqlFilePath', null);
        this.detailColumns = _sh.get(jsons, 'detailColumns', []);
        _.each(this.detailColumns, oneForm => {
          oneForm.allChecked = false;
          oneForm.indeterminate = false;
        })
      });

    });

  }

}
