import {Component, HostListener, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {DataCenterService} from './../../service/data-center.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import {UtilsService} from '../../service/utils.service';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.css']
})
export class CustomFormComponent implements OnInit {

  @ViewChildren('detail') detail;

  public custormFormType: any;
  //父级组件的数据对象
  @Input() parentData: any;

  // 监听自定义详情页面backspace 返回到上一页
  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    // backspace
    if (event.keyCode === 8) {
      const nowMenu = this.dataCenterService._dataObject.nowMenu;
      _.each(this.detail['_results'], (form: any) => {
        const formNowMenu = form['parentData'].nowMenu;
        if (nowMenu['MENU_ID'] === formNowMenu['MENU_ID']) {
          if (_.isFunction(form.gotoBack)) {
            form.gotoBack();
          }
        }
      });
    }
  }

  constructor(private dataCenterService: DataCenterService,
              private util: UtilsService) {
  }

  ngOnInit() {
    // 如果是按钮名称的点击进入自定义页面
    // else 是菜单点击进入自定义页面
    if (!this.util.Base_HasValue(this.parentData['custormFormType'])) {
      if (this.parentData.btnName) {
        this.getCustormFormTypeByBtn(this.parentData['btnName']);
      } else {
        this.getCustormFormType(this.parentData['nowMenu']);
      }
    }
  }


  getCustormFormTypeByBtn(btnName) {
    switch (btnName) {
      case 'dataSetInsert':
        this.parentData.custormFormType = 'app-dataset-insert';
        break;
      case 'dataSetModify':
        this.parentData.custormFormType = 'app-dataset-insert';
        break;
      case 'dataverseInsert':
        this.parentData.custormFormType = 'app-dataverse-insert';
        break;
      case 'dataverseModify':
        this.parentData.custormFormType = 'app-dataverse-insert';
        break;
      case 'modelInsert':
        this.parentData.custormFormType = 'app-model-insert';
        break;
      case 'editModel':
        this.parentData.custormFormType = 'app-model-insert';
        break;
      case 'dataset.detail':
        this.parentData.custormFormType = 'app-dataset-detail';
        break;
      case 'dataverse.detail':
        this.parentData.custormFormType = 'app-dataverse-detail';
        break;
      case 'model.detail':
        this.parentData.custormFormType = 'app-model-detail';
        break;
      case 'dataversePermission':
        this.parentData.custormFormType = 'app-dataverse-permission';
        break;
      case 'datasetPermission':
        this.parentData.custormFormType = 'app-dataset-permission';
        break;
      case 'filePower.detail':
        this.parentData.custormFormType = 'app-apply-file-detail';
        break;
      case 'appAutoJson':
        this.parentData.custormFormType = 'app-auto-json';
        break;
      case 'cnResume':
        this.parentData.custormFormType = 'app-cn-resume';
        break;
      case 'enResume':
        this.parentData.custormFormType = 'app-en-resume';
        break;
      case 'custplanModify':
        this.parentData.custormFormType = 'app-custplan-modal';
        break;
      case 'editNews':
        this.parentData.custormFormType = 'app-edit-news';
        break;
      case 'modelManagement':
        this.parentData.custormFormType = 'app-model-management';
        break;
      case 'drModel.detail':
        this.parentData.custormFormType = 'app-dr-model-detail';
        break;
      case 'addGrouping':
        this.parentData.custormFormType = 'app-resource-group-management';
        break;
      case 'editGrouping':
        this.parentData.custormFormType = 'app-resource-group-management';
        break;
      case 'groupDescripition':
        this.parentData.custormFormType = 'app-grouping-edit';
        break;
      case 'addWord':
        this.parentData.custormFormType = 'app-word-add-edit';
        break;
      case 'editWord':
        this.parentData.custormFormType = 'app-word-add-edit';
        break;
      case 'groupingDetail':
        this.parentData.custormFormType = 'app-resource-group-detail';
        break;
      case 'wordDetail':
        this.parentData.custormFormType = 'app-word-detail';
        break;
      case 'userGroupAdd':
        this.parentData.custormFormType = 'app-user-group-management';
        break;
      case 'userGroupEdit':
        this.parentData.custormFormType = 'app-user-group-management';
        break;
      case 'userGroup.detail':
        this.parentData.modeType = 'detail';
        this.parentData.custormFormType = 'app-user-group-management';
        break;
      case 'managementUserGroup':
        this.parentData.custormFormType = 'app-group-user-management';
        break;
      case 'distributionUserGroup':
        this.parentData.custormFormType = 'app-distribution-user-group';
        break;
      case 'editReport':
        this.parentData.custormFormType = 'app-report-management';
        break;
      case 'wagonFormation':
        this.parentData.custormFormType = 'app-wagon-formation';
        break;
      case 'warehouseInput':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-storage';
        break;
      case 'outboundInput':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-outbound';
        break;
      case 'rolloverOut':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-rollover';
        break;
      case 'inventoryInput':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-inventory';
        break;
      case 'incomeInput':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-income';
        break;
      case 'disbursementInput':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-disbursement';
        break;
      case 'newlyBuild':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-sku';
        break;
      case 'inputService':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-input-service';
        break;
      case 'updateService':
        this.parentData.modeType = 'modify';
        this.parentData.custormFormType = 'app-input-service';
        break;
      case 'customerStatement':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-customer-statements';
        break;
      case 'esOrderDetail':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-es-order-detail';
        break;
      case 'esOrderFiles':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-es-order-files';
        break;
      case 'addWordTemplate':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-word-template';
        break;
      case 'modifyWordTemplate':
        this.parentData.modeType = 'modify';
        this.parentData.custormFormType = 'app-word-template';
        break;
      case 'addIgWordTemplate':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-ig-word-template';
        break;
      case 'modifyIgWordTemplate':
        this.parentData.modeType = 'modify';
        this.parentData.custormFormType = 'app-ig-word-template';
        break;
      case 'addWorkflow':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-workflow-management';
        break;
      case 'modifyWorkflow':
        this.parentData.modeType = 'modify';
        this.parentData.custormFormType = 'app-workflow-management';
        break;
      case 'editApproval':
        this.parentData.custormFormType = 'app-edit-approval';
        break;
      case 'createOrderAndQRcode':
        this.parentData.custormFormType = 'app-pay-form';
        break;
      case 'SEAT_MANAGEMENT':
        this.parentData.modeType = 'add';
        this.parentData.custormFormType = 'app-sale-seat';
        break;
      default:
        let actionValueArray = _sh.split(btnName,".");
        const actionValueType = actionValueArray[0],
          modeType = actionValueArray[1],
          custormFormType = actionValueArray[2];
        if (actionValueType == 'systemCustom') {
          this.parentData.modeType = modeType;
          this.parentData.custormFormType = custormFormType;
        }

    }
  }

  /**
   * 响应页面来自于菜单。
   * @param pMenuObj
   */
  getCustormFormType(pMenuObj): void {
    switch (pMenuObj.MENU_URL) {
      case 'index.presonal':
        this.parentData.custormFormType = 'app-parsonal-center';
        break;
      case 'index.menuToBtn':
        this.parentData.custormFormType = 'app-menu-btn';
        break;
      case 'index.permissionSet':
        this.parentData.custormFormType = 'app-role-menu';
        break;
      case 'index.loginRoleMenu':
        this.parentData.custormFormType = 'app-login-role-menu';
        break;
      case 'index.userPower':
        this.parentData.custormFormType = 'app-user-role';
        break;
      case 'index.roleCust':
        this.parentData.custormFormType = 'app-role-cust';
        break;
      case 'site.report':
        this.parentData.custormFormType = 'app-site-report';
        break;
      case 'visit.report':
        this.parentData.custormFormType = 'app-visit-report';
        break;
      case 'sitecollect.report':
        this.parentData.custormFormType = 'app-collect-report';
        break;
      case 'scriptgrab.report':
        this.parentData.custormFormType = 'app-grab-report';
        break;
      case 'day.release':
        this.parentData.custormFormType = 'app-day-release';
        break;
      case 'grab.release':
        this.parentData.custormFormType = 'app-grab-release';
        break;
      case 'index.preRelease':
        this.parentData.custormFormType = 'app-pre-release';
        break;
      case 'index.siteRelease':
        this.parentData.custormFormType = 'app-site-release';
        break;
      case 'library.info':
        this.parentData.custormFormType = 'app-library-info';
        break;
      case 'visual.report':
        this.parentData.custormFormType = 'app-visual-report';
        break;
      case 'index.autoJson':
        this.parentData.custormFormType = 'app-auto-json';
        break;
      case 'index.resume.list':
        this.parentData.custormFormType = 'app-resume';
        break;
      case 'operate.table':
        this.parentData.custormFormType = 'app-operate-table';
        break;
      case 'index.fryCockpit':
        this.parentData.custormFormType = 'app-fury-cockpit';
        break;
      case 'index.futureHighSpeed':
        this.parentData.custormFormType = 'app-future-high-speed';
        break;
      case 'index.futureSignalList':
        this.parentData.custormFormType = 'app-future-signal-list';
        break;
      case 'index.reportTeacher':
        this.parentData.custormFormType = 'app-report-teacher';
        break;
      case 'index.numberOfDoctoral':
        this.parentData.custormFormType = 'app-number-of-doctoral';
        break;
      case 'index.graduatePrograms':
        this.parentData.custormFormType = 'app-graduate-programs';
        break;
      case 'index.numberOfMaster':
        this.parentData.custormFormType = 'app-number-of-master';
        break;
      case 'index.communicationDoctoral':
        this.parentData.custormFormType = 'app-communication-doctoral';
        break;
      case 'index.graduateJobUnit':
        this.parentData.custormFormType = 'app-graduate-job-unit';
        break;
      case 'index.communicationMaster':
        this.parentData.custormFormType = 'app-communication-master';
        break;
      case 'index.communicationForeigners':
        this.parentData.custormFormType = 'app-communication-foreigners';
        break;
      case 'index.graduateEmployment':
        this.parentData.custormFormType = 'app-graduate-employment';
        break;
      case 'index.researchResults':
        this.parentData.custormFormType = 'app-research-results';
        break;
      case 'index.researchOutlay':
        this.parentData.custormFormType = 'app-research-outlay';
        break;
      case 'index.instrumentEquipment':
        this.parentData.custormFormType = 'app-instrument-equipment';
        break;
      case 'index.paper':
        this.parentData.custormFormType = 'app-paper';
        break;
      case 'index.studentAidInfo':
        this.parentData.custormFormType = 'app-student-aid-info';
        break;
      case 'index.teachingAward':
        this.parentData.custormFormType = 'app-teaching-award';
        break;
      case 'index.academicExchange':
        this.parentData.custormFormType = 'app-academic-exchange';
        break;
      case 'index.scienceInnovation':
        this.parentData.custormFormType = 'app-science-innovation';
        break;
      case 'index.subjectTeacher':
        this.parentData.custormFormType = 'app-subject-teacher';
        break;
      case 'index.studentAchievement':
        this.parentData.custormFormType = 'app-student-achievement';
        break;
      case 'index.studentsExam':
        this.parentData.custormFormType = 'app-students-exam';
        break;
      case 'index.teacherInformation':
        this.parentData.custormFormType = 'app-teacher-information';
        break;
      case 'index.teachingInfrastructure':
        this.parentData.custormFormType = 'app-teaching-infrastructure';
        break;
      case 'index.graduatePractice':
        this.parentData.custormFormType = 'app-graduate-practice';
        break;
      case 'index.subjectVisualReportSet':
        this.parentData.custormFormType = 'app-subject-visual-report-set';
        break;
      case 'index.subjectVisualReport':
        this.parentData.custormFormType = 'app-subject-visual-report';
        break;
      case 'index.academicCommunication':
        this.parentData.custormFormType = 'app-academic-communication';
        break;
      case 'index.resumeChart':
        this.parentData.custormFormType = 'app-resume-chart';
        break;
      case 'index.channel':
        this.parentData.custormFormType = 'app-channel';
        break;
      case 'index.picture':
        this.parentData.custormFormType = 'app-picture-list';
        break;
      case 'index.grouping':
        this.parentData.custormFormType = 'app-grouping-list';
        break;
      case 'index.word':
        this.parentData.custormFormType = 'app-word-list';
        break;
      case 'index.knowledgeMap':
        this.parentData.custormFormType = 'app-knowledge-map-management';
        break;
      case 'index.digitalVisualReport':
        this.parentData.custormFormType = 'app-digital-visual-report';
        break;
      case 'index.placeDay':
        this.parentData.custormFormType = 'app-place-use-day';
        break;
      case 'index.inputWagon':
        this.parentData.custormFormType = 'app-input-wagon';
        break;
      case 'index.inputWagonNew':
        this.parentData.custormFormType = 'app-input-wagon-new';
        break;
      case 'index.assetMap':
        this.parentData.custormFormType = 'app-asset-map';
        break;
      case 'index.assetControlMap':
        this.parentData.custormFormType = 'app-asset-control-map';
        break;
      case 'index.wagonFormation':
        this.parentData.custormFormType = 'app-wagon-formation';
        break;
      case 'index.dashboardOne':
        this.parentData.custormFormType = 'app-dashboard-one';
        break;
      case 'index.assetDashboardOne':
        this.parentData.custormFormType = 'app-asset-dashboard-one';
        break;
      case 'index.assetMainMapin':
        this.parentData.custormFormType = 'app-asset-main-map';
        break;
      case 'index.inputBid':
        this.parentData.custormFormType = 'app-input-bid';
        break;
      case 'index.rankBid':
        this.parentData.custormFormType = 'app-rank-bid';
        break;
      case 'index.rankBidAdmin':
        this.parentData.custormFormType = 'app-rank-bid-admin';
        break;
      case 'index.acSettlement':
        this.parentData.custormFormType = 'app-ac-settlement';
        break;
      case 'index.hulkFileCenter':
        this.parentData.custormFormType = 'app-hulk-config-file-center';
        break;
      case 'index.editApproval':
        this.parentData.custormFormType = 'app-edit-approval';
        break;
      case 'index.briefingPreview':
        this.parentData.custormFormType = 'app-briefing-preview';
        break;
      case 'index.aiocr':
        this.parentData.custormFormType = 'app-aiocr';
        break;
      case 'index.seatView':
        this.parentData.custormFormType = 'app-seat-view';
        break;
    }
  }
}
