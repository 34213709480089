import {Injectable} from '@angular/core';
import {JieliService} from "../project/jieli/jieli.service";
import * as _sh from 'lodash';
import {ConfirmModalService} from "./confirm-modal.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceCenterService {

  constructor(private jieliService: JieliService,
              private confirmModal: ConfirmModalService,
  ) {
  }

  doChangeListAction(pForms, pExecList, pServicePath) {
    let serviceArray = _sh.split(pServicePath, '.');
    let serviceName = _sh.get(serviceArray, [0], null);
    let methodName = _sh.get(serviceArray, [1], null);


    if (serviceName == 'jieliService') {
      if (methodName == 'getService') {
        this.jieliService.getService(pForms, pExecList);
      } else if (methodName == 'splitOrders') {
        return this.jieliService.splitOrders(pForms);
      } else {
        this.confirmModal.show('error', {title: methodName + '：serviceName 不存在'});
      }
    } else {
      this.confirmModal.show('error', {title: serviceName + '：methodName 不存在'});
    }
  }
}
