import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import PubSub from 'pubsub-js';
import * as moment from 'moment';
import {forkJoin as observableForkJoin, forkJoin} from "rxjs";
@Component({
  selector: 'app-pay-form',
  templateUrl: './pay-form.component.html',
  styleUrls: ['./pay-form.component.scss']
})
export class PayFormComponent implements OnInit {
  @Input() parentData: any;
  constructor(
    private requestService: RequestDataService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
  ) { }

  showPay:any = false;
  out_trade_no: any = null;
  description: any = null;
  total: any = 1;
  selectData: any = [];
  payData: any = [];
  payWay: any = "wx";
  modeType: any = "";
  buttonLabel: any = "开通席位缴费";

  ngOnInit() {
    let btn = _sh.filter(this.parentData.actionArray,function (e){
      return e.ACTION_VALUE === 'createOrderAndQRcode'
    })
    this.requestService.getDataByJson(btn[0].ACTION_OPERATE).subscribe(res=>{
      this.modeType = res.modeType;
      this.buttonLabel = res.buttonLabel;
    })
  }

  doCancel() {
    this.showPay = false
  }

  doOk() {
      let query = {
        out_trade_no: this.out_trade_no,
        wxPf: "meetingWxPF"
      }
      this.requestService.doActionByHdbsthor("AppApi/WxNativePaymentController","queryWxPaymentOrder",query,"",null,"hdbsthor_login_center","appToken").subscribe(res=>{
        if(res.trade_state === 'SUCCESS'){
          let query = {
            out_trade_no: this.out_trade_no
          }
          if(this.modeType === 'hotel'){
            this.requestService.doActionByHdbsthor("AppApi/MsHotelController","hotelPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
              if(res === true){
                this.showPay = false;
                let menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
                if (this.utils.Base_HasValue(menuId)) {
                  PubSub.publish('listRefresh', menuId);
                }
              }else {
                if(res.status === 500){
                  this.confirmModal.show('error', {'title': '执行错误','content':res.message});
                }
              }
            })
          }
          if(this.modeType === 'saleSeat'){
            this.requestService.doActionByHdbsthor("AppApi/MsHotelController","saleSeatPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
              if(res === true){
                this.showPay = false;
                let menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
                if (this.utils.Base_HasValue(menuId)) {
                  PubSub.publish('listRefresh', menuId);
                }
              }else {
                if(res.status === 500){
                  this.confirmModal.show('error', {'title': '执行错误','content':res.message});
                }
              }
            })
          }
          if(this.modeType === 'company'){
            this.requestService.doActionByHdbsthor("AppApi/MsHotelController","companyPayCheck",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
              if(res === true){
                this.showPay = false;
                let menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
                if (this.utils.Base_HasValue(menuId)) {
                  PubSub.publish('listRefresh', menuId);
                }
              }else {
                if(res.status === 500){
                  this.confirmModal.show('error', {'title': '执行错误','content':res.message});
                }
              }
            })
          }

        }else if(res.trade_state === 'NOTPAY'){

        }else{
          this.confirmModal.show('error', {'title': '执行错误','content':res.message});
        }
      })

  }

  async doShow() {
    let check = true;
    this.selectData = _sh.filter(this.parentData.data.data,function (e){
      return e.checked == true;
    })
    if(!this.utils.Base_HasValue(this.selectData)){
      this.confirmModal.show('warning',{'title':'至少选择一条数据！'})
      check = false;
    }
    if(this.utils.Base_HasValue(this.selectData) && this.selectData.length>1){
      this.confirmModal.show('warning',{'title':'只能选取一条数据！'})
      check = false;
    }
    let type = '';
    if(this.modeType === 'hotel'){
      type = 'HOTEL'
    }
    else if(this.modeType === 'company'){
      type = 'COMPANY'
    }
    await observableForkJoin(
      this.requestService.getDataByCondition("meeting/hotel/mt_pay_number.json",{'PAYMENT_TYPE':type,'PAYMENT_TARGET_ID':this.selectData[0].ID}),//查询今年购买数量
      this.requestService.getDataByCondition("meeting/hotel/mt_pricing_management_list.json",{})//查询定价信息
    ).subscribe(async res=>{
        const account = _sh.get(res,[0,'data',0,'ACCOUNT'],0);
        const princeInfo = _sh.get(res,[1,'data',0],[]);
        if(!this.utils.Base_HasValue(princeInfo)){
          this.confirmModal.show('error',{title:"未找到定价信息"});
          check = false;
        }
        if(this.modeType === 'hotel' && account>=princeInfo['PRICING_IN_THE_BIDDING_HALL_COUNT']){
          this.confirmModal.show('error',{title:"今年开通次数已达上限"})
          check = false
        }
        if(this.modeType === 'company' && account>=princeInfo['COMMUNICATION_SEAT_PRICING_COUNT']){
          this.confirmModal.show('error',{title:"今年开通次数已达上限"})
          check = false
        }
      if(check){
        this.requestService.getDataByCondition("meeting/hotel/mt_pricing_management_list.json",{}).subscribe(res=>{
          let checked = true;
          if(this.modeType === 'hotel'){
            this.total = _sh.get(res,['data',0,'PRICING_IN_THE_BIDDING_HALL'],null);
          }
          if(this.modeType === 'saleSeat'){
            this.total = _sh.get(res,['data',0,'SALES_SEAT_PRICING'],null);
          }
          if(this.modeType === 'company'){
            this.total = _sh.get(res,['data',0,'COMMUNICATION_SEAT_PRICING'],null);
          }
          if(!this.utils.Base_HasValue(this.total)){
            this.confirmModal.show("warning",{title:"未找到定价请联系管理员"});
            checked = false;
          }
          if(checked){
            let query = {
              hotelId: this.selectData[0].ID,
              total: this.total,
              orderType: this.modeType
            }
            this.requestService.doActionByHdbsthor("AppApi/MsHotelController","createPayOrderLog",query,"",null,"hdbsthor_meeting","appToken").subscribe(res=>{
              this.out_trade_no = res.id
              if(this.modeType === 'hotel'){
                this.description = this.selectData[0].HOTEL_NAME + "--投标年费";
              }
              if(this.modeType === 'saleSeat'){
                this.description = this.selectData[0].HOTEL_NAME + "--销售年费";
              }
              if(this.modeType === 'company'){
                this.description = this.selectData[0].CORPORATE_NAME + "--沟通席位";
              }
              let query = {
                wxPf: "meetingWxPF",
                out_trade_no: this.out_trade_no,
                description: this.description,
                total: this.total*100
              }
              this.requestService.doActionByHdbsthor("AppApi/WxNativePaymentController","placeNativeOrder",query,"",null,"hdbsthor_login_center","appToken").subscribe(res=>{
                if(this.utils.Base_HasValue(res)){
                  this.payData = res;
                  this.showPay = true
                }
              })
            })
          }
        })
      }
    })

  }

  searchPrice(){
    this.requestService.getDataByCondition("meeting/hotel/mt_pricing_management_list.json",{}).subscribe(res=>{
      if(this.modeType === 'hotel'){
        this.total = _sh.get(res,['data',0,'PRICING_IN_THE_BIDDING_HALL'],0.01);
      }
      if(this.modeType === 'saleSeat'){
        this.total = _sh.get(res,['data',0,'SALES_SEAT_PRICING'],0.01);
      }
      if(this.modeType === 'company'){
        this.total = _sh.get(res,['data',0,'COMMUNICATION_SEAT_PRICING'],0.01);
      }
    })
  }

  changePayWay() {
  }

}
