import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, AfterViewInit} from '@angular/core';
import {RequestDataService} from './../../../service/request-data.service';
import {UtilsService} from './../../../service/utils.service';
import * as echarts from 'echarts';
import * as _ from 'underscore';
import {UserIdentity} from './../../../enum/user-identity.enum';
import * as moment from 'moment';
import * as screenfull from 'screenfull';

@Component({
  selector: 'app-visual-report',
  templateUrl: './visual-report.component.html',
  styleUrls: ['./visual-report.component.css']
})
export class VisualReportComponent implements OnInit {

  UserIdentity: UserIdentity;
  // 统计总数
  public countData: any = [
    {account: 0},
    {account: 0},
    {account: 0},
    {account: 0},
    {account: 0}
  ];
  // 7日
  public category: any = [];
  // 馆藏信息
  public libData: any = {};
  // 注册比例
  public registerChartOption: any = {};
  // 每日浏览 下载情况
  public viewDownloadOption: any = {};
  // 数据集总量TOP5
  public datasetOption: any = {};
  // 数据集下载总量TOP5
  public downloadOption: any = {};
  // 年度添加数量趋势
  public yearOption: any = {};
  // 年度
  public years: any = [];

  public isfull: any = false;

  public registerHeight: any = '340px';
  public viewDownloadHeight: any = '280px';
  public datasetHeight: any = '203px';


  // 词云
  public wordCloudChart: any = {};
  public wordCloudData: any = [];

  constructor(private requestData: RequestDataService,
              private utils: UtilsService) {

  }

  getFileSize(size) {
    return this.utils.conver(size * 1024 * 1024);
  }

  fullscreen() {
    const el = document.getElementById('target');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
        if (this.isfull) {
          const height = document.documentElement.clientHeight;
          this.viewDownloadHeight = (height * 0.28).toFixed(2) + 'px';
          this.datasetHeight = (height * 0.23).toFixed(2) + 'px';
          this.registerHeight = +((height * 0.51).toFixed(2)) - 144 + 'px';
        } else {
          this.registerHeight = '340px';
          this.viewDownloadHeight = '280px';
          this.datasetHeight = '203px';
        }
        setTimeout(() => {
          this.wordCloudChart.setOption(this.setWordsCloudOption(this.wordCloudData));
          this.refreshWordCloudOption();
        }, 10);
      });
    }
  }

  // 封装注册比例数据
  transformRegisterData(data) {
    const obj = {};
    const groupData = _.groupBy(data, item => item['IDENTITY']);
    _.each(Object.keys(UserIdentity), key => {
      obj[key] = groupData[key] ? (groupData[key][0]['account'] || 0) : 0;
    });
    return obj;
  }

  setRegisterOption(data) {
    const obj = this.transformRegisterData(data);
    const option = {
      grid: {
        top: '4%',
        bottom: '2%',
        left: '2%',
        right: '2%'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      legend: {
        x: 'center',
        y: 'bottom',
        data: [{
          name: '导师',
          textStyle: {
            fontSize: 12,
            fontWeight: 'bolder',
            color: '#333'
          },
          icon: 'circle'
        }, {
          name: '学生',
          textStyle: {
            fontSize: 12,
            fontWeight: 'bolder',
            color: '#333'
          },
          icon: 'circle'
        }, {
          name: '研究人员',
          textStyle: {
            fontSize: 12,
            fontWeight: 'bolder',
            color: '#333'
          },
          icon: 'circle'
        }, {
          name: '教授',
          textStyle: {
            fontSize: 12,
            fontWeight: 'bolder',
            color: '#333'
          },
          icon: 'circle'
        }]
      },
      series: [
        {
          name: '注册比例',
          type: 'pie',
          radius: ['25%', '50%'],
          roseType: 'radius',
          label: {
            normal: {
              show: true,
              formatter: '{b}\n{c}({d}%)'
            },
            emphasis: {
              show: true
            }
          },
          lableLine: {
            normal: {
              show: false
            },
            emphasis: {
              show: true
            }
          },
          data: [
            {
              value: obj[UserIdentity.TUTOR], name: '导师', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#0bb2f2' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#293bf5' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            },
            {
              value: obj[UserIdentity.STUDENT], name: '学生', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#ffba63' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#ff8400' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            },
            {
              value: obj[UserIdentity.RESEARCHERS], name: '研究人员', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#6ff7ff' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#00e3ff' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            },
            {
              value: obj[UserIdentity.PROFESSOR], name: '教授', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#b55bfd' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#5400d7' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            }
          ]
        }
      ]
    };
    return option;
  }


  // 获取7天的起始日期
  getSearchData() {
    const today = moment().format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(7 - 1, 'days').format('YYYY-MM-DD');
    for (let i = 7 - 1; i >= 0; i--) {
      category.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return {
      searchData: data,
      category: category
    };
  }

  // 封装下载浏览数据
  transformViewDownloadData(viewList, downloadList) {
    const getList = (list) => {
      const obj = _.groupBy(list, item => item['SELECT_DATE']);
      const result = [];
      _.each(this.category, (cate: string) => {
        result.push(obj[cate] ? (obj[cate][0]['TOTAL'] || 0) : 0);
      });
      return result;
    };
    return {
      view: getList(viewList),
      download: getList(downloadList),
      category: this.category
    };
  }

  setViewDownloadOption(viewList, downloadList) {
    const data = this.transformViewDownloadData(viewList, downloadList);
    const option = {
      color: ['#3C1FED', '#ED4D7E'],
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        right: '6%',
        left: '6%',
        top: '4%',
        bottom: '8%'
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          axisPointer: {
            type: 'line'
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#f7f8f8'
            }
          },
          axisLabel: {
            color: '#000000',
            fontWeight: 'bolder',
            fontSize: 14,
            lineHeight: 24,
            formatter: function (name) {
              let val = moment(name).format('MM/DD');
              if (val.startsWith('0')) {
                val = val.replace('0', '');
              }
              return val;
            }
          },
          data: data.category
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          position: 'left',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            formatter: '{value}',
            color: '#3C1FED'
          },
          splitLine: {
            lineStyle: {
              width: 2,
              color: '#f7f8f8'
            }
          }
        },
        {
          type: 'value',
          name: '',
          position: 'right',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            formatter: '{value}',
            color: '#ED4D7E'
          },
          splitLine: {
            show: false,
            lineStyle: {
              width: 2,
              color: '#f7f8f8'
            }
          }
        }
      ],
      series: [
        {
          name: '每日浏览',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0.2, color: '#0da7f1' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#2819f5' // 100% 处的颜色
                }],
              globalCoord: true // 缺省为 false
            }
          },
          data: data.view
        },
        {
          name: '下载情况',
          type: 'line',
          yAxisIndex: 1,
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0.2, color: '#ff5db2' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#ff3783' // 100% 处的颜色
                }],
              globalCoord: true // 缺省为 false
            }
          },
          data: data.download
        }
      ]
    };
    return option;
  }

  // 封装数据集top5
  transformDatasetData(list) {
    let result = {
      category: [],
      value: []
    };
    _.each(list, (item: any) => {
      result.category.push(item.DATAVERSE_NAME);
      result.value.push(item.TOTAL);
    });
    return result;
  }

  setDatasetOption(list) {
    const data = this.transformDatasetData(list);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        right: '5%',
        left: '27%',
        top: '11%',
        bottom: '3%'
      },
      xAxis: {
        show: true,
        position: 'top',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            fontSize: 14,
            color: '#a7a7a7'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#f7f8f8',
            width: 2
          }
        }
      },
      yAxis: {
        type: 'category',
        inverse: true,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#787878',
          margin: 10,
          fontSize: 12,
          formatter: (name) => {
            return echarts.format.truncateText(name, 72, '12px', '…');
          }
        },
        splitLine: {
          show: false
        },
        data: data.category
      },
      series: [
        {
          type: 'bar',
          barWidth: '15px',
          data: data.value,
          label: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              barBorderRadius: 20,
              color: new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  {offset: 0, color: '#74B5EF'},
                  {offset: 1, color: '#6C57EF'}
                ]
              )
            }

          }
        }
      ]
    };
    return option;
  }

  // 封装数据集下载top5
  transformDownloadData(list) {
    let result = {
      category: [],
      value: []
    };
    _.each(list, (item: any) => {
      result.category.push(item.DATAVERSE_NAME);
      result.value.push(item.TOTAL);
    });
    return result;
  }

  setDownloadOption(list) {
    const data = this.transformDownloadData(list);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        right: '3%',
        top: '5%',
        left: '16%',
        bottom: '20%'
      },
      yAxis: {
        show: true,
        position: 'top',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            fontSize: 12,
            color: '#a7a7a7'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#f7f8f8',
            width: 2
          }
        }
      },
      xAxis: {
        type: 'category',
        inverse: true,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#787878',
          fontSize: 12,
          rotate: 40,
          formatter: function (name) {
            return echarts.format.truncateText(name, 47, '12px', '…');
          }
        },
        splitLine: {
          show: false
        },
        data: data.category.reverse()
      },
      series: [
        {
          type: 'bar',
          barWidth: '15px',
          barCategoryGap: '10%',
          data: data.value.reverse(),
          label: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              barBorderRadius: 20,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6C57EF'},
                  {offset: 1, color: '#74B5EF'}
                ]
              )
            }

          }
        }
      ]
    };
    return option;
  }

  // 获取近四年的起始日期
  getSearchYearData() {
    const today = moment().format('YYYY');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'year').format('YYYY');
    for (let i = 4 - 1; i >= 0; i--) {
      years.push(moment().subtract(i, 'year').format('YYYY'));
    }
    return {
      searchData: data,
      years: years
    };
  }

  // 年度添加数量
  transformYearCountData(viewList) {
    const obj = _.groupBy(viewList, item => item['ADD_DATE']);
    const result = [];
    _.each(this.years, (cate: string) => {
      result.push(obj[cate] ? (obj[cate][0]['account'] || 0) : 0);
    });
    return {
      view: result.reverse(),
      category: this.years.reverse()
    };
  }


  setYearOption(list) {
    const data = this.transformYearCountData(list);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: '{b}: {c0}'
      },
      grid: {
        right: '3%',
        top: '5%',
        left: '16%',
        bottom: '20%'
      },
      xAxis: [
        {
          type: 'category',
          inverse: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#787878',
            fontSize: 12,
            rotate: 40,
            formatter: function (name) {
              return echarts.format.truncateText(name, 47, '12px', '…');
            }
          },
          splitLine: {
            show: false
          },
          data: data.category
        }
      ],
      yAxis: [
        {
          show: true,
          position: 'top',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontSize: 12,
              color: '#a7a7a7'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#f7f8f8',
              width: 2
            }
          }
        }
      ],
      series: [
        {
          type: 'bar',
          barWidth: '15px',
          barCategoryGap: '10%',
          itemStyle: {
            normal: {
              barBorderRadius: 20,
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6C57EF'},
                  {offset: 1, color: '#74B5EF'}
                ]
              )
            }

          },
          data: data.view
        },
        {
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 5,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [
                {
                  offset: 0.2, color: '#ff5db2' // 0% 处的颜色
                },
                {
                  offset: 1, color: '#ff3783' // 100% 处的颜色
                }],
              globalCoord: true // 缺省为 false
            }
          },
          data: data.view
        }
      ]
    };
    return option;
  }


  setWordsCloudOption(data) {
    const option = {
      tooltip: {},
      series: [{
        type: 'wordCloud',
        gridSize: 8,
        sizeRange: [13, 40],
        rotationRange: [-90, 90],
        shape: 'pentagon',
        width: '99%',
        height: this.datasetHeight,
        left: 'center',
        top: 'center',
        textStyle: {
          normal: {
            color: function () {
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')';
            }
          },
          emphasis: {
            shadowBlur: 10,
            shadowColor: '#333'
          }
        },
        data: data
      }]
    };
    return option;
  }

  refreshWordCloudOption() {
    this.wordCloudChart.resize();
  }

  ngOnInit() {
    // echarts使用词云的前提必须先加载包
    require('echarts-wordcloud');
    const searchData = this.getSearchData();
    const condition = searchData.searchData || {};
    this.category = searchData.category || [];
    const yearData = this.getSearchYearData();
    const yearCondition = yearData.searchData || {};
    this.years = yearData.years || [];
    const parallel$ = observableForkJoin(
      this.requestData.getDataByCondition('headLine/report/visualReport/main.json'),
      this.requestData.getDataByCondition('headLine/ds/ds_libinfo_detail.json'),
      this.requestData.getDataByCondition('headLine/report/visualReport/register_count.json'),
      this.requestData.getDataByCondition('headLine/report/visualReport/ds_views_report.json', condition),
      this.requestData.getDataByCondition('headLine/report/visualReport/ds_download_report.json', condition),
      this.requestData.getDataByCondition('headLine/report/visualReport/ds_dataset_top_report.json'),
      // this.requestData.getDataByCondition('headLine/report/visualReport/ds_download_top_report.json')
      // this.requestData.getDataByCondition('headLine/report/visualReport/ds_dataset_count_onyear.json', yearCondition)
      this.requestData.getDataByCondition('headLine/report/visualReport/ds_keywords_count.json')
    );

    parallel$.subscribe(
      values => {
        this.countData = values[0]['data'] || [];
        this.libData = values[1]['data'][0] || {};
        this.registerChartOption = this.setRegisterOption(values[2]['data'] || []);
        this.viewDownloadOption = this.setViewDownloadOption(values[3]['data'] || [], values[4]['data'] || []);
        this.datasetOption = this.setDatasetOption(values[5]['data'] || []);
        // this.downloadOption = this.setDownloadOption(values[6]['data'] || []);
        // this.yearOption = this.setYearOption(values[6]['data'] || [])；
        this.wordCloudChart = echarts.init(document.getElementById('wordsCloud'));
        this.wordCloudData = values[6]['data'] || [];
        this.wordCloudChart.setOption(this.setWordsCloudOption(this.wordCloudData));
      }
    );
  }

}
