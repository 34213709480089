import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {UtilsService} from "../../service/utils.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _ from "underscore";

@Component({
  selector: 'app-hulk-config-poplist-tablename',
  templateUrl: './hulk-config-poplist-tablename.component.html',
  styleUrls: ['./hulk-config-poplist-tablename.component.scss']
})
export class HulkConfigPoplistTablenameComponent implements OnInit {

  @Input() showOne: Boolean = true;
  @Input() showLabel = true;
  @Input() oneForm: any;
  @Input() pf: any;
  @Input() needPf: Boolean = false;
  @Input() nzSpanLable = 3;
  @Input() nzSpanInput = 21;

  public isVisible = false;
  public tablelist: any = [];
  public searchText: String = null;

  constructor(
    private modalService: NzModalService,
    private requestDataService: RequestDataService,
    private appConfigService: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private confirmModal: ConfirmModalService,
    private utils: UtilsService
  ) {
  }

  ngOnInit() {
  }

  removeValue(): void {
    this.oneForm['tablename'] = null;
  }

  // 弹出框
  showModal() {
    let checked = true;
    if (this.needPf) {
      if (!this.utils.Base_HasValue(this.pf)) {
        checked = false;
        this.confirmModal.show("warning", {
          title: "注意",
          content: "请先选择数据源"
        });
      }
    }
    if (checked) {
      this.isVisible = true;
      this.getTableList(); // 获取所有的表
    }
  }

  // 弹出框
  closeModal() {
    this.isVisible = false;
  }


  deleteTextReSearch() {
    this.searchText = null;
    this.getTableList();
  }

  doSearch() {
    this.getTableList();
  }


  // 单选框 点击行事件
  selectData(data) {
    this.oneForm.tablename = data['TABLE_NAME'];
    this.isVisible = false;
  }


  getTableList() {
    let that = this;
    const search = observableForkJoin(
      this.requestDataService.getTables(this.searchText, this.pf)
    );
    search.subscribe(
      values => {
        that.tablelist = values[0];
      });
  }

}
