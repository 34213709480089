import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import * as _un from "underscore";
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _sh from 'lodash';
import {NzFormatEmitEvent} from "ng-zorro-antd";

@Component({
    selector: 'app-hulk-config-design-condition',
    templateUrl: './hulk-config-design-condition.component.html',
    styleUrls: ['./hulk-config-design-condition.component.scss']
})
export class HulkConfigDesignConditionComponent implements OnInit {
    // 以树形结构，选择一个字段。

    @Input() searchId: String = null;
    @Input() callBack: any;
    @Input() oneColumn: any;
    @Input() attributes = null;
    @Input() getLvOne: Boolean = false; // true 只获取一级数据
    @Input() tableList: []; // 表列表

    isVisible = false;
    treeData = [];

    constructor(
        private _httpClient: HttpClient,
        private modalService: NzModalService,
        private _FileSaverService: FileSaverService,
        private requestDataService: RequestDataService,
        private appConfigService: AppConfigService,
        private notification: NzNotificationService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private clipboardService: ClipboardService,
        private utils: UtilsService
    ) {

    }


    ngOnInit() {

    }

    showModal() {
        this.isVisible = true;
        const search = observableForkJoin(
            this.requestDataService.getPaginationData(this.searchId, {}, null, 1, null
            )
        );
        search.subscribe(
            values => {
                this.treeData = this.utils.toTree(_sh.get(values, ['0', 'data', '0']));
            }
        );
    }

    removeValue() {

        this.oneColumn[this.attributes] = null;
    }

    nzClick(event: NzFormatEmitEvent): void {
        let that = this;

        function execCallBack() {
            let newName = _sh.get(event, ['node', 'key']);
            that.confirmModal.show('confirm', {
                title: '温馨提示',
                content: '选择：' + newName,
                suc: () => {
                    that.callBack(event, that.oneColumn, newName);
                    that.isVisible = false;
                }
            });
        }

        let link = _sh.reverse(that.utils.getLinks(_sh.get(event, ['node'])));
        if (that.getLvOne) {
            if (link.length == 1) {
                execCallBack();
            } else if (link.length > 1) {
                that.confirmModal.show('confirm', {
                    title: '温馨提示',
                    content: '不能选择二级数据'
                });
            }
        } else {
            execCallBack();
        }
    }

}
