import {Component, OnInit} from '@angular/core';
import {RequestDataService} from '../../../service/request-data.service';
import * as alasql from 'alasql';
import * as XLSX from 'xlsx';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as moment from 'moment';
import {UtilsService} from '../../../service/utils.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-graduate-job-unit',
  templateUrl: './graduate-job-unit.component.html',
  styleUrls: ['./../report-teacher/report-teacher.component.css', './graduate-job-unit.component.css']
})
export class GraduateJobUnitComponent implements OnInit {
  listData: any;
  evaluationYear: any;

  constructor(private requestDataService: RequestDataService,
              private confirmModal: ConfirmModalService,
              private utils: UtilsService) {
  }

  export() {
    const data1: any = [];
    _.each(this.listData, oneData => {
      const data = {
        '年份': oneData['YEAR'] ? oneData['YEAR'] + '年' : '',
        '学位类别': oneData['EDUCATION'] || 0,
        '签约总人数': oneData['ACOUNT'] || 0,
        '党政机关': oneData['党政机关'] || 0,
        '高等教育单位': oneData['高等教育单位'] || 0,
        '中、初等教育单位': oneData['中、初等教育单位'] || 0,
        '科研设计单位': oneData['科研设计单位'] || 0,
        '医疗卫生单位': oneData['医疗卫生单位'] || 0,
        '其它事业单位': oneData['其它事业单位'] || 0,
        '国有企业': oneData['国有企业'] || 0,
        '三资企业': oneData['三资企业'] || 0,
        '民营企业': oneData['民营企业'] || 0,
        '其它': oneData['其它'] || 0
      };
      data1.push(data);
    });
    if (this.listData.length === 0) {
      const data = {
        '年份': '',
        '学位类别': '',
        '签约总人数': '',
        '党政机关': '',
        '高等教育单位': '',
        '中、初等教育单位': '',
        '科研设计单位': '',
        '医疗卫生单位': '',
        '其它事业单位': '',
        '国有企业': '',
        '三资企业': '',
        '民营企业': '',
        '其它': ''
      };
      data1.push(data);
    }
    const opts = [
      {
        sheetid:  this.evaluationYear - 4 + '~' + this.evaluationYear + '年毕业生签约单位类型分布',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("III-6-2 近5年毕业生签约单位类型分布.xlsx",?) FROM ?', [opts, [data1]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  changeModel() {
    const date = this.evaluationYear;
    if (date) {
      this.evaluationYear = moment(date).format('YYYY');
    } else {
      this.evaluationYear = null;
    }
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.evaluationYear)) {
      this.evaluationYear = moment().format('YYYY');
    }
    this.requestDataService.getDataByCondition('subjectInfo/report/report_graduate_job_unit.json', {'EVALUATION_YEAR_BEGIN': this.evaluationYear - 4, 'EVALUATION_YEAR_END': this.evaluationYear - 0}).subscribe( response => {
      this.listData = response['data'];
    });
  }

}
