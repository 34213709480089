import {Component, OnInit, Input} from '@angular/core';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import {DataCenterService} from './../../service/data-center.service';
import {AppConfigService} from './../../service/app-config.service';
import {UserInfoService} from './../../service/user-info.service';
import {AjaxService} from './../../service/ajax.service';
import {RequestDataService} from './../../service/request-data.service';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import * as _un from "underscore";

@Component({
    selector: 'app-role-menu',
    templateUrl: './role-menu.component.html',
    styleUrls: ['./role-menu.component.css']
})
export class RoleMenuComponent implements OnInit {
    public listRole: any = [];//所有角色
    public listMenu: any = [];//所有菜单
    public listAction: any = [];//所有按钮
    public roleId: string = "";//被选中的角色id
    public uniqIndex: any;
    public helpUrl: any;//帮助文档地址
    public menuActionList: any = [];
    public checkedMenu: any = [];
    public checkedAction: any;
    public searchRole: any;
    public searchMenu: any;
    public user: any;


    constructor(private utils: UtilsService,
                private confirmModal: ConfirmModalService,
                private dataCenter: DataCenterService,
                private appConfigService: AppConfigService,
                private userInfo: UserInfoService,
                private ajaxService: AjaxService,
                private requestData: RequestDataService) {
        this.user = userInfo.getUserInfo();
    }

    /**
     * 选中角色之后获取对应菜单数据和按钮数据进行选中或者取消CheckBox
     * @param pOneRole
     */
    doCheckRole(pOneRole) {
        this.selectAllMenu();
        // 获取角色对应菜单数据
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_role_menu.json', {"ROLE_ID": pOneRole.ROLE_ID}).subscribe((response) => {
            this.checkedMenu = response["data"] || [];
            // 获取菜单对应按钮数据
            return this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_role_menu_action.json', {"ROLE_ID": pOneRole.ROLE_ID}).subscribe(response => {
                this.checkedAction = response["data"] || [];
                this.selectedActionAndMenu();
            });
        }, (error) => {
            this.utils.Base_Errored(error);
        });
    };

    /**
     *  循环角色菜单 选中菜单
     */
    selectedActionAndMenu() {
        // 循环菜单按钮数据
        _.each(this.menuActionList, (pMenu) => {
            // 循环二级菜单数据
            _.each(pMenu["children"], (sMenu) => {
                // 查找该角色对应菜单数据里是否有当前菜单数据 有的话 选中 并且循环子按钮执行同样操作
                let menu = _.find(this.checkedMenu, (cMenu) => {
                    return cMenu["MENU_ID"] === sMenu["value"];
                });
                if (this.utils.Base_HasValue(menu)) {
                    sMenu["selected"] = true;
                    if (this.utils.Base_HasValue(sMenu["children"])) {
                        _.each(sMenu["children"], (actionList) => {
                            let action = _.find(this.checkedAction, (cAction) => {
                                return cAction["ACTION_ID"] === actionList["value"] && actionList["menu_id"] === cAction["MENU_ID"];
                            });
                            if (this.utils.Base_HasValue(action)) {
                                actionList["selected"] = true;
                                actionList["order"] = action["ACTION_ORDER"];
                            }
                        });
                    }
                }
            });
        });
    }


    /**
     * 反选checkbox的递归方法
     * @param list
     */
    falseSelected(list) {
        _.each(list, (data) => {
            data["selected"] = false;
            if (this.utils.Base_HasValue(data["children"])) {
                this.falseSelected(data["children"]);
            }
        });
    };

    /**
     * 取消全选
     */
    selectAllMenu() {
        this.falseSelected(this.menuActionList);
    };

    /**
     * 循环按钮选中
     * @param menu
     */
    doSelectAction = (menu) => {
        if (this.utils.Base_HasValue(menu.children)) {
            _.each(menu.children, (sMenu) => {
                sMenu["selected"] = menu.selected;
            });
        }
    };

    /**
     * 循环二级菜单并选中
     * @param pDatas
     */
    doSelectAll(pDatas) {
        _.each(pDatas.children, (sMenu) => {
            sMenu["selected"] = pDatas.selected;
            this.doSelectAction(sMenu);
        });
    };


    async newMenuSave() {
      let roleId = this.roleId; // 选中角色ID

      // 没有角色弹出提示
      if (!this.utils.Base_HasValue(roleId)) {
        return this.confirmModal.show('error', {title: '请选择角色'})
      }

      let menuList = [];
      let menuActionList = [];
      _sh.each(this.menuActionList, menuLv01 => {
        let menuLv02List = _sh.get(menuLv01, ['children'], []);
        if (this.utils.Base_HasValue(menuLv02List)) {
          _sh.each(menuLv02List, menuLv02 => {
            let lv02Selected = _sh.get(menuLv02, ['selected'], false);
            if (lv02Selected) {
              let menuObj = {
                'menuId': menuLv02['value'],
                'upId': menuLv02['up_id']
              }
              menuList.push(menuObj);

              let actionList = _sh.get(menuLv02, ['children'], []);
              if (this.utils.Base_HasValue(actionList)) {
                _sh.each(actionList, oneAction => {
                  let actionSelected = _sh.get(oneAction, ['selected'], false);
                  if (actionSelected) {
                    let actionObj = {
                      'menuId': menuLv02['value'],
                      'actionId': oneAction['value'],
                      'actionOrder': oneAction['order']
                    }
                    menuActionList.push(actionObj);
                  }
                })
              }
            }
          });
        }
      })

      _sh.each(menuList, menuLv02 => {
        let menuObj = {
          'menuId': menuLv02['upId'],
          'upId': null
        }
        menuList.push(menuObj);
      })
      menuList = _sh.uniqBy(menuList, 'menuId'); // 去掉重复的数据

      // 没有菜单按钮信息弹出提示
      if (!menuList.length) {
        return this.confirmModal.show('error', {title: '请选择菜单'})
      }

      const saveRes = await this.requestData.doSaveRoleMenuAction(roleId,menuList, menuActionList).toPromise();
      if (saveRes.state === 'success') {
        this.confirmModal.show('success', {'title': '保存成功'});
      } else {
        this.confirmModal.show('error', {'title': '保存失败，请刷新重试'});
      }
    }

    /**
     *  保存数据
     */
    menuSave() {
        let roleId = this.roleId; // 选中角色ID
        let menuList = []; // 定义空数组
        // 循环菜单按钮数据
        _.each(this.menuActionList, (data) => {
            let hasSelected = false; // 定义是否二级菜单有选中的FLAG
            // 循环耳机菜单 如果有选中。循环按钮。push到list里
            _.each(data["children"], (menu) => {
                hasSelected = menu["selected"] || hasSelected;
                if (menu["selected"]) {
                    let obj = {
                        menuId: menu["value"]
                    };
                    if (menu["children"] && menu["children"].length) {
                        let actionList = [];
                        _.each(menu["children"], (action) => {
                            if (action["selected"]) {
                                if (this.utils.Base_HasValue(action["value"])) {
                                    actionList.push({
                                        actionId: action["value"],
                                        actionOrder: action["order"]
                                    });
                                }

                            }
                        });
                        obj["action"] = actionList;
                    }
                    menuList.push(obj)
                }
            });
            // 如果二级菜单有选中并且不是之前定义的parentMenu 则一级菜单push到list
            if (hasSelected && data["value"] !== 'parentMenu') {
                menuList.push({
                    menuId: data["value"],
                    type: data["type"]
                });
            }
        });
        // 没有角色弹出提示
        if (!this.utils.Base_HasValue(roleId)) {
            return this.confirmModal.show('error', {title: '请选择角色'})
        }
        // 没有菜单按钮信息弹出提示
        if (!menuList.length) {
            return this.confirmModal.show('error', {title: '请选择菜单'})
        }

        if (this.utils.Base_HasValue(this.checkedMenu)) {
            if (!this.utils.Base_HasValue(this.uniqIndex)) {
                _.each(this.checkedMenu, (oneData) => {
                    if (this.utils.Base_HasValue(oneData["UNIQ_INDEX"])) {
                        this.uniqIndex = oneData["UNIQ_INDEX"];
                    }
                });
            } else if (this.utils.Base_HasValue(this.uniqIndex)) {
                if (this.uniqIndex === 0) {
                    this.uniqIndex = 1;
                } else if (this.uniqIndex === 1) {
                    this.uniqIndex = 0;
                }
            }
        } else {
            this.uniqIndex = 0;
        }
        if (!this.utils.Base_HasValue(this.uniqIndex)) {
            this.uniqIndex = 0;
        }
        // 数据封装并调用保存方法
        var data = {
            PF: this.appConfigService.config.MysqlPF,
            menuActionArray: JSON.stringify({
                roleId: roleId,
                menu: menuList,
                uniqIndex: this.uniqIndex
            })
        };
        this.requestData.doActionByHdbsthorJson('hdbsthor-right', 'assignRightController', this.appConfigService.config.MysqlPF + '/roleMenuAndAction', data).subscribe(
            (response) => {
                if (response["suc"] === 'success') {
                    this.utils.Base_Checking_Java("执行成功");
                } else {
                    this.utils.Base_JAVA_ERROR(response);
                }
            }, (error) => {
                this.utils.Base_JAVA_ERROR(error);
            }
        );
    }

    ngOnInit() {
        let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
        if (this.utils.Base_HasValue(currentMenu)) {
            this.helpUrl = currentMenu.HELP_URL;
        }

        const userInfo = this.userInfo.getUserInfo();
        const sqlPath = this.appConfigService.config.sql_path;
        let menuBtnJson = sqlPath + '/system/system_menu_action.json';//菜单按钮
        let menuJson = sqlPath + '/system/system_app_menu.json';//菜单
        let roleJson = sqlPath + '/system/system_role_ctrl_list.json';//角色
        if (sqlPath == "otms") {
            var comId = userInfo.COM_ID;
            roleJson = sqlPath + '/system/system_menu_role.json';
            if (comId != "public") {
                menuBtnJson = sqlPath + '/system_user_menu_action.json';
                menuJson = sqlPath + '/user_menu.json';
            }
        }

        this.requestData.getDataByCondition(roleJson, {"ROLE_TYPE": "MENUROLE"}).subscribe(response => {
            this.listRole = response["data"];
        })

        this.requestData.getDataByCondition(menuJson, {"MENU_LV": 1}).subscribe(dataLv01 => {

            let menuLv01List = dataLv01["data"];

            this.requestData.getDataByCondition(menuJson, {"MENU_LV": 2}).subscribe(response => {
                this.listMenu = response["data"];
                this.requestData.getDataByCondition(menuBtnJson).subscribe(response => {
                    this.listAction = response["data"];
                    let array = []; // 定义一个空数组
                    // 获取菜单对象、按钮对象
                    let menuObj = _.groupBy(this.listMenu, (oneData) => {
                        return oneData["UP_ID"];
                    });
                    let actionObj = _.groupBy(this.listAction, (action) => {
                        return action["MENU_ID"];
                    });
                    // 定义某些没有一级菜单的菜单
                    if (menuObj['null']) {
                        array = array.concat(menuObj['null']);
                        delete menuObj['null'];
                    }
                    if (menuObj['']) {
                        array = array.concat(menuObj['']);
                        delete menuObj[''];
                    }
                    menuObj['parentMenu'] = array;

                    //循环菜单对象 分级push 并且将按钮push到对应二级菜单下
                    _.each(menuObj, (value, key) => {
                        let childMenu = value || [];
                        if (!childMenu.length) {
                            return;
                        }
                        let findObj = _sh.filter(menuLv01List, oneLv01 => {
                            return oneLv01['MENU_ID'] == key;
                        })
                        let obj = {
                            value: key,
                            label: childMenu[0]["UP_ID_DICT"] || '按钮类型菜单',
                            type: "LEVEL1",
                            order: _sh.get(findObj, [0, 'MENU_ORDER'], 0),
                            selected: false,
                            children: []
                        };

                        _.each(childMenu, (cv) => {
                            let actionBtn = actionObj[cv["MENU_ID"]] || [];
                            let actionChildren = [];
                            _.each(actionBtn, (av) => {
                                actionChildren.push({
                                    label: av["ACTION_NAME"],
                                    value: av["ACTION_ID"],
                                    order: av["ACTION_ORDER"],
                                    menu_id: av["MENU_ID"],
                                    selected: false,
                                    type: 'LEVEL3'
                                })
                            });
                            obj.children.push({
                                value: cv["MENU_ID"],
                                label: cv["MENU_NAME"],
                                up_id: cv["UP_ID"],
                                selected: false,
                                type: "LEVEL2",
                                children: actionChildren
                            })
                        });
                        this.menuActionList.push(obj);
                    })
                    this.menuActionList = _sh.orderBy(this.menuActionList, ['order'], ['asc']);
                })
            })

        })

    }

}
