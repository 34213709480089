import {Component, Input, OnInit} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import * as _ from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import * as _un from "underscore";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {UserInfoService} from "../../../service/user-info.service";

@Component({
  selector: 'app-ms-storage-strategy',
  templateUrl: './ms-storage-strategy.component.html',
  styleUrls: ['./ms-storage-strategy.component.scss']
})
export class MsStorageStrategyComponent implements OnInit {

  @Input() parentData: any;
  baseForm: any = {
    "type": "normal",
    "columns": [
      {
        "column": "STO_CODE",
        "name": "STO_CODE_NAME",
        "component": "input",
        "lable": "仓储计划单号",
        "comment": "仓储计划单号",
        "placeholder": "请输入仓储计划单号",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "CUST_ID",
        "name": "CUST_ID_NAME",
        "component": "poplist",
        "lable": "客户名称",
        "comment": "客户名称",
        "placeholder": "请选择客户名称",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_CUST.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "CARRI_ID",
        "name": "CARRI_ID_NAME",
        "component": "poplist",
        "lable": "承运商",
        "comment": "承运商",
        "placeholder": "请选择承运商",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_CARRIER.json",
        "dictionaryPF": null,
        "disable": false
      },
      {
        "column": "SERVICE_ID",
        "name": "SERVICE_ID_NAME",
        "component": "select",
        "lable": "业务类型",
        "comment": "业务类型",
        "placeholder": "业务类型",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_ALLSERVICE.json",
        "dictionaryPF": null,
        "disable": true
      },
      {
        "column": "STO_QUANTITY",
        "name": "STO_QUANTITY_NAME",
        "component": "input",
        "lable": "总数量(件)",
        "comment": "总数量(件)",
        "placeholder": "请输入总数量(件)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "STO_WEIGHT",
        "name": "STO_WEIGHT_NAME",
        "component": "input",
        "lable": "总重量(吨)",
        "comment": "总重量(吨)",
        "placeholder": "请输入总重量(吨)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "STO_VOLUME",
        "name": "STO_VOLUME_NAME",
        "component": "input",
        "lable": "总体积(立方米)",
        "comment": "总体积(立方米)",
        "placeholder": "请输入总体积(立方米)",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DR_ID",
        "name": "DR_ID_NAME",
        "component": "poplist",
        "lable": "司机",
        "comment": "司机",
        "placeholder": "请选择司机",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_DRIVER.json",
        "dictionaryPF": null,
        "disable": false
      },
      {
        "column": "TRUCK_ID",
        "name": "TRUCK_ID_NAME",
        "component": "poplist",
        "lable": "车辆",
        "comment": "车辆",
        "placeholder": "请选择车辆",
        "model": null,
        "visible": true,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/TRUCK_ID.json",
        "dictionaryPF": null,
        "disable": false
      },
      {
        "column": "STO_TEXT",
        "name": "STO_TEXT_NAME",
        "component": "textarea",
        "lable": "备注",
        "comment": "备注",
        "placeholder": "请输入备注",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
    ]
  };
  baseDetailForm: any = {
    "type": "normal",
    "columns": [
      {
        'column': 'OD_DET_ID',
        'iscondition': true,
        'pk_column': true,
        'name': 'OD_DET_ID_NAME',
        'component': 'input',
        'lable': '主键',
        'comment': '主键',
        'placeholder': '主键',
        'class': 'col-lg-4 col-sm-6 col-xs-12',
        'insertValue': 'SYS-ACTION-UUID',
        'model': null,
        'visible': false,
        'require': true
      },
      {
        "column": "GOODS_CODE",
        "name": "GOODS_CODE_NAME",
        "component": "input",
        "lable": "货品编号",
        "comment": "货品编号",
        "placeholder": "请输入货品编号",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "GOODS_ID",
        "name": "GOODS_ID_NAME",
        "component": "poplist",
        "lable": "货品名称",
        "comment": "货品名称",
        "placeholder": "货品名称",
        "model": null,
        "type": "normal",
        "visible": false,
        "require": false,
        "dictionary": "JieLiv2/basic/dict/BASIC_GOODS.json",
        "dictionaryPF": null
      },
      {
        "column": "GOODS_ID_DICT",
        "name": "GOODS_ID_DICT_NAME",
        "component": "input",
        "lable": "货品名称",
        "comment": "货品名称",
        "placeholder": "请输入货品名称",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DET_BATCH",
        "name": "DET_BATCH_NAME",
        "component": "input",
        "lable": "货品批次",
        "comment": "货品批次",
        "placeholder": "请输入货品批次",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DET_PRODUCT",
        "name": "DET_PRODUCT_NAME",
        "component": "input",
        "lable": "生产日期",
        "comment": "生产日期",
        "placeholder": "请输入生产日期",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "DET_QUANTITY",
        "name": "DET_QUANTITY_NAME",
        "component": "input",
        "lable": "总件数",
        "comment": "总件数",
        "placeholder": "请输入总件数",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "ASSIGNED_NUM",
        "name": "ASSIGNED_NUM_NAME",
        "component": "input",
        "lable": "已分配件数",
        "comment": "已分配件数",
        "placeholder": "请输入已分配件数",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      },
      {
        "column": "GOODS_NOW_NUM",
        "name": "GOODS_NOW_NUM_NAME",
        "component": "input",
        "lable": "现分配件数",
        "comment": "现分配件数",
        "placeholder": "请输入现分配件数",
        "model": null,
        "visible": true,
        "require": false,
        "disable": true
      }
    ]
  };
  public showColumns: any = [
    {
      "column": "GOODS_CODE",
      "title": "货品编号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "GOODS_ID_DICT",
      "title": "货品名称",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_BATCH",
      "title": "货品批次",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_PRODUCT",
      "title": "生成日期",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_QUANTITY",
      "title": "总件数",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "ASSIGNED_NUM",
      "title": "已分配件数",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
  ];
  public ordersColumns: any = [
    {
      "column": "GOODS_ID_DICT",
      "title": "货品名称",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "DET_BATCH",
      "title": "批次",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "LOC_ID_DICT",
      "title": "库位编号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "PUT_NUMBER",
      "title": "可存数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "GOODS_NUM",
      "title": "分配数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
  ];
  public ordersDetailColumns: any = [
    {
      "column": "PLANT_ID_DICT",
      "title": "仓库编码",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "LOC_ID_DICT",
      "title": "库位编号",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "PUT_NUMBER",
      "title": "可存数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
    {
      "column": "GOODS_NUM",
      "title": "分配数量",
      "visible": true,
      "export": true,
      "type": "head",
      "class": ""
    },
  ];
  goodsList: any[];
  ordersList: any[];
  ordersDetailList: any[];
  stoId = null;
  custId = null;
  allChecked = false;
  allChecked_orders = false;
  allChecked_ordersDetail = false;
  indeterminate = false;
  indeterminateOrder = false;
  indeterminateOrderDetail = false;
  isVisible = false;
  assignedNum = null;

  constructor(
    private dataCenterService: DataCenterService,
    private requestService: RequestDataService,
    private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.stoId = _.cloneDeep(_.get(this.parentData, ['id']));
    this.custId = _.cloneDeep(_.get(this.parentData, ['data','data',0,'CUST_ID']),null);
    this.doSearch();
  }

  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  checkData() {
    let bHasValue = true;
    _.each(this.baseForm['columns'], oneColumn => {
      if (bHasValue && oneColumn['require']) {
        bHasValue = this.utils.Base_HasValue(oneColumn['model']) || this.utils.Base_HasValue(oneColumn['insertValue']);
        if (!bHasValue) {
          this.confirmModal.show('error', {title: '请填写必填信息。', content: oneColumn['lable']});
        }
      }
    })
    return bHasValue;
  }


  doSave() {
    let check = false;
    let checkData = false;
    _.each(this.goodsList,oneData=>{
      if(oneData['DET_QUANTITY'] == oneData['ASSIGNED_NUM']){
        check = true;
      }
    })
    checkData = this.checkData();
    if(!check){
      this.confirmModal.show('warning', {title: '温馨提示', content:'商品已分配件数不等于总件数！'});
    }
    if(check && checkData){
      let entity = [];
      _.each(this.ordersList, oneData => {
        entity.push({
          "stockId": oneData['STOCK_ID'],
          "locId": oneData['LOC_ID'],
          "odDetId": oneData['OD_DET_ID'],
          "goodsId": oneData['GOODS_ID'],
          "goodsNum": oneData['GOODS_NUM']
        });
      });

      const user = this.userInfoService.getUserInfo();
      let query = {
        'stoId': this.stoId,
        'userId': user.USER_ID
      }

      this.requestService.doActionByHdbsthor("AppApi/MsStorageTaskController", "execInStrategy", query, entity, null, "hdbsthor_JieLiv2", "appToken").subscribe(res => {
        if (res === true) {
          this.confirmModal.show('success',
            {title: '保存成功。', content: '策略保存成功'});
          this.gotoBack();
        } else {
          if (res.status === 500) {
            this.confirmModal.show('error', {'title': '执行错误', 'content': res.error.message});
          }
        }
      })
    }
  }

  doSearch() {
    const search = observableForkJoin(
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_plant_list_list.json', {'STO_ID': this.stoId}), // 0
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_orders_detail_detail.json', {'STO_ID': this.stoId}),
      this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_detail_strategy_detail.json', {'PLAN_ID': this.stoId})
    );
    search.subscribe(values => {
      this.backfillData(this.baseForm, values[0]['data']);
      this.goodsList = values[1]['data'];
      this.ordersList = values[2]['data'];
    })
  }

  doSearchDetail() {
    let oneData = _.get(_.filter(this.goodsList, {'checked': true}),0);
      const search = observableForkJoin(
        this.requestService.getDataByCondition('JieLiv2/ms/ms_storage_orders_detail_detail.json', {'OD_DET_ID': oneData['OD_DET_ID']}),
        this.requestService.getDataByCondition('JieLiv2/ms/ms_stock_allocationBase.json', {'GOODS_ID': oneData['GOODS_ID'], 'CUST_ID': this.custId})
      );
      search.subscribe(values => {
        this.backfillData(this.baseDetailForm, values[0]['data']);
        this.ordersDetailList = values[1]['data'];
      })
  }

  backfillData(pForm, pDataArray) {
    _.each(pDataArray, oneData => {
      _.each(pForm['columns'], oneColumn => {
        if (oneData[oneColumn['column']] == 'null') {
          oneData[oneColumn['column']] = null
        }
        oneColumn['model'] = oneData[oneColumn['column']];

        if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
          oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
        }
        if (oneColumn['component'] == 'file') {
          let url = oneData[oneColumn['column']];
          let fileName = this.utils.Base_getFileNameOnly(url);
          oneColumn['fileList'] = [];
          oneColumn['fileList'].push(
            {
              uid: this.utils.Base_getUuid(),
              name: fileName,
              status: 'done',
              url: url
            }
          )
        }
        _.each(oneColumn['columnGroup'], oneColumnGroup => {
          oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
          if (_un.contains(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
            oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
          }
        })
      })
    })
  }

  delete(): void {
    const checkedList = _.filter(this.ordersList, (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.ordersList = this.ordersList.filter((item) => {
            return !item.checked;
          });
          _.each(this.goodsList,oneData=>{
            _.each(checkedList,oneItem=>{
              let goodId = oneData['GOODS_ID'];
              if(oneItem['GOODS_ID'] == goodId) {
                oneData['ASSIGNED_NUM'] = 0
              }
            })
          })
          this.allChecked_orders = false;
          _.each(this.ordersList, oneData => {
            oneData.checked = false;
          })
          this.refreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  checkAll(value: boolean): void {
    this.goodsList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(data?): void {
    const validData = this.goodsList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll_orders(value: boolean): void {
    this.ordersList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatusOrders();
  }

  refreshStatusOrders(data?): void {
    const validData = this.ordersList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_orders = allChecked;
    this.indeterminateOrder = (!allChecked) && (!allUnChecked);
  }

  checkAll_ordersDetail(value: boolean): void {
    this.ordersDetailList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatusOrdersDetail();
  }

  refreshStatusOrdersDetail(data?): void {
    const validData = this.ordersDetailList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked_ordersDetail = allChecked;
    this.indeterminateOrderDetail = (!allChecked) && (!allUnChecked);
  }

  assign():void {
    let check = true;
    let list = _.filter(this.goodsList, {'checked': true});
    if(list.length > 1 ){
      this.confirmModal.show('warning', {title: '温馨提示', content: '请选择一条数据！'});
      check = false;
    }
    if(list.length == 0 ){
      this.confirmModal.show('warning', {title: '温馨提示', content: '请至少选择一条数据！'});
      check = false;
    }
    let goods = _.filter(this.goodsList, {'checked': true});
    _.each(goods,oneData=>{
      let goodsId = oneData['OD_DET_ID'];
      _.each(this.ordersList,oneData=>{
        let ordersId = oneData['OD_DET_ID'];
        if(goodsId == ordersId){
          this.confirmModal.show('warning', {title: '温馨提示', content: '该货品已被分配请勿再次分配！'});
          check = false;
        }
      })
    })
    if(check){
      this.doSearchDetail();
      this.isVisible = true;
    }
  }

  doCancel() {
    this.isVisible = false;
  }

  confirm(){
    let check = true;
    let orders = _.filter(this.ordersDetailList, {'checked': true});
    if(orders.length > 1 ){
      this.confirmModal.show('warning', {title: '温馨提示', content: '请选择一条数据！'});
      check = false;
    }
    if(orders.length == 0 ){
      this.confirmModal.show('warning', {title: '温馨提示', content: '请至少选择一条数据！'});
      check = false;
    }
    if(check) {
      _.each(orders,oneData =>{
        let temp = {
          "STR_ID": this.utils.Base_getUuid(),
          "GOODS_NAME": this.findValue(this.baseDetailForm, 'GOODS_ID_DICT'),
          "GOODS_ID": this.findValue(this.baseDetailForm, 'GOODS_ID'),
          "LOC_ID": oneData['LOC_ID'],
          "LOC_ID_DICT": oneData['LOC_ID_DICT'],
          "PUT_NUMBER": oneData['PUT_NUMBER'],
          "GOODS_NUM": oneData['GOODS_NUM'],
          "DET_BATCH": this.findValue(this.baseDetailForm, 'DET_BATCH'),
          "OD_DET_ID": this.findValue(this.baseDetailForm, 'OD_DET_ID')
        }
        if(oneData['GOODS_NUM'] != this.findValue(this.baseDetailForm, 'DET_QUANTITY') || oneData['GOODS_NUM'] == 0){
          this.confirmModal.show('warning', {title: '温馨提示', content: '请核对货品总件数是否与分配数量相同！'});
          check = false;
        }
        if(check) {
          let goodId = this.findValue(this.baseDetailForm,'GOODS_ID');
          _.each(this.goodsList,oneItem=>{
            if(oneItem['GOODS_ID'] == goodId){
              oneItem['ASSIGNED_NUM'] = oneData['GOODS_NUM']
            }
          })
          this.ordersList.push(temp);
          this.isVisible = false;
          this.allChecked = false;
          this.allChecked_ordersDetail = false;
          _.each(this.goodsList, oneData => {
            oneData.checked = false;
          })
        }
      })
    }
  }

  findValue(pForm, pColumnName) {
    let bFind = false;
    let value = null;
    _.each(pForm['columns'], oneColumn => {
      if ((oneColumn['column'] == pColumnName) && (!bFind)) {
        value = oneColumn['model'];
        bFind = true;
      }
    });
    return value;
  }

  changeQuantity($event:any) {
    _.each(this.baseDetailForm['columns'],oneColumn=>{
      if(oneColumn['column'] == 'GOODS_NOW_NUM' ){
        oneColumn['model'] = $event;
      }
    })
  }

  async assignSto() {
    const user = this.userInfoService.getUserInfo();
    let query = {
      userId: user.USER_ID,
      stoId: this.stoId
    }
    const res = await this.requestService.doActionByHdbsthor("AppApi/MsStrategyController", "warehousingStrategy", query, "", false, 'hdbsthor_JieLiv2', 'appToken').toPromise();
    if (res.status == 500) {
      this.confirmModal.show('error', {'title': '执行错误', 'content': res.error.message});
    } else {
      this.ordersList = [];
      _.each(res, item => {
        let temp = {
          "STR_ID": this.utils.Base_getUuid(),
          "GOODS_NAME": item['goods_NAME'],
          "GOODS_ID": item['goods_ID'],
          "LOC_ID": item['loc_ID'],
          "LOC_ID_DICT": item['loc_ID_DICT'],
          "PUT_NUMBER": item['put_NUMBER'],
          "GOODS_NUM": item['goods_NUM'],
          "DET_BATCH": item['det_BATCH'],
          "OD_DET_ID": item['od_DET_ID']
        }
        this.ordersList.push(temp)
        _.each(this.goodsList, goods => {
          if (goods['OD_DET_ID'] == item['od_DET_ID']) {
            goods['ASSIGNED_NUM'] = item['goods_NUM']
          }
        })
      })
    }
  }
}
