import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {UtilsService} from '../../../service/utils.service';
import {RequestDataService} from '../../../service/request-data.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-place-use-day',
  templateUrl: './place-use-day.component.html',
  styleUrls: ['./place-use-day.component.css']
})
export class PlaceUseDayComponent implements OnInit {

  selectedValue = new Date();
  listData: any;
  preDateList: any;
  showTable: boolean = false;
  nowDate = moment(this.selectedValue).format('YYYY-MM-DD');
  choiceData: any;
  listArray = [];
  searchColumns = {
    'title': '查询',
    'columns': [
      {
        'column': 'PLACE_ID',
        'name': 'PLACE_ID',
        'component': 'poplist',
        'lable': '选择场所',
        'comment': '选择场所',
        'placeholder': '选择场所',
        'model': null,
        'visible': true,
        'require': false,
        'dictionary': 'wisdomHs/basic/dict/CHOOSE_PLACE.json',
        'dictionaryPF': null
      }
    ]
  };

  constructor(private utils: UtilsService,
              private requestService: RequestDataService) {
  }

  reRearch() {
    let condition = {};
    if (this.utils.Base_HasValue(this.searchColumns.columns[0].model)) {
      condition = {
        PLACE_ID: this.searchColumns.columns[0].model
      };
    }
    this.requestService.getDataByCondition('wisdomHs/ms/ms_apply_place_day.json', condition).subscribe((res) => {
      this.listData = res.data || [];
      if (this.utils.Base_HasValue(this.listData[0])) {
        this.listData = _.groupBy(this.listData, 'DAY_START');
        this.preDateList = Object.keys(this.listData);
        this.toDay();
      } else {
        this.listArray = [];
        this.preDateList = [];
        this.showTable = false;
      }
    });
  }

  toDay() {
    this.listArray = [];
    this.nowDate = moment(this.selectedValue).format('YYYY-MM-DD');
    let nowdate: any = this.nowDate;
    this.choiceData = _.filter(this.listData, function (item, key) {
      return key === nowdate;
    });
    if (this.utils.Base_HasValue(this.choiceData[0])) {
      this.choiceData = _.groupBy(this.choiceData[0], 'PLACE_ID');
      _.each(this.choiceData, (value, key) => {
        this.listArray.push(value);
      });
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  ngOnInit() {
    this.reRearch();
  }

}
