
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {
  FormBuilder
} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UploadService} from './../../../service/upload.service';
import {UtilsService} from './../../../service/utils.service';
import {ConfirmModalService} from './../../../service/confirm-modal.service';
import {AppConfigService} from './../../../service/app-config.service';
import {DataService} from './../data.service';
import * as _ from 'underscore';
import * as Mtils from 'mtils';


@Component({
  selector: 'app-model-insert',
  templateUrl: './model-insert.component.html',
  styleUrls: ['./model-insert.component.css']
})
export class ModelInsertComponent implements OnInit {

  @Input() parentData: any;

  modelState = []; // 状态

  modelType = []; // 类型

  columnType = []; // 字段类型

  required: boolean = false;

  selectedValue: any = null;

  dataTypeSelect: any = [];

  data: any = {
    'modelName': null,
    'modelType': null,
    'modelState': 'ENABLE',
    'modelOrder': 1,
    'modelEnName': null,
    'modelId': '',
    'groups': [
      {
        'type': null,
        'add': null,
        'groupOrder': null,
        'groupName': null,
        'groupId': null,
        'require': false,
        'columns': [],
        'data': []
      },
      {
        'type': 'form',
        'isDefault': true,
        'groupOrder': '2',
        'groupName': '学科',
        'groupId': 'subjectName',
        'require': true,
        'showDetail': true,
        'columns': [
          {
            'column': 'subjectName',
            'columnName': '学科',
            'placeholder': '请选择学科',
            'component': 'multipop',
            'limitLength': 10,
            'require': true,
            'name': '学科',
            'model': '',
            'dictionary': 'headLine/basic/dict/SUBJECT_NAME.json'
          }
        ],
        'data': []
      },
      {
        'type': 'form',
        'isDefault': true,
        'groupOrder': '3',
        'groupName': '关键词',
        'groupId': 'keywordsName',
        'require': false,
        'showDetail': true,
        'columns': [
          {
            'column': 'keywordsName',
            'columnName': '关键词',
            'placeholder': '请选择关键词',
            'component': 'multiSelect',
            'limitLength': 10,
            'require': false,
            'name': '关键词',
            'model': '',
            'dictionary': 'headLine/basic/dict/KEYWORDS_NAME.json'
          }
        ],
        'data': []
      },
      {
        'type': 'pictureModel',
        'groupOrder': '4',
        'groupName': '上传图片',
        'groupId': '',
        'require': false,
        'showDetail': true,
        'columns': [
          {
            'column': 'pictureName',
            'columnName': '上传图片',
            'placeholder': '请选择图片',
            'component': 'picture',
            'require': false,
            'name': 'pictureName',
            'model': ''
          }
        ]
      },
      {
        'type': 'fileModel',
        'groupOrder': '5',
        'groupName': '上传文件',
        'groupId': '',
        'require': true,
        'showDetail': true,
        'columns': [
          {
            'column': 'fileName',
            'columnName': '上传文件',
            'placeholder': '请选择文件',
            'component': 'file',
            'require': true,
            'name': 'fileName',
            'model': ''
          }
        ]
      },
      {
        'type': 'form',
        'isDefault': true,
        'groupOrder': '6',
        'groupName': '描述',
        'groupId': '',
        'require': true,
        'showDetail': true,
        'columns': [
          {
            'column': 'datasetDesc',
            'columnName': '描述',
            'placeholder': '请输入描述',
            'component': 'textarea',
            'require': true,
            'name': 'datasetDesc',
            'model': ''
          }
        ]
      }]
  };

  constructor(private formBuilder: FormBuilder,
              private dataCenterService: DataCenterService,
              private requestData: RequestDataService,
              private uploadService: UploadService,
              private utils: UtilsService,
              private config: AppConfigService,
              private confirmModal: ConfirmModalService,
              private modalService: NzModalService,
              private dataService: DataService,
              private fb: FormBuilder) {
  }

  // 添加一个组
  addGroup() {
    let group: any = {
      'type': null,
      'add': null,
      'groupOrder': null,
      'groupName': null,
      'groupId': null,
      'require': false,
      'columns': [],
      'data': []
    };
    this.data.groups.push(group);
  }

  // 删除一个组
  deleteGroup(pIndex) {
    if (pIndex > 0) {
      this.data.groups.splice(pIndex, 1);
    }
  }

  // 上移组
  upShiftGroup(pIndex, pObject) {
    if (pIndex > 0) {
      let group = this.data.groups[pIndex - 1];
      this.data.groups[pIndex - 1] = pObject;
      this.data.groups[pIndex] = group;
    }
  }

  // 下移组
  downShiftGroup(pIndex, pObject) {
    if (this.data.groups.length - 1 > pIndex) {
      let group = this.data.groups[pIndex + 1];
      this.data.groups[pIndex + 1] = pObject;
      this.data.groups[pIndex] = group;
    }
  }

  // 添加一个字段
  addColumn(pObject, pIndex) {
    if (!this.utils.Base_HasValue(pObject.columnType)) {
      return;
    }
    let column: any = {
      'column': null,
      'columnName': null,
      'component': null,
      'require': false,
      'maxLength': 0,
      'dictionary': null
    };
    if (pObject.columnType === 'input') {
      column.component = 'input';
      column.maxLength = 50;

    } else if (pObject.columnType === 'textarea') {
      column.component = 'textarea';
      column.maxLength = 500;
    } else if (pObject.columnType === 'date') {
      column.component = 'date';
    } else if (pObject.columnType === 'datetime') {
      column.component = 'datetime';
    } else if (pObject.columnType === 'dateRange') {
      column.component = 'dateRange';
    } else if (pObject.columnType === 'select') {
      column.component = 'select';
    } else if (pObject.columnType === 'multiSelect') {
      column.component = 'multiSelect';
      column.limitLength = 5;
    } else if (pObject.columnType === 'poplist') {
      column.component = 'poplist';
    } else if (pObject.columnType === 'multipop') {
      column.component = 'multipop';
      column.limitLength = 5;
    }
    pObject.columns.push(column);
  }

  // 添加当前字段
  addThisColumn(pIndex, pObject) {
    let column: any = {
      'column': null,
      'columnName': null,
      'component': null,
      'require': false,
      'maxLength': 0,
      'dictionary': null
    };
    column.component = pObject.component;
    column.maxLength = pObject.maxLength;
    this.data.groups[pIndex].columns.push(column);
  }

  // 删除一个字段
  deleteColumn(pIndex, num) {
    this.data.groups[pIndex].columns.splice(num, 1);
    if (!this.data.groups[pIndex].columns.length) {
      this.data.groups[pIndex]['columnType'] = null;
    }
  }

  // 上移字段
  upShiftColumn(pIndex, num, pObject) {
    if (num > 0) {
      let column = this.data.groups[pIndex].columns[num - 1];
      this.data.groups[pIndex].columns[num - 1] = pObject;
      this.data.groups[pIndex].columns[num] = column;
    }
  }

  // 下移字段
  downShiftColumn(pIndex, num, pObject) {
    if (this.data.groups[pIndex].columns.length - 1 > num) {
      let column = this.data.groups[pIndex].columns[num + 1];
      this.data.groups[pIndex].columns[num + 1] = pObject;
      this.data.groups[pIndex].columns[num] = column;
    }
  }

  setColumnTypeExtend() {
    this.columnType = [
      {
        code: 'input',
        name: '输入框'
      },
      {
        code: 'textarea',
        name: '长文本输入'
      },
      {
        code: 'date',
        name: '日期选择'
      }
    ];
  }

  setColumnTypeBasic() {
    this.columnType = [
      {
        code: 'input',
        name: '输入框'
      },
      {
        code: 'textarea',
        name: '长文本输入'
      },
      {
        code: 'date',
        name: '日期选择'
      },
      {
        code: 'datetime',
        name: '时间选择'
      }, {
        code: 'dateRange',
        name: '起止日期选择框'
      },
      {
        code: 'select',
        name: '下拉单选框'
      },
      {
        code: 'multiSelect',
        name: '下拉多选框'
      },

      {
        code: 'poplist',
        name: '单选弹出框'
      },
      {
        code: 'multipop',
        name: '多选弹出框'
      }
    ];
    _.each(this.data.groups, group => {
      const current = _.find(group['columns'], column => {
        return column['column'] === 'keywordsName'
          || column['column'] === 'subjectName'
          || column['column'] === 'datasetDesc';
      });
      if (current) {
        group['isDefault'] = true;
      }
    });
  }

  // 类型变更
  updateModelType() {
    switch (this.data.modelType) {
      case 'extend':
        this.setColumnTypeExtend();
        _.each(this.data.groups, (oneGroups) => {
          if (oneGroups['type'] === 'pictureModel' || oneGroups['type'] === 'fileModel' || oneGroups['isDefault']) {
            this.data.groups = _.without(this.data.groups, oneGroups);
          }
        });
        break;
      case 'basic':
        this.setColumnTypeBasic();
        let hasFile = false;
        _.each(this.data.groups, (oneGroups) => {
          if (oneGroups['type'] === 'pictureModel' || oneGroups['type'] === 'fileModel' || oneGroups['isDefault']) {
            hasFile = true;
          }
        });
        if (!hasFile) {
          this.data.groups = this.data.groups.concat([{
            'type': 'form',
            'isDefault': true,
            'groupOrder': '2',
            'groupName': '学科',
            'groupId': 'subjectName',
            'require': true,
            'showDetail': true,
            'columns': [
              {
                'column': 'subjectName',
                'columnName': '学科',
                'placeholder': '请选择学科',
                'component': 'multipop',
                'limitLength': 10,
                'require': true,
                'name': '学科',
                'model': '',
                'dictionary': 'headLine/basic/dict/SUBJECT_NAME.json'
              }
            ],
            'data': []
          },
            {
              'type': 'form',
              'isDefault': true,
              'groupOrder': '3',
              'groupName': '关键词',
              'groupId': 'keywordsName',
              'require': false,
              'showDetail': true,
              'columns': [
                {
                  'column': 'keywordsName',
                  'columnName': '关`键词',
                  'placeholder': '请选择关键词',
                  'component': 'multiSelect',
                  'limitLength': 10,
                  'require': false,
                  'name': '关键词',
                  'model': '',
                  'dictionary': 'headLine/basic/dict/KEYWORDS_NAME.json'
                }
              ],
              'data': []
            },
            {
              'type': 'pictureModel',
              'groupOrder': '4',
              'groupName': '上传图片',
              'groupId': '',
              'showDetail': true,
              'require': false
            },
            {
              'type': 'fileModel',
              'groupOrder': '5',
              'groupName': '上传文件',
              'groupId': '',
              'require': true,
              'showDetail': true,
              'columns': [
                {
                  'column': 'fileName',
                  'columnName': '上传文件',
                  'placeholder': '请选择文件',
                  'component': 'file',
                  'require': true,
                  'name': 'fileName',
                  'model': ''
                }
              ]
            },
            {
              'type': 'form',
              'isDefault': true,
              'groupOrder': '6',
              'groupName': '描述',
              'groupId': '',
              'require': true,
              'showDetail': true,
              'columns': [
                {
                  'column': 'datasetDesc',
                  'columnName': '描述',
                  'placeholder': '请输入描述',
                  'component': 'textarea',
                  'require': true,
                  'name': 'datasetDesc',
                  'model': ''
                }
              ]
            }]);
        }
        break;
    }
  }

  // 保存
  doSave() {
    this.required = true;
    let ok = true;
    let group = true;
    let column = true;
    let copyData = this.utils.DeepCopy(this.data);
    if (!this.utils.Base_HasValue(this.data.modelEnName)
      || !this.utils.Base_HasValue(this.data.modelName)
      || this.data.modelName.length > 50
      || !this.utils.Base_HasValue(this.data.modelType)
      || !this.utils.Base_HasValue(this.data.modelState)
      || !this.utils.Base_HasValue(this.data.modelOrder)) {
      return;
    }
    copyData.modelEnName = copyData.modelEnName.replace(/\s/g, ''); // 去掉模型标识里的空格
    _.each(copyData.groups, (oneGroup, index) => {
      if (!this.utils.Base_HasValue(oneGroup['groupName'])) {
        ok = false;
        return false;
      }
      if (!_.contains(['fileModel', 'pictureModel'], oneGroup['type'])
        && !oneGroup['columns'].length) {
        ok = false;
        return false;
      }
      oneGroup['groupOrder'] = index + 1;
      if (!_.contains(['fileModel', 'pictureModel'], oneGroup['type'])) {
        if (oneGroup['add']) {
          oneGroup['type'] = 'list';
        } else {
          oneGroup['type'] = 'form';
        }
      }
      if (oneGroup['require']) {
        oneGroup['require'] = 1;
      } else {
        oneGroup['require'] = 0;
      }
      // 验证组内的字段名称是否重复
      let columnCount = _.countBy(oneGroup['columns'], column => {
        return column['columnName'];
      });
      _.each(columnCount, (value, key) => {
        if (value > 1) {
          column = false;
          return false;
        }
      });
      _.each(oneGroup['columns'], (oneColumn, num) => {
        if (!this.utils.Base_HasValue(oneColumn['columnName'])) {
          ok = false;
          return false;
        }
        if (_.contains(['select', 'poplist', 'multiSelect', 'multipop'], oneColumn['component'])
          && !this.utils.Base_HasValue(oneColumn['dictionary'])) {
          ok = false;
          return false;
        }
        if (!_.contains(['keywordsName', 'subjectName', 'datasetDesc'], oneColumn['column'])) {
          if (!this.utils.Base_HasValue(oneColumn['column'])) {
            oneColumn['column'] = Mtils.utils.makePy(oneColumn['columnName']) + this.utils.Base_getUuid().slice(0, 8);
          }
        } else {
          if (oneColumn['column'] === 'keywordsName') {
            oneColumn['limitLength'] = 10;
          }
        }
        if (oneColumn['component'] === 'dateRange') {
          oneColumn['column'] = oneColumn['column'] + '_START,' + oneColumn['column'] + '_END';
          oneColumn['columnName'] = oneColumn['columnName'] + '_起,' + oneColumn['columnName'] + '_止';
        } else if (_.contains(['multiSelect', 'multipop'], oneColumn['component']) && oneColumn['column'] !== 'keywordsName') {
          oneColumn['limitLength'] = 5;
        } else if (oneColumn['component'] === 'input') {
          oneColumn['maxLength'] = 50;
        } else if (oneColumn['component'] === 'textarea') {
          oneColumn['maxLength'] = 500;
        }
        oneColumn['order'] = num + 1;
        if (oneColumn['require']) {
          oneColumn['require'] = 1;
        } else {
          oneColumn['require'] = 0;
        }
      });
    });
    // 验证分组名称不能重复
    let groupCount = _.countBy(this.data.groups, groups => {
      return groups['groupName'];
    });
    _.each(groupCount, (value, key) => {
      if (value > 1) {
        group = false;
        return;
      }
    });
    if (!group) {
      this.confirmModal.show('warning', {title: '分组名称不能重复'});
      return;
    }
    if (!column) {
      this.confirmModal.show('warning', {title: '同一个分组内字段描述不能重复'});
      return;
    }
    if (!ok) {
      return;
    }
    let verification = observableForkJoin([this.requestData.getDataByCondition('headLine/ds/ds_basic_model_count.json', {'MODEL_NAME': this.data.modelName}),
      this.requestData.getDataByCondition('headLine/ds/ds_basic_model_count.json', {'MODEL_EN_NAME': this.data.modelEnName})]);
    if (this.parentData.modeType === 'modify') {
      verification = observableForkJoin([this.requestData.getDataByCondition('headLine/ds/ds_basic_model_count.json', {
        'MODEL_NAME': this.data.modelName,
        'ID': this.data.modelId
      }),
        this.requestData.getDataByCondition('headLine/ds/ds_basic_model_count.json', {
          'MODEL_EN_NAME': this.data.modelEnName,
          'ID': this.data.modelId
        })]);
    }
    verification.subscribe(([result1, result2]) => {
      if (this.utils.Base_HasValue(result1.data[0].acount) && result1.data[0].acount > 0) {
        this.confirmModal.show('warning', {title: '模型名称已存在'});
        return;
      }
      if (this.utils.Base_HasValue(result2.data[0].acount) && result2.data[0].acount > 0) {
        this.confirmModal.show('warning', {title: '模型标识已存在'});
        return;
      }
      this.dataService.insertModel(copyData).subscribe(response => {
        if (response.state === 'success') {
          this.confirmModal.show('success', {
            title: '温馨提示',
            content: '保存成功',
            suc: () => {
              this.gotoBack();
            }
          });
        } else {
          this.confirmModal.show('error', {title: '保存失败请刷新重试。'});
        }
      });
    });
  }

  // 返回
  gotoBack(): void {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    this.parentData.custormFormType = '';
    // 覆盖当前数据到data-center;
    _.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  ngOnInit() {
    this.modelState = [
      {
        code: 'ENABLE',
        name: '可用'
      },
      {
        code: 'DISABLE',
        name: '禁用'
      }
    ];
    this.modelType = [
      {
        code: 'basic',
        name: '基础模型'
      },
      {
        code: 'extend',
        name: '扩展模型'
      }
    ];
    this.requestData.getDataByCondition('headLine/ds/ds_stridic_list.json').subscribe(result => {
      this.dataTypeSelect = result['data'] || [];
    });
    if (this.parentData.modeType === 'add') {
      this.requestData.getDataByCondition('headLine/ds/ds_basic_model_count.json', {
        MODEL_TYPE: 'basic'
      }).subscribe(result => {
        if (result['data'][0].acount > 0) {
          this.modelType = [
            {
              code: 'extend',
              name: '扩展模型'
            }
          ];
          this.data.modelType = 'extend';
        } else {
          this.data.modelType = 'basic';
        }
        this.updateModelType();
      });
    } else if (this.parentData.modeType === 'modify') {
      this.dataService.searchModel({modelId: this.parentData.detailCondition.ID}).subscribe(response => {
        if (this.utils.Base_HasValue(response.error)) {
          this.confirmModal.show('error', {title: response.error.message});
        } else {
          this.data = response;
          if (this.data.modelType === 'extend') {
            this.setColumnTypeExtend();
          } else {
            this.setColumnTypeBasic();
          }
          _.each(this.data.groups, group => {
            if (group['type'] === 'list') {
              group['add'] = true;
            } else {
              group['add'] = false;
            }
          });
        }
      });
    }
  }

}
