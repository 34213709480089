import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {AppConfigService} from './../../../service/app-config.service';
import {RequestDataService} from './../../../service/request-data.service';
import {DataCenterService} from './../../../service/data-center.service';
import {UtilsService} from './../../../service/utils.service';
import {ListActionService} from '../../../service/list-action.service';

import * as _ from 'underscore';
import * as alasql from 'alasql';
import {ConfirmModalService} from '../../../service/confirm-modal.service';


@Component({
  selector: 'app-report-teacher',
  templateUrl: './report-teacher.component.html',
  styleUrls: ['./report-teacher.component.css']
})
export class ReportTeacherComponent implements OnInit {
  teacherData: any; // 教师数据
  internationalTeacher: any; // 国籍教师
  masterTeacherCount = 0; // 硕士生导师人数
  doctoralTeacherCount = 0; // 博士生导师人数
  otherUnitDegreeCount = 0; // 最高学位非本单位授予的人数
  senior: any = {
    VALUE1: 0,
    VALUE2: 0,
    VALUE3: 0,
    VALUE4: 0,
    VALUE5: 0,
    VALUE6: 0,
    VALUE7: 0,
    VALUE8: 0,
    VALUE9: 0,
    VALUE10: 0,
    VALUE11: 0
  }; // 正高级
  deputyHigh: any = {
    VALUE1: 0,
    VALUE2: 0,
    VALUE3: 0,
    VALUE4: 0,
    VALUE5: 0,
    VALUE6: 0,
    VALUE7: 0,
    VALUE8: 0,
    VALUE9: 0,
    VALUE10: 0,
    VALUE11: 0
  }; // 副高级
  intermediate: any = {
    VALUE1: 0,
    VALUE2: 0,
    VALUE3: 0,
    VALUE4: 0,
    VALUE5: 0,
    VALUE6: 0,
    VALUE7: 0,
    VALUE8: 0,
    VALUE9: 0,
    VALUE10: 0,
    VALUE11: 0
  };  // 中级
  other: any = {
    VALUE1: 0,
    VALUE2: 0,
    VALUE3: 0,
    VALUE4: 0,
    VALUE5: 0,
    VALUE6: 0,
    VALUE7: 0,
    VALUE8: 0,
    VALUE9: 0,
    VALUE10: 0,
    VALUE11: 0
  };  // 其它
  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService,
              private listAction: ListActionService) {
  }

  export() {
    const data1 = [
      {
        '专业技术职务': '正高级',
        '人数合计': this.senior.VALUE1,
        '35岁以下': this.senior.VALUE2,
        '36至40岁': this.senior.VALUE3,
        '41至45岁': this.senior.VALUE4,
        '46至50岁': this.senior.VALUE5,
        '50至55岁': this.senior.VALUE6,
        '56至60岁': this.senior.VALUE7,
        '61岁及以上': this.senior.VALUE8,
        '博士学位教师': this.senior.VALUE9,
        '海外经历教师': this.senior.VALUE10,
        '外籍教师': this.senior.VALUE11
      },
      {
        '专业技术职务': '副高级',
        '人数合计': this.deputyHigh.VALUE1,
        '35岁以下': this.deputyHigh.VALUE2,
        '36至40岁': this.deputyHigh.VALUE3,
        '41至45岁': this.deputyHigh.VALUE4,
        '46至50岁': this.deputyHigh.VALUE5,
        '50至55岁': this.deputyHigh.VALUE6,
        '56至60岁': this.deputyHigh.VALUE7,
        '61岁及以上': this.deputyHigh.VALUE8,
        '博士学位教师': this.deputyHigh.VALUE9,
        '海外经历教师': this.deputyHigh.VALUE10,
        '外籍教师': this.deputyHigh.VALUE11
      },
      {
        '专业技术职务': '中级',
        '人数合计': this.intermediate.VALUE1,
        '35岁以下': this.intermediate.VALUE2,
        '36至40岁': this.intermediate.VALUE3,
        '41至45岁': this.intermediate.VALUE4,
        '46至50岁': this.intermediate.VALUE5,
        '50至55岁': this.intermediate.VALUE6,
        '56至60岁': this.intermediate.VALUE7,
        '61岁及以上': this.intermediate.VALUE8,
        '博士学位教师': this.intermediate.VALUE9,
        '海外经历教师': this.intermediate.VALUE10,
        '外籍教师': this.intermediate.VALUE11
      },
      {
        '专业技术职务': '其它',
        '人数合计': this.other.VALUE1,
        '35岁以下': this.other.VALUE2,
        '36至40岁': this.other.VALUE3,
        '41至45岁': this.other.VALUE4,
        '46至50岁': this.other.VALUE5,
        '50至55岁': this.other.VALUE6,
        '56至60岁': this.other.VALUE7,
        '61岁及以上': this.other.VALUE8,
        '博士学位教师': this.other.VALUE9,
        '海外经历教师': this.other.VALUE10,
        '外籍教师': this.other.VALUE11
      },
      {
        '专业技术职务': '总计',
        '人数合计': this.senior.VALUE1 + this.deputyHigh.VALUE1 + this.intermediate.VALUE1 + this.other.VALUE1,
        '35岁以下': this.senior.VALUE2 + this.deputyHigh.VALUE2 + this.intermediate.VALUE2 + this.other.VALUE2,
        '36至40岁': this.senior.VALUE3 + this.deputyHigh.VALUE3 + this.intermediate.VALUE3 + this.other.VALUE3,
        '41至45岁': this.senior.VALUE4 + this.deputyHigh.VALUE4 + this.intermediate.VALUE4 + this.other.VALUE4,
        '46至50岁': this.senior.VALUE5 + this.deputyHigh.VALUE5 + this.intermediate.VALUE5 + this.other.VALUE5,
        '50至55岁': this.senior.VALUE6 + this.deputyHigh.VALUE6 + this.intermediate.VALUE6 + this.other.VALUE6,
        '56至60岁': this.senior.VALUE7 + this.deputyHigh.VALUE7 + this.intermediate.VALUE7 + this.other.VALUE7,
        '61岁及以上': this.senior.VALUE8 + this.deputyHigh.VALUE8 + this.intermediate.VALUE8 + this.other.VALUE8,
        '博士学位教师': this.senior.VALUE9 + this.deputyHigh.VALUE9 + this.intermediate.VALUE9 + this.other.VALUE9,
        '海外经历教师': this.senior.VALUE10 + this.deputyHigh.VALUE10 + this.intermediate.VALUE10 + this.other.VALUE10,
        '外籍教师': this.senior.VALUE11 + this.deputyHigh.VALUE11 + this.intermediate.VALUE11 + this.other.VALUE11,
      }
    ];
    const data2 = [
      {
        '硕士生导师人数': this.masterTeacherCount,
        '博士生导师人数': this.doctoralTeacherCount,
        '最高学位非本单位授予的人数': this.otherUnitDegreeCount
      }
    ];
    const opts = [
      {
        sheetid: 'II-1 专任教师基本情况',
        header: true
      },
      {
        sheetid: '教师人数',
        header: true
      }
    ];
    alasql.promise('SELECT INTO XLSX("II-1 专任教师基本情况.xlsx",?) FROM ?', [opts, [data1, data2]])
      .then((data) => {
        if (data === 1) {
          this.confirmModal.show('success', {
            title: '导出成功',
          });
        }
      });
  }

  // 赋值
  assignment(pArray, key) {
    _.each(pArray, oneData => {
      if (oneData['TECHNICAL_POSITION'] === '教授') {
        this.senior[key] = oneData['acount'];
      } else if (oneData['TECHNICAL_POSITION'] === '副教授') {
        this.deputyHigh[key] = oneData['acount'];
      } else if (oneData['TECHNICAL_POSITION'] === '讲师') {
        this.intermediate[key] = oneData['acount'];
      } else {
        this.other[key] += oneData['acount'];
      }
    });
  }

  transformatData() {
    const that = this;
    const alaSql = require('alasql');
    const moment = require('moment');
    this.other = {
      VALUE1: 0,
      VALUE2: 0,
      VALUE3: 0,
      VALUE4: 0,
      VALUE5: 0,
      VALUE6: 0,
      VALUE7: 0,
      VALUE8: 0,
      VALUE9: 0,
      VALUE10: 0,
      VALUE11: 0
    };
    function teacherCount(pCondition, pType?) {
      // tslint:disable-next-line:max-line-length
      if (that.utils.Base_HasValue(pType)) {
        return alaSql('SELECT  TECHNICAL_POSITION , count(*) acount FROM ? WHERE ' + pCondition + ' GROUP BY TECHNICAL_POSITION', [that.internationalTeacher]);
      } else {
        return alaSql('SELECT  TECHNICAL_POSITION , count(*) acount FROM ? WHERE ' + pCondition + ' GROUP BY TECHNICAL_POSITION', [that.teacherData]);
      }
    }

    function teacherTypeCount(pCondition) {
      // tslint:disable-next-line:max-line-length
      return alaSql('SELECT  count(*) acount FROM ? WHERE ' + pCondition, [that.teacherData]);
    }

    _.each(that.teacherData, oneData => {
      if (that.utils.Base_HasValue(oneData['TEACHER_BIRTHDAY'])) {
        oneData['AGE'] = moment().diff(oneData['TEACHER_BIRTHDAY'], 'years');
      }
    });
    const numberPeople = alaSql('SELECT  TECHNICAL_POSITION , count(*) acount FROM ? GROUP BY TECHNICAL_POSITION', [that.teacherData]); // 各专业人数
    const age35 = teacherCount(' AGE <= 35 ');
    const age36 = teacherCount(' AGE >= 36 AND AGE <= 40');
    const age41 = teacherCount(' AGE >= 41 AND AGE <= 45');
    const age46 = teacherCount(' AGE >= 46 AND AGE <= 50');
    const age51 = teacherCount(' AGE >= 51 AND AGE <= 55');
    const age56 = teacherCount(' AGE >= 56 AND AGE <= 60');
    const age60 = teacherCount(' AGE >= 61');
    const maxDegree = teacherCount('TEACHER_MAX_DEGREE = "博士学位"'); // 博士学位
    const visitingAbroad = teacherCount('VISITING_ABROAD = "是"'); // 有出国经历的教师
    const foreignTeacher = teacherCount('1 = 1', '1'); // 外国教师
    that.masterTeacherCount = teacherTypeCount('TUTOR_CATEGORY = "硕导"')[0].acount || 0; // 硕导人数
    that.doctoralTeacherCount = teacherTypeCount('TUTOR_CATEGORY = "博导"')[0].acount || 0;  // 博导人数
    that.otherUnitDegreeCount = teacherTypeCount('IS_UNIT = "否"')[0].acount || 0;  // 最高学位非本单位
    this.assignment(numberPeople, 'VALUE1');
    this.assignment(age35, 'VALUE2');
    this.assignment(age36, 'VALUE3');
    this.assignment(age41, 'VALUE4');
    this.assignment(age46, 'VALUE5');
    this.assignment(age51, 'VALUE6');
    this.assignment(age56, 'VALUE7');
    this.assignment(age60, 'VALUE8');
    this.assignment(maxDegree, 'VALUE9');
    this.assignment(visitingAbroad, 'VALUE10');
    this.assignment(foreignTeacher, 'VALUE11');
  }

  // 过滤
  searchFilter() {
    this.ngOnInit();
  }

  ngOnInit() {
    const parallel = observableForkJoin(
      this.requestDataService.getDataByCondition('subjectInfo/report/report_teacher.json'),
      this.requestDataService.getDataByCondition('subjectInfo/report/report_teacher_international.json')
    );
    parallel.subscribe(response => {
      this.teacherData = response[0]['data'];
      this.internationalTeacher = response[1]['data'];
      this.transformatData();
    });
  }
}
